import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as listedSuppliersActions from '@payaca/store/listedSuppliers/listedSuppliersActions';
import * as suppliersActions from '@payaca/store/suppliers/suppliersActions';
import { GetListedSuppliersRequestData } from '@payaca/store/listedSuppliers/listedSuppliersTypes';

import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CreateEditSupplierModal from '@/ui/components/createEditSupplierModal/CreateEditSupplierModal';
import ListedSuppliersTable from '@/ui/components/listedSuppliersTable/ListedSuppliersTable';
import Modal from '@payaca/components/modal/Modal';

import { actions as appActions } from '@/api/app';

import { SuppliersPermissions } from '@payaca/permissions/suppliers/suppliers.permissions';

import { singularPlural } from '@payaca/utilities/stringUtilities';

import { SortBy } from '@payaca/types/listedSupplierTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

import './ListedSuppliersPage.sass';
import { useSelector } from '@/api/state';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@/i18n';

const ListedSuppliersPage: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showCreateSupplierModal, setShowCreateSupplierModal] = useState(false);
  const [isInBulkActionsMode, setIsInBulkActionsMode] = useState(false);
  const [selectedSupplierIds, setSelectedSupplierIds] = useState<number[]>([]);
  const [showConfirmDeleteSuppliersModal, setShowConfirmDeleteSuppliersModal] =
    useState(false);

  const [getListedSuppliersRequestData, setGetListedSuppliersRequestData] =
    useState<GetListedSuppliersRequestData>({
      pageSize: 50,
      pageNumber: 1,
      searchTerm: '',
      sortDirection: SortDirection.ASCENDING,
      sortBy: SortBy.NAME,
    });

  const isArchivingSuppliers = useSelector((state) => {
    return state.suppliers.isArchivingSuppliers;
  });

  useEffect(() => {
    return () => {
      dispatch(listedSuppliersActions.clearListedSuppliersPage());
      dispatch(suppliersActions.clearArchiveSuppliers());
    };
  }, []);

  useEffect(() => {
    requestGetListedSuppliersPage();
  }, [getListedSuppliersRequestData]);

  const requestGetListedSuppliersPage = useCallback(() => {
    dispatch(
      listedSuppliersActions.requestGetListedSuppliersPage(
        getListedSuppliersRequestData
      )
    );
  }, [getListedSuppliersRequestData, dispatch]);

  const onSelectPage = useCallback((pageNumber: number) => {
    setGetListedSuppliersRequestData(
      (getListedSuppliersRequestData: GetListedSuppliersRequestData) => {
        return {
          ...getListedSuppliersRequestData,
          pageNumber: pageNumber,
        };
      }
    );
  }, []);

  const onSelectPageSize = useCallback((pageSize: number) => {
    setGetListedSuppliersRequestData(
      (getListedSuppliersRequestData: GetListedSuppliersRequestData) => {
        return {
          ...getListedSuppliersRequestData,
          pageSize: pageSize,
          pageNumber: 1,
        };
      }
    );
  }, []);

  const onChangeSortOrder = useCallback(
    (sortBy: SortBy, sortDirection: SortDirection) => {
      setGetListedSuppliersRequestData(
        (getListedSuppliersRequestData: GetListedSuppliersRequestData) => {
          return {
            ...getListedSuppliersRequestData,
            sortBy: sortBy,
            sortDirection: sortDirection,
          };
        }
      );
    },
    []
  );

  const onSelectSuppliers = useCallback((supplierIds: number[]) => {
    setSelectedSupplierIds((selectedSupplierIds) => {
      return selectedSupplierIds
        .concat(supplierIds)
        .filter((supplierId: number, index: number, array: number[]) => {
          return array.indexOf(supplierId) === index; // get unique
        });
    });
  }, []);

  const onUnselectSuppliers = useCallback((supplierIds: number[]) => {
    setSelectedSupplierIds((selectedSupplierIds) => {
      return selectedSupplierIds.filter((supplierId: number) => {
        return !supplierIds.includes(supplierId);
      });
    });
  }, []);

  const listedSuppliersTitleBar = useMemo(() => {
    return (
      <div className="listed-suppliers-title-bar flex-grow">
        <div className="listed-suppliers-title-bar-inner flex-container flex-center">
          <div>
            <h1>Suppliers</h1>
          </div>
          <PermissionGuard
            renderIfHasPermissions={[SuppliersPermissions.PERSIST_SUPPLIER]}
          >
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              onClick={() => setShowCreateSupplierModal(true)}
            >
              Create Supplier
            </Button>
          </PermissionGuard>
          <PermissionGuard
            renderIfHasPermissions={[SuppliersPermissions.ARCHIVE_SUPPLIER]}
          >
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={() => setIsInBulkActionsMode(true)}
            >
              Bulk edit
            </Button>
          </PermissionGuard>
        </div>
      </div>
    );
  }, [history]);

  const navigateToSupplierPage = useCallback(
    (supplierId: number) => {
      history.push(`/suppliers/${supplierId}`);
    },
    [history]
  );

  const onClickRow = useCallback(
    (supplierId: number) => {
      navigateToSupplierPage(supplierId);
    },
    [navigateToSupplierPage]
  );

  const supplierActionCallback = useCallback(
    (error?: Error) => {
      if (error) {
        dispatch(appActions.showBanner({ type: 'error' }));
      }
      setShowConfirmDeleteSuppliersModal(false);
      setIsInBulkActionsMode(false);
      setSelectedSupplierIds([]);
      requestGetListedSuppliersPage();
    },
    [requestGetListedSuppliersPage, dispatch]
  );

  const onBulkDeleteSuppliers = useCallback(
    (supplierIds: number[]) => {
      dispatch(
        suppliersActions.requestArchiveSuppliers(supplierIds, () => {
          supplierActionCallback();
        })
      );
    },
    [dispatch, supplierActionCallback]
  );

  const listedSuppliersBulkActionsBar = useMemo(() => {
    const isAnySuppliersSelected = !!selectedSupplierIds.length;

    return (
      <div className="flex-container flex-center flex-grow bulk-actions-title-bar">
        <div className="flex-container">
          <div>
            <h1>Suppliers</h1>
          </div>
          <span>
            {isAnySuppliersSelected
              ? `You have ${singularPlural(
                  selectedSupplierIds.length,
                  'row',
                  'rows'
                )} selected`
              : 'Check the rows you wish to select'}
          </span>
        </div>

        <div className="flex-grow"></div>
        <Button
          isDisabled={!isAnySuppliersSelected}
          isProcessing={isArchivingSuppliers}
          onClick={() =>
            !isArchivingSuppliers && setShowConfirmDeleteSuppliersModal(true)
          }
        >
          Delete
        </Button>
        <Button
          styleVariant={ButtonStyleVariant.ANCHOR}
          onClick={() => {
            setIsInBulkActionsMode(false);
            setSelectedSupplierIds([]);
          }}
        >
          Cancel
        </Button>
      </div>
    );
  }, [selectedSupplierIds, onBulkDeleteSuppliers, isArchivingSuppliers]);

  const translate = useTranslation();

  return (
    <>
      <Helmet title={translate('pages.suppliers.title')} />
      <AuthenticatedPageWrapper
        className="listed-suppliers-page"
        title={
          isInBulkActionsMode
            ? listedSuppliersBulkActionsBar
            : listedSuppliersTitleBar
        }
        searchFieldConfig={{
          placeholder: 'Search',
          onSearchTermChange: (searchTerm?: string) => {
            setGetListedSuppliersRequestData(
              (getListedItemsRequestData: GetListedSuppliersRequestData) => {
                return {
                  ...getListedItemsRequestData,
                  searchTerm: searchTerm,
                  pageNumber: 1,
                };
              }
            );
          },
        }}
      >
        <div
          className={`listed-suppliers-table-container ${
            isInBulkActionsMode ? 'bulk-actions-mode' : ''
          }`}
        >
          <ListedSuppliersTable
            sortBy={getListedSuppliersRequestData.sortBy}
            sortDirection={getListedSuppliersRequestData.sortDirection}
            searchTerm={getListedSuppliersRequestData.searchTerm}
            onClickRow={isInBulkActionsMode ? undefined : onClickRow}
            onSelectPage={onSelectPage}
            onChangeSortOrder={onChangeSortOrder}
            onSelectPageSize={onSelectPageSize}
            quickActionDefinitions={[
              {
                actionName: 'Edit',
                actionBehaviour: navigateToSupplierPage,
              },
              {
                actionName: 'Delete',
                actionBehaviour: (supplierId: number) => {
                  setSelectedSupplierIds([supplierId]);
                  setShowConfirmDeleteSuppliersModal(true);
                },
              },
            ]}
            selectedSupplierIds={selectedSupplierIds}
            onSelectSuppliers={onSelectSuppliers}
            onUnselectSuppliers={onUnselectSuppliers}
          />
        </div>
        <CreateEditSupplierModal
          isOpen={showCreateSupplierModal}
          onClose={() => setShowCreateSupplierModal(false)}
          onPersistSupplierSuccess={(supplierId: number) => {
            history.push(`/suppliers/${supplierId}`);
          }}
        />
        <Modal
          isOpen={showConfirmDeleteSuppliersModal}
          onClose={() => {
            setShowConfirmDeleteSuppliersModal(false);
          }}
          title={`Are you sure you want to delete ${singularPlural(
            selectedSupplierIds.length,
            'supplier',
            'suppliers'
          )}?`}
          actions={
            <>
              <Button
                styleVariant={ButtonStyleVariant.OUTSIZE}
                onClick={() => {
                  if (isArchivingSuppliers) return;
                  onBulkDeleteSuppliers(selectedSupplierIds);
                }}
                isProcessing={isArchivingSuppliers}
              >
                Delete
              </Button>
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={() => {
                  setShowConfirmDeleteSuppliersModal(false);
                }}
              >
                Cancel
              </Button>
            </>
          }
        ></Modal>
      </AuthenticatedPageWrapper>
    </>
  );
};

export default ListedSuppliersPage;
