import { ListViewPage, SortDirection } from './listViewTypes';

import {
  PublicHydratedTimelog,
  PublicTimelogAssignee,
  TimelogAssignee,
  TimelogLinkedEntity,
  TimelogType,
} from './timelogs';
import { Nullable } from '@payaca/utilities/types';

export type ListedTimelog = PublicHydratedTimelog;

export enum SortBy {
  END_TIME = 'endTime',
  COST = 'calculatedCost',
  COST_PER_HOUR = 'costPerHour',
}

export type GetListedTimelogsQueryParams = {
  pageNumber: number;
  pageSize: number;
  sortBy: SortBy;
  sortDirection: SortDirection;
  periodStart?: Date;
  periodEnd?: Date;
  typePublicIds?: TimelogType['publicId'][];
  assignees?: {
    type: TimelogAssignee['type'];
    publicId: TimelogAssignee['publicId'];
  }[];
  entity?: Pick<TimelogLinkedEntity, 'entityId' | 'entityType'>;
};

export type GetAssigneeGroupedListedTimelogsQueryParams = Pick<
  GetListedTimelogsQueryParams,
  'periodStart' | 'periodEnd' | 'typePublicIds' | 'assignees' | 'entity'
>;

export type TimelogsTotals = {
  totalCalculatedCost: number;
  totalDuration?: Nullable<string>;
};

export type ListedTimelogsPage = ListViewPage<ListedTimelog> & TimelogsTotals;

export type AssigneeTimelogsGrouping = TimelogsTotals & {
  assignee: PublicTimelogAssignee;
  timelogs: ListedTimelog[];
};

export type AssigneeGroupedListedTimelogs = TimelogsTotals & {
  assigneeGroupings: AssigneeTimelogsGrouping[];
};
