import { GetSuppliersInput, PaginationInput } from '@/gql/graphql';

const supplierKeys = {
  all: ['suppliers'],
  search: (input: GetSuppliersInput, pagination: PaginationInput) => [
    ...supplierKeys.all,
    'search',
    input,
    pagination,
  ],
};

export default supplierKeys;
