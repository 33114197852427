import React, { FC, useEffect, useMemo } from 'react';
import { Calendar } from 'react-iconly';
import { useHistory } from 'react-router';

import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';

import { groupScheduledEventsByDate } from '@payaca/helpers/scheduledEventsHelper';

import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import Button from '@payaca/components/button/Button';
import GroupedScheduledEventsList from '../groupedScheduledEventsList/GroupedScheduledEventsList';
import UpcomingScheduledEvent from './UpcomingScheduledEvent';
import { HeaderTagType } from '@payaca/components/headerTag/HeaderTag';

import './UpcomingScheduledEventsList.sass';

type Props = {
  scheduledEvents: any[];
  getScheduledEvents: () => void;
  isGettingScheduledEvents: boolean;
  showLocation?: boolean;
};
const UpcomingScheduledEventsList: FC<Props> = ({
  scheduledEvents,
  getScheduledEvents,
  isGettingScheduledEvents,
  showLocation,
}: Props): JSX.Element => {
  const history = useHistory();
  const groupedScheduledEvents = useMemo(() => {
    return groupScheduledEventsByDate(scheduledEvents);
  }, [scheduledEvents]);

  useEffect(() => {
    getScheduledEvents();
  }, []);

  return (
    <div className="upcoming-scheduled-events-list">
      {isGettingScheduledEvents && !scheduledEvents?.length && (
        <div className="loader-container">
          <MiniLoader />
        </div>
      )}
      {!scheduledEvents?.length && !isGettingScheduledEvents && (
        <div className="empty-state-container">
          <div className="calendar-icon-container">
            <Calendar size={50} set={'light'} />
          </div>
          <h2>No upcoming Events</h2>
          <p>You can add Events via your Schedule</p>
          <Button onClick={() => history.push('/schedule')}>Schedule</Button>
        </div>
      )}
      {!!scheduledEvents?.length && (
        <>
          <div
            className="calendar-link"
            onClick={() => history.push('/schedule')}
          >
            <Calendar size={22} set={'light'} />
          </div>
          {groupedScheduledEvents && (
            <GroupedScheduledEventsList
              groupHeaderTagType={HeaderTagType.H2}
              groupedScheduledEvents={groupedScheduledEvents}
              renderScheduledEvent={(scheduledEvent: ScheduledEvent) => {
                return (
                  <UpcomingScheduledEvent
                    scheduledEvent={scheduledEvent}
                    onScheduledEventUpdateSuccess={getScheduledEvents}
                    showLocation={showLocation}
                  />
                );
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UpcomingScheduledEventsList;
