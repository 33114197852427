import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import Badge from '@payaca/components/badge/Badge';
import Modal from '@payaca/components/plModal/Modal';
import EntityCard from '@payaca/components/entityCard/EntityCard';
import CreateAddOnSubscriptionControl from './CreateAddOnSubscriptionControl';
import Button from '@payaca/components/plButton/Button';

import {
  PublicAddOnProductPrice,
  PublicHydratedAddOnProduct,
  Tier,
} from '@payaca/types/add-on-products';

import { getAccountRegionFromCurrencyCode } from '@payaca/helpers/internationalHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  isGraduatedPrice,
  isPerUnitPrice,
  isVolumePrice,
} from '@payaca/helpers/addOnProductsHelper';

import { useAccountSubscription } from '@payaca/store/hooks/appState';
import { isNullish } from '@payaca/utilities/guards';

import { requestGetAccountSubscription } from '@payaca/store/subscription/subscriptionActions';

import './AvailableAddOnProductCard.sass';
import { requestGetUserRoles } from '@payaca/store/userRoles/userRolesActions';
import { EBtnSize } from '@payaca/components/plButton/useButtonClassName';

type Props = {
  addOnProduct: PublicHydratedAddOnProduct;
};
const AvailableAddOnProductCard: FunctionComponent<Props> = ({
  addOnProduct,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const accountSubscription = useAccountSubscription();

  const [selectedPrice, setSelectedPrice] = useState<PublicAddOnProductPrice>();

  const addOnSubscription = useMemo(
    () =>
      accountSubscription?.addOnSubscriptions.find(
        (x) => x.addOnProductPublicId === addOnProduct.publicId
      ),
    [accountSubscription, addOnProduct]
  );

  const renderPriceTier = useCallback(
    (
      tier: Tier<'graduated' | 'volume'>,
      price: PublicAddOnProductPrice,
      i: number
    ) => {
      const region = getAccountRegionFromCurrencyCode(
        price.currencyCode || 'gbp'
      );

      const isGraduated = isGraduatedPrice(price);

      return (
        <div className={`add-on-product-price-tier`} key={i}>
          <span className="description">
            {tier.minQty || 0}{' '}
            {isNullish(tier.maxQty) ? '+' : <>- {tier.maxQty}</>}{' '}
            {price.unitNounPlural}
          </span>
          <span className="price">
            {isGraduated ? (
              <>
                {currencyPrice((tier as Tier<'graduated'>).flatAmount, region)}
              </>
            ) : (
              <>{currencyPrice((tier as Tier<'volume'>).unitAmount, region)}</>
            )}
          </span>
        </div>
      );
    },
    []
  );

  const renderPrice = useCallback(
    (price: PublicAddOnProductPrice, i: number) => {
      const region = getAccountRegionFromCurrencyCode(
        price.currencyCode || 'gbp'
      );

      return (
        <div className={`add-on-product-price`} key={i}>
          <h4 className="price-description">
            Pricing{' '}
            {isVolumePrice(price) ? ` per ${price.unitNounSingular}` : ''} per{' '}
            {price.billingInterval}
            {!isNullish(price.taxPercentage) ? ` + VAT` : ''}
          </h4>
          {isPerUnitPrice(price) && (
            <div className={`add-on-product-price-tier`} key={i}>
              <span className="description">{price.description}</span>
              <span className="price">
                {currencyPrice(price.unitAmount, region)}
              </span>
            </div>
          )}
          {price.tiers?.map((tier, ii) => renderPriceTier(tier, price, ii))}
        </div>
      );
    },
    [addOnProduct, renderPriceTier]
  );

  return (
    <EntityCard
      className="available-add-on-product-card"
      expandableFooterConfig={{
        expandPrompt: addOnSubscription ? 'Show pricing' : 'Enable',
        collapsePrompt: 'Hide',
        content: (
          <div className="flex flex-col items-center pt-2">
            <div>{addOnProduct.prices.map(renderPrice)}</div>
            {!addOnSubscription && (
              <Button
                onClick={() => setSelectedPrice(addOnProduct.prices[0])}
                size={EBtnSize.Small}
              >
                Enable add-on
              </Button>
            )}
          </div>
        ),
      }}
    >
      <div className="add-on-product-info">
        <div className="title-more-info-wrapper">
          <h4>{addOnProduct.name}</h4>
          {addOnSubscription && (
            <Badge backgroundColour="#4ea83d" colour="#fff">
              Enabled
            </Badge>
          )}
        </div>
        <p>{addOnProduct.description}</p>
      </div>

      {selectedPrice && (
        <Modal
          title={`Enable ${addOnProduct.name} add-on`}
          isOpen={!!selectedPrice}
          onClose={() => {
            setSelectedPrice(undefined);
          }}
        >
          <Modal.Body>
            <CreateAddOnSubscriptionControl
              existingAddOnSubscriptionId={addOnSubscription?.publicId}
              price={selectedPrice}
              addOnProduct={addOnProduct}
              onSuccess={() => {
                setSelectedPrice(undefined);
                dispatch(requestGetAccountSubscription());
                dispatch(requestGetUserRoles());
              }}
            />
          </Modal.Body>
        </Modal>
      )}
    </EntityCard>
  );
};

export default AvailableAddOnProductCard;
