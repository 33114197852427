import UntitledIcon from '@payaca/untitled-icons';
import { formatDistanceToNow } from 'date-fns';
import { FC, useCallback, useMemo } from 'react';
import ListedNotificationActionButtons from './ListedNotificationActionButtons';
import { useHistory } from 'react-router';
import {
  ChecklistTask,
  ConnectionApplication,
  FormTask,
  IntegratedEmail,
  LegacyFormTask,
  MaterialsListTask,
  Note,
  ScheduledEvent,
} from '../../../../gql/graphql';

type TLinkedEntityChecklistTask = Pick<ChecklistTask, 'id' | '__typename'> & {
  events: { id: string }[];
};
type TLinkedEntityFormTask = Pick<FormTask, 'id' | '__typename'> & {
  events: { id: string }[];
};
type TLinkedEntityLegacyFormTask = Pick<LegacyFormTask, 'id' | '__typename'> & {
  events: { id: string }[];
};
type TLinkedEntityMaterialsListTask = Pick<
  MaterialsListTask,
  'id' | '__typename'
> & {
  events: { id: string }[];
  project: { id: string };
};
type TLinkedEntityNote = Pick<Note, 'id' | '__typename'> & {
  event: { id: string };
  project?: { id: string };
};
type TLinkedEntityScheduledEvent = Pick<ScheduledEvent, 'id' | '__typename'> & {
  project?: { id: string };
};
type TLinkedEntityIntegratedEmail = Pick<
  IntegratedEmail,
  'id' | '__typename'
> & {
  project: { id: string };
};
type TLinkedEntityConnectionApplication = Pick<
  ConnectionApplication,
  'id' | '__typename'
> & {
  project: { id: string };
};

export type TLinkedEntity =
  | TLinkedEntityChecklistTask
  | TLinkedEntityFormTask
  | TLinkedEntityLegacyFormTask
  | TLinkedEntityNote
  | TLinkedEntityScheduledEvent
  | TLinkedEntityIntegratedEmail
  | TLinkedEntityConnectionApplication
  | TLinkedEntityMaterialsListTask
  | null;

type Notification = {
  title: string;
  body?: string | null;
  timestamp: string;
  viewedAt?: string | null;
  linkedEntity?: TLinkedEntity;
  markNotificationAsRead: () => void;
};
const ListedNotification: FC<Notification> = (props) => {
  const {
    title,
    body,
    timestamp,
    viewedAt,
    linkedEntity,
    markNotificationAsRead,
  } = props;
  const history = useHistory();

  const icon = useMemo(() => {
    switch (linkedEntity?.__typename) {
      case 'ScheduledEvent':
        return 'calendar';
      case 'Note':
        return 'edit-01.3';
      case 'MaterialsListTask':
      case 'ChecklistTask':
        return 'check-done-02.3';
      case 'FormTask':
      case 'LegacyFormTask':
        return 'file-06.3';
      case 'IntegratedEmail':
        return 'mail-02.3';
    }
  }, [linkedEntity?.__typename]);

  const onClick = useCallback(() => {
    markNotificationAsRead();
    switch (linkedEntity?.__typename) {
      case 'ScheduledEvent':
        if (linkedEntity.project?.id) {
          history.push(
            `/deals/${linkedEntity.project.id}/schedule/${linkedEntity.id}`
          );
        } else {
          history.push(`/schedule/${linkedEntity.id}`);
        }
        break;
      case 'Note':
        if (linkedEntity?.project?.id) {
          history.push(`/deals/${linkedEntity.project.id}/activity`);
        } else if (linkedEntity?.event?.id) {
          history.push(`/schedule/${linkedEntity.event.id}`);
        }
        break;
      case 'ChecklistTask':
      case 'FormTask':
      case 'LegacyFormTask':
      case 'MaterialsListTask':
        if (linkedEntity?.events?.[0]?.id) {
          history.push(`/schedule/${linkedEntity.events[0].id}`);
        } else {
          history.push(`/tasks/${linkedEntity.id}`);
        }
        break;
      case 'IntegratedEmail':
        if (linkedEntity?.project?.id) {
          history.push(`/deals/${linkedEntity.project.id}`);
        }
        break;
      case 'ConnectionApplication':
        if (linkedEntity?.project?.id) {
          history.push(`/deals/${linkedEntity.project.id}/compliance`);
        }
        break;
    }
  }, [linkedEntity]);

  return (
    <li
      className={`group/listed-notification relative flex w-full gap-x-5 p-5 text-start dark:bg-neutral-800 pr-10${
        viewedAt ? '' : ' bg-gray-100'
      }${linkedEntity ? ' cursor-pointer' : ''}`}
      onClick={linkedEntity ? onClick : undefined}
    >
      {/* icon */}
      <div className="relative flex-shrink-0">
        {icon && <UntitledIcon name={icon} className="h-6 w-6" />}
        {!viewedAt && (
          <span className="absolute -start-3 top-2 h-2 w-2 rounded-full bg-cyan-700 dark:bg-blue-500" />
        )}
      </div>

      {/* content */}
      <div className="grow">
        {/* timestamp */}
        <p className="m-0 text-xs text-gray-500 dark:text-neutral-500">
          {formatDistanceToNow(timestamp, { addSuffix: true })}
        </p>

        {/* title */}
        <span className="block text-sm font-medium text-gray-800 dark:text-neutral-300">
          {title}
        </span>
        {/* body */}
        <p className="text-sm text-gray-500 dark:text-neutral-500">{body}</p>
      </div>
      <ListedNotificationActionButtons
        markNotificationAsRead={() => markNotificationAsRead()}
      />
    </li>
  );
};
export default ListedNotification;
