import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import BasicField from '@payaca/components/basicField/BasicField';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import EditableElementControl from '@payaca/components/editableElementControl/EditableElementControl';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import TextareaFieldFormatter from '@payaca/components/textareaField/TextareaFieldFormatter';
import UploadCollectionControl from '../../components/uploadCollectionControl/UploadCollectionControl';
import ServicePlanCommitmentsControl from '../servicePlanCommitmentControl/ServicePlanCommitmentsControl';
import ServicePlanPricesControl from '../servicePlanPricesControl/ServicePlanPricesControl';
import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';

import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';

import {
  UpdateServicePlanCommitmentInput,
  UpdateServicePlanPriceInput,
} from '@payaca/store/servicePlans/servicePlansTypes';
import { ServiceInterval } from '@payaca/types/service-plans';

import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';
import { sortByDateKey } from '@payaca/utilities/sortable';

import {
  useServicePlan,
  useSubscriptionsForServicePlan,
} from '@payaca/store/hooks/appState';
import {
  createServicePlanCommitment,
  createServicePlanPrice,
  deleteServicePlanCommitment,
  updateServicePlanCommitment,
  updateServicePlanPrice,
  deleteServicePlanPrice,
} from '@payaca/store/servicePlans/servicePlansActions';

import './ManageServicePlanControl.sass';
import { useAccount } from '@/utils/storeHooks';
import { AccountRegions } from '@payaca/types/accountTypes';

const acceptFileTypes = getAcceptedFileTypes(['document']);
const serviceIntervalOptions: { label: string; value: ServiceInterval }[] = [
  { label: 'year', value: 'year' },
  { label: 'month', value: 'month' },
];

type Props = {
  servicePlanId: string;
};

const ManageServicePlanControl = ({ servicePlanId }: Props) => {
  const dispatch = useDispatch();
  const account = useAccount();

  const servicePlan = useServicePlan(servicePlanId);
  const subscriptions = useSubscriptionsForServicePlan(servicePlanId);

  const [hasChangesMade, setHasChangesMade] = useState(false);

  const hasSubscriptions = useMemo(() => {
    return !!subscriptions.length;
  }, [subscriptions]);

  const getServicePlan = useCallback(() => {
    return new Promise<void>((resolve) => {
      dispatch(
        servicePlansActions.getServicePlan.request({
          publicId: servicePlanId,
          callback: () => resolve(),
        })
      );
    });
  }, [servicePlanId]);

  useEffect(() => {
    if (servicePlanId) {
      getServicePlan();
      dispatch(
        servicePlansActions.getSubscriptionsForServicePlan.request({
          servicePlanId: servicePlanId,
        })
      );
    }
    () => {
      dispatch(
        servicePlansActions.clearServicePlanSubscriptions.request({
          servicePlanId: servicePlanId,
        })
      );
    };
  }, [servicePlanId, getServicePlan]);

  const [name, setName] = useState(servicePlan?.name);
  const [description, setDescription] = useState(servicePlan?.description);
  const [serviceTermsDirectLink, setServiceTermsDirectLink] = useState(
    servicePlan?.serviceTermsDirectLink || ''
  );
  const [serviceInterval, setServiceInterval] = useState(
    servicePlan?.serviceInterval
  );
  const [uploadId, setUploadId] = useState(servicePlan?.termsUploadId);

  useEffect(() => {
    if (servicePlan) {
      setName(servicePlan.name);
      setDescription(servicePlan.description);
      setServiceInterval(servicePlan.serviceInterval);
      setServiceTermsDirectLink(servicePlan.serviceTermsDirectLink || '');
    }
  }, [servicePlan?.publicId]);

  if (!servicePlan) {
    return null;
  }

  return (
    <div className="manage-service-plan-control">
      <div className="name-wrapper">
        <EditableElementControl
          addText={'Name'}
          value={name}
          onSave={() => {
            dispatch(
              servicePlansActions.updateServicePlan.request({
                servicePlanPublicId: servicePlan.publicId,
                updateServicePlanRequestData: {
                  name: name,
                },
                callback: () => {
                  getServicePlan();
                },
                onErrorCallback: () => setName(servicePlan.name),
              })
            );
          }}
          editTemplate={
            <BasicField
              name="name"
              value={name}
              onChange={(value) => {
                setHasChangesMade(value.name !== name);
                setName(value.name);
              }}
            />
          }
          renderValue={(value) => <h1>{value}</h1>}
        />
      </div>
      <div className="service-plan-details-wrapper">
        <div className="basic-information">
          <EditableElementControl
            addText={'Description'}
            value={description}
            onSave={() => {
              dispatch(
                servicePlansActions.updateServicePlan.request({
                  servicePlanPublicId: servicePlan.publicId,
                  updateServicePlanRequestData: {
                    description: description,
                  },
                  callback: () => {
                    getServicePlan();
                  },
                  onErrorCallback: () =>
                    setDescription(servicePlan.description),
                })
              );
            }}
            editTemplate={
              <TextareaFieldFormatter
                name="description"
                value={description}
                onChange={(value) => {
                  setHasChangesMade(value.description !== description);
                  setDescription(value.description);
                }}
                additionalEditorProps={{ onblur: () => null }}
              />
            }
            renderValue={(value) => <MarkdownLabel markdown={value} />}
          />
          {!hasSubscriptions && (
            <>
              {serviceTermsDirectLink ? (
                <>
                  <FieldLabel label="Terms and conditions link" />
                  <EditableElementControl
                    addText={'Terms and conditions'}
                    value={serviceTermsDirectLink}
                    onSave={() => {
                      dispatch(
                        servicePlansActions.updateServicePlan.request({
                          servicePlanPublicId: servicePlan.publicId,
                          updateServicePlanRequestData: {
                            serviceTermsDirectLink,
                          },
                          callback: () => {
                            getServicePlan();
                          },
                          onErrorCallback: () =>
                            setServiceTermsDirectLink(
                              servicePlan?.serviceTermsDirectLink || ''
                            ),
                        })
                      );
                    }}
                    editTemplate={
                      <BasicField
                        name="serviceTermsDirectLink"
                        value={serviceTermsDirectLink}
                        onChange={(value) => {
                          setHasChangesMade(
                            value.serviceTermsDirectLink !==
                              serviceTermsDirectLink
                          );
                          setServiceTermsDirectLink(
                            value.serviceTermsDirectLink
                          );
                        }}
                      />
                    }
                    renderValue={(value) => (
                      <a target="_blank" rel="noreferrer" href={value}>
                        {value}
                      </a>
                    )}
                  />
                </>
              ) : (
                <UploadCollectionControl
                  label="Upload terms and conditions"
                  uploadIds={uploadId ? [uploadId] : []}
                  onUploadCollectionChange={(uploadIds: number[]) => {
                    dispatch(
                      servicePlansActions.updateServicePlan.request({
                        servicePlanPublicId: servicePlan.publicId,
                        updateServicePlanRequestData: {
                          termsUploadId: uploadIds[0] || null,
                        },
                        callback: () => {
                          setUploadId(uploadIds[0] || null);
                          getServicePlan();
                        },
                        onErrorCallback: () =>
                          setUploadId(servicePlan.termsUploadId),
                      })
                    );
                  }}
                  acceptFileTypes={acceptFileTypes}
                  showFileNames={true}
                  allowMultipleUploads={false}
                  isDisabled={hasSubscriptions}
                />
              )}
            </>
          )}
          {(servicePlan.serviceTermsDirectLink ||
            servicePlan.downloadTermsUrl) && (
            <a
              href={
                servicePlan.serviceTermsDirectLink ||
                servicePlan.downloadTermsUrl ||
                undefined
              }
              download
            >
              <FontAwesomeIcon icon={faDownload} /> Terms and conditions
            </a>
          )}

          {hasSubscriptions ? (
            <p>
              This Service Plan will be carried out every{' '}
              {servicePlan.serviceIntervalCount > 1
                ? `${servicePlan.serviceIntervalCount} ${servicePlan.serviceInterval}s`
                : servicePlan.serviceInterval}
            </p>
          ) : (
            <EditableElementControl
              className="service-interval"
              addText={'Service interval'}
              value={serviceInterval}
              onSave={() => {
                dispatch(
                  servicePlansActions.updateServicePlan.request({
                    servicePlanPublicId: servicePlan.publicId,
                    updateServicePlanRequestData: {
                      serviceInterval: serviceInterval,
                    },
                    callback: () => {
                      getServicePlan();
                    },
                    onErrorCallback: () =>
                      setServiceInterval(servicePlan.serviceInterval),
                  })
                );
              }}
              editTemplate={
                <span className="flex-container flex-center">
                  <span>
                    This Service Plan will be carried out every
                    {servicePlan.serviceIntervalCount === 1
                      ? ''
                      : ` ${servicePlan.serviceIntervalCount}`}
                  </span>
                  <DropdownField
                    name="serviceInterval"
                    value={serviceInterval}
                    options={serviceIntervalOptions}
                    onChange={(value) => {
                      setHasChangesMade(
                        value.serviceInterval !== serviceInterval
                      );
                      setServiceInterval(value.serviceInterval);
                    }}
                  />
                </span>
              }
              renderValue={(value) => (
                <p>
                  This Service Plan will be carried out every{' '}
                  {servicePlan.serviceIntervalCount > 1
                    ? `${servicePlan.serviceIntervalCount} ${value}s`
                    : value}
                </p>
              )}
            />
          )}
        </div>

        <div className="prices">
          <h3>Prices</h3>
          <ServicePlanPricesControl
            prices={servicePlan.prices || []}
            subscriptions={subscriptions || []}
            onAddPrice={(price) => {
              return new Promise<void>((resolve, reject) => {
                if (price.basicPrice >= 0) {
                  dispatch(
                    createServicePlanPrice.request({
                      servicePlanPrice: {
                        ...price,
                        servicePlanPublicId: servicePlan.publicId,
                      },
                      callback: (a) => {
                        return getServicePlan();
                      },
                      onErrorCallback: () => reject(),
                    })
                  );
                } else {
                  reject();
                }
              });
            }}
            onChangePrice={(price) => {
              return new Promise<void>((resolve, reject) => {
                if (price.basicPrice >= 0) {
                  dispatch(
                    updateServicePlanPrice.request({
                      servicePlanPrice: price as UpdateServicePlanPriceInput,
                      callback: (a) => {
                        return getServicePlan();
                      },
                      onErrorCallback: () => {
                        reject();
                      },
                    })
                  );
                } else {
                  reject();
                }
              });
            }}
            onDeletePrice={(pricePublicId) => {
              return new Promise<void>((resolve, reject) => {
                dispatch(
                  deleteServicePlanPrice.request({
                    servicePlanPricePublicId: pricePublicId,
                    callback: () => {
                      return getServicePlan();
                    },
                    onErrorCallback: () => {
                      reject();
                    },
                  })
                );
              });
            }}
          />
        </div>
        {account.region === AccountRegions.UK && (
          <div className="payment-method-types">
            <h3>Payment methods</h3>
            <p>
              <small>
                {`Please ensure that the payment methods you have selected here are activated in
              your Stripe dashboard.`}
                <br />
                {`If you select 'Direct debit' for example, but do not
              have that method active in Stripe, you will not be able to sell
              your service plan.`}
              </small>
            </p>
            <CheckboxField
              name="card"
              label="Card"
              value={servicePlan.paymentMethodTypes?.includes('card')}
              onChange={(value) => {
                const paymentMethodTypes: Array<'card' | 'bacs_debit'> =
                  value.card
                    ? [...servicePlan.paymentMethodTypes, 'card']
                    : servicePlan.paymentMethodTypes.filter(
                        (type) => type !== 'card'
                      );

                dispatch(
                  servicePlansActions.updateServicePlan.request({
                    servicePlanPublicId: servicePlan.publicId,
                    updateServicePlanRequestData: {
                      paymentMethodTypes: paymentMethodTypes,
                    },
                    callback: () => {
                      getServicePlan();
                    },
                    onErrorCallback: () => null,
                  })
                );
              }}
            />
            <CheckboxField
              name="bacsdebit"
              label="Direct debit"
              value={servicePlan.paymentMethodTypes?.includes('bacs_debit')}
              onChange={(value) => {
                const paymentMethodTypes: Array<'card' | 'bacs_debit'> =
                  value.bacsdebit
                    ? [...servicePlan.paymentMethodTypes, 'bacs_debit']
                    : servicePlan.paymentMethodTypes.filter(
                        (type) => type !== 'bacs_debit'
                      );

                dispatch(
                  servicePlansActions.updateServicePlan.request({
                    servicePlanPublicId: servicePlan.publicId,
                    updateServicePlanRequestData: {
                      paymentMethodTypes: paymentMethodTypes,
                    },
                    callback: () => {
                      getServicePlan();
                    },
                    onErrorCallback: () => null,
                  })
                );
              }}
            />
          </div>
        )}
        <div className="commitments">
          <h3>Commitments</h3>
          <ServicePlanCommitmentsControl
            commitments={servicePlan.commitments.sort(
              sortByDateKey('createdAt', true)
            )}
            onAddCommitment={(commitment) => {
              return new Promise<void>((resolve, reject) => {
                dispatch(
                  createServicePlanCommitment.request({
                    servicePlanPublicId: servicePlan.publicId,
                    servicePlanCommitment: {
                      ...commitment,
                    },
                    callback: (a) => {
                      return getServicePlan();
                    },
                    onErrorCallback: () => reject(),
                  })
                );
              });
            }}
            onRemoveCommitment={(index) => {
              return new Promise<void>((resolve) => {
                const commitmentToRemove = servicePlan.commitments[index];

                return new Promise<void>((resolve, reject) => {
                  dispatch(
                    deleteServicePlanCommitment.request({
                      servicePlanCommitmentId: commitmentToRemove.publicId,
                      callback: () => {
                        return getServicePlan();
                      },
                      onErrorCallback: () => reject(),
                    })
                  );
                });
              });
            }}
            onChangeCommitment={(index, commitment) => {
              return new Promise<void>((resolve, reject) => {
                dispatch(
                  updateServicePlanCommitment.request({
                    publicId: commitment.publicId,
                    servicePlanCommitment: {
                      name: commitment.name,
                      description: commitment.description,
                      scalesWithQuantity: commitment.scalesWithQuantity,
                      entityTemplate: commitment.entityTemplate,
                    } as UpdateServicePlanCommitmentInput,
                    callback: (a) => {
                      return getServicePlan();
                    },
                    onErrorCallback: () => reject(),
                  })
                );
              });
            }}
          />
        </div>
      </div>
      {/* warning message if name, description or service interval have been added */}
      <Prompt
        when={hasChangesMade}
        message="There are unsaved changes on the page, are you sure you want to leave?"
      />
    </div>
  );
};

export default ManageServicePlanControl;
