import { Reducer } from 'redux';

import { ActionType, State } from './dealsTypes';

export const initialState: State = {
  deals: {},

  isSettingDealCustomer: false,
  isDealCustomerSetSuccessfully: undefined,

  isArchivingDeals: false,
  areDealsArchivedSuccessfully: false,
  isRestoringDeals: false,
  areDealsRestoredSuccessfully: false,

  isGettingListedDealsPage: false,
  listedDealsPage: null,

  isAddingDealAddress: false,
  isDealAddressAddedSuccessfully: undefined,

  isRemovingDealAddress: false,
  isDealAddressRemovedSuccessfully: undefined,

  isGettingListedPipelineDeals: false,
};

const dealsReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_DEAL:
    case ActionType.REQUEST_UPDATE_DEAL: {
      const fetchedEntity = state.deals && state.deals[action.payload.dealId];

      return {
        ...state,
        deals: {
          ...state.deals,
          [action.payload.dealId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_DEAL_SUCCESS:
    case ActionType.UPDATE_DEAL_SUCCESS: {
      const fetchedEntity = state.deals && state.deals[action.payload.dealId];

      return {
        ...state,
        deals: {
          ...state.deals,
          [action.payload.dealId]: {
            ...fetchedEntity,
            entity: action.payload.deal,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.GET_DEAL_FAILURE:
    case ActionType.UPDATE_DEAL_FAILURE: {
      const fetchedEntity = state.deals && state.deals[action.payload.dealId];

      return {
        ...state,
        deals: {
          ...state.deals,
          [action.payload.dealId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.CLEAR_DEAL: {
      const deals = state.deals || {};
      delete deals[action.payload.dealId];

      return {
        ...state,
        deals: deals,
      };
    }
    case ActionType.CLEAR_DEALS: {
      return {
        ...initialState,
      };
    }

    case ActionType.REQUEST_SET_DEAL_CUSTOMER:
    case ActionType.REQUEST_UNSET_DEAL_CUSTOMER:
      return {
        ...state,
        isSettingDealCustomer: true,
        isDealCustomerSetSuccessfully: undefined,
      };
    case ActionType.SET_DEAL_CUSTOMER_SUCCESS:
    case ActionType.UNSET_DEAL_CUSTOMER_SUCCESS:
      return {
        ...state,
        isSettingDealCustomer: false,
        isDealCustomerSetSuccessfully: true,
      };
    case ActionType.SET_DEAL_CUSTOMER_FAILURE:
    case ActionType.UNSET_DEAL_CUSTOMER_FAILURE:
      return {
        ...state,
        isSettingDealCustomer: false,
        isDealCustomerSetSuccessfully: false,
      };
    case ActionType.REQUEST_GET_LISTED_DEALS_PAGE:
      return {
        ...state,
        isGettingListedDealsPage: true,
      };
    case ActionType.GET_LISTED_DEALS_PAGE_FAILURE:
      return {
        ...state,
        listedDealsPage: null,
        isGettingListedDealsPage: false,
      };
    case ActionType.GET_LISTED_DEALS_PAGE_SUCCESS:
      return {
        ...state,
        listedDealsPage: action.payload.listedDealsPage,
        isGettingListedDealsPage: false,
      };
    case ActionType.CLEAR_LISTED_DEALS_PAGE:
      return {
        ...state,
        listedDealsPage: null,
      };
    case ActionType.REQUEST_ARCHIVE_DEALS:
      return {
        ...state,
        isArchivingDeals: true,
        areDealsArchivedSuccessfully: undefined,
      };
    case ActionType.ARCHIVE_DEALS_SUCCESS:
      return {
        ...state,
        isArchivingDeals: false,
        areDealsArchivedSuccessfully: true,
      };
    case ActionType.ARCHIVE_DEALS_FAILURE:
      return {
        ...state,
        isArchivingDeals: false,
        areDealsArchivedSuccessfully: false,
      };
    case ActionType.REQUEST_RESTORE_DEALS:
      return {
        ...state,
        isRestoringDeals: true,
        areDealsRestoredSuccessfully: undefined,
      };
    case ActionType.RESTORE_DEALS_SUCCESS:
      return {
        ...state,
        isRestoringDeals: false,
        areDealsRestoredSuccessfully: true,
      };
    case ActionType.RESTORE_DEALS_FAILURE:
      return {
        ...state,
        isRestoringDeals: false,
        areDealsRestoredSuccessfully: false,
      };

    case ActionType.REQUEST_ADD_DEAL_ADDRESS:
      return {
        ...state,
        isAddingDealAddress: true,
        isDealAddressAddedSuccessfully: undefined,
      };
    case ActionType.ADD_DEAL_ADDRESS_SUCCESS:
      return {
        ...state,
        isAddingDealAddress: false,
        isDealAddressAddedSuccessfully: true,
      };
    case ActionType.ADD_DEAL_ADDRESS_FAILURE:
      return {
        ...state,
        isAddingDealAddress: false,
        isDealAddressAddedSuccessfully: false,
      };

    case ActionType.REQUEST_REMOVE_DEAL_ADDRESS:
      return {
        ...state,
        isRemovingDealAddress: true,
        isDealAddressRemovedSuccessfully: undefined,
      };
    case ActionType.REMOVE_DEAL_ADDRESS_SUCCESS:
      return {
        ...state,
        isRemovingDealAddress: false,
        isDealAddressRemovedSuccessfully: true,
      };
    case ActionType.REMOVE_DEAL_ADDRESS_FAILURE:
      return {
        ...state,
        isRemovingDealAddress: false,
        isDealAddressRemovedSuccessfully: false,
      };

    case ActionType.GET_LISTED_PIPELINE_DEALS_REQUEST:
      return {
        ...state,
        isGettingListedPipelineDeals: true,
      };
    case ActionType.GET_LISTED_PIPELINE_DEALS_SUCCESS:
    case ActionType.GET_LISTED_PIPELINE_DEALS_FAILURE:
      return {
        ...state,
        isGettingListedPipelineDeals: false,
      };

    default:
      return state;
  }
};

export default dealsReducer;
