import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';
import { getMaterial, getRegion } from '@/utils/stateAccessors';

import * as listedSupplierMaterialsActions from '@payaca/store/listedSupplierMaterials/listedSupplierMaterialsActions';
import { GetListedSupplierMaterialsRequestData } from '@payaca/store/listedSupplierMaterials/listedSupplierMaterialsTypes';

import { SupplierMaterialControlContext } from '../supplierMaterialControl/SupplierMaterialControl';
import CreateEditSupplierMaterialModal from '../createEditSupplierMaterialModal/CreateEditSupplierMaterialModal';
import ListedSupplierMaterials from '../listedSupplierMaterials/ListedSupplierMaterials';
import Button from '@payaca/components/button/Button';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';

import { SupplierMaterial } from '@payaca/types/materialTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import { SortBy } from '@payaca/types/listedSupplierMaterialTypes';

import './MaterialSupplierMaterials.sass';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import { useSelector } from '@/api/state';
import { useDefaultTaxRate } from '@payaca/store/hooks/appState';

type Props = {
  materialId: number;
  onMaterialUpdateSuccess: () => void;
};
const MaterialSupplierMaterials: FC<Props> = ({
  materialId,
  onMaterialUpdateSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const [
    getListedSupplierMaterialsRequestData,
    setGetListedSupplierMaterialsRequestData,
  ] = useState<GetListedSupplierMaterialsRequestData>({
    pageSize: 20,
    pageNumber: 1,
    materialId: materialId,
    sortDirection: SortDirection.ASCENDING,
    sortBy: SortBy.COST,
  });

  useEffect(() => {
    return () => {
      dispatch(
        listedSupplierMaterialsActions.clearListedSupplierMaterialsPage()
      );
    };
  }, []);

  useEffect(() => {
    requestGetListedSupplierMaterialsPage();
  }, [getListedSupplierMaterialsRequestData]);

  const requestGetListedSupplierMaterialsPage = useCallback(() => {
    dispatch(
      listedSupplierMaterialsActions.requestGetListedSupplierMaterialsPage(
        getListedSupplierMaterialsRequestData
      )
    );
  }, [getListedSupplierMaterialsRequestData, dispatch]);

  const [showCreateSupplierMaterialModal, setShowCreateSupplierMaterialModal] =
    useState(false);

  const material = useSelector((state) => getMaterial(state, materialId));

  const region = useSelector(getRegion);

  const isGettingListedSupplierMaterialsPage = useSelector(
    (state) =>
      state.listedSupplierMaterials.isGettingListedSupplierMaterialsPage
  );

  const defaultTaxRate = useDefaultTaxRate();

  if (!material) return null;

  return (
    <>
      <div className="material-supplier-materials">
        <PermissionGuard
          renderIfHasPermissions={[
            MaterialsPermissions.PERSIST_SUPPLIER_MATERIAL,
          ]}
        >
          <div className="button-loader-container">
            <Button onClick={() => setShowCreateSupplierMaterialModal(true)}>
              Add Supplier
            </Button>
            {isGettingListedSupplierMaterialsPage && <MiniLoader />}
          </div>
        </PermissionGuard>
        <ListedSupplierMaterials
          onSelectPage={(pageNumber: number) => {
            setGetListedSupplierMaterialsRequestData(
              (getListedSupplierMaterialsRequestData) => ({
                ...getListedSupplierMaterialsRequestData,
                pageNumber: pageNumber,
              })
            );
          }}
          context={SupplierMaterialControlContext.MATERIAL}
          onDeleteSupplierMaterialSuccess={() => {
            requestGetListedSupplierMaterialsPage();
            onMaterialUpdateSuccess && onMaterialUpdateSuccess();
          }}
          onPersistSupplierMaterialSuccess={
            requestGetListedSupplierMaterialsPage
          }
        />
      </div>
      <CreateEditSupplierMaterialModal
        isOpen={showCreateSupplierMaterialModal}
        onClose={() => setShowCreateSupplierMaterialModal(false)}
        supplierMaterial={
          {
            materialId: materialId,
            isTaxIncluded: defaultTaxRate?.isIncluded ?? false,
            taxPercentage: defaultTaxRate?.percentage ?? 0,
            taxRateId: defaultTaxRate?.id ?? null,
          } as unknown as SupplierMaterial
        }
        isMaterialSelectionDisabled={true}
        onPersistSupplierMaterialSuccess={() => {
          setShowCreateSupplierMaterialModal(false);
          onMaterialUpdateSuccess();
          requestGetListedSupplierMaterialsPage();
        }}
      />
    </>
  );
};

export default MaterialSupplierMaterials;
