import { Reducer } from 'redux';

import { ActionType, State } from './listedSupplierMaterialsTypes';

export const initialState: State = {
  isGettingListedSupplierMaterialsPage: false,
  listedSupplierMaterialsPage: null,
};

const listedSupplierMaterialsReducer: Reducer<State> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_LISTED_SUPPLIERMATERIALS_PAGE:
      return { ...state, isGettingListedSupplierMaterialsPage: true };
    case ActionType.GET_LISTED_SUPPLIERMATERIALS_PAGE_FAILURE:
      return {
        ...state,
        listedSupplierMaterialsPage: null,
        isGettingListedSupplierMaterialsPage: false,
      };
    case ActionType.GET_LISTED_SUPPLIERMATERIALS_PAGE_SUCCESS:
      return {
        ...state,
        listedSupplierMaterialsPage: action.payload.listedSupplierMaterialsPage,
        isGettingListedSupplierMaterialsPage: false,
      };
    case ActionType.CLEAR_LISTED_SUPPLIERMATERIALS_PAGE:
      return {
        ...state,
        listedSupplierMaterialsPage: null,
        isGettingListedSupplierMaterialsPage: false,
      };
    default:
      return state;
  }
};

export default listedSupplierMaterialsReducer;
