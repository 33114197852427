import React, { FunctionComponent } from 'react';
import './SelectCustomerModal.sass';

import Modal from '@payaca/components/modal/Modal';
import SelectCustomerControl from '../selectCustomerControl/SelectCustomerControl';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSelectCustomer: (customerId: number) => void;
  actions?: React.ReactNode;
};
const SelectCustomerModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  onSelectCustomer,
  actions,
}: Props): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      title={
        <div className="title-bar-inner">
          <h3>Select a Customer</h3>
          <div className="title-actions-container">{actions}</div>
        </div>
      }
      onClose={onClose}
      className={'select-customer-modal'}
      size="md"
    >
      <SelectCustomerControl onSelectCustomer={onSelectCustomer} />
    </Modal>
  );
};

export default SelectCustomerModal;
