import React, { FC, useMemo } from 'react';

import Modal from '@payaca/components/modal/Modal';
import { Supplier } from '@payaca/types/supplierTypes';
import CreateEditSupplierControl from '../createEditSupplierControl/CreateEditSupplierControl';
import './CreateEditSupplierModal.sass';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onPersistSupplierSuccess?: (supplierId: number) => void;
  supplier?: Supplier;
};
const CreateEditSupplierModal: FC<Props> = ({
  isOpen,
  onClose,
  onPersistSupplierSuccess,
  supplier,
}: Props): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      className="create-edit-supplier-modal"
      title={supplier?.id ? 'Edit supplier' : 'Create supplier'}
      onClose={onClose}
      disableBackdropClick={true}
    >
      <CreateEditSupplierControl
        supplier={supplier}
        onPersistSupplierSuccess={(supplierId: number) => {
          onClose();
          onPersistSupplierSuccess && onPersistSupplierSuccess(supplierId);
        }}
      />
    </Modal>
  );
};
export default CreateEditSupplierModal;
