import { LineItemsPermissions } from './line-items.permissions';
import { LineItemsRoles } from './line-items.roles';

export const lineItemsMappings = {
  [LineItemsPermissions.ADD_LINE_ITEM]: [LineItemsRoles.CREATOR],
  [LineItemsPermissions.GET_COLLECTION_MANAGEMENT_VIEW]: [
    LineItemsRoles.COLLECTION_MANAGER,
  ],
  [LineItemsPermissions.UPDATE_LINE_ITEM]: [
    LineItemsRoles.BASIC_EDITOR,
    LineItemsRoles.EDITOR,
  ],
  [LineItemsPermissions.GET_LINE_ITEMS]: [LineItemsRoles.VIEWER],
  [LineItemsPermissions.UPDATE_LINE_ITEM_MARKUP_PERCENTAGE]: [
    LineItemsRoles.EDITOR,
  ],
  [LineItemsPermissions.UPDATE_LINE_ITEM_MARKUP_AMOUNT]: [
    LineItemsRoles.EDITOR,
  ],
  [LineItemsPermissions.DELETE_LINE_ITEM]: [LineItemsRoles.DELETER],
  [LineItemsPermissions.ADD_LINE_ITEM_GROUP]: [LineItemsRoles.GROUP_CREATOR],
  [LineItemsPermissions.UPDATE_LINE_ITEM_GROUP]: [LineItemsRoles.GROUP_EDITOR],
  [LineItemsPermissions.GET_LINE_ITEM_GROUPS]: [LineItemsRoles.GROUP_EDITOR],

  [LineItemsPermissions.BULK_IMPORT]: [LineItemsRoles.BULK_IMPORTER],
};
