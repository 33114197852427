import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BasicField from '@payaca/components/basicField/BasicField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import React, {
  DetailedHTMLProps,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

type Props = {
  fieldNamePrefix?: string;
  checklistItem: { id?: number; name: string; completedAt?: Date };
  touchedState?: { [fieldName: string]: boolean };
  validationState?: { [fieldName: string]: FieldValidationResult };
  onChange: (value: { [key: string]: any }) => void;
  onTouch?: (fieldName: string) => void;
  autofocus?: boolean;
};
const ChecklistItemControl: FunctionComponent<Props> = ({
  fieldNamePrefix,
  checklistItem,
  touchedState,
  validationState,
  onChange,
  onTouch,
  autofocus = false,
}: Props): JSX.Element => {
  const nameFieldName = useMemo(() => {
    return fieldNamePrefix ? `${fieldNamePrefix}.name` : 'name';
  }, [fieldNamePrefix]);

  return (
    <div className="checklist-item-control flex-container">
      {/* <div className="checkmark-container">
        <FontAwesomeIcon
          icon={!!checklistItem.completedAt ? faCheckSquare : faSquare}
        />
      </div> */}
      <div className="flex-grow">
        <ValidatedFieldWrapper
          isTouched={(touchedState && touchedState[nameFieldName]) || false}
          validationResult={validationState && validationState[nameFieldName]}
        >
          <BasicField
            value={checklistItem.name || ''}
            name={nameFieldName}
            onChange={onChange}
            onTouch={onTouch}
            additionalInputProps={{
              autoFocus: autofocus,
            }}
          />
        </ValidatedFieldWrapper>
      </div>
    </div>
  );
};

export default ChecklistItemControl;
