import { Country } from '@payaca/types/country-types';
import { Loadable } from '@payaca/utilities/monads/types';

import { DeclareAsyncAction, UnpackAsyncAction } from '../types';

export type State = {
  countries: Loadable<Array<Country>>;
};

export enum ActionType {
  GET_COUNTRIES_REQUEST = 'countries.getCountries:request',
  GET_COUNTRIES_SUCCESS = 'countries.getCountries:success',
  GET_COUNTRIES_FAILURE = 'countries.getCountries:failure',
}

export type GetCountriesAction = DeclareAsyncAction<
  ActionType,
  {
    request: { type: ActionType.GET_COUNTRIES_REQUEST };
    success: {
      type: ActionType.GET_COUNTRIES_SUCCESS;
      payload: Array<Country>;
    };
    failure: { type: ActionType.GET_COUNTRIES_FAILURE };
  }
>;

export type AnyAction = UnpackAsyncAction<GetCountriesAction>;
