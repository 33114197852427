export enum InvoiceActionType {
  SEND = 'send',
  EDIT = 'edit',
  MARK_AS_SENT = 'mark-as-sent',
  ASSIGN = 'assign',
  UNASSIGN = 'unassign',

  RESEND = 'resend',
  VOID = 'void',
  CONFIRM_PENDING_PAYMENT = 'confirm-pending-payment',
  RECORD_PAYMENT = 'record-payment',
  PRINT = 'print',
}
