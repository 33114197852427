import { FC } from 'react';
import { get as _get } from 'lodash-es';
import { useParams } from 'react-router';
import useGetConnectionApplication from '@/api/queries/connectionApplication/useGetConnectionApplication';
import Field from '@payaca/components/plField/Field';
import Address from '@payaca/components/address/Address';
import DeviceSearchFieldReadonly from '@/ui/pages/connectionApplicationPage/components/DeviceSearchFieldReadonly';
import { getFieldLabelFromFieldSchema } from '@/ui/pages/connectionApplicationPage/utils';
import { format } from 'date-fns';
import DiscriminatorFieldReadOnly from './components/DiscriminatorFieldReadOnly';
import ProjectFileSelectionReadonly from './components/ProjectFileSelectionReadonly';
import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import {
  DateFormats,
  getInternationalDateFormatByLocale,
} from '@payaca/helpers/internationalHelper';

export type Props = {
  name: string;
  fieldSchema: any;
  namespace?: string;
  definitions: Record<string, any>;
};

export const DynamicViewField: FC<Props> = ({
  name,
  namespace,
  fieldSchema,
  definitions,
}) => {
  /**
   * Router
   */
  const { connectionApplicationId } = useParams<{
    projectId: string;
    connectionApplicationId: string;
  }>();

  /**
   * Queries
   */
  const { data: persistedFormStateData } = useGetConnectionApplication(
    connectionApplicationId,
    false
  );
  const { data: accountRegionPreferences } = useGetMyRegionPreferences();

  const persistedFormState = persistedFormStateData?.connectionApplication.data;

  const helperText = fieldSchema.json_schema_extra.tooltip;
  const fieldLabel = getFieldLabelFromFieldSchema(fieldSchema);

  switch (fieldSchema.json_schema_extra.type) {
    case 'USER ACCOUNT DERIVED INPUT':
    case 'TEXT INPUT':
    case 'SELECT INPUT':
    case 'MPAN RECOGNISED INPUT': {
      return (
        <Field>
          <Field.Label>{fieldLabel}</Field.Label>

          <p>{_get(persistedFormState, name)}</p>

          <Field.Helper>{helperText}</Field.Helper>
        </Field>
      );
    }
    case 'RADIO INPUT':
      return (
        <Field>
          <Field.Label>{fieldLabel}</Field.Label>

          <p>
            {
              Object.entries<any>(fieldSchema.json_schema_extra.options).find(
                ([key, value]) => {
                  return value === _get(persistedFormState, name);
                }
              )?.[0]
            }
          </p>

          <Field.Helper>{helperText}</Field.Helper>
        </Field>
      );
    case 'DATE INPUT': {
      const value = _get(persistedFormState, name);

      return (
        <Field>
          <Field.Label>{fieldLabel}</Field.Label>

          <p>
            {value
              ? format(
                  value,
                  getInternationalDateFormatByLocale(
                    DateFormats.SHORT,
                    accountRegionPreferences?.locale
                  )
                )
              : 'Not set'}
          </p>

          <Field.Helper>{helperText}</Field.Helper>
        </Field>
      );
    }
    case 'ADDRESS RECOGNISED INPUT': {
      return (
        <Field>
          <Field.Label>{fieldLabel}</Field.Label>

          <p>
            <Address address={_get(persistedFormState, name)} />
          </p>

          <Field.Helper>{helperText}</Field.Helper>
        </Field>
      );
    }
    case 'SEARCH DEVICE RECOGNISED INPUT': {
      const deviceType = _get(persistedFormState, `${namespace}.deviceType`);
      return (
        <DeviceSearchFieldReadonly
          fieldSchema={fieldSchema}
          name={name}
          deviceType={deviceType}
        />
      );
    }
    case 'G100_SEARCH_RECOGNISED_INPUT_01': {
      return (
        <DeviceSearchFieldReadonly
          fieldSchema={fieldSchema}
          name={name}
          deviceType="G100_DEVICE"
        />
      );
    }
    case 'NESTED INPUT FORM DISCRIMINATED UNION 01': {
      // this should show a select field which on selection would conditionally show further schema
      return (
        <DiscriminatorFieldReadOnly
          fieldSchema={fieldSchema}
          name={name}
          namespace={namespace}
          definitions={definitions}
        />
      );
    }
    case 'SINGLE ATTACHMENT INPUT 01': {
      return (
        <Field>
          <Field.Label>{fieldLabel}</Field.Label>
          <ProjectFileSelectionReadonly name={name} formSection={fieldSchema} />
          <Field.Helper>{helperText}</Field.Helper>
        </Field>
      );
    }

    default:
      return null;
  }
};
