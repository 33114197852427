import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import ProgressiveDisclosure from '@payaca/components/progressiveDisclosure/ProgressiveDisclosure';

import './PurchaseOrderMaterialPurchaseIntentsControl.sass';
import {
  useMaterialsListMaterialsForMaterialsList,
  useMaterialsForMaterialsList,
  useMaterialPurchaseIntentsForPurchaseOrder,
} from '@payaca/store/hooks/appState';
import { PersistPurchaseOrderMaterialPurchaseIntentRequestData } from '@payaca/store/materialsList/materialsListTypes';
import MaterialPurchaseIntentSelectionControl from './MaterialPurchaseIntentSelectionControl';
import { MaterialsListMaterial } from '@payaca/types/materialsListTypes';

type Props = {
  purchaseOrderId?: number;
  supplierId: number;
  materialsListId: number;
  materialPurchaseIntents: PersistPurchaseOrderMaterialPurchaseIntentRequestData[];
  onChange: (
    materialPurchaseIntents: PersistPurchaseOrderMaterialPurchaseIntentRequestData[]
  ) => void;
};
const PurchaseOrderMaterialPurchaseIntentsControl: FC<Props> = ({
  purchaseOrderId,
  supplierId,
  materialsListId,
  materialPurchaseIntents,
  onChange,
}: PropsWithChildren<Props>): JSX.Element => {
  const [defaultToQuantityEditMode, setDefaultToQuantityEditMode] =
    useState(false);
  const [showUnavailableAtSupplier, setShowUnavailableAtSupplier] =
    useState(false);
  const materialsListMaterials =
    useMaterialsListMaterialsForMaterialsList(materialsListId);

  const existingMaterialPurchaseIntents =
    useMaterialPurchaseIntentsForPurchaseOrder(purchaseOrderId);

  const materials = useMaterialsForMaterialsList(materialsListId);

  const purchasableMaterialsListMaterials = useMemo(() => {
    return materialsListMaterials.filter(
      (x) =>
        !(x.isFullyPurchaseIntended || x.isFullyPurchased) ||
        !!existingMaterialPurchaseIntents.find(
          (y) => y.materialsListMaterialId === x.id
        )
    );
  }, [materialsListMaterials, existingMaterialPurchaseIntents]);

  const { availableAtSupplier, unavailableAtSupplier } = useMemo(() => {
    const availableAtSupplier: MaterialsListMaterial[] = [];
    const unavailableAtSupplier: MaterialsListMaterial[] = [];

    purchasableMaterialsListMaterials.forEach((x) => {
      const material = materials.find((y) => y.id === x.materialId);
      let isAvailable = !!material?.supplierIds.includes(supplierId);

      if (!isAvailable) {
        isAvailable = !!existingMaterialPurchaseIntents.find(
          (y) => y.materialsListMaterialId === x.id
        );
      }

      if (isAvailable) {
        availableAtSupplier.push(x);
      } else unavailableAtSupplier.push(x);
    });

    return { availableAtSupplier, unavailableAtSupplier };
  }, [
    supplierId,
    materials,
    purchasableMaterialsListMaterials,
    existingMaterialPurchaseIntents,
  ]);

  const onUnselect = useCallback(
    (materialsListMaterialId: number) => {
      onChange(
        materialPurchaseIntents.filter(
          (x) => x.materialsListMaterialId !== materialsListMaterialId
        )
      );
    },
    [onChange, materialPurchaseIntents]
  );

  const onSelect = useCallback(
    (
      materialsListMaterialId: number,
      materialQuantity: number,
      materialPurchaseIntentId?: number
    ) => {
      const filteredMpi = materialPurchaseIntents.filter((x) => {
        return (
          x.materialsListMaterialId != materialsListMaterialId &&
          (materialPurchaseIntentId ? x.id != materialPurchaseIntentId : true)
        );
      });

      onChange([
        ...filteredMpi,
        {
          materialsListMaterialId,
          materialQuantity,
          id: materialPurchaseIntentId,
        },
      ]);
    },
    [onChange, materialPurchaseIntents]
  );

  const renderChecklistItem = useCallback(
    (materialsListMaterial: MaterialsListMaterial) => {
      const materialPurchaseIntent = materialPurchaseIntents.find(
        (x) => materialsListMaterial.id === x.materialsListMaterialId
      );

      const existingMaterialPurchaseIntent =
        existingMaterialPurchaseIntents.find(
          (x) => materialsListMaterial.id === x.materialsListMaterialId
        );

      const quantitySelected = materialPurchaseIntent?.materialQuantity;

      return (
        <MaterialPurchaseIntentSelectionControl
          materialPurchaseIntentId={existingMaterialPurchaseIntent?.id}
          supplierId={supplierId}
          quantitySelected={quantitySelected}
          materialsListMaterialId={materialsListMaterial.id}
          onSelect={(materialQuantity) =>
            onSelect(
              materialsListMaterial.id,
              materialQuantity,
              materialPurchaseIntent?.id
            )
          }
          onUnselect={() => onUnselect(materialsListMaterial.id)}
          defaultToQuantityEditMode={defaultToQuantityEditMode}
        />
      );
    },
    [
      materialPurchaseIntents,
      existingMaterialPurchaseIntents,
      supplierId,
      onSelect,
      onUnselect,
      defaultToQuantityEditMode,
    ]
  );

  const isAnyUnavailableMaterialsSelected = useMemo(() => {
    return !!materialPurchaseIntents.find((x) =>
      unavailableAtSupplier.find((y) => y.id === x.materialsListMaterialId)
    );
  }, [materialPurchaseIntents, unavailableAtSupplier]);

  useEffect(() => {
    if (isAnyUnavailableMaterialsSelected) {
      setShowUnavailableAtSupplier(true);
    }
  }, [isAnyUnavailableMaterialsSelected]);

  return (
    <div className="purchase-order-material-purchase-intents-control">
      {!!availableAtSupplier?.length && (
        <ul className="material-purchase-intent-selection-list">
          {availableAtSupplier.map((materialsListMaterial, i) => {
            return (
              <li key={`material-purchase-intent-selection-control-${i}`}>
                {renderChecklistItem(materialsListMaterial)}
              </li>
            );
          })}
        </ul>
      )}
      {!availableAtSupplier?.length && !!unavailableAtSupplier?.length && (
        <ul className="material-purchase-intent-selection-list">
          {unavailableAtSupplier.map((materialsListMaterial, i) => {
            return (
              <li key={`material-purchase-intent-selection-control-${i}`}>
                {renderChecklistItem(materialsListMaterial)}
              </li>
            );
          })}
        </ul>
      )}
      {!!availableAtSupplier?.length && !!unavailableAtSupplier?.length && (
        <ProgressiveDisclosure
          title="Show materials not linked to this supplier"
          showCloseButton={false}
          isRevealed={showUnavailableAtSupplier}
          setIsRevealed={() => setShowUnavailableAtSupplier(true)}
        >
          <ul className="material-purchase-intent-selection-list">
            {unavailableAtSupplier.map((materialsListMaterial, i) => {
              return (
                <li key={`material-purchase-intent-selection-control-${i}`}>
                  {renderChecklistItem(materialsListMaterial)}
                </li>
              );
            })}
          </ul>
        </ProgressiveDisclosure>
      )}
      {!defaultToQuantityEditMode && (
        <Button
          styleVariant={ButtonStyleVariant.ANCHOR}
          onClick={() => setDefaultToQuantityEditMode(true)}
        >
          Edit quantities
        </Button>
      )}
    </div>
  );
};

export default PurchaseOrderMaterialPurchaseIntentsControl;
