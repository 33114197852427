import {
  requestGetChangeProposal,
  requestGetChangeProposalsForDeal,
} from '@payaca/store/proposals/proposalsActions';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { useChangeProposal } from '@payaca/store/hooks/appState';
import * as proposalsActions from '@payaca/store/proposals/proposalsActions';

import './EditChangeProposalPage.sass';
import EditChangeProposalPreviewButton from '@/ui/components/editChangeProposalPreviewButton/EditChangeProposalPreviewButton';
import Modal from '@payaca/components/modal/Modal';
import PreviewAndSendChangeProposalControl from '@/ui/components/previewAndSendChangeProposalControl/PreviewAndSendChangeProposalControl';
import EditChangeProposalControl from '@/ui/components/editChangeProposalControl/EditChangeProposalControl';
import ChangeProposalSidePanel from '@/ui/components/changeProposalSidePanel/ChangeProposalSidePanel';
import ChangeProposalValueSummary from '@/ui/components/changeProposalValueSummary/ChangeProposalValueSummary';
import { getJobLineItemsByDealId } from '@/utils/stateAccessors';
import {
  requestGetJobContentsForDeal,
  requestGetJobLineItemsForDeal,
} from '@payaca/store/jobContent/jobContentActions';
import { requestGetJobsForDeal } from '@payaca/store/jobs/jobsActions';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';
import EditChangeProposalSaveFeedback from '@/ui/components/editChangeProposalSaveFeedback/EditChangeProposalSaveFeedback';
import { requestGetUploadsForEntity } from '@payaca/store/uploads/uploadsActions';

export enum ViewType {
  EDIT = 'edit',
  SEND = 'send',
}

type Props = {
  changeProposalId: number;
  dealId?: number;
  viewType?: ViewType;
};

const EditChangeProposalPage: FC<Props> = ({
  changeProposalId,
  dealId,
  viewType,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [view, setView] = useState<ViewType>(ViewType.EDIT);
  const [validationResult, setValidationResult] = useState<{
    isValid: boolean;
    errors: string[];
  }>();
  const changeProposal = useChangeProposal(changeProposalId);

  useEffect(() => {
    dispatch(requestGetChangeProposal(changeProposalId));
    dispatch(requestGetUploadsForEntity(changeProposalId, 'changeProposal'));
  }, []);

  useEffect(() => {
    if (changeProposal?.dealId) {
      dispatch(requestGetDeal(changeProposal.dealId));
      dispatch(requestGetJobsForDeal(changeProposal.dealId));
      dispatch(requestGetJobContentsForDeal(changeProposal.dealId));
      dispatch(requestGetChangeProposalsForDeal(changeProposal.dealId));
      dispatch(requestGetJobLineItemsForDeal(changeProposal?.dealId));
    }
  }, [changeProposal?.dealId]);

  useEffect(() => {
    if (
      viewType === ViewType.SEND &&
      validationResult &&
      validationResult.isValid
    ) {
      setView(ViewType.SEND);
    }
  }, [viewType, validationResult]);

  useEffect(() => {
    dispatch(
      proposalsActions.getChangeProposalValidationResult.request(
        changeProposalId,
        (validationResult) => {
          setValidationResult(validationResult);
        }
      )
    );
  }, [changeProposalId]);

  useEffect(() => {
    if (changeProposal?.sentAt) {
      history.push(`/deals/${dealId}/changeproposals/${changeProposalId}`);
    }
  }, [changeProposal?.sentAt]);

  return (
    <AuthenticatedPageWrapper
      className="edit-change-proposal-page"
      previousPageNavigationConfig={{
        route: `/deals/${dealId}/proposals`,
        navigationPromptName: 'Back',
      }}
      sidebarContent={
        <div className="edit-change-proposal-sidebar">
          <EditChangeProposalPreviewButton
            changeProposalId={changeProposalId}
            proceedToPreview={() => setView(ViewType.SEND)}
          />
          <EditChangeProposalSaveFeedback changeProposalId={changeProposalId} />
          <ChangeProposalSidePanel changeProposalId={changeProposalId} />
        </div>
      }
    >
      {changeProposal && (
        <>
          <EditChangeProposalControl changeProposalId={changeProposalId} />
          <Modal
            className="preview-and-send-change-proposal-modal"
            isOpen={view === ViewType.SEND}
            size="lg"
            onClose={() => setView(ViewType.EDIT)}
          >
            <PreviewAndSendChangeProposalControl
              changeProposalId={changeProposalId}
            />
          </Modal>
        </>
      )}
    </AuthenticatedPageWrapper>
  );
};

export default EditChangeProposalPage;
