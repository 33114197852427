import {
  takeEvery,
  call,
  put,
  race,
  delay,
  takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions';

import {
  ActionType,
  CreateScheduledEventRequestData,
  SagaConfig,
} from './scheduledEventsTypes';
import {
  GetListedScheduledEventsRequestData,
  ScheduledEvent,
} from '@payaca/types/scheduledEventsTypes';

import {
  archiveScheduledEventFailure,
  archiveScheduledEventSuccess,
  clearScheduledEvents,
  createScheduledEventFailure,
  createScheduledEventSuccess,
  getListedScheduledEventsFailure,
  getListedScheduledEventsSuccess,
  getScheduledEventFailure,
  getScheduledEventsForDealFailure,
  getScheduledEventsForDealSuccess,
  getScheduledEventsForTaskFailure,
  getScheduledEventsForTaskSuccess,
  getScheduledEventSuccess,
  sendScheduledEventConfirmation,
  updateScheduledEventFailure,
  updateScheduledEventSuccess,
} from './scheduledEventsActions';

import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { handleAsyncAction } from '../utils';

const dealsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleGetScheduledEvent(
    action: PayloadAction<
      ActionType.REQUEST_GET_SCHEDULED_EVENT,
      {
        scheduledEventId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getScheduledEvent, action.payload.scheduledEventId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getScheduledEventFailure(
            action.payload.scheduledEventId,
            new Error('Get scheduled event request timed out.')
          )
        );
      } else {
        yield put(
          getScheduledEventSuccess(action.payload.scheduledEventId, response)
        );
      }
    } catch (error: any) {
      yield put(
        getScheduledEventFailure(action.payload.scheduledEventId, error)
      );
    }
  }

  function* handleGetListedScheduledEvents(
    action: PayloadAction<
      ActionType.REQUEST_GET_LISTED_SCHEDULED_EVENTS,
      {
        getListedScheduledEventsRequestData: GetListedScheduledEventsRequestData;
        callback?: (scheduledEvents: ScheduledEvent[]) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getListedScheduledEvents,
          action.payload.getListedScheduledEventsRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getListedScheduledEventsFailure(
            new Error('Get listed scheduled events request timed out.')
          )
        );
      } else {
        yield put(getListedScheduledEventsSuccess(response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(getListedScheduledEventsFailure(error));
    }
  }
  const getListedScheduledEvents = async (
    getListedScheduledEventsRequestData: GetListedScheduledEventsRequestData
  ) => {
    const response = await req.get(
      `/scheduled_events?start=${
        getListedScheduledEventsRequestData.start || ''
      }&end=${getListedScheduledEventsRequestData.end || ''}&statuses=${
        getListedScheduledEventsRequestData.statuses
          ? getListedScheduledEventsRequestData.statuses.join(',')
          : ''
      }&pageSize=${
        getListedScheduledEventsRequestData.pageSize != undefined
          ? getListedScheduledEventsRequestData.pageSize
          : ''
      }&pageNumber=${
        getListedScheduledEventsRequestData.pageNumber != undefined
          ? getListedScheduledEventsRequestData.pageNumber
          : ''
      }&sortBy=${
        getListedScheduledEventsRequestData.sortBy || ''
      }&sortDirection=${
        getListedScheduledEventsRequestData.sortDirection || ''
      }&userAssignments=${
        getListedScheduledEventsRequestData.userAssignments
          ? getListedScheduledEventsRequestData.userAssignments.join(',')
          : ''
      }&customerId=${
        getListedScheduledEventsRequestData.customerId || ''
      }&dealId=${getListedScheduledEventsRequestData.dealId || ''}`
    );
    return await response.json();
  };

  const getScheduledEvent = async (scheduledEventId: number) => {
    const response = await req.get(`/scheduled_events/${scheduledEventId}`);
    return await response.json();
  };

  function* handleCreateScheduledEvent(
    action: PayloadAction<
      ActionType.REQUEST_CREATE_SCHEDULED_EVENT,
      {
        createScheduledEventRequestData: CreateScheduledEventRequestData;
        callback?: (scheduledEventId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          createScheduledEvent,
          action.payload.createScheduledEventRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Create scheduled event request timed out.';
        yield put(
          createScheduledEventFailure(new Error(errorMessage), errorMessage)
        );
      } else {
        yield put(createScheduledEventSuccess(response.scheduledEvent));
        action.payload.callback?.(response.scheduledEvent.id);
      }
    } catch (error: any) {
      yield put(createScheduledEventFailure(error, error.message));
    }
  }
  const createScheduledEvent = async (
    createScheduledEventRequestData: CreateScheduledEventRequestData
  ) => {
    const response = await req.post('/scheduled_events', {
      scheduledEvent: createScheduledEventRequestData,
    });
    return await response.json();
  };

  function* handleUpdateScheduledEvent(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_SCHEDULED_EVENT,
      {
        scheduledEvent: any;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(updateScheduledEvent, action.payload.scheduledEvent),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Update scheduled event request timed out.';
        yield put(
          updateScheduledEventFailure(new Error(errorMessage), errorMessage)
        );
      } else {
        yield put(updateScheduledEventSuccess(response.scheduledEvent));
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(updateScheduledEventFailure(error, error.message));
    }
  }
  const updateScheduledEvent = async (scheduledEvent: any) => {
    const response = await req.put(`/scheduled_events/${scheduledEvent.id}`, {
      scheduledEvent,
    });
    return await response.json();
  };

  function* handleArchiveScheduledEvent(
    action: PayloadAction<
      ActionType.REQUEST_ARCHIVE_SCHEDULED_EVENT,
      {
        scheduledEventId: any;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(archiveScheduledEvent, action.payload.scheduledEventId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Archive scheduled event request timed out.';
        yield put(
          archiveScheduledEventFailure(new Error(errorMessage), errorMessage)
        );
      } else {
        yield put(
          archiveScheduledEventSuccess(action.payload.scheduledEventId)
        );
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(archiveScheduledEventFailure(error, error.message));
    }
  }
  const archiveScheduledEvent = async (scheduledEventId: any) => {
    const response = await req.put(
      `/scheduled_events/${scheduledEventId}/archive`
    );
    return;
  };

  function* handleGetScheduledEventsForDeal(
    action: PayloadAction<
      ActionType.REQUEST_GET_SCHEDULED_EVENTS_FOR_DEAL,
      {
        dealId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getScheduledEventsForDeal, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (!timeout) {
        yield put(getListedScheduledEventsSuccess(response));
        yield put(getScheduledEventsForDealSuccess());
      } else {
        yield put(
          getScheduledEventsForDealFailure(
            new Error('Get scheduled events for Project timeout')
          )
        );
      }
    } catch (error: any) {
      yield put(getListedScheduledEventsFailure(error));
      yield put(getScheduledEventsForDealFailure(error));
    }
  }

  function* handleGetScheduledEventsForTask(
    action: PayloadAction<
      ActionType.REQUEST_GET_SCHEDULED_EVENTS_FOR_TASK,
      {
        taskId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getScheduledEventsForTask, action.payload.taskId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getScheduledEventsForTaskFailure(
            new Error('Get scheduled events for task timeout')
          )
        );
      } else {
        yield put(getScheduledEventsForTaskSuccess(response));
      }
    } catch (error: any) {
      yield put(getScheduledEventsForTaskFailure(error));
    }
  }

  const getScheduledEventsForTask = async (taskId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/scheduled_events/task/${taskId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get scheduled events for task failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getScheduledEventsForDeal = async (dealId: number) => {
    const response = await req.get(`/scheduled_events/deal/${dealId}`);
    return await response.json();
  };

  const handleSendScheduledEventConfirmation = handleAsyncAction(
    sendScheduledEventConfirmation,
    async (args) => {
      const { scheduledEventId, ...body } = args.data;

      const response = await req.post(
        `/scheduled_events/${scheduledEventId}/send_confirmation`,
        body
      );
      return;
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_error, requestData) => {
      requestData.payload.onErrorCallback?.(_error);
    }
  );

  function* handleAppLogout() {
    yield put(clearScheduledEvents());
  }

  return function* () {
    yield takeEvery(
      ActionType.REQUEST_GET_LISTED_SCHEDULED_EVENTS,
      handleGetListedScheduledEvents
    );
    yield takeLatest(
      ActionType.REQUEST_CREATE_SCHEDULED_EVENT,
      handleCreateScheduledEvent
    );
    yield takeLatest(
      ActionType.REQUEST_UPDATE_SCHEDULED_EVENT,
      handleUpdateScheduledEvent
    );
    yield takeLatest(
      ActionType.REQUEST_ARCHIVE_SCHEDULED_EVENT,
      handleArchiveScheduledEvent
    );
    yield takeLatest(
      ActionType.REQUEST_GET_SCHEDULED_EVENTS_FOR_DEAL,
      handleGetScheduledEventsForDeal
    );
    yield takeLatest(
      ActionType.REQUEST_GET_SCHEDULED_EVENTS_FOR_TASK,
      handleGetScheduledEventsForTask
    );

    yield takeEvery(
      ActionType.REQUEST_GET_SCHEDULED_EVENT,
      handleGetScheduledEvent
    );
    yield takeEvery(
      ActionType.SEND_SCHEDULED_EVENT_CONFIRMATION_REQUEST,
      handleSendScheduledEventConfirmation
    );
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default dealsSagaCreator;
