import { FC, useCallback } from 'react';

import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import {
  MaterialsListMaterial,
  MaterialsListSummaryItems,
} from '@/api/queries/project/useGetProjectMaterialListSummaryItems';
import {
  getAccountRegionFromCurrencyCode,
  getRegionalTextString,
} from '@payaca/helpers/internationalHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import { Link } from 'react-router-dom';
import { Deal } from '@payaca/types/dealTypes';
import Badge from '@payaca/components/plBadge/Badge';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import { humanizeCurrency } from '@/helpers/currency';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import { useUserHasPermission } from '@/hooks/usePermissions';
import { TTableRowAction } from '@payaca/components/plManageableItemsList/components/Table';
import { SupplierBadges } from './SupplierBadges';
import { roundToDecimalPlaces } from '@payaca/helpers/numberHelper';
import { useSummaryItemsInputPaginationState } from './materialsPanelHooks';

export const OrderedPanel: FC<{
  projectId?: Deal['id'];
  summaryItems: MaterialsListSummaryItems | undefined;
  inputPaginationState: Omit<
    ReturnType<typeof useSummaryItemsInputPaginationState>,
    'setStatuses'
  >;
  onRequestDeleteMaterialPurchaseRecord: (row: MaterialsListMaterial) => void;
}> = ({
  summaryItems,
  projectId,
  inputPaginationState: { pagination, input },
  onRequestDeleteMaterialPurchaseRecord,
}) => {
  const { data: regionPreferences } = useGetMyRegionPreferences();

  const region = regionPreferences
    ? getAccountRegionFromCurrencyCode(regionPreferences?.currency.code)
    : AccountRegions.UK;

  const hasDeleteMprPermission = useUserHasPermission({
    permissions: [MaterialsListPermissions.DELETE_MATERIAL_PURCHASE_RECORD],
  });

  const getItemActions = useCallback(
    (row: MaterialsListMaterial) => {
      const actions: TTableRowAction<MaterialsListMaterial>[] = [];

      if (
        hasDeleteMprPermission &&
        row.__typename === 'MaterialsListOrderSummaryPurchasedItem'
      ) {
        actions.push({
          label: 'Delete Purchase Record',
          onClick: onRequestDeleteMaterialPurchaseRecord,
        });
      }

      return actions;
    },
    [hasDeleteMprPermission, onRequestDeleteMaterialPurchaseRecord]
  );

  return (
    <div className="@container">
      <ManageableItemsList.ActionBar>
        <ManageableItemsList.ActionBar.SearchInput
          onChange={input.setSearchTerm}
        />
      </ManageableItemsList.ActionBar>
      <ManageableItemsList.Table<MaterialsListMaterial>
        isLoading={!summaryItems}
        items={summaryItems?.items || []}
        itemActions={getItemActions}
        uniqueKey="id"
      >
        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header="Material"
          field="id"
          render={(id, row) => (
            <div className="max-w-[400px] truncate">
              <Link
                to={`/materials/${row.materialsListMaterial.material.internalId}`}
              >
                {row.materialsListMaterial.material.name}
              </Link>
            </div>
          )}
        />
        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header="Qty"
          field="id"
          render={(id, row) => roundToDecimalPlaces(row.materialQuantity, 2)}
        />
        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header="PO"
          field="id"
          render={(id, row) => {
            if (
              row.__typename === 'MaterialsListOrderSummaryPurchasedItem' &&
              row.materialPurchaseRecord?.purchaseOrder
            )
              return (
                <Link
                  to={`/purchaseOrders/${row.materialPurchaseRecord?.purchaseOrder?.id}?projectId=${projectId}`}
                >
                  {row.materialPurchaseRecord?.purchaseOrder?.reference}
                </Link>
              );
            if (
              row.__typename === 'MaterialsListOrderSummaryOrderedItem' &&
              row.materialPurchaseIntent?.purchaseOrder
            )
              return (
                <Link
                  to={`/purchaseOrders/${row.materialPurchaseIntent?.purchaseOrder?.id}?projectId=${projectId}`}
                >
                  {row.materialPurchaseIntent?.purchaseOrder?.reference}
                </Link>
              );
          }}
        />

        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header="Supplier"
          field="id"
          render={(id, row) => {
            let supplier = null;
            if (row.__typename === 'MaterialsListOrderSummaryPurchasedItem')
              supplier = row.materialPurchaseRecord?.supplier;
            if (row.__typename === 'MaterialsListOrderSummaryOrderedItem')
              supplier = row.materialPurchaseIntent?.supplier;

            return supplier && <SupplierBadges suppliers={[supplier]} />;
          }}
        />
        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header={`Total (ex ${getRegionalTextString(
            region,
            RegionalStrings.VALUE_ADDED_TAX
          )})`}
          field="id"
          render={(id, row) => {
            let price = null;
            if (row.__typename === 'MaterialsListOrderSummaryPurchasedItem')
              price = row.materialPurchaseRecord?.price.totalPriceExcTax;
            if (row.__typename === 'MaterialsListOrderSummaryOrderedItem')
              price =
                row.materialPurchaseIntent?.predictedPrice.totalPriceExcTax;

            return price && humanizeCurrency(price);
          }}
        />
        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header="Status"
          field="id"
          render={(id, row) => {
            if (row.status === 'PURCHASED') {
              return (
                <Badge variant="soft" colour="teal">
                  Purchased
                </Badge>
              );
            }

            return (
              <Badge variant="soft" colour="yellow">
                Ordered
              </Badge>
            );
          }}
        />
      </ManageableItemsList.Table>
      {summaryItems && (
        <ManageableItemsList.PaginationBar
          totalItems={summaryItems.totalCount}
          pageSize={summaryItems.limit}
          currentPage={summaryItems.offset / summaryItems.limit + 1}
          onPageChange={pagination.setCurrentPage}
        />
      )}
    </div>
  );
};
