import React, { ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';
import FloatingTooltip, {
  IProps as IFloatingTooltipProps,
} from './FloatingTooltip';
import TooltipCore from './TooltipCore';

export enum TooltipPositionVariant {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export type IProps = {
  tooltipContent: React.ReactNode;
} & Omit<IFloatingTooltipProps, 'arrowClassName' | 'children'> &
  ComponentPropsWithoutRef<'div'>;

const Tooltip: FC<PropsWithChildren<IProps>> = (props) => {
  const {
    positionVariant,
    showArrow,
    enabled,
    tooltipContent,
    className,
    children,
    ...rest
  } = props;

  return (
    <FloatingTooltip
      positionVariant={positionVariant}
      showArrow={showArrow}
      enabled={enabled}
      arrowClassName="fill-gray-900 shadow-sm dark:fill-slate-700"
    >
      <div
        className={'inline-block' + (className ? ' ' + className : '')}
        {...rest}
      >
        {children}
      </div>

      <TooltipCore>{tooltipContent}</TooltipCore>
    </FloatingTooltip>
  );
};

export default Tooltip;
