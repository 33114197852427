import React, { FunctionComponent as FC } from 'react';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import { Task } from '@payaca/types/taskTypes';
import useGetTask from '@/api/queries/tasks/useGetTask';
import { format } from 'date-fns';
import {
  DateFormats,
  getInternationalDateFormatByLocale,
} from '@payaca/helpers/internationalHelper';
import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import Button from '@payaca/components/plButton/Button';
import { MaterialsListTaskMaterialsList } from '../materialsListTaskMaterialsList/MaterialsListTaskMaterialsList';

export const MaterialsListTaskModal: FC<
  Omit<IModalProps, 'title'> & {
    taskId: Task['id'];
  }
> = ({ isOpen, taskId, onClose }) => {
  const { data } = useGetTask(taskId);
  const { data: accountRegionPreferences } = useGetMyRegionPreferences();

  if (!data || data?.task?.__typename !== 'MaterialsListTask') {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={data.task.name}>
      <Modal.Body>
        <h3>Materials added:</h3>
        <MaterialsListTaskMaterialsList taskId={taskId} />
        {!!data.task.completedAt && (
          <p>
            Completed at:{' '}
            {format(
              new Date(data.task.completedAt),
              getInternationalDateFormatByLocale(
                DateFormats.SHORT,
                accountRegionPreferences?.locale
              )
            )}
          </p>
        )}
        {!data.task.completedAt &&
          !!data.task.materialsListMaterials?.length && (
            <p>This Task has not been completed yet.</p>
          )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button onClick={onClose}>OK</Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};
