import { action } from 'typesafe-actions';

import { ActionType } from './lineItemGroupsTypes';
import { LineItemGroup } from '@payaca/types/lineItemGroupTypes';

// GET LINE ITEM GROUPS
export const requestGetLineItemGroups = () => {
  return action(ActionType.REQUEST_GET_LINE_ITEM_GROUPS);
};
export const getLineItemGroupsSuccess = (lineItemGroups: {
  [key: string]: LineItemGroup;
}) => {
  return action(ActionType.GET_LINE_ITEM_GROUPS_SUCCESS, { lineItemGroups });
};
export const getLineItemGroupsFailure = (
  error: Error,
  errorMessage: string
) => {
  return action(
    ActionType.GET_LINE_ITEM_GROUPS_FAILURE,
    { errorMessage },
    null,
    error
  );
};

// GET LINE ITEM GROUP
export const requestGetLineItemGroup = (lineItemGroupId: number) =>
  action(ActionType.REQUEST_GET_LINE_ITEM_GROUP, { lineItemGroupId });
export const getLineItemGroupSuccess = (lineItemGroup: LineItemGroup) => {
  return action(ActionType.GET_LINE_ITEM_GROUP_SUCCESS, {
    lineItemGroupId: lineItemGroup.id,
    lineItemGroup,
  });
};
export const getLineItemGroupFailure = (
  lineItemGroupId: number,
  error: Error,
  errorMessage: string
) => {
  return action(
    ActionType.GET_LINE_ITEM_GROUP_FAILURE,
    { lineItemGroupId, errorMessage },
    null,
    error
  );
};

// CREATE LINE ITEM GROUP
export const requestCreateLineItemGroup = () =>
  action(ActionType.REQUEST_CREATE_LINE_ITEM_GROUP, {});
export const createLineItemGroupSuccess = (lineItemGroupId: number) => {
  return action(ActionType.CREATE_LINE_ITEM_GROUP_SUCCESS, {
    lineItemGroupId,
  });
};
export const createLineItemGroupFailure = (
  error: Error,
  errorMessage: string
) => {
  return action(
    ActionType.CREATE_LINE_ITEM_GROUP_FAILURE,
    { errorMessage },
    null,
    error
  );
};
export const clearCreateLineItemGroup = () =>
  action(ActionType.CLEAR_CREATE_LINE_ITEM_GROUP);

// UPDATE LINE ITEM GROUP
export const requestUpdateLineItemGroup = (
  lineItemGroupId: number,
  lineItemGroup: LineItemGroup
) =>
  action(ActionType.REQUEST_UPDATE_LINE_ITEM_GROUP, {
    lineItemGroupId,
    lineItemGroup,
  });
export const updateLineItemGroupSuccess = (lineItemGroup: LineItemGroup) => {
  return action(ActionType.UPDATE_LINE_ITEM_GROUP_SUCCESS, {
    lineItemGroupId: lineItemGroup.id,
    lineItemGroup,
  });
};
export const updateLineItemGroupFailure = (
  lineItemGroupId: number,
  error: Error,
  errorMessage: string
) => {
  return action(
    ActionType.UPDATE_LINE_ITEM_GROUP_FAILURE,
    { lineItemGroupId, errorMessage },
    null,
    error
  );
};
export const clearUpdateLineItemGroup = () =>
  action(ActionType.CLEAR_UPDATE_LINE_ITEM_GROUP);

// ARCHIVE LINE ITEM GROUP
export const requestArchiveLineItemGroup = (lineItemGroupId: number) =>
  action(ActionType.REQUEST_ARCHIVE_LINE_ITEM_GROUP, {
    lineItemGroupId,
  });
export const archiveLineItemGroupSuccess = (lineItemGroupId: number) => {
  return action(ActionType.ARCHIVE_LINE_ITEM_GROUP_SUCCESS, {
    lineItemGroupId,
  });
};
export const archiveLineItemGroupFailure = (
  lineItemGroupId: number,
  error: Error,
  errorMessage: string
) => {
  return action(
    ActionType.ARCHIVE_LINE_ITEM_GROUP_FAILURE,
    { lineItemGroupId, errorMessage },
    null,
    error
  );
};
export const clearArchiveLineItemGroup = () =>
  action(ActionType.CLEAR_ARCHIVE_LINE_ITEM_GROUP);

// CLEAR LINE ITEM GROUP
export const clearLineItemGroups = () => {
  return action(ActionType.CLEAR_LINE_ITEM_GROUPS);
};
