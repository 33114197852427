import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';

import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import AccountNameField from '../bacsFields/AccountNameField';
import SortCodeField from '../bacsFields/SortCodeField';
import AccountNumberField from '../bacsFields/AccountNumberField';

import { actions as userActions } from '@/api/users';

import { getUserRoles } from '@/utils/stateAccessors';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';

import './AddBacsModal.sass';
import {
  getAllowEmptyValidator,
  getRegexMatchFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import { useSelector } from '@/api/state';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
const AddBacsModal: FC<Props> = ({ isOpen, onClose }): JSX.Element => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [isSaving, setIsSaving] = useState(false);

  const account = useSelector(
    (state: any) => state.users?.myProfile?.accounts[0]
  );

  const userRoles = useSelector(getUserRoles);

  const isAdmin = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      AccountsPermissions.UPDATE_ACCOUNT,
    ]);
  }, [userRoles]);

  const initialFormState = useMemo(() => {
    return {
      accountName: account?.accountNameRaw,
      sortCode: account?.sortCodeRaw,
      accountNumber: account?.accountNumberRaw,
    };
  }, [account]);

  const fieldValidators = useMemo(() => {
    let sortCodeRegex = /^[0-9]{6}$/;
    let sortCodeValidationError = 'Invalid sort code';
    let accountNumberRegex = /^[0-9]{8}$/;

    if (account.region === AccountRegions.SOUTH_AFRICA) {
      sortCodeRegex = /^[0-9]{6}$/;
      sortCodeValidationError = 'Invalid branch code';
      accountNumberRegex = /^[0-9]{9,11}$/;
    }

    if (account.region === AccountRegions.US) {
      sortCodeRegex = /^[0-9]{9}$/;
      sortCodeValidationError = 'Invalid routing number';
      accountNumberRegex = /^[0-9]{8,12}$/;
    }

    return {
      accountName: [],
      sortCode: [
        getAllowEmptyValidator(
          getRegexMatchFieldValidator(sortCodeRegex, {
            customErrorMessage: sortCodeValidationError,
          })
        ),
      ],
      accountNumber: [
        getAllowEmptyValidator(
          getRegexMatchFieldValidator(accountNumberRegex, {
            customErrorMessage: 'Invalid account number',
          })
        ),
      ],
    };
  }, [account]);

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      if (!isAdmin) {
        // user does not have access to save this - show error
        setErrorMessage('Please contact an admin to amend these details');
        return;
      }
      setIsSaving(true);
      dispatch(
        userActions.updateBusinessAccount(
          account.id,
          {
            accountName: formState.accountName,
            accountNumber: formState.accountNumber,
            sortCode: formState.sortCode,
          },
          (err: any) => {
            if (err) {
              setErrorMessage('An error occurred updating your account.');
            } else {
              dispatch(
                userActions.getProfile(() => {
                  setIsSaving(false);
                  onClose();
                })
              );
            }
          }
        )
      );
    },
    [dispatch, isAdmin, onClose]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <ValidatedFieldWrapper validationResult={validationState.accountName}>
            <AccountNameField
              name="accountName"
              value={formState.accountName}
              onChange={onFieldChange}
              isReadOnly={!isAdmin}
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper validationResult={validationState.sortCode}>
            <SortCodeField
              name="sortCode"
              value={formState.sortCode}
              onChange={onFieldChange}
              isReadOnly={!isAdmin}
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper
            validationResult={validationState.accountNumber}
          >
            <AccountNumberField
              name="accountNumber"
              value={formState.accountNumber}
              onChange={onFieldChange}
              isReadOnly={!isAdmin}
            />
          </ValidatedFieldWrapper>
          <div className="actions-container flex-center flex-container">
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isDisabled={!isValid}
              isProcessing={isSaving}
              onClick={() => !isSaving && onSubmit(formState)}
            >
              Save
            </Button>
            {errorMessage && <ErrorMessage message={errorMessage} />}
          </div>
        </>
      );
    },
    [errorMessage, isAdmin, isSaving, onSubmit]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="add-bacs-modal"
      title={`Add bank transfer details`}
    >
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
      />
    </Modal>
  );
};

export default AddBacsModal;
