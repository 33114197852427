import { DeclareAsyncAction, UnpackAsyncAction } from '../types';
import {
  ListedFile,
  ListedFileType,
  TShareFilesBody,
} from '@payaca/types/listedFileTypes';

export interface State {
  deals: Record<number, ListedFile[] | undefined>;
  isGettingListedFilesForDeal: boolean;
  isSharingFilesForDeal: boolean;
  isUnsharingFilesForDeal: boolean;
}

export enum ActionType {
  GET_LISTED_FILES_FOR_DEALS_REQUEST = 'listedFiles.getListedFilesPage:request',
  GET_LISTED_FILES_FOR_DEALS_SUCCESS = 'listedFiles.getListedFilesPage:success',
  GET_LISTED_FILES_FOR_DEALS_FAILURE = 'listedFiles.getListedFilesPage:failure',
  CLEAR_LISTED_FILES_PAGE = 'listedFiles.clearListedFilesPage',
  SHARE_LISTED_FILES_REQUEST = 'listedFiles.shareListedFiles:request',
  SHARE_LISTED_FILES_SUCCESS = 'listedFiles.shareListedFiles:success',
  SHARE_LISTED_FILES_FAILURE = 'listedFiles.shareListedFiles:failure',
  UNSHARE_LISTED_FILES_REQUEST = 'listedFiles.unshareListedFiles:request',
  UNSHARE_LISTED_FILES_SUCCESS = 'listedFiles.unshareListedFiles:success',
  UNSHARE_LISTED_FILES_FAILURE = 'listedFiles.unshareListedFiles:failure',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export type GetListedFilesForDeal = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_LISTED_FILES_FOR_DEALS_REQUEST;
      payload: {
        dealId: number;
        fileType?: ListedFileType[];
        searchTerm?: string;
        callback?: (listedFiles: ListedFile[]) => void;
        onErrorCallback?: () => void;
      };
    };
    success: {
      type: ActionType.GET_LISTED_FILES_FOR_DEALS_SUCCESS;
      payload: { dealId: string; files: ListedFile[] };
    };
    failure: { type: ActionType.GET_LISTED_FILES_FOR_DEALS_FAILURE };
  }
>;

type PutShareFilesForDealResponse = { dealId: string; files: ListedFile[] };
export type PutShareFilesForDeal = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.SHARE_LISTED_FILES_REQUEST;
      payload: {
        dealId: number;
        callback?: (response: PutShareFilesForDealResponse) => void;
      } & TShareFilesBody;
    };
    success: {
      type: ActionType.SHARE_LISTED_FILES_SUCCESS;
      payload: { dealId: string; files: ListedFile[] };
    };
    failure: { type: ActionType.SHARE_LISTED_FILES_FAILURE };
  }
>;

type PutUnshareFilesForDealResponse = { dealId: string; files: ListedFile[] };
export type PutUnshareFilesForDeal = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UNSHARE_LISTED_FILES_REQUEST;
      payload: {
        dealId: number;
        callback?: (response: PutUnshareFilesForDealResponse) => void;
      } & TShareFilesBody;
    };
    success: {
      type: ActionType.UNSHARE_LISTED_FILES_SUCCESS;
      payload: { dealId: string; files: ListedFile[] };
    };
    failure: { type: ActionType.UNSHARE_LISTED_FILES_FAILURE };
  }
>;

export type AnyAction = UnpackAsyncAction<
  GetListedFilesForDeal | PutShareFilesForDeal | PutUnshareFilesForDeal
>;
