/* eslint react/prop-types: 0 */
import React from 'react';

import './MarkerWrapper.sass';

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { lat?: number; lng?: number };
const MarkerWrapper = (props: Props) => {
  return (
    <div
      {...props}
      className={`marker-wrapper${
        props.className ? ` ${props.className}` : ''
      }${props.onClick ? ' clickable' : ''}`}
    ></div>
  );
};
export default MarkerWrapper;
