import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { get } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { faChevronRight, faPen } from '@fortawesome/free-solid-svg-icons';

import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';

import { getEmailFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';

import { Customer, CustomerContact } from '@payaca/types/customerTypes';
import {
  FieldValidationOptions,
  FieldValidationResult,
  FieldValidator,
} from '@payaca/types/fieldValidationTypes';

import * as customerActions from '@payaca/store/customer/customerActions';

import './CustomerEmailControl.css';
import { useSelector } from '@/api/state';

export const getEmailMustNotBeBlockedValidator = (
  blockedEmails: string[],
  options?: FieldValidationOptions
): FieldValidator => {
  const errorMessage =
    options?.customErrorMessage ||
    `${
      options?.readableName ? options?.readableName : 'This'
    } must be a valid email address`;

  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName);

    if (blockedEmails && blockedEmails.includes(value)) {
      return {
        isValid: false,
        errors: [errorMessage],
      };
    }

    return {
      isValid: true,
    };
  };
};

type Props = {
  blockedEmails: string[];
  customer: Customer;
  contactId: number | null;
  onPersistCustomer?: (customerId: number) => void;
};
const CustomerEmailControl: FC<Props> = ({
  blockedEmails,
  customer,
  contactId,
  onPersistCustomer,
}: Props): JSX.Element => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const dispatch = useDispatch();
  const isPersistingCustomer = useSelector(
    (state) => state.customer.isPersistingCustomer
  );

  const jobContact = useMemo(
    () => getJobContactFromCustomer(customer, contactId),
    [customer, contactId]
  );

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      const contacts = customer.contacts.map(
        (customerContact: CustomerContact, index: number) => {
          if (customerContact.id === jobContact?.id) {
            // update email on job contact
            return {
              ...customerContact,
              emailAddress: formState.emailAddress,
            };
          } else {
            return customerContact;
          }
        }
      );

      const customerToPersist = {
        ...customer,
        contacts: contacts,
      };

      dispatch(
        customerActions.requestPersistCustomer(
          customerToPersist,
          (customerId: number) => {
            setIsInEditMode(false);
            onPersistCustomer && onPersistCustomer(customerId);
          }
        )
      );
    },
    [customer, onPersistCustomer]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      if (!isInEditMode)
        return (
          <div>
            <div className="flex-container">
              <div>{formState.emailAddress}</div>
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                isProcessing={isPersistingCustomer}
                onClick={() => setIsInEditMode(true)}
              >
                <FontAwesomeIcon icon={faPen} />
              </Button>
            </div>
          </div>
        );

      return (
        <div className="flex-container form-contents">
          <div className="field-container flex-grow">
            <ValidatedFieldWrapper
              validationResult={validationState.emailAddress}
              isTouched={true}
            >
              <BasicField
                name="emailAddress"
                value={formState.emailAddress || ''}
                isRequired={true}
                onChange={onFieldChange}
                onTouch={onFieldTouch}
              />
            </ValidatedFieldWrapper>
          </div>
          <div className="button-container">
            <Button
              isDisabled={!isValid}
              isProcessing={isPersistingCustomer}
              onClick={() => !isPersistingCustomer && onSubmit(formState)}
              iconAfter={faChevronRight}
            >
              Update email
            </Button>
          </div>
        </div>
      );
    },
    [isPersistingCustomer, onSubmit, isInEditMode]
  );

  const fieldValidators = useMemo(() => {
    return {
      emailAddress: [
        getEmailFieldValidator(),
        getEmailMustNotBeBlockedValidator(blockedEmails || []),
      ],
    };
  }, [blockedEmails]);

  const initialFormState = useMemo(() => {
    return {
      emailAddress: jobContact?.emailAddress,
    };
  }, [jobContact]);

  return (
    <ResponsiveViewWrapper
      className="customer-email-control"
      downBreakpointSm={550}
    >
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
        fieldValidators={fieldValidators}
      />
    </ResponsiveViewWrapper>
  );
};

export default CustomerEmailControl;
