import React, { FC, useCallback, useEffect, useReducer, useState } from 'react';
import { EBtnColour } from '@payaca/components/plButton/useButtonClassName';

import Modal from '@payaca/components/plModal/Modal';
import { useDispatch } from 'react-redux';
import Button from '@payaca/components/plButton/Button';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import { MaterialPurchaseRecord } from '@/api/queries/project/useGetProjectMaterialListSummaryItems';

export const DeleteMaterialPurchaseRecordModal: FC<{
  materialPurchaseRecord: MaterialPurchaseRecord;
  onClose: () => void;
  isOpen: boolean;
  onDeleteSuccess: () => void;
}> = ({ isOpen, onClose, materialPurchaseRecord, onDeleteSuccess }) => {
  const canDelete = materialPurchaseRecord.purchaseOrder === null;

  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDelete = useCallback(() => {
    if (isProcessing) return;
    setIsProcessing(true);
    dispatch(
      materialsListActions.requestDeleteMaterialPurchaseRecord(
        +materialPurchaseRecord.id,
        () => {
          setIsProcessing(false);
          onDeleteSuccess();
        }
      )
    );
  }, [materialPurchaseRecord.id, onClose, onDeleteSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Delete Record of Purchase">
      <Modal.Body>
        {canDelete ? (
          'Are you sure you want to delete?'
        ) : (
          <>
            <p>
              This Record of Purchase cannot be deleted as it&#39;s part of a
              Purchase Order.{' '}
            </p>
            <p>Consider voiding the Purchase Order instead.</p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          {canDelete ? (
            <Button
              colour={EBtnColour.Red}
              onClick={handleDelete}
              isProcessing={isProcessing}
            >
              Delete
            </Button>
          ) : (
            <Button onClick={onClose}>Ok</Button>
          )}
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};
