import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteTaxRate } from '@payaca/store/tax-rates/actions';
import { TaxRate } from '@payaca/types/taxRateTypes';
import { isNullish } from '@payaca/utilities/guards';
import { Nullish } from '@payaca/utilities/types';

import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import Modal from '@payaca/components/plModal/Modal';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  taxRateId?: Nullish<TaxRate['id']>;
};

const DeleteTaxRateConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  taxRateId,
}) => {
  const dispatch = useDispatch();

  const [isBusy, setIsBusy] = useState(false);
  const [deleteError, setDeleteError] = useState<Error | null>(null);

  const handleDelete = useCallback(() => {
    if (!isNullish(taxRateId)) {
      setIsBusy(true);
      dispatch(
        deleteTaxRate.request(taxRateId, (err) => {
          setIsBusy(false);
          err && setDeleteError(err);
          err || onClose();
        })
      );
    }
  }, [taxRateId, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Delete Tax Rate'}>
      <Modal.Body>
        <p className="delete-tax-rate-confirmation-modal__message">
          Are you sure you want to delete this tax rate?
        </p>
        {deleteError && (
          <FeedbackBlock
            isDismissable={true}
            onDismissed={() => setDeleteError(null)}
            feedbackLevel={FeedbackLevel.ERROR}
          >
            <span>{deleteError.message}</span>
          </FeedbackBlock>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            onClick={handleDelete}
            disabled={isBusy}
            variant={EBtnVariant.Outline}
            colour={EBtnColour.Red}
          >
            Delete
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTaxRateConfirmationModal;
