import { Account } from '@payaca/types/accountTypes';
import { Address } from '@payaca/types/locationTypes';

import { DocumentSenderContext } from '@payaca/types/accountBrandTypes';

export const getDocumentSenderContextFromAccount = (
  account: Pick<
    Account,
    | 'logoUrl'
    | 'email'
    | 'companyName'
    | 'contactNumber'
    | 'vatNumber'
    | 'address'
    | 'postcode'
    | 'city'
    | 'region'
    | 'brandColour'
    | 'legalBusinessName'
    | 'businessNotes'
    | 'paymentTerms'
    | 'proposalPaymentTerms'
    | "invoicePaymentTerms"
    | 'companyRegistrationNumber'
    | 'registeredCountryName'
  >
): DocumentSenderContext => {
  return {
    logoUrl: account.logoUrl,
    emailAddress: account.email,
    companyName: account.companyName,
    phoneNumber: account.contactNumber,
    vatNumber: account.vatNumber,
    address: {
      line1: account.address,
      postcode: account.postcode,
      city: account.city,
    } as Address,
    region: account.region,
    brandColour: account.brandColour,
    legalBusinessName: account.legalBusinessName,
    businessTerms: account.businessNotes,
    // @deprecated
    paymentTerms: account.proposalPaymentTerms || undefined,
    proposalPaymentTerms: account.proposalPaymentTerms || undefined,
    invoicePaymentTerms: account.invoicePaymentTerms || undefined,
    companyRegistrationNumber: account.companyRegistrationNumber,
    registeredCountryName: account.registeredCountryName,
  };
};
