import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '@payaca/components/plModal/Modal';
import PLButton from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import { ListedFile, TFileShareEmailBody } from '@payaca/types/listedFileTypes';
import { useDispatch } from 'react-redux';
import * as listedFilesActions from '@payaca/store/listedFiles/listedFilesActions';
import {
  useCustomerForDeal,
  useDeal,
  useListedFilesForDeal,
} from '@payaca/store/hooks/appState';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import { useSelector } from '@/api/state';
import { Textarea } from '@payaca/components/plTextarea/Textarea';
import { getPrimaryContactFromCustomer } from '@payaca/helpers/customerHelper';
import { useUserHasPermission } from '@/hooks/usePermissions';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import Button from '@payaca/components/plButton/Button';
import { buildEmailSubjectPrefixForProjectFromProject } from '@payaca/helpers/dealHelper';
import { singularPlural } from '@payaca/utilities/stringUtilities';
import { Field } from '@payaca/components/plField/Field';
import CheckboxGroupField from '@payaca/components/plGroupField/CheckboxGroupField';

export interface IProps {
  dealId: number;
  filesToShare: ListedFile[];
  setFilesToShare: (files: ListedFile[]) => void;
}

const ShareModal: FC<IProps> = (props) => {
  const { dealId, filesToShare, setFilesToShare } = props;
  const dispatch = useDispatch();
  const { isSharingFilesForDeal } = useListedFilesForDeal(dealId);
  const [emailBody, setEmailBody] = useState<TFileShareEmailBody>();
  const [selectedCustomerContactIds, setSelectedCustomerContactIds] = useState<
    string[]
  >([]);
  const customer = useCustomerForDeal(dealId);
  const myProfile = useSelector((state: any) => state.users.myProfile);
  const deal = useDeal(dealId);
  const { companyName, logoUrl } = useMemo(
    () => ({
      companyName: myProfile?.accounts?.[0]?.companyName,
      logoUrl: myProfile?.accounts?.[0]?.logoUrl,
    }),
    [myProfile]
  );
  useEffect(() => {
    if (!customer) return;

    const primaryContact = getPrimaryContactFromCustomer(customer);
    if (primaryContact) {
      setSelectedCustomerContactIds([primaryContact.id.toString()]);
    }
  }, [customer, filesToShare]);

  const canUnshareDealFiles = useUserHasPermission({
    permissions: [DealsPermissions.UNSHARE_DEAL_FILES],
  });

  useEffect(() => {
    if (!companyName || filesToShare.length !== 0) return;

    setEmailBody(
      !canUnshareDealFiles || customer?.fileSharingSettings?.attachToEmails
        ? {
            intro: `${companyName} has shared the following files with you:`,
            outro: `Thanks,\n\n${companyName}`,
          }
        : {
            intro: `${companyName} has shared files with you via your client portal.\n\nPlease click the button below to access your client portal and view the files.`,
            outro: `Thanks,\n\n${companyName}`,
          }
    );
  }, [companyName, filesToShare, canUnshareDealFiles, customer]);

  /**
   * Handles sharing files for deal
   */
  const handleShareFiles = useCallback(() => {
    if (filesToShare.length === 0 || !selectedCustomerContactIds.length) return;

    dispatch(
      listedFilesActions.shareFilesForDeal.request({
        dealId,
        // @ts-expect-error
        files: filesToShare.map((file) => ({
          type: file.entity,
          id: file.entityId.toString(),
        })),
        emailBody,
        customerContactIds: selectedCustomerContactIds.map((i) =>
          parseInt(i, 10)
        ),
        callback: () => {
          setFilesToShare([]);
        },
      })
    );
  }, [dealId, dispatch, emailBody, filesToShare, selectedCustomerContactIds]);

  const handleTextChange =
    (key: keyof TFileShareEmailBody) => (value: string) => {
      setEmailBody((s = { intro: '', outro: '' }) => ({
        ...s,
        [key]: value,
      }));
    };

  return (
    <>
      {/*Share modal*/}
      <Modal
        title="Are you sure you want to share?"
        isOpen={filesToShare.length > 0}
        onClose={() => setFilesToShare([])}
      >
        <Modal.Body>
          <div className="mb-4 space-y-3">
            <p className="text-base">
              <span className="font-medium">To:</span>{' '}
              {customer?.contacts
                .filter((c) =>
                  selectedCustomerContactIds.includes(c.id.toString())
                )
                .map((c) => c.emailAddress)
                .join(', ')}
            </p>

            <Field name="customerContacts">
              <CheckboxGroupField
                value={selectedCustomerContactIds}
                options={
                  customer?.contacts.map((c) => ({
                    label: c.name,
                    value: c.id.toString(),
                  })) || []
                }
                onChange={({ customerContacts }) =>
                  setSelectedCustomerContactIds(customerContacts)
                }
              />
            </Field>

            <p className="text-base">
              <span className="font-medium">Subject:</span>{' '}
              {deal && buildEmailSubjectPrefixForProjectFromProject(deal)}
              {companyName} has shared{' '}
              {singularPlural(filesToShare.length, 'file', 'files', {
                singularlengthAlternative: 'a',
              })}{' '}
              with you
            </p>

            <Card sizeVariant={CardSizeVariant.SM}>
              <Card.Body>
                <div className="text-center">
                  <img
                    className="mb-3 h-auto max-w-[200px]"
                    src={logoUrl}
                    alt="Company Logo"
                  />
                </div>

                <Textarea
                  className="resize-y"
                  value={emailBody?.intro || ''}
                  onChange={handleTextChange('intro')}
                />

                {(!canUnshareDealFiles ||
                  customer?.fileSharingSettings?.attachToEmails) && (
                  <ul className="text-left text-base">
                    {filesToShare.map((file) => (
                      <li key={file.entityId}>
                        {file.entity === 'form_instances' ? (
                          <>{file.name} (attached to this email)</>
                        ) : (
                          <a href={file.url} target="_blank" rel="noreferrer">
                            {file.name}
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                )}

                {canUnshareDealFiles && (
                  <div className="my-4 text-center">
                    <Button disabled>View files</Button>
                  </div>
                )}

                <Textarea
                  className="resize-y"
                  value={emailBody?.outro || ''}
                  onChange={handleTextChange('outro')}
                />
              </Card.Body>
            </Card>
          </div>

          <p className="mb-2 text-base">
            You are about to share {filesToShare.length}{' '}
            {filesToShare.length === 1 ? 'file' : 'files'} with the customer of
            this project.
          </p>

          <p className="mb-2 text-base">
            This will allow the customer to view and download the{' '}
            {filesToShare.length === 1 ? 'file' : 'files'} via{' '}
            {canUnshareDealFiles ? 'the client portal' : 'email'}.
          </p>

          {!canUnshareDealFiles && (
            <p className="mb-2 text-base">This action cannot be undone.</p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Actions>
            <PLButton
              className="mr-2"
              variant={EBtnVariant.Outline}
              disabled={isSharingFilesForDeal}
              onClick={() => setFilesToShare([])}
            >
              Cancel
            </PLButton>
            <PLButton
              onClick={handleShareFiles}
              disabled={isSharingFilesForDeal}
              isProcessing={isSharingFilesForDeal}
            >
              Share
            </PLButton>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShareModal;
