import React, { useMemo, useState } from 'react';

import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import CurrencyField from '@payaca/components/currencyField/CurrencyField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import SalesTaxSettingsField from '../salesTaxSettingsField/SalesTaxSettingsField';
import TotalsEditableValue from '../jobSidePanel/TotalsEditableValue';
import JobLineItemDiscountModal from './JobLineItemDiscountModal';
import { FormState } from '../../pages/jobLineItemPage/JobLineItemPage';

import { JobLineItem } from '@payaca/types/jobContentTypes';
import { Deal } from '@payaca/types/dealTypes';

import { currencyPrice } from '@payaca/helpers/financeHelper';

import { getJobLineItem, getRegion } from '@/utils/stateAccessors';

import { useSelector } from '../../../api/state';
import { useAccount } from '../../../utils/storeHooks';
import { useDeal } from '@payaca/store/hooks/appState';

import { isNullish } from '@payaca/utilities/guards';

import './EditItemPrice.sass';
import {
  getJoblineItemTotalsFromJobLineItemBase,
  getUnitJoblineItemTotalsFromJobLineItem,
} from '@payaca/helpers/jobLineItemHelper';

type Props = {
  jobLineItemId: JobLineItem['id'];
  formState: FormState;
  updateFormFields: (changedFields: Partial<FormState>) => void;
  dealId?: Deal['id'];
  saveAndFlush?: () => void;
};
const EditJobLineItemPrice = ({
  jobLineItemId,
  formState,
  updateFormFields,
  dealId,
  saveAndFlush,
}: Props) => {
  const account = useAccount();
  const region = useSelector(getRegion);
  const jobLineItem = useSelector((state) =>
    getJobLineItem(state, jobLineItemId)
  );
  const deal = useDeal(dealId);
  const [showDiscountModal, setShowDiscountModal] = useState(false);

  const unitJobLineItem = useMemo(() => {
    return (
      jobLineItem && getUnitJoblineItemTotalsFromJobLineItem({ ...jobLineItem })
    );
  }, [
    jobLineItem?.price,
    jobLineItem?.quantity,
    jobLineItem?.markupPercentage,
    jobLineItem?.discountPercentage,
    jobLineItem?.vatIncluded,
    jobLineItem?.cisDeductionRate,
    jobLineItem?.isReverseChargeVat,
    jobLineItem?.vatAmount,
  ]);

  return (
    <ContentPanel className="edit-item-price">
      <>
        <h3>Item sales price</h3>
        <div>
          <div className="edit-price-wrapper">
            <div className="price-input-method-radio">
              <div>
                <span>Enter price</span>
                <CurrencyField
                  name="price"
                  value={
                    isNullish(formState?.price) ? undefined : formState.price
                  }
                  onChange={updateFormFields}
                  onBlur={saveAndFlush}
                />
              </div>
            </div>
          </div>

          {/* price ex tax, vat, totals */}
          <div className="item-totals-wrapper">
            <LabelValuePair
              label={`Item price (ex tax)`}
              value={currencyPrice(jobLineItem?.price || 0, region)}
              suffixLabelWith=""
            />

            {/* Discount */}
            {deal && deal.version > 1 && (
              <>
                {!!jobLineItem?.markupPercentage && (
                  <LabelValuePair
                    label={`Commission (${jobLineItem.markupPercentage})%`}
                    value={`${currencyPrice(
                      unitJobLineItem?.markupAmount || 0,
                      region
                    )}`}
                    suffixLabelWith=""
                  />
                )}
                <TotalsEditableValue
                  editValueTitle={`Discount (${formState.discountPercentage}%)`}
                  emptyValueTitle="Apply discount"
                  onClick={() => {
                    setShowDiscountModal(true);
                  }}
                  value={
                    jobLineItem?.discountAmount
                      ? `-${currencyPrice(
                          unitJobLineItem?.discountAmount || 0,
                          region
                        )}`
                      : null
                  }
                />
              </>
            )}

            <div className="cis-and-tax">
              {/* cis */}
              {account.isCisSubcontractor && (
                <div className="cis-input">
                  <LabelValuePair
                    label={
                      <>
                        <span>CIS</span>
                        <DropdownField
                          name="cisApplies"
                          onChange={updateFormFields}
                          options={[
                            { label: 'No', value: false },
                            {
                              label: `Yes (${account.cisDeductionRate}%)`,
                              value: true,
                            },
                          ]}
                          value={formState.cisApplies}
                          onBlur={saveAndFlush}
                        />
                      </>
                    }
                    value={`-${currencyPrice(
                      unitJobLineItem?.cisTotal || 0,
                      region
                    )}`}
                    suffixLabelWith=""
                  />
                </div>
              )}

              {/* vat */}
              <div className="tax-setting-wrapper">
                <div className="tax-setting-field-wrapper">
                  <SalesTaxSettingsField
                    styleVariant={InputStyleVariant.STANDARD}
                    taxRateId={formState?.taxRateId}
                    onChange={updateFormFields}
                  />
                  <span>
                    {currencyPrice(
                      unitJobLineItem?.vatTotal || 0,
                      account.region
                    )}
                  </span>
                </div>
              </div>
            </div>

            {/* Total */}
            <LabelValuePair
              label={`Total sales price`}
              value={currencyPrice(unitJobLineItem?.total || 0, region)}
              className="price-to-customer"
              suffixLabelWith=""
            />
          </div>
        </div>
      </>
      <JobLineItemDiscountModal
        isOpen={showDiscountModal}
        onClose={() => setShowDiscountModal(false)}
        discountPercentage={formState?.discountPercentage || null}
        discountDescription={formState?.discountDescription || null}
        saveDiscount={updateFormFields}
      />
    </ContentPanel>
  );
};

export default EditJobLineItemPrice;
