/**
 * Form template for forms which have a json template structure i.e. UnventedHotWaterCylinderCertificate
 *
 * - prefixed properties with "account." will use account data, otherwise will use form data
 * - id ends with "_date" - format date to DD/MM/YYYY
 * - accountAddressString, inspectionAddressString, landlordAddressString standard properties will be replaced with address strings
 */
import React, { FC } from 'react';
import get from 'lodash.get';
import moment from 'moment-timezone';
import CompanyLogo from '../companyLogo/CompanyLogo';
import OutlinedContentPanel, {
  ContentPanelPaddingVariant,
  ContentPanelTitleStyleVariant,
} from '../outlinedContentPanel/OutlinedContentPanel';
import LabelValuePair from '../labelValuePair/LabelValuePair';

import {
  useCertificateNumber,
  useCommonFormAccountData,
  useInspectionFormAddresses,
} from './hooks';

import { formatAnswer } from '@payaca/helpers/formHelper';

import './StandardFormTemplate.sass';

import GasSafeLogo from './gas-safe.png';

export type TemplateStructure = {
  header: {
    title: string; // form title
    logo?: string; // form logo key (normally `account.logoUrl`)
    gasSafe?: string; // shows gas safe logo - gas safe reg key in form data, leave blank to hide
  };
  rows: Row[];
};
type Row = {
  [columnName: string]: {
    data: LabelKeyData[];
    stripeRows?: boolean; // adds candy stripe to rows in the column
    columnCount?: number; // split column into multiple columns
    table?: string[]; // table column headers
  };
};
type LabelKeyData = {
  [label: string]: string;
};

interface Props {
  formTemplateStructure: TemplateStructure;
  data: any;
  account: any;
}
const StandardFormTemplate: FC<Props> = ({
  formTemplateStructure,
  data,
  account,
}) => {
  const { brandColour } = useCommonFormAccountData(account);
  const certificateNumber = useCertificateNumber(data);
  const {
    accountAddressString,
    inspectionAddressString,
    landlordAddressString,
  } = useInspectionFormAddresses(account, data);

  const getValueFromCellKey = (cellKey: string) => {
    switch (cellKey) {
      case 'accountAddressString':
        return accountAddressString;
      case 'inspectionAddressString':
        return inspectionAddressString;
      case 'landlordAddressString':
        return landlordAddressString;
      default:
        if (cellKey.startsWith('account.')) {
          return get(account, cellKey.split('account.')[1]);
        } else {
          return get(data, cellKey);
        }
    }
  };

  const getCellElement = (cell: LabelKeyData) => {
    const [label, key] = Object.entries(cell)[0];
    const value = getValueFromCellKey(key);
    switch (key) {
      case 'engineer_signature':
      case 'customer_signature':
        return <img src={value} className="form-template-signature" />;
      case 'customer_not_present':
        return <p>Customer not present</p>;
      case 'comments':
        return <p>{value || 'None'}</p>;
      default:
        if (
          value &&
          Object.prototype.toString.call(value) === '[object Object]'
        ) {
          // e.g. multi-input with checkboxes
          const list = Object.entries(value)
            .map(([label, value]) => {
              return value ? label : undefined;
            })
            .filter((l) => !!l);
          return (
            <LabelValuePair label={label} value={list.join(', ')} key={key} />
          );
        } else if (Array.isArray(value)) {
          return value.map((row: any, i: number) => {
            return (
              <tr key={`table-row-${i}`}>
                {Object.values(row).map((tableValue: any, i) => (
                  <td key={`table-value-${i}`}>{tableValue}</td>
                ))}
              </tr>
            );
          });
        } else {
          let formattedValue = formatAnswer(value);
          if (key.endsWith('_date')) {
            formattedValue = moment(value).format('DD/MM/YYYY');
          } else if (key.endsWith('_time')) {
            formattedValue = moment(value).format('HH:mm');
          }
          return (
            <LabelValuePair label={label} value={formattedValue} key={key} />
          );
        }
    }
  };

  if (!data || !account) return <></>;

  return (
    <div className={'form-template-output'}>
      {/* Header - account logo, title, cert no, gas safe reg */}
      <div className={'form-template-header'}>
        <div className={'flex-container'}>
          {formTemplateStructure.header.logo && (
            <CompanyLogo
              logoUrl={getValueFromCellKey(formTemplateStructure.header.logo)}
            />
          )}
          <h3>{formTemplateStructure.header.title}</h3>
        </div>
        <div className={'flex-container flex-center'}>
          <div className="header-details-container">
            <LabelValuePair label="Cert No" value={certificateNumber} />
          </div>
          {!!(
            formTemplateStructure.header.gasSafe &&
            getValueFromCellKey(formTemplateStructure.header.gasSafe)
          ) && (
            <img
              className="gas-safe-logo"
              src={GasSafeLogo}
              alt={'Gas Safe Logo'}
            />
          )}
        </div>
      </div>

      {/* Data rows */}
      {formTemplateStructure.rows.map((row: Row, rowIndex: number) => {
        const columns = Object.entries(row);
        return (
          <div key={rowIndex} className={'form-template-row no-page-break'}>
            {columns.map(([columnName, column], columnIndex: number) => {
              return (
                <OutlinedContentPanel
                  title={columnName}
                  titleStyleVariant={
                    ContentPanelTitleStyleVariant.BORDER_ALIGNED
                  }
                  colourOverride={brandColour}
                  paddingVariant={ContentPanelPaddingVariant.SMALL}
                  key={`row-${rowIndex}-column-${columnIndex}`}
                  className={`${column.stripeRows ? 'stripe' : ''}${
                    column.columnCount
                      ? ` column-count-${column.columnCount}`
                      : ''
                  }`}
                >
                  {column.table ? (
                    <table className="form-template-table">
                      <thead style={{ borderColor: brandColour }}>
                        <tr>
                          {column.table.map((tableHeader, i) => (
                            <td key={`table-header-${i}`}>{tableHeader}</td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {column.data.map((cell: LabelKeyData) =>
                          getCellElement(cell)
                        )}
                      </tbody>
                    </table>
                  ) : (
                    column.data.map((cell: LabelKeyData) =>
                      getCellElement(cell)
                    )
                  )}
                </OutlinedContentPanel>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default StandardFormTemplate;
