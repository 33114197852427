import React, { FunctionComponent } from 'react';

import { default as MaterialUiCheckbox } from '@material-ui/core/Checkbox';

import './LinkCheckbox.sass';
import LinkIcon from '../svgAssets/LinkIcon';
import BrokenLinkIcon from '../svgAssets/BrokenLinkIcon';

type Props = {
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: () => void;
  colour?: string;
  preventClickPropagation?: boolean;
};

const LinkCheckbox: FunctionComponent<Props> = ({
  isChecked,
  isDisabled = false,
  colour,
  onChange,
  preventClickPropagation,
}: Props): JSX.Element => {
  return (
    <div
      className={`link-checkbox ${isDisabled ? 'disabled' : ''} ${
        isChecked ? 'checked' : ''
      }`}
    >
      <div className="link-checkbox-inner">
        <MaterialUiCheckbox
          color="default"
          checked={isChecked}
          onClick={(e) => {
            onChange();
            preventClickPropagation && e.stopPropagation();
          }}
          inputProps={{ 'aria-label': 'checkbox' }}
          disabled={isDisabled}
          checkedIcon={
            <div className="checkmark-wrapper">
              <LinkIcon />
              <BrokenLinkIcon />
            </div>
          }
          size={'medium'}
          icon={
            <div className="checkmark-wrapper">
              <LinkIcon />
              <BrokenLinkIcon />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default LinkCheckbox;
