import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faTimes } from '@fortawesome/free-solid-svg-icons';

import './StarControl.sass';
import { TimeInterval } from 'd3';

interface Props {
  isActive: boolean;
  isDisabled?: boolean;
  onChange: (isActive: boolean) => void;
  isProcessing?: boolean;
}

const StarControl: FC<Props> = ({
  isActive,
  isDisabled = false,
  onChange,
  isProcessing = false,
}: Props): JSX.Element | null => {
  const [showProcessingAnimation, setShowProcessingAnimation] = useState(false);
  const [processingInterval, setProcessingInterval] = useState<any>();

  const intervalFunction = useCallback(() => {
    if (!isProcessing) {
      setShowProcessingAnimation(false);
      setProcessingInterval((interval: any) => {
        clearInterval(interval);
        return undefined;
      });
    }
  }, []);

  useEffect(() => {
    if (isProcessing) {
      setShowProcessingAnimation(true);
      setProcessingInterval(setInterval(intervalFunction, 1000));
    }
  }, [isProcessing]);

  return (
    <button
      disabled={isDisabled}
      className={`star-control ${isActive ? 'active' : ''} ${
        showProcessingAnimation ? 'processing' : ''
      }`}
      onClick={() => !isDisabled && onChange(!isActive)}
    >
      <FontAwesomeIcon icon={faStar} />
    </button>
  );
};

export default StarControl;
