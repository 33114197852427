import React, { FC, PropsWithChildren } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogProps, Drawer } from '@material-ui/core';

import './SideDrawer.sass';

type Props = {
  className?: string;
  isOpen: boolean;
  showCloseControl?: boolean;
  onClose?: () => void;
  disableBackdropClick?: boolean;
};

const SideDrawer: FC<PropsWithChildren<Props>> = ({
  className,
  isOpen,
  showCloseControl = true,
  onClose,
  children,
  disableBackdropClick = false,
}: PropsWithChildren<Props>): JSX.Element | null => {
  const onCloseInternal: DialogProps['onClose'] = (event, reason) => {
    if (!disableBackdropClick || reason !== 'backdropClick') {
      onClose?.();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      className={`side-drawer !z-sideDrawer ${className ? className : ''}`}
      onClose={onCloseInternal}
    >
      {showCloseControl && (
        <div className="close-control" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
      <div className="side-drawer-inner">
        <div className="side-drawer-content-wrapper mb-8">{children}</div>
      </div>
    </Drawer>
  );
};
export default SideDrawer;
