import { Reducer } from 'redux';

import { mergeFetchedEntities } from '../utils';

import { ActionType, State } from './tasksTypes';

export const initialState: State = {
  isPersistingTask: false,
  isDeletingTask: false,
  isUpdatingTaskCompletion: false,

  isGettingTasksForDeal: false,
  isGettingTasksForScheduledEvent: false,

  tasks: {},
};

const tasksReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_TASK: {
      const fetchedEntity = state.tasks && state.tasks[action.payload.taskId];

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.payload.taskId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_TASK_FAILURE: {
      const fetchedEntity = state.tasks && state.tasks[action.payload.taskId];

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.payload.taskId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.REQUEST_PERSIST_TASK:
      return {
        ...state,
        isPersistingTask: true,
      };
    case ActionType.PERSIST_TASK_FAILURE:
    case ActionType.PERSIST_TASK_SUCCESS:
      return {
        ...state,
        isPersistingTask: false,
      };

    case ActionType.REQUEST_DELETE_TASK:
      return {
        ...state,
        isDeletingTask: true,
      };
    case ActionType.DELETE_TASK_FAILURE:
    case ActionType.DELETE_TASK_SUCCESS: {
      const newState = { ...state };
      delete newState.tasks[action.payload.taskId];
      return {
        ...newState,
        isDeletingTask: false,
      };
    }

    case ActionType.REQUEST_UPDATE_TASK_COMPLETION:
      return {
        ...state,
        isUpdatingTaskCompletion: true,
      };
    case ActionType.UPDATE_TASK_COMPLETION_FAILURE:
    case ActionType.UPDATE_TASK_COMPLETION_SUCCESS:
      return {
        ...state,
        isUpdatingTaskCompletion: false,
      };
    case ActionType.REQUEST_GET_TASKS_FOR_DEAL:
      return {
        ...state,
        isGettingTasksForDeal: true,
      };
    case ActionType.GET_TASKS_FOR_DEAL_FAILURE:
      return {
        ...state,
        isGettingTasksForDeal: false,
      };
    case ActionType.GET_TASKS_FOR_DEAL_SUCCESS:
      return {
        ...state,
        tasks: mergeFetchedEntities({
          cache: state.tasks,
          values: action.payload,
        }),
        isGettingTasksForDeal: false,
      };
    case ActionType.REQUEST_GET_TASKS_FOR_SCHEDULED_EVENT:
      return {
        ...state,
        isGettingTasksForScheduledEvent: true,
      };
    case ActionType.GET_TASKS_FOR_SCHEDULED_EVENT_FAILURE:
      return {
        ...state,
        isGettingTasksForScheduledEvent: false,
      };
    case ActionType.GET_TASKS_FOR_SCHEDULED_EVENT_SUCCESS:
      return {
        ...state,
        tasks: mergeFetchedEntities({
          cache: state.tasks,
          values: action.payload,
        }),
        isGettingTasksForScheduledEvent: false,
      };

    case ActionType.CLEAR_TASK_REQUEST:
      return {
        ...state,
        isPersistingTask: false,
        isDeletingTask: false,
        isUpdatingTaskCompletion: false,
      };
    case ActionType.CLEAR_TASKS:
      return {
        ...state,
        tasks: {},
      };
    case ActionType.GET_TASK_SUCCESS: {
      const fetchedEntity = state.tasks && state.tasks[action.payload.taskId];

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.payload.taskId]: {
            ...fetchedEntity,
            entity: action.payload.task,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default tasksReducer;
