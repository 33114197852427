import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { actions as usersActions } from '@/api/users';
import ConfirmMakePrimaryUserModal from '@/ui/components/confirmMakePrimaryUserModal/ConfirmMakePrimaryUserModal';
import ConfirmDeleteUserModal from '@/ui/components/confirmDeleteUserModal/ConfirmDeleteUserModal';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import './ListedUsersPage.sass';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { DefaultSystemRoles } from '@payaca/permissions/default-role.config';
import { User } from '@payaca/types/userTypes';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import useGetMyAccountUsers from '@/api/queries/me/useGetMyAccountUsers';
import UserAvatar from '@payaca/components/userAvatar/UserAvatar';
import { AvatarSizeVariant } from '@payaca/components/plAvatar/Avatar';
import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import Badge from '@payaca/components/plBadge/Badge';
import { TTableRowAction } from '@payaca/components/plManageableItemsList/components/Table';
import useGetMe from '@/api/queries/me/useGetMe';

import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { AddUserModal } from '@/ui/components';
import { useHistory } from 'react-router';
import EditUserSortOrderModal from './EditUserSortOrderModal';

const UsersPage: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [makePrimaryUserId, setMakePrimaryUserId] = useState<User['id']>();
  const [deleteUserId, setDeleteUserId] = useState<User['id']>();
  const [editUserId, setEditUserId] = useState<User['id']>();
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const {
    data: accountUsers,
    refetch: refetchAccountUsers,
    isLoading,
  } = useGetMyAccountUsers();
  const { data: me, refetch: refetchMe } = useGetMe();

  const activeUsers = useMemo(() => {
    return accountUsers
      ? accountUsers.filter((u) => u.status !== 'Deactivated')
      : [];
  }, [accountUsers]);

  const anyActiveUserHasCostPerHour = useMemo(() => {
    return activeUsers?.some((u) => u.costPerHour !== null);
  }, [activeUsers]);

  const anyActiveUserHasSortOrder = useMemo(() => {
    return activeUsers?.some((u) => u.sortOrder !== null);
  }, [activeUsers]);

  const getItemActions = useCallback(
    (item: (typeof activeUsers)[0]) => {
      const actions: TTableRowAction<(typeof activeUsers)[0]>[] = [];

      if (!me?.me.permissions.includes(AccountsPermissions.UPDATE_ACCOUNT))
        return [];

      actions.push({
        label: 'Edit sort order',
        onClick: (item) => {
          setEditUserId(+item.id);
        },
      });

      if (
        !item.isPrimaryUser &&
        item.systemRole === DefaultSystemRoles.SUPER_ADMIN &&
        me?.me.user.isPrimaryUser
      ) {
        actions.push({
          label: 'Make primary user',
          onClick: (item) => {
            setMakePrimaryUserId(+item.id);
          },
        });
      }

      if (!item.isPrimaryUser && item.id !== me?.me.user.id) {
        actions.push({
          label: 'Delete',
          onClick: (item) => {
            setDeleteUserId(+item.id);
          },
        });
      }

      return actions;
    },
    [me]
  );

  return (
    <AuthenticatedPageWrapper title="Users">
      <div className="p-4 pt-0">
        <ManageableItemsList>
          <ManageableItemsList.ActionBar>
            <PermissionGuard
              renderIfHasPermissions={[AccountsPermissions.UPDATE_ACCOUNT]}
            >
              <ManageableItemsList.ActionBar.GlobalAction
                tooltipContent="Invite a new user to join your account"
                onClick={() => {
                  setShowAddUserModal(true);
                }}
              >
                Add User
              </ManageableItemsList.ActionBar.GlobalAction>
            </PermissionGuard>
          </ManageableItemsList.ActionBar>
          <ManageableItemsList.Table<(typeof activeUsers)[0]>
            isLoading={isLoading}
            items={activeUsers || []}
            uniqueKey="id"
            itemActions={getItemActions}
            onClickRow={(item) => history.push(`/users/${item.id}`)}
          >
            <ManageableItemsList.Table.Column
              header=""
              field="fullName"
              render={(_, user) => {
                return (
                  <div className="flex items-center gap-4">
                    <UserAvatar
                      sizeVariant={AvatarSizeVariant.SM}
                      user={{
                        firstName: user.firstName,
                        lastName: user.lastName,
                        emailAddress: user.email,
                        userColour: user.colour,
                        imgSrc: user.avatarUrl,
                      }}
                      enableUserInfoTooltip={true}
                    />
                    {user.fullName}
                  </div>
                );
              }}
            />
            <ManageableItemsList.Table.Column header="Email" field="email" />
            <ManageableItemsList.Table.Column
              header="Role"
              field="systemRole"
              render={(_, user) => {
                return (
                  <>
                    <div>
                      <div>
                        {user.systemRole === DefaultSystemRoles.SUPER_ADMIN ? (
                          <strong>{user.systemRole}</strong>
                        ) : (
                          user.systemRole
                        )}
                      </div>
                      {user.isPrimaryUser && (
                        <div className="text-sm text-gray-600">
                          Primary User
                        </div>
                      )}
                    </div>
                  </>
                );
              }}
            />
            {anyActiveUserHasCostPerHour && (
              <ManageableItemsList.Table.Column
                header="Cost/hr"
                field="costPerHour"
                className="text-right"
                render={(_, user) => {
                  return user.costPerHour ? (
                    <CurrencyValue
                      value={user.costPerHour.value}
                      currency={user.costPerHour.currency}
                    />
                  ) : null;
                }}
              />
            )}
            <ManageableItemsList.Table.Column
              header="Status"
              field="status"
              render={(_, user) => {
                return (
                  <Badge
                    colour={user.status === 'Active' ? 'teal' : 'yellow'}
                    variant="soft"
                  >
                    {user.status}
                  </Badge>
                );
              }}
            />
            {anyActiveUserHasSortOrder && (
              <ManageableItemsList.Table.Column
                header="Order"
                field="sortOrder"
                className="text-center"
              />
            )}
          </ManageableItemsList.Table>
        </ManageableItemsList>
      </div>
      {makePrimaryUserId && (
        <ConfirmMakePrimaryUserModal
          userId={makePrimaryUserId}
          isOpen={!!makePrimaryUserId}
          onClose={() => {
            setMakePrimaryUserId(undefined);
          }}
          onMakePrimaryUserSuccess={() => {
            refetchAccountUsers();
            refetchMe();
            setMakePrimaryUserId(undefined);
          }}
        />
      )}
      <ConfirmDeleteUserModal
        isOpen={!!deleteUserId}
        onClose={() => {
          setDeleteUserId(undefined);
        }}
        numSelected={1}
        onConfirmDelete={() => {
          setDeleteUserId(undefined);
          dispatch(
            usersActions.deleteUsersFromAccount([deleteUserId], () => {
              refetchAccountUsers();
            })
          );
        }}
      />
      <EditUserSortOrderModal
        userId={editUserId}
        isOpen={!!editUserId}
        onClose={() => setEditUserId(undefined)}
        onSuccess={() => {
          void refetchAccountUsers();
          setEditUserId(undefined);
        }}
      />
      <AddUserModal
        isOpen={showAddUserModal}
        onClose={() => setShowAddUserModal(false)}
        onAddUserSuccess={() => {
          refetchAccountUsers();
          setShowAddUserModal(false);
        }}
      />
    </AuthenticatedPageWrapper>
  );
};

export default UsersPage;
