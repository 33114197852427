import { get } from 'lodash-es';
import momentTimezone from 'moment-timezone';

export default class StringUtil {
  /**
   * search
   * @param {object} data - data array of objects i.e. jobs, customers
   * @param {string} tableConfig - predefined config property depending on the page of search i.e. quoteRef, name
   * @param {string} searchingValue - typed input value being searched
   * @return {object} - searched data results
   */
  static search(data, tableConfig, searchingValue) {
    // set to all columns in table if no specific searchBy value
    let searchBy =
      tableConfig.search.searchBy ||
      tableConfig.columns.reduce((acc, curr) => [...acc, curr.target], []);

    // find a match on any of the data properties to match on
    const searchedData = data.filter((dataItem) => {
      const match = searchBy.find((property) => {
        const val = get(dataItem, property);
        const searchReg = new RegExp(searchingValue, 'i');
        return searchReg.test(val);
      });
      return match;
    });
    return searchedData;
  }

  /**
   * list
   * @param {array} arr - array of items to list out
   * @return {string} - list of items
   */
  static list(arr) {
    return arr.reduce((acc, curr, i) => {
      if (i === 0) {
        acc = curr;
      } else if (i === arr.length - 1) {
        acc = `${acc} and ${curr}`;
      } else {
        acc = `${acc}, ${curr}`;
      }
      return acc;
    }, '');
  }

  /**
   * singularPlural
   * @param {number} length  - number being described
   * @param {string} singularString - string for singular
   * @param {string} pluralString  - string for plural
   * @param {string} singularlengthAlternative - string for an alternative to specfying 1
   */
  static singularPlural(
    length,
    singularString,
    pluralString,
    singularlengthAlternative
  ) {
    if (length === 1) {
      return `${
        singularlengthAlternative ? singularlengthAlternative : length
      } ${singularString}`;
    }
    return `${length} ${pluralString}`;
  }

  /**
   * readableDate
   * @param {string} date - string of date
   * @return {string} readable formatted date
   */
  static readableDate(date, short) {
    if (!date) {
      return null;
    }
    const momentDate = momentTimezone(date);
    return short
      ? momentDate.format('DD-MMM-YY')
      : momentDate.format('Do MMMM YYYY');
  }
}
