import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Job } from '@payaca/types/jobTypesV2';
import { JobContent } from '@payaca/types/jobContentTypes';

import { getJob, getDealByJobId, getJobContent } from '@/utils/stateAccessors';
import JobOverview from './JobOverview';

import { FileDownloadTypes } from '@payaca/types/fileDownloadTypes';
import * as dealsActions from '@payaca/store/deals/dealsActions';
import * as jobPaymentActions from '@payaca/store/jobPayments/jobPaymentsActions';
import * as customerActions from '@payaca/store/customer/customerActions';
import * as jobsActions from '@payaca/store/jobs/jobsActions';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import { Deal } from '@payaca/types/dealTypes';
import * as fileDownloadActions from '@payaca/store/fileDownload/fileDownloadActions';
import { useSelector } from '@/api/state';

type Props = {
  jobId: number;
  stickyTopClassName?: string;
};

const JobOverviewRetrievalWrapper: FunctionComponent<Props> = ({
  jobId,
  stickyTopClassName,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const job: Job | undefined = useSelector((state) => {
    return getJob(state, jobId);
  });

  const jobContent: JobContent | undefined = useSelector((state) => {
    if (!job?.jobContentId) return;
    return getJobContent(state, job.jobContentId);
  });

  const deal: Deal | undefined = useSelector((state) => {
    return getDealByJobId(state, jobId);
  });

  useEffect(() => {
    dispatch(jobsActions.requestGetJobAttachmentsForJob(jobId));
    dispatch(
      fileDownloadActions.requestDownloadFile(
        jobId,
        FileDownloadTypes.QUOTE_SIGNATURE_IMAGE
      )
    );
  }, []);

  useEffect(() => {
    if (job) {
      dispatch(dealsActions.requestGetDeal(job.dealId));
      dispatch(jobPaymentActions.requestGetJobPaymentsForDeal(job.dealId));

      dispatch(
        jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
          job.jobContentId
        )
      );
    }
  }, [job]);

  useEffect(() => {
    if (jobContent) {
      jobContent.jobLineItemIds.forEach((jobLineItemId: number) => {
        dispatch(
          jobContentActions.requestGetJobLineItemAttachmentsForJobLineItem(
            jobLineItemId
          )
        );
      });
    }
  }, [jobContent]);

  useEffect(() => {
    if (deal) {
      deal.customerId &&
        dispatch(
          customerActions.requestGetAndSetCurrentCustomer(deal.customerId)
        );
    }
  }, [deal]);

  return <JobOverview stickyTopClassName={stickyTopClassName} jobId={jobId} />;
};

export default JobOverviewRetrievalWrapper;
