import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { useFetchPollUnreadNotificationCount } from '../../../api/queries/notifications/useFetchNotifications';
import { useSelector } from '../../../api/state';
import { selectors as appSelectors } from '@/api/app';

export const NotificationContext = createContext({
  unreadNotificationCount: 0,
});

export const useNotificationContext = () => useContext(NotificationContext);

const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const loggedIn = useSelector((state: any) =>
    appSelectors.isLoggedIn(state.app)
  );
  const { data } = useFetchPollUnreadNotificationCount(loggedIn);

  const notificationContext = useMemo(() => {
    return { unreadNotificationCount: data?.me.unreadNotificationCount || 0 };
  }, [data?.me.unreadNotificationCount]);

  return (
    <NotificationContext.Provider value={notificationContext}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
