import React, { ComponentPropsWithoutRef, FC } from 'react';
import useButtonClassName, { TBtnVariantProps } from './useButtonClassName';

export interface IAnchorButtonProps
  extends ComponentPropsWithoutRef<'a'>,
    TBtnVariantProps {
  disabled?: boolean;
}

const AnchorButton: FC<IAnchorButtonProps> = (props) => {
  const {
    variant,
    size,
    colour,
    shape,
    className,
    disabled,
    children,
    ...rest
  } = props;

  const variantClassName = useButtonClassName({
    variant,
    size,
    colour,
    shape,
  });

  // Anchor Links can't be disabled, render an aria-disabled span instead!
  if (disabled) {
    return (
      <span
        className={variantClassName + (className ? ' ' + className : '')}
        aria-disabled="true"
      >
        {children}
      </span>
    );
  }

  return (
    <a
      className={variantClassName + (className ? ' ' + className : '')}
      {...rest}
    >
      {children}
    </a>
  );
};

export default AnchorButton;
