import React, { FC, useMemo } from 'react';
import DropdownField from '@payaca/components/dropdownField/DropdownField';

export enum RequiredSettingsType {
  REQUIRED = 'required',
  MULTIPLE_CHOICE = 'multiple-choice',
  OPTIONAL = 'optional',
}

export const REQUIRED_SETTINGS_OPTIONS = [
  {
    label: 'Required',
    value: RequiredSettingsType.REQUIRED,
  },
  {
    label: 'Multiple choice',
    value: RequiredSettingsType.MULTIPLE_CHOICE,
  },
  {
    label: 'Optional',
    value: RequiredSettingsType.OPTIONAL,
  },
];

export const getRequiredSettingsType = (formState: { [key: string]: any }) => {
  return formState.isOptional
    ? RequiredSettingsType.OPTIONAL
    : formState.isMultipleChoice
    ? RequiredSettingsType.MULTIPLE_CHOICE
    : RequiredSettingsType.REQUIRED;
};

type Props = {
  fieldNamePrefix?: string;
  value: {
    isMultipleChoice: boolean;
    isOptional: boolean;
    isSelected: boolean;
  };
  onChange: (value: { [key: string]: any }) => void;
};

const RequiredSettingsDropdown: FC<Props> = ({
  fieldNamePrefix,
  value,
  onChange,
}: Props): JSX.Element => {
  const requiredSettingsType = useMemo(() => {
    return getRequiredSettingsType(value);
  }, [value]);

  return (
    <DropdownField
      label="Multiple choice / Optional extra"
      name="requiredSettingsType"
      value={requiredSettingsType}
      options={REQUIRED_SETTINGS_OPTIONS}
      onChange={(value: { [key: string]: any }) => {
        const updateObject = {
          [fieldNamePrefix
            ? `${fieldNamePrefix}.isMultipleChoice`
            : 'isMultipleChoice']:
            value.requiredSettingsType === RequiredSettingsType.MULTIPLE_CHOICE,
          [fieldNamePrefix ? `${fieldNamePrefix}.isOptional` : 'isOptional']:
            value.requiredSettingsType === RequiredSettingsType.OPTIONAL,
          [fieldNamePrefix ? `${fieldNamePrefix}.isSelected` : 'isSelected']:
            value.requiredSettingsType !== RequiredSettingsType.REQUIRED,
        };

        onChange(updateObject);
      }}
    />
  );
};

export default RequiredSettingsDropdown;
