import { Optional } from '../types';

export type Maybe<T> = {
  map<U>(fn: (x: T) => U): Maybe<U>;
  flatMap<U>(fn: (x: T) => Maybe<U>): Maybe<U>;
};

export enum LoadableState {
  UNLOADED = 'unloaded',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
}

export type LoadableMatchStates<T, U> = {
  [LoadableState.UNLOADED]: (x: Optional<T>) => U;
  [LoadableState.LOADING]: (x: Optional<T>) => U;
  [LoadableState.LOADED]: (x: T) => U;
  [LoadableState.ERROR]: (err: Error, x: Optional<T>) => U;
};

export type UnloadedLoadable<T> = {
  readonly state: LoadableState.UNLOADED;
  readonly value?: T;
};

export type LoadingLoadable<T> = {
  readonly state: LoadableState.LOADING;
  readonly value?: T;
};

export type LoadedLoadable<T> = {
  readonly state: LoadableState.LOADED;
  readonly value: T;
};

export type ErrorLoadable<T> = {
  readonly state: LoadableState.ERROR;
  readonly error: Error;
  readonly value?: T;
};

export type Loadable<T> =
  | UnloadedLoadable<T>
  | LoadingLoadable<T>
  | LoadedLoadable<T>
  | ErrorLoadable<T>;
