import React, { FunctionComponent } from 'react';
import {
  faCheck,
  faExclamationTriangle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';

import './FeedbackMessage.sass';

type Props = {
  message: string;
  feedbackLevel?: FeedbackLevel;
  iconBefore?: IconDefinition;
  className?: string;
};

const FeedbackMessage: FunctionComponent<Props> = ({
  message,
  feedbackLevel,
  iconBefore,
  className,
}: Props): JSX.Element => {
  return (
    <p
      className={`flex-container feedback-message ${
        className ? className : ''
      } ${feedbackLevel ? `feedback-level-${feedbackLevel}` : ''}`}
    >
      {iconBefore && (
        <span className="icon-container">
          <FontAwesomeIcon icon={iconBefore} />
        </span>
      )}
      <span>{message}</span>
    </p>
  );
};

export const ErrorMessage: FunctionComponent<Props> = ({
  message,
  className,
}: Props): JSX.Element => {
  return (
    <FeedbackMessage
      message={message}
      feedbackLevel={FeedbackLevel.ERROR}
      iconBefore={faExclamationTriangle}
      className={className}
    />
  );
};

export const SuccessMessage: FunctionComponent<Props> = ({
  message,
}: Props): JSX.Element => {
  return (
    <FeedbackMessage
      message={message}
      feedbackLevel={FeedbackLevel.SUCCESS}
      iconBefore={faCheck}
    />
  );
};

export default FeedbackMessage;
