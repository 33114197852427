import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@payaca/components/button/Button';
import { BusinessSettingsModal } from '..';

import { actions as userActions } from '@/api/users';

import { getUpdateReminders } from '@/helpers/jobHelper';

import './PreviewUpdateReminders.sass';
import { useSelector } from '@/api/state';

const PreviewUpdateReminders: FunctionComponent = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const [showBusinessSettingsModal, setShowBusinessSettingsModal] =
    useState(false);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  const termsOfBusiness: any = useSelector((state: any) => state.users.terms);

  useEffect(() => {
    dispatch(userActions.getAccountTerms(account.id));
  }, []);

  const reminders = useMemo(
    () => getUpdateReminders(account, termsOfBusiness),
    [account, termsOfBusiness]
  );

  return reminders.length ? (
    <div className="preview-update-reminder">
      <p>Before sending this you can add:</p>
      <ul>
        {reminders.map((reminder, i) => (
          <li key={i}>{reminder}</li>
        ))}
      </ul>
      <Button onClick={() => setShowBusinessSettingsModal(true)}>
        Update now
      </Button>
      <BusinessSettingsModal
        open={showBusinessSettingsModal}
        onClose={() => setShowBusinessSettingsModal(false)}
      />
    </div>
  ) : null;
};

export default PreviewUpdateReminders;
