import { AppState } from '../index';
import {
  useSelector as vendorUseSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import {
  MaterialPurchaseIntent,
  MaterialPurchaseRecord,
  MaterialsListMaterial,
} from '@payaca/types/materialsListTypes';
import { Material, SupplierMaterial } from '@payaca/types/materialTypes';
import {
  Invoice,
  InvoiceLine,
  PaymentReconciliationRecord,
} from '@payaca/types/invoiceTypes';
import { JobPayment } from '@payaca/types/jobPaymentTypes';
import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';
import { Customer } from '@payaca/types/customerTypes';
import { Deal } from '@payaca/types/dealTypes';
import { AccountAccessInformation } from '@payaca/types/accountTypes';
import { JobContent, JobLineItem } from '@payaca/types/jobContentTypes';
import { Job } from '@payaca/types/jobTypesV2';
import { ChangeProposal } from '@payaca/types/changeProposalTypes';
import { JobLineItemAttachment } from '@payaca/types/jobTypes';
import { Nullish } from '@payaca/utilities/types';
import { isNullish } from '@payaca/utilities/guards';
import { Upload } from '@payaca/types/uploadTypes';
import { Note } from '@payaca/types/notesTypes';
import {
  HydratedServicePlan,
  PublicHydratedServicePlan,
  PublicHydratedServicePlanPeriod,
  PublicHydratedServicePlanSubscription,
  PublicServicePlanSubscription,
  ServicePlan,
} from '@payaca/types/service-plans';

const emptyArray: Array<any> = [];

export const useSelector: TypedUseSelectorHook<AppState> = vendorUseSelector;

export const getUpload = (
  state: AppState,
  uploadId: number
): Upload | undefined => {
  return state.uploads.uploads && state.uploads.uploads[uploadId]?.entity;
};

export const getUploadsByIds = (
  state: AppState,
  uploadIds: number[]
): Upload[] => {
  return uploadIds
    .map((uploadId) => getUpload(state, uploadId))
    .filter(Boolean) as Upload[];
};

const getJobContent = (state: AppState, id?: number) => {
  if (id && state.jobContent.jobContents) {
    return state.jobContent.jobContents[id]?.entity;
  }
};

const getAddOnProduct = (state: AppState, id?: string) => {
  if (id && state.subscription.addOnProducts) {
    return state.subscription.addOnProducts[id]?.entity;
  }
};

const getJob = (state: AppState, id?: number) => {
  if (id && state.jobsStore.jobs) {
    return state.jobsStore.jobs[id]?.entity;
  }
};

const getJobLineItem = (state: AppState, id?: number) => {
  if (id && state.jobContent.jobLineItems) {
    return state.jobContent.jobLineItems[id]?.entity;
  }
};

const getInvoiceLine = (state: AppState, id?: number) => {
  if (id && state.invoices.invoiceLines) {
    return state.invoices.invoiceLines[id]?.entity;
  }
};

const getChangeProposal = (state: AppState, id?: number) => {
  if (id && state.proposals.changeProposals) {
    return state.proposals.changeProposals[id]?.entity;
  }
};

const getSupplierMaterial = (state: AppState, id?: number) => {
  if (id && state.materials.supplierMaterials) {
    return state.materials.supplierMaterials[id]?.entity;
  }
};

const getSupplierMaterialBySupplierIdAndMaterialId = (
  state: AppState,
  supplierId?: number,
  materialId?: number
) => {
  if (!state.materials.supplierMaterials) return;
  return Object.values(state.materials.supplierMaterials)
    .map((x) => x.entity)
    .find((x) => x?.materialId === materialId && x?.supplierId === supplierId);
};

const getMaterial = (state: AppState, id?: number) => {
  if (id && state.materials.materials) {
    return state.materials.materials[id]?.entity;
  }
};

const getInvoice = (state: AppState, id?: number) => {
  if (id && state.invoices.invoices) {
    return state.invoices.invoices[id]?.entity;
  }
};

const getPaymentReconciliationRecord = (state: AppState, id?: number) => {
  if (id && state.invoices.paymentReconciliationRecords) {
    return state.invoices.paymentReconciliationRecords[id]?.entity;
  }
};

const getPayment = (state: AppState, id?: number) => {
  if (id && state.jobPayments.jobPayments) {
    return state.jobPayments.jobPayments[id]?.entity;
  }
};

const getMaterialsList = (state: AppState, id?: number) => {
  if (id && state.materialsLists.materialsLists) {
    return state.materialsLists.materialsLists[id]?.entity;
  }
};

const getPurchaseOrder = (state: AppState, id?: number) => {
  if (id && state.materialsLists.purchaseOrders) {
    return state.materialsLists.purchaseOrders[id]?.entity;
  }
};

const getMaterialPurchaseRecord = (state: AppState, id?: number) => {
  if (id && state.materialsLists.materialPurchaseRecords) {
    return state.materialsLists.materialPurchaseRecords[id]?.entity;
  }
};

const getMaterialPurchaseIntent = (state: AppState, id?: number) => {
  if (id && state.materialsLists.materialPurchaseIntents) {
    return state.materialsLists.materialPurchaseIntents[id]?.entity;
  }
};

const getMaterialsListMaterial = (state: AppState, id?: number) => {
  if (id && state.materialsLists.materialsListMaterials) {
    return state.materialsLists.materialsListMaterials[id]?.entity;
  }
};

const getDeal = (state: AppState, id?: number | null) => {
  if (id && state.deals.deals) {
    return state.deals.deals[id]?.entity;
  }
};

const getCustomer = (state: AppState, id?: number) => {
  if (id && state.customer.customers) {
    return state.customer.customers[id]?.entity;
  }
};

const getScheduledEvent = (state: AppState, id?: number) => {
  if (id && state.scheduledEvents.scheduledEvents) {
    return state.scheduledEvents.scheduledEvents[id]?.entity;
  }
};

const getAccountAccessInformation = (state: AppState) => {
  return state.account?.accountAccessInformation;
};

const getJobLineItemAttachments = (
  state: AppState,
  jobLineItemAttachmentIds: number[]
) => {
  const jobLineItemAttachments = jobLineItemAttachmentIds.map(
    (jobLineItemAttachmentId: number) => {
      return (
        state.jobContent.jobLineItemAttachments &&
        state.jobContent.jobLineItemAttachments[jobLineItemAttachmentId]?.entity
      );
    }
  );

  return jobLineItemAttachments.filter((x?: JobLineItemAttachment) => !!x);
};

const getNote = (state: AppState, id?: number): Note | undefined => {
  if (id && state.notes.notes) {
    return state.notes.notes[id]?.entity;
  }
};

const getNotesForNoteIds = (state: AppState, noteIds: number[]): Note[] => {
  if (!state.notes?.notes) return [];

  const notes = noteIds.map((noteId: number) => {
    return getNote(state, noteId);
  });

  return notes.filter((x?: any) => !!x) as Note[];
};

const getNotesByDealId = (state: AppState, dealId?: number) => {
  const deal = getDeal(state, dealId);
  if (!deal?.noteIds?.length) return [];

  return getNotesForNoteIds(state, deal.noteIds);
};

export const getNotesByScheduledEventId = (
  state: AppState,
  scheduledEventId?: number
) => {
  const scheduledEvent = getScheduledEvent(state, scheduledEventId);
  if (!scheduledEvent?.noteIds?.length) return [];

  return getNotesForNoteIds(state, scheduledEvent.noteIds);
};

const getServicePlan = (
  state: AppState,
  id?: string
): PublicHydratedServicePlan | undefined => {
  if (id && state.servicePlans.servicePlans) {
    return state.servicePlans.servicePlans[id]?.entity;
  }
};

const getServicePlanPeriod = (
  state: AppState,
  id?: string
): PublicHydratedServicePlanPeriod | undefined => {
  if (id && state.servicePlans.servicePlanPeriods) {
    return state.servicePlans.servicePlanPeriods[id]?.entity;
  }
};

const getServicePlanSubscription = (
  state: AppState,
  id?: string
): PublicHydratedServicePlanSubscription | undefined => {
  if (id && state.servicePlans.servicePlanSubscriptions) {
    return state.servicePlans.servicePlanSubscriptions[id]?.entity;
  }
};

export const useMaterialsList = (id?: number) => {
  return useSelector((state) => {
    return getMaterialsList(state, id);
  });
};

export const useMaterialsListMaterial = (id?: number) => {
  return useSelector((state) => {
    return getMaterialsListMaterial(state, id);
  });
};

export const useMaterialPurchaseRecord = (id?: number) => {
  return useSelector((state) => {
    return getMaterialPurchaseRecord(state, id);
  });
};

export const useMaterialPurchaseIntent = (id?: number) => {
  return useSelector((state) => {
    return getMaterialPurchaseIntent(state, id);
  });
};

export const useMaterial = (id?: number) => {
  return useSelector((state) => {
    return getMaterial(state, id);
  });
};

export const useSupplier = (id?: number) => {
  return useSelector((state) => {
    if (id && state.suppliers.suppliers) {
      return state.suppliers.suppliers[id]?.entity;
    }
  });
};

export const useMaterialPurchaseRecordsForMaterialsListMaterial = (
  materialsListMaterialId: number
) => {
  return useSelector((state) => {
    const materialsListMaterial = getMaterialsListMaterial(
      state,
      materialsListMaterialId
    );

    if (!materialsListMaterial?.materialPurchaseRecordIds?.length) return [];

    return materialsListMaterial.materialPurchaseRecordIds
      .map((id) => getMaterialPurchaseRecord(state, id))
      .filter(Boolean) as MaterialPurchaseRecord[];
  });
};

export const useMaterialPurchaseIntentsForMaterialsListMaterial = (
  materialsListMaterialId: number
) => {
  return useSelector((state) => {
    const materialsListMaterial = getMaterialsListMaterial(
      state,
      materialsListMaterialId
    );

    if (!materialsListMaterial?.materialPurchaseIntentIds?.length) return [];

    return materialsListMaterial.materialPurchaseIntentIds
      .map((id) => getMaterialPurchaseIntent(state, id))
      .filter(Boolean) as MaterialPurchaseIntent[];
  });
};

export const useMaterialsListMaterialsForMaterialsList = (
  materialsListId?: number
) => {
  return useSelector((state) => {
    const materialsList = getMaterialsList(state, materialsListId);

    if (!materialsList?.materialsListMaterialIds?.length) return [];

    return materialsList.materialsListMaterialIds
      .map((id) => getMaterialsListMaterial(state, id))
      .filter(Boolean) as MaterialsListMaterial[];
  });
};

export const useMaterialPurchaseIntentsForPurchaseOrder = (
  purchaseOrderId?: number
) => {
  return useSelector((state) => {
    const purchaseOrder = getPurchaseOrder(state, purchaseOrderId);

    if (!purchaseOrder?.materialPurchaseIntentIds?.length) return [];

    return purchaseOrder.materialPurchaseIntentIds
      .map((id) => getMaterialPurchaseIntent(state, id))
      .filter(Boolean) as MaterialPurchaseIntent[];
  });
};

export const useMaterialsListMaterialsForPurchaseOrder = (
  purchaseOrderId?: number
) => {
  return useSelector((state) => {
    const purchaseOrder = getPurchaseOrder(state, purchaseOrderId);

    if (!purchaseOrder?.materialPurchaseIntentIds?.length) return [];

    return purchaseOrder.materialPurchaseIntentIds
      .map((id) => getMaterialPurchaseIntent(state, id))
      .map((x) => getMaterialsListMaterial(state, x?.materialsListMaterialId))
      .filter(Boolean) as MaterialsListMaterial[];
  });
};

export const useMaterialsForPurchaseOrder = (purchaseOrderId?: number) => {
  return useSelector((state) => {
    const purchaseOrder = getPurchaseOrder(state, purchaseOrderId);

    if (!purchaseOrder?.materialPurchaseIntentIds?.length) return [];

    return purchaseOrder.materialPurchaseIntentIds
      .map((id) => getMaterialPurchaseIntent(state, id))
      .map((x) => getMaterialsListMaterial(state, x?.materialsListMaterialId))
      .map((x) => getMaterial(state, x?.materialId))
      .filter(Boolean) as Material[];
  });
};

export const useSupplierMaterialsForPurchaseOrder = (
  purchaseOrderId: number
) => {
  return useSelector((state) => {
    const purchaseOrder = getPurchaseOrder(state, purchaseOrderId);

    if (!purchaseOrder?.materialPurchaseIntentIds?.length) return [];

    return purchaseOrder.materialPurchaseIntentIds
      .map((id) => getMaterialPurchaseIntent(state, id))
      .map((x) => getMaterialsListMaterial(state, x?.materialsListMaterialId))
      .map((x) =>
        x
          ? getSupplierMaterialBySupplierIdAndMaterialId(
              state,
              purchaseOrder.supplierId,
              x.materialId
            )
          : undefined
      )
      .filter(Boolean) as SupplierMaterial[];
  });
};

export const useMaterialsForMaterialsList = (materialsListId: number) => {
  return useSelector((state) => {
    const materialsList = getMaterialsList(state, materialsListId);

    if (!materialsList?.materialsListMaterialIds?.length) return [];

    return materialsList.materialsListMaterialIds
      .map((id) => getMaterialsListMaterial(state, id))
      .map((materialsListMaterial) =>
        getMaterial(state, materialsListMaterial?.materialId)
      )
      .filter(Boolean) as Material[];
  });
};

export const useMaterials = (materialIds: number[]): Material[] => {
  return useSelector((state) => {
    return materialIds
      .map((id) => getMaterial(state, id))
      .filter(Boolean) as Material[];
  });
};

export const usePurchaseOrder = (id?: number) => {
  return useSelector((state) => {
    return getPurchaseOrder(state, id);
  });
};

export const useSupplierMaterialForSupplierAndMaterial = (
  supplierId?: number,
  materialId?: number
) => {
  return useSelector((state) => {
    return getSupplierMaterialBySupplierIdAndMaterialId(
      state,
      supplierId,
      materialId
    );
  });
};

export const useDeal = (id?: number | null): Deal | undefined => {
  return useSelector((state) => getDeal(state, id));
};

export const useCustomer = (id?: number): Customer | undefined => {
  return useSelector((state) => getCustomer(state, id));
};

export const useCustomerForDeal = (id?: number): Customer | undefined => {
  return useSelector((state) => {
    const deal = getDeal(state, id);
    return getCustomer(state, deal?.customerId);
  });
};

export const useScheduledEvent = (id?: number): ScheduledEvent | undefined => {
  return useSelector((state) => getScheduledEvent(state, id));
};

export const useAccountAccessInformation = ():
  | AccountAccessInformation
  | null
  | undefined => {
  return useSelector((state) => getAccountAccessInformation(state));
};

export const useChangeProposal = (id?: number) => {
  return useSelector((state) => {
    return getChangeProposal(state, id);
  });
};

export const useInvoice = (id?: number) => {
  return useSelector((state: any) => {
    return getInvoice(state, id);
  });
};

export const usePayment = (id?: number) => {
  return useSelector((state: any) => {
    return getPayment(state, id);
  });
};

export const usePaymentReconciliationRecordsForInvoice = (
  invoiceId?: number
) => {
  return useSelector((state) => {
    const invoice = getInvoice(state, invoiceId);

    if (!invoice?.paymentReconciliationRecordIds?.length) return [];

    return invoice.paymentReconciliationRecordIds
      .map((id) => getPaymentReconciliationRecord(state, id))
      .filter(Boolean) as PaymentReconciliationRecord[];
  });
};

export const usePaymentsForInvoice = (invoiceId?: number) => {
  return useSelector((state) => {
    const invoice = getInvoice(state, invoiceId);

    if (!invoice?.paymentReconciliationRecordIds?.length) return [];

    return invoice.paymentReconciliationRecordIds
      .map((id) => getPaymentReconciliationRecord(state, id))
      .filter((x) => !!x?.jobPaymentId)
      .map((x) => getPayment(state, x?.jobPaymentId))
      .filter(Boolean) as JobPayment[];
  });
};

export const usePaymentsForDeal = (dealId?: number) => {
  return useSelector((state) => {
    const deal = getDeal(state, dealId);

    if (!deal?.jobPaymentIds?.length) return [];

    return deal.jobPaymentIds
      .map((x) => getPayment(state, x))
      .filter(Boolean) as JobPayment[];
  });
};

export const useInvoicesForDeal = (dealId?: number) => {
  return useSelector((state) => {
    const deal = getDeal(state, dealId);

    if (!deal?.invoiceIds?.length) return [];

    return deal.invoiceIds
      .map((x) => getInvoice(state, x))
      .filter(Boolean) as Invoice[];
  });
};

export const useProposalsForDeal = (dealId?: number) => {
  return useSelector((state) => {
    const deal = getDeal(state, dealId);

    if (!deal?.propositionIds?.length) return [];

    return deal.propositionIds
      .map((x) => getJob(state, x))
      .filter(Boolean) as Job[];
  });
};

export const useJobContentsForDeal = (dealId?: number) => {
  return useSelector((state) => {
    const deal = getDeal(state, dealId);

    if (!deal?.jobContentIds?.length) return [];

    return deal.jobContentIds
      .map((x) => getJobContent(state, x))
      .filter(Boolean) as JobContent[];
  });
};

export const useChangeProposalsForDeal = (dealId?: number) => {
  return useSelector((state) => {
    const deal = getDeal(state, dealId);

    if (!deal?.changeProposalIds?.length) return [];

    return deal.changeProposalIds
      .map((x) => getChangeProposal(state, x))
      .filter(Boolean) as ChangeProposal[];
  });
};

export const usePaymentReconciliationRecordsForDeal = (dealId?: number) => {
  return useSelector((state) => {
    const deal = getDeal(state, dealId);

    if (!deal?.paymentReconciliationRecordIds?.length) return [];

    return deal.paymentReconciliationRecordIds
      .map((x) => getPaymentReconciliationRecord(state, x))
      .filter(Boolean) as PaymentReconciliationRecord[];
  });
};

export const useJobLineItemsForDeal = (dealId?: number) => {
  return useSelector((state) => {
    const deal = getDeal(state, dealId);

    if (!deal?.jobLineItemIds?.length) return [];

    return deal.jobLineItemIds
      .map((x) => getJobLineItem(state, x))
      .filter(Boolean) as JobLineItem[];
  });
};

export const useJobLineItemsForChangeProposal = (changeProposalId?: number) => {
  return useSelector((state) => {
    const changeProposal = getChangeProposal(state, changeProposalId);

    if (!changeProposal?.jobLineItemIds?.length) return [];

    return changeProposal.jobLineItemIds
      .map((x) => getJobLineItem(state, x))
      .filter(Boolean) as JobLineItem[];
  });
};

export const useJobLineItemsForJob = (jobId?: number) => {
  return useSelector((state) => {
    const job = getJob(state, jobId);
    const jobContent = getJobContent(state, job?.jobContentId);

    if (!jobContent?.jobLineItemIds?.length) return [];

    return jobContent.jobLineItemIds
      .map((x) => getJobLineItem(state, x))
      .filter(Boolean) as JobLineItem[];
  });
};

export const useAcceptedJobLineItemsForDeal = (dealId?: number) => {
  return useSelector((state) => {
    return getAcceptedJobLineItemsForDeal(state, dealId);
  });
};

export const getAcceptedJobLineItemsForDeal = (state: any, dealId?: number) => {
  const deal = getDeal(state, dealId);

  if (!deal?.acceptedJobLineItemIds?.length) return [];

  return deal.acceptedJobLineItemIds
    .map((x) => getJobLineItem(state, x))
    .filter(Boolean) as JobLineItem[];
};

export const useJob = (id?: number) => {
  return useSelector((state: any) => {
    return getJob(state, id);
  });
};

// This is synonymous with useJob in terms of behaviour
export const useProposal = (id?: number) => {
  return useSelector((state: any) => {
    return getJob(state, id);
  });
};

export const useJobContent = (id?: number) => {
  return useSelector((state: any) => {
    return getJobContent(state, id);
  });
};

export const useJobLineItem = (id?: number) => {
  return useSelector((state: any) => {
    return getJobLineItem(state, id);
  });
};

export const useAcceptedJobContentForDeal = (id?: number) => {
  return useSelector((state: any) => {
    const deal = getDeal(state, id);

    if (!deal?.propositionIds?.length) return;

    const proposals = deal.propositionIds
      .map((x) => getJob(state, x))
      .filter(Boolean) as Job[];

    const acceptedProposal = proposals.find(
      (x) => x.acceptedAt && !x.inactivatedAt
    );

    return getJobContent(state, acceptedProposal?.jobContentId);
  });
};

export const useJobLineItemAttachments = (jobLineItem?: JobLineItem) => {
  return useSelector((state) =>
    getJobLineItemAttachments(
      state,
      jobLineItem?.jobLineItemAttachmentIds || emptyArray
    )
  );
};

export const useIsFetchingInvoice = (id?: number) => {
  return useSelector((state) => {
    return (
      id && state.invoices.invoices && state.invoices.invoices[id]?.isFetching
    );
  });
};

export const useIsUpdatingInvoice = () => {
  return useSelector((state) => {
    return state.invoices.isUpdatingInvoice;
  });
};

export const useTaxRate = (taxRateId?: Nullish<number>) => {
  return useSelector((state) => {
    return isNullish(taxRateId) ? null : state.taxRates.store[taxRateId];
  });
};

export const useDefaultTaxRate = () =>
  useSelector((state) =>
    Object.values(state.taxRates.store).find((x) => x.isDefault)
  );

export const useInvoiceLinesForDeal = (dealId?: number): InvoiceLine[] => {
  return useSelector((state) => {
    const deal = getDeal(state, dealId);

    if (!deal?.invoiceLineIds?.length) return [];

    return deal.invoiceLineIds
      .map((id) => getInvoiceLine(state, id))
      .filter(Boolean) as InvoiceLine[];
  });
};

export const useAcceptedAndDraftJobLineItemAndAmendments = (
  jobLineItemId: number,
  changeProposalId: number
) => {
  return useSelector((state) => {
    let dealId;
    const jobLineItem = getJobLineItem(state, jobLineItemId);

    if (jobLineItem?.changeProposalId) {
      // v2
      dealId = getChangeProposal(state, jobLineItem.changeProposalId)?.dealId;
    } else {
      // v1
      dealId = getJobContent(state, jobLineItem?.jobContentId)?.dealId;
    }
    const acceptedJobLineItems = getAcceptedJobLineItemsForDeal(state, dealId);
    return Object.values(state.jobContent.jobLineItems)
      .map((x) => x.entity)
      .filter(
        (x) =>
          !!x &&
          (acceptedJobLineItems.includes(x) ||
            x.changeProposalId === changeProposalId) && // filter out voided joblineitems from voided change proposals, but keep ones on current cp
          (x.id === jobLineItemId || x.amendmentParentId === jobLineItemId)
      ) as JobLineItem[];
  });
};

export const usePipelines = () =>
  useSelector((state) => state.pipelines?.pipelines ?? emptyArray);

export const useDealPipeline = (dealId?: number) =>
  useSelector((state) => {
    const deal = getDeal(state, dealId);
    return state.pipelines?.pipelines?.find((p) => p.id === deal?.pipelineId);
  });

export const usePipeline = (pipelineId?: number) =>
  useSelector((state) => {
    return state.pipelines?.pipelines?.find((p) => p.id === pipelineId);
  });

export const useUploads = (uploadIds?: number[]) =>
  useSelector((state) => {
    return getUploadsByIds(state, uploadIds || emptyArray);
  });

export const useAccountSubscription = () =>
  useSelector((state) => state.subscription?.accountSubscription);

export const useNotesForDeal = (dealId?: number): Note[] =>
  useSelector((state) => getNotesByDealId(state, dealId));

export const useNote = (noteId?: number) =>
  useSelector((state) => getNote(state, noteId));

export const useServicePlan = (servicePlanPublicId?: string) =>
  useSelector((state) => getServicePlan(state, servicePlanPublicId));

export const useServicePlanPeriod = (servicePlanPeriodPublicId?: string) =>
  useSelector((state) =>
    getServicePlanPeriod(state, servicePlanPeriodPublicId)
  );

export const useSubscriptionsForServicePlan = (servicePlanPublicId: string) =>
  useSelector((state) => {
    if (!state.servicePlans?.servicePlanSubscriptions) return [];
    return Object.values(state.servicePlans.servicePlanSubscriptions)

      .map((x) => x.entity)
      .filter((x) => {
        return x?.servicePlanPublicId === servicePlanPublicId;
      }) as PublicHydratedServicePlanSubscription[];
  });

export const useSubscriptionsForCustomer = (customerId: number) =>
  useSelector((state) => {
    if (!state.servicePlans?.servicePlanSubscriptions) return [];
    return Object.values(state.servicePlans.servicePlanSubscriptions)

      .map((x) => x.entity)
      .filter((x) => {
        return x?.customerId == customerId;
      }) as PublicHydratedServicePlanSubscription[];
  });

export const useServicePlanSubscription = (
  servicePlanSubscriptionPublicId?: string
) =>
  useSelector((state) =>
    getServicePlanSubscription(state, servicePlanSubscriptionPublicId)
  );

export const useAddOnProduct = (addOnProductPublicId?: string) =>
  useSelector((state) => getAddOnProduct(state, addOnProductPublicId));

export const useNotesForScheduledEvent = (scheduledEventId?: number): Note[] =>
  useSelector((state) => getNotesByScheduledEventId(state, scheduledEventId));

export const useListedFilesForDeal = (dealId: number) => ({
  listedFiles: useSelector((state) => state.listedFiles.deals[dealId]),
  isGettingDealFiles: useSelector(
    (state) => state.listedFiles.isGettingListedFilesForDeal
  ),
  isSharingFilesForDeal: useSelector(
    (state) => state.listedFiles.isSharingFilesForDeal
  ),
  isUnsharingFilesForDeal: useSelector(
    (state) => state.listedFiles.isUnsharingFilesForDeal
  ),
});
