import React, { FC, useMemo } from 'react';
import moment from 'moment-timezone';

import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';

import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import AssignedUsersIndicator from '@payaca/components/assignedUsersIndicator/AssignedUsersIndicator';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';

import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';

import { useAccountUsers } from '../../../utils/storeHooks';

import './ScheduledEventChip.sass';
import { User } from '@payaca/types/userTypes';
import { getAddressAsString } from '@payaca/helpers/locationHelper';

type Props = {
  scheduledEvent: ScheduledEvent;
  onClick?: () => void;
  showLocation?: boolean;
};
const ScheduledEventChip: FC<Props> = ({
  scheduledEvent,
  onClick,
  showLocation = false,
}: Props): JSX.Element => {
  const startTime = useMemo(
    () => moment(scheduledEvent.beginAt).format('HH:mm A'),
    [scheduledEvent]
  );
  const startDate = useMemo(
    () => moment(scheduledEvent.beginAt).format("D MMM 'YY"),
    [scheduledEvent]
  );

  const accountUsers = useAccountUsers();

  const assignedUsers = useMemo(() => {
    if (!scheduledEvent?.userAssignments) return [];
    return scheduledEvent.userAssignments
      .map((userId) => {
        return accountUsers.find((x) => x.id === userId);
      })
      .filter((x) => !!x) as User[];
  }, [scheduledEvent, accountUsers]);

  const addressAsString = useMemo(() => {
    return scheduledEvent.location?.address
      ? getAddressAsString(scheduledEvent.location?.address)
      : '';
  }, [scheduledEvent]);

  return (
    <ResponsiveViewWrapper
      className="scheduled-event-chip"
      downBreakpointSm={700}
      downBreakpointXs={350}
    >
      <div
        className={`scheduled-event-chip-inner ${onClick ? 'clickable' : ''}`}
        onClick={onClick}
      >
        <div className="scheduled-event-details-container">
          {/* Currently event type is meaningless until we implement. Commented out until then. */}
          {/* <div className={`type-indicator ${scheduledEvent.type}-type`}></div> */}
          <span className="start-time md">{startTime}</span>
          <span className="event-name">
            {scheduledEvent.name}
            {showLocation ? <small>{addressAsString}</small> : ''}
          </span>
          {!!assignedUsers.length && (
            <PermissionGuard
              renderIfHasPermissions={[AccountsPermissions.GET_USERS]}
            >
              <div className="md user-indicator-container">
                <AssignedUsersIndicator
                  size="sm"
                  assignedUsers={assignedUsers.map((x) => ({
                    firstName: x.firstname,
                    lastName: x.lastname,
                    emailAddress: x.email,
                    userColour: x.userColour,
                    imgSrc: x.avatarUrl,
                  }))}
                />
              </div>
            </PermissionGuard>
          )}
          <div className="start-date-time-container">
            <span className="start-date md">{startDate}</span>
            <div className="start-date-time xs">
              <span className="start-time">{startTime}</span>
              <span className="start-date">{startDate}</span>
            </div>
          </div>
        </div>
      </div>
    </ResponsiveViewWrapper>
  );
};

export default ScheduledEventChip;
