import React from 'react';

import './Loader.sass';

const Loader = () => {
  return (
    <img
      className="loader"
      src="https://storage.googleapis.com/payaca-prod-assets/payaca-loader.gif"
      alt="payaca loading icon"
    />
  );
};

export default Loader;
