import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import SearchField from '../searchField/SearchField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';

import { GetListedMaterialsRequestData } from '@payaca/store/listedMaterials/listedMaterialsTypes';
import {
  requestGetMaterialCategories,
  requestGetMaterialSuppliers,
} from '@payaca/store/materials/materialsActions';

import { ManagedBy } from '@payaca/types/listedMaterialTypes';
import { MaterialCategory } from '@payaca/types/materialTypes';
import { FilterOption } from '@payaca/types/filterTypes';

import './ListedMaterialsSearchFilterControl.sass';
import { useSelector } from '@/api/state';
import { capitaliseFirstLetter } from '@payaca/utilities/stringUtilities';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';

type Props = {
  getListedMaterialsRequestData?: GetListedMaterialsRequestData;
  onChange: (value: { [key: string]: any }) => void;
  initSearchTerm?: string;
  condensedLabels?: boolean;
};

const ListedMaterialsSearchFilterControl: FunctionComponent<Props> = ({
  getListedMaterialsRequestData,
  onChange,
  initSearchTerm,
  condensedLabels = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestGetMaterialCategories());
    dispatch(requestGetMaterialSuppliers());
  }, []);

  const materialCategories = useSelector((state) => {
    return state.materials.materialCategories || [];
  });
  const materialSuppliers = useSelector((state) => {
    return state.materials.materialSuppliers || [];
  });

  const materialCategoryOptions = useMemo(() => {
    const options = materialCategories.map((x: MaterialCategory) => {
      return {
        label: x.label,
        value: x.id,
      };
    });
    return [...options, { label: 'Uncategorised', value: -1 }];
  }, [materialCategories]);

  const materialSuppliersOptions = useMemo(
    () =>
      materialSuppliers.map((x: FilterOption) => {
        return {
          label: x.label,
          value: x.id,
        };
      }),
    [materialSuppliers]
  );

  const emptyTextLabel = (type: string) =>
    condensedLabels ? `${capitaliseFirstLetter(type)}` : `Filter by ${type}`;

  return (
    <div className="listed-materials-search-filter-control">
      <div className="search-field">
        <SearchField
          onSearchTermChange={(searchTerm) => onChange({ searchTerm })}
          placeholder="Search materials"
          initSearchTerm={initSearchTerm}
        />
      </div>
      <div className="categories-filter filter">
        <DropdownField
          options={materialCategoryOptions}
          multiple={true}
          displayCheckboxes={true}
          value={getListedMaterialsRequestData?.categoryIds || []}
          name="categoryIds"
          onChange={onChange}
          emptyText={emptyTextLabel('category')}
          minToShorten={1}
        />
      </div>
      <div className="suppliers-filter filter">
        <DropdownField
          options={materialSuppliersOptions}
          multiple={true}
          displayCheckboxes={true}
          value={getListedMaterialsRequestData?.supplierIds || []}
          name="supplierIds"
          onChange={onChange}
          emptyText={emptyTextLabel('Suppliers')}
          minToShorten={1}
        />
      </div>
      <PermissionGuard
        renderIfHasPermissions={[
          MaterialsPermissions.GET_SYSTEM_MANAGED_MATERIALS,
        ]}
      >
        <div className="managed-by-filter filter">
          <DropdownField
            options={[
              {
                label: 'System',
                value: ManagedBy.SYSTEM,
              },
              {
                label: 'My Materials',
                value: ManagedBy.SELF,
              },
            ]}
            multiple={true}
            displayCheckboxes={true}
            value={getListedMaterialsRequestData?.managedBy || []}
            name="managedBy"
            onChange={onChange}
            emptyText={emptyTextLabel('source')}
          />
        </div>
      </PermissionGuard>
    </div>
  );
};

export default ListedMaterialsSearchFilterControl;
