import { AccountRegions } from '@payaca/types/accountTypes';
import { action } from 'typesafe-actions';

import { ActionType } from './validationTypes';
import { LoqateAddressResult } from '@payaca/types/addressTypes';

export const requestValidateEmail = (
  email: string,
  callback: (error: any, isValid: boolean) => void
) => {
  return action(ActionType.REQUEST_VALIDATE_EMAIL, {
    email,
    callback,
  });
};
export const validateEmailSuccess = () => {
  return action(ActionType.VALIDATE_EMAIL_SUCCESS);
};
export const validateEmailFailure = (error: Error) => {
  return action(ActionType.VALIDATE_EMAIL_FAILURE, null, null, error);
};

export const requestValidatePhone = (
  phone: string,
  callback: (error: any, isValid: boolean) => void
) => {
  return action(ActionType.REQUEST_VALIDATE_PHONE, {
    phone,
    callback,
  });
};
export const validatePhoneSuccess = () => {
  return action(ActionType.VALIDATE_PHONE_SUCCESS);
};
export const validatePhoneFailure = (error: Error) => {
  return action(ActionType.VALIDATE_PHONE_FAILURE, null, null, error);
};

export const requestLookupAddressBySearchTerm = (
  searchTerm: string,
  containerId: string,
  region: AccountRegions,
  callback: (error: any, isValid: boolean) => void
) => {
  return action(ActionType.REQUEST_LOOKUP_ADDRESS_BY_SEARCH_TERM, {
    searchTerm,
    containerId,
    region,
    callback,
  });
};
export const lookupAddressBySearchTermSuccess = (
  addressResults: LoqateAddressResult[]
) => {
  return action(ActionType.LOOKUP_ADDRESS_BY_SEARCH_TERM_SUCCESS, {
    addressResults,
  });
};
export const lookupAddressBySearchTermFailure = (error: Error) => {
  return action(
    ActionType.LOOKUP_ADDRESS_BY_SEARCH_TERM_FAILURE,
    null,
    null,
    error
  );
};

export const requestRetrieveAddress = (
  addressId: string,
  callback: (error: any, resp: any) => void
) => {
  return action(ActionType.REQUEST_RETRIEVE_ADDRESS, {
    addressId,
    callback,
  });
};
export const retrieveAddressSuccess = () => {
  return action(ActionType.RETRIEVE_ADDRESS_SUCCESS);
};
export const retrieveAddressFailure = (error: Error) => {
  return action(ActionType.RETRIEVE_ADDRESS_FAILURE, null, null, error);
};

export const requestGetBlockedEmails = (
  callback?: (error: any, blockedEmails: any[]) => void
) => {
  return action(ActionType.REQUEST_GET_BLOCKED_EMAILS, {
    callback,
  });
};
export const getBlockedEmailsSuccess = (blockedEmails: any[]) => {
  return action(ActionType.GET_BLOCKED_EMAILS_SUCCESS, { blockedEmails });
};
export const getBlockedEmailsFailure = (error: Error) => {
  return action(ActionType.GET_BLOCKED_EMAILS_FAILURE, null, null, error);
};
