export enum InvoicesPermissions {
  GET_INVOICES = 'invoices.getInvoices',
  GET_LISTED_INVOICES = 'invoices.getListedInvoices',
  GET_ASSIGNED_INVOICES = 'invoices.getAssignedInvoices',
  GET_PAYMENT_RECONCILIATION_RECORDS = 'invoices.getPaymentReconciliationRecords',
  CREATE_INVOICE = 'invoices.createInvoice',
  EDIT_INVOICE = 'invoices.editInvoice',
  SEND_INVOICE = 'invoices.sendInvoice',
  VOID_INVOICE = 'invoices.voidInvoice',
}
