export enum AutomationsPermissions {
  GET_LISTED_AUTOMATIONS = 'automations.getListedAutomations',
  GET_AUTOMATION = 'automations.getAutomation',
  GET_DEFAULT_AUTOMATIONS = 'automations.getDefaultAutomations',
  MANAGE_DEFAULT_AUTOMATION = 'automations.manageDefaultAutomation',

  ADD_AUTOMATION = 'automations.addAutomation', // adding full automation
  ADD_SIMPLE_AUTOMATION = 'automations.addSimpleAutomation', // adding simple (templated) automation
  EDIT_AUTOMATION = 'automations.editAutomation', // editing full automation
  EDIT_SIMPLE_AUTOMATION = 'automations.editSimpleAutomation', // editing simple (templated) automation
  DELETE_AUTOMATION = 'automations.deleteAutomation',
}
