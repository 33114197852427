import { GraphQLClient } from 'graphql-request';
import { getNewWorldTokenLocalStorageKey } from '@/helpers/localStorageKeyHelper';
import { TOKEN_KEY } from '@/api/index';

type AuthHeaderProps = {
  authorization?: string;
};

export const gqlClient = new GraphQLClient(import.meta.env.VITE_GRAPHQL_HOST, {
  headers: () => {
    const authHeaders = {} as AuthHeaderProps;

    const token =
      // New world graphql token
      localStorage.getItem(getNewWorldTokenLocalStorageKey()) ||
      // Old world Nest token
      localStorage.getItem(TOKEN_KEY);

    if (token) {
      authHeaders['authorization'] = `Bearer ${token}`;
    }

    return {
      'Content-Type': 'application/json',
      ...authHeaders,
    };
  },
});
