import { FC, useEffect, useState } from 'react';
import Button from '@payaca/components/plButton/Button';
import Modal, { IProps as ModalProps } from '@payaca/components/plModal/Modal';

type Props = {
  onConfirmDeleteTask: () => void;
  taskHasDocument?: boolean;
} & Pick<ModalProps, 'isOpen' | 'onClose'>;

const ConfirmDeleteTaskModal: FC<Props> = (props) => {
  const { isOpen, onClose, onConfirmDeleteTask, taskHasDocument } = props;
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setIsProcessing(false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Delete Task">
      <Modal.Body>
        <p>
          Are you sure you want to delete this Task?
          {taskHasDocument && ' Your attached Form will also be deleted.'}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            isProcessing={isProcessing}
            onClick={() => {
              !isProcessing && onConfirmDeleteTask();
              setIsProcessing(true);
            }}
          >
            Delete
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteTaskModal;
