import React, { FC, useMemo } from 'react';
import moment from 'moment-timezone';

import { CustomerContact } from '@payaca/types/customerTypes';

type Props = {
  jobType?: string;
  jobAcceptedAt: Date;
  jobContact: CustomerContact;
  acceptedSignatureImageData?: string;
};

const DocumentAcceptedSignature: FC<Props> = ({
  jobType = '',
  jobAcceptedAt,
  jobContact,
  acceptedSignatureImageData,
}) => {
  const formattedAcceptedAtDate = useMemo(() => {
    return moment(jobAcceptedAt).format('Do MMMM YYYY [at] HH:mm');
  }, [jobAcceptedAt]);

  return (
    <div>
      <h4 className="font-lg mb-4">
        {jobType ? `${jobType} accepted` : 'Accepted'}
      </h4>

      {acceptedSignatureImageData && (
        <div className="border-pc-blue-lighter mb-2 rounded-lg border-2 p-3">
          <img
            className="h-auto max-w-full"
            src={acceptedSignatureImageData}
            alt={`${jobContact?.name}'s signature`}
          />
        </div>
      )}
      <p className="text-base">
        {acceptedSignatureImageData ? (
          <>
            Signed by <b>{jobContact?.name}</b> on{' '}
            <b>{formattedAcceptedAtDate}</b>
          </>
        ) : (
          `Accepted on ${formattedAcceptedAtDate}`
        )}
      </p>
    </div>
  );
};

export default DocumentAcceptedSignature;
