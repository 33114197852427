import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import FileUploadPersistRemoveControl from '@payaca/components/fileUploadPersistRemoveControl/FileUploadPersistRemoveControl';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import ScalingContentWrapper from '@payaca/components/scalingContentWrapper/ScalingContentWrapper';
import {
  getAcceptedFileTypes,
  ImageFileExtensions,
} from '@payaca/helpers/fileHelper';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import { useSelector } from '@/api/state';
import { actions as usersActions } from '@/api/users';
import { useFormState } from '@/hooks/useFormState';
import { useAccount } from '@/utils/storeHooks';

import ColourPickerField from '../colourPickerField/ColourPickerField';
import ExampleProposal from '../exampleProposal/ExampleProposal';
import { EntityUploadCollectionControl } from '../entityUploadCollectionControl/EntityUploadCollectionControl';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Button from '@payaca/components/plButton/Button';
import useGetMyAccountBranding from '../../../api/queries/me/useGetMyAccountBranding';
import CompanySettingsBrandingSubdomainSection from './CompanySettingsBrandingSubdomainSection';
import Card from '@payaca/components/plCard/Card';
import { Account } from '@payaca/types/accountTypes';
import { MyAccountBrandingQuery } from '@/gql/graphql';
import Loader from '@payaca/components/loader/Loader';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';

const marketingAcceptFileTypes = getAcceptedFileTypes(['document', 'image']);

const accreditationAcceptFileTypes = getAcceptedFileTypes(['image']);

type Props = {
  readOnly: boolean;
};

type FormState = {
  brandColour: string | null;
  logoUrl: string | null;
};

type FormValidationResult = Partial<
  Record<keyof FormState, FieldValidationResult>
>;

const CompanySettingsBranding: FC<Props> = ({ readOnly }: Props) => {
  const dispatch = useDispatch();

  const businessAttachments = useSelector(
    (state: any) => state.users.businessAttachments
  );
  const { data: accountBranding } = useGetMyAccountBranding();

  const account = useAccount();

  useEffect(() => {
    dispatch(usersActions.getBusinessAttachments());
  }, []);

  if (account && accountBranding) {
    return (
      <CompanySettingsBrandingBody
        account={account}
        accountBranding={accountBranding}
        businessAttachments={businessAttachments}
        readOnly={readOnly}
      />
    );
  }

  return (
    <div className="flex w-full flex-row  items-center justify-center">
      <MiniLoader />
    </div>
  );
};

const CompanySettingsBrandingBody: FC<{
  account: Account;
  accountBranding: MyAccountBrandingQuery['me']['user']['account']['branding'];
  businessAttachments: any;
  readOnly: boolean;
}> = ({
  account,
  accountBranding,
  businessAttachments,
  readOnly,
}): JSX.Element => {
  const dispatch = useDispatch();

  const onPersistBusinessAttachment = useCallback(
    (file: File) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(
          usersActions.manageBusinessAttachments(
            [{ file, fileName: file.name }],
            [],
            () => {
              dispatch(usersActions.getBusinessAttachments());
              resolve();
            }
          )
        );
      });
    },
    [dispatch]
  );

  const onRemoveBusinessAttachment = useCallback(
    (fileIdentifier: string) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(
          usersActions.manageBusinessAttachments(
            [],
            [{ id: fileIdentifier }],
            () => {
              dispatch(usersActions.getBusinessAttachments());
              resolve();
            }
          )
        );
      });
    },
    [dispatch]
  );

  const initialState: FormState | null = useMemo(
    () => ({
      brandColour: accountBranding?.theme?.primaryColour || null,
      logoUrl: accountBranding?.logoUrl || null,
    }),
    [accountBranding, account]
  );

  const persistedBusinessAttachments = useMemo(() => {
    return businessAttachments?.length
      ? businessAttachments.map((businessAttachment: any) => ({
          fileName: businessAttachment.fileName,
          identifier: businessAttachment.id,
        }))
      : [];
  }, [businessAttachments]);

  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const clearDynamicFeedbackMessage = useRef<() => void>();

  const saveFormData = useMemo(
    () => async (state: FormState) => {
      clearDynamicFeedbackMessage.current?.();
      return new Promise<void>((resolve, reject) => {
        dispatch(
          usersActions.updateBusinessAccount(
            account.id,
            state,
            (err: unknown, response: any) => {
              if (err) {
                clearDynamicFeedbackMessage.current =
                  showDynamicFeedbackMessage({
                    title: `We couldn't save your changes`,
                    isCancellable: true,
                    feedbackLevel: FeedbackLevel.ERROR,
                  });
                reject(err);
              } else {
                clearDynamicFeedbackMessage.current =
                  showDynamicFeedbackMessage({
                    title: 'Your changes have been saved',
                    lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
                    feedbackLevel: FeedbackLevel.SUCCESS,
                  });
                resolve();
              }
            }
          )
        );
      });
    },
    []
  );

  const { formState, updateFormFields } = useFormState<
    FormState,
    FormValidationResult
  >(
    initialState,
    {},
    {
      autoSaveFn: saveFormData,
    }
  );

  // this ends up calling xxx on legacy .js account saga
  const onPersistLogo = useCallback(
    (file: File) => {
      return new Promise<void>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e: any) => {
          dispatch(
            usersActions.uploadAccountLogo(
              account.id,
              {
                uri: reader.result,
                file: file,
              },
              (_: any, { logoUrl }: { logoUrl: string }) => {
                updateFormFields({
                  logoUrl: logoUrl,
                });
                resolve();
              }
            )
          );
        };
      });
    },
    [dispatch]
  );

  const onRemoveLogo = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        usersActions.uploadAccountLogo(account.id, undefined, () => {
          updateFormFields({
            logoUrl: null,
          });
          resolve();
        })
      );
    });
  }, [dispatch, updateFormFields]);

  return (
    <div className="company-settings-branding flex flex-col gap-y-4">
      <CompanySettingsBrandingSubdomainSection readOnly={readOnly} />

      <Card>
        <Card.Body>
          {/* Logo */}
          <h2>Logo</h2>
          <div className="logo-upload-wrapper">
            {formState.logoUrl && <img src={formState.logoUrl} />}
            {!formState.logoUrl && (
              <FileUploadPersistRemoveControl
                acceptFileTypes={ImageFileExtensions}
                persistedFiles={
                  formState.logoUrl
                    ? [
                        {
                          fileName: 'logo',
                          identifier: formState.logoUrl,
                          thumbnailUrl: formState.logoUrl,
                        },
                      ]
                    : []
                }
                persistFile={onPersistLogo}
                removePersistedFile={onRemoveLogo}
                enableDragAndDrop={true}
                showFileNames={false}
                allowMultipleUploads={false}
                isDisabled={readOnly}
              />
            )}
          </div>

          {formState.logoUrl && (
            <div className="mt-2 flex justify-center">
              <Button
                variant={EBtnVariant.Link}
                className="text-red-500"
                onClick={async () => await onRemoveLogo()}
              >
                Remove image
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>

      {/* Marketing material */}
      <Card>
        <Card.Body>
          <div className="flex flex-col gap-2">
            <h2>Attach marketing material</h2>
            <p className="text-sm">
              * Documents or images added here will be included on every quote
              and invoice.
            </p>
            <div className="marketing-upload-wrapper">
              <FileUploadPersistRemoveControl
                acceptFileTypes={marketingAcceptFileTypes}
                allowMultipleUploads={true}
                enableDragAndDrop={true}
                persistFile={onPersistBusinessAttachment}
                removePersistedFile={onRemoveBusinessAttachment}
                persistedFiles={persistedBusinessAttachments}
                // readOnly={readOnly}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div className="flex flex-col gap-2">
            <h2>Attach credentials</h2>
            <p className="text-sm">
              Images attached here will be shown in the footer on proposals,
              change proposals, invoices and the client portal.
            </p>
            <EntityUploadCollectionControl
              entityId={account.id}
              entityType={'account'}
              role="accreditationImage"
              acceptFileTypes={accreditationAcceptFileTypes}
              continueDeleteIfAnyLinkedEntities
            />
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body className="brand-colour">
          <h2>Brand colour</h2>
          <div className="brand-colour-introduction">
            <span>Select or enter your brand colour</span>
          </div>
          <div className="flex-container brand-colour-body">
            <div className="colour-picker-container">
              <div className="colour-picker-container-inner">
                <ColourPickerField
                  colour={formState.brandColour || undefined}
                  onColourChange={(colour) => {
                    updateFormFields({
                      brandColour: colour.hex,
                    });
                  }}
                />
                <div className="clear-brand-colour-trigger-container">
                  <small
                    className="clear-brand-colour-trigger"
                    onClick={() => {
                      updateFormFields({
                        brandColour: null,
                      });
                    }}
                  >
                    Clear brand colour <FontAwesomeIcon icon={faTimes} />
                  </small>
                </div>
              </div>
            </div>
            <div className="flex-grow">
              <div className="example-proposal-container">
                <ScalingContentWrapper naturalWidth={1000}>
                  <ExampleProposal
                    brandColourOverride={formState.brandColour}
                  />
                </ScalingContentWrapper>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CompanySettingsBranding;
