import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Contact, UpdateAddressContactInput } from '@/gql/graphql';
import ValidatedForm, {
  TRenderFormContents,
} from '@payaca/components/validatedForm/ValidatedForm';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Conditional from '@payaca/components/conditional/Conditional';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';

export interface IProps {
  contacts: Contact[];
  onUpdate?: (contacts: UpdateAddressContactInput[]) => void;
}

type TContactFormState = Omit<Contact, 'id'>;

type ContactFormProps = {
  isEditMode?: boolean;
  contact?: Omit<Contact, 'id'>;
  onUpdate?: (state: TContactFormState) => void;
  onRemove?: () => void;
  onCancel?: () => void;
};

const ContactForm: FC<ContactFormProps> = (props) => {
  const {
    isEditMode: _isEditMode = false,
    contact,
    onUpdate,
    onRemove,
    onCancel,
  } = props;
  const [isEditMode, setIsEditMode] = useState(_isEditMode);

  const initialState = useMemo<TContactFormState>(() => {
    return {
      name: contact?.name || '',
      email: contact?.email || '',
      phone: contact?.phone || '',
      description: contact?.description || '',
    };
  }, [contact]);

  const fieldValidators = useMemo(
    () => ({
      name: [getIsRequiredFieldValidator()],
    }),
    []
  );

  const renderFormContents = useCallback<
    TRenderFormContents<TContactFormState>
  >(
    (
      isValid,
      formState,
      validationState,
      touchedState,
      onFieldChange,
      onFieldTouch
    ) => {
      return (
        <div className="space-y-4">
          <Field
            name="name"
            validationState={
              validationState?.['name']?.isValid === false &&
              touchedState?.['name']
                ? {
                    isValid: false,
                    validationMessages: validationState?.['name']?.errors,
                  }
                : undefined
            }
          >
            <Field.Label>Name</Field.Label>
            <Input
              value={formState.name || ''}
              onChange={(value) => onFieldChange({ name: value })}
              onTouch={() => onFieldTouch('name')}
            />
          </Field>

          <Field name="email">
            <Field.Label>Email</Field.Label>
            <Input
              value={formState.email || ''}
              onChange={(value) => onFieldChange({ email: value })}
            />
          </Field>

          <Field name="phone">
            <Field.Label>Phone</Field.Label>
            <Input
              value={formState.phone || ''}
              onChange={(value) => onFieldChange({ phone: value })}
            />
          </Field>

          <Field name="description">
            <Field.Label>Description</Field.Label>
            <Input
              value={formState.description || ''}
              onChange={(value) => onFieldChange({ description: value })}
            />
          </Field>

          <div className="space-x-2">
            <Button
              disabled={!isValid}
              size={EBtnSize.Small}
              onClick={() => {
                onUpdate?.(formState);
                setIsEditMode(false);
              }}
            >
              Save
            </Button>
            <Button
              size={EBtnSize.Small}
              variant={EBtnVariant.White}
              onClick={() => {
                setIsEditMode(false);
                onCancel?.();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    },
    []
  );

  if (!isEditMode) {
    return (
      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body>
          <div className="flex flex-row items-stretch gap-2">
            <div className="grow">
              <div className="text-base">
                {!!contact?.name?.length && (
                  <p className="mb-4 text-base font-medium">{contact.name}</p>
                )}
                <div className="text-gray-500">
                  {!!contact?.email?.length && (
                    <p className="mb-1 text-base">{contact.email}</p>
                  )}
                  {!!contact?.phone?.length && (
                    <p className="mb-0 text-base">{contact.phone}</p>
                  )}
                </div>
                {!!contact?.description?.length && (
                  <p className="mb-0 mt-4 text-base text-gray-500">
                    {contact.description}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col items-end justify-between gap-2">
              <Button variant={EBtnVariant.LinkInline} onClick={onRemove}>
                Remove
              </Button>
              <Button
                onClick={() => setIsEditMode(true)}
                variant={EBtnVariant.White}
                size={EBtnSize.XSmall}
              >
                Edit
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <ValidatedForm<TContactFormState>
          initialFormState={initialState}
          renderFormContents={renderFormContents}
          fieldValidators={fieldValidators}
        />
      </Card.Body>
    </Card>
  );
};

const AddEditAddressContactsControl: FC<IProps> = (props) => {
  const { contacts: initialContacts, onUpdate } = props;
  const [contacts, setContacts] = useState(initialContacts);
  const [isAddingContact, setIsAddingContact] = useState(false);

  useEffect(() => {
    setContacts(initialContacts);
  }, [initialContacts]);

  const handleUpdateContact = useCallback(
    (contactId: string) => (updatedContact: TContactFormState) => {
      setContacts((_contacts) => {
        const updatedContacts = _contacts.map((i) =>
          i.id === contactId ? { id: contactId, ...updatedContact } : i
        );

        onUpdate?.(updatedContacts);

        return updatedContacts;
      });
    },
    [onUpdate]
  );

  const handleDeleteContact = useCallback(
    (contactId: string) => () => {
      setContacts((_contacts) => {
        const updatedContacts = _contacts.filter((i) => i.id !== contactId);

        onUpdate?.(updatedContacts);

        return updatedContacts;
      });
    },
    [onUpdate]
  );

  return (
    <>
      <Field>
        <Field.Label>Access Contacts</Field.Label>

        <div className="space-y-4">
          {contacts.map((contact) => (
            <ContactForm
              key={contact.id}
              contact={contact}
              onUpdate={handleUpdateContact(contact.id)}
              onRemove={handleDeleteContact(contact.id)}
            />
          ))}

          <Conditional condition={isAddingContact}>
            <ContactForm
              isEditMode
              onUpdate={(updatedContact) => {
                onUpdate?.([...contacts, updatedContact]);
                setIsAddingContact(false);
              }}
              onCancel={() => setIsAddingContact(false)}
            />
          </Conditional>
        </div>
      </Field>

      <div>
        <Button
          onClick={() => setIsAddingContact(true)}
          variant={EBtnVariant.LinkInline}
        >
          Add Contact
        </Button>
      </div>
    </>
  );
};

export default AddEditAddressContactsControl;
