import React, { FC, useMemo } from 'react';

import { Job } from '@payaca/types/jobTypesV2';
import {
  JobContent,
  JobLineItem,
  JobLineItemGroup,
} from '@payaca/types/jobContentTypes';

import './ExampleProposal.sass';
import { JobStatus } from '@payaca/types/jobTypes';
import moment from 'moment-timezone';
import { Customer } from '@payaca/types/customerTypes';
import { useSelector } from '@/api/state';
import JobDocument from '@payaca/components/jobDocument/JobDocument';
import { getDocumentSenderContextFromAccount } from '@payaca/helpers/documentSenderContextHelper';
import Button from '@payaca/components/plButton/Button';
import { EBtnColour, EBtnVariant } from '@payaca/components/plButton/useButtonClassName';

type Props = {
  brandColourOverride?: string | null;
};

const ExampleProposal: FC<Props> = ({
  brandColourOverride,
}: Props): JSX.Element => {
  const job = useMemo(() => {
    return {
      status: JobStatus.QUOTED,
      reference: 1001,
      showBacsPaymentOption: true,
      dueAt: moment().add(7, 'days'),
    } as unknown as Job;
  }, []);

  const customer = useMemo(() => {
    return {
      name: 'Customer Name',
      addresses: [
        {
          address: {
            line1: 'Building name',
            line2: 'Street name',
            city: 'Town',
            postcode: 'XX0 0XX',
          },
        },
      ],
      contacts: [
        {
          isPrimaryContact: true,
          name: 'Customer name',
          emailAddress: 'customername@emailaddress.co.uk',
          telephoneNumber: '+447777777777',
        },
      ],
    } as Customer;
  }, []);

  const jobContent = useMemo(() => {
    return {
      jobLineItemGroupIds: [1, 2],
      jobLineItemIds: [1, 2, 3, 4, 5],
      id: 19438,
      isActive: true,
      depositPercentage: 20,
      depositAmount: 6960,
      minimumDepositPercentage: null,
      minimumDepositAmount: null,
      discountPercentage: null,
      discountAmount: null,
      markupPercentage: null,
      markupAmount: null,
      dealId: 6194,
      subtotal: 29500,
      subtotalIncMarkup: 29500,
      total: 34800,
      cisTotal: 0,
      vatTotal: 5300,
      isAnyVatIncluded: true,
      hasAnyCisDeduction: false,
      calculatedDepositAmount: 6960,
    } as unknown as JobContent;
  }, []);

  const baseAccount = useSelector(
    (state: any) => state.users?.myProfile?.accounts[0]
  );

  const account = useMemo(() => {
    if (!baseAccount) return;

    return {
      ...baseAccount,
      brandColour:
        brandColourOverride === undefined
          ? baseAccount.brandColour
          : brandColourOverride,
    };
  }, [baseAccount, brandColourOverride]);

  const jobLineItems = useMemo(() => {
    return [
      {
        id: 1,
        description: 'Item A',
        price: 3000,
        vatIncluded: false,
        vatAmount: 0,
        isReverseChargeVat: false,
        quantity: 1,
        isOptional: null,
        isMultipleChoice: null,
        isSelected: null,
        jobLineItemGroupId: 3223,
        jobContentId: 19438,
        markupAmount: 0,
        discountAmount: 0,
        priceIncMarkup: 3000,
        priceIncMarkupDiscount: 3000,
        subtotalIncMarkupDiscount: 3000,
        vatTotal: 0,
        total: 3000,
      },
      {
        id: 2,
        description: 'Item B',
        price: 10000,
        vatIncluded: true,
        vatAmount: 20,
        isReverseChargeVat: false,
        quantity: 1,
        isOptional: false,
        isMultipleChoice: true,
        isSelected: true,
        jobLineItemGroupId: 3223,
        jobContentId: 19438,
        markupAmount: 0,
        discountAmount: 0,
        priceIncMarkup: 10000,
        priceIncMarkupDiscount: 10000,
        subtotalIncMarkupDiscount: 10000,
        vatTotal: 2000,
        total: 12000,
      },
      {
        id: 3,
        description: 'Item C',
        price: 8500,
        vatIncluded: true,
        vatAmount: 20,
        isReverseChargeVat: false,
        quantity: 1,
        isOptional: false,
        isMultipleChoice: true,
        isSelected: false,

        jobLineItemGroupId: 3223,
        jobContentId: 19438,
        markupAmount: 0,
        discountAmount: 0,
        priceIncMarkup: 8500,
        priceIncMarkupDiscount: 8500,
        subtotalIncMarkupDiscount: 8500,
        vatTotal: 1700,
        total: 10200,
        jobLineItemAttachmentIds: [],
      },
      {
        id: 4,
        description: 'Item D',
        price: 7500,
        vatIncluded: true,
        vatAmount: 20,
        isReverseChargeVat: false,
        quantity: 2,
        isOptional: null,
        isMultipleChoice: null,
        isSelected: null,

        jobLineItemGroupId: 3247,
        jobContentId: 19438,
        markupAmount: 0,
        discountAmount: 0,
        priceIncMarkup: 7500,
        priceIncMarkupDiscount: 7500,
        subtotalIncMarkupDiscount: 15000,
        vatTotal: 3000,
        total: 18000,
        jobLineItemAttachmentIds: [],
      },
      {
        id: 5,

        description: 'Item E',
        price: 1500,
        vatIncluded: true,
        vatAmount: 20,
        isReverseChargeVat: false,
        quantity: 1,
        isOptional: true,
        isMultipleChoice: false,
        isSelected: true,

        jobLineItemGroupId: 3247,
        jobContentId: 19438,
        markupAmount: 0,
        discountAmount: 0,
        priceIncMarkup: 1500,
        priceIncMarkupDiscount: 1500,
        subtotalIncMarkupDiscount: 1500,
        vatTotal: 300,
        total: 1800,
        jobLineItemAttachmentIds: [],
      },
    ] as unknown[] as JobLineItem[];
  }, []);

  const jobLineItemGroups = useMemo(() => {
    return [
      {
        id: 1,
        description: 'Item group 1',
        jobLineItemIds: [1, 2, 3],
      },
      {
        id: 2,
        description: 'Item group 2',
        jobLineItemIds: [4, 5],
      },
    ] as unknown[] as JobLineItemGroup[];
  }, []);

  return (
    <div className="example-proposal">
      <JobDocument
        isEditable={false}
        siteAddresses={[]}
        documentSenderContext={getDocumentSenderContextFromAccount(account)}
        paymentMethodConfig={{
          allowStripePayment: job.showStripePaymentOption,
          allowBankTransferPayment: job.showBacsPaymentOption,
          bankTransferPaymentSettings: {
            accountNumber: account.accountNumberRaw || '',
            accountName: account.accountNameRaw || '',
            sortCode: account.sortCodeRaw || '',
          },
        }}
        job={job}
        customer={customer}
        jobContent={jobContent}
        jobLineItems={jobLineItems || []}
        jobLineItemAttachments={[]}
        jobLineItemGroups={jobLineItemGroups || []}
        hideItemPrices={account.hideItemPrices}
        hideTaxBreakdown={account.hideTaxBreakdown}
        jobPayments={[]}
        callToAction={
          <>
            <hr className="hr my-4" />
            <Button
              colour={EBtnColour.JobBrand}
              disabled
              className="w-full print:hidden"
            >
              Sign to accept
            </Button>
            <Button
              colour={EBtnColour.JobBrand}
              variant={EBtnVariant.Link}
              disabled
              className="w-full print:hidden mt-2"
            >
              Decline
            </Button>
          </>
        }
      />
    </div>
  );
};

export default ExampleProposal;
