import { MaterialAndSuppliers } from '@/api/queries/project/useGetProjectMaterialListSummaryItems';
import useGetSuppliers from '@/api/queries/suppliers/useGetSuppliers';
import { humanizeCurrency } from '@/helpers/currency';
import Combobox from '@payaca/components/plCombobox/Combobox';
import { SelectOption } from '@payaca/components/plSelect/Select';
import { FC, useState } from 'react';

export const MaterialPurchaseRecordSupplierSelectionControl: FC<{
  materialAndSuppliers: MaterialAndSuppliers;
  value: string | null;
  onChange: (supplierId: string | null) => void;
}> = ({ materialAndSuppliers, value, onChange }) => {
  const [query, setQuery] = useState<string>('');
  const { suppliers, isLoading } = useGetSuppliers(
    { searchTerm: query },
    {
      limit: 10,
    },
    query.length > 0
  );

  let options: SelectOption<string, object>[];
  if (query) {
    if (isLoading) {
      options = [];
    } else {
      options =
        suppliers?.items.map((supplier) => ({
          label: supplier.name,
          value: supplier.id,
        })) || [];
    }
  } else {
    options = materialAndSuppliers.material.suppliedBy.map(
      (supplierMaterial) => ({
        label: `${supplierMaterial.supplier.name} (${humanizeCurrency(
          supplierMaterial.price.unitPriceExcTax
        )})`,
        value: supplierMaterial.supplier.id,
      })
    );
  }
  return (
    <Combobox
      inputPlaceholder="Search across all existing suppliers"
      placeholder="Choose a supplier"
      onChange={(value) => onChange(value as string)}
      value={value || ''}
      query={query}
      setQuery={(val) => {
        setQuery(val);
        // While searching for a new supplier it would not be obvious that the previously
        // set supplier is still set, so we clear it
        onChange(null);
      }}
      options={options}
    />
  );
};
