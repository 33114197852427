import { Reducer } from 'redux';

import { ActionType, State } from './listedItemsTypes';

export const initialState: State = {
  isGettingListedItemsPage: false,
  listedItemsPage: null,
};

const listedItemsReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_LISTED_ITEMS_PAGE:
      return { ...state, isGettingListedItemsPage: true };
    case ActionType.GET_LISTED_ITEMS_PAGE_FAILURE:
      return {
        ...state,
        listedItemsPage: null,
        isGettingListedItemsPage: false,
      };
    case ActionType.GET_LISTED_ITEMS_PAGE_SUCCESS:
      return {
        ...state,
        listedItemsPage: action.payload.listedItemsPage,
        isGettingListedItemsPage: false,
      };
    case ActionType.CLEAR_LISTED_ITEMS_PAGE:
      return { ...state, listedItemsPage: null };

    default:
      return state;
  }
};

export default listedItemsReducer;
