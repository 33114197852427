import {
  ListedSupplierMaterialListViewPage,
  SortBy,
} from '@payaca/types/listedSupplierMaterialTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

export interface State {
  isGettingListedSupplierMaterialsPage: boolean;
  listedSupplierMaterialsPage: ListedSupplierMaterialListViewPage | null;
}

export enum ActionType {
  REQUEST_GET_LISTED_SUPPLIERMATERIALS_PAGE = 'listedSupplierMaterials.requestGetListedSupplierMaterialsPage',
  GET_LISTED_SUPPLIERMATERIALS_PAGE_SUCCESS = 'listedSupplierMaterials.getListedSupplierMaterialsPageSuccess',
  GET_LISTED_SUPPLIERMATERIALS_PAGE_FAILURE = 'listedSupplierMaterials.getListedSupplierMaterialsPageFailure',
  CLEAR_LISTED_SUPPLIERMATERIALS_PAGE = 'listedSupplierMaterials.clearListedSupplierMaterialsPage',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export interface GetListedSupplierMaterialsRequestData {
  pageSize: number;
  pageNumber: number;
  supplierSearchTerm?: string;
  materialSearchTerm?: string;
  sortDirection: SortDirection;
  sortBy: SortBy;
  supplierId?: number;
  materialId?: number;
}
