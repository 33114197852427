import { gqlClient } from '@/api/graphql-client';
import supplierKeys from '@/api/queries/suppliers/keyFactory';
import { graphql } from '@/gql';
import {
  RegisterCityPlumbingPartnerApplicationInput,
  RegisterCityPlumbingPartnerApplicationMutation,
  UnregisterCityPlumbingPartnerApplicationMutation,
} from '@/gql/graphql';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

const REGISTER_PARTNER_APPLICATION = graphql(`
  mutation RegisterCityPlumbingPartnerApplication(
    $input: RegisterCityPlumbingPartnerApplicationInput!
  ) {
    registerCityPlumbingPartnerApplication(input: $input) {
      __typename
      ... on CityPlumbingPartnerApplicationSuccessResponse {
        accountNumber
      }
    }
  }
`);

export const useRegisterCityPlumbingPartnerApplication = (
  options?: UseMutationOptions<
    RegisterCityPlumbingPartnerApplicationMutation,
    unknown,
    RegisterCityPlumbingPartnerApplicationInput
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(REGISTER_PARTNER_APPLICATION, {
        input,
      });
    },
    onSettled: () => queryClient.invalidateQueries(supplierKeys.all),
    ...options,
  });
};

const UNREGISTER_PARTNER_APPLICATION = graphql(`
  mutation UnregisterCityPlumbingPartnerApplication {
    unregisterCityPlumbingPartnerApplication
  }
`);

export const useUnregisterCityPlumbingPartnerApplication = (
  options?: UseMutationOptions<
    UnregisterCityPlumbingPartnerApplicationMutation,
    unknown,
    unknown
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return gqlClient.request(UNREGISTER_PARTNER_APPLICATION);
    },
    onSettled: () => queryClient.invalidateQueries(supplierKeys.all),
    ...options,
  });
};
