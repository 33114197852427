import React, { FC } from 'react';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';

const DealScheduledEventSkeletonCard: FC = () => {
  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body className="flex flex-row">
        <SkeletonLoader.Square className="h-[45px] w-[45px] flex-shrink-0 mr-4" />
        <div className="w-full space-y-2">
          <SkeletonLoader.Text className="w-3/4" />
          <SkeletonLoader.Title className="w-1/6" />
          <SkeletonLoader.Text className="w-1/2" />
        </div>
      </Card.Body>
    </Card>
  );
};
const DealScheduledEventsSkeleton: FC = () => {
  return (
    <div className="my-4 space-y-4">
      <DealScheduledEventSkeletonCard />
      <DealScheduledEventSkeletonCard />
      <DealScheduledEventSkeletonCard />
    </div>
  );
};

export default DealScheduledEventsSkeleton;
