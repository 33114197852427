export enum TimelogsRoles {
  PREVIEWER = 'roles.timelogsPreviewer',
  VIEWER = 'roles.timelogsViewer',
  ASSIGNEE_VIEWER = 'roles.timelogsAssigneeViewer',
  CREATOR = 'roles.timelogsCreator',
  ASSIGNEE_CREATOR = 'roles.timelogsAssigneeCreator',
  UPDATER = 'roles.timelogsUpdater',
  ASSIGNEE_UPDATER = 'roles.timelogsAssigneeUpdater',
  DELETER = 'roles.timelogsDeleter',
  ASSIGNEE_DELETER = 'roles.timelogsAssigneeDeleter',
  COST_ADMINISTRATOR = 'roles.timelogsCostAdministrator',

  TYPES_VIEWER = 'roles.timelogTypesViewer',
  TYPES_MANAGER = 'roles.timelogTypesManager',
}
