import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import Modal from '@payaca/components/modal/Modal';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import * as jobsStoreActions from '@payaca/store/jobs/jobsActions';

import './MarkAsSentModal.css';
import { JobType } from '@payaca/types/jobTypes';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { useSelector } from '@/api/state';

type Props = {
  isOpen: boolean;
  jobId: number;
  jobType?: JobType;
  onClose: () => void;
  markAsSentCallback?: () => void;
};

const MarkAsSentModal: FunctionComponent<Props> = ({
  isOpen,
  jobId,
  jobType = 'Job',
  onClose,
  markAsSentCallback,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [hasMarkedAsSent, setHasMarkedAsSent] = useState(false);

  const isMarkingJobAsSent = useSelector((state) => {
    return state.jobsStore.isMarkingJobAsSent;
  });

  const markJobAsSentError = useSelector((state) => {
    return state.jobsStore.markJobAsSentErrorMessage;
  });

  const onMarkAsSent = useCallback(() => {
    dispatch(jobsStoreActions.requestMarkJobAsSent(jobId));
    setHasMarkedAsSent(true);
  }, [jobId, dispatch]);

  const onModalClose = () => {
    dispatch(jobsStoreActions.clearMarkJobAsSent());
    onClose();
  };

  useEffect(() => {
    if (hasMarkedAsSent && !isMarkingJobAsSent && !markJobAsSentError) {
      markAsSentCallback && markAsSentCallback();
      setHasMarkedAsSent(false);
      onModalClose();
    }
  }, [
    hasMarkedAsSent,
    isMarkingJobAsSent,
    markJobAsSentError,
    markAsSentCallback,
    onModalClose,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      title="Mark as sent"
      size="xs"
      onClose={onModalClose}
      className="mark-as-sent-modal"
      actions={
        <>
          <Button
            onClick={onMarkAsSent}
            iconAfter={faChevronRight}
            isProcessing={isMarkingJobAsSent}
            styleVariant={ButtonStyleVariant.OUTSIZE}
          >
            Mark as sent without sending
          </Button>
          {markJobAsSentError && <ErrorMessage message={markJobAsSentError} />}
        </>
      }
    >
      <p className="text-center">
        An email won&apos;t be sent but automations will still run.
      </p>
    </Modal>
  );
};

export default MarkAsSentModal;
