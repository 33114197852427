import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
} from 'react';
import './HorizontalBarChart.sass';
import * as d3 from 'd3';
import tinycolor from 'tinycolor2';
import HorizontalChart, {
  ValueDatapoint,
  Datapoint,
} from '../horizontalChart/HorizontalChart';

type Props = {
  datapoints: ValueDatapoint[];
  valueFormatter?: (value: number) => string;
  axisTickFormatter?: (value: number) => string;
  renderLabel?: (datapoint: ValueDatapoint) => React.ReactNode;
  isDiscrete?: boolean;
};
const HorizontalBarChart: FunctionComponent<PropsWithChildren<Props>> = ({
  datapoints,
  valueFormatter = (value) => value.toString(),
  axisTickFormatter = (value) => value.toString(),
  renderLabel = (datapoint) => <span>{datapoint.label}</span>,
  isDiscrete = false,
}: PropsWithChildren<Props>): JSX.Element | null => {
  const renderDatapoint = useCallback(
    (
      datapoint: ValueDatapoint,
      i: number,
      xScale: d3.ScaleLinear<number, number, never>
    ) => {
      const barColor = datapoint.colour || d3.schemeTableau10[i % 10];
      return (
        <div
          className="bar"
          style={{
            width: `${xScale(datapoint.data)}%`,
            backgroundColor: barColor,
          }}
        >
          <div className="value-wrapper">
            <span
              className="value"
              style={{
                color: tinycolor
                  .mostReadable(barColor, ['#263e59', 'white'], {
                    includeFallbackColors: true,
                  })
                  .toHexString(),
              }}
            >
              {valueFormatter(datapoint.data)}
            </span>
          </div>
        </div>
      );
    },
    [valueFormatter]
  );

  return (
    <HorizontalChart
      className="horizontal-bar-chart"
      datapoints={datapoints}
      axisTickFormatter={axisTickFormatter}
      renderLabel={renderLabel}
      isDiscrete={isDiscrete}
      renderDatapoint={renderDatapoint}
    />
  );
};

export default HorizontalBarChart;
