// ======================================================================================================
// Public routes config
// - Define public routes
// ======================================================================================================

import React from 'react';

import { Routes } from './RoutesConfig';

import Login from '@/ui/pages/loginPage/LoginPage';
import AppleCallback from '@/ui/pages/appleCallbackPage/AppleCallbackPage';
import XeroCallback from '@/ui/pages/xeroCallbackPage/XeroCallbackPage';
import Register from '@/ui/pages/registerPage/RegisterPage';
import ForgotPassword from '@/ui/pages/forgotPasswordPage/ForgotPasswordPage';
import ResetPassword from '@/ui/pages/resetPasswordPage/ResetPasswordPage';
import VerifyUser from '@/ui/pages/verifyUserPage/VerifyUserPage';
import UserInvite from '@/ui/pages/userInvitePage/UserInvitePage';
import EnableCustomerPayments from '@/ui/pages/enableCustomerPaymentsPage/EnableCustomerPaymentsPage';
import LoginOAuth from '@/ui/pages/loginOAuthPage/LoginOAuthPage';
import FormPage from '@/ui/pages/formPage/FormPage';
import PurchaseOrderPage from '@/ui/pages/pdfDocumentPages/purchaseOrderPage/PurchaseOrderPage';
import ChangeProposalPage from '@/ui/pages/pdfDocumentPages/changeProposalPage/ChangeProposalPage';
import InvoicePage from '@/ui/pages/pdfDocumentPages/invoicePage/InvoicePage';
import ProtoInvoicePage from '@/ui/pages/pdfDocumentPages/protoInvoicePage/ProtoInvoicePage';

const PublicRoutingConfig = {
  Routes: {
    [Routes.LOGIN]: {
      path: 'login',
      component: Login,
    },
    [Routes.LOGIN_OAUTH]: {
      path: 'oauth',
      component: LoginOAuth,
    },
    [Routes.APPLE_CALLBACK]: {
      path: 'apple/callback',
      component: AppleCallback,
    },
    [Routes.XERO_CALLBACK]: {
      path: 'xero/callback',
      component: XeroCallback,
    },
    [Routes.REGISTER]: {
      path: 'register',
      component: Register,
    },
    [Routes.FORGOT_PASSWORD]: {
      path: 'forgotPassword',
      component: ForgotPassword,
    },
    [Routes.RESET]: {
      path: 'reset/:token',
      component: ResetPassword,
    },
    [Routes.USER_INVITE]: {
      path: 'invite/:token',
      component: UserInvite,
    },
    [Routes.VERIFY_USER_ACCOUNT]: {
      path: 'verify/:token',
      renderComponent: (props) => <VerifyUser {...props} isAuthed={false} />,
    },
    [Routes.ENABLE_CUSTOMER_PAYMENTS]: {
      path: 'enableCustomerPayments',
      component: EnableCustomerPayments,
    },
    [Routes.NEW_EDIT_FORM]: {
      path: 'forms/:formPreviewToken/:pageIndex',
      component: FormPage,
    },
    [Routes.PURCHASE_ORDER_PDF_DOCUMENT]: {
      path: 'pdfdocuments/purchaseorders/:previewToken',
      component: (props) => {
        return (
          <PurchaseOrderPage previewToken={props.match.params.previewToken} />
        );
      },
    },
    [Routes.CHANGE_PROPOSAL_PDF_DOCUMENT]: {
      path: 'pdfdocuments/changeproposals/:previewToken/:changeProposalId',
      component: (props) => {
        return (
          <ChangeProposalPage
            previewToken={props.match.params.previewToken}
            changeProposalId={+props.match.params.changeProposalId}
          />
        );
      },
    },

    [Routes.INVOICE_PDF_DOCUMENT]: {
      path: 'pdfdocuments/invoices/:previewToken/:invoiceId',
      component: (props) => {
        return (
          <InvoicePage
            previewToken={props.match.params.previewToken}
            invoiceId={+props.match.params.invoiceId}
          />
        );
      },
    },

    [Routes.PROTO_INVOICE_PDF_DOCUMENT]: {
      path: 'pdfdocuments/protoinvoices/:previewToken/:protoInvoiceId',
      component: (props) => {
        return (
          <ProtoInvoicePage
            previewToken={props.match.params.previewToken}
            protoInvoiceId={+props.match.params.protoInvoiceId}
          />
        );
      },
    },
  },
  Redirects: {
    TO_LOGIN: {
      fromPaths: ['*'],
      toPath: 'login',
      component: Login,
    },
  },
};

export { PublicRoutingConfig };
