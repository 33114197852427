import React, { FC, useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';

import RadioButtons from './RadioButtons';
import './SectionRadioButtons.sass';

const styles: any = () => ({
  radioButtonWrapper: {
    height: 50,
    width: '100%',
    borderRadius: 10,
    borderWidth: 1,
    flexDirection: 'row',
    display: 'flex',
    overflow: 'hidden',
    border: '1px solid #FABb00',
    marginTop: 10,
    marginBottom: 20,
    cursor: 'pointer',
  },
});

type Props = {
  items: { label: string; value: string; altSelected?: boolean }[];
  onChange: (value: any) => void;
  title?: any;
  value: any;
};

const SectionRadioButtons: FC<Props> = ({
  items,
  onChange,
  title,
  value,
}: Props): JSX.Element => {
  const getItemStyle = (selected: boolean, isLastItem: boolean) => {
    const style: any = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 48,
      width: `calc(100% - ${41 + (items.length - 1)}px)`,
      position: 'relative',
      fontWeight: 'bold',
    };
    if (selected) {
      style.backgroundColor = '#fff2cc';
    }
    if (!isLastItem) {
      style.borderRight = '1px solid #FABb00';
    }
    return style;
  };

  return (
    <div style={{ position: 'relative' }} className="section-radio-buttons">
      <div>
        <label className="additional-label">{title}</label>
      </div>
      <div className="radio-button-wrapper">
        <RadioButtons items={items} value={value} onChange={onChange} />
      </div>
    </div>
  );
};

export default withStyles(styles)(SectionRadioButtons);
