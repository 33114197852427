import React, {
  FunctionComponent,
  useEffect,
  useCallback,
  useState,
} from 'react';
import './KeyIndicatorsData.sass';
import { useDispatch } from 'react-redux';

import * as analyticsActions from '@payaca/store/analytics/analyticsActions';
import {
  KeyIndicatorData as KeyIndicatorDataType,
  KeyIndicatorsData as KeyIndicatorsDataType,
  KeyIndicatorType,
} from '@payaca/types/analyticsTypes';
import KeyIndicatorData from '../keyIndicatorData/KeyIndicatorData';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import { useSelector } from '@/api/state';

const KeyIndicatorsData: FunctionComponent = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const [keyIndicatorsData, setKeyIndicatorsData] = useState<
    KeyIndicatorDataType<KeyIndicatorType>[]
  >([]);

  useEffect(() => {
    dispatch(
      analyticsActions.getKeyIndicatorsData.request({
        indicatorTypes: [
          KeyIndicatorType.REVENUE_SECURED,
          KeyIndicatorType.REVENUE_RECEIVED,
          KeyIndicatorType.CONVERSION_RATE,
        ],
        callback: setKeyIndicatorsData,
      })
    );
  }, []);

  const currencyFormatter = useCallback(
    (value: number) =>
      value === undefined
        ? currencyPrice(0, account.region, false).replace('0', '-')
        : currencyPrice(value, account.region, false),
    [account]
  );

  const renderKeyIndicatorData = useCallback(
    (keyIndicatorData: KeyIndicatorDataType<KeyIndicatorType>, idx: number) => {
      let title;
      let formatValue: any;
      let explainerText;

      switch (keyIndicatorData.type) {
        case KeyIndicatorType.REVENUE_SECURED:
          title = 'Revenue secured';
          formatValue = currencyFormatter;
          explainerText =
            'This represents the total value (excluding VAT) of revenue due from quotes which have been accepted in this period';
          break;
        case KeyIndicatorType.REVENUE_RECEIVED:
          title = 'Revenue received';
          formatValue = currencyFormatter;
          explainerText =
            'This represents the total value of payments received in this period. Not including payouts or deposit payments from 0% quotes at this time.';
          break;
        case KeyIndicatorType.CONVERSION_RATE:
          title = 'Quote conversion rate';
          formatValue = (value?: number) =>
            value === undefined ? '-%' : `${(value * 100).toFixed(1)}%`;
          explainerText =
            'This represents the percentage of sent quotes which have been accepted.';
          break;
        default:
          title = keyIndicatorData.type;
      }

      return (
        <ContentPanel className="key-indicator-data-container" key={idx}>
          <KeyIndicatorData
            title={title}
            explainerText={explainerText}
            periodLengthInDays={keyIndicatorData.periodLengthInDays}
            currentPeriod={keyIndicatorData.currentPeriod}
            previousPeriod={keyIndicatorData.previousPeriod}
            percentageChange={keyIndicatorData.percentageChange}
            formatValue={formatValue}
            sparkLineData={keyIndicatorData.sparkLineData}
          />
        </ContentPanel>
      );
    },
    []
  );

  if (!keyIndicatorsData) return null;

  return (
    <div className={`key-indicators-data`}>
      <div className="key-indicators-data-inner flex-container">
        {keyIndicatorsData.map(renderKeyIndicatorData)}
      </div>
    </div>
  );
};

export default KeyIndicatorsData;
