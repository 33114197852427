import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import './PurchaseOrdersTable.sass';

import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import Table from '@payaca/components/plTable/Table';
import { PurchaseOrder } from '@payaca/types/materialsListTypes';
import type { ListedPurchaseOrder } from '@payaca/types/listedPurchaseOrderTypes';
import PurchaseOrderStatusBadge from '../purchaseOrderStatusBadge/PurchaseOrderStatusBadge';
import { Link, useHistory } from 'react-router-dom';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getRegion } from '@/utils/stateAccessors';
import { useSelector } from '@/api/state';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import moment from 'moment-timezone';

type PurchaseOrderRowType = ListedPurchaseOrder;

type ExcludableColumns = 'supplier' | 'project' | 'customer';

type Props = {
  purchaseOrders: PurchaseOrderRowType[];
  quickActionDefinitions?: {
    actionName: 'Edit' | 'Void' | 'Send' | 'Confirm';
    actionBehaviour: (row: PurchaseOrderRowType) => void;
    isActionProcessing?: boolean;
  }[];
  onClickRow?: (row: PurchaseOrderRowType) => void;
  isLoading?: boolean;
  excludeColumns?: ExcludableColumns[];
};

const PurchaseOrdersTable: FC<Props> = ({
  purchaseOrders,
  quickActionDefinitions,
  isLoading,
  excludeColumns,
  onClickRow,
}) => {
  const history = useHistory();
  const region = useSelector(getRegion);
  const shortDateRegionalFormat = useMemo(
    () => getInternationalMomentDateFormatByRegion(DateFormats.SHORT, region),
    [region]
  );

  return (
    <ResponsiveViewWrapper
      className="purchase-orders-table"
      downBreakpointSm={800}
    >
      <Table
        uniqueKey="id"
        data={purchaseOrders}
        quickActions={(row: PurchaseOrderRowType) => {
          if (!quickActionDefinitions?.length || row.voidedAt) return [];
          return quickActionDefinitions
            .filter((qad) => {
              if (qad.actionName === 'Void' && !row.confirmedAt) return true;
              if (
                qad.actionName === 'Confirm' &&
                !row.confirmedAt &&
                row.sentAt
              )
                return true;
              if (qad.actionName === 'Send' && !row.sentAt) return true;
              if (qad.actionName === 'Edit' && !row.sentAt) return true;
              return false;
            })
            .map((qad) => {
              return {
                name: qad.actionName,
                onClick: (row: PurchaseOrderRowType) =>
                  qad.actionBehaviour(row),
                isProcessing: qad.isActionProcessing,
              };
            });
        }}
        isLoading={isLoading}
        onClickRow={onClickRow}
      >
        <Table.Column
          header="PO Number"
          field={'reference'}
          render={(reference, row) => {
            if (row.deal) {
              return (
                <Link
                  to={`/purchaseorders/${row.id}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {reference}
                </Link>
              );
            } else if (row.isIsolated) {
              return (
                <Link
                  to={`/purchaseorders/${row.id}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {reference}
                </Link>
              );
            } else {
              return reference;
            }
          }}
        />
        {!excludeColumns?.includes('supplier') && (
          <Table.Column<PurchaseOrderRowType, 'supplier'>
            header="Supplier"
            field={'supplier'}
            render={(supplier) => {
              return (
                <Link
                  onClick={(e) => e.stopPropagation()}
                  to={`/suppliers/${supplier.id}`}
                >
                  {supplier.name}
                </Link>
              );
            }}
          />
        )}
        {!excludeColumns?.includes('project') && (
          <Table.Column<PurchaseOrderRowType, 'deal'>
            header="Project"
            field={'deal'}
            render={(deal) => {
              if (deal) {
                return (
                  <Link
                    to={`/deals/${deal.id}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    #{deal?.customReference || deal?.reference}
                  </Link>
                );
              }
            }}
          />
        )}
        {!excludeColumns?.includes('customer') && (
          <Table.Column<PurchaseOrderRowType, 'deal'>
            header="Customer"
            field={'deal'}
            render={(deal) => {
              if (deal?.customer) {
                return (
                  <Link
                    to={`/customers/${deal.customer.id}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {deal.customer.name}
                  </Link>
                );
              }
            }}
          />
        )}
        <Table.Column
          header="Cost ex tax"
          field={'confirmedTotal'}
          render={(_, row) => {
            if (row.confirmedAt && row.confirmedTotalExcludingTax) {
              return currencyPrice(row.confirmedTotalExcludingTax, region); // inc or exc tax?
            } else if (row.predictedTotalExcludingTax) {
              return currencyPrice(row.predictedTotalExcludingTax, region); // inc or exc tax?
            }
          }}
        />
        <Table.Column<PurchaseOrderRowType, 'sentAt'>
          header="Issued"
          field={'sentAt'}
          render={(sentAt) => {
            return sentAt ? moment(sentAt).format(shortDateRegionalFormat) : '';
          }}
        />
        <Table.Column<PurchaseOrderRowType, 'status'>
          header="Status"
          field={'status'}
          render={(status) => {
            return <PurchaseOrderStatusBadge status={status} />;
          }}
        />
      </Table>
    </ResponsiveViewWrapper>
  );
};

export default PurchaseOrdersTable;
