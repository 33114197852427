import { action } from 'typesafe-actions';

import { ChangeProposal } from '@payaca/types/changeProposalTypes';

import { createAsyncAction } from '../utils';

import {
  ActionType,
  GetChangeProposalGeneratedPdfUrl,
  GetChangeProposalValidationResultAction,
  MarkChangeProposalAcceptedAction,
  MarkChangeProposalAsSent,
  MarkChangeProposalDeclinedAction,
  PersistChangeProposalRequestData,
  ProgressProposalToInvoice,
  SendChangeProposal,
  SendProtoInvoice,
  SetChangeProposalDiscount,
  SetProposalDiscount,
  VoidChangeProposalAction,
} from './proposalsTypes';

export const requestGetChangeProposal = (
  changeProposalId: number,
  callback?: (changeProposal: ChangeProposal) => void
) =>
  action(ActionType.GET_CHANGE_PROPOSAL_REQUEST, {
    changeProposalId,
    callback,
  });

export const getChangeProposalSuccess = (
  changeProposalId: number,
  changeProposal: ChangeProposal
) =>
  action(ActionType.GET_CHANGE_PROPOSAL_SUCCESS, {
    changeProposalId,
    changeProposal,
  });

export const getChangeProposalFailure = (
  changeProposalId: number,
  error: Error
) =>
  action(
    ActionType.GET_CHANGE_PROPOSAL_FAILURE,
    {
      changeProposalId,
    },
    null,
    error
  );

export const clearChangeProposals = () =>
  action(ActionType.CLEAR_CHANGE_PROPOSALS);

export const requestGetChangeProposalsForDeal = (dealId: number) =>
  action(ActionType.GET_CHANGE_PROPOSALS_FOR_DEAL_REQUEST, {
    dealId,
  });

export const getChangeProposalsForDealSuccess = (
  changeProposals: Array<ChangeProposal>
) => action(ActionType.GET_CHANGE_PROPOSALS_FOR_DEAL_SUCCESS, changeProposals);

export const getChangeProposalsForDealFailure = (error: Error) =>
  action(ActionType.GET_CHANGE_PROPOSALS_FOR_DEAL_FAILURE, null, null, error);

export const requestPersistChangeProposal = (
  persistChangeProposalRequestData: PersistChangeProposalRequestData,
  callback?: (changeProposalId: number) => void,
  onErrorCallback?: () => void
) =>
  action(ActionType.PERSIST_CHANGE_PROPOSAL_REQUEST, {
    persistChangeProposalRequestData,
    callback,
    onErrorCallback,
  });

export const persistChangeProposalSuccess = (changeProposalId: number) =>
  action(ActionType.PERSIST_CHANGE_PROPOSAL_SUCCESS, {
    changeProposalId,
  });

export const persistChangeProposalFailure = (
  error: Error,
  changeProposalId?: number
) =>
  action(
    ActionType.PERSIST_CHANGE_PROPOSAL_FAILURE,
    { changeProposalId },
    null,
    error
  );

export const markChangeProposalAccepted =
  createAsyncAction<MarkChangeProposalAcceptedAction>({
    request: ActionType.MARK_CHANGE_PROPOSAL_ACCEPTED_REQUEST,
    success: ActionType.MARK_CHANGE_PROPOSAL_ACCEPTED_SUCCESS,
    failure: ActionType.MARK_CHANGE_PROPOSAL_ACCEPTED_FAILURE,
  })(
    (
      changeProposalId: number,
      callback?: () => void,
      onErrorCallback?: (err: Error) => void
    ) => ({ payload: { changeProposalId, callback, onErrorCallback } })
  );

export const markChangeProposalDeclined =
  createAsyncAction<MarkChangeProposalDeclinedAction>({
    request: ActionType.MARK_CHANGE_PROPOSAL_DECLINED_REQUEST,
    success: ActionType.MARK_CHANGE_PROPOSAL_DECLINED_SUCCESS,
    failure: ActionType.MARK_CHANGE_PROPOSAL_DECLINED_FAILURE,
  })(
    (
      changeProposalId: number,
      callback?: () => void,
      onErrorCallback?: (err: Error) => void
    ) => ({ payload: { changeProposalId, callback, onErrorCallback } })
  );

export const voidChangeProposal = createAsyncAction<VoidChangeProposalAction>({
  request: ActionType.VOID_CHANGE_PROPOSAL_REQUEST,
  success: ActionType.VOID_CHANGE_PROPOSAL_SUCCESS,
  failure: ActionType.VOID_CHANGE_PROPOSAL_FAILURE,
})(
  (
    changeProposalId: number,
    callback?: () => void,
    onErrorCallback?: (err: Error) => void
  ) => ({ payload: { changeProposalId, callback, onErrorCallback } })
);

export const getChangeProposalValidationResult =
  createAsyncAction<GetChangeProposalValidationResultAction>({
    request: ActionType.GET_CHANGE_PROPOSAL_VALIDATION_RESULT_REQUEST,
  })(
    (
      changeProposalId: number,
      callback?: (validationResult: {
        isValid: boolean;
        errors: string[];
      }) => void,
      onErrorCallback?: (err: Error) => void
    ) => ({ payload: { changeProposalId, callback, onErrorCallback } })
  );

export const getChangeProposalGeneratedPdfUrl =
  createAsyncAction<GetChangeProposalGeneratedPdfUrl>({
    request: ActionType.GET_CHANGE_PROPOSAL_GENERATED_PDF_URL,
  })((payload: GetChangeProposalGeneratedPdfUrl['request']['payload']) => ({
    payload,
  }));

export const sendChangeProposal = createAsyncAction<SendChangeProposal>({
  request: ActionType.SEND_CHANGE_PROPOSAL_REQUEST,
})((payload: SendChangeProposal['request']['payload']) => ({
  payload,
}));

export const markChangeProposalAsSent =
  createAsyncAction<MarkChangeProposalAsSent>({
    request: ActionType.MARK_CHANGE_PROPOSAL_AS_SENT_REQUEST,
  })((payload: MarkChangeProposalAsSent['request']['payload']) => ({
    payload,
  }));

export const progressProposalToInvoice =
  createAsyncAction<ProgressProposalToInvoice>({
    request: ActionType.PROGRESS_PROPOSAL_TO_INVOICE_REQUEST,
    success: ActionType.PROGRESS_PROPOSAL_TO_INVOICE_SUCCESS,
    failure: ActionType.PROGRESS_PROPOSAL_TO_INVOICE_FAILURE,
  })((payload: ProgressProposalToInvoice['request']['payload']) => ({
    payload,
  }));

export const setProposalDiscount = createAsyncAction<SetProposalDiscount>({
  request: ActionType.SET_PROPOSAL_DISCOUNT_REQUEST,
})((payload: SetProposalDiscount['request']['payload']) => ({
  payload,
}));

export const setChangeProposalDiscount =
  createAsyncAction<SetChangeProposalDiscount>({
    request: ActionType.SET_CHANGE_PROPOSAL_DISCOUNT_REQUEST,
  })((payload: SetChangeProposalDiscount['request']['payload']) => ({
    payload,
  }));

export const sendProtoInvoice = createAsyncAction<SendProtoInvoice>({
  request: ActionType.SEND_PROTO_INVOICE_REQUEST,
})((payload: SendProtoInvoice['request']['payload']) => ({
  payload,
}));
