import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  AddTagsToEntityMutation,
  AddTagsToEntityMutationVariables,
  AddTagsToEntityInput,
} from '@/gql/graphql';

const AddTagsToEntity = graphql(`
  mutation AddTagsToEntity($input: AddTagsToEntityInput!) {
    addTagsToEntity(input: $input) {
      ... on Project {
        id
        tags {
          id
          colour
          name
        }
      }
      ... on ScheduledEvent {
        id
        tags {
          id
          colour
          name
        }
      }
    }
  }
`);

const useAddTagsToEntity = (
  options?: UseMutationOptions<
    AddTagsToEntityMutation,
    unknown,
    AddTagsToEntityMutationVariables['input']
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(AddTagsToEntity, {
        input,
      });
    },
    ...options,
  });
};

export default useAddTagsToEntity;
