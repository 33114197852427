import {
  ListedSupplierListViewPage,
  SortBy,
} from '@payaca/types/listedSupplierTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

export interface State {
  isGettingListedSuppliersPage: boolean;
  listedSuppliersPage: ListedSupplierListViewPage | null;
}

export enum ActionType {
  REQUEST_GET_LISTED_SUPPLIERS_PAGE = 'listedSuppliers.requestGetListedSuppliersPage',
  GET_LISTED_SUPPLIERS_PAGE_SUCCESS = 'listedSuppliers.getListedSuppliersPageSuccess',
  GET_LISTED_SUPPLIERS_PAGE_FAILURE = 'listedSuppliers.getListedSuppliersPageFailure',
  CLEAR_LISTED_SUPPLIERS_PAGE = 'listedSuppliers.clearListedSuppliersPage',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export interface GetListedSuppliersRequestData {
  pageSize: number;
  pageNumber: number;
  searchTerm?: string;
  sortDirection: SortDirection;
  excludeSystemManaged?: boolean;
  sortBy: SortBy;
}
