import { useQuery } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import meKeys from './keyFactory';

const GetMyAccountFeatures = graphql(`
  query MyAccountFeatures {
    me {
      user {
        account {
          features
        }
      }
    }
  }
`);

export const useMyAccountHasFeature = (feature: string) => {
  const { data } = useGetMyAccountFeatures();

  return data?.includes(feature) || false;
};

const useGetMyAccountFeatures = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountFeatures(),
    queryFn: () => {
      return gqlClient.request(GetMyAccountFeatures);
    },
    staleTime: Infinity,
  });

  return { data: data?.me.user.account.features, ...rest };
};

export default useGetMyAccountFeatures;
