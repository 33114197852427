import { FetchedEntity } from '@payaca/types/storeTypes';

export interface State {
  fileDownloads: {
    [jobId: number]: {
      [fileType: string]: FetchedEntity<{ [key: string]: string }>;
    };
  };

  isDownloadingFile: boolean;
}

export enum ActionType {
  REQUEST_FILE_DOWNLOAD = 'fileDownload.requestFileDownload',
  FILE_DOWNLOAD_SUCCESS = 'fileDownload.fileDownloadSuccess',
  FILE_DOWNLOAD_FAILURE = 'fileDownload.fileDownloadFailure',

  CLEAR_FILE_DOWNLOADS = 'fileDownload.clearFileDownloads',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}
