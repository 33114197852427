import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import qs from 'qs';
import { LoginControl, StatusError } from '@/ui/components';
import { actions as appActions } from '@/api/app';
import * as authActions from '@payaca/store/auth/authActions';
import BasicPageWrapper from '../pageWrappers/basicPageWrapper/BasicPageWrapper';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import './LoginOAuthPage.sass';

enum PageState {
  LOADING = 'loading',
  ERROR = 'error',
  LOGIN = 'login',
}

type Props = {
  location: any;
  history: any;
  isAuthed: boolean;
};

const LoginOAuthPage: FC<Props> = ({
  location,
  history,
  isAuthed,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [verifiedOAuth, setVerifiedOAuth] = useState(false);
  const [verifyError, setVerifyError] = useState<{
    status: number;
    error: string;
    error_description: string;
  } | null>(null);

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    // store pathname for reference in grant access page if logging out
    dispatch(
      appActions.storePreviousPathname(`${location.pathname}${location.search}`)
    );

    const payload = {
      //@ts-ignore
      redirectUri: decodeURIComponent(query.redirect_uri) as string,
      clientId: query.client_id as string,
      responseType: query.response_type as string,
      state: query.state as string,
      scope: query.scope as string,
    };

    // verify oauth query before proceeding to grantAccess page
    // or error if verification fails
    dispatch(
      authActions.verifyOAuth(payload, (err: any, resp: any) => {
        if (resp) {
          const grantAccessPath = `grantAccess?${resp.grantAccessPath}`;
          setVerifiedOAuth(true);
          if (isAuthed) {
            history.push(grantAccessPath);
          } else {
            dispatch(appActions.storePathname(grantAccessPath));
          }
        } else {
          setVerifyError(err);
        }
      })
    );
  }, []);

  const pageState = useMemo(() => {
    if (!verifyError && (!verifiedOAuth || (verifiedOAuth && isAuthed))) {
      return PageState.LOADING;
    } else if (verifyError) {
      return PageState.ERROR;
    } else {
      return PageState.LOGIN;
    }
  }, [verifyError, verifiedOAuth, isAuthed]);

  return (
    <BasicPageWrapper className="login-oauth-page">
      {pageState === PageState.LOADING && <MiniLoader />}
      {pageState === PageState.ERROR && (
        <StatusError
          title="Authorisation Error"
          status={verifyError?.status || undefined}
          errorName={verifyError?.error || ''}
          errorDescriptionMessage={verifyError?.error_description || ''}
        />
      )}
      {pageState === PageState.LOGIN && (
        <LoginControl hideForgotPassword={true} />
      )}
    </BasicPageWrapper>
  );
};

export default LoginOAuthPage;
