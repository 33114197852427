import { getRegion, getTask, getUserRoles } from '@/utils/stateAccessors';
import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import './TaskReadCompleteView.sass';
import * as scheduledEventActions from '@payaca/store/scheduledEvents/scheduledEventsActions';
import * as taskActions from '@payaca/store/tasks/tasksActions';
import * as dealActions from '@payaca/store/deals/dealsActions';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import TaskDetailsAndActionsHeader from '../taskDetailsAndActionsHeader/TaskDetailsAndActionsHeader';
import TaskDeadlineCompletionDateInformation from '../taskDeadlineCompletionDateInformation/TaskDeadlineCompletionDateInformation';
import DynamicTaskChecklistCompletionForm from '../dynamicTaskChecklistCompletionForm/DynamicTaskChecklistCompletionForm';
import TaskLinkedScheduledEventsPanel from '../taskLinkedScheduledEventsPanel/TaskLinkedScheduledEventsPanel';
import { useSelector } from '@/api/state';
import { isValidHtml, parseHtmlToReact } from '@payaca/utilities/htmlUtilities';
import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';

type Props = {
  taskId: number;
  hideDealLink?: boolean;
  onDeleteTaskSuccess?: () => void;
  onUpdateTaskSuccess?: () => void;
};
const TaskReadCompleteView: FC<Props> = ({
  taskId,
  hideDealLink,
  onDeleteTaskSuccess,
  onUpdateTaskSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const task = useSelector((state) => getTask(state, taskId));

  useEffect(() => {
    dispatch(scheduledEventActions.requestGetScheduledEventsForTask(taskId));
    dispatch(taskActions.requestGetTask(taskId));
  }, [taskId]);

  useEffect(() => {
    if (task?.dealId) dispatch(dealActions.requestGetDeal(task.dealId));
  }, [task?.dealId]);

  if (!task) return null;

  return (
    <div className={'task-read-complete-view'}>
      <TaskDetailsAndActionsHeader
        taskId={taskId}
        hideDealLink={hideDealLink}
        onDeleteTaskSuccess={onDeleteTaskSuccess}
        onUpdateTaskSuccess={() => {
          dispatch(taskActions.requestGetTask(taskId));
          if (onUpdateTaskSuccess) {
            void onUpdateTaskSuccess();
          }
        }}
      />
      <TaskDeadlineCompletionDateInformation task={task} />
      <div className="dynamic-task-checklist-completion-form-panel">
        <DynamicTaskChecklistCompletionForm
          task={task}
          onUpdateTaskCompletionSuccess={onUpdateTaskSuccess}
        />
      </div>
      {task.notes && (
        <div className="notes-container">
          <label>
            <strong>Notes:</strong>
          </label>
          {isValidHtml(task.notes) ? (
            parseHtmlToReact(task.notes)
          ) : (
            <MarkdownLabel markdown={task.notes} />
          )}
        </div>
      )}
      <PermissionGuard
        renderIfHasPermissions={[
          ScheduledEventsPermissions.GET_EVENTS,
          ScheduledEventsPermissions.GET_MY_EVENTS,
        ]}
      >
        <TaskLinkedScheduledEventsPanel taskId={taskId} />
      </PermissionGuard>
    </div>
  );
};
export default TaskReadCompleteView;
