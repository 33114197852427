import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import FileUploadField from '../fileUploadField/FileUploadField';
import MiniLoader from '../miniLoader/MiniLoader';
import PersistedFileControl from '../persistedFileControl/PersistedFileControl';

import './FileUploadPersistRemoveControl.sass';
import FieldLabel from '../fieldLabel/FieldLabel';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type Props = {
  acceptFileTypes?: string[];
  allowFileNameModification?: boolean;
  allowMultipleUploads?: boolean;
  description?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  persistedFiles: {
    fileName: string;
    identifier: any;
    thumbnailUrl?: string;
    mimeType?: string;
    downloadUrl?: string;
  }[];
  persistFile: (file: File) => Promise<any>;
  persistFileName?: (fileIdentifier: any, fileName: string) => Promise<void>;
  onPersistFilesSuccess?: (fileIdentifiers: any[]) => void;
  removePersistedFile: (fileIdentifier: any) => Promise<void>;
  enableDragAndDrop?: boolean;
  showFileNames?: boolean;
  uploadTriggerIcon?: IconDefinition;
  canRemove?: boolean;
  canAdd?: boolean;
};

const FileUploadPersistRemoveControl: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  acceptFileTypes,
  allowFileNameModification = false,
  allowMultipleUploads = false,
  description,
  label,
  isDisabled = false,
  isRequired = false,
  persistedFiles,
  persistFile,
  persistFileName,
  removePersistedFile,
  onPersistFilesSuccess,
  enableDragAndDrop = true,
  showFileNames = true,
  uploadTriggerIcon,
  canRemove = true,
  canAdd = true,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const [persistingFiles, setPersistingFiles] = useState<
    { fileName: string; identifier: any }[]
  >([]);

  const [removingFileIdentifiers, setRemovingFileIdentifiers] = useState<any[]>(
    []
  );

  const [persistingFileNameIdentifiers, setPersistingFileNameIdentifiers] =
    useState<any[]>([]);

  const handlePersistFileName = useCallback(
    async (fileIdentifier: any, fileName: string) => {
      if (!persistFileName) return;

      setPersistingFileNameIdentifiers((persistingFileNameIdentifiers) => [
        ...persistingFileNameIdentifiers,
        fileIdentifier,
      ]);
      // TODO make this persist filename instead of removepersistedfile
      await persistFileName(fileIdentifier, fileName).then(() => {
        setPersistingFileNameIdentifiers((persistingFileNameIdentifiers) => [
          ...persistingFileNameIdentifiers.filter(
            (persistingFileNameIdentifier) => {
              return persistingFileNameIdentifier !== fileIdentifier;
            }
          ),
        ]);
      });
    },
    [persistFileName]
  );

  const handlePersistFile = useCallback(
    async (file: File) => {
      const fileIdentifier = uuidv4();
      setPersistingFiles((persistingFiles) => [
        ...persistingFiles,
        {
          fileName: file.name,
          identifier: fileIdentifier,
          fileSize: file.size,
        },
      ]);

      const newFileIdentifier = await persistFile(file).finally(() => {
        // remove file from persisting state regardless of success/failed to upload
        setPersistingFiles((persistingFiles) => [
          ...persistingFiles.filter((persistingFile) => {
            return persistingFile.identifier !== fileIdentifier;
          }),
        ]);
      });
      return newFileIdentifier;
    },
    [persistFile]
  );

  const handleRemoveFile = useCallback(
    async (fileIdentifier: any) => {
      setRemovingFileIdentifiers((removingFileIdentifiers) => [
        ...removingFileIdentifiers,
        fileIdentifier,
      ]);
      await removePersistedFile(fileIdentifier).then(() => {
        setRemovingFileIdentifiers((removingFileIdentifiers) => [
          ...removingFileIdentifiers.filter((removingFileIdentifier) => {
            return removingFileIdentifier !== fileIdentifier;
          }),
        ]);
      });
    },
    [removePersistedFile]
  );

  const persistFromFileList = useCallback(
    async (fileList: FileList) => {
      const fileArray = Array.prototype.slice.call(fileList);

      const fileIdentifiers = await Promise.all(
        fileArray.map((file: File) => handlePersistFile(file))
      );

      onPersistFilesSuccess?.(fileIdentifiers);
    },
    [handlePersistFile, onPersistFilesSuccess]
  );

  const onChange = useCallback(
    (value: { [key: string]: FileList | null }) => {
      const fileList = value.fileList;
      if (fileList) {
        persistFromFileList(fileList);
      }
    },
    [persistFromFileList]
  );

  const persistingFilesElement = useMemo(() => {
    if (persistingFiles.length === 0) {
      return null;
    }

    return persistingFiles.map((persistingFile, index: number) => {
      return (
        <li key={index}>
          <div className="file-block">
            <div className="main-block">
              <MiniLoader />
            </div>
            {showFileNames && <small>{persistingFile.fileName}</small>}
          </div>
        </li>
      );
    });
  }, [persistingFiles, showFileNames]);

  const persistedFilesElement = useMemo(() => {
    if (persistedFiles.length === 0) {
      return null;
    }

    return persistedFiles.map((persistedFile, index: number) => {
      const isRemoving = removingFileIdentifiers.includes(
        persistedFile.identifier
      );
      const isPersistingFileName = persistingFileNameIdentifiers.includes(
        persistedFile.identifier
      );

      return (
        <li key={index}>
          <PersistedFileControl
            persistedFile={persistedFile}
            isDisabled={isDisabled}
            allowFileNameModification={allowFileNameModification}
            isRemoving={isRemoving}
            isPersistingFileName={isPersistingFileName}
            persistFileName={(fileName: string) => {
              handlePersistFileName(persistedFile.identifier, fileName);
            }}
            removeFile={() => handleRemoveFile(persistedFile.identifier)}
            canRemove={canRemove}
            showFileName={showFileNames}
          />
        </li>
      );
    });
  }, [
    persistedFiles,
    isDisabled,
    handleRemoveFile,
    removingFileIdentifiers,
    persistingFileNameIdentifiers,
    allowFileNameModification,
    handlePersistFileName,
    showFileNames,
  ]);

  const onDrop = useCallback(
    (files: File[]) => {
      const filesToUpload: any = files.slice(
        0,
        allowMultipleUploads ? files.length : 1
      );
      onChange &&
        onChange({
          fileList: filesToUpload,
        });
    },
    [allowMultipleUploads, onChange]
  );

  const acceptedFiles = useMemo(
    () => acceptFileTypes?.join(','),
    [acceptFileTypes]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: isDisabled,
    onDrop,
    accept: acceptedFiles,
    noClick: true,
  });

  return (
    <div
      className={`file-upload-persist-remove-control ${
        isDragActive ? 'dragging' : ''
      }`}
      {...(enableDragAndDrop ? getRootProps() : undefined)}
    >
      <input
        type="file"
        style={{ display: 'none' }}
        name={'fileList'}
        {...(enableDragAndDrop ? getInputProps() : undefined)}
      />

      {(canRemove || (!canRemove && persistedFiles.length > 0)) && (
        <FieldLabel
          label={label}
          description={description}
          isRequired={isRequired}
        />
      )}

      <div className="file-list-container">
        <ul>
          {persistedFilesElement}
          {persistingFilesElement}

          {canAdd &&
            (allowMultipleUploads ||
              (!persistedFiles.length && !persistingFiles.length)) && (
              <li>
                <FileUploadField
                  name="fileList"
                  isDisabled={
                    isDisabled ||
                    (!allowMultipleUploads && !!persistedFiles.length)
                  }
                  allowMultipleUploads={allowMultipleUploads}
                  acceptFileTypes={acceptFileTypes}
                  onChange={onChange}
                  enableDragAndDrop={enableDragAndDrop && !allowMultipleUploads}
                  uploadTriggerIcon={uploadTriggerIcon}
                />
              </li>
            )}
        </ul>
      </div>
      {canAdd && (
        <div className="drag-indicator">
          <FontAwesomeIcon icon={uploadTriggerIcon || faPlus} />
          <span>Drag and drop your files here</span>
        </div>
      )}
    </div>
  );
};

export default FileUploadPersistRemoveControl;
