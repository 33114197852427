import { User } from '@payaca/types/userTypes';
import { DeclareAsyncAction } from '../types';

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export interface State {
  isMakingPrimaryUser: boolean;
  isPrimaryUserMadeSuccessfully?: boolean;

  isGettingTermsAndPrivacyUpdates: boolean;
  updatedTermsAndPrivacy: {
    termsMarkdown?: string;
    privacyMarkdown?: string;
    version: number;
  } | null;

  isAgreeingToTermsAndPrivacyVersion: boolean;
  agreedToTermsAndPrivacyVersionSuccess: boolean | null;

  isUpdatingPassword: boolean;
  isUpdatedPasswordSuccessfully: boolean | null;
  updatePasswordError: string | null;

  isUpdatingProfile: boolean;

  isGettingAccountTerms: boolean;

  terms: any;
}

export enum ActionType {
  REQUEST_MAKE_PRIMARY_USER = 'user.requestMakePrimaryUser',
  MAKE_PRIMARY_USER_SUCCESS = 'user.makePrimaryUserSuccess',
  MAKE_PRIMARY_USER_FAILURE = 'user.makePrimaryUserFailure',

  REQUEST_GET_TERMS_AND_PRIVACY_UPDATES = 'user.requestGetTermsAndPrivacyUpdates',
  GET_TERMS_AND_PRIVACY_UPDATES_SUCCESS = 'user.getTermsAndPrivacyUpdatesSuccess',
  GET_TERMS_AND_PRIVACY_UPDATES_FAILURE = 'user.getTermsAndPrivacyUpdatesFailure',

  REQUEST_AGREE_TO_TERMS_AND_PRIVACY_VERSION = 'user.requestAgreeToTermsAndPrivacyVersion',
  AGREE_TO_TERMS_AND_PRIVACY_VERSION_SUCCESS = 'user.agreeToTermsAndPrivacyVersionSuccess',
  AGREE_TO_TERMS_AND_PRIVACY_VERSION_FAILURE = 'user.agreeToTermsAndPrivacyVersionFailure',
  CLEAR_TERMS_AND_PRIVACY_UPDATES = 'user.clearTermsAndPrivacyUpdates',

  REQUEST_UPDATE_PASSWORD = 'users.requestUpdatePassword',
  UPDATE_PASSWORD_SUCCESS = 'users.updatePasswordSuccess',
  UPDATE_PASSWORD_FAILURE = 'users.updatePasswordFailure',
  CLEAR_UPDATE_PASSWORD = 'users.clearUpdatePassword',

  REQUEST_GET_ACCOUNT_TERMS = 'users.requestGetAccountTerms',
  GET_ACCOUNT_TERMS_SUCCESS = 'users.getAccountTermsSuccess',
  GET_ACCOUNT_TERMS_FAILURE = 'users.getAccountTermsFailure',

  REQUEST_UPDATE_PROFILE = 'users.requestUpdateProfile',
  UPDATE_PROFILE_SUCCESS = 'users.updateProfileSuccess',
  UPDATE_PROFILE_FAILURE = 'users.updateProfileFailure',

  SET_COST_PER_HOUR_REQUEST = 'users.setCostPerHour:request',
}

export interface UpdateProfileRequestData {
  pendingEmail?: string;
  firstname?: string;
  lastname?: string;
  contactNumber?: string;
  gasSafeIdCardNumber?: number | null;
  userSignature?: string;
  enableAllNotifications?: boolean;
  notificationSettings?: string;
}

export type SetCostPerHour = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.SET_COST_PER_HOUR_REQUEST;
      payload: {
        userId: User['id'];
        costPerHour?: number;
        callback?: () => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;
