import React, { useState } from 'react';

import { PublicHydratedServicePlanPeriodCommitment } from '@payaca/types/service-plans';
import { BaseTask } from '@payaca/types/taskTypes';

import TaskReadCompleteDrawer from '../taskReadCompleteDrawer/TaskReadCompleteDrawer';

import './ServicePlanPeriodTaskCommitment.sass';
import ServicePlanPeriodCommitmentEntityCard from './ServicePlanPeriodCommitmentEntityCard';
import { useDispatch } from 'react-redux';
import { updateServicePlanPeriodCommitment } from '@payaca/store/servicePlans/servicePlansActions';

type Props = {
  commitment: PublicHydratedServicePlanPeriodCommitment;
  onCommitmentUpdate: () => void;
};
const ServicePlanPeriodTaskCommitment = ({
  commitment,
  onCommitmentUpdate,
}: Props) => {
  const dispatch = useDispatch();
  const [showReadDrawer, setShowReadDrawer] = useState(false);
  const entity = commitment.entity as BaseTask;
  return (
    <>
      <ServicePlanPeriodCommitmentEntityCard
        commitmentId={commitment.publicId}
        onClick={() => setShowReadDrawer(true)}
        entityType={commitment.entityType}
        status={commitment.status}
        onCommitmentUpdate={onCommitmentUpdate}
      >
        <div className="service-plan-period-task-commitment-inner">
          <h3 className="name">{entity.name}</h3>
        </div>
      </ServicePlanPeriodCommitmentEntityCard>
      <TaskReadCompleteDrawer
        taskId={entity.id}
        isOpen={showReadDrawer}
        onClose={() => setShowReadDrawer(false)}
        onUpdateTaskSuccess={onCommitmentUpdate}
        onDeleteTaskSuccess={() => {
          dispatch(
            updateServicePlanPeriodCommitment.request({
              servicePlanPeriodCommitment: {
                publicId: commitment.publicId,
                entityId: null,
              },
              callback: () => onCommitmentUpdate(),
            })
          );
        }}
      />
    </>
  );
};

export default ServicePlanPeriodTaskCommitment;
