import React, { FC } from 'react';

import Button from '@payaca/components/plButton/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import CurrentSubscriptionControl from '../currentSubscriptionControl/CurrentSubscriptionControl';

import './CurrentSubscriptionControls.sass';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';

interface Props {
  onUpdateSubscriptionSuccess?: () => void;
  showSelectSubscriptionView?: () => void;
}

const CurrentSubscriptionControls: FC<Props> = ({
  onUpdateSubscriptionSuccess,
  showSelectSubscriptionView,
}): JSX.Element => {
  return (
    <ResponsiveViewWrapper
      className="current-subscription-controls"
      downBreakpointSm={800}
    >
      <div>
        <h3>Core plan</h3>
        <ContentPanel>
          <CurrentSubscriptionControl
            onUpdateSubscriptionSuccess={onUpdateSubscriptionSuccess}
          />
        </ContentPanel>
      </div>

      {showSelectSubscriptionView && (
        <Button
          onClick={showSelectSubscriptionView}
          variant={EBtnVariant.LinkInline}
        >
          View available plans
        </Button>
      )}
    </ResponsiveViewWrapper>
  );
};

export default CurrentSubscriptionControls;
