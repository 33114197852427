import { action } from 'typesafe-actions';
import {
  ActionRequiredPendingPayment,
  ActionRequiredDraftJob,
  ActionRequiredPendingProposal,
  ActionRequiredPaymentOutstandingInvoice,
} from '@payaca/types/dashboardTypes';

import { ActionType } from './dashboardTypes';

export const requestGetActionRequiredPendingPayments = () => {
  return action(ActionType.REQUEST_GET_ACTION_REQUIRED_PENDING_PAYMENTS);
};

export const getActionRequiredPendingPaymentsSuccess = (
  actionRequiredPendingPayments: ActionRequiredPendingPayment[]
) => {
  return action(ActionType.GET_ACTION_REQUIRED_PENDING_PAYMENTS_SUCCESS, {
    actionRequiredPendingPayments,
  });
};

export const getActionRequiredPendingPaymentsFailure = (error: Error) => {
  return action(
    ActionType.GET_ACTION_REQUIRED_PENDING_PAYMENTS_FAILURE,
    null,
    null,
    error
  );
};

export const clearActionRequiredPendingPayments = () => {
  return action(ActionType.CLEAR_ACTION_REQUIRED_PENDING_PAYMENTS);
};

export const requestGetActionRequiredDraftJobs = () => {
  return action(ActionType.REQUEST_GET_ACTION_REQUIRED_DRAFT_JOBS);
};

export const getActionRequiredDraftJobsSuccess = (
  actionRequiredDraftJobs: ActionRequiredDraftJob[]
) => {
  return action(ActionType.GET_ACTION_REQUIRED_DRAFT_JOBS_SUCCESS, {
    actionRequiredDraftJobs,
  });
};

export const getActionRequiredDraftJobsFailure = (error: Error) => {
  return action(
    ActionType.GET_ACTION_REQUIRED_DRAFT_JOBS_FAILURE,
    null,
    null,
    error
  );
};

export const clearActionRequiredDraftJobs = () => {
  return action(ActionType.CLEAR_ACTION_REQUIRED_DRAFT_JOBS);
};

export const requestGetActionRequiredPendingProposals = () => {
  return action(ActionType.REQUEST_GET_ACTION_REQUIRED_PENDING_PROPOSALS);
};

export const getActionRequiredPendingProposalsSuccess = (
  actionRequiredPendingProposals: ActionRequiredPendingProposal[]
) => {
  return action(ActionType.GET_ACTION_REQUIRED_PENDING_PROPOSALS_SUCCESS, {
    actionRequiredPendingProposals,
  });
};

export const getActionRequiredPendingProposalsFailure = (error: Error) => {
  return action(
    ActionType.GET_ACTION_REQUIRED_PENDING_PROPOSALS_FAILURE,
    null,
    null,
    error
  );
};

export const clearActionRequiredPendingProposals = () => {
  return action(ActionType.CLEAR_ACTION_REQUIRED_PENDING_PROPOSALS);
};

export const requestGetActionRequiredPaymentOutstandingInvoices = () => {
  return action(
    ActionType.REQUEST_GET_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES
  );
};

export const getActionRequiredPaymentOutstandingInvoicesSuccess = (
  actionRequiredPaymentOutstandingInvoices: ActionRequiredPaymentOutstandingInvoice[]
) => {
  return action(
    ActionType.GET_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES_SUCCESS,
    {
      actionRequiredPaymentOutstandingInvoices,
    }
  );
};

export const getActionRequiredPaymentOutstandingInvoicesFailure = (
  error: Error
) => {
  return action(
    ActionType.GET_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES_FAILURE,
    null,
    null,
    error
  );
};

export const clearActionRequiredPaymentOutstandingInvoices = () => {
  return action(ActionType.CLEAR_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES);
};
