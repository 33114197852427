import { DocumentsPermissions } from './documents.permissions';
import { DocumentsRoles } from './documents.roles';

export const documentsMappings = {
  [DocumentsPermissions.ADD_DOCUMENT]: [DocumentsRoles.CREATOR],
  [DocumentsPermissions.ASSIGNEE_ADD_DOCUMENT]: [
    DocumentsRoles.ASSIGNEE_CREATOR,
  ],
  [DocumentsPermissions.GET_DOCUMENTS_FOR_DEAL]: [DocumentsRoles.VIEWER],
  [DocumentsPermissions.GET_DOCUMENTS_FOR_MY_DEAL]: [
    DocumentsRoles.ASSIGNEE_VIEWER,
  ],
  [DocumentsPermissions.UPLOAD_DOCUMENT]: [DocumentsRoles.CREATOR],
  [DocumentsPermissions.ASSIGNEE_UPLOAD_DOCUMENT]: [
    DocumentsRoles.ASSIGNEE_CREATOR,
  ],
  [DocumentsPermissions.DELETE_DOCUMENT]: [DocumentsRoles.EDITOR],
  [DocumentsPermissions.ASSIGNEE_DELETE_DOCUMENT]: [
    DocumentsRoles.ASSIGNEE_EDITOR,
  ],
  [DocumentsPermissions.EDIT_DOCUMENT]: [DocumentsRoles.EDITOR],
  [DocumentsPermissions.ASSIGNEE_EDIT_DOCUMENT]: [
    DocumentsRoles.ASSIGNEE_EDITOR,
  ],
  [DocumentsPermissions.SEND_DOCUMENT]: [DocumentsRoles.CREATOR],
  [DocumentsPermissions.ASSIGNEE_SEND_DOCUMENT]: [
    DocumentsRoles.ASSIGNEE_CREATOR,
  ],
  [DocumentsPermissions.MANAGE_PREFILL_PREFERENCES]: [
    DocumentsRoles.PREFILL_PREFERENCES_MANAGER,
  ],
};
