import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  DeleteCustomerContactInput,
  DeleteCustomerContactMutation as _DeleteCustomerAddressMutation,
} from '@/gql/graphql';

const DeleteCustomerContactMutation = graphql(`
  mutation DeleteCustomerContact($input: DeleteCustomerContactInput!) {
    deleteCustomerContact(input: $input) {
      id
    }
  }
`);

const useDeleteCustomerContact = (
  options?: UseMutationOptions<
    _DeleteCustomerAddressMutation,
    unknown,
    DeleteCustomerContactInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(DeleteCustomerContactMutation, {
        input,
      });
    },
    ...options,
  });
};

export default useDeleteCustomerContact;
