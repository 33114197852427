import React, { FC, useMemo, useState, useCallback, useEffect } from 'react';
import moment from 'moment-timezone';
import { backOff } from 'exponential-backoff';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import './PurchaseOrderPage.sass';
import { useDispatch } from 'react-redux';
import {
  getHydratedPurchaseOrder,
  requestGetMaterialsListWithRelatedEntities,
  requestGetPurchaseOrder,
  requestVoidPurchaseOrder,
} from '@payaca/store/materialsList/materialsListActions';
import PurchaseOrderDocument from '@/ui/components/purchaseOrderDocument/PurchaseOrderDocument';
import { useSelector } from '@/api/state';
import {
  HydratedPurchaseOrder,
  PurchaseOrderStatus,
} from '@payaca/types/materialsListTypes';
import { useHistory, useLocation } from 'react-router';
import QueryString from 'qs';
import { useDeal } from '@payaca/store/hooks/appState';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';
import AuthenticatedPageWrapper from '@/ui/pages/pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import PreviewDocument from '@/ui/components/PreviewQuoteInvoice/PreviewDocument';
import { useHashFragment } from '@/utils/customHooks';
import { getUserRoles } from '@/utils/stateAccessors';
import { TDropdownItem } from '@payaca/components/plDropdown/DropdownCore';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import Card from '@payaca/components/plCard/Card';
import ConfirmPurchaseOrderModal from '@/ui/components/confirmPurchaseOrderModal/ConfirmPurchaseOrderModal';
import SendPurchaseOrderModal from '@/ui/components/sendPurchaseOrderModal/SendPurchaseOrderModal';
import EditPurchaseOrderModal from '@/ui/components/editPurchaseOrderModal/EditPurchaseOrderModal';
import Modal from '@payaca/components/modal/Modal';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import UntitledIcon from '@payaca/untitled-icons';
import PurchaseOrderPageSkeletonLoader from '@/ui/pages/purchaseOrderPage/PurchaseOrderPageSkeletonLoader';
import { EAlertColour } from '@payaca/components/plAlert/Alert';
import { Props as BadgeProps } from '@payaca/components/plBadge/Badge';

type Props = {
  purchaseOrderId: number;
};

const PurchaseOrderPage: FC<Props> = ({
  purchaseOrderId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [purchaseOrder, setPurchaseOrder] = useState<HydratedPurchaseOrder>();
  const location = useLocation();
  const history = useHistory();
  const [showConfirmPurchaseOrderModal, toggleConfirmPurchaseOrderModal] =
    useHashFragment('#confirm-po');
  const [showSendPurchaseOrderModal, toggleSendPurchaseOrderModal] =
    useHashFragment('#send-po');
  const [
    showConfirmVoidPurchaseOrderModal,
    toggleConfirmVoidPurchaseOrderModal,
  ] = useHashFragment('#void-po');
  const [showEditPurchaseOrderModal, toggleEditPurchaseOrderModal] =
    useHashFragment('#edit-po');

  const [isVoiding, setIsVoiding] = useState(false);

  const userRoles = useSelector(getUserRoles);

  const fetchPdfRetry = useCallback(() => {
    return backOff(
      async () => {
        return new Promise<void>((resolve, reject) => {
          dispatch(
            requestGetPurchaseOrder(purchaseOrderId, (po) => {
              if (po.pdfUrl) {
                resolve();
              } else {
                // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
                reject();
              }
            })
          );
        });
      },
      {
        numOfAttempts: 5,
        startingDelay: 1500,
        delayFirstAttempt: true,
      }
    );
  }, [purchaseOrderId]);

  useEffect(() => {
    if (!purchaseOrder?.pdfUrl) {
      void fetchPdfRetry();
    }
  }, []);

  const { projectId } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const dealId = projectId as string | undefined;

  const deal = useDeal(dealId ? +dealId : undefined);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  const sentAtTime = moment(purchaseOrder?.sentAt).format(
    getInternationalMomentDateFormatByRegion(DateFormats.SHORT, account.region)
  );

  const getPurchaseOrder = useCallback(() => {
    dispatch(
      getHydratedPurchaseOrder.request({
        purchaseOrderId,
        callback: (purchaseOrder) => {
          setPurchaseOrder(purchaseOrder);
        },
      })
    );
  }, []);

  useEffect(() => {
    if (dealId) {
      dispatch(requestGetDeal(+dealId));
    }
  }, [dealId]);

  useEffect(() => {
    getPurchaseOrder();
  }, []);

  const primaryAction = useMemo(() => {
    if (
      !purchaseOrder?.confirmedAt &&
      purchaseOrder?.sentAt &&
      userHasRequiredPermission(userRoles, [
        MaterialsListPermissions.CONFIRM_PURCHASE_ORDER,
      ])
    ) {
      return {
        children: 'Confirm purchases',
        onClick: () => toggleConfirmPurchaseOrderModal(),
      };
    }

    if (
      !purchaseOrder?.sentAt &&
      userHasRequiredPermission(userRoles, [
        MaterialsListPermissions.SEND_PURCHASE_ORDER,
      ])
    ) {
      return {
        children: 'Send',
        onClick: () => toggleSendPurchaseOrderModal(),
      };
    }

    if (purchaseOrder?.pdfUrl) {
      return {
        children: <UntitledIcon name="download-01.3" className="h-5 w-5" />,
        onClick: () => {
          // todo: anchor link as a context menu item
          window.open(purchaseOrder.pdfUrl, '_blank');
        },
      };
    }
  }, [purchaseOrder, userRoles]);

  const availableContextMenuItems = useMemo(() => {
    const actions: TDropdownItem[] = [];

    if (
      !purchaseOrder?.sentAt &&
      userHasRequiredPermission(userRoles, [
        MaterialsListPermissions.PERSIST_PURCHASE_ORDER,
      ])
    ) {
      actions.push({
        label: 'Edit',
        onClick: () => {
          if (!purchaseOrder) return;

          if (purchaseOrder.isIsolated) {
            history.push(`/purchaseorders/${purchaseOrderId}/edit`);
          } else {
            dispatch(
              requestGetMaterialsListWithRelatedEntities(
                purchaseOrder.materialsListId
              )
            );
            toggleEditPurchaseOrderModal();
          }
        },
      });
    }

    if (
      !purchaseOrder?.confirmedAt &&
      userHasRequiredPermission(userRoles, [
        MaterialsListPermissions.VOID_PURCHASE_ORDER,
      ])
    ) {
      actions.push({
        label: 'Void this purchase order',
        onClick: () => toggleConfirmVoidPurchaseOrderModal(),
      });
    }

    return actions;
  }, [userRoles, purchaseOrder, purchaseOrderId]);

  const purchaseOrderStateBadge = useMemo<BadgeProps>(() => {
    switch (purchaseOrder?.status) {
      case PurchaseOrderStatus.PURCHASED:
        return {
          variant: 'soft',
          colour: 'teal',
          children: purchaseOrder?.status,
        };
      case PurchaseOrderStatus.SENT:
        return {
          variant: 'soft',
          colour: 'yellow',
          children: purchaseOrder?.status,
        };
      case PurchaseOrderStatus.VOID:
        return {
          variant: 'soft',
          colour: 'red',
          children: purchaseOrder?.status,
        };
      case PurchaseOrderStatus.DRAFT:
        return {
          variant: 'soft',
          colour: 'black',
          children: purchaseOrder?.status,
        };
    }

    return {
      variant: 'soft',
      colour: 'blue',
      children: purchaseOrder?.status,
    };
  }, [purchaseOrder]);

  return (
    <AuthenticatedPageWrapper
      className="bg-gray-50"
      previousPageNavigationConfig={{
        route: dealId
          ? `/deals/${dealId}/materials/purchase-orders`
          : `/suppliers/${purchaseOrder?.supplierId}?tab=purchase-orders`,
      }}
    >
      <div className="p-4">
        <PreviewDocument
          title={`Purchase order ${purchaseOrder?.reference || ''}: ${
            purchaseOrder?.supplier?.name || ''
          }`}
          badge={purchaseOrderStateBadge}
          firstAction={primaryAction}
          actions={availableContextMenuItems}
          alerts={
            purchaseOrder?.sentAt
              ? [
                  {
                    colour: EAlertColour.SOFT_YELLOW,
                    children: `This purchase order was ${
                      purchaseOrder?.isSentOffline ? 'marked as' : ''
                    } sent on ${sentAtTime}`,
                  },
                ]
              : undefined
          }
        >
          {purchaseOrder ? (
            <Card>
              <Card.Body>
                <PurchaseOrderDocument
                  purchaseOrder={purchaseOrder}
                  project={
                    deal
                      ? {
                          reference:
                            deal.customReference || deal.reference?.toString(),
                        }
                      : undefined
                  }
                />
              </Card.Body>
            </Card>
          ) : (
            <PurchaseOrderPageSkeletonLoader />
          )}
        </PreviewDocument>
      </div>

      {!!purchaseOrder && (
        <>
          {!purchaseOrder.confirmedAt && purchaseOrder.sentAt && (
            <ConfirmPurchaseOrderModal
              purchaseOrder={purchaseOrder}
              isOpen={showConfirmPurchaseOrderModal}
              onClose={() => toggleConfirmPurchaseOrderModal()}
              onConfirmPurchaseOrderSuccess={() => {
                toggleConfirmPurchaseOrderModal();
                getPurchaseOrder?.();
              }}
            />
          )}
          {!purchaseOrder.sentAt && (
            <>
              <SendPurchaseOrderModal
                isOpen={showSendPurchaseOrderModal}
                onClose={() => toggleSendPurchaseOrderModal()}
                purchaseOrder={purchaseOrder}
                onSendPurchaseOrderSuccess={() => {
                  toggleSendPurchaseOrderModal();
                  getPurchaseOrder?.();
                }}
              />
              <EditPurchaseOrderModal
                projectId={dealId ? +dealId : undefined}
                isOpen={showEditPurchaseOrderModal}
                onClose={() => toggleEditPurchaseOrderModal()}
                purchaseOrderId={purchaseOrderId}
                onEditPurchaseOrderSuccess={() => {
                  toggleEditPurchaseOrderModal();
                  getPurchaseOrder?.();
                  void fetchPdfRetry();
                }}
              />
            </>
          )}

          {!purchaseOrder.confirmedAt && (
            <Modal
              isOpen={showConfirmVoidPurchaseOrderModal}
              onClose={() => toggleConfirmVoidPurchaseOrderModal()}
              title={'Are you sure you want to void the purchase order?'}
              actions={
                <>
                  <Button
                    colourVariant={ButtonColourVariant.RED}
                    styleVariant={ButtonStyleVariant.OUTSIZE}
                    onClick={() => {
                      if (isVoiding) return;
                      setIsVoiding(true);
                      dispatch(
                        requestVoidPurchaseOrder(
                          purchaseOrderId,
                          () => {
                            getPurchaseOrder?.();
                            setIsVoiding(false);
                            toggleConfirmVoidPurchaseOrderModal();
                          },
                          () => setIsVoiding(false)
                        )
                      );
                    }}
                    isProcessing={isVoiding}
                  >
                    Void
                  </Button>
                </>
              }
            ></Modal>
          )}
        </>
      )}
    </AuthenticatedPageWrapper>
  );
};
export default PurchaseOrderPage;
