import React, { FunctionComponent, useMemo } from 'react';
import tinycolor from 'tinycolor2';

import './OutlinedContentPanel.sass';

export enum ContentPanelTitleStyleVariant {
  BASIC = 'basic',
  BASIC_WITH_SEPARATOR = 'basic-with-separator',
  BANNER = 'banner',
  BORDER_ALIGNED = 'border-aligned',
}

export enum ContentPanelColourVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECONDARY_LIGHT = 'secondary-light',
  SECONDARY_LIGHTER = 'secondary-lighter',
}

export enum ContentPanelPaddingVariant {
  LARGE = 'large',
  STANDARD = 'standard',
  SMALL = 'small',
}

type Props = React.PropsWithChildren<{
  title?: string | JSX.Element | JSX.Element[];
  className?: string;
  colourVariant?: ContentPanelColourVariant;
  colourOverride?: string;
  titleStyleVariant?: ContentPanelTitleStyleVariant;
  paddingVariant?: ContentPanelPaddingVariant;
}>;

const OutlinedContentPanel: FunctionComponent<Props> = ({
  title,
  children,
  className,
  colourVariant = ContentPanelColourVariant.SECONDARY_LIGHT,
  colourOverride,
  titleStyleVariant = ContentPanelTitleStyleVariant.BASIC,
  paddingVariant = ContentPanelPaddingVariant.STANDARD,
}: Props): JSX.Element => {
  const readableTextColour = useMemo(() => {
    if (!colourOverride) return undefined;

    return tinycolor
      .mostReadable(colourOverride, ['#263e59', 'white'], {
        includeFallbackColors: true,
      })
      .toHexString();
  }, [colourOverride]);

  return (
    <div
      className={`outlined-content-panel title-${titleStyleVariant} colour-${colourVariant} padding-${paddingVariant}  ${
        className ? className : ''
      }`}
      style={{ borderColor: colourOverride }}
    >
      {title && (
        <div
          className="title"
          role="heading"
          style={{
            backgroundColor:
              titleStyleVariant === ContentPanelTitleStyleVariant.BANNER
                ? colourOverride
                : undefined,
            color:
              titleStyleVariant === ContentPanelTitleStyleVariant.BANNER
                ? readableTextColour
                : undefined,
          }}
        >
          {typeof title === 'string' ? <h3>{title}</h3> : title}
        </div>
      )}
      <div className="body">{children}</div>
    </div>
  );
};

export default OutlinedContentPanel;
