import moment from 'moment-timezone';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { Video } from 'react-iconly';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { AnnouncementBanner } from '@payaca/components/announcementBanner/AnnouncementBanner';

import { getUserRoles } from '@/utils/stateAccessors';

import { actions as appActions } from '@/api/app';

import { ButtonColourVariant } from '@payaca/components/button/enums';
import IconButton from '@payaca/components/button/IconButton';

import './TrialPeriodCountdownBanner.sass';
import { useSelector } from '@/api/state';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { useAccount } from '@/utils/storeHooks';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { Link } from 'react-router-dom';

const WELCOME_VIDEO_SRC = 'https://www.youtube.com/embed/FNBkEITCtvg';

const TrialPeriodCountdownBanner: FunctionComponent =
  (): JSX.Element | null => {
    const dispatch = useDispatch();

    const account = useAccount();

    const currentUserFirstName: string = useSelector((state: any) => {
      return state.users.myProfile?.firstname;
    });

    const hasHiddenWelcomeVideo = useSelector(
      (state: any) => state.app.hasHiddenWelcomeVideo
    );

    const userRoles = useSelector(getUserRoles);
    const currentUserIsAdmin = useMemo(() => {
      return userHasRequiredPermission(userRoles, [
        AccountsPermissions.UPDATE_SUBSCRIPTION,
      ]);
    }, [userRoles]);

    const accountAccessInformation = useSelector((state) => {
      return state.account.accountAccessInformation;
    });

    const isOnVerifyPage = useMemo(() => {
      return window.location.href.includes('/verifyHolding');
    }, []);

    const isOnManageSubscriptionPage = useMemo(() => {
      return location.pathname == '/upgradeAccount';
    }, [location]);

    const showWelcomeVideo = useCallback(() => {
      dispatch(
        appActions.toggleVideoOverlay({
          src: WELCOME_VIDEO_SRC,
          onClose: () => {
            dispatch(appActions.hideWelcomeVideo());
          },
        })
      );
    }, [dispatch]);

    useEffect(() => {
      if (!isOnVerifyPage && !hasHiddenWelcomeVideo) {
        // open welcome video automatically if it hasn't been closed
        showWelcomeVideo();
      }
    }, [hasHiddenWelcomeVideo, isOnVerifyPage, showWelcomeVideo]);

    const prettyDiscountDate = accountAccessInformation?.discountAvailableUntil
      ? moment(accountAccessInformation.discountAvailableUntil).format(
          getInternationalMomentDateFormatByRegion(
            DateFormats.SHORT,
            account.region
          )
        )
      : null;

    return (
      <AnnouncementBanner>
        <div className="flex flex-row items-baseline gap-4">
          <span className="mr-auto text-white">
            Hi {currentUserFirstName}, your free trial ends in{' '}
            {accountAccessInformation?.trialPeriodDaysRemaining}{' '}
            {accountAccessInformation?.trialPeriodDaysRemaining === 1
              ? 'day'
              : 'days'}
            .
            {(!currentUserIsAdmin &&
              ' Contact your account administrator to subscribe.') ||
              (!!accountAccessInformation?.discountAvailableUntil &&
                ` Subscribe before ${prettyDiscountDate} for a ${accountAccessInformation.discountPercentage}% discount!`) ||
              (!!accountAccessInformation?.discountPercentage &&
                ` A ${accountAccessInformation.discountPercentage}% discount is available!`)}
          </span>
          {!isOnManageSubscriptionPage && (
            <PermissionGuard
              renderIfHasPermissions={[AccountsPermissions.UPDATE_SUBSCRIPTION]}
            >
              <Link to="/upgradeAccount">
                <AnnouncementBanner.Button>
                  Select your plan
                </AnnouncementBanner.Button>
              </Link>
            </PermissionGuard>
          )}
        </div>
      </AnnouncementBanner>
    );
  };

export default TrialPeriodCountdownBanner;
