import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { serializerToHTML } from '@payaca/helpers/rich-text-editor-serializer';

import './EntityTemplateReadView.sass';
import {
  PublicEntityTemplate,
  PublicHydratedEntityTemplate,
  TemplatableEntity,
} from '@payaca/types/entity-templates';
import { isNullish } from '@payaca/utilities/guards';
import RichTextarea from '@payaca/components/plRichTextarea/RichTextarea';
import PaymentScheduleDefinitionReadView from '../paymentScheduleDefinitionReadView/PaymentScheduleDefinitionReadView';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import { singularPlural } from '@payaca/utilities/stringUtilities';

type Props = {
  entityTemplate: PublicEntityTemplate<'proposal'>;
  onUpdateEntityTemplateSuccess?: (
    updatedEntityTemplate: PublicHydratedEntityTemplate<TemplatableEntity>
  ) => void;
};
const EntityTemplateProposalReadView: FC<Props> = ({
  entityTemplate,
  onUpdateEntityTemplateSuccess,
}: Props): JSX.Element | null => {
  if (!entityTemplate) return null;

  const validSummaryMessages = useMemo(() => {
    return (
      entityTemplate.data.summaryMessages?.filter((x) => x.message?.length) ||
      []
    );
  }, [entityTemplate.data.summaryMessages]);

  return (
    <div className="flex flex-col gap-6">
      {!!entityTemplate?.data?.introduction && (
        <div>
          <SectionTitle>Intro</SectionTitle>
          <SectionDivider />
          <RichTextarea readOnly value={entityTemplate.data.introduction} />
        </div>
      )}
      {!!entityTemplate?.data?.notes && (
        <div>
          <SectionTitle>Notes</SectionTitle>
          <SectionDivider />
          <RichTextarea readOnly value={entityTemplate.data.notes} />
        </div>
      )}
      {!isNullish(entityTemplate?.data?.validForDays) && (
        <div>
          <SectionTitle>Valid for (days)</SectionTitle>
          {entityTemplate.data.validForDays}
        </div>
      )}
      {entityTemplate.data.paymentSchedule && (
        <div>
          <SectionTitle>Payment Schedule</SectionTitle>
          <PaymentScheduleDefinitionReadView
            paymentSchedule={entityTemplate.data.paymentSchedule}
          />
        </div>
      )}
      {!!validSummaryMessages.length && (
        <div>
          <div>
            <SectionTitle>
              Payment summary{' '}
              {validSummaryMessages.length === 1 ? 'message' : 'messages'}
            </SectionTitle>
            <SectionDivider />
            <div className="flex flex-col gap-2">
              {validSummaryMessages.map((message, index) => (
                <Alert
                  iconName={'info-circle.3'}
                  key={index}
                  colour={
                    message.level === 'info'
                      ? EAlertColour.SOFT_BLUE
                      : EAlertColour.SOFT_YELLOW
                  }
                >
                  {message.message}
                </Alert>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default EntityTemplateProposalReadView;

export const SectionDivider: FC = () => {
  return <hr className="m-0 my-2" />;
};

export const SectionTitle: FC<PropsWithChildren> = ({ children }) => {
  return <h3 className="mb-0 text-sm">{children}</h3>;
};
