/**
 * PreviewDocumenActionButtons
 * - This is currently specific to form documents - This will need to be updated if ever used for other documents
 */
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import * as fileDownloadActions from '@payaca/store/fileDownload/fileDownloadActions';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import IconButton from '@payaca/components/button/IconButton';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import Modal from '@payaca/components/modal/Modal';
import { SendSimpleCustomEmailControl } from '@/ui/components/sendSimpleCustomEmailControl/SendSimpleCustomEmailControl';
import * as documentActions from '@payaca/store/documents/documentActions';

import { FileDownloadTypes } from '@payaca/types/fileDownloadTypes';
import { DocumentActionType } from '@payaca/types/documentActionsTypes';

import { getAvailableActionsForDocument } from '@payaca/helpers/documentActionsHelper';

import {
  getCustomerByDealId,
  getDocument,
  getUserRoles,
} from '@/utils/stateAccessors';
import { downloadPdfData } from '@payaca/utilities/fileUtilities';

import './PreviewDocumentActionButtons.sass';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { useSelector } from '@/api/state';

type Props = {
  documentId: number;
  taskId: number | null;
};

const PreviewDocumentActionButtons: FunctionComponent<Props> = ({
  documentId,
  taskId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [showEditDocumentWarning, setShowEditDocumentWarning] = useState(false);
  const [isErrorEditing, setIsErrorEditing] = useState(false);

  const document = useSelector((state) => {
    return getDocument(state, documentId);
  });

  const customer = useSelector((state) => {
    return document?.dealId
      ? getCustomerByDealId(state, document.dealId)
      : undefined;
  });

  const userRoles = useSelector(getUserRoles);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const isSendingDocument = useSelector(
    (state) => state.documents.isSendingDocument
  );

  const isEditingDocument = useSelector(
    (state) => state.documents.isEditingDocument
  );

  // @ts-ignore
  const formId = useMemo(() => document?.formInstances?.[0]?.id, [document]);

  const conditionalText =
    document?.title === 'Job Report'
      ? ''
      : `\n\nThere is an expiry date listed on your certificate. We will get in touch nearer the time to book in your renewal.`;

  const triggerFileDownload = useCallback(
    (fileContent: string) => {
      downloadPdfData(fileContent, document?.title || 'Document');
      setIsDownloadingPdf(false);
    },
    [document]
  );

  const availableActions: DocumentActionType[] = useMemo(
    () => getAvailableActionsForDocument(userRoles, document),
    [document]
  );

  const downloadAsPdfIconButton = useMemo(() => {
    if (!document) return;
    if (document.template?.type === 'pdf') {
      const previewToken = document.formInstances?.at(0)?.previewToken;
      if (!previewToken) {
        // something went wrong and we shouldn't be here
        return null;
      }
      return (
        <a
          href={`${
            import.meta.env.VITE_API_HOST
          }/provider/rest/forms/revisions/${previewToken}/pdf`}
        >
          <IconButton label="Download as pdf" icon="Download" />
        </a>
      );
    }
    return (
      <IconButton
        isProcessing={isDownloadingPdf}
        onClick={() => {
          setIsDownloadingPdf(true);
          dispatch(
            fileDownloadActions.requestDownloadFile(
              documentId,
              FileDownloadTypes.DOCUMENT,
              triggerFileDownload
            )
          );
        }}
        label="Download as pdf"
        icon="Download"
      />
    );
  }, [dispatch, documentId, document, triggerFileDownload, isDownloadingPdf]);

  const handleSend = useCallback(
    ({
      sendToEmail,
      emailBody,
      sendACopy,
    }: {
      sendToEmail: string;
      emailBody: string;
      sendACopy: boolean;
    }) => {
      dispatch(
        documentActions.requestSendDocument(
          documentId,
          sendToEmail,
          emailBody,
          sendACopy,
          () => setIsSent(true)
        )
      );
    },
    [documentId]
  );

  const editDocument = useCallback(() => {
    dispatch(
      documentActions.requestEditDocument(documentId, (error, response) => {
        if (!error) {
          history.push(
            `/forms/${response?.formInstances?.[0]
              .previewToken}/menu?dealId=${document?.dealId}&taskId=${
              taskId || ''
            }`
          );
        } else {
          setIsErrorEditing(true);
        }
      })
    );
  }, [document?.dealId, documentId, taskId]);

  const onCloseEditDocumentWarningModal = useCallback(() => {
    setIsErrorEditing(false);
    setShowEditDocumentWarning(false);
  }, []);

  if (!document) return null;

  if (isProcessing)
    return (
      <div className="preview-job-action-buttons loader-container">
        <MiniLoader />
      </div>
    );

  return (
    <>
      <div className="preview-job-action-buttons">
        <div className="preview-job-action-buttons-inner">
          {availableActions.includes(DocumentActionType.SEND_A_COPY) &&
            formId && (
              <IconButton
                onClick={() => setShowSendModal(true)}
                label="Send a copy"
                icon="Message"
              />
            )}
          {availableActions.includes(DocumentActionType.PRINT) &&
            downloadAsPdfIconButton}
          {availableActions.includes(DocumentActionType.EDIT) && formId && (
            <IconButton
              onClick={() => {
                setShowEditDocumentWarning(true);
              }}
              label="Edit"
              icon="Edit"
            />
          )}
        </div>
      </div>

      {/* Send document modal */}
      <Modal
        isOpen={showSendModal}
        onClose={() => {
          setShowSendModal(false);
          setIsSent(false);
        }}
        title={'Send a copy'}
      >
        {isSent ? (
          <p>A PDF copy of the document has been successfully sent.</p>
        ) : (
          <SendSimpleCustomEmailControl
            customer={customer}
            allowCustomEmailTemplate={true}
            initialSubject={`${account.companyName} has sent you a form`}
            initialEmailBody={`\n\nYour ${document?.title} is complete!${conditionalText}\n\nPlease find your pdf document attached.\n\nThanks,\n\n${account.companyName}`.trim()}
            onSend={handleSend}
            isSending={isSendingDocument}
          />
        )}
      </Modal>

      {/* Edit document warning modal */}
      <Modal
        isOpen={showEditDocumentWarning}
        title="Edit document"
        onClose={onCloseEditDocumentWarningModal}
        actions={
          <>
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              onClick={editDocument}
              isProcessing={isEditingDocument}
            >
              Proceed to edit
            </Button>
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={onCloseEditDocumentWarningModal}
            >
              Cancel
            </Button>
          </>
        }
      >
        <p>
          Editing this copy will create a new revision, are you sure you want to
          edit?
        </p>
        {isErrorEditing && (
          <ErrorMessage message="Sorry, there was an issue editing your form. Please try again." />
        )}
      </Modal>
    </>
  );
};

export default PreviewDocumentActionButtons;
