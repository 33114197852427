import { Tag } from '@payaca/types/tagTypes';

export interface State {
  tags: Tag[];
  isGettingTags: boolean;

  isRemovingTagFromEntity: boolean;
  isAddingExistingTagToEntity: boolean;
}

export enum ActionType {
  REQUEST_GET_TAGS = 'tags.requestGetTags',
  GET_TAGS_SUCCESS = 'tags.getTagsSuccess',
  GET_TAGS_FAILURE = 'tags.getTagsFailure',
  CLEAR_TAGS = 'tags.clearTags',

  REQUEST_REMOVE_TAG_FROM_ENTITY = 'tags.requestRemoveTagFromEntity',
  REMOVE_TAG_FROM_ENTITY_SUCCESS = 'tags.removeTagFromEntitySuccess',
  REMOVE_TAG_FROM_ENTITY_FAILURE = 'tags.removeTagFromEntityFailure',

  REQUEST_ADD_EXISTING_TAG_TO_ENTITY = 'tags.requestAddExistingTagToEntity',
  ADD_EXISTING_TAG_TO_ENTITY_SUCCESS = 'tags.addExistingTagToEntitySuccess',
  ADD_EXISTING_TAG_TO_ENTITY_FAILURE = 'tags.addExistingTagToEntityFailure',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}
