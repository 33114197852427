import { getSupplier } from '@/utils/stateAccessors';
import { faGlobe, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { requestGetSupplier } from '@payaca/store/suppliers/suppliersActions';
import React, { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import './SupplierContactInformationControl.sass';
import CreateEditSupplierModal from '@/ui/components/createEditSupplierModal/CreateEditSupplierModal';
import SupplierContactInformation from '../supplierContactInformation/SupplierContactInformation';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import CreateArea from '@payaca/components/clickableArea/CreateArea';
import { useSelector } from '@/api/state';

type Props = {
  supplierId: number;
};
const SupplierContactInformationControl: FC<Props> = ({
  supplierId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const [showEditSupplierModal, setShowEditSupplierModal] = useState(false);

  const supplier = useSelector((state) => getSupplier(state, supplierId));

  const hasAnySupplierContactInformation = useMemo(() => {
    if (!supplier) return false;

    if (
      supplier.emailAddress?.length ||
      supplier.websiteUrl?.length ||
      supplier.phoneNumber?.length
    )
      return true;

    if (
      supplier.addresses?.some(
        (x: any) => !!getAddressAsString(x.address)?.length
      )
    )
      return true;

    return false;
  }, [supplier]);

  if (!supplier) return null;
  return (
    <div className="supplier-contact-information-control">
      {hasAnySupplierContactInformation && (
        <>
          <SupplierContactInformation supplierId={supplierId} />
          {!supplier?.isSystemManaged && (
            <div className="actions-container">
              <span
                className="edit-supplier-trigger"
                onClick={() => setShowEditSupplierModal(true)}
              >
                <FontAwesomeIcon icon={faPen} />
              </span>
            </div>
          )}
        </>
      )}
      {!hasAnySupplierContactInformation && !supplier?.isSystemManaged && (
        <CreateArea onClick={() => setShowEditSupplierModal(true)}>
          Add contact information
        </CreateArea>
      )}
      <CreateEditSupplierModal
        isOpen={showEditSupplierModal}
        onClose={() => setShowEditSupplierModal(false)}
        supplier={supplier}
        onPersistSupplierSuccess={() =>
          dispatch(requestGetSupplier(supplier.id))
        }
      />
    </div>
  );
};

export default SupplierContactInformationControl;
