import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import React, { FC, useState } from 'react';
import { CreateEditDistributionBoardModal } from './CreateEditDistributionBoardModal';
import { CreateEditFinalCircuitModal } from './CreateEditFinalCircuitModal';
import { FinalCircuitSummary } from './FinalCircuitSummary';

import './DistributionBoardSummary.sass';
import {
  DistributionBoard,
  FinalCircuit,
  ElectricalFormVersion,
} from '@payaca/types/electrical-forms-types';

type Props = {
  version: ElectricalFormVersion;
  distributionBoard: DistributionBoard;
  onPersist: (db: DistributionBoard) => void;
  onDelete: () => void;
};

export const DistributionBoardSummary: FC<Props> = ({
  distributionBoard,
  onPersist,
  onDelete,
  version,
}) => {
  const [
    shouldShowEditDistributionBoardModal,
    setShouldShowDistributionBoardModal,
  ] = useState(false);

  const handleEditButtonClicked = () => {
    setShouldShowDistributionBoardModal(true);
  };

  const handleDeleteButtonClicked = () => {
    onDelete();
  };

  const handlePersistDistributionBoard = (x: DistributionBoard) => {
    onPersist(x);
  };

  const handleCreateCircuit = (circuit: FinalCircuit) => {
    onPersist({
      ...distributionBoard,
      circuits: [...distributionBoard.circuits, circuit],
    });
  };

  const handlePersistCircuit = (circuit: FinalCircuit, index: number) => {
    const newArr = [...(distributionBoard?.circuits ?? [])];
    newArr[index] = circuit;
    onPersist({
      ...distributionBoard,
      circuits: newArr,
    });
  };

  const handleDeleteCircuit = (index: number) => {
    const newArr = [...(distributionBoard?.circuits ?? [])];
    newArr.splice(index, 1);
    onPersist({
      ...distributionBoard,
      circuits: newArr,
    });
  };

  const handleDuplicateCircuit = (c: FinalCircuit) => {
    onPersist({
      ...distributionBoard,
      circuits: [
        ...(distributionBoard?.circuits ?? []),
        {
          ...c,
          number: `${c.number} copy`,
        },
      ],
    });
  };

  const [
    shouldShowCreateEditCircuitModal,
    setShouldShowCreateEditCircuitModal,
  ] = useState(false);

  const handleAddCircuitButtonClicked = () => {
    setShouldShowCreateEditCircuitModal(true);
  };

  return (
    <article className="distribution-board-summary">
      <header>
        <h2>{distributionBoard.designation}</h2>
        <p>{distributionBoard.location}</p>
        <Button
          onClick={handleEditButtonClicked}
          styleVariant={ButtonStyleVariant.ANCHOR}
        >
          Edit
        </Button>
        <Button
          onClick={handleDeleteButtonClicked}
          styleVariant={ButtonStyleVariant.ANCHOR}
        >
          Delete
        </Button>
      </header>
      <section>
        <header className="final-circuits-header">
          <h3>Final circuits</h3>
          <Button onClick={handleAddCircuitButtonClicked}>Add circuit</Button>
        </header>

        {(!!distributionBoard.circuits.length && (
          <div className="final-circuits-table-container">
            <table className="data-table">
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Description</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {distributionBoard.circuits.map((circuit, idx) => (
                  <FinalCircuitSummary
                    key={idx}
                    circuit={circuit}
                    onPersist={(c) => handlePersistCircuit(c, idx)}
                    onDelete={() => handleDeleteCircuit(idx)}
                    onDuplicate={(c) => handleDuplicateCircuit(c)}
                    version={version}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )) || <p>No circuits added yet</p>}
      </section>

      <CreateEditDistributionBoardModal
        version={version}
        distributionBoard={distributionBoard}
        isOpen={shouldShowEditDistributionBoardModal}
        onClose={() => setShouldShowDistributionBoardModal(false)}
        onPersist={handlePersistDistributionBoard}
      />
      <CreateEditFinalCircuitModal
        isOpen={shouldShowCreateEditCircuitModal}
        onClose={() => setShouldShowCreateEditCircuitModal(false)}
        onPersist={handleCreateCircuit}
        version={version}
      />
    </article>
  );
};
