// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import { GetLineItemsInput, PaginationInput } from '../../../gql/graphql';

const lineItemKeys = {
  all: ['lineItem'] as const,
  lineItem: (lineItemId: string) => [...lineItemKeys.all, lineItemId] as const,
  lineItems: (pagination?: PaginationInput, input?: GetLineItemsInput) => {
    if (!pagination) {
      return [...lineItemKeys.all, 'lineItems'] as const;
    }

    if (!input) {
      return [...lineItemKeys.all, 'lineItems', pagination] as const;
    }

    return [...lineItemKeys.all, 'lineItems', pagination, input] as const;
  },
};

export default lineItemKeys;
