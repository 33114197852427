import {
  take,
  takeEvery,
  call,
  put,
  race,
  delay,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import {
  SagaConfig,
  ActionType,
  PersistLineItemRequestData,
  DuplicateLineItemAction,
} from './lineItemsTypes';
import {
  clearLineItems,
  duplicateLineItem,
  getLineItemFailure,
  getLineItemsForLineItemGroupSuccess,
  getLineItemSuccess,
  getLineItemWithRelatedEntitiesSuccess,
  persistLineItemFailure,
  persistLineItemSuccess,
  requestGetLineItem,
} from './lineItemsActions';
import { LineItem } from '@payaca/types/lineItemTypes';

import { Req } from '@payaca/helpers/storeHelper';
import { handleAsyncAction } from '../utils';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

export function* getLineItem(lineItemId: number) {
  yield put(requestGetLineItem(lineItemId));

  yield take([
    ActionType.GET_LINE_ITEM_SUCCESS,
    ActionType.GET_LINE_ITEM_FAILURE,
  ]);
}

const lineItemsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
  storage,
  storageKeys = {
    taxRateId: 'payaca-taxRateId',
  },
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  // GET LINE ITEM
  function* handleGetLineItem(
    action: PayloadAction<
      ActionType.REQUEST_GET_LINE_ITEM,
      {
        lineItemId: number;
        callback: (lineItem: LineItem) => void;
        errorCallback?: (error: any) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getLineItem, action.payload.lineItemId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get line item timed out.';
        yield put(getLineItemFailure(new Error(errorMessage), errorMessage));
      } else {
        action.payload.callback && action.payload.callback(response);
        yield put(getLineItemSuccess(response));
      }
    } catch (error: any) {
      yield put(getLineItemFailure(error, error.message));
      action.payload?.errorCallback?.(error.message);
    }
  }

  function* handleGetLineItemsForLineItemGroup(
    action: PayloadAction<
      ActionType.REQUEST_GET_LINE_ITEMS_FOR_LINE_ITEM_GROUP,
      {
        lineItemGroupId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getLineItemsForLineItemGroup,
          action.payload.lineItemGroupId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getLineItemsForLineItemGroup request timed out');
      }

      yield put(getLineItemsForLineItemGroupSuccess(response));
    } catch (error: any) {}
  }

  const getLineItemsForLineItemGroup = async (lineItemGroupId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/line_items/line_item_group/${lineItemGroupId}`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getLineItemsForLineItemGroup failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handlePersistLineItem(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_LINE_ITEM,
      {
        persistLineItemRequestData: PersistLineItemRequestData;
        callback?: (lineItemId: number) => void;
        errorCallback?: (error: string) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          persistLineItem,
          action.payload.persistLineItemRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          persistLineItemFailure(new Error('Persist line item timed out.'))
        );
      } else {
        yield put(persistLineItemSuccess());
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(persistLineItemFailure(error as Error));
      action.payload?.errorCallback?.(error.message);
    }
  }

  async function persistLineItem(
    persistLineItemRequestData: PersistLineItemRequestData
  ) {
    if (!persistLineItemRequestData?.id) {
      // save tax rate to storage to use as default for next item created
      storage.setItem(
        storageKeys.taxRateId,
        (persistLineItemRequestData.taxRateId || 0).toString()
      );
    }
    const response = await req.post(`/line_items`, persistLineItemRequestData);
    return await response.json();
  }

  function* handleGetLineItemWithRelatedEntities(
    action: PayloadAction<
      ActionType.REQUEST_GET_LINE_ITEM_WITH_RELATED_ENTITIES,
      { lineItemId: number; callback: () => void }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getLineItemWithRelatedEntities,
          action.payload.lineItemId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get line item timed out.';
        yield put(getLineItemFailure(new Error(errorMessage), errorMessage));
      } else {
        yield put(getLineItemWithRelatedEntitiesSuccess(response));
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(getLineItemFailure(error, error.message));
    }
  }

  const getLineItemWithRelatedEntities = async (lineItemId: number) => {
    const response = await req.get(
      `/line_items/${lineItemId}/with_related_entities`
    );
    return response.json();
  };

  // LOGOUT
  function* handleAppLogout() {
    yield put(clearLineItems());
  }

  const getLineItem = async (lineItemId: number) => {
    const response = await req.get(`/line_item/${lineItemId}`);
    return await response.json();
  };

  const handleDuplicateLineItemRequest =
    handleAsyncAction<DuplicateLineItemAction>(
      duplicateLineItem,
      async (payload) => {
        const response = await req.post(
          `/line_items/${payload.itemId}/duplicate`,
          {}
        );
        return response.json();
      },
      (newItemId, payload) => {
        payload.payload?.callback?.(newItemId as number);
      }
    );

  return function* () {
    yield takeEvery(ActionType.REQUEST_GET_LINE_ITEM, handleGetLineItem);
    yield takeEvery(
      ActionType.REQUEST_GET_LINE_ITEM_WITH_RELATED_ENTITIES,
      handleGetLineItemWithRelatedEntities
    );
    yield takeEvery(
      ActionType.REQUEST_GET_LINE_ITEMS_FOR_LINE_ITEM_GROUP,
      handleGetLineItemsForLineItemGroup
    );
    yield takeLatest(
      ActionType.REQUEST_PERSIST_LINE_ITEM,
      handlePersistLineItem
    );
    yield takeLatest(
      ActionType.DUPLICATE_LINE_ITEM_REQUEST,
      handleDuplicateLineItemRequest
    );

    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default lineItemsSagaCreator;
