import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { GetTasksInput, PaginationInput } from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import taskKeys from './keyFactory';

const GetTasks = graphql(`
  query Tasks($input: GetTasksInput, $pagination: PaginationInput!) {
    tasks(input: $input, pagination: $pagination) {
      items {
        __typename
        ... on IEntity {
          id
        }
        createdBy {
          id
        }
        name
        deadline
        completionStatus
        assignee {
          id
          firstName
          lastName
          fullName
          colour
          email
          avatarUrl
        }
        project {
          id
          reference
          customer {
            id
            name
          }
        }
        events {
          id
          name
          customer {
            id
            name
          }
        }
      }
      offset
      totalCount
      limit
    }
  }
`);

const useGetTasks = (pagination?: PaginationInput, input?: GetTasksInput) => {
  const { data, ...rest } = useQuery({
    queryKey: taskKeys.tasks(input, pagination),
    queryFn: () => {
      return gqlClient.request(GetTasks, {
        input,
        pagination: pagination || PAGINATION_ARG_DEFAULTS,
      });
    },
  });
  return { tasks: data?.tasks, data, ...rest };
};

export default useGetTasks;
