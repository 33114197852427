import React, { useState } from 'react';

import { PublicEntityTemplate } from '@payaca/types/entity-templates';
import {
  PublicHydratedServicePlanCommitment,
  ServicePlan,
} from '@payaca/types/service-plans';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

import { Nullish } from '@payaca/utilities/types';
import CreateEditScheduledEventTemplateControl from '../createEditScheduledEventTemplateControl/CreateEditScheduledEventTemplateControl';
import CreateEditTaskTemplateControl from '../createEditTaskTemplateControl/CreateEditTaskTemplateControl';
import ServicePlanCommitmentControl from './ServicePlanCommitmentControl';
import './ServicePlanCommitmentsControl.sass';
import {
  CreateServicePlanCommitmentInput,
  UpdateServicePlanCommitmentInput,
} from '@payaca/store/servicePlans/servicePlansTypes';
import CreateEditEntityTemplateModal from '../createEditEntityTemplateModal/CreateEditEntityTemplateModal';

export type ControllableServicePlanCommitment<
  TEntityType extends 'task' | 'scheduledEvent',
> = {
  publicId?: Nullish<PublicHydratedServicePlanCommitment['publicId']>;
  name?: Nullish<PublicHydratedServicePlanCommitment['name']>;
  description?: Nullish<PublicHydratedServicePlanCommitment['description']>;
  scalesWithQuantity?: Nullish<
    PublicHydratedServicePlanCommitment['scalesWithQuantity']
  >;
  entityTemplate: {
    publicId?: Nullish<PublicEntityTemplate<TEntityType>['publicId']>;
    entityType: PublicEntityTemplate<TEntityType>['entityType'];
    data: PublicEntityTemplate<TEntityType>['data'];
  };
};

type Props = {
  commitments: Array<
    ControllableServicePlanCommitment<'task' | 'scheduledEvent'>
  >;
  onChangeCommitment: (
    index: number,
    commitment: Omit<UpdateServicePlanCommitmentInput, 'publicId'> & {
      publicId?: Nullish<UpdateServicePlanCommitmentInput['publicId']>;
    }
  ) => Promise<void>;
  onAddCommitment: (
    commitment: Omit<CreateServicePlanCommitmentInput, 'servicePlanPublicId'>
  ) => Promise<void>;
  onRemoveCommitment: (index: number) => Promise<void>;
};

const ServicePlanCommitmentsControl = ({
  commitments,
  onChangeCommitment,
  onAddCommitment,
  onRemoveCommitment,
}: Props) => {
  const [showScheduledEventTemplateModal, setShowScheduledEventTemplateModal] =
    useState(false);
  const [showTaskTemplateModal, setShowTaskTemplateModal] = useState(false);

  return (
    <div className="service-plan-commitments-control">
      {!!commitments?.length && (
        <div className="service-plan-commitments-container">
          {commitments.map((commitment, index) => {
            return (
              <ServicePlanCommitmentControl
                key={`service-plan-commitment-control-${index}`}
                commitment={commitment}
                onChange={(commitment) => onChangeCommitment(index, commitment)}
                onRemove={() => onRemoveCommitment(index)}
              />
            );
          })}
        </div>
      )}
      {/* add commitments */}
      <div className="buttons-container">
        <Button
          styleVariant={ButtonStyleVariant.ANCHOR}
          onClick={() => setShowScheduledEventTemplateModal(true)}
        >
          Add Event with Tasks
        </Button>
        <Button
          styleVariant={ButtonStyleVariant.ANCHOR}
          onClick={() => setShowTaskTemplateModal(true)}
        >
          Add unlinked Tasks
        </Button>
      </div>

      <CreateEditEntityTemplateModal
        isOpen={showScheduledEventTemplateModal}
        onClose={() => setShowScheduledEventTemplateModal(false)}
        onRequestSave={(t) => {
          setShowScheduledEventTemplateModal(false);
          onAddCommitment({
            scalesWithQuantity: true,
            entityTemplate:
              t as CreateServicePlanCommitmentInput['entityTemplate'],
          });
        }}
        allowModifyTemplateNameDescription={false}
        template={{
          entityType: 'scheduledEvent',
          data: {},
        }}
      />
      <CreateEditEntityTemplateModal
        isOpen={showTaskTemplateModal}
        onClose={() => setShowTaskTemplateModal(false)}
        onRequestSave={(t) => {
          setShowTaskTemplateModal(false);
          onAddCommitment({
            scalesWithQuantity: true,
            entityTemplate:
              t as CreateServicePlanCommitmentInput['entityTemplate'],
          });
        }}
        allowModifyTemplateNameDescription={false}
        template={{
          entityType: 'task',
          data: {},
        }}
      />
    </div>
  );
};

export default ServicePlanCommitmentsControl;
