import { ListedUserListViewPage } from '@payaca/types/listedUserTypes';

export interface State {
  isGettingListedUsersPage: boolean;
  listedUsersPage: ListedUserListViewPage | null;
}

export enum ActionType {
  REQUEST_GET_LISTED_USERS_PAGE = 'listedUsers.requestGetListedUsersPage',
  GET_LISTED_USERS_PAGE_SUCCESS = 'listedUsers.getListedUsersPageSuccess',
  GET_LISTED_USERS_PAGE_FAILURE = 'listedUsers.getListedUsersPageFailure',
  CLEAR_LISTED_USERS_PAGE = 'listedUsers.clearListedUsersPage',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
}

export interface GetListedUsersRequestData {
  pageSize: number;
  pageNumber: number;
  includeDeactivatedUsers: boolean;
  searchTerm?: string;
}
