import { takeLatest, takeEvery } from 'redux-saga/effects';

import { DefaultSagaConfig } from '../types';
import { handleAsyncAction } from '../utils';

import {
  getTaxRates,
  createTaxRate,
  updateTaxRate,
  deleteTaxRate,
  updateTaxRatesAccountingIntegrationIdentifiers,
} from './actions';
import { ActionType } from './types';
import { TaxRate } from '@payaca/types/taxRateTypes';
import { Req } from '@payaca/helpers/storeHelper';

const taxRatesSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: DefaultSagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  const handleGetTaxRates = handleAsyncAction(getTaxRates, async () => {
    const authHeader = await getAuthHeader();
    const response = await fetch(`${apiBaseurl}/api/tax-rates`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
        'X-Native-App': `${isNativeApp}`,
      },
    });
    if (response.ok) {
      return (await response.json()) as Array<TaxRate>;
    } else {
      throw new Error('Failed to fetch tax rates');
    }
  });

  const handleCreateTaxRate = handleAsyncAction(
    createTaxRate,
    async (data) => {
      const authHeader = await getAuthHeader();
      const response = await fetch(`${apiBaseurl}/api/tax-rates`, {
        method: 'POST',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
          'X-Native-App': `${isNativeApp}`,
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        return (await response.json()) as TaxRate;
      } else {
        const body: { error: string; message: string; statusCode: number } =
          await response.json();
        throw new Error(body.message || 'Failed to create tax rate');
      }
    },
    (_taxRate, { meta: callback }) => callback(),
    (err, { meta: callback }) => callback(err)
  );

  const handleUpdateTaxRate = handleAsyncAction(
    updateTaxRate,
    async ({ id, ...body }) => {
      const authHeader = await getAuthHeader();
      const response = await fetch(`${apiBaseurl}/api/tax-rates/${id}`, {
        method: 'PATCH',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
          'X-Native-App': `${isNativeApp}`,
        },
        body: JSON.stringify(body),
      });
      if (response.ok) {
        return (await response.json()) as TaxRate;
      } else {
        const body: { error: string; message: string; statusCode: number } =
          await response.json();
        throw new Error(body.message || 'Failed to update tax rate');
      }
    },
    (_taxRate, { meta: callback }) => callback(),
    (err, { meta: callback }) => callback(err)
  );

  const handleDeleteTaxRate = handleAsyncAction(
    deleteTaxRate,
    async (id) => {
      const authHeader = await getAuthHeader();
      const response = await fetch(`${apiBaseurl}/api/tax-rates/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
          'X-Native-App': `${isNativeApp}`,
        },
      });
      if (response.ok) {
        return response.json();
      } else {
        const body: { error: string; message: string; statusCode: number } =
          await response.json();
        throw new Error(body.message || 'Failed to delete tax rate');
      }
    },
    (_taxRate, { meta: callback }) => callback(),
    (err, { meta: callback }) => callback(err)
  );

  const handleUpdateTaxRatesAccountingIntegrationIdentifiers =
    handleAsyncAction(
      updateTaxRatesAccountingIntegrationIdentifiers,
      async (args) => {
        return req.post(`/tax-rates/accounting-integration-identifiers`, {
          accountingIntegrationIdentifiers:
            args.accountingIntegrationIdentifiers,
        });
      },
      (_response, requestData) => {
        requestData.payload.callback?.();
      },
      (_error, requestData) => {
        requestData.payload.onErrorCallback?.(_error);
      }
    );

  return function* () {
    yield takeLatest(ActionType.GET_TAX_RATES_REQUEST, handleGetTaxRates);
    yield takeEvery(ActionType.CREATE_TAX_RATE_REQUEST, handleCreateTaxRate);
    yield takeEvery(ActionType.UPDATE_TAX_RATE_REQUEST, handleUpdateTaxRate);
    yield takeEvery(ActionType.DELETE_TAX_RATE_REQUEST, handleDeleteTaxRate);
    yield takeEvery(
      ActionType.UPDATE_TAX_RATES_ACCOUNTING_INTEGRATION_IDENTIFIERS_REQUEST,
      handleUpdateTaxRatesAccountingIntegrationIdentifiers
    );
  };
};

export default taxRatesSagaCreator;
