import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useMemo,
  useRef,
} from 'react';
import { Dialog } from '@headlessui/react';
import Button from '../plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
  EBtnSize,
} from '../plButton/useButtonClassName';
import UntitledIcon from '@payaca/untitled-icons';
import ModalCore, { IModalCoreProps } from './ModalCore';
import WebUtil from '@payaca/shared-web/utilities/index';
import { twMerge } from 'tailwind-merge';
import { nanoid } from 'nanoid';
import clsx from 'clsx';

export interface IProps extends IModalCoreProps {
  title: string;
  onBack?: () => void;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

const Modal: FC<PropsWithChildren<IProps>> = (props) => {
  const {
    title,
    draggable = true,
    className,
    children,
    onBack,
    onClose,
    ...rest
  } = props;

  const uId = useRef(nanoid());

  const isDraggable = useMemo(
    () => (WebUtil.isMobileScreen() ? false : draggable),
    [draggable]
  );

  const sizeClasses = useMemo(() => {
    switch (props.size) {
      case 'sm':
        return 'sm:max-w-lg sm:w-full';
      case 'md':
        return 'sm:max-w-2xl md:w-full';
      case 'lg':
        return 'sm:max-w-4xl lg:w-full';
      case 'xl':
        return 'sm:max-w-7xl lg:w-full';
      default:
        return 'sm:max-w-lg sm:w-full';
    }
  }, [props.size]);

  return (
    <ModalCore
      uId={uId.current}
      draggable={isDraggable}
      className={clsx(
        `flex max-h-full flex-col overflow-hidden rounded-xl border bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:shadow-slate-700/[.7]`,
        sizeClasses,
        className
      )}
      onClose={onClose}
      {...rest}
    >
      {/*Header*/}
      <div
        className={clsx(
          `payaca-modal-handle${uId.current}`,
          `flex flex-col border-b px-4 py-3 dark:border-gray-700`,
          isDraggable && 'cursor-move'
        )}
      >
        {isDraggable && (
          <div className="mx-auto mb-1 h-[4px] w-8 rounded bg-gray-400 bg-opacity-80">
            <span className="sr-only">Drag Modal</span>
          </div>
        )}

        <div className="prose flex items-center gap-2">
          {onBack && (
            <Button
              variant={EBtnVariant.Ghost}
              colour={EBtnColour.Gray}
              size={EBtnSize.XSmall}
              onClick={onBack}
            >
              <span className="sr-only">Back</span>
              <UntitledIcon className="h-5 w-5" name="chevron-left" />
            </Button>
          )}
          <Dialog.Title>{title}</Dialog.Title>
          <Button
            className="ml-auto"
            variant={EBtnVariant.Ghost}
            colour={EBtnColour.Gray}
            size={EBtnSize.Small}
            onClick={() => onClose?.()}
          >
            <span className="sr-only">Close</span>
            <UntitledIcon className="h-3 w-3" name="x-close" />
          </Button>
        </div>
      </div>

      <div className="overflow-y-auto">{children}</div>
    </ModalCore>
  );
};

const ModalBody: FC<PropsWithChildren<{ className?: string }>> = (props) => {
  const { className, children } = props;

  return <div className={twMerge('prose p-4', className)}>{children}</div>;
};

const ModalFooter: FC<PropsWithChildren<{ className?: string }>> = (props) => {
  const { className, children } = props;

  return (
    <div
      className={twMerge(
        'z-sticky sticky bottom-0 border-t bg-white px-4 py-3 dark:border-gray-700',
        className
      )}
    >
      {children}
    </div>
  );
};

const ModalFooterActions: FC<PropsWithChildren<{ className?: string }>> = (
  props
) => {
  const { className, children } = props;

  return (
    <div
      className={twMerge('flex items-center justify-end gap-x-2', className)}
    >
      {children}
    </div>
  );
};

export default Object.assign(Modal, {
  Body: ModalBody,
  Footer: Object.assign(ModalFooter, { Actions: ModalFooterActions }),
});
