import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PublicEntityTemplate } from '@payaca/types/entity-templates';
import { useDispatch } from 'react-redux';
import { SortDirection } from '@payaca/types/listViewTypes';
import './AssigneeGroupedListedTimelogsControl.sass';
import EmptyState from '../emptyState/EmptyState';
import {
  GetListedTimelogsQueryParams,
  SortBy,
  ListedTimelogsPage,
  GetAssigneeGroupedListedTimelogsQueryParams,
  AssigneeGroupedListedTimelogs,
} from '@payaca/types/listedTimelogTypes';
import moment from 'moment-timezone';
import {
  getAssigneeGroupedListedTimelogs,
  getListedTimelogsPage,
  getTimelogTypes,
} from '@payaca/store/timelogs/timelogsActions';
import TimelogCard from '../timelogCard/TimelogCard';
import {
  PublicTimelogType,
  TimelogAssignee,
  TimelogLinkedEntity,
} from '@payaca/types/timelogs';
import { actions as usersActions } from '@/api/users';
import { useSelector } from '@/api/state';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getRegion } from '@/utils/stateAccessors';
import {
  DurationUnit,
  getReadableStringFromDurationString,
} from '@payaca/utilities/timeUtilities';
import { useAccountUsers } from '@/utils/storeHooks';
import TimelogFilters from '../timelogFilters/TimelogFilters';
import AssigneeTimelogsGrouping from '../assigneeTimelogsGrouping/AssigneeTimelogsGrouping';

type Props = {
  entity?: Pick<TimelogLinkedEntity, 'entityType' | 'entityId'>;
  disableFilters?: ('periodStart' | 'periodEnd')[];
  triggerRefreshDataAt?: Date;
};

const AssigneeGroupedListedTimelogsControl: FC<Props> = ({
  entity,
  disableFilters,
  triggerRefreshDataAt,
}): JSX.Element | null => {
  const dispatch = useDispatch();

  const [getListedTimelogsRequestData, setGetListedTimelogsRequestData] =
    useState<GetAssigneeGroupedListedTimelogsQueryParams>({
      periodStart: disableFilters?.includes('periodStart')
        ? undefined
        : moment().startOf('month').toDate(),
      periodEnd: disableFilters?.includes('periodEnd')
        ? undefined
        : moment().endOf('month').toDate(),
      entity: entity,
    });

  const [assigneeGroupedListedTimelogs, setAssigneeGroupedListedTimelogs] =
    useState<AssigneeGroupedListedTimelogs | undefined>(undefined);

  const [isGettingTimelogs, setIsGettingTimelogs] = useState(false);

  const region = useSelector(getRegion);

  const handleGetAssigneeGroupedListedTimelogs = useCallback(
    (queryParams: GetAssigneeGroupedListedTimelogsQueryParams) => {
      setIsGettingTimelogs(true);

      dispatch(
        getAssigneeGroupedListedTimelogs.request({
          queryParams: queryParams,
          callback: (timelogs: AssigneeGroupedListedTimelogs) => {
            setAssigneeGroupedListedTimelogs(timelogs);
            setIsGettingTimelogs(false);
          },
          onErrorCallback: () => {
            setIsGettingTimelogs(false);
          },
        })
      );
    },
    []
  );

  useEffect(() => {
    handleGetAssigneeGroupedListedTimelogs(getListedTimelogsRequestData);
  }, [getListedTimelogsRequestData]);

  useEffect(() => {
    if (triggerRefreshDataAt && triggerRefreshDataAt <= new Date()) {
      handleGetAssigneeGroupedListedTimelogs(getListedTimelogsRequestData);
    }
  }, [triggerRefreshDataAt]);

  return (
    <div className="assignee-grouped-listed-timelogs-control">
      <div className="filters-container">
        <TimelogFilters
          disableFilters={disableFilters}
          onChange={(value) => {
            setGetListedTimelogsRequestData((d) => ({
              ...d,
              ...value,
            }));
          }}
          appliedFilters={{
            periodStart: getListedTimelogsRequestData.periodStart,
            periodEnd: getListedTimelogsRequestData.periodEnd,
            assignees: getListedTimelogsRequestData.assignees,
            typePublicIds: getListedTimelogsRequestData.typePublicIds,
          }}
        />
      </div>
      {assigneeGroupedListedTimelogs && (
        <>
          <div className="filtered-period-totals">
            <h2>Totals</h2>

            {assigneeGroupedListedTimelogs.totalDuration && (
              <div>
                <span className="label">Time spent:</span>{' '}
                <span className="value">
                  {getReadableStringFromDurationString(
                    assigneeGroupedListedTimelogs.totalDuration,
                    { units: [DurationUnit.HOURS, DurationUnit.MINUTES] }
                  )}
                </span>
              </div>
            )}
            <div>
              <span className="label">Cost:</span>{' '}
              <span className="value">
                {currencyPrice(
                  assigneeGroupedListedTimelogs.totalCalculatedCost,
                  region
                )}
              </span>
            </div>
          </div>
          <div className="assignee-grouped-listed-timelogs-container">
            <ul>
              {assigneeGroupedListedTimelogs.assigneeGroupings.map(
                (assigneeGrouping, i) => {
                  return (
                    <li key={i}>
                      <AssigneeTimelogsGrouping
                        assigneeTimelogsGrouping={assigneeGrouping}
                        onArchiveTimelogSuccess={() =>
                          handleGetAssigneeGroupedListedTimelogs(
                            getListedTimelogsRequestData
                          )
                        }
                        onUpdateTimelogSuccess={() =>
                          handleGetAssigneeGroupedListedTimelogs(
                            getListedTimelogsRequestData
                          )
                        }
                      />
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        </>
      )}
      {!isGettingTimelogs &&
        !assigneeGroupedListedTimelogs?.assigneeGroupings?.length && (
          <EmptyState configName={'timelogs'} arrowIndication={false} />
        )}
    </div>
  );
};

export default AssigneeGroupedListedTimelogsControl;
