import React, { useMemo } from 'react';
import { faUsers, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UserIndicator from '@payaca/components/userIndicator/UserIndicator';
import MarkerWrapper from './MarkerWrapper';

import { useAccountUsers } from '../../../utils/storeHooks';

import './UserMarker.sass';

type Props = {
  onClick?: () => void;
  userIds: number[];
  lat: number; // required for positioning
  lng: number; // required for positioning
};
const UserMarker = ({ onClick, userIds }: Props) => {
  const userId = useMemo(() => userIds?.[0], userIds);
  const accountUsers = useAccountUsers();

  const userIcon = useMemo(() => {
    const accountUser = accountUsers.find((x) => x.id === userId);
    if (userIds?.length === 1 && accountUser) {
      return (
        <UserIndicator
          user={{
            firstName: accountUser.firstname,
            lastName: accountUser.lastname,
            emailAddress: accountUser.email,
            userColour: accountUser.userColour,
            imgSrc: accountUser.avatarUrl,
          }}
          renderPointer={(userColour) => (
            <div
              style={{ backgroundColor: userColour }}
              className="triangle"
            ></div>
          )}
        />
      );
    } else if (userIds?.length) {
      return (
        <>
          <div className="multiple-users user-indicator">
            <FontAwesomeIcon icon={faUsers} />
          </div>
          <div className="triangle"></div>
        </>
      );
    } else {
      return (
        <>
          <div className="unassigned-user user-indicator">
            <FontAwesomeIcon icon={faUserSlash} />
          </div>
          <div className="triangle"></div>
        </>
      );
    }
  }, [userIds]);

  return (
    <MarkerWrapper onClick={onClick} className="user-marker">
      {userIcon}
    </MarkerWrapper>
  );
};
export default UserMarker;
