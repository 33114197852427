import Modal from '@payaca/components/plModal/Modal';
import React, { FC } from 'react';
import {
  ScheduledEvent,
  SimpleEventContactWithRoles,
} from '@payaca/types/scheduledEventsTypes';
import { SendEventConfirmationControl } from './SendEventConfirmationControl';

export const SendEventConfirmationModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  scheduledEvent: ScheduledEvent;
  contacts: SimpleEventContactWithRoles[];
}> = ({ isOpen, onClose, scheduledEvent, contacts }) => {
  return (
    <Modal isOpen={isOpen} title={'Send event confirmation'} onClose={onClose}>
      <SendEventConfirmationControl
        scheduledEvent={scheduledEvent}
        contacts={contacts}
        onCancel={onClose}
        onSuccess={onClose}
      />
    </Modal>
  );
};
