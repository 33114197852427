import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@tanstack/react-query';

import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import BasicField from '@payaca/components/basicField/BasicField';
import PrivateField from '@payaca/components/privateField/PrivateField';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import {
  getEmailFieldValidator,
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';

import { REFRESH_TOKEN_KEY, TOKEN_KEY } from '@/api';
import { graphql } from '@/gql';
import { LoginMutationVariables } from '@/gql/graphql';
import { gqlClient } from '@/api/graphql-client';
import { getNewWorldTokenLocalStorageKey } from '@/helpers/localStorageKeyHelper';

import './LoginForm.sass';

type Props = {
  hideForgotPassword: boolean;
  onLoginCallback?: () => void;
};

const initialFormState = {};

const LoginMutation = graphql(`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
    }
  }
`);

const LoginForm: FC<Props> = ({
  hideForgotPassword,
  onLoginCallback,
}: Props): JSX.Element => {
  const history = useHistory();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loginError, setLoginError] = useState<string>();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoggedInSuccessfully, setIsLoggedInSuccessfully] = useState(false);

  const { mutateAsync: mutateLogin } = useMutation({
    mutationFn: (variables: LoginMutationVariables) => {
      setIsLoggingIn(true);
      setIsSubmitted(true);
      return gqlClient.request(LoginMutation, variables);
    },
  });

  const [formState, setFormState] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (isSubmitted && !isLoggingIn) {
      if (isLoggedInSuccessfully) {
        onLoginCallback && onLoginCallback();
        if (!onLoginCallback) {
          history.push('/');
        }
      } else {
        setLoginError('Email or password not recognised.');
      }
    }
  }, [isSubmitted, isLoggingIn, isLoggedInSuccessfully, onLoginCallback]);

  const onSubmit = useCallback(async () => {
    if (isLoggingIn) return;
    setLoginError(undefined);

    const data = await mutateLogin({
      email: formState.emailAddress,
      password: formState.password,
    }).catch((error) => {
      setIsLoggedInSuccessfully(false);
      setIsLoggingIn(false);
      return;
    });

    if (data?.login) {
      localStorage.setItem(
        getNewWorldTokenLocalStorageKey(),
        data.login.accessToken
      );
      localStorage.setItem(TOKEN_KEY, data.login.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, data.login.accessToken);
      setIsLoggedInSuccessfully(true);
      setIsLoggingIn(false);
    }
  }, [formState, isLoggingIn, mutateLogin, history]);

  const fieldValidators = useMemo(() => {
    const isRequiredFieldValidator = getIsRequiredFieldValidator();
    return {
      emailAddress: [isRequiredFieldValidator, getEmailFieldValidator()],
      password: [isRequiredFieldValidator, getLengthFieldValidator({ min: 6 })],
    };
  }, []);

  const goToForgotPasswordPage = useCallback(() => {
    history.push('/forgotPassword');
  }, [history]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <ValidatedFieldWrapper
            validationResult={validationState['emailAddress']}
            isTouched={touchedState['emailAddress'] || false}
          >
            <BasicField
              styleVariant={InputStyleVariant.OUTSIZE}
              label={'Email'}
              value={formState.emailAddress}
              name="emailAddress"
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              type="email"
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper
            validationResult={validationState['password']}
            isTouched={touchedState['password'] || false}
          >
            <PrivateField
              styleVariant={InputStyleVariant.OUTSIZE}
              label={'Password'}
              value={formState.password}
              name="password"
              onChange={onFieldChange}
              onTouch={onFieldTouch}
            />
          </ValidatedFieldWrapper>
          {!hideForgotPassword && (
            <div className="forgot-password-prompt">
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={goToForgotPasswordPage}
              >
                Forgotten password
              </Button>
            </div>
          )}
          <div className="button-wrapper">
            <Button
              type="submit"
              isDisabled={!isValid}
              isProcessing={isLoggingIn}
              styleVariant={ButtonStyleVariant.OUTSIZE}
              colourVariant={ButtonColourVariant.PRIMARY}
            >
              Log in
            </Button>
          </div>

          {loginError && <ErrorMessage message={loginError} />}
        </>
      );
    },
    [
      hideForgotPassword,
      onSubmit,
      goToForgotPasswordPage,
      isLoggingIn,
      loginError,
    ]
  );

  return (
    <div className="login-form-container">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        onFormSubmit={() => {
          onSubmit();
        }}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
        onFormStateChange={setFormState}
      />
    </div>
  );
};

export default LoginForm;
