import { ProposalsPermissions } from './proposals.permissions';
import { ProposalsRoles } from './proposals.roles';

export const proposalsMappings = {
  [ProposalsPermissions.GET_CHANGE_PROPOSALS]: [ProposalsRoles.VIEWER],
  [ProposalsPermissions.PERSIST_CHANGE_PROPOSAL]: [
    ProposalsRoles.PERSISTER_SENDER,
  ],
  [ProposalsPermissions.SEND_CHANGE_PROPOSAL]: [
    ProposalsRoles.PERSISTER_SENDER,
  ],
  [ProposalsPermissions.MARK_CHANGE_PROPOSAL_ACCEPTED]: [
    ProposalsRoles.MANAGER,
  ],
  [ProposalsPermissions.MARK_CHANGE_PROPOSAL_DECLINED]: [
    ProposalsRoles.MANAGER,
  ],
  [ProposalsPermissions.VOID_CHANGE_PROPOSAL]: [ProposalsRoles.MANAGER],
};
