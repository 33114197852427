import { useQuery } from '@tanstack/react-query';

import { apiFetch } from '@/utils/apiFetch';
import { SearchDevice } from '@payaca/types/deviceTypes';

const useDeviceSearch = (
  name: string,
  deviceType: string,
  searchTerm: string,
  phaseCode?: string | number
) => {
  return useQuery({
    queryKey: ['device-search', name],
    queryFn: async (): Promise<SearchDevice[]> => {
      const response = await apiFetch(
        '/provider/rest/ena/connection-application/device-search?' +
          new URLSearchParams({
            deviceType,
            phases: phaseCode ? `${phaseCode}` : '',
            searchTerm,
          }).toString(),
        {
          method: 'GET',
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch device search results');
      }

      const data = (await response.json()) as SearchDevice[];

      return data;
    },
  });
};

export default useDeviceSearch;
