import { Reducer } from 'redux';

import { ActionType, State } from './appTypes';

export const initialState: State = {
  loaded: false,
  currentTab: null,
  banner: null,
  dismissMobileMode: localStorage.getItem('payacaDismissMobileMode') || false,
  modal: null,
  showNavbar: false,
  maintenanceSchedule: {},
  hideMaintenanceBanner: true,
  lastReadFutureMaintenaceMessageDownTimeAt: null,
  navigationSidebarIsHidden: false,
  navigationSidebarIsCollapsed: false,
  navigationSidebarAutoExpandedAfterDeploy: false,

  videoOverlay: null,
  hasHiddenWelcomeVideo: false,
};

const appReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_NAVIGATION_SIDEBAR_AUTO_EXPANDED_AFTER_DEPLOY:
      return { ...state, navigationSidebarAutoExpandedAfterDeploy: true };
    case ActionType.SHOW_NAVIGATION_SIDEBAR:
      return { ...state, navigationSidebarIsHidden: false };
    case ActionType.HIDE_NAVIGATION_SIDEBAR:
      return { ...state, navigationSidebarIsHidden: true };

    case ActionType.EXPAND_NAVIGATION_SIDEBAR:
      return { ...state, navigationSidebarIsCollapsed: false };
    case ActionType.COLLAPSE_NAVIGATION_SIDEBAR:
      return { ...state, navigationSidebarIsCollapsed: true };

    case ActionType.STORE_PREVIOUS_PATHNAME: {
      return { ...state, previousPathname: action.pathname };
    }

    case ActionType.STORE_PATHNAME: {
      return { ...state, pathname: action.pathname };
    }

    case ActionType.TOGGLE_SHOW_NAVBAR: {
      return { ...state, showNavbar: !state.showNavbar };
    }

    case ActionType.HIDE_NAVBAR: {
      return { ...state, showNavbar: false };
    }

    case ActionType.LOADED: {
      return { ...state, loaded: true };
    }

    case ActionType.SHOW_MODAL: {
      return {
        ...state,
        modal: action.payload,
      };
    }

    case ActionType.HIDE_MODAL: {
      return {
        ...state,
        modal: initialState.modal,
      };
    }

    case ActionType.SHOW_BANNER: {
      return {
        ...state,
        banner: action.payload,
      };
    }

    case ActionType.HIDE_BANNER: {
      return {
        ...state,
        banner: initialState.banner,
      };
    }

    case ActionType.DISMISS_MOBILE_MODE: {
      localStorage.setItem('payacaDismissMobileMode', 'true');
      return {
        ...state,
        dismissMobileMode: true,
      };
    }

    case ActionType.HIDE_MAINTENANCE_BANNER: {
      return {
        ...state,
        hideMaintenanceBanner: action.hideMaintenanceBanner,
      };
    }

    case ActionType.REQUEST_GET_MAINTENANCE: {
      return {
        ...state,
        isGettingMaintenance: true,
      };
    }

    case ActionType.GET_MAINTENANCE_SUCCESS: {
      return {
        ...state,
        isGettingMaintenance: false,
        maintenanceSchedule: action.payload,
      };
    }
    case ActionType.GET_MAINTENANCE_FAILURE: {
      return {
        ...state,
        isGettingMaintenance: false,
        maintenanceError: action.maintenanceError,
      };
    }
    case ActionType.SET_LAST_READ_FUTURE_MAINTENACE_MESSAGE_DOWN_TIME_AT: {
      return {
        ...state,
        lastReadFutureMaintenaceMessageDownTimeAt: action.downTimeAt,
      };
    }

    case ActionType.TOGGLE_VIDEO_OVERLAY: {
      return {
        ...state,
        videoOverlay: action.payload,
      };
    }

    case ActionType.HIDE_WELCOME_VIDEO: {
      return {
        ...state,
        hasHiddenWelcomeVideo: true,
      };
    }

    case ActionType.CLEAR_APP: {
      // should we be clearing whole app state?
      return {
        ...state,
        videoOverlay: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default appReducer;
