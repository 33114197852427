import { HeaderTagType } from '../headerTag/HeaderTag';
import React, { FC, useCallback, useEffect, useState } from 'react';
import Tab from './Tab';

import './TabbedView.sass';

type Props = {
  tabs: {
    content: React.ReactNode;
    defaultSelected?: boolean;
    isDisabled?: boolean;
    title: string;
    label: string;
  }[];
  titleHeaderTagType?: HeaderTagType;
  activeTabLabel?: string;
  onChangeTab: (label: string) => void;
};
const TabbedView: FC<Props> = ({
  tabs,
  titleHeaderTagType,
  activeTabLabel,
  onChangeTab,
}: Props): JSX.Element => {
  const renderSelectedTabContent = useCallback(() => {
    const content = tabs?.find((x) => x.label === activeTabLabel)?.content;
    return content;
  }, [activeTabLabel, tabs]);

  return (
    <div className="tabbed-view-container">
      <div className="tabs-wrapper">
        <div className="tabs" role="tablist">
          {tabs.map((tab, i) => {
            return (
              <Tab
                label={tab.label}
                title={tab.title}
                titleHeaderTagType={titleHeaderTagType}
                isActive={tab.label === activeTabLabel}
                isDisabled={tab.isDisabled}
                onTabSelection={() => {
                  onChangeTab(tab.label);
                }}
                key={i}
              />
            );
          })}
        </div>
      </div>
      <div className="tab-content-container" role="tabpanel">
        {/* tab content for selected tab */}
        {renderSelectedTabContent()}
      </div>
    </div>
  );
};

export default TabbedView;
