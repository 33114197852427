import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import { GetLineItemQuery } from '@/gql/graphql';
import lineItemKeys from '@/api/queries/lineItems/keyFactory';

const GET_LINE_ITEM = graphql(`
  query GetLineItem($lineItemId: ID!) {
    lineItem(id: $lineItemId) {
      id
      name
      description
      thumbnailUrl
      price {
        taxRate {
          id
          label
          percentage
        }
        unitPrice {
          currency {
            code
            exponent
            id
          }
          value
        }
        unitPriceExcTax {
          currency {
            code
            exponent
            id
          }
          value
        }
        markupPercentage
        markupAmount {
          currency {
            code
            exponent
            id
          }
          value
        }
        cisDeductionRate
      }
      materials {
        id
        materialQuantity
        material {
          id
          name
          thumbnailUrl
          suppliedBy {
            supplier {
              id
              name
            }
            price {
              unitPriceExcTax {
                value
                currency {
                  code
                  exponent
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`);

const useGetLineItem = (
  opts: {
    lineItemId?: string;
    enabled?: boolean;
  } = {},
  queryOptions?: UseQueryOptions<GetLineItemQuery>
) => {
  return useQuery({
    enabled: opts.enabled || opts.lineItemId !== undefined,
    queryKey: lineItemKeys.lineItem(opts.lineItemId!),
    queryFn: () => {
      return gqlClient.request(GET_LINE_ITEM, {
        lineItemId: opts.lineItemId!,
      });
    },
    ...queryOptions,
  });
};

export default useGetLineItem;
