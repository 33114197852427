import React, { FC, forwardRef, Fragment } from 'react';
import clsx from 'clsx';
import { Transition } from '@headlessui/react';

type AvailableColours =
  | 'black'
  | 'gray'
  | 'teal'
  | 'blue'
  | 'red'
  | 'yellow'
  | 'purple'
  | 'white';

type ProgressBarBaseProps = { progress: number; total: number };

type TProgressBarProps =
  | (ProgressBarBaseProps & { colour?: AvailableColours })
  | (ProgressBarBaseProps & {
      colour: 'custom';
      // use bg-x-xxx class, e.g. bg-green-500
      customColourClass: string;
    });

export interface IProps {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  bars: TProgressBarProps[];
}

const getBarColourStyle = (colour: AvailableColours) => {
  switch (colour) {
    case 'black':
      return 'bg-gray-800 dark:bg-white';
    case 'gray':
      return 'bg-gray-500 dark:bg-gray-500';
    case 'teal':
      return 'bg-teal-500 dark:bg-teal-500';
    case 'blue':
      return 'bg-blue-500';
    case 'red':
      return 'bg-red-500';
    case 'yellow':
      return 'bg-yellow-500';
    case 'purple':
      return 'bg-purple-500';
    case 'white':
      return 'bg-white';
  }
};

const Progress = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { className, size = 'sm', bars = [] } = props;

  let sizeClass = '';
  switch (size) {
    case 'sm':
      sizeClass = 'h-1.5';
      break;
    case 'md':
      sizeClass = 'h-4';
      break;
    case 'lg':
      sizeClass = 'h-6';
      break;
  }

  return (
    <>
      <div
        ref={ref}
        className={clsx(
          'relative flex w-full overflow-hidden rounded-full bg-gray-200 dark:bg-neutral-700',
          sizeClass,
          className
        )}
      >
        <Transition show appear>
          {bars.toReversed().map((bar, index) => (
            <ProgressBar key={`${index}-${bar.progress}`} {...bar} />
          ))}
        </Transition>
      </div>
    </>
  );
});

const ProgressBar: FC<TProgressBarProps> = (props) => {
  const { progress, total, colour } = props;

  let barColour = '';
  if (colour === 'custom') {
    barColour = props.customColourClass;
  } else {
    barColour = getBarColourStyle(colour || 'black');
  }

  const percentage = (progress / total) * 100;

  return (
    <Transition.Child
      appear
      as={Fragment}
      enter="transition-[width] duration-1000"
      enterFrom="!w-0"
    >
      <div
        className={clsx(
          'absolute left-0 top-0 flex h-full flex-col justify-center overflow-hidden whitespace-nowrap rounded-full text-center text-xs text-white',
          barColour
        )}
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={total}
        style={{ width: `${percentage}%` }}
      />
    </Transition.Child>
  );
};

export default Progress;
