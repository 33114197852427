import { Reducer } from 'redux';

import { AnalyticsState, AnalyticsActionTypes } from './analyticsTypes';

export const initialState: AnalyticsState = {
  sentQuotesAggregateChartData: null,
  sentQuoteValueAggregateChartData: null,
  sentInvoicesAggregateChartData: null,
  sentInvoiceValueAggregateChartData: null,
  subscriptionUsageData: null,
  isGettingSubscriptionUsageData: false,
  keyIndicatorsData: null,
};

const analyticsReducer: Reducer<AnalyticsState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AnalyticsActionTypes.GET_SENT_QUOTES_AGGREGATE_CHART_DATA_FAILURE:
    case AnalyticsActionTypes.CLEAR_SENT_QUOTES_AGGREGATE_CHART_DATA:
      return { ...state, sentQuotesAggregateChartData: null };
    case AnalyticsActionTypes.GET_SENT_QUOTES_AGGREGATE_CHART_DATA_SUCCESS:
      return {
        ...state,
        sentQuotesAggregateChartData:
          action.payload.sentQuotesAggregateChartData,
      };
    case AnalyticsActionTypes.GET_SENT_QUOTE_VALUE_AGGREGATE_CHART_DATA_FAILURE:
    case AnalyticsActionTypes.CLEAR_SENT_QUOTE_VALUE_AGGREGATE_CHART_DATA:
      return { ...state, sentQuoteValueAggregateChartData: null };
    case AnalyticsActionTypes.GET_SENT_QUOTE_VALUE_AGGREGATE_CHART_DATA_SUCCESS:
      return {
        ...state,
        sentQuoteValueAggregateChartData:
          action.payload.sentQuoteValueAggregateChartData,
      };
    case AnalyticsActionTypes.GET_SENT_INVOICES_AGGREGATE_CHART_DATA_FAILURE:
    case AnalyticsActionTypes.CLEAR_SENT_INVOICES_AGGREGATE_CHART_DATA:
      return { ...state, sentInvoicesAggregateChartData: null };
    case AnalyticsActionTypes.GET_SENT_INVOICES_AGGREGATE_CHART_DATA_SUCCESS:
      return {
        ...state,
        sentInvoicesAggregateChartData:
          action.payload.sentInvoicesAggregateChartData,
      };
    case AnalyticsActionTypes.GET_SENT_INVOICE_VALUE_AGGREGATE_CHART_DATA_FAILURE:
    case AnalyticsActionTypes.CLEAR_SENT_INVOICE_VALUE_AGGREGATE_CHART_DATA:
      return { ...state, sentInvoiceValueAggregateChartData: null };
    case AnalyticsActionTypes.GET_SENT_INVOICE_VALUE_AGGREGATE_CHART_DATA_SUCCESS:
      return {
        ...state,
        sentInvoiceValueAggregateChartData:
          action.payload.sentInvoiceValueAggregateChartData,
      };
    case AnalyticsActionTypes.REQUEST_GET_SUBSCRIPTION_USAGE_DATA:
      return {
        ...state,
        isGettingSubscriptionUsageData: true,
      };
    case AnalyticsActionTypes.GET_SUBSCRIPTION_USAGE_DATA_FAILURE:
      return {
        ...state,
        subscriptionUsageData: null,
        isGettingSubscriptionUsageData: false,
      };
    case AnalyticsActionTypes.CLEAR_SUBSCRIPTION_USAGE_DATA:
      return {
        ...state,
        subscriptionUsageData: null,
      };
    case AnalyticsActionTypes.GET_SUBSCRIPTION_USAGE_DATA_SUCCESS:
      return {
        ...state,
        isGettingSubscriptionUsageData: false,
        subscriptionUsageData: action.payload.subscriptionUsageData,
      };

    default:
      return state;
  }
};

export default analyticsReducer;
