import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  UpdateEventNoteInput,
  UpdateEventNoteMutation as _UpdateEventNoteMutation,
} from '@/gql/graphql';

const UpdateEventNoteMutation = graphql(`
  mutation UpdateEventNote($input: UpdateEventNoteInput!) {
    updateEventNote(input: $input) {
      id
    }
  }
`);

const useUpdateEventNote = (
  options?: UseMutationOptions<
    _UpdateEventNoteMutation,
    unknown,
    UpdateEventNoteInput
  >
) => {
  return useMutation({
    mutationFn: (input: UpdateEventNoteInput) => {
      return gqlClient.request(UpdateEventNoteMutation, { input });
    },
    ...options,
  });
};

export default useUpdateEventNote;
