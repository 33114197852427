import React, { useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PublicHydratedServicePlanPeriodCommitment } from '@payaca/types/service-plans';

import './ServicePlanPeriodCommitment.sass';
import ServicePlanTaskPeriodCommitment from './ServicePlanPeriodTaskCommitment';
import ServicePlanPeriodUnarrangedEventCommitment from './ServicePlanPeriodUnarrangedEventCommitment';
import ServicePlanPeriodUnarrangedTaskCommitment from './ServicePlanPeriodUnarrangedTaskCommitment';
import ServicePlanPeriodEventCommitment from './ServicePlanPeriodEventCommitment';

type Props = {
  commitment: PublicHydratedServicePlanPeriodCommitment;
  onCommitmentUpdate: (newEntity?: {
    id: number;
    type: 'scheduledEvent' | 'task';
  }) => void;
};
const ServicePlanPeriodCommitment = ({
  commitment,
  onCommitmentUpdate,
}: Props) => {
  if (commitment.entityId) {
    if (commitment.entityType === 'scheduledEvent') {
      return (
        <ServicePlanPeriodEventCommitment
          commitment={commitment}
          onCommitmentUpdate={onCommitmentUpdate}
        />
      );
    } else if (commitment.entityType === 'task') {
      return (
        <ServicePlanTaskPeriodCommitment
          commitment={commitment}
          onCommitmentUpdate={onCommitmentUpdate}
        />
      );
    }
  } else {
    if (commitment.entityType === 'scheduledEvent') {
      return (
        <ServicePlanPeriodUnarrangedEventCommitment
          commitment={commitment}
          onCommitmentUpdate={onCommitmentUpdate}
        />
      );
    } else if (commitment.entityType === 'task') {
      return (
        <ServicePlanPeriodUnarrangedTaskCommitment
          commitment={commitment}
          onCommitmentUpdate={onCommitmentUpdate}
        />
      );
    }
  }

  return null;
};

export default ServicePlanPeriodCommitment;
