import { Reducer } from 'redux';
import { mergeFetchedEntities } from '../utils';

import { ActionType, State } from './notesTypes';

export const initialState: State = {
  isCreatingNote: false,
  hasCreatedNoteSuccessfully: null,
  createNoteErrorMessage: null,

  isUpdatingNote: false,
  hasUpdatedNoteSuccessfully: null,
  updateNoteErrorMessage: null,

  isArchivingNote: false,
  hasArchivedNoteSuccessfully: null,
  archiveNoteErrorMessage: null,

  notes: {},
  isGettingNotes: false,

  isGettingNotesForScheduledEvent: false,
};

const NotesReducer: Reducer<State> = (state = initialState, action): any => {
  switch (action.type) {
    // CREATE NOTE
    case ActionType.REQUEST_CREATE_NOTE:
      return {
        ...state,
        isCreatingNote: true,

        hasCreatedNoteSuccessfully: null,
        hasArchivedNoteSuccessfully: null,
        hasUpdatedNoteSuccessfully: null,
        updateNoteErrorMessage: null,
        archiveNoteErrorMessage: null,
        createNoteErrorMessage: null,
      };
    case ActionType.CREATE_NOTE_SUCCESS: {
      return {
        ...state,
        isCreatingNote: false,
        hasCreatedNoteSuccessfully: true,
        createNoteErrorMessage: null,
      };
    }
    case ActionType.CREATE_NOTE_FAILURE:
      return {
        ...state,
        isCreatingNote: false,
        hasCreatedNoteSuccessfully: false,
        createNoteErrorMessage: action.payload.errorMessage,
      };

    // UDPATE NOTE
    case ActionType.REQUEST_UPDATE_NOTE:
      return {
        ...state,
        isUpdatingNote: true,
        hasCreatedNoteSuccessfully: null,
        hasArchivedNoteSuccessfully: null,
        hasUpdatedNoteSuccessfully: null,
        updateNoteErrorMessage: null,
        archiveNoteErrorMessage: null,
        createNoteErrorMessage: null,
      };
    case ActionType.UPDATE_NOTE_SUCCESS: {
      return {
        ...state,
        isUpdatingNote: false,
        hasUpdatedNoteSuccessfully: true,
        updateNoteErrorMessage: null,
      };
    }
    case ActionType.UPDATE_NOTE_FAILURE:
      return {
        ...state,
        isUpdatingNote: false,
        hasUpdatedNoteSuccessfully: false,
        updateNoteErrorMessage: action.payload.errorMessage,
      };

    // ARHCIVE NOTE
    case ActionType.REQUEST_ARCHIVE_NOTE:
      return {
        ...state,
        isArchivingNote: true,
        hasCreatedNoteSuccessfully: null,
        hasArchivedNoteSuccessfully: null,
        hasUpdatedNoteSuccessfully: null,
        updateNoteErrorMessage: null,
        archiveNoteErrorMessage: null,
        createNoteErrorMessage: null,
      };
    case ActionType.ARCHIVE_NOTE_SUCCESS: {
      return {
        ...state,
        isArchivingNote: false,
        hasArchivedNoteSuccessfully: true,
        archiveNoteErrorMessage: null,
      };
    }
    case ActionType.ARCHIVE_NOTE_FAILURE:
      return {
        ...state,
        isArchivingNote: false,
        hasArchivedNoteSuccessfully: false,
        archiveNoteErrorMessage: action.payload.errorMessage,
      };
    case ActionType.GET_NOTES_FOR_DEAL_REQUEST:
      return {
        ...state,
        isGettingNotes: true,
      };
    case ActionType.GET_NOTES_FOR_DEAL_FAILURE:
      return {
        ...state,
        isGettingNotes: false,
      };

    case ActionType.GET_NOTES_FOR_DEAL_SUCCESS:
    case ActionType.GET_NOTES_FOR_SCHEDULED_EVENT_SUCCESS:
      return {
        ...state,
        notes: mergeFetchedEntities({
          cache: state.notes,
          values: action.payload,
        }),
        isGettingNotes: false,
        isGettingNotesForScheduledEvent: false,
      };

    case ActionType.CLEAR_NOTE:
      return {
        ...state,
        isCreatingNote: false,
        hasCreatedNoteSuccessfully: null,
        createNoteErrorMessage: null,

        isUpdatingNote: false,
        hasUpdatedNoteSuccessfully: null,
        updateNoteErrorMessage: null,

        isArchivingNote: false,
        hasArchivedNoteSuccessfully: null,
        archiveNoteErrorMessage: null,
      };
    case ActionType.GET_NOTES_FOR_SCHEDULED_EVENT_REQUEST:
      return {
        ...state,
        isGettingNotesForScheduledEvent: true,
      };
    case ActionType.GET_NOTES_FOR_SCHEDULED_EVENT_FAILURE:
      return {
        ...state,
        isGettingNotesForScheduledEvent: false,
      };
    case ActionType.CLEAR_NOTES_STORE:
      return initialState;
    default:
      return state;
  }
};

export default NotesReducer;
