import React, { FC, useMemo } from 'react';

import Modal from '@payaca/components/modal/Modal';
import { Task } from '@payaca/types/taskTypes';
import './ViewCompleteTaskModal.sass';
import ViewCompleteTaskControl from '../viewCompleteTaskControl.tsx/ViewCompleteTaskControl';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUpdateTaskCompletionSuccess?: () => void;
  task: Task;
};
const ViewCompleteTaskModal: FC<Props> = ({
  isOpen,
  onClose,
  onUpdateTaskCompletionSuccess,
  task,
}: Props): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      className="view-complete-task-modal"
      title={task.name}
      onClose={onClose}
      size="xs"
    >
      <ViewCompleteTaskControl
        task={task}
        onUpdateTaskCompletionSuccess={onUpdateTaskCompletionSuccess}
      />
    </Modal>
  );
};
export default ViewCompleteTaskModal;
