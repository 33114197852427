import { takeEvery, call, put, race, delay } from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import { SagaConfig, ActionType } from './csvUploadTypes';

import { Req } from '@payaca/helpers/storeHelper';
import {
  confirmContinueProcessing,
  getCsvTemplateFailure,
  getCsvTemplateSuccess,
  getCsvUploads,
  getFileDownloadUrl,
  uploadCsvFailure,
  uploadCsvSuccess,
} from './csvUploadActions';
import { handleAsyncAction } from '../utils';
import {
  CsvUploadDataType,
  CsvUploadMetadata,
} from '@payaca/types/csvUploadTypes';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const csvUploadSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  // GET ITEMS CSV TEMPLATE
  function* handleGetCsvTemplate(
    action: PayloadAction<
      ActionType.REQUEST_GET_CSV_TEMPLATE,
      {
        dataType: CsvUploadDataType;
        // requestParam?: number;
        urlSearchParams?: URLSearchParams;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getCsvUploadTemplate,
          action.payload.dataType,
          action.payload.urlSearchParams
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get csv template timed out.';
        yield put(getCsvTemplateFailure(new Error(errorMessage), errorMessage));
      } else {
        yield put(
          getCsvTemplateSuccess(response.downloadUrl, response.guidelines)
        );
      }
    } catch (error: unknown) {
      const err = error instanceof Error ? error : new Error(String(error));
      yield put(getCsvTemplateFailure(err, err.message));
    }
  }
  function* handleGetCsvUpdateTemplate(
    action: PayloadAction<
      ActionType.REQUEST_GET_CSV_UPDATE_TEMPLATE,
      {
        dataType: CsvUploadDataType;
        // requestParam?: number;
        metadata?: any;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getCsvUpdateTemplate,
          action.payload.dataType,
          action.payload.metadata
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get csv template timed out.';
        yield put(getCsvTemplateFailure(new Error(errorMessage), errorMessage));
      } else {
        yield put(
          getCsvTemplateSuccess(response.downloadUrl, response.guidelines)
        );
      }
    } catch (error: any) {
      yield put(getCsvTemplateFailure(error, error?.message));
    }
  }

  const createUploadIntentAndUploadFileToBucket = async (action: any) => {
    try {
      // Create CSV upload intent
      const { url, csvUploadId } = await req
        .post('/csv-uploads', {
          dataType: action.payload.dataType,
          fileName: action.payload.csvFile.name,
          fileSize: action.payload.csvFile.size,
          metadata: action.payload.metadata,
        })
        .then((response) => {
          return response.json();
        })
        .catch((err: unknown) => {
          throw new Error('createUploadFailed');
        });

      // Set status to 'uploading'
      await req
        .put(`/csv-uploads/${csvUploadId}/status`, {
          status: 'uploading',
        })
        .catch((_err: unknown) => {
          // Swallow error
        });

      // Upload to bucket using signed URL
      await fetch(url, {
        method: 'PUT',
        headers: {
          'content-type': 'application/octet-stream',
        },
        body: action.payload.csvFile,
      }).catch((err: unknown) => {
        throw new Error('uploadFailed');
      });

      // Update status to 'uploadComplete'
      await req
        .put(`/csv-uploads/${csvUploadId}/status`, {
          status: 'uploadComplete',
        })
        .catch((_err: unknown) => {
          // Swallow error;
        });
    } catch (err) {
      // TODO
    }
  };

  // UPLOAD ITEMS CSV
  function* handleUploadCsv(
    action: PayloadAction<
      ActionType.REQUEST_UPLOAD_CSV,
      {
        dataType: CsvUploadDataType;
        csvFile: File;
        metadata?: CsvUploadMetadata[CsvUploadDataType];
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      yield call(createUploadIntentAndUploadFileToBucket, action);
      (action as any).meta?.();
      yield put(uploadCsvSuccess());
    } catch (error: any) {
      yield put(uploadCsvFailure(error, error?.message));
    }
  }

  const getCsvUploadTemplate = async (
    dataType: CsvUploadDataType,
    metadata: any
  ) => {
    // FIXME: was requestParam
    const queryStringSuffix = new URLSearchParams(metadata ?? {}).toString();
    const response = await req.get(
      `/csv-uploads/upload-template?dataType=${dataType}&${queryStringSuffix}`
    );
    return await response.json();
  };

  const getCsvUpdateTemplate = async (
    dataType: CsvUploadDataType,
    metadata?: any
  ) => {
    // FIXME: was requestParam
    const queryStringSuffix = new URLSearchParams(metadata ?? {}).toString();
    const response = await req.get(
      `/csv-uploads/update-template?dataType=${dataType}&${queryStringSuffix}`
    );
    return await response.json();
  };

  const handleConfirmContinueProcessing = handleAsyncAction(
    confirmContinueProcessing,
    async ({ csvUploadId }) => {
      await req.put(`/csv-uploads/${csvUploadId}/status`, {
        status: 'validationComplete',
      });
    }
  );

  const handleGetFileDownloadUrl = handleAsyncAction(
    getFileDownloadUrl,
    async ({ csvUploadId }, cb) => {
      const response = await req.get(
        `/csv-uploads/${csvUploadId}/download-url`
      );
      const body = await response.json();
      cb(body.url);
    }
  );

  return function* () {
    yield takeEvery(
      ActionType.CONFIRM_CONTINUE_PROCESSING_REQUEST,
      handleConfirmContinueProcessing
    );
    yield takeEvery(
      ActionType.GET_FILE_DOWNLOAD_URL_REQUEST,
      handleGetFileDownloadUrl
    );
    yield takeEvery(ActionType.REQUEST_GET_CSV_TEMPLATE, handleGetCsvTemplate);
    yield takeEvery(
      ActionType.REQUEST_GET_CSV_UPDATE_TEMPLATE,
      handleGetCsvUpdateTemplate
    );
    yield takeEvery(ActionType.REQUEST_UPLOAD_CSV, handleUploadCsv);
    yield takeEvery(
      ActionType.GET_CSV_UPLOADS_REQUEST,
      handleAsyncAction(getCsvUploads, async () => {
        const response = await req.get('/csv-uploads');
        const json = await response.json();
        return json;
      })
    );
  };
};

export default csvUploadSagaCreator;
