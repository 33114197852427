import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import CompanySettingsCompanyInfo from './CompanySettingsCompanyInfo';
import CompanySettingsGettingPaid from './CompanySettingsGettingPaid';
import CompanySettingsTerms from './CompanySettingsTerms';
import CompanySettingsBranding from './CompanySettingsBranding';
import CompanySettingsEmailCustomisation from './CompanySettingsEmailCustomisation';
import CollapsiblePanel, {
  CollapsiblePanelStyleVariant,
} from '@payaca/components/collapsiblePanel/CollapsiblePanel';

import { actions as userActions } from '@/api/users';
import { useSelector } from '@/api/state';
import * as accountActions from '@payaca/store/account/accountActions';
import { actions as accountSettingsActions } from '@/api/accountSettings';

import { parseAccount } from '@/helpers/userHelper';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';

import './CompanySettings.sass';
import ContactUsModal from '../contactUsModal/ContactUsModal';
import { getUserRoles } from '@/utils/stateAccessors';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import CompanySettingsTaxRates from './CompanySettingsTaxRates';
import { useCanUpdateAccount } from '@/hooks/useCanUpdateAccount';
import CompanySettingsTimeTracking from './CompanySettingsTimeTracking';

const CompanySettings: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isAdmin = useCanUpdateAccount();

  const [showContactUsModal, setShowContactUsModal] = useState(false);

  const currentAccount = useSelector(
    (state: any) => state.users?.myProfile?.accounts[0]
  );

  const emailCustomisationRegionalLabel = useMemo(
    () =>
      getRegionalTextString(
        currentAccount.region,
        RegionalStrings.EMAIL_CUSTOMISATION
      ),
    [currentAccount]
  );

  const pendingAccount = useSelector(
    (state: any) => state.accountSettings.pendingAccount
  );

  const taxRates = useSelector((state) => state.taxRates.store);

  const sections = useMemo(
    () => [
      {
        title: 'Company info',
        urlSlug: 'company_info',
        children: <CompanySettingsCompanyInfo readOnly={!isAdmin} />,
        watchSize: pendingAccount,
      },
      {
        title: 'Branding',
        urlSlug: 'branding',
        children: <CompanySettingsBranding readOnly={!isAdmin} />,
      },
      {
        title: 'Getting paid',
        urlSlug: 'getting_paid',
        children: <CompanySettingsGettingPaid readOnly={!isAdmin} />,
      },
      {
        title: 'Tax rates',
        urlSlug: 'tax_rates',
        children: <CompanySettingsTaxRates readOnly={!isAdmin} />,
        watchSize: taxRates,
      },
      {
        title: 'Terms',
        urlSlug: 'terms',
        children: <CompanySettingsTerms readOnly={!isAdmin} />,
      },
      {
        title: emailCustomisationRegionalLabel,
        urlSlug: 'email_customisation',
        children: <CompanySettingsEmailCustomisation readOnly={!isAdmin} />,
      },
    ],
    [isAdmin, pendingAccount, taxRates, emailCustomisationRegionalLabel]
  );

  const defaultOpenSectionIndex = useMemo(() => {
    const queryTabName = getUrlSearchParam(location.search, 'tab');
    if (!queryTabName) {
      return null;
    }
    const chosenSectionIndex = sections.findIndex(
      (section) => section.urlSlug === queryTabName?.toLowerCase()
    );
    return chosenSectionIndex >= 0 ? chosenSectionIndex : null;
  }, [location, sections]);

  const [openSectionIndex, setOpenSectionIndex] = useState<number | null>(
    defaultOpenSectionIndex
  );

  useEffect(() => {
    dispatch(userActions.getBusinessAttachments());
    dispatch(userActions.getAccountTerms(currentAccount.id));
    dispatch(accountActions.requestGetEmailTemplates(currentAccount.id, true));

    return () => {
      dispatch(accountSettingsActions.clearAccountPendingChanges());
    };
  }, []);

  const terms = useSelector((state: any) => state.users.terms);

  const emailTemplates = useSelector((state) => state.account.emailTemplates);
  const businessAttachments = useSelector(
    (state: any) => state.users.businessAttachments
  );

  useEffect(() => {
    dispatch(
      accountSettingsActions.storeAccountPendingChanges(
        parseAccount(currentAccount)
      )
    );
  }, [currentAccount]);

  useEffect(() => {
    dispatch(
      accountSettingsActions.storeAccountPendingBusinessAttachments(
        businessAttachments
      )
    );
  }, [businessAttachments]);

  useEffect(() => {
    dispatch(accountSettingsActions.storeAccountPendingTerms(terms));
  }, [terms]);

  useEffect(() => {
    if (emailTemplates) {
      dispatch(
        accountSettingsActions.storeAccountPendingEmailTemplates({
          sendEstimate: emailTemplates.sendEstimate,
          sendQuote: emailTemplates.sendQuote,
          sendInvoice: emailTemplates.sendInvoice,
        })
      );
    }
  }, [emailTemplates]);

  return (
    <div className="company-settings-container">
      {!isAdmin && (
        <div className="contact-admin-text">
          Please contact an admin to amend these details
        </div>
      )}
      {sections.map(({ title, children, watchSize }, index) => (
        <CollapsiblePanel
          key={index}
          title={title}
          isOpen={openSectionIndex === index}
          onOpen={() => setOpenSectionIndex(index)}
          onClose={() => setOpenSectionIndex(null)}
          styleVariant={CollapsiblePanelStyleVariant.OUTSIZE}
          watchSize={watchSize}
        >
          {children}
        </CollapsiblePanel>
      ))}
      <ContactUsModal
        isOpen={showContactUsModal}
        onClose={() => setShowContactUsModal(false)}
      />
    </div>
  );
};

export default CompanySettings;
