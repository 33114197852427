import { action } from 'typesafe-actions';

import { ListedSupplierMaterialListViewPage } from '@payaca/types/listedSupplierMaterialTypes';
import {
  ActionType,
  GetListedSupplierMaterialsRequestData,
} from './listedSupplierMaterialsTypes';

export const requestGetListedSupplierMaterialsPage = (
  getListedSupplierMaterialsRequestData: GetListedSupplierMaterialsRequestData
) => {
  return action(ActionType.REQUEST_GET_LISTED_SUPPLIERMATERIALS_PAGE, {
    getListedSupplierMaterialsRequestData,
  });
};

export const clearListedSupplierMaterialsPage = () => {
  return action(ActionType.CLEAR_LISTED_SUPPLIERMATERIALS_PAGE);
};

export const getListedSupplierMaterialsPageSuccess = (
  listedSupplierMaterialsPage: ListedSupplierMaterialListViewPage
) => {
  return action(ActionType.GET_LISTED_SUPPLIERMATERIALS_PAGE_SUCCESS, {
    listedSupplierMaterialsPage,
  });
};

export const getListedSupplierMaterialsPageFailure = (error: Error) => {
  return action(
    ActionType.GET_LISTED_SUPPLIERMATERIALS_PAGE_FAILURE,
    null,
    null,
    error
  );
};
