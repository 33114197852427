import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import FullCalendar, {
  CustomContentGenerator,
  DateSelectArg,
  DayHeaderContentArg,
  EventClickArg,
  EventContentArg,
  SlotLabelContentArg,
} from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import moment from 'moment-timezone';

import {
  ScheduledEvent,
  ScheduledEventColourCoding,
  ScheduledEventPayload,
} from '@payaca/types/scheduledEventsTypes';

import { getAddressAsString } from '@payaca/helpers/locationHelper';

import Tooltip from '../tooltip/Tooltip';
import LabelValuePair from '../labelValuePair/LabelValuePair';

import './Calendar.sass';

type Props = {
  additionalCalendarProps?: any;
  className?: string;
  hasBoxShadow?: boolean;
  headerToolbar: any;
  initialView?: string;
  onExistingEventSelect: (event: ScheduledEvent) => void;
  onDateSelect: (dateSelectInfo?: {
    beginAt: string;
    endAt?: string;
    userAssignments?: number[];
  }) => void;
  onViewableDateChange?: (dateInfo: any) => void;
  plugins: any[];
  renderEventContent?: (eventContent: EventContentArg) => JSX.Element;
  scheduledEvents: ScheduledEventPayload[];
  renderDayHeaderContent?: CustomContentGenerator<DayHeaderContentArg>;
  renderSlotLabelContent?: CustomContentGenerator<SlotLabelContentArg>;
  eventDrop?: any;
  eventResize?: any;
  editable?: boolean;
  onViewTypeChange?: (viewType: string) => void;
  minTime?: string;
  maxTime?: string;
  hiddenDays?: number[];
  scheduledEventColourCoding?: ScheduledEventColourCoding;
  externalCalendarRef?: React.RefObject<FullCalendar>;
  isExternallyControlled?: boolean;
};
const Calendar: FC<Props> = ({
  additionalCalendarProps,
  className,
  hasBoxShadow = true,
  headerToolbar,
  initialView,
  onExistingEventSelect,
  onDateSelect,
  onViewableDateChange,
  plugins,
  renderEventContent,
  scheduledEvents,
  renderDayHeaderContent,
  renderSlotLabelContent,
  eventDrop,
  eventResize,
  editable = true,
  onViewTypeChange,
  minTime = '00:00:00',
  maxTime = '23:59:59',
  hiddenDays = [],
  scheduledEventColourCoding,
  externalCalendarRef,
  isExternallyControlled,
}: Props): JSX.Element => {
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  const parsedEvents = useMemo(() => {
    return (
      scheduledEvents &&
      scheduledEvents.map((scheduledEvent: ScheduledEventPayload) => {
        const backgroundColour = scheduledEventColourCoding
          ? scheduledEvent?.colour?.[scheduledEventColourCoding]?.background
          : null;
        const textColour = scheduledEventColourCoding
          ? scheduledEvent?.colour?.[scheduledEventColourCoding]?.text
          : null;
        return {
          ...scheduledEvent,
          id: `${scheduledEvent.id}`,
          title: scheduledEvent.name,
          start: scheduledEvent.beginAt,
          end: scheduledEvent.endAt,
          allDay: scheduledEvent.allDay,
          userAssignments: scheduledEvent.userAssignments,
          resourceId: scheduledEvent.userAssignment,
          classNames: [scheduledEvent.isDisabled ? 'disabled' : ''],
          resourceEditable: true,
          backgroundColor: backgroundColour,
          textColor: textColour,
        };
      })
    );
  }, [scheduledEvents]);

  const renderEventContentDefault = (eventContent: EventContentArg) => {
    const startTime = moment(eventContent.event.start).format('HH:mm');
    const hoursDuration = moment(eventContent.event.end).diff(
      moment(eventContent.event.start),
      'hours'
    );

    const stickyEvent = hoursDuration >= 6;
    const postcode =
      eventContent?.event?._def?.extendedProps?.location?.address?.postcode;
    const customerName = eventContent?.event?._def?.extendedProps?.customerName;

    const informationArray = [];
    if (postcode?.length) {
      informationArray.push(postcode);
    }
    if (customerName?.length) {
      informationArray.push(customerName);
    }

    // if event is longer than 6 hours - make sticky, so that on scroll the event name can always be seen, otherwise default to ellipsis
    return (
      <>
        <span className="event-strip" />
        <div
          className={`event-content-body${
            stickyEvent ? ' sticky' : ''
          } min-h-[3em]`}
        >
          <div className="truncate">
            <span className="event-title">{eventContent.event.title}</span>
            <span className="event-time">{`${startTime}`}</span>
          </div>
          <div className="truncate">
            <span>{informationArray.join(' - ')}</span>
          </div>
        </div>
      </>
    );
  };

  const onClickExistingEvent = useCallback(
    (clickInfo: EventClickArg) => {
      const event = parseCalendarInfo(clickInfo.event.toPlainObject());
      if (!event.isDisabled) {
        onExistingEventSelect(event);
      }
    },
    [onExistingEventSelect]
  );

  const parseCalendarInfo = useCallback((info: any) => {
    return {
      ...info.extendedProps,
      id: +info.id,
      beginAt: info.start,
      endAt: info.end,
      name: info.title,
    };
  }, []);

  const onSelect = (dateSelectInfo: DateSelectArg) => {
    const userAssignments = [];
    if (dateSelectInfo?.resource?.id && Number(dateSelectInfo?.resource?.id)) {
      userAssignments.push(+dateSelectInfo?.resource?.id);
    }

    onDateSelect({
      beginAt: moment(dateSelectInfo.start).toISOString(true),
      endAt: dateSelectInfo.end
        ? moment(dateSelectInfo.end).toISOString(true)
        : undefined,
      userAssignments,
    });
  };

  const calendarRef = externalCalendarRef || useRef<FullCalendar>(null);
  const viewType = calendarRef.current?.getApi().view.type ?? null;
  useEffect(() => {
    viewType && onViewTypeChange?.(viewType);
  }, [viewType]);

  const allHours = useMemo(
    () => minTime === '00:00:00' && maxTime === '23:59:59',
    [minTime, maxTime]
  );

  return (
    <div
      className={`calendar-wrapper${hasBoxShadow ? ' box-shadow' : ''}${
        className ? ` ${className}` : ''
      }${allHours ? ' all-hours' : ''}`}
      ref={calendarContainerRef}
    >
      <FullCalendar
        ref={calendarRef}
        firstDay={1}
        schedulerLicenseKey="0248494705-fcs-1731065906"
        plugins={[...plugins, momentPlugin, interactionPlugin]}
        headerToolbar={isExternallyControlled ? false : headerToolbar}
        editable={editable}
        eventDrop={eventDrop}
        eventResize={eventResize}
        selectable={true}
        selectMirror={true}
        weekends={true}
        initialView={initialView}
        events={parsedEvents}
        select={onSelect}
        eventContent={(eventContent) => {
          const location = getAddressAsString(
            eventContent?.event?._def?.extendedProps?.location?.address
          );
          const taskStatus =
            eventContent?.event?._def?.extendedProps.taskStatus;
          const customerName =
            eventContent?.event?._def?.extendedProps.customerName;
          return (
            <Tooltip
              text={
                <div className="event-details-tooltip">
                  <span className="event-title">
                    {eventContent.event.title}
                  </span>
                  <LabelValuePair
                    label="Time"
                    value={`${moment(eventContent.event.start).format(
                      'HH:mm'
                    )} - ${moment(eventContent.event.end).format('HH:mm')}`}
                  />

                  {location && (
                    <LabelValuePair label="Location" value={location} />
                  )}
                  {!!customerName?.length && (
                    <LabelValuePair label="Customer" value={customerName} />
                  )}
                  {taskStatus && taskStatus != 'No tasks' && (
                    <LabelValuePair label="Task status" value={taskStatus} />
                  )}
                </div>
              }
            >
              <div className="event-content-wrapper">
                {renderEventContent
                  ? renderEventContent(eventContent)
                  : renderEventContentDefault(eventContent)}
              </div>
            </Tooltip>
          );
        }}
        eventDurationEditable={true}
        eventClick={onClickExistingEvent}
        datesSet={onViewableDateChange}
        eventDisplay="auto"
        droppable={false}
        dayMaxEventRows={true}
        expandRows={true}
        displayEventTime={true}
        eventOrder={
          viewType === 'listWeek' || viewType === 'dayGridMonth'
            ? 'start,-duration,allDay,title'
            : (a: any, b: any) => (a.isDisabled && !b.isDisabled ? 1 : -1)
        }
        nowIndicator={true}
        eventTimeFormat="HH:mm"
        contentHeight={'auto'}
        stickyHeaderDates={true}
        buttonText={{
          month: 'Month',
          week: 'Week',
          day: 'Day',
          list: 'List',
          today: 'Today',
        }}
        {...additionalCalendarProps}
        dayHeaderContent={renderDayHeaderContent}
        slotLabelContent={renderSlotLabelContent}
        slotMinTime={minTime}
        slotMaxTime={maxTime}
        hiddenDays={hiddenDays?.length ? hiddenDays : undefined} // crashes with empty array
        allDayText="All day"
      />
    </div>
  );
};

export default Calendar;
