import useCreateCustomField from '@/api/mutations/custom-field-groups/useCreateCustomField';
import meKeys from '@/api/queries/me/keyFactory';
import {
  CustomField,
  CustomFieldDefinition,
  CustomFieldGroup,
  MyCustomFieldGroupsQuery,
} from '@/gql/graphql';
import AdvancedTable from '@payaca/components/plAdvancedTable/AdvancedTable';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Field, { ValidationMessages } from '@payaca/components/plField/Field';
import Input, { InputSizeVariant } from '@payaca/components/plInput/Input';
import Modal from '@payaca/components/plModal/Modal';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import {
  getArrayMustNotBeEmptyFieldValidator,
  getConditionalValidator,
  getIsRequiredFieldValidator,
  getIsRequiredIfTrueConditionValidator,
  getLengthFieldValidator,
  getValueMustNotExistInArrayFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import useUpdateCustomField from '@/api/mutations/custom-field-groups/useUpdateCustomField';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import { CustomFieldType } from '@payaca/custom-fields/types/index';
import Select from '@payaca/components/plSelect/Select';
import useArchiveCustomField from '@/api/mutations/custom-field-groups/useArchiveCustomField';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import Tooltip, {
  TooltipPositionVariant,
} from '@payaca/components/plTooltip/Tooltip';
import InputGroup from '@payaca/components/plInputGroup/InputGroup';
import Badge from '@payaca/components/plBadge/Badge';
import UntitledIcon from '@payaca/untitled-icons';

const CustomFieldTypeReadableNameMap: Record<CustomFieldType, string> = {
  textarea: 'Textarea',
  duration: 'Duration',
  'multi-upload': 'Multiple file upload',
  text: 'Text',
  email: 'Email',
  select: 'Drop-down list',
};

export const CustomFieldGroupControl: FC<{
  customFieldGroup: CustomFieldGroup;
  heading?: string;
  subHeading?: string;
}> = ({ customFieldGroup, heading, subHeading }) => {
  const [editCustomFieldId, setEditCustomFieldId] = useState<
    CustomField['id'] | null
  >(null);
  const [archiveCustomFieldId, setArchiveCustomFieldId] = useState<
    CustomField['id'] | null
  >(null);
  const [showCreateCustomFieldModal, setShowCreateCustomFieldModal] =
    useState(false);

  return (
    <div>
      <ManageableItemsList>
        <ManageableItemsList.HeaderBar
          heading={heading || ''}
          subHeading={subHeading}
          buttons={
            <Button onClick={() => setShowCreateCustomFieldModal(true)}>
              Add custom field
            </Button>
          }
        />
        <ManageableItemsList.Table
          emptyText="No Custom Fields yet"
          items={customFieldGroup.customFields.filter((x) => !x.archivedAt)}
          uniqueKey={'id'}
          itemActions={[
            {
              label: 'Edit',
              onClick: (x) => setEditCustomFieldId(x.id),
            },
            {
              label: 'Delete',
              onClick: (x) => setArchiveCustomFieldId(x.id),
            },
          ]}
        >
          <ManageableItemsList.Table.Column header="Label" field="label" />
          <ManageableItemsList.Table.Column
            header="Type"
            field="type"
            render={(type) =>
              CustomFieldTypeReadableNameMap[type as CustomFieldType]
            }
          />
          {customFieldGroup.customFields.some((x) => x.options?.length) && (
            <ManageableItemsList.Table.Column
              header="Options"
              field="options"
              render={(options?: string[]) => {
                return (
                  <div>
                    {options?.length && (
                      <ul className="m-0 list-none p-0">
                        {options.slice(0, 3).map((option, index) => (
                          <li key={`${option}-${index}`}>{option}</li>
                        ))}
                        {options.length > 3 && (
                          <li>
                            <Tooltip
                              positionVariant={TooltipPositionVariant.BOTTOM}
                              tooltipContent={
                                <ul className="m-0 list-none p-0">
                                  {options
                                    .slice(3, options.length)
                                    .map((option, index) => (
                                      <li key={`${option}-${index}`}>
                                        {option}
                                      </li>
                                    ))}
                                </ul>
                              }
                            >
                              ...and {options.length - 3} more
                            </Tooltip>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                );
              }}
            />
          )}
          <ManageableItemsList.Table.Column
            header="Internal Name"
            field="identifier"
          />
        </ManageableItemsList.Table>
      </ManageableItemsList>
      {editCustomFieldId && (
        <EditCustomFieldModal
          isOpen={!!editCustomFieldId}
          onClose={() => setEditCustomFieldId(null)}
          customFieldGroup={customFieldGroup}
          customFieldId={editCustomFieldId}
        />
      )}
      {archiveCustomFieldId && (
        <ArchiveCustomFieldModal
          isOpen={!!archiveCustomFieldId}
          onClose={() => setArchiveCustomFieldId(null)}
          customFieldId={archiveCustomFieldId}
        />
      )}
      <CreateCustomFieldModal
        isOpen={showCreateCustomFieldModal}
        onClose={() => setShowCreateCustomFieldModal(false)}
        customFieldGroup={customFieldGroup}
      />
    </div>
  );
};

const getExistingIdentifiersFromCustomFields = (
  customFields: Pick<CustomField, 'identifier' | 'archivedAt'>[]
) => {
  const identifiers: {
    all: string[];
    archived: string[];
    unarchived: string[];
  } = {
    all: [],
    archived: [],
    unarchived: [],
  };

  customFields.forEach((x) => {
    identifiers.all.push(x.identifier);
    if (x.archivedAt) {
      identifiers.archived.push(x.identifier);
    } else {
      identifiers.unarchived.push(x.identifier);
    }
  });

  return identifiers;
};

const getFieldValidators = (existingIdentifiers: {
  archived: string[];
  unarchived: string[];
}) => {
  return {
    type: [getIsRequiredFieldValidator()],
    label: [
      getIsRequiredFieldValidator(),
      getLengthFieldValidator({ max: 1023 }),
    ],
    identifier: [
      getLengthFieldValidator({ max: 255 }),
      getValueMustNotExistInArrayFieldValidator(existingIdentifiers.archived, {
        customErrorMessage:
          'This identifier has already been used by a field which is now deleted. Please enter a different one.',
      }),
      getValueMustNotExistInArrayFieldValidator(
        existingIdentifiers.unarchived,
        {
          customErrorMessage:
            'This identifier is already in use by another field.',
        }
      ),
    ],
    options: [
      getIsRequiredIfTrueConditionValidator((x) => x.type === 'select', {
        customErrorMessage: 'At least one option is required',
      }),
      getConditionalValidator(
        (x) => x.type === 'select',
        getArrayMustNotBeEmptyFieldValidator({
          customErrorMessage: 'At least one option is required',
        })
      ),
    ],
  };
};

const EditCustomFieldModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  customFieldGroup: CustomFieldGroup;
  customFieldId: CustomField['id'];
}> = ({ isOpen, onClose, customFieldGroup, customFieldId }) => {
  const customField = useMemo(
    () => customFieldGroup.customFields.find((x) => x.id === customFieldId),
    [customFieldGroup.customFields, customFieldId]
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const queryClient = useQueryClient();

  const { mutateAsync: updateCustomField } = useUpdateCustomField();

  const identifiers = useMemo(() => {
    return getExistingIdentifiersFromCustomFields(
      customFieldGroup.customFields.filter((x) => x.id !== customFieldId)
    );
  }, [customFieldGroup.customFields, customFieldId]);

  const initialFormState = useMemo(() => {
    if (!customField) return {};

    return {
      label: customField.label,
      identifier: customField.identifier,
      type: customField.type,
      options: customField.options || undefined,
    };
  }, []);

  const fieldValidators = useMemo(() => {
    return getFieldValidators(identifiers);
  }, [identifiers]);

  const handleSave = useCallback(
    async (formState: {
      label: CustomFieldDefinition['label'];
      identifier: CustomFieldDefinition['identifier'];
      options: CustomFieldDefinition['options'];
    }) => {
      setIsProcessing(true);
      await updateCustomField({
        id: customFieldId,
        label: formState.label,
        identifier: formState.identifier,
        options: formState.options,
      })
        .then((result) => {
          updateCustomFieldGroupsQueryData(
            queryClient,
            result.updateCustomField
          );

          onClose();
        })
        .catch(() => {
          setErrorMessage('An error occurred while saving your changes.');
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
    [customFieldId]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Edit a Custom Field'}>
      <ValidatedForm
        initialFormState={initialFormState}
        fieldValidators={fieldValidators}
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => {
          return (
            <>
              <Modal.Body>
                <div className="space-y-4">
                  <Field name="type">
                    <Field.Label>Type </Field.Label>
                    <Tooltip
                      tooltipContent="The type of an existing Custom Field cannot be changed"
                      className="w-full"
                      positionVariant={TooltipPositionVariant.BOTTOM}
                    >
                      <Input
                        value={
                          CustomFieldTypeReadableNameMap[
                            formState.type as CustomFieldType
                          ]
                        }
                        disabled={true}
                      />
                    </Tooltip>
                  </Field>
                  <Field
                    name="label"
                    validationState={
                      validationState?.['label']?.isValid === false &&
                      touchedState?.['label']
                        ? {
                            isValid: false,
                            validationMessages:
                              validationState?.['label']?.errors,
                          }
                        : undefined
                    }
                  >
                    <Field.Label>Label</Field.Label>
                    <Input
                      value={formState.label}
                      onChange={(value) => {
                        onFieldChange({ label: value });
                      }}
                      onTouch={() => onFieldTouch('label')}
                    />
                  </Field>
                  {formState.type === 'select' && (
                    <Field
                      name="options"
                      validationState={
                        validationState?.['options']?.isValid === false
                          ? {
                              isValid: false,
                              validationMessages:
                                validationState?.['options']?.errors,
                            }
                          : undefined
                      }
                    >
                      <Field.Label>Options</Field.Label>
                      <CustomFieldGroupOptionsInput
                        options={formState.options}
                        onChange={(value) => {
                          onFieldChange({ options: value });
                          onFieldTouch('options');
                        }}
                      />
                    </Field>
                  )}
                  <Field name="identifier">
                    <Field.Label>Internal Name</Field.Label>
                    <Tooltip
                      tooltipContent="The internal name of an existing Custom Field cannot be changed"
                      className="w-full"
                      positionVariant={TooltipPositionVariant.BOTTOM}
                    >
                      <Input value={formState.identifier} disabled={true} />
                    </Tooltip>
                  </Field>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {errorMessage && (
                  <Alert colour={EAlertColour.SOFT_RED} className="mb-4">
                    {errorMessage}
                  </Alert>
                )}
                <Modal.Footer.Actions>
                  <Button
                    disabled={!isValid}
                    isProcessing={isProcessing}
                    onClick={() =>
                      !isProcessing &&
                      handleSave({
                        label: formState.label as string,
                        identifier: formState.identifier as string,
                        options: formState.options,
                      })
                    }
                  >
                    Save
                  </Button>
                </Modal.Footer.Actions>
              </Modal.Footer>
            </>
          );
        }}
      />
    </Modal>
  );
};

const CreateCustomFieldModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  customFieldGroup: CustomFieldGroup;
}> = ({ isOpen, onClose, customFieldGroup }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const queryClient = useQueryClient();
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsSubmitted(false);
    }
  }, [isOpen]);

  const { mutateAsync: createCustomField } = useCreateCustomField();

  const initialFormState = useMemo(() => {
    return {
      label: '',
      identifier: '',
      type: 'text',
      options: undefined,
    };
  }, []);

  const identifiers = useMemo(() => {
    return getExistingIdentifiersFromCustomFields(
      customFieldGroup.customFields
    );
  }, [customFieldGroup.customFields]);

  const fieldValidators = useMemo(() => {
    return getFieldValidators(identifiers);
  }, [identifiers]);

  const handleSave = useCallback(
    async (formState: {
      type: CustomField['type'];
      label: CustomField['label'];
      identifier: CustomField['identifier'];
      options: CustomField['options'];
    }) => {
      setIsProcessing(true);
      setIsSubmitted(true);
      await createCustomField({
        groupId: customFieldGroup.id,
        type: formState.type,
        label: formState.label,
        identifier: formState.identifier,
        options: formState.options,
      })
        .then((result) => {
          updateCustomFieldGroupsQueryData(
            queryClient,
            result.createCustomField
          );

          onClose();
        })
        .catch(() => {
          setErrorMessage('An error occurred while saving your changes.');
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
    [customFieldGroup.id]
  );

  const typeOptions = useMemo(() => {
    return [
      { value: 'text', label: CustomFieldTypeReadableNameMap['text'] },
      { value: 'email', label: CustomFieldTypeReadableNameMap['email'] },
      { value: 'select', label: CustomFieldTypeReadableNameMap['select'] },
    ];
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Create a Custom Field'}>
      <ValidatedForm
        initialFormState={initialFormState}
        fieldValidators={fieldValidators}
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => {
          return (
            <>
              <Modal.Body>
                <div className="space-y-4">
                  <Field
                    name="type"
                    validationState={
                      validationState?.['type']?.isValid === false
                        ? {
                            isValid: false,
                            validationMessages:
                              validationState?.['label']?.errors,
                          }
                        : undefined
                    }
                  >
                    <Field.Label>Type</Field.Label>

                    <Select
                      options={typeOptions}
                      value={formState.type}
                      onChange={(value) =>
                        onFieldChange({
                          type: value,
                          options: value === 'select' ? [] : undefined,
                        })
                      }
                    />
                  </Field>
                  <Field
                    name="label"
                    validationState={
                      validationState?.['label']?.isValid === false &&
                      touchedState?.['label']
                        ? {
                            isValid: false,
                            validationMessages:
                              validationState?.['label']?.errors,
                          }
                        : undefined
                    }
                  >
                    <Field.Label>Label</Field.Label>
                    <Input
                      value={formState.label}
                      onChange={(value) => {
                        const change: Record<string, any> = {
                          label: value,
                        };

                        if (
                          (!formState.identifier?.length ||
                            !touchedState['identifier']) &&
                          value?.length
                        ) {
                          let newIdentifier = value
                            .toLowerCase()
                            .replace(/[^a-z0-9]/g, '-')
                            .slice(0, 250);

                          if (identifiers.all.includes(newIdentifier)) {
                            newIdentifier = `${newIdentifier}-${uuid().slice(
                              0,
                              5
                            )}`;
                          }

                          change.identifier = newIdentifier;
                        }

                        onFieldChange(change);
                        onFieldTouch('label');
                      }}
                    />
                  </Field>
                  {formState.type === 'select' && (
                    <Field
                      name="options"
                      validationState={
                        touchedState['options'] &&
                        validationState?.['options']?.isValid === false
                          ? {
                              isValid: false,
                              validationMessages:
                                validationState?.['options']?.errors,
                            }
                          : undefined
                      }
                    >
                      <Field.Label>Options</Field.Label>
                      <CustomFieldGroupOptionsInput
                        options={formState.options}
                        onChange={(value) => {
                          onFieldChange({ options: value });
                          onFieldTouch('options');
                        }}
                      />
                    </Field>
                  )}
                  <Field
                    name="identifier"
                    validationState={
                      !isSubmitted &&
                      validationState?.['identifier']?.isValid === false &&
                      touchedState?.['identifier']
                        ? {
                            isValid: false,
                            validationMessages:
                              validationState?.['identifier']?.errors,
                          }
                        : undefined
                    }
                  >
                    <Field.Label>Internal Name</Field.Label>
                    <Input
                      value={formState.identifier}
                      onChange={(value) =>
                        onFieldChange({
                          identifier: value
                            .toLowerCase()
                            .replace(/[^a-z0-9]/g, '-'),
                        })
                      }
                      onTouch={() => onFieldTouch('identifier')}
                      autoCapitalize="none"
                    />
                    <Field.Helper>
                      This unique identifier will be used for integrations.
                    </Field.Helper>
                  </Field>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {errorMessage && (
                  <Alert colour={EAlertColour.SOFT_RED} className="mb-4">
                    {errorMessage}
                  </Alert>
                )}
                <Modal.Footer.Actions>
                  <Button
                    disabled={!isValid}
                    isProcessing={isProcessing}
                    onClick={() =>
                      !isProcessing &&
                      handleSave({
                        label: formState.label as string,
                        identifier: formState.identifier as string,
                        type: formState.type as CustomFieldType,
                        options: formState.options,
                      })
                    }
                  >
                    Save
                  </Button>
                </Modal.Footer.Actions>
              </Modal.Footer>
            </>
          );
        }}
      />
    </Modal>
  );
};

const ArchiveCustomFieldModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  customFieldId: CustomField['id'];
}> = ({ isOpen, onClose, customFieldId }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const queryClient = useQueryClient();

  const { mutateAsync: archiveCustomField } = useArchiveCustomField();

  const handleArchive = useCallback(async () => {
    setIsProcessing(true);
    await archiveCustomField({
      id: customFieldId,
    })
      .then((result) => {
        updateCustomFieldGroupsQueryData(
          queryClient,
          result.archiveCustomField
        );

        onClose();
      })
      .catch(() => {
        setErrorMessage('An error occurred while deleting this field.');
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, [customFieldId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Delete a Custom Field'}>
      <Modal.Body>
        <p>You will lose any data set against this field.</p>
      </Modal.Body>
      <Modal.Footer>
        {errorMessage && (
          <Alert colour={EAlertColour.SOFT_RED} className="mb-4">
            {errorMessage}
          </Alert>
        )}
        <Modal.Footer.Actions>
          <Button
            colour={EBtnColour.Red}
            isProcessing={isProcessing}
            onClick={() => !isProcessing && handleArchive()}
          >
            {'Yes, delete'}
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

const updateCustomFieldGroupsQueryData = (
  queryClient: QueryClient,
  updatedCustomFieldGroup: CustomFieldGroup
) => {
  queryClient.setQueryData<MyCustomFieldGroupsQuery>(
    meKeys.customFieldGroups(),
    (old) => {
      if (!old) return;
      return {
        me: {
          user: {
            account: {
              customFieldGroups: old.me.user.account.customFieldGroups.map(
                (x) => {
                  if (x.id === updatedCustomFieldGroup.id) {
                    return updatedCustomFieldGroup;
                  }
                  return x;
                }
              ),
            },
          },
        },
      };
    }
  );
};

const CustomFieldGroupOptionsInput: FC<{
  options?: string[];
  onChange: (options: string[]) => void;
}> = ({ options, onChange }) => {
  const [optionToAdd, setOptionToAdd] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleAddOption = useCallback(() => {
    if (!optionToAdd.length) return;
    if (options?.includes(optionToAdd)) {
      setErrorMessage('This option has already been added');
      return;
    }
    onChange([...(options || []), optionToAdd as string]);
    setOptionToAdd('');
  }, [options, optionToAdd]);

  return (
    <div>
      <InputGroup sizeVariant={InputSizeVariant.MD}>
        <InputGroup.Input
          placeholder="Type the option you want to add..."
          value={optionToAdd}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleAddOption();
              e.preventDefault();
            }
          }}
          onChange={(option) => {
            setOptionToAdd(option);
            setErrorMessage('');
          }}
        ></InputGroup.Input>
        <InputGroup.Button
          variant={EBtnVariant.Outline}
          disabled={!optionToAdd?.length}
          onClick={handleAddOption}
        >
          Add
        </InputGroup.Button>
      </InputGroup>
      {errorMessage && (
        <ValidationMessages
          validationState={{
            isValid: false,
            validationMessages: [errorMessage],
          }}
        />
      )}
      {!!options && (
        <div className="mt-2 flex flex-row flex-wrap gap-2">
          {options?.map((option, index) => {
            return (
              <Badge
                key={`${option}-${index}`}
                rounded={false}
                size="md"
                variant="soft"
                colour="blue"
              >
                {option}

                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(options.filter((x) => x !== option));
                  }}
                  className={`ml-2 flex w-2 shrink-0 grow-0 cursor-pointer items-center border-none bg-transparent p-0 text-inherit outline-none transition-all`}
                >
                  <UntitledIcon name="x-close" className="h-2 w-2" />
                </button>
              </Badge>
            );
          })}
        </div>
      )}
    </div>
  );
};
