import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Switch as HeadlessSwitch } from '@headlessui/react';

import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import Conditional from '@payaca/components/conditional/Conditional';
import LineItemPriceField from '@/ui/pages/listedItemsPage/components/LineItemPriceField';
import useGetMyTaxRates from '@/api/queries/me/useGetMyTaxRates';
import useGetMyCisDeductionRate from '@/api/queries/me/useGetMyCisDeductionRate';
import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import Select, { SelectSizeVariant } from '@payaca/components/plSelect/Select';
import { TFormState } from '@/ui/pages/listedItemsPage/components/AddEditItemSidebar';
import Switch from '@payaca/components/plSwitch/Switch';

const EditLineItemPrice: FC = () => {
  /**
   * Queries
   */
  const { taxRates } = useGetMyTaxRates();
  const { isCisSubcontractor, cisDeductionRate } = useGetMyCisDeductionRate();
  const { data: regionPreferences, isLoading: isLoadingRegionPreferences } =
    useGetMyRegionPreferences();

  /**
   * use form hook
   */
  const formMethods = useFormContext<TFormState>();
  const selectedCis = formMethods.watch('cis');
  const selectedTaxRate = formMethods.watch('taxRateId');
  const { markupType, price: unitPriceExcTax } =
    formMethods.watch('markupTotals');
  const linkedMaterials = formMethods.watch('materials');
  const predictedMaterialCost = formMethods.watch('predictedMaterialCost');

  const hasLinkedMaterials = linkedMaterials.length > 0;

  const taxAmount = (() => {
    const selctedTaxRate = taxRates?.find(
      (taxRate) => taxRate.id === selectedTaxRate
    );

    if (!selctedTaxRate || selctedTaxRate.isReverseCharge) return 0;

    return (unitPriceExcTax * selctedTaxRate.percentage) / 100;
  })();

  const cisTotal = (() => {
    if (selectedCis === 'no' || !cisDeductionRate) {
      return 0;
    }

    return (unitPriceExcTax * cisDeductionRate) / 100;
  })();

  const priceIncludingTax = unitPriceExcTax + taxAmount - cisTotal;

  if (isLoadingRegionPreferences || !regionPreferences) {
    return null;
  }

  return (
    <>
      <Conditional condition={hasLinkedMaterials}>
        <Card sizeVariant={CardSizeVariant.SM}>
          <Card.Body>
            <div className="flex justify-between">
              <h4>Materials (ex Tax)</h4>

              <p>
                <CurrencyValue
                  value={predictedMaterialCost || 0}
                  currency={regionPreferences.currency}
                />
              </p>
            </div>
          </Card.Body>
        </Card>
      </Conditional>

      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body>
          <h4 className="mb-2.5">Set price by</h4>

          <Controller
            control={formMethods.control}
            name="markupTotals"
            render={({ field: { onChange, value } }) => {
              return (
                <LineItemPriceField
                  markupTotals={value}
                  hasLinkedMaterials={hasLinkedMaterials}
                  predictedMaterialCost={predictedMaterialCost}
                  onMarkupTotalChange={onChange}
                />
              );
            }}
          />
        </Card.Body>
      </Card>

      <Conditional condition={markupType !== 'price'}>
        <HeadlessSwitch.Group>
          <HeadlessSwitch.Label className="block">
            <Card sizeVariant={CardSizeVariant.SM}>
              <Card.Body>
                <div className="flex justify-between">
                  <div>
                    <h4>Auto update</h4>

                    <p>When Material costs change update my sale price</p>
                  </div>

                  <Controller
                    name="autoUpdatePrice"
                    render={({ field: { onChange, value } }) => {
                      return <Switch value={value} onChange={onChange} />;
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </HeadlessSwitch.Label>
        </HeadlessSwitch.Group>
      </Conditional>

      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body>
          <h4 className="mb-2.5">Taxes</h4>

          <div className="grid grid-cols-[60px_160px_auto] items-center gap-2.5">
            <Conditional condition={isCisSubcontractor}>
              <div>
                <p>CIS</p>
              </div>

              <div>
                <Controller
                  name="cis"
                  defaultValue="no"
                  control={formMethods.control}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Select
                        sizeVariant={SelectSizeVariant.SM}
                        // Todo: fix me?
                        value={value as 'yes' | 'no'}
                        onChange={onChange}
                        options={[
                          {
                            label: `Yes (${cisDeductionRate}%)`,
                            value: 'yes',
                          },
                          {
                            label: 'No',
                            value: 'no',
                          },
                        ]}
                      />
                    );
                  }}
                />
              </div>

              <div className="text-right">
                <p>
                  <CurrencyValue
                    value={-cisTotal}
                    currency={regionPreferences.currency}
                  />
                </p>
              </div>
            </Conditional>

            <div className="pr-4">
              <p>Tax</p>
            </div>

            <div>
              <Controller
                name="taxRateId"
                defaultValue={
                  taxRates?.find((taxRate) => taxRate.isDefault)?.id
                }
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <Select
                      sizeVariant={SelectSizeVariant.SM}
                      value={value}
                      onChange={onChange}
                      options={[
                        ...(taxRates
                          ?.filter((taxRate) => !taxRate.isReverseCharge)
                          .map((taxRate) => ({
                            label: taxRate.label,
                            value: taxRate.id,
                          })) || []),
                        ...(taxRates
                          ?.filter((taxRate) => taxRate.isReverseCharge)
                          .map((taxRate) => ({
                            label: taxRate.label,
                            value: taxRate.id,
                          })) || []),
                      ]}
                    />
                  );
                }}
              />
            </div>

            <div className="text-right">
              <p>
                <CurrencyValue
                  value={taxAmount}
                  currency={regionPreferences.currency}
                />
              </p>
            </div>
          </div>

          <div className="mt-4 flex justify-between">
            <h4>Total sales price (inc tax)</h4>

            <p className="font-semibold">
              <CurrencyValue
                value={priceIncludingTax}
                currency={regionPreferences.currency}
              />
            </p>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditLineItemPrice;
