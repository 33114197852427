import React, { FC, useCallback, useMemo } from 'react';

import TextareaFieldFormatter from '@payaca/components/textareaField/TextareaFieldFormatter';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import EditJobSection from './EditJobSection';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Job } from '@payaca/types/jobTypesV2';

import { getJob } from '@/utils/stateAccessors';

import { getInitialUpdateJobRequestDataFromBaseJob } from '@payaca/helpers/jobHelperV2';
import { isInvoice } from '@payaca/helpers/jobStatusHelper';
import { useSelector } from '@/api/state';

type Props = {
  jobId: number;
  updateJob: (
    value: { [key: string]: any },
    onUpdateSuccess?: () => void
  ) => void;
};
const JobAdditionalNotesControl: FC<Props> = ({ jobId, updateJob }: Props) => {
  const job: Job | undefined = useSelector((state) => getJob(state, jobId));

  const initialFormState = useMemo(() => {
    return {
      job: {
        jobNotes: job?.jobNotes,
      },
    };
  }, [job]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <TextareaFieldFormatter
          name="job.jobNotes"
          value={formState.job?.jobNotes}
          onChange={onFieldChange}
          placeholder={`Give yourself an edge over your competitors by adding additional information to your ${
            !!job && isInvoice(job?.status) ? 'invoice' : 'proposal'
          }.`}
          changeTimeoutMs={2000}
        />
      );
    },
    [job?.status]
  );

  return (
    <EditJobSection title="Notes" className="job-additional-notes-control">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
        onFormStateChange={updateJob}
      />
    </EditJobSection>
  );
};

export default JobAdditionalNotesControl;
