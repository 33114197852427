import React, { FC, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import PipelineControl from '@/ui/pages/dealPage/components/ProjectHeader/PipelineControl';
import PipelineStageControl from '@/ui/pages/dealPage/components/ProjectHeader/PipelineStageControl';
import ProjectOwnerControl from '@/ui/pages/dealPage/components/ProjectHeader/ProjectOwnerControl';
import useGetProjectOverview from '@/api/queries/project/useGetProjectOverview';
import { Link, useParams } from 'react-router-dom';
import { TaggableEntityType } from '@payaca/types/tagTypes';
import EntityTagControl from '@/ui/components/entityTagControl/EntityTagControl';
import projectKeys from '@/api/queries/project/keyFactory';
import ContextMenu from '@payaca/components/plDropdown/ContextMenu';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import DealTemplateModal, {
  DealTemplateModalViews,
} from '@/ui/components/dealTemplateModal/DealTemplateModal';
import EditProjectModal from '@/ui/pages/dealPage/components/ProjectHeader/EditProjectModal';
import * as dealTemplateActions from '@payaca/store/dealTemplates/dealTemplateActions';
import { useDispatch } from 'react-redux';
import { useSelector } from '@/api/state';
import DeleteProjectModal from '@/ui/pages/dealPage/components/ProjectHeader/DeleteProjectModal';
import RestoreProjectModal from '@/ui/pages/dealPage/components/ProjectHeader/RestoreProjectModal';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { JobPermissions } from '@payaca/permissions/jobs/job.permissions';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';

export interface IProps {}

const ProjectHeader: FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { dealId } = useParams<{ dealId: string }>();
  const { data: projectData, isLoading: isProjectDataLoading } =
    useGetProjectOverview(+dealId);

  // *start*
  // ToDo: remove use of redux in this component
  useEffect(() => {
    // fetch deal templates with empty search to see if any exist on account
    dispatch(dealTemplateActions.requestGetDealTemplates(''));
  }, []);
  const dealTemplates = useSelector(
    (state: any) => state.dealTemplates.dealTemplates
  );
  // *end*

  const [showDealTemplateModal, setShowDealTemplateModal] = useState<
    DealTemplateModalViews | undefined
  >();
  const [isEditProjectModalOpen, setIsEditProjectModalOpen] = useState(false);
  const [isDeleteProjectModalOpen, setIsDeleteProjectModalOpen] =
    useState(false);
  const [isRestoreProjectModalOpen, setIsRestoreProjectModalOpen] =
    useState(false);

  const projectContextMenuOptions = useMemo(() => {
    const options = [
      {
        label: 'Create template',
        onClick: () => {
          setShowDealTemplateModal(DealTemplateModalViews.CREATE);
        },
      },
    ];

    if (projectData?.project.archivedAt) {
      options.push({
        label: 'Restore Project',
        onClick: () => {
          setIsRestoreProjectModalOpen(true);
        },
      });
    } else {
      options.push({
        label: 'Edit reference/description',
        onClick: () => setIsEditProjectModalOpen(true),
      });

      if (dealTemplates.length) {
        options.push({
          label: 'Load template',
          onClick: () => {
            setShowDealTemplateModal(DealTemplateModalViews.LOAD);
          },
        });
      }

      options.push({
        label: 'Delete Project',
        onClick: () => {
          setIsDeleteProjectModalOpen(true);
        },
      });
    }

    return options;
  }, [projectData, dealTemplates]);

  if (isProjectDataLoading) {
    return (
      <>
        <div className="mb-4">
          <SkeletonLoader.Title className="mb-4 w-1/4" />

          <div className="flex gap-4">
            <SkeletonLoader.Title />
            <SkeletonLoader.Title />
            <SkeletonLoader.Title />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="prose flex flex-col-reverse sm:flex-row sm:items-center sm:gap-4">
        <h2 className="order-1 sm:order-first">
          Project: #{projectData?.project.reference}
        </h2>
        {projectData?.project.customer && (
          <h2 className="order-first sm:order-1">
            <Link to={`/customers/${projectData.project.customer?.id}`}>
              {projectData.project.customer.name}
            </Link>
          </h2>
        )}
        <div className="order-2 ml-auto">
          <EntityTagControl
            entityId={+dealId}
            entityType={TaggableEntityType.DEAL}
            entityTags={
              projectData?.project?.tags.map((tag) => ({
                id: +tag.id,
                tagText: tag.name,
                tagColour: tag.colour || '',
                isSystemManaged: false,
                archivedAt: null,
              })) || []
            }
            isReadOnly={projectData?.project?.archivedAt !== null}
            onEntityTagsChange={() => {
              queryClient.invalidateQueries({
                queryKey: projectKeys.overview(+dealId),
              });
            }}
          />
        </div>
      </div>

      {projectData?.project.description && (
        <div className="prose">
          <p>{projectData?.project.description}</p>
        </div>
      )}

      <div className="my-4 flex items-start gap-4 lg:items-stretch">
        <div className="flex w-full flex-wrap gap-4">
          <PermissionGuard
            renderIfHasPermissions={[
              DealsPermissions.UPDATE_DEAL,
              DealsPermissions.UPDATE_MY_DEAL,
            ]}
          >
            <div className="flex-[1_1_0px]">
              <PipelineControl />
            </div>
            <div className="flex-[1_1_0px]">
              <PipelineStageControl />
            </div>
          </PermissionGuard>

          <PermissionGuard
            renderIfHasPermissions={[
              JobPermissions.UPDATE_JOB,
              DealsPermissions.GET_DEAL,
            ]}
            requireAllPermissions={true}
          >
            <div className="flex-[1_1_0px]">
              <ProjectOwnerControl />
            </div>
          </PermissionGuard>
        </div>
        <ContextMenu
          variant={EBtnVariant.White}
          size={EBtnSize.Small}
          menuItems={projectContextMenuOptions}
        />
      </div>

      {showDealTemplateModal && (
        <DealTemplateModal
          dealId={+dealId}
          isOpen={!!showDealTemplateModal}
          view={showDealTemplateModal}
          onClose={() => setShowDealTemplateModal(undefined)}
        />
      )}

      <EditProjectModal
        isOpen={isEditProjectModalOpen}
        onClose={() => setIsEditProjectModalOpen(false)}
      />

      <DeleteProjectModal
        isOpen={isDeleteProjectModalOpen}
        onClose={() => setIsDeleteProjectModalOpen(false)}
      />

      <RestoreProjectModal
        isOpen={isRestoreProjectModalOpen}
        onClose={() => setIsRestoreProjectModalOpen(false)}
      />
    </>
  );
};

export default ProjectHeader;
