import isEqual from 'lodash.isequal';
import {
  CANADA_SALES_TAX_OPTIONS,
  CANADA_SALES_TAX_SETTINGS,
  GST_OPTIONS,
  GST_SETTINGS,
  VAT_OPTIONS,
  VAT_SETTINGS,
  SALES_TAX_OPTIONS,
  SALES_TAX_SETTINGS,
  SOUTH_AFRICA_VAT_OPTIONS,
  SOUTH_AFRICA_VAT_SETTINGS,
} from '@payaca/types/salesTaxTypes';
import { AccountRegions } from '@payaca/types/accountTypes';

export const getRegionalSalesTaxSettings = (accountRegion?: AccountRegions) => {
  switch (accountRegion) {
    case AccountRegions.AUSTRALIA:
      return GST_SETTINGS;
    case AccountRegions.CANADA:
      return CANADA_SALES_TAX_SETTINGS;
    case AccountRegions.SOUTH_AFRICA:
      return SOUTH_AFRICA_VAT_SETTINGS;
    case AccountRegions.US:
      return SALES_TAX_SETTINGS;
    case AccountRegions.UK:
    // fall-through
    default:
      return VAT_SETTINGS;
  }
};

export const getRegionalSalesTaxOptions = (
  accountRegion?: AccountRegions
): any[] => {
  switch (accountRegion) {
    case AccountRegions.AUSTRALIA:
      return GST_OPTIONS;
    case AccountRegions.CANADA:
      return CANADA_SALES_TAX_OPTIONS;
    case AccountRegions.SOUTH_AFRICA:
      return SOUTH_AFRICA_VAT_OPTIONS;
    case AccountRegions.US:
      return SALES_TAX_OPTIONS;
    case AccountRegions.UK:
    // fall-through
    default:
      return VAT_OPTIONS;
  }
};

export const getVatTypeFromVatSettings = (
  vatAmount: number | null,
  isReverseChargeVat: boolean,
  isVatIncluded: boolean,
  accountRegion?: AccountRegions
) => {
  const vatSettings = {
    vatAmount: isVatIncluded ? vatAmount : null,
    vatIncluded: isVatIncluded,
    isReverseChargeVat: isReverseChargeVat,
  };
  const regionalSettings = getRegionalSalesTaxSettings(accountRegion);

  return Object.keys(regionalSettings).find((key: string) => {
    return isEqual(regionalSettings[key], vatSettings);
  });
};

export const getVatLabelFromVatSettings = (
  vatAmount: number | null,
  isReverseChargeVat: boolean,
  isVatIncluded: boolean,
  accountRegion?: AccountRegions
) => {
  const vatType = getVatTypeFromVatSettings(
    vatAmount,
    isReverseChargeVat,
    isVatIncluded,
    accountRegion
  );

  const regionalOptions: Array<{ label: string; value: string }> =
    getRegionalSalesTaxOptions(accountRegion);

  return regionalOptions.find((x: any) => x.value === vatType)?.label;
};

export const getRegionalZeroPercentageSalesTaxName = (
  accountRegion = AccountRegions.UK
) => {
  switch (accountRegion) {
    case AccountRegions.AUSTRALIA:
      return 'GST free';
    case AccountRegions.CANADA:
      return 'GST/HST free';
    case AccountRegions.NEW_ZEALAND:
      return 'GST free';
    case AccountRegions.US:
      return 'No Sales tax';
    case AccountRegions.SOUTH_AFRICA:
    // fall-through
    case AccountRegions.UK:
    // fall-through
    default:
      return 'No VAT';
  }
};

// the amount of VAT we have to apply when selling in different regions - used for subscriptions
export const getRegionalPayacaVATPercentage = (
  accountRegion: AccountRegions
) => {
  switch (accountRegion) {
    case AccountRegions.AUSTRALIA:
    // fall-through
    case AccountRegions.CANADA:
    // fall-through
    // TODO: Check - is it correct that we don't apply VAT when selling in Canada?
    case AccountRegions.NEW_ZEALAND:
    // fall-through
    // TODO: Check - is it correct that we don't apply VAT when selling in New Zealand?
    case AccountRegions.SOUTH_AFRICA:
    // fall-through
    // TODO: Check - is it correct that we don't apply VAT when selling in South Africa?
    case AccountRegions.US:
      return 0;
    case AccountRegions.UK:
    // fall-through
    default:
      return 20;
  }
};
