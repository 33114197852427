import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getSupplier } from '@/utils/stateAccessors';

import BasicField from '@payaca/components/basicField/BasicField';
import EditableElementControl from '@payaca/components/editableElementControl/EditableElementControl';
import TextareaField from '@payaca/components/textareaField/TextareaField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { TagControlAlignment } from '@payaca/components/tagControl/TagControl';
import EntityTagControl from '../entityTagControl/EntityTagControl';

import {
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';

import {
  requestGetSupplier,
  requestPersistSupplier,
} from '@payaca/store/suppliers/suppliersActions';
import { PersistSupplierRequestData } from '@payaca/store/suppliers/suppliersTypes';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { TaggableEntityType } from '@payaca/types/tagTypes';

import './SupplierBasicInformationControl.sass';
import { useSelector } from '@/api/state';

const isRequiredFieldValidator = getIsRequiredFieldValidator();
const supplierNameLengthFieldValidator = getLengthFieldValidator({ max: 250 });

type Props = {
  supplierId: number;
};
const SupplierBasicInformationControl: FC<Props> = ({
  supplierId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isEditingSupplierName, setIsEditingSupplierName] = useState(false);
  const [isEditingSupplierNotes, setIsEditingSupplierNotes] = useState(false);

  const supplier = useSelector((state) => getSupplier(state, supplierId));

  const fieldValidators = useMemo(() => {
    return {
      name: [isRequiredFieldValidator, supplierNameLengthFieldValidator],
    };
  }, []);

  const initialFormState = useMemo(() => {
    return {
      id: supplier?.id,
      name: supplier?.name,
      notes: supplier?.notes,
    };
  }, [supplier]);

  const onSubmit = useCallback(
    (persistSupplierData: PersistSupplierRequestData) => {
      dispatch(
        requestPersistSupplier(persistSupplierData, () =>
          dispatch(requestGetSupplier(supplierId))
        )
      );
    },
    [dispatch, supplierId]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <div className="form-body">
          <div className="supplier-name">
            {supplier?.isSystemManaged ? (
              <h3>{formState.name}</h3>
            ) : (
              <EditableElementControl
                customIsEditingState={isEditingSupplierName}
                onEdit={() => {
                  setIsEditingSupplierNotes(false);
                  setIsEditingSupplierName(true);
                }}
                onSave={() => {
                  if (!validationState.name.isValid) {
                    return;
                  }
                  setIsEditingSupplierName(false);
                  onSubmit(formState as PersistSupplierRequestData);
                }}
                addText="Notes about this supplier can be added here"
                value={formState.name}
                renderValue={(value: string) => <h3>{value}</h3>}
                editTemplate={
                  <ValidatedFieldWrapper
                    validationResult={validationState.name}
                  >
                    <BasicField
                      name={'name'}
                      styleVariant={InputStyleVariant.OUTSIZE}
                      value={formState.name}
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                    />
                  </ValidatedFieldWrapper>
                }
              />
            )}
          </div>
          <div className="supplier-notes">
            {supplier?.isSystemManaged ? (
              <div>{formState.notes}</div>
            ) : (
              <EditableElementControl
                customIsEditingState={isEditingSupplierNotes}
                onEdit={() => {
                  setIsEditingSupplierNotes(true);
                  setIsEditingSupplierName(false);
                }}
                onSave={() => {
                  setIsEditingSupplierNotes(false);
                  onSubmit(formState as PersistSupplierRequestData);
                }}
                addText="Notes about this supplier can be added here"
                value={formState.notes}
                editTemplate={
                  <ValidatedFieldWrapper
                    validationResult={validationState.notes}
                  >
                    <TextareaField
                      name={'notes'}
                      styleVariant={InputStyleVariant.OUTSIZE}
                      value={formState.notes}
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                    />
                  </ValidatedFieldWrapper>
                }
              />
            )}
          </div>
        </div>
      );
    },
    [isEditingSupplierName, isEditingSupplierNotes, supplier?.isSystemManaged]
  );

  if (!supplier) return null;
  return (
    <div className="supplier-basic-information-control">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
        fieldValidators={fieldValidators}
      />
      <EntityTagControl
        isReadOnly={supplier?.isSystemManaged}
        alignment={TagControlAlignment.LEFT}
        entityId={supplierId}
        entityType={TaggableEntityType.SUPPLIER}
        entityTags={supplier.tags}
        onEntityTagsChange={() => dispatch(requestGetSupplier(supplierId))}
      />
    </div>
  );
};

export default SupplierBasicInformationControl;
