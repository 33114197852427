export const apiFetch = (path: string, requestInit?: RequestInit) => {
  const token = localStorage.getItem('payacav1'); // TOKEN_KEY

  return fetch(`${import.meta.env.VITE_API_HOST}${path}`, {
    ...requestInit,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...requestInit?.headers,
    },
  });
};
