import { Reducer } from 'redux';

import { ActionType, State } from './tagsTypes';

export const initialState: State = {
  tags: [],
  isGettingTags: false,
  isRemovingTagFromEntity: false,
  isAddingExistingTagToEntity: false,
};

const tagsReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_TAGS:
      return {
        ...state,
        isGettingTags: true,
      };
    case ActionType.GET_TAGS_SUCCESS:
      return {
        ...state,
        isGettingTags: false,
        tags: action.payload.tags,
      };
    case ActionType.GET_TAGS_FAILURE:
      return {
        ...state,
        isGettingTags: false,
      };

    case ActionType.REQUEST_ADD_EXISTING_TAG_TO_ENTITY:
      return {
        ...state,
        isAddingExistingTagToEntity: true,
      };
    case ActionType.ADD_EXISTING_TAG_TO_ENTITY_FAILURE:
    case ActionType.ADD_EXISTING_TAG_TO_ENTITY_SUCCESS:
      return {
        ...state,
        isAddingExistingTagToEntity: false,
      };

    case ActionType.REQUEST_REMOVE_TAG_FROM_ENTITY:
      return {
        ...state,
        isRemovingTagFromEntity: true,
      };
    case ActionType.REMOVE_TAG_FROM_ENTITY_FAILURE:
    case ActionType.REMOVE_TAG_FROM_ENTITY_SUCCESS:
      return {
        ...state,
        isRemovingTagFromEntity: false,
      };

    default:
      return state;
  }
};

export default tagsReducer;
