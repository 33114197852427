import React, {
  FunctionComponent,
  useCallback,
  useRef,
  useMemo,
  useEffect,
  useState,
} from 'react';
import './CollapsiblePanel.sass';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
// @ts-ignore
import scrollToWithAnimation from 'scrollto-with-animation';

export enum CollapsiblePanelStyleVariant {
  STANDARD = 'standard',
  OUTSIZE = 'outsize',
  MINIMAL = 'minimal',
  UNSTYLED = 'unstyled',
  BAR = 'bar',
}

type Props = {
  openControlIcon?: IconDefinition;
  closeControlIcon?: IconDefinition;
  delayMs?: number;
  title?: string | JSX.Element;
  children?: JSX.Element | JSX.Element[];
  iconOnlyClick?: boolean;
  isDisabled?: boolean;
  isOpen: boolean;
  showControlIcons?: boolean;
  transitionDurationMs?: number;
  shouldRenderHeader?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  styleVariant?: CollapsiblePanelStyleVariant;
  watchSize?: any; // Property that will trigger resize on change
  scrollParentReference?: React.RefObject<HTMLDivElement>;
};

const CollapsiblePanel: FunctionComponent<Props> = ({
  openControlIcon,
  closeControlIcon,
  delayMs = 0,
  title,
  children,
  iconOnlyClick = false,
  isDisabled = false,
  isOpen,
  showControlIcons = true,
  transitionDurationMs = 300,
  shouldRenderHeader = true,
  onOpen,
  onClose,
  styleVariant = CollapsiblePanelStyleVariant.STANDARD,
  watchSize,
  scrollParentReference,
}: Props): JSX.Element => {
  const bodyReference = useRef<HTMLDivElement>(null);
  const [bodyContainerHeight, setBodyContainerheight] = useState(0);

  const getBodyContainerHeight = useCallback(() => {
    if (!isOpen) {
      return 0;
    }
    return bodyReference?.current?.clientHeight || 0;
  }, [isOpen, bodyReference]);

  const transitionDuration = useMemo(
    () => `${transitionDurationMs}ms`,
    [transitionDurationMs]
  );

  const scrollToPanelContent = useCallback(() => {
    if (!scrollParentReference?.current) return;

    const scrollParentOffsetTop = scrollParentReference.current.offsetTop;

    // todo rename

    const parentElementClientHeight =
      scrollParentReference.current.clientHeight;

    const materialsPanelOffsetTop = bodyReference?.current?.offsetTop || 0;

    const materialsPanelOffsetHeight =
      bodyReference?.current?.offsetHeight || 0;

    const materialsPanelTopScroll =
      materialsPanelOffsetTop - scrollParentOffsetTop;

    const materialsPanelBottomScroll =
      materialsPanelOffsetHeight +
      materialsPanelOffsetTop -
      parentElementClientHeight;

    scrollToWithAnimation(
      scrollParentReference.current,
      'scrollTop',
      Math.min(materialsPanelBottomScroll, materialsPanelTopScroll),
      300,
      'easeInOutCirc'
    );
  }, []);

  const onHeaderClick = useCallback(() => {
    if (isDisabled) {
      return;
    }

    if (isOpen) {
      onClose && onClose();
    } else {
      onOpen && onOpen();
      scrollToPanelContent();
    }
  }, [isDisabled, isOpen, onClose, onOpen, scrollToPanelContent]);

  useEffect(() => {
    setBodyContainerheight(getBodyContainerHeight());
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setBodyContainerheight(getBodyContainerHeight());
    }, delayMs);
  }, [isOpen, bodyReference, children, delayMs, watchSize]);

  const openIcon = useMemo(() => {
    let icon = faChevronRight;
    if (openControlIcon) {
      icon = openControlIcon;
    } else if (styleVariant === CollapsiblePanelStyleVariant.BAR) {
      icon = faChevronDown;
    }
    return <FontAwesomeIcon icon={icon} className="open-control-icon" />;
  }, [openControlIcon, styleVariant]);

  const closeIcon = useMemo(() => {
    let icon = faChevronDown;
    if (closeControlIcon) {
      icon = closeControlIcon;
    } else if (styleVariant === CollapsiblePanelStyleVariant.BAR) {
      return null;
    }
    return <FontAwesomeIcon icon={icon} className="close-control-icon" />;
  }, [closeControlIcon, styleVariant]);

  const onTitleClick = (e: any) => {
    if (iconOnlyClick) {
      e.stopPropagation();
    }
  };

  const onIconContainerClick = (e: any) => {
    if (styleVariant === CollapsiblePanelStyleVariant.BAR && isOpen) {
      e.stopPropagation();
    }
  };

  return (
    <div
      className={`collapsible-panel${isOpen ? ' open' : ''}${
        isDisabled ? ' disabled' : ''
      } collapsible-panel-style-${styleVariant}`}
      style={{ transitionDuration: transitionDuration }}
    >
      {shouldRenderHeader && (
        <div
          className="collapsible-panel-header"
          onClick={onHeaderClick}
          style={{ transitionDuration: transitionDuration }}
        >
          <div className="title" onClick={onTitleClick}>
            {title}
          </div>
          {showControlIcons && (
            <span
              className="control-icon-container"
              onClick={onIconContainerClick}
            >
              <span>{openIcon}</span>
              <span>{closeIcon}</span>
            </span>
          )}
        </div>
      )}
      <div
        className="collapsible-panel-body-container"
        style={{
          height: bodyContainerHeight,
          transitionDuration: transitionDuration,
        }}
      >
        <div className="collapsible-panel-body" ref={bodyReference}>
          {children}
          {styleVariant === CollapsiblePanelStyleVariant.BAR && (
            <span className="control-icon-container" onClick={onHeaderClick}>
              <span>
                <FontAwesomeIcon
                  icon={faChevronUp}
                  className="close-control-icon"
                />
              </span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollapsiblePanel;
