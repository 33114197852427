export enum TasksPermissions {
  GET_TASKS = 'tasks.getTasks',
  GET_SELF_ASSIGNED_DEAL_TASKS = 'tasks.getSelfAssignedDealTasks',
  GET_SELF_ASSIGNED_EVENT_TASKS = 'tasks.getSelfAssignedEventTasks',
  GET_SELF_ASSIGNED_TASKS = 'tasks.getSelfAssignedTasks',
  PERSIST_TASK = 'tasks.persistTask',
  DELETE_TASK = 'tasks.deleteTask',
  PERSIST_SELF_CREATED_TASK = 'tasks.persistSelfCreatedTask',
  PERSIST_SELF_ASSIGNED_DEAL_TASK = 'tasks.persistSelfAssignedDealTask',
  COMPLETE_SELF_ASSIGNED_EVENT_TASKS = 'tasks.persistSelfAssignedEventTasks',
  DELETE_SELF_CREATED_TASK = 'tasks.deleteSelfCreatedTask',
  COMPLETE_TASK = 'tasks.completeTask',
  COMPLETE_SELF_ASSIGNED_TASK = 'tasks.completeSelfAssignedTask',
  COMPLETE_SELF_ASSIGNED_DEAL_TASK = 'tasks.completeSelfAssignedDealTask',
}
