import { FC } from 'react';

import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import {
  MaterialsListMaterial,
  MaterialsListSummaryItems,
} from '@/api/queries/project/useGetProjectMaterialListSummaryItems';
import {
  getAccountRegionFromCurrencyCode,
  getInternationalDateFormatByLocale,
  getRegionalTextString,
} from '@payaca/helpers/internationalHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import { Link } from 'react-router-dom';
import { Deal } from '@payaca/types/dealTypes';

import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';

import { roundToDecimalPlaces } from '@payaca/helpers/numberHelper';
import {
  MaterialsListAuditLog,
  MaterialsListAuditLogsPage,
} from '@/api/queries/project/useGetProjectMaterialsListAuditLogs';
import UserAvatar from '@payaca/components/userAvatar/UserAvatar';
import { AvatarSizeVariant } from '@payaca/components/plAvatar/Avatar';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { format } from 'date-fns';
import {
  useAuditLogsInputPaginationState,
  usePaginationState,
} from './materialsPanelHooks';
import useGetMyAccountUsers from '@/api/queries/me/useGetMyAccountUsers';

export const HistoryPanel: FC<{
  auditLogs: MaterialsListAuditLogsPage | undefined;
  projectId?: Deal['id'];
  inputPaginationState: ReturnType<typeof useAuditLogsInputPaginationState>;
}> = ({
  projectId,
  auditLogs,
  inputPaginationState: { pagination, input },
}) => {
  const { data: regionPreferences } = useGetMyRegionPreferences();

  const timestampRegionalFormat = getInternationalDateFormatByLocale(
    DateFormats.TIMESTAMP_SHORT,
    regionPreferences?.locale
  );

  const { data: accountUsers } = useGetMyAccountUsers();
  const activeAccountUsers = accountUsers?.filter((u) => u.status === 'Active');

  return (
    <div className="@container">
      <ManageableItemsList.ActionBar>
        <ManageableItemsList.ActionBar.SearchInput
          onChange={input.setSearchTerm}
        />
        <ManageableItemsList.ActionBar.BasicFilter
          options={
            activeAccountUsers?.map((u) => ({
              value: u.id,
              label: u.fullName,
            })) || []
          }
          value={input.actingUserIds || []}
          onChange={input.setActingUserIds}
        />
      </ManageableItemsList.ActionBar>
      <ManageableItemsList.Table<MaterialsListAuditLog>
        isLoading={!auditLogs}
        items={auditLogs?.items || []}
        uniqueKey={'id'}
      >
        <ManageableItemsList.Table.Column<MaterialsListAuditLog, 'id'>
          header="Material"
          field="id"
          render={(id, row) => (
            <div className="max-w-[400px] truncate">
              <Link to={`/materials/${row.material.internalId}`}>
                {row.material.name}
              </Link>
            </div>
          )}
        />

        <ManageableItemsList.Table.Column<MaterialsListAuditLog, 'id'>
          header="Qty change"
          field="id"
          className="text-right"
          render={(id, row) => (
            <>
              {row.quantityChange >= 0 ? '+' : '-'}
              {roundToDecimalPlaces(Math.abs(row.quantityChange), 2)}
            </>
          )}
        />
        <ManageableItemsList.Table.Column<MaterialsListAuditLog, 'id'>
          header="Submitted by"
          field="id"
          render={(id, row) => row.actingUser?.fullName}
        />
        <ManageableItemsList.Table.Column<MaterialsListAuditLog, 'id'>
          header="Submitted at"
          field="id"
          render={(id, row) => format(row.timestamp, timestampRegionalFormat)}
        />
      </ManageableItemsList.Table>
      {auditLogs && (
        <ManageableItemsList.PaginationBar
          totalItems={auditLogs.totalCount}
          pageSize={auditLogs.limit}
          currentPage={auditLogs.offset / auditLogs.limit + 1}
          onPageChange={pagination.setCurrentPage}
        />
      )}
    </div>
  );
};
