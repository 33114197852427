import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PublicEntityTemplate } from '@payaca/types/entity-templates';
import { useDispatch } from 'react-redux';
import { SortDirection } from '@payaca/types/listViewTypes';
import './TimelogLinkedEntity.sass';
import EmptyState from '../emptyState/EmptyState';
import {
  GetListedTimelogsQueryParams,
  SortBy,
  ListedTimelogsPage,
  GetAssigneeGroupedListedTimelogsQueryParams,
  AssigneeGroupedListedTimelogs,
  AssigneeTimelogsGrouping as AssigneeTimelogsGroupingType,
} from '@payaca/types/listedTimelogTypes';
import moment from 'moment-timezone';
import {
  getAssigneeGroupedListedTimelogs,
  getListedTimelogsPage,
  getTimelogTypes,
} from '@payaca/store/timelogs/timelogsActions';
import TimelogCard from '../timelogCard/TimelogCard';
import {
  PublicHydratedTimelog,
  PublicTimelogLinkedEntity,
  PublicTimelogType,
  TimelogAssignee,
} from '@payaca/types/timelogs';
import { actions as usersActions } from '@/api/users';
import { useSelector } from '@/api/state';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getRegion } from '@/utils/stateAccessors';
import {
  DurationUnit,
  getReadableStringFromDurationString,
} from '@payaca/utilities/timeUtilities';
import { useAccountUsers } from '@/utils/storeHooks';
import TimelogFilters from '../timelogFilters/TimelogFilters';
import Tag from '@payaca/components/tag/Tag';
import { useHistory } from 'react-router';
import ScheduledEventReadDrawer from '../scheduledEventReadDrawer/ScheduledEventReadDrawer';
import TaskReadCompleteDrawer from '../taskReadCompleteDrawer/TaskReadCompleteDrawer';
import { Link } from 'react-router-dom';

type Props = {
  entity: PublicTimelogLinkedEntity;
};

const TimelogLinkedEntity: FC<Props> = ({ entity }): JSX.Element | null => {
  const history = useHistory();

  const [showScheduledEventDrawer, setShowScheduledEventDrawer] =
    useState(false);
  const [showTaskDrawer, setShowTaskDrawer] = useState(false);

  const linkTo = useMemo(() => {
    switch (entity.entityType) {
      case 'deal':
        return `/deals/${entity.entityPublicId}`;
      case 'customer':
        return `/customers/${entity.entityPublicId}`;
    }
  }, [entity]);

  const clickAction = useMemo(() => {
    switch (entity.entityType) {
      case 'task':
        return () => setShowTaskDrawer(true);
      case 'scheduledEvent':
        return () => setShowScheduledEventDrawer(true);
    }
  }, [entity]);

  const readableEntityType = useMemo(() => {
    switch (entity.entityType) {
      case 'scheduledEvent':
        return 'event';
      case 'deal':
        return 'project';
      default:
        return entity.entityType;
    }
  }, [entity]);

  const tagElement = useMemo(() => {
    return (
      <Tag
        tagText={`${readableEntityType}: ${entity.entityReadableIdentifier}`}
        showTagIcon={false}
        isRemovable={false}
        colour="#edf2f7"
      />
    );
  }, [readableEntityType, entity]);

  return (
    <>
      <div className="timelog-linked-entity">
        {linkTo && (
          <Link to={linkTo} target="_blank" rel="noopener noreferrer">
            {tagElement}
          </Link>
        )}

        {!linkTo && (
          <div
            className={clickAction ? 'clickable' : ''}
            role={clickAction ? 'button' : ''}
            onClick={clickAction}
          >
            {tagElement}
          </div>
        )}
      </div>
      <ScheduledEventReadDrawer
        isOpen={showScheduledEventDrawer}
        onClose={() => setShowScheduledEventDrawer(false)}
        scheduledEventId={+entity.entityPublicId}
      />
      <TaskReadCompleteDrawer
        isOpen={showTaskDrawer}
        onClose={() => setShowTaskDrawer(false)}
        taskId={+entity.entityPublicId}
      />
    </>
  );
};

export default TimelogLinkedEntity;
