import React, { FC, useEffect, useReducer, useState } from 'react';
import { Transition } from '@headlessui/react';
import { useScroll } from 'react-use';

import { GetMaterialsInput, MaterialsQuery } from '@/gql/graphql';
import { useGetInfiniteMaterials } from '@/api/queries/materials/useGetMaterials';
import Sidebar, {
  Props as TSidebarProps,
} from '@payaca/components/plSidebar/Sidebar';
import Conditional from '@payaca/components/conditional/Conditional';
import EmptyState from '@payaca/components/plEmptyState/EmptyState';
import MaterialCard from '@payaca/components/materialCard/MaterialCard';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import useGetSuppliers from '@/api/queries/suppliers/useGetSuppliers';
import useGetMaterialCategories from '@/api/queries/materials/useGetMaterialCategories';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import Modal from '@payaca/components/plModal/Modal';
import CreateEditMaterialModal from '@/ui/components/createEditMaterialModal/CreateEditMaterialModal';
import usePreferredNumberFormat from '@/hooks/usePreferredNumberFormat';
import { singularPlural } from '@payaca/utilities/stringUtilities';
import { clstx } from '@payaca/components/utils';
import InfiniteList from '@payaca/components/infiniteList/InfiniteList';

type AddMaterialSidebarProps = Pick<
  TSidebarProps,
  'isOpen' | 'onClose' | 'title' | 'zIndexLevel' | 'warnUserContent'
> & {
  onAddMaterials: (materials: MaterialState[]) => Promise<void>;
  emptyStatePromptText?: string;
  primaryActionText?: string;
};

type Material = MaterialsQuery['materials']['items'][number];

export type MaterialState = Material & { quantity: number };

type ModalState = {
  activeModal: 'createMaterial' | 'blockEdit' | null;
};

type ModalAction =
  | {
      type: 'openCreateModalModal';
    }
  | {
      type: 'openBlockEditModal';
    }
  | {
      type: 'closeModal';
    };

const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case 'openCreateModalModal':
      return { activeModal: 'createMaterial' };
    case 'openBlockEditModal':
      return { activeModal: 'blockEdit' };
    case 'closeModal':
      return { activeModal: null };
    default:
      return state;
  }
};

const BuildMaterialsListSidebar: FC<AddMaterialSidebarProps> = (props) => {
  const {
    isOpen,
    onClose,
    title,
    zIndexLevel,
    onAddMaterials,
    emptyStatePromptText,
    primaryActionText,
    warnUserContent,
  } = props;

  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  const { y: scrollY } = useScroll({ current: scrollRef });
  const [modalState, modalDispatch] = useReducer(modalReducer, {
    activeModal: null,
  });
  const [isSaving, setIsSaving] = useState(false);

  const [selectedMaterials, setSelectedMaterials] = useState<MaterialState[]>(
    []
  );

  const [getMaterialsInput, setGetMaterialsInput] = useState<GetMaterialsInput>(
    {}
  );

  useEffect(() => {
    if (isOpen) {
      setSelectedMaterials([]);
      setGetMaterialsInput({});
    }
  }, [isOpen]);

  const compactNumberFormat = usePreferredNumberFormat({
    notation: 'compact',
    compactDisplay: 'short',
  });

  const {
    data: materials,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useGetInfiniteMaterials(getMaterialsInput);

  const { suppliers } = useGetSuppliers({}, { limit: 100 });
  const { materialCategories } = useGetMaterialCategories();

  const suppliersFilters =
    suppliers?.items.map((supplier) => ({
      id: supplier.id,
      label: supplier.name,
      selected: getMaterialsInput.suppliers?.some((i) => i === supplier.id),
      onClick: (selected: boolean) => {
        setGetMaterialsInput((state) => ({
          ...state,
          suppliers: selected
            ? [...(state.suppliers || []), supplier.id]
            : state.suppliers?.filter((i) => i !== supplier.id),
        }));
      },
    })) || [];

  const categoriesFilters =
    materialCategories?.map((category) => ({
      id: category.id,
      label: category.name,
      selected: getMaterialsInput.categories?.some((i) => i === category.id),
      onClick: (selected: boolean) => {
        setGetMaterialsInput((state) => ({
          ...state,
          categories: selected
            ? [...(state.categories || []), category.id]
            : state.categories?.filter((i) => i !== category.id),
        }));
      },
    })) || [];

  const totalQuantity = selectedMaterials.reduce((acc, selectedMaterial) => {
    return acc + selectedMaterial.quantity;
  }, 0);

  const totalMaterialsFound = materials?.pages[0]?.materials.totalCount || 0;

  return (
    <>
      <Sidebar
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        zIndexLevel={zIndexLevel}
        title={title}
        warnUserOnClose={selectedMaterials.length > 0}
        warnUserContent={warnUserContent}
      >
        <Sidebar.Body>
          <div className="grid h-full grid-cols-2">
            <InfiniteList ref={setScrollRef}>
              <InfiniteList.Header className={clstx(scrollY > 0 && 'shadow')}>
                <InfiniteList.Search
                  placeholder="Search materials"
                  onChangeTimeout={(value) => {
                    setGetMaterialsInput((s) => ({
                      ...s,
                      searchTerm: value,
                    }));
                  }}
                />

                <InfiniteList.Actionbar>
                  <InfiniteList.Filter
                    filters={categoriesFilters}
                    name="Category"
                  />

                  <InfiniteList.Filter
                    filters={suppliersFilters}
                    name="Suppliers"
                  />

                  <InfiniteList.PrimaryAction
                    className="flex-auto"
                    onClick={() => {
                      modalDispatch({ type: 'openCreateModalModal' });
                    }}
                  >
                    Create new Material
                  </InfiniteList.PrimaryAction>
                </InfiniteList.Actionbar>
              </InfiniteList.Header>

              <InfiniteList.List
                skeletonItem={<SkeletonLoader.MaterialCard />}
                isLoading={isLoading}
                prefix={
                  <Conditional
                    condition={!isLoading && totalMaterialsFound > 0}
                  >
                    <p className="supporting-body text-right">
                      {singularPlural(
                        totalMaterialsFound,
                        'material found',
                        'materials found',
                        {
                          lengthFormatted:
                            compactNumberFormat.format(totalMaterialsFound),
                        }
                      )}
                    </p>
                  </Conditional>
                }
                emptyStateText="No materials found"
                numOfSkeletonLoaders={12}
                canLoadMore={hasNextPage}
                onLoadMore={() => {
                  if (hasNextPage) {
                    void fetchNextPage();
                  }
                }}
                items={
                  materials?.pages
                    .map((m) => m.materials.items.map((i) => i))
                    .flat() || []
                }
                item={(material) => {
                  return (
                    <MaterialCard
                      key={material.id}
                      name={material.name}
                      price={material.suppliedBy[0].price.unitPrice}
                      thumbnailUrl={material.thumbnailUrl || ''}
                      suppliers={material.suppliedBy.map(
                        (i) => i.supplier.name
                      )}
                      actionOnClick={() => {
                        setSelectedMaterials((state) => [
                          ...state,
                          { ...material, quantity: 1 },
                        ]);
                      }}
                      actionDisabled={selectedMaterials.some(
                        (i) => i.id === material.id
                      )}
                    />
                  );
                }}
              />
            </InfiniteList>

            <div className="flex flex-col gap-2.5 overflow-auto border-l bg-gray-50 p-3.5">
              <Conditional condition={selectedMaterials.length === 0}>
                <EmptyState
                  className="my-auto"
                  iconName="arrow-block-left.3"
                  text={emptyStatePromptText}
                />
              </Conditional>

              {selectedMaterials.map((material) => (
                <Transition
                  key={material.id}
                  as="div"
                  appear
                  unmount
                  show={material.quantity > 0}
                  enter="transition duration-300"
                  enterFrom="opacity-0 -translate-x-full"
                  enterTo="opacity-100 translate-x-0"
                  leave="transition duration-300"
                  leaveFrom="opacity-100 translate-x-0"
                  leaveTo="opacity-0 -translate-x-full"
                  afterLeave={() => {
                    setSelectedMaterials((state) =>
                      state.filter((i) => i.quantity > 0)
                    );
                  }}
                >
                  <MaterialCard
                    name={material.name}
                    price={material.suppliedBy[0].price.unitPrice}
                    thumbnailUrl={material.thumbnailUrl || ''}
                    quantity={material.quantity}
                    suppliers={material.suppliedBy.map((i) => i.supplier.name)}
                    onChangeQuantity={(newQuantity) => {
                      setSelectedMaterials((state) =>
                        state.map((i) =>
                          i.id === material.id
                            ? { ...i, quantity: newQuantity }
                            : i
                        )
                      );
                    }}
                  />
                </Transition>
              ))}

              <Conditional condition={selectedMaterials.length !== 0}>
                <Button
                  className="sticky bottom-0 mt-auto"
                  isProcessing={isSaving}
                  disabled={isSaving}
                  onClick={async () => {
                    setIsSaving(true);

                    await onAddMaterials(selectedMaterials);

                    setIsSaving(false);

                    onClose?.();
                  }}
                >
                  {primaryActionText} ({totalQuantity})
                </Button>
              </Conditional>
            </div>
          </div>
        </Sidebar.Body>
      </Sidebar>

      <CreateEditMaterialModal
        isOpen={modalState.activeModal === 'createMaterial'}
        onClose={() => {
          modalDispatch({ type: 'closeModal' });
        }}
        onPersistMaterialSuccess={(materialId: number) => {
          modalDispatch({ type: 'closeModal' });
        }}
        enableSupplierMaterialInput={true}
      />
    </>
  );
};

export default BuildMaterialsListSidebar;
