import {
  faCheck,
  faCircleNotch,
  faFile,
  faFileCsv,
  faFilePdf,
  faImage,
  faPen,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../modal/Modal';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import BasicField from '../basicField/BasicField';
import Button from '../button/Button';
import { ButtonStyleVariant } from '../button/enums';

import './PersistedFileControl.sass';

type Props = {
  persistedFile: {
    fileName: string;
    identifier: any;
    thumbnailUrl?: string;
    mimeType?: string;
    downloadUrl?: string;
  };
  isDisabled?: boolean;
  allowFileNameModification?: boolean;
  showFileName?: boolean;
  isPersistingFileName?: boolean;
  isRemoving?: boolean;
  persistFileName?: (fileName: string) => void;
  removeFile?: (fileIdentifier: any) => void;
  canRemove?: boolean;
};

const PersistedFileControl: FunctionComponent<Props> = ({
  persistedFile,
  isDisabled = false,
  allowFileNameModification = false,
  showFileName = true,
  isPersistingFileName = false,
  isRemoving = false,
  persistFileName,
  removeFile,
  canRemove = false,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const [isModifyingFileName, setIsModifyingFileName] = useState(false);
  const [fileName, setFileName] = useState(persistedFile.fileName);
  const [fileNamePersistIsRequired, setFileNamePersistIsRequired] =
    useState(false);

  useEffect(() => {
    if (!isPersistingFileName && persistedFile.fileName === fileName) {
      setIsModifyingFileName(false);
    }
  }, [isPersistingFileName, persistedFile]);

  useEffect(() => {
    if (fileNamePersistIsRequired) {
      persistFileName && persistFileName(fileName);
      setFileNamePersistIsRequired(false);
    }
  }, [fileName, fileNamePersistIsRequired, persistFileName]);

  const fileIcon = useMemo(() => {
    switch (persistedFile.mimeType) {
      case 'application/pdf':
        return faFilePdf;
      case 'image/png':
      case 'image/jpeg':
        return faImage;
      case 'text/csv':
        return faFileCsv;
      default:
        return faFile;
    }
  }, [persistedFile.mimeType]);

  return (
    <div
      className={`persisted-file-control file-block ${
        persistedFile.downloadUrl ? 'downloadable' : ''
      }}`}
    >
      <a
        href={persistedFile.downloadUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className={`main-block ${
            !persistedFile.thumbnailUrl ? '' : 'image-block'
          }`}
        >
          {persistedFile.thumbnailUrl ? (
            <img src={persistedFile.thumbnailUrl} />
          ) : (
            <FontAwesomeIcon icon={fileIcon} />
          )}
        </div>
      </a>
      {showFileName && (
        <small>
          {persistedFile.fileName}{' '}
          {allowFileNameModification && persistFileName && !isDisabled && (
            <span
              className="modify-file-name-trigger"
              onClick={() => {
                setIsModifyingFileName(true);
              }}
            >
              <FontAwesomeIcon
                icon={isPersistingFileName ? faCircleNotch : faPen}
              />
            </span>
          )}
        </small>
      )}

      {!isDisabled && canRemove && !!removeFile && (
        <div
          className="remove-file-trigger"
          onClick={() => !isRemoving && removeFile?.(persistedFile.identifier)}
        >
          {<FontAwesomeIcon icon={isRemoving ? faCircleNotch : faTimes} />}
        </div>
      )}
      <Modal
        isOpen={isModifyingFileName}
        onClose={() => setIsModifyingFileName(false)}
        size="xs"
        actions={
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={() => {
              setFileNamePersistIsRequired(true);
              setIsModifyingFileName(false);
            }}
          >
            Save
          </Button>
        }
      >
        <BasicField
          name="fileName"
          value={fileName}
          onChange={(value: { [key: string]: any }) => {
            setFileName(value.fileName);
          }}
        />
      </Modal>
    </div>
  );
};

export default PersistedFileControl;
