import React, { FC, useCallback, useMemo, useState } from 'react';

import BasicField from '@payaca/components/basicField/BasicField';
import TextareaField from '@payaca/components/textareaField/TextareaField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';

import {
  getAllowEmptyValidator,
  getRegexMatchFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import { useSelector } from '@/api/state';
import { getUserRoles } from '@/utils/stateAccessors';
import DurationInputControl from '@payaca/components/durationInputControl/DurationInputControl';
import { VALID_ISO_8601_DURATION_REGEX } from '@payaca/constants';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';
import { UploadsPermissions } from '@payaca/permissions/uploads/uploads.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { PublicEntityTemplate } from '@payaca/types/entity-templates';
import { UPLOAD_MAX_FILESIZE_LIMIT } from '@payaca/types/uploadTypes';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import TagSelectionControl from '../tagSelectionControl/TagSelectionControl';
import UploadCollectionControl from '../uploadCollectionControl/UploadCollectionControl';
import './CreateEditScheduledEventTemplateControl.sass';

const acceptFileTypes = getAcceptedFileTypes(['document', 'image', 'video']);

type Props = {
  scheduledEventTemplate?: Omit<
    PublicEntityTemplate<'scheduledEvent'>,
    'publicId'
  >;
  onRequestSave?: (
    scheduledEventTemplate: Omit<
      PublicEntityTemplate<'scheduledEvent'>,
      'publicId'
    >
  ) => void;
  allowModifyTemplateNameDescription?: boolean;
};
const CreateEditScheduledEventTemplateControl: FC<Props> = ({
  scheduledEventTemplate,
  onRequestSave,
  allowModifyTemplateNameDescription = false,
}: Props): JSX.Element => {
  const fieldValidators = useMemo(() => {
    return {
      'data.duration': [
        getAllowEmptyValidator(
          getRegexMatchFieldValidator(VALID_ISO_8601_DURATION_REGEX)
        ),
      ],
    };
  }, []);
  const [showAddTemplateNameDescription, setShowAddTemplateNameDescription] =
    useState<boolean>(
      allowModifyTemplateNameDescription &&
        (!!scheduledEventTemplate?.name?.length ||
          !!scheduledEventTemplate?.description?.length)
    );

  const initialFormState = useMemo(() => {
    return {
      name: scheduledEventTemplate?.name,
      description: scheduledEventTemplate?.description,
      entityType: 'scheduledEvent',
      data: {
        duration: 'P0Y0M0DT1H0M0S',
        ...scheduledEventTemplate?.data,
      },
    };
  }, [scheduledEventTemplate]);

  const userRoles = useSelector(getUserRoles);
  const userCanDeleteAttachment = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      UploadsPermissions.DELETE_UPLOAD,
    ]);
  }, [userRoles]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <TagSelectionControl
            selectedTagIds={formState.data?.tagIds || []}
            onSelectedTagsChange={(tagIds) =>
              onFieldChange({ 'data.tagIds': tagIds })
            }
          />
          {!showAddTemplateNameDescription &&
            allowModifyTemplateNameDescription && (
              <Button
                className="add-template-name-description-button"
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={() => setShowAddTemplateNameDescription(true)}
              >
                Add Template name and description
              </Button>
            )}
          {showAddTemplateNameDescription && (
            <>
              <ValidatedFieldWrapper
                validationResult={validationState['name']}
                isTouched={touchedState['name'] || false}
              >
                <BasicField
                  name="name"
                  value={formState.name || ''}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  label="Template name"
                  styleVariant={InputStyleVariant.OUTSIZE}
                />
              </ValidatedFieldWrapper>
              <ValidatedFieldWrapper
                validationResult={validationState['description']}
                isTouched={touchedState['description'] || false}
              >
                <TextareaField
                  name="description"
                  value={formState.description || ''}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  label="Template description"
                  styleVariant={InputStyleVariant.OUTSIZE}
                />
              </ValidatedFieldWrapper>
            </>
          )}
          <ValidatedFieldWrapper
            validationResult={validationState['data.name']}
            isTouched={touchedState['data.name'] || false}
          >
            <BasicField
              name="data.name"
              value={formState.data?.name || ''}
              // isRequired={true}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              label="Event name"
              styleVariant={InputStyleVariant.OUTSIZE}
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper
            validationResult={validationState['data.description']}
            isTouched={touchedState['data.description'] || false}
          >
            <TextareaField
              name="data.description"
              value={formState.data?.description || ''}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              label="Event description"
              styleVariant={InputStyleVariant.OUTSIZE}
            />
          </ValidatedFieldWrapper>
          <ValidatedFieldWrapper
            validationResult={validationState['data.duration']}
            isTouched={touchedState['data.duration'] || false}
          >
            <DurationInputControl
              label="Duration"
              value={formState.data?.duration}
              name="data.duration"
              onChange={onFieldChange}
              onTouch={onFieldTouch}
            />
          </ValidatedFieldWrapper>
          <PermissionGuard
            renderIfHasPermissions={[UploadsPermissions.GET_UPLOADS]}
          >
            <UploadCollectionControl
              label="Attachments"
              uploadIds={formState.data?.uploadIds || []}
              onUploadCollectionChange={(uploadIds) => {
                onFieldChange({ 'data.uploadIds': uploadIds });
              }}
              allowMultipleUploads={true}
              canRemove={userCanDeleteAttachment}
              acceptFileTypes={acceptFileTypes}
              fileSizeLimitBytes={UPLOAD_MAX_FILESIZE_LIMIT}
            />
          </PermissionGuard>
          <div className="actions-container">
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              onClick={() =>
                onRequestSave?.(
                  formState as Omit<
                    PublicEntityTemplate<'scheduledEvent'>,
                    'publicId'
                  >
                )
              }
              isDisabled={!isValid}
            >
              {scheduledEventTemplate ? 'Save' : 'Create'} Event Template
            </Button>
          </div>
        </>
      );
    },
    [
      userCanDeleteAttachment,
      scheduledEventTemplate,
      onRequestSave,
      showAddTemplateNameDescription,
      allowModifyTemplateNameDescription,
    ]
  );

  return (
    <div className="create-edit-scheduled-event-template-control">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
        initialFormState={initialFormState}
      ></ValidatedForm>
    </div>
  );
};
export default CreateEditScheduledEventTemplateControl;
