import React, { FC, useEffect, useMemo, useState } from 'react';

import SwitchField from '@payaca/components/switchField/SwitchField';

import { Pipeline } from '@payaca/types/pipelineTypes';

import './DefaultAutomationCard.sass';
import {
  AutomationActionTypes,
  PublicHydratedDefaultAutomationDefinition,
} from '@payaca/types/automationTypes';
import EntityCard from '@payaca/components/entityCard/EntityCard';

type Props = {
  defaultAutomationDefinition: Pick<
    PublicHydratedDefaultAutomationDefinition<AutomationActionTypes>,
    'title' | 'automation'
  >;
  onClick?: () => void;
};

const DefaultAutomationCard: FC<Props> = ({
  defaultAutomationDefinition,
  onClick,
}) => {
  const { automation, ...automationDefinition } = defaultAutomationDefinition;
  return (
    <EntityCard className="default-automation-card" onClick={onClick}>
      <span className="title">{automationDefinition.title}</span>
      <SwitchField name="-" value={automation?.isActive} />
    </EntityCard>
  );
};

export default DefaultAutomationCard;
