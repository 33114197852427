import React, { FC } from 'react';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';

import './ListedMaterialLineItem.sass';
import ImageBlock from '@payaca/components/imageBlock/ImageBlock';

interface Props {
  lineItem: any;
  materialLineItem: any;
}

const ListedMaterialLineItem: FC<Props> = ({
  lineItem,
  materialLineItem,
}: Props): JSX.Element | null => {
  if (!materialLineItem) return null;

  return (
    <>
      <ResponsiveViewWrapper
        className="listed-material-line-item flex-container flex-center"
        downBreakpointSm={750}
      >
        <>
          <div
            className="listed-material-line-item-panel flex-grow"
            onClick={() =>
              window.open(`/items/${lineItem.id}`, '_blank', 'noreferrer')
            }
          >
            <div className="listed-material-line-item-inner">
              <div>
                <ImageBlock
                  imageSrc={lineItem?.attachments?.[0]?.attachmentUrl}
                />
              </div>
              <div className="listed-material-line-item-information-container">
                <span>{lineItem?.name}</span>
                <h4>{lineItem?.description}</h4>
              </div>
            </div>
          </div>
        </>
      </ResponsiveViewWrapper>
    </>
  );
};

export default ListedMaterialLineItem;
