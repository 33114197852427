import '../../slateEditor/types';

import React, { FC } from 'react';
import { enhanceEditor } from '../../slateEditor/enhanceEditor';
import { Transforms, CustomTypes } from 'slate';
import { ReactEditor, RenderElementProps, useSelected } from 'slate-react';

export const withVariables = enhanceEditor({
  type: 'variable',
  isInline: true,
  isVoid: true,
  isMarkableVoid: false,
});

export const insertVariable = (args: {
  editor: CustomTypes['Editor'];
  name: string;
  displayName: string;
}) => {
  const { editor, name, displayName } = args;

  const element: CustomTypes['Element'] = {
    type: 'variable',
    name,
    displayName,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, element);
  editor.move();
  ReactEditor.focus(editor);
};

const VariableElement: FC<RenderElementProps> = (props) => {
  const selected = useSelected();

  return (
    <span
      className={
        'rich-text-editor-variable' + (selected ? ' bg-opacity-30' : '')
      }
      contentEditable={false}
      {...props.attributes}
    >
      {props.element.displayName}
      {props.children}
    </span>
  );
};

export default VariableElement;
