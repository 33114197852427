// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import { GetTasksInput, PaginationInput } from '../../../gql/graphql';

const taskKeys = {
  all: ['task'] as const,
  task: (taskId: number) => [...taskKeys.all, taskId] as const,
  tasks: (input?: GetTasksInput, pagination?: PaginationInput) => {
    if (!input) {
      return [...taskKeys.all, 'tasks'] as const;
    }

    if (!pagination) {
      return [...taskKeys.all, 'tasks', input] as const;
    }

    return [...taskKeys.all, 'tasks', input, pagination] as const;
  },
};

export default taskKeys;
