import React, { useMemo, useState } from 'react';

import {
  EntityTemplate,
  PublicEntityTemplate,
  PublicHydratedEntityTemplate,
  TaskTemplate,
  TemplatableEntity,
} from '@payaca/types/entity-templates';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import CalendarIcon from '../../components/navigationSidebar/icon--cal.svg?react';
import TasksIcon from '../../components/navigationSidebar/icon--tasks.svg?react';

import './EntityTemplateCard.sass';
import EntityCard from '@payaca/components/entityCard/EntityCard';
import EntityTemplateReadDrawer from '../entityTemplateReadDrawer/EntityTemplateReadDrawer';
import { WithOptional } from '@payaca/utilities/types';
import CreateEditEntityTemplateModal from '../createEditEntityTemplateModal/CreateEditEntityTemplateModal';
import { TemplatesPermissions } from '@payaca/permissions/templates/templates.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { useSelector } from '@/api/state';
import { getUserRoles } from '@/utils/stateAccessors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileLines,
  faSquareCheck,
} from '@fortawesome/free-regular-svg-icons';
import { isNullish } from '@payaca/utilities/guards';
import { useHistory } from 'react-router';

const readableEntityTypeMap: Record<TemplatableEntity, string> = {
  task: 'Task',
  scheduledEvent: 'Event',
  proposal: 'Proposal',
};

type Props = {
  allowedQuickActions?: ('edit' | 'delete')[];
  template: WithOptional<PublicEntityTemplate<TemplatableEntity>, 'publicId'>;
  onChange: (
    template: WithOptional<PublicEntityTemplate<TemplatableEntity>, 'publicId'>
  ) => Promise<void>;
  onRemove: () => Promise<void>;
  onClick?: (showEditTemplateModal: () => void) => void;
  allowModifyTemplateNameDescription?: boolean;
  showEntityTypeIcon?: boolean;
  editInModal?: boolean;
};

const EntityTemplateCard = ({
  template,
  onChange,
  onRemove,
  onClick,
  allowedQuickActions = ['edit', 'delete'],
  showEntityTypeIcon = true,
  allowModifyTemplateNameDescription,
  editInModal = true,
}: Props) => {
  const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);
  const templateIcon =
    template.entityType === 'scheduledEvent' ? <CalendarIcon /> : <TasksIcon />;
  const [showConfirmDeletePrompt, setShowConfirmDeletePrompt] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const userRoles = useSelector(getUserRoles);
  const history = useHistory();

  const quickActions = useMemo(() => {
    const qa = [];

    if (
      allowedQuickActions.includes('edit') &&
      userHasRequiredPermission(userRoles, [
        TemplatesPermissions.PERSIST_TEMPLATE,
      ])
    ) {
      qa.push({
        actionName: 'Edit',
        actionBehaviour: () => {
          if (!editInModal && template.publicId) {
            history.push(`/templates/edit/${template.publicId}`);
          } else {
            setShowEditTemplateModal(true);
          }
        },
      });
    }

    if (
      allowedQuickActions.includes('delete') &&
      userHasRequiredPermission(userRoles, [
        TemplatesPermissions.DELETE_TEMPLATE,
      ])
    ) {
      qa.push({
        actionName: 'Delete',
        actionBehaviour: () => {
          setShowConfirmDeletePrompt(true);
        },
        isActionProcessing: isRemoving,
      });
    }

    return qa;
  }, [
    isRemoving,
    allowedQuickActions,
    userRoles,
    editInModal,
    template.publicId,
  ]);

  const name = useMemo(() => {
    if (template.name?.length) {
      return (
        <>
          <strong>{template.name}</strong>
          {template?.data &&
            'name' in template.data &&
            !!template.data.name?.length && <span>{template?.data?.name}</span>}
        </>
      );
    } else if (
      template?.data &&
      'name' in template.data &&
      template.data.name?.length
    ) {
      return <span>{template.data.name}</span>;
    } else {
      return (
        <em>{`${readableEntityTypeMap[template.entityType]} template`}</em>
      );
    }
  }, [template]);

  const taskType = useMemo(() => {
    if (template.entityType !== 'task') {
      return;
    }

    const t = template as PublicEntityTemplate<'task'>;

    if (t.data.checklistItems?.length) {
      return <FontAwesomeIcon className="task-type" icon={faSquareCheck} />;
      // return <small className="task-type">Checklist</small>;
    } else if (!isNullish(t.data.formTemplateId)) {
      return (
        <FontAwesomeIcon className="task-type" icon={faFileLines} />
        // <small className="task-type">
        //   <FontAwesomeIcon icon={faFileLines} />
        // </small>
      );
    }
  }, [template.entityType, template.data]);

  return (
    <>
      <EntityCard
        breakpointConfig={{
          downBreakpointXs: 400,
        }}
        className="entity-template-card"
        onClick={
          onClick
            ? () => onClick(() => setShowEditTemplateModal(true))
            : undefined
        }
        quickActionsConfig={{
          recordId: '',
          quickActions: quickActions,
        }}
      >
        {showEntityTypeIcon && templateIcon}
        <div className="name">{name}</div>
        {taskType}
      </EntityCard>

      <CreateEditEntityTemplateModal
        isOpen={showEditTemplateModal}
        onClose={() => setShowEditTemplateModal(false)}
        action="Edit"
        template={template}
        onRequestSave={(t) => {
          setShowEditTemplateModal(false);
          onChange(t);
        }}
        allowModifyTemplateNameDescription={allowModifyTemplateNameDescription}
      />
      <Modal
        isOpen={showConfirmDeletePrompt}
        onClose={() => setShowConfirmDeletePrompt(false)}
        title="Are you sure you want to delete this Template?"
        actions={
          <>
            <Button
              onClick={() => {
                setIsRemoving(true);
                onRemove()
                  .then(() => {
                    setIsRemoving(false);
                  })
                  .finally(() => {
                    setShowConfirmDeletePrompt(false);
                  });
              }}
              styleVariant={ButtonStyleVariant.OUTSIZE}
            >
              Delete
            </Button>
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={() => setShowConfirmDeletePrompt(false)}
            >
              Cancel
            </Button>
          </>
        }
      ></Modal>
    </>
  );
};

export default EntityTemplateCard;
