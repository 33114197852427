import { Supplier } from '@payaca/types/supplierTypes';
import { FetchedEntity } from '@payaca/types/storeTypes';

export interface State {
  isPersistingSupplier: boolean;
  suppliers: { [key: number]: FetchedEntity<Supplier> };
  isArchivingSuppliers: boolean;
  areSuppliersArchivedSuccessfully?: boolean;
}

export enum ActionType {
  REQUEST_PERSIST_SUPPLIER = 'suppliers.requestPersistSupplier',
  PERSIST_SUPPLIER_SUCCESS = 'suppliers.persistSupplierSuccess',
  PERSIST_SUPPLIER_FAILURE = 'suppliers.persistSupplierFailure',

  REQUEST_ARCHIVE_SUPPLIERS = 'suppliers.requestArchiveSuppliers',
  ARCHIVE_SUPPLIERS_SUCCESS = 'suppliers.archiveSuppliersSuccess',
  ARCHIVE_SUPPLIERS_FAILURE = 'suppliers.archiveSuppliersFailure',
  CLEAR_ARCHIVE_SUPPLIERS = 'suppliers.clearArchiveSuppliers',

  REQUEST_GET_SUPPLIERS_FOR_MATERIAL = 'suppliers.requestGetSuppliersForMaterial',
  GET_SUPPLIERS_FOR_MATERIAL_SUCCESS = 'suppliers.getSuppliersForMaterialSuccess',

  REQUEST_GET_SUPPLIER = 'suppliers.requestGetSupplier',
  GET_SUPPLIER_SUCCESS = 'suppliers.getSupplierSuccess',
  GET_SUPPLIER_FAILURE = 'suppliers.getSupplierFailure',

  CLEAR_SUPPLIERS = 'suppliers.clearSuppliers',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export interface PersistSupplierRequestData {
  id?: number;
  name?: string;
  notes?: string | null;
  emailAddress?: string | null;
  phoneNumber?: string | null;
  websiteUrl?: string | null;
  tagIds?: number[];
  addresses?: {
    id?: number;
    description?: string | null;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    postcode?: string | null;
    country?: string | null;
  }[];
}
