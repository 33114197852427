import React, { FC } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import UntitledIcon from '@payaca/untitled-icons';

import Button from '../plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '../plButton/useButtonClassName';
import Conditional from '../conditional/Conditional';
import { clstx } from '../utils';

export interface IProps {
  dropzoneOptions?: Omit<DropzoneOptions, 'onDrop' | 'maxFiles' | 'multiple'>;
  onDrop?: (file: File) => void;
  onDelete?: () => void;
  url?: string;
  label: string;
}

const FileInput: FC<IProps> = (props) => {
  const { dropzoneOptions, onDrop, url, label, onDelete } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    disabled: !!url,
    onDrop: (acceptedFiles) => {
      const acceptedFile = acceptedFiles[0];

      if (!acceptedFile) {
        return;
      }

      onDrop?.(acceptedFile);
    },
    ...dropzoneOptions,
  });

  return (
    <div className="prose flex items-center gap-3">
      <div className="flex items-center gap-3" {...getRootProps()}>
        <div
          className={clstx(
            'flex h-20 w-20 items-center justify-center overflow-hidden rounded-lg bg-white',
            !url && 'border border-dashed',
            isDragActive && 'border-blue-600 bg-blue-50'
          )}
        >
          <Conditional condition={!url}>
            <UntitledIcon
              name="image-01.3"
              className="non-scaling-stroke h-7 w-7"
            />
          </Conditional>

          {url && <img src={url} alt={label} />}
        </div>

        <input {...getInputProps()} />

        <Conditional condition={!url}>
          <Button size={EBtnSize.Small} variant={EBtnVariant.White}>
            <UntitledIcon name="upload-01.3" className="h-4 w-4" />
            Upload
          </Button>
        </Conditional>
      </div>

      <Conditional condition={!!url && !!onDelete}>
        <Button
          size={EBtnSize.Small}
          variant={EBtnVariant.Link}
          onClick={onDelete}
        >
          Delete
        </Button>
      </Conditional>
    </div>
  );
};

export default FileInput;
