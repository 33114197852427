import { useMemo } from 'react';

import { validations } from '@/helpers/formHelper';
import { AccountRegions } from '@payaca/types/accountTypes';

type FieldValidator = {
  validate: (x: any) => boolean;
  error: string;
};

export const useCompanyInfoFormValidators = (blockedEmails: Array<string>) => {
  const validators = useMemo(
    () => ({
      businessName: [validations.required],
      smsSenderHandle: [validations.required, validations.smsSenderHandle],
      email: [validations.emailCurried({ blockedEmails })],
      // contactNumber: [validations.businessContactNumber],
      // firstLineAddress: [],
      // secondLineAddress: [],
      // city: [],
      // postcode: [],
      // registeredCountryName: [],
      // companyRegistrationNumber: [],
      propositionValidForDays: [validations.dueOrValidDays],
      invoiceDueInDays: [validations.dueOrValidDays],
      // gasSafeRegNo: [],
      // hideItemPrices: [],
      // hideVatBreakdown: [],
      // isCisSubcontractor: [],
      // cisDeductionRate: [],
    }),
    [blockedEmails]
  );
  return validators as Record<string, Array<FieldValidator>>;
};

export const useGettingPaidFormValidators = (region: AccountRegions) => {
  const validators = useMemo(
    () => ({
      // accountName: [],
      sortCode: [validations.sortCodeByRegion(region)],
      accountNumber: [validations.accountNumberByRegion(region)],
      vatNumber: [validations.vatNumber],
    }),
    [region]
  );
  return validators as Record<string, Array<FieldValidator>>;
};
