import React, { FC, useMemo } from 'react';
import { ListedSupplier, SortBy } from '@payaca/types/listedSupplierTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import PaginationControl from '@payaca/components/paginationControl/PaginationControl';
import ListedSuppliersTableHeader from './ListedSuppliersTableHeader';
import EmptyState from '../emptyState/EmptyState';
import ListedSuppliersTableRow from './ListedSuppliersTableRow';

import './ListedSuppliersTable.sass';
import { useSelector } from '@/api/state';

type Props = {
  sortBy: SortBy;
  sortDirection: SortDirection;
  searchTerm?: string;
  onClickRow?: (supplierId: number) => void;
  onSelectPage: (pageNumber: number) => void;
  onSelectPageSize?: (pageSize: number) => void;
  onChangeSortOrder: (sortBy: SortBy, sortDirection: SortDirection) => void;
  quickActionDefinitions?: {
    actionName: string;
    actionBehaviour: (id: number) => void;
    isActionProcessing?: boolean;
  }[];
  selectedSupplierIds: number[];
  onSelectSuppliers?: (customerIds: number[]) => void;
  onUnselectSuppliers?: (customerIds: number[]) => void;
};

const ListedSuppliersTable: FC<Props> = ({
  sortBy,
  sortDirection,
  searchTerm,
  onClickRow,
  onSelectPage,
  onChangeSortOrder,
  onSelectPageSize,
  quickActionDefinitions,
  selectedSupplierIds,
  onSelectSuppliers,
  onUnselectSuppliers,
}: Props): JSX.Element | null => {
  const listedSuppliersPage = useSelector((state: any) => {
    return state.listedSuppliers.listedSuppliersPage;
  });
  const isGettingListedSuppliersPage = useSelector(
    (state: any) => state.deals.isGettingListedSuppliersPage
  );

  const canSelectSuppliers = useMemo(() => {
    return !!(onSelectSuppliers && onUnselectSuppliers);
  }, [onUnselectSuppliers, onSelectSuppliers]);

  const paginationControl = useMemo(() => {
    if (!listedSuppliersPage) return null;
    return (
      <PaginationControl
        pageNumber={listedSuppliersPage.pageNumber}
        pageSize={listedSuppliersPage.pageSize}
        totalItemCount={listedSuppliersPage.totalItemCount}
        onSelectPage={onSelectPage}
        allowPageSizeSelection={true}
        availablePageSizes={[5, 20, 30, 50]}
        onSelectPageSize={onSelectPageSize}
      />
    );
  }, [listedSuppliersPage, onSelectPage]);

  return (
    <div className="listed-suppliers-table">
      <div className="list-view-table-wrapper">
        <table className="list-view-table">
          <ListedSuppliersTableHeader
            sortBy={sortBy}
            sortDirection={sortDirection}
            onChangeSortOrder={onChangeSortOrder}
            quickActionDefinitions={quickActionDefinitions}
            selectedSupplierIds={selectedSupplierIds}
            onSelectSuppliers={onSelectSuppliers}
            onUnselectSuppliers={onUnselectSuppliers}
          />
          {isGettingListedSuppliersPage && (
            <tbody>
              <tr>
                <td colSpan={10} className="tr-exclude">
                  <div className="loader-container">
                    <MiniLoader />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
          {!isGettingListedSuppliersPage &&
            !listedSuppliersPage?.totalItemCount &&
            !listedSuppliersPage?.totalUnfilteredItemCount && (
              <tbody>
                <tr>
                  <td colSpan={10} className="tr-exclude">
                    <EmptyState
                      configName={'suppliers'}
                      arrowIndication={false}
                    />
                  </td>
                </tr>
              </tbody>
            )}
          {!isGettingListedSuppliersPage &&
            !listedSuppliersPage?.totalItemCount &&
            !!listedSuppliersPage?.totalUnfilteredItemCount && (
              <tbody>
                <tr>
                  <td colSpan={10} className="tr-exclude">
                    <div className="no-results-container">
                      {searchTerm ? (
                        <span>
                          There are no suppliers that match your search.
                        </span>
                      ) : (
                        <>No suppliers to show.</>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          {!isGettingListedSuppliersPage &&
            !!listedSuppliersPage?.items?.length && (
              <>
                {listedSuppliersPage.items.map(
                  (listedSupplier: ListedSupplier, index: number) => {
                    return (
                      <ListedSuppliersTableRow
                        key={`${listedSupplier.id}-${index}`}
                        listedSupplier={listedSupplier}
                        onClickRow={onClickRow}
                        quickActionDefinitions={quickActionDefinitions}
                        selectedSupplierIds={selectedSupplierIds}
                        onSelectSuppliers={onSelectSuppliers}
                        onUnselectSuppliers={onUnselectSuppliers}
                        canSelectSuppliers={canSelectSuppliers}
                      />
                    );
                  }
                )}
              </>
            )}
        </table>
      </div>
      {paginationControl}
    </div>
  );
};

export default ListedSuppliersTable;
