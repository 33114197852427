import React, { FC, useState } from 'react';

import { getUntitledIconForFileType } from '@/utils/files';
import { File as _File } from '@/gql/graphql';

import Button from '@payaca/components/plButton/Button';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import UntitledIcon from '@payaca/untitled-icons';

type File = Pick<_File, 'id'> & Partial<Omit<_File, 'id'>>;

interface IProps extends Omit<IModalProps, 'title'> {
  title?: string;
  // Is loading files
  isLoading?: boolean;
  files: File[];
  onSubmit?: (files: File[]) => void;
  // Is processing submission
  isProcessing?: boolean;
  disabledFileIds?: string[];
  multiple?: boolean;
}

const LinkFilesModal: FC<IProps> = (props) => {
  const {
    onSubmit,
    isLoading = false,
    isProcessing = false,
    files,
    disabledFileIds = [],
    multiple = true,
    // Modal props
    title = 'Link uploads',
    size = 'md',
    ...rest
  } = props;

  const [selectedUploads, setSelectedUploads] = useState<File[]>([]);

  return (
    <Modal title={title} size="md" {...rest}>
      <Modal.Body>
        <div>
          <ManageableItemsList>
            <ManageableItemsList.Table<File>
              items={files}
              uniqueKey="id"
              isLoading={isLoading}
              enableSelectAll={multiple}
              selectedItems={selectedUploads}
              setSelectedItems={(newState) => {
                if (multiple) {
                  setSelectedUploads(newState);
                } else {
                  setSelectedUploads((prev) => {
                    return newState.filter(
                      (i) => !prev.find((x) => x.id === i.id)
                    );
                  });
                }
              }}
              shouldDisableItem={(item) => {
                const disabled = !!disabledFileIds.find((x) => x === item.id);

                return {
                  disabled,
                  disabledMessage: disabled
                    ? 'This upload is already linked'
                    : undefined,
                };
              }}
            >
              <ManageableItemsList.Table.Column
                header=""
                field="thumbnailUrl"
                render={(thumbnailUrl, row) => {
                  if (thumbnailUrl) {
                    return (
                      <button className="cursor-pointer bg-transparent p-0">
                        <img
                          className="h-auto w-12"
                          src={thumbnailUrl}
                          alt="File preview"
                        />
                      </button>
                    );
                  }

                  return (
                    <UntitledIcon
                      name={getUntitledIconForFileType(row.fileType)}
                      className="h-7 w-7"
                    />
                  );
                }}
              />
              <ManageableItemsList.Table.Column
                header="Name"
                field="fileName"
                render={(fileName) => (
                  <span className="truncate">{fileName}</span>
                )}
              />
            </ManageableItemsList.Table>
          </ManageableItemsList>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            disabled={!selectedUploads.length}
            onClick={() => onSubmit?.(selectedUploads)}
            isProcessing={isProcessing}
          >
            Link{selectedUploads.length ? ` ${selectedUploads.length}` : ``}{' '}
            uploads
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default LinkFilesModal;
