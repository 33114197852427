import React, { FC, useMemo, useState } from 'react';
import moment from 'moment-timezone';

import { DealPaymentItem } from '@/ui/components/dealPaymentItem/DealPaymentItem';
import {
  default as DealPaymentSummaryV1,
  default as DealPaymentSummaryV2,
} from '@/ui/components/dealPaymentSummary/DealPaymentSummary.v1';
import ConfirmPaymentModal from '../confirmPaymentModal/ConfirmPaymentModal';
import RecordDealPaymentControl from '../recordDealPaymentControl/RecordDealPaymentControl';

import { getDeal, getJobPaymentsByDealId } from '@/utils/stateAccessors';

import { JobPayment } from '@payaca/types/jobPaymentTypes';

import './DealPayments.sass';
import { useSelector } from '@/api/state';

const getLatestDateFromJobPayment = (jobPayment: JobPayment) => {
  const array = [
    jobPayment.bacsPaymentMadeAt,
    jobPayment.stripePaymentAttemptedAt,
    jobPayment.paymentCompletedConfirmationAt,
    jobPayment.paymentFailedConfirmationAt,
  ]
    .filter((x) => !!x)
    .map((x) => moment(x));
  return moment.max(array).toDate();
};

interface Props {
  dealId: number;
  onDealUpdateSuccess: (updatedJobIds?: number[] | undefined) => void;
}

// change to deal payment
const DealPayments: FC<Props> = ({ dealId, onDealUpdateSuccess }: Props) => {
  const [confirmPaymentId, setConfirmPaymentId] = useState<number>();

  const jobPayments = useSelector((state) => {
    return getJobPaymentsByDealId(state, dealId);
  });

  const deal = useSelector((state) => {
    return getDeal(state, dealId);
  });

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const sortedJobPayments = useMemo(() => {
    if (!jobPayments) return [];

    return jobPayments.sort((p1, p2) => {
      const p1Date = getLatestDateFromJobPayment(p1);
      const p2Date = getLatestDateFromJobPayment(p2);

      return p1Date < p2Date ? 1 : -1;
    });
  }, [jobPayments]);

  const pendingJobPayments = useMemo(() => {
    return sortedJobPayments.filter(
      (x) => !x.paymentFailedConfirmationAt && !x.paymentCompletedConfirmationAt
    );
  }, [sortedJobPayments]);

  const nonPendingJobPayments = useMemo(() => {
    return sortedJobPayments.filter(
      (x) =>
        !!x.paymentFailedConfirmationAt || !!x.paymentCompletedConfirmationAt
    );
  }, [sortedJobPayments]);

  return (
    <div>
      <div className="additional-tab-header-content">
        <RecordDealPaymentControl
          dealId={+dealId}
          onJobUpdateSuccess={(jobId: number) => onDealUpdateSuccess([jobId])}
        />
      </div>
      <div className="deal-payments">
        <div className="deal-payment-summary-container">
          {(deal?.version || 0) < 2 ? (
            <DealPaymentSummaryV1 dealId={dealId} />
          ) : (
            <DealPaymentSummaryV2 dealId={dealId} />
          )}
        </div>

        {!sortedJobPayments?.length && (
          <h4 style={{ textAlign: 'center' }}>
            Looks like you haven&#39;t had any payments yet
          </h4>
        )}
        {!!sortedJobPayments?.length && (
          <div className="job-payments-container">
            {pendingJobPayments.map((jobPayment, index) => {
              return (
                <DealPaymentItem
                  key={`pending-deal-payment-item-${index}`}
                  jobPaymentId={jobPayment.id}
                  setShowRecordPaymentModal={() =>
                    setConfirmPaymentId(jobPayment.id)
                  }
                />
              );
            })}
            {nonPendingJobPayments.map((jobPayment, index) => {
              return (
                <DealPaymentItem
                  key={`deal-payment-item-${index}`}
                  jobPaymentId={jobPayment.id}
                  setShowRecordPaymentModal={() =>
                    setConfirmPaymentId(jobPayment.id)
                  }
                />
              );
            })}
          </div>
        )}

        {!!confirmPaymentId && (
          <ConfirmPaymentModal
            jobPaymentId={confirmPaymentId}
            isOpen={!!confirmPaymentId}
            onClose={() => {
              setConfirmPaymentId(undefined);
            }}
            confirmPaymentCallback={() => {
              setConfirmPaymentId(undefined);
              onDealUpdateSuccess();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DealPayments;
