import { getRegexMatchFieldValidator } from './fieldValidationHelper';

export const minimumLengthValidationMessage =
  'Password must be at least 12 characters';
export const mustContainLetterValidationMessage =
  'Password must contain 1 letter';
export const mustContainNumberValidationMessage =
  'Password must contain 1 number';

export const minimumLengthFieldValidator = getRegexMatchFieldValidator(
  /^.{12,}$/,
  {
    customErrorMessage: minimumLengthValidationMessage,
  }
);
export const mustContainLetterFieldValidator = getRegexMatchFieldValidator(
  /[a-zA-Z]/,
  {
    customErrorMessage: mustContainLetterValidationMessage,
  }
);
export const mustContainNumberFieldValidator = getRegexMatchFieldValidator(
  /\d/,
  {
    customErrorMessage: mustContainNumberValidationMessage,
  }
);
