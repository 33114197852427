import React, { FC } from 'react';
import moment from 'moment-timezone';

import CompanyLogo from '../companyLogo/CompanyLogo';
import OutlinedContentPanel, {
  ContentPanelPaddingVariant,
  ContentPanelTitleStyleVariant,
} from '../outlinedContentPanel/OutlinedContentPanel';
import LabelValuePair from '../labelValuePair/LabelValuePair';

import './FormTemplateElements.sass';
import './LandlordOilInstallationCertificate.sass';
import {
  useCertificateNumber,
  useCommonFormAccountData,
  useInspectionFormAddresses,
} from './hooks';
import { formatAnswer } from '@payaca/helpers/formHelper';

import GasSafeLogo from './gas-safe.png';

interface Props {
  data: any;
  account: any;
}

const applianceRows = [
  { label: 'Appliance Owner', key: 'appliance_owner' },
  { label: 'Appliance Make', key: 'appliance_make' },
  { label: 'Appliance Model', key: 'appliance_model' },
  { label: 'Appliance Location', key: 'appliance_location' },
  { label: 'Serial Number', key: 'serial_number' },
  { label: 'Burner Make', key: 'burner_make' },
  { label: 'Burner Model ', key: 'burner_model' },
  { label: 'Burner Type ', key: 'burner_type' },
  { label: 'Tank Type', key: 'tank_type' },
  { label: 'Flue Type', key: 'flue_type' },
  { label: 'Fuel Type', key: 'fuel_type' },
  { label: 'Service Period', key: 'service_period' },
];

const safetyChecks = [
  {
    title: 'Oil Storage',
    field: 'oil_storage',
    text: 'parts_fitted_observations',
  },
  {
    title: 'Oil Supply System',
    field: 'oil_supply_system',
    text: 'parts_fitted_observations1',
  },
  {
    title: 'Air Supply',
    field: 'air_supply',
    text: 'parts_fitted_observations2',
  },
  {
    title: 'Chimney Flue ',
    field: 'chimney_flue',
    text: 'parts_fitted_observations3',
  },
  {
    title: 'Burner',
    field: 'burner',
    text: 'parts_fitted_observations4',
  },
  {
    title: 'Safety Checks',
    field: 'safety_checks',
    text: 'parts_fitted_observations5',
  },
  {
    title: 'Comments and observations',
    field: 'comments_and_observations',
  },
];

const testResults = [
  { label: 'Smoke No.', key: 'smoke_no_' },
  { label: 'Draught', key: 'draught' },
  { label: 'Print Out Attached', key: 'print_out_attached' },
  { label: 'Efficiency Gross', key: 'efficiency_gross' },
  { label: 'CO2', key: 'co2' },
  { label: 'Flue Gase Temp.', key: 'flue_gas_temp_' },
  { label: 'CO (PPM)', key: 'co_ppm_' },
  { label: 'Efficiency Net', key: 'efficiency_net' },
];

const LandlordOilInstallationCertificate: FC<Props> = ({ data, account }) => {
  const { brandColour } = useCommonFormAccountData(account);
  const certificateNumber = useCertificateNumber(data);
  const {
    accountAddressString,
    inspectionAddressString,
    landlordAddressString,
  } = useInspectionFormAddresses(account, data);

  if (!data || !account) return <></>;

  return (
    <div
      className={'form-template-output landlord-oil-installation-certificate'}
    >
      <div className={'form-template-header'}>
        <div className={'flex-container'}>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <h3>Landlord Oil Installation Certificate</h3>
        </div>
        <div className={'flex-container flex-center'}>
          <div className="header-details-container">
            <LabelValuePair label="Cert No" value={certificateNumber} />
          </div>
          {data.gas_safe_reg_number && (
            <img
              className="gas-safe-logo"
              src={GasSafeLogo}
              alt={'Gas Safe Logo'}
            />
          )}
        </div>
      </div>
      <div className={'form-template-row no-page-break'}>
        <OutlinedContentPanel
          className="business-details"
          title="Company/Installer"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label={'Engineer'} value={data.completed_by} />
          <LabelValuePair label={'Company'} value={account?.companyName} />
          <LabelValuePair label={'Address'} value={accountAddressString} />
          <LabelValuePair label={'Contact'} value={account?.contactNumber} />
          <LabelValuePair label={'E-mail'} value={account?.email} />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          className="site-details"
          title="Inspection/Installation Address"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label={'Name'} value={data.name} />
          <LabelValuePair label={'Address'} value={inspectionAddressString} />
          <LabelValuePair label={'E-mail'} value={data.email} />
          <LabelValuePair label={'Contact'} value={data.contact} />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          className="client-details"
          title="Landlord/Agent Details"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label={'Name'} value={data.landlord_name} />
          <LabelValuePair label={'Address'} value={landlordAddressString} />
          <LabelValuePair label={'E-mail'} value={data.landlord_email} />
          <LabelValuePair label={'Contact'} value={data.landlord_contact} />
        </OutlinedContentPanel>
      </div>
      <div className={'form-template-row no-page-break'}>
        <OutlinedContentPanel
          className="appliance"
          title="Appliance Details"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <>
            {applianceRows.map((applianceRow, index) => {
              return (
                formatAnswer(
                  data,
                  applianceRow.key
                  // applianceRow.historicKey
                ) && (
                  <LabelValuePair
                    key={`appliance-row-${index}`}
                    label={applianceRow.label}
                    value={formatAnswer(
                      data,
                      applianceRow.key
                      // applianceRow.historicKey
                    )}
                  />
                )
              );
            })}
          </>
        </OutlinedContentPanel>
      </div>
      <div className={'form-template-row no-page-break'}>
        <OutlinedContentPanel
          className="safety-checks"
          title="Safety Checks"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="flex-container">
            <table className="inspections-table">
              <thead style={{ borderColor: brandColour }}>
                <tr>
                  <td>Item</td>
                  <td>Pass/Fail</td>
                  <td>Parts Fitted/ Observations</td>
                </tr>
              </thead>
              <tbody>
                {safetyChecks.map(
                  (row: any, i: number) =>
                    data[row.field] && (
                      <tr key={i}>
                        <td>{row.title}</td>
                        <td>{formatAnswer(data, row.field)}</td>
                        <td>{data[row.text]}</td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </OutlinedContentPanel>
        <OutlinedContentPanel
          className="test-results"
          title="Test Results"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <>
            {testResults.map((item, index) => {
              return (
                formatAnswer(
                  data,
                  item.key
                  // testResults.historicKey
                ) && (
                  <LabelValuePair
                    key={`testResults-row-${index}`}
                    label={item.label}
                    value={formatAnswer(
                      data,
                      item.key
                      // testResults.historicKey
                    )}
                  />
                )
              );
            })}
          </>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row no-page-break">
        <OutlinedContentPanel
          className="summary-checks"
          colourOverride={brandColour}
          title={'Summary Checks'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="A warning label has been affixed to the equipment"
            value={formatAnswer(data, 'a_warning_label_has')}
          />
          <LabelValuePair
            label="It is strongly recommended these faults are remedied urgently"
            value={formatAnswer(data, 'it_is_strongly_recommended')}
          />
          <LabelValuePair
            label="The equipment should not be used until the faults are rectified"
            value={formatAnswer(data, 'the_equipment_should_not')}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Completed by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.completed_by} />
          <LabelValuePair
            label="Signed"
            value={
              <div className={'form-template-signature'}>
                {data.engineer_signature && (
                  <img src={data.engineer_signature} />
                )}
              </div>
            }
          />
          <LabelValuePair
            label="Inspection conducted"
            value={moment(data.issue_date).format('DD/MM/YYYY')}
            noBreak={true}
          />
          <LabelValuePair
            label="Next Inspection Date"
            value={moment(data.next_inspection_due_date).format('DD/MM/YYYY')}
            noBreak={true}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Received by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
          className="customer-signature"
        >
          <div>
            <LabelValuePair label="Name" value={data.name} />
            <LabelValuePair
              label="Signed"
              value={
                <div className={'form-template-signature'}>
                  {data.customer_signature && (
                    <img src={data.customer_signature} />
                  )}
                </div>
              }
            />
          </div>
          {data.customer_not_present && <p>Customer not present</p>}
        </OutlinedContentPanel>
      </div>
    </div>
  );
};

export default LandlordOilInstallationCertificate;
