import React, { FC, useMemo } from 'react';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import Tooltip from '@payaca/components/tooltip/Tooltip';
import { ListedDeal } from '@payaca/types/listedDealTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertTooltip } from '@payaca/components/iconTooltip/IconTooltip';

import './ListedDealInfoIcons.sass';

type Props = {
  listedDeal: ListedDeal;
};
const ListedDealInfoIcons: FC<Props> = ({ listedDeal }: Props) => {
  const issue = useMemo(() => {
    if (listedDeal.isAnyBounced) {
      return 'E-mail has bounced';
    } else if (listedDeal.isAnyOverdue) {
      return 'Invoice payment is overdue';
    }
  }, [listedDeal]);
  return (
    <div className="listed-deal-info-icons">
      {listedDeal.isAllViewed && (
        <Tooltip text={'Viewed by customer'}>
          <FontAwesomeIcon icon={faEye} />
        </Tooltip>
      )}

      {issue && (
        <div className={'card-issue'}>
          <AlertTooltip tooltipText={issue} />
        </div>
      )}
    </div>
  );
};

export default ListedDealInfoIcons;
