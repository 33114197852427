import React from 'react';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import './404.sass';
import BasicPageWrapper from '../pageWrappers/basicPageWrapper/BasicPageWrapper';
import { useHistory } from 'react-router';

import SadFaceIcon from './sad-face.svg';
import useLogout from '@/hooks/auth/useLogout';

const FourOhFourPage = (): JSX.Element => {
  const history = useHistory();
  const logout = useLogout();

  return (
    <BasicPageWrapper className="four-oh-four-page">
      <div className="information-wrapper">
        <div className="sad-face">
          <img src={SadFaceIcon} />
        </div>
        <div className="information-container">
          <h1>404</h1>
          <h2>Page not found</h2>
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={() => history.push('/')}
          >
            Return to dashboard
          </Button>
          <Button styleVariant={ButtonStyleVariant.ANCHOR} onClick={logout}>
            Log out
          </Button>
        </div>
      </div>
    </BasicPageWrapper>
  );
};

export default FourOhFourPage;
