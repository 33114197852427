import Button from '@payaca/components/plButton/Button';
import Modal from '@payaca/components/plModal/Modal';
import { PartialAddress } from '@payaca/types/locationTypes';
import { ComponentProps, FC, useState } from 'react';
import { AddressLookupOrInput } from '../addressLookupOrInput/AddressLookupOrInput';

export const AddressLookupOrInputModal: FC<
  Omit<ComponentProps<typeof Modal>, 'children'> & {
    onSubmit: (value: PartialAddress) => void;
  }
> = ({ onSubmit, ...modalProps }) => {
  const [address, setAddress] = useState<PartialAddress>({});

  return (
    <Modal {...modalProps}>
      <Modal.Body>
        <AddressLookupOrInput
          address={address}
          onChange={(value) => {
            setAddress((a) => ({ ...a, ...value }));
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            onClick={() => {
              onSubmit(address);
            }}
          >
            Continue
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};
