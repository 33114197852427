import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  faEnvelope,
  faPhone,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons';

import './CreateEditSupplierControl.sass';
import BasicField from '@payaca/components/basicField/BasicField';
import TextareaField from '@payaca/components/textareaField/TextareaField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import {
  FieldValidationResult,
  FieldValidator,
} from '@payaca/types/fieldValidationTypes';
import {
  getAllowEmptyValidator,
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
  getRegexMatchFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { Supplier } from '@payaca/types/supplierTypes';
import { VALID_EMAIL_REGEX, VALID_PHONE_NUMBER_REGEX } from '@payaca/constants';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import LoqateAdvisoryPhoneInputField from '../loqateAdvisoryPhoneInputField/LoqateAdvisoryPhoneInputField';
import SupplierAddressesControl from './SupplierAddressesControl';
import { requestPersistSupplier } from '@payaca/store/suppliers/suppliersActions';
import { PersistSupplierRequestData } from '@payaca/store/suppliers/suppliersTypes';
import TagSelectionControl from '../tagSelectionControl/TagSelectionControl';
import { useSelector } from '@/api/state';

const isRequiredFieldValidator = getIsRequiredFieldValidator();
const supplierNameLengthFieldValidator = getLengthFieldValidator({ max: 250 });
const supplierEmailAddressFieldValidator = getAllowEmptyValidator(
  getRegexMatchFieldValidator(VALID_EMAIL_REGEX)
);
const supplierTelephoneNumberLengthFieldValidator = getAllowEmptyValidator(
  getRegexMatchFieldValidator(VALID_PHONE_NUMBER_REGEX)
);
const supplierWebsiteUrlAddressLengthFieldValidator = getLengthFieldValidator({
  max: 500,
});

type Props = {
  supplier?: Supplier;
  onPersistSupplierSuccess?: (taskId: number) => void;
};
const CreateEditSupplierControl: FunctionComponent<Props> = ({
  supplier,
  onPersistSupplierSuccess,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const initialFormState = useMemo(() => {
    return {
      id: supplier?.id,
      name: supplier?.name || null,
      notes: supplier?.notes || null,
      emailAddress: supplier?.emailAddress || null,
      phoneNumber: supplier?.phoneNumber || null,
      websiteUrl: supplier?.websiteUrl || null,
      tagIds: supplier?.id ? undefined : [],
      addresses: supplier?.addresses
        ? supplier?.addresses.map((x) => {
            return {
              id: x.id,
              description: x.description || null,
              line1: x.address?.line1 || null,
              line2: x.address?.line2 || null,
              city: x.address?.city || null,
              postcode: x.address?.postcode || null,
              country: x.address?.country || null,
            };
          })
        : [],
    };
  }, [supplier]);

  const fieldValidators = useMemo(() => {
    const fv: { [fieldName: string]: FieldValidator[] } = {
      name: [isRequiredFieldValidator, supplierNameLengthFieldValidator],
      emailAddress: [supplierEmailAddressFieldValidator],
      telephoneNumber: [supplierTelephoneNumberLengthFieldValidator],
      websiteUrl: [supplierWebsiteUrlAddressLengthFieldValidator],
    };

    return fv;
  }, []);

  const isPersistingSupplier = useSelector((state) => {
    return state.suppliers.isPersistingSupplier;
  });

  const persistSupplier = useCallback(
    (persistSupplierData: PersistSupplierRequestData) => {
      dispatch(
        requestPersistSupplier(persistSupplierData, onPersistSupplierSuccess)
      );
    },
    [dispatch, onPersistSupplierSuccess]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <React.Fragment>
          <div className="form-body">
            {!supplier?.id && (
              <TagSelectionControl
                selectedTagIds={formState.tagIds || []}
                onSelectedTagsChange={(tagIds) =>
                  onFieldChange({ tagIds: tagIds })
                }
              />
            )}
            <ValidatedFieldWrapper
              validationResult={validationState['name']}
              isTouched={touchedState['name'] || false}
            >
              <BasicField
                isRequired={true}
                name="name"
                label="Supplier name"
                value={formState.name}
                onTouch={onFieldTouch}
                onChange={onFieldChange}
                styleVariant={InputStyleVariant.OUTSIZE}
              />
            </ValidatedFieldWrapper>

            <ValidatedFieldWrapper
              validationResult={validationState['emailAddress']}
              isTouched={touchedState['emailAddress'] || false}
            >
              <LoqateAdvisoryEmailInputField
                iconBefore={faEnvelope}
                name="emailAddress"
                label="Supplier email"
                value={formState.emailAddress}
                onTouch={onFieldTouch}
                onChange={onFieldChange}
                styleVariant={InputStyleVariant.OUTSIZE}
              />
            </ValidatedFieldWrapper>
            <ValidatedFieldWrapper
              validationResult={validationState['phoneNumber']}
              isTouched={touchedState['phoneNumber'] || false}
            >
              <LoqateAdvisoryPhoneInputField
                iconBefore={faPhone}
                name="phoneNumber"
                label="Phone number"
                value={formState.phoneNumber}
                onTouch={onFieldTouch}
                onChange={onFieldChange}
                styleVariant={InputStyleVariant.OUTSIZE}
                inputType={'basic'}
              />
            </ValidatedFieldWrapper>
            <ValidatedFieldWrapper
              validationResult={validationState['websiteUrl']}
              isTouched={touchedState['websiteUrl'] || false}
            >
              <BasicField
                iconBefore={faGlobe}
                name="websiteUrl"
                label="Website url"
                value={formState.websiteUrl}
                onTouch={onFieldTouch}
                onChange={onFieldChange}
                styleVariant={InputStyleVariant.OUTSIZE}
              />
            </ValidatedFieldWrapper>
            <ValidatedFieldWrapper
              validationResult={validationState['notes']}
              isTouched={touchedState['notes'] || false}
            >
              <TextareaField
                name="notes"
                label="Notes"
                value={formState.notes}
                onTouch={onFieldTouch}
                onChange={onFieldChange}
                styleVariant={InputStyleVariant.OUTSIZE}
              />
            </ValidatedFieldWrapper>
            <div className="addresses-section">
              <h2>Supplier addresses</h2>
              <SupplierAddressesControl
                inputStyleVariant={InputStyleVariant.OUTSIZE}
                supplierAddresses={formState.addresses}
                onChange={onFieldChange}
                onTouch={onFieldTouch}
                validationState={validationState}
                touchedState={touchedState}
                fieldNamePrefix="addresses"
              />
            </div>
          </div>
          <div className="actions-container">
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isDisabled={!isValid}
              isProcessing={isPersistingSupplier}
              onClick={() =>
                !isPersistingSupplier &&
                persistSupplier(formState as PersistSupplierRequestData)
              }
            >
              Save
            </Button>
          </div>
        </React.Fragment>
      );
    },
    [isPersistingSupplier, supplier]
  );

  return (
    <div className="create-edit-supplier-control">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
      />
    </div>
  );
};

export default CreateEditSupplierControl;
