import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getMaterial } from '@/utils/stateAccessors';

import * as listedMaterialLineItemsActions from '@payaca/store/listedMaterialLineItems/listedMaterialILinetemsActions';
import { GetListedMaterialLineItemsRequestData } from '@payaca/store/listedMaterialLineItems/listedMaterialLineItemsTypes';

import ListedMaterialLineItems from '../listedMaterialLineItems/ListedMaterialLineItems';

import { SortDirection } from '@payaca/types/listViewTypes';
import { SortBy } from '@payaca/types/listedMaterialLineItemTypes';

import './MaterialLineItems.sass';
import { useSelector } from '@/api/state';

type Props = {
  materialId: number;
};
const MaterialLineItems: FC<Props> = ({
  materialId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const [
    getListedMaterialLineItemsRequestData,
    setGetListedMaterialLineItemsRequestData,
  ] = useState<GetListedMaterialLineItemsRequestData>({
    pageSize: 20,
    pageNumber: 1,
    materialId: materialId,
    sortDirection: SortDirection.ASCENDING,
    sortBy: SortBy.ITEM_NAME,
  });

  useEffect(() => {
    return () => {
      dispatch(
        listedMaterialLineItemsActions.clearListedMaterialLineItemsPage()
      );
    };
  }, []);

  useEffect(() => {
    requestGetListedMaterialLineItemsPage();
  }, [getListedMaterialLineItemsRequestData]);

  const requestGetListedMaterialLineItemsPage = useCallback(() => {
    dispatch(
      listedMaterialLineItemsActions.requestGetListedMaterialLineItemsPage(
        getListedMaterialLineItemsRequestData
      )
    );
  }, [getListedMaterialLineItemsRequestData, dispatch]);

  const material = useSelector((state) => getMaterial(state, materialId));

  if (!material) return null;

  return (
    <>
      <div className="material-line-items">
        <ListedMaterialLineItems
          onSelectPage={(pageNumber: number) => {
            setGetListedMaterialLineItemsRequestData(
              (getListedMaterialLineItemsRequestData) => ({
                ...getListedMaterialLineItemsRequestData,
                pageNumber: pageNumber,
              })
            );
          }}
        />
      </div>
    </>
  );
};

export default MaterialLineItems;
