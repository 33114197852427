import { action } from 'typesafe-actions';
import { createAsyncAction } from '../utils';

import {
  ActionType,
  SetCostPerHour,
  UpdateProfileRequestData,
} from './userTypes';

export const requestMakePrimaryUser = (userId: number) => {
  return action(ActionType.REQUEST_MAKE_PRIMARY_USER, {
    userId,
  });
};
export const makePrimaryUserSuccess = () => {
  return action(ActionType.MAKE_PRIMARY_USER_SUCCESS);
};
export const makePrimaryUserFailure = (error: Error) => {
  return action(ActionType.MAKE_PRIMARY_USER_FAILURE, null, null, error);
};

export const requestGetTermsAndPrivacyUpdates = () => {
  return action(ActionType.REQUEST_GET_TERMS_AND_PRIVACY_UPDATES);
};
export const getTermsAndPrivacyUpdatesSuccess = (
  updatedTermsAndPrivacy: {
    termsMarkdown?: string;
    privacyMarkdown?: string;
    version: number;
  } | null
) => {
  return action(ActionType.GET_TERMS_AND_PRIVACY_UPDATES_SUCCESS, {
    updatedTermsAndPrivacy,
  });
};
export const getTermsAndPrivacyUpdatesFailure = (error: Error) => {
  return action(
    ActionType.GET_TERMS_AND_PRIVACY_UPDATES_FAILURE,
    null,
    null,
    error
  );
};

export const requestAgreeToTermsAndPrivacyVersion = (
  termsAndPrivacyVersion: number
) => {
  return action(ActionType.REQUEST_AGREE_TO_TERMS_AND_PRIVACY_VERSION, {
    termsAndPrivacyVersion,
  });
};
export const agreeToTermsAndPrivacyVersionSuccess = () => {
  return action(ActionType.AGREE_TO_TERMS_AND_PRIVACY_VERSION_SUCCESS);
};
export const agreeToTermsAndPrivacyVersionFailure = (error: Error) => {
  return action(
    ActionType.AGREE_TO_TERMS_AND_PRIVACY_VERSION_FAILURE,
    null,
    null,
    error
  );
};
export const clearTermsAndPrivacyUpdates = () => {
  return action(ActionType.CLEAR_TERMS_AND_PRIVACY_UPDATES);
};

export const requestUpdatePassword = (payload: {
  email: string;
  currentPassword: string;
  newPassword: string;
}) => {
  return action(ActionType.REQUEST_UPDATE_PASSWORD, {
    payload,
  });
};
export const updatePasswordSuccess = () => {
  return action(ActionType.UPDATE_PASSWORD_SUCCESS);
};
export const updatePasswordFailure = (error: Error, errorMessage: string) => {
  return action(
    ActionType.UPDATE_PASSWORD_FAILURE,
    { errorMessage },
    null,
    error
  );
};
export const clearUpdatePassword = () => {
  return action(ActionType.CLEAR_UPDATE_PASSWORD);
};

export const requestGetAccountTerms = (
  accountId: number,
  callback?: () => void
) => {
  return action(ActionType.REQUEST_GET_ACCOUNT_TERMS, {
    accountId,
    callback,
  });
};
export const getAccountTermsSuccess = (terms: any) => {
  return action(ActionType.GET_ACCOUNT_TERMS_SUCCESS, { terms });
};
export const getAccountTermsFailure = (error: Error, errorMessage: string) => {
  return action(
    ActionType.GET_ACCOUNT_TERMS_FAILURE,
    { errorMessage },
    null,
    error
  );
};

export const requestUpdateProfile = (
  profileUpdates: UpdateProfileRequestData,
  callback: (err: any, response?: any) => void
) => {
  return action(ActionType.REQUEST_UPDATE_PROFILE, {
    profileUpdates,
    callback,
  });
};
export const updateProfileFailure = (error: Error, errorMessage: string) => {
  return action(
    ActionType.UPDATE_PROFILE_FAILURE,
    { errorMessage },
    null,
    error
  );
};
export const updateProfileSuccess = (profile: any) => {
  return action(ActionType.UPDATE_PROFILE_SUCCESS, {
    profile,
  });
};

export const setCostPerHour = createAsyncAction<SetCostPerHour>({
  request: ActionType.SET_COST_PER_HOUR_REQUEST,
})((payload: SetCostPerHour['request']['payload']) => ({
  payload,
}));
