import { ComponentPropsWithoutRef, FC } from 'react';
import { twMerge } from 'tailwind-merge';
import Card, { CardSizeVariant } from '../plCard/Card';

type Props = ComponentPropsWithoutRef<'div'>;

const SkeletonLoader: FC<Props> = (props) => {
  const { className, ...rest } = props;

  return (
    <div
      className={twMerge('animate-pulse rounded-full bg-gray-200', className)}
      {...rest}
    />
  );
};

const SkeletonLoaderCircle: FC<Props> = (props) => {
  const { className, ...rest } = props;

  return <SkeletonLoader className={twMerge('h-8 w-8', className)} {...rest} />;
};
const SkeletonLoaderSquare: FC<Props> = (props) => {
  const { className, ...rest } = props;

  return (
    <SkeletonLoader
      className={twMerge('b h-8 w-8 rounded-none	', className)}
      {...rest}
    />
  );
};

const SkeletonLoaderTitle: FC<Props> = (props) => {
  const { className, ...rest } = props;

  return (
    <SkeletonLoader className={twMerge('h-5 w-full', className)} {...rest} />
  );
};

const SkeletonLoaderText: FC<Props> = (props) => {
  const { className, ...rest } = props;

  return (
    <SkeletonLoader className={twMerge('h-3 w-full', className)} {...rest} />
  );
};

const SkeletonLoaderInput: FC = () => {
  return (
    <div className="space-y-2">
      <SkeletonLoaderTitle className="w-1/4" />
      <SkeletonLoader className="h-10 w-full rounded-lg" />
    </div>
  );
};

const SkeletonLoaderTextarea: FC = () => {
  return (
    <div className="space-y-2">
      <SkeletonLoaderTitle className="w-1/4" />
      <SkeletonLoader className="h-32 w-full rounded-lg" />
    </div>
  );
};

const SkeletonLoaderProjectActivityTimeline: FC = () => {
  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body className="flex items-center gap-4">
        <SkeletonLoaderCircle className="flex-shrink-0" />

        <SkeletonLoaderText />
      </Card.Body>
    </Card>
  );
};
const SkeletonLoaderNotification: FC = () => {
  return (
    <div className="flex flex-row gap-5 p-5">
      <SkeletonLoaderSquare />
      <div className="flex flex-col gap-2">
        <SkeletonLoaderText className="w-10" />
        <SkeletonLoaderText className="w-20" />
      </div>
    </div>
  );
};

const SkeletonLoaderMaterialCard: FC = () => {
  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <div className="flex gap-3.5">
          <SkeletonLoaderSquare className="h-14 w-14 rounded" />

          <div className="flex flex-auto flex-col justify-between gap-3.5">
            <div className="flex flex-wrap justify-between">
              <SkeletonLoaderTitle className="w-1/2" />

              <SkeletonLoaderText className="w-1/4" />
            </div>

            <div className="flex flex-wrap items-center justify-between">
              <SkeletonLoaderText className="w-1/4" />

              <SkeletonLoaderText className="w-1/4" />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Object.assign(SkeletonLoader, {
  Square: SkeletonLoaderSquare,
  Circle: SkeletonLoaderCircle,
  Title: SkeletonLoaderTitle,
  Text: SkeletonLoaderText,
  Input: SkeletonLoaderInput,
  Textarea: SkeletonLoaderTextarea,
  ProjectActivityTimeline: SkeletonLoaderProjectActivityTimeline,
  Notification: SkeletonLoaderNotification,
  MaterialCard: SkeletonLoaderMaterialCard,
});
