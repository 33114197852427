import {
  takeEvery,
  call,
  put,
  race,
  delay,
  takeLatest,
} from 'redux-saga/effects';

import { Action, PayloadAction } from 'typesafe-actions';

import {
  SagaConfig,
  ActionType,
  CreateAutomationPayload,
} from './automationTypes';
import {
  Automation,
  AutomationActionTypes,
  GetListedAutomationsRequestData,
  ListedAutomationsListViewPage,
  PublicHydratedDefaultAutomationDefinition,
} from '@payaca/types/automationTypes';

import {
  archiveAutomationsFailure,
  archiveAutomationsSuccess,
  clearAutomations,
  createAutomationFailure,
  createAutomationSuccess,
  getAutomationFailure,
  getAutomationSuccess,
  getAutomationTemplatesFailure,
  getAutomationTemplatesSuccess,
  getDefaultAutomationDefinition,
  getDefaultAutomationDefinitions,
  getListedAutomationsPageFailure,
  getListedAutomationsPageSuccess,
  updateAutomationFailure,
  updateAutomationSuccess,
  updateDefaultAutomation,
} from './automationActions';

import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { handleAsyncAction } from '../utils';

const automationSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleGetAutomation(
    action: PayloadAction<
      ActionType.REQUEST_GET_AUTOMATION,
      {
        automationId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getAutomation, action.payload.automationId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get automation timed out.';
        yield put(
          getAutomationFailure(
            action.payload.automationId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(getAutomationSuccess(action.payload.automationId, response));
      }
    } catch (error: any) {
      yield put(getAutomationFailure(action.payload.automationId, error));
    }
  }

  function* handleUpdateAutomation(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_AUTOMATION,
      {
        automationId: number;
        automationUpdates: Partial<Automation<AutomationActionTypes>>;
        callback?: (automation: Automation<AutomationActionTypes>) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          updateAutomation,
          action.payload.automationId,
          action.payload.automationUpdates
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Update automation timed out.';
        yield put(
          updateAutomationFailure(
            action.payload.automationId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(
          updateAutomationSuccess(action.payload.automationId, response)
        );
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(updateAutomationFailure(action.payload.automationId, error));
    }
  }

  function* handleCreateAutomation(
    action: PayloadAction<
      ActionType.REQUEST_CREATE_AUTOMATION,
      {
        createAutomationPayload: CreateAutomationPayload;
        callback: (automationId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          createAutomation,
          action.payload.createAutomationPayload
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Create automation timed out.';
        yield put(createAutomationFailure(new Error(errorMessage)));
      } else {
        yield put(createAutomationSuccess(response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(createAutomationFailure(error));
    }
  }

  const getAutomation = async (automationId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/automations/${automationId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getAutomation failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const createAutomation = async (
    createAutomationPayload: CreateAutomationPayload
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/automations`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
      body: JSON.stringify(createAutomationPayload),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `createAutomation failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const updateAutomation = async (
    automationId: number,
    automationUpdates: Partial<Automation<AutomationActionTypes>>
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/automations/${automationId}`, {
      method: 'PUT',
      body: JSON.stringify(automationUpdates),
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `updateAutomation failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetListedAutomationsPage(
    action: PayloadAction<
      ActionType.REQUEST_GET_LISTED_AUTOMATIONS_PAGE,
      {
        getListedAutomationsRequestData: GetListedAutomationsRequestData;
        callback?: (
          listedAutomationsListViewPage: ListedAutomationsListViewPage
        ) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(
          getListedAutomationsPage,
          action.payload.getListedAutomationsRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getListedAutomationsPageFailure(
            new Error('Get listed automations request timed out.')
          )
        );
      } else {
        yield put(getListedAutomationsPageSuccess(response));
        action.payload.callback?.(response);
      }
    } catch (error: any) {
      yield put(getListedAutomationsPageFailure(error));
    }
  }

  const getListedAutomationsPage = async (
    getListedAutomationsRequestData: GetListedAutomationsRequestData
  ) => {
    return fetch(
      `${apiBaseurl}/api/automations?pageNumber=${
        getListedAutomationsRequestData.pageNumber
      }&pageSize=${getListedAutomationsRequestData.pageSize}&searchTerm=${
        getListedAutomationsRequestData.searchTerm || ''
      }&sortBy=${getListedAutomationsRequestData.sortBy || ''}&sortDirection=${
        getListedAutomationsRequestData.sortDirection || ''
      }&entityTypes=${
        getListedAutomationsRequestData.entityTypes
          ? getListedAutomationsRequestData.entityTypes.join(',')
          : ''
      }&triggerEvents=${
        getListedAutomationsRequestData.triggerEvents
          ? getListedAutomationsRequestData.triggerEvents.join(',')
          : ''
      }&actionTypes=${
        getListedAutomationsRequestData.actionTypes
          ? getListedAutomationsRequestData.actionTypes.join(',')
          : ''
      }`,
      {
        method: 'GET',
        headers: {
          Authorization: await getAuthHeader(),
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GetListedAutomationsPage failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleArchiveAutomations(
    action: PayloadAction<
      ActionType.REQUEST_ARCHIVE_AUTOMATIONS,
      {
        automationIds: number[];
        onArchiveSuccess?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(archiveAutomations, action.payload.automationIds),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          archiveAutomationsFailure(new Error('Archive automations timed out.'))
        );
      } else {
        action.payload.onArchiveSuccess && action.payload.onArchiveSuccess();
        yield put(archiveAutomationsSuccess());
      }
    } catch (error) {
      archiveAutomationsFailure(new Error('Archive automations timed out.'));
    }
  }

  const archiveAutomations = async (automationIds: number[]) => {
    const response = await req.put('/automations/bulk_archive', {
      automationIds,
    });
    return response;
  };

  function* handleGetAutomationTemplates(
    action: Action<ActionType.REQUEST_GET_AUTOMATION_TEMPLATES>
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(getAutomationTemplates),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getAutomationTemplatesFailure(
            new Error('Get automation templates timed out.')
          )
        );
      } else {
        yield put(getAutomationTemplatesSuccess(response));
      }
    } catch (error) {
      getAutomationTemplatesFailure(
        new Error('Get automation templates timed out.')
      );
    }
  }

  const getAutomationTemplates = async () => {
    const response = await req.get('/automations/templates');
    return response.json();
  };

  const handleGetDefaultAutomationDefinitionRequest = handleAsyncAction(
    getDefaultAutomationDefinition,
    async (args) => {
      const response = await req.get(
        `/automations/default/${args.defaultAutomationDefinitionPublicId}`
      );
      return response.json();
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as PublicHydratedDefaultAutomationDefinition<AutomationActionTypes>
      );
    }
  );

  const handleGetDefaultAutomationDefinitionsRequest = handleAsyncAction(
    getDefaultAutomationDefinitions,
    async (args) => {
      const response = await req.get(`/automations/default`);
      return response.json();
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as PublicHydratedDefaultAutomationDefinition<AutomationActionTypes>[]
      );
    }
  );

  const handleUpdateDefaultAutomationRequest = handleAsyncAction(
    updateDefaultAutomation,
    async (args) => {
      const { definitionPublicId, ...automation } = args.defaultAutomation;

      const response = await req.put(
        `/automations/default/${definitionPublicId}`,
        automation
      );
      return response.json();
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as Automation<AutomationActionTypes>
      );
    },
    (_err, requestData) => {
      requestData.payload.onErrorCallback?.(_err);
    }
  );

  function* handleAppLogout() {
    yield put(clearAutomations());
  }

  return function* () {
    yield takeLatest(ActionType.REQUEST_GET_AUTOMATION, handleGetAutomation);
    yield takeLatest(
      ActionType.REQUEST_UPDATE_AUTOMATION,
      handleUpdateAutomation
    );
    yield takeLatest(
      ActionType.REQUEST_CREATE_AUTOMATION,
      handleCreateAutomation
    );
    yield takeLatest(
      ActionType.REQUEST_GET_LISTED_AUTOMATIONS_PAGE,
      handleGetListedAutomationsPage
    );
    yield takeLatest(
      ActionType.REQUEST_ARCHIVE_AUTOMATIONS,
      handleArchiveAutomations
    );
    yield takeLatest(
      ActionType.REQUEST_GET_AUTOMATION_TEMPLATES,
      handleGetAutomationTemplates
    );
    yield takeEvery(
      ActionType.GET_DEFAULT_AUTOMATION_DEFINITION_REQUEST,
      handleGetDefaultAutomationDefinitionRequest
    );
    yield takeEvery(
      ActionType.GET_DEFAULT_AUTOMATION_DEFINITIONS_REQUEST,
      handleGetDefaultAutomationDefinitionsRequest
    );

    yield takeEvery(
      ActionType.UPDATE_DEFAULT_AUTOMATION_REQUEST,
      handleUpdateDefaultAutomationRequest
    );
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default automationSagaCreator;
