import { Reducer } from 'redux';

import {
  DealActivityActionTypes,
  DealActivityState,
} from './dealActivityTypes';

export const initialState: DealActivityState = {
  dealActivityEvents: null,
  isGettingDealActivityEvents: false,
};

const dealActivityReducer: Reducer<DealActivityState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DealActivityActionTypes.REQUEST_GET_DEAL_ACTIVITY_EVENTS:
      return { ...state, isGettingDealActivityEvents: true };
    case DealActivityActionTypes.GET_DEAL_ACTIVITY_EVENTS_FAILURE:
      return { ...state, isGettingDealActivityEvents: false };
    case DealActivityActionTypes.CLEAR_DEAL_ACTIVITY_EVENTS:
      return {
        ...state,
        dealActivityEvents: null,
        isGettingDealActivityEvents: false,
      };
    case DealActivityActionTypes.GET_DEAL_ACTIVITY_EVENTS_SUCCESS:
      return {
        ...state,
        dealActivityEvents: action.payload.dealActivityEvents,
        isGettingDealActivityEvents: false,
      };
    default:
      return state;
  }
};

export default dealActivityReducer;
