import React, { FC } from 'react';
import moment from 'moment-timezone';

import CompanyLogo from '../../companyLogo/CompanyLogo';

import '../FormTemplateElements.sass';

interface Props {
  data: any;
  account: any;
}

const radioAnswerMap: any = {
  yes: 'Yes',
  no: 'No',
};

const formatAnswer = (answer: string) => {
  return radioAnswerMap[answer] || answer;
};

const formatAddress = (
  building: string,
  street: string,
  city: string,
  region: string
) => {
  const addressElements = [];
  addressElements.push(building);
  addressElements.push(street);
  addressElements.push(city);
  addressElements.push(region);
  return addressElements.filter((x) => !!x).join(', ');
};

const JobSheetWintecFormTemplate: FC<Props> = ({ data, account }) => {
  if (!data || !account) return <></>;
  return (
    <div className={'form-template-output'}>
      <div className={'form-template-header'}>
        <div>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <h3>Job Sheet</h3>
        </div>
        <div>
          <span>Doc No</span>: {data.certificate_number}
        </div>
      </div>
      <div className={'form-template-row no-page-break'}>
        <div className={'form-template-bubble'}>
          <ul>
            <li>
              <span>Reference</span>: {data.reference}
            </li>
            <li>
              <span>Name</span>: {data.name}
            </li>
            <li>
              <span>Job type</span>: {data.job_type}
            </li>
            <li>
              <span>Scheduled</span>: {formatAnswer(data.scheduled)}
            </li>
          </ul>
        </div>
        <div className={'form-template-bubble'}>
          <ul>
            <li>
              <span>Staff</span>: {(data.staff || []).join(', ')}
            </li>
            <li>
              <span>Entered by</span>: {data.entered_by}
            </li>
            <li>
              <span>Entered on</span>:{' '}
              {moment(data.entered_on).format('DD/MM/YYYY')}
            </li>
          </ul>
        </div>
      </div>
      <div className={'form-template-row no-page-break'}>
        <div className={'form-template-bubble'}>
          <h4>Contact details</h4>
          <ul>
            <li>
              <span>Name</span>: {data.contact_name}
            </li>
            <li>
              <span>Address</span>:{' '}
              {formatAddress(
                data.building,
                data.street,
                data.city,
                data.region
              )}
            </li>
            <li>
              <span>Postcode</span>: {data.postcode}
            </li>
            <li>
              <span>Phone</span>: {data.contact_phone}
            </li>
          </ul>
        </div>
        <div className={'form-template-bubble'}>
          <h4>Site details</h4>
          <ul>
            <li>
              <span>Name</span>: {data.site_name}
            </li>
            <li>
              <span>Address</span>:
              {formatAddress(
                data.site_building,
                data.site_street,
                data.site_city,
                data.site_region
              )}
            </li>
            <li>
              <span>Postcode</span>: {data.site_postcode}
            </li>
            <li>
              <span>Phone</span>: {data.site_phone}
            </li>
          </ul>
        </div>
      </div>
      <div className={'form-template-row no-page-break'}>
        <div className={'form-template-bubble'}>
          <h4>Windows & Doors</h4>
          <p>{formatAnswer(data.windows_and_doors)}</p>
        </div>
        <div className={'form-template-bubble'}>
          <h4>Roofline</h4>
          <p>{formatAnswer(data.roofline)}</p>
        </div>
        <div className={'form-template-bubble'}>
          <h4>Conservatories</h4>
          <p>{formatAnswer(data.conservatories)}</p>
        </div>
        <div className={'form-template-bubble'}>
          <h4>Cladding</h4>
          <p>{formatAnswer(data.cladding)}</p>
        </div>
      </div>
      <div className={'form-template-row no-page-break'}>
        <div className={'form-template-bubble'}>
          <h4>Job description</h4>
          <p>{data.job_description}</p>
        </div>
      </div>
    </div>
  );
};

export default JobSheetWintecFormTemplate;
