import { Account, AccountRegions } from '@payaca/types/accountTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import {
  MOMENT_CA_DATE_MID_FORMAT,
  MOMENT_CA_DATE_SHORT_DELIMITED_FORMAT,
  MOMENT_CA_DATE_SHORT_FORMAT,
  MOMENT_CA_DATE_SHORT_READABLE_FORMAT,
  MOMENT_CA_DATE_TIMESTAMP_FORMAT,
  MOMENT_CA_DATE_TIMESTAMP_SHORT_FORMAT,
  MOMENT_CA_DATE_TODAY_FORMAT,
  MOMENT_DATE_MID_FORMAT,
  MOMENT_DATE_SHORT_DELIMITED_FORMAT,
  MOMENT_DATE_SHORT_FORMAT,
  MOMENT_DATE_SHORT_READABLE_FORMAT,
  MOMENT_DATE_TIMESTAMP_FORMAT,
  MOMENT_DATE_TIMESTAMP_SHORT_FORMAT,
  MOMENT_DATE_TODAY_FORMAT,
  MOMENT_US_DATE_MID_FORMAT,
  MOMENT_US_DATE_SHORT_DELIMITED_FORMAT,
  MOMENT_US_DATE_SHORT_FORMAT,
  MOMENT_US_DATE_SHORT_READABLE_FORMAT,
  MOMENT_US_DATE_TIMESTAMP_FORMAT,
  MOMENT_US_DATE_TIMESTAMP_SHORT_FORMAT,
  MOMENT_US_DATE_TODAY_FORMAT,
  MOMENT_ZA_DATE_MID_FORMAT,
  MOMENT_ZA_DATE_SHORT_DELIMITED_FORMAT,
  MOMENT_ZA_DATE_SHORT_FORMAT,
  MOMENT_ZA_DATE_SHORT_READABLE_FORMAT,
  MOMENT_ZA_DATE_TIMESTAMP_FORMAT,
  MOMENT_ZA_DATE_TIMESTAMP_SHORT_FORMAT,
  MOMENT_ZA_DATE_TODAY_FORMAT,
} from '@payaca/constants/momentFormatConstants';
import {
  DATE_TODAY_FORMAT,
  DATE_SHORT_FORMAT,
  DATE_MID_FORMAT,
  DATE_SHORT_READABLE_FORMAT,
  DATE_TIMESTAMP_FORMAT,
  DATE_TIMESTAMP_SHORT_FORMAT,
  DATE_SHORT_DELIMITED_FORMAT,
  CA_DATE_TODAY_FORMAT,
  CA_DATE_SHORT_FORMAT,
  CA_DATE_MID_FORMAT,
  CA_DATE_SHORT_READABLE_FORMAT,
  CA_DATE_TIMESTAMP_FORMAT,
  CA_DATE_TIMESTAMP_SHORT_FORMAT,
  CA_DATE_SHORT_DELIMITED_FORMAT,
  US_DATE_TODAY_FORMAT,
  US_DATE_SHORT_FORMAT,
  US_DATE_MID_FORMAT,
  US_DATE_SHORT_READABLE_FORMAT,
  US_DATE_TIMESTAMP_FORMAT,
  US_DATE_TIMESTAMP_SHORT_FORMAT,
  US_DATE_SHORT_DELIMITED_FORMAT,
  ZA_DATE_TODAY_FORMAT,
  ZA_DATE_SHORT_FORMAT,
  ZA_DATE_MID_FORMAT,
  ZA_DATE_SHORT_READABLE_FORMAT,
  ZA_DATE_TIMESTAMP_FORMAT,
  ZA_DATE_TIMESTAMP_SHORT_FORMAT,
  ZA_DATE_SHORT_DELIMITED_FORMAT,
} from '@payaca/constants/formatConstants';
import moment from 'moment-timezone';

const regionalTextStringMappings = {
  [AccountRegions.AUSTRALIA]: {
    [RegionalStrings.CURRENCY_SYMBOL]: '$',
    [RegionalStrings.VALUE_ADDED_TAX]: 'GST',
    [RegionalStrings.VALUE_ADDED_TAX_NUMBER]:
      'Australian Business Number (ABN)',
    [RegionalStrings.VALUE_ADDED_TAX_PERCENTAGE]: '10%',
    [RegionalStrings.VALUE_ADDED_TAX_CSV_HELPER]: `[GST] - must be empty (no GST) or '10'`,
    [RegionalStrings.VALUE_ADDED_TAX_CSV_HELPER_NO_RC]: `[GST] - must be empty (no GST) or '10'`,
    [RegionalStrings.SORT_CODE]: 'BSB',
    [RegionalStrings.POSTCODE]: 'Postcode',
    [RegionalStrings.EMAIL_CUSTOMISATION]: 'E-mail customisation',
  },
  [AccountRegions.CANADA]: {
    [RegionalStrings.CURRENCY_SYMBOL]: '$',
    [RegionalStrings.VALUE_ADDED_TAX]: 'GST/HST',
    [RegionalStrings.VALUE_ADDED_TAX_NUMBER]: 'GST/HST number',
    [RegionalStrings.VALUE_ADDED_TAX_PERCENTAGE]: '5%',
    [RegionalStrings.VALUE_ADDED_TAX_CSV_HELPER]: `[GST/HST] - must be empty (no GST), '0', '5', '13', or '15`,
    [RegionalStrings.VALUE_ADDED_TAX_CSV_HELPER_NO_RC]: `[GST/HST] - must be empty (no GST), '0', '5', '13', or '15`,
    [RegionalStrings.SORT_CODE]: 'Routing number',
    [RegionalStrings.POSTCODE]: 'ZIP Code',
    [RegionalStrings.EMAIL_CUSTOMISATION]: 'E-mail customization',
  },
  [AccountRegions.NEW_ZEALAND]: {
    [RegionalStrings.CURRENCY_SYMBOL]: '$',
    [RegionalStrings.VALUE_ADDED_TAX]: 'GST',
    [RegionalStrings.VALUE_ADDED_TAX_NUMBER]: 'GST number',
    [RegionalStrings.VALUE_ADDED_TAX_PERCENTAGE]: '15%',
    [RegionalStrings.VALUE_ADDED_TAX_CSV_HELPER]: `[GST] - must be empty (no GST) or '15'`,
    [RegionalStrings.VALUE_ADDED_TAX_CSV_HELPER_NO_RC]: `[GST] - must be empty (no GST) or '15'`,
    [RegionalStrings.SORT_CODE]: 'BSB',
    [RegionalStrings.POSTCODE]: 'Postcode',
    [RegionalStrings.EMAIL_CUSTOMISATION]: 'E-mail customisation',
  },
  [AccountRegions.SOUTH_AFRICA]: {
    [RegionalStrings.CURRENCY_SYMBOL]: 'R',
    [RegionalStrings.VALUE_ADDED_TAX]: 'VAT',
    [RegionalStrings.VALUE_ADDED_TAX_NUMBER]: 'VAT number',
    [RegionalStrings.VALUE_ADDED_TAX_PERCENTAGE]: '15%',
    [RegionalStrings.VALUE_ADDED_TAX_CSV_HELPER]: `[VAT] - must be empty (no VAT), '0', or '15'`,
    [RegionalStrings.VALUE_ADDED_TAX_CSV_HELPER_NO_RC]: `[VAT] - must be empty (no VAT), '0', or '15'`,
    [RegionalStrings.SORT_CODE]: 'Branch code',
    [RegionalStrings.POSTCODE]: 'Postcode',
    [RegionalStrings.EMAIL_CUSTOMISATION]: 'E-mail customisation',
  },
  [AccountRegions.UK]: {
    [RegionalStrings.CURRENCY_SYMBOL]: '£',
    [RegionalStrings.VALUE_ADDED_TAX]: 'VAT',
    [RegionalStrings.VALUE_ADDED_TAX_NUMBER]: 'VAT number',
    [RegionalStrings.VALUE_ADDED_TAX_PERCENTAGE]: '20%',
    [RegionalStrings.VALUE_ADDED_TAX_CSV_HELPER]: `[VAT] - must be empty (no VAT), '0', '5' or '20' or '20 RC' (reverse charge)`,
    [RegionalStrings.VALUE_ADDED_TAX_CSV_HELPER_NO_RC]: `[VAT] - must be empty (no VAT), '0', '5' or '20'`,
    [RegionalStrings.SORT_CODE]: 'Sort code',
    [RegionalStrings.POSTCODE]: 'Postcode',
    [RegionalStrings.EMAIL_CUSTOMISATION]: 'E-mail customisation',
  },
  [AccountRegions.US]: {
    [RegionalStrings.CURRENCY_SYMBOL]: '$',
    [RegionalStrings.VALUE_ADDED_TAX]: 'Sales tax',
    [RegionalStrings.VALUE_ADDED_TAX_NUMBER]: 'Sales tax number',
    [RegionalStrings.VALUE_ADDED_TAX_PERCENTAGE]: '0%',
    [RegionalStrings.VALUE_ADDED_TAX_CSV_HELPER]: `[Sales Tax] - must be empty (no Sales tax)`,
    [RegionalStrings.VALUE_ADDED_TAX_CSV_HELPER_NO_RC]: `[Sales Tax] - must be empty (no Sales tax)`,
    [RegionalStrings.SORT_CODE]: 'Routing number',
    [RegionalStrings.POSTCODE]: 'ZIP Code',
    [RegionalStrings.EMAIL_CUSTOMISATION]: 'E-mail customization',
  },
};

export const getRegionalDefaultTax = (region = AccountRegions.UK) => {
  switch (region) {
    case AccountRegions.AUSTRALIA:
      return 10;
    case AccountRegions.CANADA:
      return 5;
    case AccountRegions.NEW_ZEALAND:
      return 15;
    case AccountRegions.SOUTH_AFRICA:
      return 15;
    case AccountRegions.US:
      return 0;
    case AccountRegions.UK:
    // fall-through
    default:
      return 20;
  }
};

export const getRegionalTextString = (
  region = AccountRegions.UK,
  label?: RegionalStrings
) => {
  if (!label) return 'No label';
  const desiredLabel = regionalTextStringMappings?.[region]?.[label];

  // return UK label if for some reason the desired label can't be found
  return (
    desiredLabel ||
    regionalTextStringMappings[AccountRegions.UK]?.[label] ||
    'Missing label'
  );
};

export enum RegionalFormattingTypes {
  SORT_CODE = 'sortCode',
  ACCOUNT_NUMBER = 'accountNumber',
}

const regionalFormattingMap: any = {
  // TODO: ZA
  [RegionalFormattingTypes.SORT_CODE]: {
    [AccountRegions.AUSTRALIA]: (value: any) =>
      `${value.slice(0, 3)}-${value.slice(3)}`,
  },
  [RegionalFormattingTypes.ACCOUNT_NUMBER]: {
    [AccountRegions.AUSTRALIA]: (value: any) =>
      `${value.slice(0, 4)} ${value.slice(4)}`,
  },
};

export const getRegionalFormattedValue = (
  region = AccountRegions.UK,
  format: RegionalFormattingTypes,
  value: string | number
) => {
  // apply a formatting function or just return the raw value
  return regionalFormattingMap?.[format]?.[region]
    ? regionalFormattingMap?.[format]?.[region](value)
    : value;
};

const currencyIsoRegionMap: { [key in AccountRegions]: string } = {
  [AccountRegions.AUSTRALIA]: 'aud',
  [AccountRegions.CANADA]: 'cad',
  [AccountRegions.NEW_ZEALAND]: 'nzd',
  [AccountRegions.SOUTH_AFRICA]: 'zar',
  [AccountRegions.UK]: 'gbp',
  [AccountRegions.US]: 'usd',
};

export const getCurrencyIsoCode = (accountRegion = AccountRegions.UK) => {
  return currencyIsoRegionMap[accountRegion] || 'gbp';
};

export const getAccountRegionFromCurrencyCode = (
  currencyCode: string
): AccountRegions | undefined => {
  return Object.keys(currencyIsoRegionMap).find(
    (key) =>
      currencyIsoRegionMap[key as AccountRegions] ===
      (currencyCode.toLowerCase() || AccountRegions.UK)
  ) as AccountRegions;
};

export const getAccountRegionFromPhoneNumber = (contactNumber = '') => {
  const cleanNumber = contactNumber.trim();
  switch (true) {
    case cleanNumber.startsWith('+61'):
      return AccountRegions.AUSTRALIA;
    case cleanNumber.startsWith('+64'):
      return AccountRegions.NEW_ZEALAND;
    case cleanNumber.startsWith('+27'):
      return AccountRegions.SOUTH_AFRICA;
    case cleanNumber.startsWith('+1'):
      return AccountRegions.US;
    default:
      return AccountRegions.UK;
  }
};

export const getPayacaTaxCodeDescriptionsToApplyToSubscription = (
  accountRegion: AccountRegions
) => {
  switch (accountRegion) {
    case AccountRegions.AUSTRALIA:
    // fall-through
    case AccountRegions.CANADA:
    // fall-through
    case AccountRegions.NEW_ZEALAND:
    // fall-through
    case AccountRegions.SOUTH_AFRICA:
    // fall-through
    case AccountRegions.US:
      return ['UK VAT Zero Rated'];
    default:
      return ['UK VAT'];
  }
};

export enum DateFormats {
  TODAY = 'today',
  SHORT = 'short',
  MID = 'mid',
  TIMESTAMP = 'timestamp',
  TIMESTAMP_SHORT = 'timestamp_short',
  SHORT_DELIMITED = 'short_delimited',
  SHORT_READABLE = 'short_readable',
}
const dateFormatMap: {
  [key in AccountRegions]: { [key in DateFormats]: any };
} = {
  [AccountRegions.AUSTRALIA]: {
    [DateFormats.TODAY]: DATE_TODAY_FORMAT,
    [DateFormats.SHORT]: DATE_SHORT_FORMAT,
    [DateFormats.MID]: DATE_MID_FORMAT,
    [DateFormats.SHORT_DELIMITED]: DATE_SHORT_DELIMITED_FORMAT,
    [DateFormats.TIMESTAMP]: DATE_TIMESTAMP_FORMAT,
    [DateFormats.TIMESTAMP_SHORT]: DATE_TIMESTAMP_SHORT_FORMAT,
    [DateFormats.SHORT_READABLE]: DATE_SHORT_READABLE_FORMAT,
  },
  [AccountRegions.CANADA]: {
    [DateFormats.TODAY]: CA_DATE_TODAY_FORMAT,
    [DateFormats.SHORT]: CA_DATE_SHORT_FORMAT,
    [DateFormats.MID]: CA_DATE_MID_FORMAT,
    [DateFormats.SHORT_DELIMITED]: CA_DATE_SHORT_DELIMITED_FORMAT,
    [DateFormats.TIMESTAMP]: CA_DATE_TIMESTAMP_FORMAT,
    [DateFormats.TIMESTAMP_SHORT]: CA_DATE_TIMESTAMP_SHORT_FORMAT,
    [DateFormats.SHORT_READABLE]: CA_DATE_SHORT_READABLE_FORMAT,
  },
  [AccountRegions.NEW_ZEALAND]: {
    [DateFormats.TODAY]: DATE_TODAY_FORMAT,
    [DateFormats.SHORT]: DATE_SHORT_FORMAT,
    [DateFormats.MID]: DATE_MID_FORMAT,
    [DateFormats.SHORT_DELIMITED]: DATE_SHORT_DELIMITED_FORMAT,
    [DateFormats.TIMESTAMP]: DATE_TIMESTAMP_FORMAT,
    [DateFormats.TIMESTAMP_SHORT]: DATE_TIMESTAMP_SHORT_FORMAT,
    [DateFormats.SHORT_READABLE]: DATE_SHORT_READABLE_FORMAT,
  },
  [AccountRegions.SOUTH_AFRICA]: {
    [DateFormats.TODAY]: ZA_DATE_TODAY_FORMAT,
    [DateFormats.SHORT]: ZA_DATE_SHORT_FORMAT,
    [DateFormats.MID]: ZA_DATE_MID_FORMAT,
    [DateFormats.SHORT_DELIMITED]: ZA_DATE_SHORT_DELIMITED_FORMAT,
    [DateFormats.TIMESTAMP]: ZA_DATE_TIMESTAMP_FORMAT,
    [DateFormats.TIMESTAMP_SHORT]: ZA_DATE_TIMESTAMP_SHORT_FORMAT,
    [DateFormats.SHORT_READABLE]: ZA_DATE_SHORT_READABLE_FORMAT,
  },
  [AccountRegions.UK]: {
    [DateFormats.TODAY]: DATE_TODAY_FORMAT,
    [DateFormats.SHORT]: DATE_SHORT_FORMAT,
    [DateFormats.MID]: DATE_MID_FORMAT,
    [DateFormats.SHORT_DELIMITED]: DATE_SHORT_DELIMITED_FORMAT,
    [DateFormats.TIMESTAMP]: DATE_TIMESTAMP_FORMAT,
    [DateFormats.TIMESTAMP_SHORT]: DATE_TIMESTAMP_SHORT_FORMAT,
    [DateFormats.SHORT_READABLE]: DATE_SHORT_READABLE_FORMAT,
  },
  [AccountRegions.US]: {
    [DateFormats.TODAY]: US_DATE_TODAY_FORMAT,
    [DateFormats.SHORT]: US_DATE_SHORT_FORMAT,
    [DateFormats.MID]: US_DATE_MID_FORMAT,
    [DateFormats.SHORT_DELIMITED]: US_DATE_SHORT_DELIMITED_FORMAT,
    [DateFormats.TIMESTAMP]: US_DATE_TIMESTAMP_FORMAT,
    [DateFormats.TIMESTAMP_SHORT]: US_DATE_TIMESTAMP_SHORT_FORMAT,
    [DateFormats.SHORT_READABLE]: US_DATE_SHORT_READABLE_FORMAT,
  },
};

export const getInternationalDateFormatByLocale = (
  format: DateFormats,
  locale?: string
) => {
  switch (locale) {
    case 'en-CA':
      return dateFormatMap[AccountRegions.CANADA][format];
    case 'en-NZ':
      return dateFormatMap[AccountRegions.NEW_ZEALAND][format];
    case 'en-ZA':
      return dateFormatMap[AccountRegions.SOUTH_AFRICA][format];
    case 'en-US':
      return dateFormatMap[AccountRegions.US][format];
    case 'en-GB':
      return dateFormatMap[AccountRegions.UK][format];
    default:
      return dateFormatMap[AccountRegions.UK][format];
  }
};

const dateMomentFormatMap: {
  [key in AccountRegions]: { [key in DateFormats]: any };
} = {
  [AccountRegions.AUSTRALIA]: {
    [DateFormats.TODAY]: MOMENT_DATE_TODAY_FORMAT,
    [DateFormats.SHORT]: MOMENT_DATE_SHORT_FORMAT,
    [DateFormats.MID]: MOMENT_DATE_MID_FORMAT,
    [DateFormats.SHORT_DELIMITED]: MOMENT_DATE_SHORT_DELIMITED_FORMAT,
    [DateFormats.TIMESTAMP]: MOMENT_DATE_TIMESTAMP_FORMAT,
    [DateFormats.TIMESTAMP_SHORT]: MOMENT_DATE_TIMESTAMP_SHORT_FORMAT,
    [DateFormats.SHORT_READABLE]: MOMENT_DATE_SHORT_READABLE_FORMAT,
  },
  [AccountRegions.CANADA]: {
    [DateFormats.TODAY]: MOMENT_CA_DATE_TODAY_FORMAT,
    [DateFormats.SHORT]: MOMENT_CA_DATE_SHORT_FORMAT,
    [DateFormats.MID]: MOMENT_CA_DATE_MID_FORMAT,
    [DateFormats.SHORT_DELIMITED]: MOMENT_CA_DATE_SHORT_DELIMITED_FORMAT,
    [DateFormats.TIMESTAMP]: MOMENT_CA_DATE_TIMESTAMP_FORMAT,
    [DateFormats.TIMESTAMP_SHORT]: MOMENT_CA_DATE_TIMESTAMP_SHORT_FORMAT,
    [DateFormats.SHORT_READABLE]: MOMENT_CA_DATE_SHORT_READABLE_FORMAT,
  },
  [AccountRegions.NEW_ZEALAND]: {
    [DateFormats.TODAY]: MOMENT_DATE_TODAY_FORMAT,
    [DateFormats.SHORT]: MOMENT_DATE_SHORT_FORMAT,
    [DateFormats.MID]: MOMENT_DATE_MID_FORMAT,
    [DateFormats.SHORT_DELIMITED]: MOMENT_DATE_SHORT_DELIMITED_FORMAT,
    [DateFormats.TIMESTAMP]: MOMENT_DATE_TIMESTAMP_FORMAT,
    [DateFormats.TIMESTAMP_SHORT]: MOMENT_DATE_TIMESTAMP_SHORT_FORMAT,
    [DateFormats.SHORT_READABLE]: MOMENT_DATE_SHORT_READABLE_FORMAT,
  },
  [AccountRegions.SOUTH_AFRICA]: {
    [DateFormats.TODAY]: MOMENT_ZA_DATE_TODAY_FORMAT,
    [DateFormats.SHORT]: MOMENT_ZA_DATE_SHORT_FORMAT,
    [DateFormats.MID]: MOMENT_ZA_DATE_MID_FORMAT,
    [DateFormats.SHORT_DELIMITED]: MOMENT_ZA_DATE_SHORT_DELIMITED_FORMAT,
    [DateFormats.TIMESTAMP]: MOMENT_ZA_DATE_TIMESTAMP_FORMAT,
    [DateFormats.TIMESTAMP_SHORT]: MOMENT_ZA_DATE_TIMESTAMP_SHORT_FORMAT,
    [DateFormats.SHORT_READABLE]: MOMENT_ZA_DATE_SHORT_READABLE_FORMAT,
  },
  [AccountRegions.UK]: {
    [DateFormats.TODAY]: MOMENT_DATE_TODAY_FORMAT,
    [DateFormats.SHORT]: MOMENT_DATE_SHORT_FORMAT,
    [DateFormats.MID]: MOMENT_DATE_MID_FORMAT,
    [DateFormats.SHORT_DELIMITED]: MOMENT_DATE_SHORT_DELIMITED_FORMAT,
    [DateFormats.TIMESTAMP]: MOMENT_DATE_TIMESTAMP_FORMAT,
    [DateFormats.TIMESTAMP_SHORT]: MOMENT_DATE_TIMESTAMP_SHORT_FORMAT,
    [DateFormats.SHORT_READABLE]: MOMENT_DATE_SHORT_READABLE_FORMAT,
  },
  [AccountRegions.US]: {
    [DateFormats.TODAY]: MOMENT_US_DATE_TODAY_FORMAT,
    [DateFormats.SHORT]: MOMENT_US_DATE_SHORT_FORMAT,
    [DateFormats.MID]: MOMENT_US_DATE_MID_FORMAT,
    [DateFormats.SHORT_DELIMITED]: MOMENT_US_DATE_SHORT_DELIMITED_FORMAT,
    [DateFormats.TIMESTAMP]: MOMENT_US_DATE_TIMESTAMP_FORMAT,
    [DateFormats.TIMESTAMP_SHORT]: MOMENT_US_DATE_TIMESTAMP_SHORT_FORMAT,
    [DateFormats.SHORT_READABLE]: MOMENT_US_DATE_SHORT_READABLE_FORMAT,
  },
};
export const getInternationalMomentDateFormatByRegion = (
  format: DateFormats,
  accountRegion: AccountRegions
) => {
  return (
    dateMomentFormatMap?.[accountRegion]?.[format] ||
    MOMENT_DATE_TIMESTAMP_SHORT_FORMAT
  );
};

// TODO: Expand to cover the many timezones in different countries
export const getInternationalTimezoneByRegion = (
  accountRegion: AccountRegions
) => {
  switch (accountRegion) {
    case AccountRegions.AUSTRALIA:
      // FIXME: Why do we use Australia/Brisbane here, but Australia/Sydney above?
      return 'Australia/Brisbane';
    case AccountRegions.CANADA:
      return 'Canada/Atlantic';
    case AccountRegions.NEW_ZEALAND:
      return 'Pacific/Auckland';
    case AccountRegions.SOUTH_AFRICA:
      return 'Africa/Johannesburg';
    case AccountRegions.US:
      return 'America/New_York';
    case AccountRegions.UK:
    // fall-through
    default:
      return 'Europe/London';
  }
};

export const setDefaultTimezoneUsingAccountRegionAndPreferredTimezone = (
  region: AccountRegions,
  preferredTimezone: Account['preferredTimezone']
) => {
  // Set Monday as the first day of the week
  moment.updateLocale('en', {
    week: {
      dow: 1, // Monday is the first day of the week.
    },
  });
  // use preferredTimezone if set (currently only saved to account via web sign up), otherwise fallback to region
  if (preferredTimezone) {
    moment.tz.setDefault(preferredTimezone);
  } else {
    switch (region) {
      case AccountRegions.AUSTRALIA:
        // FIXME: Why do we use Australia/Sydney here, but Australia/Brisbane above?
        // @ts-ignore
        moment.tz.setDefault('Australia/Sydney');
        break;
      case AccountRegions.CANADA:
        // @ts-ignore
        moment.tz.setDefault('Canada/Atlantic');
        break;
      case AccountRegions.NEW_ZEALAND:
        // @ts-ignore
        moment.tz.setDefault('Pacific/Auckland');
        break;
      case AccountRegions.SOUTH_AFRICA:
        // @ts-ignore
        moment.tz.setDefault('Africa/Johannesburg');
        break;
      case AccountRegions.US:
        // @ts-ignore
        moment.tz.setDefault('America/New_York');
        break;
      case AccountRegions.UK:
      // fall-through
      default:
        // We'll use UK time as the default
        // @ts-ignore
        moment.tz.setDefault('Europe/London');
        break;
    }
  }
};

const twilioAlphanumericSupportHandleMap: Record<AccountRegions, boolean> = {
  [AccountRegions.AUSTRALIA]: true,
  [AccountRegions.CANADA]: false,
  [AccountRegions.NEW_ZEALAND]: false,
  [AccountRegions.SOUTH_AFRICA]: false,
  [AccountRegions.UK]: true,
  [AccountRegions.US]: false,
};

export const cansupportTwilioSenderHandle = (region: AccountRegions) =>
  twilioAlphanumericSupportHandleMap[region] || false;
