import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, matchPath, useLocation } from 'react-router';
import './SupplierPurchaseOrders.sass';
import { Supplier } from '@payaca/types/supplierTypes';
import PurchaseOrdersTable from '../purchaseOrdersTable/PurchaseOrdersTable';
import {
  GetListedPurchaseOrdersQueryParams,
  ListedPurchaseOrdersPage,
  SortBy,
} from '@payaca/types/listedPurchaseOrderTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import {
  createIsolatedPurchaseOrder,
  getListedPurchaseOrdersPage,
} from '@payaca/store/materialsList/materialsListActions';
import Button from '@payaca/components/button/Button';
import PaginationControl from '@payaca/components/paginationControl/PaginationControl';
import SearchField from '../searchField/SearchField';
import { InputWidth } from '@payaca/components/inputWrapper/InputWrapper';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';

type Props = {
  supplierId: Supplier['id'];
};
const SupplierPurchaseOrders: FC<Props> = ({
  supplierId,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isCreatingPurchaseOrder, setIsCreatingPurchaseOrder] = useState(false);

  const [purchaseOrdersPage, setPurchaseOrdersPage] =
    useState<ListedPurchaseOrdersPage>();
  const [
    isGettingListedPurchaseOrdersPage,
    setIsGettingListedPurchaseOrdersPage,
  ] = useState(false);

  const [
    getListedPurchaseOrdersRequestData,
    setGetListedPurchaseOrdersRequestData,
  ] = useState<GetListedPurchaseOrdersQueryParams>({
    sortBy: SortBy.SENT_AT,
    sortDirection: SortDirection.DESCENDING,
    pageSize: 20,
    pageNumber: 1,
    searchTerm: '',
    supplierIds: [supplierId],
  });

  useEffect(() => {
    handleGetListedPurchaseOrdersPage();
  }, [getListedPurchaseOrdersRequestData]);

  const handleGetListedPurchaseOrdersPage = useCallback(() => {
    setIsGettingListedPurchaseOrdersPage(true);
    dispatch(
      getListedPurchaseOrdersPage.request({
        queryParams: getListedPurchaseOrdersRequestData,
        callback: (page) => {
          setPurchaseOrdersPage(page);
          setIsGettingListedPurchaseOrdersPage(false);
        },
      })
    );
  }, [getListedPurchaseOrdersRequestData, dispatch]);

  return (
    <div className="supplier-purchase-orders">
      <div className="flex gap-4 flex-wrap items-center">
        <div className="grow">
          <SearchField
            inputWidthVariant={InputWidth.MD}
            placeholder="Search for a Purchase Order"
            onSearchTermChange={(searchTerm) => {
              setGetListedPurchaseOrdersRequestData((x) => ({
                ...x,
                searchTerm: searchTerm,
                pageNumber: 1,
              }));
            }}
          />
        </div>
        <PermissionGuard
          renderIfHasPermissions={[
            MaterialsListPermissions.PERSIST_ISOLATED_PURCHASE_ORDER,
          ]}
        >
          <div className="shrink ml-auto">
            <Button
              isProcessing={isCreatingPurchaseOrder}
              onClick={() => {
                if (isCreatingPurchaseOrder) return;
                setIsCreatingPurchaseOrder(true);
                dispatch(
                  createIsolatedPurchaseOrder.request({
                    payload: { supplierId: supplierId },
                    callback: (purchaseOrder) => {
                      setIsCreatingPurchaseOrder(false);
                      history.push(`/purchaseOrders/${purchaseOrder.id}/edit`);
                    },
                    onErrorCallback: () => {
                      setIsCreatingPurchaseOrder(false);
                    },
                  })
                );
              }}
            >
              Create Purchase Order
            </Button>
          </div>
        </PermissionGuard>
      </div>

      <div className="supplier-purchase-orders__table-wrapper">
        <PurchaseOrdersTable
          purchaseOrders={purchaseOrdersPage?.items || []}
          isLoading={isGettingListedPurchaseOrdersPage}
          excludeColumns={['supplier']}
          onClickRow={(po) => {
            history.push(`/purchaseOrders/${po.id}`);
          }}
          quickActionDefinitions={[
            {
              actionName: 'Edit',
              actionBehaviour: (po) =>
                po.isIsolated
                  ? history.push(`/purchaseOrders/${po.id}/edit`)
                  : history.push(`/purchaseOrders/${po.id}#edit-po`),
            },
            {
              actionName: 'Send',
              actionBehaviour: (po) =>
                history.push(`/purchaseOrders/${po.id}#send-po`),
            },
            {
              actionName: 'Void',
              actionBehaviour: (po) =>
                history.push(`/purchaseOrders/${po.id}#void-po`),
            },
            {
              actionName: 'Confirm',
              actionBehaviour: (po) =>
                history.push(`/purchaseOrders/${po.id}#confirm-po`),
            },
          ]}
        />
        {purchaseOrdersPage && (
          <PaginationControl
            pageNumber={purchaseOrdersPage.pageNumber}
            totalItemCount={purchaseOrdersPage.totalItemCount}
            pageSize={purchaseOrdersPage.pageSize}
            onSelectPage={(pageNumber) => {
              setGetListedPurchaseOrdersRequestData((x) => ({
                ...x,
                pageNumber: pageNumber,
              }));
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SupplierPurchaseOrders;
