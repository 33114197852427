import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  createServicePlansCustomerReminder,
  getServicePlansCustomerReminder,
  updateServicePlansCustomerReminder,
} from '@payaca/store/servicePlans/servicePlansActions';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { useDispatch } from 'react-redux';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import SwitchField from '@payaca/components/switchField/SwitchField';
import {
  TFieldValidators,
  TOnFormSubmit,
  TRenderFormContents,
} from '@payaca/components/validatedForm/ValidatedForm';
import { InputWidth } from '@payaca/components/inputWrapper/InputWrapper';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import {
  PublicHydratedServicePlanCustomerReminderTemplate,
  PublicServicePlan,
} from '@payaca/types/service-plans';
import RichTextEditorField from '@payaca/components/richTextEditorField/RichTextEditorField';
import { Descendant } from 'slate';
import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';
import { useSelector } from '@payaca/store/hooks/appState';
import { useHistory } from 'react-router';
import {
  channelOptions,
  defaultMessage,
  messageVariables,
  timeOffsetOptions,
} from '@/ui/components/servicePlanCustomerReminderControl/constants';
import { ServicePlanPeriodReminderTemplateVariables } from '@payaca/types/richTextEditorVariables';

type TFormState = {
  name: PublicHydratedServicePlanCustomerReminderTemplate['name'];
  channels: PublicHydratedServicePlanCustomerReminderTemplate['channels'];
  servicePlans: PublicServicePlan['publicId'][];
  timeOffset: PublicHydratedServicePlanCustomerReminderTemplate['timeOffset'];
  subject: PublicHydratedServicePlanCustomerReminderTemplate['subject'];
  isEnabled: PublicHydratedServicePlanCustomerReminderTemplate['isEnabled'];
  message: Descendant[];
};

export interface IProps {
  servicePlanCustomerReminderPublicId?: string;
}

const ServicePlanCustomerReminderControl: FC<IProps> = (props) => {
  const { servicePlanCustomerReminderPublicId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const [isSavingCustomerReminder, setIsSavingCustomerReminder] =
    useState(false);
  const [isLoadingCustomerReminder, setIsLoadingCustomerReminder] = useState(
    !!servicePlanCustomerReminderPublicId
  );
  const [customerReminder, setCustomerReminder] = useState<
    PublicHydratedServicePlanCustomerReminderTemplate | undefined
  >(undefined);

  useEffect(() => {
    dispatch(servicePlansActions.getListedServicePlans.request({}));
  }, [dispatch]);

  const listedServicePlans = useSelector(
    (state) => state.servicePlans.listedServicePlans
  );

  const servicePlanOptions = useMemo(
    () =>
      listedServicePlans.map((servicePlan) => ({
        label: servicePlan.name,
        value: servicePlan.publicId,
      })),
    [listedServicePlans]
  );

  useEffect(() => {
    if (!servicePlanCustomerReminderPublicId) return;

    setIsLoadingCustomerReminder(true);
    dispatch(
      getServicePlansCustomerReminder.request({
        publicId: servicePlanCustomerReminderPublicId,
        onSuccess: (template) => {
          setCustomerReminder(template);
          setIsLoadingCustomerReminder(false);
        },
        onError: (err) => {
          setIsLoadingCustomerReminder(false);
          showDynamicFeedbackMessage({
            title: 'Error fetching service plan customer reminder',
            body: (
              <>
                <p>Please try again in a few minutes.</p>
              </>
            ),
            isCancellable: true,
            lifespanMs: DynamicFeedbackLifespanMs.SHORT,
            feedbackLevel: FeedbackLevel.ERROR,
          });
        },
      })
    );
  }, [servicePlanCustomerReminderPublicId]);

  const initialFormState = useMemo<TFormState>(
    () => ({
      name: customerReminder?.name || '',
      channels: customerReminder?.channels || [],
      servicePlans: customerReminder?.servicePlans.map((i) => i.publicId) || [],
      timeOffset: customerReminder?.timeOffset || '',
      subject: customerReminder?.subject || '',
      message: (customerReminder?.message as Descendant[]) || defaultMessage,
      isEnabled: customerReminder?.isEnabled || true,
    }),
    [customerReminder]
  );

  const onFormSubmit: TOnFormSubmit<TFormState> = (formState) => {
    setIsSavingCustomerReminder(true);

    const body = {
      name: formState.name,
      subject: formState.subject,
      message: formState.message,
      timeOffset: formState.timeOffset,
      channels: formState.channels,
      servicePlanPublicIds: formState.servicePlans,
      isEnabled: formState.isEnabled,
    };

    const onSuccess = () => {
      setIsSavingCustomerReminder(false);
      history.push('/service-plans/customer-reminders');
    };
    const onError = () => {
      setIsSavingCustomerReminder(false);
      showDynamicFeedbackMessage({
        title: 'Error saving service plan customer reminder',
        body: (
          <>
            <p>Please try again in a few minutes. Or contact support</p>
          </>
        ),
        isCancellable: true,
        lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
        feedbackLevel: FeedbackLevel.ERROR,
      });
    };

    if (servicePlanCustomerReminderPublicId) {
      // Edit
      dispatch(
        updateServicePlansCustomerReminder.request({
          publicId: servicePlanCustomerReminderPublicId,
          body,
          onSuccess: onSuccess,
          onError: onError,
        })
      );
    } else {
      // Create
      dispatch(
        createServicePlansCustomerReminder.request({
          body,
          onSuccess: onSuccess,
          onError: onError,
        })
      );
    }
  };

  const renderFormContents = useCallback<TRenderFormContents<TFormState>>(
    (
      isValid,
      formState,
      validationState,
      touchedState,
      onFieldChange,
      onFieldTouch
    ) => {
      return (
        <>
          <SwitchField
            name="isEnabled"
            label="Off/On"
            value={formState.isEnabled}
            onChange={onFieldChange}
          />

          <BasicField
            label="Reminder Name"
            inputWidth={InputWidth.MD}
            name="name"
            isRequired
            value={formState.name}
            onChange={onFieldChange}
          />

          <DropdownField
            name="channels"
            inputWidth={InputWidth.XSM}
            label="Reminder type"
            isRequired
            multiple
            value={formState.channels}
            onChange={onFieldChange}
            displayCheckboxes
            options={channelOptions}
          />

          <hr />
          <h4>
            Select which service plan you would like to set a reminder for
          </h4>

          <DropdownField
            name="servicePlans"
            inputWidth={InputWidth.SM}
            label="Service plans"
            isRequired
            multiple
            displayCheckboxes
            value={formState.servicePlans}
            onChange={onFieldChange}
            options={servicePlanOptions}
          />

          <hr />
          <h4>When should the reminder by sent?</h4>

          <DropdownField
            name="timeOffset"
            isRequired
            inputWidth={InputWidth.SM}
            label="Send trigger"
            value={formState.timeOffset}
            onChange={onFieldChange}
            options={timeOffsetOptions}
          />

          <BasicField
            label="Subject"
            isRequired
            inputWidth={InputWidth.MD}
            name="subject"
            value={formState.subject}
            placeholder="Message subject"
            onChange={onFieldChange}
          />

          <RichTextEditorField<ServicePlanPeriodReminderTemplateVariables>
            initialValue={initialFormState.message}
            label="Message"
            isRequired
            name="message"
            onChange={onFieldChange}
            variables={messageVariables}
          />

          <span>
            <Button
              isDisabled={!isValid}
              isProcessing={isSavingCustomerReminder}
              type="submit"
              styleVariant={ButtonStyleVariant.OUTSIZE}
            >
              Save
            </Button>
          </span>
        </>
      );
    },
    [
      servicePlanCustomerReminderPublicId,
      isSavingCustomerReminder,
      initialFormState,
      servicePlanOptions,
    ]
  );

  const fieldValidators = useMemo<TFieldValidators<TFormState>>(
    () => ({
      name: [getIsRequiredFieldValidator()],
      channels: [getIsRequiredFieldValidator()],
      servicePlans: [getIsRequiredFieldValidator()],
      timeOffset: [getIsRequiredFieldValidator()],
      subject: [getIsRequiredFieldValidator()],
      message: [getIsRequiredFieldValidator()],
    }),
    []
  );

  if (isLoadingCustomerReminder) return <MiniLoader />;

  return (
    <>
      <ValidatedForm<TFormState>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
        onFormSubmit={onFormSubmit}
      />
    </>
  );
};

export default ServicePlanCustomerReminderControl;
