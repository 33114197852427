/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

export enum MaterialsListRoles {
  VIEWER = 'roles.materialsListViewer',
  PERSISTER = 'roles.materialsListPersister',
  DELETER = 'roles.materialsListPersister',
  POPULATOR = 'roles.materialsListPopulator',

  PURCHASE_RECORD_VIEWER = 'roles.materialsListPurchaseRecordViewer',
  PURCHASE_RECORD_PERSISTER = 'roles.materialsListPurchaseRecordPersister',
  PURCHASE_RECORD_DELETER = 'roles.materialsListPurchaseRecordDeleter',

  PURCHASE_ORDER_VIEWER = 'roles.materialsListPurchaseOrderViewer',
  PURCHASE_ORDER_PERSISTER = 'roles.materialsListPurchaseOrderPersister',
  PURCHASE_ORDER_SENDER = 'roles.materialsListPurchaseOrderSender',
  PURCHASE_ORDER_VOIDER = 'roles.materialsListPurchaseOrderVoider',
  PURCHASE_ORDER_CONFIRMER = 'roles.materialsListPurchaseOrderConfirmer',

  SELF_ASSIGNED_EVENT_DEAL_PURCHASE_ORDER_VIEWER = 'roles.selfAssignedEventDealPurchaseOrderViewer',

  ISOLATED_PURCHASE_ORDER_PERSISTER = 'roles.materialsListPurchaseOrderPersister',
  ISOLATED_PURCHASE_ORDER_SENDER = 'roles.materialsListPurchaseOrderSender',
  ISOLATED_PURCHASE_ORDER_VOIDER = 'roles.materialsListPurchaseOrderVoider',
  ISOLATED_PURCHASE_ORDER_CONFIRMER = 'roles.materialsListPurchaseOrderConfirmer',
}
