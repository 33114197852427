export enum TasksRoles {
  VIEWER = 'roles.tasksViewer',
  PERSISTER = 'roles.tasksPersister',
  DELETER = 'roles.tasksDeleter',
  CREATOR_PERSISTER = 'roles.tasksCreatorPersister', // Able to persist tasks only if you are the original creator of that task
  CREATOR_DELETER = 'roles.tasksCreatorDeleter', // Able to delete tasks only if you are the original creator of that task
  COMPLETER = 'roles.tasksCompleter',
  EVENT_ASSIGNEE_VIEWER = 'roles.tasksEventAssigneeViewer',
  EVENT_ASSIGNEE_COMPLETER = 'roles.tasksEventAssigneeCompleter',
  ASSIGNEE_VIEWER = 'roles.tasksAssigneeViewer',
  ASSIGNEE_COMPLETER = 'roles.tasksAsigneeCompleter',
  DEAL_ASSIGNEE_VIEWER = 'roles.tasksDealAssigneeViewer',
  DEAL_ASSIGNEE_COMPLETER = 'roles.tasksDealAsigneeCompleter',
  DEAL_ASSIGNEE_PERSISTER = 'roles.tasksDealAsigneePersister',
}
