import React, { FC, useMemo } from 'react';

export interface IProps {
  className?: string;
  address: {
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    country?: string | null;
    postalCode?: string | null;
  };
}

const Address2Lines: FC<IProps> = (props) => {
  const { className, address } = props;

  const firstLineElements = useMemo(() => {
    if (!address) return [];

    const addressEl = [];
    addressEl.push(address.line1);
    addressEl.push(address.line2);

    return addressEl.filter(Boolean);
  }, [address]);

  const secondLineElements = useMemo(() => {
    if (!address) return [];

    const addressEl = [];
    addressEl.push(address.city);
    addressEl.push(address.country);
    addressEl.push(address.postalCode);

    return addressEl.filter(Boolean);
  }, [address]);

  return (
    <>
      <p className={className}>
        {firstLineElements.map((el, index) => (
          <React.Fragment key={index}>
            <span>{el}</span>
            {index < firstLineElements.length - 1 && ', '}
          </React.Fragment>
        ))}
      </p>
      <p className={className}>
        {secondLineElements.map((el, index) => (
          <React.Fragment key={index}>
            <span>{el}</span>
            {index < secondLineElements.length - 1 && ', '}
          </React.Fragment>
        ))}
      </p>
    </>
  );
};

export default Address2Lines;
