import React, { FC, useEffect, useMemo, useState } from 'react';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import Button from '@payaca/components/plButton/Button';
import { AddressLookupSelect } from '@/ui/components/addressLookupSelect/AddressLookupSelect';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import ManuallyAddEditAddressControl from '@/ui/components/addressControls/ManualAddEditAddressControl';
import Conditional from '@payaca/components/conditional/Conditional';
import { Address as _Address } from '@/gql/graphql';

type Address = Partial<Omit<_Address, 'id'>>;

export interface IProps extends Omit<IModalProps, 'title'> {
  title?: string;
  onSave?: (address: Address) => void;
}

const AddAddressModal: FC<IProps> = (props) => {
  const { title = 'Add Addresses', isOpen, onSave, onBack, ...rest } = props;
  const [address, setAddress] = useState<Address>({});
  const [isManualEdit, setIsManualEdit] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setAddress({});
      setIsManualEdit(false);
    }
  }, [isOpen]);

  const isDirty = useMemo(() => {
    return (
      !!address?.line1 ||
      !!address?.line2 ||
      !!address?.city ||
      !!address?.postalCode
    );
  }, [address]);

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      disableBackdropClick={isDirty || isManualEdit}
      onBack={isManualEdit ? () => setIsManualEdit(false) : onBack}
      {...rest}
    >
      <Conditional
        condition={!isManualEdit}
        fallback={
          <ManuallyAddEditAddressControl
            isInModal
            address={address}
            onSave={onSave}
            onClose={() => {
              setIsManualEdit(false);
            }}
          />
        }
      >
        <Modal.Body>
          <div className="flex flex-col gap-4">
            <AddressLookupSelect
              address={address}
              onChange={(addressType) => {
                const input = {
                  ...addressType,
                  postalCode: addressType?.postcode,
                };

                delete input.postcode;

                setAddress?.(input);
              }}
            />

            <div>
              <Button
                variant={EBtnVariant.LinkInline}
                onClick={() => {
                  setIsManualEdit(true);
                }}
              >
                {isDirty ? 'Edit' : 'Enter'} manually
              </Button>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Actions>
            <Button disabled={!isDirty} onClick={() => onSave?.(address)}>
              Add Address
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </Conditional>
    </Modal>
  );
};

export default AddAddressModal;
