import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, convertFromRaw, EditorState, Modifier } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import FieldLabel from '../fieldLabel/FieldLabel';
import {
  InputColourVariant,
  InputStyleVariant,
} from '../inputWrapper/InputWrapper';
import { Emojis } from './editorConfig';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextareaField.sass';

export enum ToolbarColourVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WHITE = 'white',
}

type Props = {
  styleVariant?: InputStyleVariant;
  colourVariant?: InputColourVariant;
  toolbarColourVariant?: ToolbarColourVariant;
  description?: string | JSX.Element;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string | JSX.Element;
  name: string;
  onChange?: (value: { [key: string]: string }) => void;
  onTouch?: (fieldName: string) => void;
  placeholder?: string;
  value?: any;
  changeTimeoutMs?: number;
  onBlur?: () => void;
  onChangeTimeout?: () => void;
  additionalEditorProps?: { [key: string]: any };
  hasBoxShadow?: boolean;
  bindEventHandlers?: (handlerObj: { insertText: any }) => void;
};

const TextareaFieldFormatter: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  styleVariant = InputStyleVariant.STANDARD,
  colourVariant = InputColourVariant.WHITE,
  toolbarColourVariant = ToolbarColourVariant.WHITE,
  hasBoxShadow = true,
  description,
  isDisabled = false,
  isRequired = false,
  label,
  name,
  onChange,
  onTouch,
  placeholder,
  value,
  onBlur,
  onChangeTimeout,
  changeTimeoutMs = 1000,
  bindEventHandlers,
  additionalEditorProps,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const [editorRef, setEditorRef] = useState<any>();
  const changeTimeoutRef = useRef<any>();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const hasInitialisedRef = useRef<boolean>(false);

  const onInputTouch = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      onTouch && onTouch(name);
    },
    [onTouch, name]
  );

  useEffect(() => {
    // only initialise when a value exists
    if (value !== undefined && !hasInitialisedRef.current) {
      hasInitialisedRef.current = true;
      // convert md to editorstate
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(
            markdownToDraft(value, {
              preserveNewlines: true,
            })
          )
        )
      );
    }
  }, [hasInitialisedRef.current, value]);

  const insertTextHandler = useCallback(
    (text: string) => {
      if (!editorRef) return;
      const currentContent = editorState.getCurrentContent();
      const currentSelection = editorState.getSelection();

      const newContent = Modifier.replaceText(
        currentContent,
        currentSelection,
        text
      );

      const newEditorState = EditorState.push(
        editorState,
        newContent,
        'insert-characters'
      );
      editorRef.focusEditor();
      onEditorChange(
        EditorState.forceSelection(
          newEditorState,
          newContent.getSelectionAfter()
        )
      );
    },
    [editorRef, editorState]
  );

  useEffect(() => {
    bindEventHandlers &&
      bindEventHandlers({
        insertText: insertTextHandler,
      });
  }, [insertTextHandler]);

  const onEditorChange = useCallback(
    (editorStateChange: any) => {
      const content = editorStateChange.getCurrentContent();
      const rawObject = convertToRaw(content);
      const markdownString = draftToMarkdown(rawObject, {
        preserveNewlines: true,
      });
      onChange &&
        onChange({
          [name]: markdownString,
        });
      setEditorState(editorStateChange);
      changeTimeoutRef.current && clearTimeout(changeTimeoutRef.current);
      changeTimeoutRef.current = setTimeout(() => {
        onChangeTimeout && onChangeTimeout();
      }, changeTimeoutMs);
    },
    [onChange, onChangeTimeout, changeTimeoutRef.current, changeTimeoutMs]
  );

  return (
    <div className="text-area-field-formatter">
      <FieldLabel
        label={label}
        isRequired={isRequired}
        description={description}
      />
      <Editor
        ref={setEditorRef}
        editorClassName={`text-area-field-formatter-editor`}
        toolbarClassName={`text-area-field-formatter-toolbar toolbar-col-${toolbarColourVariant}`}
        editorState={editorState}
        onEditorStateChange={onEditorChange}
        toolbar={{
          options: ['inline', 'emoji', 'list', 'link'],
          inline: {
            options: ['bold', 'italic', 'strikethrough'],
          },
          emoji: {
            emojis: Emojis,
          },
        }}
        placeholder={placeholder}
        onBlur={(event: React.FocusEvent<HTMLTextAreaElement>) => {
          onInputTouch && onInputTouch(event);
          onBlur && onBlur();
        }}
        handlePastedText={() => false}
        spellCheck={true}
        {...additionalEditorProps}
      />
    </div>
  );
};

export default TextareaFieldFormatter;
