import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import './TagMetricAnalyticsDataVisualisation.sass';
import { TaggableEntityType } from '@payaca/types/tagTypes';
import {
  getAvailableTagMetricAnalyticsConfigsForEntityType,
  getTagMetricAnalyticsConfig,
  isRangeMetricAnalyticsData,
  isValueMetricAnalyticsData,
} from '@payaca/helpers/tagAnalyticsHelper';
import {
  DataType,
  MetricType,
  TagMetricAnalyticsData,
} from '@payaca/types/tagAnalyticsTypes';
import HorizontalBarChart from '@payaca/components/horizontalBarChart/HorizontalBarChart';
import HorizontalBoxPlot from '@payaca/components/horizontalBoxPlot/HorizontalBoxPlot';
import Tag from '@payaca/components/tag/Tag';
import { AccountRegions } from '@payaca/types/accountTypes';
import { RangeDatapoint } from '@payaca/components/horizontalChart/HorizontalChart';

type Props = {
  tagMetricAnalyticsData: TagMetricAnalyticsData<DataType>;
  region?: AccountRegions;
};

const TagMetricAnalyticsDataVisualisation: FunctionComponent<Props> = ({
  tagMetricAnalyticsData,
  region,
}: Props): JSX.Element | null => {
  const config = getTagMetricAnalyticsConfig(
    tagMetricAnalyticsData.entityType,
    tagMetricAnalyticsData.metricType
  );

  if (!config) return null;

  return (
    <div className={`tag-metric-analytics-data-visualisation`}>
      <div>
        <h2>{config.name}</h2>
        {config.description && <p>{config.description}</p>}
      </div>
      {tagMetricAnalyticsData.datapoints.length ? (
        <>
          {isRangeMetricAnalyticsData(tagMetricAnalyticsData) && (
            <HorizontalBoxPlot
              datapoints={tagMetricAnalyticsData.datapoints.map((d) => ({
                label: d.tag.tagText,
                colour: d.tag.tagColour,
                data: d.data,
              }))}
              renderLabel={(datapoint) => {
                return (
                  <Tag tagText={datapoint.label} colour={datapoint.colour} />
                );
              }}
              valueFormatter={
                config.valueFormatter
                  ? (x) => config.valueFormatter?.(x, region) || x.toString()
                  : undefined
              }
              axisTickFormatter={
                config.axisTickFormatter
                  ? (x) => config.axisTickFormatter?.(x, region) || x.toString()
                  : undefined
              }
            />
          )}
          {/*  */}
          {isValueMetricAnalyticsData(tagMetricAnalyticsData) && (
            <HorizontalBarChart
              isDiscrete={config.isDiscrete}
              datapoints={tagMetricAnalyticsData.datapoints.map((d) => ({
                label: d.tag.tagText,
                colour: d.tag.tagColour,
                data: d.data,
              }))}
              renderLabel={(datapoint) => {
                return (
                  <Tag tagText={datapoint.label} colour={datapoint.colour} />
                );
              }}
              valueFormatter={
                config.valueFormatter
                  ? (x) => config.valueFormatter?.(x, region) || x.toString()
                  : undefined
              }
              axisTickFormatter={
                config.axisTickFormatter
                  ? (x) => config.axisTickFormatter?.(x, region) || x.toString()
                  : undefined
              }
            />
          )}
        </>
      ) : (
        <em>There is no data to display</em>
      )}
    </div>
  );
};

export default TagMetricAnalyticsDataVisualisation;
