// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import { LowCarbonTech } from '@payaca/types/deviceTypes';

const connectionApplicationKeys = {
  all: ['connectionApplication'] as const,
  schema: (lcts?: LowCarbonTech[]) => {
    if (!lcts) {
      return [...connectionApplicationKeys.all, 'schema'] as const;
    }

    return [...connectionApplicationKeys.all, 'schema', lcts] as const;
  },
  connectionApplication: (connectionApplicationId: string) =>
    [...connectionApplicationKeys.all, connectionApplicationId] as const,
};

export default connectionApplicationKeys;
