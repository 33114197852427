import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export enum FeedbackLevel {
  ERROR = 'error',
  ALERT = 'alert',
  SUCCESS = 'success',
  INFORMATION = 'information',
  NEUTRAL = 'neutral',
}

export enum DynamicFeedbackLifespanMs {
  SHORT = 1000,
  MEDIUM = 5000,
  LONG = 10000,
}

export interface DynamicFeedbackMessage {
  title?: string;
  body?: string | React.ReactNode;
  lifespanMs?: number;
  feedbackLevel?: FeedbackLevel;
  isCancellable?: boolean;
  icon?: IconDefinition | React.ReactNode | null;
  colour?: string;
}
