import { CurrencyCodes } from '@payaca/types/currencyTypes';

const AWIN_MERCHANT_ID = '38196';
const ENABLE_AWIN_TRACKING =
  import.meta.env.VITE_ENABLE_AWIN_TRACKING === 'true';

export const trackPurchaseWithAwin = (info: {
  amountInMajorCurrencyUnits: number;
  currency: CurrencyCodes;
  orderReference: string;
}) => {
  if (!ENABLE_AWIN_TRACKING) {
    return;
  }

  const fTotalAmount = String(info.amountInMajorCurrencyUnits);
  const sChannel = 'aw';
  const sCurrency = info.currency;
  const aAdditionalInfo: Array<string> = [];
  const sOrderReference = info.orderReference;
  const sVoucherCode = '';

  const sProtocol = location.protocol == 'http:' ? 'http' : 'https';

  let url =
    sProtocol +
    `://www.awin1.com/sread.img?tt=ns&tv=2&merchant=${AWIN_MERCHANT_ID}&amount=` +
    parseFloat(fTotalAmount).toFixed(2) +
    '&ch=' +
    sChannel +
    '&cr=' +
    sCurrency;
  for (let i = 1; i < aAdditionalInfo.length + 1; i++) {
    url += 'p' + i + '=' + aAdditionalInfo[i - 1];
  }
  url +=
    '&parts=DEFAULT:' +
    parseFloat(fTotalAmount).toFixed(2) +
    '&ref=' +
    sOrderReference +
    '&testmode=0&vc=' +
    sVoucherCode;
  const awPixel = new Image(0, 0);
  awPixel.src = url;

  (window as any).AWIN = {
    Tracking: {
      Sale: {
        amount: parseFloat(fTotalAmount).toFixed(2),
        channel: sChannel,
        currency: sCurrency,
        custom: aAdditionalInfo,
        orderRef: sOrderReference,
        parts: 'DEFAULT:' + parseFloat(fTotalAmount).toFixed(2),
        test: '0',
        voucher: sVoucherCode,
      },
    },
  };

  const awMastertag = document.createElement('script');
  awMastertag.setAttribute('defer', 'defer');
  // awMastertag.src = sProtocol + `://www.dwin1.com/${AWIN_MERCHANT_ID}.js`;
  awMastertag.src = '/awin-master-tag.js';
  awMastertag.type = 'text/javascript';
  document.getElementsByTagName('body')[0].appendChild(awMastertag);
};
