import { TaxRate } from '@payaca/types/taxRateTypes';

import { DeclareAsyncAction, UnpackAsyncAction } from '../types';

export type State = {
  isGettingTaxRates: boolean;
  isCreatingTaxRate: boolean;
  busyTaxRateIds: Array<TaxRate['id']>;
  store: Record<TaxRate['id'], TaxRate>;
};

export enum ActionType {
  GET_TAX_RATES_REQUEST = 'taxRates.getTaxRates:request',
  GET_TAX_RATES_SUCCESS = 'taxRates.getTaxRates:success',
  GET_TAX_RATES_FAILURE = 'taxRates.getTaxRates:failure',

  CREATE_TAX_RATE_REQUEST = 'taxRates.createTaxRate:request',
  CREATE_TAX_RATE_SUCCESS = 'taxRates.createTaxRate:success',
  CREATE_TAX_RATE_FAILURE = 'taxRates.createTaxRate:failure',

  UPDATE_TAX_RATE_REQUEST = 'taxRates.updateTaxRate:request',
  UPDATE_TAX_RATE_SUCCESS = 'taxRates.updateTaxRate:success',
  UPDATE_TAX_RATE_FAILURE = 'taxRates.updateTaxRate:failure',

  DELETE_TAX_RATE_REQUEST = 'taxRates.deleteTaxRate:request',
  DELETE_TAX_RATE_SUCCESS = 'taxRates.deleteTaxRate:success',
  DELETE_TAX_RATE_FAILURE = 'taxRates.deleteTaxRate:failure',

  UPDATE_TAX_RATES_ACCOUNTING_INTEGRATION_IDENTIFIERS_REQUEST = 'taxRates.updateTaxRatesAccountingIntegrationIdentifiers:request',
}

export type UpdateTaxRatesAccountingIntegrationIdentifiers = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_TAX_RATES_ACCOUNTING_INTEGRATION_IDENTIFIERS_REQUEST;
      payload: {
        accountingIntegrationIdentifiers: Record<
          TaxRate['id'],
          TaxRate['accountingIntegrationIdentifiers']
        >;
        callback?: () => void;
        onErrorCallback?: (err?: Error) => void;
      };
    };
  }
>;

export type GetTaxRatesAction = DeclareAsyncAction<
  ActionType,
  {
    request: { type: ActionType.GET_TAX_RATES_REQUEST };
    success: {
      type: ActionType.GET_TAX_RATES_SUCCESS;
      payload: Array<TaxRate>;
    };
    failure: { type: ActionType.GET_TAX_RATES_FAILURE };
  }
>;

export type CreateTaxRateAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_TAX_RATE_REQUEST;
      payload: CreateTaxRateData;
      meta: (err?: Error) => void;
    };
    success: {
      type: ActionType.CREATE_TAX_RATE_SUCCESS;
      payload: TaxRate;
    };
    failure: { type: ActionType.CREATE_TAX_RATE_FAILURE };
  }
>;

export type CreateTaxRateData = Pick<
  TaxRate,
  'name' | 'percentage' | 'isReverseCharge'
>;

export type UpdateTaxRateAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_TAX_RATE_REQUEST;
      payload: UpdateTaxRateData;
      meta: (err?: Error) => void;
    };
    success: {
      type: ActionType.UPDATE_TAX_RATE_SUCCESS;
      payload: TaxRate;
    };
    failure: { type: ActionType.UPDATE_TAX_RATE_FAILURE };
  }
>;

export type UpdateTaxRateData = Pick<TaxRate, 'id'> &
  Partial<CreateTaxRateData>;

export type DeleteTaxRateAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.DELETE_TAX_RATE_REQUEST;
      payload: DeleteTaxRateData;
      meta: (err?: Error) => void;
    };
    success: {
      type: ActionType.DELETE_TAX_RATE_SUCCESS;
      payload: TaxRate['id'];
    };
    failure: { type: ActionType.DELETE_TAX_RATE_FAILURE };
  }
>;

export type DeleteTaxRateData = TaxRate['id'];

export type AnyAction =
  | UnpackAsyncAction<GetTaxRatesAction>
  | UnpackAsyncAction<CreateTaxRateAction>
  | UnpackAsyncAction<UpdateTaxRateAction>
  | UnpackAsyncAction<DeleteTaxRateAction>;
