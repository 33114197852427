import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  ArchiveNoteInput,
  ArchiveNoteMutation as _ArchiveNoteMutation,
} from '@/gql/graphql';

const ArchiveNoteMutation = graphql(`
  mutation ArchiveNote($input: ArchiveNoteInput!) {
    archiveNote(input: $input) {
      id
    }
  }
`);

const useArchiveNote = (
  options?: UseMutationOptions<_ArchiveNoteMutation, unknown, ArchiveNoteInput>
) => {
  return useMutation({
    mutationFn: (input: ArchiveNoteInput) => {
      return gqlClient.request(ArchiveNoteMutation, { input });
    },
    ...options,
  });
};

export default useArchiveNote;
