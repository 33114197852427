import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import './DefaultAutomations.sass';
import { useHistory } from 'react-router';
import {
  AutomationActionTypes,
  AutomationEntityTypes,
  PublicHydratedDefaultAutomationDefinition,
} from '@payaca/types/automationTypes';
import DefaultAutomationCard from '@/ui/components/defaultAutomationCard/DefaultAutomationCard';
import { useDispatch } from 'react-redux';
import { getDefaultAutomationDefinitions } from '@payaca/store/automations/automationActions';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';

const DefaultAutomations: FC = () => {
  const dispatch = useDispatch();

  const [defaultAutomationDefinitions, setDefaultAutomationDefinitions] =
    useState<
      PublicHydratedDefaultAutomationDefinition<AutomationActionTypes>[]
    >();

  useEffect(() => {
    dispatch(
      getDefaultAutomationDefinitions.request({
        callback: setDefaultAutomationDefinitions,
      })
    );
  }, []);

  return (
    <div className="default-automations">
      <DefaultAutomationsSection
        isLoading={!defaultAutomationDefinitions}
        title="Projects"
        automationEntityType={AutomationEntityTypes.DEAL}
        defaultAutomationDefinitions={defaultAutomationDefinitions || []}
      />
      <DefaultAutomationsSection
        isLoading={!defaultAutomationDefinitions}
        title="Proposals"
        automationEntityType={AutomationEntityTypes.PROPOSAL}
        defaultAutomationDefinitions={defaultAutomationDefinitions || []}
      />
      <DefaultAutomationsSection
        isLoading={!defaultAutomationDefinitions}
        title="Invoices"
        automationEntityType={AutomationEntityTypes.INVOICE}
        defaultAutomationDefinitions={defaultAutomationDefinitions || []}
      />
    </div>
  );
};

const DefaultAutomationsList: FC<{
  filterFunction: (
    dad: PublicHydratedDefaultAutomationDefinition<AutomationActionTypes>
  ) => boolean;
  defaultAutomationDefinitions: PublicHydratedDefaultAutomationDefinition<AutomationActionTypes>[];
}> = ({ filterFunction, defaultAutomationDefinitions }) => {
  const dads = defaultAutomationDefinitions.filter(filterFunction);
  const history = useHistory();

  return (
    <ul className="default-automations-list">
      {dads
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((dad) => {
          return (
            <li key={dad.publicId}>
              <DefaultAutomationCard
                defaultAutomationDefinition={dad}
                onClick={() =>
                  history.push(`/automations/default/${dad.publicId}`)
                }
              />
            </li>
          );
        })}
    </ul>
  );
};

const DefaultAutomationsSection: FC<{
  title: string;
  automationEntityType: AutomationEntityTypes;
  defaultAutomationDefinitions: PublicHydratedDefaultAutomationDefinition<AutomationActionTypes>[];
  isLoading: boolean;
}> = ({
  title,
  automationEntityType,
  defaultAutomationDefinitions,
  isLoading,
}) => {
  return (
    <section className="default-automations-section">
      <h2>
        <span>{title}</span> {isLoading && <MiniLoader />}
      </h2>
      <hr />
      <DefaultAutomationsList
        filterFunction={(dad) =>
          dad.automationConfig.entityType === automationEntityType
        }
        defaultAutomationDefinitions={defaultAutomationDefinitions}
      />
    </section>
  );
};

export default DefaultAutomations;
