export interface ResendJobEmailPayload {
  preButtonEmailText: string;
  postButtonEmailText: string;
  sendMeACopy: boolean;
}

export enum JobActionType {
  DUPLICATE = 'duplicate',
  SEND = 'send',
  RESEND = 'resend',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
  INVOICE = 'invoice',
  REMOVE_ACCEPTED = 'remove-accepted',
  MARK_AS_ACCEPTED = 'mark-as-accepted',
  MARK_AS_DECLINED = 'mark-as-declined',
  MARK_AS_SENT = 'mark-as-sent',
  RECORD_PAYMENT = 'record-payment',
  RECORD_DEPOSIT_PAYMENT = 'record-deposit-payment',
  INACTIVATE_AND_EDIT = 'inactivate-and-edit',
  EDIT = 'edit',
}
