const Routes = {
  DEALS: 'deals',
  MANAGE_PIPELINE: 'pipeline',
  ARCHIVED_DEALS: 'archivedDeals',
  DEAL_PAGE: 'dealPage',
  CUSTOMERS: 'customers',
  USERS: 'users',
  USER: 'user',
  ITEM_GROUPS: 'itemGroups',
  ITEMS: 'items',
  INVOICES: 'invoices',
  NEW_QUOTE: 'newQuote',
  EDIT_QUOTE: 'editQuote',
  EDIT_NEW_QUOTE: 'editNewQuote',
  EDIT_NEW_INVOICE: 'editNewInvoice',
  NEW_INVOICE: 'newInvoice',
  EDIT_INVOICE_LEGACY: 'editInvoiceLegacy',

  EDIT_PROPOSAL_LINE_ITEM: 'editProposalLineItem',
  EDIT_PROTO_INVOICE_LINE_ITEM: 'editProtoInvoiceLineItem',
  EDIT_INVOICE_V1_LINE_ITEM: 'editInvoiceV1LineItem',

  INVOICE: 'invoice',
  EDIT_INVOICE: 'editInvoice',
  EDIT_PROTO_INVOICE: 'editProtoInvoice',

  ITEM: 'item',
  EDIT_ITEM_GROUP: 'editItemGroup',
  NEW_CUSTOMER: 'newCustomer',
  EDIT_CUSTOMER: 'editCustomer',
  CUSTOMER: 'customer',
  PERSONAL_SETTINGS: 'personalSettings',
  COMPANY_SETTINGS: 'companySettings',
  CONNECTIONS: 'connections',
  UPGRADE_ACCOUNT: 'upgradeAccount',
  ENABLE_CUSTOMER_PAYMENTS: 'enableCustomerPayments',
  VERIFY_USER: 'verifyUserAccount',
  LOGIN: 'login',
  APPLE_CALLBACK: 'appleCallback',
  XERO_CALLBACK: 'xeroCallback',
  REGISTER: 'register',
  FORGOT_PASSWORD: 'forgotPassword',
  RESET: 'reset',
  USER_INVITE: 'userInvite',
  ANALYTICS_DASHBOARD: 'analyticsDashboard',
  DASHBOARD: 'dashboard',
  RESOURCES: 'resource',
  SETUP_BUSINESS_PAGE: 'setupBusinessPage',
  SETUP_USER: 'setupUser',
  LOGIN_OAUTH: 'loginOAuth',
  DEV: 'dev',
  GRANT_ACCESS: 'grantAccess',
  SCHEDULE: 'schedule',
  SCHEDULE_DISPATCH: 'scheduleDispatch',
  PAYACA_CREDIT_PAGE: 'credit',
  NEW_EDIT_FORM: 'forms',
  CREATE_FORM: 'createForm',
  DOCUMENT_OVERVIEW_PAGE: 'documentOverviewPage',
  CHOSEN_REPORT_PAGE: 'chosenReportPage',
  AUTOMATIONS_PAGE_OLD: 'automationsPageOld',
  AUTOMATIONS_PAGE: 'automationsPage',
  CREATE_EDIT_AUTOMATION_PAGE: 'createEditAutomationPage',
  DEFAULT_AUTOMATION_PAGE: 'defaultAutomationPage',
  LISTED_TASKS: 'listedTasks',
  LISTED_SUPPLIERS: 'listedSuppliers',
  PAYACA_PAGES: 'payacaPages',
  SUPPLIER: 'supplier',
  LISTED_MATERIALS: 'listedMaterials',
  MATERIAL: 'material',
  EDIT_ISOLATED_PURCHASE_ORDER: 'editIsolatedPurchaseOrder',
  VIEW_PURCHASE_ORDER: 'viewPurchaseOrder',
  PURCHASE_ORDER_PDF_DOCUMENT: 'purchaseOrderPdfDocument',
  CHANGE_PROPOSAL: 'changeProposal',
  CHANGE_PROPOSAL_PDF_DOCUMENT: 'changeProposalPdfDocument',
  INVOICE_PDF_DOCUMENT: 'invoicePdfDocument',
  PROTO_INVOICE_PDF_DOCUMENT: 'protoInvoicePdfDocument',
  EDIT_CHANGE_PROPOSAL: 'editChangeProposal',
  CSV_UPLOADS: 'csv-uploads',
  SERVICE_PLANS: 'service-plans',
  SERVICE_PLAN: 'service-plan',
  SERVICE_PLAN_PERIOD: 'service-plan-period',
  DISCOUNT_CODES: 'discount-codes',
  CUSTOMER_REMINDERS: 'customer-reminders',
  DISPATCH: 'dispatch',
  EDIT_ITEM: 'editItem',
  CREATE_ITEM: 'createItem',
  CLEAR_CACHE: 'clearCache',
  LISTED_TEMPLATES: 'listedTemplates',
  TIMELOG_REPORTING: 'timelogReporting',

  CREATE_EDIT_ENTITY_TEMPLATE: 'createEditEntityTemplate',

  TAGS: 'tags',

  CONNECTION_APPLICATION: 'connectionApplication',
};

export { Routes };
