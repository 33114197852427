import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';

export const getDefaultIconForFeedbackLevel = (
  feedbackLevel: FeedbackLevel
) => {
  switch (feedbackLevel) {
    case FeedbackLevel.ERROR:
    case FeedbackLevel.ALERT:
      return faExclamationTriangle;
    case FeedbackLevel.INFORMATION:
      return faInfoCircle;
    case FeedbackLevel.SUCCESS:
      return faCheckCircle;
    default:
      return;
  }
};

export const getDefaultColourForFeedbackLevel = (
  feedbackLevel: FeedbackLevel
) => {
  switch (feedbackLevel) {
    case FeedbackLevel.ERROR:
      return '#d93a3a';
    case FeedbackLevel.ALERT:
      return '#fabb00';
    case FeedbackLevel.SUCCESS:
      return '#75e582';
    default:
      return;
  }
};
