import { Pipeline } from '@payaca/types/pipelineTypes';

export const DEFAULT_ACCOUNT_PIPELINE: Pipeline = {
  id: 0,
  title: 'Default',
  colour: '#99b1cc',
  stages: [
    { id: 0, title: 'New lead', index: 0 },
    { id: 0, title: 'Proposal sent', index: 1 },
    { id: 0, title: 'Proposal accepted', index: 2 },
    { id: 0, title: 'Invoiced', index: 3 },
    { id: 0, title: 'Complete - fully paid', index: 4 },
    { id: 0, title: 'Expired / Declined', index: 5 },
  ],
};
