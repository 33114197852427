import { action } from 'typesafe-actions';

import { Supplier } from '@payaca/types/supplierTypes';

import { ActionType, PersistSupplierRequestData } from './suppliersTypes';

export const requestPersistSupplier = (
  persistSupplierRequestData: PersistSupplierRequestData,
  callback?: (supplierId: number) => void
) =>
  action(ActionType.REQUEST_PERSIST_SUPPLIER, {
    persistSupplierRequestData,
    callback,
  });

export const persistSupplierSuccess = () =>
  action(ActionType.PERSIST_SUPPLIER_SUCCESS);

export const persistSupplierFailure = (error: Error) =>
  action(ActionType.PERSIST_SUPPLIER_FAILURE, null, null, error);

export const requestGetSuppliersForMaterial = (materialId: number) =>
  action(ActionType.REQUEST_GET_SUPPLIERS_FOR_MATERIAL, {
    materialId,
  });

export const getSuppliersForMaterialSuccess = (suppliers: Array<Supplier>) =>
  action(ActionType.GET_SUPPLIERS_FOR_MATERIAL_SUCCESS, suppliers);

export const requestGetSupplier = (supplierId: number) =>
  action(ActionType.REQUEST_GET_SUPPLIER, {
    supplierId,
  });

export const getSupplierSuccess = (supplierId: number, supplier: Supplier) =>
  action(ActionType.GET_SUPPLIER_SUCCESS, {
    supplierId,
    supplier,
  });

export const getSupplierFailure = (supplierId: number, error: Error) =>
  action(
    ActionType.GET_SUPPLIER_FAILURE,
    {
      supplierId,
    },
    null,
    error
  );

export const requestArchiveSuppliers = (
  supplierIds: number[],
  onArchiveSuccess?: () => void
) =>
  action(ActionType.REQUEST_ARCHIVE_SUPPLIERS, {
    supplierIds,
    onArchiveSuccess,
  });

export const archiveSuppliersSuccess = () =>
  action(ActionType.ARCHIVE_SUPPLIERS_SUCCESS);

export const archiveSuppliersFailure = (error: Error) =>
  action(ActionType.ARCHIVE_SUPPLIERS_FAILURE, null, null, error);

export const clearArchiveSuppliers = () =>
  action(ActionType.CLEAR_ARCHIVE_SUPPLIERS);

export const clearSuppliers = () => action(ActionType.CLEAR_SUPPLIERS);
