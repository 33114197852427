import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import supplierKeys from './keyFactory';
import { GetSuppliersInput, PaginationInput } from '@/gql/graphql';

const GetSuppliers = graphql(`
  query SearchSuppliers(
    $pagination: PaginationInput!
    $input: GetSuppliersInput!
  ) {
    suppliers(input: $input, pagination: $pagination) {
      items {
        id
        name
        accountConnectionMetadata
      }
      totalCount
    }
  }
`);

const useGetSuppliers = (
  input: GetSuppliersInput,
  pagination?: PaginationInput,
  enabled: boolean = true
) => {
  const { data, ...rest } = useQuery({
    queryKey: supplierKeys.search(input, pagination || PAGINATION_ARG_DEFAULTS),
    queryFn: () => {
      return gqlClient.request(GetSuppliers, {
        input,
        pagination: pagination || PAGINATION_ARG_DEFAULTS,
      });
    },
    enabled,
  });

  return { suppliers: data?.suppliers, data, ...rest };
};

export default useGetSuppliers;
