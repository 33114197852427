import React, { FunctionComponent, useMemo } from 'react';

import { Job } from '@payaca/types/jobTypesV2';
import { JobContent } from '@payaca/types/jobContentTypes';
import { FileDownloadTypes } from '@payaca/types/fileDownloadTypes';
import { BusinessAttachment } from '@payaca/types/accountTypes';
import JobDocumentAttachmentTable from '@payaca/components/jobDocument/JobDocumentAttachmentTable';
import DocumentCard from '@payaca/components/clientDocument/DocumentCard';

import { isQuoteOrEstimate } from '@payaca/helpers/jobStatusHelper';
import JobDocument from '@payaca/components/jobDocument/JobDocument';

import {
  getJob,
  getJobContent,
  getJobAttachmentsByJobId,
  getJobDownloadByJobId,
  getJobLineItemAttachmentsByJobContentId,
  getJobLineItemGroupsByJobContentId,
  getJobLineItemsByJobContentId,
  getJobPaymentsByJobId,
  getDeal,
  getDealByJobId,
} from '@/utils/stateAccessors';

import './JobOverview.sass';
import { useSelector } from '@/api/state';
import { Address } from '@payaca/types/locationTypes';
import { getDocumentSenderContextFromAccount } from '@payaca/helpers/documentSenderContextHelper';
import moment from 'moment-timezone';
import PlButton from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import ClientDocumentLoading from '@payaca/components/clientDocument/ClientDocumentLoading';

type Props = {
  jobId: number;
  stickyTopClassName?: string;
};

const JobOverview: FunctionComponent<Props> = ({
  jobId,
  stickyTopClassName,
}: Props): JSX.Element | null => {
  const businessAttachments: BusinessAttachment[] = useSelector(
    (state: any) => state.users.businessAttachments
  );

  const deal = useSelector((state) => {
    return getDealByJobId(state, jobId);
  });

  const job: Job | undefined = useSelector((state) => {
    return getJob(state, jobId);
  });

  const jobContent: JobContent | undefined = useSelector((state) => {
    if (!job) return;
    return getJobContent(state, job.jobContentId);
  });

  const account = useSelector(
    (state: any) => state.users?.myProfile?.accounts[0]
  );

  const customer = useSelector((state) => {
    return state.customer.currentCustomer;
  });

  const jobAttachments = useSelector((state) =>
    getJobAttachmentsByJobId(state, jobId)
  );
  const jobLineItemGroups = useSelector((state) => {
    if (!job) return;
    return getJobLineItemGroupsByJobContentId(state, job.jobContentId);
  });
  const jobLineItems = useSelector((state) => {
    if (!job) return;
    return getJobLineItemsByJobContentId(state, job.jobContentId);
  });
  const jobLineItemAttachments = useSelector((state) => {
    if (!job) return;
    return getJobLineItemAttachmentsByJobContentId(state, job.jobContentId);
  });
  const jobPayments = useSelector((state) =>
    getJobPaymentsByJobId(state, jobId)
  );

  const acceptedSignatureImage = useSelector((state) =>
    getJobDownloadByJobId(state, jobId, FileDownloadTypes.QUOTE_SIGNATURE_IMAGE)
  );

  const allJobAttachments = useMemo(() => {
    let attachments: any[] = [];
    if (jobAttachments && jobAttachments.length) {
      attachments = [...jobAttachments];
    }
    if (businessAttachments && businessAttachments.length) {
      attachments = [...attachments, ...businessAttachments];
    }
    return attachments;
  }, [businessAttachments, jobAttachments]);

  const j = useMemo(() => {
    if (!job) return;

    const sentAt = job.sentAt ? job.sentAt : new Date();
    const validUntil =
      job?.validUntil ||
      moment(sentAt).add(job.dueInOrValidForDays, 'days').toDate();

    return {
      ...job,
      sentAt,
      validUntil,
    };
  }, []);

  if (!j || !account || !customer || !jobContent || !deal)
    return <ClientDocumentLoading />;

  return (
    <div className="job-overview-wrapper">
      <JobDocument
        stickyTopClassName={stickyTopClassName}
        hasMaxWidth={false}
        isEditable={false}
        documentSenderContext={getDocumentSenderContextFromAccount(account)}
        paymentMethodConfig={{
          allowStripePayment: j.showStripePaymentOption,
          allowBankTransferPayment: j.showBacsPaymentOption,
          bankTransferPaymentSettings: {
            accountNumber: account.accountNumberRaw || '',
            accountName: account.accountNameRaw || '',
            sortCode: account.sortCodeRaw || '',
          },
        }}
        job={j}
        paymentSchedule={deal.paymentSchedule}
        customer={customer}
        siteAddresses={deal.siteAddresses || []}
        jobContent={jobContent}
        jobLineItems={jobLineItems || []}
        jobLineItemAttachments={jobLineItemAttachments || []}
        jobLineItemGroups={jobLineItemGroups || []}
        hideItemPrices={deal?.hideItemPrices}
        hideTaxBreakdown={deal?.hideVatBreakdown}
        jobPayments={jobPayments || []}
        acceptedSignatureImageData={acceptedSignatureImage}
        jobAttachments={allJobAttachments}
        dealVersion={deal.version}
        callToAction={
          !j.acceptedAt && !j.declinedAt ? (
            <div className="print:hidden">
              <PlButton
                colour={EBtnColour.JobBrand}
                disabled
                className="mt-4 w-full print:hidden"
              >
                Sign to accept
              </PlButton>
              <PlButton
                colour={EBtnColour.JobBrand}
                variant={EBtnVariant.Link}
                disabled
                className="mt-2 w-full print:hidden"
              >
                Decline
              </PlButton>
            </div>
          ) : undefined
        }
      />
    </div>
  );
};

export default JobOverview;
