import clsx from 'clsx';
import { FC } from 'react';

type LabelValuePairProps = {
  label: string | JSX.Element;
  value: string;
  className?: string;
};
const LabelValuePair: FC<LabelValuePairProps> = (props) => {
  const { label, value, className } = props;
  return (
    <div className={clsx('flex items-center gap-x-4', className)}>
      {typeof label === 'string' ? (
        <span className="grow">{label}:</span>
      ) : (
        label
      )}
      <span className="font-bold min-w-[75px] text-right">{value}</span>
    </div>
  );
};

export default LabelValuePair;
