import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Deal } from '@payaca/types/dealTypes';
import { IntegratedEmail } from '@payaca/types/integratedEmails';
import {
  ReplyToEmailMutationVariables,
  SendEmailMutationVariables,
} from '../../../gql/graphql';
import { Nullish } from '@payaca/utilities/types';
import { actions as usersActions } from '../../../api/users';

import Button from '@payaca/components/plButton/Button';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import Modal, { IProps as ModalProps } from '@payaca/components/plModal/Modal';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Select, { SelectOption } from '@payaca/components/plSelect/Select';
import RichTextarea from '@payaca/components/plRichTextarea/RichTextarea';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';

import { useCustomerForDeal } from '@payaca/store/hooks/appState';
import useGetMe from '../../../api/queries/me/useGetMe';

import {
  getArrayMustNotBeEmptyFieldValidator,
  getIsRequiredFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';

import { graphql } from '../../../gql';
import { gqlClient } from '../../../api/graphql-client';
import useGetIntegratedEmailLazy from '../../../api/queries/useGetIntegratedEmail';
import { useAccountUsers } from '../../../utils/storeHooks';
import { useDispatch } from 'react-redux';
import projectKeys from '@/api/queries/project/keyFactory';

const copyOptionGroups = [
  { id: 'customer', label: 'Customer contacts' },
  { id: 'user', label: 'Users' },
];

const SendEmailMutation = graphql(`
  mutation SendEmail($email: EmailParamInput!, $entity: EmailEntityInput!) {
    sendEmail(email: $email, entity: $entity)
  }
`);
const ReplyToEmailMutation = graphql(`
  mutation ReplyToEmail(
    $integratedEmailId: Int!
    $email: EmailParamInput!
    $entity: EmailEntityInput!
  ) {
    replyToEmail(
      integratedEmailId: $integratedEmailId
      email: $email
      entity: $entity
    )
  }
`);

export interface SendIntegratedEmailProps {
  dealId: Deal['id'];
  integratedEmailId?: IntegratedEmail['id']; // determines if reply or new email
}

type SendEmailData = {
  to: string[];
  subject: string;
  message: string;
  cc: string[];
  bcc: string[];
};

interface Props extends Omit<ModalProps, 'title'>, SendIntegratedEmailProps {}
const SendIntegratedEmailModal = (props: Props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { dealId, integratedEmailId, ...rest } = props;
  const { data, refetch } = useGetMe();
  const {
    data: integratedEmailData,
    refetch: fetchIntegratedEmail,
    isFetching,
  } = useGetIntegratedEmailLazy(`${integratedEmailId}`);
  const userIntegrations = useMemo(() => data?.me.integrations, [data]);
  const customer = useCustomerForDeal(dealId);
  const accountUsers = useAccountUsers();

  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [sendEmailError, setSendEmailError] = useState<false | string>(false);
  const [
    showEmailConnectionInactiveError,
    setShowEmailConnectionInactiveError,
  ] = useState<false | 'connect' | 'reconnect'>(false);

  const isLoading = useMemo(
    () => integratedEmailId && isFetching,
    [integratedEmailId, isFetching]
  );

  const { mutateAsync: mutateSendEmail, isLoading: isSendingEmail } =
    useMutation({
      mutationFn: (variables: SendEmailMutationVariables) => {
        return gqlClient.request(SendEmailMutation, variables);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(projectKeys.activityFeed(dealId));
      },
    });
  const { mutateAsync: mutateReplyToEmail, isLoading: isReplyingToEmail } =
    useMutation({
      mutationFn: (variables: ReplyToEmailMutationVariables) => {
        return gqlClient.request(ReplyToEmailMutation, variables);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(projectKeys.activityFeed(dealId));
      },
    });

  const hasEmailSignature = useMemo(() => !!data?.me.emailSignature, [data]);

  useEffect(() => {
    setShowEmailConnectionInactiveError(
      userIntegrations?.google
        ? userIntegrations.google?.isActive
          ? false
          : 'reconnect'
        : 'connect'
    );
  }, [userIntegrations?.google?.isActive, rest.isOpen]);

  const customerContactOptions = useMemo(() => {
    return (customer?.contacts || [])
      .map((contact) => {
        if (contact.emailAddress) {
          return {
            label: `${contact.name} (${contact.emailAddress})`,
            value: contact.emailAddress,
          };
        }
      })
      .filter((contact) => !!contact) as SelectOption<string>[];
  }, [customer?.contacts]);

  const copyOptions = useMemo(() => {
    const users = accountUsers
      .filter(
        (u) => data?.me.user && u.id !== +data?.me.user.id && !u.deactivatedAt
      )
      .map((u) => ({ label: u.name, value: u.email, groupId: 'user' }));

    const customerContacts = customerContactOptions.map((c) => ({
      ...c,
      groupId: 'customer',
    }));

    return [...customerContacts, ...users];
  }, [accountUsers, customerContactOptions, data?.me.user]);

  useEffect(() => {
    if (!rest.isOpen) {
      // closing - reset state
      setShowBcc(false);
      setShowCc(false);
      setSendEmailError(false);
    } else {
      dispatch(usersActions.getAccountUsers());
      if (integratedEmailId) {
        void fetchIntegratedEmail();
      }
    }
  }, [rest.isOpen, integratedEmailId]);

  const sendEmail = async (data: SendEmailData) => {
    const email = {
      emailSubject: data.subject,
      emailBody: data.message,
      sendToEmails: data.to,
      sendCcEmails: data.cc,
      sendBccEmails: data.bcc,
    };

    try {
      if (integratedEmailId) {
        await mutateReplyToEmail({
          integratedEmailId,
          email,
          entity: { entityType: 'deal', entityId: `${dealId}` },
        });
      } else {
        await mutateSendEmail({
          entity: { entityType: 'deal', entityId: `${dealId}` },
          email,
        });
      }
      rest?.onClose?.();
    } catch (err) {
      setSendEmailError('Sorry, there was an error sending your email.');
      // get profile in case userIntegrations has updated with inactive state on email connection
      await refetch();
    }
  };

  const fieldValidators = useMemo(() => {
    return {
      to: [
        getArrayMustNotBeEmptyFieldValidator({
          customErrorMessage: 'You must have at least one recipient',
        }),
      ],
      subject: [getIsRequiredFieldValidator()],
      cc: [],
      bcc: [],
      message: [getIsRequiredFieldValidator()],
    };
  }, []);

  const validEmails = useCallback(
    (emails?: Nullish<string[]>) => {
      // validate emails to ensure they are in the customer contacts
      return (emails || []).filter((em) =>
        customerContactOptions.find((contact) => contact.value === em)
      );
    },
    [customerContactOptions]
  );

  const initialFormState = useMemo(() => {
    const integratedEmail = integratedEmailData?.integratedEmail;
    return {
      to: validEmails(integratedEmail?.from), // replying to the "from" address of the email
      subject: integratedEmail?.subject || '',
      cc: validEmails(integratedEmail?.cc),
      bcc: validEmails(integratedEmail?.bcc) || [],
      message: '',
    };
  }, [rest.isOpen, integratedEmailData, validEmails]);

  useEffect(() => {
    if (initialFormState?.cc?.length) {
      setShowCc(true);
    }
    if (initialFormState?.bcc?.length) {
      setShowBcc(true);
    }
  }, [initialFormState]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <Modal.Body className="flex flex-col space-y-4">
            <Field name="to">
              <div className="flex justify-between">
                <Field.Label>To</Field.Label>
                <div className="space-x-4">
                  {!showCc && (
                    <Button
                      variant={EBtnVariant.LinkInline}
                      onClick={() => setShowCc(true)}
                    >
                      Cc
                    </Button>
                  )}
                  {!showBcc && (
                    <Button
                      variant={EBtnVariant.LinkInline}
                      onClick={() => setShowBcc(true)}
                    >
                      Bcc
                    </Button>
                  )}
                </div>
              </div>
              <Select
                options={customerContactOptions}
                multiple
                value={formState.to}
                onChange={(value) => onFieldChange({ to: value })}
              />
            </Field>

            {/* cc/bcc */}
            {showCc && (
              <Field name="cc">
                <Field.Label>Cc</Field.Label>
                <Select
                  options={copyOptions}
                  multiple
                  optionGroups={copyOptionGroups}
                  value={formState.cc}
                  onChange={(value) => onFieldChange({ cc: value })}
                />
              </Field>
            )}
            {showBcc && (
              <Field name="bcc">
                <Field.Label>Bcc</Field.Label>
                <Select
                  options={copyOptions}
                  multiple
                  optionGroups={copyOptionGroups}
                  value={formState.bcc}
                  onChange={(value) => onFieldChange({ bcc: value })}
                />
              </Field>
            )}

            <Field name="subject">
              <Field.Label>Subject</Field.Label>
              <Input
                value={formState.subject}
                onChange={(value) => onFieldChange({ subject: value })}
              />
            </Field>

            <Field name="message">
              <Field.Label>Message</Field.Label>
              <RichTextarea
                value={formState.message}
                onChange={(value) => onFieldChange({ message: value })}
                isHtmlOutput
                className="min-h-[150px]"
              />
              {hasEmailSignature && (
                <Field.Helper>
                  Your email signature will be automatically added to the end of
                  your message.
                </Field.Helper>
              )}
            </Field>
            {sendEmailError && <ErrorMessage message={sendEmailError} />}
            {showEmailConnectionInactiveError && (
              <ErrorMessage
                message={`It looks like you need to ${showEmailConnectionInactiveError} your email account - head to your Profile page to ${showEmailConnectionInactiveError}.`}
              />
            )}
          </Modal.Body>

          <Modal.Footer>
            <Modal.Footer.Actions>
              <Button
                className="mt-2 self-start"
                onClick={() => {
                  void sendEmail(formState as SendEmailData);
                }}
                disabled={!isValid || !!showEmailConnectionInactiveError}
                isProcessing={isSendingEmail || isReplyingToEmail}
              >
                Send
              </Button>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </>
      );
    },
    [
      customerContactOptions,
      showCc,
      showBcc,
      sendEmailError,
      showEmailConnectionInactiveError,
      isSendingEmail,
      isReplyingToEmail,
      hasEmailSignature,
    ]
  );

  // TODO: replace MiniLoader with a skeleton loader
  return (
    <Modal
      {...rest}
      title={integratedEmailId ? 'Reply to email' : 'Send email'}
      size="md"
      disableBackdropClick
    >
      {isLoading ? (
        <Modal.Body>
          <div className="flex justify-center">
            <MiniLoader />
          </div>
        </Modal.Body>
      ) : (
        <ValidatedForm
          renderFormContents={renderFormContents}
          fieldValidators={fieldValidators}
          initialFormState={initialFormState}
        />
      )}
    </Modal>
  );
};

export default SendIntegratedEmailModal;
