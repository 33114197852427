/**
 * Overview tab of service plans screen - displaying the list of service plan account has set up
 */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import ListedServicePlan from '../listedServicePlan/ListedServicePlan';
import ServicePlanInviteCustomerModal from '../servicePlanInviteCustomerModal/ServicePlanInviteCustomerModal';

import { useSelector } from '@payaca/store/hooks/appState';

import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';
import { ListedServicePlan as ListedServicePlanType } from '@payaca/types/service-plans';

import './ServicePlansListedPlans.sass';
import { useAccount } from '@/utils/storeHooks';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { ServicePlanPermissions } from '@payaca/permissions/service-plans/service-plans.permissions';
import Button from '@payaca/components/plButton/Button';

const ServicePlansListedPlans = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [
    servicePlanInviteCustomerModalOpen,
    setServicePlanInviteCustomerModalOpen,
  ] = useState<string | false>(false);

  const isGettingListedServicePlans = useSelector(
    (state) => state.servicePlans.isGettingListedServicePlans
  );
  const listedServicePlans = useSelector(
    (state) => state.servicePlans.listedServicePlans
  );

  useEffect(() => {
    dispatch(servicePlansActions.getListedServicePlans.request({}));
  }, []);

  const account = useAccount();

  return (
    <div className="service-plans-listed-plans">
      {account.connectedToStripe && (
        <PermissionGuard
          renderIfHasPermissions={[ServicePlanPermissions.PERSIST_SERVICE_PLAN]}
        >
          <Button
            onClick={() => history.push('/service-plans/service-plan/new')}
          >
            Create Plan
          </Button>
        </PermissionGuard>
      )}
      {isGettingListedServicePlans ? (
        <div className="loader-container">
          <MiniLoader />
        </div>
      ) : listedServicePlans?.length ? (
        <ResponsiveViewWrapper
          className={`listed-service-plans-container`}
          downBreakpointXs={600}
          downBreakpointSm={900}
          upBreakpointLg={1400}
        >
          {listedServicePlans?.map(
            (listedServicePlan: ListedServicePlanType, i: number) => {
              return (
                <ListedServicePlan
                  listedServicePlan={listedServicePlan}
                  openInviteCustomerModal={() =>
                    setServicePlanInviteCustomerModalOpen(
                      listedServicePlan.publicId
                    )
                  }
                  key={`listed-service-plan-${i}`}
                />
              );
            }
          )}
        </ResponsiveViewWrapper>
      ) : (
        <div className="no-results">
          <span>No Service Plans found</span>
        </div>
      )}
      <ServicePlanInviteCustomerModal
        isOpen={!!servicePlanInviteCustomerModalOpen}
        onClose={() => setServicePlanInviteCustomerModalOpen(false)}
        servicePlanId={servicePlanInviteCustomerModalOpen || undefined}
      />
    </div>
  );
};

export default ServicePlansListedPlans;
