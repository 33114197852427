import { Supplier } from './supplierTypes';
import { ListViewPage } from './listViewTypes';

export interface ListedSupplier extends Supplier {}

export enum SortBy {
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  WEBSITE = 'website',
}

export interface ListedSupplierListViewPage
  extends ListViewPage<ListedSupplier> {}
