import { Reducer } from 'redux';

import { ActionType, State } from './materialsTypes';

import { ActionType as MaterialsListActionType } from '../materialsList/materialsListTypes';
import { ActionType as LineItemsV2ActionType } from '../lineItemsV2/lineItemsTypes';
import { mergeFetchedEntities } from '../utils';

export const initialState: State = {
  isPersistingMaterial: false,
  isPersistingSupplierMaterial: false,
  isPersistingLineItemMaterial: false,
  isDeletingSupplierMaterial: false,
  isDeletingLineItemMaterial: false,
  isFetchingMaterialCategories: false,
  isFetchingMaterialSuppliers: false,

  materialCategories: [],
  materialSuppliers: [],
  isArchivingMaterials: false,
  areMaterialsArchivedSuccessfully: undefined,

  materials: {},
  supplierMaterials: {},
  lineItemMaterials: {},
};

const materialsReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_MATERIALCATEGORIES:
      return {
        ...state,
        isFetchingMaterialCategories: true,
      };
    case ActionType.GET_MATERIALCATEGORIES_FAILURE:
      return {
        ...state,
        isFetchingMaterialCategories: false,
      };
    case ActionType.GET_MATERIALCATEGORIES_SUCCESS:
      return {
        ...state,
        materialCategories: action.payload.materialCategories,
        isFetchingMaterialCategories: false,
      };
    case ActionType.REQUEST_GET_MATERIALSUPPLIERS:
      return {
        ...state,
        isFetchingMaterialSuppliers: true,
      };
    case ActionType.GET_MATERIALSUPPLIERS_FAILURE:
      return {
        ...state,
        isFetchingMaterialSuppliers: false,
      };
    case ActionType.GET_MATERIALSUPPLIERS_SUCCESS:
      return {
        ...state,
        materialSuppliers: action.payload.materialSuppliers,
        isFetchingMaterialSuppliers: false,
      };
    case ActionType.REQUEST_PERSIST_MATERIAL:
      return {
        ...state,
        isPersistingMaterial: true,
      };
    case ActionType.PERSIST_MATERIAL_FAILURE:
    case ActionType.PERSIST_MATERIAL_SUCCESS:
      return {
        ...state,
        isPersistingMaterial: false,
      };

    case ActionType.REQUEST_DELETE_SUPPLIERMATERIAL:
      return {
        ...state,
        isDeletingSupplierMaterial: true,
      };
    case ActionType.DELETE_SUPPLIERMATERIAL_FAILURE:
    case ActionType.DELETE_SUPPLIERMATERIAL_SUCCESS:
      return {
        ...state,
        isDeletingSupplierMaterial: false,
      };

    case ActionType.REQUEST_PERSIST_SUPPLIERMATERIAL:
      return {
        ...state,
        isPersistingSupplierMaterial: true,
      };
    case ActionType.PERSIST_SUPPLIERMATERIAL_FAILURE:
    case ActionType.PERSIST_SUPPLIERMATERIAL_SUCCESS:
      return {
        ...state,
        isPersistingSupplierMaterial: false,
      };

    case ActionType.REQUEST_GET_MATERIAL: {
      const fetchedEntity =
        state.materials && state.materials[action.payload.materialId];

      return {
        ...state,
        materials: {
          ...state.materials,
          [action.payload.materialId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_MATERIAL_FAILURE: {
      const fetchedEntity =
        state.materials && state.materials[action.payload.materialId];

      return {
        ...state,
        materials: {
          ...state.materials,
          [action.payload.materialId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.GET_MATERIAL_SUCCESS: {
      const fetchedEntity =
        state.materials && state.materials[action.payload.materialId];

      return {
        ...state,
        materials: {
          ...state.materials,
          [action.payload.materialId]: {
            ...fetchedEntity,
            entity: action.payload.material,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.REQUEST_GET_SUPPLIERMATERIAL: {
      const fetchedEntity =
        state.supplierMaterials &&
        state.supplierMaterials[action.payload.supplierMaterialId];

      return {
        ...state,
        supplierMaterials: {
          ...state.supplierMaterials,
          [action.payload.supplierMaterialId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_SUPPLIERMATERIAL_FAILURE: {
      const fetchedEntity =
        state.supplierMaterials &&
        state.supplierMaterials[action.payload.supplierMaterialId];

      return {
        ...state,
        supplierMaterials: {
          ...state.supplierMaterials,
          [action.payload.supplierMaterialId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.GET_SUPPLIERMATERIAL_SUCCESS: {
      const fetchedEntity =
        state.supplierMaterials &&
        state.supplierMaterials[action.payload.supplierMaterialId];

      return {
        ...state,
        supplierMaterials: {
          ...state.supplierMaterials,
          [action.payload.supplierMaterialId]: {
            ...fetchedEntity,
            entity: action.payload.supplierMaterial,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.REQUEST_GET_LINEITEMMATERIAL: {
      const fetchedEntity =
        state.lineItemMaterials &&
        state.lineItemMaterials[action.payload.lineItemMaterialId];

      return {
        ...state,
        lineItemMaterials: {
          ...state.lineItemMaterials,
          [action.payload.lineItemMaterialId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_LINEITEMMATERIAL_FAILURE: {
      const fetchedEntity =
        state.lineItemMaterials &&
        state.lineItemMaterials[action.payload.lineItemMaterialId];

      return {
        ...state,
        lineItemMaterials: {
          ...state.lineItemMaterials,
          [action.payload.lineItemMaterialId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.GET_LINEITEMMATERIAL_SUCCESS: {
      const fetchedEntity =
        state.lineItemMaterials &&
        state.lineItemMaterials[action.payload.lineItemMaterialId];

      return {
        ...state,
        lineItemMaterials: {
          ...state.lineItemMaterials,
          [action.payload.lineItemMaterialId]: {
            ...fetchedEntity,
            entity: action.payload.lineItemMaterial,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.REQUEST_ARCHIVE_MATERIALS:
      return {
        ...state,
        isArchivingMaterials: true,
        areMaterialsArchivedSuccessfully: undefined,
      };
    case ActionType.ARCHIVE_MATERIALS_SUCCESS:
      return {
        ...state,
        isArchivingMaterials: false,
        areMaterialsArchivedSuccessfully: true,
      };
    case ActionType.ARCHIVE_MATERIALS_FAILURE:
      return {
        ...state,
        isArchivingMaterials: false,
        areMaterialsArchivedSuccessfully: false,
      };

    case ActionType.CLEAR_ARCHIVE_MATERIALS:
      return {
        ...state,
        isArchivingMaterials: false,
        areMaterialsArchivedSuccessfully: undefined,
      };

    case ActionType.CLEAR_MATERIALS:
      return {
        ...state,
        materials: {},
      };
    case ActionType.CLEAR_MATERIALCATEGORIES:
      return {
        ...state,
        materialCategories: [],
      };
    case ActionType.CLEAR_MATERIALSUPPLIERS:
      return {
        ...state,
        materialSuppliers: [],
      };

    case ActionType.CLEAR_SUPPLIERMATERIALS:
      return {
        ...state,
        supplierMaterials: {},
      };

    case ActionType.GET_SUPPLIERMATERIALS_FOR_MATERIAL_SUCCESS:
      return {
        ...state,
        supplierMaterials: mergeFetchedEntities({
          cache: state.supplierMaterials,
          values: action.payload,
        }),
      };

    case MaterialsListActionType.GET_MATERIALS_LIST_WITH_RELATED_ENTITIES_SUCCESS: {
      const now = new Date();
      return {
        ...state,
        materials: mergeFetchedEntities({
          cache: state.materials,
          values: action.payload.materials,
          now,
        }),
        supplierMaterials: mergeFetchedEntities({
          cache: state.supplierMaterials,
          values: action.payload.supplierMaterials,
          now,
        }),
      };
    }

    case MaterialsListActionType.GET_MATERIALS_LIST_MATERIAL_WITH_RELATED_ENTITIES_SUCCESS: {
      const now = new Date();
      return {
        ...state,
        materials: mergeFetchedEntities({
          cache: state.materials,
          values: [action.payload.material],
          now,
        }),
        supplierMaterials: mergeFetchedEntities({
          cache: state.supplierMaterials,
          values: action.payload.supplierMaterials,
          now,
        }),
      };
    }

    case LineItemsV2ActionType.GET_LINE_ITEM_WITH_RELATED_ENTITIES_SUCCESS: {
      const now = new Date();
      return {
        ...state,
        materials: mergeFetchedEntities({
          cache: state.materials,
          values: action.payload.materials,
          now,
        }),
        supplierMaterials: mergeFetchedEntities({
          cache: state.supplierMaterials,
          values: action.payload.supplierMaterials,
          now,
        }),
        lineItemMaterials: mergeFetchedEntities({
          cache: state.lineItemMaterials,
          values: action.payload.lineItemMaterials,
          now,
        }),
      };
    }

    default:
      return state;
  }
};

export default materialsReducer;
