import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { graphql } from '@/gql';
import { ArchiveProjectMutation as _ArchiveProjectMutation } from '@/gql/graphql';
import { gqlClient } from '@/api/graphql-client';

const ArchiveProjectMutation = graphql(`
  mutation ArchiveProject($input: ProjectIdInput!) {
    archiveProject(input: $input) {
      id
    }
  }
`);

const useArchiveProject = (
  options?: UseMutationOptions<_ArchiveProjectMutation, unknown, string>
) => {
  return useMutation({
    mutationFn: (projectId: string) => {
      return gqlClient.request(ArchiveProjectMutation, {
        input: { projectId },
      });
    },
    ...options,
  });
};

export default useArchiveProject;
