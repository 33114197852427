import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  UpdateContactInput,
  UpdateContactMutation as _UpdateContactMutation,
} from '@/gql/graphql';

const UpdateContact = graphql(`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      id
      description
      email
      name
      phone
    }
  }
`);

const useUpdateContact = (
  options?: UseMutationOptions<
    _UpdateContactMutation,
    unknown,
    UpdateContactInput
  >
) => {
  return useMutation({
    mutationFn: (update: UpdateContactInput) => {
      return gqlClient.request(UpdateContact, { input: update });
    },
    ...options,
  });
};

export default useUpdateContact;
