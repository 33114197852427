import { ChangeProposalPdfDocumentData } from '@payaca/types/changeProposalPdfDocumentTypes';
import { InvoicePdfDocumentData } from '@payaca/types/invoicePdfDocumentTypes';

import { DeclareAsyncAction } from '../types';

export enum ActionType {
  GET_PURCHASE_ORDER_DATA_REQUEST = 'pdfDocumentData.getPurchaseOrderData:request',
  GET_CHANGE_PROPOSAL_DATA_REQUEST = 'pdfDocumentData.getChangeProposalData:request',
  GET_INVOICE_DATA_REQUEST = 'pdfDocumentData.getInvoiceData:request',
  GET_PROTO_INVOICE_DATA_REQUEST = 'pdfDocumentData.getProtoInvoiceData:request',
}

export interface SagaConfig {
  apiBaseurl: string;
}

export type GetChangeProposalDataAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_CHANGE_PROPOSAL_DATA_REQUEST;
      payload: {
        previewToken: string;
        callback?: (pdfDocumentData: ChangeProposalPdfDocumentData) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type GetInvoiceDataAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_INVOICE_DATA_REQUEST;
      payload: {
        invoiceId: number;
        previewToken: string;
        callback?: (invoiceData: InvoicePdfDocumentData) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type GetProtoInvoiceDataAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_PROTO_INVOICE_DATA_REQUEST;
      payload: {
        protoInvoiceId: number;
        previewToken: string;
        callback?: (invoiceData: InvoicePdfDocumentData) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;
