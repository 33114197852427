import React, { FC } from 'react';

import { CompanySettings, CompanySettingsSaveButton } from '@/ui/components';
import Modal from '@payaca/components/modal/Modal';

import './BusinessSettingsModal.css';

type Props = { open: boolean; onClose: () => void };
const BusinessSettingsModal: FC<Props> = ({ open, onClose }: Props) => {
  return (
    <Modal
      title="My Company"
      isOpen={open}
      onClose={() => onClose()}
      size="lg"
      className="business-settings"
      actions={
        <CompanySettingsSaveButton
          onSaveSuccess={() => onClose()}
          isModal={true}
          buttonTitle="Save and close"
        />
      }
    >
      <CompanySettings />
    </Modal>
  );
};

export default BusinessSettingsModal;
