import {
  IndustryTypes,
  NumberOfEmployees,
} from '@payaca/types/companyInformationTypes';

export const INDUSTRY_OPTIONS = [
  { label: 'Appliance Repair', value: IndustryTypes.APPLIANCE_REPAIR },
  { label: 'Carpentry', value: IndustryTypes.CARPENTRY },
  {
    label: 'Commercial Construction',
    value: IndustryTypes.COMMERCIAL_CONSTRUCTION,
  },
  { label: 'Electrical', value: IndustryTypes.ELECTRICAL },
  { label: 'Fencing & Decking', value: IndustryTypes.FENCING_AND_DECKING },
  { label: 'Flooring', value: IndustryTypes.FLOORING },
  { label: 'Glazing', value: IndustryTypes.GLAZING },
  { label: 'Landscaping & Lawns', value: IndustryTypes.LANDSCAPING_AND_LAWNS },
  { label: 'Locksmith Services', value: IndustryTypes.LOCKSMITH_SERVICES },
  {
    label: 'Painting & Decorating',
    value: IndustryTypes.PAINTING_AND_DECORATING,
  },
  { label: 'Pest Control', value: IndustryTypes.PEST_CONTROL },
  { label: 'Pool & Spa Services', value: IndustryTypes.POOL_AND_SPA_SERVICES },
  { label: 'Plastering', value: IndustryTypes.PLASTERING },
  { label: 'Plumbing', value: IndustryTypes.PLUMBING },
  { label: 'Heating', value: IndustryTypes.HEATING },
  { label: 'Property Maintenance', value: IndustryTypes.PROPERTY_MAINTENANCE },
  {
    label: 'Residential Renovations',
    value: IndustryTypes.RESIDENTIAL_RENOVATIONS,
  },
  { label: 'Roofing', value: IndustryTypes.ROOFING },
  { label: 'Security Services', value: IndustryTypes.SECURITY_SERVICES },
  { label: 'Tiling', value: IndustryTypes.TILING },
  { label: 'Tree Care', value: IndustryTypes.TREE_CARE },
  { label: 'Window Cleaning', value: IndustryTypes.WINDOW_CLEANING },
  { label: 'Other...', value: IndustryTypes.OTHER },
];

export const NUMBER_OF_EMPLOYEES_OPTIONS = [
  { label: '1', value: NumberOfEmployees.ONE },
  { label: '2-5', value: NumberOfEmployees.TWO_TO_FIVE },
  { label: '6-20', value: NumberOfEmployees.SIX_TO_TWENTY },
  { label: '21-50', value: NumberOfEmployees.TWNETY_ONE_TO_FIFTY },
  { label: '51-200', value: NumberOfEmployees.FIFTY_ONE_TO_TWO_HUNDRED },
  { label: '200+', value: NumberOfEmployees.TWO_HUNDRED_PLUS },
];
