import { AutomationsPermissions } from './automations.permissions';
import { AutomationsRoles } from './automations.roles';

export const automationsMappings = {
  [AutomationsPermissions.GET_LISTED_AUTOMATIONS]: [AutomationsRoles.VIEWER],
  [AutomationsPermissions.GET_AUTOMATION]: [AutomationsRoles.VIEWER],
  [AutomationsPermissions.ADD_AUTOMATION]: [AutomationsRoles.CREATOR],
  [AutomationsPermissions.EDIT_AUTOMATION]: [AutomationsRoles.EDITOR],
  [AutomationsPermissions.DELETE_AUTOMATION]: [AutomationsRoles.EDITOR],
  [AutomationsPermissions.ADD_SIMPLE_AUTOMATION]: [
    AutomationsRoles.SIMPLE_CREATOR,
    AutomationsRoles.CREATOR,
  ],
  [AutomationsPermissions.EDIT_SIMPLE_AUTOMATION]: [
    AutomationsRoles.SIMPLE_EDITOR,
    AutomationsRoles.EDITOR,
  ],
  [AutomationsPermissions.GET_DEFAULT_AUTOMATIONS]: [
    AutomationsRoles.DEFAULT_VIEWER,
  ],
  [AutomationsPermissions.MANAGE_DEFAULT_AUTOMATION]: [
    AutomationsRoles.DEFAULT_MANAGER,
  ],
};
