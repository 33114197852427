import { Reducer } from 'redux';

import { ActionType, State } from './dashboardTypes';

export const initialState: State = {
  isGettingActionRequiredDraftJobs: false,
  actionRequiredDraftJobs: [],
  isGettingActionRequiredPendingPayments: false,
  actionRequiredPendingPayments: [],
  isGettingActionRequiredPendingProposals: false,
  actionRequiredPendingProposals: [],
  isGettingActionRequiredPaymentOutstandingInvoices: false,
  actionRequiredPaymentOutstandingInvoices: [],
};

const dashboardReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_ACTION_REQUIRED_PENDING_PAYMENTS:
      return {
        ...state,
        isGettingActionRequiredPendingPayments: true,
      };
    case ActionType.GET_ACTION_REQUIRED_PENDING_PAYMENTS_SUCCESS:
      return {
        ...state,
        isGettingActionRequiredPendingPayments: false,
        actionRequiredPendingPayments:
          action.payload.actionRequiredPendingPayments,
      };
    case ActionType.GET_ACTION_REQUIRED_PENDING_PAYMENTS_FAILURE:
      return {
        ...state,
        isGettingActionRequiredPendingPayments: false,
      };
    case ActionType.CLEAR_ACTION_REQUIRED_PENDING_PAYMENTS:
      return {
        ...state,
        actionRequiredPendingPayments: [],
      };

    case ActionType.REQUEST_GET_ACTION_REQUIRED_DRAFT_JOBS:
      return {
        ...state,
        isGettingActionRequiredDraftJobs: true,
      };
    case ActionType.GET_ACTION_REQUIRED_DRAFT_JOBS_SUCCESS:
      return {
        ...state,
        isGettingActionRequiredDraftJobs: false,
        actionRequiredDraftJobs: action.payload.actionRequiredDraftJobs,
      };
    case ActionType.GET_ACTION_REQUIRED_DRAFT_JOBS_FAILURE:
      return {
        ...state,
        isGettingActionRequiredDraftJobs: false,
      };
    case ActionType.CLEAR_ACTION_REQUIRED_DRAFT_JOBS:
      return {
        ...state,
        actionRequiredDraftJobs: [],
      };

    case ActionType.REQUEST_GET_ACTION_REQUIRED_PENDING_PROPOSALS:
      return {
        ...state,
        isGettingActionRequiredPendingProposals: true,
      };
    case ActionType.GET_ACTION_REQUIRED_PENDING_PROPOSALS_SUCCESS:
      return {
        ...state,
        isGettingActionRequiredPendingProposals: false,
        actionRequiredPendingProposals:
          action.payload.actionRequiredPendingProposals,
      };
    case ActionType.GET_ACTION_REQUIRED_PENDING_PROPOSALS_FAILURE:
      return {
        ...state,
        isGettingActionRequiredPendingProposals: false,
      };
    case ActionType.CLEAR_ACTION_REQUIRED_PENDING_PROPOSALS:
      return {
        ...state,
        actionRequiredPendingProposals: [],
      };

    case ActionType.REQUEST_GET_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES:
      return {
        ...state,
        isGettingActionRequiredPaymentOutstandingInvoices: true,
      };
    case ActionType.GET_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES_SUCCESS:
      return {
        ...state,
        isGettingActionRequiredPaymentOutstandingInvoices: false,
        actionRequiredPaymentOutstandingInvoices:
          action.payload.actionRequiredPaymentOutstandingInvoices,
      };
    case ActionType.GET_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES_FAILURE:
      return {
        ...state,
        isGettingActionRequiredPaymentOutstandingInvoices: false,
      };
    case ActionType.CLEAR_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES:
      return {
        ...state,
        actionRequiredPaymentOutstandingInvoices: [],
      };

    default:
      return state;
  }
};

export default dashboardReducer;
