import React, { FC, useState, useCallback, useMemo } from 'react';
import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import Modal from '@payaca/components/modal/Modal';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { useDispatch } from 'react-redux';
import * as pipelineActions from '@payaca/store/pipelines/pipelineActions';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import {
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import './AddNewPipelineModal.sass';
import { Pipeline } from '@payaca/types/pipelineTypes';
import { useSelector } from '@/api/state';

const MAX_PIPELINES = 20;
const MAX_PIPELINE_TITLE_CHARS = 20;

const initialFormState = {
  name: '',
  templatePipelineId: 0,
};

interface Props {
  isOpen?: boolean;
  onClose?: any;
  onPipelineCreated?: any;
}

export const AddNewPipelineModal: FC<Props> = ({
  isOpen = false,
  onClose,
  onPipelineCreated,
}) => {
  const dispatch = useDispatch();

  const pipelines = useSelector((state) => state.pipelines.pipelines);

  const isCreatingPipeline = useSelector(
    (state) => state.pipelines.isPerformingPipelineAction
  );

  const [titleIsUnique, setTitleIsUnique] = useState<boolean | null>(null);

  const hasReachedPipelineLimit = useMemo(
    () => pipelines.length >= MAX_PIPELINES,
    [pipelines]
  );

  const fieldValidators = useMemo(() => {
    const isRequiredFieldValidator = getIsRequiredFieldValidator();

    return {
      title: [
        isRequiredFieldValidator,
        getLengthFieldValidator(
          { min: 0, max: MAX_PIPELINE_TITLE_CHARS },
          {
            customErrorMessage: `Your pipeline title must be less than ${MAX_PIPELINE_TITLE_CHARS} characters long`,
          }
        ),
      ],
    };
  }, []);

  const handleCreateNewPipeline = useCallback(
    (title: string, templatePipelineId: number) => {
      const trimmedTitle = title.trim();
      const titleAlreadyExists = pipelines.some(
        (pipeline: any) => pipeline.title.trim() === trimmedTitle
      );

      if (titleAlreadyExists) {
        setTitleIsUnique(false);
      } else {
        setTitleIsUnique(true);
        dispatch(
          pipelineActions.requestCreatePipeline(
            trimmedTitle,
            templatePipelineId,
            onPipelineCreated
          )
        );
      }
    },
    [onPipelineCreated]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => (
      <>
        <div className="field-group">
          <ValidatedFieldWrapper
            validationResult={validationState.title}
            isTouched={!!formState.title}
          >
            <BasicField
              name={'title'}
              label={'Title'}
              styleVariant={InputStyleVariant.OUTSIZE}
              value={formState.title}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
            />
          </ValidatedFieldWrapper>
        </div>
        <div className="field-group">
          <DropdownField
            name={'templatePipelineId'}
            label={'Which pipeline would you like to use as a template?'}
            styleVariant={InputStyleVariant.OUTSIZE}
            value={formState.templatePipelineId}
            onChange={onFieldChange}
            onTouch={onFieldTouch}
            options={pipelines.map((pipeline: Pipeline) => ({
              label:
                pipeline.id === 0
                  ? `Payaca "default" pipeline`
                  : pipeline.title,
              value: pipeline.id,
            }))}
          />
        </div>
        <Button
          styleVariant={ButtonStyleVariant.OUTSIZE}
          onClick={() =>
            handleCreateNewPipeline(
              formState.title,
              formState.templatePipelineId
            )
          }
          isDisabled={hasReachedPipelineLimit || !isValid || isCreatingPipeline}
          isProcessing={isCreatingPipeline}
        >
          Create
        </Button>
        {titleIsUnique === false && (
          <ErrorMessage message="That pipeline title already exists. Please enter a unique title." />
        )}
      </>
    ),
    [
      handleCreateNewPipeline,
      hasReachedPipelineLimit,
      isCreatingPipeline,
      titleIsUnique,
    ]
  );

  return (
    <Modal
      className={'add-new-pipeline-modal'}
      isOpen={isOpen}
      onClose={onClose}
      title={'Add new pipeline'}
    >
      {hasReachedPipelineLimit ? (
        <p>
          You have reached the limit of {MAX_PIPELINES} pipelines per account.
          Please remove an existing pipeline before adding a new one.
        </p>
      ) : (
        <ValidatedForm<{ [key: string]: any }>
          fieldValidators={fieldValidators}
          renderFormContents={renderFormContents}
          initialFormState={initialFormState}
        />
      )}
    </Modal>
  );
};
