export const getUrlSearchParams = (url: string): URLSearchParams => {
  const urlQueryString = url ? url.substring(url.indexOf('?')) : '';
  return new URLSearchParams(urlQueryString);
};

export const getUrlSearchParam = (
  url: string,
  parameterName: string
): string | null => {
  const searchParams = getUrlSearchParams(url);
  return searchParams.get(parameterName);
};
