import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TypeToSearchField from '@payaca/components/typeToSearchField/TypeToSearchField';
import { ListedSupplier, SortBy } from '@payaca/types/listedSupplierTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import * as suppliersActions from '@payaca/store/suppliers/suppliersActions';
import * as listedSupplierActions from '@payaca/store/listedSuppliers/listedSuppliersActions';

import './SupplierSelectionControl.sass';
import InputWrapper, {
  InputStyleVariant,
} from '@payaca/components/inputWrapper/InputWrapper';
import { Supplier } from '@payaca/types/supplierTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import CreateEditSupplierModal from '../createEditSupplierModal/CreateEditSupplierModal';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import IconButton from '@payaca/components/button/IconButton';
import { LabelStyleVariant } from '@payaca/components/fieldLabel/FieldLabel';
import { useSupplier } from '@payaca/store/hooks/appState';
import { useSelector } from '@/api/state';

interface Props {
  label?: string;
  selectedSupplierId?: number;
  onChange: (selectedSupplierId?: number | null) => void;
  enableSupplierCreation?: boolean;
  excludeSystemManaged?: boolean;
  labelStyleVariant?: LabelStyleVariant;
}

const SupplierSelectionControl: FC<Props> = ({
  label,
  selectedSupplierId,
  onChange,
  enableSupplierCreation = false,
  excludeSystemManaged = false,
  labelStyleVariant,
}: Props): JSX.Element => {
  const [showCreateSupplierModal, setShowCreateSupplierModal] = useState(false);
  const [newSupplierData, setNewSupplierData] = useState({});
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [requiresGetListedSuppliersPage, setRequiresGetListedSuppliersPage] =
    useState(false);

  const isGettingListedSuppliersPage = useSelector(
    (state) => !!state.listedSuppliers?.isGettingListedSuppliersPage
  );

  const isPersistingSupplier = useSelector(
    (state) => !!state.suppliers?.isPersistingSupplier
  );

  const supplier: Supplier | undefined = useSupplier(selectedSupplierId);

  const listedSuppliers: ListedSupplier[] = useSelector((state) => {
    return state.listedSuppliers?.listedSuppliersPage?.items || [];
  });

  const requestGetListedSuppliersPage = useCallback(() => {
    !!searchTerm?.length &&
      dispatch(
        listedSupplierActions.requestGetListedSuppliersPage({
          sortDirection: SortDirection.ASCENDING,
          pageSize: 10,
          pageNumber: 1,
          searchTerm: searchTerm,
          sortBy: SortBy.NAME,
          excludeSystemManaged: excludeSystemManaged,
        })
      );
    setRequiresGetListedSuppliersPage(false);
  }, [searchTerm, dispatch, excludeSystemManaged]);

  useEffect(() => {
    if (!selectedSupplierId) {
      setRequiresGetListedSuppliersPage(true);
    } else {
      dispatch(suppliersActions.requestGetSupplier(selectedSupplierId));
    }
  }, [selectedSupplierId]);

  useEffect(() => {
    if (requiresGetListedSuppliersPage) {
      requestGetListedSuppliersPage();
    }
  }, [requiresGetListedSuppliersPage]);

  useEffect(() => {
    dispatch(listedSupplierActions.clearListedSuppliersPage());
  }, []);

  const renderListedSupplierOption = useCallback(
    (listedSupplier: ListedSupplier) => {
      return (
        <div className="listed-supplier-option flex-container flex-contiguous flex-center">
          {listedSupplier.id === -1 && <IconButton size={'xs'} icon={faPlus} />}
          <span title={listedSupplier.name}>{listedSupplier.name}</span>
        </div>
      );
    },
    []
  );

  const handleCreateSupplier = useCallback((supplierName: string) => {
    dispatch(
      suppliersActions.requestPersistSupplier(
        { name: supplierName },
        (supplierId: number) => {
          onChange(supplierId);
        }
      )
    );
  }, []);

  return (
    <div className="supplier-selection-control">
      {label && (
        <FieldLabel label={label} styleVariant={labelStyleVariant} isRequired />
      )}
      {!selectedSupplierId && (
        <TypeToSearchField
          styleVariant={InputStyleVariant.OUTSIZE}
          onSearchTermChangeTimeout={() =>
            setRequiresGetListedSuppliersPage(true)
          }
          autoHighlight={true}
          onSearchTermChange={setSearchTerm}
          options={
            enableSupplierCreation
              ? [
                  {
                    id: -1,
                    name: 'Create a new Supplier',
                  } as ListedSupplier,
                  ...listedSuppliers,
                ]
              : listedSuppliers
          }
          isLoadingOptions={
            isGettingListedSuppliersPage || isPersistingSupplier
          }
          renderOption={renderListedSupplierOption}
          disableFilteringInComponent={true}
          onSelectOption={(listedSupplier?: ListedSupplier) => {
            dispatch(listedSupplierActions.clearListedSuppliersPage());
            if (listedSupplier?.id === -1 && enableSupplierCreation) {
              if (searchTerm) {
                handleCreateSupplier(searchTerm);
              } else {
                setNewSupplierData({ name: searchTerm });
                setShowCreateSupplierModal(true);
              }
            } else {
              onChange(listedSupplier?.id);
            }
          }}
          placeholder="Type here to search Suppliers"
          noOptionsText={'No Suppliers found.'}
        />
      )}

      {selectedSupplierId && (
        <div className="selected-supplier-container">
          <InputWrapper styleVariant={InputStyleVariant.OUTSIZE}>
            <>
              {supplier && (
                <>
                  <span className="selected-supplier-name">
                    {supplier.name}
                  </span>
                  <Button
                    styleVariant={ButtonStyleVariant.ANCHOR}
                    onClick={() => {
                      onChange(null);
                      setSearchTerm(undefined);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </>
              )}
              {!supplier && <MiniLoader />}
            </>
          </InputWrapper>
        </div>
      )}
      <CreateEditSupplierModal
        isOpen={showCreateSupplierModal}
        onClose={() => setShowCreateSupplierModal(false)}
        supplier={newSupplierData as Supplier}
        onPersistSupplierSuccess={(supplierId: number) => {
          onChange(supplierId);
        }}
      />
    </div>
  );
};

export default SupplierSelectionControl;
