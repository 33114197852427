import { useQueryClient, UseMutationOptions } from '@tanstack/react-query';

import projectKeys from '@/api/queries/project/keyFactory';
import {
  ProjectQuery,
  UpdateCustomerContactInput,
  UpdateCustomerContactMutation as _UpdateCustomerContactMutation,
} from '@/gql/graphql';
import useUpdateCustomerContact from '@/api/mutations/customers/useUpdateCustomerContacts';
import complianceKeys from '@/api/queries/compliance/keyFactory';

const useOptimisticUpdateCustomerContactOnProject = (
  projectId: number,
  options?: UseMutationOptions<
    _UpdateCustomerContactMutation,
    unknown,
    UpdateCustomerContactInput
  >
) => {
  const queryClient = useQueryClient();

  return useUpdateCustomerContact({
    ...options,
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: projectKeys.overview(projectId),
      });

      // Snapshot the previous value
      const previousProjectOverview = queryClient.getQueryData(
        projectKeys.overview(projectId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData<ProjectQuery>(
        projectKeys.overview(projectId),
        (old) => {
          if (!old) return;

          return {
            ...old,
            project: {
              ...old.project,
              customer: old.project.customer
                ? {
                    ...old.project.customer,
                    contacts:
                      old.project.customer?.contacts.map((contact) => {
                        if (contact.id === variables.customerContactId) {
                          return {
                            ...contact,
                            name: variables.name,
                            description: variables.description,
                            email: variables.email,
                            phone: variables.phone,
                            isPrimary: variables.isPrimary || contact.isPrimary,
                          };
                        } else {
                          return contact;
                        }
                      }) || [],
                  }
                : null,
            },
          };
        }
      );

      options?.onMutate?.(variables);

      // Return a context object with the snapshotted value
      return { previousProjectOverview };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        projectKeys.overview(projectId),
        // @ts-ignore
        context.previousProjectOverview
      );

      options?.onError?.(err, newTodo, context);
    },
    // Always refetch after error or success:
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.overview(projectId),
      });
      queryClient.invalidateQueries({
        queryKey: complianceKeys.formPrefill(projectId),
      });

      options?.onSettled?.(...args);
    },
  });
};

export default useOptimisticUpdateCustomerContactOnProject;
