import { action } from 'typesafe-actions';

import { Task } from '@payaca/types/taskTypes';

import {
  ActionType,
  PersistTaskRequestData,
  UpdateTaskCompletionRequestData,
} from './tasksTypes';

export const requestPersistTask = (
  persistTaskRequestData: PersistTaskRequestData,
  callback?: (taskId: number) => void
) =>
  action(
    ActionType.REQUEST_PERSIST_TASK,
    {
      persistTaskRequestData,
      callback,
    },
    { retry: true }
  );

export const persistTaskSuccess = () => action(ActionType.PERSIST_TASK_SUCCESS);

export const persistTaskFailure = (error: Error) =>
  action(ActionType.PERSIST_TASK_FAILURE, null, null, error);

export const requestDeleteTask = (taskId: number, callback?: () => void) =>
  action(ActionType.REQUEST_DELETE_TASK, {
    taskId,
    callback,
  });

export const deleteTaskSuccess = (taskId: number) =>
  action(ActionType.DELETE_TASK_SUCCESS, { taskId });

export const deleteTaskFailure = (error: Error) =>
  action(ActionType.DELETE_TASK_FAILURE, null, null, error);

export const requestUpdateTaskCompletion = (
  updateTaskCompletionRequestData: UpdateTaskCompletionRequestData,
  callback?: () => void
) =>
  action(
    ActionType.REQUEST_UPDATE_TASK_COMPLETION,
    {
      updateTaskCompletionRequestData,
      callback,
    },
    {
      retry: true,
    }
  );

export const updateTaskCompletionSuccess = () =>
  action(ActionType.UPDATE_TASK_COMPLETION_SUCCESS);

export const updateTaskCompletionFailure = (error: Error) =>
  action(ActionType.UPDATE_TASK_COMPLETION_FAILURE, null, null, error);

export const requestGetTask = (taskId: number) =>
  action(ActionType.REQUEST_GET_TASK, {
    taskId,
  });

export const requestGetTasksForDeal = (dealId: number) =>
  action(ActionType.REQUEST_GET_TASKS_FOR_DEAL, {
    dealId,
  });

export const getTasksForDealSuccess = (tasks: Array<Task>) =>
  action(ActionType.GET_TASKS_FOR_DEAL_SUCCESS, tasks);

export const getTasksForDealFailure = () =>
  action(ActionType.GET_TASKS_FOR_DEAL_FAILURE);

export const requestGetTasksForScheduledEvent = (scheduledEventId: number) =>
  action(ActionType.REQUEST_GET_TASKS_FOR_SCHEDULED_EVENT, {
    scheduledEventId,
  });

export const getTasksForScheduledEventSuccess = (tasks: Array<Task>) =>
  action(ActionType.GET_TASKS_FOR_SCHEDULED_EVENT_SUCCESS, tasks);

export const getTasksForScheduledEventFailure = () =>
  action(ActionType.GET_TASKS_FOR_SCHEDULED_EVENT_FAILURE);

export const getTaskSuccess = (taskId: number, task: Task) =>
  action(ActionType.GET_TASK_SUCCESS, {
    taskId,
    task,
  });

export const getTaskFailure = (taskId: number, error: Error) =>
  action(
    ActionType.GET_TASK_FAILURE,
    {
      taskId,
    },
    null,
    error
  );

export const clearTaskRequest = () => action(ActionType.CLEAR_TASK_REQUEST);

export const clearTasks = () => action(ActionType.CLEAR_TASKS);
