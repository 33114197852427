import { ChangeProposal } from '@payaca/types/changeProposalTypes';
import { Invoice } from '@payaca/types/invoiceTypes';
import { Job } from '@payaca/types/jobTypesV2';
import {
  FetchedEntity,
  RemoteEntity,
  RequestAttempt,
  UpdateResult,
} from '@payaca/types/storeTypes';

import { DeclareAsyncAction } from '../types';

export interface ChangeProposalRemoteEntity
  extends RemoteEntity<ChangeProposal> {
  persistAttempt: RequestAttempt;
}

export interface State {
  changeProposals: { [key: number]: ChangeProposalRemoteEntity };
  isGettingChangeProposalsForDeal: boolean;
}

export enum ActionType {
  GET_CHANGE_PROPOSALS_FOR_DEAL_REQUEST = 'proposals.getChangeProposalsForDeal:request',
  GET_CHANGE_PROPOSALS_FOR_DEAL_SUCCESS = 'proposals.getChangeProposalsForDeal:success',
  GET_CHANGE_PROPOSALS_FOR_DEAL_FAILURE = 'proposals.getChangeProposalsForDeal:failure',

  GET_CHANGE_PROPOSAL_REQUEST = 'proposals.getChangeProposal:request',
  GET_CHANGE_PROPOSAL_SUCCESS = 'proposals.getChangeProposal:success',
  GET_CHANGE_PROPOSAL_FAILURE = 'proposals.getChangeProposal:failure',

  PERSIST_CHANGE_PROPOSAL_REQUEST = 'proposals.persistChangeProposal:request',
  PERSIST_CHANGE_PROPOSAL_SUCCESS = 'proposals.persistChangeProposal:success',
  PERSIST_CHANGE_PROPOSAL_FAILURE = 'proposals.persistChangeProposal:failure',

  MARK_CHANGE_PROPOSAL_ACCEPTED_REQUEST = 'proposals.markChangeProposalAccepted:request',
  MARK_CHANGE_PROPOSAL_ACCEPTED_SUCCESS = 'proposals.markChangeProposalAccepted:success',
  MARK_CHANGE_PROPOSAL_ACCEPTED_FAILURE = 'proposals.markChangeProposalAccepted:failure',

  MARK_CHANGE_PROPOSAL_DECLINED_REQUEST = 'proposals.markChangeProposalDeclined:request',
  MARK_CHANGE_PROPOSAL_DECLINED_SUCCESS = 'proposals.markChangeProposalDeclined:success',
  MARK_CHANGE_PROPOSAL_DECLINED_FAILURE = 'proposals.markChangeProposalDeclined:failure',

  VOID_CHANGE_PROPOSAL_REQUEST = 'proposals.voidChangeProposal:request',
  VOID_CHANGE_PROPOSAL_SUCCESS = 'proposals.voidChangeProposal:success',
  VOID_CHANGE_PROPOSAL_FAILURE = 'proposals.voidChangeProposal:failure',

  GET_CHANGE_PROPOSAL_VALIDATION_RESULT_REQUEST = 'proposals.getChangeProposalValidationResult:request',
  GET_CHANGE_PROPOSAL_GENERATED_PDF_URL = 'proposals.getChangeProposalGeneratedPdfUrl:request',

  CLEAR_CHANGE_PROPOSALS = 'proposals.clearChangeProposals',

  SEND_CHANGE_PROPOSAL_REQUEST = 'proposals.sendChangeProposal:request',
  SEND_CHANGE_PROPOSAL_SUCCESS = 'proposals.sendChangeProposal:success',
  SEND_CHANGE_PROPOSAL_FAILURE = 'proposals.sendChangeProposal:failure',

  MARK_CHANGE_PROPOSAL_AS_SENT_REQUEST = 'proposals.markChangeProposalAsSent:request',
  MARK_CHANGE_PROPOSAL_AS_SENT_SUCCESS = 'proposals.markChangeProposalAsSent:success',
  MARK_CHANGE_PROPOSAL_AS_SENT_FAILURE = 'proposals.markChangeProposalAsSent:failure',

  PROGRESS_PROPOSAL_TO_INVOICE_REQUEST = 'proposals.progressProposalToInvoice:request',
  PROGRESS_PROPOSAL_TO_INVOICE_SUCCESS = 'proposals.progressProposalToInvoice:success',
  PROGRESS_PROPOSAL_TO_INVOICE_FAILURE = 'proposals.progressProposalToInvoice:failure',

  SEND_PROTO_INVOICE_REQUEST = 'proposals.sendProtoInvoice:request',

  SET_PROPOSAL_DISCOUNT_REQUEST = 'proposals.setProposalDiscount:request',
  SET_CHANGE_PROPOSAL_DISCOUNT_REQUEST = 'proposals.setChangeProposalDiscount:request',
}

export interface SendProtoInvoiceRequestData {
  isMarkAsSent?: boolean;
  sendMeACopy?: boolean;
  emailCopy?: {
    preButton: string;
    postButton: string;
  };
}

export type SendProtoInvoice = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.SEND_PROTO_INVOICE_REQUEST;
      payload: {
        protoInvoiceId: Job['id'];
        data: SendProtoInvoiceRequestData;
        callback?: (invoiceId: Invoice['id']) => void;
        onErrorCallback?: (errorMessages: string[]) => void;
      };
    };
  }
>;

export type SetProposalDiscountPayload = {
  proposalId: number;
  discountPercentage: number;
  discountDescription?: string;
};

export type SetProposalDiscount = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.SET_PROPOSAL_DISCOUNT_REQUEST;
      payload: SetProposalDiscountPayload & {
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type SetChangeProposalDiscountPayload = {
  changeProposalId: number;
  discountPercentage: number;
  discountDescription?: string;
};

export type SetChangeProposalDiscount = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.SET_CHANGE_PROPOSAL_DISCOUNT_REQUEST;
      payload: SetChangeProposalDiscountPayload & {
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export interface PersistChangeProposalRequestData {
  id?: number;
  dealId?: number;
  expiresInDays?: number;
  contactId?: number | null;
  notes?: string | null;
  customReference?: string | null;
}

export type MarkChangeProposalAcceptedAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.MARK_CHANGE_PROPOSAL_ACCEPTED_REQUEST;
      payload: {
        changeProposalId: number;
        callback?: () => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
    success: { type: ActionType.MARK_CHANGE_PROPOSAL_ACCEPTED_SUCCESS };
    failure: { type: ActionType.MARK_CHANGE_PROPOSAL_ACCEPTED_FAILURE };
  }
>;

export type MarkChangeProposalDeclinedAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.MARK_CHANGE_PROPOSAL_DECLINED_REQUEST;
      payload: {
        changeProposalId: number;
        callback?: () => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
    success: { type: ActionType.MARK_CHANGE_PROPOSAL_DECLINED_SUCCESS };
    failure: { type: ActionType.MARK_CHANGE_PROPOSAL_DECLINED_FAILURE };
  }
>;

export type VoidChangeProposalAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.VOID_CHANGE_PROPOSAL_REQUEST;
      payload: {
        changeProposalId: number;
        callback?: () => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
    success: { type: ActionType.VOID_CHANGE_PROPOSAL_SUCCESS };
    failure: { type: ActionType.VOID_CHANGE_PROPOSAL_FAILURE };
  }
>;

export type GetChangeProposalValidationResultAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_CHANGE_PROPOSAL_VALIDATION_RESULT_REQUEST;
      payload: {
        changeProposalId: number;
        callback?: (validationResult: {
          isValid: boolean;
          errors: string[];
        }) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type GetChangeProposalGeneratedPdfUrl = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_CHANGE_PROPOSAL_GENERATED_PDF_URL;
      payload: {
        changeProposalId: number;
        callback?: (pdfUrl: string) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type SendChangeProposal = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.SEND_CHANGE_PROPOSAL_REQUEST;
      payload: {
        isResend?: boolean;
        changeProposalId: number;
        sendMeACopy: boolean;
        emailCopy: {
          preButton: string;
          postButton: string;
        };
        callback?: () => void;
        onErrorCallback?: (errorMessages: string[]) => void;
      };
    };
    success: { type: ActionType.SEND_CHANGE_PROPOSAL_SUCCESS };
    failure: { type: ActionType.SEND_CHANGE_PROPOSAL_FAILURE };
  }
>;

export type MarkChangeProposalAsSent = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.MARK_CHANGE_PROPOSAL_AS_SENT_REQUEST;
      payload: {
        changeProposalId: number;
        callback?: () => void;
        onErrorCallback?: (errorMessages: string[]) => void;
      };
    };
    success: { type: ActionType.MARK_CHANGE_PROPOSAL_AS_SENT_SUCCESS };
    failure: { type: ActionType.MARK_CHANGE_PROPOSAL_AS_SENT_FAILURE };
  }
>;

export type ProgressProposalToInvoice = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.PROGRESS_PROPOSAL_TO_INVOICE_REQUEST;
      payload: {
        proposalId: number;
        callback?: (invoiceId: number) => void;
        onErrorCallback?: (errorMessages: string[]) => void;
      };
    };
    success: { type: ActionType.PROGRESS_PROPOSAL_TO_INVOICE_SUCCESS };
    failure: { type: ActionType.PROGRESS_PROPOSAL_TO_INVOICE_FAILURE };
  }
>;
