import React, { FC, useCallback, useEffect, useState } from 'react';
import Slider from '@payaca/components/slider/Slider';
import ColourPickerField from '../colourPickerField/ColourPickerField';
import {
  PayacaPagesConfig,
  PayacaPagesQuestionOptions,
} from '@payaca/types/payacaPagesTypes';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import {
  InputColourVariant,
  InputStyleVariant,
} from '@payaca/components/inputWrapper/InputWrapper';

interface Props {
  value?: PayacaPagesConfig;
  onChange: (config: PayacaPagesConfig) => void;
}

export const PayacaPagesConfigControl: FC<Props> = ({ value, onChange }) => {
  const [values, setValues] = useState<PayacaPagesConfig>();

  useEffect(() => setValues(value), [value]);

  const onFieldChange = useCallback(
    (update: any) => {
      const newValues = {
        ...values,
        ...update,
      };
      setValues(newValues);
      onChange(newValues);
    },
    [values]
  );

  if (!values) return <></>;

  return (
    <div className={'payaca-pages-config-control'}>
      <div className={'payaca-pages-config-control-section'}>
        <h2>Customise your page</h2>
        <label>Choose your questions</label>
        <DropdownField
          name={'questions'}
          value={values.questions || []}
          optionGroups={[
            { name: 'General', id: 1 },
            { name: 'Plumbing & Heating', id: 2 },
          ]}
          options={PayacaPagesQuestionOptions.map(
            ({ label, optionGroupId, id }) => ({
              label,
              value: id,
              optionGroupId,
            })
          )}
          onChange={({ questions }: any) =>
            onFieldChange({
              questions: questions.filter((q: string) => typeof q === 'string'),
            })
          }
          additionalSelectProps={{
            renderValue: (value: any, label: any) => {
              if (value.length) {
                return `${value.length} selected`;
              }
              return 'Click to select...';
            },
            displayEmpty: true,
          }}
          multiple={true}
          colourVariant={InputColourVariant.SECONDARY}
          styleVariant={InputStyleVariant.STANDARD}
          hasBoxShadow={false}
          displayCheckboxes={true}
        />
      </div>

      <div className={'payaca-pages-config-control-section'}>
        <label>Colour</label>
        <ColourPickerField
          colour={values.color}
          onColourChange={(colorResult) =>
            onFieldChange({ color: colorResult.hex })
          }
        />
      </div>

      <div className={'payaca-pages-config-control-section'}>
        <label>Field spacing</label>
        <Slider
          sliderValue={values.cellSpacing - 8}
          minSliderValue={0}
          maxSliderValue={22}
          hideLabels={true}
          onSliderChange={(cellSpacing: number) =>
            onFieldChange({ cellSpacing: cellSpacing + 8 })
          }
          isDisabled={false}
          onInputChange={(cellSpacing: number) =>
            onFieldChange({ cellSpacing: cellSpacing + 8 })
          }
          sliderStep={1}
        />
        <label>Field padding</label>
        <Slider
          sliderValue={values.cellPadding}
          minSliderValue={4}
          maxSliderValue={16}
          hideLabels={true}
          onSliderChange={(cellPadding: number) =>
            onFieldChange({ cellPadding })
          }
          isDisabled={false}
          onInputChange={(cellPadding) => onFieldChange({ cellPadding })}
          sliderStep={1}
        />

        <label>Border thickness</label>
        <Slider
          sliderValue={values.borderWidth}
          minSliderValue={1}
          maxSliderValue={5}
          hideLabels={true}
          onSliderChange={(borderWidth: number) =>
            onFieldChange({ borderWidth })
          }
          isDisabled={false}
          onInputChange={(borderWidth: number) =>
            onFieldChange({ borderWidth })
          }
          sliderStep={1}
        />

        <label>Corner rounding</label>
        <Slider
          sliderValue={values.borderRadius}
          minSliderValue={0}
          maxSliderValue={50}
          hideLabels={true}
          onSliderChange={(borderRadius: number) =>
            onFieldChange({ borderRadius })
          }
          isDisabled={false}
          onInputChange={(borderRadius: number) =>
            onFieldChange({ borderRadius })
          }
          sliderStep={1}
        />
      </div>
    </div>
  );
};
