import React, { FC, useMemo, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';

import { actions as usersActions } from '@/api/users';

import Modal from '@payaca/components/plModal/Modal';
import {
  ConnectionControl,
  ConnectionControlBenefitList,
} from '@/ui/components';
import GmailLogo from './gmail.png';
import GoogleButton from './google-button.png';

import useGetMe from '../../../../api/queries/me/useGetMe';
import { parseHtmlToReact } from '@payaca/utilities/htmlUtilities';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import { useImportEmailServiceSignature } from '../../../../api/mutations/user/useImportEmailSignature';
import { useRemoveEmailSignature } from '../../../../api/mutations/user/useRemoveEmailSignature';
import { marketingSiteUrl } from '@/helpers/urlHelper';
import { useToastContext } from '@payaca/components/plToast/ToastContext';

const GmailConnectionControl: FC = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { data, refetch: refetchMe } = useGetMe();
  const { pushToast } = useToastContext();

  const [showConfirmConnectModal, setShowConfirmConnectModal] = useState(false);
  const [showViewSignatureModal, setShowViewSignatureModal] = useState(false);

  const {
    mutateAsync: importEmailServiceSignatureMutation,
    isLoading: isImportingEmailServiceSignature,
  } = useImportEmailServiceSignature();
  const {
    mutateAsync: removeEmailSignatureMutation,
    isLoading: isRemovingEmailSignature,
  } = useRemoveEmailSignature();

  const userIntegrations = useMemo(() => data?.me.integrations, [data]);

  const connectToGoogle = useGoogleLogin({
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly', // scope to access gmail - read/send
    onSuccess: async (codeResponse) => {
      setShowConfirmConnectModal(false);
      dispatch(
        usersActions.connectWithGoogle(codeResponse.code, (error: string) => {
          refetchMe();
          if (!error) {
            console.log('Connected with Google');
          } else {
            console.log('Unable to connect with Google');
            pushToast({
              variant: 'white',
              icon: 'error',
              message:
                'There was an error connecting to your Google account. Please try again.',
            });
          }
        })
      );
    },
  });

  return (
    <>
      <ConnectionControl
        disableConnection={false}
        connectionName="Gmail"
        isConnected={
          userIntegrations?.google ? userIntegrations.google?.isActive : false
        }
        allowConnectDisconnect
        onAddConnection={() => setShowConfirmConnectModal(true)}
        onRemoveConnection={(onConnectionRemoved: () => void) => {
          dispatch(
            usersActions.removeGoogleConnection(() => {
              refetchMe();
              onConnectionRemoved();
            })
          );
        }}
        logo={GmailLogo}
        hasConnectionExpired={
          userIntegrations?.google ? !userIntegrations.google?.isActive : false
        }
        disconnectionWarningMessage={
          <ul>
            <li>
              You will no longer be able to send emails via your Gmail account
              in Payaca.
            </li>
            <li>
              You will no longer be able to see newly received emails about your
              projects in Payaca.
            </li>
          </ul>
        }
      >
        {!userIntegrations?.google ? (
          <div>
            <p>
              Use your Gmail account in Payaca with our seamless integration.
            </p>
            <ConnectionControlBenefitList
              benefits={[
                'Send emails on a Project from your Gmail account.',
                'Replies are automatically tracked on a Project.',
              ]}
            />
          </div>
        ) : !userIntegrations.google?.isActive ? (
          <p>
            Your connection with your{' '}
            <span className="font-bold">{userIntegrations.google.email}</span>{' '}
            Google account has expired, please click the button below to
            reconnect.
          </p>
        ) : (
          <div>
            <p>
              Your Gmail account{' '}
              <span className="font-bold">{userIntegrations.google.email}</span>{' '}
              has successfully been connected to Payaca.
            </p>
            {data?.me.emailSignature ? (
              <>
                <p>
                  You email signature has been imported and will be used in
                  outgoing emails.{' '}
                  <Button
                    onClick={() => setShowViewSignatureModal(true)}
                    variant={EBtnVariant.LinkInline}
                  >
                    View signature
                  </Button>
                </p>
              </>
            ) : (
              <Button
                onClick={async () => {
                  await importEmailServiceSignatureMutation();
                  refetchMe();
                }}
                isProcessing={isImportingEmailServiceSignature}
                variant={EBtnVariant.LinkInline}
              >
                Import signature
              </Button>
            )}
          </div>
        )}
      </ConnectionControl>
      <Modal
        title="Connect to Gmail"
        isOpen={showConfirmConnectModal}
        onClose={() => setShowConfirmConnectModal(false)}
      >
        <Modal.Body>
          <p>
            By connecting Payaca with your Google account to integrate with
            Gmail, Payaca will have access to the following:
          </p>
          <ul>
            <li>Send emails on behalf of your Gmail account</li>
            <li>Track email replies from your Gmail account</li>
          </ul>
          <p>
            Once connected, if you would like to stop using this integration,
            return to your Profile page &gt; Connections and click
            &quot;Disconnect from Gmail&quot;.
          </p>
          <br />
          <p>
            For more information on the Google integration and the types of data
            we can access, please see our{' '}
            <a
              href={marketingSiteUrl('/privacy-policy')}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Actions>
            <button
              className="bg-transparent hover:cursor-pointer"
              onClick={connectToGoogle}
            >
              <img src={GoogleButton} />
            </button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </Modal>
      <Modal
        title="Email signature"
        isOpen={showViewSignatureModal}
        onClose={() => setShowViewSignatureModal(false)}
        size="lg"
      >
        <Modal.Body>
          <Card sizeVariant={CardSizeVariant.MD}>
            <Card.Body>
              {data?.me.emailSignature ? (
                parseHtmlToReact(data?.me.emailSignature)
              ) : (
                <p>You do not have a signature set.</p>
              )}
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Actions>
            <Button
              onClick={async () => {
                await removeEmailSignatureMutation();
                setShowViewSignatureModal(false);
                refetchMe();
              }}
              isProcessing={isRemovingEmailSignature}
              colour={EBtnColour.Red}
              variant={EBtnVariant.Outline}
            >
              Remove signature
            </Button>
            <Button onClick={() => setShowViewSignatureModal(false)}>
              Close
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GmailConnectionControl;
