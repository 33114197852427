import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import * as tasksActions from '@payaca/store/tasks/tasksActions';
import * as dealsActions from '@payaca/store/deals/dealsActions';
import CreateEditTaskControl from '../createEditTaskControl/CreateEditTaskControl';
import { Task } from '@payaca/types/taskTypes';
import { requestGetDocumentForTask } from '@payaca/store/documents/documentActions';

type Props = {
  onPersistScheduledEventSuccess?: () => void;
  dealId: number;
  scheduledEventId: number;
};
const ScheduledEventLinkedNewTaskControl: FC<Props> = ({
  onPersistScheduledEventSuccess,
  scheduledEventId,
  dealId,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const onPersistTaskSuccess = useCallback(
    (taskId: number) => {
      dispatch(dealsActions.requestGetDeal(dealId));
      dispatch(tasksActions.requestGetTasksForDeal(dealId));
      dispatch(requestGetDocumentForTask(taskId));
    },
    [dealId]
  );

  const task = useMemo(() => {
    return {
      scheduledEventIds: [scheduledEventId],
    } as Task;
  }, [scheduledEventId]);

  return (
    <div className="scheduled-event-linked-new-task-control">
      <CreateEditTaskControl
        onPersistTaskSuccess={(taskId) => {
          onPersistTaskSuccess(taskId);
          onPersistScheduledEventSuccess && onPersistScheduledEventSuccess();
        }}
        task={task}
        dealId={dealId}
      />
    </div>
  );
};
export default ScheduledEventLinkedNewTaskControl;
