import React, { FC, useState } from 'react';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import AddEditAddressContactsControl from '@/ui/components/addressControls/AddEditAddressContactsControl';
import Conditional from '@payaca/components/conditional/Conditional';
import ManuallyAddEditAddressControl from '@/ui/components/addressControls/ManualAddEditAddressControl';
import { Address as _Address, UpdateAddressContactInput } from '@/gql/graphql';

type Address = Pick<
  _Address,
  | 'id'
  | 'line1'
  | 'line2'
  | 'city'
  | 'country'
  | 'postalCode'
  | 'fullLocalAddress'
  | 'contacts'
>;

export interface IProps extends Omit<IModalProps, 'onBack' | 'title'> {
  title?: string;
  addresses: Address[];
  onChange?: (
    address: Omit<Address, 'contacts'> & {
      contacts: UpdateAddressContactInput[];
    }
  ) => void;
  onRemoveAddress?: (addressId: string) => void;
}

const EditMultipleAddressesAndAddressContactsModal: FC<IProps> = (props) => {
  const {
    title = 'Edit multiple Addresses',
    addresses,
    onChange,
    onRemoveAddress,
    ...rest
  } = props;

  const [isEditingMode, setIsEditingMode] = useState<string>();

  return (
    <Modal
      title={title}
      onBack={isEditingMode ? () => setIsEditingMode(undefined) : undefined}
      {...rest}
    >
      <Conditional
        condition={!isEditingMode}
        fallback={
          <ManuallyAddEditAddressControl
            isInModal
            address={addresses.find((address) => address.id === isEditingMode)}
            onSave={(updatedAddress) => {
              const currentAddress = addresses.find(
                (address) => address.id === isEditingMode
              );

              if (!currentAddress) {
                return;
              }

              onChange?.({
                ...currentAddress,
                ...updatedAddress,
              });

              setIsEditingMode(undefined);
            }}
            onClose={() => {
              setIsEditingMode(undefined);
            }}
          />
        }
      >
        <Modal.Body>
          <div className="flex flex-col gap-4">
            {addresses.map((address, index) => {
              return (
                <div
                  key={address.id}
                  className={
                    'space-y-4' + (index !== 0 ? ' border-t pt-4' : '')
                  }
                >
                  <div className="flex items-start justify-between gap-4">
                    <p>{address.fullLocalAddress}</p>

                    <Button
                      className="whitespace-nowrap"
                      variant={EBtnVariant.LinkInline}
                      onClick={(e) => {
                        onRemoveAddress?.(address.id);
                      }}
                    >
                      Remove address
                    </Button>
                  </div>

                  <div>
                    <Button
                      variant={EBtnVariant.LinkInline}
                      onClick={(e) => {
                        setIsEditingMode(address.id);
                      }}
                    >
                      Edit Address
                    </Button>
                  </div>

                  <AddEditAddressContactsControl
                    contacts={address.contacts || []}
                    onUpdate={(newContacts) => {
                      const copyAddress = { ...address };

                      onChange?.({
                        ...copyAddress,
                        contacts: newContacts,
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Conditional>
    </Modal>
  );
};

export default EditMultipleAddressesAndAddressContactsModal;
