import React, { FC, useMemo } from 'react';

import SortableColumnHeaderTableCell from '@payaca/components/sortableColumnHeaderTableCell/SortableColumnHeaderTableCell';
import Checkbox from '@payaca/components/checkbox/Checkbox';

import { ListedSupplier, SortBy } from '@payaca/types/listedSupplierTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import { useSelector } from '@/api/state';

type Props = {
  onChangeSortOrder: (sortBy: SortBy, sortDirection: SortDirection) => void;
  sortBy: SortBy;
  sortDirection: SortDirection;
  quickActionDefinitions?: {
    actionName: string;
    actionBehaviour: (id: number) => void;
    isActionProcessing?: boolean;
  }[];
  selectedSupplierIds: number[];
  onSelectSuppliers?: (supplierIds: number[]) => void;
  onUnselectSuppliers?: (supplierIds: number[]) => void;
};
const ListedSuppliersTableHeader: FC<Props> = ({
  onChangeSortOrder,
  sortBy,
  sortDirection,
  quickActionDefinitions,
  selectedSupplierIds,
  onSelectSuppliers,
  onUnselectSuppliers,
}: Props): JSX.Element => {
  const listedSuppliersPage = useSelector((state) => {
    return state.listedSuppliers?.listedSuppliersPage;
  });
  const canSelectSuppliers = useMemo(() => {
    return !!(onUnselectSuppliers && onUnselectSuppliers);
  }, [onUnselectSuppliers, onUnselectSuppliers]);

  const allSupplierIds = useMemo(() => {
    if (!listedSuppliersPage?.items) return [];
    return listedSuppliersPage.items.map(
      (listedSupplier: ListedSupplier) => listedSupplier.id
    );
  }, [listedSuppliersPage]);

  const isAllSuppliersSelected = useMemo(() => {
    return (
      allSupplierIds.sort().join(',') === selectedSupplierIds.sort().join(',')
    );
  }, [allSupplierIds, selectedSupplierIds]);

  return (
    <thead>
      <tr>
        {canSelectSuppliers && (
          <th className="row-selection-table-cell">
            <Checkbox
              isChecked={isAllSuppliersSelected}
              onChange={() => {
                isAllSuppliersSelected
                  ? onUnselectSuppliers && onUnselectSuppliers(allSupplierIds)
                  : onSelectSuppliers && onSelectSuppliers(allSupplierIds);
              }}
            />
          </th>
        )}
        <SortableColumnHeaderTableCell
          columnName="Name"
          selectedSortDirection={
            sortBy === SortBy.NAME ? sortDirection : undefined
          }
          onChangeSortDirection={(sortDirection: SortDirection) =>
            onChangeSortOrder(SortBy.NAME, sortDirection)
          }
        />
        <SortableColumnHeaderTableCell
          columnName="Email"
          selectedSortDirection={
            sortBy === SortBy.EMAIL ? sortDirection : undefined
          }
          onChangeSortDirection={(sortDirection: SortDirection) =>
            onChangeSortOrder(SortBy.EMAIL, sortDirection)
          }
        />
        <SortableColumnHeaderTableCell
          columnName="Phone"
          selectedSortDirection={
            sortBy === SortBy.PHONE ? sortDirection : undefined
          }
          onChangeSortDirection={(sortDirection: SortDirection) =>
            onChangeSortOrder(SortBy.PHONE, sortDirection)
          }
        />
        <SortableColumnHeaderTableCell
          columnName="Website"
          selectedSortDirection={
            sortBy === SortBy.WEBSITE ? sortDirection : undefined
          }
          onChangeSortDirection={(sortDirection: SortDirection) =>
            onChangeSortOrder(SortBy.WEBSITE, sortDirection)
          }
        />
        <SortableColumnHeaderTableCell
          columnName="Addresses"
          selectedSortDirection={
            sortBy === SortBy.WEBSITE ? sortDirection : undefined
          }
          onChangeSortDirection={(sortDirection: SortDirection) =>
            onChangeSortOrder(SortBy.WEBSITE, sortDirection)
          }
        />
        {quickActionDefinitions && <th></th>}
      </tr>
    </thead>
  );
};

export default ListedSuppliersTableHeader;
