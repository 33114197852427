import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import RadioButtons from './RadioButtons';

const styles = () => ({
  radioButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    cursor: 'pointer',
  },
});
const ListRadioButtons = ({
  callout,
  classes,
  items,
  onChange,
  title,
  value,
}) => {
  const getItemStyle = () => {
    return {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      fontWeight: 'bold',
    };
  };
  return (
    <div style={{ position: 'relative' }}>
      <div className={classes.labelWrapper}>
        <label className="additional-label">{title}</label>
      </div>
      <div className={classes.radioButtonWrapper}>
        <RadioButtons
          items={items}
          value={value}
          onChange={onChange}
          itemStyle={() => getItemStyle()}
          selectButton={true}
        />
      </div>
      {callout && <div className={classes.callout}>{callout}</div>}
    </div>
  );
};

export default withStyles(styles)(ListRadioButtons);
