import { useQuery } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '../graphql-client';

const GetIntegratedEmail = graphql(`
  query IntegratedEmail($integratedEmailId: ID!) {
    integratedEmail(id: $integratedEmailId) {
      id
      to
      from
      cc
      bcc
      subject
      body
    }
  }
`);

const useGetIntegratedEmailLazy = (integratedEmailId: string) => {
  return useQuery({
    queryKey: ['integratedEmail', integratedEmailId],
    queryFn: () => {
      return gqlClient.request(GetIntegratedEmail, {
        integratedEmailId,
      });
    },
    enabled: false,
  });
};

export default useGetIntegratedEmailLazy;
