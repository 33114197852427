import Modal from '@payaca/components/plModal/Modal';
import { DocumentPrefillPreferencesControl } from './DocumentPrefillPreferencesControl';
import { ComponentProps, FC } from 'react';

export const DocumentPrefillPreferencesModal: FC<
  ComponentProps<typeof DocumentPrefillPreferencesControl> &
    ComponentProps<typeof Modal>
> = ({ documentId, contextTaskId, ...modalProps }) => {
  return (
    <Modal {...modalProps}>
      <DocumentPrefillPreferencesControl
        documentId={documentId}
        contextTaskId={contextTaskId}
        onSuccess={modalProps.onClose}
      />
    </Modal>
  );
};
