import { DashboardPermissions } from './dashboard.permissions';
import { DashboardRoles } from './dashboard.roles';

export const dashboardMappings = {
  [DashboardPermissions.GET_ACTION_REQUIRED_PAYMENT_OUTSTANDING_INVOICES]: [
    DashboardRoles.VIEWER,
  ],
  [DashboardPermissions.GET_ACTION_REQUIRED_PENDING_PROPOSALS]: [
    DashboardRoles.VIEWER,
  ],
  [DashboardPermissions.GET_PENDING_PAYMENTS]: [DashboardRoles.VIEWER],
  [DashboardPermissions.GET_ACTION_REQUIRED_DRAFT_JOBS]: [
    DashboardRoles.VIEWER,
  ],
};
