import { SuppliersPermissions } from './suppliers.permissions';
import { SuppliersRoles } from './suppliers.roles';

export const suppliersMappings = {
  [SuppliersPermissions.GET_SUPPLIERS]: [SuppliersRoles.VIEWER],
  [SuppliersPermissions.PERSIST_SUPPLIER]: [SuppliersRoles.PERSISTER],
  [SuppliersPermissions.ARCHIVE_SUPPLIER]: [SuppliersRoles.ARCHIVER],
  [SuppliersPermissions.GET_COLLECTION_MANAGEMENT_VIEW]: [
    SuppliersRoles.COLLECTION_MANAGER,
  ],
};
