import React, { FunctionComponent, useCallback } from 'react';

import FieldLabel from '../fieldLabel/FieldLabel';
import RadioButton from '../radioButton/RadioButton';

import { useFieldId } from '@payaca/hooks/useFieldId';

import './RadioGroupField.sass';

type Props = {
  name: string;
  value?: any;
  label?: string;
  description?: string | JSX.Element;
  isDisabled?: boolean;
  isRequired?: boolean;
  options: { value: any; label: string }[];
  onChange?: (value: { [key: string]: any }) => void;
  onTouch?: (fieldName: string) => void;
};

const RadioGroupField: FunctionComponent<React.PropsWithChildren<Props>> = ({
  name,
  value,
  label,
  description,
  isDisabled = false,
  isRequired = false,
  options,
  onChange,
  onTouch,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const onRadioClick = useCallback(
    (value: any) => {
      onChange &&
        onChange({
          [name]: value,
        });
      onTouch && onTouch(name);
    },
    [name, onChange]
  );

  const id = useFieldId(name);

  return (
    <fieldset id={name} className="radio-group-fieldset">
      <legend>
        <FieldLabel
          label={label}
          description={description}
          isRequired={isRequired}
        />
      </legend>
      <div className="options-container">
        {options.map((option, index: number) => {
          const val = option.value;
          return (
            <div className="option-container" key={index}>
              <RadioButton
                isSelected={value === option.value}
                onClick={() => onRadioClick(option.value)}
                isDisabled={isDisabled}
                name={name}
                id={`${id}-${val}`}
              />
              <FieldLabel label={option.label} id={`${id}-${val}`} />
            </div>
          );
        })}
      </div>
    </fieldset>
  );
};

export default RadioGroupField;
