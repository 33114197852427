import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  UpdateProjectInput,
  UpdateProjectMutation as _UpdateProjectMutation,
} from '@/gql/graphql';
import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';

const UpdateProjectMutation = graphql(`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      reference
      description
      owner {
        id
      }
      pipeline {
        id
      }
      currentPipelineStage {
        id
      }
    }
  }
`);

const useUpdateProject = (
  options?: UseMutationOptions<
    _UpdateProjectMutation,
    unknown,
    UpdateProjectInput
  >
) => {
  return useMutation({
    mutationFn: (input: UpdateProjectInput) => {
      return gqlClient.request(UpdateProjectMutation, { input });
    },
    ...options,
  });
};

export default useUpdateProject;
