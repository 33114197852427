import { FC } from 'react';

import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import { MaterialsListMaterials } from '@/api/queries/project/useGetProjectMaterialsListMaterials';
import {
  getAccountRegionFromCurrencyCode,
  getRegionalTextString,
} from '@payaca/helpers/internationalHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import { Link } from 'react-router-dom';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import { DeleteMaterialListMaterial } from './DeleteMaterialsListMaterialModal';
import { EditMaterialListMaterialQuantity } from './EditMaterialsListMaterialModal';
import { humanizeCurrency } from '@/helpers/currency';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import { useUserHasPermission } from '@/hooks/usePermissions';
import { TTableRowAction } from '@payaca/components/plManageableItemsList/components/Table';
import Progress from '@payaca/components/plProgress/Progress';
type MaterialsListMaterial = MaterialsListMaterials['items'][0];

export const SummaryPanel: FC<{
  projectId: number;
  summaryItems: MaterialsListMaterials;
  onPageChange: (page: number) => void;
  onRequestDeleteMaterialsListMaterial: (
    mlm: DeleteMaterialListMaterial
  ) => void;
  onRequestEditMaterialsListMaterial: (
    mlm: EditMaterialListMaterialQuantity
  ) => void;
}> = ({
  projectId,
  summaryItems,
  onPageChange,
  onRequestDeleteMaterialsListMaterial,
  onRequestEditMaterialsListMaterial,
}) => {
  const { data: regionPreferences } = useGetMyRegionPreferences();

  const region = regionPreferences
    ? getAccountRegionFromCurrencyCode(regionPreferences?.currency.code)
    : AccountRegions.UK;

  const hasPersistMlmPermission = useUserHasPermission({
    permissions: [MaterialsListPermissions.PERSIST_MATERIALS_LIST_MATERIAL],
  });

  const hasDeleteMlmPermission = useUserHasPermission({
    permissions: [MaterialsListPermissions.DELETE_MATERIALS_LIST_MATERIAL],
  });

  const itemActions: TTableRowAction<MaterialsListMaterial>[] = [];

  if (hasPersistMlmPermission) {
    itemActions.push({
      label: 'Edit quantity',
      onClick: onRequestEditMaterialsListMaterial,
    });
  }
  if (hasDeleteMlmPermission) {
    itemActions.push({
      label: 'Remove all',
      onClick: onRequestDeleteMaterialsListMaterial,
    });
  }

  return (
    <div className="@container">
      <ManageableItemsList.Table<MaterialsListMaterial>
        items={summaryItems.items}
        itemActions={itemActions}
        uniqueKey="id"
      >
        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header="Material"
          field="id"
          render={(id, row) => (
            <Link to={`/materials/${row.material.internalId}`}>
              {row.material.name}
            </Link>
          )}
        />
        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header="Order Progress"
          field="id"
          render={(id, row) => {
            return (
              <div className="flex items-center gap-4">
                <span className="grow">
                  {row.quantityOrdered}/{row.quantity}
                </span>

                <Progress
                  bars={[
                    {
                      progress: row.quantityOrdered,
                      total: row.quantity,
                      colour: 'purple',
                    },
                  ]}
                />
              </div>
            );
          }}
        />
        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header={`Forecast Cost (ex ${getRegionalTextString(
            region,
            RegionalStrings.VALUE_ADDED_TAX
          )})`}
          field="id"
          render={(id, row) => {
            if (row.material.suppliedBy.length) {
              const price = row.material.suppliedBy[0].price.unitPriceExcTax;

              return humanizeCurrency({
                value: Math.round(price.value * row.quantity),
                currency: price.currency,
              });
            }

            return;
          }}
        />

        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header={`Purchase Cost (ex ${getRegionalTextString(
            region,
            RegionalStrings.VALUE_ADDED_TAX
          )})`}
          field="id"
          render={(id, row) => {
            if (row.materialPurchaseRecords.length) {
              const purchased = row.materialPurchaseRecords.reduce(
                (acc, record) => {
                  return acc + (record.price.totalPriceExcTax?.value || 0);
                },
                0
              );

              const currency =
                row.materialPurchaseRecords[0].price.totalPriceExcTax?.currency;
              return humanizeCurrency({ value: purchased, currency });
            } else {
              return;
            }
          }}
        />
      </ManageableItemsList.Table>
      <ManageableItemsList.PaginationBar
        totalItems={summaryItems.totalCount}
        pageSize={summaryItems.limit}
        currentPage={summaryItems.offset / summaryItems.limit + 1}
        onPageChange={onPageChange}
      />
    </div>
  );
};
