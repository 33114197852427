import { Tag as TagType } from '@payaca/types/tagTypes';
import React, { FC, useCallback, useEffect, useState } from 'react';

import './TagControl.sass';
import Tag from '../tag/Tag';
import AddTagControl from './AddTagControl';
import MiniLoader from '../miniLoader/MiniLoader';
import IconButton from '../button/IconButton';
import { ButtonColourVariant } from '../button/enums';
import { faPlus, faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum TagControlAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

type Props = {
  label?: string;
  availableTags?: TagType[];
  tags: TagType[];
  isReadOnly?: boolean;
  isAddingTag?: boolean;
  isRemovingTag?: boolean;
  onRemoveTag?: (tagId: number) => void;
  onAddAvailableTag?: (tagId: number) => void;
  alignment?: TagControlAlignment;
};
const TagControl: FC<Props> = ({
  label,
  availableTags = [],
  tags,
  isAddingTag = false,
  isReadOnly = true,
  isRemovingTag = false,
  onRemoveTag,
  onAddAvailableTag,
  alignment = TagControlAlignment.RIGHT,
}: Props): JSX.Element => {
  const [showAddTagControl, setShowAddTagControl] = useState(false);
  const [removingTagId, setRemovingTagId] = useState<number>();
  const [hasMadeAddTagRequest, setHasMadeAddTagRequest] = useState(false);

  return (
    <div className={`tag-control ${alignment}-alignment`}>
      <div className="tag-control-inner">
        {tags.map((tag, i) => {
          return (
            <Tag
              tagText={tag.tagText}
              colour={tag.tagColour}
              key={`tag-${i}`}
              isProcessing={removingTagId === tag.id && isRemovingTag}
              isRemovable={!isReadOnly}
              onRemoveTag={
                !isReadOnly && onRemoveTag
                  ? () => {
                      setRemovingTagId(tag.id);
                      onRemoveTag(tag.id);
                    }
                  : undefined
              }
            />
          );
        })}
        {!isReadOnly && !!onAddAvailableTag && (
          <>
            {isAddingTag && hasMadeAddTagRequest && <MiniLoader />}
            {!(isAddingTag && hasMadeAddTagRequest) && (
              <>
                {showAddTagControl && (
                  <AddTagControl
                    availableTags={availableTags}
                    onAddAvailableTag={(tagId) => {
                      setHasMadeAddTagRequest(true);
                      onAddAvailableTag(tagId);
                      setShowAddTagControl(false);
                    }}
                    onBlur={() => setShowAddTagControl(false)}
                    userCanCreateNewTag={false}
                  />
                )}
                {!showAddTagControl && (
                  <>
                    {!tags.length && (
                      <small className="add-tags-prompt">
                        {label || 'Add tags'}
                      </small>
                    )}
                    <IconButton
                      icon={faPlus}
                      size="xs"
                      colourVariant={ButtonColourVariant.SECONDARY}
                      onClick={() => setShowAddTagControl(true)}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TagControl;
