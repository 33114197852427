import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import { CreateTagMutation, CreateTagMutationVariables } from '@/gql/graphql';

const CreateTag = graphql(`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      id
    }
  }
`);

const useCreateTag = (
  options?: UseMutationOptions<
    CreateTagMutation,
    unknown,
    CreateTagMutationVariables['input']
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(CreateTag, {
        input,
      });
    },
    ...options,
  });
};

export default useCreateTag;
