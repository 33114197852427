import { action } from 'typesafe-actions';
import {
  ActionType,
  ConfirmContinueProcessingCsvUploadAction,
  CreateCsvUploadAction,
  GetCsvUploadsAction,
  GetFileDownloadUrlAction,
  UpdateCsvUploadStatusAction,
} from './csvUploadTypes';
import {
  CsvUpload,
  CsvUploadDataType,
  CsvUploadMetadata,
} from '@payaca/types/csvUploadTypes';
import { createAsyncAction } from '../utils';

// GET CSV TEMPLATE
export const requestGetCsvTemplate = (
  dataType: CsvUploadDataType,
  // requestParam?: number
  metadata?: any
) => {
  return action(ActionType.REQUEST_GET_CSV_TEMPLATE, {
    dataType,
    metadata,
    // requestParam,
  });
};
export const requestGetCsvUpdateTemplate = (
  dataType: CsvUploadDataType,
  metadata?: any
  // requestParam?: number
) => {
  return action(ActionType.REQUEST_GET_CSV_UPDATE_TEMPLATE, {
    dataType,
    metadata,
    // requestParam,
  });
};
export const getCsvTemplateSuccess = (
  downloadUrl: string,
  guidelines: { header: string; guideline: string }[]
) => {
  return action(ActionType.GET_CSV_TEMPLATE_SUCCESS, {
    downloadUrl,
    guidelines,
  });
};
export const getCsvTemplateFailure = (error: Error, errorMessage: string) => {
  return action(
    ActionType.GET_CSV_TEMPLATE_FAILURE,
    { errorMessage },
    null,
    error
  );
};

// UPLOAD CSV
export const requestUploadCsv = (
  dataType: CsvUploadDataType,
  csvFile: File,
  metadata?: CsvUploadMetadata[CsvUploadDataType],
  cb?: any
) => {
  return action(
    ActionType.REQUEST_UPLOAD_CSV,
    {
      dataType,
      csvFile,
      metadata,
    },
    cb
  );
};
export const uploadCsvSuccess = () => {
  return action(ActionType.UPLOAD_CSV_SUCCESS);
};
export const uploadCsvFailure = (error: Error, errorMessage: any) => {
  return action(ActionType.UPLOAD_CSV_FAILURE, { errorMessage }, null, error);
};

// CLEAR CSV STATE
export const clearCsv = () => {
  return action(ActionType.CLEAR_CSV);
};

export const getCsvUploads = createAsyncAction<GetCsvUploadsAction>({
  request: ActionType.GET_CSV_UPLOADS_REQUEST,
  success: ActionType.GET_CSV_UPLOADS_SUCCESS,
  failure: ActionType.GET_CSV_UPLOADS_FAILURE,
})();

export const createCsvUpload = createAsyncAction<CreateCsvUploadAction>({
  request: ActionType.CREATE_CSV_UPLOAD_REQUEST,
  success: ActionType.CREATE_CSV_UPLOAD_SUCCESS,
  failure: ActionType.CREATE_CSV_UPLOAD_FAILURE,
})((dataType: CsvUploadDataType, { name, size }: File) => ({
  payload: {
    dataType,
    fileName: name,
    fileSize: size,
  },
}));

export const updateCsvUploadStatus =
  createAsyncAction<UpdateCsvUploadStatusAction>({
    request: ActionType.UPDATE_CSV_UPLOAD_STATUS_REQUEST,
    success: ActionType.UPDATE_CSV_UPLOAD_STATUS_SUCCESS,
    failure: ActionType.UPDATE_CSV_UPLOAD_STATUS_FAILURE,
  })(
    (
      csvUploadId: CsvUpload<CsvUploadDataType>['id'],
      status: 'uploading' | 'uploadAborted' | 'uploadComplete' | 'uploadFailed'
    ) => ({
      payload: {
        csvUploadId,
        status,
      },
    })
  );

export const confirmContinueProcessing =
  createAsyncAction<ConfirmContinueProcessingCsvUploadAction>({
    request: ActionType.CONFIRM_CONTINUE_PROCESSING_REQUEST,
  })((csvUploadId: CsvUpload<CsvUploadDataType>['id']) => ({
    payload: { csvUploadId },
  }));

export const getFileDownloadUrl = createAsyncAction<GetFileDownloadUrlAction>({
  request: ActionType.GET_FILE_DOWNLOAD_URL_REQUEST,
})(
  (
    csvUploadId: CsvUpload<CsvUploadDataType>['id'],
    cb: (url: string) => void
  ) => ({ payload: { csvUploadId }, meta: cb })
);
