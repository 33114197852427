import {
  FieldValidator,
  FieldValidationResult,
} from '@payaca/types/fieldValidationTypes';

export const validateForm = <TFormState extends Record<string, any>>(
  formState: TFormState,
  fieldValidators?: { [key: string]: FieldValidator<TFormState>[] }
): { [key: string]: FieldValidationResult } => {
  const validationState: { [key: string]: FieldValidationResult } = {};
  if (!fieldValidators) return validationState;

  for (const fieldName in fieldValidators) {
    const validators = fieldValidators[fieldName];

    if (!validators) continue;

    const validationResults = validators.map((validator) => {
      return validator(fieldName, formState);
    });

    const combinedValidationResult: FieldValidationResult =
      validationResults.reduce(
        (combinedValidationResult: FieldValidationResult, validationResult) => {
          if (!combinedValidationResult.isValid) {
            return combinedValidationResult;
          }

          if (!validationResult.isValid) {
            combinedValidationResult.isValid = false;
          }
          if (
            validationResult.errors?.length &&
            combinedValidationResult.errors
          ) {
            combinedValidationResult.errors =
              combinedValidationResult.errors.concat(validationResult.errors);
          }

          return combinedValidationResult;
        },
        { isValid: true, errors: [] }
      );

    validationState[fieldName] = combinedValidationResult;
  }

  return validationState;
};
