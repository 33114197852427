import { Reducer } from 'redux';

import { ActionType, State } from './listedMaterialsTypes';

export const initialState: State = {
  isGettingListedMaterialsPage: false,
  listedMaterialsPage: null,
};

const listedSuppliersReducer: Reducer<State> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_LISTED_MATERIALS_PAGE:
      return { ...state, isGettingListedMaterialsPage: true };
    case ActionType.GET_LISTED_MATERIALS_PAGE_FAILURE:
      return {
        ...state,
        listedMaterialsPage: null,
        isGettingListedMaterialsPage: false,
      };
    case ActionType.GET_LISTED_MATERIALS_PAGE_SUCCESS:
      return {
        ...state,
        listedMaterialsPage: action.payload.listedMaterialsPage,
        isGettingListedMaterialsPage: false,
      };
    case ActionType.CLEAR_LISTED_MATERIALS_PAGE:
      return {
        ...state,
        listedMaterialsPage: null,
        isGettingListedMaterialsPage: false,
      };
    default:
      return state;
  }
};

export default listedSuppliersReducer;
