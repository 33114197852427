import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { actions as appActions } from '@/api/app';

import './Banner.sass';

class Banner extends Component {
  componentDidMount() {
    this.timer = setInterval(() => this.closeBanner(), 6000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  closeBanner() {
    this.props.app.hideBanner();
  }

  getbannerType() {
    const Banner = {
      error: {
        message:
          'Sorry, something went wrong. We are still in the testing phase, please click the chat button and let us know what went wrong.',
        icon: faExclamationCircle,
        class: 'error',
      },
    };
    return get(Banner, this.props.options.type);
  }

  render() {
    const { text } = this.props.options;
    const banner = this.getbannerType();
    if (text) {
      banner.message = text;
    }

    const bannerClass = 'banner' + (banner.class ? ` ${banner.class}` : '');
    return (
      <div className={bannerClass}>
        <div className="banner-message-wrapper">
          {banner.icon && (
            <FontAwesomeIcon icon={banner.icon} className="type-icon" />
          )}
          <div>{banner.message}</div>
        </div>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() => this.closeBanner()}
          className="close-icon"
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    app: bindActionCreators(appActions, dispatch),
  })
)(Banner);
