import React, { FunctionComponent, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as accountActions from '@payaca/store/account/accountActions';
import { actions as usersActions } from '../../../api/users';
import { TrialPeriodCountdownBanner } from '@/ui/components';
import UserSeatsExceededBanner from '../userSeatsExceededBanner/UserSeatsExceededBanner';
import TrialPeriodExpiryBanner from '../trialPeriodExpiryBanner/TrialPeriodExpiryBanner';
import TrialSubscriptionUpgradeBanner from '../trialSubscriptionUpgradeBanner/TrialSubscriptionUpgradeBanner';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { getUserRoles } from '@/utils/stateAccessors';
import { useSelector } from '@/api/state';

const AccountAccessBanner: FunctionComponent = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const userRoles = useSelector(getUserRoles);

  const accountAccessInformation = useSelector((state) => {
    return state.account.accountAccessInformation;
  });

  const {
    shouldRenderTrialPeriodCountdownBanner = false,
    shouldRenderTrialPeriodExpiryBanner = false,
    shouldRenderUserSeatsExceededBanner = false,
    shouldRenderTrialSubscriptionUpgradeBanner = false,
  } = useMemo(() => {
    if (!accountAccessInformation) {
      return {};
    }
    const {
      hasActiveSubscription,
      isTrialPeriodExpired,
      trialPeriodDaysRemaining,
    } = accountAccessInformation;
    return {
      shouldRenderTrialPeriodCountdownBanner:
        !isTrialPeriodExpired &&
        !hasActiveSubscription &&
        trialPeriodDaysRemaining !== null,
      shouldRenderTrialPeriodExpiryBanner:
        isTrialPeriodExpired &&
        !hasActiveSubscription &&
        trialPeriodDaysRemaining !== null,
      shouldRenderUserSeatsExceededBanner:
        hasActiveSubscription &&
        accountAccessInformation.hasExceededActiveSubscriptionUserSeats,
      shouldRenderTrialSubscriptionUpgradeBanner:
        hasActiveSubscription && accountAccessInformation.temporarySubscription,
    };
  }, [accountAccessInformation]);

  useEffect(() => {
    dispatch(accountActions.requestGetAccountAccessInformation());
    if (userHasRequiredPermission(userRoles, [AccountsPermissions.GET_USERS])) {
      dispatch(usersActions.getAccountUsers());
    }
  }, [dispatch, userRoles]);

  return (
    <React.Fragment>
      {shouldRenderTrialPeriodCountdownBanner && <TrialPeriodCountdownBanner />}
      {shouldRenderUserSeatsExceededBanner && (
        <PermissionGuard
          renderIfHasPermissions={[AccountsPermissions.UPDATE_SUBSCRIPTION]}
        >
          <UserSeatsExceededBanner />
        </PermissionGuard>
      )}
      {shouldRenderTrialPeriodExpiryBanner && <TrialPeriodExpiryBanner />}
      {shouldRenderTrialSubscriptionUpgradeBanner && (
        <TrialSubscriptionUpgradeBanner />
      )}
    </React.Fragment>
  );
};

export default AccountAccessBanner;
