import { Reducer } from 'redux';

import { ActionType, State } from './formsTypes';

export const initialState: State = {
  availableForms: [],
  isGettingForms: false,
  formData: {},
  formProgress: {},
  searchItemResults: [],
  isSearchingFormItems: false,
  isGettingFormData: false,
  isSubmittingForm: false,
  lastUpdated: new Date(),
};

const formsReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SET_FORM_PROGRESS:
      return {
        ...state,
        formProgress: action.payload,
      };
    case ActionType.REQUEST_GET_AVAILABLE_FORMS:
      return {
        ...state,
        availableForms: [],
        isGettingForms: true,
      };
    case ActionType.GET_AVAILABLE_FORMS_FAILURE:
      return {
        ...state,
        availableForms: [],
        isGettingForms: false,
      };
    case ActionType.GET_AVAILABLE_FORMS_SUCCESS:
      return {
        ...state,
        availableForms: [...action.payload.forms],
        isGettingForms: false,
      };
    case ActionType.REQUEST_GET_FORM_DATA:
      return {
        ...state,
        formData: {},
        isGettingFormData: true,
        lastUpdated: null,
      };
    case ActionType.GET_FORM_DATA_SUCCESS:
      return {
        ...state,
        formData: { ...action.payload.formData },
        isGettingFormData: false,
        lastUpdated: null,
      };
    case ActionType.GET_FORM_DATA_FAILURE:
    case ActionType.UPDATE_FORM_DATA_FAILURE:
      return {
        ...state,
        formData: {},
        isGettingFormData: false,
      };
    case ActionType.UPDATE_FORM_DATA_SUCCESS:
      return {
        ...state,
        lastUpdated: new Date(),
      };
    case ActionType.REQUEST_SUBMIT_FORM:
      return {
        ...state,
        isSubmittingForm: true,
      };
    case ActionType.SUBMIT_FORM_FAILURE:
      return {
        ...state,
        isSubmittingForm: false,
      };
    case ActionType.SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        formData: {},
        isSubmittingForm: false,
      };
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        formData: {},
        formProgress: {},
        isGettingFormData: false,
        isSubmittingForm: false,
        searchItemResults: [],
        isSearchingFormItems: false,
        lastUpdated: null,
      };
    case ActionType.REQUEST_SEARCH_FORM_ITEMS:
      return {
        ...state,
        isSearchingFormItems: true,
      };
    case ActionType.SEARCH_FORM_ITEMS_SUCCESS:
      return {
        ...state,
        searchItemResults: action.payload.items,
        isSearchingFormItems: false,
      };
    case ActionType.SEARCH_FORM_ITEMS_FAILURE:
      return {
        ...state,
        isSearchingFormItems: false,
      };
    default:
      return state;
  }
};

export default formsReducer;
