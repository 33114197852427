import React, { FC } from 'react';

import RadioButton from './RadioButton';

type Props = {
  items: any;
  onChange: (itemValue: any) => void;
  value: any;
};
const RadioButtons: FC<Props> = ({
  items,
  onChange,
  value,
}: Props): JSX.Element => {
  return items.map((item: any, index: number) => {
    const isSelected = item.value === value || item.altSelected;
    const onClick = () => onChange(item.value);
    return (
      <RadioButton
        key={`item-${index}`}
        isSelected={isSelected}
        onClick={onClick}
        label={item.label}
      />
    );
  });
};

export default RadioButtons;
