import { FormElement } from '@payaca/types/formElementTypes';
import { createValidatedField } from '@/ui/pages/formPage/formUtils/createValidatedField';
import React, { FC, useEffect, useState } from 'react';
import CollapsiblePanel, { CollapsiblePanelStyleVariant } from '@payaca/components/collapsiblePanel/CollapsiblePanel';
import { InfoSquare } from 'react-iconly';

interface Props {
  elem: FormElement;
  formState: any;
  changeHandler: any;
  onFieldTouch: any;
  touchedState: any;
  validationState: any;
  uiHandlers: any;
}

export const CollapsableGroupElement: FC<Props> = ({
  elem,
  formState,
  changeHandler,
  onFieldTouch,
  touchedState,
  validationState,
  uiHandlers,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!Object.keys(formState).length || !elem?.children?.length) {
      return;
    }
    // we should open our panel if any of the required fields are not yet complete
    const requiredFieldIsMissing = !!elem?.children?.find(
      ({ isOptional, id }: FormElement) => !isOptional && !formState[id]
    );
    if (requiredFieldIsMissing) {
      setIsOpen(true);
    }
  }, [elem, formState]);

  return (
    <div className={'collapsible-group-wrapper'}>
      <CollapsiblePanel
        title={elem.title}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        styleVariant={CollapsiblePanelStyleVariant.OUTSIZE}
        watchSize={true}
      >
        <div className={'collapsible-group-inner'}>
          {elem.children &&
            elem.children.map((childElem: FormElement, ind: number) => (
              <div key={ind}>
                {createValidatedField(
                  childElem,
                  formState,
                  formState?.[childElem.id],
                  changeHandler,
                  onFieldTouch,
                  touchedState,
                  validationState,
                  uiHandlers,
                  ind
                )}
              </div>
            ))}
        </div>
      </CollapsiblePanel>
      {elem.description && (
        <div className={'collapsible-group-description'}>
          <InfoSquare />
          <span>{elem.description}</span>
        </div>
      )}
    </div>
  );
};
