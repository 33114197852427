import React, { FC, useMemo } from 'react';
import BasicField from '@payaca/components/basicField/BasicField';
import SwitchField from '@payaca/components/switchField/SwitchField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { useSelector } from '@/api/state';

interface Props {
  formState: any;
  onUpdateAutomation: (changes: any) => void;
}

export const CreateEditAutomationHeader: FC<Props> = ({
  formState,
  onUpdateAutomation,
}) => {
  const isPerformingAutomationAction = useSelector(
    (state) => state.automations.isPerformingAutomationAction
  );

  const titleValidation = useMemo(() => {
    const isValid = !!formState?.title && formState.title.length <= 80;
    return {
      isValid,
      errors: isValid
        ? []
        : ['Your title must be between 0 and 80 characters in length'],
    };
  }, [formState]);

  return (
    <div className={'create-edit-automation-header'}>
      <div className={'create-edit-automation-header-title'}>
        {!isPerformingAutomationAction && (
          <ValidatedFieldWrapper validationResult={titleValidation}>
            <BasicField
              name={'title'}
              value={formState?.title}
              styleVariant={InputStyleVariant.UNSTYLED}
              onChange={onUpdateAutomation}
            />
          </ValidatedFieldWrapper>
        )}
      </div>
      <SwitchField
        name="isActive"
        value={formState?.isActive}
        onChange={onUpdateAutomation}
        label="Off/On"
      />
    </div>
  );
};
