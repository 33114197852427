export enum DealTemplateGroups {
  DEAL = 'Deal',
  TAGS = 'Tags',
  TASKS = 'Tasks',
  LINE_ITEM_GROUPS = 'Item Groups',
  LINE_ITEMS = 'Line Items',
}

export interface DealTemplateLineItem {
  name: string | null;
  description: string | null;
  price: number;
  vatAmount: number | null;
  isReverseChargeVat: boolean;
  vatIncluded: boolean | null;
  taxRateId: number;
  lineItemId: number | null;
  cisDeductionRate?: number | null;
}

export interface DealTemplateLineItemGroup {
  name: string;
  description: string;
  jobLineItems: DealTemplateLineItem[];
}

export interface DealTemplateTask {
  name: string;
  notes: string;
  checklistItems?: string[];
  formId?: number;
}

export interface DealTemplate {
  id?: number;
  title: string;
  dealDescription?: string;
  tags: string[];
  tasks: DealTemplateTask[];
  // TODO: Template proposals
  // jobLineItemGroups: DealTemplateLineItemGroup[];
  // jobLineItems: DealTemplateLineItem[];
}

export interface DealTemplateItem {
  label: string;
  value: any;
  group: DealTemplateGroups;
}
