import { action } from 'typesafe-actions';

import {
  Invoice,
  InvoiceLine,
  PaymentReconciliationRecord,
} from '@payaca/types/invoiceTypes';

import { createAsyncAction } from '../utils';

import {
  ActionType,
  CreateInvoiceRequestData,
  GetInvoiceGeneratedPdfUrl,
  GetListedInvoices,
  GetListedInvoicesRequestData,
  GetUpcomingReference,
  PushInvoiceToAccountingIntegrationsAction,
  SendInvoiceRequestData,
  UpdateInvoiceRequestData,
  VoidInvoiceAction,
} from './invoicesTypes';

export const requestGetInvoice = (
  invoiceId: number,
  callback?: (invoice: Invoice) => void
) =>
  action(ActionType.REQUEST_GET_INVOICE, {
    invoiceId,
    callback,
  });

export const getInvoiceSuccess = (invoiceId: number, invoice: Invoice) =>
  action(ActionType.GET_INVOICE_SUCCESS, {
    invoiceId,
    invoice,
  });

export const getInvoiceFailure = (invoiceId: number, error: Error) =>
  action(
    ActionType.GET_INVOICE_FAILURE,
    {
      invoiceId,
    },
    null,
    error
  );

export const requestGetInvoiceLine = (
  invoiceLineId: number,
  callback?: (invoiceLine: InvoiceLine) => void
) =>
  action(ActionType.REQUEST_GET_INVOICE_LINE, {
    invoiceLineId,
    callback,
  });

export const getInvoiceLineSuccess = (
  invoiceLineId: number,
  invoiceLine: InvoiceLine
) =>
  action(ActionType.GET_INVOICE_LINE_SUCCESS, {
    invoiceLineId,
    invoiceLine,
  });

export const getInvoiceLineFailure = (invoiceLineId: number, error: Error) =>
  action(
    ActionType.GET_INVOICE_LINE_FAILURE,
    {
      invoiceLineId,
    },
    null,
    error
  );

export const requestGetInvoicesForDeal = (
  dealId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_GET_INVOICES_FOR_DEAL, {
    dealId,
    callback,
  });

export const getInvoicesForDealSuccess = (invoices: Array<Invoice>) =>
  action(ActionType.GET_INVOICES_FOR_DEAL_SUCCESS, invoices);

export const getInvoicesForDealFailure = (error: Error) =>
  action(ActionType.GET_INVOICES_FOR_DEAL_FAILURE, null, null, error);

export const requestGetInvoiceLinesForDeal = (
  dealId: number,
  callback?: () => void
) => {
  return action(ActionType.REQUEST_GET_INVOICE_LINES_FOR_DEAL, {
    dealId,
    callback,
  });
};

export const getInvoiceLinesForDealSuccess = (
  invoiceLines: Array<InvoiceLine>
) => action(ActionType.GET_INVOICE_LINES_FOR_DEAL_SUCCESS, invoiceLines);

export const getInvoiceLinesForDealFailure = (error: Error) =>
  action(ActionType.GET_INVOICE_LINES_FOR_DEAL_FAILURE, null, null, error);

export const requestGetInvoiceLinesForInvoice = (
  invoiceId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_GET_INVOICE_LINES_FOR_INVOICE, {
    invoiceId,
    callback,
  });

export const getInvoiceLinesForInvoiceSuccess = (
  invoiceLines: Array<InvoiceLine>
) => action(ActionType.GET_INVOICE_LINES_FOR_INVOICE_SUCCESS, invoiceLines);

export const getInvoiceLinesForInvoiceFailure = (error: Error) =>
  action(ActionType.GET_INVOICE_LINES_FOR_INVOICE_FAILURE, null, null, error);

export const clearInvoices = () => action(ActionType.CLEAR_INVOICES);

export const clearInvoiceLines = () => action(ActionType.CLEAR_INVOICE_LINES);

export const clearInvoice = (invoiceId: number) =>
  action(ActionType.CLEAR_INVOICE, { invoiceId });

export const clearInvoiceLine = (invoiceLineId: number) =>
  action(ActionType.CLEAR_INVOICE_LINE, { invoiceLineId });

export const requestMarkInvoiceAsSent = (
  invoiceId: number,
  callback?: () => void,
  errorCallback?: (errorMessages: string[]) => void
) =>
  action(ActionType.REQUEST_MARK_INVOICE_AS_SENT, {
    invoiceId,
    callback,
    errorCallback,
  });

export const markInvoiceAsSentSuccess = () =>
  action(ActionType.MARK_INVOICE_AS_SENT_SUCCESS);

export const markInvoiceAsSentFailure = (error: Error) =>
  action(ActionType.MARK_INVOICE_AS_SENT_FAILURE, null, null, error);

export const requestCreateInvoice = (
  createInvoiceRequestData: CreateInvoiceRequestData,
  callback?: (invoiceId: number) => void,
  onErrorCallback?: () => void
) =>
  action(ActionType.REQUEST_CREATE_INVOICE, {
    createInvoiceRequestData,
    callback,
    onErrorCallback,
  });

export const createInvoiceSuccess = () =>
  action(ActionType.CREATE_INVOICE_SUCCESS);

export const createInvoiceFailure = (error: Error) =>
  action(ActionType.CREATE_INVOICE_FAILURE, null, null, error);

export const requestSendInvoice = (
  sendInvoiceRequestData: SendInvoiceRequestData,
  callback?: () => void,
  errorCallback?: (errorMessages: string[]) => void
) =>
  action(ActionType.REQUEST_SEND_INVOICE, {
    sendInvoiceRequestData,
    callback,
    errorCallback,
  });

export const sendInvoiceSuccess = () => action(ActionType.SEND_INVOICE_SUCCESS);

export const sendInvoiceFailure = (error: Error) =>
  action(ActionType.SEND_INVOICE_FAILURE, null, null, error);

export const requestGetPaymentReconciliationRecordsForDeal = (
  dealId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_GET_PAYMENT_RECONCILIATION_RECORDS_FOR_DEAL, {
    dealId,
    callback,
  });

export const clearPaymentReconciliationRecords = () =>
  action(ActionType.CLEAR_PAYMENT_RECONCILIATION_RECORDS);

export const getPaymentReconciliationRecordSuccess = (
  paymentReconciliationRecordId: number,
  paymentReconciliationRecord: PaymentReconciliationRecord
) =>
  action(ActionType.GET_PAYMENT_RECONCILIATION_RECORD_SUCCESS, {
    paymentReconciliationRecordId,
    paymentReconciliationRecord,
  });

export const getPaymentReconciliationRecordsForDealSuccess = (
  paymentReconciliationRecords: Array<PaymentReconciliationRecord>
) =>
  action(
    ActionType.GET_PAYMENT_RECONCILIATION_RECORDS_FOR_DEAL_SUCCESS,
    paymentReconciliationRecords
  );

export const requestUpdateInvoice = (
  updateInvoiceRequestData: UpdateInvoiceRequestData,
  callback?: () => void
) =>
  action(ActionType.REQUEST_UPDATE_INVOICE, {
    updateInvoiceRequestData,
    callback,
  });

export const updateInvoiceSuccess = (invoiceId: number) =>
  action(ActionType.UPDATE_INVOICE_SUCCESS, {
    invoiceId,
  });

export const updateInvoiceFailure = (invoiceId: number, error: Error) =>
  action(ActionType.UPDATE_INVOICE_FAILURE, { invoiceId }, null, error);

export const requestGetInvoiceValidationResult = (
  invoiceId: number,
  callback?: (validationResult: { isValid: boolean; errors: string[] }) => void
) =>
  action(ActionType.REQUEST_GET_INVOICE_VALIDATION_RESULT, {
    invoiceId,
    callback,
  });

export const getInvoiceValidationResultSuccess = (validationResult: {
  isValid: boolean;
  errors: string[];
}) =>
  action(ActionType.GET_INVOICE_VALIDATION_RESULT_SUCCESS, {
    validationResult,
  });

export const getInvoiceValidationResultFailure = (error: Error) =>
  action(ActionType.GET_INVOICE_VALIDATION_RESULT_FAILURE, null, null, error);

export const voidInvoice = createAsyncAction<VoidInvoiceAction>({
  request: ActionType.REQUEST_VOID_INVOICE,
  success: ActionType.VOID_INVOICE_SUCCESS,
  failure: ActionType.VOID_INVOICE_FAILURE,
})((payload: VoidInvoiceAction['request']['payload']) => ({ payload }));

export const getInvoiceGeneratedPdfUrl =
  createAsyncAction<GetInvoiceGeneratedPdfUrl>({
    request: ActionType.REQUEST_GET_INVOICE_GENERATED_PDF_URL,
  })((payload: GetInvoiceGeneratedPdfUrl['request']['payload']) => ({
    payload,
  }));

export const getUpcomingReference = createAsyncAction<GetUpcomingReference>({
  request: ActionType.GET_UPCOMING_REFERENCE_REQUEST,
})((payload: GetUpcomingReference['request']['payload']) => ({
  payload,
}));

export const pushInvoiceToAccountingIntegrations =
  createAsyncAction<PushInvoiceToAccountingIntegrationsAction>({
    request: ActionType.PUSH_INVOICE_TO_ACCOUNTING_INTEGRATIONS_REQUEST,
  })(
    (
      payload: PushInvoiceToAccountingIntegrationsAction['request']['payload']
    ) => ({
      payload,
    })
  );

export const getListedInvoices = createAsyncAction<GetListedInvoices>({
  request: ActionType.GET_LISTED_INVOICES_REQUEST,
})((payload: GetListedInvoices['request']['payload']) => ({
  payload,
}));
