import { ArchivableEntity } from './archivableEntityTypes';

export interface Tag extends ArchivableEntity {
  id: number;
  tagText: string;
  tagColour: string;
  accountId?: number;
  isSystemManaged: boolean;
}

export interface TaggableEntity {
  tags: Tag[];
}

export enum TaggableEntityType {
  DEAL = 'deal',
  JOB = 'job',
  CUSTOMER = 'customer',
  LINE_ITEM = 'line-item',
  SCHEDULED_EVENT = 'scheduled-event',
  MATERIAL = 'material',
  SUPPLIER = 'supplier',
}
