import React, { FunctionComponent } from 'react';
import './LabelValuePair.css';

type Props = {
  label: string | React.ReactNode;
  value?: string | React.ReactNode;
  suffixLabelWith?: string;
  noBreak?: boolean;
  className?: string;
};

const LabelValuePair: FunctionComponent<Props> = ({
  label,
  value,
  suffixLabelWith = ':',
  noBreak = false,
  className = '',
}: Props): JSX.Element => {
  return (
    <div className={`label-value-pair${className ? ` ${className}` : ''}`}>
      <span className="label">
        {label}
        {suffixLabelWith}
      </span>
      <span className={`value${noBreak ? ' no-break' : ''}`}>{value}</span>
    </div>
  );
};

export default LabelValuePair;
