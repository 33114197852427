import { createAsyncAction } from '../utils';

import {
  ActionType,
  CreateTemplate,
  DeleteTemplate,
  GetListedTemplatesPage,
  GetTemplate,
  UpdateTemplate,
} from './templatesTypes';

export const getListedTemplatesPage = createAsyncAction<GetListedTemplatesPage>(
  {
    request: ActionType.GET_LISTED_TEMPLATES_PAGE_REQUEST,
    success: ActionType.GET_LISTED_TEMPLATES_PAGE_SUCCESS,
  }
)((payload: GetListedTemplatesPage['request']['payload']) => ({
  payload,
}));

export const getTemplate = createAsyncAction<GetTemplate>({
  request: ActionType.GET_TEMPLATE_REQUEST,
})((payload: GetTemplate['request']['payload']) => ({
  payload,
}));

export const updateTemplate = createAsyncAction<UpdateTemplate>({
  request: ActionType.UPDATE_TEMPLATE_REQUEST,
  success: ActionType.UPDATE_TEMPLATE_SUCCESS,
})((payload: UpdateTemplate['request']['payload']) => ({
  payload,
}));

export const createTemplate = createAsyncAction<CreateTemplate>({
  request: ActionType.CREATE_TEMPLATE_REQUEST,
  success: ActionType.CREATE_TEMPLATE_SUCCESS,
})((payload: CreateTemplate['request']['payload']) => ({
  payload,
}));

export const deleteTemplate = createAsyncAction<DeleteTemplate>({
  request: ActionType.DELETE_TEMPLATE_REQUEST,
})((payload: DeleteTemplate['request']['payload']) => ({
  payload,
}));
