import { Reducer } from 'redux';

import { mergeFetchedEntities } from '../utils';

import { ActionType, State } from './documentTypes';

export const initialState: State = {
  documentsStore: {},
  isGettingDocuments: false,
  isCreatingDocument: false,
  isSendingDocument: false,
  isEditingDocument: false,
};

const documentReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_DOCUMENTS_FOR_DEAL:
      return {
        ...state,
        isGettingDocuments: true,
      };
    case ActionType.GET_DOCUMENTS_FOR_DEAL_FAILURE:
      return {
        ...state,
        isGettingDocuments: false,
      };

    case ActionType.REQUEST_GET_DOCUMENT: {
      const fetchedEntity =
        state.documentsStore && state.documentsStore[action.payload.documentId];

      return {
        ...state,
        documentsStore: {
          ...state.documentsStore,
          [action.payload.documentId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }

    case ActionType.GET_DOCUMENT_SUCCESS:
    case ActionType.RENAME_DOCUMENT_SUCCESS: {
      const fetchedEntity =
        state.documentsStore && state.documentsStore[action.payload.documentId];

      return {
        ...state,
        documentsStore: {
          ...state.documentsStore,
          [action.payload.documentId]: {
            ...fetchedEntity,
            entity: action.payload.document,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.GET_DOCUMENT_FAILURE: {
      const fetchedEntity =
        state.documentsStore && state.documentsStore[action.payload.documentId];

      return {
        ...state,
        documentsStore: {
          ...state.documentsStore,
          [action.payload.documentId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }

    case ActionType.GET_DOCUMENTS_FOR_DEAL_SUCCESS:
      return {
        ...state,
        documentsStore: mergeFetchedEntities({
          cache: state.documentsStore,
          values: action.payload,
        }),
        isGettingDocuments: false,
      };
    case ActionType.REQUEST_CREATE_DOCUMENT:
    case ActionType.REQUEST_CREATE_DOCUMENT_LINK:
      return {
        ...state,
        isCreatingDocument: true,
      };
    case ActionType.CREATE_DOCUMENT_SUCCESS:
    case ActionType.CREATE_DOCUMENT_FAILURE:
    case ActionType.CREATE_DOCUMENT_LINK_SUCCESS:
    case ActionType.CREATE_DOCUMENT_LINK_FAILURE:
      return {
        ...state,
        isCreatingDocument: false,
      };
    case ActionType.REQUEST_SEND_DOCUMENT:
      return {
        ...state,
        isSendingDocument: true,
      };
    case ActionType.SEND_DOCUMENT_SUCCESS:
    case ActionType.SEND_DOCUMENT_FAILURE:
      return {
        ...state,
        isSendingDocument: false,
      };

    case ActionType.REQUEST_EDIT_DOCUMENT:
      return {
        ...state,
        isEditingDocument: true,
      };
    case ActionType.EDIT_DOCUMENT_SUCCESS:
    case ActionType.EDIT_DOCUMENT_FAILURE:
      return {
        ...state,
        isEditingDocument: false,
      };

    case ActionType.CLEAR_DOCUMENTS:
      return initialState;
    default:
      return state;
  }
};

export default documentReducer;
