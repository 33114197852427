import { useMemo } from 'react';

import { validations } from '@/helpers/formHelper';

type FieldValidator = {
  validate: (x: any) => boolean;
  error: string;
};

export const useLineItemInfoFormValidators = () => {
  const validators = useMemo(
    () => ({
      description: [validations.required],
      quantity: [validations.required],
    }),
    []
  );
  return validators as Record<string, Array<FieldValidator>>;
};
