import './types';

import { ReactEditor } from 'slate-react';

export const enhanceEditor =
  (opts: {
    type: string;
    isInline: boolean;
    isVoid: boolean;
    isMarkableVoid: boolean;
  }) =>
  (editor: ReactEditor) => {
    const { isInline, isVoid, markableVoid } = editor;

    editor.isInline = (element) =>
      element.type === opts.type ? opts.isInline : isInline(element);

    editor.isVoid = (element) =>
      element.type === opts.type ? opts.isVoid : isVoid(element);

    editor.markableVoid = (element) =>
      element.type === opts.type ? opts.isMarkableVoid : markableVoid(element);

    return editor;
  };
