import { Reducer } from 'redux';

import { mergeFetchedEntities } from '../utils';

import { ActionType, State } from './jobContentTypes';

export const initialState: State = {
  isUpdatingJobContent: false,

  isCreatingJobLineItemGroup: false,
  isUpdatingJobLineItemGroup: false,
  isDeletingJobLineItemGroup: false,
  isAddingLineItemGroupToJobContent: false,
  isRemovingAttachmentFromJobLineItem: false,
  isAddingAttachmentToJobLineItem: false,

  isCreatingJobLineItem: false,
  isUpdatingJobLineItem: false,
  isDeletingJobLineItem: false,

  jobContents: {},
  jobLineItemAttachments: {},
  jobLineItemGroups: {},
  jobLineItems: {},

  jobContentUpdateResults: {},
  jobLineItemGroupUpdateResults: {},
  jobLineItemUpdateResults: {},
};

const jobsReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_JOB_CONTENT:
    case ActionType.REQUEST_GET_JOB_CONTENT_WITH_JOB_GROUPS_AND_JOB_LINE_ITEMS: {
      const fetchedEntity =
        state.jobContents && state.jobContents[action.payload.jobContentId];

      return {
        ...state,
        jobContents: {
          ...state.jobContents,
          [action.payload.jobContentId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_JOB_CONTENT_SUCCESS: {
      const fetchedEntity =
        state.jobContents && state.jobContents[action.payload.jobContentId];

      return {
        ...state,
        jobContents: {
          ...state.jobContents,
          [action.payload.jobContentId]: {
            ...fetchedEntity,
            entity: action.payload.jobContent,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.GET_JOB_CONTENT_FAILURE: {
      const fetchedEntity =
        state.jobContents && state.jobContents[action.payload.jobContentId];

      return {
        ...state,
        jobContents: {
          ...state.jobContents,
          [action.payload.jobContentId]: {
            ...fetchedEntity,
            fetchFailedAtAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.CLEAR_JOB_CONTENT: {
      const jobContents = state.jobContents || {};
      delete jobContents[action.payload.jobContentId];

      return {
        ...state,
        jobContents: jobContents,
      };
    }
    case ActionType.CLEAR_JOB_CONTENTS: {
      return {
        ...state,
        jobContents: {},
      };
    }

    // UPDATE JOB
    case ActionType.REQUEST_UPDATE_JOB_CONTENT:
      return {
        ...state,
        isUpdatingJobContent: true,
        jobContentUpdateResults: {
          ...state.jobContentUpdateResults,
          [action.payload.updateJobContentRequestData.jobContentId]: {
            isUpdatedSuccessfully: undefined,
            updateError: undefined,
          },
        },
      };
    case ActionType.UPDATE_JOB_CONTENT_SUCCESS:
      return {
        ...state,
        isUpdatingJobContent: false,
        jobUpdateResults: {
          ...state.jobContentUpdateResults,
          [action.payload.jobContentId]: {
            isUpdatedSuccessfully: true,
            updateError: undefined,
          },
        },
      };
    case ActionType.UPDATE_JOB_CONTENT_FAILURE:
      return {
        ...state,
        isUpdatingJobContent: false,
        jobContentUpdateResults: {
          ...state.jobContentUpdateResults,
          [action.payload.jobContentId]: {
            isUpdatedSuccessfully: false,
            updateError: action.error,
          },
        },
      };

    case ActionType.GET_JOB_LINE_ITEM_ATTACHMENT_SUCCESS: {
      const fetchedEntity =
        state.jobLineItemAttachments &&
        state.jobLineItemAttachments[action.payload.jobLineItemAttachmentId];

      return {
        ...state,
        jobLineItemAttachments: {
          ...state.jobLineItemAttachments,
          [action.payload.jobLineItemAttachmentId]: {
            ...fetchedEntity,
            entity: action.payload.jobLineItemAttachment,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.GET_JOB_LINE_ITEM_ATTACHMENT_FAILURE: {
      const fetchedEntity =
        state.jobLineItemAttachments &&
        state.jobLineItemAttachments[action.payload.jobId];

      return {
        ...state,
        jobLineItemAttachments: {
          ...state.jobLineItemAttachments,
          [action.payload.jobLineItemAttachmentId]: {
            ...fetchedEntity,
            fetchFailedAtAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.CLEAR_JOB_LINE_ITEM_ATTACHMENT: {
      const jobLineItemAttachments = state.jobLineItemAttachments || {};
      delete jobLineItemAttachments[action.payload.jobLineItemAttachmentId];

      return {
        ...state,
        jobLineItemAttachments: jobLineItemAttachments,
      };
    }
    case ActionType.CLEAR_JOB_LINE_ITEM_ATTACHMENTS: {
      return {
        ...state,
        jobLineItemAttachments: {},
      };
    }

    case ActionType.GET_JOB_LINE_ITEM_GROUP_SUCCESS: {
      const fetchedEntity =
        state.jobLineItemGroups &&
        state.jobLineItemGroups[action.payload.jobLineItemGroupId];

      return {
        ...state,
        jobLineItemGroups: {
          ...state.jobLineItemGroups,
          [action.payload.jobLineItemGroupId]: {
            ...fetchedEntity,
            entity: action.payload.jobLineItemGroup,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.GET_JOB_LINE_ITEM_GROUP_FAILURE: {
      const fetchedEntity =
        state.jobLineItemGroups &&
        state.jobLineItemGroups[action.payload.jobId];

      return {
        ...state,
        jobLineItemGroups: {
          ...state.jobLineItemGroups,
          [action.payload.jobLineItemGroupId]: {
            ...fetchedEntity,
            fetchFailedAtAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.CLEAR_JOB_LINE_ITEM_GROUP: {
      const jobLineItemGroups = state.jobLineItemGroups || {};
      delete jobLineItemGroups[action.payload.jobLineItemGroupId];

      return {
        ...state,
        jobLineItemGroups: jobLineItemGroups,
      };
    }
    case ActionType.CLEAR_JOB_LINE_ITEM_GROUPS: {
      return {
        ...state,
        jobLineItemGroups: {},
      };
    }

    case ActionType.GET_JOB_LINE_ITEM_SUCCESS: {
      const fetchedEntity =
        state.jobLineItems && state.jobLineItems[action.payload.jobLineItemId];

      return {
        ...state,
        jobLineItems: {
          ...state.jobLineItems,
          [action.payload.jobLineItemId]: {
            ...fetchedEntity,
            entity: action.payload.jobLineItem,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.GET_JOB_LINE_ITEM_FAILURE: {
      const fetchedEntity =
        state.jobLineItems && state.jobLineItems[action.payload.jobId];

      return {
        ...state,
        jobLineItems: {
          ...state.jobLineItems,
          [action.payload.jobLineItemId]: {
            ...fetchedEntity,
            fetchFailedAtAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.CLEAR_JOB_LINE_ITEM: {
      const jobLineItems = state.jobLineItems || {};
      delete jobLineItems[action.payload.jobLineItemId];

      return {
        ...state,
        jobLineItems: jobLineItems,
      };
    }
    case ActionType.CLEAR_JOB_LINE_ITEMS: {
      return {
        ...state,
        jobLineItems: {},
      };
    }

    case ActionType.REQUEST_CREATE_JOB_LINE_ITEM_GROUP:
      return {
        ...state,
        isCreatingJobLineItemGroup: true,
      };
    case ActionType.CREATE_JOB_LINE_ITEM_GROUP_SUCCESS:
    case ActionType.CREATE_JOB_LINE_ITEM_GROUP_FAILURE:
      return {
        ...state,
        isCreatingJobLineItemGroup: false,
      };

    case ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_GROUP:
      return {
        ...state,
        isUpdatingJobLineItemGroup: true,
        jobLineItemGroupUpdateResults: {
          ...state.jobLineItemGroupUpdateResults,
          [action.payload.updateJobLineItemGroupRequestData.jobLineItemGroupId]:
            {
              isUpdatedSuccessfully: undefined,
              updateError: undefined,
            },
        },
      };
    case ActionType.UPDATE_JOB_LINE_ITEM_GROUP_SUCCESS:
      return {
        ...state,
        isUpdatingJobLineItemGroup: false,
        jobLineItemGroupUpdateResults: {
          ...state.jobLineItemGroupUpdateResults,
          [action.payload.jobLineItemGroupId]: {
            isUpdatedSuccessfully: true,
            updateError: undefined,
          },
        },
      };
    case ActionType.UPDATE_JOB_LINE_ITEM_GROUP_FAILURE:
      return {
        ...state,
        isUpdatingJobLineItemGroup: false,
        jobLineItemGroupUpdateResults: {
          ...state.jobLineItemGroupUpdateResults,
          [action.payload.jobLineItemGroupId]: {
            isUpdatedSuccessfully: false,
            updateError: action.error,
          },
        },
      };

    case ActionType.REQUEST_DELETE_JOB_LINE_ITEM_GROUP:
      return {
        ...state,
        isDeletingJobLineItemGroup: true,
      };
    case ActionType.DELETE_JOB_LINE_ITEM_GROUP_SUCCESS:
    case ActionType.DELETE_JOB_LINE_ITEM_GROUP_FAILURE:
      return {
        ...state,
        isDeletingJobLineItemGroup: false,
      };
    case ActionType.REQUEST_ADD_LINE_ITEM_GROUP_TO_JOB_CONTENT:
      return {
        ...state,
        isAddingLineItemGroupToJobContent: true,
      };
    case ActionType.ADD_LINE_ITEM_GROUP_TO_JOB_CONTENT_FAILURE:
    case ActionType.ADD_LINE_ITEM_GROUP_TO_JOB_CONTENT_SUCCESS:
      return {
        ...state,
        isAddingLineItemGroupToJobContent: false,
      };

    case ActionType.REQUEST_CREATE_JOB_LINE_ITEM:
    case ActionType.CREATE_JOB_LINE_ITEM_FROM_AMENDMENT_PARENT_REQUEST:
      return {
        ...state,
        isCreatingJobLineItem: true,
      };
    case ActionType.CREATE_JOB_LINE_ITEM_SUCCESS:
    case ActionType.CREATE_JOB_LINE_ITEM_FAILURE:
      return {
        ...state,
        isCreatingJobLineItem: false,
      };

    case ActionType.REQUEST_UPDATE_JOB_LINE_ITEM:
      return {
        ...state,
        isUpdatingJobLineItem: true,
        jobLineItemUpdateResults: {
          ...state.jobLineItemUpdateResults,
          [action.payload.updateJobLineItemRequestData.jobLineItemId]: {
            isUpdatedSuccessfully: undefined,
            updateError: undefined,
          },
        },
      };
    case ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_FROM_LINE_ITEM:
      return {
        ...state,
        isUpdatingJobLineItem: true,
        jobLineItemUpdateResults: {
          ...state.jobLineItemUpdateResults,
          [action.payload.jobLineItemId]: {
            isUpdatedSuccessfully: undefined,
            updateError: undefined,
          },
        },
      };
    case ActionType.UPDATE_JOB_LINE_ITEM_SUCCESS:
      return {
        ...state,
        isUpdatingJobLineItem: false,
        jobLineItemUpdateResults: {
          ...state.jobLineItemUpdateResults,
          [action.payload.jobLineItemId]: {
            isUpdatedSuccessfully: true,
            updateError: undefined,
          },
        },
      };
    case ActionType.UPDATE_JOB_LINE_ITEM_FAILURE:
      return {
        ...state,
        isUpdatingJobLineItem: false,
        jobLineItemUpdateResults: {
          ...state.jobLineItemUpdateResults,
          [action.payload.jobLineItemId]: {
            isUpdatedSuccessfully: false,
            updateError: action.error,
          },
        },
      };

    case ActionType.REQUEST_DELETE_JOB_LINE_ITEM:
      return {
        ...state,
        isDeletingJobLineItem: true,
      };
    case ActionType.DELETE_JOB_LINE_ITEM_SUCCESS:
    case ActionType.DELETE_JOB_LINE_ITEM_FAILURE:
      return {
        ...state,
        isDeletingJobLineItem: false,
      };

    case ActionType.REQUEST_ADD_ATTACHMENT_TO_JOB_LINE_ITEM:
      return {
        ...state,
        isAddingAttachmentToJobLineItem: true,
      };
    case ActionType.ADD_ATTACHMENT_TO_JOB_LINE_ITEM_SUCCESS:
    case ActionType.ADD_ATTACHMENT_TO_JOB_LINE_ITEM_FAILURE:
      return {
        ...state,
        isAddingAttachmentToJobLineItem: false,
      };

    case ActionType.REQUEST_REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM:
      return {
        ...state,
        isRemovingAttachmentFromJobLineItem: true,
      };
    case ActionType.REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM_FAILURE:
    case ActionType.REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM_SUCCESS:
      return {
        ...state,
        isRemovingAttachmentFromJobLineItem: false,
      };
    case ActionType.CLEAR_JOB_LINE_ITEM_UPDATE_RESULTS:
      return {
        ...state,
        jobLineItemUpdateResults: {},
      };
    case ActionType.CLEAR_JOB_LINE_ITEM_GROUP_UPDATE_RESULTS:
      return {
        ...state,
        jobLineItemGroupUpdateResults: {},
      };
    case ActionType.CLEAR_JOB_CONTENT_UPDATE_RESULTS:
      return {
        ...state,
        jobContentUpdateResults: {},
      };

    case ActionType.GET_JOB_CONTENTS_FOR_DEAL_SUCCESS:
      return {
        ...state,
        jobContents: mergeFetchedEntities({
          cache: state.jobContents,
          values: action.payload,
        }),
      };

    case ActionType.GET_JOB_CONTENT_WITH_JOB_GROUPS_AND_JOB_LINE_ITEMS_SUCCESS: {
      const now = new Date();
      return {
        ...state,
        jobContents: mergeFetchedEntities({
          cache: state.jobContents,
          values: [action.payload.jobContent],
          now,
        }),
        jobLineItemGroups: mergeFetchedEntities({
          cache: state.jobLineItemGroups,
          values: action.payload.jobLineItemGroups,
          now,
        }),
        jobLineItems: mergeFetchedEntities({
          cache: state.jobLineItems,
          values: action.payload.jobLineItems,
          now,
        }),
      };
    }

    case ActionType.GET_JOB_LINE_ITEM_GROUPS_FOR_JOB_CONTENT_SUCCESS:
      return {
        ...state,
        jobLineItemGroups: mergeFetchedEntities({
          cache: state.jobLineItemGroups,
          values: action.payload,
        }),
      };

    case ActionType.GET_JOB_LINE_ITEMS_FOR_DEAL_SUCCESS:
    case ActionType.GET_JOB_LINE_ITEMS_FOR_INVOICE_SUCCESS:
    case ActionType.GET_JOB_LINE_ITEMS_FOR_JOB_CONTENT_SUCCESS:
      return {
        ...state,
        jobLineItems: mergeFetchedEntities({
          cache: state.jobLineItems,
          values: action.payload,
        }),
      };

    case ActionType.GET_JOB_LINE_ITEM_ATTACHMENTS_FOR_JOB_LINE_ITEM_SUCCESS:
      return {
        ...state,
        jobLineItemAttachments: mergeFetchedEntities({
          cache: state.jobLineItemAttachments,
          values: action.payload,
        }),
      };

    default:
      return state;
  }
};

export default jobsReducer;
