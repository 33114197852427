import {
  DistributionBoard,
  FinalCircuit,
} from '@payaca/types/electrical-forms-types';
import { FieldValidator } from '@payaca/types/fieldValidationTypes';

import { getIsRequiredFieldValidator } from './fieldValidationHelper';

export const distributionBoardFieldLabels: Record<
  Exclude<keyof DistributionBoard, 'circuits'>,
  string
> = {
  location: 'Location of consumer unit',
  designation: 'Designation',
  prospectiveFaultCurrent:
    'Prospective fault current at consumer unit (where applicable) (kA)',
  testEngineerName: 'Name',
  testEngineerPosition: 'Position',
  testEngineerSignatureBase64: 'Signature',
  testEngineerDate: 'Date',
  multiFunctionTestInstrumentSerialNumber: 'Multi-function',
  continuityTestInstrumentSerialNumber: 'Continuity',
  insulationResistanceTestInstrumentSerialNumber: 'Insulation resistance',
  earthFaultLoopImpedanceTestInstrumentSerialNumber:
    'Earth fault loop impedance',
  earthElectrodeResistanceTestInstrumentSerialNumber:
    'Earth electrode resistance',
  rcdTestInstrumentSerialNumber: 'RCD',

  zDb: 'Zs at DB (Ω)',

  confirmationOfSupplyPolarity: 'Confirmation of supply polarity',
  phaseSequenceConfirmed: 'Phase sequence confirmed',
  spdDetailsT1: 'T1',
  spdDetailsT2: 'T2',
  spdDetailsT3: 'T3',
  spdDetailsT4: 'T4',
  statusIndicatorChecked:
    'Status indicator checked (where functionality indicator is present)',
  isNotConnectedToOrigin: 'not connected to origin',
  supplyIsFrom: 'Supply to DB is from',

  //Overcurrent protection device for the distribution circuit
  overcurrentProtectionDeviceBs: 'BS (EN)',
  overcurrentProtectionNominalVoltage: 'Nominal voltage (V)',
  overcurrentProtectionRating: 'Rating (A)',
  overcurrentProtectionNoOfPhases: 'No. of phases',

  // Associated RCD (if any)
  associatedRcdBs: 'BS (EN)',
  associatedRcdType: 'RCD Type',
  associatedRcdResidualCurrent: 'IΔn (mA)',
  associatedRcdNoOfPoles: 'No. of poles',
  associatedRcdOperatingTime: 'Operating time',
};

export const finalCircuitFieldLabels: Record<keyof FinalCircuit, string> = {
  number: 'Circuit Number',
  description: 'Circuit Description',
  wiringType: 'Type of wiring',
  wiringTypeOther: 'Other value',
  referenceMethod: 'Reference Method (BS 7671)',
  numPointsServed: 'Number of points served',
  liveConductorCsa: 'Live conductor CSA (mm²)',
  cpcConductorCsa: 'CPC conductor CSA (mm²)',
  maxDisconnectionTime: 'Max disconnection time (BS 7671) (s)',
  overcurrentProtectiveDevice: 'Overcurrent protective device BS(EN)',
  overcurrentProtectiveDeviceType: 'Overcurrent protective device type',
  currentRating: 'Current Rating (A)',
  shortCircuitCapacity: 'Short-circuit capacity (kA)',
  rcdOperatingCurrent: 'RCD Operating current, I∆ₙ (mA)',
  maxPermittedZs: 'Maximum permitted Zs for installed protective device (Ω)',
  lineImpedance: '(Line) r₁',
  neutralImpedance: '(Neutral) rₙ',
  cpcImpedance: '(cpc) r₂',
  r1PlusR2Impedance: '(R₁+R₂)',
  r2Impedance: 'R₂',
  liveLiveInsulationResistance: 'Live/Live (MΩ)',
  liveEarthInsulationResistance: 'Live/Earth (MΩ)',
  testVoltage: 'Test Voltage DC (V)',
  polarity: 'Polarity',
  maxMeasuredEarthFaultLoopImpedance:
    'Max. measured earth fault loop impedance, Zs (Ω)',
  rcdOperatingTime: 'Operating time (ms)',
  rcdTestButtonOperation: 'Test button operation RCD',
  afddTestButtonOperation: 'Test button operation AFDD',
  vulnerableToDamage: 'Vulnerable to damage',
  rcdBs: 'BS (EN)',
  rcdType: 'Type',
  rcdRating: 'Rating',
  comments: 'Comments and additional information, where required',
};

export const finalCircuitValidators: Record<
  keyof FinalCircuit,
  Array<FieldValidator>
> = {
  number: [
    getIsRequiredFieldValidator({
      readableName: finalCircuitFieldLabels.number,
    }),
  ],
  description: [
    getIsRequiredFieldValidator({
      readableName: finalCircuitFieldLabels.description,
    }),
  ],
  wiringType: [
    getIsRequiredFieldValidator({
      readableName: finalCircuitFieldLabels.wiringType,
    }),
  ],
  wiringTypeOther: [],
  referenceMethod: [
    getIsRequiredFieldValidator({
      readableName: finalCircuitFieldLabels.referenceMethod,
    }),
  ],
  numPointsServed: [
    getIsRequiredFieldValidator({
      readableName: finalCircuitFieldLabels.numPointsServed,
    }),
  ],
  liveConductorCsa: [],
  cpcConductorCsa: [],
  maxDisconnectionTime: [],
  overcurrentProtectiveDevice: [],
  overcurrentProtectiveDeviceType: [],
  currentRating: [],
  shortCircuitCapacity: [],
  rcdOperatingCurrent: [],
  maxPermittedZs: [],
  lineImpedance: [],
  neutralImpedance: [],
  cpcImpedance: [],
  r1PlusR2Impedance: [],
  r2Impedance: [],
  liveLiveInsulationResistance: [],
  liveEarthInsulationResistance: [],
  testVoltage: [],
  polarity: [],
  maxMeasuredEarthFaultLoopImpedance: [],
  rcdOperatingTime: [],
  rcdTestButtonOperation: [],
  afddTestButtonOperation: [],
  vulnerableToDamage: [],

  rcdBs: [],
  rcdType: [],
  rcdRating: [],
  comments: [],
};
