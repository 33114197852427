import React, { FC, useMemo } from 'react';

import BasicField from '@payaca/components/basicField/BasicField';
import FormattedNumberField, { NumberFormatVariant} from '@payaca/components/formattedNumberField/FormattedNumberField';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';

import { getRegionalTextString } from '@payaca/helpers/internationalHelper';

import { AccountRegions } from '@payaca/types/accountTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import { useSelector } from '@/api/state';

type Props = {
  isReadOnly?: boolean;
  name: string;
  onChange: (value: { [key: string]: any }) => void;
  value: string;
};
const SortCodeField: FC<Props> = ({
  isReadOnly = false,
  name,
  onChange,
  value,
}: Props): JSX.Element => {
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  const sortCodeRegionalLabel = useMemo(
    () => getRegionalTextString(account.region, RegionalStrings.SORT_CODE),
    [account]
  );

  const sortCodeField = useMemo(() => {
    switch (account.region) {
      case AccountRegions.AUSTRALIA:
        return (
          <FormattedNumberField
            name={name}
            label={sortCodeRegionalLabel}
            value={value}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={onChange}
            formatVariant={NumberFormatVariant.BSB}
            isDisabled={isReadOnly}
          />
        );
      case AccountRegions.CANADA:
        return (
          <FormattedNumberField
            name={name}
            label={sortCodeRegionalLabel}
            value={value}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={onChange}
            formatVariant={NumberFormatVariant.CA_ROUTING_NUMBER}
            isDisabled={isReadOnly}
          />
        );
      case AccountRegions.NEW_ZEALAND:
        return (
          <FormattedNumberField
            name={name}
            label={sortCodeRegionalLabel}
            value={value}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={onChange}
            formatVariant={NumberFormatVariant.NZ_BSB}
            isDisabled={isReadOnly}
          />
        );
      case AccountRegions.SOUTH_AFRICA:
        return (
          <FormattedNumberField
            name={name}
            label={sortCodeRegionalLabel}
            value={value}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={onChange}
            formatVariant={NumberFormatVariant.ZA_BRANCH_CODE}
            isDisabled={isReadOnly}
          />
        );
      case AccountRegions.UK:
        return (
          <FormattedNumberField
            name={name}
            label={sortCodeRegionalLabel}
            value={value}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={onChange}
            formatVariant={NumberFormatVariant.SORT_CODE}
            isDisabled={isReadOnly}
          />
        );
      case AccountRegions.US:
        return (
          <BasicField
            name={name}
            label={sortCodeRegionalLabel}
            value={value}
            styleVariant={InputStyleVariant.OUTSIZE}
            onChange={onChange}
            isDisabled={isReadOnly}
          />
        );
      default:
        return <></>;
    }
  }, [onChange, value, account.region, isReadOnly]);

  return sortCodeField;
};

export default SortCodeField;
