import {
  ActionType,
  ClearServicePlanPeriods,
  ClearServicePlans,
  ClearServicePlanSubscriptions,
  CreateServicePlan,
  CreateServicePlanCommitment,
  CreateServicePlanPrice,
  DeleteServicePlanPrice,
  DeleteServicePlanCommitment,
  DeleteServicePlanPeriodCommitment,
  GetListedServicePlans,
  GetServicePeriods,
  GetServicePlan,
  GetServicePlanPeriod,
  GetServicePlansAnalytics,
  GetServicePlanSubscription,
  GetSubscriptionsForCustomer,
  GetSubscriptionsForServicePlan,
  InviteCustomerToServicePlan,
  UpdateServicePlan,
  UpdateServicePlanCommitment,
  UpdateServicePlanPeriod,
  UpdateServicePlanPeriodCommitment,
  UpdateServicePlanPrice,
  DeleteServicePlan,
  GetServicePlanDiscountCodes,
  GetListedDiscountCodes,
  CreateDiscountCode,
  DeleteServicePlanDiscountCode,
  GetServicePlansCustomerReminders,
  UpdateServicePlansCustomerReminder,
  DeleteServicePlansCustomerReminder,
  GetServicePlansCustomerReminder,
  CreateServicePlansCustomerReminder,
  ToggleServicePlansCustomerReminder,
} from './servicePlansTypes';
import { createAsyncAction } from '../utils';

/**
 * Get listed service plans discount codes for an account
 */
export const getListedDiscountCodes = createAsyncAction<GetListedDiscountCodes>(
  {
    request: ActionType.GET_LISTED_DISCOUNT_CODES_REQUEST,
    success: ActionType.GET_LISTED_DISCOUNT_CODES_SUCCESS,
    failure: ActionType.GET_LISTED_DISCOUNT_CODES_FAILURE,
  }
)((payload: GetListedDiscountCodes['request']['payload']) => ({ payload }));

/**
 * Get listed service plans for an account - i.e. the service plans a user has created and available for their customer to purchase
 */
export const getListedServicePlans = createAsyncAction<GetListedServicePlans>({
  request: ActionType.GET_LISTED_SERVICE_PLANS_REQUEST,
  success: ActionType.GET_LISTED_SERVICE_PLANS_SUCCESS,
  failure: ActionType.GET_LISTED_SERVICE_PLANS_FAILURE,
})((payload: GetListedServicePlans['request']['payload']) => ({ payload }));

/**
 * Get service periods - details of customers subscribed plans
 */
export const getServicePeriods = createAsyncAction<GetServicePeriods>({
  request: ActionType.GET_SERVICE_PERIODS_REQUEST,
  success: ActionType.GET_SERVICE_PERIODS_SUCCESS,
  failure: ActionType.GET_SERVICE_PERIODS_FAILURE,
})((payload: GetServicePeriods['request']['payload']) => ({ payload }));

/**
 * Get service plans analytics
 */
export const getServicePlansAnalytics =
  createAsyncAction<GetServicePlansAnalytics>({
    request: ActionType.GET_SERVICE_PLANS_ANALYTICS_REQUEST,
    success: ActionType.GET_SERVICE_PLANS_ANALYTICS_SUCCESS,
    failure: ActionType.GET_SERVICE_PLANS_ANALYTICS_FAILURE,
  })((payload: GetServicePlansAnalytics['request']['payload']) => ({
    payload,
  }));

/**
 * Get service plan - details of a service plan which a user has created
 */
export const getServicePlan = createAsyncAction<GetServicePlan>({
  request: ActionType.GET_SERVICE_PLAN_REQUEST,
  success: ActionType.GET_SERVICE_PLAN_SUCCESS,
  failure: ActionType.GET_SERVICE_PLAN_FAILURE,
})((payload: GetServicePlan['request']['payload']) => ({
  payload,
  meta: { publicId: payload.publicId },
}));

/**
 * Create service plan
 */
export const createServicePlan = createAsyncAction<CreateServicePlan>({
  request: ActionType.CREATE_SERVICE_PLAN_REQUEST,
  success: ActionType.CREATE_SERVICE_PLAN_SUCCESS,
  failure: ActionType.CREATE_SERVICE_PLAN_FAILURE,
})((payload: CreateServicePlan['request']['payload']) => ({
  payload,
}));

/**
 * Update service plan
 */
export const updateServicePlan = createAsyncAction<UpdateServicePlan>({
  request: ActionType.UPDATE_SERVICE_PLAN_REQUEST,
  success: ActionType.UPDATE_SERVICE_PLAN_SUCCESS,
  failure: ActionType.UPDATE_SERVICE_PLAN_FAILURE,
})((payload: UpdateServicePlan['request']['payload']) => ({
  payload,
}));

/**
 * Delete service plan
 */
export const deleteServicePlan = createAsyncAction<DeleteServicePlan>({
  request: ActionType.DELETE_SERVICE_PLAN_REQUEST,
  success: ActionType.DELETE_SERVICE_PLAN_SUCCESS,
  failure: ActionType.DELETE_SERVICE_PLAN_FAILURE,
})((payload: DeleteServicePlan['request']['payload']) => ({
  payload,
}));

/**
 * Delete service plan discount code
 */
export const deleteServicePlanDiscountCode =
  createAsyncAction<DeleteServicePlanDiscountCode>({
    request: ActionType.DELETE_SERVICE_PLAN_DISCOUNT_CODE_REQUEST,
    success: ActionType.DELETE_SERVICE_PLAN_DISCOUNT_CODE_SUCCESS,
    failure: ActionType.DELETE_SERVICE_PLAN_DISCOUNT_CODE_FAILURE,
  })((payload: DeleteServicePlanDiscountCode['request']['payload']) => ({
    payload,
  }));

/**
 * Get discount codes by servicePlanPublicId
 */
export const getServicePlanDiscountCodes =
  createAsyncAction<GetServicePlanDiscountCodes>({
    request: ActionType.GET_SERVICE_PLAN_DISCOUNT_CODES_REQUEST,
    success: ActionType.GET_SERVICE_PLAN_DISCOUNT_CODES_SUCCESS,
    failure: ActionType.GET_SERVICE_PLAN_DISCOUNT_CODES_FAILURE,
  })((payload: GetServicePlanDiscountCodes['request']['payload']) => ({
    payload,
    meta: { publicId: payload.publicId },
  }));

/**
 * Create service plan discount code
 */
export const createDiscountCode = createAsyncAction<CreateDiscountCode>({
  request: ActionType.CREATE_DISCOUNT_CODE_REQUEST,
  success: ActionType.CREATE_DISCOUNT_CODE_SUCCESS,
  failure: ActionType.CREATE_DISCOUNT_CODE_FAILURE,
})((payload: CreateDiscountCode['request']['payload']) => ({
  payload,
}));

/**
 * Send invite service plan email to customer
 */

export const inviteCustomerToServicePlan =
  createAsyncAction<InviteCustomerToServicePlan>({
    request: ActionType.INVITE_CUSTOMER_TO_SERVICE_PLAN_REQUEST,
    success: ActionType.INVITE_CUSTOMER_TO_SERVICE_PLAN_SUCCESS,
    failure: ActionType.INVITE_CUSTOMER_TO_SERVICE_PLAN_FAILURE,
  })((payload: InviteCustomerToServicePlan['request']['payload']) => ({
    payload,
  }));

export const updateServicePlanCommitment =
  createAsyncAction<UpdateServicePlanCommitment>({
    request: ActionType.UPDATE_SERVICE_PLAN_COMMITMENT_REQUEST,
  })((payload: UpdateServicePlanCommitment['request']['payload']) => ({
    payload,
  }));

export const createServicePlanCommitment =
  createAsyncAction<CreateServicePlanCommitment>({
    request: ActionType.CREATE_SERVICE_PLAN_COMMITMENT_REQUEST,
  })((payload: CreateServicePlanCommitment['request']['payload']) => ({
    payload,
  }));

export const deleteServicePlanCommitment =
  createAsyncAction<DeleteServicePlanCommitment>({
    request: ActionType.DELETE_SERVICE_PLAN_COMMITMENT_REQUEST,
  })((payload: DeleteServicePlanCommitment['request']['payload']) => ({
    payload,
  }));

export const getSubscriptionsForServicePlan =
  createAsyncAction<GetSubscriptionsForServicePlan>({
    request: ActionType.GET_SUBSCRIPTIONS_FOR_SERVICE_PLAN_REQUEST,
    success: ActionType.GET_SUBSCRIPTIONS_FOR_SERVICE_PLAN_SUCCESS,
    failure: ActionType.GET_SUBSCRIPTIONS_FOR_SERVICE_PLAN_FAILURE,
  })((payload: GetSubscriptionsForServicePlan['request']['payload']) => ({
    payload,
  }));

export const getSubscriptionsForCustomer =
  createAsyncAction<GetSubscriptionsForCustomer>({
    request: ActionType.GET_SUBSCRIPTIONS_FOR_CUSTOMER_REQUEST,
    success: ActionType.GET_SUBSCRIPTIONS_FOR_CUSTOMER_SUCCESS,
    failure: ActionType.GET_SUBSCRIPTIONS_FOR_CUSTOMER_FAILURE,
  })((payload: GetSubscriptionsForCustomer['request']['payload']) => ({
    payload,
  }));

export const clearServicePlanSubscriptions =
  createAsyncAction<ClearServicePlanSubscriptions>({
    request: ActionType.CLEAR_SERVICE_PLAN_SUBSCRIPTIONS,
  })();

export const clearServicePlans = createAsyncAction<ClearServicePlans>({
  request: ActionType.CLEAR_SERVICE_PLANS,
})();

export const clearServicePlanPeriods =
  createAsyncAction<ClearServicePlanPeriods>({
    request: ActionType.CLEAR_SERVICE_PLAN_PERIODS,
  })();

export const updateServicePlanPrice = createAsyncAction<UpdateServicePlanPrice>(
  {
    request: ActionType.UPDATE_SERVICE_PLAN_PRICE_REQUEST,
  }
)((payload: UpdateServicePlanPrice['request']['payload']) => ({
  payload,
}));

export const deleteServicePlanPrice = createAsyncAction<DeleteServicePlanPrice>(
  {
    request: ActionType.DELETE_SERVICE_PLAN_PRICE_REQUEST,
  }
)((payload: DeleteServicePlanPrice['request']['payload']) => ({
  payload,
}));

export const updateServicePlanPeriodCommitment =
  createAsyncAction<UpdateServicePlanPeriodCommitment>({
    request: ActionType.UPDATE_SERVICE_PLAN_PERIOD_COMMITMENT_REQUEST,
  })((payload: UpdateServicePlanPeriodCommitment['request']['payload']) => ({
    payload,
  }));

export const createServicePlanPrice = createAsyncAction<CreateServicePlanPrice>(
  {
    request: ActionType.CREATE_SERVICE_PLAN_PRICE_REQUEST,
  }
)((payload: CreateServicePlanPrice['request']['payload']) => ({
  payload,
}));

/**
 * Get service plan period
 */
export const getServicePlanPeriod = createAsyncAction<GetServicePlanPeriod>({
  request: ActionType.GET_SERVICE_PLAN_PERIOD_REQUEST,
  success: ActionType.GET_SERVICE_PLAN_PERIOD_SUCCESS,
  failure: ActionType.GET_SERVICE_PLAN_PERIOD_FAILURE,
})((payload: GetServicePlanPeriod['request']['payload']) => ({
  payload,
  meta: { publicId: payload.publicId },
}));

export const deleteServicePlanPeriodCommitment =
  createAsyncAction<DeleteServicePlanPeriodCommitment>({
    request: ActionType.DELETE_SERVICE_PLAN_PERIOD_COMMITMENT_REQUEST,
  })((payload: DeleteServicePlanPeriodCommitment['request']['payload']) => ({
    payload,
  }));

export const updateServicePlanPeriod =
  createAsyncAction<UpdateServicePlanPeriod>({
    request: ActionType.UPDATE_SERVICE_PLAN_PERIOD_REQUEST,
  })((payload: UpdateServicePlanPeriod['request']['payload']) => ({
    payload,
  }));

export const getServicePlanSubscription =
  createAsyncAction<GetServicePlanSubscription>({
    request: ActionType.GET_SERVICE_PLAN_SUBSCRIPTION_REQUEST,
    success: ActionType.GET_SERVICE_PLAN_SUBSCRIPTION_SUCCESS,
    failure: ActionType.GET_SERVICE_PLAN_SUBSCRIPTION_FAILURE,
  })((payload: GetServicePlanSubscription['request']['payload']) => ({
    payload,
  }));

export const getServicePlansCustomerReminder =
  createAsyncAction<GetServicePlansCustomerReminder>({
    request: ActionType.GET_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST,
  })((payload: GetServicePlansCustomerReminder['request']['payload']) => ({
    payload,
  }));

export const getServicePlansCustomerReminders =
  createAsyncAction<GetServicePlansCustomerReminders>({
    request: ActionType.GET_SERVICE_PLANS_CUSTOMER_REMINDERS_REQUEST,
    success: ActionType.GET_SERVICE_PLANS_CUSTOMER_REMINDERS_SUCCESS,
  })((payload: GetServicePlansCustomerReminders['request']['payload']) => ({
    payload,
  }));

export const toggleServicePlansCustomerReminder =
  createAsyncAction<ToggleServicePlansCustomerReminder>({
    request: ActionType.TOGGLE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST,
    success: ActionType.TOGGLE_SERVICE_PLANS_CUSTOMER_REMINDER_SUCCESS,
  })((payload: ToggleServicePlansCustomerReminder['request']['payload']) => ({
    payload,
  }));

export const createServicePlansCustomerReminder =
  createAsyncAction<CreateServicePlansCustomerReminder>({
    request: ActionType.CREATE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST,
  })((payload: CreateServicePlansCustomerReminder['request']['payload']) => ({
    payload,
  }));

export const updateServicePlansCustomerReminder =
  createAsyncAction<UpdateServicePlansCustomerReminder>({
    request: ActionType.UPDATE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST,
  })((payload: UpdateServicePlansCustomerReminder['request']['payload']) => ({
    payload,
  }));

export const deleteServicePlansCustomerReminder =
  createAsyncAction<DeleteServicePlansCustomerReminder>({
    request: ActionType.DELETE_SERVICE_PLANS_CUSTOMER_REMINDER_REQUEST,
  })((payload: DeleteServicePlansCustomerReminder['request']['payload']) => ({
    payload,
  }));
