import React, { FunctionComponent, useMemo, useState, useEffect } from 'react';

import Modal from '@payaca/components/modal/Modal';
import RecordPaymentControl from '../recordPaymentControl/RecordPaymentControl';
import { JobPayment } from '@payaca/types/jobPaymentTypes';
import { getPrimaryContactFromCustomer } from '@payaca/helpers/customerHelper';

import { isInvoice } from '@payaca/helpers/jobStatusHelper';
import ConfirmPaymentControl from '../confirmPaymentControl/ConfirmPaymentControl';
import {
  getBacsPendingDepositPaymentsFromJobPayments,
  getBacsPendingPaymentsExcludingDepositFromJobPayments,
  getBacsPendingPaymentsFromJobPayments,
} from '@payaca/helpers/jobPaymentHelper';
import { usePrevious } from '@/utils/customHooks';
import { getOutstandingPaymentValue } from '@payaca/helpers/jobHelperV2';
import {
  getCustomerByDealId,
  getDeal,
  getInvoice,
  getJobContent,
  getJobPaymentsByDealId,
  getJobsByDealId,
} from '@/utils/stateAccessors';
import { useSelector } from '@/api/state';

type Props = {
  dealId: number;
  paymentTarget?: {
    // if dealV1
    jobId?: number;
    // if dealV2
    invoiceId?: number;
  };
  isOpen: boolean;
  onClose?: () => void;
  recordPaymentCallback?: () => void;
  confirmPaymentCallback?: () => void;
};

const RecordPaymentModal: FunctionComponent<Props> = ({
  dealId,
  paymentTarget,
  isOpen,
  onClose,
  recordPaymentCallback,
  confirmPaymentCallback,
}: Props): JSX.Element => {
  const prevIsOpen = usePrevious(isOpen);

  const deal = useSelector((state) => {
    return getDeal(state, dealId);
  });

  const jobPayments = useSelector((state) =>
    getJobPaymentsByDealId(state, dealId)
  );

  const customer = useSelector((state) => getCustomerByDealId(state, dealId));

  const jobs = useSelector((state) => {
    return getJobsByDealId(state, dealId);
  });

  // only care about this if v1 deal
  const job = useMemo(() => {
    if (paymentTarget?.jobId) {
      const j = jobs.find((x) => x.id === paymentTarget.jobId);
      if (j) {
        return j;
      }
    }

    const activeJobs = jobs.filter((x) => !x.archivedAt && !x.inactivatedAt);
    const sentJobs = activeJobs.filter((x) => !!x.sentAt);
    if (!sentJobs.length) return;

    const invoice = sentJobs.find((x) => isInvoice(x.status));

    if (invoice) return invoice;
    return sentJobs[0];
  }, [jobs, paymentTarget?.jobId]);

  const jobContent = useSelector((state) => {
    if (!job?.jobContentId) return;
    return getJobContent(state, job.jobContentId);
  });

  // only care about this if v2 deal
  const invoice = useSelector((state) => {
    if (!paymentTarget?.invoiceId) return;
    return getInvoice(state, paymentTarget?.invoiceId);
  });

  const jobIsInvoice = useMemo(() => {
    if (!job) return false;
    return isInvoice(job.status);
  }, [job]);

  const [isInConfirmPendingPaymentMode, setIsInConfirmPendingPaymentMode] =
    useState(false);

  const outstandingPaymentValue = useMemo(() => {
    if (!deal) return 0;

    if (deal?.version < 2) {
      if (job && jobContent) {
        return getOutstandingPaymentValue(job, jobContent, jobPayments);
      } else {
        return 0;
      }
    } else if (invoice) {
      return invoice.dueValue;
    } else {
      return deal.dueValue;
    }
  }, [deal, invoice, job, jobContent, jobPayments]);

  const pendingPayment = useMemo(() => {
    let pendingPayments: JobPayment[] = [];

    if (!deal) return;

    if (deal?.version < 2) {
      if (!job) {
        pendingPayments = getBacsPendingPaymentsFromJobPayments(jobPayments);
      } else if (jobIsInvoice) {
        pendingPayments =
          getBacsPendingPaymentsExcludingDepositFromJobPayments(jobPayments);
      } else {
        pendingPayments =
          getBacsPendingDepositPaymentsFromJobPayments(jobPayments);
      }
    } else {
      if (paymentTarget?.invoiceId) {
        pendingPayments = getBacsPendingPaymentsFromJobPayments(
          jobPayments.filter((x) => x.invoiceId == paymentTarget.invoiceId)
        );
      } else {
        pendingPayments = getBacsPendingPaymentsFromJobPayments(jobPayments);
      }
    }

    return pendingPayments.shift();
  }, [jobPayments, job, jobIsInvoice, paymentTarget?.invoiceId, deal?.version]);

  const canSendReceipt = useMemo(() => {
    if (!customer) return false;
    const contact = getPrimaryContactFromCustomer(customer);
    return !!contact?.emailAddress;
  }, [job, customer]);

  useEffect(() => {
    if (pendingPayment) {
      setIsInConfirmPendingPaymentMode(true);
    }
  }, [pendingPayment]);

  const isRecordingJobPayment = useSelector((state) => {
    return state.jobPayments.isRecordingJobPayment;
  });
  const prevIsRecordingJobPayment = usePrevious(isRecordingJobPayment);
  const isJobPaymentRecordedSuccessfully = useSelector((state) => {
    return state.jobPayments.isJobPaymentRecordedSuccessfully;
  });
  const isConfirmingJobPayment = useSelector((state) => {
    return state.jobPayments.isConfirmingJobPayment;
  });
  const prevIsConfirmingJobPayment = usePrevious(isConfirmingJobPayment);
  const isJobPaymentConfirmedSuccessfully = useSelector((state) => {
    return state.jobPayments.isJobPaymentConfirmedSuccessfully;
  });

  useEffect(() => {
    if (
      prevIsRecordingJobPayment &&
      !isRecordingJobPayment &&
      isJobPaymentRecordedSuccessfully
    ) {
      recordPaymentCallback && recordPaymentCallback();
    }
  }, [
    prevIsRecordingJobPayment,
    isRecordingJobPayment,
    isJobPaymentRecordedSuccessfully,
  ]);

  useEffect(() => {
    if (
      prevIsConfirmingJobPayment &&
      !isConfirmingJobPayment &&
      isJobPaymentConfirmedSuccessfully
    ) {
      confirmPaymentCallback && confirmPaymentCallback();
    }
  }, [
    prevIsConfirmingJobPayment,
    isConfirmingJobPayment,
    isJobPaymentConfirmedSuccessfully,
  ]);

  useEffect(() => {
    if (isOpen && !prevIsOpen && !pendingPayment) {
      setIsInConfirmPendingPaymentMode(false);
    }
  }, [prevIsOpen, isOpen, pendingPayment]);

  return (
    <Modal
      isOpen={isOpen}
      title={
        isInConfirmPendingPaymentMode ? 'Confirm a payment' : 'Record a payment'
      }
      size="sm"
      onClose={onClose}
      className="record-payment-modal"
    >
      {!isInConfirmPendingPaymentMode && (
        <RecordPaymentControl
          outstandingPaymentValue={Math.max(outstandingPaymentValue || 0, 0)}
          isDepositPayment={!jobIsInvoice}
          customerName={customer?.name || ''}
          dealId={dealId}
          invoiceId={paymentTarget?.invoiceId}
          canSendReceipt={canSendReceipt}
        />
      )}
      {isInConfirmPendingPaymentMode && (
        <ConfirmPaymentControl
          jobPayment={pendingPayment || ({} as JobPayment)}
          customerName={customer?.name || ''}
          dealReference={deal?.customReference || String(deal?.reference)}
          goToRecordPayment={() => setIsInConfirmPendingPaymentMode(false)}
          canSendReceipt={canSendReceipt}
        />
      )}
    </Modal>
  );
};

export default RecordPaymentModal;
