import Modal from '@payaca/components/plModal/Modal';
import React, { FC } from 'react';
import CreateScheduledEventControl from './CreateScheduledEventControl';
import { CreateScheduledEventRequestData } from '@payaca/store/scheduledEvents/scheduledEventsTypes';
import { DeepPartial } from '@payaca/utilities/types';
import { Props as FieldsetProps } from '../scheduledEventFieldset/ScheduledEventFieldset';
import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';

export const CreateScheduledEventModal: FC<
  {
    isOpen: boolean;
    onClose: () => void;
    initialEventData?: DeepPartial<CreateScheduledEventRequestData>;
    onSuccess?: (scheduledEventId: ScheduledEvent['id']) => void;
    disableTemplateSelect?: boolean;
  } & Pick<FieldsetProps, 'hiddenFields' | 'disabledFields'>
> = ({
  isOpen,
  onClose,
  initialEventData,
  onSuccess,
  hiddenFields,
  disabledFields,
  disableTemplateSelect,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      title={'Create an Event'}
      onClose={onClose}
      disableBackdropClick={true}
    >
      <CreateScheduledEventControl
        initialEventData={initialEventData}
        onSuccess={(scheduledEventId) => {
          onClose();
          onSuccess?.(scheduledEventId);
        }}
        hiddenFields={hiddenFields}
        disabledFields={disabledFields}
        disableTemplateSelect={disableTemplateSelect}
      />
    </Modal>
  );
};
