import { AccountsPermissions } from './accounts.permissions';
import { AccountsRoles } from './accounts.roles';

export const accountsMappings = {
  [AccountsPermissions.GET_ACCOUNT]: [AccountsRoles.VIEWER],
  [AccountsPermissions.GET_USERS]: [AccountsRoles.USERS_VIEWER],
  [AccountsPermissions.INVITE_USER]: [AccountsRoles.EDITOR],
  [AccountsPermissions.DEACTIVATE_USER]: [AccountsRoles.EDITOR],
  [AccountsPermissions.REACTIVATE_USER]: [AccountsRoles.EDITOR],
  [AccountsPermissions.UPDATE_ROLE]: [AccountsRoles.EDITOR],
  [AccountsPermissions.ADD_CONNECTION]: [AccountsRoles.EDITOR],
  [AccountsPermissions.UPDATE_CONNECTION]: [AccountsRoles.EDITOR],
  [AccountsPermissions.DELETE_CONNECTION]: [AccountsRoles.EDITOR],
  [AccountsPermissions.UPDATE_SUBSCRIPTION]: [
    AccountsRoles.SUBSCRIPTION_EDITOR,
  ],
  [AccountsPermissions.UPDATE_ACCOUNT]: [AccountsRoles.EDITOR],
  [AccountsPermissions.UPDATE_MY_ACCOUNT]: [AccountsRoles.PERSONAL_EDITOR],
  [AccountsPermissions.GET_USER_COST_PER_HOUR]: [
    AccountsRoles.USERS_COST_PER_HOUR_VIEWER,
  ],
  [AccountsPermissions.SET_USER_COST_PER_HOUR]: [
    AccountsRoles.USERS_COST_PER_HOUR_EDITOR,
  ],
  [AccountsPermissions.SET_CUSTOM_EMAIL_DOMAIN]: [
    AccountsRoles.CUSTOM_EMAIL_DOMAIN_EDITOR,
  ],
  [AccountsPermissions.SEND_FROM_CUSTOM_EMAIL_DOMAIN]: [
    AccountsRoles.CUSTOM_EMAIL_DOMAIN_SENDER,
  ],
  [AccountsPermissions.MANAGE_CUSTOM_FIELD_GROUPS]: [
    AccountsRoles.CUSTOM_FIELD_GROUP_MANAGER,
  ],
  [AccountsPermissions.MANAGE_CUSTOM_FIELD_VALUES]: [
    AccountsRoles.CUSTOM_FIELD_VALUE_MANAGER,
  ],
  [AccountsPermissions.MANAGE_TAGS]: [AccountsRoles.TAG_MANAGER],
};
