import { getScheduledEvent, getUserRoles } from '@/utils/stateAccessors';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './EntityTemplateReadView.sass';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';
import {
  PublicEntityTemplate,
  PublicHydratedEntityTemplate,
  TemplatableEntity,
} from '@payaca/types/entity-templates';
import { WithOptional } from '@payaca/utilities/types';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { TemplatesPermissions } from '@payaca/permissions/templates/templates.permissions';
import { UploadsPermissions } from '@payaca/permissions/uploads/uploads.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import TagSelectionControl from '../tagSelectionControl/TagSelectionControl';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import './EntityTemplateScheduledEventReadView.sass';
import { getTimeDuration } from '@payaca/utilities/timeUtilities';
import { roundToDecimalPlaces } from '@payaca/helpers/numberHelper';
import UploadCollectionControl from '../uploadCollectionControl/UploadCollectionControl';
import { UPLOAD_MAX_FILESIZE_LIMIT } from '@payaca/types/uploadTypes';
import ScheduledEventLinkedTaskTemplatesPanel from './ScheduledEventLinkedTaskTemplatesPanel';
import { updateTemplate } from '@payaca/store/templates/templatesActions';

const acceptFileTypes = getAcceptedFileTypes(['document', 'image', 'video']);

type Props = {
  entityTemplate: PublicHydratedEntityTemplate<'scheduledEvent'>;
  onUpdateEntityTemplateSuccess?: (
    updatedEntityTemplate: PublicHydratedEntityTemplate<TemplatableEntity>
  ) => void;
};
const EntityTemplateScheduledEventReadView: FC<Props> = ({
  entityTemplate,
  onUpdateEntityTemplateSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const userRoles = useSelector(getUserRoles);
  const userCanDeleteAttachment = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      UploadsPermissions.DELETE_UPLOAD,
    ]);
  }, [userRoles]);

  const duration = useMemo<any>(() => {
    if (!entityTemplate?.data?.duration) return null;
    return getTimeDuration(
      moment().subtract(entityTemplate.data.duration).toDate(),
      moment().toDate()
    );
  }, [entityTemplate?.data.duration]);

  if (!entityTemplate) return null;

  return (
    <div className={'entity-template-scheduled-event-read-view'}>
      <PermissionGuard
        renderIfHasPermissions={[TemplatesPermissions.PERSIST_TEMPLATE]}
      >
        <TagSelectionControl
          selectedTagIds={
            (
              entityTemplate as WithOptional<
                PublicEntityTemplate<'scheduledEvent'>,
                'publicId'
              >
            ).data?.tagIds || []
          }
          onSelectedTagsChange={(tagIds) => {
            dispatch(
              updateTemplate.request({
                publicId: entityTemplate.publicId,
                template: {
                  entityType: entityTemplate.entityType,
                  data: {
                    ...entityTemplate.data,
                    tagIds: tagIds,
                  },
                },
                callback: onUpdateEntityTemplateSuccess,
              })
            );
          }}
        />
      </PermissionGuard>
      <div>
        {entityTemplate?.data.name && (
          <h1 className="name">{entityTemplate.data.name}</h1>
        )}
        {duration && (
          <span className="duration">
            <span className="label">Duration:</span>{' '}
            {roundToDecimalPlaces(duration.value)} {duration.label}
          </span>
        )}
      </div>
      {entityTemplate?.data.description && (
        <div className="description">
          <div className="label">Description</div>
          <div>{entityTemplate.data.description}</div>
        </div>
      )}
      {entityTemplate?.data.includeCustomerInformation && (
        <div className="include-customer-information">
          <FontAwesomeIcon icon={faSquareCheck} />
          <span>
            Customer information will be shown against the Event where it is
            available
          </span>
        </div>
      )}
      <div className="tasks">
        <div className="label">Add linked Tasks</div>
        <ScheduledEventLinkedTaskTemplatesPanel
          taskTemplates={entityTemplate.data?.tasks || []}
          onChange={(taskTemplates) => {
            return new Promise((resolve, reject) => {
              dispatch(
                updateTemplate.request({
                  publicId: entityTemplate.publicId,
                  template: {
                    entityType: entityTemplate.entityType,
                    data: {
                      ...entityTemplate.data,
                      tasks: taskTemplates,
                    },
                  },
                  callback: (et) => {
                    onUpdateEntityTemplateSuccess?.(et);
                    resolve();
                  },
                })
              );
            });
          }}
        />
      </div>
      <PermissionGuard
        renderIfHasPermissions={[UploadsPermissions.GET_UPLOADS]}
      >
        <UploadCollectionControl
          label="Attachments"
          uploadIds={entityTemplate.data?.uploadIds || []}
          onUploadCollectionChange={(uploadIds) => {
            dispatch(
              updateTemplate.request({
                publicId: entityTemplate.publicId,
                template: {
                  entityType: entityTemplate.entityType,
                  data: {
                    ...entityTemplate.data,
                    uploadIds: uploadIds,
                  },
                },
                callback: onUpdateEntityTemplateSuccess,
              })
            );
          }}
          allowMultipleUploads={true}
          canRemove={userCanDeleteAttachment}
          acceptFileTypes={acceptFileTypes}
          fileSizeLimitBytes={UPLOAD_MAX_FILESIZE_LIMIT}
        />
      </PermissionGuard>
    </div>
  );
};
export default EntityTemplateScheduledEventReadView;
