import { Deal } from '@payaca/types/dealTypes';
import {
  CompletionState,
  DeadlineState,
  ListedTaskListViewPage,
  SortBy,
} from '@payaca/types/listedTaskTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

export interface State {
  isGettingListedTasksPage: boolean;
  listedTasksPage: ListedTaskListViewPage | null;
}

export enum ActionType {
  REQUEST_GET_LISTED_TASKS_PAGE = 'listedTasks.requestGetListedTasksPage',
  GET_LISTED_TASKS_PAGE_SUCCESS = 'listedTasks.getListedTaskssPageSuccess',
  GET_LISTED_TASKS_PAGE_FAILURE = 'listedTasks.getListedTasksPageFailure',
  CLEAR_LISTED_TASKS_PAGE = 'listedTasks.clearListedTasksPage',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export interface GetListedTasksRequestData {
  pageSize: number;
  pageNumber: number;
  searchTerm?: string;
  assignedTo?: (number | 'unassigned')[];
  createdBy?: number[];
  sortDirection?: SortDirection;
  sortBy?: SortBy;
  deadlineStates?: DeadlineState[];
  completionStates?: CompletionState[];
  excludeTasksCompletedOverXHoursAgo?: number;
  dealId?: Deal['id'];
}
