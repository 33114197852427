import React, {
  ComponentPropsWithoutRef,
  FC,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { clstx } from '../utils';
import { InputIcon, InputSizeVariant } from '../plInput/RawInput';
import Input from '../plInput/Input';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '../plButton/useButtonClassName';
import FilterDropdown, {
  IProps as IFilterDropdownProps,
} from '../plDropdown/FilterDropdown';
import Button, { IButtonProps } from '../plButton/Button';
import List, { IProps as IListProps } from '../list/List';
import { useObserveIntersection } from '@payaca/hooks/useObserveIntersection';
import Conditional from '../conditional/Conditional';
import EmptyState from '../plEmptyState/EmptyState';

const InfiniteListHeader: FC<
  PropsWithChildren<ComponentPropsWithoutRef<'div'>>
> = (props) => {
  const { className, children, ...rest } = props;

  return (
    <div
      className={clstx('sticky top-0 z-10 bg-white p-3.5', className)}
      {...rest}
    >
      {children}
    </div>
  );
};

const InfiniteListSearch: FC<ComponentPropsWithoutRef<typeof Input>> = (
  props
) => {
  return (
    <Input
      type="search"
      aria-label={`Search`}
      leadingElement={<InputIcon name="search-lg.3" />}
      sizeVariant={InputSizeVariant.SM}
      placeholder="Search"
      {...props}
    />
  );
};

const InfiniteListActionbar: FC<
  PropsWithChildren<ComponentPropsWithoutRef<'div'>>
> = (props) => {
  const { className, children, ...rest } = props;

  return (
    <div className={clstx('mt-2.5 flex gap-2.5', className)} {...rest}>
      {children}
    </div>
  );
};

const InfiniteListFilter: FC<IFilterDropdownProps> = (props) => {
  return (
    <FilterDropdown
      variant={EBtnVariant.White}
      size={EBtnSize.Small}
      colour={EBtnColour.Black}
      {...props}
    />
  );
};

const InfiniteListPrimaryAction: FC<PropsWithChildren<IButtonProps>> = (
  props
) => {
  const { className, children, ...rest } = props;

  return (
    <Button
      className={clstx('flex-auto', className)}
      variant={EBtnVariant.White}
      size={EBtnSize.Small}
      colour={EBtnColour.Black}
      {...rest}
    >
      {children}
    </Button>
  );
};

interface IInfiniteListItemsProps<T extends Record<string, any>>
  extends IListProps<T> {
  skeletonItem?: ReactNode;
  isLoading?: boolean;
  numOfSkeletonLoaders?: number;
  onLoadMore?: () => void;
  canLoadMore?: boolean;
  emptyStateText?: string;
}

const InfiniteListItems = <T extends Record<string, any>>(
  props: IInfiniteListItemsProps<T>
) => {
  const {
    className,
    skeletonItem,
    numOfSkeletonLoaders = 5,
    onLoadMore = () => {},
    isLoading = false,
    canLoadMore = false,
    emptyStateText,
    ...rest
  } = props;

  const loaderRef = useObserveIntersection(onLoadMore);

  return (
    <List className={clstx('p-3.5 pt-0', className)} {...rest}>
      <Conditional condition={!isLoading && !rest.items.length}>
        <EmptyState className="mt-10" text={emptyStateText} />
      </Conditional>

      <Conditional condition={isLoading}>
        {[...Array(numOfSkeletonLoaders)].map((_, index) => (
          <div key={index}>{skeletonItem}</div>
        ))}
      </Conditional>

      <Conditional condition={!isLoading && canLoadMore}>
        <div ref={loaderRef}>{skeletonItem}</div>
      </Conditional>
    </List>
  );
};

const InfiniteList = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<ComponentPropsWithoutRef<'div'>>
>((props, ref) => {
  const { className, children, ...rest } = props;

  return (
    <div ref={ref} className={clstx('overflow-auto', className)} {...rest}>
      {children}
    </div>
  );
});

export default Object.assign(InfiniteList, {
  Header: InfiniteListHeader,
  Search: InfiniteListSearch,
  Actionbar: InfiniteListActionbar,
  Filter: InfiniteListFilter,
  PrimaryAction: InfiniteListPrimaryAction,
  List: InfiniteListItems,
});
