import { useQueryClient, UseMutationOptions } from '@tanstack/react-query';

import {
  CreateCustomerContactInput,
  CreateCustomerContactMutation as _CreateCustomerContactMutation,
  CustomerContact,
  CustomerQuery,
} from '@/gql/graphql';
import customerKeys from '@/api/queries/customers/keyFactory';
import useCreateCustomerContact from '@/api/mutations/customers/useCreateCustomerContact';

const useOptimisticCreateCustomerContactOnCustomer = (
  customerId: string,
  options?: UseMutationOptions<
    _CreateCustomerContactMutation,
    unknown,
    CreateCustomerContactInput
  >
) => {
  const queryClient = useQueryClient();

  return useCreateCustomerContact({
    ...options,
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: customerKeys.customer(customerId),
      });

      // Snapshot the previous value
      const previousCustomer = queryClient.getQueryData(
        customerKeys.customer(customerId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData<CustomerQuery>(
        customerKeys.customer(customerId),
        (old) => {
          if (!old) return;

          return {
            ...old,
            customer: {
              ...old.customer,
              contacts: [
                ...old.customer.contacts,
                { ...variables, id: 'temp-id', isPrimary: false },
              ],
            },
          };
        }
      );

      options?.onMutate?.(variables);

      // Return a context object with the snapshotted value
      return { previousCustomer };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        customerKeys.customer(customerId),
        // @ts-ignore
        context.previousCustomer
      );

      options?.onError?.(err, newTodo, context);
    },
    // Always refetch after error or success:
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: customerKeys.customer(customerId),
      });

      options?.onSettled?.(...args);
    },
  });
};

export default useOptimisticCreateCustomerContactOnCustomer;
