import React, { useState } from 'react';

import { PublicHydratedServicePlanPeriodCommitment } from '@payaca/types/service-plans';

import CreateEditTaskModal from '../createEditTaskModal/CreateEditTaskModal';

import { updateServicePlanPeriodCommitment } from '@payaca/store/servicePlans/servicePlansActions';
import { PublicEntityTemplate } from '@payaca/types/entity-templates';
import { useDispatch } from 'react-redux';
import './ServicePlanPeriodCommitment.sass';
import ServicePlanPeriodUnarrangedCommitmentEntityCard from './ServicePlanPeriodUnarrangedCommitmentEntityCard';

type Props = {
  commitment: PublicHydratedServicePlanPeriodCommitment;
  onCommitmentUpdate: (newEntity?: {
    id: number;
    type: 'scheduledEvent' | 'task';
  }) => void;
};
const ServicePlanPeriodUnarrangedTaskCommitment = ({
  commitment,
  onCommitmentUpdate,
}: Props) => {
  const dispatch = useDispatch();
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);

  const templateData = (
    commitment.entityTemplate as PublicEntityTemplate<'task'>
  ).data;

  return (
    <>
      <ServicePlanPeriodUnarrangedCommitmentEntityCard
        commitmentId={commitment.publicId}
        name={templateData.name}
        onArrange={() => setShowCreateTaskModal(true)}
        entityType="task"
        onCommitmentUpdate={onCommitmentUpdate}
      />
      <CreateEditTaskModal
        onClose={() => setShowCreateTaskModal(false)}
        onPersistTaskSuccess={(taskId) => {
          setShowCreateTaskModal(false);

          dispatch(
            updateServicePlanPeriodCommitment.request({
              servicePlanPeriodCommitment: {
                publicId: commitment.publicId,
                entityId: taskId.toString(),
              },
              callback: () =>
                onCommitmentUpdate({
                  id: taskId,
                  type: 'task',
                }),
            })
          );
        }}
        isOpen={showCreateTaskModal}
        task={{
          name: templateData.name || 'task',
          notes: templateData.notes || '',
          checklistItems:
            templateData.checklistItems?.map((x) => ({ name: x })) || [],
          formTemplateId: templateData.formTemplateId
            ? +templateData.formTemplateId
            : undefined,
          // todo put this on the commitment...?
          customerId: commitment.customerId,
        }}
      />
    </>
  );
};

export default ServicePlanPeriodUnarrangedTaskCommitment;
