import {
  BaseSupplierMaterial,
  SupplierMaterialTotals,
  SupplierMaterial,
} from '@payaca/types/materialTypes';

export const getSupplierMaterialTotals = (
  supplierMaterial: BaseSupplierMaterial
): SupplierMaterialTotals => {
  const tax = Math.round(
    supplierMaterial.isTaxIncluded && supplierMaterial.taxPercentage
      ? (supplierMaterial.price * supplierMaterial.taxPercentage) / 100
      : 0
  );

  return {
    tax: tax,
    priceIncludingTax: supplierMaterial.price + tax,
  };
};

export const getPreferredSupplierMaterial = <T extends BaseSupplierMaterial>(
  supplierMaterials: T[],
  preferredSupplierId?: number
) => {
  // FIXME: supplierMaterials is not supposed to be falsy,
  // but non-type-safe code is sometimes calling this with null or undefined.
  // I haven't yet reproduced it in an environment with a meaningful stack trace
  // or source mapping, so for now I've simply added a nullish dereference to
  // mitigate potential crashes:
  if (!supplierMaterials?.length) return;

  let supplierMaterial;
  if (preferredSupplierId) {
    supplierMaterial = supplierMaterials.find(
      (x) => x.supplierId === preferredSupplierId
    );
  }

  if (supplierMaterial) return supplierMaterial;

  return supplierMaterials.sort((a, b) => {
    return a.price - b.price;
  })[0];
};
