import moment from 'moment-timezone';

export const getDurationValidator = (startTimeFieldName: string) => {
  return (
    fieldName: string,
    formState: {
      [key: string]: any;
    }
  ) => {
    const duration = moment.duration(formState[fieldName]);
    const endTime = moment(formState[startTimeFieldName]).add(duration);

    if (!endTime.isBefore(moment())) {
      return {
        isValid: false,
        errors: ['Timelog cannot be in the future'],
      };
    } else if (duration.asMilliseconds() == 0) {
      return {
        isValid: false,
        errors: ['Timelog must have a duration'],
      };
    } else {
      return {
        isValid: true,
      };
    }
  };
};
