import { FC, FunctionComponent, useMemo } from 'react';

import { currencyPrice } from '@/helpers/financeHelper';

import {
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';

import { useSelector } from '@/api/state';

import Card from '@payaca/components/plCard/Card';
import Button from '@payaca/components/plButton/Button';
import UntitledIcon from '@payaca/untitled-icons';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import { hasUnlimitedUsers } from '@payaca/helpers/subscriptionHelper';
import { IntercomAPI } from 'react-intercom';

type Props = {
  subscriptionProduct: SubscriptionProduct;
  recurringInterval: RecurringInterval;
  isSelected: boolean;
  onSelect: (isSelected: boolean) => void;
};

const SubscriptionProductSelectionControl: FunctionComponent<Props> = ({
  subscriptionProduct,
  recurringInterval,
  isSelected,
  onSelect,
}: Props): JSX.Element => {
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const productPrice = useMemo(() => {
    return recurringInterval === RecurringInterval.MONTH
      ? subscriptionProduct.monthlyPrice
      : subscriptionProduct.annualPrice;
  }, [subscriptionProduct, recurringInterval]);

  const usersText = useMemo(() => {
    if (hasUnlimitedUsers(subscriptionProduct.numberOfUserSeats)) {
      return 'Unlimited Users';
    } else {
      if (subscriptionProduct.minRequiredUserSeats) {
        // user required to buy minimum seats
        return `Up to ${subscriptionProduct.minRequiredUserSeats} Users`;
      } else {
        // included seats in base price
        return `${subscriptionProduct.numberOfUserSeats} Users`;
      }
    }
  }, [subscriptionProduct]);

  return (
    <Card>
      <Card.Body>
        <h2 className="text-2xl">{subscriptionProduct.name}</h2>

        {/* description */}
        {!!subscriptionProduct.displayMetdata.description && (
          <div className="flex min-h-[78px] items-center">
            <p className="text-gray-600 mt-2">
              {subscriptionProduct.displayMetdata.description}
            </p>
          </div>
        )}

        {/* price */}
        {productPrice && (
          <div>
            <div className="flex flex-col mt-4">
              <span className="text-gray-800 font-semibold text-xl dark:text-neutral-200">
                {subscriptionProduct.hasCustomPrice && (
                  <span className="text-base font-medium">from </span>
                )}

                {/* base subscription cost */}
                {currencyPrice(
                  productPrice.basicCost +
                    (subscriptionProduct.minRequiredUserSeats &&
                    productPrice.additionalUserSeatCost // include cost of min required seats in base cost
                      ? productPrice.additionalUserSeatCost *
                        subscriptionProduct.minRequiredUserSeats
                      : 0),
                  account.region,
                  false
                )}
              </span>

              {/* users */}
              <span className="text-gray-600 mt-1">{usersText}</span>
            </div>
          </div>
        )}

        {/* select plan button */}
        <Button
          variant={isSelected ? EBtnVariant.White : EBtnVariant.Outline}
          disabled={isSelected}
          onClick={() =>
            subscriptionProduct.hasCustomPrice
              ? IntercomAPI('show')
              : onSelect(isSelected)
          }
          className="mt-5 w-full"
        >
          {subscriptionProduct.hasCustomPrice
            ? 'Get in touch'
            : isSelected
              ? 'Current plan'
              : `Select ${subscriptionProduct.name}`}
        </Button>

        {/* feature list */}
        {!!subscriptionProduct.displayMetdata.featuresList.length && (
          <ul className="list-none mt-5 space-y-1 p-0">
            {subscriptionProduct.displayMetdata.featuresList.map(
              (feature, i) => {
                return <FeatureListItem key={`feature-${i}`} label={feature} />;
              }
            )}
          </ul>
        )}
      </Card.Body>
    </Card>
  );
};

const FeatureListItem: FC<{ label: string }> = (props) => {
  const { label } = props;
  return (
    <li className="flex gap-x-3">
      <UntitledIcon name="check" className="h-4 w-4 shrink-0 mt-2" />
      <span>{label}</span>
    </li>
  );
};

export default SubscriptionProductSelectionControl;
