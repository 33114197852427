import React, { FC, useMemo } from 'react';

import StatusBadge from '@payaca/components/statusBadge/StatusBadge';
import { ReadableChangeProposalStatus } from '@payaca/types/changeProposalTypes';
import { getChangeProposalStatusBadgeState } from '@payaca/helpers/changeProposalHelper';

type Props = {
  status: ReadableChangeProposalStatus;
};

const ChangeProposalStatusBadge: FC<Props> = ({
  status,
}: Props): JSX.Element => {
  const state = useMemo(
    () => getChangeProposalStatusBadgeState(status),
    [status]
  );

  return <StatusBadge state={state}>{status}</StatusBadge>;
};

export default ChangeProposalStatusBadge;
