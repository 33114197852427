import moment from 'moment-timezone';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';

import ConfirmDeleteScheduledEventModal from '@/ui/components/confirmDeleteScheduledEventModal/ConfirmDeleteScheduledEventModal';
import HeaderTag, {
  HeaderTagType,
} from '@payaca/components/headerTag/HeaderTag';
import ScheduledEventControl from '../scheduledEventControl/ScheduledEventControl';

import * as scheduledEventActions from '@payaca/store/scheduledEvents/scheduledEventsActions';

import './DealListedScheduledEvents.sass';

enum EventStatus {
  PAST = 'PAST',
  PRESENT = 'PRESENT',
  FUTURE = 'FUTURE',
}

interface Props {
  dealScheduledEvents: ScheduledEvent[];
  hideTitles?: boolean;
  onDealUpdateSuccess: () => void;
  hideQuickActions?: boolean;
  titleHeaderTagType?: HeaderTagType;
}

export const DealListedScheduledEvents: FC<Props> = ({
  dealScheduledEvents,
  hideTitles = false,
  onDealUpdateSuccess,
  hideQuickActions = true,
  titleHeaderTagType = HeaderTagType.H3,
}: Props) => {
  const dispatch = useDispatch();

  const [confirmDeleteScheduledEvent, setConfirmDeleteScheduledEvent] =
    useState<number | null>(null);

  const archiveEvent = useCallback(
    (scheduledEventId: number) => {
      dispatch(
        scheduledEventActions.requestArchiveScheduledEvent(scheduledEventId)
      );
    },
    [dispatch]
  );

  const getEventStatus = useCallback((beginAt: Date, endAt: Date) => {
    if (moment(beginAt).isBefore(moment())) {
      if (moment(endAt).isBefore(moment())) {
        return EventStatus.PAST;
      } else {
        return EventStatus.PRESENT;
      }
    } else {
      return EventStatus.FUTURE;
    }
  }, []);

  const sortedDealScheudledEvents = useMemo(() => {
    return dealScheduledEvents.reduce(
      (
        acc: {
          past: ScheduledEvent[];
          present: ScheduledEvent[];
          future: ScheduledEvent[];
        },
        curr: ScheduledEvent
      ) => {
        const eventStatus = getEventStatus(curr.beginAt, curr.endAt);
        if (eventStatus === EventStatus.PAST) {
          acc.past.unshift(curr);
        } else if (eventStatus === EventStatus.PRESENT) {
          acc.present.push(curr);
        } else {
          acc.future.push(curr);
        }
        return acc;
      },
      { past: [], present: [], future: [] }
    );
  }, [dealScheduledEvents]);

  const renderScheduledEventItem = useCallback(
    (dealScheduledEvent: ScheduledEvent, i: number) => {
      return (
        <ScheduledEventControl
          hideAssignedUsers={true}
          key={`scheduled-event-control${i}`}
          scheduledEventId={dealScheduledEvent.id}
          hideQuickActions={hideQuickActions}
          onPersistScheduledEventSuccess={onDealUpdateSuccess}
          onDeleteScheduledEventSuccess={onDealUpdateSuccess}
        />
      );
    },
    [hideQuickActions, onDealUpdateSuccess]
  );

  return (
    <div
      className={`deal-listed-scheduled-events${
        hideTitles ? ' no-titles' : ''
      }`}
    >
      {!!sortedDealScheudledEvents.present.length && (
        <div className="deal-scheduled-events-list-present">
          {!hideTitles && (
            <HeaderTag type={titleHeaderTagType}>Happening now</HeaderTag>
          )}
          {sortedDealScheudledEvents.present.map(renderScheduledEventItem)}
        </div>
      )}
      <div className="deal-scheduled-events-list-future">
        {!hideTitles && (
          <HeaderTag type={titleHeaderTagType}>Upcoming Events</HeaderTag>
        )}
        {sortedDealScheudledEvents.future.length ? (
          sortedDealScheudledEvents.future.map(renderScheduledEventItem)
        ) : !hideTitles ? (
          <span>No upcoming Events</span>
        ) : null}
      </div>
      {!!sortedDealScheudledEvents.past.length && (
        <div className="deal-scheduled-events-list-past">
          {!hideTitles && (
            <HeaderTag type={titleHeaderTagType}>Past Events</HeaderTag>
          )}
          {sortedDealScheudledEvents.past.map(renderScheduledEventItem)}
        </div>
      )}
      <ConfirmDeleteScheduledEventModal
        onClose={() => setConfirmDeleteScheduledEvent(null)}
        isOpen={!!confirmDeleteScheduledEvent}
        onConfirmDeleteScheduledEvent={() => {
          confirmDeleteScheduledEvent &&
            archiveEvent(confirmDeleteScheduledEvent);
          setConfirmDeleteScheduledEvent(null);
        }}
      />
    </div>
  );
};

export default DealListedScheduledEvents;
