import { FC, useEffect } from 'react';
import { UnionFieldArray } from './ConnectionApplication';
import { get, Controller, useFormContext, useWatch } from 'react-hook-form';
import Field, { ValidationMessages } from '@payaca/components/plField/Field';
import Select from '@payaca/components/plSelect/Select';
import FieldSet from './FieldSet';
import {
  fieldValidationState,
  getDeviceClassForDeviceType,
  getDeviceItemForDeviceType,
  getDeviceTypesFromSchemaSection,
  getSchemaDataForDevice,
} from './utils';
import { sentenceCase } from '@payaca/utilities/stringUtilities';
import { FormSection } from '@/api/rest/connectionApplication/useGetConnectionApplicationSchema';
import DeviceCard from '@/ui/pages/connectionApplicationPage/components/DeviceCard';
import DeviceArrayCard from '@/ui/pages/connectionApplicationPage/components/DeviceArrayCard';

const FieldArray: FC<{
  schemaSection: FormSection;
  definitions: Record<string, any>;
  fieldKey: string;
  unionFieldArray: UnionFieldArray;
}> = ({ definitions, schemaSection, fieldKey: key, unionFieldArray }) => {
  const form = useFormContext();
  const lcts = form.watch('lcts');
  const fieldArrayError = get(form.formState.errors, key);
  const deviceTypes = getDeviceTypesFromSchemaSection(schemaSection, key, lcts);
  const output = useWatch({
    control: form.control,
    name: key,
  });

  // Ensure that the `deviceClass` for each field is set
  // If the deviceType changes, update the `deviceClass`
  useEffect(() => {
    if (output) {
      output.forEach((item: any, index: number) => {
        if (item.deviceType) {
          const newDeviceClass = getDeviceClassForDeviceType(
            schemaSection,
            item.deviceType
          );
          const deviceClassKey = `${key}.${index}.deviceClass`;

          const currentDeviceClass = form.getValues(deviceClassKey);
          if (currentDeviceClass !== newDeviceClass) {
            form.setValue(deviceClassKey, newDeviceClass, {
              shouldValidate: true,
            });
          }
        }
      });
    }
  }, [output]);

  return (
    <DeviceArrayCard
      onAdd={() =>
        unionFieldArray.fieldArray?.append({
          deviceType: null,
          deviceSysRef: null,
          deviceClass: null,
        })
      }
    >
      {unionFieldArray.fieldArray?.fields.map((field: any, index: number) => {
        // namespace for field array index
        const namespace = `${key}.${index}`;
        const deviceType = field.deviceType;
        const deviceTypeName = `${namespace}.deviceType`;
        const item = getDeviceItemForDeviceType(
          schemaSection,
          deviceType || deviceTypes[0]
        );

        const deviceTypeField = item?.properties?.deviceType as
          | { title: string }
          | undefined;
        const schemaData = getSchemaDataForDevice(
          schemaSection,
          definitions,
          output?.[index]?.deviceType || null
        );

        const fieldError = get(
          form.formState.errors,
          `${namespace}.deviceClass`
        );

        const fieldProps = fieldError
          ? {
              validationState: {
                isValid: false,
                validationMessages: ['This field is required'],
              },
            }
          : {};

        return (
          <DeviceCard
            key={field.id}
            onRemove={
              // conditionally allow remove dependent on a minItems limit
              schemaSection.minItems &&
              unionFieldArray.fieldArray?.fields.length <=
                schemaSection.minItems
                ? undefined
                : () => unionFieldArray.fieldArray?.remove(index)
            }
          >
            <Field name={deviceTypeName} {...fieldProps}>
              <Field.Label>
                {sentenceCase(deviceTypeField?.title || '')}
              </Field.Label>
              <Controller
                control={form.control}
                name={deviceTypeName}
                render={({ field: { ref, onChange, ...rest } }) => (
                  <Select
                    {...rest}
                    options={deviceTypes.map((option: any) => ({
                      label: option,
                      value: option,
                    }))}
                    onChange={(change) => {
                      const newSchemaData = getSchemaDataForDevice(
                        schemaSection,
                        definitions,
                        change
                      );
                      if (deviceType !== change && newSchemaData) {
                        // deviceType has changed - reset the fields
                        Object.keys(newSchemaData.properties).forEach((key) => {
                          form.setValue(`${namespace}.${key}`, null);
                        });
                      }

                      // update deviceType
                      onChange(change);
                    }}
                  />
                )}
              />
            </Field>

            {/* conditional fields based on deviceType */}
            {schemaData && (
              <FieldSet
                namespace={namespace}
                data={schemaData}
                definitions={definitions}
              />
            )}
          </DeviceCard>
        );
      })}

      {!!fieldArrayError?.message && (
        <ValidationMessages
          validationState={fieldValidationState(fieldArrayError)}
        />
      )}
    </DeviceArrayCard>
  );
};
export default FieldArray;
