import React, { FC, useMemo } from 'react';

import { ListedMaterial } from '@payaca/types/listedMaterialTypes';

import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import PaginationControl from '@payaca/components/paginationControl/PaginationControl';
import EmptyState from '../emptyState/EmptyState';
import ListedMaterialCard from '../listedMaterialCard/ListedMaterialCard';

import { useSelector } from '@/api/state';

import './ListedMaterials.sass';

type Props = {
  onClickMaterial?: (materialId: number) => void;
  onSelectPage: (pageNumber: number) => void;
  onSelectPageSize?: (pageSize: number) => void;
  quickActionDefinitions?: {
    archive?: {
      actionName: string;
      actionBehaviour: (recordId: any) => void;
      isActionProcessing?: boolean;
    };
  };
  isMultiSelect?: boolean;
  selectedMaterialIds?: number[];
  disabledMaterialIds?: number[];
};

const ListedMaterials: FC<Props> = ({
  onClickMaterial,
  onSelectPage,
  onSelectPageSize,
  quickActionDefinitions,
  isMultiSelect = false,
  selectedMaterialIds,
  disabledMaterialIds,
}: Props): JSX.Element | null => {
  const listedMaterialsPage = useSelector((state: any) => {
    return state.listedMaterials.listedMaterialsPage;
  });

  const isGettingListedMaterialsPage = useSelector(
    (state) => state.listedMaterials.isGettingListedMaterialsPage
  );

  const paginationControl = useMemo(() => {
    if (!listedMaterialsPage) return null;
    return (
      <PaginationControl
        pageNumber={listedMaterialsPage.pageNumber}
        pageSize={listedMaterialsPage.pageSize}
        totalItemCount={listedMaterialsPage.totalItemCount}
        onSelectPage={onSelectPage}
        allowPageSizeSelection={true}
        availablePageSizes={[5, 20, 30, 50]}
        onSelectPageSize={onSelectPageSize}
      />
    );
  }, [listedMaterialsPage, onSelectPage]);

  return (
    <div className="listed-materials">
      {isGettingListedMaterialsPage && (
        <div className="loader-container">
          <MiniLoader />
        </div>
      )}
      {!isGettingListedMaterialsPage &&
        !listedMaterialsPage?.totalItemCount &&
        !listedMaterialsPage?.totalUnfilteredItemCount && (
          <EmptyState configName={'materials'} arrowIndication={false} />
        )}
      {!isGettingListedMaterialsPage &&
        !listedMaterialsPage?.totalItemCount &&
        !!listedMaterialsPage?.totalUnfilteredItemCount && (
          <div className="no-results-container">
            <>No materials to show.</>
          </div>
        )}
      {!isGettingListedMaterialsPage &&
        !!listedMaterialsPage?.items?.length && (
          <>
            {listedMaterialsPage.items.map(
              (listedMaterial: ListedMaterial, index: number) => {
                const isSelected = !!selectedMaterialIds?.includes(
                  listedMaterial.id
                );
                const isDisabled = !!disabledMaterialIds?.includes(
                  listedMaterial.id
                );
                const onClick = () =>
                  !isDisabled
                    ? onClickMaterial?.(listedMaterial.id)
                    : undefined;
                const selectionConfig = isMultiSelect
                  ? { isSelected, onSelectionChange: onClick, isDisabled }
                  : undefined;
                return (
                  <ListedMaterialCard
                    key={`${listedMaterial.id}-${index}`}
                    listedMaterial={listedMaterial}
                    onClick={onClick}
                    quickActionDefinitions={quickActionDefinitions}
                    selectionConfig={selectionConfig}
                  />
                );
              }
            )}
          </>
        )}
      {paginationControl}
    </div>
  );
};

export default ListedMaterials;
