import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  SetCustomerPrimaryContactInput,
  SetCustomerPrimaryContactMutation as _SetCustomerPrimaryContactMutation,
} from '@/gql/graphql';

const SetCustomerPrimaryContactMutation = graphql(`
  mutation SetCustomerPrimaryContact($input: SetCustomerPrimaryContactInput!) {
    setCustomerPrimaryContact(input: $input) {
      id
    }
  }
`);

const useSetCustomerPrimaryContact = (
  options?: UseMutationOptions<
    _SetCustomerPrimaryContactMutation,
    unknown,
    SetCustomerPrimaryContactInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(SetCustomerPrimaryContactMutation, { input });
    },
    ...options,
  });
};

export default useSetCustomerPrimaryContact;
