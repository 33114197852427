import { ListViewPage } from './listViewTypes';
import {
  BaseMaterial,
  MaterialRelationships,
  SupplierMaterial,
} from './materialTypes';
import { BaseSupplier } from './supplierTypes';

export interface ListedSupplierMaterial extends SupplierMaterial {
  material: BaseMaterial &
    Pick<MaterialRelationships, 'thumbnailUrl' | 'category'>;
  supplier: BaseSupplier;
}

export enum SortBy {
  COST = 'cost',
  MATERIAL_NAME = 'materialName',
  SUPPLIER_NAME = 'supplierName',
}

export interface ListedSupplierMaterialListViewPage
  extends ListViewPage<ListedSupplierMaterial> {}
