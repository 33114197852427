import {
  ActionType,
  GetDispatchPage,
  GetLocationSearch,
} from './dispatchTypes';
import { createAsyncAction } from '../utils';

export const getLocationSearch = createAsyncAction<GetLocationSearch>({
  request: ActionType.GET_LOCATION_SEARCH_REQUEST,
})((payload: GetLocationSearch['request']['payload']) => ({ payload }));

export const getDispatchPage = createAsyncAction<GetDispatchPage>({
  request: ActionType.GET_DISPATCH_PAGE_REQUEST,
  success: ActionType.GET_DISPATCH_PAGE_SUCCESS,
  failure: ActionType.GET_DISPATCH_PAGE_FAILURE,
})((payload: GetDispatchPage['request']['payload']) => ({ payload }));
