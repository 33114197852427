import {
  DynamicFeedbackLifespanMs,
  DynamicFeedbackMessage,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import React, { PropsWithChildren, useState } from 'react';
import { v4 } from 'uuid';

type QueueEntry = {
  id: string;
  addedAt: Date;
  onCancelCallback?: (isCancelled: boolean) => void;
  dynamicFeedbackMessage: DynamicFeedbackMessage;
};

type DynamicFeedbackContextValue = {
  messages: Array<QueueEntry>;
  showDynamicFeedbackMessage: (
    message: DynamicFeedbackMessage,
    onAddToQueueCallback?: (id: string) => void,
    onRemoveFromQueueCallback?: (isCancelled: boolean) => void
  ) => () => void;
  cancelDynamicFeedbackMessage: (id: string) => void;
};

export const DynamicFeedbackContext =
  React.createContext<DynamicFeedbackContextValue>({
    messages: [],
    showDynamicFeedbackMessage: () => () => {},
    cancelDynamicFeedbackMessage: () => {},
  });

export const DynamicFeedbackContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [messages, setMessages] = useState<Array<QueueEntry>>([]);

  const showDynamicFeedbackMessage = (
    message: DynamicFeedbackMessage,
    onAddToQueueCallback?: (id: string) => void,
    onRemoveFromQueueCallback?: (isCancelled: boolean) => void
  ) => {
    const id = v4();

    const dynamicFeedbackMessage = {
      lifespanMs: !message.isCancellable
        ? DynamicFeedbackLifespanMs.MEDIUM
        : undefined,
      feedbackLevel: FeedbackLevel.NEUTRAL,
      ...message,
    };

    const onCancelCallback =
      dynamicFeedbackMessage.isCancellable && onRemoveFromQueueCallback
        ? () => onRemoveFromQueueCallback?.(true)
        : undefined;

    setMessages((prev) => [
      ...prev,
      {
        id,
        addedAt: new Date(),
        onCancelCallback,
        dynamicFeedbackMessage,
      },
    ]);

    onAddToQueueCallback?.(id);

    if (dynamicFeedbackMessage.lifespanMs) {
      window.setTimeout(() => {
        setMessages((prev) => {
          const msg = prev.find((x) => x.id === id);
          const next = prev.filter((x) => x !== msg);
          msg?.onCancelCallback?.(false);
          return next;
        });
      }, dynamicFeedbackMessage.lifespanMs);
    }

    return () => {
      cancelDynamicFeedbackMessage(id);
    };
  };

  const cancelDynamicFeedbackMessage = (id: string) => {
    setMessages((prev) => {
      const msg = prev.find((x) => x.id === id);
      const next = prev.filter((x) => x !== msg);
      msg?.onCancelCallback?.(true);
      return next;
    });
  };

  return (
    <DynamicFeedbackContext.Provider
      value={{
        messages,
        showDynamicFeedbackMessage,
        cancelDynamicFeedbackMessage,
      }}
    >
      {children}
    </DynamicFeedbackContext.Provider>
  );
};
