import { action } from 'typesafe-actions';

import {
  DealActivityEvent,
  GetDealActivityEventsRequestData,
} from '@payaca/types/dealActivityTypes';
import { DealActivityActionTypes } from './dealActivityTypes';

export const requestGetDealActivityEvents = (
  dealId: number,
  getDealActivityEventsRequestData: GetDealActivityEventsRequestData
) => {
  return action(DealActivityActionTypes.REQUEST_GET_DEAL_ACTIVITY_EVENTS, {
    dealId,
    getDealActivityEventsRequestData,
  });
};

export const clearDealActivityEvents = () => {
  return action(DealActivityActionTypes.CLEAR_DEAL_ACTIVITY_EVENTS);
};

export const getDealActivityEventsSuccess = (
  dealActivityEvents: DealActivityEvent[]
) => {
  return action(DealActivityActionTypes.GET_DEAL_ACTIVITY_EVENTS_SUCCESS, {
    dealActivityEvents,
  });
};

export const getDealActivityEventsFailure = (error: Error) => {
  return action(
    DealActivityActionTypes.GET_DEAL_ACTIVITY_EVENTS_FAILURE,
    null,
    null,
    error
  );
};
