import { SendableEntity } from './sendableEntityTypes';
import { AssignableEntity } from './assignableEntityTypes';
import { TimestampedEntity } from './timestampedEntityTypes';
import { VoidableEntity } from './voidableEntityTypes';
import { ReferencedEntity } from './referencedEntityTypes';
import { JobLineItem } from './jobContentTypes';
import { Nullable } from '@payaca/utilities/types';
import { Customer } from './customerTypes';
import { Deal } from './dealTypes';

export interface Invoice
  extends BaseInvoice,
    InvoiceRelationships,
    InvoiceTotals {}

export interface BaseInvoice
  extends TimestampedEntity,
    VoidableEntity,
    SendableEntity,
    ReferencedEntity,
    AssignableEntity {
  id: number;
  dealId: number;
  dueAt?: Date;
  dueInDays?: number;
  isProFormaInvoice: boolean;
  isDepositInvoice: boolean;
  contactId?: number;
  createdByUserId?: number;
  notes?: string;
  readableStatus: ReadableInvoiceStatus;
  latestClientViewLogAt?: Date;
  quickbooksInvoiceId: Nullable<string>;
  xeroInvoiceId: Nullable<string>;
}

export interface InvoiceRelationships {
  invoiceLineIds: number[];
  paymentReconciliationRecordIds: [];
  pdfUrl?: string;
  attachmentUploadIds: number[];
}

export interface InvoiceTotals {
  totalValue: number;
  reconciledPaymentValue: number;
  dueValue: number;
}

export interface InvoiceLine extends TimestampedEntity {
  id: number;
  invoiceId: number;
  jobLineItemId: number;
  value: number;
  jobLineItemQuantity: number;
  jobLineItemPercentage: number;
  subTotalValue: number;
  taxValue: number;
  cisValue: number;
}
export interface InvoiceLineWithJobLineItem extends InvoiceLine {
  jobLineItem?: JobLineItem;
}
export interface PaymentReconciliationRecord {
  id: number;
  dealId: number;
  invoiceId?: number;
  jobPaymentId: number;
  value: number;
}
export enum ReadableInvoiceStatus {
  SENT = 'Sent',
  VOID = 'Void',
  PAID = 'Paid',
  PARTIALLY_PAID = 'Partially paid',
  DRAFT = 'Draft',
}

export enum InvoiceUploadRoles {
  GENERATED_PDF = 'generated-pdf',
  ATTACHMENT = 'attachment',
}

export type ListedInvoice = Pick<
  Invoice,
  | 'id'
  | 'readableStatus'
  | 'totalValue'
  | 'dueValue'
  | 'dueAt'
  | 'quickbooksInvoiceId'
  | 'xeroInvoiceId'
> & {
  reference: string;
  customer: Pick<Customer, 'id' | 'name'>;
  deal: Pick<Deal, 'id' | 'reference' | 'customReference'>;
};
