import ChangeProposalDocument from '@payaca/components/changeProposalDocument/ChangeProposalDocument';
import * as pdfDocumentDataActions from '@payaca/store/pdfDocumentData/pdfDocumentDataActions';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ChangeProposalPdfDocumentData } from '@payaca/types/changeProposalPdfDocumentTypes';
import './ChangeProposalPage.sass';
import { IntercomAPI } from 'react-intercom';

type Props = {
  previewToken: string;
  changeProposalId: number;
};

const ChangeProposalPage: FC<Props> = ({
  previewToken,
  changeProposalId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [changeProposalData, setChangeProposalData] =
    useState<ChangeProposalPdfDocumentData>();

  useEffect(() => {
    dispatch(
      pdfDocumentDataActions.getChangeProposalData.request(
        previewToken,
        setChangeProposalData
      )
    );

    IntercomAPI('update', {
      hide_default_launcher: true,
    });

    return () => {
      IntercomAPI('update', {
        hide_default_launcher: false,
      });
    };
  }, []);

  if (!changeProposalData) return null;

  return (
    <div className="pdf-change-proposal-page">
      <ChangeProposalDocument
        documentSenderContext={changeProposalData.documentSenderContext}
        changeProposals={changeProposalData.changeProposals}
        changeProposalId={changeProposalId}
        jobLineItems={changeProposalData.jobLineItems}
        customer={changeProposalData.customer}
        siteAddresses={changeProposalData.siteAddresses || []}
        acceptedJobContent={changeProposalData.acceptedJobContent}
        hideItemPrices={changeProposalData.hideItemPrices}
        hideVatBreakdown={changeProposalData.hideVatBreakdown}
      />
    </div>
  );
};

export default ChangeProposalPage;
