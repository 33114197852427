import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ModalWrapper } from '@/ui/components';
import Modal from '@payaca/components/modal/Modal';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { isQuoteOrEstimate } from '@payaca/helpers/jobStatusHelper';
import * as jobsStoreActions from '@payaca/store/jobs/jobsActions';

import './EditJobModal.css';
import { getJob } from '@/utils/stateAccessors';
import { getJobType } from '@payaca/helpers/jobHelperV2';
import { useSelector } from '@/api/state';
type Props = {
  isOpen: boolean;
  jobId: number;
  dealId?: number;
  onClose: () => void;
};
const EditJobModal: FC<Props> = ({
  isOpen,
  jobId,
  dealId,
  onClose,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const job = useSelector((state) => {
    return getJob(state, jobId);
  });

  const jobType = useMemo(() => {
    if (!job) return 'job';
    return getJobType(job).toLowerCase();
  }, [job]);
  const [isEditingJob, setIsEditingJob] = useState(false);
  const editJobSuccess = useSelector((state) => state.jobsStore.editJobSuccess);
  const editJobErrorMessage = useSelector(
    (state) => state.jobsStore.editJobErrorMessage
  );

  const editJob = useCallback(() => {
    if (isEditingJob) return;
    setIsEditingJob(true);
    dispatch(jobsStoreActions.requestEditJob(jobId));
  }, [dispatch, jobId, isEditingJob]);

  useEffect(() => {
    setIsEditingJob(false);
    // new job id returned from request - navigate to new job page
    if (editJobSuccess) {
      onClose();
      const jobTypePath = isQuoteOrEstimate(editJobSuccess.status)
        ? 'quotes'
        : 'invoices';
      history.replace({
        pathname: `/${jobTypePath}/${editJobSuccess.id}`,
        search: dealId ? `?dealId=${dealId}` : '',
      });
    }
  }, [history, editJobSuccess]);

  useEffect(() => {
    return () => {
      dispatch(jobsStoreActions.clearEditJob());
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      title={`Edit ${jobType}`}
      size="sm"
      onClose={onClose}
      className="edit-job-modal"
      actions={
        <>
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={editJob}
            isProcessing={isEditingJob}
          >
            Yes, edit {jobType}
          </Button>
          <Button styleVariant={ButtonStyleVariant.ANCHOR} onClick={onClose}>
            No, cancel
          </Button>
        </>
      }
    >
      <p>
        If you edit this {jobType}, your customer will no longer be able to
        access it using the link that has been sent to them. When you send the
        job again, the customer will receive a new access link. Are you sure you
        want to edit this {jobType}?
      </p>
      {editJobErrorMessage && (
        <div className="error">
          Sorry, there was an error editing your {jobType}. Please try again.
        </div>
      )}
    </Modal>
  );
};

export default EditJobModal;
