import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useMemo } from 'react';

import DropdownField from '../dropdownField/DropdownField';

import './PaginationControl.sass';

type Props = {
  pageNumber: number;
  totalItemCount: number;
  pageSize: number;
  allowPageSizeSelection?: boolean;
  availablePageSizes?: number[];
  onSelectPage: (pageNumber: number) => void;
  onSelectPageSize?: (pageSize: number) => void;
};

const PaginationControl: FunctionComponent<Props> = ({
  pageNumber,
  totalItemCount,
  pageSize,
  allowPageSizeSelection = false,
  availablePageSizes = [10, 20, 40],
  onSelectPage,
  onSelectPageSize,
}: Props): JSX.Element => {
  const shouldRenderPageSizeSelection = useMemo(() => {
    return allowPageSizeSelection && onSelectPageSize;
  }, [allowPageSizeSelection, onSelectPageSize]);

  const shouldRenderPageSelectionControl = useMemo(() => {
    return totalItemCount > pageSize;
  }, [totalItemCount, pageSize]);

  const lastPage = useMemo(() => {
    return Math.ceil(totalItemCount / pageSize);
  }, [pageSize, totalItemCount]);

  const shouldRenderPreviousArrow = useMemo(() => {
    return pageNumber > 1;
  }, [pageNumber]);

  const shouldRenderNextArrow = useMemo(() => {
    return pageNumber < lastPage;
  }, [pageNumber, lastPage]);

  const pageIndicators = useMemo(() => {
    let pageIndicators = [1, lastPage];

    pageIndicators.push(pageNumber);
    pageIndicators.push(pageNumber - 1);
    pageIndicators.push(pageNumber - 2);
    pageIndicators.push(pageNumber + 1);
    pageIndicators.push(pageNumber + 2);

    pageIndicators = pageIndicators.sort((a: number, b: number) => {
      return a - b;
    });
    pageIndicators = pageIndicators.filter(
      (pageIndicator: number, index: number) => {
        return (
          pageIndicator > 0 &&
          pageIndicator <= lastPage &&
          pageIndicators.indexOf(pageIndicator) === index
        );
      }
    );

    return pageIndicators;
  }, [lastPage, pageNumber]);

  return (
    <div className="pagination-control">
      <div className="total-item-count">{totalItemCount} results</div>

      {shouldRenderPageSelectionControl && (
        <div className="page-selection-control">
          {shouldRenderPreviousArrow && (
            <span
              onClick={() => onSelectPage(pageNumber - 1)}
              className="arrow-control"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </span>
          )}
          {pageIndicators.map((pageIndicator: number, index: number) => {
            const prefixWithEllipsis =
              index !== 0 && pageIndicators[index - 1] < pageIndicator - 1;

            return (
              <React.Fragment key={index}>
                {prefixWithEllipsis && <span className="ellipsis">...</span>}
                <span
                  onClick={() => onSelectPage(pageIndicator)}
                  className={`${pageIndicator === pageNumber ? 'active' : ''}`}
                >
                  {pageIndicator}
                </span>
              </React.Fragment>
            );
          })}
          {shouldRenderNextArrow && (
            <span
              onClick={() => onSelectPage(pageNumber + 1)}
              className="arrow-control"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          )}
        </div>
      )}
      <div>
        {shouldRenderPageSizeSelection && (
          <div className="page-size-control">
            <span>Items per page</span>
            <DropdownField
              name="pageSize"
              value={pageSize}
              options={availablePageSizes.map((availablePageSize: number) => {
                return {
                  label: `${availablePageSize}`,
                  value: availablePageSize,
                };
              })}
              onChange={(value: { [key: string]: number }) => {
                onSelectPageSize && onSelectPageSize(value.pageSize);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PaginationControl;
