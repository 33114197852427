import {
  takeEvery,
  call,
  put,
  race,
  delay,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import {
  SagaConfig,
  ActionType,
  CreateJobLineItemGroupRequestData,
  UpdateJobLineItemGroupRequestData,
  CreateJobLineItemRequestData,
  UpdateJobLineItemRequestData,
  CreateJobLineItemFromAmendmentParent,
} from './jobContentTypes';
import { UpdateJobContentRequestData } from '@payaca/types/jobContentRequestTypes';

import {
  clearJobContents,
  getJobContentFailure,
  getJobContentSuccess,
  updateJobContentFailure,
  updateJobContentSuccess,
  clearJobLineItemGroups,
  clearJobLineItems,
  getJobLineItemSuccess,
  createJobLineItemGroupFailure,
  createJobLineItemGroupSuccess,
  updateJobLineItemGroupFailure,
  updateJobLineItemGroupSuccess,
  deleteJobLineItemGroupFailure,
  deleteJobLineItemGroupSuccess,
  addLineItemGroupToJobContentFailure,
  addLineItemGroupToJobContentSuccess,
  createJobLineItemFailure,
  createJobLineItemSuccess,
  updateJobLineItemFailure,
  updateJobLineItemSuccess,
  deleteJobLineItemFailure,
  deleteJobLineItemSuccess,
  clearJobLineItemAttachments,
  addAttachmentToJobLineItemFailure,
  addAttachmentToJobLineItemSuccess,
  removeAttachmentFromJobLineItemFailure,
  removeAttachmentFromJobLineItemSuccess,
  getJobLineItemFailure,
  createJobLineItemFromAmendmentParent,
  getJobContentsForDealSuccess,
  getJobLineItemAttachmentsForJobLineItemSuccess,
  getJobLineItemsForJobContentSuccess,
  getJobLineItemsForInvoiceSuccess,
  getJobLineItemsForDealSuccess,
  getJobContentWithJobGroupsAndJobLineItemsSuccess,
  getJobLineItemGroupsForJobContentSuccess,
} from './jobContentActions';

import { Req } from '@payaca/helpers/storeHelper';

import { handleAsyncAction } from '../utils';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const jobContentSaga = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
  storage,
  storageKeys = {
    vatAmount: 'payaca-vatAmount',
    vatIncluded: 'payaca-vatIncluded',
    isReverseChargeVat: 'payaca-isReverseChargeVat',
    taxRateId: 'payaca-taxRateId',
  },
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleGetJobContent(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB_CONTENT,
      {
        jobContentId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getJobContent, action.payload.jobContentId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get job content timed out.';
        yield put(
          getJobContentFailure(
            action.payload.jobContentId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(getJobContentSuccess(action.payload.jobContentId, response));
      }
    } catch (error: any) {
      yield put(getJobContentFailure(action.payload.jobContentId, error));
    }
  }

  function* handleGetJobContentWithJobGroupsAndJobLineItems(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB_CONTENT_WITH_JOB_GROUPS_AND_JOB_LINE_ITEMS,
      {
        jobContentId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getJobContentWithJobGroupsAndJobLineItems,
          action.payload.jobContentId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get job timed out.';
        yield put(
          getJobContentFailure(
            action.payload.jobContentId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(getJobContentWithJobGroupsAndJobLineItemsSuccess(response));
        action?.payload?.callback?.();
      }
    } catch (error: any) {
      yield put(getJobContentFailure(action.payload.jobContentId, error));
    }
  }

  function* handleUpdateJobContent(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_JOB_CONTENT,
      {
        callback: () => void;
        updateJobContentRequestData: UpdateJobContentRequestData;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { timeout } = yield race({
        response: call(
          updateJobContent,
          action.payload.updateJobContentRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Update job content timed out.';
        yield put(
          updateJobContentFailure(
            action.payload.updateJobContentRequestData.jobContentId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(
          updateJobContentSuccess(
            action.payload.updateJobContentRequestData.jobContentId
          )
        );
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(
        updateJobContentFailure(
          action.payload.updateJobContentRequestData.jobContentId,
          error
        )
      );
    }
  }

  function* handleGetJobContentsForDeal(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB_CONTENTS_FOR_DEAL,
      {
        dealId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getJobContentsForDeal, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getJobContentsForDeal request timed out');
      }

      yield put(getJobContentsForDealSuccess(response));
    } catch (error: any) {}
  }

  function* handleGetJobLineItemAttachmentsForJobLineItem(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB_LINE_ITEM_ATTACHMENTS_FOR_JOB_LINE_ITEM,
      {
        jobLineItemId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getJobLineItemAttachmentsForJobLineItem,
          action.payload.jobLineItemId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error(
          'getJobLineItemAttachmentsForJobLineItem request timed out'
        );
      }

      yield put(getJobLineItemAttachmentsForJobLineItemSuccess(response));
    } catch (error: any) {}
  }

  function* handleGetJobLineItemGroupsForJobContent(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB_LINE_ITEM_GROUPS_FOR_JOB_CONTENT,
      {
        jobContentId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getJobLineItemGroupsForJobContent,
          action.payload.jobContentId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getJobLineItemGroupsForJobContent request timed out');
      }

      yield put(getJobLineItemGroupsForJobContentSuccess(response));
    } catch (error: any) {}
  }

  function* handleCreateJobLineItemGroup(
    action: PayloadAction<
      ActionType.REQUEST_CREATE_JOB_LINE_ITEM_GROUP,
      {
        createJobLineItemGroupRequestData: CreateJobLineItemGroupRequestData;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(
          createJobLineItemGroup,
          action.payload.createJobLineItemGroupRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Create job line item group timed out.';
        yield put(createJobLineItemGroupFailure(new Error(errorMessage)));
      } else {
        yield put(createJobLineItemGroupSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(createJobLineItemGroupFailure(error));
    }
  }

  function* handleUpdateJobLineItemGroup(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_GROUP,
      {
        updateJobLineItemGroupRequestData: UpdateJobLineItemGroupRequestData;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(
          updateJobLineItemGroup,
          action.payload.updateJobLineItemGroupRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Update job line item group timed out.';
        yield put(
          updateJobLineItemGroupFailure(
            action.payload.updateJobLineItemGroupRequestData.jobLineItemGroupId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(
          updateJobLineItemGroupSuccess(
            action.payload.updateJobLineItemGroupRequestData.jobLineItemGroupId
          )
        );
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(
        updateJobLineItemGroupFailure(
          action.payload.updateJobLineItemGroupRequestData.jobLineItemGroupId,
          error
        )
      );
    }
  }

  function* handleDeleteJobLineItemGroup(
    action: PayloadAction<
      ActionType.REQUEST_DELETE_JOB_LINE_ITEM_GROUP,
      {
        jobLineItemGroupId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(
          deleteJobLineItemGroup,
          action.payload.jobLineItemGroupId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Delete job line item group timed out.';
        yield put(deleteJobLineItemGroupFailure(new Error(errorMessage)));
      } else {
        yield put(deleteJobLineItemGroupSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(deleteJobLineItemGroupFailure(error));
    }
  }

  function* handleAddLineItemGroupToJobContent(
    action: PayloadAction<
      ActionType.REQUEST_ADD_LINE_ITEM_GROUP_TO_JOB_CONTENT,
      {
        jobContentId: number;
        lineItemGroupId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(
          addLineItemGroupToJobContent,
          action.payload.jobContentId,
          action.payload.lineItemGroupId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Add line item group to job content timed out.';
        yield put(addLineItemGroupToJobContentFailure(new Error(errorMessage)));
      } else {
        yield put(addLineItemGroupToJobContentSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(addLineItemGroupToJobContentFailure(error));
    }
  }

  function* handleGetJobLineItemsForJobContent(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB_LINE_ITEMS_FOR_JOB_CONTENT,
      {
        jobContentId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getJobLineItemsForJobContent,
          action.payload.jobContentId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getJobLineItemsForJobContent request timed out');
      }

      yield put(getJobLineItemsForJobContentSuccess(response));
    } catch (error: any) {}
  }

  function* handleGetJobLineItemsForInvoice(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB_LINE_ITEMS_FOR_INVOICE,
      {
        invoiceId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getJobLineItemsForInvoice, action.payload.invoiceId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getJobLineItemsForInvoice request timed out');
      }

      yield put(getJobLineItemsForInvoiceSuccess(response));
    } catch (error: any) {}
  }

  function* handleGetJobLineItemsForDeal(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB_LINE_ITEMS_FOR_DEAL,
      {
        dealId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getJobLineItemsForDeal, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getJobLineItemsForDeal request timed out');
      }

      yield put(getJobLineItemsForDealSuccess(response));
    } catch (error: any) {}
  }

  function* handleGetJobLineItem(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB_LINE_ITEM,
      {
        jobLineItemId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getJobLineItem, action.payload.jobLineItemId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (!timeout) {
        if (timeout) {
          const errorMessage = 'Get job line item timed out.';
          yield put(
            getJobLineItemFailure(
              action.payload.jobLineItemId,
              new Error(errorMessage)
            )
          );
        } else {
          yield put(
            getJobLineItemSuccess(action.payload.jobLineItemId, response)
          );
          action.payload?.callback?.();
        }
      }
    } catch (error: any) {
      getJobLineItemFailure(action.payload.jobLineItemId, error);
    }
  }

  function* handleCreateJobLineItem(
    action: PayloadAction<
      ActionType.REQUEST_CREATE_JOB_LINE_ITEM,
      {
        createJobLineItemRequestData: CreateJobLineItemRequestData;
        callback?: (jobLineItemId: number) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout, response } = yield race({
        response: call(
          createJobLineItem,
          action.payload.createJobLineItemRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Create job line item timed out.';
        yield put(createJobLineItemFailure(new Error(errorMessage)));
      } else {
        yield put(createJobLineItemSuccess());
        action.payload.callback &&
          action.payload.callback(response.jobLineItemId);
      }
    } catch (error: any) {
      yield put(createJobLineItemFailure(error));
    }
  }

  function* handleUpdateJobLineItem(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_JOB_LINE_ITEM,
      {
        updateJobLineItemRequestData: UpdateJobLineItemRequestData;
        callback?: (error?: Error) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    storage.setItem(
      storageKeys.taxRateId,
      (action.payload.updateJobLineItemRequestData.taxRateId || 0).toString()
    );

    try {
      const { timeout } = yield race({
        response: call(
          updateJobLineItem,
          action.payload.updateJobLineItemRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Update job line item  timed out.';
        yield put(
          updateJobLineItemFailure(
            action.payload.updateJobLineItemRequestData.jobLineItemId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(
          updateJobLineItemSuccess(
            action.payload.updateJobLineItemRequestData.jobLineItemId
          )
        );
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(
        updateJobLineItemFailure(
          action.payload.updateJobLineItemRequestData.jobLineItemId,
          error
        )
      );
      action.payload.callback && action.payload.callback(error as Error);
    }
  }

  function* handleUpdateJobLineItemFromLineItem(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_FROM_LINE_ITEM,
      {
        jobLineItemId: number;
        lineItemId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(
          updateJobLineItemFromLineItem,
          action.payload.jobLineItemId,
          action.payload.lineItemId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Update job line item from line item timed out.';
        yield put(
          updateJobLineItemFailure(
            action.payload.jobLineItemId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(updateJobLineItemSuccess(action.payload.jobLineItemId));
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(updateJobLineItemFailure(action.payload.jobLineItemId, error));
    }
  }

  function* handleDeleteJobLineItem(
    action: PayloadAction<
      ActionType.REQUEST_DELETE_JOB_LINE_ITEM,
      {
        jobLineItemId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(deleteJobLineItem, action.payload.jobLineItemId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Delete job line item timed out.';
        yield put(deleteJobLineItemFailure(new Error(errorMessage)));
      } else {
        yield put(deleteJobLineItemSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(deleteJobLineItemFailure(error));
    }
  }

  function* handleAddAttachmentToJobLineItem(
    action: PayloadAction<
      ActionType.REQUEST_ADD_ATTACHMENT_TO_JOB_LINE_ITEM,
      {
        jobLineItemId: number;
        attachment: { file: File; fileName: string };
        updateLineItemAttachment: boolean;
        callback?: (error?: Error) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(
          addAttachmentToJobLineItem,
          action.payload.jobLineItemId,
          action.payload.attachment,
          action.payload.updateLineItemAttachment
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Add attachment to job line item.';
        yield put(addAttachmentToJobLineItemFailure(new Error(errorMessage)));
      } else {
        yield put(addAttachmentToJobLineItemSuccess());
        action.payload.callback?.();
      }
    } catch (error: any) {
      yield put(addAttachmentToJobLineItemFailure(error));
      action.payload.callback?.(error as Error);
    }
  }

  function* handleRemoveAttachmentFromJobLineItem(
    action: PayloadAction<
      ActionType.REQUEST_REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM,
      {
        jobLineItemId: number;
        attachmentId: string;
        updateLineItemAttachment: boolean;
        callback?: (error?: Error) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(
          removeAttachmentFromJobLineItem,
          action.payload.jobLineItemId,
          action.payload.attachmentId,
          action.payload.updateLineItemAttachment
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Add attachment to job line item.';
        yield put(
          removeAttachmentFromJobLineItemFailure(new Error(errorMessage))
        );
      } else {
        yield put(removeAttachmentFromJobLineItemSuccess());
        action.payload.callback?.();
      }
    } catch (error: any) {
      yield put(removeAttachmentFromJobLineItemFailure(error));
      action.payload.callback?.(error as Error);
    }
  }

  const addAttachmentToJobLineItem = async (
    jobLineItemId: number,
    attachment: { file: File; fileName: string },
    updateLineItemAttachment: boolean
  ) => {
    const response = await req.postFile(
      `/job_line_items/${jobLineItemId}/job_line_item_attachment?updateLineItemAttachment=${updateLineItemAttachment}`,
      attachment,
      isNativeApp
    );
    if (response.ok) {
      return;
    } else {
      throw new Error(
        `addAttachmentToJobLineItem failed: ${response.status} ${response.statusText}`
      );
    }
  };

  const removeAttachmentFromJobLineItem = async (
    jobLineItemId: number,
    attachmentId: string,
    updateLineItemAttachment: boolean
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/job_line_items/${jobLineItemId}/job_line_item_attachment/${attachmentId}?updateLineItemAttachment=${updateLineItemAttachment}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `removeAttachmentFromJobLineItem failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const createJobLineItem = async (
    createJobLineItemRequestData: CreateJobLineItemRequestData
  ) => {
    const authHeader = await getAuthHeader();
    const taxRateId = await storage.getItem(storageKeys.taxRateId);

    return fetch(
      `${apiBaseurl}/api/job_line_items/create/${createJobLineItemRequestData.jobContentId}/${createJobLineItemRequestData.jobLineItemGroupId}`,
      {
        method: 'POST',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
        body: JSON.stringify({
          taxRateId: taxRateId?.length ? Number(taxRateId) : undefined,
          ...createJobLineItemRequestData,
        }),
      }
    ).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `createJobLineItem failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const updateJobLineItem = async (
    updateJobLineItemRequestData: UpdateJobLineItemRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/job_line_items/${updateJobLineItemRequestData.jobLineItemId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
        body: JSON.stringify(updateJobLineItemRequestData),
      }
    ).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `updateJobLineItem failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const updateJobLineItemFromLineItem = async (
    jobLineItemId: number,
    lineItemId: number
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/job_line_items/${jobLineItemId}/line_item/${lineItemId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `updateJobLineItemFromLineItem failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const deleteJobLineItem = async (jobLineItemId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/job_line_items/${jobLineItemId}`, {
      method: 'DELETE',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `deleteJobLineItem failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const createJobLineItemGroup = async (
    createJobLineItemGroupRequestData: CreateJobLineItemGroupRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/job_content/${createJobLineItemGroupRequestData.jobContentId}/job_line_item_group`,
      {
        method: 'POST',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `createJobLineItemGroup failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const updateJobLineItemGroup = async (
    updateJobLineItemGroupRequestData: UpdateJobLineItemGroupRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/job_line_item_groups/${updateJobLineItemGroupRequestData.jobLineItemGroupId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
        body: JSON.stringify(updateJobLineItemGroupRequestData),
      }
    ).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `updateJobLineItemGroup failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const deleteJobLineItemGroup = async (jobLineItemGroupId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/job_line_item_groups/${jobLineItemGroupId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `deleteJobLineItemGroup failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const addLineItemGroupToJobContent = async (
    jobContentId: number,
    lineItemGroupId: number
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/job_content/${jobContentId}/job_line_item_group/${lineItemGroupId}`,
      {
        method: 'POST',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `addLineItemGroupToJobContent failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJobLineItemsForJobContent = async (jobContentId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/job_content/${jobContentId}/job_line_items`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobLineItemsForJob failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJobLineItemsForInvoice = async (invoiceId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/invoices/${invoiceId}/job_line_items`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobLineItemsForInvoice failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJobLineItemsForDeal = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/deals/${dealId}/job_line_items`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobLineItemsForDeal failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJobLineItem = async (jobLineItemId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/job_line_items/${jobLineItemId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobLineItem failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJobLineItemGroupsForJobContent = async (jobContentId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/job_content/${jobContentId}/job_line_item_groups`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobLineItemGroupsForJobContent failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJobLineItemAttachmentsForJobLineItem = async (
    jobLineItemId: number
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/job_line_items/${jobLineItemId}/job_line_item_attachments`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobLineItemAttachmentsForJob failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJobContentsForDeal = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/job_content/deal/${dealId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobContentsForDeal failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const updateJobContent = async (
    updateJobContentRequestData: UpdateJobContentRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/job_content/${updateJobContentRequestData.jobContentId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
        body: JSON.stringify(updateJobContentRequestData),
      }
    ).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `updateJobContent failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJobContent = async (jobContentId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/job_content/${jobContentId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobContent failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJobContentWithJobGroupsAndJobLineItems = async (
    jobContentId: number
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/job_content/${jobContentId}/with_related_entities`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobContent failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const handleCreateJobLineItemFromAmendmentParentRequest =
    handleAsyncAction<CreateJobLineItemFromAmendmentParent>(
      createJobLineItemFromAmendmentParent,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/job_line_items/create_from_amendment_parent/`,
          {
            method: 'POST',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
              'X-Native-App': `${isNativeApp}`,
            },
            body: JSON.stringify({
              changeProposalId: payload.data.changeProposalId,
              quantity: payload.data.quantity,
              amendmentParentJobLineItemId:
                payload.data.amendmentParentJobLineItemId,
            }),
          }
        );
        if (response.ok) {
          return response.json();
        }
      },
      (response: any, payload) => {
        payload.payload?.callback?.(response?.jobLineItemId as number);
      }
    );

  function* handleAppLogout() {
    yield put(clearJobContents());
    yield put(clearJobLineItemAttachments());
    yield put(clearJobLineItemGroups());
    yield put(clearJobLineItems());
  }

  return function* () {
    yield takeEvery(ActionType.REQUEST_GET_JOB_CONTENT, handleGetJobContent);
    yield takeLatest(
      ActionType.REQUEST_GET_JOB_CONTENT_WITH_JOB_GROUPS_AND_JOB_LINE_ITEMS,
      handleGetJobContentWithJobGroupsAndJobLineItems
    );
    yield takeLatest(
      ActionType.REQUEST_UPDATE_JOB_CONTENT,
      handleUpdateJobContent
    );
    yield takeLatest(
      ActionType.REQUEST_GET_JOB_CONTENTS_FOR_DEAL,
      handleGetJobContentsForDeal
    );
    yield takeLatest(
      ActionType.REQUEST_GET_JOB_LINE_ITEM,
      handleGetJobLineItem
    );
    yield takeEvery(
      ActionType.REQUEST_GET_JOB_LINE_ITEM_ATTACHMENTS_FOR_JOB_LINE_ITEM,
      handleGetJobLineItemAttachmentsForJobLineItem
    );
    yield takeLatest(
      ActionType.REQUEST_GET_JOB_LINE_ITEM_GROUPS_FOR_JOB_CONTENT,
      handleGetJobLineItemGroupsForJobContent
    );
    yield takeLatest(
      ActionType.REQUEST_GET_JOB_LINE_ITEMS_FOR_JOB_CONTENT,
      handleGetJobLineItemsForJobContent
    );
    yield takeLatest(
      ActionType.REQUEST_GET_JOB_LINE_ITEMS_FOR_INVOICE,
      handleGetJobLineItemsForInvoice
    );
    yield takeLatest(
      ActionType.REQUEST_GET_JOB_LINE_ITEMS_FOR_DEAL,
      handleGetJobLineItemsForDeal
    );
    yield takeEvery('auth.logout', handleAppLogout);

    yield takeEvery(
      ActionType.REQUEST_CREATE_JOB_LINE_ITEM_GROUP,
      handleCreateJobLineItemGroup
    );
    yield takeEvery(
      ActionType.REQUEST_DELETE_JOB_LINE_ITEM_GROUP,
      handleDeleteJobLineItemGroup
    );
    yield takeLatest(
      ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_GROUP,
      handleUpdateJobLineItemGroup
    );
    yield takeLatest(
      ActionType.REQUEST_ADD_LINE_ITEM_GROUP_TO_JOB_CONTENT,
      handleAddLineItemGroupToJobContent
    );

    yield takeEvery(
      ActionType.REQUEST_CREATE_JOB_LINE_ITEM,
      handleCreateJobLineItem
    );
    yield takeEvery(
      ActionType.REQUEST_DELETE_JOB_LINE_ITEM,
      handleDeleteJobLineItem
    );
    yield takeLatest(
      ActionType.REQUEST_UPDATE_JOB_LINE_ITEM,
      handleUpdateJobLineItem
    );
    yield takeLatest(
      ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_FROM_LINE_ITEM,
      handleUpdateJobLineItemFromLineItem
    );

    yield takeEvery(
      ActionType.REQUEST_REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM,
      handleRemoveAttachmentFromJobLineItem
    );
    yield takeEvery(
      ActionType.REQUEST_ADD_ATTACHMENT_TO_JOB_LINE_ITEM,
      handleAddAttachmentToJobLineItem
    );

    yield takeEvery(
      ActionType.CREATE_JOB_LINE_ITEM_FROM_AMENDMENT_PARENT_REQUEST,
      handleCreateJobLineItemFromAmendmentParentRequest
    );
  };
};

export default jobContentSaga;
