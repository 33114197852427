export enum CurrencyCodes {
  AUD = 'AUD',
  CAD = 'CAD',
  NZD = 'NZD',
  ZAR = 'ZAR',
  GBP = 'GBP',
  USD = 'USD',
}

export type CurrencyValue = {
  value: number;
  currency: Currency;
};

export type Currency = {
  code: string;
  exponent: number;
};
