import {
  ListedMaterialListViewPage,
  ManagedBy,
  SortBy,
} from '@payaca/types/listedMaterialTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

export interface State {
  isGettingListedMaterialsPage: boolean;
  listedMaterialsPage: ListedMaterialListViewPage | null;
}

export enum ActionType {
  REQUEST_GET_LISTED_MATERIALS_PAGE = 'listedMaterials.requestGetListedMaterialsPage',
  GET_LISTED_MATERIALS_PAGE_SUCCESS = 'listedMaterials.getListedMaterialsPageSuccess',
  GET_LISTED_MATERIALS_PAGE_FAILURE = 'listedMaterials.getListedMaterialsPageFailure',
  CLEAR_LISTED_MATERIALS_PAGE = 'listedMaterials.clearListedMaterialsPage',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export interface GetListedMaterialsRequestData {
  pageSize: number;
  pageNumber: number;
  searchTerm?: string;
  sortDirection: SortDirection;
  sortBy: SortBy;
  categoryIds?: number[];
  supplierIds?: number[];
  managedBy?: ManagedBy[];
}
