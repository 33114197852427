import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import './Information.css';

const styles = () => ({
  bold: {
    fontFamily: 'Inter',
    fontWeight: 600,
  },
});

function Information(props) {
  const { label, value, hideLabel, boldValue, classes } = props;

  return (
    <div className="information-field">
      {!hideLabel && <label className="label">{label}</label>}
      <div className={boldValue ? classes.bold : ''}>{value}</div>
    </div>
  );
}

export default withStyles(styles)(Information);
