import React, { FC } from 'react';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import LinkButton from '@payaca/components/plButton/LinkButton';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import UntitledIcon from '@payaca/untitled-icons';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { IntegratedEmailsPermissions } from '@payaca/permissions/integrated-emails/integrated-emails.permissions';
import Button from '@payaca/components/plButton/Button';
import { useParams } from 'react-router-dom';

export interface IProps {
  className?: string;
  setIsSendEmailModalOpen: (isOpen: boolean) => void;
}

const NavbarActions: FC<IProps> = (props) => {
  const { className, setIsSendEmailModalOpen } = props;
  const { dealId } = useParams<{ dealId: string }>();

  return (
    <div className={'flex gap-x-2' + (className ? ' ' + className : '')}>
      <Tooltip className="inline-flex" tooltipContent="Create new Project Note">
        <LinkButton
          className="h-10"
          to={`/deals/${dealId}/activity/addNote`}
          variant={EBtnVariant.White}
          size={EBtnSize.Small}
          aria-label="Create new Project Note"
        >
          <UntitledIcon name="pencil-line.3" className="h-5 w-5" />
        </LinkButton>
      </Tooltip>
      <Tooltip
        className="inline-flex"
        tooltipContent="Create new Project Event"
      >
        <LinkButton
          to={`/deals/${dealId}/schedule/new`}
          variant={EBtnVariant.White}
          size={EBtnSize.Small}
          aria-label="Create new Project Event"
        >
          <UntitledIcon name="calendar-plus-01.3" className="h-5 w-5" />
        </LinkButton>
      </Tooltip>
      <PermissionGuard
        renderIfHasPermissions={[IntegratedEmailsPermissions.SEND_EMAIL]}
      >
        <Tooltip className="inline-flex" tooltipContent="Send email">
          <Button
            onClick={() => setIsSendEmailModalOpen(true)}
            variant={EBtnVariant.White}
            size={EBtnSize.Small}
            aria-label="Send email"
          >
            <UntitledIcon name="mail-02.3" className="h-5 w-5" />
          </Button>
        </Tooltip>
      </PermissionGuard>
    </div>
  );
};

export default NavbarActions;
