import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ListedItemGroupControl from '@/ui/components/listedItemGroupControl/ListedItemGroupControl';

import * as lineItemGroupActions from '@payaca/store/lineItemGroups/lineItemGroupsActions';

import './ListedItemGroupsControl.css';
import { useSelector } from '@/api/state';

type Props = {
  onClick: (lineItemGroupId: number) => void;
  showAddGroupButton?: boolean;
};
const ListedItemGroupsControl: FC<Props> = ({
  onClick,
  showAddGroupButton = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const createdLineItemGroupId = useSelector(
    (state) => state.lineItemGroups.createLineItemGroupSuccessId
  );
  const lineItemGroups = useSelector(
    (state) => state.lineItemGroups.lineItemGroups
  );

  const listedLineItemGroups = useMemo(
    () => Object.values(lineItemGroups),
    [lineItemGroups]
  );

  useEffect(() => {
    dispatch(lineItemGroupActions.requestGetLineItemGroups());
    return () => {
      dispatch(lineItemGroupActions.clearCreateLineItemGroup());
    };
  }, []);

  useEffect(() => {
    if (createdLineItemGroupId) {
      history.push(`/items/groups/${createdLineItemGroupId}`);
    }
  }, [createdLineItemGroupId]);

  return (
    <div
      className={`listed-item-groups-control${
        !listedLineItemGroups?.length && !showAddGroupButton
          ? ' no-groups-description'
          : ''
      }`}
    >
      {listedLineItemGroups.map((lineItemGroup: any, i: number) => {
        const lineItemGroupEntity = lineItemGroup.entity;
        return (
          <ListedItemGroupControl
            key={`item-group-${i}`}
            itemGroup={lineItemGroupEntity}
            onClick={() => onClick(lineItemGroupEntity)}
          />
        );
      })}
      {/* Add button */}
      {showAddGroupButton && (
        <ListedItemGroupControl
          onClick={() =>
            dispatch(lineItemGroupActions.requestCreateLineItemGroup())
          }
        />
      )}
      {!showAddGroupButton && !listedLineItemGroups?.length && (
        <div>
          You don&#39;t have any item groups, head over to your{' '}
          <a
            className="underline"
            href="/items/groups"
            target="_blank"
            rel="noopener noreferrer"
          >
            Item groups
          </a>{' '}
          page to add one.
        </div>
      )}
    </div>
  );
};

export default ListedItemGroupsControl;
