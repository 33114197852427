import React, { FunctionComponent } from 'react';
import './InputWrapper.sass';

export enum InputStyleVariant {
  STANDARD = 'standard',
  OUTSIZE = 'outsize',
  UNSTYLED = 'unstyled',
}

export enum InputColourVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WHITE = 'white',
  GREY = 'grey',
}

export enum InputWidth {
  XXSM = 'xxsm',
  XSM = 'xsm',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  FULL = 'full',
}

type Props = {
  children: JSX.Element | (JSX.Element | undefined)[];
  isDisabled?: boolean;
  styleVariant?: InputStyleVariant;
  inputWidth?: InputWidth;
  colourVariant?: InputColourVariant;
  hasBoxShadow?: boolean;
};

const InputWrapper: FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  isDisabled = false,
  styleVariant = InputStyleVariant.STANDARD,
  inputWidth = InputWidth.FULL,
  colourVariant = InputColourVariant.WHITE,
  hasBoxShadow = true,
}: React.PropsWithChildren<Props>): JSX.Element => {
  return (
    <div
      className={`input-wrapper ${
        isDisabled ? 'input-wrapper--disabled' : ''
      } input-wrapper--style-${styleVariant} input-wrapper--width-${inputWidth} input-wrapper--bg-${colourVariant} ${
        hasBoxShadow ? '' : 'input-no-shadow'
      }`}
    >
      {children}
    </div>
  );
};

export default InputWrapper;
