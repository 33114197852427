import React, { FC, FormEventHandler, useEffect, useState } from 'react';

import Input from '@payaca/components/plInput/Input';
import PLButton from '@payaca/components/plButton/Button';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import { ListedFile } from '@payaca/types/listedFileTypes';
import Field from '@payaca/components/plField/Field';
import * as documentActions from '@payaca/store/documents/documentActions';
import { useDispatch } from 'react-redux';
import { renameUpload } from '@payaca/store/uploads/uploadsActions';
import UntitledIcon from '@payaca/untitled-icons';
import Conditional from '@payaca/components/conditional/Conditional';
import { stripExtension } from '@payaca/shared-isomorphic/strings';

export interface IProps extends Omit<IModalProps, 'title'> {
  fileToRename?: ListedFile;
  onSuccess?: () => void;
}

const RenameFileModal: FC<IProps> = (props) => {
  const { fileToRename, onSuccess, onClose, ...modalProps } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(fileToRename?.name || '');
  const [replaceWithAIName, setReplaceWithAIName] = useState(false);

  useEffect(() => {
    setFileName(fileToRename?.name || '');
    setReplaceWithAIName(false);
  }, [fileToRename]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!fileToRename || !fileName || fileToRename.name === fileName) return;
    setIsLoading(true);

    if (fileToRename.entity === 'documents') {
      dispatch(
        documentActions.requestRenameDocument(
          fileToRename.entityId,
          fileName,
          () => {
            setIsLoading(false);
            onClose?.();
            onSuccess?.();
          }
        )
      );
    } else if (fileToRename.entity === 'uploads') {
      dispatch(
        renameUpload.request({
          payload: {
            uploadId: fileToRename.entityId,
            fileName,
          },
          callback: () => {
            setIsLoading(false);
            onClose?.();
            onSuccess?.();
          },
          onErrorCallback: () => {
            setIsLoading(false);
          },
        })
      );
    }
  };

  const shouldReplaceWithAIButtonAppear = !!(
    fileToRename &&
    fileToRename.fileNameGen &&
    stripExtension(fileName) !== fileToRename.fileNameGen
  );

  return (
    <Modal title="Rename File" onClose={onClose} {...modalProps}>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <Field className="relative">
            <Field.Label>File Name</Field.Label>

            <Input
              className={replaceWithAIName ? 'text-reveal' : ''}
              disabled={isLoading}
              value={fileName}
              onChange={setFileName}
              wrapperProps={{
                onAnimationEnd: () => {
                  setReplaceWithAIName(false);
                },
              }}
            />
          </Field>

          <Conditional condition={shouldReplaceWithAIButtonAppear}>
            <Button
              className="mt-2"
              variant={EBtnVariant.LinkInline}
              onClick={() => {
                setReplaceWithAIName(true);
                setFileName(fileToRename?.fileNameGen || '');
              }}
            >
              <UntitledIcon name="star-06.3" className="h-4 w-4" />
              Rename with AI
            </Button>
          </Conditional>
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Actions>
            <PLButton
              className="mr-2"
              disabled={isLoading}
              onClick={onClose}
              variant={EBtnVariant.Outline}
            >
              Cancel
            </PLButton>
            <PLButton
              disabled={isLoading}
              isProcessing={isLoading}
              type="submit"
            >
              Save
            </PLButton>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default RenameFileModal;
