import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './SignHerePrompt.sass';

const SignHerePrompt: FunctionComponent = (): JSX.Element => {
  return (
    <div className="sign-here-prompt">
      <span>Sign here</span>
      <span className="animated-icon-container">
        <FontAwesomeIcon icon={faArrowRight} />
      </span>
    </div>
  );
};

export default SignHerePrompt;
