import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  GetCustomersInput,
  GetTasksInput,
  PaginationInput,
} from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import taskKeys from './keyFactory';
import customerKeys from './keyFactory';

const GetCustomers = graphql(`
  query Customers($pagination: PaginationInput!, $input: GetCustomersInput) {
    customers(pagination: $pagination, input: $input) {
      items {
        id
        name
        billingAddress {
          fullLocalAddress
        }
        integrationSyncStatus {
          quickbooks
          xero
        }
        primaryContact {
          name
          phone
          email
        }
        tags {
          colour
          id
          name
        }
      }
      limit
      offset
      totalCount
    }
  }
`);

const useGetCustomers = (
  pagination?: PaginationInput,
  input?: GetCustomersInput
) => {
  const { data, ...rest } = useQuery({
    queryKey: customerKeys.customers(input, pagination),
    queryFn: () => {
      return gqlClient.request(GetCustomers, {
        input,
        pagination: pagination || PAGINATION_ARG_DEFAULTS,
      });
    },
  });
  return { customers: data?.customers, data, ...rest };
};

export default useGetCustomers;
