import { Reducer } from 'redux';

import { ActionType, State } from './validationTypes';

export const initialState: State = {
  isValidatingEmail: false,
  isValidatingPhone: false,

  isLookingUpAddressBySearchTerm: false,
  isRetrievingAddress: false,

  isGettingBlockedEmails: false,
  blockedEmails: [],
};

const validationReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_VALIDATE_EMAIL:
      return {
        ...state,
        isValidatingEmail: true,
      };
    case ActionType.VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        isValidatingEmail: false,
      };
    case ActionType.VALIDATE_EMAIL_FAILURE:
      return {
        ...state,
        isValidatingEmail: false,
      };

    case ActionType.REQUEST_VALIDATE_PHONE:
      return {
        ...state,
        isValidatingPhone: true,
      };
    case ActionType.VALIDATE_PHONE_SUCCESS:
      return {
        ...state,
        isValidatingPhone: false,
      };
    case ActionType.VALIDATE_PHONE_FAILURE:
      return {
        ...state,
        isValidatingPhone: false,
      };

    case ActionType.REQUEST_LOOKUP_ADDRESS_BY_SEARCH_TERM:
      return {
        ...state,
        isLookingUpAddressBySearchTerm: true,
      };
    case ActionType.LOOKUP_ADDRESS_BY_SEARCH_TERM_SUCCESS:
      return {
        ...state,
        isLookingUpAddressBySearchTerm: false,
      };
    case ActionType.LOOKUP_ADDRESS_BY_SEARCH_TERM_FAILURE:
      return {
        ...state,
        isLookingUpAddressBySearchTerm: false,
      };

    case ActionType.REQUEST_RETRIEVE_ADDRESS:
      return {
        ...state,
        isRetrievingAddress: true,
      };
    case ActionType.RETRIEVE_ADDRESS_SUCCESS:
      return {
        ...state,
        isRetrievingAddress: false,
      };
    case ActionType.RETRIEVE_ADDRESS_FAILURE:
      return {
        ...state,
        isRetrievingAddress: false,
      };

    case ActionType.REQUEST_GET_BLOCKED_EMAILS:
      return {
        ...state,
        isGettingBlockedEmails: true,
      };
    case ActionType.GET_BLOCKED_EMAILS_SUCCESS:
      return {
        ...state,
        isGettingBlockedEmails: false,
        blockedEmails: action.payload.blockedEmails,
      };
    case ActionType.GET_BLOCKED_EMAILS_FAILURE:
      return {
        ...state,
        isGettingBlockedEmails: false,
      };

    default:
      return state;
  }
};

export default validationReducer;
