import { FunctionComponent, useState, useEffect } from 'react';

import Modal from '@payaca/components/plModal/Modal';
import UpdateSubscriptionAdditionalUserSeatsControl from './UpdateSubscriptionAdditonalUserSeatsControl';
import UntitledIcon from '@payaca/untitled-icons';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUpdateSubscriptionSuccess?: (additionalUserSeats: number) => void;
};

const UpdateSubscriptionAdditionalUserSeatsModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  onUpdateSubscriptionSuccess,
}: Props): JSX.Element => {
  const [subscriptionIsUpdated, setSubscriptionIsUpdated] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setSubscriptionIsUpdated(false);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      onClose={onClose}
      title="Update subscription"
    >
      <Modal.Body>
        {!subscriptionIsUpdated ? (
          <UpdateSubscriptionAdditionalUserSeatsControl
            onUpdateSubscriptionSuccess={(additionalUserSeats) => {
              setSubscriptionIsUpdated(true);
              onUpdateSubscriptionSuccess?.(additionalUserSeats);
            }}
          />
        ) : (
          <div className="flex justify-center items-center">
            <UntitledIcon name="check" className="h-6 w-6 text-green-600" />
            <p>Success!</p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UpdateSubscriptionAdditionalUserSeatsModal;
