import { action } from 'typesafe-actions';

import { JobLineItemAttachment } from '@payaca/types/jobTypes';
import {
  JobContent,
  JobLineItemGroup,
  JobLineItem,
} from '@payaca/types/jobContentTypes';
import { UpdateJobContentRequestData } from '@payaca/types/jobContentRequestTypes';

import { createAsyncAction } from '../utils';

import {
  ActionType,
  CreateJobLineItemFromAmendmentParent,
  CreateJobLineItemGroupRequestData,
  CreateJobLineItemRequestData,
  UpdateJobLineItemGroupRequestData,
  UpdateJobLineItemRequestData,
} from './jobContentTypes';

export const requestGetJobContent = (jobContentId: number) =>
  action(ActionType.REQUEST_GET_JOB_CONTENT, {
    jobContentId,
  });

export const requestGetJobContentWithJobGroupsAndJobLineItems = (
  jobContentId: number,
  callback?: () => void
) =>
  action(
    ActionType.REQUEST_GET_JOB_CONTENT_WITH_JOB_GROUPS_AND_JOB_LINE_ITEMS,
    {
      jobContentId,
      callback,
    }
  );

export const getJobContentWithJobGroupsAndJobLineItemsSuccess = (
  response: any
) =>
  action(
    ActionType.GET_JOB_CONTENT_WITH_JOB_GROUPS_AND_JOB_LINE_ITEMS_SUCCESS,
    response
  );

export const getJobContentSuccess = (
  jobContentId: number,
  jobContent: JobContent
) =>
  action(ActionType.GET_JOB_CONTENT_SUCCESS, {
    jobContentId,
    jobContent,
  });

export const getJobContentFailure = (jobContentId: number, error: Error) =>
  action(
    ActionType.GET_JOB_CONTENT_FAILURE,
    {
      jobContentId,
    },
    null,
    error
  );

export const clearJobContent = (jobContentId: number) =>
  action(ActionType.CLEAR_JOB_CONTENT, {
    jobContentId,
  });

export const clearJobContents = () => action(ActionType.CLEAR_JOB_CONTENTS);

export const requestUpdateJobContent = (
  updateJobContentRequestData: UpdateJobContentRequestData,
  callback?: () => void
) =>
  action(ActionType.REQUEST_UPDATE_JOB_CONTENT, {
    updateJobContentRequestData,
    callback,
  });

export const updateJobContentSuccess = (jobContentId: number) =>
  action(ActionType.UPDATE_JOB_CONTENT_SUCCESS, {
    jobContentId,
  });

export const updateJobContentFailure = (jobContentId: number, error: Error) =>
  action(ActionType.UPDATE_JOB_CONTENT_FAILURE, { jobContentId }, null, error);

export const requestGetJobContentsForDeal = (dealId: number) =>
  action(ActionType.REQUEST_GET_JOB_CONTENTS_FOR_DEAL, {
    dealId,
  });

export const getJobContentsForDealSuccess = (jobContents: Array<JobContent>) =>
  action(ActionType.GET_JOB_CONTENTS_FOR_DEAL_SUCCESS, jobContents);

export const requestGetJobLineItemAttachmentsForJobLineItem = (
  jobLineItemId: number
) =>
  action(ActionType.REQUEST_GET_JOB_LINE_ITEM_ATTACHMENTS_FOR_JOB_LINE_ITEM, {
    jobLineItemId,
  });

export const getJobLineItemAttachmentsForJobLineItemSuccess = (
  jobLineItemAttachments: Array<JobLineItemAttachment>
) =>
  action(
    ActionType.GET_JOB_LINE_ITEM_ATTACHMENTS_FOR_JOB_LINE_ITEM_SUCCESS,
    jobLineItemAttachments
  );

export const getJobLineItemAttachmentSuccess = (
  jobLineItemAttachmentId: string,
  jobLineItemAttachment: JobLineItemAttachment
) =>
  action(ActionType.GET_JOB_LINE_ITEM_ATTACHMENT_SUCCESS, {
    jobLineItemAttachmentId,
    jobLineItemAttachment,
  });

export const getJobLineItemAttachmentFailure = (
  jobLineItemAttachmentId: string,
  error: Error
) =>
  action(
    ActionType.GET_JOB_LINE_ITEM_ATTACHMENT_FAILURE,
    {
      jobLineItemAttachmentId,
    },
    null,
    error
  );

export const clearJobLineItemAttachment = (jobLineItemAttachmentId: string) =>
  action(ActionType.CLEAR_JOB_LINE_ITEM_ATTACHMENT, {
    jobLineItemAttachmentId,
  });

export const clearJobLineItemAttachments = () =>
  action(ActionType.CLEAR_JOB_LINE_ITEM_ATTACHMENTS);

export const requestAddAttachmentToJobLineItem = (
  jobLineItemId: number,
  attachment: { file: File; fileName: string },
  updateLineItemAttachment = false,
  callback?: (error?: Error) => void
) =>
  action(ActionType.REQUEST_ADD_ATTACHMENT_TO_JOB_LINE_ITEM, {
    jobLineItemId,
    attachment,
    updateLineItemAttachment,
    callback,
  });

export const addAttachmentToJobLineItemSuccess = () =>
  action(ActionType.ADD_ATTACHMENT_TO_JOB_LINE_ITEM_SUCCESS);

export const addAttachmentToJobLineItemFailure = (error: Error) =>
  action(ActionType.ADD_ATTACHMENT_TO_JOB_LINE_ITEM_FAILURE, null, null, error);

export const requestRemoveAttachmentFromJobLineItem = (
  jobLineItemId: number,
  attachmentId: string,
  updateLineItemAttachment = false,
  callback?: (error?: Error) => void
) =>
  action(ActionType.REQUEST_REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM, {
    jobLineItemId,
    attachmentId,
    updateLineItemAttachment,
    callback,
  });

export const removeAttachmentFromJobLineItemSuccess = () =>
  action(ActionType.REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM_SUCCESS);

export const removeAttachmentFromJobLineItemFailure = (error: Error) =>
  action(
    ActionType.REMOVE_ATTACHMENT_FROM_JOB_LINE_ITEM_FAILURE,
    null,
    null,
    error
  );

export const requestGetJobLineItemGroupsForJobContent = (
  jobContentId: number
) =>
  action(ActionType.REQUEST_GET_JOB_LINE_ITEM_GROUPS_FOR_JOB_CONTENT, {
    jobContentId,
  });

export const getJobLineItemGroupsForJobContentSuccess = (
  jobLineItemGroups: Array<JobLineItemGroup>
) =>
  action(
    ActionType.GET_JOB_LINE_ITEM_GROUPS_FOR_JOB_CONTENT_SUCCESS,
    jobLineItemGroups
  );

export const requestGetJobLineItem = (
  jobLineItemId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_GET_JOB_LINE_ITEM, {
    jobLineItemId,
    callback,
  });

export const getJobLineItemGroupSuccess = (
  jobLineItemGroupId: string,
  jobLineItemGroup: JobLineItemGroup
) =>
  action(ActionType.GET_JOB_LINE_ITEM_GROUP_SUCCESS, {
    jobLineItemGroupId,
    jobLineItemGroup,
  });

export const getJobLineItemGroupFailure = (
  jobLineItemGroupId: string,
  error: Error
) =>
  action(
    ActionType.GET_JOB_LINE_ITEM_GROUP_FAILURE,
    {
      jobLineItemGroupId,
    },
    null,
    error
  );

export const clearJobLineItemGroup = (jobLineItemGroupId: string) =>
  action(ActionType.CLEAR_JOB_LINE_ITEM_GROUP, {
    jobLineItemGroupId,
  });

export const clearJobLineItemGroups = () =>
  action(ActionType.CLEAR_JOB_LINE_ITEM_GROUPS);

export const requestGetJobLineItemsForJobContent = (jobContentId: number) =>
  action(ActionType.REQUEST_GET_JOB_LINE_ITEMS_FOR_JOB_CONTENT, {
    jobContentId,
  });

export const getJobLineItemsForJobContentSuccess = (
  jobLineItems: Array<JobLineItem>
) =>
  action(ActionType.GET_JOB_LINE_ITEMS_FOR_JOB_CONTENT_SUCCESS, jobLineItems);

export const requestGetJobLineItemsForInvoice = (invoiceId: number) =>
  action(ActionType.REQUEST_GET_JOB_LINE_ITEMS_FOR_INVOICE, {
    invoiceId,
  });

export const getJobLineItemsForInvoiceSuccess = (
  jobLineItems: Array<JobLineItem>
) => action(ActionType.GET_JOB_LINE_ITEMS_FOR_INVOICE_SUCCESS, jobLineItems);

export const requestGetJobLineItemsForDeal = (dealId: number) =>
  action(ActionType.REQUEST_GET_JOB_LINE_ITEMS_FOR_DEAL, {
    dealId,
  });

export const getJobLineItemsForDealSuccess = (
  jobLineItems: Array<JobLineItem>
) => action(ActionType.GET_JOB_LINE_ITEMS_FOR_DEAL_SUCCESS, jobLineItems);

export const getJobLineItemSuccess = (
  jobLineItemId: number,
  jobLineItem: JobLineItem
) =>
  action(ActionType.GET_JOB_LINE_ITEM_SUCCESS, {
    jobLineItemId,
    jobLineItem,
  });

export const getJobLineItemFailure = (jobLineItemId: number, error: Error) =>
  action(
    ActionType.GET_JOB_LINE_ITEM_FAILURE,
    {
      jobLineItemId,
    },
    null,
    error
  );

export const clearJobLineItem = (jobLineItemId: number) =>
  action(ActionType.CLEAR_JOB_LINE_ITEM, {
    jobLineItemId,
  });

export const clearJobLineItems = () => action(ActionType.CLEAR_JOB_LINE_ITEMS);

export const requestCreateJobLineItemGroup = (
  createJobLineItemGroupRequestData: CreateJobLineItemGroupRequestData,
  callback?: () => void
) =>
  action(ActionType.REQUEST_CREATE_JOB_LINE_ITEM_GROUP, {
    createJobLineItemGroupRequestData,
    callback,
  });

export const createJobLineItemGroupSuccess = () =>
  action(ActionType.CREATE_JOB_LINE_ITEM_GROUP_SUCCESS);

export const createJobLineItemGroupFailure = (error: Error) =>
  action(ActionType.CREATE_JOB_LINE_ITEM_GROUP_FAILURE, null, null, error);

export const requestUpdateJobLineItemGroup = (
  updateJobLineItemGroupRequestData: UpdateJobLineItemGroupRequestData,
  callback?: () => void
) =>
  action(ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_GROUP, {
    updateJobLineItemGroupRequestData,
    callback,
  });

export const updateJobLineItemGroupSuccess = (jobLineItemGroupId: number) =>
  action(ActionType.UPDATE_JOB_LINE_ITEM_GROUP_SUCCESS, {
    jobLineItemGroupId,
  });

export const updateJobLineItemGroupFailure = (
  jobLineItemGroupId: number,
  error: Error
) =>
  action(
    ActionType.UPDATE_JOB_LINE_ITEM_GROUP_FAILURE,
    { jobLineItemGroupId },
    null,
    error
  );

export const requestDeleteJobLineItemGroup = (
  jobLineItemGroupId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_DELETE_JOB_LINE_ITEM_GROUP, {
    jobLineItemGroupId,
    callback,
  });

export const deleteJobLineItemGroupSuccess = () =>
  action(ActionType.DELETE_JOB_LINE_ITEM_GROUP_SUCCESS);

export const deleteJobLineItemGroupFailure = (error: Error) =>
  action(ActionType.DELETE_JOB_LINE_ITEM_GROUP_FAILURE, null, null, error);

export const requestAddLineItemGroupToJobContent = (
  jobContentId: number,
  lineItemGroupId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_ADD_LINE_ITEM_GROUP_TO_JOB_CONTENT, {
    jobContentId,
    lineItemGroupId,
    callback,
  });

export const addLineItemGroupToJobContentSuccess = () =>
  action(ActionType.ADD_LINE_ITEM_GROUP_TO_JOB_CONTENT_SUCCESS);

export const addLineItemGroupToJobContentFailure = (error: Error) =>
  action(
    ActionType.ADD_LINE_ITEM_GROUP_TO_JOB_CONTENT_FAILURE,
    null,
    null,
    error
  );

export const requestCreateJobLineItem = (
  createJobLineItemRequestData: CreateJobLineItemRequestData,
  callback?: (jobLineItemId: number) => void
) =>
  action(ActionType.REQUEST_CREATE_JOB_LINE_ITEM, {
    createJobLineItemRequestData,
    callback,
  });

export const createJobLineItemSuccess = () =>
  action(ActionType.CREATE_JOB_LINE_ITEM_SUCCESS);

export const createJobLineItemFailure = (error: Error) =>
  action(ActionType.CREATE_JOB_LINE_ITEM_FAILURE, null, null, error);

export const requestUpdateJobLineItem = (
  updateJobLineItemRequestData: UpdateJobLineItemRequestData,
  callback?: () => void
) =>
  action(ActionType.REQUEST_UPDATE_JOB_LINE_ITEM, {
    updateJobLineItemRequestData,
    callback,
  });

export const requestUpdateJobLineItemFromLineItem = (
  jobLineItemId: number,
  lineItemId: number,
  callback?: (error?: Error) => void
) =>
  action(ActionType.REQUEST_UPDATE_JOB_LINE_ITEM_FROM_LINE_ITEM, {
    jobLineItemId,
    lineItemId,
    callback,
  });

export const updateJobLineItemSuccess = (jobLineItemId: number) =>
  action(ActionType.UPDATE_JOB_LINE_ITEM_SUCCESS, { jobLineItemId });

export const updateJobLineItemFailure = (jobLineItemId: number, error: Error) =>
  action(
    ActionType.UPDATE_JOB_LINE_ITEM_FAILURE,
    { jobLineItemId },
    null,
    error
  );

export const requestDeleteJobLineItem = (
  jobLineItemId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_DELETE_JOB_LINE_ITEM, {
    jobLineItemId,
    callback,
  });

export const deleteJobLineItemSuccess = () =>
  action(ActionType.DELETE_JOB_LINE_ITEM_SUCCESS);

export const deleteJobLineItemFailure = (error: Error) =>
  action(ActionType.DELETE_JOB_LINE_ITEM_FAILURE, null, null, error);

export const clearJobLineItemGroupUpdateResults = () =>
  action(ActionType.CLEAR_JOB_LINE_ITEM_GROUP_UPDATE_RESULTS);

export const clearJobLineItemUpdateResults = () =>
  action(ActionType.CLEAR_JOB_LINE_ITEM_UPDATE_RESULTS);

export const clearJobContentUpdateResults = () =>
  action(ActionType.CLEAR_JOB_CONTENT_UPDATE_RESULTS);

export const createJobLineItemFromAmendmentParent =
  createAsyncAction<CreateJobLineItemFromAmendmentParent>({
    request: ActionType.CREATE_JOB_LINE_ITEM_FROM_AMENDMENT_PARENT_REQUEST,
    failure: ActionType.CREATE_JOB_LINE_ITEM_FAILURE,
    success: ActionType.CREATE_JOB_LINE_ITEM_SUCCESS,
  })((payload: CreateJobLineItemFromAmendmentParent['request']['payload']) => ({
    payload,
  }));
