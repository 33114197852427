import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as materialActions from '@payaca/store/materials/materialsActions';
import { formatPhoneNumber } from '@payaca/helpers/phoneNumberHelper';

import './SupplierMaterialControl.sass';
import { getRegion, getUserRoles } from '@/utils/stateAccessors';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import CreateEditSupplierMaterialModal from '../createEditSupplierMaterialModal/CreateEditSupplierMaterialModal';
import {
  BaseMaterial,
  MaterialRelationships,
  SupplierMaterial,
} from '@payaca/types/materialTypes';
import { BaseSupplier } from '@payaca/types/supplierTypes';
import ImageBlock from '@payaca/components/imageBlock/ImageBlock';
import StarControl from '@payaca/components/starControl/StarControl';
import { Tooltip } from '@material-ui/core';
import EntityCard from '@payaca/components/entityCard/EntityCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from '@/api/state';
import { useTaxRate } from '@payaca/store/hooks/appState';

export enum SupplierMaterialControlContext {
  SUPPLIER = 'supplier',
  MATERIAL = 'material',
}

interface Props {
  supplier: BaseSupplier;
  material: BaseMaterial &
    Pick<MaterialRelationships, 'thumbnailUrl' | 'category'>;
  supplierMaterial: SupplierMaterial;
  context: SupplierMaterialControlContext;
  onPersistSupplierMaterialSuccess?: () => void;
  onDeleteSupplierMaterialSuccess?: () => void;
}

const SupplierMaterialControl: FC<Props> = ({
  supplier,
  material,
  supplierMaterial,
  context,
  onPersistSupplierMaterialSuccess,
  onDeleteSupplierMaterialSuccess,
}: Props): JSX.Element | null => {
  const history = useHistory();
  const [
    isSettingPreferredSupplierForMaterial,
    setIsSettingPreferredSupplierForMaterial,
  ] = useState(false);
  const dispatch = useDispatch();
  const region = useSelector(getRegion);

  const userRoles = useSelector(getUserRoles);
  const [showEditSupplierMaterialModal, setShowEditSupplierMaterialModal] =
    useState(false);

  const isDeletingSupplierMaterial = useSelector(
    (state) => state.materials.isDeletingSupplierMaterial
  );

  const taxRate = useTaxRate(supplierMaterial.taxRateId);

  const deleteSupplierMaterial = useCallback(() => {
    dispatch(
      materialActions.requestDeleteSupplierMaterial(
        supplierMaterial.id,
        onDeleteSupplierMaterialSuccess
      )
    );
  }, [onDeleteSupplierMaterialSuccess, supplierMaterial.id]);

  const quickActions = useMemo(() => {
    const quickActions: any[] = [];

    if (
      userHasRequiredPermission(userRoles, [
        MaterialsPermissions.PERSIST_SUPPLIER_MATERIAL,
      ]) &&
      !supplierMaterial.isSystemManaged
    ) {
      quickActions.push({
        actionName: 'Edit',
        actionBehaviour: () => setShowEditSupplierMaterialModal(true),
      });
    }

    if (
      userHasRequiredPermission(userRoles, [
        MaterialsPermissions.DELETE_SUPPLIER_MATERIAL,
      ]) &&
      !supplierMaterial.isSystemManaged
    ) {
      quickActions.push({
        actionName: 'Delete',
        actionBehaviour: deleteSupplierMaterial,
        isProcessing: isDeletingSupplierMaterial,
      });
    }

    return quickActions;
  }, [
    userRoles,
    isDeletingSupplierMaterial,
    deleteSupplierMaterial,
    supplierMaterial.isSystemManaged,
  ]);

  const url = useMemo(() => {
    if (supplierMaterial.url?.length) {
      return supplierMaterial.url;
    }

    return supplier.websiteUrl;
  }, [supplierMaterial.url, supplier.websiteUrl]);

  const baseUrl = useMemo(() => {
    if (!url?.length) return;
    try {
      const u = new URL(url);
      return `${u.hostname}`;
    } catch (error) {
      return url;
    }
  }, [url]);

  const navigateToUrl = useMemo(() => {
    if (!url?.length) return;
    try {
      const u = new URL(url);
      return url;
    } catch (error) {
      return `//${url}`;
    }
  }, [url]);

  const handleSetPreferredSupplierForMaterial = useCallback(
    (preferredSupplierId: number | null) => {
      setIsSettingPreferredSupplierForMaterial(true);
      dispatch(
        materialActions.requestPersistMaterial(
          {
            id: material.id,
            preferredSupplierId: preferredSupplierId,
          },
          () => {
            onPersistSupplierMaterialSuccess &&
              onPersistSupplierMaterialSuccess();
            setIsSettingPreferredSupplierForMaterial(false);
          }
        )
      );
    },
    [material.id, dispatch, onPersistSupplierMaterialSuccess]
  );

  const navigateToPage = useCallback(
    (id: number) => {
      if (context === SupplierMaterialControlContext.MATERIAL) {
        history.push(`/suppliers/${id}`);
      } else {
        history.push(`/materials/${id}`);
      }
    },
    [history]
  );

  const onClick = useCallback(
    (id: number) => {
      navigateToPage(id);
    },
    [navigateToPage]
  );

  if (!supplierMaterial) return null;

  return (
    <>
      <EntityCard
        className="supplier-material-control"
        onClick={() =>
          onClick(
            context === SupplierMaterialControlContext.MATERIAL
              ? supplier.id
              : material.id
          )
        }
        quickActionsConfig={{
          recordId: supplierMaterial.id,
          quickActions: quickActions,
          renderDisabledIfNoActions: false,
        }}
      >
        <ResponsiveViewWrapper
          className="supplier-material-control-content"
          downBreakpointSm={400}
        >
          <div className="supplier-material-control-panel flex-grow">
            <div className="supplier-material-control-panel-inner">
              {context === SupplierMaterialControlContext.MATERIAL && (
                <div className="supplier-information-container">
                  <Tooltip
                    title={
                      material.preferredSupplierId === supplier.id ? (
                        <small>This is your preferred supplier</small>
                      ) : (
                        <small>Make this your preferred supplier</small>
                      )
                    }
                    arrow
                    placement="bottom"
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <StarControl
                        isProcessing={isSettingPreferredSupplierForMaterial}
                        isActive={material.preferredSupplierId === supplier.id}
                        isDisabled={
                          !userHasRequiredPermission(userRoles, [
                            MaterialsPermissions.PERSIST_MATERIAL,
                          ])
                        }
                        onChange={(isActive: boolean) =>
                          handleSetPreferredSupplierForMaterial(
                            isActive ? supplier.id : null
                          )
                        }
                      />
                    </div>
                  </Tooltip>

                  <div className="name-contact-container">
                    <h3 className="supplier-name">{supplier?.name}</h3>
                    {(!!supplier?.emailAddress?.length ||
                      !!supplier?.phoneNumber?.length) && (
                      <div className="supplier-contact-information">
                        {!!supplier?.emailAddress?.length && (
                          <span>{supplier.emailAddress}</span>
                        )}
                        {!!supplier?.phoneNumber?.length && (
                          <span className="font-size-sm">
                            {formatPhoneNumber(supplier.phoneNumber)}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {context === SupplierMaterialControlContext.SUPPLIER && (
                <div className="material-information-container">
                  <ImageBlock imageSrc={material.thumbnailUrl} />
                  <h3 className="font-size-lg">{material?.name}</h3>
                </div>
              )}
              <div className="cost-reference-url-container">
                <div className="cost-reference-url-container-inner">
                  <div className="reference-url-container">
                    {!!supplierMaterial.reference?.length && (
                      <div>
                        <span className={'font-size-sm'}>
                          <LabelValuePair
                            label="Ref"
                            value={supplierMaterial.reference}
                          />
                        </span>
                      </div>
                    )}
                    {!!url?.length && (
                      <div>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className={'font-size-sm'}
                        >
                          <a
                            href={navigateToUrl}
                            target={'_blank'}
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                          </a>
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="tax-cost-container flex-container flex-center">
                    <div className="tax-container">
                      <span>{taxRate?.name ?? '-'}</span>
                    </div>
                    <div className="cost-container">
                      <div>
                        <span className={'font-size-lg'}>
                          {currencyPrice(supplierMaterial.price, region)}
                        </span>
                      </div>
                      {supplierMaterial.isTaxIncluded && (
                        <div>
                          <small className="font-size-sm">
                            inc tax{' '}
                            {currencyPrice(
                              supplierMaterial.priceIncludingTax,
                              region
                            )}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ResponsiveViewWrapper>
      </EntityCard>
      <CreateEditSupplierMaterialModal
        isOpen={showEditSupplierMaterialModal}
        onClose={() => setShowEditSupplierMaterialModal(false)}
        supplierMaterial={supplierMaterial}
        isMaterialSelectionDisabled={true}
        onPersistSupplierMaterialSuccess={() => {
          setShowEditSupplierMaterialModal(false);
          dispatch(
            materialActions.requestGetSupplierMaterial(supplierMaterial.id)
          );
          onPersistSupplierMaterialSuccess &&
            onPersistSupplierMaterialSuccess();
        }}
      />
    </>
  );
};

export default SupplierMaterialControl;
