import { Tag as TagType } from '@payaca/types/tagTypes';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import TypeToSearchField from '@payaca/components/typeToSearchField/TypeToSearchField';
import './CustomerFilterControl.sass';
import BasicField from '@payaca/components/basicField/BasicField';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCustomer, useSelector } from '@payaca/store/hooks/appState';
import { ListedCustomer } from '@payaca/types/listedCustomerTypes';
import { useDispatch } from 'react-redux';
import {
  requestGetCustomer,
  requestGetListedCustomersPage,
} from '@payaca/store/customer/customerActions';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';

type Props = {
  selectedCustomerId?: number;
  onChange: (selectedCustomerId?: number) => void;
  styleVariant?: InputStyleVariant;
  displayValue?: string;
};
const CustomerFilterControl: FC<Props> = ({
  selectedCustomerId,
  onChange,
  styleVariant,
  displayValue,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [renderField, setRenderField] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [requiresGetListedCustomersPage, setRequiresGetListedCustomersPage] =
    useState(false);

  const customer = useCustomer(selectedCustomerId);

  const isGettingListedCustomersPage = useSelector(
    (state) => state.customer.isGettingListedCustomersPage
  );

  const listedCustomers: ListedCustomer[] = useSelector((state) => {
    return state.customer?.listedCustomersPage?.items || [];
  });

  const requestGetListedCustomers = useCallback(() => {
    dispatch(
      requestGetListedCustomersPage({
        pageSize: 20,
        pageNumber: 1,
        searchTerm: searchTerm,
      })
    );
    setRequiresGetListedCustomersPage(false);
  }, [searchTerm, dispatch]);

  useEffect(() => {
    if (requiresGetListedCustomersPage) {
      requestGetListedCustomers();
    }
  }, [requiresGetListedCustomersPage, requestGetListedCustomers]);

  useEffect(() => {
    if (selectedCustomerId) {
      dispatch(requestGetCustomer(selectedCustomerId));
    }
  }, [selectedCustomerId]);

  useEffect(() => {
    if (renderField) {
      setRequiresGetListedCustomersPage(true);
    }
  }, [renderField]);

  return (
    <div className="customer-filter-control">
      <FieldLabel label="Customer" />
      {!renderField && (
        <BasicField
          styleVariant={styleVariant}
          name=""
          value={displayValue || customer?.name}
          additionalInputProps={{
            onFocus: () => setRenderField(true),
          }}
          placeholder="Search Customers"
          iconAfter={
            selectedCustomerId ? (
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => {
                  onChange();
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={() => setRenderField(true)}
              />
            )
          }
        />
      )}
      {renderField && (
        <TypeToSearchField
          styleVariant={styleVariant}
          autofocus={true}
          onBlur={() => setRenderField(false)}
          placeholder="Search Customers"
          options={listedCustomers}
          disableFilteringInComponent={true}
          disableCloseOnSelect={false}
          openOnFocus={true}
          onSearchTermChangeTimeout={() =>
            setRequiresGetListedCustomersPage(true)
          }
          isLoadingOptions={isGettingListedCustomersPage}
          onSearchTermChange={setSearchTerm}
          onSelectOption={(customer) => {
            onChange(customer?.customerId);
          }}
          renderOption={(customer) => {
            return (
              <div>
                <span>{customer.customerName}</span>
              </div>
            );
          }}
          getOptionLabel={(customer) => customer.customerName}
          blockInputChangeReasons={['reset']}
          multiple={false}
        />
      )}
    </div>
  );
};

export default CustomerFilterControl;
