import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { UsersInput } from '@/gql/graphql';
import projectKeys from '@/api/queries/project/keyFactory';

const GetProject = graphql(`
  query Project($projectId: Int!) {
    # Todo: Move this to a separate query
    me {
      user {
        account {
          features
          pipelines {
            id
            name
            colour
            stages {
              id
              index
              title
            }
          }
        }
      }
    }
    project(id: $projectId) {
      reference
      archivedAt
      description
      version
      pipeline {
        id
        name
        colour
        stages {
          id
          index
          title
        }
      }
      currentPipelineStage {
        id
        title
      }
      owner {
        id
        fullName
      }
      tags {
        id
        name
        colour
      }
      totals {
        projectValue {
          value
          currency {
            code
            exponent
          }
        }
        acceptedValue {
          value
          currency {
            code
            exponent
          }
        }
        sentInvoicedValue {
          value
          currency {
            code
            exponent
          }
        }
        completedPaymentValue {
          value
          currency {
            code
            exponent
          }
        }
      }
      addresses {
        site {
          id
          line1
          line2
          city
          country
          postalCode
          fullLocalAddress
          contacts {
            id
            name
            description
            email
            phone
          }
        }
      }
      customer {
        id
        name
        addresses {
          id
          line1
          line2
          city
          country
          postalCode
          fullLocalAddress
          contacts {
            id
            name
            description
            email
            phone
          }
        }
        contacts {
          id
          name
          description
          email
          phone
          isPrimary
        }
      }
      connectionApplications {
        id
        status
        data
        createdAt
        submittedAt
        connectionApplicationStatus
        createdBy {
          fullName
        }
        submittedBy {
          fullName
        }
      }
    }
  }
`);

const useGetProjectOverview = (projectId?: number, refetch = false) => {
  return useQuery({
    enabled: !!projectId,
    queryKey: projectKeys.overview(projectId!),
    refetchInterval: refetch && 10_000,
    queryFn: () => {
      return gqlClient.request(GetProject, {
        projectId: projectId!,
      });
    },
  });
};

export default useGetProjectOverview;
