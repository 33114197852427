import React, { FC, useMemo } from 'react';

import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { DocumentSenderContext } from '@payaca/types/accountBrandTypes';
import {
  HydratedMaterialPurchaseIntent,
  HydratedMaterialPurchaseRecord,
  PurchaseOrder,
} from '@payaca/types/materialsListTypes';
import { Material, SupplierMaterial } from '@payaca/types/materialTypes';
import { Supplier } from '@payaca/types/supplierTypes';
import moment from 'moment-timezone';
import GenericDocument from '../genericDocument/GenericDocument';
import PurchaseOrderBody, { PurchaseOrderLine } from './PurchaseOrderBody';
import './PurchaseOrderDocument.sass';
import { getBrandingColourContext } from '@payaca/helpers/brandingColourContextHelper';
import FieldLabel from '../fieldLabel/FieldLabel';
import MarkdownLabel from '../markdownLabel/MarkdownLabel';
import { PartialAddress } from '@payaca/types/locationTypes';
import { getAddressAsString } from '@payaca/helpers/locationHelper';

type Props = {
  project?: {
    reference?: string;
  };
  documentSenderContext: DocumentSenderContext;
  supplier?: Pick<Supplier, 'name' | 'addresses'>;
  purchaseOrder: Pick<
    PurchaseOrder,
    | 'reference'
    | 'sentAt'
    | 'additionalNotes'
    | 'dueAt'
    | 'showMaterialPrices'
    | 'confirmedAt'
  >;
  materialPurchaseIntents: (Pick<
    HydratedMaterialPurchaseIntent,
    | 'materialQuantity'
    | 'supplierMaterialReference'
    | 'predictedUnitPriceExcludingTax'
    | 'predictedTotalPriceExcludingTax'
    | 'materialsListMaterialId'
  > & {
    material: Pick<Material, 'name' | 'thumbnailUrl'>;
  })[];
  materialPurchaseRecords: Pick<
    HydratedMaterialPurchaseRecord,
    | 'materialsListMaterialId'
    | 'materialQuantity'
    | 'price'
    | 'totalPriceExcludingTax'
  >[];
  deliveryAddress?: PartialAddress;
};
const PurchaseOrderDocument: FC<Props> = ({
  documentSenderContext,
  purchaseOrder,
  supplier,
  materialPurchaseIntents,
  materialPurchaseRecords,
  deliveryAddress,
  project,
}: Props): JSX.Element | null => {
  const purchaseOrderLines = materialPurchaseIntents.map<PurchaseOrderLine>(
    (mpi) => {
      let materialPurchaseRecord;
      if (purchaseOrder.confirmedAt) {
        materialPurchaseRecord = materialPurchaseRecords.find(
          (mpr) => mpr.materialsListMaterialId === mpi.materialsListMaterialId
        );
      }

      return {
        materialName: mpi?.material?.name,
        thumbnailUrl: mpi?.material?.thumbnailUrl,
        predictedMaterialQuantity: mpi?.materialQuantity,
        supplierReference: mpi?.supplierMaterialReference,
        predictedUnitPrice: mpi?.predictedUnitPriceExcludingTax,
        predictedTotalPrice: mpi?.predictedTotalPriceExcludingTax,
        materialQuantity: materialPurchaseRecord?.materialQuantity,
        unitPrice: materialPurchaseRecord?.price || undefined,
        totalPrice: materialPurchaseRecord?.totalPriceExcludingTax,
      };
    }
  );

  const keyInformation = useMemo(() => {
    const ki = [{ label: 'PO ref', value: purchaseOrder.reference.toString() }];

    if (project?.reference) {
      ki.push({ label: 'Project ref', value: project.reference });
    }

    if (purchaseOrder.sentAt) {
      ki.push({
        label: 'Issued',
        value: moment(purchaseOrder.sentAt).format(
          getInternationalMomentDateFormatByRegion(
            DateFormats.SHORT,
            documentSenderContext.region
          )
        ),
      });
    }

    if (purchaseOrder.dueAt) {
      ki.push({
        label: 'Due by',
        value: moment(purchaseOrder.dueAt).format(
          getInternationalMomentDateFormatByRegion(
            DateFormats.SHORT,
            documentSenderContext.region
          )
        ),
      });
    }

    return ki;
  }, [purchaseOrder]);

  const brandingColourContext = getBrandingColourContext(
    documentSenderContext.brandColour
  );

  const deliveryAddressString = useMemo(() => {
    if (!deliveryAddress) return;

    const string = getAddressAsString(deliveryAddress);

    return string?.length ? string : null;
  }, [deliveryAddress]);

  if (!purchaseOrder) return null;

  return (
    <GenericDocument
      documentSenderContext={documentSenderContext}
      className="purchase-order-document"
      title="Purchase order"
      keyInformation={keyInformation}
      documentRecipient={
        supplier
          ? {
              name: supplier.name,
              address: supplier.addresses?.length
                ? supplier.addresses[0].address
                : undefined,
            }
          : undefined
      }
    >
      {deliveryAddressString && (
        <div>
          <h2 className="mb-2">Deliver to</h2>{' '}
          <span>{deliveryAddressString}</span>
        </div>
      )}
      <PurchaseOrderBody
        region={documentSenderContext.region}
        purchaseOrderLines={purchaseOrderLines}
        brandingColourContext={brandingColourContext}
        showMaterialPrices={purchaseOrder.showMaterialPrices}
      />
      {purchaseOrder.additionalNotes && (
        <div className="additional-notes">
          <FieldLabel label="Notes" />
          <MarkdownLabel markdown={purchaseOrder.additionalNotes} />
        </div>
      )}
    </GenericDocument>
  );
};

export default PurchaseOrderDocument;
