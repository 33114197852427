import { useQuery } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import meKeys from '@/api/queries/me/keyFactory';

const GetMyTaxRates = graphql(`
  query GetMyTaxRates {
    me {
      user {
        account {
          taxRates {
            id
            label
            percentage
            isDefault
            isReverseCharge
          }
        }
      }
    }
  }
`);

const useGetMyTaxRates = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountTaxRates(),
    queryFn: () => {
      return gqlClient.request(GetMyTaxRates);
    },
  });

  return {
    taxRates: data?.me.user.account.taxRates,
    defaultTaxRate: data?.me.user.account.taxRates.find(
      (taxRate) => taxRate.isDefault
    ),
    ...rest,
  };
};

export default useGetMyTaxRates;
