import React, { FunctionComponent, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import './TrialPeriodExpiryBanner.sass';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { getUserRoles } from '@/utils/stateAccessors';
import { useSelector } from '@/api/state';
import { AnnouncementBanner } from '@payaca/components/announcementBanner/AnnouncementBanner';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { Link } from 'react-router-dom';

const TrialPeriodExpiryBanner: FunctionComponent = (): JSX.Element | null => {
  const history = useHistory();
  const location = useLocation();
  const userRoles = useSelector(getUserRoles);

  const currentUserFirstName: string = useSelector((state: any) => {
    return state.users.myProfile?.firstname;
  });

  const currentUserIsAdmin: boolean = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      AccountsPermissions.UPDATE_SUBSCRIPTION,
    ]);
  }, [userRoles]);

  const isOnManageSubscriptionPage = useMemo(() => {
    return location.pathname == '/upgradeAccount';
  }, [location]);

  return (
    <AnnouncementBanner>
      <div className="flex flex-row items-baseline gap-4">
        <span className="mr-auto text-white">
          Hi {currentUserFirstName}, your trial period has expired.
          <PermissionGuard
            renderIfHasPermissions={[AccountsPermissions.UPDATE_SUBSCRIPTION]}
          >
            <> Contact your account administrator to subscribe.</>
          </PermissionGuard>
        </span>
        {!isOnManageSubscriptionPage && (
          <PermissionGuard
            renderIfHasPermissions={[AccountsPermissions.UPDATE_SUBSCRIPTION]}
          >
            <Link to="/upgradeAccount">
              <AnnouncementBanner.Button>
                Select your plan
              </AnnouncementBanner.Button>
            </Link>
          </PermissionGuard>
        )}
      </div>
    </AnnouncementBanner>
  );
};

export default TrialPeriodExpiryBanner;
