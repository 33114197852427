import { InfoSquare } from 'react-iconly';
import React, { FC } from 'react';
import './GenericUserHelpTip.sass';

interface Props {
  children: any;
  icon?: JSX.Element;
}

export const GenericUserHelpTip: FC<Props> = ({ icon, children }) => (
  <div className={'generic-user-help-tip'}>
    <div>{icon || <InfoSquare />}</div>
    <div>
      <strong>Tip:</strong>
      {children}
    </div>
  </div>
);
