import { useQueryClient, UseMutationOptions } from '@tanstack/react-query';

import {
  CustomerQuery,
  SetCustomerPrimaryContactInput,
  SetCustomerPrimaryContactMutation as _SetCustomerPrimaryContactMutation,
} from '@/gql/graphql';
import customerKeys from '@/api/queries/customers/keyFactory';
import useSetCustomerPrimaryContact from '@/api/mutations/customers/useSetCustomerPrimaryContact';

const useOptimisticSetCustomerPrimaryContact = (
  customerId: string,
  options?: UseMutationOptions<
    _SetCustomerPrimaryContactMutation,
    unknown,
    SetCustomerPrimaryContactInput
  >
) => {
  const queryClient = useQueryClient();

  return useSetCustomerPrimaryContact({
    ...options,
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: customerKeys.customer(customerId),
      });

      // Snapshot the previous value
      const previousCustomer = queryClient.getQueryData(
        customerKeys.customer(customerId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData<CustomerQuery>(
        customerKeys.customer(customerId),
        (old) => {
          if (!old) return;

          const newContacts = old.customer.contacts.map((contact) => {
            return {
              ...contact,
              isPrimary: contact.id === variables.contactId,
            };
          });

          return {
            ...old,
            customer: {
              ...old.customer,
              primaryContact: newContacts.find(
                (contact) => contact.id === variables.contactId
              ),
              contacts: newContacts,
            },
          };
        }
      );

      options?.onMutate?.(variables);

      // Return a context object with the snapshotted value
      return { previousCustomer };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        customerKeys.customer(customerId),
        // @ts-ignore
        context.previousCustomer
      );

      options?.onError?.(err, newTodo, context);
    },
    // Always refetch after error or success:
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: customerKeys.customer(customerId),
      });

      options?.onSettled?.(...args);
    },
  });
};

export default useOptimisticSetCustomerPrimaryContact;
