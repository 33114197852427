import React, { FC, PropsWithChildren, useMemo } from 'react';

import {
  useChangeProposalsForDeal,
  useJobContentsForDeal,
  useProposalsForDeal,
} from '@payaca/store/hooks/appState';

import { getRegion } from '@/utils/stateAccessors';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { getJobType } from '@payaca/helpers/jobHelperV2';
import {
  ChangeProposal,
  ReadableChangeProposalStatus,
} from '@payaca/types/changeProposalTypes';
import { ReadableJobStatus } from '@payaca/types/jobTypes';
import { Job } from '@payaca/types/jobTypesV2';
import { isNullish } from '@payaca/utilities/guards';
import moment from 'moment-timezone';
import ChangeProposalStatusBadge from '../changeProposalStatusBadge/ChangeProposalStatusBadge';
import JobStatusBadge from '../jobStatusBadge/JobStatusBadge';
import './DealProposalHistoryTable.sass';
import { useSelector } from '@/api/state';

interface Props {
  dealId: number;
  navigateToProposal?: (proposalId: number) => void;
  navigateToChangeProposal?: (changeProposalId: number) => void;
}

interface TableRowData {
  onClick?: () => void;
  reference: string;
  proposalType: 'Quote' | 'Estimate' | 'Change proposal';
  status: string;
  createdAt: Date;
  sentAt?: Date;
  acceptedAt?: Date;
  cumulativeDealValue: number;
  changeValue?: number;
}

const DealProposalHistoryTable: FC<PropsWithChildren<Props>> = ({
  dealId,
  navigateToProposal,
  navigateToChangeProposal,
}) => {
  const region = useSelector((state) => getRegion(state));
  const shortDateRegionalFormat = useMemo(
    () => getInternationalMomentDateFormatByRegion(DateFormats.SHORT, region),
    [region]
  );

  const proposals = useProposalsForDeal(dealId)?.filter(
    (x) => !x.isProtoInvoice
  );
  const jobContents = useJobContentsForDeal(dealId);
  const changeProposals = useChangeProposalsForDeal(dealId);

  const tableRowDataArray = useMemo(() => {
    const dataArray: TableRowData[] = [];

    const sortedProposals = proposals.sort(
      (a: Job, b: Job) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

    const sortedChangeProposals = changeProposals.sort(
      (a: ChangeProposal, b: ChangeProposal) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

    let cumulativeDealValue = 0;

    sortedProposals.forEach((proposal: Job) => {
      const jobType = getJobType(proposal);
      const jobContent = jobContents.find(
        (x) => x.id === proposal.jobContentId
      );

      if (proposal.acceptedAt) {
        cumulativeDealValue = jobContent?.total ?? 0;
      }

      if (jobType != 'Invoice') {
        dataArray.push({
          reference: proposal.customReference || proposal.reference.toString(),
          proposalType: jobType === 'Job' ? 'Quote' : jobType,
          status: proposal.readableStatus || '',
          createdAt: proposal.createdAt,
          sentAt: proposal.sentAt,
          acceptedAt: proposal.acceptedAt,
          cumulativeDealValue: jobContent?.total ?? 0,
          onClick: navigateToProposal
            ? () => navigateToProposal(proposal.id)
            : undefined,
        });
      }
    });

    sortedChangeProposals.forEach((changeProposal: ChangeProposal) => {
      dataArray.push({
        reference:
          changeProposal.customReference ||
          changeProposal.reference?.toString() ||
          '',
        proposalType: 'Change proposal',
        status: changeProposal.readableStatus,
        createdAt: changeProposal.createdAt,
        sentAt: changeProposal.sentAt,
        acceptedAt: changeProposal.acceptedAt,
        changeValue: changeProposal.valueChangeIncTax,
        cumulativeDealValue:
          cumulativeDealValue + changeProposal.valueChangeIncTax,
        onClick: navigateToChangeProposal
          ? () => navigateToChangeProposal(changeProposal.id)
          : undefined,
      });

      if (changeProposal.acceptedAt) {
        cumulativeDealValue += changeProposal.valueChangeIncTax;
      }
    });

    return dataArray.reverse();
  }, [
    proposals,
    changeProposals,
    jobContents,
    navigateToChangeProposal,
    navigateToProposal,
  ]);

  return (
    <div className="deal-proposal-history-table">
      <table className="data-table">
        <thead>
          <tr>
            <th className="text-left">Ref</th>
            <th className="text-left">Status</th>
            <th className="value-table-cell">Project value</th>
            <th className="text-left">Sent</th>
            <th className="text-left">Accepted</th>
          </tr>
        </thead>
        <tbody>
          {tableRowDataArray.map((tableRowData, i) => {
            const changeValueIsNegative =
              tableRowData.changeValue && tableRowData.changeValue < 0;

            return (
              <tr
                key={i}
                onClick={tableRowData.onClick}
                className={tableRowData.onClick ? 'clickable' : ''}
              >
                <td className="reference-table-cell">
                  <div className="inner">
                    <small>{tableRowData.proposalType}</small>
                    <strong>{tableRowData.reference}</strong>
                  </div>
                </td>
                <td>
                  {tableRowData.proposalType === 'Change proposal' ? (
                    <ChangeProposalStatusBadge
                      status={
                        tableRowData.status as ReadableChangeProposalStatus
                      }
                    />
                  ) : (
                    <JobStatusBadge
                      status={tableRowData.status as ReadableJobStatus}
                    />
                  )}
                </td>
                <td className="value-table-cell">
                  <div className="inner">
                    {!isNullish(tableRowData.changeValue) && (
                      <small
                        className={`value-change ${
                          changeValueIsNegative ? 'negative' : 'positive'
                        }`}
                      >
                        {changeValueIsNegative ? '-' : '+'}
                        {currencyPrice(
                          Math.abs(tableRowData.changeValue),
                          region
                        )}
                      </small>
                    )}
                    <span>
                      {currencyPrice(tableRowData.cumulativeDealValue, region)}
                    </span>
                  </div>
                </td>
                <td>
                  {!!tableRowData.sentAt &&
                    moment(tableRowData.sentAt).format(shortDateRegionalFormat)}
                </td>
                <td>
                  {!!tableRowData.acceptedAt &&
                    moment(tableRowData.acceptedAt).format(
                      shortDateRegionalFormat
                    )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DealProposalHistoryTable;
