import React, { FunctionComponent, useMemo, useState } from 'react';

import {
  AnalyticsDashboardContent,
  AnalyticsDashboardUnsubscribedHoldingContent,
} from '@/ui/components';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import './AnalyticsDashboardPage.sass';
import { useSelector } from '@/api/state';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@/i18n';
import TagAnalyticsControl from '@/ui/components/analytics/tagAnalyticsControl/TagAnalyticsControl';
import TabbedContentPageWrapper from '../pageWrappers/tabbedContentPageWrapper/TabbedContentPageWrapper';
import { useHistory, useParams } from 'react-router';

enum ViewType {
  PERFORMANCE = 'performance',
  TAG_METRICS = 'tag-metrics',
}

const AnalyticsDashboardPage: FunctionComponent = (): JSX.Element => {
  const currentAccountHasSubscription: boolean = useSelector(
    (state) => !!state?.account?.accountAccessInformation?.hasActiveSubscription
  );
  const { tab } = useParams<{ tab?: string }>();
  const history = useHistory();

  const translate = useTranslation();

  const tabs = useMemo(() => {
    if (!currentAccountHasSubscription) {
      [
        {
          content: <AnalyticsDashboardUnsubscribedHoldingContent />,
          title: 'Performance',
          label: ViewType.PERFORMANCE,
        },
      ];
    }

    return [
      {
        content: <AnalyticsDashboardContent />,
        title: 'Performance',
        label: ViewType.PERFORMANCE,
      },
      {
        content: <TagAnalyticsControl />,
        title: 'Tag metrics',
        label: ViewType.TAG_METRICS,
      },
    ];
  }, [currentAccountHasSubscription]);

  return (
    <>
      <Helmet title={translate('pages.analytics.title')} />
      <TabbedContentPageWrapper
        className="analytics-dashboard-page"
        title="Analytics"
        tabbedContentConfig={{
          tabs: tabs,
          onChangeTab: (label) => {
            history.push(`/analytics/${label}`);
          },
          activeTabLabel: tab || ViewType.PERFORMANCE,
        }}
      />
    </>
  );
};

export default AnalyticsDashboardPage;
