import { Reducer } from 'redux';

import { ActionType, State } from './proposalsTypes';

export const initialState: State = {
  changeProposals: {},
  isGettingChangeProposalsForDeal: false,
};

const changeProposalsReducer: Reducer<State> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionType.GET_CHANGE_PROPOSALS_FOR_DEAL_REQUEST:
      return {
        ...state,
        isGettingChangeProposalsForDeal: true,
      };
    case ActionType.GET_CHANGE_PROPOSALS_FOR_DEAL_SUCCESS: {
      const now = new Date();
      return {
        ...state,
        changeProposals: {
          ...state.changeProposals,
          ...action.payload.reduce((acc: any, x: any) => {
            const fetchedEntity = state.changeProposals?.[x.id];
            return {
              ...acc,
              [x.id]: {
                ...fetchedEntity,
                entity: x,
                fetchAttempt: {
                  ...fetchedEntity?.fetchAttempt,
                  succeededAt: now,
                  isInProgress: false,
                },
              },
            };
          }, {}),
        },
        isGettingChangeProposalsForDeal: false,
      };
    }
    case ActionType.GET_CHANGE_PROPOSALS_FOR_DEAL_FAILURE:
      return {
        ...state,
        isGettingChangeProposalsForDeal: false,
      };

    case ActionType.GET_CHANGE_PROPOSAL_REQUEST: {
      const remoteEntity =
        state.changeProposals &&
        state.changeProposals[action.payload.changeProposalId];

      return {
        ...state,
        changeProposals: {
          ...state.changeProposals,
          [action.payload.changeProposalId]: {
            ...remoteEntity,
            fetchAttempt: {
              attemptedAt: new Date(),
              succeededAt: undefined,
              failedAt: undefined,
              isInProgress: true,
              error: undefined,
            },
          },
        },
      };
    }
    case ActionType.GET_CHANGE_PROPOSAL_SUCCESS: {
      const remoteEntity =
        state.changeProposals &&
        state.changeProposals[action.payload.changeProposalId];

      return {
        ...state,
        changeProposals: {
          ...state.changeProposals,
          [action.payload.changeProposalId]: {
            ...remoteEntity,

            entity: action.payload.changeProposal,
            fetchAttempt: {
              ...remoteEntity?.fetchAttempt,
              succeededAt: new Date(),
              isInProgress: false,
            },
          },
        },
      };
    }
    case ActionType.GET_CHANGE_PROPOSAL_FAILURE: {
      const remoteEntity =
        state.changeProposals &&
        state.changeProposals[action.payload.changeProposalId];

      return {
        ...state,
        changeProposals: {
          ...state.changeProposals,
          [action.payload.changeProposalId]: {
            ...remoteEntity,
            fetchAttempt: {
              ...remoteEntity?.fetchAttempt,
              failedAt: new Date(),
              isInProgress: false,
              error: action.error,
            },
          },
        },
      };
    }

    case ActionType.PERSIST_CHANGE_PROPOSAL_REQUEST: {
      if (!action.payload.persistChangeProposalRequestData.id) {
        return state;
      }

      const remoteEntity =
        state.changeProposals &&
        state.changeProposals[
          action.payload.persistChangeProposalRequestData.id
        ];

      return {
        ...state,
        changeProposals: {
          ...state.changeProposals,
          [action.payload.persistChangeProposalRequestData.id]: {
            ...remoteEntity,
            persistAttempt: {
              attemptedAt: new Date(),
              succeededAt: undefined,
              failedAt: undefined,
              isInProgress: true,
              error: undefined,
            },
          },
        },
      };
    }

    case ActionType.PERSIST_CHANGE_PROPOSAL_SUCCESS: {
      const remoteEntity =
        state.changeProposals &&
        state.changeProposals[action.payload.changeProposalId];

      return {
        ...state,
        changeProposals: {
          ...state.changeProposals,
          [action.payload.changeProposalId]: {
            ...remoteEntity,
            persistAttempt: {
              ...remoteEntity?.persistAttempt,
              succeededAt: new Date(),
              isInProgress: false,
            },
          },
        },
      };
    }
    case ActionType.PERSIST_CHANGE_PROPOSAL_FAILURE: {
      if (!action.payload.changeProposalId) return state;

      const remoteEntity =
        state.changeProposals &&
        state.changeProposals[action.payload.changeProposalId];

      return {
        ...state,
        changeProposals: {
          ...state.changeProposals,
          [action.payload.changeProposalId]: {
            ...remoteEntity,
            persistAttempt: {
              ...remoteEntity?.persistAttempt,
              failedAt: new Date(),
              isInProgress: false,
              error: action.error,
            },
          },
        },
      };
    }

    case ActionType.CLEAR_CHANGE_PROPOSALS: {
      return {
        ...state,
        changeProposals: {},
      };
    }

    default:
      return state;
  }
};

export default changeProposalsReducer;
