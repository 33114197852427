import { FetchedEntity, UpdateResult } from '@payaca/types/storeTypes';
import {
  Invoice,
  InvoiceLine,
  ListedInvoice,
  PaymentReconciliationRecord,
} from '@payaca/types/invoiceTypes';
import {
  Action,
  AsyncAction,
  DeclareAsyncAction,
  ErrorAction,
  UnpackAsyncAction,
} from '../types';
import { ListViewPage, SortDirection } from '@payaca/types/listViewTypes';

export interface State {
  isCreatingInvoice: boolean;
  isInvoiceCreatedSuccessfully?: boolean;

  isMarkingInvoiceAsSent: boolean;
  isInvoiceMarkedAsSentSuccessfully?: boolean;

  isSendingInvoice: boolean;
  isInvoiceSentSuccessfully?: boolean;

  invoices: { [key: number]: FetchedEntity<Invoice> };
  invoiceLines: { [key: string]: FetchedEntity<InvoiceLine> };
  paymentReconciliationRecords: {
    [key: number]: FetchedEntity<PaymentReconciliationRecord>;
  };

  isGettingInvoicesForDeal: boolean;
  isGettingInvoiceLinesForDeal: boolean;
  isGettingInvoiceLinesForInvoice: boolean;

  isGettingInvoiceValidationResult: boolean;

  isUpdatingInvoice: boolean;
  invoiceUpdateResults: { [key: number]: UpdateResult };
}

export enum ActionType {
  REQUEST_GET_INVOICE = 'invoices.requestGetInvoice',
  REQUEST_GET_INVOICES_FOR_DEAL = 'invoices.requestGetInvoicesForDeal',
  GET_INVOICE_SUCCESS = 'invoices.getInvoiceSuccess',
  GET_INVOICE_FAILURE = 'invoices.getInvoiceFailure',
  GET_INVOICES_FOR_DEAL_SUCCESS = 'invoices.getInvoicesForDealSuccess',
  GET_INVOICES_FOR_DEAL_FAILURE = 'invoices.getInvoicesForDealFailure',

  REQUEST_GET_INVOICE_LINE = 'invoices.requestGetInvoiceLine',
  REQUEST_GET_INVOICE_LINES_FOR_DEAL = 'invoices.requestGetInvoiceLinesForDeal',
  REQUEST_GET_INVOICE_LINES_FOR_INVOICE = 'invoices.requestGetInvoiceLinesForInvoice',
  GET_INVOICE_LINE_SUCCESS = 'invoices.getInvoiceLineSuccess',
  GET_INVOICE_LINE_FAILURE = 'invoices.getInvoiceLineFailure',
  GET_INVOICE_LINES_FOR_DEAL_SUCCESS = 'invoices.getInvoiceLinesForDealSuccess',
  GET_INVOICE_LINES_FOR_DEAL_FAILURE = 'invoices.getInvoiceLinesForDealFailure',
  GET_INVOICE_LINES_FOR_INVOICE_SUCCESS = 'invoices.getInvoiceLinesForInvoiceSuccess',
  GET_INVOICE_LINES_FOR_INVOICE_FAILURE = 'invoices.getInvoiceLinesForInvoiceFailure',

  REQUEST_GET_PAYMENT_RECONCILIATION_RECORDS_FOR_DEAL = 'invoices.requestGetPaymentReconciliationRecordsForDeal',
  GET_PAYMENT_RECONCILIATION_RECORDS_FOR_DEAL_SUCCESS = 'invoices.getPaymentReconciliationRecordsForDealSuccess',

  GET_PAYMENT_RECONCILIATION_RECORD_SUCCESS = 'invoices.getPaymentReconciliationRecordSuccess',

  CLEAR_INVOICE = 'invoices.clearInvoice',
  CLEAR_INVOICES = 'invoices.clearInvoices',
  CLEAR_INVOICE_LINE = 'invoices.clearInvoiceLine',
  CLEAR_INVOICE_LINES = 'invoices.clearInvoiceLines',
  CLEAR_PAYMENT_RECONCILIATION_RECORDS = 'invoices.clearPaymentReconciliationRecords',

  REQUEST_MARK_INVOICE_AS_SENT = 'invoices.requestMarkInvoiceAsSent',
  MARK_INVOICE_AS_SENT_SUCCESS = 'invoices.markInvoiceAsSentSuccess',
  MARK_INVOICE_AS_SENT_FAILURE = 'invoices.markInvoiceAsSentFailure',

  REQUEST_CREATE_INVOICE = 'invoices.requestCreateInvoice',
  CREATE_INVOICE_SUCCESS = 'invoices.createInvoiceSuccess',
  CREATE_INVOICE_FAILURE = 'invoices.createInvoiceFailure',

  REQUEST_SEND_INVOICE = 'invoices.requestSendInvoice',
  SEND_INVOICE_SUCCESS = 'invoices.sendInvoiceSuccess',
  SEND_INVOICE_FAILURE = 'invoices.sendInvoiceFailure',

  REQUEST_UPDATE_INVOICE = 'invoices.requestUpdateInvoice',
  UPDATE_INVOICE_SUCCESS = 'invoices.updateInvoiceSuccess',
  UPDATE_INVOICE_FAILURE = 'invoices.updateInvoiceFailure',

  REQUEST_GET_INVOICE_VALIDATION_RESULT = 'invoices.requestGetInvoiceValidationResult',
  GET_INVOICE_VALIDATION_RESULT_SUCCESS = 'invoices.getInvoiceValidationResultSuccess',
  GET_INVOICE_VALIDATION_RESULT_FAILURE = 'invoices.getInvoiceValidationResultFailure',

  REQUEST_VOID_INVOICE = 'invoices.requestVoidInvoice',
  VOID_INVOICE_SUCCESS = 'invoices.voidInvoiceSuccess',
  VOID_INVOICE_FAILURE = 'invoices.voidInvoiceFailure',

  REQUEST_GET_INVOICE_GENERATED_PDF_URL = 'invoices.requestGetInvoiceGeneratedPdfUrl',

  GET_UPCOMING_REFERENCE_REQUEST = 'invoice.getUpcomingReference:request',

  PUSH_INVOICE_TO_ACCOUNTING_INTEGRATIONS_REQUEST = 'invoices.pushInvoiceToAccountingIntegrations:request',
  GET_LISTED_INVOICES_REQUEST = 'invoices.getListed:request',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}

export interface CreateInvoiceRequestData {
  dealId: number;
  createForUninvoicedDealValue: boolean;
  invoiceValue?: number;
}

export interface UpdateInvoiceRequestData {
  invoiceId: number;
  customReference?: string | null;
  notes?: string;
  dueInDays?: number;
  assignedToUserId?: number | null;
  contactId?: number | null;
  invoiceLines?: Pick<InvoiceLine, 'id' | 'value'>[];
}

export interface SendInvoiceRequestData {
  isResend?: boolean;
  invoiceId: number;
  sendMeACopy: boolean;
  emailCopy: {
    preButton: string;
    postButton: string;
  };
}

export type GetListedInvoicesRequestData = {
  pageSize?: number;
  pageNumber?: number;
  searchTerm?: string;
  sortDirection?: SortDirection;
  sortBy?: 'createdAt';
  statuses?: Invoice['readableStatus'][];
};

export type VoidInvoiceAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.REQUEST_VOID_INVOICE;
      payload: {
        invoiceId: number;
        callback?: () => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
    success: {
      type: ActionType.VOID_INVOICE_SUCCESS;
    };
    failure: {
      type: ActionType.VOID_INVOICE_FAILURE;
    };
  }
>;

export type GetListedInvoices = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_LISTED_INVOICES_REQUEST;
      payload: {
        requestData: GetListedInvoicesRequestData;
        callback?: (page: ListViewPage<ListedInvoice>) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
    success: {
      type: ActionType.VOID_INVOICE_SUCCESS;
    };
    failure: {
      type: ActionType.VOID_INVOICE_FAILURE;
    };
  }
>;

export type GetInvoiceGeneratedPdfUrl = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.REQUEST_GET_INVOICE_GENERATED_PDF_URL;
      payload: {
        invoiceId: number;
        callback?: (pdfUrl: string) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type GetUpcomingReference = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_UPCOMING_REFERENCE_REQUEST;
      payload: {
        callback?: (reference: number) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type PushInvoiceToAccountingIntegrationsAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.PUSH_INVOICE_TO_ACCOUNTING_INTEGRATIONS_REQUEST;
      payload: {
        invoiceId: number;
      };
    };
  }
>;
