import {
  PublicServicePlanPrice,
  ServicePlanPeriod,
} from '@payaca/types/service-plans';

export const getServicePlanMonthlyPrice = (
  prices: PublicServicePlanPrice[]
) => {
  return prices.find(
    (p) => p.billingInterval === 'month' && p.billingIntervalCount === 1
  );
};

export const getServicePlanYearlyPrice = (prices: PublicServicePlanPrice[]) => {
  return prices.find(
    (p) => p.billingInterval === 'year' && p.billingIntervalCount === 1
  );
};

export const getServicePlanPeriodStatus = (
  status: ServicePlanPeriod['status']
) => {
  switch (status) {
    case 'arranged':
      return 'Arranged';
    case 'unarranged':
      return 'Unarranged';
    case 'fulfilled':
      return 'Complete';
    default:
      return '';
  }
};
