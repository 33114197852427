import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IntercomAPI } from 'react-intercom';
import * as Sentry from '@sentry/react';
import { actions as usersActions } from '@/api/users';
import { selectors as appSelectors } from '@/api/app';
import { useSelector } from '@/api/state';

declare global {
  interface Window {
    profitwell: any;
  }
}

const DevelopmentToolsInitialiser: FC = (): null => {
  const dispatch = useDispatch();
  const myProfile = useSelector((state: any) => state.users.myProfile);
  const isLoggedIn = useSelector((state: any) =>
    appSelectors.isLoggedIn(state.app)
  );

  useEffect(() => {
    isLoggedIn && dispatch(usersActions.getProfile());
  }, [isLoggedIn]);

  const setupIntercom = useCallback(() => {
    // TODO: only send to intercom if specifc details changed
    if (myProfile.name && myProfile.name !== 'undefined undefined') {
      IntercomAPI('registerIdentifiedUser', {
        userId: myProfile.name,
      });
      const userDetails = {
        user_hash: myProfile.intercomHash,
        email: myProfile.email,
        user_id: myProfile.name,
        name: myProfile.name,
        phone: myProfile.contactNumber,
        using_tradesperson_web: true,
        company: {
          id:
            myProfile.accounts &&
            myProfile.accounts[0] &&
            myProfile.accounts[0].id,
          name:
            myProfile.accounts &&
            myProfile.accounts[0] &&
            myProfile.accounts[0].companyName,
        },
      };
      IntercomAPI('update', userDetails);
    }
  }, [myProfile]);

  const setupSentry = useCallback(() => {
    const scope = Sentry.getCurrentScope();
    scope.setUser({
      email: myProfile.email,
    });
  }, [myProfile]);

  const setupProfitWell = useCallback(() => {
    const stripeCustomerId = myProfile?.accounts?.[0]?.stripeCustomerId;
    window.profitwell('start', {
      user_email: myProfile.email,
      user_id: stripeCustomerId,
    });
  }, [myProfile]);

  const handleSetupProfitwell = useCallback(() => {
    if (myProfile && window.profitwell) {
      if (
        import.meta.env.VITE_NODE_ENV !== 'development' &&
        import.meta.env.VITE_ENV !== 'staging'
      ) {
        // only send to profitwell on prod
        setupProfitWell();
      }
    }
  }, [window.profitwell, setupProfitWell, myProfile]);

  useEffect(() => {
    //  setup data when profile has loaded
    if (myProfile && Object.keys(myProfile).length > 0) {
      setupIntercom();
      handleSetupProfitwell();
      if (import.meta.env.VITE_NODE_ENV !== 'development') {
        setupSentry();
      }
    }
  }, [myProfile]);

  return null;
};

export default DevelopmentToolsInitialiser;
