import i18n from 'i18next';
import {
  initReactI18next,
  useTranslation as useVendorTranslation,
} from 'react-i18next';

import { DeepPartial } from '@payaca/utilities/types';
import {
  CsvUploadDataType,
  CsvUploadStatus,
} from '@payaca/types/csvUploadTypes';

/** UK English */
const defaultLanguage = 'en-GB' as const;

type DefaultLanguage = typeof defaultLanguage;

const routes = {
  dashboard: '/dashboard',
  deals: '/deals',
  deal: '/deals/:dealId/:tab?',
  customers: '/customers',
  customer: '/customers/:customerId',
  schedule: '/schedule',
  dispatch: '/dispatch',
  tasks: '/tasks',
  items: '/items',
  item: '/items/:itemId',
  invoices: '/invoices',
  materials: '/materials',
  material: '/materials/:materialId',
  suppliers: '/suppliers',
  supplier: '/suppliers/:supplierId',
  automations: '/automations',
  automation: '/automations/:automationId',
  analytics: '/analytics',
  profile: '/profile',
  myCompany: '/my-company',
  users: '/users',
  userProfile: '/users/:userId',
  myPages: '/my-pages',
  manageSubscription: '/manage-subscription',
  csvUploads: '/csv-uploads',
  tags: '/tags',
  connections: '/connections',
  logOut: '/logout',
  servicePlans: '/service-plans',
  servicePlanCustomerReminders: '/service-plans/customer-reminders',
  servicePlan: '/service-plans/service-plan/:servicePlanId',
  templates: '/templates',
  timelogs: '/timelogs',
} as const;

type Routes = typeof routes;

type PersistentRoutes = Pick<
  Routes,
  | 'dashboard'
  | 'deals'
  | 'invoices'
  | 'customers'
  | 'schedule'
  | 'dispatch'
  | 'tasks'
  | 'timelogs'
  | 'items'
  | 'materials'
  | 'suppliers'
  | 'automations'
  | 'analytics'
  | 'servicePlans'
>;

type AccountRoutes = Pick<
  Routes,
  | 'profile'
  | 'myCompany'
  | 'users'
  | 'myPages'
  | 'manageSubscription'
  | 'csvUploads'
  | 'tags'
  | 'connections'
  | 'logOut'
  | 'templates'
>;

type LabelWithTooltip = {
  label: string;
  tooltip?: string | null;
};

type Cta = LabelWithTooltip;

type Page = {
  title: string;
  description?: string | null;
  heading?: string | null;
  ctas?: Record<string, Cta> | null;
};

type PersistentNavItems = Record<keyof PersistentRoutes, LabelWithTooltip>;
type AccountNavItems = Record<keyof AccountRoutes, LabelWithTooltip>;

type Pages = Record<keyof Routes, Page>;

type WebappTranslation = {
  navigation: {
    persistent: PersistentNavItems;
    account: AccountNavItems;
  };
  pages: Pages;
  csvUploads: {
    dataTypes: Record<CsvUploadDataType, string>;
    statuses: Record<CsvUploadStatus, string>;
  };
};

const supportedLanguages = [
  defaultLanguage,
  'en-US', // US English
  // TODO: Uncomment this once translations have been approved
  // 'af-ZA', // Afrikaans (South Africa)
] as const;

type SupportedLanguages = (typeof supportedLanguages)[number];
type AdditionalLanguages = Exclude<SupportedLanguages, DefaultLanguage>;

const translations: Record<DefaultLanguage, WebappTranslation> &
  Record<AdditionalLanguages, DeepPartial<WebappTranslation>> &
  Record<string, DeepPartial<WebappTranslation>> = {
  'en-GB': {
    navigation: {
      persistent: {
        dashboard: {
          label: 'Dashboard',
        },
        deals: {
          label: 'Projects',
        },
        customers: {
          label: 'Customers',
        },
        schedule: {
          label: 'Schedule',
        },
        dispatch: {
          label: 'Dispatch',
        },
        tasks: {
          label: 'Tasks',
        },
        timelogs: {
          label: 'Timelogs',
        },
        items: {
          label: 'Items you sell',
        },
        invoices: {
          label: 'Invoices',
        },
        materials: {
          label: 'Materials',
        },
        suppliers: {
          label: 'Suppliers',
        },
        automations: {
          label: 'Automations',
        },
        analytics: {
          label: 'Analytics',
        },
        servicePlans: {
          label: 'Service Plans',
        },
      },
      account: {
        profile: {
          label: 'Profile',
        },
        myCompany: {
          label: 'My company',
        },
        users: {
          label: 'Users',
        },
        myPages: {
          label: 'My pages',
        },
        manageSubscription: {
          label: 'Manage subscription',
        },
        tags: {
          label: 'Tags',
        },
        csvUploads: {
          label: 'Import data',
        },
        connections: {
          label: 'Connections',
        },
        logOut: {
          label: 'Log out',
        },
        templates: {
          label: 'Templates',
        },
      },
    },
    pages: {
      timelogs: {
        title: 'Timelog Reporting',
      },
      dashboard: {
        title: 'Dashboard',
      },
      invoices: {
        title: 'Invoices',
      },
      deals: {
        title: 'Projects',
        ctas: {
          createDeal: {
            label: 'New Project',
          },
        },
      },
      deal: {
        title: 'Project: {{reference}}',
      },
      customers: {
        title: 'Customers',
        heading: 'Customers',
        ctas: {
          createCustomer: {
            label: 'Create new',
          },
        },
      },
      customer: {
        title: 'Customer: {{name}}',
      },
      schedule: {
        title: 'Schedule',
        heading: 'Schedule',
      },
      dispatch: {
        title: 'Dispatch',
        heading: 'Dispatch',
      },
      tasks: {
        title: 'Tasks',
        heading: 'Tasks',
        ctas: {
          createTask: {
            label: 'Create new',
          },
        },
      },
      items: {
        title: 'Items you sell',
      },
      item: {
        title: 'Item: {{reference}}',
      },
      materials: {
        title: 'Materials',
      },
      material: {
        title: 'Material: {{name}}',
      },
      suppliers: {
        title: 'Suppliers',
      },
      supplier: {
        title: 'Supplier: {{name}}',
      },
      automations: {
        title: 'Automations',
      },
      automation: {
        title: 'Automation: {{name}}',
      },
      analytics: {
        title: 'Analytics',
      },
      profile: {
        title: 'Profile',
      },
      myCompany: {
        title: 'My company',
      },
      users: {
        title: 'Users',
      },
      userProfile: {
        title: 'User: {{name}}',
      },
      myPages: {
        title: 'My pages',
      },
      manageSubscription: {
        title: 'Manage subscription',
      },
      tags: {
        title: 'Tags',
      },
      csvUploads: {
        title: 'Import data',
      },
      connections: {
        title: 'Connections',
      },
      logOut: {
        title: 'Log out',
      },
      servicePlans: {
        title: 'Service Plans',
      },
      servicePlan: {
        title: 'Service Plan: {{name}}',
      },
      servicePlanCustomerReminders: {
        title: 'Customer Reminders',
      },
      templates: {
        title: 'Templates',
      },
    },
    csvUploads: {
      dataTypes: {
        customers: 'Customers',
        deals: 'Projects',
        lineItems: 'Line Items',
        materials: 'Materials',
        serviceReminders: 'Service Reminders',
        supplierMaterials: 'Materials',
        suppliers: 'Suppliers',
      } as any,
      statuses: {
        created: 'Created',
        uploading: 'Uploading',
        uploadAborted: 'Upload aborted',
        uploadFailed: 'Upload failed',
        uploadComplete: 'Uploaded',
        received: 'Received',
        validating: 'Validating',
        validationFailed: 'Validation failed',
        validationComplete: 'Validation complete',
        pendingConfirmation: 'Pending confirmation',
        processing: 'Processing',
        processingFailed: 'Processing failed',
        complete: 'Complete',
      },
    },
  },
  'en-US': {
    navigation: {
      persistent: {
        deals: {},
      },
    },
    pages: {
      deals: {
        ctas: {
          createDeal: {},
        },
      },
      deal: {},
    },
  },
  'af-ZA': {
    navigation: {
      persistent: {
        dashboard: {
          label: 'Dashboard',
        },
        invoices: {
          label: 'Invoices',
        },
        deals: {
          label: 'Projects',
        },
        customers: {
          label: 'Kliënte',
        },
        schedule: {
          label: 'Schedule',
        },
        dispatch: {
          label: 'Dispatch',
        },
        tasks: {
          label: 'Take',
        },
        items: {
          label: 'Lynitems',
        },
        materials: {
          label: 'Materiaal',
        },
        suppliers: {
          label: 'Verskaffers',
        },
        automations: {
          label: 'Outomatiserings',
        },
        analytics: {
          label: 'Optrede',
        },
        servicePlans: {
          label: 'Service Plans',
        },
      },
      account: {
        profile: {
          label: 'Profiel',
        },
        myCompany: {
          label: 'My maatskappy',
        },
        users: {
          label: 'Gebruikers',
        },
        myPages: {
          label: 'My bladsye',
        },
        manageSubscription: {
          label: 'Bestuur intekening',
        },
        tags: {
          label: 'Tags',
        },
        csvUploads: {
          label: 'Import data',
        },
        connections: {
          label: 'Verbindings',
        },
        logOut: {
          label: 'Teken uit',
        },
      },
    },
    pages: {
      dashboard: {
        title: 'Dashboard',
      },
      invoices: {
        title: 'Invoices',
      },
      deals: {
        title: 'Projects',
        ctas: {
          createDeal: {
            label: 'New Project',
          },
        },
      },
      customers: {
        title: 'Customers',
        heading: 'Customers',
        ctas: {
          createCustomer: {
            label: 'Create new',
          },
        },
      },
      schedule: {
        title: 'Schedule',
        heading: 'Schedule',
      },
      tasks: {
        title: 'Tasks',
        heading: 'Tasks',
        ctas: {
          createTask: {
            label: 'Create new',
          },
        },
      },
      items: {
        title: 'Items',
      },
      materials: {
        title: 'Materials',
      },
      suppliers: {
        title: 'Suppliers',
      },
      automations: {
        title: 'Automations',
      },
      analytics: {
        title: 'Analytics',
      },
    },
  },
};

const defaultNamespace = 'translations' as const;

i18n.use(initReactI18next).init({
  resources: Object.fromEntries(
    Object.entries(translations).map(([key, value]) => [
      key,
      {
        [defaultNamespace]: value,
      },
    ])
  ),
  supportedLngs: supportedLanguages,
  lng: navigator.language,
  defaultNS: defaultNamespace,
  fallbackLng: {
    default: [defaultLanguage],
    en: ['en-GB'],
    // TODO: Uncomment this once translations have been approved
    // af: ['af-ZA'],
    // 'af-ZA': ['en-ZA'],
    // 'en-ZA': ['en-GB'],
  },
  interpolation: {
    escapeValue: false,
  },
});

export const useTranslation = (keyPrefix?: string) => {
  const { t } = useVendorTranslation(defaultNamespace, {
    keyPrefix,
  });
  return t;
};

export default i18n;
