import {
  error,
  flatMapLoadable,
  loaded,
  loading,
  unloaded,
} from '@payaca/utilities/monads/loadable';

import { createReducer } from '../utils';

import { ActionType, AnyAction, State } from './types';

const initialState: State = {
  countries: unloaded(),
};

export default createReducer<State, ActionType, AnyAction>(initialState, {
  [ActionType.GET_COUNTRIES_REQUEST]: (state) => ({
    ...state,
    countries: flatMapLoadable(state.countries, loading),
  }),
  [ActionType.GET_COUNTRIES_SUCCESS]: (state, action) => ({
    ...state,
    countries: loaded(action.payload),
  }),
  [ActionType.GET_COUNTRIES_FAILURE]: (state, action) => ({
    ...state,
    countries: flatMapLoadable(state.countries, (countries) =>
      error(action.error, countries)
    ),
  }),
});
