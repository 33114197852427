import React, { FC, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';

import './TrialSubscriptionUpgradeBanner.sass';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { getUserRoles } from '@/utils/stateAccessors';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { useSelector } from '@/api/state';
import { AnnouncementBanner } from '@payaca/components/announcementBanner/AnnouncementBanner';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { Link } from 'react-router-dom';

const TrialSubscriptionUpgradeBanner: FC = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const userRoles = useSelector(getUserRoles);
  const currentUserIsAdmin = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      AccountsPermissions.UPDATE_SUBSCRIPTION,
    ]);
  }, [userRoles]);
  const accountAccessInformation = useSelector((state) => {
    return state.account.accountAccessInformation;
  });
  const temporarySubscription = useMemo(() => {
    return accountAccessInformation?.temporarySubscription;
  }, [accountAccessInformation]);

  const isOnManageSubscriptionPage = useMemo(() => {
    return location.pathname == '/upgradeAccount';
  }, [location]);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const timestampShortDateRegionalFormat = useMemo(
    () =>
      getInternationalMomentDateFormatByRegion(
        DateFormats.TIMESTAMP_SHORT,
        account.region
      ),
    [account]
  );

  return (
    <AnnouncementBanner>
      <div className="flex flex-row items-baseline gap-4">
        <span className="mr-auto text-white">
          You are currently trialing{' '}
          {temporarySubscription?.name
            ? `our ${temporarySubscription.name} `
            : 'another'}{' '}
          plan
          {temporarySubscription?.expiresAt &&
            `, this will expire on
            ${moment(temporarySubscription.expiresAt).format(
              timestampShortDateRegionalFormat
            )}.`}{' '}
          {!currentUserIsAdmin &&
            'Please contact your account administrator to upgrade.'}
        </span>
        {!isOnManageSubscriptionPage && (
          <PermissionGuard
            renderIfHasPermissions={[AccountsPermissions.UPDATE_SUBSCRIPTION]}
          >
            <Link to="/upgradeAccount">
              <AnnouncementBanner.Button>
                Upgrade here
              </AnnouncementBanner.Button>
            </Link>
          </PermissionGuard>
        )}
      </div>
    </AnnouncementBanner>
  );
};
export default TrialSubscriptionUpgradeBanner;
