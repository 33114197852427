import { FunctionComponent, useMemo } from 'react';
import { useMaterialsList } from '@payaca/store/hooks/appState';
import PurchaseOrderCard from '../purchaseOrderCard/PurchaseOrderCard';

import './ManageableMaterialsListPurchaseOrders.sass';
import { clstx } from '@payaca/components/utils';
import EmptyState from '@payaca/components/plEmptyState/EmptyState';

type Props = {
  materialsListId: number;
  navigateToPurchaseOrder?: (purchaseOrderId: number) => void;
  className?: string;
  emptyText?: string;
};

const ManageableMaterialsListPurchaseOrders: FunctionComponent<Props> = ({
  materialsListId,
  navigateToPurchaseOrder,
  className,
  emptyText,
}: Props): JSX.Element | null => {
  const materialsList = useMaterialsList(materialsListId);
  if (!materialsList) return null;
  if (!materialsList.purchaseOrderIds?.length && emptyText)
    return <EmptyState text={emptyText} />;

  return (
    <div
      className={clstx('manageable-materials-list-purchase-orders', className)}
    >
      {!!materialsList.purchaseOrderIds?.length && (
        <ul className="purchase-orders-list">
          {materialsList.purchaseOrderIds.map((purchaseOrderId, i) => {
            return (
              <li key={`purchase-order-tile-${i}`}>
                <PurchaseOrderCard
                  purchaseOrderId={purchaseOrderId}
                  onClick={() =>
                    navigateToPurchaseOrder &&
                    navigateToPurchaseOrder(purchaseOrderId)
                  }
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ManageableMaterialsListPurchaseOrders;
