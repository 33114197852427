import '../slateEditor/types';

import React, { useMemo } from 'react';
import { createEditor, CustomTypes, Descendant } from 'slate';
import { Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import useRenderElements from './Elements';
import { withVariables } from './Elements/Variable';
import VariableList, { IProps as IVariableListProps } from './VariableList';
import Toolbar from './Toolbar';
import FieldLabel, { LabelStyleVariant } from '../fieldLabel/FieldLabel';
import { useFieldId } from '@payaca/hooks/useFieldId';
import { Format } from '../slateEditor/types';
import Editable from '../slateEditor/Editable';

export const blankInitialValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export const FORMATS: Format[] = [
  {
    type: 'bold',
    hotkey: 'mod+b',
    leafRender: ({ children }) => <strong>{children}</strong>,
    toolbarRender: () => <strong>B</strong>,
  },
  {
    type: 'italic',
    hotkey: 'mod+i',
    leafRender: ({ children }) => <em>{children}</em>,
    toolbarRender: () => <em>I</em>,
  },
  {
    type: 'underline',
    hotkey: 'mod+u',
    leafRender: ({ children }) => <u>{children}</u>,
    toolbarRender: () => <u>U</u>,
  },
  {
    type: 'strikethrough',
    hotkey: 'mod+shift+s',
    leafRender: ({ children }) => <s>{children}</s>,
    toolbarRender: () => <s>S</s>,
  },
  {
    type: 'code',
    leafRender: ({ children }) => <code>{children}</code>,
  },
];

export interface IProps<T = string> {
  name: string;
  label?: string | JSX.Element;
  description?: string | JSX.Element;
  labelStyleVariant?: LabelStyleVariant;
  isRequired?: boolean;
  onChange?: (value: Record<string, any>) => void;
  variables?: IVariableListProps<T>['variables'];
  initialValue?: Descendant[];
}

const RichTextEditorField = <T extends string>(props: IProps<T>) => {
  const {
    name,
    label,
    description,
    labelStyleVariant,
    isRequired,
    variables,
    onChange,
    initialValue = blankInitialValue,
  } = props;

  const id = useFieldId(name);

  const editor = useMemo(
    () =>
      withVariables(
        withHistory(withReact(createEditor()))
      ) as CustomTypes['Editor'],
    []
  );
  const renderElements = useRenderElements();

  return (
    <div className="gap-5 md:flex">
      <Slate
        onChange={(event) => onChange?.({ [name]: event })}
        editor={editor}
        initialValue={initialValue}
      >
        <div className="flex flex-col md:w-3/4">
          <FieldLabel
            label={label}
            id={id}
            isRequired={isRequired}
            description={description}
            styleVariant={labelStyleVariant}
          />
          <div className="input-wrapper flex-1">
            <div className="input-wrapper__input flex h-full flex-col">
              <Toolbar />

              <Editable
                id={id}
                className="flex-1 focus:outline-0"
                formats={FORMATS}
                renderElements={renderElements}
              />
            </div>
          </div>
        </div>

        <VariableList<T> className="md:w-1/4" variables={variables} />
      </Slate>
    </div>
  );
};

export default RichTextEditorField;
