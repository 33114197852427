export enum SortDirection {
  ASCENDING = 'Ascending',
  DESCENDING = 'Descending',
}

export interface ListViewPage<T> {
  pageSize: number;
  pageNumber: number;
  totalItemCount: number;
  items: Array<T>;

  sortBy?: string;
  sortDirection?: SortDirection;
}
