import React, { FC } from 'react';
import SkeletonLoader from '../plSkeletonLoader/SkeletonLoader';
import Card from '../plCard/Card';

const ClientDocumentLoading: FC = () => {
  return (
    <div className="grid grid-cols-3 gap-8">
      <div className="col-span-2 space-y-8">
        <Card>
          <Card.Body>
            <div className="flex flex-col items-end gap-4">
              <SkeletonLoader.Title className="w-1/6" />
              <SkeletonLoader.Text className="w-1/4" />

              <hr className="hr my-4" />

              <SkeletonLoader.Text />
              <SkeletonLoader.Text />
              <SkeletonLoader.Text />
            </div>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <div className="space-y-4">
              <SkeletonLoader.Title className="w-1/6" />

              <SkeletonLoader className="h-28 rounded-lg" />
              <SkeletonLoader className="h-28 rounded-lg" />
              <SkeletonLoader className="h-28 rounded-lg" />

              <div className="flex flex-col items-end gap-4">
                <SkeletonLoader.Text className="w-1/12" />
                <SkeletonLoader.Text className="w-1/12" />
              </div>
            </div>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <div className="space-y-4">
              <SkeletonLoader.Title className="w-1/6" />

              <SkeletonLoader className="h-28 rounded-lg" />
            </div>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <div className="space-y-4">
              <SkeletonLoader.Title className="w-1/6" />

              <SkeletonLoader className="h-28 rounded-lg" />
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="space-y-4">
        <Card>
          <Card.Body className="flex flex-col gap-4">
            <SkeletonLoader.Title className="w-1/3" />

            <hr className="hr my-4" />

            <SkeletonLoader.Text />
            <SkeletonLoader.Text />
            <SkeletonLoader.Text />

            <hr className="hr my-4" />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ClientDocumentLoading;
