import Modal from '@payaca/components/plModal/Modal';
import React, { FC, useMemo } from 'react';
import { EditScheduledEventControl } from './EditScheduledEventControl';
import { UpdateScheduledEventRequestData } from '@payaca/store/scheduledEvents/scheduledEventsTypes';

import { Props as FieldsetProps } from '../scheduledEventFieldset/ScheduledEventFieldset';
import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';

export const EditScheduledEventModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  event: ScheduledEvent;
  onSuccess?: () => void;
}> = ({ isOpen, onClose, event, onSuccess }) => {
  const eventData = useMemo<UpdateScheduledEventRequestData>(() => {
    return {
      id: event.id,
      name: event.name,
      description: event.description,
      beginAt: new Date(event.beginAt),
      endAt: new Date(event.endAt),
      userAssignments: event.userAssignments,
      dealId: event.dealId || undefined,
      includeCustomerInformation: event.includeCustomerInformation,
      location: event.location,
      customerIds: event.customerIds,
      contactsToNotify: event.contactsToNotify,
    };
  }, [event]);

  const {
    hiddenFields,
    disabledFields,
  }: Pick<FieldsetProps, 'hiddenFields' | 'disabledFields'> = useMemo(() => {
    if (eventData.dealId) {
      return {
        hiddenFields: ['customerIds', 'tagIds'],
        disabledFields: ['dealId'],
      };
    } else if (eventData.customerIds?.length) {
      return {
        hiddenFields: ['dealId', 'tagIds'],
        disabledFields: ['customerIds'],
      };
    } else {
      return {
        hiddenFields: ['customerIds', 'tagIds'],
        disabledFields: [],
      };
    }
  }, [eventData]);

  return (
    <Modal
      isOpen={isOpen}
      title={'Update an Event'}
      onClose={onClose}
      disableBackdropClick={true}
    >
      <EditScheduledEventControl
        initialEventData={eventData}
        onSuccess={() => {
          onClose();
          onSuccess?.();
        }}
        hiddenFields={hiddenFields}
        disabledFields={disabledFields}
      />
    </Modal>
  );
};
