import { FC, useCallback, useMemo } from 'react';

import { User } from '@payaca/types/userTypes';
import Modal, { IProps as ModalProps } from '@payaca/components/plModal/Modal';
import { useGetUser } from '@/api/queries/user/useGetUser';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { UpdateUserInput } from '@/gql/graphql';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';

import {
  getConditionalValidator,
  getNumericalRangeFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import Button from '@payaca/components/plButton/Button';
import { useUpdateUser } from '@/api/mutations/user/useUpdateUser';

type Props = {
  userId?: User['id'];
  onSuccess?: () => void;
} & Omit<ModalProps, 'title'>;
const EditUserSortOrderModal: FC<Props> = (props) => {
  const { isOpen, onClose, onSuccess, userId } = props;
  const { data: userData } = useGetUser(userId);
  const { mutateAsync: updateUserMutation, isLoading } = useUpdateUser();

  const initialFormData: Pick<UpdateUserInput, 'sortOrder'> = useMemo(() => {
    return {
      sortOrder: userData?.user.sortOrder,
    };
  }, [userData]);

  const fieldValidators = useMemo(() => {
    return {
      sortOrder: [
        getConditionalValidator(
          (formState) => typeof formState.sortOrder === 'number',
          getNumericalRangeFieldValidator(0)
        ),
      ],
    };
  }, []);

  const onSubmit = useCallback(
    async (formState: Pick<UpdateUserInput, 'sortOrder'>) => {
      const updateData: UpdateUserInput = {
        userId: userId?.toString() || '',
      };

      if (formState.sortOrder !== userData?.user.sortOrder) {
        updateData.sortOrder = formState.sortOrder;
      }

      await updateUserMutation({
        ...updateData,
      });
      onSuccess?.();
    },
    [userId, onSuccess, userData?.user]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Edit sort order'}>
      <ValidatedForm
        fieldValidators={fieldValidators}
        initialFormState={initialFormData}
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => {
          return (
            <>
              <Modal.Body>
                <div className="flex flex-col gap-4">
                  <Field
                    name="sortOrder"
                    validationState={
                      validationState?.['sortOrder']?.isValid === false &&
                      touchedState?.['sortOrder']
                        ? {
                            isValid: false,
                            validationMessages:
                              validationState?.['sortOrder']?.errors,
                          }
                        : undefined
                    }
                  >
                    <Field.Label>Sort order</Field.Label>
                    <Input
                      step={1}
                      type="number"
                      value={formState.sortOrder?.toString()}
                      onChange={(value) =>
                        onFieldChange({
                          sortOrder: value?.length ? Math.floor(+value) : null,
                        })
                      }
                      onBlur={() => onFieldTouch('sortOrder')}
                    />
                  </Field>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Modal.Footer.Actions>
                  <Button
                    isProcessing={isLoading}
                    disabled={!isValid}
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }
                      void onSubmit(formState);
                    }}
                  >
                    Save
                  </Button>
                </Modal.Footer.Actions>
              </Modal.Footer>
            </>
          );
        }}
      ></ValidatedForm>
    </Modal>
  );
};

export default EditUserSortOrderModal;
