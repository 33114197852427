import React, { FC } from 'react';

import { default as MaterialUiSwitch } from '@material-ui/core/Switch';

import './Switch.sass';

type Props = {
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: () => void;
  id?: string;
};

const Switch: FC<Props> = ({
  isChecked,
  isDisabled = false,
  onChange,
  id,
}: Props): JSX.Element => {
  return (
    <div className="switch">
      <MaterialUiSwitch
        color="default"
        checked={isChecked}
        onChange={onChange}
        inputProps={{ 'aria-label': `switch`, id: `${id}` }}
        disabled={isDisabled}
      />
    </div>
  );
};

export default Switch;
