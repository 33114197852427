export const VALID_EMAIL_REGEX = /^[A-Z0-9.'_%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const COMMA_SEPARATED_EMAIL_LIST =
  /^([a-z][a-z0-9.'_%+-]+@([a-z0-9-]+\.)+[a-z]{2,6}(, ?)*)+$/i;

// taken from https://stackoverflow.com/questions/5066329/regex-for-valid-international-mobile-phone-number
/* eslint-disable-next-line */
export const VALID_PHONE_NUMBER_REGEX_STRICT = /^[\+]{0,1}[1-9]{1}[0-9]{3,14}$/; // strict - for phone numbers input using country code validator
export const VALID_PHONE_NUMBER_REGEX = /^[0-9+() -]{3,14}$/; // less strict - for manually entered or imported phone numbers
export const EMPTY_STRING_REGEX = /^\s*$/;

const MATCH_ALL_EMOJI =
  '(\\u00a9|\\u00ae|[\\u2000-\\u3300]|\\ud83c[\\ud000-\\udfff]|\\ud83d[\\ud000-\\udfff]|\\ud83e[\\ud000-\\udfff])';

export const VALID_TAG_CHARACTERS_REGEX = new RegExp(
  `^(${MATCH_ALL_EMOJI}|[\\w -])*$`
);

// Taken from update 2 here: https://stackoverflow.com/a/32045167/4815046
export const VALID_ISO_8601_DURATION_REGEX = new RegExp(
  '^P(?!$)(\\d+(?:\\.\\d+)?Y)?(\\d+(?:\\.\\d+)?M)?(\\d+(?:\\.\\d+)?W)?(\\d+(?:\\.\\d+)?D)?(T(?=\\d)(\\d+(?:\\.\\d+)?H)?(\\d+(?:\\.\\d+)?M)?(\\d+(?:\\.\\d+)?S)?)?$'
);

export const VALID_CLIENT_FACING_DISCOUNT_CODE = /^[a-zA-Z0-9]+$/;

export const VALID_FILENAME = /^[\w\-. ]+$/;

export const VALID_HEX_COLOUR = /^#([a-f0-9]{6}|[a-f0-9]{3})$/i;
