import { Reducer } from 'redux';

import { ActionType, State } from './dealTemplateTypes';

export const initialState: State = {
  dealTemplates: [],
  dealTemplateItems: [],

  isGettingDealTemplates: false,
  isGettingDealTemplateItems: false,
  isPersistingDealTemplate: false,
  isApplyingDealTemplate: false,
};

const dealTemplateReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_DEAL_TEMPLATE_ITEMS: {
      return {
        ...state,
        dealTemplateItems: [],
        isGettingDealTemplateItems: true,
      };
    }
    case ActionType.GET_DEAL_TEMPLATE_ITEMS_SUCCESS: {
      return {
        ...state,
        dealTemplateItems: [...action.payload],
        isGettingDealTemplateItems: false,
      };
    }
    case ActionType.GET_DEAL_TEMPLATE_ITEMS_FAILURE: {
      return {
        ...state,
        isGettingDealTemplateItems: false,
      };
    }

    case ActionType.REQUEST_GET_DEAL_TEMPLATES: {
      return {
        ...state,
        dealTemplates: [],
        isGettingDealTemplates: true,
      };
    }
    case ActionType.GET_DEAL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        dealTemplates: [...action.payload],
        isGettingDealTemplates: false,
      };
    }
    case ActionType.GET_DEAL_TEMPLATES_FAILURE: {
      return {
        ...state,
        isGettingDealTemplates: true,
      };
    }

    case ActionType.REQUEST_PERSIST_DEAL_TEMPLATE: {
      return {
        ...state,
        isPersistingDealTemplate: true,
      };
    }
    case ActionType.PERSIST_DEAL_TEMPLATE_FAILURE:
    case ActionType.PERSIST_DEAL_TEMPLATE_SUCCESS:
      return {
        ...state,
        isPersistingDealTemplate: false,
      };

    case ActionType.REQUEST_APPLY_DEAL_TEMPLATE: {
      return {
        ...state,
        isApplyingDealTemplate: true,
      };
    }
    case ActionType.APPLY_DEAL_TEMPLATE_SUCCESS:
    case ActionType.APPLY_DEAL_TEMPLATE_FAILURE:
      return {
        ...state,
        isApplyingDealTemplate: false,
      };

    case ActionType.CLEAR_DEAL_TEMPLATES: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default dealTemplateReducer;
