import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { format, getMonth } from 'date-fns';

import useGetProjectActivityFeed from '@/api/queries/project/useGetProjectActivityFeed';
import {
  IntegratedEmailProjectActivityEvent,
  ProjectActivityEvent,
} from '@/gql/graphql';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { DealActivityEvent } from '@/ui/components';
import EmptyState from '@payaca/components/plEmptyState/EmptyState';
import Conditional from '@payaca/components/conditional/Conditional';

export interface IProps {
  activityFilter: (keyof typeof activityFeedEntityTypeToEventTypeMapping)[];
}

const activityFeedEntityTypeToEventTypeMapping = {
  automation: ['automationActionRan', 'automationActionRunFailed'],
  purchaseOrder: [
    'purchaseOrderConfirmed',
    'purchaseOrderCreated',
    'purchaseOrderMarkedAsSent',
    'purchaseOrderPushToAccountingIntegrationFailed',
    'purchaseOrderSent',
    'purchaseOrderVoided',
  ],
  note: ['note'],
  jobPayment: [
    'paymentAttempted',
    'paymentCompleted',
    'paymentFailed',
    'paymentRecorded',
  ],
  job: [
    'proposalAccepted',
    'proposalCreated',
    'proposalDeclined',
    'proposalExpired',
    'proposalMarkedAsAccepted',
    'proposalMarkedAsDeclined',
    'proposalMarkedAsSent',
    'proposalResent',
    'proposalSent',
    'proposalViewed',
    'proposalVoided',
  ],
  invoice: [
    'invoiceCreated',
    'invoiceMarkedAsSent',
    'invoicePushToAccountingIntegrationFailed',
    'invoicePushedToAccountingIntegration',
    'invoiceResent',
    'invoiceSent',
    'invoiceViewed',
    'invoiceVoided',
  ],
  document: ['documentSent'],
  deal: [
    'dealArchived',
    'dealAssigned',
    'dealCreated',
    'dealPipelineChanged',
    'dealPipelineStageChanged',
    'dealRestored',
    'dealUnassigned',
  ],
  changeProposal: [
    'changeProposalAccepted',
    'changeProposalCreated',
    'changeProposalDeclined',
    'changeProposalExpired',
    'changeProposalMarkedAsAccepted',
    'changeProposalMarkedAsDeclined',
    'changeProposalMarkedAsSent',
    'changeProposalResent',
    'changeProposalSent',
    'changeProposalViewed',
    'changeProposalVoided',
  ],
  communication: [
    'emailSendAttempted',
    'integratedEmailReceived',
    'integratedEmailSendAttempted',
    'smsSendAttempted',
  ],
};

const activityFeedFilterOptions: {
  label: string;
  value: keyof typeof activityFeedEntityTypeToEventTypeMapping;
}[] = [
  {
    label: 'Project Info',
    value: 'deal',
  },
  {
    label: 'Communications',
    value: 'communication',
  },
  {
    label: 'Automations',
    value: 'automation',
  },
  {
    label: 'Notes',
    value: 'note',
  },
  {
    label: 'Proposals',
    value: 'job',
  },
  {
    label: 'Change Proposals',
    value: 'changeProposal',
  },
  {
    label: 'Invoices',
    value: 'invoice',
  },
  {
    label: 'Payments',
    value: 'jobPayment',
  },
  {
    label: 'Purchase Orders',
    value: 'purchaseOrder',
  },
];

const ActivityList: FC<IProps> = (props) => {
  const { activityFilter } = props;
  const { dealId } = useParams<{ dealId: string }>();

  const loaderRef = useRef(null);

  const {
    data,
    isLoading: isLoadingActivityFeed,
    hasNextPage,
    fetchNextPage,
  } = useGetProjectActivityFeed(+dealId, {
    types: activityFilter
      .map((i) => activityFeedEntityTypeToEventTypeMapping[i])
      .flat(),
  });

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [fetchNextPage, hasNextPage, loaderRef.current]);

  const activityFeedElements = useMemo(() => {
    if (!data) {
      return [];
    }

    let previousMonthNum: number | undefined = undefined;
    const elements: (
      | {
          type: 'date';
          data: string;
        }
      | {
          type: 'activity';
          data: ProjectActivityEvent | IntegratedEmailProjectActivityEvent;
        }
    )[] = [];
    for (const page of data.pages) {
      if (!page.project.activityFeed) {
        continue;
      }

      for (const projectActivityEvent of page.project.activityFeed.items) {
        const date = new Date(projectActivityEvent.timestamp);
        const monthNum = getMonth(date);

        if (monthNum !== previousMonthNum) {
          elements.push({
            type: 'date',
            data: format(date, 'MMMM yyyy'),
          });
        }

        elements.push({
          type: 'activity',
          // @ts-expect-error
          data: projectActivityEvent,
        });

        previousMonthNum = monthNum;
      }
    }

    return elements;
  }, [data]);

  const isLoading = isLoadingActivityFeed || !data?.pages.length;

  return (
    <div className="flex flex-col gap-4">
      <Conditional condition={isLoading}>
        <div className="flex flex-col gap-4">
          <SkeletonLoader.Text className="w-1/4" />
          <SkeletonLoader.ProjectActivityTimeline />
          <SkeletonLoader.ProjectActivityTimeline />
          <SkeletonLoader.ProjectActivityTimeline />
          <SkeletonLoader.ProjectActivityTimeline />
          <SkeletonLoader.ProjectActivityTimeline />
          <SkeletonLoader.ProjectActivityTimeline />
        </div>
      </Conditional>

      <Conditional condition={activityFeedElements.length === 0 && !isLoading}>
        <EmptyState className="my-10" text="No activity found" />
      </Conditional>

      <Conditional condition={activityFeedElements.length > 0}>
        {activityFeedElements.map((el, index) => {
          switch (el.type) {
            case 'date':
              return <h4 key={index}>{el.data}</h4>;
            case 'activity':
              return (
                <DealActivityEvent
                  key={el.data.timestamp}
                  projectActivityEvent={el.data}
                  projectId={dealId}
                />
              );
          }
        })}
      </Conditional>

      <Conditional condition={hasNextPage}>
        <div ref={loaderRef}>
          <SkeletonLoader.ProjectActivityTimeline />
        </div>
      </Conditional>
    </div>
  );
};

export default ActivityList;
