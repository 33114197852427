import { useSelector } from '@/api/state';
import { useTranslation } from '@/i18n';
import SupplierHeader from '@/ui/components/supplierHeader/SupplierHeader';
import SupplierSupplierMaterials from '@/ui/components/supplierSupplierMaterials/SupplierSupplierMaterials';
import { getSupplier } from '@/utils/stateAccessors';

import { requestGetSupplier } from '@payaca/store/suppliers/suppliersActions';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

import TabbedContentPageWrapper from '../pageWrappers/tabbedContentPageWrapper/TabbedContentPageWrapper';

import './SupplierPage.sass';
import { usePermissions } from '@/hooks/usePermissions';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import SupplierPurchaseOrders from '@/ui/components/supplierPurchaseOrders/SupplierPurchaseOrders';
import { useHistory, useLocation } from 'react-router';
import QueryString from 'qs';

type Props = {
  supplierId: number;
};

type SupplierTab = 'purchase-orders' | 'materials';

const SupplierPage: FC<Props> = ({ supplierId }: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const { userHasRequiredPermission } = usePermissions();
  const location = useLocation();
  const history = useHistory();

  const { tab } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [selectedTab, setSelectedTab] = useState<SupplierTab>(
    tab == 'purchase-orders' ? 'purchase-orders' : 'materials'
  );

  useEffect(() => {
    dispatch(requestGetSupplier(supplierId));
  }, [supplierId]);

  const showPurchaseOrdersTab = userHasRequiredPermission([
    MaterialsListPermissions.GET_PURCHASE_ORDERS,
  ]);

  const tabs = useMemo(() => {
    const t = [
      {
        label: 'materials',
        title: 'Materials',
        content: (
          <SupplierSupplierMaterials
            supplierId={supplierId}
            onSupplierUpdateSuccess={() =>
              dispatch(requestGetSupplier(supplierId))
            }
          />
        ),
      },
    ];

    if (showPurchaseOrdersTab) {
      t.push({
        label: 'purchase-orders',
        title: 'Purchase Orders',
        content: <SupplierPurchaseOrders supplierId={supplierId} />,
      });
    }

    return t;
  }, [supplierId, dispatch, location.hash, showPurchaseOrdersTab]);

  const translate = useTranslation();

  const supplier = useSelector((state) => getSupplier(state, supplierId));

  return (
    <>
      <Helmet
        title={translate('pages.supplier.title', {
          name: supplier?.name || '',
        })}
      />
      <TabbedContentPageWrapper
        className="supplier-page"
        title={
          <div className="flex-grow title-bar-inner">
            <SupplierHeader supplierId={supplierId} />
          </div>
        }
        previousPageNavigationConfig={{ route: '/suppliers' }}
        tabbedContentConfig={{
          tabs: tabs,
          activeTabLabel: selectedTab,
          onChangeTab: (label: string) => {
            history.push(`/suppliers/${supplierId}?tab=${label}`);
            setSelectedTab(label as SupplierTab);
          },
        }}
      />
    </>
  );
};

export default SupplierPage;
