import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  DeleteCustomerAddressInput,
  DeleteCustomerAddressMutation as _DeleteCustomerAddressMutation,
} from '@/gql/graphql';

const DeleteCustomerAddressMutation = graphql(`
  mutation DeleteCustomerAddress($input: DeleteCustomerAddressInput!) {
    deleteCustomerAddress(input: $input) {
      id
    }
  }
`);

const useDeleteCustomerAddress = (
  options?: UseMutationOptions<
    _DeleteCustomerAddressMutation,
    unknown,
    DeleteCustomerAddressInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(DeleteCustomerAddressMutation, {
        input,
      });
    },
    ...options,
  });
};

export default useDeleteCustomerAddress;
