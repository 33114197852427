import React, { FC } from 'react';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';

const ConnectionApplicationSkeleton: FC = () => {
  return (
    <div className="my-4 space-y-4">
      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body className="space-y-4">
          <SkeletonLoader.Title className="w-1/6" />
        </Card.Body>
      </Card>

      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body className="space-y-4">
          <SkeletonLoader.Title className="w-1/6" />
          <SkeletonLoader.Text className="w-1/2" />

          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
        </Card.Body>
      </Card>

      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body className="space-y-4">
          <SkeletonLoader.Title className="w-1/6" />
          <SkeletonLoader.Text className="w-1/2" />

          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
        </Card.Body>
      </Card>

      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body className="space-y-4">
          <SkeletonLoader.Title className="w-1/6" />
          <SkeletonLoader.Text className="w-1/2" />

          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
          <SkeletonLoader.Input />
        </Card.Body>
      </Card>
    </div>
  );
};

export default ConnectionApplicationSkeleton;
