import React, { FC } from 'react';
import { JobAttachment as JobAttachmentType } from '@payaca/types/jobTypes';
import { getFileType, ImageFileExtensions } from '@payaca/helpers/fileHelper';
import UntitledIcon from '@payaca/untitled-icons';
import { Upload } from '@payaca/types/uploadTypes';
import { Nullish } from '@payaca/utilities/types';

export interface IProps {
  jobAttachments: (JobAttachmentType | Upload)[];
}

const JobDocumentAttachmentTable: FC<IProps> = (props) => {
  const { jobAttachments } = props;

  return (
    <div>
      <div className="grid grid-cols-[58px_1fr] items-center gap-3 py-2">
        <div className="col-start-2 text-base font-semibold">File Name</div>
      </div>

      {jobAttachments.map((file) => {
        let fileExtension: Nullish<string>;
        let url = '';
        if (
          !('fileExtension' in file && file.fileExtension) &&
          'attachmentUrl' in file
        ) {
          fileExtension = getFileType(file.attachmentUrl);
          url = file.attachmentUrl;
        } else if ('fileExtension' in file) {
          fileExtension = getFileType(file.url);
          url = file.url;
        }

        const isImage = !!ImageFileExtensions.find((t) => t === fileExtension);
        const downloadName = fileExtension
          ? file.fileName.replace(/\.(\w+)$/, '') + fileExtension
          : file.fileName;

        return (
          <div
            key={file.id}
            className="grid grid-cols-[58px_1fr] items-center gap-3 border-b-2 py-2 last:border-0"
          >
            <a
              className="relative h-12 w-12 cursor-pointer justify-self-center overflow-hidden rounded-lg border-0 bg-transparent p-0"
              href={url}
              download={downloadName}
              target="_blank"
              rel="noopener noreferrer"
            >
              {isImage ? (
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src={url}
                  alt={`attachment ${file.fileName}`}
                ></img>
              ) : (
                <UntitledIcon
                  className="text-pc-blue-darker p-1"
                  name="file-attachment-03.3"
                />
              )}
            </a>

            <div>
              <a
                className="text-pc-blue-mid cursor-pointer break-all border-0 bg-transparent p-0 text-base font-normal underline print:hidden"
                href={url}
                download={downloadName}
                target="_blank"
                rel="noopener noreferrer"
              >
                {downloadName}
              </a>

              <a className="hidden print:block" href={url}>
                {downloadName}
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default JobDocumentAttachmentTable;
