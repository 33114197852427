import { useMemo } from 'react';
import { v4 } from 'uuid';

export const useFieldId = (name: string): string => {
  const fieldId = useMemo(() => {
    const uuid = v4();
    return `${name}-${uuid}`;
  }, [name]);
  return fieldId;
};
