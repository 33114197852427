import { Reducer } from 'redux';

import { mergeFetchedEntities } from '../utils';

import { ActionType, State } from './uploadsTypes';

export const initialState: State = {
  isPersistingUpload: false,
  isDeletingUpload: false,

  uploads: {},
};

const uploadsReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_UPLOAD: {
      const fetchedEntity =
        state.uploads && state.uploads[action.payload.uploadId];

      return {
        ...state,
        uploads: {
          ...state.uploads,
          [action.payload.uploadId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_UPLOAD_FAILURE: {
      const fetchedEntity =
        state.uploads && state.uploads[action.payload.uploadId];

      return {
        ...state,
        uploads: {
          ...state.uploads,
          [action.payload.uploadId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.REQUEST_PERSIST_UPLOAD:
    case ActionType.REQUEST_PERSIST_UPLOAD_AND_LINK_TO_ENTITY:
      return {
        ...state,
        isPersistingUpload: true,
      };
    case ActionType.PERSIST_UPLOAD_FAILURE:
    case ActionType.PERSIST_UPLOAD_SUCCESS:
    case ActionType.PERSIST_UPLOAD_AND_LINK_TO_ENTITY_FAILURE:
    case ActionType.PERSIST_UPLOAD_AND_LINK_TO_ENTITY_SUCCESS:
      return {
        ...state,
        isPersistingUpload: false,
      };

    case ActionType.REQUEST_DELETE_UPLOAD:
      return {
        ...state,
        isDeletingUpload: true,
      };
    case ActionType.DELETE_UPLOAD_FAILURE:
    case ActionType.DELETE_UPLOAD_SUCCESS: {
      const newState = { ...state };
      delete newState.uploads[action.payload.uploadId];
      return {
        ...newState,
        isDeletingUpload: false,
      };
    }
    case ActionType.CLEAR_UPLOADS:
      return {
        ...state,
        uploads: {},
      };
    case ActionType.GET_UPLOAD_SUCCESS: {
      const fetchedEntity =
        state.uploads && state.uploads[action.payload.uploadId];

      return {
        ...state,
        uploads: {
          ...state.uploads,
          [action.payload.uploadId]: {
            ...fetchedEntity,
            entity: action.payload.upload,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.GET_UPLOADS_FOR_ENTITY_SUCCESS:
      return {
        ...state,
        uploads: mergeFetchedEntities({
          cache: state.uploads,
          values: action.payload,
        }),
      };
    case ActionType.GET_UPLOADS_SUCCESS:
      return {
        ...state,
        uploads: mergeFetchedEntities({
          cache: state.uploads,
          values: action.payload.uploads,
        }),
      };

    default:
      return state;
  }
};

export default uploadsReducer;
