import React, { Component, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import qs from 'qs';

import { actions as appActions } from '@/api/app';
import { actions as userActions } from '@/api/users';

import './EnableCustomerPaymentsPage.sass';
import BasicPageWrapper from '../pageWrappers/basicPageWrapper/BasicPageWrapper';
import { useHistory, useLocation } from 'react-router';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';

const EnableCustomerPaymentsPage: React.FunctionComponent =
  (): JSX.Element | null => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isProcessing, setIsProcessing] = useState(true);

    const [hasConnectionError, setHasConnectionError] = useState(false);

    const query = useMemo(() => {
      return qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
    }, [location]);

    useEffect(() => {
      if (query.code) {
        dispatch(
          userActions.connectWithStripe(query.code, (error: any) => {
            if (!error) {
              history.push(
                '/connections?isStripeConnectionSuccessRedirect=true'
              );
            } else {
              setIsProcessing(false);
              setHasConnectionError(true);
            }
          })
        );
      }
    }, [query.code]);

    useEffect(() => {
      if (query.error === 'access_denied') {
        // The user clicked 'return to Payaca' instead of proceeding with their stripe connection.
        // Do nothing, redirect to connections page.
        history.push('/connections');
      } else if (query.error) {
        setHasConnectionError(true);
        setIsProcessing(false);
      }
    }, [query.error]);

    return (
      <BasicPageWrapper className={'enable-customer-payments-page'}>
        {isProcessing && <MiniLoader />}
        {!isProcessing && (
          <>
            {hasConnectionError && (
              <h3>Something went wrong setting up your stripe connection</h3>
            )}
            <Button
              onClick={() => history.push('/connections')}
              styleVariant={ButtonStyleVariant.OUTSIZE}
            >
              Go to connections page
            </Button>
          </>
        )}
      </BasicPageWrapper>
    );
  };

export default EnableCustomerPaymentsPage;
