import React, { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';

import PersonalInformationForm from '@/ui/components/personalInformationForm/PersonalInformationForm';
import TabbedContentPageWrapper from '../pageWrappers/tabbedContentPageWrapper/TabbedContentPageWrapper';
import { userHasAllRequiredPermissions } from '@payaca/permissions/permissions.utils';
import { useSelector } from '@/api/state';
import { getUserRoles } from '@/utils/stateAccessors';
import { UserPermissions } from '@payaca/permissions/user/user.permissions';
import { IntegratedEmailsPermissions } from '@payaca/permissions/integrated-emails/integrated-emails.permissions';
import PersonalConnections from './personalConnections/PersonalConnections';

type TabDescriptor = React.ComponentProps<
  typeof TabbedContentPageWrapper
>['tabbedContentConfig']['tabs'][number] & {
  urlSlug: string;
};
const PersonalSettingsPage: FC = (): JSX.Element => {
  const userRoles = useSelector(getUserRoles);

  const tabs: Array<TabDescriptor> = useMemo(() => {
    const t = [
      {
        title: 'Personal information',
        label: 'Personal information',
        content: <PersonalInformationForm />,
        urlSlug: 'personal-information',
      },
    ];
    if (
      userHasAllRequiredPermissions(userRoles, [
        UserPermissions.ADD_CONNECTION,
        IntegratedEmailsPermissions.SEND_EMAIL,
      ])
    ) {
      t.push({
        title: 'Connections',
        // TODO: show a warning triangle if any connections have expired
        label: 'Connections',
        content: <PersonalConnections />,
        urlSlug: 'connections',
      });
    }
    return t;
  }, [userRoles]);

  const { tab: activeTabRouteParam } = useParams<{
    tab?: string;
  }>();
  const history = useHistory();

  useEffect(() => {
    if (
      !activeTabRouteParam ||
      !tabs.find((t) => t.urlSlug === activeTabRouteParam)
    ) {
      history.replace(`/personalSettings/personal-information`);
    }
  }, [activeTabRouteParam]);

  const activeTabLabel = tabs.find((tab) => tab.urlSlug === activeTabRouteParam)
    ?.label;

  const handleChangeTab = (newActiveTabLabel: string) => {
    const urlSlug = tabs.find((tab) => tab.label === newActiveTabLabel)
      ?.urlSlug;
    if (urlSlug) {
      history.push(`/personalSettings/${urlSlug}`);
    }
  };

  return (
    <TabbedContentPageWrapper
      title="Profile"
      tabbedContentConfig={{
        tabs: tabs,
        onChangeTab: handleChangeTab,
        activeTabLabel: activeTabLabel,
      }}
    />
  );
};

export default PersonalSettingsPage;
