import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TickSquare } from 'react-iconly';

import * as fileDownloadActions from '@payaca/store/fileDownload/fileDownloadActions';
import * as dealActions from '@payaca/store/deals/dealsActions';
import * as customerActions from '@payaca/store/customer/customerActions';

import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';

import { FileDownloadTypes } from '@payaca/types/fileDownloadTypes';

import { getCustomer, getDeal } from '@/utils/stateAccessors';
import { downloadPdfData } from '@payaca/utilities/fileUtilities';
import { SendSimpleCustomEmailControl } from '@/ui/components/sendSimpleCustomEmailControl/SendSimpleCustomEmailControl';
import * as documentActions from '@payaca/store/documents/documentActions';
import { useSelector } from '@/api/state';

interface Props {
  isFromMobileApp: boolean;
  dealId: number;
  documentData: {
    documentId: number;
    title: string;
  };
}

export const FormCompleteActions: FC<Props> = ({
  isFromMobileApp,
  dealId,
  documentData: { documentId, title },
}) => {
  const dispatch = useDispatch();
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const deal = useSelector((state) => {
    return dealId ? getDeal(state, +dealId) : null;
  });
  const customer = useSelector((state) =>
    deal?.customerId ? getCustomer(state, deal?.customerId) : undefined
  );
  const isSendingDocument = useSelector(
    (state) => state.documents.isSendingDocument
  );

  useEffect(() => {
    if (!dealId) return;
    dispatch(dealActions.requestGetDeal(+dealId));
  }, [dealId]);
  useEffect(() => {
    // @ts-ignore
    dispatch(customerActions.requestGetAndSetCurrentCustomer(deal?.customerId));
  }, [deal]);

  const triggerFileDownload = useCallback(
    (fileContent: string) => {
      const fileName = title || 'Document';
      downloadPdfData(fileContent, fileName);
      setIsDownloadingPdf(false);
    },
    [title]
  );

  const handleDownloadForm = useCallback(() => {
    setIsDownloadingPdf(true);
    dispatch(
      fileDownloadActions.requestDownloadFile(
        documentId,
        FileDownloadTypes.DOCUMENT,
        triggerFileDownload
      )
    );
  }, [dispatch, documentId]);

  const handleSend = useCallback(
    ({
      sendToEmail,
      emailBody,
      sendACopy,
    }: {
      sendToEmail: string;
      emailBody: string;
      sendACopy: boolean;
    }) => {
      dispatch(
        documentActions.requestSendDocument(
          documentId,
          sendToEmail,
          emailBody,
          sendACopy,
          () => {
            setIsEmailSent(true);
          }
        )
      );
    },
    []
  );

  return (
    <div className={'form-external-complete-message'}>
      <TickSquare />
      {isFromMobileApp ? (
        <>
          <p>Document created!</p>
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={handleDownloadForm}
            isProcessing={isDownloadingPdf}
            isDisabled={isDownloadingPdf}
          >
            Download PDF
          </Button>
          {isEmailSent ? (
            <h4>A PDF copy of the document has been successfully sent.</h4>
          ) : (
            <SendSimpleCustomEmailControl
              customer={customer}
              onSend={handleSend}
              isSending={isSendingDocument}
            />
          )}
          <Button
            colourVariant={ButtonColourVariant.PRIMARY}
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={() => {
              // @ts-ignore
              window.location = 'Payaca://';
            }}
          >
            Return to App
          </Button>
        </>
      ) : (
        <>
          <p>Thanks for your time!</p>
          <p>This form is now complete.</p>
          <p>You can close this tab.</p>
        </>
      )}
    </div>
  );
};
