import { useQuery } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import lineItemKeys from './keyFactory';
import { GetLineItemsInput, PaginationInput } from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';

const GetLineItems = graphql(`
  query LineItems($pagination: PaginationInput!, $input: GetLineItemsInput) {
    lineItems(pagination: $pagination, input: $input) {
      items {
        id
        name
        description
        price {
          taxRate {
            id
          }
          unitPrice {
            value
            currency {
              id
              code
              exponent
            }
          }
          unitPriceExcTax {
            value
            currency {
              id
              code
              exponent
            }
          }
        }
        thumbnailUrl
      }
      limit
      offset
      totalCount
    }
  }
`);

const useGetLineItems = (
  pagination?: PaginationInput,
  input?: GetLineItemsInput
) => {
  return useQuery({
    queryKey: lineItemKeys.lineItems(pagination, input),
    queryFn: () => {
      return gqlClient.request(GetLineItems, {
        pagination: pagination || PAGINATION_ARG_DEFAULTS,
        input: input,
      });
    },
  });
};

export default useGetLineItems;
