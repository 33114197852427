import { CustomerAddress } from '@payaca/types/customerTypes';
import { DealAddress } from '@payaca/types/dealTypes';
import {
  Address,
  LocationOption,
  LocationOptionType,
} from '@payaca/types/locationTypes';

export const getAddressAsString = (
  address: Partial<Pick<Address, 'line1' | 'line2' | 'city' | 'postcode'>>
) => {
  if (!address) return;

  const addressElements = [];
  addressElements.push(address.line1);
  addressElements.push(address.line2);
  addressElements.push(address.city);
  addressElements.push(address.postcode);

  return addressElements.filter(Boolean).join(', ');
};

export const getLocationOptionsForDeal = (
  siteAddresses: DealAddress[],
  customerAddresses: CustomerAddress[]
): LocationOption[] => {
  const options = [];
  if (siteAddresses?.length) {
    options.push(
      ...siteAddresses.map((x) => ({
        id: x.id,
        address: x.address,
        contacts: x.contacts,
        type: LocationOptionType.SITE,
      }))
    );
  }
  if (customerAddresses?.length) {
    options.push(
      ...customerAddresses.map((x: CustomerAddress) => ({
        ...x,
        type: LocationOptionType.CUSTOMER,
      }))
    );
  }
  return options;
};

export const loqateAddressToAddress = (
  loqateAddress: any
): Omit<Address, 'id' | 'latitude' | 'longitude'> => ({
  line1:
    [
      loqateAddress.company,
      loqateAddress.flatNumber,
      loqateAddress.houseNumber,
      loqateAddress.houseName,
    ]
      .filter(Boolean)
      .join(', ') ?? null,
  line2:
    [loqateAddress.street1, loqateAddress.street2, loqateAddress.district]
      .filter(Boolean)
      .join(', ') ?? null,
  city: loqateAddress.postTown ?? null,
  postcode: loqateAddress.postCode ?? null,
  country: loqateAddress.countryName ?? null,
});
