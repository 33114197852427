import React, { FC, useEffect, useRef, useState } from 'react';

import BasicField from '@payaca/components/basicField/BasicField';
import { InputStyleVariant, InputWidth } from '@payaca/components/inputWrapper/InputWrapper';
import { Search } from 'react-iconly';
import { usePrevious } from '@payaca/hooks/usePrevious';

type Props = {
  styleVariant?: InputStyleVariant;
  initSearchTerm?: string;
  onSearchTermChange: (searchTerm?: string) => void;
  placeholder?: string;
  showSearchIcon?: boolean;
  inputWidthVariant?: InputWidth;
};
const SearchField: FC<Props> = ({
  styleVariant,
  initSearchTerm = '',
  placeholder,
  onSearchTermChange,
  showSearchIcon = true,
  inputWidthVariant,
}: Props): JSX.Element => {
  const searchTermRef = useRef<string>(initSearchTerm);
  const [searchTerm, setSearchTerm] = useState(initSearchTerm);
  const prevInitSearchTerm = usePrevious(initSearchTerm);

  useEffect(() => {
    if (prevInitSearchTerm !== initSearchTerm) {
      setSearchTerm(initSearchTerm);
    }
  }, [initSearchTerm, prevInitSearchTerm]);

  return (
    <div className="search-term-field-container flex-grow">
      <BasicField
        inputWidth={inputWidthVariant}
        styleVariant={styleVariant}
        name="searchTerm"
        value={searchTerm}
        onChange={(value: { [key: string]: any }) => {
          searchTermRef.current = value.searchTerm;
          setSearchTerm(value.searchTerm);
        }}
        iconBefore={
          showSearchIcon ? <Search set="light" size={18} /> : undefined
        }
        onChangeTimeout={() => {
          onSearchTermChange(searchTermRef.current);
        }}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchField;
