import React, { FC, useCallback, useEffect, useState } from 'react';

import { Contact } from '@payaca/types/contactTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Field, {
  FieldValidationState,
  ValidationMessages,
} from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import UntitledIcon from '@payaca/untitled-icons';

type Props = {
  validationState?: { [fieldName: string]: FieldValidationResult };
  onChange: (value: Record<string, any> | Record<string, any>[]) => void;
  accessContacts: Partial<Contact>[];
  onTouch?: (fieldName: string) => void;
  touchedState?: { [fieldName: string]: boolean };
};
const ContactGroupControl: FC<Props> = ({
  validationState,
  onChange,
  accessContacts,
  onTouch,
  touchedState,
}: Props): JSX.Element => {
  const [editMode, setEditMode] = useState<number | null>(null);

  const onContactAdd = useCallback(() => {
    onChange([...(accessContacts ?? []), {} as Contact]);
    setEditMode(accessContacts?.length ?? 0);
  }, [onChange, accessContacts]);

  const onContactRemove = useCallback(
    (contactIndex: number) => {
      onChange(accessContacts.filter((c, i) => i !== contactIndex));
    },
    [onChange, accessContacts]
  );

  return (
    <div className="flex w-full flex-col items-start gap-4">
      {accessContacts &&
        accessContacts.map((contact, index) => {
          const vs = validationState
            ? Object.entries(validationState).reduce(
                (acc: Record<string, FieldValidationResult>, [key, value]) => {
                  if (key.startsWith(`[${index}].`)) {
                    acc[key.replace(`[${index}].`, '')] = value;
                  }
                  return acc;
                },
                {}
              )
            : {};

          return (
            <div
              className="flex w-full flex-col gap-2 rounded-lg border border-solid border-gray-200 p-4"
              key={index}
            >
              {editMode !== index && (
                <div>
                  {/* delete */}
                  <div className="flex flex-row items-stretch gap-2">
                    <div className="grow">
                      <ContactInfo contact={contact} />
                    </div>
                    <div className="flex flex-col items-end justify-between gap-2">
                      <Button
                        variant={EBtnVariant.LinkInline}
                        onClick={() => onContactRemove(index)}
                      >
                        Remove
                      </Button>
                      <Button
                        onClick={() => setEditMode(index)}
                        variant={EBtnVariant.White}
                        size={EBtnSize.XSmall}
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                  {/* edit */}
                  <ValidationMessages
                    validationState={Object.entries(vs).reduce(
                      (acc: FieldValidationState | undefined, [key, value]) => {
                        if (value.isValid === false) {
                          if (!acc) {
                            acc = {
                              isValid: false,
                              validationMessages: [],
                            };
                          }
                          if (value.errors)
                            acc.validationMessages?.push(...value.errors);

                          return acc;
                        }

                        return undefined;
                      },
                      undefined
                    )}
                  />
                </div>
              )}
              {editMode === index && (
                <div className="flex flex-col gap-4">
                  <ContactFieldset
                    contact={contact}
                    onChange={(value) => {
                      const change: Record<string, any> = {};

                      Object.entries(value).forEach(([key, value]) => {
                        change[`[${index}].${key}`] = value;
                      });

                      onChange(change);
                    }}
                    validationState={vs}
                    onTouch={(fieldName) => {
                      onTouch?.(`[${index}].${fieldName}`);
                    }}
                    touchedState={
                      touchedState
                        ? Object.entries(touchedState).reduce(
                            (acc: Record<string, boolean>, [key, value]) => {
                              if (key.startsWith(`[${index}].`)) {
                                acc[key.replace(`[${index}].`, '')] = value;
                              }
                              return acc;
                            },
                            {}
                          )
                        : undefined
                    }
                  />
                  <div className="flex items-baseline gap-4">
                    <Button
                      onClick={() => setEditMode(null)}
                      variant={EBtnVariant.White}
                      size={EBtnSize.XSmall}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        setEditMode(null);
                        !contact.id && onContactRemove(index);
                      }}
                      variant={EBtnVariant.LinkInline}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      <Button onClick={onContactAdd} variant={EBtnVariant.LinkInline}>
        Add a contact
      </Button>
    </div>
  );
};

export default ContactGroupControl;

const ContactInfo: FC<{ contact: Partial<Contact> }> = ({ contact }) => {
  return (
    <div className="text-base">
      {!!contact.name?.length && (
        <p className="mb-4 text-base font-medium">{contact.name}</p>
      )}
      <div className="text-gray-500">
        {!!contact.emailAddress?.length && (
          <p className="mb-1 text-base">{contact.emailAddress}</p>
        )}
        {!!contact.telephoneNumber?.length && (
          <p className="mb-0 text-base">{contact.telephoneNumber}</p>
        )}
      </div>
      {!!contact.description?.length && (
        <p className="mb-0 mt-4 text-base text-gray-500">
          {contact.description}
        </p>
      )}
    </div>
  );
};

export const ContactFieldset: FC<{
  contact: Partial<Contact>;
  onChange: (value: Partial<Contact>) => void;
  validationState?: { [fieldName: string]: FieldValidationResult };

  onTouch?: (fieldName: string) => void;
  touchedState?: { [fieldName: string]: boolean };
}> = ({ contact, onChange, validationState, onTouch, touchedState }) => {
  return (
    <fieldset className="flex flex-col gap-2 border-0 p-0">
      <Field
        name="name"
        validationState={
          validationState?.['name']?.isValid === false && touchedState?.['name']
            ? {
                isValid: false,
                validationMessages: validationState?.['name']?.errors,
              }
            : undefined
        }
      >
        <Field.Label>Name</Field.Label>
        <Input
          value={contact.name}
          onChange={(value) => {
            onChange({ name: value });
          }}
          onBlur={() => onTouch?.('name')}
        />
      </Field>
      <Field
        name="emailAddress"
        validationState={
          validationState?.['emailAddress']?.isValid === false &&
          touchedState?.['emailAddress']
            ? {
                isValid: false,
                validationMessages: validationState?.['emailAddress']?.errors,
              }
            : undefined
        }
      >
        <Field.Label>Email</Field.Label>
        <Input
          value={contact.emailAddress}
          onChange={(value) => {
            onChange({ emailAddress: value });
          }}
          onBlur={() => onTouch?.('emailAddress')}
        />
      </Field>
      <Field
        name="telephoneNumber"
        validationState={
          validationState?.['telephoneNumber']?.isValid === false &&
          touchedState?.['telephoneNumber']
            ? {
                isValid: false,
                validationMessages:
                  validationState?.['telephoneNumber']?.errors,
              }
            : undefined
        }
      >
        <Field.Label>Telephone</Field.Label>
        <Input
          value={contact.telephoneNumber}
          onChange={(value) => onChange({ telephoneNumber: value })}
          onBlur={() => onTouch?.('telephoneNumber')}
        />
      </Field>
      <Field
        name="description"
        validationState={
          validationState?.['description']?.isValid === false &&
          touchedState?.['description']
            ? {
                isValid: false,
                validationMessages: validationState?.['description']?.errors,
              }
            : undefined
        }
      >
        <Field.Label>Description</Field.Label>
        <Input
          value={contact.description}
          onChange={(value) => onChange({ description: value })}
          onBlur={() => onTouch?.('description')}
        />
      </Field>
    </fieldset>
  );
};
