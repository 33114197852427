import { takeEvery, call, put, race, take, delay } from 'redux-saga/effects';

import { Action, PayloadAction } from 'typesafe-actions';

import { SagaConfig, ActionType, UpdateProfileRequestData } from './userTypes';
import {
  agreeToTermsAndPrivacyVersionFailure,
  agreeToTermsAndPrivacyVersionSuccess,
  getTermsAndPrivacyUpdatesFailure,
  getTermsAndPrivacyUpdatesSuccess,
  makePrimaryUserFailure,
  makePrimaryUserSuccess,
  updatePasswordSuccess,
  updatePasswordFailure,
  getAccountTermsFailure,
  getAccountTermsSuccess,
  updateProfileFailure,
  updateProfileSuccess,
  setCostPerHour,
} from './userActions';

import { Req } from '@payaca/helpers/storeHelper';

import * as authActions from '../auth/authActions';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { handleAsyncAction } from '../utils';

const userSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleMakePrimaryUser(
    action: PayloadAction<
      ActionType.REQUEST_MAKE_PRIMARY_USER,
      {
        userId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(makePrimaryUser, action.payload.userId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          makePrimaryUserFailure(new Error('Make primary user timed out.'))
        );
      } else {
        yield put(makePrimaryUserSuccess());
      }
    } catch (error: any) {
      yield put(makePrimaryUserFailure(error as Error));
    }
  }

  const makePrimaryUser = async (userId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/user/${userId}/make_primary`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `Make primary user failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleRequestTermsAndPrivacyUpdates(
    action: Action<ActionType.REQUEST_GET_TERMS_AND_PRIVACY_UPDATES>
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getTermsAndPrivacyUpdates),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getTermsAndPrivacyUpdatesFailure(
            new Error('Make primary user timed out.')
          )
        );
      } else {
        yield put(getTermsAndPrivacyUpdatesSuccess(response));
      }
    } catch (error: any) {
      yield put(getTermsAndPrivacyUpdatesFailure(error as Error as Error));
    }
  }

  const getTermsAndPrivacyUpdates = async () => {
    const response = await req.get('/users/terms_and_privacy_updates');
    return await response.json();
  };

  function* handleRequestAgreeToTermsAndPrivacyUpdates(
    action: PayloadAction<
      ActionType.REQUEST_AGREE_TO_TERMS_AND_PRIVACY_VERSION,
      {
        termsAndPrivacyVersion: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(
          agreeToTermsAndPrivacyVersion,
          action.payload.termsAndPrivacyVersion
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          agreeToTermsAndPrivacyVersionFailure(
            new Error('Make primary user timed out.')
          )
        );
      } else {
        yield put(agreeToTermsAndPrivacyVersionSuccess());
      }
    } catch (error: any) {
      yield put(agreeToTermsAndPrivacyVersionFailure(error as Error));
    }
  }

  const agreeToTermsAndPrivacyVersion = async (
    termsAndPrivacyVersion: number
  ) => {
    const response = await req.post(
      `/users/terms_and_privacy_updates/${termsAndPrivacyVersion}`,
      {}
    );
    return await response.json();
  };

  function* handleUpdatePassword(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_PASSWORD,
      {
        payload: {
          email: string;
          currentPassword: string;
          newPassword: string;
        };
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(updatePassword, action.payload.payload),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Update password timed out.';
        yield put(updatePasswordFailure(new Error(errorMessage), errorMessage));
      } else {
        yield put(updatePasswordSuccess());
        yield put(
          authActions.storeTokens(response.token, response.refreshToken)
        );
        yield take('auth.storeTokensSuccess');
      }
    } catch (error: any) {
      yield put(updatePasswordFailure(error, error.message));
    }
  }

  const updatePassword = async ({
    email,
    currentPassword,
    newPassword,
  }: {
    email: string;
    currentPassword: string;
    newPassword: string;
  }) => {
    const response = await req.put('/users/me/password', {
      email,
      password: currentPassword,
      newPassword: newPassword,
    });
    return await response.json();
  };

  function* handleGetAccountTerms(
    action: PayloadAction<
      ActionType.REQUEST_GET_ACCOUNT_TERMS,
      {
        accountId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getAccountTerms, action.payload.accountId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get account terms timed out.';
        yield put(
          getAccountTermsFailure(new Error(errorMessage), errorMessage)
        );
      } else {
        yield put(getAccountTermsSuccess(response));
        action.payload?.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(getAccountTermsFailure(error, error.message));
    }
  }

  const getAccountTerms = async (accountId: number) => {
    const response = await req.get(`/accounts/${accountId}/terms/`);
    return await response.json();
  };

  function* handleUpdateProfile(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_PROFILE,
      {
        profileUpdates: UpdateProfileRequestData;
        callback: (error: any, response?: any) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(updateProfile, action.payload.profileUpdates),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Update profile request timed out.';
        yield put(updateProfileFailure(new Error(errorMessage), errorMessage));
      } else {
        yield put(updateProfileSuccess(response));
        action.payload.callback(null, response);
      }
    } catch (error: any) {
      yield put(updateProfileFailure(error, error.message));
      action.payload.callback(error.message);
    }
  }
  const updateProfile = async ({
    pendingEmail,
    firstname,
    lastname,
    contactNumber,
    gasSafeIdCardNumber,
    userSignature,
  }: UpdateProfileRequestData) => {
    const response = await req.put('/users/me', {
      pendingEmail,
      firstname,
      lastname,
      contactNumber,
      gasSafeIdCardNumber,
      userSignature,
    });
    return await response.json();
  };

  const handleSetCostPerHour = handleAsyncAction(
    setCostPerHour,
    async (args) => {
      const response = await req.patch(`/users/${args.userId}/cost_per_hour`, {
        costPerHour: args.costPerHour || null,
      });
      return;
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_err, requestData) => {
      requestData.payload.onErrorCallback?.(_err);
    }
  );

  return function* () {
    yield takeEvery(
      ActionType.REQUEST_MAKE_PRIMARY_USER,
      handleMakePrimaryUser
    );
    yield takeEvery(
      ActionType.REQUEST_GET_TERMS_AND_PRIVACY_UPDATES,
      handleRequestTermsAndPrivacyUpdates
    );
    yield takeEvery(
      ActionType.REQUEST_AGREE_TO_TERMS_AND_PRIVACY_VERSION,
      handleRequestAgreeToTermsAndPrivacyUpdates
    );
    yield takeEvery(ActionType.REQUEST_UPDATE_PASSWORD, handleUpdatePassword);
    yield takeEvery(
      ActionType.REQUEST_GET_ACCOUNT_TERMS,
      handleGetAccountTerms
    );
    yield takeEvery(ActionType.REQUEST_UPDATE_PROFILE, handleUpdateProfile);
    yield takeEvery(ActionType.SET_COST_PER_HOUR_REQUEST, handleSetCostPerHour);
  };
};

export default userSagaCreator;
