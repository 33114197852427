import React, { FC, PropsWithChildren } from 'react';

type Props = {
  instructionNumber: number;
  instructionTitle: string;
};

const BulkUploadInstruction: FC<PropsWithChildren<Props>> = ({
  children,
  instructionNumber,
  instructionTitle,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <div className="upload-instruction">
      <span>
        {instructionNumber}. {instructionTitle}
      </span>
      {children}
    </div>
  );
};

export default BulkUploadInstruction;
