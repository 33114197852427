export enum LineItemsRoles {
  VIEWER = 'roles.lineItemViewer',
  BASIC_EDITOR = 'roles.lineItemBasicEditor',
  EDITOR = 'roles.lineItemEditor',
  CREATOR = 'roles.lineItemCreator',
  DELETER = 'roles.lineItemDeleter',
  COLLECTION_MANAGER = 'roles.lineItemCollectionManager',

  GROUP_VIEWER = 'roles.lineItemGroupViewer',
  GROUP_EDITOR = 'roles.lineItemGroupEditor',
  GROUP_CREATOR = 'roles.lineItemGroupCreator',

  BULK_IMPORTER = 'roles.lineItemsBulkImporter',
}
