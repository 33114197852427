import { useQuery } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import meKeys from '@/api/queries/me/keyFactory';

const GetMyCustomFieldGroups = graphql(`
  query MyCustomFieldGroups {
    me {
      user {
        account {
          customFieldGroups {
            id
            role
            customFields {
              id
              identifier
              isRequired
              label
              type
              archivedAt
              options
            }
          }
        }
      }
    }
  }
`);

const useGetMyCustomFieldGroups = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.customFieldGroups(),
    queryFn: () => {
      return gqlClient.request(GetMyCustomFieldGroups);
    },
  });

  return { data: data?.me.user.account.customFieldGroups, ...rest };
};

export default useGetMyCustomFieldGroups;
