import { action } from 'typesafe-actions';

import { ListedTaskListViewPage } from '@payaca/types/listedTaskTypes';
import { ActionType, GetListedTasksRequestData } from './listedTasksTypes';

export const requestGetListedTasksPage = (
  getListedTasksRequestData: GetListedTasksRequestData
) => {
  return action(ActionType.REQUEST_GET_LISTED_TASKS_PAGE, {
    getListedTasksRequestData,
  });
};

export const clearListedTasksPage = () => {
  return action(ActionType.CLEAR_LISTED_TASKS_PAGE);
};

export const getListedTasksPageSuccess = (
  listedTasksPage: ListedTaskListViewPage
) => {
  return action(ActionType.GET_LISTED_TASKS_PAGE_SUCCESS, {
    listedTasksPage,
  });
};

export const getListedTasksPageFailure = (error: Error) => {
  return action(ActionType.GET_LISTED_TASKS_PAGE_FAILURE, null, null, error);
};
