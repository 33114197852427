import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import './UpdateTimelogControl.sass';
import Button from '@payaca/components/button/Button';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { PublicHydratedTimelog, PublicTimelog } from '@payaca/types/timelogs';
import { updateTimelog } from '@payaca/store/timelogs/timelogsActions';
import moment from 'moment-timezone';
import PersistTimelogFields from '../persistTimelogFields/PersistTimelogFields';
import {
  getAllowEmptyValidator,
  getDateRangeFieldValidator,
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { getDurationValidator } from '@payaca/helpers/timelogValidationHelper';
import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import ValidationFeedbackBlock from '@payaca/components/validationFeedbackBlock/ValidationFeedbackBlock';

type Props = {
  timelog: PublicTimelog;
  onUpdateTimelogSuccess?: (timelog: PublicHydratedTimelog) => void;
};

const UpdateTimelogControl: FunctionComponent<Props> = ({
  timelog,
  onUpdateTimelogSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showValidation, setShowValidation] = useState(false);

  const validators = {
    startTime: [
      getIsRequiredFieldValidator(),
      getDateRangeFieldValidator(undefined, new Date()),
    ],
    duration: [getDurationValidator('startTime')],
    notes: [getAllowEmptyValidator(getLengthFieldValidator({ max: 10000 }))],
  };

  const initialFormState = useMemo(() => {
    return {
      startTime: timelog.startTime,
      notes: timelog.notes,
      costPerHour: timelog.costPerHour,
      typePublicId: timelog.typePublicId,
      duration: moment
        .duration(moment(timelog.endTime).diff(moment(timelog.startTime)))
        .toISOString(),
      assigneePublicId: timelog.assigneePublicId,
      assigneeType: 'user',
    };
  }, []);

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      setErrorMessage(undefined);
      setIsProcessing(true);
      dispatch(
        updateTimelog.request({
          timelog: {
            startTime: formState.startTime,
            endTime: moment(formState.startTime)
              .add(formState.duration)
              .toDate(),
            notes: formState.notes,
            costPerHour: formState.costPerHour,
            typePublicId: formState.typePublicId,
            assigneePublicId: formState.assigneePublicId,
            assigneeType: formState.assigneeType,
            publicId: timelog.publicId,
          },
          callback: (timelog) => {
            setIsProcessing(false);
            onUpdateTimelogSuccess?.(timelog);
          },
          onErrorCallback: (error) => {
            setIsProcessing(false);
            setErrorMessage('Something went wrong');
          },
        })
      );
    },
    [onUpdateTimelogSuccess, timelog.publicId]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <PersistTimelogFields
            touchedState={touchedState}
            onFieldChange={onFieldChange}
            onFieldTouch={onFieldTouch}
            formState={formState}
            validationState={validationState}
            persistType="update"
          />
          <div className="actions-container">
            <Button
              onClick={() => {
                isValid ? onSubmit(formState) : setShowValidation(true);
              }}
              isProcessing={isProcessing}
            >
              Submit
            </Button>
            {errorMessage && (
              <FeedbackBlock feedbackLevel={FeedbackLevel.ERROR}>
                {errorMessage}
              </FeedbackBlock>
            )}
            {showValidation && !isValid && (
              <ValidationFeedbackBlock
                validationResults={Object.values(validationState)}
              />
            )}
          </div>
        </>
      );
    },
    [isProcessing, onSubmit, errorMessage, showValidation]
  );

  return (
    <ResponsiveViewWrapper
      downBreakpointSm={700}
      downBreakpointXs={500}
      className="update-timelog-control"
    >
      <ValidatedForm<{ [key: string]: any }>
        fieldValidators={validators}
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
      />
    </ResponsiveViewWrapper>
  );
};

export default UpdateTimelogControl;
