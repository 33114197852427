import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import qs from 'qs';

import { actions as usersActions } from '@/api/users';
import * as authActions from '@payaca/store/auth/authActions';

import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';
import ResendVerificationEmailControl from '@/ui/components/resendVerificationEmailControl/ResendVerificationEmailControl';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import CreatePasswordForm from '@/ui/components/createPasswordForm/CreatePasswordForm';

import './VerifyUserPage.sass';
import { useSelector } from '@/api/state';

enum PageState {
  VALIDATING = 'validating',
  VERIFYING = 'verifying',
  CREATE_PASSWORD = 'create-password',
  VERIFY_FAILURE = 'verify-failure',
  VERIFY_EXPIRED = 'verify-expired',
  VERIFY_SUCCESS_MOBILE = 'verify-success-mobile',
}

type Props = {
  match: any;
  location: any;
  history: any;
  isAuthed?: boolean;
};
const VerifyUserPage: FunctionComponent<Props> = ({
  match,
  location,
  history,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [linkToNativeApp, setLinkToNativeApp] = useState(false);
  const [pageState, setPageState] = useState(PageState.VALIDATING);
  const [verificationError, setVerificationError] = useState<any>();

  const profile = useSelector((state: any) => state.users.myProfile);

  const isVerifyingUserToken = useSelector(
    (state) => state.auth.isVerifyingUserToken
  );

  useEffect(() => {
    const token = match.params.token;
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    setLinkToNativeApp(query.linkToNativeApp === 'true');

    // validate verification token
    dispatch(
      authActions.requestValidateVerificationToken(
        token,
        (error: any, isPasswordRequired?: boolean) => {
          if (error) {
            setVerificationError(error);
          } else {
            handleVerificationValidationSuccess(isPasswordRequired);
          }
        }
      )
    );
  }, []);

  const hasVerifyFailed = useMemo(
    () =>
      pageState === PageState.VERIFY_EXPIRED ||
      pageState === PageState.VERIFY_FAILURE,
    [pageState]
  );

  useEffect(() => {
    if (verificationError?.errors) {
      if (verificationError.errors === 'TOKEN_EXPIRED') {
        // we know the token is on a user, but expired
        setPageState(PageState.VERIFY_EXPIRED);
      } else {
        // other verification failure
        setPageState(PageState.VERIFY_FAILURE);
      }
    }
  }, [verificationError]);

  const verifyUserToken = useCallback(
    (newPassword?: string) => {
      setPageState(PageState.VERIFYING);
      const payload = {
        token: match.params.token,
        newPassword,
      };
      dispatch(
        authActions.requestVerifyUserToken(payload, (error: any) => {
          if (error) {
            setVerificationError(error);
          } else {
            // successfully verified
            dispatch(usersActions.getProfile());
          }
        })
      );
    },
    [dispatch, match?.params?.token]
  );

  const handleVerificationValidationSuccess = useCallback(
    (isPasswordRequired?: boolean) => {
      if (isPasswordRequired) {
        // password required - show create password
        setPageState(PageState.CREATE_PASSWORD);
      } else {
        // password not required (mobile) - verify straight away
        verifyUserToken();
      }
    },
    [verifyUserToken]
  );

  useEffect(() => {
    if (profile.verifiedAt) {
      // user is verified
      if (linkToNativeApp) {
        setPageState(PageState.VERIFY_SUCCESS_MOBILE);
      } else {
        history.push('/dashboard');
      }
    }
  }, [history, linkToNativeApp, profile]);

  const titleContent = useMemo(() => {
    if (hasVerifyFailed) {
      return (
        <>
          <h2>
            {pageState === PageState.VERIFY_EXPIRED
              ? 'This verification link has expired'
              : 'Invalid verification link'}
          </h2>
          <p>
            {
              "Enter your email below and we'll send you a new verification link."
            }
          </p>
        </>
      );
    } else if (pageState === PageState.CREATE_PASSWORD) {
      return (
        <>
          <h2>Create password</h2>
          <p>
            {
              'This helps keep your data safe and allows you to log in from other devices.'
            }
          </p>
        </>
      );
    } else if (pageState === PageState.VERIFY_SUCCESS_MOBILE) {
      return (
        <>
          <h2>Your account has been verified!</h2>
          <p>{'You can now log in and start using Payaca.'}</p>
        </>
      );
    }
  }, [hasVerifyFailed, pageState]);

  return (
    <UnauthenticatedPageWrapper
      className="verify-user-page"
      titleContent={titleContent}
      hideBody={
        pageState === PageState.VERIFYING || pageState === PageState.VALIDATING
      }
      footerContent={
        (pageState === PageState.VERIFYING ||
          pageState === PageState.VALIDATING) && (
          <div className="loader-container">
            <MiniLoader />
          </div>
        )
      }
    >
      {/* resend verification email */}
      {hasVerifyFailed && (
        <ResendVerificationEmailControl allowUserEmailAddressInput={true} />
      )}

      {/* create user password */}
      {pageState === PageState.CREATE_PASSWORD && (
        <>
          {/* create password form */}
          <CreatePasswordForm
            onSubmit={(newPassword: string) => verifyUserToken(newPassword)}
            isSubmitting={isVerifyingUserToken}
          />
        </>
      )}

      {/* verification success (mobile only) */}
      {pageState === PageState.VERIFY_SUCCESS_MOBILE && (
        <a href={'Payaca://'} className="mobile-app-link">
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            hasBoxShadow={true}
            colourVariant={ButtonColourVariant.PRIMARY}
          >
            Continue
          </Button>
        </a>
      )}
    </UnauthenticatedPageWrapper>
  );
};

export default VerifyUserPage;
