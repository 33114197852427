import React, { FC } from 'react';
import GroupFieldBase, { TGroupFieldBaseProps } from './GroupFieldBase';

export type { TOption } from './GroupFieldBase';

export interface IProps extends Omit<TGroupFieldBaseProps<true>, 'multiple'> {}

const CheckboxGroupField: FC<IProps> = (props) => {
  return <GroupFieldBase multiple {...props} />;
};

export default CheckboxGroupField;
