import React, { FC, useMemo } from 'react';
import moment from 'moment-timezone';

import CompanyLogo from '../companyLogo/CompanyLogo';
import OutlinedContentPanel, {
  ContentPanelPaddingVariant,
  ContentPanelTitleStyleVariant,
} from '../outlinedContentPanel/OutlinedContentPanel';
import LabelValuePair from '../labelValuePair/LabelValuePair';

import './FormTemplateElements.sass';
import './OilCommissioningReportTemplate.sass';
import { formatAnswer } from '@payaca/helpers/formHelper';
import { useCertificateNumber, useInspectionFormAddresses } from './hooks';

import OftecLogo from './oftec.png';

interface Props {
  data: any;
  account: any;
}

const applianceDetails = [
  { label: 'Make', key: 'make' },
  { label: 'Model', key: 'model' },
  { label: 'Serial Number', key: 'serial_number' },
  { label: 'Burner Make', key: 'burner_make' },
  { label: 'Burner Model ', key: 'burner_model' },
  { label: 'Burner Type ', key: 'burner_type' },
  { label: 'Tank Type', key: 'tank_type' },
  { label: 'Flue Type', key: 'flue_type' },
  { label: 'Fuel Type', key: 'fuel_type' },
  { label: 'Call Type', key: 'call_type' },
];

const testResults = [
  { label: 'Pump pressure (bar)', key: 'pump_pressure_bar' },
  { label: 'Efficiency nett (%)', key: 'efficiency_nett' },
  { label: 'Gross (%)', key: 'gross' },
  { label: 'Pump vacuum (bar)', key: 'pump_vacuum_bar' },
  { label: 'Smoke no', key: 'smoke_no' },
  { label: 'Co (ppm)', key: 'co_ppm' },
  { label: 'Excess air (%)', key: 'excess_air' },
  { label: 'Nozzle size', key: 'nozzle_size' },
  { label: 'Nozzle angle', key: 'nozzle_angle' },
  { label: 'Nozzle pattern', key: 'nozzle_pattern' },
  { label: 'Co2 (%)', key: 'co2' },
  { label: 'Flue gas temp', key: 'flue_gas_temp_c' },
  { label: 'Flow rate (oil) - Low (cc/min)', key: 'flow_rate_oil_low_cc_min' },
  {
    label: 'Flow rate (oil) - High (cc/min)',
    key: 'flow_rate_oil_high_cc_min',
  },
  { label: 'Flow rate (oil) - Cold (cc/min)', key: 'flow_rate_oil_cold_l_min' },
  { label: 'Flow rate (oil) - Hot (l/min)', key: 'flow_rate_oil_hot_l_min' },
];

const commissioningSchedule = [
  {
    id: 'oil_storage',
    label: 'Oil Storage',
  },
  {
    id: 'oil_supply_system',
    label: 'Oil supply System',
  },
  {
    id: 'air_supply',
    label: 'Air supply',
  },
  {
    id: 'chimney_flue',
    label: 'Chimney/Flue',
  },
  {
    id: 'electrical_safety',
    label: 'Electrical safety',
  },
  {
    id: 'heat_exchanger',
    label: 'Heat exchanger',
  },
  {
    id: 'combustion_chamber',
    label: 'Combustion chamber',
  },
  {
    id: 'pressure_jet_burner',
    label: 'Chimney/Pressure jet burner',
  },
  {
    id: 'vaporising_and_wallflame_burner',
    label: 'Vaporising and wallflame burner safety',
  },
  {
    id: 'wallflame_burner',
    label: 'Wallflame burner',
  },
  {
    id: 'appliance_safety_controls',
    label: 'Appliance safety controls',
  },
  {
    id: 'heating_system_service',
    label: 'Heating system service',
  },
  {
    id: 'controls_check',
    label: 'Controls check',
  },
  {
    id: 'system_check_hot_water',
    label: 'System check - Hot water type',
  },
  {
    id: 'system_check_warm_air',
    label: 'System check - Warm air type',
  },
];

const OilCommissioningReportTemplate: FC<Props> = ({ data, account }) => {
  const brandColour = useMemo(() => {
    return account?.brandColour;
  }, [account]);

  const {
    accountAddressString,
    inspectionAddressString,
    landlordAddressString,
  } = useInspectionFormAddresses(account, data);
  const certificateNumber = useCertificateNumber(data);

  if (!data || !account) return <></>;
  return (
    <div className={'form-template-output oil-commissioning-report'}>
      <div className={'form-template-header'}>
        <div className={'flex-container'}>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <h3>Oil Firing Servicing and Commissioning Report</h3>
        </div>
        <div>
          <LabelValuePair label="Cert No" value={certificateNumber} />
          {data.gas_safe_reg_number && (
            <img className="oftec-logo" src={OftecLogo} alt={'OFTEC Logo'} />
          )}
        </div>
      </div>
      <div className="form-template-row company-inspection-landlord-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          className="company-details-content-panel"
          title={'Company/Installer'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="company-details-content-panel-body">
            <LabelValuePair label="Engineer" value={data.completed_by} />
            <LabelValuePair label="Company" value={account?.companyName} />
            <LabelValuePair label="Address" value={accountAddressString} />
            <LabelValuePair label="Contact" value={account?.contactNumber} />
            {data.gas_safe_reg_number && (
              <LabelValuePair
                label="Gas Safe Reg"
                value={data.gas_safe_reg_number}
              />
            )}
            {data.oftec_reg_number && (
              <LabelValuePair label="OFTEC Reg" value={data.oftec_reg_number} />
            )}
            {data.id_card_number && (
              <LabelValuePair
                label="ID Card Number"
                value={data.id_card_number}
              />
            )}

            <LabelValuePair label="E-Mail" value={account?.email} />
          </div>
        </OutlinedContentPanel>

        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Inspection/Installation Address'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.name} />
          <LabelValuePair label="Address" value={inspectionAddressString} />
          <LabelValuePair label="E-Mail" value={data.email} />
          <LabelValuePair label="Contact" value={data.contact} />
        </OutlinedContentPanel>

        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Landlord/Agent Details'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.landlord_name} />
          <LabelValuePair label="Address" value={landlordAddressString} />
          <LabelValuePair label="E-mail" value={data.landlord_email} />
          <LabelValuePair label="Contact" value={data.landlord_contact} />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row pre-commissioning-checks no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Pre-commissioning checks/legislation'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="If completing commissioning works, is there a completed CD/10(or equivalent) for the installation works?"
            value={formatAnswer(data.if_completing_commissioning_works)}
          />
          <LabelValuePair
            label="If the installation has been completed by a Non-Competent Person, is there a building control notice?"
            value={formatAnswer(data.if_the_installation_has)}
          />
          <LabelValuePair
            label="If YES to question 2, insert building control ref no"
            value={formatAnswer(data.if_yes_to_question)}
          />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row appliance">
        <OutlinedContentPanel
          className="appliance"
          title="Appliance Details"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <>
            {applianceDetails.map((detail, index) => {
              return (
                <LabelValuePair
                  key={`appliance-detail-${index}`}
                  label={detail.label}
                  value={formatAnswer(data, detail.key)}
                />
              );
            })}
          </>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row commissioning-schedule no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Oil Firing Service and Commissioning Schedule'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="flex-container">
            <table className="inspections-table">
              <thead style={{ borderColor: brandColour }}>
                <tr>
                  <td>Schedule item</td>
                  <td>Checked</td>
                  <td>Passed</td>
                  <td>Parts Fitted/ Observations</td>
                </tr>
              </thead>
              <tbody>
                {commissioningSchedule.map((row: any, i: number) => {
                  return (
                    <tr key={i}>
                      <td>{row.label}</td>
                      <td>{formatAnswer(data, row.id).checked}</td>
                      <td>{formatAnswer(data, row.id).passed}</td>
                      <td>
                        {formatAnswer(data, row.id).parts_fitted_observations}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row test-results">
        <OutlinedContentPanel
          className="test-results"
          title="Test Results"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <>
            {testResults.map((result, index) => {
              return (
                <LabelValuePair
                  key={`test-result-${index}`}
                  label={result.label}
                  value={formatAnswer(data, result.key)}
                />
              );
            })}
          </>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row important-comments no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'Important comments'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <p>{formatAnswer(data.important_comments)}</p>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row signatures">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Completed by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.completed_by} />
          <LabelValuePair
            label="Signed"
            value={
              <div className={'form-template-signature'}>
                {data.engineer_signature && (
                  <img src={data.engineer_signature} />
                )}
              </div>
            }
          />
          <LabelValuePair
            label="Inspection conducted"
            value={moment(data.issue_date).format('DD/MM/YYYY')}
            noBreak={true}
          />
          <LabelValuePair
            label="Next Inspection Date"
            value={moment(data.next_inspection_due_date).format('DD/MM/YYYY')}
            noBreak={true}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Received by"
          className="customer-signature"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div>
            <LabelValuePair label="Name" value={data.name} />
            <LabelValuePair
              label="Signed"
              value={
                <div className={'form-template-signature'}>
                  {data.customer_signature && (
                    <img src={data.customer_signature} />
                  )}
                </div>
              }
            />
          </div>
          {data.customer_not_present && <p>Customer not present</p>}
        </OutlinedContentPanel>
      </div>
    </div>
  );
};

export default OilCommissioningReportTemplate;
