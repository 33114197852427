import { TemplateStructure } from './StandardFormTemplate';

const UnventedHotWaterCylinderCertificateTemplate: TemplateStructure = {
  header: {
    title: 'Unvented Hot Water Cylinder Certificate',
    logo: 'account.logoUrl',
    gasSafe: 'gas_safe_reg_number',
  },
  rows: [
    {
      'Business Details': {
        stripeRows: true,
        columnCount: 2,
        data: [
          { Engineer: 'completed_by' },
          { Company: 'account.companyName' },
          { Address: 'accountAddressString' },
          { Contact: 'account.contactNumber' },
          { 'Gas Safe Reg': 'gas_safe_reg_number' },
          { 'ID Card Number': 'id_card_number' },
          { 'E-mail': 'account.email' },
        ],
      },
      'Inspection Details': {
        stripeRows: true,
        data: [
          { Name: 'name' },
          { Address: 'inspectionAddressString' },
          { Email: 'email' },
          { Contact: 'contact' },
        ],
      },
      'Landlord/Agent Details': {
        stripeRows: true,
        data: [
          { Name: 'landlord_name' },
          { Address: 'landlordAddressString' },
          { 'E-mail': 'landlord_email' },
          { Contact: 'landlord_contact' },
        ],
      },
    },
    {
      'Appliance Details': {
        data: [
          { Location: 'location' },
          { Make: 'make' },
          { Model: 'model' },
          { 'Serial number': 'serial_number' },
          { 'Direct/Indirect': 'direct_indirect' },
          { Capacity: 'capacity' },
        ],
      },
      'Inspection Details': {
        data: [
          {
            'Gas boiler and/or Solar, or Immersion Heaters':
              'gas_solar_immersion',
          },
          { 'Makers Warning label attached': 'warning_label' },
          { 'Inlet water pressure (bar)': 'inlet_water_pressure' },
          { 'Fully commissioned': 'fully_commissioned' },
          { 'Flow Rate (l/min)': 'flow_rate' },
        ],
      },
      'Hot Water Storage Vessel Operating Details': {
        data: [
          { 'System operating pressure': 'system_operating_pressure' },
          {
            'Operating pressure of expansion vessel':
              'operating_pressure_expansion_vessel',
          },
          {
            'Operating pressure of expansion valve':
              'operating_pressure_expansion_valve',
          },
          {
            'Operating temperature of temperature relief valve':
              'operating_temp_relief_valve',
          },
          { 'Operating temperature': 'operating_temp' },
          {
            'Pressure of combined temperature and pressure relief valve':
              'combined_pressure_temp_relief_valve',
          },
          {
            'Maximum primary circuit pressure': 'max_primary_circuit_pressure',
          },
          {
            'Flow Temperature (indirectly heated vessel)':
              'flow_temp_indirectly_heated_vessel',
          },
        ],
      },
    },
    {
      'Discharge Pipework (D1) - relief valve to tundish': {
        data: [
          { 'Nominal size of D1': 'd1_nom_size' },
          { 'Length of D1': 'd1_length' },
          { 'Number of discharges': 'no_of_discharges' },
          { 'Size of manifold, if more than one discharge': 'manifold_size' },
          {
            'Is tundish installed within the same location as the hot water storage vessel':
              'tundish_same_location',
          },
          { 'Is the tundish visible?': 'tundish_visible' },
          {
            'Is automatic means of identifying discharge installed?':
              'automatic_discharge_installed',
          },
        ],
      },
      'Discharge Pipework (D2) - tundish to point of termination': {
        data: [
          { 'Nominal size of D2': 'nominal_size_d2' },
          { 'Pipework material': 'pipework_material' },
          {
            'Does pipework have a minimum vertical length of 300mm from tundish':
              'pipework_min_300_from_tundish',
          },
          {
            'Does the pipework fall continuously to point of termination':
              'pipework_fall_continuous',
          },
          { 'Method of termination': 'termination_method' },
          {
            'Method of termination satisfactory?':
              'termination_method_satisfactory',
          },
        ],
      },
    },
    {
      Comments: {
        data: [{ Comments: 'comments' }],
      },
      'Completed by': {
        data: [
          { Name: 'completed_by' },
          { Signed: 'engineer_signature' },
          { 'Inspection conducted': 'issue_date' },
          { 'Next inspection date': 'next_inspection_due_date' },
        ],
      },
      'Received by': {
        data: [
          { Name: 'name' },
          {
            Signed: 'customer_signature',
            'Customer not present': 'customer_not_present',
          },
        ],
      },
    },
  ],
};

export default UnventedHotWaterCylinderCertificateTemplate;
