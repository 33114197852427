export type ConnectionApplication = {
  id: string;
  publicId: string;
  accountId: string;
  status: Status;
  data: Record<string, any>;
  createdAt: Date;
  submittedAt: Date;
  dealId: number;
  createdByUserId: string;
  submittedUserId: string;
  connectionApplicationId: string;
  connectionApplicationStatus: EnaApplicationStatus;
};

export type Status = 'draft' | 'submitted' | 'complete' | 'failed' | 'pending';

// Statuses from ENA api
export type EnaApplicationStatus =
  | PendingEnaApplicationStatus
  | TerminatingEnaApplicationStatus;

type PendingEnaApplicationStatus =
  | 'Awaiting Assessment'
  | 'Scanning Cut-Out Image'
  | 'Under Assessment'
  | 'Escalated to DNO'
  | 'Commissioning Update Required'
  | 'Commissioning Submitted'
  | '(Re) Escalated to DNO'
  | '(Re) Auto-Approved';

export const PendingEnaApplicationStatuses: PendingEnaApplicationStatus[] = [
  'Awaiting Assessment',
  'Scanning Cut-Out Image',
  'Under Assessment',
  'Escalated to DNO',
  'Commissioning Update Required',
  'Commissioning Submitted',
  '(Re) Escalated to DNO',
  '(Re) Auto-Approved',
];

type TerminatingEnaApplicationStatus =
  | FailedEnaApplicationStatus
  | SuccessEnaApplicationStatus;

type FailedEnaApplicationStatus =
  | 'Image Scan Failed'
  | 'Further Information Required'
  | 'Failed'
  | 'Failed - Too Large'
  // generation app only
  | 'Failed - Invalid Cut-Out Image';

type SuccessEnaApplicationStatus =
  // generation app only
  | 'Auto-Approved'
  | 'DNO Approved'
  | 'DNO Approved with Limit'
  // generation + demand app only
  | 'Commissioning Accepted';

// TODO: ena - need an awaiting-action status?
export const FailedEnaApplicationStatuses: FailedEnaApplicationStatus[] = [
  'Image Scan Failed',
  'Further Information Required',
  'Failed',
  'Failed - Too Large',
  'Failed - Invalid Cut-Out Image',
];
export const SuccessEnaApplicationStatuses: SuccessEnaApplicationStatus[] = [
  'Auto-Approved',
  'DNO Approved',
  'DNO Approved with Limit',
  'Commissioning Accepted',
];

export const TerminatingEnaApplicationStatuses: TerminatingEnaApplicationStatus[] =
  [...FailedEnaApplicationStatuses, ...SuccessEnaApplicationStatuses];
