import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  ManageableItemsList,
  HeaderBarProps,
} from '@payaca/components/plManageableItemsList/ManageableItemsList';
import { useAccount } from '../../../utils/storeHooks';
import UserAvatar from '@payaca/components/userAvatar/UserAvatar';
import { AvatarSizeVariant } from '@payaca/components/plAvatar/Avatar';
import {
  ChecklistTask,
  FormTask,
  GetTasksInput,
  LegacyFormTask,
  ITask,
  User,
  IEntity,
  ScheduledEvent,
  Project,
  MaterialsListTask,
} from '../../../gql/graphql';
import { TTableRowAction } from '@payaca/components/plManageableItemsList/components/Table';
import { getUserRoles } from '../../../utils/stateAccessors';
import { useSelector } from '../../../api/state';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import useGetMe from '../../../api/queries/me/useGetMe';
import { requestDeleteTask } from '@payaca/store/tasks/tasksActions';
import Badge from '@payaca/components/plBadge/Badge';
import useGetMyAccountUsers from '../../../api/queries/me/useGetMyAccountUsers';
import { SortDirection } from '@payaca/types/listViewTypes';
import { FilterOption } from '@payaca/components/plManageableItemsList/components/MultiFilterSidebar';
import EditTaskModal from '../createEditTaskModal/EditTaskModal';
import ScheduledEventReadDrawer from '../scheduledEventReadDrawer/ScheduledEventReadDrawer';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import ConfirmDeleteTaskModal from '../confirmDeleteTaskModal/ConfirmDeleteTaskModal';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { CustomersPermissions } from '@payaca/permissions/customers/customers.permissions';
import UntitledIcon from '@payaca/untitled-icons';
import { differenceInDays, startOfDay } from 'date-fns';
import { BadgeColourVariant } from '@payaca/types/plBadge';

type TBaseTask = {
  id: IEntity['id'];
  name: ITask['name'];
  deadlineDate: ITask['deadline'];
  createdByUserId?: User['id'];
  events: {
    id: ScheduledEvent['id'];
    name: ScheduledEvent['name'];
  }[];
  project?: {
    id: Project['id'];
    reference: Project['reference'];
  };
  assignee?: {
    id: User['id'];
    firstName: User['firstName'];
    lastName: User['lastName'];
    email: User['email'];
    colour: User['colour'];
  };
  completionStatus: ITask['completionStatus'];
};
export type ListedTasksTChecklistTask = TBaseTask & {
  __typename: ChecklistTask['__typename'];
};
export type ListedTasksTFormTask = TBaseTask & {
  __typename: FormTask['__typename'];
};
export type ListedTasksTLegacyFormTask = TBaseTask & {
  __typename: LegacyFormTask['__typename'];
};
export type ListedTasksTMaterialsListTask = TBaseTask & {
  __typename: MaterialsListTask['__typename'];
};
export type ListedTasksTTask =
  | ListedTasksTChecklistTask
  | ListedTasksTFormTask
  | ListedTasksTLegacyFormTask
  | ListedTasksTMaterialsListTask;

const CompletionStatusMap: {
  [key: string]: { label: string; badgeColour: BadgeColourVariant };
} = {
  'in progress': { label: 'In progress', badgeColour: 'yellow' },
  'to do': { label: 'To do', badgeColour: 'blue' },
  complete: { label: 'Done', badgeColour: 'teal' },
};

const standardFilterOptions: FilterOption[] = [
  {
    title: 'Status',
    name: 'completionStatuses',
    type: 'checkbox',
    options: [
      { label: 'To do', value: 'to do' },
      { label: 'In progress', value: 'in progress' },
      { label: 'Done', value: 'complete' },
    ],
  },
  {
    title: 'Type',
    name: 'types',
    type: 'checkbox',
    options: [
      {
        label: 'Checklist',
        value: 'checklist',
      },
      {
        label: 'Form',
        value: 'form',
      },
      {
        label: 'Materials List',
        value: 'materials-list',
      },
    ],
  },
  {
    title: 'Event',
    name: 'eventRelationshipStatuses',
    type: 'checkbox',
    options: [
      {
        label: 'Linked',
        value: 'linked',
      },
      {
        label: 'Unlinked',
        value: 'unlinked',
      },
    ],
  },
];

const TaskTypeIconNameMap: Record<
  Exclude<ListedTasksTTask['__typename'], undefined>,
  ComponentProps<typeof UntitledIcon>['name']
> = {
  ChecklistTask: 'check-done-02.3',
  MaterialsListTask: 'list',
  FormTask: 'file-06.3',
  LegacyFormTask: 'file-06.3',
};

export type ListedTasksPaginationInput = {
  limit: number;
  offset: number;
};
type Props = {
  getTasksInput: GetTasksInput;
  setGetTasksInput: (updatedGetTasksInput: GetTasksInput) => void;
  getTasksPagination: ListedTasksPaginationInput;
  setGetTasksPagination: (
    updatedGetTasksPagination: ListedTasksPaginationInput
  ) => void;
  onPersistTaskSuccess?: () => void;
  listedTasks?: ListedTasksTTask[];
  isLoadingTasks: boolean;
  hideProjectAndCustomerColumn?: boolean;
  totalCount: number;
  headerContent?: HeaderBarProps;
};

const ListedTasks = ({
  getTasksInput,
  setGetTasksInput,
  getTasksPagination,
  setGetTasksPagination,
  onPersistTaskSuccess,
  listedTasks,
  isLoadingTasks,
  hideProjectAndCustomerColumn = false,
  totalCount,
  headerContent,
}: Props): JSX.Element | null => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const account = useAccount();
  const userRoles = useSelector(getUserRoles);
  const { data: me } = useGetMe();

  const [showDeleteTaskWithFormModal, setShowDeleteTaskWithFormModal] =
    useState<false | ListedTasksTTask>(false);
  const [showEventDrawer, setShowEventDrawer] = useState<false | number>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [requiresPersistSearchTerm, setRequiresPersistSearchTerm] =
    useState<boolean>(false);
  const [showEditTaskModal, setShowEditTaskModal] = useState<false | number>(
    false
  );
  const { data: accountUsers } = useGetMyAccountUsers({
    hasAnyPermission: ['tasks.getSelfAssignedTasks'],
  });

  const activeUsers = useMemo(() => {
    return accountUsers
      ? accountUsers.filter((u) => u.status !== 'Deactivated')
      : [];
  }, [accountUsers]);

  const canNavigateToProject = userHasRequiredPermission(userRoles, [
    DealsPermissions.GET_MY_DEAL,
  ]);
  const canNavigateToCustomer = userHasRequiredPermission(userRoles, [
    CustomersPermissions.GET_CUSTOMERS,
  ]);

  useEffect(() => {
    if (requiresPersistSearchTerm) {
      setGetTasksInput({ ...getTasksInput, searchTerm });
      setRequiresPersistSearchTerm(false);
    }
  }, [requiresPersistSearchTerm, searchTerm]);

  const filterOptions: FilterOption[] = useMemo(() => {
    const o: FilterOption[] = [];
    if (activeUsers) {
      o.push({
        title: 'Assignee',
        name: 'assignedTo',
        type: 'checkbox',
        options: [
          ...activeUsers.map((user) => ({
            label: user.fullName,
            value: user.id,
          })),
          { label: 'Unassigned', value: 'unassigned' },
        ],
      });
    }
    o.push(...standardFilterOptions);
    return o;
  }, [activeUsers]);

  const getItemActions = useCallback(
    (task: ListedTasksTTask) => {
      const actions: TTableRowAction<ListedTasksTTask>[] = [];
      if (!me?.me.user.id || task.__typename === 'MaterialsListTask') {
        return [];
      }
      if (
        userHasRequiredPermission(userRoles, [TasksPermissions.PERSIST_TASK]) ||
        (userHasRequiredPermission(userRoles, [
          TasksPermissions.PERSIST_SELF_CREATED_TASK,
        ]) &&
          task.createdByUserId == me.me.user.id)
      ) {
        actions.push({
          label: 'Edit',
          onClick: (task) => setShowEditTaskModal(+task.id),
        });
      }

      if (
        userHasRequiredPermission(userRoles, [TasksPermissions.DELETE_TASK]) ||
        (userHasRequiredPermission(userRoles, [
          TasksPermissions.DELETE_SELF_CREATED_TASK,
        ]) &&
          task?.createdByUserId == me?.me.user.id)
      ) {
        actions.push({
          label: 'Delete',
          onClick: (task) => setShowDeleteTaskWithFormModal(task),
        });
      }
      return actions;
    },
    [me, onPersistTaskSuccess]
  );

  const onSort = useCallback(
    (field: string, sortDirection: SortDirection) => {
      setGetTasksInput({
        ...getTasksInput,
        sortBy: field,
        sortDirection: sortDirection
          ? sortDirection === SortDirection.ASCENDING
            ? 'asc'
            : 'desc'
          : undefined,
      });
    },
    [getTasksInput]
  );

  const dueInDays = useCallback((deadlineDate: Date) => {
    const nowMoment = startOfDay(new Date());
    return differenceInDays(deadlineDate, nowMoment);
  }, []);

  const deadline = useCallback((dueDays: number) => {
    const abs = Math.abs(dueDays);
    const dayOrDays = abs === 1 ? 'day' : 'days';

    if (dueDays === 0) return 'Today';
    if (dueDays < 0) return `${-dueDays} ${dayOrDays} ago`;
    return `In ${dueDays} ${dayOrDays}`;
  }, []);

  return (
    <div className="listed-tasks flex flex-col gap-4">
      <div className="listed-tasks-results-container">
        <ManageableItemsList>
          {headerContent && (
            <ManageableItemsList.HeaderBar
              heading={headerContent.heading}
              subHeading={headerContent.subHeading}
              buttons={headerContent.buttons}
            />
          )}
          <ManageableItemsList.ActionBar>
            <ManageableItemsList.ActionBar.SearchInput
              value={searchTerm}
              onChange={(value) => setSearchTerm(value)}
              onChangeTimeout={() => setRequiresPersistSearchTerm(true)}
              onBlur={() => setRequiresPersistSearchTerm(true)}
              changeTimeoutMs={300}
            />
            <ManageableItemsList.ActionBar.AdvancedFilter
              options={filterOptions}
              appliedFilters={{
                assignedTo: getTasksInput?.assignedTo || [],
                completionStatuses: getTasksInput?.completionStatuses || [],
                eventRelationshipStatuses:
                  getTasksInput?.eventRelationshipStatuses || [],
                types: getTasksInput?.types || [],
              }}
              onApplyFilters={(filters) => {
                setGetTasksInput({
                  ...filters,
                  searchTerm,
                });
                // reset to page 1
                setGetTasksPagination({
                  ...getTasksPagination,
                  offset: 0,
                });
              }}
            />
          </ManageableItemsList.ActionBar>
          <ManageableItemsList.Table
            items={listedTasks || []}
            uniqueKey={'id'}
            isLoading={!listedTasks && isLoadingTasks}
            onClickRow={(row) => history.push(`${url}/${row.id}`)}
            itemActions={getItemActions}
            sortBy={
              (getTasksInput?.sortBy as keyof ListedTasksTTask) || undefined
            }
            sortDirection={
              getTasksInput.sortDirection
                ? getTasksInput.sortDirection === 'asc'
                  ? SortDirection.ASCENDING
                  : SortDirection.DESCENDING
                : undefined
            }
          >
            <ManageableItemsList.Table.Column
              header="Task"
              field="name"
              render={(name, row: ListedTasksTTask) => (
                <>
                  <div className="flex flex-row items-center">
                    <UntitledIcon
                      name={
                        row.__typename
                          ? TaskTypeIconNameMap[row.__typename]
                          : 'check-done-02.3'
                      }
                      className="mr-4 mt-1.5 h-5 w-5 flex-shrink-0 text-blue-500"
                    />
                    <div className="inline-block max-w-[300px] truncate">
                      {name}
                    </div>
                  </div>
                </>
              )}
              onSort={onSort}
            />
            <ManageableItemsList.Table.Column
              header="Status"
              field="completionStatus"
              render={(value: string) =>
                CompletionStatusMap?.[value] && (
                  <Badge
                    colour={CompletionStatusMap[value].badgeColour}
                    variant="soft"
                  >
                    {CompletionStatusMap[value].label}
                  </Badge>
                )
              }
              sortByName="status"
              onSort={onSort}
            />
            {!hideProjectAndCustomerColumn && (
              <ManageableItemsList.Table.Column
                header="Customer"
                field="project"
                render={(project, row) => {
                  const customer =
                    project?.customer || row.events?.[0]?.customer;
                  if (customer) {
                    return (
                      <div
                        className="max-w-[140px] truncate"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {canNavigateToCustomer ? (
                          <Link to={`/customers/${customer.id}`}>
                            {customer.name}
                          </Link>
                        ) : (
                          customer.name
                        )}
                      </div>
                    );
                  }
                }}
              />
            )}
            {!hideProjectAndCustomerColumn && (
              <ManageableItemsList.Table.Column
                header="Project"
                field="project"
                render={(project) =>
                  project && (
                    <div
                      className="max-w-[60px] truncate"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {canNavigateToProject ? (
                        <Link to={`/deals/${project.id}`}>
                          {project.reference}
                        </Link>
                      ) : (
                        project.reference
                      )}
                    </div>
                  )
                }
                sortByName="projectReference"
                onSort={onSort}
              />
            )}
            <ManageableItemsList.Table.Column
              header="Event"
              field="events"
              render={(events) =>
                !!events.length && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowEventDrawer(events[0].id);
                    }}
                    variant={EBtnVariant.LinkInline}
                  >
                    <span className="max-w-[100px] truncate">
                      {events[0].name}
                    </span>
                  </Button>
                )
              }
            />

            <ManageableItemsList.Table.Column
              header="Assignee"
              field="assignee"
              render={(value) =>
                value && (
                  <UserAvatar
                    sizeVariant={AvatarSizeVariant.XS}
                    user={{
                      firstName: value.firstName || '',
                      lastName: value.lastName || '',
                      emailAddress: value.email || '',
                      userColour: value.colour || '',
                      imgSrc: value.avatarUrl,
                    }}
                    enableUserInfoTooltip
                  />
                )
              }
              onSort={onSort}
            />
            <ManageableItemsList.Table.Column
              header="Deadline"
              field="deadlineDate"
              render={(value, row) => {
                const isComplete = row.completionStatus === 'complete';
                if (value && !isComplete) {
                  const dueDays = dueInDays(value);
                  const deadlineText = deadline(dueDays);
                  return (
                    <span className={dueDays <= 0 ? 'text-red-600' : ''}>
                      {deadlineText}
                    </span>
                  );
                }
              }}
              onSort={onSort}
            />
          </ManageableItemsList.Table>
          <ManageableItemsList.PaginationBar
            pageSize={getTasksPagination.limit || 20}
            currentPage={
              getTasksPagination.offset / getTasksPagination.limit + 1
            }
            totalItems={totalCount}
            onPageChange={(newPage) =>
              setGetTasksPagination({
                ...getTasksPagination,
                offset: (newPage - 1) * getTasksPagination.limit,
              })
            }
          />
        </ManageableItemsList>
      </div>
      <EditTaskModal
        taskId={
          typeof showEditTaskModal !== 'boolean' ? showEditTaskModal : undefined
        }
        isOpen={!!showEditTaskModal}
        onClose={() => setShowEditTaskModal(false)}
        onPersistTaskSuccess={() => {
          setShowEditTaskModal(false);
          onPersistTaskSuccess?.();
        }}
      />
      <ConfirmDeleteTaskModal
        isOpen={!!showDeleteTaskWithFormModal}
        onClose={() => setShowDeleteTaskWithFormModal(false)}
        onConfirmDeleteTask={() =>
          showDeleteTaskWithFormModal &&
          dispatch(
            requestDeleteTask(+showDeleteTaskWithFormModal.id, () => {
              onPersistTaskSuccess?.();
              setShowDeleteTaskWithFormModal(false);
            })
          )
        }
        taskHasDocument={
          showDeleteTaskWithFormModal &&
          (showDeleteTaskWithFormModal.__typename === 'LegacyFormTask' ||
            showDeleteTaskWithFormModal.__typename === 'FormTask')
        }
      />
      <ScheduledEventReadDrawer
        scheduledEventId={showEventDrawer || undefined}
        isOpen={!!showEventDrawer}
        onClose={() => setShowEventDrawer(false)}
        onUpdateScheduledEventSuccess={onPersistTaskSuccess}
        onDeleteScheduledEventSuccess={onPersistTaskSuccess}
        onUpdateTasksSuccess={onPersistTaskSuccess}
        hideNotes={true}
      />
    </div>
  );
};

export default ListedTasks;
