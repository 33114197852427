import React, {
  FunctionComponent,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import './SupplierAddressesControl.sass';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { CustomerAddress } from '@payaca/types/customerTypes';
import CustomerAddressInputControl from '../customerAddressInputControl/CustomerAddressInputControl';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import CreateArea from '@payaca/components/clickableArea/CreateArea';
import SupplierAddressInputControl from './SupplierAddressInputControl';

type Props = {
  inputStyleVariant?: InputStyleVariant;
  supplierAddresses: {
    id?: number;
    description?: string | null;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    postcode?: string | null;
    country?: string | null;
  }[];
  fieldNamePrefix: string;
  touchedState?: { [fieldName: string]: boolean };
  validationState?: { [fieldName: string]: FieldValidationResult };
  onChange: (value: { [key: string]: any }) => void;
  onTouch?: (fieldName: string) => void;
};

const SupplierAddressesControl: FunctionComponent<Props> = ({
  inputStyleVariant,
  supplierAddresses,
  fieldNamePrefix,
  touchedState,
  validationState,
  onTouch,
  onChange,
}: Props): JSX.Element | null => {
  const [watchSizeTrigger, setWatchSizeTrigger] = useState<Date>();

  const onAddressAdd = useCallback(() => {
    onChange({
      [fieldNamePrefix]: [...(supplierAddresses ?? []), {}],
    });
  }, [onChange, supplierAddresses, fieldNamePrefix]);

  const onAddressRemove = useCallback(
    (addressIndex: number) => {
      supplierAddresses.splice(addressIndex, 1);
      onChange({ [fieldNamePrefix]: supplierAddresses });
    },
    [onChange, supplierAddresses, fieldNamePrefix]
  );

  const renderAddressControl = useCallback(
    (
      supplierAddress: {
        id?: number;
        description?: string | null;
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        postcode?: string | null;
        country?: string | null;
      },
      index: number
    ) => {
      return (
        <ContentPanel
          hasBoxShadow={true}
          key={`supplier-address-control-${index}`}
          className="supplier-address-control-container"
        >
          <div className="flex-container">
            <span className="flex-grow"></span>
            <div className="remove-address-control">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => onAddressRemove(index)}
              />
            </div>
          </div>
          <SupplierAddressInputControl
            fieldNamePrefix={`${fieldNamePrefix}[${index}]`}
            address={supplierAddress}
            validationState={validationState}
            touchedState={touchedState}
            onChange={onChange}
            onTouch={onTouch}
            inputStyleVariant={inputStyleVariant}
          />
        </ContentPanel>
      );
    },
    [
      onTouch,
      onChange,
      onAddressRemove,
      validationState,
      touchedState,
      fieldNamePrefix,
      inputStyleVariant,
    ]
  );

  return (
    <ResponsiveViewWrapper
      downBreakpointSm={840}
      downBreakpointXs={550}
      className="supplier-addresses-control"
    >
      <div className="supplier-address-controls-container">
        {supplierAddresses?.map((supplierAddress, index) =>
          renderAddressControl(supplierAddress, index)
        )}
        <div className="add-address-trigger-container">
          <CreateArea onClick={onAddressAdd}>Add address</CreateArea>
        </div>
      </div>
    </ResponsiveViewWrapper>
  );
};

export default SupplierAddressesControl;
