// Todo: move to types package?
export type LineItemTotals = {
  minPrice: number;
  maxPrice: number;
  predictedPrice: number;
};

export const getLineItemTotals = (
  lineItemMaterials: {
    materialQuantity: number;
    material: {
      suppliedBy: {
        supplierId: number;
        price: number;
      }[];
    };
  }[],
  preferredSupplierId?: number
) => {
  return lineItemMaterials.reduce(
    (acc, { materialQuantity, material }) => {
      const prices = material.suppliedBy.map((i) => i.price);

      const minPrice = Math.min(...prices);
      const maxPrice = Math.max(...prices);

      const preferredSupplier = material.suppliedBy.find(
        (i) => i.supplierId === preferredSupplierId
      );

      const preferredPrice = preferredSupplier?.price || minPrice;

      return {
        minPrice: acc.minPrice + minPrice * materialQuantity,
        maxPrice: acc.maxPrice + maxPrice * materialQuantity,
        predictedPrice: acc.predictedPrice + preferredPrice * materialQuantity,
      };
    },
    {
      minPrice: 0,
      maxPrice: 0,
      predictedPrice: 0,
    } as LineItemTotals
  );
};
