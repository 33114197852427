import {
  getDeal,
  getJobContentByJobId,
  getJobPaymentsByDealId,
  getJobsByDealId,
} from '@/utils/stateAccessors';
import Button from '@payaca/components/button/Button';
import { isInvoice } from '@payaca/helpers/jobStatusHelper';
import React, { FC, useMemo, useState } from 'react';
import RecordPaymentModal from '../recordPaymentModal/RecordPaymentModal';
import { getOutstandingPaymentValue } from '@payaca/helpers/jobHelperV2';
import TooltipUI from '@material-ui/core/Tooltip';
import { useSelector } from '@/api/state';

type Props = {
  dealId: number;
  onJobUpdateSuccess: (jobId: number) => void;
  renderRecordPaymentTrigger?: (
    onTrigger?: () => void,
    isDisabled?: boolean
  ) => React.ReactNode;
};
const RecordDealPaymentControl: FC<Props> = ({
  dealId,
  onJobUpdateSuccess,
  renderRecordPaymentTrigger,
}: Props): JSX.Element => {
  const [showRecordPaymentModal, setShowRecordPaymentModal] = useState(false);

  const customer = useSelector((state) => state.customer.currentCustomer);

  const activeJobs = useSelector((state) => {
    return getJobsByDealId(state, dealId).filter(
      (x) => !x.inactivatedAt && !x.archivedAt
    );
  });

  const jobPayments = useSelector((state) =>
    getJobPaymentsByDealId(state, dealId)
  );

  const deal = useSelector((state) => {
    return getDeal(state, dealId);
  });
  const activeJob = useMemo(() => {
    if (!activeJobs.length) return;
    const sentJobs = activeJobs.filter((x) => !!x.sentAt);
    if (!sentJobs.length) return;

    const invoice = sentJobs.find((x) => isInvoice(x.status));

    if (invoice) return invoice;
    return sentJobs[0];
  }, [activeJobs]);

  const activeJobContent = useSelector((state) => {
    if (!activeJob) return;
    return getJobContentByJobId(state, activeJob.id);
  });

  const outstandingPaymentValue = useMemo(() => {
    if (!activeJob || !activeJobContent) return null;
    return getOutstandingPaymentValue(activeJob, activeJobContent, jobPayments);
  }, [activeJob, activeJobContent, jobPayments]);

  const canRecordPayment = useMemo(() => {
    if (deal?.archivedAt) return false;
    if (!activeJob) return false;
    if (!activeJobs.some((x) => !!x.sentAt)) return false;

    return true;
  }, [deal, outstandingPaymentValue, activeJob, activeJobs]);

  return (
    <div className="add-new-deal-item-control">
      {!canRecordPayment ? (
        <TooltipUI title="This option is not available" arrow placement="top">
          <div>
            {renderRecordPaymentTrigger ? (
              renderRecordPaymentTrigger(undefined, true)
            ) : (
              <Button className="disabled">Record Payment</Button>
            )}
          </div>
        </TooltipUI>
      ) : renderRecordPaymentTrigger ? (
        renderRecordPaymentTrigger(() => setShowRecordPaymentModal(true), false)
      ) : (
        <Button onClick={() => setShowRecordPaymentModal(true)}>
          Record Payment
        </Button>
      )}

      {activeJob && activeJobContent && customer && (
        <RecordPaymentModal
          dealId={dealId}
          isOpen={showRecordPaymentModal}
          onClose={() => setShowRecordPaymentModal(false)}
          recordPaymentCallback={() => {
            onJobUpdateSuccess(activeJob.id);
          }}
          confirmPaymentCallback={() => {
            onJobUpdateSuccess(activeJob.id);
          }}
        />
      )}
    </div>
  );
};

export default RecordDealPaymentControl;
