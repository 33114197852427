import React, {
  FunctionComponent,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';

import SignatureCapture from '../signatureCapture/SignatureCapture';
import Button from '../button/Button';
import { ButtonColourVariant, ButtonStyleVariant } from '../button/enums';

import './FormSignatureCaptureControl.sass';

type Props = {
  label?: string;
  hasShadow?: boolean;
  displaySignHerePrompt?: boolean;
  currentSignature: string;
  onResponseCaptureComplete: (signature: string) => void;
};

const FormSignatureCaptureControl: FunctionComponent<Props> = ({
  label,
  hasShadow = false,
  displaySignHerePrompt = false,
  currentSignature,
  onResponseCaptureComplete,
}: Props): JSX.Element => {
  const controlReference = useRef<HTMLHeadingElement>(null);
  const signatureCanvasReference = useRef<any>();
  const [isSignaturePresent, setIsSignaturePresent] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  useEffect(() => {
    setIsComplete(!!currentSignature);
  }, [currentSignature]);

  const captureSignature = useCallback(() => {
    if (!isSignaturePresent || signatureCanvasReference?.current?.isEmpty()) {
      return;
    } else {
      const signatureImage = signatureCanvasReference?.current?.toDataURL();
      setIsComplete(true);
      onResponseCaptureComplete(signatureImage);
    }
  }, [isSignaturePresent, signatureCanvasReference, onResponseCaptureComplete]);

  const resetSignature = useCallback(() => setIsComplete(false), []);

  return isComplete ? (
    <div>
      <p>The following signature has been provided:</p>
      <div className={'form-customer-signature-preview'}>
        <img src={currentSignature} alt={'Customer Signature'} />
      </div>
      <div>
        <Button
          onClick={resetSignature}
          styleVariant={ButtonStyleVariant.ANCHOR}
        >
          Add a different signature
        </Button>
      </div>
    </div>
  ) : (
    <div
      className={
        `form-signature-capture-control` + (hasShadow ? ' shadow' : '')
      }
      ref={controlReference}
    >
      <p>
        {label || (
          <>
            Please sign in the space below to{' '}
            <strong>confirm you are happy</strong>.
          </>
        )}
      </p>
      <SignatureCapture
        signatureCanvasReference={signatureCanvasReference}
        setIsSignaturePresent={setIsSignaturePresent}
        displaySignHerePrompt={displaySignHerePrompt}
      />
      <div className="button-container">
        <Button
          isDisabled={!isSignaturePresent || isComplete}
          onClick={captureSignature}
          styleVariant={ButtonStyleVariant.OUTSIZE}
          colourVariant={ButtonColourVariant.PRIMARY}
        >
          Save signature
        </Button>
      </div>
    </div>
  );
};

export default FormSignatureCaptureControl;
