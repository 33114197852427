import React, {
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';

import { actions as userActions } from '@/api/users';

import Button from '@payaca/components/button/Button';
import { ButtonColourVariant, ButtonStyleVariant } from '@payaca/components/button/enums';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';

import {
  getEmailFieldValidator,
  getIsRequiredFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

type Props = {
  userEmailAddress?: string;
  allowUserEmailAddressInput?: boolean;
};
const ResendVerificationEmailControl: FunctionComponent<Props> = ({
  userEmailAddress,
  allowUserEmailAddressInput = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [verificationEmailResent, setVerificationEmailResent] = useState(false);
  const [sendingVerificationEmail, setSendingVerificationEmail] =
    useState(false);

  const initialFormState = useMemo(() => {
    return {
      emailAddress: userEmailAddress,
    };
  }, [userEmailAddress]);

  const fieldValidators = useMemo(() => {
    return {
      emailAddress: [getIsRequiredFieldValidator(), getEmailFieldValidator()],
    };
  }, []);

  const resendVerificationEmail = useCallback(
    (emailAddress: string) => {
      setSendingVerificationEmail(true);
      dispatch(
        userActions.resendEmailVerification(
          emailAddress,
          (error: any, response: any) => {
            setSendingVerificationEmail(false);
            if (!error) {
              setVerificationEmailResent(true);
            }
          }
        )
      );
    },
    [dispatch]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          {allowUserEmailAddressInput && (
            <ValidatedFieldWrapper
              validationResult={validationState['emailAddress']}
              isTouched={touchedState['emailAddress'] || false}
            >
              <LoqateAdvisoryEmailInputField
                styleVariant={InputStyleVariant.OUTSIZE}
                isRequired={true}
                label="Email"
                name="emailAddress"
                value={formState.emailAddress}
                onTouch={onFieldTouch}
                onChange={(value: { [key: string]: any }) => {
                  setVerificationEmailResent(false);
                  onFieldChange(value);
                }}
                disableValidation={
                  validationState['emailAddress']
                    ? !validationState['emailAddress'].isValid
                    : false
                }
              />
            </ValidatedFieldWrapper>
          )}
          <Button
            onClick={() =>
              !sendingVerificationEmail &&
              resendVerificationEmail(formState.emailAddress)
            }
            styleVariant={ButtonStyleVariant.OUTSIZE}
            colourVariant={ButtonColourVariant.PRIMARY}
            isProcessing={sendingVerificationEmail}
            isDisabled={verificationEmailResent || !isValid}
            type="submit"
          >
            {verificationEmailResent ? 'Email resent' : 'Resend email'}
          </Button>
        </>
      );
    },
    [
      allowUserEmailAddressInput,
      sendingVerificationEmail,
      resendVerificationEmail,
      verificationEmailResent,
    ]
  );

  return (
    <div className="resend-verification-email-control">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
        initialFormState={initialFormState}
      />
    </div>
  );
};

export default ResendVerificationEmailControl;
