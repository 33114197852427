import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  CreateConnectionApplicationInput,
  CreateConnectionApplicationMutation as _CreateConnectionApplicationMutation,
} from '@/gql/graphql';

const CreateConnectionApplicationMutation = graphql(`
  mutation CreateConnectionApplication(
    $input: CreateConnectionApplicationInput!
  ) {
    createConnectionApplication(input: $input) {
      id
      status
      data
    }
  }
`);

const useCreateConnectionApplication = (
  options?: UseMutationOptions<
    _CreateConnectionApplicationMutation,
    unknown,
    CreateConnectionApplicationInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(CreateConnectionApplicationMutation, { input });
    },
    ...options,
  });
};

export default useCreateConnectionApplication;
