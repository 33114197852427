import { FC, useState } from 'react';
import { parseHtmlToReact } from '@payaca/utilities/htmlUtilities';
import Button from '../plButton/Button';
import { EBtnSize, EBtnVariant } from '../plButton/useButtonClassName';
import { twMerge } from 'tailwind-merge';

export interface IProps {
  html: string;
  className?: string;
}

const TruncatedHtml: FC<IProps> = (props) => {
  const { html, className } = props;
  const [isShowingMoreHTML, setIsShowingMoreHTML] = useState(false);

  return (
    <>
      <div
        className={twMerge(
          isShowingMoreHTML
            ? ''
            : `container-bottom-shadow relative max-h-[100px] overflow-hidden`,
          className || ''
        )}
      >
        {parseHtmlToReact(html)}
      </div>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setIsShowingMoreHTML((s) => !s);
        }}
        variant={EBtnVariant.LinkInline}
        size={EBtnSize.Small}
      >
        {isShowingMoreHTML ? 'Show less' : 'Show more'}
      </Button>
    </>
  );
};

export default TruncatedHtml;
