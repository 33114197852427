import { action } from 'typesafe-actions';

import {
  GetListedScheduledEventsRequestData,
  ScheduledEvent,
} from '@payaca/types/scheduledEventsTypes';

import {
  ActionType,
  CreateScheduledEventRequestData,
  SendScheduledEventConfirmation,
  UpdateScheduledEventRequestData,
} from './scheduledEventsTypes';
import { createAsyncAction } from '../utils';

export const requestGetListedScheduledEvents = (
  getListedScheduledEventsRequestData: GetListedScheduledEventsRequestData,
  callback?: (scheduledEvents: ScheduledEvent[]) => void
) =>
  action(ActionType.REQUEST_GET_LISTED_SCHEDULED_EVENTS, {
    getListedScheduledEventsRequestData,
    callback,
  });

export const getListedScheduledEventsSuccess = (scheduledEvents: any) =>
  action(ActionType.GET_LISTED_SCHEDULED_EVENTS_SUCCESS, {
    scheduledEvents,
  });

export const getListedScheduledEventsFailure = (error: Error) =>
  action(ActionType.GET_LISTED_SCHEDULED_EVENTS_FAILURE, null, null, error);

export const requestGetScheduledEvent = (scheduledEventId: number) =>
  action(ActionType.REQUEST_GET_SCHEDULED_EVENT, {
    scheduledEventId,
  });

export const getScheduledEventSuccess = (
  scheduledEventId: number,
  scheduledEvent: ScheduledEvent
) =>
  action(ActionType.GET_SCHEDULED_EVENT_SUCCESS, {
    scheduledEventId,
    scheduledEvent,
  });

export const getScheduledEventFailure = (
  scheduledEventId: number,
  error: Error
) =>
  action(
    ActionType.GET_SCHEDULED_EVENT_FAILURE,
    {
      scheduledEventId,
    },
    null,
    error
  );

export const requestCreateScheduledEvent = (
  createScheduledEventRequestData: CreateScheduledEventRequestData,
  callback?: (scheduledEventId: number) => void
) =>
  action(
    ActionType.REQUEST_CREATE_SCHEDULED_EVENT,
    {
      createScheduledEventRequestData,
      callback,
    },
    {
      retry: true,
    }
  );

export const createScheduledEventSuccess = (scheduledEvent: any) =>
  action(ActionType.CREATE_SCHEDULED_EVENT_SUCCESS, { scheduledEvent });

export const createScheduledEventFailure = (
  error: Error,
  errorMessage: string
) =>
  action(
    ActionType.CREATE_SCHEDULED_EVENT_FAILURE,
    { errorMessage },
    null,
    error
  );

export const requestUpdateScheduledEvent = (
  scheduledEvent: UpdateScheduledEventRequestData,
  callback?: () => void
) =>
  action(
    ActionType.REQUEST_UPDATE_SCHEDULED_EVENT,
    {
      scheduledEvent,
      callback,
    },
    {
      retry: true,
    }
  );

export const updateScheduledEventSuccess = (scheduledEvent: ScheduledEvent) =>
  action(ActionType.UPDATE_SCHEDULED_EVENT_SUCCESS, { scheduledEvent });

export const updateScheduledEventFailure = (
  error: Error,
  errorMessage: string
) =>
  action(
    ActionType.UPDATE_SCHEDULED_EVENT_FAILURE,
    { errorMessage },
    null,
    error
  );

export const requestArchiveScheduledEvent = (
  scheduledEventId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_ARCHIVE_SCHEDULED_EVENT, {
    scheduledEventId,
    callback,
  });

export const archiveScheduledEventSuccess = (scheduledEventId: number) =>
  action(ActionType.ARCHIVE_SCHEDULED_EVENT_SUCCESS, { scheduledEventId });

export const archiveScheduledEventFailure = (
  error: Error,
  errorMessage: string
) =>
  action(
    ActionType.ARCHIVE_SCHEDULED_EVENT_FAILURE,
    { errorMessage },
    null,
    error
  );

export const requestGetScheduledEventsForDeal = (dealId: number) =>
  action(ActionType.REQUEST_GET_SCHEDULED_EVENTS_FOR_DEAL, {
    dealId,
  });

export const getScheduledEventsForDealSuccess = () =>
  action(ActionType.GET_SCHEDULED_EVENTS_FOR_DEAL_SUCCESS);

export const requestGetScheduledEventsForTask = (taskId: number) =>
  action(ActionType.REQUEST_GET_SCHEDULED_EVENTS_FOR_TASK, {
    taskId,
  });

export const getScheduledEventsForTaskSuccess = (
  scheduledEvents: Array<ScheduledEvent>
) => action(ActionType.GET_SCHEDULED_EVENTS_FOR_TASK_SUCCESS, scheduledEvents);

export const clearScheduledEventRequest = () =>
  action(ActionType.CLEAR_SCHEDULED_EVENT_REQUESTS);

export const clearScheduledEvents = () =>
  action(ActionType.CLEAR_SCHEDULED_EVENTS);

export const getScheduledEventsForTaskFailure = (error: Error) =>
  action(ActionType.GET_SCHEDULED_EVENTS_FOR_TASK_FAILURE, null, null, error);

export const getScheduledEventsForDealFailure = (error: Error) =>
  action(ActionType.GET_SCHEDULED_EVENTS_FOR_DEAL_FAILURE, null, null, error);

export const sendScheduledEventConfirmation =
  createAsyncAction<SendScheduledEventConfirmation>({
    request: ActionType.SEND_SCHEDULED_EVENT_CONFIRMATION_REQUEST,
  })((payload: SendScheduledEventConfirmation['request']['payload']) => ({
    payload,
  }));
