import { TemplatesPermissions } from './templates.permissions';
import { TemplatesRoles } from './templates.roles';

export const templatesMappings = {
  [TemplatesPermissions.GET_TEMPLATES]: [TemplatesRoles.VIEWER],
  [TemplatesPermissions.PERSIST_TEMPLATE]: [TemplatesRoles.PERSISTER],
  [TemplatesPermissions.DELETE_TEMPLATE]: [TemplatesRoles.DELETER],
  [TemplatesPermissions.GET_COLLECTION_MANAGEMENT_VIEW]: [
    TemplatesRoles.COLLECTION_MANAGER,
  ],
  [TemplatesPermissions.USE_PROPOSAL_TEMPLATES]: [
    TemplatesRoles.PROPOSAL_TEMPLATES_USER,
  ],
};
