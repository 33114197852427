import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import './UserInviteForm.sass';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CollapsiblePanel, {
  CollapsiblePanelStyleVariant,
} from '@payaca/components/collapsiblePanel/CollapsiblePanel';
import PrivateField from '@payaca/components/privateField/PrivateField';
import PasswordValidationFeedback from '../passwordValidationFeedback/PasswordValidationFeedback';
import {
  minimumLengthFieldValidator,
  mustContainLetterFieldValidator,
  mustContainNumberFieldValidator,
} from '@payaca/helpers/passwordValidationHelper';
import { useDispatch } from 'react-redux';
import { getFormServiceError } from '@/helpers/formHelper';
import { actions as appActions } from '@/api/app';
import * as authActions from '@payaca/store/auth/authActions';
import { useHistory } from 'react-router';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { marketingSiteUrl } from '@/helpers/urlHelper';

type Props = {
  inviteToken: string;
};

const UserInviteForm: FC<Props> = ({ inviteToken }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [userInviteErrorMessage, setUserInviteErrorMessage] =
    useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPasswordValidationFeedback, setShowPasswordValidationFeedback] =
    useState(false);

  const fieldValidators = useMemo(() => {
    const isRequiredFieldValidator = getIsRequiredFieldValidator();

    return {
      password: [
        isRequiredFieldValidator,
        minimumLengthFieldValidator,
        mustContainLetterFieldValidator,
        mustContainNumberFieldValidator,
      ],
    };
  }, []);

  const login = useCallback(
    (emailAddress: string, password: string) => {
      setIsProcessing(true);
      dispatch(authActions.requestLogin(emailAddress, password));
      history.push('/deals');
    },
    [dispatch, history]
  );

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      setIsProcessing(true);
      setUserInviteErrorMessage(undefined);
      const payload = {
        password: formState.password,
        subscribeToNewsletter: true,
      };
      dispatch(
        appActions.registerInvitedUser(
          inviteToken,
          payload,
          (error: any, response: any) => {
            if (!error && response) {
              login(response.email, formState.password);
            } else {
              setIsProcessing(false);
              setUserInviteErrorMessage(
                getFormServiceError('userInvite', error)
              );
            }
          }
        )
      );
    },
    [dispatch, login]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          {/* TODO: Should we ask for user phone number here? */}
          <ValidatedFieldWrapper
            validationResult={{
              ...validationState['password'],
              errors: [],
            }}
            isTouched={touchedState['password'] || false}
          >
            <PrivateField
              styleVariant={InputStyleVariant.OUTSIZE}
              name="password"
              value={formState.password}
              label={'Create password'}
              isRequired={true}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              additionalInputProps={{
                onFocus: () => setShowPasswordValidationFeedback(true),
                onBlur: () => {
                  onFieldTouch('password');
                  validationState['password']?.isValid &&
                    setShowPasswordValidationFeedback(false);
                },
              }}
            />
            <CollapsiblePanel
              isOpen={showPasswordValidationFeedback}
              styleVariant={CollapsiblePanelStyleVariant.UNSTYLED}
              shouldRenderHeader={false}
            >
              <PasswordValidationFeedback password={formState.password} />
            </CollapsiblePanel>
          </ValidatedFieldWrapper>

          <>
            <div>
              <Button
                isProcessing={isProcessing}
                onClick={() => !isProcessing && onSubmit(formState)}
                styleVariant={ButtonStyleVariant.OUTSIZE}
                isDisabled={!isValid}
              >
                Create account
              </Button>
              {userInviteErrorMessage && (
                <ErrorMessage message={userInviteErrorMessage} />
              )}
            </div>
            <div className="marketing-and-terms">
              <p>
                * by continuing you are agreeing to Payaca&apos;s{' '}
                <a
                  href={marketingSiteUrl('/terms-and-conditions')}
                  target="_blank"
                  rel="noreferrer"
                >
                  {'terms & conditions'}
                </a>{' '}
                and{' '}
                <a
                  href={marketingSiteUrl('/privacy-cookie-policy')}
                  target="_blank"
                  rel="noreferrer"
                >
                  privacy policy
                </a>
              </p>
            </div>
          </>
        </>
      );
    },
    [
      showPasswordValidationFeedback,
      isProcessing,
      userInviteErrorMessage,
      onSubmit,
    ]
  );

  const initialFormState = useMemo(() => {
    return {
      password: '',
      inviteToken,
    };
  }, [inviteToken]);

  return (
    <div className="user-invite-form-container">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
        initialFormState={initialFormState}
      />
    </div>
  );
};

export default UserInviteForm;
