import { Reducer } from 'redux';

import { ActionType, State } from './automationTypes';

export const initialState: State = {
  automations: {},
  isGettingListedAutomationsPage: false,
  listedAutomationsPage: null,
  isPerformingAutomationAction: false,

  isArchivingAutomations: false,

  automationTemplates: [],
  isGettingAutomationTemplates: false,
  isCreatingAutomation: false,
};

const automationReducer: Reducer<State> = (state = initialState, action) => {
  let fetchedEntity: any;
  switch (action.type) {
    case ActionType.REQUEST_GET_LISTED_AUTOMATIONS_PAGE:
      return { ...state, isGettingListedAutomationsPage: true };
    case ActionType.GET_LISTED_AUTOMATIONS_PAGE_SUCCESS:
      return {
        ...state,
        isGettingListedAutomationsPage: false,
        listedAutomationsPage: action.payload,
      };
    case ActionType.GET_LISTED_AUTOMATIONS_PAGE_FAILURE:
      return { ...state, isGettingListedAutomationsPage: false };

    case ActionType.REQUEST_GET_AUTOMATION:
    case ActionType.REQUEST_UPDATE_AUTOMATION:
      fetchedEntity =
        state.automations && state.automations[action.payload.automationId];
      return {
        ...state,
        isPerformingAutomationAction: true,
        automations: {
          ...state.automations,
          [action.payload.automationId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    case ActionType.REQUEST_CREATE_AUTOMATION:
      return {
        ...state,
        isPerformingAutomationAction: true,
      };

    case ActionType.GET_AUTOMATION_SUCCESS:
    case ActionType.UPDATE_AUTOMATION_SUCCESS: {
      fetchedEntity =
        state.automations && state.automations[action.payload.automationId];

      return {
        ...state,
        isPerformingAutomationAction: false,
        automations: {
          ...state.automations,
          [action.payload.automationId]: {
            ...fetchedEntity,
            entity: action.payload.automation,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.CREATE_AUTOMATION_FAILURE:
    case ActionType.UPDATE_AUTOMATION_FAILURE:
      fetchedEntity =
        state.automations && state.automations[action.payload.automationId];

      return {
        ...state,
        isPerformingAutomationAction: false,
        automations: {
          ...state.automations,
          [action.payload.automationId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };

    case ActionType.REQUEST_ARCHIVE_AUTOMATIONS:
      return {
        ...state,
        isArchivingAutomations: true,
      };

    case ActionType.ARCHIVE_AUTOMATIONS_FAILURE: {
      const updatedAutomations = { ...state.automations };
      action.payload.automationIds.forEach((automationId: number) => {
        fetchedEntity = state.automations && state.automations[automationId];
        updatedAutomations[automationId] = {
          ...fetchedEntity,
          fetchFailedAt: new Date(),
          isFetching: false,
          fetchError: action.error,
        };
      });

      return {
        ...state,
        isArchivingAutomations: false,
        isPerformingAutomationAction: false,
        automations: updatedAutomations,
      };
    }

    case ActionType.ARCHIVE_AUTOMATIONS_SUCCESS: {
      const updatedAutomations = { ...state.automations };
      action.payload.automationIds.forEach((automationId: number) => {
        delete updatedAutomations[automationId];
      });

      return {
        ...state,
        isArchivingAutomations: false,
        isPerformingAutomationAction: false,
        automations: updatedAutomations,
      };
    }

    case ActionType.REQUEST_GET_AUTOMATION_TEMPLATES:
      return {
        ...state,
        isGettingAutomationTemplates: true,
      };
    case ActionType.GET_AUTOMATION_TEMPLATES_SUCCESS:
      return {
        ...state,
        automationTemplates: action.payload.automationTemplates,
        isGettingAutomationTemplates: false,
      };
    case ActionType.GET_AUTOMATION_TEMPLATES_FAILURE:
      return {
        ...state,
        isGettingAutomationTemplates: false,
      };

    case ActionType.CLEAR_AUTOMATIONS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default automationReducer;
