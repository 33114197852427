import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';

import './PayacaPhoneLink.sass';

type Props = {
  showPhoneIcon?: boolean;
  anchorContent?: React.ReactNode;
};

const PayacaPhoneLink: FunctionComponent<Props> = ({
  showPhoneIcon = false,
  anchorContent,
}: Props): JSX.Element => {
  return (
    <a
      className="payaca-phone-link link"
      href="tel:+443330509475"
      title="0333 050 9475"
    >
      {showPhoneIcon && <FontAwesomeIcon icon={faPhoneAlt} />}
      {anchorContent ? anchorContent : <span>0333 050 9475</span>}
    </a>
  );
};

export default PayacaPhoneLink;
