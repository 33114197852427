import React, { useMemo } from 'react';
import Table from '@payaca/components/plTable/Table';
import {
  PaymentSchedule,
  PaymentScheduleStage,
} from '@payaca/types/payment-schedule';
import { Nullish } from '@payaca/utilities/types';
import Card from '@payaca/components/plCard/Card';
import { getPaymentScheduleStagesWithValues } from '@payaca/helpers/paymentScheduleHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { useAccount } from '../../../utils/storeHooks';
import RichTextarea from '@payaca/components/plRichTextarea/RichTextarea';

type PaymentScheduleStageData = Pick<PaymentScheduleStage, 'description'> & {
  percentageDue: string;
  isDeposit: 'Yes' | 'No';
  value?: number;
};

type Props = {
  paymentSchedule: Nullish<PaymentSchedule>;
  projectValue?: number;
};
const PaymentScheduleDefinitionReadView = ({
  paymentSchedule,
  projectValue,
}: Props) => {
  const account = useAccount();

  const tableData = useMemo(() => {
    const stages = paymentSchedule?.stages
      ? getPaymentScheduleStagesWithValues(paymentSchedule, projectValue || 0)
      : [];

    return stages.map((stage) => {
      return {
        ...stage,
        percentageDue: `${stage.percentageDue}%`,
        isDeposit: stage.isDeposit ? 'Yes' : 'No',
      };
    });
  }, [paymentSchedule]);

  return (
    <Card>
      <Table
        data={tableData}
        hideEmptyState
        footerRow={
          paymentSchedule?.explainer ? (
            <div className="mx-6 my-2">
              <RichTextarea readOnly value={paymentSchedule.explainer} />
            </div>
          ) : undefined
        }
      >
        <Table.Column<PaymentScheduleStageData, 'description'>
          field="description"
          header="Stage"
          headerClassName="uppercase"
          className="!whitespace-normal"
        />
        <Table.Column<PaymentScheduleStageData, 'percentageDue'>
          field="percentageDue"
          header="Percentage"
          headerClassName="uppercase"
        />
        <Table.Column<PaymentScheduleStageData, 'isDeposit'>
          field="isDeposit"
          header="Deposit?"
          headerClassName="uppercase"
        />
        {!!projectValue && (
          <Table.Column<PaymentScheduleStageData, 'value'>
            field="value"
            header="Value"
            headerClassName="uppercase"
            render={(field) => field && currencyPrice(field, account.region)}
          />
        )}
      </Table>
    </Card>
  );
};

export default PaymentScheduleDefinitionReadView;
