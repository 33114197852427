import {
  DefaultSystemRoles,
  expiredTrialRoles,
  roleInheritanceMappings,
  rolesByAddOnProductNameAndSystemRole,
  rolesBySubscriptionFeatureNameAndSystemRole,
  SubscriptionLevels,
  SusbcriptionFeatureName,
} from './default-role.config';
import { jobMappings } from './jobs/job.mappings';
import { accountsMappings } from './accounts/accounts.mappings';
import { analyticsMappings } from './analytics/analytics.mappings';
import { customersMappings } from './customers/customers.mappings';
import { dashboardMappings } from './dashboard/dashboard.mappings';
import { dealsMappings } from './deals/deals.mappings';
import { documentsMappings } from './documents/documents.mappings';
import { lineItemsMappings } from './lineItems/line-items.mappings';
import { scheduledEventsMappings } from './scheduledEvents/scheduled-events.mappings';
import { tasksMappings } from './tasks/tasks.mappings';
import { invoicesMappings } from './invoices/invoices.mappings';
import { paymentsMappings } from './payments/payments.mappings';
import { pipelinesMappings } from './pipelines/pipelines.mappings';
import { automationsMappings } from './automations/automations.mappings';
import { suppliersMappings } from './suppliers/suppliers.mappings';
import { materialsMappings } from './materials/materials.mappings';
import { uploadsMappings } from './uploads/uploads.mappings';
import { materialsListMappings } from './materialsList/materialsList.mappings';
import { proposalsMappings } from './proposals/proposals.mappings';
import { taxRateMappings } from './tax-rates/tax-rates.mappings';
import { userAssignmentsMappings } from './userAssignments/userAssignments.mappings';
import { servicePlanMappings } from './service-plans/service-plans.mappings';
import { dispatchMappings } from './dispatch/dispatch.mappings';
import { templatesMappings } from './templates/templates.mappings';
import { timelogsMappings } from './timelogs/timelogs.mappings';
import { integratedEmailsMappings } from './integrated-emails/integrated-emails.mappings';
import { userMappings } from './user/user.mappings';
import { contactMappings } from './contacts/contacts.mappings';
import { addressMappings } from './addresses/addresses.mappings';
import { AccountsPermissions } from './accounts/accounts.permissions';

const PermissionRoleMap: any = {
  ...jobMappings,
  ...accountsMappings,
  ...analyticsMappings,
  ...customersMappings,
  ...dashboardMappings,
  ...dealsMappings,
  ...documentsMappings,
  ...jobMappings,
  ...lineItemsMappings,
  ...scheduledEventsMappings,
  ...tasksMappings,
  ...invoicesMappings,
  ...paymentsMappings,
  ...pipelinesMappings,
  ...automationsMappings,
  ...suppliersMappings,
  ...materialsMappings,
  ...uploadsMappings,
  ...materialsListMappings,
  ...proposalsMappings,
  ...taxRateMappings,
  ...userAssignmentsMappings,
  ...servicePlanMappings,
  ...dispatchMappings,
  ...templatesMappings,
  ...timelogsMappings,
  ...integratedEmailsMappings,
  ...userMappings,
  ...contactMappings,
  ...addressMappings,
};

export const getUserRoles = (
  subscriptionLevel: SubscriptionLevels,
  role: DefaultSystemRoles,
  features: {
    subscriptionFeatureNames?: Array<SusbcriptionFeatureName>;
    addOnProductNames?: Array<string>;
  },
  isExpired = false
) => {
  const roles = [...(roleInheritanceMappings[subscriptionLevel]?.[role] || [])];
  features.addOnProductNames?.forEach((name) => {
    const x = rolesByAddOnProductNameAndSystemRole[name]?.[role];
    if (x) {
      roles.push(...(x as any)); // vomit
    }
  });
  features.subscriptionFeatureNames?.forEach((name) => {
    const x = rolesBySubscriptionFeatureNameAndSystemRole[name]?.[role];
    if (x) {
      roles.push(...(x as any)); // vomit
    }
  });
  return isExpired
    ? roles.filter((role) => expiredTrialRoles.includes(role as any))
    : roles;
};

export const userHasRequiredPermission = (
  userRoles: string[],
  permissions: string[]
) => {
  // we'll loop through the required permissions for this endpoint to ensure our user has a role compatible with AT LEAST ONE of the required permissions (doesn't need all!)
  return !!permissions.find((permission: string) => {
    // the PermissionRoleMap gives an array of roles that will grant access, keyed by permission - we'll check our roles against this array to see if we have a suitable match
    return !!(PermissionRoleMap[permission] || []).find(
      (allowableRole: string) => {
        // is this role within our userRoles? - if so we're good to progress
        return userRoles.includes(allowableRole);
      }
    );
  });
};

export const getPermissionsFromRoles = (roles: string[]): string[] => {
  const permissions: string[] = [];

  Object.entries(PermissionRoleMap as Record<string, string[]>).forEach(
    ([key, value]) => {
      const hasRole = !!value.find((role) => roles.includes(role));
      if (hasRole) {
        permissions.push(key);
      }
    }
  );

  return permissions;
};

export const userHasAllRequiredPermissions = (
  userRoles: string[],
  permissions: string[]
) =>
  permissions.every((permission) => {
    const requiredRoles: Array<string> = PermissionRoleMap[permission] || [];
    return requiredRoles.every((role) => userRoles.includes(role));
  });

export const userHasRequiredRole = (userRoles: string[], roles: string[]) => {
  return !!roles.find((role: string) => {
    return userRoles.includes(role);
  });
};

export const getUserSubscriptionLevelFromSubscriptionName = (
  userSubscriptionName?: string
) => {
  switch (true) {
    case userSubscriptionName?.includes('Business'):
      return SubscriptionLevels.BUSINESS;
    case userSubscriptionName?.includes('Team'):
      return SubscriptionLevels.TEAM;
    case userSubscriptionName?.includes('Pro'):
      return SubscriptionLevels.PRO;
    default:
      return SubscriptionLevels.SOLO;
  }
};

export const userHasRequiredSubscription = (
  userSubscription: any,
  minimumAllowableSubscription: SubscriptionLevels
) => {
  // are they on a trial?
  if (!userSubscription) return SubscriptionLevels.BUSINESS;
  // evaluate their subscription level
  const userSubscriptionLevel = getUserSubscriptionLevelFromSubscriptionName(
    userSubscription?.name
  );

  switch (minimumAllowableSubscription) {
    case SubscriptionLevels.BUSINESS:
      return userSubscriptionLevel === SubscriptionLevels.BUSINESS;
    case SubscriptionLevels.TEAM:
      return [SubscriptionLevels.TEAM, SubscriptionLevels.BUSINESS].includes(
        userSubscriptionLevel
      );
    default:
      return true;
  }
};
