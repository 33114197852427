import { action } from 'typesafe-actions';

import { ListedMaterialListViewPage } from '@payaca/types/listedMaterialTypes';
import {
  ActionType,
  GetListedMaterialsRequestData,
} from './listedMaterialsTypes';

export const requestGetListedMaterialsPage = (
  getListedMaterialsRequestData: GetListedMaterialsRequestData
) => {
  return action(ActionType.REQUEST_GET_LISTED_MATERIALS_PAGE, {
    getListedMaterialsRequestData,
  });
};

export const clearListedMaterialsPage = () => {
  return action(ActionType.CLEAR_LISTED_MATERIALS_PAGE);
};

export const getListedMaterialsPageSuccess = (
  listedMaterialsPage: ListedMaterialListViewPage
) => {
  return action(ActionType.GET_LISTED_MATERIALS_PAGE_SUCCESS, {
    listedMaterialsPage,
  });
};

export const getListedMaterialsPageFailure = (error: Error) => {
  return action(
    ActionType.GET_LISTED_MATERIALS_PAGE_FAILURE,
    null,
    null,
    error
  );
};
