import { FC } from 'react';

import Badge from '@payaca/components/plBadge/Badge';

export const SupplierBadges: FC<{
  suppliers: { name: string; id: string }[];
}> = ({ suppliers }) => {
  const maxSuppliersToShow = 2;
  const suppliersToShow = suppliers.slice(0, maxSuppliersToShow);
  const remainingSuppliers = suppliers.length - maxSuppliersToShow;
  return (
    <span className="inline-flex gap-x-1">
      {suppliersToShow.map((supplier) => (
        <Badge key={supplier.id} variant="soft">
          {supplier.name}
        </Badge>
      ))}
      {remainingSuppliers > 0 && (
        <Badge variant="soft">+{remainingSuppliers}</Badge>
      )}
    </span>
  );
};
