import { TasksPermissions } from './tasks.permissions';
import { TasksRoles } from './tasks.roles';

export const tasksMappings = {
  [TasksPermissions.GET_SELF_ASSIGNED_TASKS]: [TasksRoles.ASSIGNEE_VIEWER],
  [TasksPermissions.GET_SELF_ASSIGNED_DEAL_TASKS]: [
    TasksRoles.DEAL_ASSIGNEE_VIEWER,
  ],
  [TasksPermissions.GET_TASKS]: [TasksRoles.VIEWER],
  [TasksPermissions.DELETE_TASK]: [TasksRoles.DELETER],
  [TasksPermissions.PERSIST_TASK]: [TasksRoles.PERSISTER],
  [TasksPermissions.COMPLETE_SELF_ASSIGNED_TASK]: [
    TasksRoles.ASSIGNEE_COMPLETER,
  ],
  [TasksPermissions.COMPLETE_SELF_ASSIGNED_DEAL_TASK]: [
    TasksRoles.DEAL_ASSIGNEE_COMPLETER,
  ],
  [TasksPermissions.COMPLETE_TASK]: [TasksRoles.COMPLETER],
  [TasksPermissions.PERSIST_SELF_CREATED_TASK]: [TasksRoles.CREATOR_PERSISTER],
  [TasksPermissions.DELETE_SELF_CREATED_TASK]: [TasksRoles.CREATOR_DELETER],
  [TasksPermissions.PERSIST_SELF_ASSIGNED_DEAL_TASK]: [
    TasksRoles.DEAL_ASSIGNEE_PERSISTER,
  ],
  [TasksPermissions.GET_SELF_ASSIGNED_EVENT_TASKS]: [
    TasksRoles.EVENT_ASSIGNEE_VIEWER,
  ],
  [TasksPermissions.COMPLETE_SELF_ASSIGNED_EVENT_TASKS]: [
    TasksRoles.EVENT_ASSIGNEE_COMPLETER,
  ],
};
