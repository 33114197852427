import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  UnarchiveTagMutation,
  UnarchiveTagMutationVariables,
} from '@/gql/graphql';

const UnarchiveTag = graphql(`
  mutation UnarchiveTag($input: UnarchiveTagInput!) {
    unarchiveTag(input: $input) {
      id
    }
  }
`);

const useUnarchiveTag = (
  options?: UseMutationOptions<
    UnarchiveTagMutation,
    unknown,
    UnarchiveTagMutationVariables['input']
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UnarchiveTag, {
        input,
      });
    },
    ...options,
  });
};

export default useUnarchiveTag;
