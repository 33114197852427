import React, { FC } from 'react';
import TooltipUI from '@material-ui/core/Tooltip';

import './Tooltip.sass';
import { InfoCircle } from 'react-iconly';
type Props = {
  text: string | JSX.Element;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  children?: JSX.Element;
};
const Tooltip: FC<React.PropsWithChildren<Props>> = ({
  text,
  placement = 'top',
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <TooltipUI
      title={text}
      arrow
      placement={placement}
      className="tooltip-wrapper"
      enterTouchDelay={0}
    >
      <span>
        {children || (
          <span className="info-circle-container tooltip-icon">
            <InfoCircle size={16} set="light" />
          </span>
        )}
      </span>
    </TooltipUI>
  );
};

export default Tooltip;
