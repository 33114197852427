import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { useCallback, useEffect, useRef, useState } from 'react';

type FieldValidator = {
  validate: (x: any) => boolean;
  error: string;
};

type FormValidationResult<TFormState> = Partial<
  Record<keyof TFormState, FieldValidationResult>
>;

export const useFormState = <
  TFormState,
  TFormValidationResult extends
    FormValidationResult<TFormState> = FormValidationResult<TFormState>,
>(
  initialState: TFormState,
  fieldValidators: Partial<Record<keyof TFormState, Array<FieldValidator>>>,
  options?: {
    additionalValidation?: (
      prev: TFormValidationResult,
      changedFields: Partial<TFormState>
    ) => TFormValidationResult | undefined;
    autoSaveFn?: (state: TFormState) => Promise<void>;
    autoSaveDebounceMillis?: number;
  }
) => {
  const [formState, setFormState] = useState(initialState);
  const [dirtiness, setDirtiness] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [requiresSaveAndFlush, setRequiresSaveAndFlush] = useState(false);

  const [formValidationResult, setFormValidationResult] = useState(
    {} as TFormValidationResult
  );

  const changeTimeout = useRef<number>();

  const updateFormFields = useCallback(
    (changedFields: Partial<TFormState>) => {
      setFormState((prev) => ({
        ...prev,
        ...changedFields,
      }));
      setDirtiness((d) => d + 1);
      setFormValidationResult((prev) => ({
        ...prev,
        ...Object.fromEntries(
          Object.entries(changedFields).map(([key, value]) => {
            const validators =
              fieldValidators[key as keyof typeof fieldValidators] ?? [];
            return [
              key,
              validators.reduce(
                (acc, validator) => {
                  const isValid = validator.validate(value);
                  if (!isValid) {
                    return {
                      isValid: false,
                      errors: [...acc.errors, validator.error],
                    };
                  }
                  return acc;
                },
                { isValid: true as boolean, errors: [] as Array<string> }
              ),
            ];
          })
        ),
        ...options?.additionalValidation?.(prev, changedFields),
      }));
    },
    [fieldValidators, options?.additionalValidation]
  );

  // const touchFormFields = useCallback((formFields: (keyof TFormState)[]) => {
  //   if (formFields.length) {
  //     // force save here?
  //   }
  // },
  // []);

  useEffect(() => {
    if (!dirtiness) return;

    if (changeTimeout.current) {
      window.clearTimeout(changeTimeout.current);
    }

    if (options?.autoSaveFn) {
      const isFormValid = Object.values(
        formValidationResult as Record<any, FieldValidationResult>
      ).every((fieldValidationResult) => fieldValidationResult.isValid);
      if (isFormValid) {
        changeTimeout.current = window.setTimeout(
          () => {
            if (!isSaving && isFormValid) {
              setIsSaving(true);
              options
                ?.autoSaveFn?.(formState)
                .then(() => {
                  setDirtiness((d) => d - dirtiness);
                })
                .catch((err) => {
                  // swallow error
                })
                .finally(() => {
                  setIsSaving(false);
                });
            }
          },
          options?.autoSaveDebounceMillis ?? 500
        );
      }
    }
  }, [
    options?.autoSaveFn,
    options?.autoSaveDebounceMillis,
    formValidationResult,
    dirtiness,
  ]);

  const onSaveAndFlush = useCallback(() => {
    if (!dirtiness) return;

    if (options?.autoSaveFn) {
      const isFormValid = Object.values(
        formValidationResult as Record<any, FieldValidationResult>
      ).every((fieldValidationResult) => fieldValidationResult.isValid);
      if (isFormValid) {
        if (changeTimeout.current) {
          window.clearTimeout(changeTimeout.current);
        }
        if (isFormValid) {
          setIsSaving(true);
          options
            ?.autoSaveFn?.(formState)
            .then(() => {
              setDirtiness((d) => {
                return d - dirtiness;
              });
            })
            .catch((err) => {
              // swallow error
            })
            .finally(() => {
              setIsSaving(false);
            });
        }
      }
    }
  }, [
    options?.autoSaveFn,
    options?.autoSaveDebounceMillis,
    formValidationResult,
    dirtiness,
  ]);

  useEffect(() => {
    if (requiresSaveAndFlush) {
      onSaveAndFlush();
      setRequiresSaveAndFlush(false);
    }
  }, [requiresSaveAndFlush]);

  return {
    formState,
    updateFormFields,
    formValidationResult,
    setFormValidationResult,
    isSaving,
    saveAndFlush: () => setRequiresSaveAndFlush(true),
    isDirty: dirtiness > 0,
  };
};
