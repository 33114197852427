import {
  takeEvery,
  call,
  put,
  race,
  delay,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import { ResendJobEmailPayload } from '@payaca/types/jobActionsTypes';
import {
  SagaConfig,
  ActionType,
  UpdateJobAttachmentRequestData,
} from './jobsTypes';
import { UpdateJobRequestData } from '@payaca/types/jobRequestTypes';

import {
  editJobFailure,
  editJobSuccess,
  markJobAsSentSuccess,
  markJobAsSentFailure,
  resendJobFailure,
  resendJobSuccess,
  clearJobs,
  getJobFailure,
  getJobSuccess,
  getJobsForDealSuccess,
  getJobsForDealFailure,
  updateJobFailure,
  updateJobSuccess,
  clearJobAttachments,
  getJobValidationResultFailure,
  getJobValidationResultSuccess,
  updateJobAttachmentFailure,
  updateJobAttachmentSuccess,
  sendQuoteToCustomerFailure,
  sendQuoteToCustomerSuccess,
  sendInvoiceToCustomerFailure,
  sendInvoiceToCustomerSuccess,
  archiveJobSuccess,
  archiveJobFailure,
  acceptQuoteFailure,
  acceptQuoteSuccess,
  declineJobFailure,
  declineJobSuccess,
  convertJobToInvoiceFailure,
  convertJobToInvoiceSuccess,
  duplicateJobFailure,
  duplicateJobSuccess,
  createJobFailure,
  createJobSuccess,
  getJobAttachmentsForJobSuccess,
  partialUpdateJob,
} from './jobsActions';

import { Req } from '@payaca/helpers/storeHelper';

import { Job } from '@payaca/types/jobTypesV2';
import { handleAsyncAction } from '../utils';
import { JobCostsMargins } from '@payaca/types/jobTypes';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const jobsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleResendJob(
    action: PayloadAction<
      ActionType.REQUEST_RESEND_JOB,
      {
        jobId: number;
        params: ResendJobEmailPayload;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(resendJob, action.payload.jobId, action.payload.params),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Resend job timed out.';
        yield put(resendJobFailure(new Error(errorMessage), errorMessage));
      } else {
        yield put(resendJobSuccess());
      }
    } catch (error: any) {
      yield put(resendJobFailure(error, error.message));
    }
  }
  function* handleCreateJob(
    action: PayloadAction<
      ActionType.REQUEST_CREATE_JOB,
      {
        isProtoInvoice?: boolean;
        isProposition: boolean;
        dealId: number;
        callback: (error: string | null, response?: any) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          createJob,
          action.payload.isProposition,
          action.payload.dealId,
          action.payload.isProtoInvoice
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Create job timed out.';
        yield put(createJobFailure(new Error(errorMessage), errorMessage));
        action.payload.callback(errorMessage);
      } else {
        yield put(createJobSuccess());
        action.payload.callback(null, response);
      }
    } catch (error: any) {
      yield put(createJobFailure(error, error.message));
      action.payload.callback(error.message);
    }
  }

  function* handleMarkJobAsSent(
    action: PayloadAction<
      ActionType.REQUEST_MARK_JOB_AS_SENT,
      {
        jobId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(markJobAsSent, action.payload.jobId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Mark job as sent timed out.';
        yield put(markJobAsSentFailure(new Error(errorMessage), errorMessage));
      } else {
        yield put(markJobAsSentSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(markJobAsSentFailure(error, error.message));
    }
  }

  function* handleEditJob(
    action: PayloadAction<ActionType.REQUEST_EDIT_JOB, { jobId: number }>
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(editJob, action.payload.jobId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Edit job timed out.';
        yield put(editJobFailure(new Error(errorMessage), errorMessage));
      } else {
        yield put(editJobSuccess(response));
      }
    } catch (error: any) {
      yield put(editJobFailure(error, error.message));
    }
  }

  function* handleGetJob(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB,
      {
        jobId: number;
        callback: (job: Job) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getJob, action.payload.jobId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get job timed out.';
        yield put(getJobFailure(action.payload.jobId, new Error(errorMessage)));
      } else {
        yield put(getJobSuccess(action.payload.jobId, response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(getJobFailure(action.payload.jobId, error));
    }
  }

  function* handleUpdateJob(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_JOB,
      {
        callback: () => void;
        updateJobRequestData: UpdateJobRequestData;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { timeout } = yield race({
        response: call(updateJob, action.payload.updateJobRequestData),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Update job timed out.';
        yield put(
          updateJobFailure(
            action.payload.updateJobRequestData.jobId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(updateJobSuccess(action.payload.updateJobRequestData.jobId));
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(
        updateJobFailure(action.payload.updateJobRequestData.jobId, error)
      );
    }
  }
  function* handleSendQuoteToCustomer(
    action: PayloadAction<
      ActionType.REQUEST_SEND_QUOTE_TO_CUSTOMER,
      {
        jobId: number;
        sendQuoteRequestData: any;
        callback: (error?: string) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { timeout } = yield race({
        response: call(
          sendQuoteToCustomer,
          action.payload.jobId,
          action.payload.sendQuoteRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Send quote to customer timed out.';
        yield put(
          sendQuoteToCustomerFailure(new Error(errorMessage), errorMessage)
        );
        action.payload.callback && action.payload.callback(errorMessage);
      } else {
        yield put(sendQuoteToCustomerSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(sendQuoteToCustomerFailure(error, error.mesasge));
      action.payload.callback && action.payload.callback(error.message);
    }
  }
  function* handleSendInvoiceToCustomer(
    action: PayloadAction<
      ActionType.REQUEST_SEND_INVOICE_TO_CUSTOMER,
      {
        jobId: number;
        sendInvoiceRequestData: any;
        callback: (error?: string) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { timeout } = yield race({
        response: call(
          sendInvoiceToCustomer,
          action.payload.jobId,
          action.payload.sendInvoiceRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Send invoice to customer timed out.';
        yield put(
          sendInvoiceToCustomerFailure(new Error(errorMessage), errorMessage)
        );
        action.payload.callback && action.payload.callback(errorMessage);
      } else {
        yield put(sendInvoiceToCustomerSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(sendInvoiceToCustomerFailure(error, error.message));
      action.payload.callback && action.payload.callback(error.message);
    }
  }
  function* handleArchiveJob(
    action: PayloadAction<
      ActionType.REQUEST_ARCHIVE_JOB,
      {
        jobId: number;
        callback: (error?: string) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { timeout } = yield race({
        response: call(archiveJob, action.payload.jobId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Archive job timed out.';
        yield put(archiveJobFailure(new Error(errorMessage), errorMessage));
        action.payload.callback && action.payload.callback(errorMessage);
      } else {
        yield put(archiveJobSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(archiveJobFailure(error, error.message));
      action.payload.callback && action.payload.callback(error.message);
    }
  }
  const archiveJob = async (jobId: number) => {
    const response = await req.put(`/jobs/${jobId}/archive`);
    return response;
  };

  function* handleAcceptQuote(
    action: PayloadAction<
      ActionType.REQUEST_ACCEPT_QUOTE,
      {
        jobId: number;
        callback: (error?: string) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { timeout } = yield race({
        response: call(acceptQuote, action.payload.jobId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Accept quote timed out.';
        yield put(acceptQuoteFailure(new Error(errorMessage), errorMessage));
        action.payload.callback && action.payload.callback(errorMessage);
      } else {
        yield put(acceptQuoteSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(acceptQuoteFailure(error, error.message));
      action.payload.callback && action.payload.callback(error.message);
    }
  }

  const acceptQuote = async (jobId: number) => {
    const response = await req.put(`/jobs/${jobId}/accept`);
    return response;
  };

  function* handleDeclineJob(
    action: PayloadAction<
      ActionType.REQUEST_DECLINE_JOB,
      {
        jobId: number;
        callback: (error?: string) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { timeout } = yield race({
        response: call(declineJob, action.payload.jobId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Decline job timed out.';
        yield put(declineJobFailure(new Error(errorMessage), errorMessage));
        action.payload.callback && action.payload.callback(errorMessage);
      } else {
        yield put(declineJobSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(declineJobFailure(error, error.message));
      action.payload.callback && action.payload.callback(error.message);
    }
  }

  const declineJob = async (jobId: number) => {
    const response = await req.put(`/jobs/${jobId}/decline`);
    return response;
  };

  function* handleConvertJobToInvoice(
    action: PayloadAction<
      ActionType.REQUEST_CONVERT_JOB_TO_INVOICE,
      {
        jobId: number;
        sendInvoiceRequestData: any;
        callback: (error: string | null, response?: any) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(convertJobToInvoice, action.payload.jobId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Convert job to invoice timed out.';
        yield put(
          convertJobToInvoiceFailure(new Error(errorMessage), errorMessage)
        );
        action.payload.callback && action.payload.callback(errorMessage);
      } else {
        yield put(convertJobToInvoiceSuccess());
        action.payload.callback && action.payload.callback(null, response);
      }
    } catch (error: any) {
      yield put(convertJobToInvoiceFailure(error, error.message));
      action.payload.callback && action.payload.callback(error.message);
    }
  }

  const convertJobToInvoice = async (jobId: number) => {
    const response = await req.put(`/jobs/${jobId}/convert_to_invoice`);
    return await response.json();
  };

  function* handleDuplicateJob(
    action: PayloadAction<
      ActionType.REQUEST_DUPLICATE_JOB,
      {
        jobId: number;
        callback: (error: string | null, response?: any) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(duplicateJob, action.payload.jobId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Duplicate job timed out.';
        yield put(duplicateJobFailure(new Error(errorMessage), errorMessage));
        action.payload.callback && action.payload.callback(errorMessage);
      } else {
        yield put(duplicateJobSuccess());
        action.payload.callback && action.payload.callback(null, response);
      }
    } catch (error: any) {
      yield put(duplicateJobFailure(error, error.message));
      action.payload.callback && action.payload.callback(error.message);
    }
  }
  const duplicateJob = async (jobId: number) => {
    const response = await req.post(`/jobs/${jobId}/duplicate`, {});
    return await response.json();
  };

  function* handleGetJobsForDeal(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOBS_FOR_DEAL,
      {
        dealId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getJobsForDeal, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getJobsForDeal request timed out');
      }

      yield put(getJobsForDealSuccess(response));
    } catch (error: any) {
      yield put(getJobsForDealFailure(error));
    }
  }

  function* handleGetJobAttachmentsForJob(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB_ATTACHMENTS_FOR_JOB,
      {
        jobId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getJobAttachmentsForJob, action.payload.jobId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getJobAttachmentsForJob request timed out');
      }

      yield put(getJobAttachmentsForJobSuccess(response));
    } catch (error: any) {}
  }

  function* handleUpdateJobAttachment(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_JOB_ATTACHMENT,
      {
        updateJobAttachmentRequestData: UpdateJobAttachmentRequestData;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(
          updateJobAttachment,
          action.payload.updateJobAttachmentRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });
      if (timeout) {
        const errorMessage = 'Update job attachment timed out.';
        yield put(
          updateJobAttachmentFailure(
            action.payload.updateJobAttachmentRequestData.jobAttachmentId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(
          updateJobAttachmentSuccess(
            action.payload.updateJobAttachmentRequestData.jobAttachmentId
          )
        );
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(
        updateJobAttachmentFailure(
          action.payload.updateJobAttachmentRequestData.jobAttachmentId,
          error
        )
      );
    }
  }

  function* handleGetJobValidationResult(
    action: PayloadAction<
      ActionType.REQUEST_GET_JOB_VALIDATION_RESULT,
      {
        jobId: number;
        callback: (validationResult: {
          isValid: boolean;
          errors: string[];
        }) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getJobValidationResult, action.payload.jobId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get job validation result timed out.';
        yield put(getJobValidationResultFailure(new Error(errorMessage)));
      } else {
        yield put(getJobValidationResultSuccess(response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(getJobValidationResultFailure(error));
    }
  }

  const updateJobAttachment = async (
    updateJobAttachmentRequestData: UpdateJobAttachmentRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/jobs/${updateJobAttachmentRequestData.jobId}/job_attachments/${updateJobAttachmentRequestData.jobAttachmentId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
        body: JSON.stringify(updateJobAttachmentRequestData),
      }
    ).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `updateJobAttachment failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJobValidationResult = async (jobId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/jobs/${jobId}/validation_result`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobValidationResult failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJobAttachmentsForJob = async (jobId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/jobs/${jobId}/job_attachments`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobAttachmentsForJob failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJobsForDeal = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/jobs/deal/${dealId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJobsForDeal failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const resendJob = async (jobId: number, params: ResendJobEmailPayload) => {
    const response = await req.post(`/jobs/${jobId}/resend`, params);
    return await response;
  };

  const markJobAsSent = async (jobId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/jobs/${jobId}/mark_as_sent`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        const payload = await response.json();
        throw new Error(payload.errors);
      }
    });
  };

  const updateJob = async (updateJobRequestData: UpdateJobRequestData) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/jobs/${updateJobRequestData.jobId}/v2`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
      body: JSON.stringify(updateJobRequestData),
    }).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `updateJob failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getJob = async (jobId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/jobs/${jobId}/v2`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getJob failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const editJob = async (jobId: number) => {
    const response = await req.put(`/jobs/${jobId}/edit`);
    return await response.json();
  };
  const createJob = async (
    isProposition: boolean,
    dealId: number,
    isProtoInvoice?: boolean
  ) => {
    console.log(
      '>>>>>>> ~ file: jobsSaga.ts ~ line 781 ~ createJob ~ dealId',
      dealId
    );
    console.log(
      '>>>>>>> ~ file: jobsSaga.ts ~ line 781 ~ createJob ~ isProposition',
      isProposition
    );
    const payload: {
      dealId: number;
      status?: string;
      isProtoInvoice?: boolean;
    } = { dealId, isProtoInvoice };
    // set dueInOrValidForDays to account setting in BE
    if (isProposition) {
      payload.status = 'new_quote';
    } else {
      payload.status = 'new_invoice';
    }

    const response = await req.post('/jobs', payload);
    return await response.json();
  };

  const sendQuoteToCustomer = async (
    jobId: number,
    sendQuoteRequestData: any
  ) => {
    const response = await req.post(
      `/jobs/${jobId}/send_quote_v2`,
      sendQuoteRequestData
    );
    return await response;
  };
  const sendInvoiceToCustomer = async (
    jobId: number,
    sendInvoiceRequestData: any
  ) => {
    const response = await req.post(
      `/jobs/${jobId}/send_invoice_v2`,
      sendInvoiceRequestData
    );
    return response;
  };

  const handlePartialUpdateJob = handleAsyncAction(
    partialUpdateJob,
    async (args) => {
      const response = await req.patch(`/jobs/${args.jobId}`, args.data);
      return { jobId: args.jobId };
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_err, requestData) => {
      requestData.payload.onErrorCallback?.(_err);
    }
  );

  function* handleAppLogout() {
    yield put(clearJobs());
    yield put(clearJobAttachments());
  }

  return function* () {
    yield takeEvery(ActionType.REQUEST_MARK_JOB_AS_SENT, handleMarkJobAsSent);
    yield takeEvery(ActionType.REQUEST_RESEND_JOB, handleResendJob);
    yield takeEvery(ActionType.REQUEST_EDIT_JOB, handleEditJob);
    yield takeEvery(ActionType.REQUEST_GET_JOB, handleGetJob);

    yield takeLatest(ActionType.REQUEST_UPDATE_JOB, handleUpdateJob);
    yield takeLatest(
      ActionType.REQUEST_GET_JOBS_FOR_DEAL,
      handleGetJobsForDeal
    );
    yield takeLatest(
      ActionType.REQUEST_GET_JOB_ATTACHMENTS_FOR_JOB,
      handleGetJobAttachmentsForJob
    );

    yield takeEvery(
      ActionType.REQUEST_GET_JOB_VALIDATION_RESULT,
      handleGetJobValidationResult
    );
    yield takeEvery('auth.logout', handleAppLogout);

    yield takeEvery(
      ActionType.REQUEST_UPDATE_JOB_ATTACHMENT,
      handleUpdateJobAttachment
    );
    yield takeEvery(
      ActionType.REQUEST_SEND_QUOTE_TO_CUSTOMER,
      handleSendQuoteToCustomer
    );
    yield takeEvery(
      ActionType.REQUEST_SEND_INVOICE_TO_CUSTOMER,
      handleSendInvoiceToCustomer
    );
    yield takeEvery(ActionType.REQUEST_ARCHIVE_JOB, handleArchiveJob);
    yield takeEvery(ActionType.REQUEST_ACCEPT_QUOTE, handleAcceptQuote);
    yield takeEvery(ActionType.REQUEST_DECLINE_JOB, handleDeclineJob);
    yield takeEvery(
      ActionType.REQUEST_CONVERT_JOB_TO_INVOICE,
      handleConvertJobToInvoice
    );
    yield takeEvery(ActionType.REQUEST_DUPLICATE_JOB, handleDuplicateJob);
    yield takeEvery(ActionType.REQUEST_CREATE_JOB, handleCreateJob);

    yield takeEvery(
      ActionType.PARTIAL_UPDATE_JOB_REQUEST,
      handlePartialUpdateJob
    );
  };
};

export default jobsSagaCreator;
