export interface JobLineItemAttachment {
  attachmentUrl: string;
  originalAttachmentUrl: string;
  fileName: string;
  id: number;
}

export interface JobAttachment {
  attachmentUrl: string;
  createdAt: string;
  fileName: string;
  id: string;
  job_id: number;
  updatedAt: string;
}

export type JobType = 'Quote' | 'Invoice' | 'Estimate' | 'Job';

export enum JobStatus {
  NEW_ESTIMATE = 'new_estimate',
  ESTIMATED = 'estimated',
  NEW_QUOTE = 'new_quote',
  QUOTED = 'quoted',
  ACCEPTED = 'accepted', // LEGACY: Not being used to track accepted state of job anymore
  NEW_INVOICE = 'new_invoice',
  INVOICED = 'invoiced',
  PAID = 'paid',
}

export enum ReadableJobStatus {
  ARCHIVED = 'Archived',
  INACTIVE = 'Inactive',
  PAID = 'Paid',
  DEPOSIT_PAID = 'Deposit paid',
  DEPOSIT_DUE = 'Deposit due',
  PAYMENT_PENDING = 'Payment pending',
  OVERDUE = 'Overdue',
  ACCEPTED = 'Accepted',
  DECLINED = 'Declined',
  BOUNCED = 'Bounced',
  VIEWED = 'Viewed',
  SENT = 'Sent',
  DRAFT = 'Draft',
  EXPIRED = 'Expired',
}

export type BaseJobCostsMargins = {
  cost: number;
  marginAmount: number;
  marginPercentage: number;
};

export interface JobCostsMargins extends BaseJobCostsMargins {
  hasMissingItemMaterials: boolean;
  min?: BaseJobCostsMargins;
  max?: BaseJobCostsMargins;
}
