import { Customer, CustomerContact } from '@payaca/types/customerTypes';
import {
  ScheduledEvent,
  SimpleEventContactWithRoles,
  TContactToNotify,
} from '@payaca/types/scheduledEventsTypes';
import moment from 'moment-timezone';
import { getPrimaryContactFromCustomer } from './customerHelper';
import { Contact } from '@payaca/types/contactTypes';

enum EventStatus {
  PAST = 'PAST',
  PRESENT = 'PRESENT',
  FUTURE = 'FUTURE',
}

export const groupScheduledEventsByDate = (
  scheduledEvents: ScheduledEvent[]
): { groupName: string; scheduledEvents: ScheduledEvent[] }[] => {
  const nowEvents: ScheduledEvent[] = [];
  const todayEvents: ScheduledEvent[] = [];
  const tomorrowEvents: ScheduledEvent[] = [];
  const thisWeekEvents: ScheduledEvent[] = [];
  const nextWeekEvents: ScheduledEvent[] = [];
  const thisMonthEvents: ScheduledEvent[] = [];
  const nextMonthEvents: ScheduledEvent[] = [];
  const futureEvents: ScheduledEvent[] = [];
  const pastEvents: ScheduledEvent[] = [];

  const now = new Date();
  const nowMoment = moment(now);

  const endOfToday = moment(now).endOf('day');
  const endOfTomorrow = moment(now).add(1, 'days').endOf('day');
  const endOfThisWeek = moment(now).endOf('week');
  const endOfNextWeek = moment(now).add(1, 'week').endOf('week');
  const endOfThisMonth = moment(now).endOf('month');
  const endOfNextMonth = moment(now).add(1, 'month').endOf('month');

  scheduledEvents.forEach((scheduledEvent: ScheduledEvent) => {
    const beginAtMoment = moment(scheduledEvent.beginAt);
    const endAtMoment = moment(scheduledEvent.endAt);

    if (beginAtMoment.isBefore(nowMoment)) {
      if (endAtMoment.isAfter(nowMoment)) {
        nowEvents.push(scheduledEvent);
      } else {
        pastEvents.push(scheduledEvent);
      }
    } else if (beginAtMoment.isBefore(endOfToday)) {
      todayEvents.push(scheduledEvent);
    } else if (beginAtMoment.isBefore(endOfTomorrow)) {
      tomorrowEvents.push(scheduledEvent);
    } else if (beginAtMoment.isBefore(endOfThisWeek)) {
      thisWeekEvents.push(scheduledEvent);
    } else if (beginAtMoment.isBefore(endOfNextWeek)) {
      nextWeekEvents.push(scheduledEvent);
    } else if (beginAtMoment.isBefore(endOfThisMonth)) {
      thisMonthEvents.push(scheduledEvent);
    } else if (beginAtMoment.isBefore(endOfNextMonth)) {
      nextMonthEvents.push(scheduledEvent);
    } else {
      futureEvents.push(scheduledEvent);
    }
  });

  const groupedScheduledEvents: {
    groupName: string;
    scheduledEvents: ScheduledEvent[];
  }[] = [];

  groupedScheduledEvents.push({
    groupName: 'Past',
    scheduledEvents: pastEvents,
  });
  groupedScheduledEvents.push({
    groupName: 'Now',
    scheduledEvents: nowEvents,
  });
  groupedScheduledEvents.push({
    groupName: 'Today',
    scheduledEvents: todayEvents,
  });
  groupedScheduledEvents.push({
    groupName: 'Tomorrow',
    scheduledEvents: tomorrowEvents,
  });
  groupedScheduledEvents.push({
    groupName: 'This week',
    scheduledEvents: thisWeekEvents,
  });
  groupedScheduledEvents.push({
    groupName: 'Next week',
    scheduledEvents: nextWeekEvents,
  });
  groupedScheduledEvents.push({
    groupName: 'This month',
    scheduledEvents: thisMonthEvents,
  });
  groupedScheduledEvents.push({
    groupName: 'Next month',
    scheduledEvents: nextMonthEvents,
  });
  groupedScheduledEvents.push({
    groupName: 'Upcoming',
    scheduledEvents: futureEvents,
  });

  return groupedScheduledEvents.filter((x) => x.scheduledEvents.length);
};

export const isEventAllDay = (beginAt: Date, endAt: Date) => {
  const beginAtMoment = moment(beginAt);
  const endAtMoment = moment(endAt);
  return (
    beginAtMoment.format('HH:mm') === '00:00' &&
    endAtMoment.format('HH:mm') === '00:00'
  );
};

export const getEventStatus = (beginAt: Date, endAt: Date) => {
  if (moment(beginAt).isBefore(moment())) {
    if (moment(endAt).isBefore(moment())) {
      return EventStatus.PAST;
    } else {
      return EventStatus.PRESENT;
    }
  } else {
    return EventStatus.FUTURE;
  }
};

export const sortedSchededuledEvents = (scheduledEvents: ScheduledEvent[]) => {
  return scheduledEvents.reduce(
    (
      acc: {
        past: ScheduledEvent[];
        present: ScheduledEvent[];
        future: ScheduledEvent[];
      },
      curr: ScheduledEvent
    ) => {
      const eventStatus = getEventStatus(curr.beginAt, curr.endAt);
      if (eventStatus === EventStatus.PAST) {
        acc.past.unshift(curr);
      } else if (eventStatus === EventStatus.PRESENT) {
        acc.present.push(curr);
      } else {
        acc.future.push(curr);
      }
      return acc;
    },
    { past: [], present: [], future: [] }
  );
};

export const getEventStatusBadgeColours = (
  scheduledEventStatus: EventStatus
) => {
  if (scheduledEventStatus === EventStatus.PAST) {
    return {
      backgroundColour: '#607387',
      colour: '#ffffff',
    };
  } else if (scheduledEventStatus === EventStatus.PRESENT) {
    return {
      colour: '#263E59',
      backgroundColour: '#75E582',
    };
  } else {
    return {
      backgroundColour: '#DBE5F0',
      colour: '#263E59',
    };
  }
};

export const getEventStatusLabel = (scheduledEventStatus: EventStatus) => {
  if (scheduledEventStatus === EventStatus.PAST) {
    return 'PAST';
  } else if (scheduledEventStatus === EventStatus.PRESENT) {
    return 'NOW';
  } else {
    return 'UPCOMING';
  }
};

export const getContactsToNotifyFromCustomer = (
  customer: Customer
): TContactToNotify[] => {
  const primaryContact = getPrimaryContactFromCustomer(customer);
  return primaryContact
    ? [
        {
          ...primaryContact,
          type: 'customerContact' as const,
        } as TContactToNotify,
      ]
    : [];
};

export const getContactsToNotifyFromLocationContacts = (
  contacts: Contact[]
) => {
  return contacts
    .filter(
      (c) =>
        c.name?.length && (c.emailAddress?.length || c.telephoneNumber?.length)
    )
    .map((c) => {
      return {
        ...c,
        type: 'contact' as const,
      } as TContactToNotify;
    });
};

export const getContactsToNotify = ({
  customer,
  locationContacts,
}: {
  customer?: Customer;
  locationContacts?: Contact[];
}) => {
  const cc = customer ? getContactsToNotifyFromCustomer(customer) : [];
  const lc = locationContacts
    ? getContactsToNotifyFromLocationContacts(locationContacts)
    : [];

  // we only care about notifying customer contacts if there are no location contacts available
  return lc.length ? lc : cc;
};

export const getSimpleEventContactsWithRoles = (
  scheduledEvent: ScheduledEvent,
  linkedCustomer?: Customer
) => {
  const c: SimpleEventContactWithRoles[] = [];

  const primaryContact = getPrimaryContactFromCustomer(linkedCustomer);

  linkedCustomer?.contacts.forEach((contact) => {
    c.push({
      id: contact.id,
      name: contact.name,
      emailAddress: contact.emailAddress,
      telephoneNumber: contact.telephoneNumber,
      type: 'customerContact',
      description: contact.description,
      roles: primaryContact?.id === contact.id ? ['primaryContact'] : [],
    });
  });

  scheduledEvent?.location?.contacts.forEach((contact) => {
    c.push({
      id: contact.id,
      name: contact.name,
      emailAddress: contact.emailAddress,
      telephoneNumber: contact.telephoneNumber,
      type: 'contact',
      description: contact.description,
      roles: ['accessContact'],
    });
  });

  scheduledEvent?.contactsToNotify?.forEach((contact) => {
    const existing = c.find(
      (x) => x.id === contact.id && x.type === contact.type
    );

    if (existing) {
      existing.roles.push('contactToNotify');
    } else {
      c.push({
        id: contact.id,
        name: contact.name,
        emailAddress: contact.emailAddress,
        telephoneNumber: contact.telephoneNumber,
        type: contact.type,
        description: contact.description,
        roles: ['contactToNotify'],
      });
    }
  });

  return c;
};
