import {
  getAllowEmptyValidator,
  getEmailFieldValidator,
  getIsRequiredFieldValidator,
  getMustBeTruthyValidator,
  getRegexMatchFieldValidator,
} from './fieldValidationHelper';
import { FormElement } from '@payaca/types/formElementTypes';
import {
  AvailableForm,
  FormCategoryType,
  FormOptionGroups,
} from '@payaca/types/formTypes';

export const buildValidation = (
  formElements: FormElement[],
  validationObj: any,
  path: any[] = [],
  formData: any
) => {
  formElements.forEach((formElement: FormElement) => {
    if (formElement.children) {
      buildValidation(
        formElement.children,
        validationObj,
        formElement.id
          ? [
              ...path,
              formElement.id,
              formElement.type === 'multi-items' ? 'X' : undefined,
            ].filter(Boolean)
          : path,
        formData
      );
    }

    if (!formElement.id) {
      return validationObj;
    }

    let validators: any = [];
    if (formElement.validation) {
      validators = formElement.validation
        .map((validator: string) => {
          switch (validator) {
            case 'email':
              return getEmailFieldValidator({
                customErrorMessage: 'This must be a valid e-mail address',
              });
            case 'phone':
              return getRegexMatchFieldValidator(/^[0-9+() -]{3,14}$/, {
                customErrorMessage: 'This must be a valid contact number',
              });
            default:
              return null;
          }
        })
        .filter((fn: any) => !!fn);
    }

    if (formElement.isOptional) {
      validators = validators.map((fn: any) => getAllowEmptyValidator(fn));
    } else {
      // not optional, so it must be "required" (default)
      validators.push(
        formElement.type === 'checkbox'
          ? getMustBeTruthyValidator()
          : getIsRequiredFieldValidator()
      );
    }

    if (path[1] === 'X') {
      for (let i = 0; i < (formData?.[path[0]]?.length || 1); i++) {
        validationObj[
          [path[0], i, ...path.slice(2).filter(Boolean), formElement.id].join(
            '.'
          )
        ] = validators;
      }
      return validationObj;
    }
    return (validationObj[[...path, formElement.id].join('.')] = validators);
  });

  return validationObj;
};

export const sortedAvailableFormOptions = (
  availableForms: AvailableForm[],
  excludeArchived = true
) => {
  return (availableForms || [])
    .filter((x) => !excludeArchived || !x.archivedAt)
    .map(
      ({
        label,
        value,
        category,
      }: {
        label: string;
        value: number;
        category: FormCategoryType;
      }) => {
        const optionGroup = FormOptionGroups.find(
          (optionGroup) => optionGroup.name === category
        );
        return {
          label,
          value,
          optionGroupId: optionGroup ? optionGroup.id : 1,
        };
      }
    )
    .sort((a, b) => (a.optionGroupId < b.optionGroupId ? -1 : 1));
};

const radioAnswerMap: any = {
  yes: 'Yes',
  no: 'No',
  na: 'N/A',
  pass: 'Pass',
  fail: 'Fail',
};

const gcNumberFormatter = (answer: string) => {
  const sect1 = answer.slice(0, 2);
  const sect2 = answer.slice(2, 5);
  const sect3 = answer.slice(5);
  return `${sect1}-${sect2}-${sect3}`;
};

export const getValueFromPath = (data: any, keyPath = 'empty.string') =>
  keyPath.split('.').reduce((acc, path) => {
    if (!acc) return acc;
    acc = acc[path] || '';
    return acc;
  }, data);

export const formatAnswer = (
  data: any,
  keyPath?: string,
  historicKeyPath?: string
) => {
  let answer = data;

  if (keyPath) {
    // historicKeyPath gives us a way to preserve backwards compatability on form template updates
    answer =
      getValueFromPath(data, keyPath) ||
      getValueFromPath(data, historicKeyPath);

    if (keyPath === 'gc_number') {
      return gcNumberFormatter(answer);
    }
  }
  return radioAnswerMap[answer] || answer;
};

export const parseAddress = (address: string) => {
  if (address) {
    const addressArr = address.split(', ');
    const firstLineAddress = addressArr.shift();
    const secondLineAddress = addressArr.join(', ');
    return {
      firstLineAddress,
      secondLineAddress,
    };
  }

  return {
    firstLineAddress: '',
    secondLineAddress: '',
  };
};
