export enum TimelogsPermissions {
  PREVIEW_TIMELOGS = 'timelogs.previewTimelogs',
  GET_TIMELOGS = 'timelogs.getTimelogs',
  GET_SELF_ASSIGNED_TIMELOGS = 'timelogs.getSelfAssignedTimelogs',
  CREATE_TIMELOGS = 'timelogs.createTimelogs',
  CREATE_SELF_ASSIGNED_TIMELOGS = 'timelogs.createSelfAssignedTimelogs',
  UPDATE_TIMELOGS = 'timelogs.updateTimelogs',
  UPDATE_SELF_ASSIGNED_TIMELOGS = 'timelogs.updateSelfAssignedTimelogs',
  DELETE_TIMELOGS = 'timelogs.deleteTimelogs',
  DELETE_SELF_ASSIGNED_TIMELOGS = 'timelogs.deleteSelfAssignedTimelogs',

  GET_TIMELOG_TYPES = 'timelogTypes.getTimelogTypes',
  MANAGE_TIMELOG_TYPES = 'timelogTypes.manageTimelogTypes',

  SET_TIMELOG_COST_PER_HOUR = 'timelogTypes.setTimelogCostPerHour',
  GET_TIMELOG_COST_PER_HOUR = 'timelogTypes.getTimelogCostPerHour',
}
