import React, { FunctionComponent as FC } from 'react';
import { Task } from '@payaca/types/taskTypes';
import useGetTask from '@/api/queries/tasks/useGetTask';

export const MaterialsListTaskMaterialsList: FC<{
  taskId: Task['id'];
}> = ({ taskId }) => {
  const { data } = useGetTask(taskId);

  if (!data || data?.task?.__typename !== 'MaterialsListTask') {
    return null;
  }

  if (!data.task.materialsListMaterials?.length) {
    return <p>No Materials have been added to this Task yet</p>;
  }

  return (
    <ul>
      {data.task.materialsListMaterials.map((mlm, i) => (
        <li key={i}>
          {mlm.quantity}x {mlm.material.name}
        </li>
      ))}
    </ul>
  );
};
