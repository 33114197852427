import React, { FC, useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  HydratedPurchaseOrder,
  PurchaseOrder,
} from '@payaca/types/materialsListTypes';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { getHydratedPurchaseOrder } from '@payaca/store/materialsList/materialsListActions';
import { useHistory } from 'react-router';
import IsolatedPurchaseOrderControl from '@/ui/components/isolatedPurchaseOrderControl/IsolatedPurchaseOrderControl';

type Props = {
  purchaseOrderId: PurchaseOrder['id'];
};

const EditIsolatedPurchaseOrderPage: FC<Props> = ({
  purchaseOrderId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [purchaseOrder, setPurchaseOrder] = useState<HydratedPurchaseOrder>();
  const history = useHistory();

  const getPurchaseOrder = useCallback(() => {
    dispatch(
      getHydratedPurchaseOrder.request({
        purchaseOrderId,
        callback: (purchaseOrder) => {
          setPurchaseOrder(purchaseOrder);
        },
      })
    );
  }, []);

  useEffect(() => {
    getPurchaseOrder();
  }, []);

  useEffect(() => {
    if (
      !!purchaseOrder &&
      (!purchaseOrder.isIsolated ||
        purchaseOrder.sentAt ||
        purchaseOrder.voidedAt ||
        purchaseOrder.confirmedAt)
    ) {
      history.push(`/purchaseorders/${purchaseOrder?.id}`);
    }
  }, [purchaseOrder?.isIsolated]);

  return (
    <AuthenticatedPageWrapper
      title={`Purchase Order${
        purchaseOrder ? ` for ${purchaseOrder?.supplier.name}` : ''
      }`}
      previousPageNavigationConfig={{
        route: `/suppliers/${purchaseOrder?.supplierId}?tab=purchase-orders`,
      }}
    >
      {purchaseOrder && (
        <div className="m-8">
          <IsolatedPurchaseOrderControl
            purchaseOrder={purchaseOrder}
            onPreviewPurchaseOrderClick={() =>
              history.push(`/purchaseorders/${purchaseOrderId}`)
            }
          />
        </div>
      )}
    </AuthenticatedPageWrapper>
  );
};
export default EditIsolatedPurchaseOrderPage;
