import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';

const RemoveEmailSignatureMutation = graphql(`
  mutation RemoveEmailSignature {
    removeEmailSignature
  }
`);

export const useRemoveEmailSignature = () => {
  return useMutation({
    mutationFn: () => {
      return gqlClient.request(RemoveEmailSignatureMutation);
    },
  });
};
