import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'quill-mention';
// @ts-ignore
import quillEmoji from 'quill-emoji';

import FieldLabel from '../fieldLabel/FieldLabel';

import 'react-quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.css';
import 'quill-emoji/dist/quill-emoji.css';
import './TextareaFieldMentionFormatter.sass';

Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
    'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
  },
  true
);

export enum ToolbarColourVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WHITE = 'white',
}
type Props = {
  description?: string | JSX.Element;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string | JSX.Element;
  name: string;
  onChange?: (value: { [key: string]: string }) => void;
  placeholder?: string;
  value?: any;
  changeTimeoutMs?: number;
  onBlur?: () => void;
  onChangeTimeout?: () => void;
  mentions?: { id: number; value: string }[];
  className?: string;
};

const TextareaFieldMentionFormatter: FC<React.PropsWithChildren<Props>> = ({
  description,
  isDisabled = false,
  isRequired = false,
  label,
  name,
  onChange,
  placeholder,
  value,
  onBlur,
  onChangeTimeout,
  changeTimeoutMs = 1000,
  mentions,
  className,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const changeTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>();

  useEffect(() => {
    if (document) {
      // update the link tooltip placeholder from quill website
      document
        ?.querySelector('.ql-tooltip input')
        ?.setAttribute('data-link', 'https://payaca.com');
    }
  }, []);

  const modules = useMemo(() => {
    return {
      toolbar: [
        [
          'bold',
          'italic',
          'underline',
          'strike',
          { list: 'ordered' },
          { list: 'bullet' },
          'link',
          'emoji',
        ],
      ],
      'emoji-toolbar': true,
      'emoji-shortname': true,
      mention: {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ['@'],
        source: function (
          searchTerm: string,
          renderItem: any,
          mentionChar: '@'
        ) {
          let values: any;
          if (mentionChar === '@') {
            values = mentions;
          }
          if (searchTerm.length === 0) {
            renderItem(values, mentionChar);
          } else {
            const matches = [];
            for (let i = 0; i < values?.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i]);
            renderItem(matches, mentionChar);
          }
        },
      },
    };
  }, []); // re-render causes crash, so only set once on first render

  const onEditorChange = useCallback(
    (editorStateChange: any) => {
      onChange?.({
        [name]: editorStateChange,
      });
      changeTimeoutRef.current && clearTimeout(changeTimeoutRef.current);
      changeTimeoutRef.current = setTimeout(() => {
        onChangeTimeout && onChangeTimeout();
      }, changeTimeoutMs);
    },
    [onChange, onChangeTimeout, changeTimeoutRef.current, changeTimeoutMs]
  );

  return (
    <div className={`text-area-field-mention-formatter${className ? ` ${className}` : ''}`}>
      <FieldLabel
        label={label}
        isRequired={isRequired}
        description={description}
      />
      <ReactQuill
        theme="snow"
        bounds={`[data-text-editor="name"]`}
        value={value}
        modules={modules}
        readOnly={isDisabled}
        placeholder={placeholder}
        onChange={onEditorChange}
        onBlur={() => onBlur?.()}
      />
    </div>
  );
};

export default TextareaFieldMentionFormatter;
