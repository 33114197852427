import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import FieldLabel, { LabelStyleVariant } from '../fieldLabel/FieldLabel';
import InputWrapper, {
  InputStyleVariant,
  InputWidth,
} from '../inputWrapper/InputWrapper';
import { useFieldId } from '@payaca/hooks/useFieldId';

import './BasicField.sass';

export enum LabelPositionVariant {
  STACKED = 'label-stacked',
  INLINE = 'label-inline',
}

type Props = {
  className?: string;
  name: string;
  styleVariant?: InputStyleVariant;
  inputWidth?: InputWidth;
  labelStyleVariant?: LabelStyleVariant;
  value?: any;
  label?: string | JSX.Element;
  description?: string | JSX.Element;
  type?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  iconBefore?: IconDefinition | React.ReactNode;
  iconAfter?: IconDefinition | React.ReactNode;
  additionalInputProps?: { [key: string]: any };
  changeTimeoutMs?: number;
  onChange?: (value: { [key: string]: string }) => void;
  onTouch?: (fieldName: string) => void;
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  onChangeTimeout?: () => void;
  textBefore?: string;
  hideSpinnerArrows?: boolean;
  placeholder?: string;
  onKeyPress?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
  bindEventHandlers?: (eventHandlers: {
    insertText: (text: string) => void;
  }) => void;
  labelPosition?: LabelPositionVariant;
};

const BasicField: FunctionComponent<React.PropsWithChildren<Props>> = ({
  className,
  name,
  styleVariant,
  inputWidth,
  labelStyleVariant,
  value,
  label,
  description,
  type = 'text',
  isDisabled = false,
  isRequired = false,
  iconBefore,
  iconAfter,
  additionalInputProps = {},
  changeTimeoutMs = 1000,
  onChange,
  onTouch,
  onBlur,
  onChangeTimeout,
  textBefore,
  hideSpinnerArrows = false,
  placeholder,
  onKeyPress,
  bindEventHandlers,
  labelPosition = LabelPositionVariant.STACKED,
}) => {
  const [changeTimeout, setChangeTimeout] = useState<any>();

  const id = useFieldId(name);

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange &&
        onChange({
          [event.target.name]: event.target.value,
        });

      changeTimeout && clearTimeout(changeTimeout);
      setChangeTimeout(
        setTimeout(() => {
          onChangeTimeout && onChangeTimeout();
        }, changeTimeoutMs)
      );
    },
    [onChange, onChangeTimeout, changeTimeout, changeTimeoutMs]
  );

  const onInputTouch = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onTouch && onTouch(event.target.name);
    },
    [onTouch]
  );

  const handleBindEventHandlers = (target: HTMLInputElement) => {
    bindEventHandlers?.({
      insertText: (textToInsert: string) => {
        const cursorPosition = target.selectionStart || 0;
        const textBeforeCursorPosition = target.value.substring(
          0,
          cursorPosition
        );
        const textAfterCursorPosition = target.value.substring(
          cursorPosition,
          target.value.length
        );

        onChange &&
          onChange({
            [target.name]:
              textBeforeCursorPosition + textToInsert + textAfterCursorPosition,
          });

        changeTimeout && clearTimeout(changeTimeout);
        setChangeTimeout(
          setTimeout(() => {
            onChangeTimeout && onChangeTimeout();
          }, changeTimeoutMs)
        );
      },
    });
  };

  return (
    <div
      className={`basic-field ${className ? className : ''}  ${
        hideSpinnerArrows ? 'hide-spinner-arrows' : ''
      } ${labelPosition}`}
    >
      <FieldLabel
        label={label}
        id={id}
        isRequired={isRequired}
        description={description}
        styleVariant={labelStyleVariant}
      />
      <InputWrapper
        isDisabled={isDisabled}
        inputWidth={inputWidth}
        styleVariant={styleVariant}
      >
        <>
          {iconBefore &&
            (React.isValidElement(iconBefore) ? (
              <div className={`input-wrapper__pre-icon`}>{iconBefore}</div>
            ) : (
              <FontAwesomeIcon
                className={`input-wrapper__pre-icon`}
                // @ts-ignore
                icon={iconBefore}
              />
            ))}
          {textBefore && (
            <span className={`input-wrapper__pre-text`}>{textBefore}</span>
          )}
          <input
            value={value}
            name={name}
            id={id}
            type={type}
            onChange={onInputChange}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              onInputTouch && onInputTouch(event);
              onBlur && onBlur(event);
            }}
            onKeyPress={onKeyPress}
            disabled={isDisabled}
            className={`input-wrapper__input`}
            {...additionalInputProps}
            placeholder={placeholder}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
              const target = e.target as HTMLInputElement;
              handleBindEventHandlers(target);
            }}
            onClick={(e: React.MouseEvent<HTMLInputElement>) => {
              const target = e.target as HTMLInputElement;
              handleBindEventHandlers(target);
            }}
          />
          {iconAfter &&
            (React.isValidElement(iconAfter) ? (
              <div className={`input-wrapper__post-icon`}>{iconAfter}</div>
            ) : (
              <FontAwesomeIcon
                className={`input-wrapper__post-icon`}
                // @ts-ignore
                icon={iconAfter}
              />
            ))}
        </>
      </InputWrapper>
    </div>
  );
};

export default BasicField;
