import {
  useInfiniteQuery,
  useQueryClient,
  InfiniteData,
} from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import { graphql } from '@/gql';
import projectKeys from '@/api/queries/project/keyFactory';
import {
  GetPaginatedActivityFeedForProjectInput,
  GetProjectActivityFeedQuery,
} from '@/gql/graphql';

const GetProjectActivityFeed = graphql(`
  query GetProjectActivityFeed(
    $projectId: Int!
    $input: GetPaginatedActivityFeedForProjectInput!
    $pagination: PaginationInput!
  ) {
    project(id: $projectId) {
      activityFeed(pagination: $pagination, input: $input) {
        items {
          ... on ProjectActivityEvent {
            __typename
            description
            id
            name
            timestamp
            type
            linkedEntity {
              ... on Note {
                id
              }
            }
          }
          ... on IntegratedEmailProjectActivityEvent {
            __typename
            description
            id
            name
            timestamp
            type
            integratedEmailId
          }
        }
        limit
        offset
        totalCount
      }
    }
  }
`);

const useGetProjectActivityFeed = (
  projectId?: number,
  input?: GetPaginatedActivityFeedForProjectInput
) => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    enabled: !!projectId,
    queryKey: projectKeys.activityFeed(projectId!, input),
    // If `input` contains the `types` filter
    // Check that all the unfiltered data has already been fetched
    // if so, filter it client-side before fetching it again from the server
    initialData: () => {
      if (!input || !input.types || !input.types.length) {
        return;
      }

      const data = queryClient.getQueryData<
        InfiniteData<GetProjectActivityFeedQuery>
      >(
        projectKeys.activityFeed(projectId!, {
          types: [],
        })
      );

      if (!data) {
        return;
      }

      // We already have all the data client-side
      return {
        ...data,
        pages: data.pages.map((page) => ({
          ...page,
          project: {
            ...page.project,
            activityFeed: page.project.activityFeed
              ? {
                  ...page.project.activityFeed,
                  // ... so filter it client-side
                  items: page.project.activityFeed.items.filter((item) =>
                    input!.types!.includes(item.type)
                  ),
                }
              : undefined,
          },
        })),
      };
    },
    queryFn: ({ pageParam }) => {
      return gqlClient.request(GetProjectActivityFeed, {
        projectId: projectId!,
        pagination: {
          offset: pageParam || PAGINATION_ARG_DEFAULTS.offset,
          limit: PAGINATION_ARG_DEFAULTS.limit,
        },
        input: input || {},
      });
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.project.activityFeed) {
        return null;
      }

      const newOffset =
        lastPage.project.activityFeed.offset +
        lastPage.project.activityFeed.limit;

      if (lastPage.project.activityFeed.totalCount <= newOffset) {
        return null;
      }

      return newOffset;
    },
  });
};

export default useGetProjectActivityFeed;
