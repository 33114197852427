import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { getAddressAsString } from '@payaca/helpers/locationHelper';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { ScheduledEventLocation } from '@payaca/types/scheduledEventsTypes';
import {
  LocationOption,
  LocationOptionType,
} from '@payaca/types/locationTypes';

import { AddressLookupOrInput } from '../addressLookupOrInput/AddressLookupOrInput';
import Select from '@payaca/components/plSelect/Select';
import Input from '@payaca/components/plInput/Input';
import Field from '@payaca/components/plField/Field';
import ContactGroupControl from '../contactGroupControl/ContactGroupControl';
import { DeepPartial } from '@payaca/utilities/types';
import {
  AddressSelectValue,
  useAddressSelectOptionsAndGroups,
} from '../../../hooks/useAddressSelectOptionsAndGroups';
import Combobox from '@payaca/components/plCombobox/Combobox';

type Props = {
  location?: DeepPartial<ScheduledEventLocation>;
  touchedState?: { [fieldName: string]: boolean };
  validationState?: { [fieldName: string]: FieldValidationResult };
  onChange: (
    value?: Record<string, any>,
    natureOfChange?: 'address' | 'contacts' | 'address-and-contacts' | 'reset'
  ) => void;
  onTouch?: (fieldName: string) => void;
  locationOptions?: LocationOption[];
};

const ScheduledEventLocationControl: FunctionComponent<Props> = ({
  location,
  touchedState,
  validationState,
  onTouch,
  onChange,
  locationOptions,
}: Props): JSX.Element | null => {
  const [addressMode, setAddressMode] = useState<'read' | 'select' | 'lookup'>(
    'lookup'
  );

  const addressAsString = location?.address
    ? getAddressAsString(location.address)
    : undefined;

  useEffect(() => {
    if (addressAsString?.length) {
      setAddressMode('read');
    } else if (locationOptions?.length) {
      setAddressMode('select');
    }
  }, [locationOptions]);

  const { addressSelectOptions, addressSelectOptionGroups } =
    useAddressSelectOptionsAndGroups(locationOptions);

  return (
    <div className="flex flex-col gap-4">
      {addressMode === 'read' && (
        <Input
          value={addressAsString}
          onFocus={() => {
            setAddressMode(locationOptions?.length ? 'select' : 'lookup');
          }}
        />
      )}
      {/* address */}
      {addressMode === 'lookup' && (
        <AddressLookupOrInput
          address={location?.address}
          onChange={(address) => {
            const change: Record<string, any> = {};

            if (!address) {
              change.address = undefined;
            } else {
              Object.entries(address).forEach(([key, value]) => {
                change[`address.${key}`] = value;
              });
            }
            onChange(change, 'address');
          }}
          validationState={
            validationState
              ? Object.entries(validationState).reduce(
                  (
                    acc: Record<string, FieldValidationResult>,
                    [key, value]
                  ) => {
                    if (key.startsWith(`address.`)) {
                      acc[key.replace(`address.`, '')] = value;
                    }
                    return acc;
                  },
                  {}
                )
              : undefined
          }
          onTouch={(fieldName) => {
            onTouch?.(`address.${fieldName}`);
          }}
          touchedState={
            touchedState
              ? Object.entries(touchedState).reduce(
                  (acc: Record<string, boolean>, [key, value]) => {
                    if (key.startsWith(`address.`)) {
                      acc[key.replace(`address.`, '')] = value;
                    }
                    return acc;
                  },
                  {}
                )
              : undefined
          }
        />
      )}
      {addressMode === 'select' && (
        <Combobox
          filterFunction={(option, filter) => {
            if (!filter?.length) return true;
            if (option.value === AddressSelectValue.NO_LOCATION) return true;
            if (option.value === AddressSelectValue.ADD_NEW_ADDRESS)
              return true;

            return option.label.toLowerCase().includes(filter.toLowerCase());
          }}
          options={addressSelectOptions}
          optionGroups={addressSelectOptionGroups}
          value={location?.id}
          onChange={(value) => {
            if (value === AddressSelectValue.NO_LOCATION) {
              onChange(undefined, 'reset');
            } else if (value === AddressSelectValue.ADD_NEW_ADDRESS) {
              onChange(undefined, 'reset');
              setAddressMode('lookup');
            } else {
              const l = locationOptions?.find((x) => x.id === value);
              onChange(l, 'address-and-contacts');
              setAddressMode('read');
            }
          }}
        />
      )}
      {/* access */}
      {location?.address && (
        <div>
          <Field.Label>Contacts at Event address</Field.Label>
          <ContactGroupControl
            accessContacts={location?.contacts || []}
            onChange={(value) => {
              if (Array.isArray(value)) {
                onChange({ contacts: value }, 'contacts');
              } else {
                const change: Record<string, any> = {};

                Object.entries(value).forEach(([key, value]) => {
                  change[`contacts${key}`] = value;
                });
                onChange(change, 'contacts');
              }
            }}
            validationState={
              validationState
                ? Object.entries(validationState).reduce(
                    (
                      acc: Record<string, FieldValidationResult>,
                      [key, value]
                    ) => {
                      if (key.startsWith(`contacts`)) {
                        acc[key.replace(`contacts`, '')] = value;
                      }
                      return acc;
                    },
                    {}
                  )
                : undefined
            }
            onTouch={(fieldName) => {
              onTouch?.(`contacts${fieldName}`);
            }}
            touchedState={
              touchedState
                ? Object.entries(touchedState).reduce(
                    (acc: Record<string, boolean>, [key, value]) => {
                      if (key.startsWith(`contacts`)) {
                        acc[key.replace(`contacts`, '')] = value;
                      }
                      return acc;
                    },
                    {}
                  )
                : undefined
            }
          />
        </div>
      )}
    </div>
  );
};

export default ScheduledEventLocationControl;
