import { FetchedEntity } from '@payaca/types/storeTypes';
import { Deal, DealAddressType } from '@payaca/types/dealTypes';
import {
  GetListedDealsRequestData,
  ListedDealsListViewPage,
  PipelineListedDeals,
} from '@payaca/types/listedDealTypes';
import { Address } from '@payaca/types/locationTypes';
import { Contact } from '@payaca/types/contactTypes';
import { DeclareAsyncAction } from '../types';
export interface State {
  deals: { [key: number]: FetchedEntity<Deal> };

  isSettingDealCustomer: boolean;
  isDealCustomerSetSuccessfully?: boolean;

  isArchivingDeals: boolean;
  areDealsArchivedSuccessfully?: boolean;
  isRestoringDeals: boolean;
  areDealsRestoredSuccessfully?: boolean;

  isGettingListedDealsPage: boolean;
  listedDealsPage: ListedDealsListViewPage | null;

  isAddingDealAddress: boolean;
  isDealAddressAddedSuccessfully?: boolean;

  isRemovingDealAddress: boolean;
  isDealAddressRemovedSuccessfully?: boolean;

  isGettingListedPipelineDeals: boolean;
}

export enum ActionType {
  REQUEST_GET_DEAL = 'deals.requestGetDeal',
  GET_DEAL_SUCCESS = 'deals.getDealSuccess',
  GET_DEAL_FAILURE = 'deals.getDealFailure',
  CLEAR_DEAL = 'deals.clearDeal',
  CLEAR_DEALS = 'deals.clearDeals',

  REQUEST_ACTIVATE_DEAL_VIEW = 'deals.requestActivateDealView',
  ACTIVATE_DEAL_VIEW_SUCCESS = 'deals.activateDealViewSuccess',
  ACTIVATE_DEAL_VIEW_FAILURE = 'deals.activateDealViewFailure',

  REQUEST_CREATE_DEAL = 'deals.requestCreateDeal',
  CREATE_DEAL_SUCCESS = 'deals.createDealSuccess',
  CREATE_DEAL_FAILURE = 'deals.createDealFailure',

  REQUEST_UPDATE_DEAL = 'deals.requestUpdateDeal',
  UPDATE_DEAL_SUCCESS = 'deals.updateDealSuccess',
  UPDATE_DEAL_FAILURE = 'deals.updateDealFailure',

  REQUEST_SET_DEAL_CUSTOMER = 'deals.requestSetDealCustomer',
  SET_DEAL_CUSTOMER_SUCCESS = 'deals.setDealCustomerSuccess',
  SET_DEAL_CUSTOMER_FAILURE = 'deals.setDealCustomerFailure',

  REQUEST_UNSET_DEAL_CUSTOMER = 'deals.requestUnsetDealCustomer',
  UNSET_DEAL_CUSTOMER_SUCCESS = 'deals.unsetDealCustomerSuccess',
  UNSET_DEAL_CUSTOMER_FAILURE = 'deals.unsetDealCustomerFailure',

  REQUEST_GET_LISTED_DEALS_PAGE = 'deals.requestGetListedDealsPage',
  GET_LISTED_DEALS_PAGE_SUCCESS = 'deals.getListedDealsPageSuccess',
  GET_LISTED_DEALS_PAGE_FAILURE = 'deals.getListedDealsPageFailure',
  CLEAR_LISTED_DEALS_PAGE = 'deals.clearListedDealsPage',

  REQUEST_ARCHIVE_DEALS = 'deals.requestArchiveDeals',
  ARCHIVE_DEALS_SUCCESS = 'deals.archiveDealsSuccess',
  ARCHIVE_DEALS_FAILURE = 'deals.archiveDealsFailure',

  REQUEST_RESTORE_DEALS = 'deals.requestRestoreDeals',
  RESTORE_DEALS_SUCCESS = 'deals.restoreDealsSuccess',
  RESTORE_DEALS_FAILURE = 'deals.restoreDealsFailure',

  REQUEST_ADD_DEAL_ADDRESS = 'deals.requestAddDealAddress',
  ADD_DEAL_ADDRESS_SUCCESS = 'deals.addDealAddressSuccess',
  ADD_DEAL_ADDRESS_FAILURE = 'deals.addDealAddressFailure',

  REQUEST_REMOVE_DEAL_ADDRESS = 'deals.requestRemoveDealAddress',
  REMOVE_DEAL_ADDRESS_SUCCESS = 'deals.removeDealAddressSuccess',
  REMOVE_DEAL_ADDRESS_FAILURE = 'deals.removeDealAddressFailure',

  SET_DEAL_PIPELINE_REQUEST = 'deals.setDealPipeline:request',
  SET_DEAL_PIPELINE_SUCCESS = 'deals.setDealPipeline:success',
  SET_DEAL_PIPELINE_FAILURE = 'deals.setDealPipeline:failure',

  GET_DEAL_ACCEPTED_ITEMS_COPY_REQUEST = 'deals.getDealAcceptedItemsCopy:request',

  GET_LISTED_PIPELINE_DEALS_REQUEST = 'deals.getListedPipelineDeals:request',
  GET_LISTED_PIPELINE_DEALS_SUCCESS = 'deals.getListedPipelineDeals:success',
  GET_LISTED_PIPELINE_DEALS_FAILURE = 'deals.getListedPipelineDeals:failure',
}

export interface AddDealAddressRequestData {
  dealId: number;
  dealAddressType: DealAddressType;
  address: Address;
  addAddressToDealCustomer?: boolean;
  contacts: Contact[];
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}

export type SetDealPipelineAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.SET_DEAL_PIPELINE_REQUEST;
      payload: {
        dealId: number;
        pipelineId: number | null;
        callback?: () => void;
      };
    };
    success: {
      type: ActionType.SET_DEAL_PIPELINE_SUCCESS;
    };
    failure: {
      type: ActionType.SET_DEAL_PIPELINE_FAILURE;
    };
  }
>;

export type GetDealAcceptedItemsCopy = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_DEAL_ACCEPTED_ITEMS_COPY_REQUEST;
      payload: {
        dealId: number;
        callback?: (acceptedItemsCopy: string) => void;
      };
    };
  }
>;

export type GetListedPipelineDealsAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_LISTED_PIPELINE_DEALS_REQUEST;
      payload: {
        args: GetListedDealsRequestData;
        callback?: (page: PipelineListedDeals) => void;
        errorCallback?: (error: Error) => void;
      };
    };
  }
>;
