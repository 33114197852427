import { faPercent, faTimes } from '@fortawesome/free-solid-svg-icons';
import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import { Nullable } from '@payaca/utilities/types';
import React, { useEffect, useState } from 'react';

import './JobLineItemDiscountModal.sass';

type Props = {
  discountPercentage: Nullable<number>;
  discountDescription: Nullable<string>;
  saveDiscount: ({
    discountPercentage,
    discountDescription,
  }: {
    discountPercentage: Nullable<number>;
    discountDescription: Nullable<string>;
  }) => void;
  isOpen: boolean;
  onClose: () => void;
};
const JobLineItemDiscountModal = ({
  discountPercentage,
  discountDescription,
  isOpen,
  onClose,
  saveDiscount,
}: Props) => {
  const [_discountPercentage, setDiscountPercentage] =
    useState<Nullable<number>>(discountPercentage);
  const [_discountDescription, setDiscountDescription] =
    useState(discountDescription);

  useEffect(() => {
    setDiscountPercentage(discountPercentage);
    setDiscountDescription(discountDescription);
  }, [discountPercentage, discountDescription]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Apply discount"
      className="job-line-item-discount-modal"
      size="xs"
    >
      <BasicField
        label="Discount percentage"
        name="discountPercentage"
        value={_discountPercentage}
        onChange={(change: { [key: string]: any }) =>
          setDiscountPercentage(
            change.discountPercentage
              ? Math.round(change.discountPercentage * 100) / 100
              : null
          )
        }
        iconAfter={faPercent}
        className="discount-percentage"
        type="number"
        additionalInputProps={{
          pattern: '[0-9]*',
          min: 0,
          max: 100,
          step: 1,
        }}
      />
      <BasicField
        label="Reason for discount"
        name="discountDescription"
        value={_discountDescription}
        onChange={(change) =>
          setDiscountDescription(change.discountDescription)
        }
      />
      <div className="actions-container">
        <Button
          onClick={() => {
            saveDiscount({
              discountPercentage: _discountPercentage,
              discountDescription: _discountDescription,
            });
            onClose();
          }}
          styleVariant={ButtonStyleVariant.OUTSIZE}
          type="submit"
        >
          Save
        </Button>
        <Button
          onClick={() => {
            saveDiscount({
              discountPercentage: null,
              discountDescription: null,
            });
            onClose();
          }}
          styleVariant={ButtonStyleVariant.ANCHOR}
          iconAfter={faTimes}
        >
          Remove discount
        </Button>
      </div>
    </Modal>
  );
};

export default JobLineItemDiscountModal;
