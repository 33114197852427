import HeaderTag, { HeaderTagType } from '../headerTag/HeaderTag';
import React from 'react';

type Props = {
  isActive?: boolean;
  isDisabled?: boolean;
  onTabSelection: () => void;
  title: string;
  label: string;
  titleHeaderTagType?: HeaderTagType;
};
const Tab = ({
  isActive,
  isDisabled = false,
  onTabSelection,
  title,
  label,
  titleHeaderTagType,
}: Props) => {
  return (
    <div
      aria-controls={label}
      role="tab"
      title={title}
      className={`tab${isActive ? ' active' : ''}${
        isDisabled ? ' disabled' : ''
      }`}
      onClick={() => (!isDisabled ? onTabSelection() : null)}
    >
      {titleHeaderTagType ? (
        <HeaderTag type={titleHeaderTagType}>{title}</HeaderTag>
      ) : (
        title
      )}
    </div>
  );
};

export default Tab;
