import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import './TextareaField.sass';
import FieldLabel from '../fieldLabel/FieldLabel';
import InputWrapper, {
  InputColourVariant,
  InputStyleVariant,
} from '../inputWrapper/InputWrapper';
import TextareaAutosize from 'react-textarea-autosize';
import { useFieldId } from '@payaca/hooks/useFieldId';

type Props = {
  styleVariant?: InputStyleVariant;
  colourVariant?: InputColourVariant;
  autoSize?: boolean;
  description?: string | JSX.Element;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string | JSX.Element;
  name: string;
  onChange?: (value: { [key: string]: string }) => void;
  onTouch?: (fieldName: string) => void;
  placeholder?: string;
  rows?: number;
  value?: any;
  changeTimeoutMs?: number;
  onBlur?: () => void;
  onChangeTimeout?: () => void;
  additionalInputProps?: { [key: string]: any };
};

const TextareaField: FunctionComponent<React.PropsWithChildren<Props>> = ({
  styleVariant,
  colourVariant,
  autoSize = false,
  description,
  isDisabled = false,
  isRequired = false,
  label,
  name,
  onChange,
  onTouch,
  placeholder,
  rows = 3,
  value,
  onBlur,
  onChangeTimeout,
  changeTimeoutMs = 1000,
  additionalInputProps,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const [changeTimeout, setChangeTimeout] = useState<any>();
  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange &&
        onChange({
          [event.target.name]: event.target.value,
        });

      changeTimeout && clearTimeout(changeTimeout);
      setChangeTimeout(
        setTimeout(() => {
          onChangeTimeout && onChangeTimeout();
        }, changeTimeoutMs)
      );
    },
    [onChange, onChangeTimeout, changeTimeout, changeTimeoutMs]
  );

  const onInputTouch = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      onTouch && onTouch(event.target.name);
    },
    [onTouch]
  );

  const id = useFieldId(name);

  return (
    <div className="text-area-field">
      <FieldLabel
        label={label}
        id={id}
        isRequired={isRequired}
        description={description}
      />
      <InputWrapper
        isDisabled={isDisabled}
        styleVariant={styleVariant}
        colourVariant={colourVariant}
      >
        {autoSize ? (
          <TextareaAutosize
            value={value}
            name={name}
            id={id}
            onChange={onInputChange}
            placeholder={placeholder}
            minRows={rows}
            onBlur={(event: React.FocusEvent<HTMLTextAreaElement>) => {
              onInputTouch && onInputTouch(event);
              onBlur && onBlur();
            }}
            className={`input-wrapper__text-area`}
          />
        ) : (
          <textarea
            value={value}
            name={name}
            id={id}
            rows={rows}
            onChange={onInputChange}
            disabled={isDisabled}
            onBlur={(event: React.FocusEvent<HTMLTextAreaElement>) => {
              onInputTouch && onInputTouch(event);
              onBlur && onBlur();
            }}
            className={`input-wrapper__text-area`}
            {...additionalInputProps}
          />
        )}
      </InputWrapper>
    </div>
  );
};

export default TextareaField;
