import React, { FunctionComponent, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import './DropdownField.sass';
import FieldLabel, { LabelStyleVariant } from '../fieldLabel/FieldLabel';
import InputWrapper, {
  InputColourVariant,
  InputStyleVariant,
  InputWidth,
} from '../inputWrapper/InputWrapper';
import Checkbox from '../checkbox/Checkbox';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

type Props = {
  className?: string;
  styleVariant?: InputStyleVariant;
  inputWidth?: InputWidth;
  labelStyleVariant?: LabelStyleVariant;
  colourVariant?: InputColourVariant;
  name: string;
  value?: any;
  label?: string;
  description?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  iconBefore?: IconDefinition | React.ReactNode;
  iconAfter?: IconDefinition;
  optionGroups?: { name?: string; id: number }[];
  options: {
    value: any;
    label: string;
    isDisabled?: boolean;
    optionGroupId?: number;
  }[];
  multiple?: boolean;
  additionalSelectProps?: { [key: string]: any };
  onChange?: (value: { [key: string]: any }) => void;
  onTouch?: (fieldName: string) => void;
  hasBoxShadow?: boolean;
  displayCheckboxes?: boolean;
  emptyText?: string;
  minToShorten?: number; // set for list of labels to be x, y +2 more
  renderOption?: (option: {
    value: any;
    label: string;
    optionGroupId?: number;
    className?: string;
  }) => React.ReactNode;
  renderValue?: (value: any) => React.ReactNode;
  onBlur?: () => void;
};

const DropdownField: FunctionComponent<React.PropsWithChildren<Props>> = ({
  className,
  styleVariant,
  inputWidth,
  labelStyleVariant,
  colourVariant,
  name,
  value,
  label,
  description,
  isDisabled = false,
  isRequired = false,
  iconBefore,
  iconAfter,
  options,
  optionGroups,
  multiple = false,
  additionalSelectProps = {},
  onChange,
  onTouch,
  hasBoxShadow = true,
  displayCheckboxes = false,
  emptyText,
  minToShorten,
  renderOption,
  renderValue,
  onBlur,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const onInputChange = useCallback(
    (event: React.ChangeEvent<any>) => {
      onChange &&
        onChange({
          [event.target.name]: event.target.value,
        });
    },
    [onChange]
  );

  const renderOptions = useCallback(
    (
      options: {
        value: any;
        label: string;
        isDisabled?: boolean;
        optionGroupId?: number;
        tagColour?: string;
        className?: string;
      }[]
    ) => {
      return options.map((option, index: number) => {
        return (
          <MenuItem
            value={option.value ?? undefined}
            key={`option-${index}`}
            className={option.className}
            disabled={option.isDisabled}
          >
            {displayCheckboxes && (
              <Checkbox
                isChecked={
                  Array.isArray(value)
                    ? value.includes(option.value)
                    : value === option.value ||
                      (value === null && option.value === undefined)
                }
                onChange={() => null}
                isDisabled={option.isDisabled}
              />
            )}
            {renderOption ? renderOption(option) : <span>{option.label}</span>}
          </MenuItem>
        );
      });
    },
    [displayCheckboxes, value, renderOption]
  );

  return (
    <div className={`dropdown-field ${className ? className : ''}`}>
      <FieldLabel
        label={label}
        isRequired={isRequired}
        description={description}
        styleVariant={labelStyleVariant}
      />
      <InputWrapper
        isDisabled={isDisabled}
        styleVariant={styleVariant}
        colourVariant={colourVariant}
        inputWidth={inputWidth}
        hasBoxShadow={hasBoxShadow}
      >
        <>
          {iconBefore && (
            <span
              className="input-wrapper__icon-wrapper"
              onClick={() => setIsOpen(true)}
            >
              {React.isValidElement(iconBefore) ? (
                iconBefore
              ) : (
                <FontAwesomeIcon
                  className={'input-wrapper__pre-icon'}
                  // @ts-ignore
                  icon={iconBefore}
                />
              )}
            </span>
          )}
          <Select
            onBlur={onBlur}
            value={value === undefined ? '' : value}
            name={name}
            onChange={onInputChange}
            displayEmpty={true}
            disableUnderline
            disabled={isDisabled}
            onClose={() => {
              setIsOpen(false);
              onTouch && onTouch(name);
            }}
            renderValue={(value: any) => {
              const selectedOptions = options.filter((x) => {
                return Array.isArray(value)
                  ? value.includes(x.value)
                  : value === x.value ||
                      (value === null && x.value === undefined);
              });

              const numberOfSelectedOptions = selectedOptions?.length;
              const numberOfShownOptions =
                minToShorten && numberOfSelectedOptions > minToShorten
                  ? minToShorten
                  : numberOfSelectedOptions;
              const numberOfHiddenOptions =
                numberOfSelectedOptions - numberOfShownOptions;

              return (
                <span className="dropdown-field__selected-label">
                  {renderValue ? (
                    renderValue(value)
                  ) : (
                    <>
                      {!numberOfSelectedOptions && emptyText}
                      {selectedOptions
                        .slice(0, numberOfShownOptions)
                        .map((x) => x.label)
                        .join(', ')}
                      {!!numberOfHiddenOptions && (
                        <span className="dropdown-more-values-amount">
                          {' '}
                          +{numberOfHiddenOptions} more
                        </span>
                      )}
                    </>
                  )}
                </span>
              );
            }}
            onOpen={() => setIsOpen(true)}
            open={isOpen}
            multiple={multiple}
            IconComponent={(props: any) => {
              return (
                //@ts-ignore
                <div
                  className={`dropdown-field__icon-component ${props.className}`}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              );
            }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              classes: { paper: 'dropdown-field__paper' },
            }}
            {...additionalSelectProps}
          >
            {renderOptions(options.filter((option) => !option.optionGroupId))}
            {optionGroups &&
              optionGroups.map((optionGroup, index: number) => {
                return [
                  <div
                    className="dropdown-field__option-group"
                    key={`option-group-${index}`}
                  >
                    <hr />
                    {optionGroup.name && (
                      <small className="dropdown-field__option-group-name">
                        {optionGroup.name}
                      </small>
                    )}
                  </div>,
                  ...renderOptions(
                    options.filter(
                      (option) => option.optionGroupId === optionGroup.id
                    )
                  ),
                ];
              })}
          </Select>
          {iconAfter && (
            <span
              className="input-wrapper__icon-wrapper"
              onClick={() => setIsOpen(true)}
            >
              <FontAwesomeIcon
                className={'input-wrapper__post-icon'}
                icon={iconAfter}
              />
            </span>
          )}
        </>
      </InputWrapper>
    </div>
  );
};

export default DropdownField;
