import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import './ClickableArea.sass';

export enum ClickableAreaColourVariant {
  SECONDARY_LIGHTER = 'secondary-lighter',
  SECONDARY_LIGHT = 'secondary-light',
}

type Props = {
  onClick?: () => void;
  className?: string;
  isProcessing?: boolean;
  colourVariant?: ClickableAreaColourVariant;
  isDisabled?: boolean;
  iconBefore?: IconDefinition | React.ReactNode;
};

const ClickableArea: FunctionComponent<React.PropsWithChildren<Props>> = ({
  onClick,
  className,
  isProcessing,
  colourVariant = ClickableAreaColourVariant.SECONDARY_LIGHTER,
  children,
  isDisabled = false,
  iconBefore,
}: React.PropsWithChildren<Props>): JSX.Element => {
  return (
    <div
      className={`clickable-area ${className ? className : ''} ${
        isProcessing ? 'processing' : ''
      } area-col-${colourVariant} ${isDisabled ? 'disabled' : ''}`}
      role="button"
      onClick={!isDisabled ? onClick : undefined}
    >
      <div className="clickable-area-content">
        {iconBefore && (
          <span className="icon-container">
            {React.isValidElement(iconBefore) ? (
              iconBefore
            ) : (
              // @ts-ignore
              <FontAwesomeIcon icon={iconBefore} />
            )}
          </span>
        )}
        <div>{children}</div>
      </div>

      {isProcessing && (
        <span className={'spinner-container'}>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </span>
      )}
    </div>
  );
};

export default ClickableArea;
