import React, { FC, useState } from 'react';

import './EditJobSaveFeedback.sass';
import { useMemo } from 'react';
import { getJob, getJobContentByJobId } from '@/utils/stateAccessors';
import { useEffect } from 'react';
import { usePrevious } from '@/utils/customHooks';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import {
  ErrorMessage,
  SuccessMessage,
} from '@payaca/components/feedbackMessage/FeedbackMessage';
import { useSelector } from '@/api/state';
import { clearJobContentUpdateResults } from '@payaca/store/jobContent/jobContentActions';
import { clearJobUpdateResults } from '@payaca/store/jobs/jobsActions';
import { useDispatch } from 'react-redux';

type Props = {
  jobId: number;
};
const EditJobSaveFeedback: FC<Props> = ({ jobId }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [showSavedMessageTimeout, setShowSavedMessageTimeout] = useState<any>();

  const job = useSelector((state) => getJob(state, jobId));
  const jobContent = useSelector((state) => getJobContentByJobId(state, jobId));

  useEffect(() => {
    return () => {
      dispatch(clearJobContentUpdateResults());
      dispatch(clearJobUpdateResults());
    };
  }, []);

  const jobUpdateResult = useSelector((state) => {
    return (
      state.jobsStore.jobUpdateResults &&
      state.jobsStore.jobUpdateResults[jobId]
    );
  });

  const jobContentUpdateResult = useSelector((state) => {
    if (!job) return;
    return (
      state.jobContent.jobContentUpdateResults &&
      state.jobContent.jobContentUpdateResults[job.jobContentId]
    );
  });

  const jobLineItemUpdateResults = useSelector((state) => {
    if (!state.jobContent.jobLineItemUpdateResults || !jobContent) return false;
    const jobLineItemIds = jobContent.jobLineItemIds;

    return jobLineItemIds
      .map(
        (jobLineItemId: number) =>
          state.jobContent.jobLineItemUpdateResults[jobLineItemId]
      )
      .filter((x) => !!x);
  });

  const jobLineItemGroupUpdateResults = useSelector((state) => {
    if (!state.jobContent.jobLineItemGroupUpdateResults || !jobContent)
      return false;
    const jobLineItemGroupIds = jobContent.jobLineItemGroupIds;

    return jobLineItemGroupIds
      .map(
        (jobLineItemGroupId: number) =>
          state.jobContent.jobLineItemGroupUpdateResults[jobLineItemGroupId]
      )
      .filter((x) => !!x);
  });

  const hasJobUpdateError = useMemo(() => {
    return jobUpdateResult?.isUpdatedSuccessfully === false;
  }, [jobUpdateResult]);

  const hasJobContentUpdateError = useMemo(() => {
    return jobContentUpdateResult?.isUpdatedSuccessfully === false;
  }, [jobContentUpdateResult]);

  const hasAnyJobLineItemUpdateErrors = useMemo(() => {
    if (!jobLineItemUpdateResults) return false;
    return jobLineItemUpdateResults.some(
      (x) => x?.isUpdatedSuccessfully === false
    );
  }, [jobLineItemUpdateResults]);

  const hasAnyJobLineItemGroupUpdateErrors = useMemo(() => {
    if (!jobLineItemGroupUpdateResults) return false;
    return jobLineItemGroupUpdateResults.some(
      (x) => x?.isUpdatedSuccessfully === false
    );
  }, [jobLineItemGroupUpdateResults]);

  const hasAnyErrors = useMemo(() => {
    return (
      hasAnyJobLineItemGroupUpdateErrors ||
      hasAnyJobLineItemUpdateErrors ||
      hasJobUpdateError ||
      hasJobContentUpdateError
    );
  }, [
    hasAnyJobLineItemGroupUpdateErrors,
    hasAnyJobLineItemUpdateErrors,
    hasJobUpdateError,
    hasJobContentUpdateError,
  ]);

  const jobUpdateErrorMessage = useMemo(() => {
    if (hasAnyErrors) {
      return "Something went wrong - some of your changes haven't been saved";
    }
  }, [hasAnyErrors]);

  const isModifyingJobLineItemsOrJobLineItemGroups: boolean = useSelector(
    (state) => {
      return (
        state.jobContent.isUpdatingJobLineItem ||
        state.jobContent.isDeletingJobLineItem ||
        state.jobContent.isCreatingJobLineItem ||
        state.jobContent.isCreatingJobLineItemGroup ||
        state.jobContent.isUpdatingJobLineItemGroup ||
        state.jobContent.isDeletingJobLineItemGroup ||
        state.jobContent.isAddingLineItemGroupToJobContent ||
        state.jobContent.isAddingAttachmentToJobLineItem ||
        state.jobContent.isRemovingAttachmentFromJobLineItem
      );
    }
  );

  const isUpdatingJob: boolean = useSelector((state) => {
    return state.jobsStore.isUpdatingJob;
  });

  const isUpdatingJobContent: boolean = useSelector((state) => {
    return state.jobContent.isUpdatingJobContent;
  });

  const isPersistingCustomer: boolean = useSelector((state) => {
    return state.customer.isPersistingCustomer;
  });

  const isSettingDealCustomer: boolean = useSelector((state) => {
    return state.deals.isSettingDealCustomer;
  });

  const isProcessing = useMemo(() => {
    return (
      isUpdatingJobContent ||
      isUpdatingJob ||
      isPersistingCustomer ||
      isSettingDealCustomer ||
      isModifyingJobLineItemsOrJobLineItemGroups
    );
  }, [
    isUpdatingJobContent,
    isUpdatingJob,
    isPersistingCustomer,
    isSettingDealCustomer,
    isModifyingJobLineItemsOrJobLineItemGroups,
  ]);

  const previousIsProcessing = usePrevious(isProcessing);

  useEffect(() => {
    if (!isProcessing && previousIsProcessing && !hasAnyErrors) {
      setShowSavedMessage(true);
      setShowSavedMessageTimeout(
        setTimeout(() => {
          setShowSavedMessage(false);
        }, 1000)
      );
    }
  }, [isProcessing]);

  return (
    <div className="edit-job-save-feedback">
      <div
        className={`saved-message-container ${
          showSavedMessage && !isProcessing ? '' : ' hidden'
        }`}
      >
        <SuccessMessage message={'Progress saved!'} />
      </div>

      <div
        className={`error-message-container ${
          jobUpdateErrorMessage ? '' : ' hidden'
        }`}
      >
        {jobUpdateErrorMessage && (
          <ErrorMessage message={jobUpdateErrorMessage} />
        )}
      </div>
      {isProcessing && (
        <div className="processing-container">
          <MiniLoader />
        </div>
      )}
    </div>
  );
};

export default EditJobSaveFeedback;
