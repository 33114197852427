import { Account } from '@payaca/types/accountTypes';
import { useMemo } from 'react';

export const useInspectionFormAddresses = (account: any, data: any) => {
  const accountAddressString = useMemo(() => {
    return [account?.address, account?.city, account?.postcode]
      .filter((x) => !!x?.length)
      .join(', ');
  }, [account]);

  const inspectionAddressString = useMemo(() => {
    return [
      data?.building,
      data?.street,
      data?.city,
      data?.region,
      data?.postcode,
    ]
      .filter((x) => !!x?.length)
      .join(', ');
  }, [data]);

  const landlordAddressString = useMemo(() => {
    return [
      data?.landlord_building,
      data?.landlord_street,
      data?.landlord_city,
      data?.landlord_region,
      data?.landlord_postcode,
    ]
      .filter((x) => !!x?.length)
      .join(', ');
  }, [data]);

  return {
    accountAddressString,
    inspectionAddressString,
    landlordAddressString,
  };
};

export const useCertificateNumber = (data: any) => {
  const certificateNumber = useMemo(() => {
    if (data) {
      return `${data.certificate_number}${
        data.revision ? `-${data.revision}` : ''
      }`;
    }
    return '';
  }, [data]);

  return certificateNumber;
};

export const useCommonFormAccountData = (account: Account) => {
  const brandColour = useMemo(() => {
    return account?.brandColour || '';
  }, [account]);

  return { brandColour };
};
