import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { backOff } from 'exponential-backoff';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import {
  useChangeProposal,
  useCustomerForDeal,
  useDeal,
  useUploads,
} from '@payaca/store/hooks/appState';
import { useSelector } from '@/api/state';
import * as uploadsActions from '@payaca/store/uploads/uploadsActions';

import './ChangeProposalPage.sass';
import {
  getChangeProposalGeneratedPdfUrl,
  markChangeProposalAccepted,
  markChangeProposalDeclined,
  requestGetChangeProposal,
  requestGetChangeProposalsForDeal,
  sendChangeProposal,
  voidChangeProposal,
} from '@payaca/store/proposals/proposalsActions';
import { useHistory } from 'react-router';
import ChangeProposalDocument from '@/ui/components/changeProposalDocument/ChangeProposalDocument';
import A4DocumentWrapper from '@payaca/components/a4DocumentWrapper/A4DocumentWrapper';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';
import { requestGetJobsForDeal } from '@payaca/store/jobs/jobsActions';
import { requestGetJobContentsForDeal } from '@payaca/store/jobContent/jobContentActions';
import { requestGetCustomer } from '@payaca/store/customer/customerActions';
import ChangeProposalActions from '@/ui/components/changeProposalActions/ChangeProposalActions';
import PreviewDocument from '@/ui/components/PreviewQuoteInvoice/PreviewDocument';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import { getRegion, getUserRoles } from '@/utils/stateAccessors';
import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { ProposalsPermissions } from '@payaca/permissions/proposals/proposals.permissions';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import { TDropdownItem } from '@payaca/components/plDropdown/DropdownCore';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { InvoicesPermissions } from '@payaca/permissions/invoices/invoices.permissions';
import { downloadFile } from '@/utils/download-file';
import Modal from '@payaca/components/modal/Modal';
import BounceResendModal from '@/ui/components/bounceResendModal/BounceResendModal';
import { buildEmailSubjectPrefixForProjectFromProject } from '@payaca/helpers/dealHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import ResendModal from '@/ui/components/resendModal/ResendModal';
import { getInvoicePLBadgeState } from '@payaca/helpers/invoiceHelper';
import { getChangeProposalPLBadgeState } from '@payaca/helpers/changeProposalHelper';
import UntitledIcon from '@payaca/untitled-icons';

type Props = {
  changeProposalId: number;
  dealId?: number;
};

const ChangeProposalPage: FC<Props> = ({
  changeProposalId,
  dealId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const changeProposal = useChangeProposal(changeProposalId);
  const deal = useDeal(changeProposal?.dealId);

  const attachmentUploads = useUploads(changeProposal?.attachmentUploadIds);

  const [isMarkingAsAccepted, setIsMarkingAsAccepted] = useState(false);
  const [isMarkingAsDeclined, setIsMarkingAsDeclined] = useState(false);
  const [isVoiding, setIsVoiding] = useState(false);
  const [isGettingPdfUrl, setIsGettingPdfUrl] = useState(false);
  const [
    showConfirmVoidChangeProposalModal,
    setShowConfirmVoidChangeProposalModal,
  ] = useState(false);
  const [showBounceResendModal, setShowBounceResendModal] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);

  const userRoles = useSelector(getUserRoles);

  const companyName = useSelector(
    (state: any) => state.users.myProfile.accounts[0].companyName
  );

  const customer = useCustomerForDeal(changeProposal?.dealId);

  const customerContact = useMemo(() => {
    if (!customer) return;
    return getJobContactFromCustomer(
      customer,
      changeProposal?.contactId || null
    );
  }, [customer?.contacts, changeProposal?.contactId]);

  const region = useSelector(getRegion);

  const fetchPdfRetry = useCallback(() => {
    return backOff(
      async () => {
        return new Promise<void>((resolve, reject) => {
          dispatch(
            requestGetChangeProposal(changeProposalId, (po) => {
              if (po.pdfUrl) {
                resolve();
              } else {
                reject();
              }
            })
          );
        });
      },
      {
        numOfAttempts: 5,
        startingDelay: 1500,
        delayFirstAttempt: true,
      }
    );
  }, [changeProposalId]);

  const handleMarkAsAccepted = useCallback(() => {
    setIsMarkingAsAccepted(true);
    dispatch(
      markChangeProposalAccepted.request(
        changeProposalId,
        () => {
          setIsMarkingAsAccepted(false);
          dispatch(requestGetChangeProposal(changeProposalId));
          fetchPdfRetry();
        },
        (_err) => setIsMarkingAsAccepted(false)
      )
    );
  }, [changeProposalId]);

  const handleMarkAsDeclined = useCallback(() => {
    setIsMarkingAsDeclined(true);
    dispatch(
      markChangeProposalDeclined.request(
        changeProposalId,
        () => {
          setIsMarkingAsDeclined(false);
          dispatch(requestGetChangeProposal(changeProposalId));
          fetchPdfRetry();
        },
        (_err) => setIsMarkingAsDeclined(false)
      )
    );
  }, [changeProposalId, fetchPdfRetry]);

  useEffect(() => {
    if (!changeProposal?.pdfUrl) {
      fetchPdfRetry();
    }
  }, []);

  useEffect(() => {
    dispatch(requestGetChangeProposal(changeProposalId));
    dispatch(
      uploadsActions.requestGetUploadsForEntity(
        changeProposalId,
        'changeProposal'
      )
    );
  }, []);

  useEffect(() => {
    if (changeProposal?.dealId) {
      dispatch(requestGetDeal(changeProposal.dealId));
      dispatch(requestGetJobsForDeal(changeProposal.dealId));
      dispatch(requestGetJobContentsForDeal(changeProposal.dealId));
      dispatch(requestGetChangeProposalsForDeal(changeProposal.dealId));
    }
  }, [changeProposal?.dealId]);

  useEffect(() => {
    if (deal?.customerId) {
      dispatch(requestGetCustomer(deal.customerId));
    }
  }, [deal?.customerId]);

  useEffect(() => {
    if (changeProposal && !changeProposal?.sentAt) {
      history.push(`/deals/${dealId}/changeproposals/${changeProposalId}/edit`);
    }
  }, [changeProposal?.sentAt]);

  const handleDownloadChangeProposal = useCallback(() => {
    if (!changeProposal) return;
    setIsGettingPdfUrl(true);
    dispatch(
      getChangeProposalGeneratedPdfUrl.request({
        changeProposalId: changeProposalId,
        callback: (pdfUrl: string) => {
          setIsGettingPdfUrl(false);
          downloadFile(
            pdfUrl,
            `change-proposal-${
              changeProposal.customReference || changeProposal.reference
            }.pdf`
          );
          requestGetChangeProposal(changeProposalId);
        },
        onErrorCallback: () => setIsGettingPdfUrl(false),
      })
    );
  }, [changeProposal, setIsGettingPdfUrl, changeProposalId]);

  const availableContextMenuItems = useMemo(() => {
    const actions: TDropdownItem[] = [];

    if (changeProposal?.voidedAt) {
      return actions;
    }

    if (
      !changeProposal?.acceptedAt &&
      !changeProposal?.declinedAt &&
      userHasRequiredPermission(userRoles, [
        ProposalsPermissions.MARK_CHANGE_PROPOSAL_ACCEPTED,
      ])
    ) {
      actions.push({
        label: 'Mark as accepted',
        onClick: handleMarkAsAccepted,
        isProcessing: isMarkingAsAccepted,
        disabled: isMarkingAsAccepted,
      });
    }

    if (
      !changeProposal?.acceptedAt &&
      !changeProposal?.declinedAt &&
      userHasRequiredPermission(userRoles, [
        ProposalsPermissions.MARK_CHANGE_PROPOSAL_DECLINED,
      ])
    ) {
      actions.push({
        label: 'Mark as declined',
        onClick: handleMarkAsDeclined,
        isProcessing: isMarkingAsDeclined,
        disabled: isMarkingAsDeclined,
      });
    }

    if (
      changeProposal?.sentAt &&
      !changeProposal?.bouncedAt &&
      userHasRequiredPermission(userRoles, [
        ProposalsPermissions.SEND_CHANGE_PROPOSAL,
      ])
    ) {
      actions.push({
        label: 'Resend',
        onClick: () => setShowResendModal(true),
      });
    }

    if (
      !changeProposal?.acceptedAt &&
      userHasRequiredPermission(userRoles, [
        ProposalsPermissions.VOID_CHANGE_PROPOSAL,
      ])
    ) {
      actions.push({
        label: 'Void this change proposal',
        onClick: () => setShowConfirmVoidChangeProposalModal(true),
      });
    }

    return actions;
  }, [
    changeProposal,
    userRoles,
    handleMarkAsAccepted,
    handleMarkAsDeclined,
    setShowResendModal,
    setShowConfirmVoidChangeProposalModal,
    isMarkingAsAccepted,
    isMarkingAsDeclined,
  ]);

  return (
    <AuthenticatedPageWrapper
      className="bg-gray-50"
      previousPageNavigationConfig={{
        route: `/deals/${dealId}/proposals`,
        navigationPromptName: 'Back',
      }}
    >
      <div className="p-4">
        <PreviewDocument
          title="Change proposal"
          badge={{
            variant: 'soft',
            colour: getChangeProposalPLBadgeState(
              changeProposal?.readableStatus
            ),
            children: changeProposal?.readableStatus,
          }}
          firstAction={
            changeProposal?.pdfUrl
              ? {
                  children: (
                    <UntitledIcon name="download-01.3" className="h-5 w-5" />
                  ),
                  onClick: () => {
                    // Todo: downloadable link for a context menu item?
                    window.open(changeProposal.pdfUrl, '_blank');
                  },
                }
              : {
                  children: (
                    <UntitledIcon name="download-01.3" className="h-5 w-5" />
                  ),
                  onClick: handleDownloadChangeProposal,
                  isProcessing: isGettingPdfUrl,
                  disabled: isGettingPdfUrl,
                }
          }
          actions={availableContextMenuItems}
        >
          {changeProposal?.bouncedAt && (
            <FeedbackBlock feedbackLevel={FeedbackLevel.ALERT}>
              <p>
                The email sending this change proposal to your customer has
                bounced
              </p>
              <PermissionGuard
                renderIfHasPermissions={[
                  ProposalsPermissions.SEND_CHANGE_PROPOSAL,
                ]}
              >
                <Button
                  styleVariant={ButtonStyleVariant.ANCHOR}
                  onClick={() => setShowBounceResendModal(true)}
                >
                  Retry
                </Button>
              </PermissionGuard>
            </FeedbackBlock>
          )}

          <ChangeProposalDocument
            isPreview
            changeProposalId={changeProposalId}
          />
        </PreviewDocument>
      </div>

      <Modal
        isOpen={showConfirmVoidChangeProposalModal}
        onClose={() => setShowConfirmVoidChangeProposalModal(false)}
        title={'Are you sure you want to void the Change Proposal?'}
        actions={
          <>
            <Button
              colourVariant={ButtonColourVariant.RED}
              styleVariant={ButtonStyleVariant.OUTSIZE}
              onClick={() => {
                if (isVoiding) return;
                setIsVoiding(true);
                dispatch(
                  voidChangeProposal.request(
                    changeProposalId,
                    () => {
                      dispatch(requestGetChangeProposal(changeProposalId));
                      setIsVoiding(false);
                      setShowConfirmVoidChangeProposalModal(false);
                      fetchPdfRetry();
                    },
                    (_err) => setIsVoiding(false)
                  )
                );
              }}
              isProcessing={isVoiding}
            >
              Void
            </Button>
          </>
        }
      ></Modal>
      {deal?.customerId && (
        <BounceResendModal
          title="Resend Change Proposal"
          isOpen={showBounceResendModal}
          onClose={() => setShowBounceResendModal(false)}
          customerId={deal.customerId}
          customerContactId={changeProposal?.contactId}
          resendConfig={{
            handleResend: async (requestData: {
              sendMeACopy?: boolean;
              emailCopy: {
                preButton: string;
                postButton: string;
              };
            }) => {
              dispatch(
                sendChangeProposal.request({
                  isResend: true,
                  changeProposalId: changeProposalId,
                  sendMeACopy: requestData.sendMeACopy || false,
                  emailCopy: requestData.emailCopy,
                  callback: () => {
                    dispatch(requestGetChangeProposal(changeProposalId));
                    return Promise.resolve();
                  },
                  onErrorCallback: (errorMessages) => {
                    return Promise.reject(errorMessages);
                  },
                })
              );
            },
            documentType: 'change proposal',
            emailConfig: {
              subject: `${buildEmailSubjectPrefixForProjectFromProject(
                deal
              )}Your change proposal from ${companyName}`,
              bodyTemplate: `
Dear [customer_name],
Your change proposal representing a value change of [value_change] can be viewed here:
[button]
Thanks,
[company_name]
              `,
              substitutions: [
                {
                  find: 'reference',
                  replaceWith:
                    changeProposal?.customReference ||
                    String(changeProposal?.reference),
                },
                { find: 'company_name', replaceWith: companyName },
                { find: 'customer_name', replaceWith: customerContact?.name },
                {
                  find: 'value_change',
                  replaceWith: currencyPrice(
                    changeProposal?.valueChangeIncTax || 0,
                    region
                  ),
                },
              ],
            },
          }}
        />
      )}
      {deal?.customerId && (
        <ResendModal
          title="Resend Change Proposal"
          isOpen={showResendModal}
          onClose={() => setShowResendModal(false)}
          customerId={deal.customerId}
          customerContactId={changeProposal?.contactId}
          resendConfig={{
            handleResend: async (requestData: {
              sendMeACopy?: boolean;
              emailCopy: {
                preButton: string;
                postButton: string;
              };
            }) => {
              dispatch(
                sendChangeProposal.request({
                  isResend: true,
                  changeProposalId: changeProposalId,
                  sendMeACopy: requestData.sendMeACopy || false,
                  emailCopy: requestData.emailCopy,
                  callback: () => {
                    dispatch(requestGetChangeProposal(changeProposalId));
                    return Promise.resolve();
                  },
                  onErrorCallback: (errorMessages) => {
                    return Promise.reject(errorMessages);
                  },
                })
              );
            },
            documentType: 'change proposal',
            emailConfig: {
              subject: `${buildEmailSubjectPrefixForProjectFromProject(
                deal
              )}Your change proposal from ${companyName}`,
              bodyTemplate: `
Dear [customer_name],
Your change proposal representing a value change of [value_change] can be viewed here:
[button]
Thanks,
[company_name]
              `,
              substitutions: [
                {
                  find: 'reference',
                  replaceWith:
                    changeProposal?.customReference ||
                    String(changeProposal?.reference),
                },
                { find: 'company_name', replaceWith: companyName },
                { find: 'customer_name', replaceWith: customerContact?.name },
                {
                  find: 'value_change',
                  replaceWith: currencyPrice(
                    changeProposal?.valueChangeIncTax || 0,
                    region
                  ),
                },
              ],
            },
          }}
        />
      )}
    </AuthenticatedPageWrapper>
  );
};

export default ChangeProposalPage;
