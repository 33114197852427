import { ComplianceFormPrefillDataQuery } from '../gql/graphql';

type DNOAddress = {
  city?: string;
  country?: string;
  postcode?: string;
  line1?: string;
  line2?: string;
};
type DNOPrefillFormData = {
  installerCustomerDetails?: {
    customerEmail?: string;
    customerName?: string;
    customerPhone?: string;
    mainAddress?: DNOAddress; // Installation address
    installationPostCode?: DNOAddress;
    installerCompany?: string;
    installerEmail?: string;
    installerName?: string;
    installerPhone?: string;
  };
};
export const getInitialPrefillValues = (
  prefillData?: ComplianceFormPrefillDataQuery
) => {
  if (!prefillData) {
    return;
  }
  const initial: DNOPrefillFormData = {};
  if (prefillData?.me.user.account) {
    initial.installerCustomerDetails = {
      ...initial.installerCustomerDetails,
      installerCompany: prefillData?.me.user.account.name,
      installerEmail: prefillData?.project.owner?.email || undefined,
      installerPhone: prefillData?.project.owner?.phone || undefined,
      installerName: prefillData.project.owner?.fullName || undefined,
    };
  }
  if (prefillData?.project.customer) {
    initial.installerCustomerDetails = {
      ...initial.installerCustomerDetails,
      customerName: prefillData.project.customer.name,
      customerPhone:
        prefillData.project.customer.primaryContact?.phone || undefined,
      customerEmail:
        prefillData.project.customer?.primaryContact?.email || undefined,
    };
  }
  if (prefillData?.project.addresses?.site) {
    initial.installerCustomerDetails ||= {};
    const site = prefillData?.project.addresses?.site;
    initial.installerCustomerDetails.mainAddress = {
      city: site[0]?.city || undefined,
      country: site[0]?.country || undefined,
      postcode: site[0]?.postalCode || undefined,
      line1: site[0]?.line1 || undefined,
      line2: site[0]?.line2 || undefined,
    };
  }
  return initial;
};
