import React, { FC, Fragment, useState } from 'react';

import Button from '@payaca/components/button/Button';

import { CreateEditDistributionBoardModal } from './CreateEditDistributionBoardModal';

import { DistributionBoardSummary } from './DistributionBoardSummary';

import './DistributionBoardListField.sass';
import {
  DistributionBoard,
  ElectricalFormVersion,
} from '@payaca/types/electrical-forms-types';

type Props = {
  version?: ElectricalFormVersion;
  id?: string;
  distributionBoards?: Array<DistributionBoard>;
  onChange: (dbs: Array<DistributionBoard>) => void;
};

export const DistributionBoardListField: FC<Props> = ({
  distributionBoards,
  onChange,
  version = '18',
}) => {
  const [
    shouldShowCreateEditDistributionBoardModal,
    setShowCreateEditDistributionBoardModal,
  ] = useState(false);

  const handleAddDistributionBoard = (x: DistributionBoard) => {
    const newArr = [...(distributionBoards || []), x];
    onChange(newArr);
  };

  const handleUpdateDistributionBoard = (x: DistributionBoard, i: number) => {
    const newArr = [...(distributionBoards || [])];
    newArr[i] = x;
    onChange(newArr);
  };

  const handleDeleteDistributionBoard = (i: number) => {
    const newArr = [...(distributionBoards || [])];
    newArr.splice(i, 1);
    onChange(newArr);
  };

  return (
    <article className="distribution-board-list-field">
      <header className="distribution-boards-header">
        <h2>Distribution boards</h2>
        <Button onClick={() => setShowCreateEditDistributionBoardModal(true)}>
          Add DB
        </Button>
      </header>
      <hr />
      {distributionBoards?.map((x, i) => (
        <Fragment key={i}>
          {i > 0 && <hr />}
          <DistributionBoardSummary
            version={version}
            distributionBoard={x}
            onPersist={(db) => handleUpdateDistributionBoard(db, i)}
            onDelete={() => handleDeleteDistributionBoard(i)}
          />
        </Fragment>
      ))}
      <CreateEditDistributionBoardModal
        version={version}
        isOpen={shouldShowCreateEditDistributionBoardModal}
        onPersist={handleAddDistributionBoard}
        onClose={() => setShowCreateEditDistributionBoardModal(false)}
      />
    </article>
  );
};
