import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Search } from 'react-iconly';
import { useDispatch } from 'react-redux';

import * as itemActions from '@payaca/store/listedItems/listedItemsActions';

import IconButton from '@payaca/components/button/IconButton';
import ImageBlock from '@payaca/components/imageBlock/ImageBlock';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import TypeToSearchField from '@payaca/components/typeToSearchField/TypeToSearchField';
import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';

import { JobLineItemBase } from '@payaca/types/jobContentTypes';
import {
  GetListedItemsRequestData,
  ListedItem,
  SortBy,
} from '@payaca/types/listedItemTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

import './AddJobLineItemControl.sass';
import ConfirmModal from '../modal/ConfirmModal';
import { getModal } from '@/helpers/modalHelper';
import { useSelector } from '@/api/state';

interface Option {
  id: number;
  reference: string;
  description: string;
  thumbnailUrl: string;
}

type Props = {
  handleCreateJobLineItemAndOpenCreateJobLineItemModal: (
    lineItemId: number | null,
    initialItemData?: Partial<JobLineItemBase>
  ) => void;
  handleOpenAdvancedSearchLineItemModal: (searchTerm?: string) => void;
  disabledItemIds: number[];
};
const AddJobLineItemControl: FC<Props> = ({
  disabledItemIds = [],
  handleCreateJobLineItemAndOpenCreateJobLineItemModal,
  handleOpenAdvancedSearchLineItemModal,
}: Props) => {
  const dispatch = useDispatch();

  const [itemId, setItemId] = useState<number>();
  const [showRepeatedItemConfirmation, setShowRepeatedItemConfirmation] =
    useState(false);
  const [getListedItemsRequestData, setGetListedItemsRequestData] =
    useState<GetListedItemsRequestData>({
      pageSize: 50,
      pageNumber: 1,
      searchTerm: '',
      sortDirection: SortDirection.ASCENDING,
      sortBy: SortBy.NAME,
    });

  useEffect(() => {
    requestGetListedItemsPage();
  }, [getListedItemsRequestData]);

  const requestGetListedItemsPage = useCallback(() => {
    dispatch(itemActions.requestGetListedItemsPage(getListedItemsRequestData));
  }, [getListedItemsRequestData, dispatch]);

  const listedItemsPage = useSelector((state: any) => {
    return state.listedItems?.listedItemsPage;
  });

  const isGettingListedItemsPage = useSelector(
    (state: any) => state.listedItems.isGettingListedItemsPage
  );

  const itemOptions = useMemo(() => {
    if (getListedItemsRequestData.searchTerm) {
      return (listedItemsPage?.items || []).map((li: ListedItem) => ({
        id: li.itemId,
        reference: li.name,
        description: li.description,
        thumbnailUrl: li.thumbnailUrl,
      }));
    }
    return [];
  }, [listedItemsPage?.items, getListedItemsRequestData.searchTerm]);

  const onSearchTermChange = (searchTerm: string) => {
    setGetListedItemsRequestData(
      (getListedItemsRequestData: GetListedItemsRequestData) => {
        return {
          ...getListedItemsRequestData,
          pageNumber: 1,
          searchTerm,
        };
      }
    );
  };

  const isOptionDisabled = useCallback(
    (optionId: number) => {
      return optionId >= 0 && disabledItemIds.includes(optionId);
    },
    [disabledItemIds]
  );

  const renderOption = (option: Option) => {
    const isDisabled = isOptionDisabled(option.id);
    return (
      <div
        className={`listed-line-item-option flex-container flex-center${
          isDisabled ? ' disabled' : ''
        }`}
      >
        {/* create new */}
        {option.id === -1 && <IconButton size={'xs'} icon={faPlus} />}
        {/* advanced search */}
        {option.id === -2 && <Search size={21} />}
        {/* line item image */}
        {option.id > 0 && <ImageBlock imageSrc={option.thumbnailUrl} />}
        <div className="listed-line-item-description-reference-wrapper">
          {option.reference && (
            <span className="listed-line-item-name">{option.reference}</span>
          )}
          <MarkdownLabel
            markdown={option.description}
            className="listed-line-item-description"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <TypeToSearchField
        styleVariant={InputStyleVariant.OUTSIZE}
        options={[
          ...itemOptions,
          {
            id: -1,
            description: 'Create a new Item',
          },
          {
            id: -2,
            description: 'Advanced search',
          },
        ]}
        renderOption={renderOption}
        autoHighlight={true}
        disableFilteringInComponent={true}
        isLoadingOptions={isGettingListedItemsPage}
        onSelectOption={(option?: Option) => {
          if (option?.id === -1) {
            let initialData;
            if (getListedItemsRequestData.searchTerm) {
              initialData = {
                description: getListedItemsRequestData.searchTerm,
              };
            }
            // selected "Create a new item" - open create item modal
            handleCreateJobLineItemAndOpenCreateJobLineItemModal(
              null,
              initialData
            );
          } else if (option?.id === -2) {
            // selected "Advanced search" - open search items modal
            handleOpenAdvancedSearchLineItemModal(
              getListedItemsRequestData.searchTerm
            );
          } else if (option?.id) {
            const isDisabled = isOptionDisabled(option.id);
            if (isDisabled) {
              setItemId(option.id);
              setShowRepeatedItemConfirmation(true);
            } else {
              // selected line item - open create item modal
              handleCreateJobLineItemAndOpenCreateJobLineItemModal(option.id);
            }
          }
        }}
        placeholder="Type here to search Items"
        onSearchTermChange={onSearchTermChange}
      />
      <ConfirmModal
        {...getModal('ADD_REPEATED_ITEM')}
        onClose={() => {
          setItemId(undefined);
          setShowRepeatedItemConfirmation(false);
        }}
        secondaryAction={() => {
          setItemId(undefined);
          setShowRepeatedItemConfirmation(false);
        }}
        primaryAction={() => {
          handleCreateJobLineItemAndOpenCreateJobLineItemModal(
            itemId as number
          );
          setItemId(undefined);
          setShowRepeatedItemConfirmation(false);
        }}
        open={showRepeatedItemConfirmation}
      />
    </>
  );
};

export default AddJobLineItemControl;
