import Modal from '@payaca/components/plModal/Modal';
import { Customer } from '@payaca/types/customerTypes';
import { Invoice } from '@payaca/types/invoiceTypes';
import { singularPlural } from '@payaca/utilities/stringUtilities';
import { FC } from 'react';
import { BulkMarkInvoicesAsPaidControl } from './BulkMarkInvoicesAsPaidControl';

export const BulkMarkInvoicesAsPaidModal: FC<{
  isOpen: boolean;
  onClose?: () => void;
  invoices: (Pick<Invoice, 'id' | 'dueValue'> & { reference: string })[];
  customer: Pick<Customer, 'id' | 'name'>;
  onSuccess?: () => void;
}> = ({ isOpen, onClose, invoices, customer, onSuccess }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Mark ${singularPlural(
        invoices.length,
        'invoice',
        'invoices'
      )} as paid`}
    >
      <BulkMarkInvoicesAsPaidControl
        invoices={invoices}
        customer={customer}
        onSuccess={() => {
          onClose?.();
          onSuccess?.();
        }}
      />
    </Modal>
  );
};
