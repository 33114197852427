export enum ButtonStyleVariant {
  STANDARD = 'standard',
  OUTSIZE = 'outsize',
  ANCHOR = 'anchor',
  CIRCULAR = 'circular',
  FULLWIDTH = 'full-width',
}

export enum ButtonColourVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  MIDBLUE = 'midblue',
  WHITE = 'white',
  GREY = 'grey',
  RED = 'red',
}
