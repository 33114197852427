export enum DocumentsPermissions {
  GET_DOCUMENTS_FOR_DEAL = 'documents.getDocumentsForDeal',
  GET_DOCUMENTS_FOR_MY_DEAL = 'documents.getDocumentsForMyDeal',
  ADD_DOCUMENT = 'documents.addDocument',
  ASSIGNEE_ADD_DOCUMENT = 'documents.assigneeAddDocument',
  UPLOAD_DOCUMENT = 'documents.uploadDocument',
  ASSIGNEE_UPLOAD_DOCUMENT = 'documents.assigneeUploadDocument',
  DELETE_DOCUMENT = 'documents.deleteDocument',
  ASSIGNEE_DELETE_DOCUMENT = 'documents.assigneeDeleteDocument',
  EDIT_DOCUMENT = 'documents.editDocument',
  ASSIGNEE_EDIT_DOCUMENT = 'documents.assigneeEditDocument',
  SEND_DOCUMENT = 'documents.sendDocument',
  ASSIGNEE_SEND_DOCUMENT = 'documents.assigneeSendDocument',
  MANAGE_PREFILL_PREFERENCES = 'documents.managePrefillPreferences',
}
