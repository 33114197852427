import { Reducer } from 'redux';

import { ActionType, LineItemsState } from './lineItemsTypes';

export const initialState: LineItemsState = {
  currentLineItem: {
    name: '',
    description: '',
    price: '',
    vatAmount: undefined,
    vatIncluded: true,
    taxRateId: undefined,
    attachments: [],
  },

  isGettingLineItem: false,
  gettingLineItemError: null,

  isDeletingLineItems: false,
  deletingLineItemsError: null,

  isCreatingUpdatingLineItemAttachments: false,
  creatingUpdatingLineItemAttachmentsError: null,
  isLineItemAttachmentsSuccessfullyCreatedUpdated: false,
};

const lineItemsReducer: Reducer<LineItemsState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    //  GETTING LINE ITEM
    case ActionType.REQUEST_GET_LINE_ITEM:
      return {
        ...state,
        isGettingLineItem: true,
        gettingLineItemError: null,
      };
    case ActionType.GET_LINE_ITEM_SUCCESS:
      return {
        ...state,
        isGettingLineItem: false,
        gettingLineItemError: null,
      };
    case ActionType.GET_LINE_ITEM_FAILURE:
      return {
        ...state,
        isGettingLineItem: false,
        gettingLineItemError: action.payload.errorMessage,
      };

    // DELETING LINE ITEM
    case ActionType.REQUEST_DELETE_LINE_ITEMS:
      return {
        ...state,
        isDeletingLineItems: true,
        deletingLineItemsError: null,
      };
    case ActionType.DELETE_LINE_ITEMS_SUCCESS:
      return {
        ...state,
        isDeletingLineItems: false,
        deletingLineItemsError: null,
      };
    case ActionType.DELETE_LINE_ITEMS_FAILURE:
      return {
        ...state,
        isDeletingLineItems: false,
        deletingLineItemsError: action.payload.errorMessage,
      };

    // CREATING/UPDATING LINE ITEM ATTACHMENTS
    case ActionType.REQUEST_CREATE_UPDATE_LINE_ITEM_ATTACHMENTS:
      return {
        ...state,
        isCreatingUpdatingLineItemAttachments: true,
        creatingUpdatingLineItemAttachmentsError: null,
        isLineItemAttachmentsSuccessfullyCreatedUpdated: false,
      };
    case ActionType.CREATE_UPDATE_LINE_ITEM_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        isCreatingUpdatingLineItemAttachments: false,
        creatingUpdatingLineItemAttachmentsError: null,
        isLineItemAttachmentsSuccessfullyCreatedUpdated: true,
      };
    case ActionType.CREATE_UPDATE_LINE_ITEM_ATTACHMENTS_FAILURE:
      return {
        ...state,
        isCreatingUpdatingLineItemAttachments: false,
        creatingUpdatingLineItemAttachmentsError: action.payload.errorMessage,
        isLineItemAttachmentsSuccessfullyCreatedUpdated: false,
      };

    // LOCAL
    case ActionType.CLEAR_CURRENT_LINE_ITEM:
      return {
        ...state,
        currentLineItem: initialState.currentLineItem,
        gettingLineItemError: null,
        creatingUpdatingLineItemAttachmentsError: null,
        isCreatingUpdatingLineItemAttachments: false,
        isLineItemAttachmentsSuccessfullyCreatedUpdated: false,
      };

    case ActionType.CLEAR_LINE_ITEMS:
      return {
        ...state,
        lineItems: null,
        gettingLineItemsError: null,
        deletingLineItemsError: null,
      };

    case ActionType.STORE_CURRENT_LINE_ITEM:
      return { ...state, currentLineItem: action.payload.lineItem };

    case ActionType.INITIALISE_NEW_LINE_ITEM:
      return { ...state, currentLineItem: initialState.currentLineItem };

    case ActionType.UPDATE_CURRENT_LINE_ITEM:
      return {
        ...state,
        currentLineItem: {
          ...state.currentLineItem,
          ...action.payload.lineItemUpdates,
        },
      };

    default:
      return state;
  }
};

export default lineItemsReducer;
