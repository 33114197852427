import { useQuery } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import meKeys from '@/api/queries/me/keyFactory';
import { GetTagsInput, PaginationInput } from '@/gql/graphql';

const GetMyPaginatedAvailableTags = graphql(`
  query MyPaginatedAvailableTags(
    $input: GetTagsInput
    $pagination: PaginationInput
  ) {
    me {
      user {
        account {
          availableTags(input: $input, pagination: $pagination) {
            limit
            offset
            totalCount
            items {
              colour
              id
              name
              createdAt
              archivedAt
              usageSummary {
                projectsCount
                eventsCount
                customersCount
              }
            }
          }
        }
      }
    }
  }
`);

const useGetMyPaginatedAvailableTags = (
  input: GetTagsInput,
  pagination: PaginationInput
) => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountAvailableTags(input, pagination),
    queryFn: () => {
      return gqlClient.request(GetMyPaginatedAvailableTags, {
        input,
        pagination,
      });
    },
  });

  return { data: data?.me.user.account.availableTags, ...rest };
};

export default useGetMyPaginatedAvailableTags;
