import React, { FC } from 'react';
import { Menu } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import Button, { IButtonProps } from '../../plButton/Button';
import DropdownCore, { TDropdownItem } from '../../plDropdown/DropdownCore';
import UntitledIcon from '@payaca/untitled-icons';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '../../plButton/useButtonClassName';

export interface IProps extends IButtonProps {
  actions: TDropdownItem[];
}

const RowActionDropdown: FC<IProps> = (props) => {
  const { actions, ...buttonProps } = props;

  if (actions.length === 0) return null;

  return (
    <Menu>
      <Float placement="bottom-end" portal>
        <Menu.Button
          as={Button}
          variant={EBtnVariant.White}
          size={EBtnSize.Small}
          {...buttonProps}
        >
          <UntitledIcon
            name={'dots-vertical.3'}
            className="-mx-1.5 h-3.5 w-3.5"
          />
        </Menu.Button>

        <DropdownCore items={actions} />
      </Float>
    </Menu>
  );
};

export default RowActionDropdown;
