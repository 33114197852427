import React, { FunctionComponent } from 'react';
import './FieldLabel.sass';

export enum LabelStyleVariant {
  STANDARD = 'standard',
  LIGHT = 'light',
}

type Props = {
  label?: string | JSX.Element;
  isRequired?: boolean;
  description?: string | JSX.Element;
  id?: string;
  styleVariant?: LabelStyleVariant;
};

const FieldLabel: FunctionComponent<React.PropsWithChildren<Props>> = ({
  label,
  id,
  isRequired = false,
  description,
  styleVariant = LabelStyleVariant.STANDARD,
}: React.PropsWithChildren<Props>): JSX.Element | null => {
  if (!label && !isRequired && !description) return null;
  return (
    <div className={`field-label field-label--style-${styleVariant}`}>
      {label && (
        <label htmlFor={id} className={`field-label__label`}>
          {label}
          {isRequired && (
            <span className={`field-label__required-indicator`}>*</span>
          )}
        </label>
      )}
      {description && (
        <span className={`field-label__description`}>{description}</span>
      )}
    </div>
  );
};

export default FieldLabel;
