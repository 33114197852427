import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './CreateEditAutomation.sass';
import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import Modal from '@payaca/components/modal/Modal';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import {
  Automation,
  AutomationAction,
  automationActionTypes,
  AutomationActionTypes,
  automationEntityTypes,
  AutomationEntityTypes,
  AutomationTriggerEvents,
} from '@payaca/types/automationTypes';
import {
  automationActionTypeReadableNameMap,
  automationEntityActionTypeMap,
  automationEntityTypeReadableNameMap,
  automationEntityTypeSubscriptionMap,
  automationEntityTypeTriggerEventMap,
} from '@payaca/helpers/automationHelper';
import { useDispatch } from 'react-redux';
import * as automationActions from '@payaca/store/automations/automationActions';
import { Prompt, useParams } from 'react-router-dom';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { AutomationsPermissions } from '@payaca/permissions/automations/automations.permissions';
import { SubscriptionLevels } from '@payaca/permissions/default-role.config';
import {
  userHasRequiredPermission,
  userHasRequiredSubscription,
} from '@payaca/permissions/permissions.utils';
import { useHistory } from 'react-router';
import AuthenticatedPageWrapper from '@/ui/pages/pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { CreateEditAutomationHeader } from '@/ui/pages/automationsPageOld/CreateEditAutomationHeader';
import {
  AutomationConfigBlock,
  AutomationConfigBlockType,
} from '@/ui/pages/automationsPageOld/AutomationConfigBlock';
import ActionIcon from './icons/action-icon.svg';
import {
  InputColourVariant,
  InputStyleVariant,
} from '@payaca/components/inputWrapper/InputWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {
  CONDITION_OPERATOR_OPTIONS,
  FIELD_OPTIONS,
} from '@/ui/pages/automationsPageOld/automation.utils';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import CreateArea from '@payaca/components/clickableArea/CreateArea';
import { useSelector } from '@/api/state';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@/i18n';
import { getUserRoles } from '../../../utils/stateAccessors';
import AutomationConditionValueField from '@/ui/components/automationConditionValueField/AutomationConditionValueField';
import AutomationActionConfigFieldset from '@/ui/components/automationActionConfigFieldset/AutomationActionConfigFieldset';
import { getFieldValidatorsForCustomAutomation } from '@payaca/helpers/automationValidationHelper';
import { AutomationDataAccessType } from '@payaca/types/automationDataAccessTypes';
import { useProjectCustomFields } from '@/hooks/useProjectCustomFields';

const initialFormState: Partial<Automation<AutomationActionTypes>> = {
  id: 0,
  title: 'Untitled automation',
  entityType: AutomationEntityTypes.QUOTE,
  triggerEvent: AutomationTriggerEvents.CREATED,
  conditions: [],
  actions: [],
  isActive: false,
};

const CreateEditAutomationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { automationId } = useParams<{ automationId: string }>();
  const [showAutomationActivationModal, setShowAutomationActivationModal] =
    useState(false);
  const [formState, setFormState] =
    useState<Partial<Automation<AutomationActionTypes>>>(initialFormState);
  const [openSectionIndex, setOpenSectionIndex] = useState<number | null>(0);
  const [showAutomationResetModal, setShowAutomationResetModal] =
    useState(false);
  const [
    showInvalidSubmissionWarningModal,
    setShowInvalidSubmissionWarningModal,
  ] = useState(false);
  const [hasChangesMade, setHasChangesMade] = useState(false);
  const userRoles = useSelector(getUserRoles);

  const automation = useSelector(
    (state) =>
      state.automations.automations[
        automationId as unknown as number /* FIXME */
      ]?.entity
  );
  const accountSubscription = useSelector(
    (state) => state.subscription?.accountSubscription
  );
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  const isPerformingAutomationAction = useSelector(
    (state) => state.automations.isPerformingAutomationAction
  );

  useEffect(() => {
    if (automation) {
      setFormState(automation);
    }
  }, [automation]);

  useEffect(() => {
    if (!automationId) return;
    dispatch(automationActions.requestGetAutomation(Number(automationId)));
  }, [dispatch, automationId]);

  const allowableEntityTypeOptions = useMemo(
    () =>
      automationEntityTypes
        .filter((option: AutomationEntityTypes) => {
          return (
            userHasRequiredSubscription(
              accountSubscription?.subscriptionProduct,
              automationEntityTypeSubscriptionMap[option] ||
                SubscriptionLevels.SOLO
            ) && option != AutomationEntityTypes.INVOICE_V2
          );
        })
        .map((value: AutomationEntityTypes) => ({
          label: automationEntityTypeReadableNameMap[value] || value,
          value: value,
        })),
    [accountSubscription]
  );

  const triggerEventOptions = useMemo(
    () =>
      (formState?.entityType
        ? automationEntityTypeTriggerEventMap[formState.entityType] || []
        : []
      ).map((label: any) => ({ label, value: label })),
    [formState, automation]
  );

  const projectCustomFields = useProjectCustomFields();

  const conditionFieldOptions = useMemo(() => {
    if (!formState.entityType) return [];

    const options = FIELD_OPTIONS.filter(
      (option: any) => !!option.entityColumns[formState?.entityType || '']
    )
      .filter((x) => {
        if (!formState.entityType) return false;

        const dataAccessType = x.entityColumns[formState.entityType];
        // filter out 'Newly-added tag' condition option if trigger event is not TAGGED
        if (dataAccessType === AutomationDataAccessType.TRIGGER_TAGTEXT_ARRAY) {
          return formState.triggerEvent === AutomationTriggerEvents.TAGGED;
        }

        if (
          [
            AutomationDataAccessType.CUSTOM_EMAILADDRESS,
            AutomationDataAccessType.CUSTOM_TELEPHONENUMBER,
          ].includes(dataAccessType as AutomationDataAccessType)
        ) {
          return false;
        }

        // filter out 'New Pipeline stage' and 'Old Pipeline stage' condition options if trigger event is not related to pipeline stage changing
        if (
          dataAccessType ===
            AutomationDataAccessType.TRIGGER_PIPELINESTAGE_NEW ||
          dataAccessType === AutomationDataAccessType.TRIGGER_PIPELINESTAGE_OLD
        ) {
          return [
            AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED,
            AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_YESTERDAY,
            AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_2_DAYS_AGO,
            AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_3_DAYS_AGO,
            AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_4_DAYS_AGO,
            AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_5_DAYS_AGO,
            AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_6_DAYS_AGO,
            AutomationTriggerEvents.CALCULATED_PIPELINE_STAGE_CHANGED_7_DAYS_AGO,
          ].includes(formState.triggerEvent as AutomationTriggerEvents);
        }

        return true;
      })
      .reduce((acc: { label: string; value: string }[], option) => {
        if (!formState.entityType) return acc;

        if (
          option.entityColumns[formState.entityType] ===
          AutomationDataAccessType.DEAL_CUSTOMFIELD_VALUE
        ) {
          projectCustomFields
            .filter((x) => ['text', 'select', 'email'].includes(x.type))
            .forEach((field) => {
              acc.push({
                label: `${option.label}: ${field.identifier}`,
                value: `${AutomationDataAccessType.DEAL_CUSTOMFIELD_VALUE}:${field.identifier}`,
              });
            });
        } else {
          acc.push({
            label: option.label,
            value: option.entityColumns[formState.entityType] || '',
          });
        }

        return acc;
      }, []);

    return options;
  }, [formState]);

  const onSaveAutomation = useCallback(
    (formStateToSave: any) => {
      setHasChangesMade(false);
      dispatch(
        automationActions.requestUpdateAutomation(
          Number(automationId),
          formStateToSave,
          () => {
            history.push('/automations?tab=custom');
          }
        )
      );
    },
    [dispatch, history, automationId, formState]
  );

  const onFormStateChange = useCallback(
    (newFormState: { [key: string]: any }) => {
      setHasChangesMade(true);
      setFormState(newFormState);
    },
    []
  );

  const canEditTypeOrTriggerEvent = useMemo(
    () => !formState?.conditions?.length && !formState?.actions?.length,
    [formState]
  );

  const canAddConditions = useMemo(
    () => formState?.entityType && formState?.triggerEvent,
    [formState]
  );

  const canAddActions = useMemo(
    () => formState?.entityType && formState?.triggerEvent,
    [formState]
  );

  const onResetConfiguration = useCallback(() => {
    setFormState({
      ...initialFormState,
      title: formState.title,
      isActive: formState.isActive,
    });
    setShowAutomationResetModal(false);
  }, [formState]);
  const onSubmitIncompleteAutomation = useCallback(() => {
    onSaveAutomation({ ...formState, isActive: false });
  }, [formState]);

  const onToggleSection = useCallback(
    (newIndex: number) => {
      // are we closing the section we have open?
      if (openSectionIndex === newIndex) {
        return setOpenSectionIndex(null);
      }
      setOpenSectionIndex(newIndex);
    },
    [openSectionIndex]
  );

  // ensure the new entity we're selecting has a valid corresponding event type
  const onChangeEntityType = useCallback(
    (
      onFieldChange: any,
      changes: Partial<Automation<AutomationActionTypes>>
    ) => {
      const newTriggerEventOptions = changes?.entityType
        ? automationEntityTypeTriggerEventMap[changes.entityType] || []
        : [];
      if (
        formState?.triggerEvent &&
        !newTriggerEventOptions.includes(formState.triggerEvent)
      ) {
        return onFieldChange({
          ...changes,
          triggerEvent: newTriggerEventOptions[0],
        });
      }
      onFieldChange(changes);
    },
    [formState, triggerEventOptions]
  );

  const fieldValidators = useMemo(() => {
    return getFieldValidatorsForCustomAutomation({
      conditionsCount: formState?.conditions?.length || 0,
      actionsCount: formState?.actions?.length || 0,
    });
  }, [formState]);

  const canBeSaved = useMemo(() => {
    if (
      formState?.title &&
      (formState?.title.length === 0 || formState?.title?.length > 80)
    ) {
      return false;
    }
    if (
      formState?.actions?.length &&
      formState?.actions?.find(
        (action: AutomationAction<AutomationActionTypes>) =>
          action?.title?.length > 40
      )
    ) {
      return false;
    }
    return true;
  }, [formState]);

  const canUpdateTriggersAndConditions = useMemo(
    () =>
      userHasRequiredPermission(userRoles, [
        AutomationsPermissions.EDIT_AUTOMATION,
      ]),
    [userRoles]
  );

  const actionFieldOptions = useCallback(
    (formState: { [key: string]: any }) =>
      (formState?.entityType
        ? // @ts-ignore
          automationEntityActionTypeMap[formState.entityType] || []
        : automationActionTypes
      )?.map((value: AutomationActionTypes) => ({
        label: automationActionTypeReadableNameMap[value] || value,
        value: value,
      })),
    [formState]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <div className={'create-edit-automation-form-wrapper'}>
          <div className={'create-edit-automation-container'}>
            <div className={'create-edit-automation-controls'}>
              <div className={'create-edit-automation-background-line'} />
              <div className={'automation-config'}>
                <PermissionGuard
                  renderIfHasPermissions={[
                    AutomationsPermissions.ADD_AUTOMATION,
                    AutomationsPermissions.ADD_SIMPLE_AUTOMATION,
                  ]}
                >
                  <>
                    <AutomationConfigBlock
                      type={AutomationConfigBlockType.TRIGGER}
                      isExpanded={openSectionIndex === 0}
                      onToggleExpanded={() => onToggleSection(0)}
                    >
                      <div
                        className={'trigger-controls-wrapper'}
                        onClick={() =>
                          canUpdateTriggersAndConditions
                            ? !canEditTypeOrTriggerEvent
                              ? setShowAutomationResetModal(true)
                              : null
                            : null
                        }
                      >
                        <DropdownField
                          name={'entityType'}
                          label={'Automation type'}
                          styleVariant={InputStyleVariant.STANDARD}
                          colourVariant={InputColourVariant.SECONDARY}
                          value={formState?.entityType}
                          onChange={(changes: any) =>
                            onChangeEntityType(onFieldChange, changes)
                          }
                          onTouch={onFieldTouch}
                          options={allowableEntityTypeOptions}
                          isDisabled={
                            !canEditTypeOrTriggerEvent ||
                            !canUpdateTriggersAndConditions
                          }
                        />
                      </div>
                      <DropdownField
                        name={'triggerEvent'}
                        label={'What will be the trigger for your action(s)?'}
                        styleVariant={InputStyleVariant.STANDARD}
                        colourVariant={InputColourVariant.SECONDARY}
                        value={formState?.triggerEvent}
                        onChange={onFieldChange}
                        onTouch={onFieldTouch}
                        options={triggerEventOptions}
                        isDisabled={!canUpdateTriggersAndConditions}
                      />
                    </AutomationConfigBlock>
                    <AutomationConfigBlock
                      type={AutomationConfigBlockType.CONDITIONS}
                      isExpanded={openSectionIndex === 1}
                      onToggleExpanded={() => onToggleSection(1)}
                      title={
                        formState?.conditions?.length
                          ? `${formState.conditions.length} Condition(s)`
                          : 'Conditions'
                      }
                    >
                      {!canAddConditions && (
                        <div className={'condition-row'}>
                          Please configure your Trigger before adding any
                          Conditions.
                        </div>
                      )}
                      {canAddConditions && !formState?.conditions?.length && (
                        <div className={'conditions-empty-container'}>
                          <FontAwesomeIcon
                            className="alert-icon"
                            icon={faExclamationTriangle}
                          />
                          <p>
                            <strong>{`You haven't added any Conditions - you can add them using the button below.`}</strong>
                          </p>
                          <p>{`If you don't add any Conditions, your Automation will run every time your Trigger is activated.`}</p>
                        </div>
                      )}
                      {formState?.conditions?.map(
                        (automation: any, i: number) => (
                          <div key={i} className={'condition-row'}>
                            <ValidatedFieldWrapper
                              validationResult={
                                validationState[`conditions[${i}].field`]
                              }
                            >
                              <DropdownField
                                name={`conditions[${i}].field`}
                                styleVariant={InputStyleVariant.STANDARD}
                                colourVariant={InputColourVariant.SECONDARY}
                                value={formState.conditions[i].field}
                                onChange={onFieldChange}
                                options={conditionFieldOptions}
                                isDisabled={!canUpdateTriggersAndConditions}
                              />
                            </ValidatedFieldWrapper>

                            <ValidatedFieldWrapper
                              validationResult={
                                validationState[`conditions[${i}].operator`]
                              }
                            >
                              <DropdownField
                                name={`conditions[${i}].operator`}
                                styleVariant={InputStyleVariant.STANDARD}
                                colourVariant={InputColourVariant.SECONDARY}
                                value={formState.conditions[i].operator}
                                onChange={onFieldChange}
                                options={CONDITION_OPERATOR_OPTIONS.filter(
                                  (option: any) =>
                                    !option.allowableFieldValues ||
                                    option.allowableFieldValues.includes(
                                      formState.conditions[i].field
                                    )
                                )}
                                isDisabled={
                                  !formState.conditions[i].field ||
                                  !canUpdateTriggersAndConditions
                                }
                              />
                            </ValidatedFieldWrapper>
                            <ValidatedFieldWrapper
                              validationResult={
                                validationState[`conditions[${i}].value`]
                              }
                            >
                              <AutomationConditionValueField
                                value={formState.conditions[i].value}
                                field={formState.conditions[i].field}
                                onConditionValueChange={(value) =>
                                  onFieldChange({
                                    [`conditions[${i}].value`]: value,
                                  })
                                }
                                automationTriggerEvent={formState.triggerEvent}
                                isDisabled={!canUpdateTriggersAndConditions}
                              />
                            </ValidatedFieldWrapper>
                            {canUpdateTriggersAndConditions && (
                              <div className={'condition-delete'}>
                                <Button
                                  colourVariant={ButtonColourVariant.WHITE}
                                  onClick={() =>
                                    onFieldChange({
                                      conditions: [
                                        ...formState.conditions.filter(
                                          (c: any, conditionIndex: number) =>
                                            i !== conditionIndex
                                        ),
                                      ],
                                    })
                                  }
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                              </div>
                            )}
                          </div>
                        )
                      )}
                      {canUpdateTriggersAndConditions && (
                        <CreateArea
                          isDisabled={!canAddConditions}
                          onClick={() =>
                            onFieldChange({
                              conditions: formState?.conditions
                                ? [
                                    ...formState.conditions,
                                    {
                                      field: '',
                                      operator: '',
                                      value: '',
                                    },
                                  ]
                                : [
                                    {
                                      field: '',
                                      operator: '',
                                      value: '',
                                    },
                                  ],
                            })
                          }
                          className="add-new-condition"
                        >
                          Add condition
                        </CreateArea>
                      )}
                    </AutomationConfigBlock>
                  </>
                </PermissionGuard>
                {formState?.actions?.map(
                  (
                    action: AutomationAction<AutomationActionTypes>,
                    i: number
                  ) => {
                    return (
                      <AutomationConfigBlock
                        key={i}
                        type={AutomationConfigBlockType.ACTION}
                        isExpanded={openSectionIndex === i + 2}
                        onToggleExpanded={() => onToggleSection(i + 2)}
                        title={formState?.actions?.[i]?.title}
                        stopTitleDescriptionPropagation={true}
                        additionalDescription={
                          <PermissionGuard
                            renderIfHasPermissions={[
                              AutomationsPermissions.ADD_AUTOMATION,
                              AutomationsPermissions.ADD_SIMPLE_AUTOMATION,
                            ]}
                          >
                            <ValidatedFieldWrapper
                              validationResult={
                                validationState[`actions[${i}].type`]
                              }
                            >
                              <DropdownField
                                colourVariant={InputColourVariant.WHITE}
                                name={`actions[${i}].type`}
                                value={action.type || undefined}
                                options={actionFieldOptions(formState)}
                                onChange={onFieldChange}
                                onTouch={onFieldTouch}
                              />
                            </ValidatedFieldWrapper>
                          </PermissionGuard>
                        }
                        expandedTitle={
                          <div className="action-config-block-expanded-title">
                            <h2>{AutomationConfigBlockType.ACTION}</h2>
                            <ValidatedFieldWrapper
                              validationResult={
                                validationState[`actions[${i}].title`]
                              }
                            >
                              <BasicField
                                name={`actions[${i}].title`}
                                value={formState?.actions?.[i]?.title}
                                onChange={onFieldChange}
                                styleVariant={InputStyleVariant.UNSTYLED}
                              />
                            </ValidatedFieldWrapper>
                          </div>
                        }
                      >
                        {/* TODO validation */}
                        {/* TODO deal progression */}
                        <AutomationActionConfigFieldset
                          type={formState?.actions?.[i]?.type}
                          config={formState?.actions?.[i]?.config}
                          automationEntityType={formState.entityType}
                          validationState={Object.entries(
                            validationState
                          ).reduce(
                            (
                              acc: Record<string, FieldValidationResult>,
                              [key, value]
                            ) => {
                              if (key.startsWith(`actions[${i}].config.`)) {
                                acc[key.replace(`actions[${i}].config.`, '')] =
                                  value;
                              }
                              return acc;
                            },
                            {}
                          )}
                          onChange={(changeValue) => {
                            const mappedChangeValue: { [key: string]: any } =
                              {};
                            for (const [key, value] of Object.entries(
                              changeValue
                            )) {
                              mappedChangeValue[`actions[${i}].config.${key}`] =
                                value;
                            }
                            onFieldChange(mappedChangeValue);
                          }}
                          isDefaultAutomation={false}
                          uploadIds={formState?.actions?.[i]?.uploadIds}
                          onUploadCollectionChange={(uploadIds) => {
                            onFieldChange({
                              [`actions[${i}].uploadIds`]: uploadIds,
                            });
                          }}
                          onTouch={(fieldName) => {
                            onFieldTouch(`actions[${i}].config.${fieldName}`);
                          }}
                          touchedState={
                            formState.isActive
                              ? Object.entries(touchedState).reduce(
                                  (
                                    acc: Record<string, boolean>,
                                    [key, value]
                                  ) => {
                                    if (
                                      key.startsWith(`actions[${i}].config.`)
                                    ) {
                                      acc[
                                        key.replace(`actions[${i}].config.`, '')
                                      ] = value;
                                    }
                                    return acc;
                                  },
                                  {}
                                )
                              : {}
                          }
                          showFieldValidationMessagesUnconditionally={true}
                        />
                        <PermissionGuard
                          renderIfHasPermissions={[
                            AutomationsPermissions.ADD_AUTOMATION,
                            AutomationsPermissions.ADD_SIMPLE_AUTOMATION,
                          ]}
                        >
                          <Button
                            className="delete-action"
                            colourVariant={ButtonColourVariant.SECONDARY}
                            styleVariant={ButtonStyleVariant.STANDARD}
                            isOutlined={true}
                            onClick={() => {
                              setOpenSectionIndex(null);
                              onFieldChange({
                                actions: formState?.actions
                                  ? [
                                      ...formState.actions.filter(
                                        (c: any, actionIndex: number) =>
                                          i !== actionIndex
                                      ),
                                    ]
                                  : [],
                              });
                            }}
                          >
                            Delete action
                          </Button>
                        </PermissionGuard>
                      </AutomationConfigBlock>
                    );
                  }
                )}
                <PermissionGuard
                  renderIfHasPermissions={[
                    AutomationsPermissions.ADD_SIMPLE_AUTOMATION,
                    AutomationsPermissions.ADD_AUTOMATION,
                  ]}
                >
                  <div>
                    <Button
                      className={'add-new-action'}
                      styleVariant={ButtonStyleVariant.OUTSIZE}
                      onClick={() => {
                        setOpenSectionIndex(formState?.actions?.length + 2);
                        onFieldChange({
                          actions: formState?.actions
                            ? [
                                ...formState.actions,
                                {
                                  title: 'Untitled Action',
                                  type: AutomationActionTypes.EMAIL_NOTIFICATION,
                                  config: {},
                                },
                              ]
                            : [
                                {
                                  title: 'Untitled Action',
                                  type: AutomationActionTypes.EMAIL_NOTIFICATION,
                                  config: {},
                                },
                              ],
                        });
                      }}
                      isDisabled={!canAddActions}
                    >
                      <img src={ActionIcon} />
                      Add action
                    </Button>
                  </div>
                </PermissionGuard>
              </div>
            </div>
          </div>
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            className="automation-save-button"
            type="button"
            onClick={() => {
              if (isValid && formState.isActive) {
                onSaveAutomation(formState);
              } else if (!isValid && !formState.isActive) {
                onSaveAutomation(formState);
              } else if (isValid && !formState.isActive) {
                setShowAutomationActivationModal(true);
              } else {
                setShowInvalidSubmissionWarningModal(true);
              }
            }}
            isDisabled={!canBeSaved || isPerformingAutomationAction}
            isProcessing={isPerformingAutomationAction}
          >
            Save
          </Button>
        </div>
      );
    },
    [
      account,
      actionFieldOptions,
      automation,
      accountSubscription,
      conditionFieldOptions,
      triggerEventOptions,
      openSectionIndex,
      onResetConfiguration,
      onToggleSection,
      canAddActions,
      canAddConditions,
      fieldValidators,
      isPerformingAutomationAction,
      showAutomationResetModal,
      showInvalidSubmissionWarningModal,
      canBeSaved,
      onSaveAutomation,
      canUpdateTriggersAndConditions,
    ]
  );

  const translate = useTranslation();

  return (
    <>
      <Helmet
        title={translate('pages.automation.title', {
          name: automation?.title || '',
        })}
      />
      <AuthenticatedPageWrapper
        className="create-edit-automation-page"
        previousPageNavigationConfig={{
          navigationPromptName: 'Back',
          route: `/automations?tab=custom`,
        }}
        title={
          <CreateEditAutomationHeader
            formState={formState}
            onUpdateAutomation={(changes: any) =>
              setFormState({ ...formState, ...changes })
            }
          />
        }
      >
        {!automation ? (
          <div className={'automation-loader'}>
            <MiniLoader />
          </div>
        ) : (
          <>
            <ValidatedForm<Partial<Automation<AutomationActionTypes>>>
              initialFormState={formState}
              fieldValidators={fieldValidators}
              renderFormContents={renderFormContents}
              onFormStateChange={onFormStateChange}
            />
            <Modal
              isOpen={showAutomationResetModal}
              onClose={() => setShowAutomationResetModal(false)}
              title={'This change will impact your current automation'}
              actions={
                <Button
                  styleVariant={ButtonStyleVariant.OUTSIZE}
                  onClick={onResetConfiguration}
                >
                  Start again
                </Button>
              }
            >
              <p>{`You have Conditions/Actions based on the options you've selected in the Trigger section.`}</p>
              <p>{`We can remove these if you wish to start again (or perhaps create a new Automation).`}</p>
            </Modal>
            <Modal
              isOpen={showInvalidSubmissionWarningModal}
              onClose={() => setShowInvalidSubmissionWarningModal(false)}
              title={`We'll switch your automation "Off"`}
              actions={
                <Button
                  styleVariant={ButtonStyleVariant.OUTSIZE}
                  onClick={onSubmitIncompleteAutomation}
                  isDisabled={isPerformingAutomationAction}
                  isProcessing={isPerformingAutomationAction}
                >
                  OK, no problem!
                </Button>
              }
            >
              <p>{`Your automation is switched "On" but your current configuration is not valid.`}</p>
              <p>{`Feel free to come back and finish off your setup later, then switch it back "On".`}</p>
            </Modal>
            <Prompt
              // only show prompt when there are changes made and hasnt finished updating or archiving
              when={hasChangesMade}
              message="There are unsaved changes on the page, are you sure you want to leave?"
            />
            <Modal
              isOpen={showAutomationActivationModal}
              onClose={() => setShowAutomationActivationModal(false)}
              actions={
                <>
                  <Button
                    styleVariant={ButtonStyleVariant.OUTSIZE}
                    onClick={() => {
                      setShowAutomationActivationModal(false);
                      onSaveAutomation({ ...formState, isActive: true });
                    }}
                  >
                    Switch on
                  </Button>
                  <Button
                    styleVariant={ButtonStyleVariant.ANCHOR}
                    onClick={() => {
                      setShowAutomationActivationModal(false);
                      onSaveAutomation(formState);
                    }}
                  >
                    Continue
                  </Button>
                </>
              }
            >
              <>
                <p style={{ textAlign: 'center' }}>
                  This automation is currently switched <strong>OFF</strong> and
                  will not run.
                </p>
              </>
            </Modal>
          </>
        )}
      </AuthenticatedPageWrapper>
    </>
  );
};

export default CreateEditAutomationPage;
