import React, { FC, useMemo } from 'react';
import moment from 'moment-timezone';

import CompanyLogo from '../companyLogo/CompanyLogo';
import OutlinedContentPanel, {
  ContentPanelPaddingVariant,
  ContentPanelTitleStyleVariant,
} from '../outlinedContentPanel/OutlinedContentPanel';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import { useCommonFormAccountData } from './hooks';

import { isNotNullish } from '@payaca/utilities/guards';

import './FormTemplateElements.sass';
import './DomesticElectricalInstallationCertificate18-2.sass';
import { SignatureElement } from './SignatureElement';

import NiceicLogo from './niceic-logo.png';

interface Props {
  data: any;
  account: any;
}

const createAddressString = (addressComponents: any) =>
  [
    addressComponents.line_1,
    addressComponents.line_2,
    addressComponents.line_3,
    addressComponents.town,
    addressComponents.county,
    addressComponents.postcode,
  ]
    .filter(Boolean)
    .join(', ');
const defaultToNa = (value: string) =>
  isNotNullish(value) && value !== '' ? value : 'N/A';

const DomesticElectricalInstallationCertificate: FC<Props> = ({
  data,
  account,
}) => {
  const { brandColour } = useCommonFormAccountData(account);

  const certificateNumber = useMemo(() => {
    if (data) {
      return `${data.serial_no}${data.revision ? `-${data.revision}` : ''}`;
    }
    return '';
  }, [data]);

  if (!data || !account) return <></>;

  const contractorAddressString = createAddressString(data.contractor_address);
  const clientAddressString = createAddressString(data.client_address);
  const installationAddressString = createAddressString(
    data.installation_address
  );

  console.log(data);

  return (
    <div
      className={
        'form-template-output domestic-electrical-installation-certificate-template version-18-2'
      }
    >
      <div className={'form-template-header'}>
        <div>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3>
              Domestic Electrical Installation Certificate (Single phase, up to
              100A)
            </h3>
            <p>
              Issued in accordance with BS 7671, as amended - Requirements for
              Electrical Installations
            </p>
          </div>
        </div>
        <div className={'flex-container flex-center'}>
          <div className="header-details-container">
            <LabelValuePair label="Cert No" value={certificateNumber} />
          </div>
        </div>
        {data.niceic_reg_number && (
          <img className="niceic-logo" src={NiceicLogo} alt={'NICEIC Logo'} />
        )}
      </div>
      <div className="form-template-row company-inspection-landlord-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          className="company-details-content-panel"
          title={'PART 1a: Details of approved contractor'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="company-details-content-panel-body">
            <LabelValuePair
              label="Registration number"
              value={data.contractor_reg_no}
            />
            <LabelValuePair
              label="Trading Title"
              value={data.contractor_trading_title}
            />
            {data.niceic_reg_number && (
              <LabelValuePair
                label="NICEIC Reg. Number"
                value={data.niceic_reg_number}
              />
            )}
            <LabelValuePair label="Address" value={contractorAddressString} />
            <LabelValuePair label="Tel No." value={data?.contractor_tel_no} />
          </div>
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 1b: Details of the client'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="Contractor Reference Number (CRN)"
            value={data.client_contractor_ref_no}
          />
          <LabelValuePair label="Name" value={data.client_name} />
          <LabelValuePair label="Address" value={clientAddressString} />
          <LabelValuePair label="Tel No." value={data.client_tel_no} />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 1c: Details of the installation'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Occupier" value={`${data.occupier_name}`} />
          <LabelValuePair
            label="Unique Property Reference Number (UPRN)"
            value={`${data.uprn}`}
          />
          <LabelValuePair label="Address" value={installationAddressString} />
          <LabelValuePair label="Tel No." value={data.occupier_tel_no} />
        </OutlinedContentPanel>
      </div>

      <div className="form-template-row no-page-break parts-2-and-3">
        <OutlinedContentPanel
          colourOverride={brandColour}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
          title="PART 2 : Details of the electrical work covered by this installation certificate"
        >
          <LabelValuePair
            label="Date completed"
            value={moment(data.date_completed).format('DD/MM/YYYY')}
          />
          <LabelValuePair
            label="The installation is"
            value={Object.keys(data.work_covered_installation_properties)
              .filter((key) => data.work_covered_installation_properties[key])
              .map((key) => {
                switch (key) {
                  case 'new':
                    return 'new';
                  case 'addition':
                    return 'an addition';
                  case 'alteration':
                    return 'an alteration';
                  case 'cu_replacement':
                    return 'replacement of a distribution board';
                }
              })
              .join(' | ')}
          />
          <LabelValuePair
            label="Description and extent of the installation covered by this certificate"
            value={data.work_covered_description}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
          title="PART 3: comments on the existing installation (in the case of an addition or alteration see Regulation 644.1.2)"
        >
          <LabelValuePair
            label="Comments on the existing installation"
            value={data.existing_installation_comments}
          />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row">
        <OutlinedContentPanel
          className="signatures"
          colourOverride={brandColour}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
          title={'PART 4: Declaration for the electrical installation work'}
        >
          <section className="columns-3">
            <div className="label-values-in-row-title">
              Design, construction, inspection & testing
            </div>
            <p>
              I, being the person responsible for the design, construction,
              inspection and testing of the electrical installation, particulars
              of which are described in PART 2, having exercised reasonable
              skill and care when carrying out the design, hereby CERTIFY that
              the design, construction, inspection and testing for which I have
              been responsible is to the best of my knowledge and belief in
              accordance with BS 7671: 2018+A2:2022 except for the departures,
              if any (Regulations 120.3, 133.1.3 and 133.5), detailed as
              follows:
            </p>
            <LabelValuePair
              label="Departures"
              value={data.declaration_departures}
            />
            <LabelValuePair
              label="Permitted exception applied (411.3.3)"
              value={data.permitted_exception_applied}
            />
            <LabelValuePair
              label="Risk assessment attached"
              value={data.risk_assessment_attached}
            />
            <p>
              I, being the designer of the electrical installation, also
              RECOMMEND that this installation is further inspected and tested
              by
            </p>
            <LabelValuePair
              label="Retest date"
              value={moment(data.retest_date).format('DD/MM/YYYY')}
            />
            <SignatureElement
              signatureBase64={data.declaration_engineer_signature}
              name={data.declaration_engineer_name}
              date={data.declaration_engineer_date}
            />
            <SignatureElement
              label={'Reviewed by qualified supervisor'}
              signatureBase64={data.declaration_reviewer_signature}
              name={data.declaration_reviewer_name}
              date={data.declaration_reviewer_date}
            />
          </section>
        </OutlinedContentPanel>
      </div>

      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 5: Supply characteristics and earthing arrangements"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="columns-3">
            <LabelValuePair
              label="System type and earthing arrangements"
              value={data.supply_characteristics_system_type}
            />
            <LabelValuePair
              label="Other (state)"
              value={data.supply_characteristics_system_type_other}
            />
            <section className="no-column-break">
              <h2>Supply protective device</h2>
              <LabelValuePair
                label="BS (EN)"
                value={
                  data.supply_characteristics_supplier_protective_device_bsen
                }
              />
              <LabelValuePair
                label="Type"
                value={
                  data.supply_characteristics_supplier_protective_device_type
                }
              />
              <LabelValuePair
                label="Rated current"
                value={`${data.supply_characteristics_supplier_protective_device_rated_current} A`}
              />
            </section>

            <LabelValuePair
              label="Number and type of live conductors (AC)"
              value={data.supply_characteristics_number_type_live_conductors}
            />

            <LabelValuePair
              label="Other (state)"
              value={
                data.supply_characteristics_number_type_live_conductors_other
              }
            />
            <LabelValuePair
              label="Confirmation of supply polarity"
              value={data.supply_characteristics_supply_polarity_confirmation}
            />
            {!!data.supply_characteristics_other_sources_of_supply?.length && (
              <section className="no-column-break">
                <h2>Other sources of supply</h2>

                {data.supply_characteristics_other_sources_of_supply?.map(
                  (x: any, i: number) => (
                    <LabelValuePair
                      key={`supply-characteristics-other-sources-of-supply-${i}`}
                      label={`${i + 1}`}
                      value={x.description}
                    />
                  )
                )}
              </section>
            )}
            <section className="no-column-break">
              <h2>Nature of supply parameters</h2>

              <LabelValuePair
                label="Nominal line voltage to Earth, U0 (V)"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .nominal_line_voltage_to_earth
                }
              />
              <LabelValuePair
                label="Nominal frequency, f (Hz)"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .nominal_frequency
                }
              />
              <LabelValuePair
                label="Prospective fault current, Ipf (kA)"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .prospective_fault_current
                }
              />
              <LabelValuePair
                label="External loop impedance, Ze (Ohms)"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .external_loop_impedance
                }
              />
              <LabelValuePair
                label="Tick if Ze is by Enquiry"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .by_enquiry
                    ? '✓'
                    : '✗'
                }
              />
            </section>
          </div>
        </OutlinedContentPanel>
      </div>

      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 6: Particulars of installation referred to in this report"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="columns-4">
            <section className="no-column-break">
              <LabelValuePair
                label="Maximum demand (load)"
                value={`${data['particulars__maximum-demand']} ${data['particulars__maximum-demand__unit']}`}
              />

              <h2>Means of Earthing</h2>

              <LabelValuePair
                label="Means of Earthing"
                value={data.particulars_means_of_earthing}
              />
              <LabelValuePair
                label="Type"
                value={data.particulars_earth_electrode.type}
              />
              <LabelValuePair
                label="Location"
                value={data.particulars_earth_electrode.location}
              />
              <LabelValuePair
                label="Electrode resistance to Earth"
                value={data.particulars_earth_electrode.resistance}
              />
            </section>
            <section className="no-column-break">
              <h2>Main protective conductors</h2>
              <LabelValuePair
                label="Material"
                value={
                  data.particulars_main_protective_conductors_earthing_conductor
                    .material
                }
              />
              <LabelValuePair
                label="CSA"
                value={
                  data.particulars_main_protective_conductors_earthing_conductor
                    .csa
                }
              />
              <LabelValuePair
                label="Connection / continuity verified"
                value={
                  data.particulars_main_protective_conductors_earthing_conductor
                    .connection_verified
                }
              />
            </section>
            <section className="no-column-break">
              <h2>Main protective bonding conductors</h2>

              <LabelValuePair
                label="Material"
                value={
                  data
                    .particulars_main_protective_conductors_main_protective_bonding_conductors
                    .material
                }
              />
              <LabelValuePair
                label="CSA"
                value={
                  data
                    .particulars_main_protective_conductors_main_protective_bonding_conductors
                    .csa
                }
              />
              <LabelValuePair
                label="Connection / continuity verified"
                value={
                  data
                    .particulars_main_protective_conductors_main_protective_bonding_conductors
                    .connection_verified
                }
              />
            </section>
            <section className="no-column-break">
              <h2>Main protective bonding connections</h2>
              <LabelValuePair
                label="Water installation pipes"
                value={
                  data.particulars_main_protective_bonding_connections.water
                }
              />
              <LabelValuePair
                label="Gas installation pipes"
                value={data.particulars_main_protective_bonding_connections.gas}
              />
              <LabelValuePair
                label="Structural steel"
                value={
                  data.particulars_main_protective_bonding_connections.steel
                }
              />
              <LabelValuePair
                label="Oil installation pipes"
                value={data.particulars_main_protective_bonding_connections.oil}
              />
              <LabelValuePair
                label="Lightning protection"
                value={
                  data.particulars_main_protective_bonding_connections.lightning
                }
              />
              <LabelValuePair
                label="Other"
                value={
                  data.particulars_main_protective_bonding_connections.other
                }
              />
            </section>
            <section className="no-column-break">
              <h2>Main switch / Switch-fuse / Circuit-breaker / RCD</h2>

              <LabelValuePair
                label="Type (BS (EN))"
                value={data.particulars_main_switch_type}
              />
              <LabelValuePair
                label="Location"
                value={data.particulars_main_switch_location}
              />
              <LabelValuePair
                label="No. of poles"
                value={data.particulars_main_switch_num_poles}
              />
              <LabelValuePair
                label="Current rating"
                value={data.particulars_main_switch_current_rating}
              />
              <LabelValuePair
                label="Rating / setting of device"
                value={data.particulars_main_switch_rating_setting}
              />
              <LabelValuePair
                label="Voltage rating"
                value={data.particulars_main_switch_voltage_rating}
              />
              <LabelValuePair
                label="RCD rated residual operating current, IΔn"
                value={
                  data.particulars_main_switch_rcd.residual_operating_current
                }
              />
              <LabelValuePair
                label="Measured operating time"
                value={data.particulars_main_switch_rcd.measured_operating_time}
              />
              <LabelValuePair
                label="Rated time delay"
                value={data.particulars_main_switch_rcd.rated_time_delay}
              />
            </section>
          </div>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row schedule no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 7: Schedule of items inspected"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="columns-3">
            <LabelValuePair
              label="1.  Condition of consumer’s intake equipment (visual inspection only)"
              value={data['schedule__intake-equipment']}
            />

            <LabelValuePair
              label="2.  Parallel or switched alternative sources of supply"
              value={data['schedule__supply-sources']}
            />
            <LabelValuePair
              label="3.  Methods of protection"
              value={data['schedule__methods-of-protection']}
            />
            <LabelValuePair
              label="4.  Basic protection"
              value={data['schedule__basic-protection']}
            />
            <LabelValuePair
              label="5.  Protective measures other than ADS"
              value={data['schedule__protective-measures']}
            />

            <LabelValuePair
              label="6.  Additional protection"
              value={data['schedule__additional-protection']}
            />
            <LabelValuePair
              label="7.  Distribution equipment"
              value={data['schedule__distribution-equipment']}
            />
            <LabelValuePair
              label="8.  Circuits (distribution and final)"
              value={data['schedule__circuits']}
            />
            <LabelValuePair
              label="9.  Isolation and switching"
              value={data['schedule__isolation-and-switching']}
            />
            <LabelValuePair
              label="10.  Current-using equipment (permanently connected)"
              value={data['schedule__current-using-equipment']}
            />
            <LabelValuePair
              label="11.  Identification and notices"
              value={data['schedule__identification-and-notices']}
            />
            <LabelValuePair
              label="12.  Location(s) containing a bath or shower"
              value={data['schedule__bath-shower-locations']}
            />
            <LabelValuePair
              label="13. Other special installations or locations"
              value={`${data?.['schedule__special-installations']?.['schedule__special-installations__location']} ${data?.['schedule__special-installations']?.['schedule__special-installations__outcome']}`}
            />
            <LabelValuePair
              label="14. Prosumer’s low voltage installation(s)"
              value={`${data?.['schedule__low-voltage-installations']?.['schedule__low-voltage-installations__location']} ${data?.['schedule__low-voltage-installations']?.['schedule__low-voltage-installations__outcome']}`}
            />

            <SignatureElement
              label="Schedule of items inspected by"
              name={
                data?.['schedule__inspected-by']?.[
                  'schedule__inspected-by__name'
                ]
              }
              signatureBase64={
                data?.['schedule__inspected-by']?.[
                  'schedule__inspected-by__signature'
                ]
              }
              date={
                data?.['schedule__inspected-by']?.[
                  `schedule__inspected-by__date`
                ]
              }
            />
          </div>
        </OutlinedContentPanel>
      </div>

      {data.distributionBoards && (
        <div className="form-template-row circuit-details no-page-break">
          <OutlinedContentPanel
            colourOverride={brandColour}
            title="PART 9: Schedule of circuit details and test results"
            titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
            paddingVariant={ContentPanelPaddingVariant.SMALL}
          >
            {data.distributionBoards?.map((db: any, i: number) => (
              <section
                className="distribution-board"
                key={`distribution-boards-${i}`}
              >
                <h5>Distribution board {db.designation}</h5>
                <div className="form-template-row circuit-details no-page-break">
                  <LabelValuePair
                    label="Location of consumer unit"
                    value={db.location}
                  />
                  <LabelValuePair label="Designation" value={db.designation} />
                  <LabelValuePair
                    label="Prospective fault current at consumer unit"
                    value={db.prospectiveFaultCurrent}
                  />
                  <LabelValuePair label="Zdb (Ω)" value={`${db.zDb}`} />
                </div>

                {db.circuits && (
                  <table className="electrical-circuits">
                    <thead>
                      <tr>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Circuit number</span>
                        </th>
                        <th rowSpan={3}>Circuit description</th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Type of wiring (see Codes)</span>
                        </th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Reference Method (BS 7671)</span>
                        </th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Number of points served</span>
                        </th>
                        <th colSpan={2}>Circuit conductor csa</th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Max. disconnection time (BS 7671)</span>
                        </th>
                        <th colSpan={5}>Protective device</th>
                        <th colSpan={4}>RCD</th>
                        <th colSpan={5}>Continuity (Ohms)</th>
                        <th colSpan={3}>Insulation resistance</th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Polarity</span>
                        </th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>
                            Max. measured earth fault loop impedance, Zs
                          </span>
                        </th>
                        <th colSpan={2}>RCD</th>
                        <th rowSpan={3}>AFDD test button</th>
                        <th rowSpan={3}>Comments</th>
                      </tr>
                      <tr>
                        <td rowSpan={2}>Live (mm2)</td>
                        <td rowSpan={2}>cpc (mm2)</td>
                        <td rowSpan={2}>BS (EN)</td>
                        <td rowSpan={2}>Type</td>
                        <td rowSpan={2}>Rating (A)</td>
                        <td rowSpan={2}>Short-circuit capacity (kA)</td>
                        <td rowSpan={2}>Maximum permitted Zs</td>

                        <td rowSpan={2}>BS (EN)</td>
                        <td rowSpan={2}>Type</td>
                        <td rowSpan={2}>Rating</td>
                        <td rowSpan={2}>Operating current, IΔn (mA)</td>

                        <td colSpan={3}>
                          Ring final circuits only (measured end to end)
                        </td>
                        <td colSpan={2}>
                          All circuits (complete at least one column)
                        </td>
                        <td rowSpan={2}>Live / Live (MegaOhms)</td>
                        <td rowSpan={2}>Live / Earth (MegaOhms)</td>
                        <td rowSpan={2}>Test voltage DC (V)</td>

                        <td rowSpan={2}>Operating time (ms)</td>
                        <td rowSpan={2}>Test button</td>
                      </tr>
                      <tr>
                        <th>(Line) r1</th>
                        <th>(Neutral) rn</th>
                        <th>(cpc) r2</th>
                        <th>(R1 + R2)</th>
                        <th>R2</th>
                      </tr>
                    </thead>
                    <tbody>
                      {db.circuits?.map((circuit: any, i: number) => (
                        <tr key={`db-circuits-${i}`}>
                          <td>{circuit.number}</td>
                          <td>{circuit.description}</td>
                          <td>
                            {circuit.wiringType ?? circuit.wiringTypeOther}
                          </td>
                          <td>{circuit.referenceMethod}</td>
                          <td>{circuit.numPointsServed}</td>
                          <td>{circuit.liveConductorCsa}</td>
                          <td>{circuit.cpcConductorCsa}</td>
                          <td>{circuit.maxDisconnectionTime}</td>
                          <td>{circuit.overcurrentProtectiveDevice}</td>
                          <td>
                            {defaultToNa(
                              circuit.overcurrentProtectiveDeviceType
                            )}
                          </td>
                          <td>{circuit.currentRating}</td>
                          <td>{circuit.shortCircuitCapacity}</td>
                          <td>{circuit.maxPermittedZs}</td>

                          <td>{circuit.rcdBs}</td>
                          <td>{circuit.rcdType}</td>
                          <td>{circuit.rcdRating}</td>
                          <td>{circuit.rcdOperatingCurrent}</td>

                          <td>{defaultToNa(circuit.lineImpedance)}</td>
                          <td>{defaultToNa(circuit.neutralImpedance)}</td>
                          <td>{defaultToNa(circuit.cpcImpedance)}</td>
                          <td>{defaultToNa(circuit.r1PlusR2Impedance)}</td>
                          <td>{defaultToNa(circuit.r2Impedance)}</td>
                          <td>
                            {defaultToNa(circuit.liveLiveInsulationResistance)}
                          </td>
                          <td>
                            {defaultToNa(circuit.liveEarthInsulationResistance)}
                          </td>
                          <td>{circuit.testVoltage}</td>
                          <td>{circuit.polarity}</td>
                          <td>
                            {defaultToNa(
                              circuit.maxMeasuredEarthFaultLoopImpedance
                            )}
                          </td>
                          <td>{defaultToNa(circuit.rcdOperatingTime)}</td>
                          <td>{circuit.rcdTestButtonOperation}</td>
                          <td>{circuit.afddTestButtonOperation}</td>
                          <td>{circuit.comments}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <div className="columns-3">
                  <div>
                    <LabelValuePair
                      label="Confirmation of supply polarity"
                      value={`${db.confirmationOfSupplyPolarity}`}
                    />
                    <LabelValuePair
                      label="Phase sequence confirmed"
                      value={`${db.phaseSequenceConfirmed}`}
                    />
                  </div>
                  <section className="no-column-break">
                    <h5>SPD Details</h5>
                    <div>
                      <LabelValuePair label="T1" value={db.spdDetailsT1} />
                      <LabelValuePair label="T2" value={db.spdDetailsT2} />
                      <LabelValuePair label="T3" value={db.spdDetailsT3} />
                      <LabelValuePair label="T4" value={db.spdDetailsT4} />
                    </div>
                  </section>
                  {db.isNotConnectedToOrigin && (
                    <>
                      {' '}
                      <section className="no-column-break">
                        <h5>Not connected to origin</h5>
                        <LabelValuePair
                          label="Supply to DB is from"
                          value={db.supplyIsFrom}
                        />
                      </section>
                      <section className="no-column-break">
                        <h5>
                          Overcurrent protection device for the distribution
                          circuit
                        </h5>
                        <LabelValuePair
                          label="BS (EN)"
                          value={db.overcurrentProtectionDeviceBs}
                        />
                        <LabelValuePair
                          label="Nominal voltage (V)"
                          value={db.overcurrentProtectionNominalVoltage}
                        />
                        <LabelValuePair
                          label="Rating (A)"
                          value={db.overcurrentProtectionRating}
                        />
                        <LabelValuePair
                          label="No. of Phases"
                          value={db.overcurrentProtectionNoOfPhases}
                        />
                      </section>
                      <section className="no-column-break">
                        <h5>Associated RCD (if any)</h5>
                        <LabelValuePair
                          label="BS (EN)"
                          value={db.associatedRcdBs}
                        />
                        <LabelValuePair
                          label="RCD Type"
                          value={db.associatedRcdType}
                        />
                        <LabelValuePair
                          label="IΔn (mA)"
                          value={db.associatedRcdResidualCurrent}
                        />
                        <LabelValuePair
                          label="No. of poles"
                          value={db.associatedRcdNoOfPoles}
                        />
                        <LabelValuePair
                          label="Operating time (ms)"
                          value={db.associatedRcdOperatingTime}
                        />
                      </section>
                    </>
                  )}

                  <SignatureElement
                    label="Tested by"
                    name={db.testEngineerName}
                    signatureBase64={db.testEngineerSignatureBase64}
                    position={db.testEngineerPosition}
                    date={db.testEngineerDate}
                  />

                  <section className="no-column-break">
                    <h5>Test instruments</h5>
                    <LabelValuePair
                      label="Multi-function"
                      value={
                        db.multiFunctionTestInstrumentSerialNumber || 'N/A'
                      }
                    />
                    <LabelValuePair
                      label="Continuity"
                      value={db.continuityTestInstrumentSerialNumber || 'N/A'}
                    />
                    <LabelValuePair
                      label="Insulation resistance"
                      value={
                        db.insulationResistanceTestInstrumentSerialNumber ||
                        'N/A'
                      }
                    />
                    <LabelValuePair
                      label="Earth fault loop impedance"
                      value={
                        db.earthFaultLoopImpedanceTestInstrumentSerialNumber ||
                        'N/A'
                      }
                    />
                    <LabelValuePair
                      label="Earth electrode resistance"
                      value={
                        db.earthElectrodeResistanceTestInstrumentSerialNumber ||
                        'N/A'
                      }
                    />
                    <LabelValuePair
                      label="RCD"
                      value={db.rcdTestInstrumentSerialNumber || 'N/A'}
                    />
                  </section>
                </div>
              </section>
            )) ?? <p>N/A</p>}
          </OutlinedContentPanel>
        </div>
      )}
    </div>
  );
};

export default DomesticElectricalInstallationCertificate;
