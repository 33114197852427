import React, { FC, useMemo } from 'react';

import Modal from '@payaca/components/modal/Modal';

import './CreateEditSupplierMaterialModal.sass';
import { SupplierMaterial } from '@payaca/types/materialTypes';
import CreateEditSupplierMaterialControl from '../createEditSupplierMaterialControl/CreateEditSupplierMaterialControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLeft } from '@fortawesome/free-solid-svg-icons';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onPersistSupplierMaterialSuccess?: (supplierId: number) => void;
  supplierMaterial?: SupplierMaterial;
  isMaterialSelectionDisabled?: boolean;
  isSupplierSelectionDisabled?: boolean;
};
const CreateEditSupplierMaterialModal: FC<Props> = ({
  isOpen,
  onClose,
  onPersistSupplierMaterialSuccess,
  supplierMaterial,
  isMaterialSelectionDisabled,
  isSupplierSelectionDisabled,
}: Props): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      className="create-edit-supplier-material-modal"
      title={
        <div className="flex-container flex-contiguous flex-center">
          <h1>Supplier</h1>{' '}
          <FontAwesomeIcon icon={faRightLeft} className="two-way-arrow-icon" />{' '}
          <h1>Material</h1>
        </div>
      }
      onClose={onClose}
    >
      <CreateEditSupplierMaterialControl
        supplierMaterial={supplierMaterial}
        onPersistSupplierMaterialSuccess={(supplierMaterialId: number) => {
          onClose();
          onPersistSupplierMaterialSuccess &&
            onPersistSupplierMaterialSuccess(supplierMaterialId);
        }}
        isMaterialSelectionDisabled={isMaterialSelectionDisabled}
        isSupplierSelectionDisabled={isSupplierSelectionDisabled}
      />
    </Modal>
  );
};
export default CreateEditSupplierMaterialModal;
