import StringUtil from './stringUtil';

/**
 * Return key using object value
 * @param {object} object object to check in
 * @param {any} value value in object to search by
 *
 * @return {string} key of value
 */
const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

const removeEmptyAttributes = (obj) => {
  Object.keys(obj).forEach((key) => !obj[key] && delete obj[key]);
  return obj;
};

export { getKeyByValue, removeEmptyAttributes, StringUtil };
