import React, { FC, useContext, useMemo, useState } from 'react';

import DynamicFeedbackMessage from '@payaca/components/dynamicFeedbackMessage/DynamicFeedbackMessage';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import VerifyAccountModal from '../verifyAccountModal/VerifyAccountModal';

import { useWindowDimensions } from '@payaca/hooks/useWindowDimensions';

import './DynamicFeedbackOverlay.sass';
import { useSelector } from '@/api/state';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';

const DynamicFeedbackOverlay: FC = (): JSX.Element => {
  const [showVerifyAccountModal, setShowVerifyAccountModal] = useState(false);

  const { messages, cancelDynamicFeedbackMessage } = useContext(
    DynamicFeedbackContext
  );

  const profile = useSelector((state: any) => state?.users?.myProfile);

  const { width } = useWindowDimensions();

  const feedbackQueue = useMemo(() => {
    const list = [...messages];
    // add verify email to queue if on larger screens
    if (profile?.id && !profile.verifiedAt && width > 500) {
      list.push(
        {
          dynamicFeedbackMessage: {
            body: (
              <div>
                <p>Please verify your email</p>
                <Button
                  onClick={() => setShowVerifyAccountModal(true)}
                  styleVariant={ButtonStyleVariant.ANCHOR}
                >
                  Resend
                </Button>
              </div>
            ),
          },
        } as any /* FIXME */
      );
    }
    return list;
  }, [messages, profile, width]);

  if (!feedbackQueue?.length) {
    return <></>;
  }

  return (
    <div className="dynamic-feedback-overlay">
      <div className="dynamic-feedback-messages">
        {feedbackQueue.map((dynamicFeedbackQueueObject, i) => {
          return (
            <DynamicFeedbackMessage
              key={`dynamic-feedback-message-${i}`}
              dynamicFeedbackMessage={
                dynamicFeedbackQueueObject.dynamicFeedbackMessage
              }
              onCancel={() => {
                cancelDynamicFeedbackMessage(dynamicFeedbackQueueObject.id);
              }}
            />
          );
        })}
      </div>
      <VerifyAccountModal
        isOpen={showVerifyAccountModal}
        onClose={() => setShowVerifyAccountModal(false)}
      />
    </div>
  );
};

export default DynamicFeedbackOverlay;
