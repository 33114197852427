import React, {
  FunctionComponent,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { faPen, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Search } from 'react-iconly';

import CustomerInformation from '../customerInformation/CustomerInformation';
import SelectCustomerModal from '../selectCustomerModal/SelectCustomerModal';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import RadioButton from '@payaca/components/radioButton/RadioButton';
import TypeToSearchField from '@payaca/components/typeToSearchField/TypeToSearchField';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import IconButton from '@payaca/components/button/IconButton';
import EditJobSection from './EditJobSection';

import { getPrimaryContactFromCustomer } from '@payaca/helpers/customerHelper';

import * as customerActions from '@payaca/store/customer/customerActions';
import { Customer, CustomerContact } from '@payaca/types/customerTypes';

import { getCustomer } from '@/utils/stateAccessors';
import {
  GetListedCustomersRequestData,
  ListedCustomer,
} from '@payaca/types/listedCustomerTypes';

import './JobCustomerControl.sass';
import { useSelector } from '@/api/state';

interface Option {
  id: number;
  name: string;
  primaryContactEmailAddress?: string;
}

type Props = {
  canSelectCustomer?: boolean;
  customerId?: number;
  jobContactId?: number | null;
  onPersistCustomerSuccess?: () => void;
  onSelectContact?: (customerContactId: number) => void;
  onSelectCustomer?: (customerId?: number) => void;
};

const JobCustomerControl: FunctionComponent<Props> = ({
  canSelectCustomer = true,
  customerId,
  jobContactId,
  onPersistCustomerSuccess,
  onSelectContact,
  onSelectCustomer,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [showSearchCustomerTableModal, setShowSearchCustomerTableModal] =
    useState(false);

  const [getListedCustomersRequestData, setGetListedCustomersRequestData] =
    useState<GetListedCustomersRequestData>({
      pageSize: 20,
      pageNumber: 1,
      searchTerm: '',
    });

  useEffect(() => {
    requestGetListedCustomersPage();
  }, [getListedCustomersRequestData]);

  const requestGetListedCustomersPage = useCallback(() => {
    dispatch(
      customerActions.requestGetListedCustomersPage(
        getListedCustomersRequestData
      )
    );
  }, [getListedCustomersRequestData, dispatch]);

  const listedCustomersPage = useSelector((state) => {
    return state.customer?.listedCustomersPage;
  });
  const isGettingListedCustomersPage = useSelector(
    (state) => state.customer.isGettingListedCustomersPage
  );

  const customer = useSelector((state) =>
    customerId ? getCustomer(state, customerId) : undefined
  );
  const isGettingCustomer = useSelector(
    (state) => state.customer.isGettingCustomer
  );
  const isSettingDealCustomer = useSelector(
    (state) => state.deals.isSettingDealCustomer
  );

  useEffect(() => {
    if (customerId) {
      dispatch(customerActions.requestGetCustomer(customerId));
      onPersistCustomerSuccess && onPersistCustomerSuccess();
    }
  }, [customerId]);

  const primaryCustomerContactId = useMemo(() => {
    if (!customer) return;
    const primaryContact = getPrimaryContactFromCustomer(customer);
    return primaryContact?.id;
  }, [customer]);

  const selectedContactId = useMemo(() => {
    if (customerId) {
      return jobContactId || primaryCustomerContactId;
    }
  }, [customerId, jobContactId, primaryCustomerContactId]);

  const onSearchTermChange = useCallback((searchTerm: string) => {
    setGetListedCustomersRequestData(
      (getListedCustomersRequestData: GetListedCustomersRequestData) => {
        return {
          ...getListedCustomersRequestData,
          pageNumber: 1,
          searchTerm: searchTerm,
        };
      }
    );
  }, []);

  const renderCustomerContact = useCallback(
    (
      customerContact: CustomerContact,
      index: number,
      originalComponent: JSX.Element
    ) => {
      return (
        <div
          className="customer-contact-select-wrapper"
          key={`customer-contact-${index}`}
        >
          <RadioButton
            onClick={() =>
              onSelectContact && onSelectContact(customerContact.id)
            }
            isSelected={customerContact.id === selectedContactId}
          />
          {originalComponent}
        </div>
      );
    },
    [onSelectContact, selectedContactId]
  );

  const customerContactCard = useMemo(() => {
    return (
      <ContentPanel hasBoxShadow={false} className="customer-contact-card">
        <CustomerInformation
          customer={customer}
          hideAddresses={true}
          renderCustomerContact={renderCustomerContact}
        />
      </ContentPanel>
    );
  }, [customer, onSelectCustomer, renderCustomerContact]);

  const renderOption = (option: Option) => {
    return (
      <div className="listed-job-customer-option flex-container flex-center">
        {option.id === -1 && <IconButton size={'xs'} icon={faPlus} />}
        {option.id === -2 && <Search size={21} />}
        <div className="listed-job-customer-name-email-wrapper">
          <span>{option.name}</span>
          {option.primaryContactEmailAddress && (
            <span className="listed-job-customer-email">
              - {option.primaryContactEmailAddress}
            </span>
          )}
        </div>
      </div>
    );
  };

  const handleOpenAdvancedSearchCustomerModal = () => {
    // TODO: Open customer search modal (with search term?)
    setShowSearchCustomerTableModal(true);
  };

  const addCustomerControl = useMemo(() => {
    let customerResults: Array<{
      id: ListedCustomer['customerId'];
      name: ListedCustomer['customerName'];
      primaryContactEmailAddress?: ListedCustomer['primaryContactEmailAddress'];
    }> = [];
    // only start showing customer results when started typing
    if (getListedCustomersRequestData.searchTerm) {
      customerResults = (listedCustomersPage?.items || []).map(
        (c: ListedCustomer) => ({
          id: c.customerId,
          name: c.customerName,
          primaryContactEmailAddress: c.primaryContactEmailAddress,
        })
      );
    }
    return (
      <TypeToSearchField
        styleVariant={InputStyleVariant.OUTSIZE}
        options={[
          ...customerResults,

          {
            id: -2,
            name: 'Advanced search',
          },
        ]}
        renderOption={renderOption}
        disableFilteringInComponent={true}
        isLoadingOptions={isGettingListedCustomersPage}
        onSelectOption={(option?: Option) => {
          if (option?.id === -2) {
            handleOpenAdvancedSearchCustomerModal();
          } else if (option?.id) {
            // selected existing customer - set onto job
            onSelectCustomer && onSelectCustomer(option.id);
          }
        }}
        placeholder="Type here to search Customers"
        onSearchTermChange={onSearchTermChange}
      />
    );
  }, [
    getListedCustomersRequestData,
    handleOpenAdvancedSearchCustomerModal,
    onSelectCustomer,
    isGettingListedCustomersPage,
    renderOption,
    onSearchTermChange,
    listedCustomersPage,
  ]);

  return (
    <EditJobSection
      title="Customer"
      description={!customer ? <p>Add a Customer</p> : undefined}
      className="job-customer-control"
    >
      {(isSettingDealCustomer || (isGettingCustomer && !customer)) && (
        <div className="loader-container">
          <MiniLoader />
        </div>
      )}

      {!isSettingDealCustomer &&
        (customer
          ? // Customer and contact card
            customerContactCard
          : // Add customer control
            !isGettingCustomer && addCustomerControl)}

      {/* search customer modal */}
      {canSelectCustomer && (
        <SelectCustomerModal
          isOpen={showSearchCustomerTableModal}
          onClose={() => setShowSearchCustomerTableModal(false)}
          onSelectCustomer={(customerId: number) => {
            setShowSearchCustomerTableModal(false);
            onSelectCustomer && onSelectCustomer(customerId);
          }}
        />
      )}
    </EditJobSection>
  );
};

export default JobCustomerControl;
