import { takeLatest } from 'redux-saga/effects';

import { Req } from '@payaca/helpers/storeHelper';

import { DefaultSagaConfig } from '../types';
import { handleAsyncAction } from '../utils';

import { getCountries } from './actions';
import { ActionType } from './types';

const countriesSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: DefaultSagaConfig) => {
  const api = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  return function* () {
    yield takeLatest(
      ActionType.GET_COUNTRIES_REQUEST,
      handleAsyncAction(getCountries, () =>
        api.get('/countries').then((x) => x.json())
      )
    );
  };
};

export default countriesSagaCreator;
