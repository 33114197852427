import React, { FC, useCallback, useMemo, useState } from 'react';
import { WithOptional } from '@payaca/utilities/types';
import {
  PublicEntityTemplate,
  TemplatableEntity,
} from '@payaca/types/entity-templates';
import Modal from '@payaca/components/modal/Modal';
import CreateEditTaskTemplateControl from '../createEditTaskTemplateControl/CreateEditTaskTemplateControl';
import CreateEditScheduledEventTemplateControl from '../createEditScheduledEventTemplateControl/CreateEditScheduledEventTemplateControl';
import CreateEditProposalTemplateControl from '../createEditProposalTemplateControl/CreateEditProposalTemplateControl';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  template?: WithOptional<PublicEntityTemplate<TemplatableEntity>, 'publicId'>;
  allowModifyTemplateNameDescription?: boolean;
  onRequestSave: (
    entityTemplate: Omit<PublicEntityTemplate<TemplatableEntity>, 'publicId'>
  ) => void;
  action?: 'Create' | 'Edit';
};
const CreateEditEntityTemplateModal: FC<Props> = ({
  isOpen,
  onClose,
  template,
  allowModifyTemplateNameDescription,
  onRequestSave,
  action,
}: Props): JSX.Element | null => {
  const title = useMemo(() => {
    return `${action || (template?.publicId ? 'Edit' : 'Create')} ${
      template?.entityType == 'task'
        ? 'Task'
        : template?.entityType == 'scheduledEvent'
          ? 'Event'
          : 'Proposal'
    } Template`;
  }, [template]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      {template?.entityType === 'task' && (
        <CreateEditTaskTemplateControl
          taskTemplate={
            template as Omit<PublicEntityTemplate<'task'>, 'publicId'>
          }
          allowModifyTemplateNameDescription={
            allowModifyTemplateNameDescription
          }
          onRequestSave={onRequestSave}
        />
      )}
      {template?.entityType === 'scheduledEvent' && (
        <CreateEditScheduledEventTemplateControl
          allowModifyTemplateNameDescription={
            allowModifyTemplateNameDescription
          }
          scheduledEventTemplate={
            template as Omit<PublicEntityTemplate<'scheduledEvent'>, 'publicId'>
          }
          onRequestSave={onRequestSave}
        />
      )}
      {template?.entityType === 'proposal' && (
        <CreateEditProposalTemplateControl
          allowModifyTemplateNameDescription={
            allowModifyTemplateNameDescription
          }
          proposalTemplate={
            template as Omit<PublicEntityTemplate<'proposal'>, 'publicId'>
          }
          onRequestSave={onRequestSave}
        />
      )}
    </Modal>
  );
};
export default CreateEditEntityTemplateModal;
