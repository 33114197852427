import { action } from 'typesafe-actions';

import {
  AcceptChangeProposalAction,
  ActionType,
  CreatePaymentRecordRequestData,
  DeclineChangeProposalAction,
  GetChangeProposalAction,
  GetChangeProposalsAction,
  GetInvoiceAction,
  GetJobLineItemsAction,
  GetJobLineItemsForInvoiceAction,
  GetServicePlanPayloadAction,
  RecordViewAction,
  CreateServicePlanSubscriptionAction,
  SendServicePlanSubscriptionManageBillingEmailAction,
  GetServicePlanSubscriptionPayloadAction,
  UpdateServicePlanSubscriptionStatus,
  GetServicePlanDiscountCodeByClientCode,
  GetGeneratedPdfUrlForInvoice,
} from './clientTypes';
import {
  ClientDeal,
  ClientProposal,
  ClientInvoice,
  ClientCustomer,
  StripePaymentIntentInformation,
  PaymentMethodConfig,
  ClientChangeProposal,
} from '@payaca/types/clientTypes';
import { DocumentSenderContext } from '@payaca/types/accountBrandTypes';
import {
  JobContent,
  JobLineItem,
  JobLineItemGroup,
} from '@payaca/types/jobContentTypes';
import {
  InvoiceLine,
  PaymentReconciliationRecord,
} from '@payaca/types/invoiceTypes';
import { createAsyncAction } from '../utils';

export const requestGetClientContext = (previewToken: string) => {
  return action(ActionType.GET_CLIENT_CONTEXT_REQUEST, {
    previewToken,
  });
};

export const clearClientContext = () => {
  return action(ActionType.CLEAR_CLIENT_CONTEXT);
};

export const requestGetDocumentSenderContext = (
  previewToken: string,
  callback?: (documentSenderContext: DocumentSenderContext) => void
) => {
  return action(ActionType.GET_DOCUMENT_ORIGIN_CONTEXT_REQUEST, {
    previewToken,
    callback,
  });
};

export const getDocumentSenderContextSuccess = (
  documentSenderContext: DocumentSenderContext
) => {
  return action(ActionType.GET_DOCUMENT_ORIGIN_CONTEXT_SUCCESS, {
    documentSenderContext,
  });
};

export const getDocumentSenderContextFailure = (error: Error) => {
  return action(
    ActionType.GET_DOCUMENT_ORIGIN_CONTEXT_FAILURE,
    null,
    null,
    error
  );
};

export const clearDocumentSenderContext = () => {
  return action(ActionType.CLEAR_DOCUMENT_ORIGIN_CONTEXT);
};

export const requestGetDeal = (
  previewToken: string,
  callback?: (deal: ClientDeal) => void
) => {
  return action(ActionType.GET_DEAL_REQUEST, {
    previewToken,
    callback,
  });
};

export const getDealSuccess = (deal: ClientDeal) => {
  return action(ActionType.GET_DEAL_SUCCESS, { deal });
};

export const getDealFailure = (error: Error) => {
  return action(ActionType.GET_DEAL_FAILURE, null, null, error);
};

export const clearDeal = () => {
  return action(ActionType.CLEAR_DEAL);
};

export const requestGetPaymentMethodConfig = (
  previewToken: string,
  callback?: (paymentMethodConfig: PaymentMethodConfig) => void
) => {
  return action(ActionType.GET_PAYMENT_METHOD_CONFIG_REQUEST, {
    previewToken,
    callback,
  });
};

export const getPaymentMethodConfigSuccess = (
  paymentMethodConfig: PaymentMethodConfig
) => {
  return action(ActionType.GET_PAYMENT_METHOD_CONFIG_SUCCESS, {
    paymentMethodConfig,
  });
};

export const getPaymentMethodConfigFailure = (error: Error) => {
  return action(
    ActionType.GET_PAYMENT_METHOD_CONFIG_FAILURE,
    null,
    null,
    error
  );
};

export const clearPaymentMethodConfig = () => {
  return action(ActionType.CLEAR_PAYMENT_METHOD_CONFIG);
};

export const requestGetCustomer = (
  previewToken: string,
  callback?: (deal: ClientDeal) => void
) => {
  return action(ActionType.GET_CUSTOMER_REQUEST, {
    previewToken,
    callback,
  });
};

export const getCustomerSuccess = (customer: ClientCustomer) => {
  return action(ActionType.GET_CUSTOMER_SUCCESS, { customer });
};

export const getCustomerFailure = (error: Error) => {
  return action(ActionType.GET_CUSTOMER_FAILURE, null, null, error);
};

export const clearCustomer = () => {
  return action(ActionType.CLEAR_CUSTOMER);
};

export const requestGetProposals = (
  previewToken: string,
  callback?: () => void
) => {
  return action(ActionType.GET_PROPOSALS_REQUEST, {
    previewToken,
    callback,
  });
};

export const getProposalSuccess = (
  proposalId: number,
  proposal: ClientProposal
) => {
  return action(ActionType.GET_PROPOSAL_SUCCESS, {
    proposalId,
    proposal,
  });
};

export const getProposalsSuccess = () => {
  return action(ActionType.GET_PROPOSALS_SUCCESS);
};

export const getProposalsFailure = (error: Error) => {
  return action(ActionType.GET_PROPOSALS_FAILURE, null, null, error);
};

export const clearProposals = () => {
  return action(ActionType.CLEAR_PROPOSALS);
};

export const requestGetInvoices = (
  previewToken: string,
  callback?: () => void
) => {
  return action(ActionType.GET_INVOICES_REQUEST, {
    previewToken,
    callback,
  });
};

export const getInvoiceSuccess = (
  invoiceId: number,
  invoice: ClientInvoice
) => {
  return action(ActionType.GET_INVOICE_SUCCESS, {
    invoiceId,
    invoice,
  });
};

export const getInvoicesSuccess = () => {
  return action(ActionType.GET_INVOICES_SUCCESS);
};

export const getInvoicesFailure = (error: Error) => {
  return action(ActionType.GET_INVOICES_FAILURE, null, null, error);
};

export const clearInvoices = () => {
  return action(ActionType.CLEAR_INVOICES);
};

export const requestGetJobContents = (
  previewToken: string,
  callback?: () => void
) => {
  return action(ActionType.GET_JOB_CONTENTS_REQUEST, {
    previewToken,
    callback,
  });
};

export const getJobContentsSuccess = () => {
  return action(ActionType.GET_JOB_CONTENTS_SUCCESS);
};

export const getJobContentsFailure = (error: Error) => {
  return action(ActionType.GET_JOB_CONTENTS_FAILURE, null, null, error);
};

export const getJobContentSuccess = (
  jobContentId: number,
  jobContent: JobContent
) => {
  return action(ActionType.GET_JOB_CONTENT_SUCCESS, {
    jobContentId,
    jobContent,
  });
};

export const clearJobContents = () => {
  return action(ActionType.CLEAR_JOB_CONTENTS);
};

export const requestGetJobPaymentsWithReconciliationRecords = (
  previewToken: string,
  callback?: () => void
) => {
  return action(
    ActionType.GET_JOB_PAYMENTS_WITH_RECONCILIATION_RECORDS_REQUEST,
    {
      previewToken,
      callback,
    }
  );
};

export const getJobPaymentSuccess = (
  jobPaymentId: number,
  jobPayment: ClientInvoice
) => {
  return action(ActionType.GET_JOB_PAYMENT_SUCCESS, {
    jobPaymentId,
    jobPayment,
  });
};

export const clearJobPayments = () => {
  return action(ActionType.CLEAR_JOB_PAYMENTS);
};

export const getPaymentReconciliationRecordSuccess = (
  paymentReconciliationRecordId: number,
  paymentReconciliationRecord: PaymentReconciliationRecord
) => {
  return action(ActionType.GET_PAYMENT_RECONCILIATION_RECORD_SUCCESS, {
    paymentReconciliationRecordId,
    paymentReconciliationRecord,
  });
};

export const clearPaymentReconciliationRecords = () => {
  return action(ActionType.CLEAR_PAYMENT_RECONCILIATION_RECORDS);
};

export const requestGetJobLineItemsForJobContent = (
  jobContentId: number,
  previewToken: string,
  callback?: () => void
) => {
  return action(ActionType.GET_JOB_LINE_ITEMS_FOR_JOB_CONTENT_REQUEST, {
    jobContentId,
    previewToken,
    callback,
  });
};

export const getJobLineItemSuccess = (
  jobLineItemId: number,
  jobLineItem: JobLineItem
) => {
  return action(ActionType.GET_JOB_LINE_ITEM_SUCCESS, {
    jobLineItemId,
    jobLineItem,
  });
};

export const clearJobLineItems = () => {
  return action(ActionType.CLEAR_JOB_LINE_ITEMS);
};

export const requestGetJobLineItemGroupsForJobContent = (
  jobContentId: number,
  previewToken: string,
  callback?: () => void
) => {
  return action(ActionType.GET_JOB_LINE_ITEM_GROUPS_FOR_JOB_CONTENT_REQUEST, {
    jobContentId,
    previewToken,
    callback,
  });
};

export const getJobLineItemGroupSuccess = (
  jobLineItemGroupId: number,
  jobLineItemGroup: JobLineItemGroup
) => {
  return action(ActionType.GET_JOB_LINE_ITEM_GROUP_SUCCESS, {
    jobLineItemGroupId,
    jobLineItemGroup,
  });
};

export const clearJobLineItemGroups = () => {
  return action(ActionType.CLEAR_JOB_LINE_ITEM_GROUPS);
};

export const requestGetInvoiceLinesForInvoice = (
  invoiceId: number,
  previewToken: string,
  callback?: () => void
) => {
  return action(ActionType.GET_INVOICE_LINES_FOR_INVOICE_REQUEST, {
    invoiceId,
    previewToken,
    callback,
  });
};

export const getInvoiceLineSuccess = (
  invoiceLineId: number,
  invoiceLine: InvoiceLine
) => {
  return action(ActionType.GET_INVOICE_LINE_SUCCESS, {
    invoiceLineId,
    invoiceLine,
  });
};

export const clearInvoiceLines = () => {
  return action(ActionType.CLEAR_INVOICE_LINES);
};

export const requestCreateStripePaymentIntent = (
  paymentValue: number,
  previewToken: string,
  type: 'quote' | 'invoice' | 'deal',
  callback?: (paymentIntentInformation: StripePaymentIntentInformation) => void
) => {
  return action(ActionType.CREATE_STRIPE_PAYMENT_INTENT_REQUEST, {
    paymentValue,
    previewToken,
    type,
    callback,
  });
};

export const createStripePaymentIntentSuccess = () => {
  return action(ActionType.CREATE_STRIPE_PAYMENT_INTENT_SUCCESS);
};

export const createStripePaymentIntentFailure = (error: Error) => {
  return action(
    ActionType.CREATE_STRIPE_PAYMENT_INTENT_FAILURE,
    null,
    null,
    error
  );
};

export const requestCancelStripePaymentIntent = (
  paymentIntentId: string,
  previewToken: string,
  type: 'quote' | 'invoice' | 'deal',
  callback?: () => void
) => {
  return action(ActionType.CANCEL_STRIPE_PAYMENT_INTENT_REQUEST, {
    paymentIntentId,
    previewToken,
    type,
    callback,
  });
};

export const cancelStripePaymentIntentSuccess = () => {
  return action(ActionType.CANCEL_STRIPE_PAYMENT_INTENT_SUCCESS);
};

export const cancelStripePaymentIntentFailure = (error: Error) => {
  return action(
    ActionType.CANCEL_STRIPE_PAYMENT_INTENT_FAILURE,
    null,
    null,
    error
  );
};

export const requestCreatePaymentRecord = (
  createPaymentRecordRequestData: CreatePaymentRecordRequestData,
  callback?: () => void
) => {
  return action(ActionType.CREATE_PAYMENT_RECORD_REQUEST, {
    createPaymentRecordRequestData,
    callback,
  });
};

export const createPaymentRecordSuccess = () => {
  return action(ActionType.CREATE_PAYMENT_RECORD_SUCCESS);
};

export const createPaymentRecordFailure = (error: Error) => {
  return action(ActionType.CREATE_PAYMENT_RECORD_FAILURE, null, null, error);
};

export const getChangeProposals = createAsyncAction<GetChangeProposalsAction>({
  request: ActionType.GET_CHANGE_PROPOSALS_REQUEST,
  success: ActionType.GET_CHANGE_PROPOSALS_SUCCESS,
  failure: ActionType.GET_CHANGE_PROPOSALS_FAILURE,
})((previewToken: string) => ({ payload: { previewToken } }));

export const getChangeProposal = createAsyncAction<GetChangeProposalAction>({
  request: ActionType.GET_CHANGE_PROPOSAL_REQUEST,
  success: ActionType.GET_CHANGE_PROPOSAL_SUCCESS,
  failure: ActionType.GET_CHANGE_PROPOSAL_FAILURE,
})(
  (
    previewToken: string,
    changeProposalId: number,
    callback?: (changeProposal: ClientChangeProposal) => void
  ) => ({
    payload: { previewToken, changeProposalId, callback },
  })
);

export const clearChangeProposals = () => {
  return action(ActionType.CLEAR_CHANGE_PROPOSALS);
};

export const getJobLineItems = createAsyncAction<GetJobLineItemsAction>({
  request: ActionType.GET_JOB_LINE_ITEMS_REQUEST,
  success: ActionType.GET_JOB_LINE_ITEMS_SUCCESS,
  failure: ActionType.GET_JOB_LINE_ITEMS_FAILURE,
})((previewToken: string) => ({ payload: { previewToken } }));

export const getJobLineItemsForInvoice =
  createAsyncAction<GetJobLineItemsForInvoiceAction>({
    request: ActionType.GET_JOB_LINE_ITEMS_FOR_INVOICE_REQUEST,
    success: ActionType.GET_JOB_LINE_ITEMS_FOR_INVOICE_SUCCESS,
    failure: ActionType.GET_JOB_LINE_ITEMS_FOR_INVOICE_FAILURE,
  })((payload: GetJobLineItemsForInvoiceAction['request']['payload']) => ({
    payload,
  }));

export const acceptChangeProposal =
  createAsyncAction<AcceptChangeProposalAction>({
    request: ActionType.ACCEPT_CHANGE_PROPOSAL_REQUEST,
  })((payload: AcceptChangeProposalAction['request']['payload']) => ({
    payload,
  }));

export const declineChangeProposal =
  createAsyncAction<DeclineChangeProposalAction>({
    request: ActionType.DECLINE_CHANGE_PROPOSAL_REQUEST,
  })((payload: DeclineChangeProposalAction['request']['payload']) => ({
    payload,
  }));

export const getInvoice = createAsyncAction<GetInvoiceAction>({
  request: ActionType.GET_INVOICE_REQUEST,
})((payload: GetInvoiceAction['request']['payload']) => ({
  payload,
}));

export const recordView = createAsyncAction<RecordViewAction>({
  request: ActionType.RECORD_VIEW_REQUEST,
})((payload: RecordViewAction['request']['payload']) => ({
  payload,
}));

export const getServicePlanPayload =
  createAsyncAction<GetServicePlanPayloadAction>({
    request: ActionType.GET_SERVICE_PLAN_PAYLOAD_REQUEST,
    success: ActionType.GET_SERVICE_PLAN_PAYLOAD_SUCCESS,
  })((payload: GetServicePlanPayloadAction['request']['payload']) => ({
    payload,
  }));

/**
 * Get discount codes by servicePlanPublicId
 */
export const getServicePlanDiscountCodeByClientCode =
  createAsyncAction<GetServicePlanDiscountCodeByClientCode>({
    request: ActionType.GET_DISCOUNT_CODE_BY_CLIENT_CODE_REQUEST,
    success: ActionType.GET_DISCOUNT_CODE_BY_CLIENT_CODE_SUCCESS,
    failure: ActionType.GET_DISCOUNT_CODE_BY_CLIENT_CODE_FAILURE,
  })(
    (
      payload: GetServicePlanDiscountCodeByClientCode['request']['payload']
    ) => ({
      payload,
      meta: { publicId: payload.publicId, clientCode: payload.clientCode },
    })
  );

export const createServicePlanSubscription =
  createAsyncAction<CreateServicePlanSubscriptionAction>({
    request: ActionType.CREATE_SERVICE_PLAN_SUBSCRIPTION_REQUEST,
  })((payload: CreateServicePlanSubscriptionAction['request']['payload']) => ({
    payload,
  }));

export const sendServicePlanSubscriptionManageBillingEmail =
  createAsyncAction<SendServicePlanSubscriptionManageBillingEmailAction>({
    request:
      ActionType.SEND_SERVICE_PLAN_SUBSCRIPTION_MANAGE_BILLING_EMAIL_REQUEST,
  })(
    (
      payload: SendServicePlanSubscriptionManageBillingEmailAction['request']['payload']
    ) => ({
      payload,
    })
  );

export const getServicePlanSubscriptionPayload =
  createAsyncAction<GetServicePlanSubscriptionPayloadAction>({
    request: ActionType.GET_SERVICE_PLAN_SUBSCRIPTION_PAYLOAD_REQUEST,
    success: ActionType.GET_SERVICE_PLAN_SUBSCRIPTION_PAYLOAD_SUCCESS,
  })(
    (
      payload: GetServicePlanSubscriptionPayloadAction['request']['payload']
    ) => ({
      payload,
    })
  );

export const updateServicePlanSubscriptionStatus =
  createAsyncAction<UpdateServicePlanSubscriptionStatus>({
    request: ActionType.UPDATE_SERVICE_PLAN_SUBSCRIPTION_STATUS_REQUEST,
    success: ActionType.UPDATE_SERVICE_PLAN_SUBSCRIPTION_STATUS_SUCCESS,
    failure: ActionType.UPDATE_SERVICE_PLAN_SUBSCRIPTION_STATUS_FAILURE,
  })((payload: UpdateServicePlanSubscriptionStatus['request']['payload']) => ({
    payload,
  }));

export const getGeneratedPdfUrlForInvoice =
  createAsyncAction<GetGeneratedPdfUrlForInvoice>({
    request: ActionType.GET_GENERATED_PDF_URL_FOR_INVOICE_REQUEST,
  })((payload: GetGeneratedPdfUrlForInvoice['request']['payload']) => ({
    payload,
  }));
