import React, { FunctionComponent, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import './PrivateField.sass';
import BasicField from '../basicField/BasicField';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { InputStyleVariant } from '../inputWrapper/InputWrapper';

type Props = {
  styleVariant?: InputStyleVariant;
  name: string;
  value?: any;
  label?: string | JSX.Element;
  description?: string | JSX.Element;
  isDisabled?: boolean;
  isRequired?: boolean;
  iconBefore?: IconDefinition;
  additionalInputProps?: { [key: string]: any };
  changeTimeoutMs?: number;
  onChange?: (value: { [key: string]: string }) => void;
  onTouch?: (fieldName: string) => void;
  onBlur?: () => void;
  onChangeTimeout?: () => void;
};

const PrivateField: FunctionComponent<React.PropsWithChildren<Props>> = (
  props: React.PropsWithChildren<Props>
): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={`private-field`}>
      <BasicField
        type={isVisible ? 'text' : 'password'}
        {...props}
        iconAfter={
          <FontAwesomeIcon
            onClick={() => setIsVisible((isVisible) => !isVisible)}
            icon={isVisible ? faEyeSlash : faEye}
          />
        }
      />
    </div>
  );
};

export default PrivateField;
