import {
  DealTemplate,
  DealTemplateItem,
} from '@payaca/types/dealTemplateTypes';

export interface State {
  dealTemplates: DealTemplate[];
  dealTemplateItems: DealTemplateItem[];

  isGettingDealTemplates: boolean;
  isGettingDealTemplateItems: boolean;
  isPersistingDealTemplate: boolean;
  isApplyingDealTemplate: boolean;
}

export enum ActionType {
  REQUEST_GET_DEAL_TEMPLATE_ITEMS = 'dealTemplates.requestGetDealTemplateItems',
  GET_DEAL_TEMPLATE_ITEMS_SUCCESS = 'dealTemplates.getDealTemplateItemsSuccess',
  GET_DEAL_TEMPLATE_ITEMS_FAILURE = 'dealTemplates.getDealTemplateItemsFailure',

  REQUEST_PERSIST_DEAL_TEMPLATE = 'dealTemplates.requestPersistDealTemplate',
  PERSIST_DEAL_TEMPLATE_SUCCESS = 'dealTemplates.persistDealTemplateSuccess',
  PERSIST_DEAL_TEMPLATE_FAILURE = 'dealTemplates.persistDealTemplateFailure',

  REQUEST_GET_DEAL_TEMPLATES = 'dealTemplates.requestGetDealTemplates',
  GET_DEAL_TEMPLATES_SUCCESS = 'dealTemplates.getDealTemplatesSuccess',
  GET_DEAL_TEMPLATES_FAILURE = 'dealTemplates.getDealTemplatesFailure',

  REQUEST_APPLY_DEAL_TEMPLATE = 'dealTemplates.requestApplyDealTemplate',
  APPLY_DEAL_TEMPLATE_SUCCESS = 'dealTemplates.applyDealTemplateSuccess',
  APPLY_DEAL_TEMPLATE_FAILURE = 'dealTemplates.applyDealTemplateFailure',

  CLEAR_DEAL_TEMPLATES = 'dealTemplates.clearDealTemplates',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}
