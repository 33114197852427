import {
  BaseMaterial,
  BaseSupplierMaterial,
  MaterialCategory,
} from './materialTypes';
import { ListViewPage } from './listViewTypes';
import { BaseSupplier } from './supplierTypes';

export interface ListedMaterial extends BaseMaterial {
  thumbnailUrl?: string;
  suppliers: BaseSupplier[];
  supplierMaterials: BaseSupplierMaterial[];
  predictedPriceExcludingTax?: number;
  category?: MaterialCategory;
}

export enum SortBy {
  NAME = 'name',
  DESCRIPTION = 'description',
}

export enum ManagedBy {
  SYSTEM = 'system',
  SELF = 'self',
}

export interface ListedMaterialListViewPage
  extends ListViewPage<ListedMaterial> {}
