import { takeEvery, call, put, race, delay } from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import {
  DealActivitySagaConfig,
  DealActivityActionTypes,
} from './dealActivityTypes';
import {
  getDealActivityEventsFailure,
  getDealActivityEventsSuccess,
  clearDealActivityEvents,
} from './dealActivityActions';

import { Req } from '@payaca/helpers/storeHelper';
import { GetDealActivityEventsRequestData } from '@payaca/types/dealActivityTypes';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const dealActivitySagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: DealActivitySagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleGetDealActivityEvents(
    action: PayloadAction<
      DealActivityActionTypes.REQUEST_GET_DEAL_ACTIVITY_EVENTS,
      {
        dealId: number;
        getDealActivityEventsRequestData: GetDealActivityEventsRequestData;
      }
    >
  ) {
    yield call(refreshAuthToken);
    yield put(clearDealActivityEvents());

    try {
      const { response, timeout } = yield race({
        response: call(
          getDealActivityEvents,
          action.payload.dealId,
          action.payload.getDealActivityEventsRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getDealActivityEventsFailure(
            new Error('Get Project activity events request timed out.')
          )
        );
      } else {
        yield put(getDealActivityEventsSuccess(response));
      }
    } catch (error: any) {
      yield put(getDealActivityEventsFailure(error));
    }
  }

  const getDealActivityEvents = async (
    dealId: number,
    getDealActivityEventsRequestData: GetDealActivityEventsRequestData
  ) => {
    const response = await req.get(`/deals/${dealId}/activity`);
    return await response.json();
  };

  return function* () {
    yield takeEvery(
      DealActivityActionTypes.REQUEST_GET_DEAL_ACTIVITY_EVENTS,
      handleGetDealActivityEvents
    );
  };
};

export default dealActivitySagaCreator;
