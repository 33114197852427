import { useMemo } from 'react';

import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';

import { useSelector } from '@/api/state';
import { getUserRoles } from '@/utils/stateAccessors';

// FIXME: Refactor this into a more useful hook, e.g. 'useHasSomePermissions' or 'useHasEveryPermission', or whatever...
export const useCanUpdateAccount = () => {
  const userRoles = useSelector(getUserRoles);
  const canUpdateAccount = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      AccountsPermissions.UPDATE_ACCOUNT,
    ]);
  }, [userRoles]);
  return canUpdateAccount;
};
