import React, { FC, PropsWithChildren, useMemo } from 'react';

import { ListedMaterial } from '@payaca/types/listedMaterialTypes';

import EntityCard from '@payaca/components/entityCard/EntityCard';
import Badge from '@payaca/components/badge/Badge';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import MaterialSuppliersCondensed from '../materialSuppliersCondensed/MaterialSuppliersCondensed';

import { currencyPrice } from '@payaca/helpers/financeHelper';

import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';

import { getRegion, getUserRoles } from '@/utils/stateAccessors';

import { useSelector } from '@/api/state';

import './ListedMaterialCard.sass';
import { isNullish } from '@payaca/utilities/guards';

interface Props {
  listedMaterial: ListedMaterial;
  onClick?: () => void;
  quickActionDefinitions?: {
    archive?: {
      actionName: string;
      actionBehaviour: (recordId: any) => void;
      isActionProcessing?: boolean;
    };
  };
  selectionConfig?: {
    isSelected: boolean;
    isDisabled?: boolean;
    onSelectionChange?: (isSelected?: boolean) => void;
    isRadioButton?: boolean;
  };
}

const ListedMaterialCard: FC<PropsWithChildren<Props>> = ({
  listedMaterial,
  onClick,
  quickActionDefinitions,
  selectionConfig,
}) => {
  const userRoles = useSelector(getUserRoles);
  const region = useSelector(getRegion);

  const quickActions = useMemo(() => {
    const qa: {
      actionName: string;
      actionBehaviour: (recordId: any) => void;
      isActionProcessing?: boolean;
    }[] = [];

    if (!listedMaterial.isSystemManaged) {
      if (
        quickActionDefinitions?.archive &&
        userHasRequiredPermission(userRoles, [
          MaterialsPermissions.ARCHIVE_MATERIAL,
        ])
      ) {
        qa.push(quickActionDefinitions.archive);
      }
    }
    return qa;
  }, [listedMaterial.isSystemManaged, quickActionDefinitions, userRoles]);

  return (
    <EntityCard
      className="listed-material-card"
      imageConfig={{
        imageSrc: listedMaterial.thumbnailUrl,
        renderBlockIfNoImageSrc: true,
      }}
      onClick={onClick}
      quickActionsConfig={{
        recordId: listedMaterial.id,
        quickActions: quickActions,
        renderDisabledIfNoActions: false,
      }}
      selectionConfig={selectionConfig}
    >
      <ResponsiveViewWrapper
        className="listed-material-card-content"
        downBreakpointSm={400}
      >
        <div className="name-suppliers-container">
          <h2 className="material-name">{listedMaterial.name}</h2>
          <MaterialSuppliersCondensed
            suppliers={listedMaterial.suppliers}
            supplierMaterials={listedMaterial.supplierMaterials}
            preferredSupplierId={listedMaterial.preferredSupplierId}
          />
        </div>
        <div className="category-price-container">
          {listedMaterial.category && (
            <Badge>{listedMaterial.category.label}</Badge>
          )}
          {isNullish(listedMaterial.predictedPriceExcludingTax) ? (
            ''
          ) : (
            <span className="material-price">
              {currencyPrice(listedMaterial.predictedPriceExcludingTax, region)}
            </span>
          )}
        </div>
      </ResponsiveViewWrapper>
    </EntityCard>
  );
};

export default ListedMaterialCard;
