import React, { FC, useCallback, useMemo } from 'react';
import { format, startOfMinute } from 'date-fns';
import { useQueryClient } from '@tanstack/react-query';

import useGetProjectEvents from '@/api/queries/project/useGetProjectEvents';
import DealActivityCard from '@/ui/components/dealActivityCard/DealActivityCard';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import ActivityList from '@/ui/pages/dealPage/components/ActivityPanel/ActivityList';
import CreateEditNoteModal from '@/ui/components/createEditNoteModal/CreateEditNoteModal';
import projectKeys from '@/api/queries/project/keyFactory';
import noteKeys from '@/api/queries/notes/keyFactory';
import Tabs from '@payaca/components/plTabs/Tabs';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import { getProjectOverviewActivityFilterPreferencesLocalStorageKey } from '@/helpers/localStorageKeyHelper';
import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import {
  DateFormats,
  getInternationalDateFormatByLocale,
} from '@payaca/helpers/internationalHelper';

const ActivityPanel: FC = () => {
  const { dealId } = useParams<{ dealId: string }>();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const queryClient = useQueryClient();
  const { data: accountRegionPreferences } = useGetMyRegionPreferences();
  const isAddingOrEditingNote = useRouteMatch<{
    noteId: string;
  }>({
    path: `${path}/:noteId`,
    exact: true,
  });
  const { events: upcomingProjectEvents } = useGetProjectEvents(+dealId, {
    start: startOfMinute(new Date()).toISOString(),
  });

  const defaultTabIndex = useMemo(() => {
    const allFilterTabPreferences = JSON.parse(
      localStorage.getItem(
        getProjectOverviewActivityFilterPreferencesLocalStorageKey()
      ) ?? '{}'
    );

    return allFilterTabPreferences[dealId] ?? 0;
  }, []);

  const handleFilterTabChange = useCallback((index: number) => {
    const _filterTabPreferences = JSON.parse(
      localStorage.getItem(
        getProjectOverviewActivityFilterPreferencesLocalStorageKey()
      ) ?? '{}'
    );

    localStorage.setItem(
      getProjectOverviewActivityFilterPreferencesLocalStorageKey(),
      JSON.stringify({
        ..._filterTabPreferences,
        [dealId]: index,
      })
    );
  }, []);

  return (
    <>
      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body>
          <Tabs
            variant="segment"
            defaultIndex={defaultTabIndex}
            onChange={handleFilterTabChange}
          >
            <Tabs.Panel
              label="All"
              render={() => (
                <div className="space-y-4">
                  {upcomingProjectEvents &&
                    upcomingProjectEvents.items.length > 0 && (
                      <>
                        <h4>Upcoming</h4>

                        {upcomingProjectEvents.items.map((event) => (
                          <DealActivityCard
                            key={event.id}
                            date={new Date(event.beginAt)}
                            onClick={() =>
                              history.push(
                                `/deals/${dealId}/schedule/${event.id}`
                              )
                            }
                            title={
                              <>
                                <p className="font-semibold">{event.name}</p>
                              </>
                            }
                            titleSuffix={
                              <span className="whitespace-nowrap">
                                {format(
                                  event.beginAt,
                                  getInternationalDateFormatByLocale(
                                    DateFormats.TIMESTAMP_SHORT,
                                    accountRegionPreferences?.locale
                                  )
                                )}
                              </span>
                            }
                          >
                            <p className="supporting-body whitespace-pre-wrap">
                              {event.description}
                            </p>
                          </DealActivityCard>
                        ))}
                      </>
                    )}

                  <ActivityList activityFilter={[]} />
                </div>
              )}
            />
            <Tabs.Panel
              label="Notes"
              render={() => <ActivityList activityFilter={['note']} />}
            />
            <Tabs.Panel
              label="Communications"
              render={() => <ActivityList activityFilter={['communication']} />}
            />
          </Tabs>
        </Card.Body>
      </Card>

      <CreateEditNoteModal
        projectId={dealId}
        isOpen={!!isAddingOrEditingNote}
        onClose={() => {
          history.push(url);
        }}
        notePublicId={
          isAddingOrEditingNote?.params.noteId !== 'addNote'
            ? isAddingOrEditingNote?.params.noteId
            : undefined
        }
        onSuccess={() => {
          queryClient.invalidateQueries({
            queryKey: projectKeys.activityFeed(+dealId),
          });

          if (isAddingOrEditingNote?.params.noteId) {
            queryClient.invalidateQueries({
              queryKey: noteKeys.note(isAddingOrEditingNote.params.noteId),
            });
          }
        }}
        onSuccessDelete={() => {
          queryClient.invalidateQueries({
            queryKey: projectKeys.activityFeed(+dealId),
          });

          if (isAddingOrEditingNote?.params.noteId) {
            queryClient.removeQueries({
              queryKey: noteKeys.note(isAddingOrEditingNote.params.noteId),
            });
          }
        }}
      />
    </>
  );
};

export default ActivityPanel;
