import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import Button from '@payaca/components/button/Button';
import { ButtonColourVariant, ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';

type Props = {
  materialsListId: number;
};

const ClearMaterialsListControl: FunctionComponent<Props> = ({
  materialsListId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const [showConfirmClearPrompt, setShowConfirmClearPrompt] = useState(false);
  const [isClearing, setIsClearing] = useState(false);

  const handleClearMaterialsList = useCallback(() => {
    setIsClearing(true);

    dispatch(
      materialsListActions.clearMaterialsList.request({
        materialsListId,
        callback: (response) => {
          dispatch(
            materialsListActions.requestGetMaterialsListWithRelatedEntities(
              materialsListId,
              () => {
                setIsClearing(false);
                setShowConfirmClearPrompt(false);
              }
            )
          );
        },
      })
    );
  }, [materialsListId]);

  return (
    <PermissionGuard
      renderIfHasPermissions={[MaterialsListPermissions.CLEAR_MATERIALS_LIST]}
    >
      <div className="clear-materials-list-control">
        <Button
          colourVariant={ButtonColourVariant.RED}
          styleVariant={ButtonStyleVariant.ANCHOR}
          onClick={() => setShowConfirmClearPrompt(true)}
        >
          Clear the Materials required
        </Button>

        <Modal
          title={'Are you sure?'}
          isOpen={showConfirmClearPrompt}
          onClose={() => setShowConfirmClearPrompt(false)}
          actions={
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              colourVariant={ButtonColourVariant.RED}
              onClick={handleClearMaterialsList}
              isProcessing={isClearing}
            >
              Clear Materials required
            </Button>
          }
        >
          <p>
            Materials which have already been purchased or included in a
            purchase order will not be cleared.
          </p>
        </Modal>
      </div>
    </PermissionGuard>
  );
};

export default ClearMaterialsListControl;
