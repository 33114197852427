import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as validationActions from '@payaca/store/validation/validationActions';

import {
  LoqateAddressResult,
  LoqateAddressResultType,
} from '@payaca/types/addressTypes';

import { useAccount } from '../../../utils/storeHooks';

import {
  getAddressAsString,
  loqateAddressToAddress,
} from '@payaca/helpers/locationHelper';
import { Address } from '@payaca/types/locationTypes';
import Combobox from '@payaca/components/plCombobox/Combobox';
import { SelectOption } from '@payaca/components/plSelect/Select';
type AddressType = Pick<
  Address,
  'line1' | 'line2' | 'city' | 'postcode' | 'country'
>;
interface Props {
  address?: Partial<AddressType>;
  onChange: (address?: AddressType) => void;
  disabled?: boolean;
}

export const AddressLookupSelect: FC<Props> = ({
  address,
  onChange,
  disabled,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const account = useAccount();

  const [addressLookupResults, setAddressLookupResults] =
    useState<LoqateAddressResult[]>();
  const [query, setQuery] = useState<string>();

  const options = useMemo(() => {
    const o: SelectOption<string, LoqateAddressResult | undefined>[] =
      addressLookupResults?.map((x) => ({
        value: x.id,
        label: x.label,
        metadata: x,
      })) || [];
    return o;
  }, [addressLookupResults]);

  const lookupAddress = (query: string, containerId = '') => {
    dispatch(
      validationActions.requestLookupAddressBySearchTerm(
        query,
        containerId,
        account.region,
        (error: Error, response: any) => {
          setAddressLookupResults(response);
        }
      )
    );
  };

  useEffect(() => {
    if (query?.length) {
      lookupAddress(query);
    }
  }, [query]);

  const selectAddressFromLookup = useCallback(
    (addressResult: LoqateAddressResult) => {
      if (addressResult.type === LoqateAddressResultType.ADDRESS) {
        // selected an address - retrieve full address and set
        dispatch(
          validationActions.requestRetrieveAddress(
            addressResult.id,
            (error: Error, completeAddress: any) => {
              const { line1, line2, city, postcode } =
                loqateAddressToAddress(completeAddress);
              onChange({
                line1,
                line2,
                city,
                postcode,
                country: completeAddress.countryName,
              });
              setAddressLookupResults(undefined);
              const addressString =
                getAddressAsString({
                  line1,
                  line2,
                  city,
                  postcode,
                }) || '';
              setQuery(addressString);
            }
          )
        );
      }
    },
    [onChange]
  );

  return (
    <Combobox
      placeholder="Start typing addess or postcode"
      inputPlaceholder="Start typing addess or postcode"
      disabled={disabled}
      hideDropdownChevron
      options={options || []}
      query={query}
      setQuery={setQuery}
      filterFunction={() => true}
      customButtonContent={address ? getAddressAsString(address) : undefined}
      onChange={(value) => {
        const option = options.find((x) => x.value === value);

        if (!value || !option?.metadata) return;

        selectAddressFromLookup(option.metadata);
      }}
      onOptionClick={(e, option) => {
        const addressResult = option?.metadata;

        if (
          addressResult &&
          addressResult.type !== LoqateAddressResultType.ADDRESS
        ) {
          // preventing default here stops onChange from being fired
          // this is desirable in the case that the user clicks on a postcode with multiple addresses
          e.preventDefault();
          lookupAddress(query || '', addressResult.id);
        }
      }}
    />
  );
};
