import { useQuery } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import customerKeys from '@/api/queries/customers/keyFactory';

const GetCustomerContactEmailsQuery = graphql(`
  query ExistingCustomerContactEmails {
    existingCustomerContactEmails
  }
`);

const useGetExistingCustomerContactEmails = (customerId?: string) => {
  const { data, ...rest } = useQuery({
    queryKey: customerKeys.existingCustomerContactEmails,
    queryFn: () => {
      return gqlClient.request(GetCustomerContactEmailsQuery);
    },
  });

  return {
    data: data?.existingCustomerContactEmails,
    ...rest,
  };
};

export default useGetExistingCustomerContactEmails;
