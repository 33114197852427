import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import { get } from 'lodash-es';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

import { actions as userActions } from '@/api/users';
import { actions as appActions } from '@/api/app';
import * as accountActions from '@payaca/store/account/accountActions';
import { actions as accountSettingsActions } from '@/api/accountSettings';

import { parseAccount } from '@/helpers/userHelper';
import { getModal } from '@/helpers/modalHelper';
import { getManagedAttachments } from '@/helpers/jobHelper';
import { FormValidations } from '@/helpers/formHelper';
import { getFieldErrors } from '@/helpers/formHelper';

import StringUtil from '@/utils/stringUtil';
import { AccountRegions } from '@payaca/types/accountTypes';
import { useSelector } from '@/api/state';

type Props = {
  buttonTitle?: string;
  isModal?: boolean;
  onSaveSuccess?: () => void;
};
const CompanySettingsSaveButton: FC<Props> = ({
  buttonTitle,
  isModal = false,
  onSaveSuccess,
}: Props): JSX.Element => {
  const [blockedEmails, setBlockedEmails] = useState([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      userActions.getBlockedEmails((err: any, blockedEmails: any) => {
        if (blockedEmails) {
          setBlockedEmails(blockedEmails);
        }
      })
    );
  }, []);

  const currentAccount = useSelector(
    (state: any) => state.users?.myProfile?.accounts[0]
  );
  const pendingAccount = useSelector(
    (state: any) => state.accountSettings.pendingAccount
  );
  const currentBusinessAttachments = useSelector(
    (state: any) => state.users.businessAttachments
  );
  const currentTerms = useSelector((state: any) => state.users.terms);
  const pendingTerms = useSelector(
    (state: any) => state.accountSettings.pendingTerms
  );
  const pendingBusinessAttachments = useSelector(
    (state: any) => state.accountSettings.pendingBusinessAttachments
  );
  const currentEmailTemplates = useSelector(
    (state) => state.account.emailTemplates
  );
  const pendingEmailTemplates = useSelector(
    (state: any) => state.accountSettings.pendingEmailTemplates
  );

  const getDifferences = (pendingData: any, existingData: any) => {
    if (pendingData && existingData) {
      return Object.entries(pendingData).reduce((acc: any, val: any) => {
        if (!isEqual(val[1], existingData[val[0]])) {
          acc[val[0]] = val[1];
        }
        return acc;
      }, {});
    }
    return {};
  };

  const emailTemplatesChanges = useMemo(
    () => getDifferences(pendingEmailTemplates, currentEmailTemplates),
    [currentEmailTemplates, getDifferences, pendingEmailTemplates]
  );

  const changesMade = useMemo(() => {
    const existingAccount: any = parseAccount(currentAccount);
    const detailDifferences = getDifferences(pendingAccount, existingAccount);

    // if updating one address line, add changes to other line to ensure whole address is saved together
    if (detailDifferences.firstLineAddress !== undefined) {
      detailDifferences.secondLineAddress = pendingAccount.secondLineAddress;
    } else if (detailDifferences.secondLineAddress !== undefined) {
      detailDifferences.firstLineAddress = pendingAccount.firstLineAddress;
    }
    const differences: any = {};
    if (Object.keys(detailDifferences).length) {
      differences.details = detailDifferences;
    }
    if (pendingTerms !== currentTerms) {
      differences.terms = pendingTerms;
    }
    if (pendingBusinessAttachments !== currentBusinessAttachments) {
      differences.businessAttachments = pendingBusinessAttachments;
    }
    if (Object.keys(emailTemplatesChanges).length) {
      differences.emailTemplates = emailTemplatesChanges;
    }

    return differences;
  }, [
    currentAccount,
    currentBusinessAttachments,
    currentTerms,
    emailTemplatesChanges,
    getDifferences,
    pendingAccount,
    pendingBusinessAttachments,
    pendingTerms,
  ]);

  const onSavingComplete = (errors: string[] = []) => {
    if (errors.length) {
      setIsSaving(false);
      const errorList = StringUtil.list(errors.filter((f) => f));
      const closeModal = () => dispatch(appActions.hideModal());
      if (errorList) {
        dispatch(
          appActions.showModal(
            getModal('UPDATE_ACCOUNT_ERROR_LIST', {
              primaryAction: closeModal,
              onClose: closeModal,
              text: [errorList],
            })
          )
        );
      } else {
        dispatch(
          appActions.showModal(
            getModal('UPDATE_ACCOUNT_ERROR_GENERAL', {
              primaryAction: closeModal,
              onClose: closeModal,
            })
          )
        );
      }
    } else {
      dispatch(
        userActions.getProfile(() => {
          setIsSaving(false);
          onSaveSuccess && onSaveSuccess();
        })
      );
    }
  };

  const onSave = () => {
    setIsSaving(true);
    saveFields();
  };

  const saveBusinessAttachments = useCallback(
    (errors: string[] = []) => {
      if (changesMade.businessAttachments) {
        const managedAttachments = getManagedAttachments(
          currentBusinessAttachments,
          changesMade.businessAttachments
        );
        dispatch(
          userActions.manageBusinessAttachments(
            managedAttachments.toAdd,
            managedAttachments.toRemove,
            (err: any) => {
              if (!err) {
                // fetch updated business attachments
                dispatch(userActions.getBusinessAttachments());
              }
              err && errors.push('business attachments');
              onSavingComplete(errors);
            }
          )
        );
      } else {
        onSavingComplete(errors);
      }
    },
    [changesMade, currentBusinessAttachments, dispatch, onSavingComplete]
  );

  const saveTerms = useCallback(
    (errors: string[] = []) => {
      if (changesMade.terms) {
        const managedAttachments = getManagedAttachments(
          currentTerms,
          changesMade.terms
        );
        dispatch(
          userActions.manageTermsDocuments(
            currentAccount.id,
            managedAttachments.toAdd,
            managedAttachments.toRemove,
            (err: any) => {
              if (!err) {
                // fetch updated terms
                dispatch(userActions.getAccountTerms(currentAccount.id));
              }
              err && errors.push('business terms');
              saveBusinessAttachments(errors);
            }
          )
        );
      } else {
        saveBusinessAttachments(errors);
      }
    },
    [
      changesMade,
      currentAccount,
      currentTerms,
      dispatch,
      saveBusinessAttachments,
    ]
  );

  const saveLogo = useCallback(
    (errors: string[] = []) => {
      if (changesMade?.details?.logoUrl?.uri) {
        dispatch(
          userActions.uploadAccountLogo(
            currentAccount.id,
            changesMade.details.logoUrl,
            (err: any, resp: any) => {
              err && errors.push('logo');
              saveTerms(errors);
            }
          )
        );
      } else {
        saveTerms(errors);
      }
    },
    [changesMade, currentAccount, dispatch, saveTerms]
  );

  const saveEmailTemplates = useCallback(
    (errors: string[] = []) => {
      if (changesMade.emailTemplates) {
        dispatch(
          accountActions.requestUpdateEmailTemplates(
            currentAccount.id,
            changesMade.emailTemplates,
            (err: any) => {
              err && errors.push('email templates');
              saveLogo(errors);
            }
          )
        );
      } else {
        saveLogo(errors);
      }
    },
    [changesMade, currentAccount, dispatch, saveLogo]
  );

  const saveFields = useCallback(() => {
    if (changesMade.details) {
      let changesToSave = changesMade.details;
      if (changesMade?.details?.logoUrl) {
        // save logoUrl as null if new logo/removed logo, this gets updated on account when uploading logo
        changesToSave = { ...changesMade.details, logoUrl: null };
      }
      dispatch(
        userActions.updateBusinessAccount(
          currentAccount.id,
          changesToSave,
          (err: any) => {
            saveEmailTemplates(
              err
                ? getReadableFieldErrors(Object.keys(changesMade.details))
                : []
            );
          }
        )
      );
    } else {
      saveEmailTemplates();
    }
  }, [changesMade, currentAccount, dispatch, saveEmailTemplates]);

  const getReadableFieldErrors = (fields: string[]) => {
    const fieldsToReadable: any = {
      businessName: 'trading name',
      contactNumber: 'contact number',
      businessNotes: 'business notes',
      firstLineAddress: 'address',
      city: 'city',
      email: 'email',
      postcode: 'postcode',
      vatNumber: 'vat number',
      paymentTerms: 'payment terms',
      accountName: 'account name',
      accountNumber: 'account number',
      sortCode: 'sort code',
      hideItemPrices: 'hide item prices',
      propositionValidForDays: 'default proposal valid for days',
      invoiceDueInDays: 'default invoice due in days',
    };
    return fields.map((f) => fieldsToReadable[f]);
  };

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  // TODO: refactor the form validations to take account of region
  const formValidations = useMemo(() => {
    return {
      ...FormValidations.businessSettings,
      ...(account.region === AccountRegions.CANADA
        ? {
            sortCode: [
              {
                validate: (routingNumber: string) =>
                  !routingNumber || /^0[0-9]{8}$/.test(routingNumber),
                error: 'Invalid routing number',
              },
            ],
            accountNumber: [
              {
                validate: (accountNumber: string) =>
                  !accountNumber ||
                  (accountNumber.length <= 12 &&
                    accountNumber.length >= 5 &&
                    /^[0-9]+$/.test(accountNumber)),
                error: 'Invalid account number',
              },
            ],
          }
        : {}),
      ...(account.region === AccountRegions.NEW_ZEALAND
        ? {
            sortCode: [
              {
                validate: (routingNumber: string) =>
                  !routingNumber || /^[0-9]{6}$/.test(routingNumber),
                error: 'Invalid BSB',
              },
            ],
            accountNumber: [
              {
                validate: (accountNumber: string) =>
                  !accountNumber ||
                  (accountNumber.length == 16 &&
                    /^[0-9]+$/.test(accountNumber)),
                error: 'Invalid account number',
              },
            ],
          }
        : {}),
      ...(account.region === AccountRegions.SOUTH_AFRICA
        ? {
            sortCode: [
              {
                validate: (routingNumber: string) =>
                  !routingNumber || /^[0-9]{6}$/.test(routingNumber),
                error: 'Invalid branch code',
              },
            ],
            accountNumber: [
              {
                validate: (accountNumber: string) =>
                  !accountNumber ||
                  (accountNumber.length <= 11 &&
                    accountNumber.length >= 9 &&
                    /^[0-9]+$/.test(accountNumber)),
                error: 'Invalid account number',
              },
            ],
          }
        : {}),
      ...(account.region === AccountRegions.US
        ? {
            sortCode: [
              {
                validate: (routingNumber: string) =>
                  !routingNumber || /^[0-9]{9}$/.test(routingNumber),
                error: 'Invalid routing number',
              },
            ],
            accountNumber: [
              {
                validate: (accountNumber: string) =>
                  !accountNumber ||
                  (accountNumber.length <= 12 &&
                    accountNumber.length >= 8 &&
                    /^[0-9]+$/.test(accountNumber)),
                error: 'Invalid account number',
              },
            ],
          }
        : {}),
    };
  }, [account]);

  const isInvalid = useMemo(() => {
    if (changesMade.details) {
      const isInvalid = Object.entries(changesMade.details).reduce(
        (acc: any, change: any) => {
          const fieldValidation = get(formValidations, [change[0]]);
          const error = getFieldErrors(change[1], fieldValidation, {
            blockedEmails,
          });
          if (error.length) {
            acc = true;
          }
          return acc;
        },
        false
      );
      return isInvalid;
    }
    return false;
  }, [changesMade, formValidations]);

  const discardChanges = useCallback(() => {
    dispatch(
      accountSettingsActions.storeAccountPendingChanges(
        parseAccount(currentAccount)
      )
    );
    dispatch(
      accountSettingsActions.storeAccountPendingBusinessAttachments(
        currentBusinessAttachments
      )
    );
    dispatch(accountSettingsActions.storeAccountPendingTerms(currentTerms));

    if (currentEmailTemplates) {
      dispatch(
        accountSettingsActions.storeAccountPendingEmailTemplates({
          sendEstimate: currentEmailTemplates.sendEstimate,
          sendQuote: currentEmailTemplates.sendQuote,
          sendInvoice: currentEmailTemplates.sendInvoice,
        })
      );
    }
  }, [
    currentAccount,
    currentBusinessAttachments,
    currentTerms,
    currentEmailTemplates,
    dispatch,
  ]);

  return (
    <Fragment>
      <Button
        isProcessing={isSaving}
        isDisabled={!Object.keys(changesMade).length || isInvalid}
        onClick={() => onSave()}
        styleVariant={ButtonStyleVariant.OUTSIZE}
      >
        {buttonTitle || 'Save changes'}
      </Button>
      <Button
        styleVariant={ButtonStyleVariant.ANCHOR}
        onClick={discardChanges}
        isDisabled={!Object.keys(changesMade).length}
      >
        Discard changes
      </Button>
      <Prompt
        when={!isModal && !!Object.keys(changesMade).length && !isSaving}
        message="There are unsaved changes on the page, are you sure you want to leave?"
      />
    </Fragment>
  );
};

export default CompanySettingsSaveButton;
