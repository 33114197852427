import React, { FC, PropsWithChildren } from 'react';

export interface IProps {
  condition?: boolean;
  fallback?: React.ReactNode;
}

/*
 * Conditional component
 * Will render its children if the condition is true
 */
const Conditional: FC<PropsWithChildren<IProps>> = (props) => {
  const { condition, fallback, children } = props;

  if (!condition) return fallback || null;

  return <>{children}</>;
};

export default Conditional;
