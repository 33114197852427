import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Folder, Upload } from 'react-iconly';

import FieldLabel from '../fieldLabel/FieldLabel';
import TooltipUI from '@material-ui/core/Tooltip';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import './FileUploadField.sass';

type Props = {
  name: string;
  value?: FileList;
  label?: string;
  description?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  allowMultipleUploads?: boolean;
  acceptFileTypes?: string[];
  onChange?: (value: { [key: string]: FileList | null }) => void;
  onTouch?: (fieldName: string) => void;
  enableDragAndDrop?: boolean;
  uploadTriggerIcon?: IconDefinition;
};

const FileUploadField: FunctionComponent<React.PropsWithChildren<Props>> = ({
  name,
  value,
  label,
  description,
  isDisabled = false,
  isRequired = false,
  acceptFileTypes,
  allowMultipleUploads = false,
  onChange,
  onTouch,
  enableDragAndDrop = false,
  uploadTriggerIcon,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const inputElementRef = useRef<HTMLInputElement>(null);

  const onUploadTriggerClick = useCallback(() => {
    inputElementRef.current?.click();
  }, [inputElementRef]);

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange &&
        onChange({
          [event.target.name]: event.target.files,
        });
      onTouch && onTouch(event.target.name);
      // Allow uploading of the same file
      if (event.target && event.target.value) {
        event.target.value = '';
      }
    },
    [onChange, onTouch]
  );

  const fileListElement = useMemo(() => {
    if (!value) return;
    const fileArray = Array.prototype.slice.call(value);

    return (
      <ul>
        {fileArray.map((file: File, index: number) => {
          return <li key={index}>{file.name}</li>;
        })}
      </ul>
    );
  }, [value]);

  const onDrop = useCallback(
    (files: File[]) => {
      const filesToUpload: any = files.slice(
        0,
        allowMultipleUploads ? files.length : 1
      );
      onChange &&
        onChange({
          [name]: filesToUpload,
        });
      onTouch && onTouch(name);
    },
    [allowMultipleUploads, onChange, onTouch]
  );

  const acceptedFiles = useMemo(
    () => acceptFileTypes?.join(','),
    [acceptFileTypes]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: isDisabled,
    onDrop,
    accept: acceptedFiles,
  });

  return (
    <div className={`file-upload-field`}>
      <FieldLabel
        label={label}
        isRequired={isRequired}
        description={description}
      />
      <input
        ref={inputElementRef}
        type="file"
        style={{ display: 'none' }}
        onChange={onInputChange}
        multiple={allowMultipleUploads}
        name={name}
        accept={acceptedFiles}
        disabled={isDisabled}
        {...(enableDragAndDrop ? getInputProps() : undefined)}
      />
      <TooltipUI
        title={
          acceptedFiles?.length ? (
            <small>
              Accepted file types: {acceptedFiles?.replaceAll(',', ', ')}
            </small>
          ) : (
            ''
          )
        }
        arrow
        placement="bottom"
        PopperProps={{
          className: `MuiTooltip-popper MuiTooltip-popperArrow`,
        }}
      >
        <div className={`file-block`}>
          <div
            className={`file-upload-trigger main-block ${
              isDragActive ? 'dragging' : ''
            }${isDisabled ? 'disabled' : ''}`}
            onClick={!isDisabled ? onUploadTriggerClick : undefined}
            {...(enableDragAndDrop ? getRootProps() : undefined)}
          >
            <FontAwesomeIcon icon={uploadTriggerIcon || faPlus} />
          </div>
        </div>
      </TooltipUI>
      <div className="file-list-container">{fileListElement}</div>
    </div>
  );
};

export default FileUploadField;
