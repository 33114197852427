import React, { FC, ComponentPropsWithoutRef, useMemo } from 'react';

import building013 from './icons/building-01.3.svg?react';
import coinsStacked013 from './icons/coins-stacked-01.3.svg?react';
import user01 from './icons/user-01.svg?react';
import user03 from './icons/user-03.svg?react';
import userX01 from './icons/user-x-01.svg?react';
import userCircle3 from './icons/user-circle.3.svg?react';
import arrowUp from './icons/arrow-up.svg?react';
import arrowLeft from './icons/arrow-left.svg?react';
import arrowDown from './icons/arrow-down.svg?react';
import mail053 from './icons/mail-05.3.svg?react';
import dataflow03 from './icons/dataflow-03.svg?react';
import notificationMessage from './icons/notification-message.svg?react';
import xClose from './icons/x-close.svg?react';
import chevronUp from './icons/chevron-up.svg?react';
import chevronDown from './icons/chevron-down.svg?react';
import chevronLeft from './icons/chevron-left.svg?react';
import chevronRight from './icons/chevron-right.svg?react';
import calendar from './icons/calendar.svg?react';
import list from './icons/list.svg?react';
import checkCircle3 from './icons/check-circle.3.svg?react';
import fileCheck013 from './icons/file-check-01.3.svg?react';
import server013 from './icons/server-01.3.svg?react';
import minusSquare3 from './icons/minus-square.3.svg?react';
import plusSquare3 from './icons/plus-square.3.svg?react';
import plus3 from './icons/plus.3.svg?react';
import fileAttachment033 from './icons/file-attachment-03.3.svg?react';
import file023 from './icons/file-02.3.svg?react';
import download013 from './icons/download-01.3.svg?react';
import file063 from './icons/file-06.3.svg?react';
import currencyDollar from './icons/currency-dollar.svg?react';
import currencyPound from './icons/currency-pound.svg?react';
import copy073 from './icons/copy-07.3.svg?react';
import check from './icons/check.svg?react';
import columns033 from './icons/columns-03.3.svg?react';
import searchRefraction3 from './icons/search-refraction.3.svg?react';
import settings013 from './icons/settings-01.3.svg?react';
import searchLg3 from './icons/search-lg.3.svg?react';
import loading023 from './icons/loading-02.3.svg?react';
import mail023 from './icons/mail-02.3.svg?react';
import mail043 from './icons/mail-04.3.svg?react';
import tag013 from './icons/tag-01.3.svg?react';
import loading013 from './icons/loading-01.3.svg?react';
import filterLines3 from './icons/filter-lines.3.svg?react';
import filePlus033 from './icons/file-plus-03.3.svg?react';
import dotsHorizontal from './icons/dots-horizontal.3.svg?react';
import image013 from './icons/image-01.3.svg?react';
import videoRecorder3 from './icons/video-recorder.3.svg?react';
import home023 from './icons/home-02.3.svg?react';
import lightbulb053 from './icons/lightbulb-05.3.svg?react';
import repeat043 from './icons/repeat-04.3.svg?react';
import users013 from './icons/users-01.3.svg?react';
import calendarCheck023 from './icons/calendar-check-02.3.svg?react';
import list3 from './icons/list.3.svg?react';
import clockStopwatch3 from './icons/clock-stopwatch.3.svg?react';
import shoppingCart023 from './icons/shopping-cart-02.3.svg?react';
import fileCheck023 from './icons/file-check-02.3.svg?react';
import truck013 from './icons/truck-01.3.svg?react';
import dataflow033 from './icons/dataflow-03.3.svg?react';
import lineChartUp033 from './icons/line-chart-up-03.3.svg?react';
import stars033 from './icons/stars-03.3.svg?react';
import logOut033 from './icons/log-out-03.3.svg?react';
import arrowRight from './icons/arrow-right.svg?react';
import infoCircle3 from './icons/info-circle.3.svg?react';
import bellRinging013 from './icons/bell-ringing-01.3.svg?react';
import key013 from './icons/key-01.3.svg?react';
import phoneOutgoing023 from './icons/phone-outgoing-02.3.svg?react';
import alertTriangle3 from './icons/alert-triangle.3.svg?react';
import bold013 from './icons/bold-01.3.svg?react';
import italic013 from './icons/italic-01.3.svg?react';
import underline013 from './icons/underline-01.3.svg?react';
import strikethrough013 from './icons/strikethrough-01.3.svg?react';
import listOrdered from './icons/list-ordered.svg?react';
import dotpoints013 from './icons/dotpoints-01.3.svg?react';
import flipForward3 from './icons/flip-forward.3.svg?react';
import flipBackward3 from './icons/flip-backward.3.svg?react';
import link013 from './icons/link-01.3.svg?react';
import typeStrikethrough013 from './icons/type-strikethrough-01.3.svg?react';
import eye3 from './icons/eye.3.svg?react';
import fileX013 from './icons/file-x-01.3.svg?react';
import linkBroken013 from './icons/link-broken-01.3.svg?react';
import edit013 from './icons/edit-01.3.svg?react';
import plusCircle3 from './icons/plus-circle.3.svg?react';
import userSquare3 from './icons/user-square.3.svg?react';
import checkDone023 from './icons/check-done-02.3.svg?react';
import dotsVertical3 from './icons/dots-vertical.3.svg?react';
import pencilLine3 from './icons/pencil-line.3.svg?react';
import calendarPlus013 from './icons/calendar-plus-01.3.svg?react';
import star013 from './icons/star-01.3.svg?react';
import sliders013 from './icons/sliders-01.3.svg?react';
import helpCircle3 from './icons/help-circle.3.svg?react';
import announcement033 from './icons/announcement-03.3.svg?react';
import bell013 from './icons/bell-01.3.svg?react';
import checkSquareBroken1 from './icons/check-square-broken.1.svg?react';
import xCircle3 from './icons/x-circle.3.svg?react';
import infoOctagon3 from './icons/info-octagon.3.svg?react';
import refreshCw01 from './icons/refresh-cw-01.svg?react';
import dataflow043 from './icons/dataflow-04.3.svg?react';
import phone3 from './icons/phone.3.svg?react';
import star063 from './icons/star-06.3.svg?react';
import minus3 from './icons/minus.3.svg?react';
import menu013 from './icons/menu-01.3.svg?react';
import arrowBlockLeft3 from './icons/arrow-block-left.3.svg?react';
import grid013 from './icons/grid-01.3.svg?react';
import upload013 from './icons/upload-01.3.svg?react';
import percent023 from './icons/percent-02.3.svg?react';
import snowflake013 from './icons/snowflake-01.3.svg?react';
import link043 from './icons/link-04.3.svg?react';

const icons = {
  'building-01.3': building013,
  'coins-stacked-01.3': coinsStacked013,
  'user-01': user01,
  'user-03': user03,
  'user-x-01': userX01,
  'user-circle.3': userCircle3,
  'arrow-up': arrowUp,
  'arrow-left': arrowLeft,
  'arrow-down': arrowDown,
  'arrow-right': arrowRight,
  'mail-05.3': mail053,
  'dataflow-03': dataflow03,
  'notification-message': notificationMessage,
  'x-close': xClose,
  'chevron-up': chevronUp,
  'chevron-down': chevronDown,
  'chevron-left': chevronLeft,
  'chevron-right': chevronRight,
  calendar,
  list,
  'check-circle.3': checkCircle3,
  'file-check-01.3': fileCheck013,
  'server-01.3': server013,
  'minus-square.3': minusSquare3,
  'plus-square.3': plusSquare3,
  'plus.3': plus3,
  'file-attachment-03.3': fileAttachment033,
  'file-02.3': file023,
  'download-01.3': download013,
  'file-06.3': file063,
  'currency-dollar': currencyDollar,
  'currency-pound': currencyPound,
  'copy-07.3': copy073,
  check,
  'columns-03.3': columns033,
  'search-refraction.3': searchRefraction3,
  'settings-01.3': settings013,
  'search-lg.3': searchLg3,
  'loading-02.3': loading023,
  'mail-02.3': mail023,
  'mail-04.3': mail043,
  'tag-01.3': tag013,
  'loading-01.3': loading013,
  'filter-lines.3': filterLines3,
  'file-plus-03.3': filePlus033,
  'dots-horizontal.3': dotsHorizontal,
  'image-01.3': image013,
  'video-recorder.3': videoRecorder3,
  'home-02.3': home023,
  'lightbulb-05.3': lightbulb053,
  'repeat-04.3': repeat043,
  'users-01.3': users013,
  'calendar-check-02.3': calendarCheck023,
  'list.3': list3,
  'clock-stopwatch.3': clockStopwatch3,
  'shopping-cart-02.3': shoppingCart023,
  'file-check-02.3': fileCheck023,
  'truck-01.3': truck013,
  'dataflow-03.3': dataflow033,
  'line-chart-up-03.3': lineChartUp033,
  'stars-03.3': stars033,
  'log-out-03.3': logOut033,
  'info-circle.3': infoCircle3,
  'bell-ringing-01.3': bellRinging013,
  'key-01.3': key013,
  'phone-outgoing-02.3': phoneOutgoing023,
  'alert-triangle.3': alertTriangle3,
  'bold-01.3': bold013,
  'italic-01.3': italic013,
  'underline-01.3': underline013,
  'strikethrough-01.3': strikethrough013,
  'list-ordered': listOrdered,
  'dotpoints-01.3': dotpoints013,
  'flip-forward.3': flipForward3,
  'flip-backward.3': flipBackward3,
  'link-01.3': link013,
  'type-strikethrough-01.3': typeStrikethrough013,
  'link-broken-01.3': linkBroken013,
  'eye.3': eye3,
  'file-x-01.3': fileX013,
  'edit-01.3': edit013,
  'plus-circle.3': plusCircle3,
  'user-square.3': userSquare3,
  'check-done-02.3': checkDone023,
  'dots-vertical.3': dotsVertical3,
  'pencil-line.3': pencilLine3,
  'calendar-plus-01.3': calendarPlus013,
  'star-01.3': star013,
  'sliders-01.3': sliders013,
  'helpCircle.3': helpCircle3,
  'announcement-03.3': announcement033,
  'bell-01.3': bell013,
  'check-square-broken.1': checkSquareBroken1,
  'x-circle.3': xCircle3,
  'info-octagon.3': infoOctagon3,
  'refresh-cw-01': refreshCw01,
  'dataflow-04.3': dataflow043,
  'phone.3': phone3,
  'star-06.3': star063,
  'minus.3': minus3,
  'menu-01.3': menu013,
  'arrow-block-left.3': arrowBlockLeft3,
  'upload-01.3': upload013,
  'grid-01.3': grid013,
  'percent-02.3': percent023,
  'snowflake-01.3': snowflake013,
  'link-04.3': link043,
} as const;

export type TIconName = keyof typeof icons;

interface IProps extends ComponentPropsWithoutRef<'svg'> {
  name: TIconName;
}

const UntitledIcon: FC<IProps> = (props) => {
  const { name, className, vectorEffect, ...svgProps } = props;

  const ImportedIcon = useMemo(() => icons[name], [name]);

  return (
    <ImportedIcon
      {...svgProps}
      className={`${
        vectorEffect === 'non-scaling-stroke' ? 'non-scaling-stroke' : ''
      } ${className}`}
    />
  );
};

export default UntitledIcon;
