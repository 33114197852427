import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import * as dashboardActions from '@payaca/store/dashboard/dashboardActions';
import DashboardActionRequiredCarousel from '../dashboardActionRequiredCarousel/DashboardActionRequiredCarousel';
import DashboardJobActionRequiredItem from '../dashboardJobActionRequiredItem/DashboardJobActionRequiredItem';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { useSelector } from '../../../api/state';

const ActionRequiredPendingPaymentsCarousel: FC = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    actionRequiredPendingPayments,
    isGettingActionRequiredPendingPayments,
  } = useSelector((state) => state.dashboard);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  useEffect(() => {
    dispatch(dashboardActions.requestGetActionRequiredPendingPayments());
  }, [dispatch]);

  const actionRequiredItems = useMemo(() => {
    return actionRequiredPendingPayments?.map(
      (actionRequiredPendingPayment: any, index: number) => {
        return (
          <DashboardJobActionRequiredItem
            key={index}
            onClick={() =>
              history.push(`deals/${actionRequiredPendingPayment.dealId}`)
            }
            reference={`Project ${actionRequiredPendingPayment.dealReference}`}
            value={currencyPrice(
              actionRequiredPendingPayment.paymentValue,
              account.region,
              false
            )}
            customerName={actionRequiredPendingPayment.customerName}
          />
        );
      }
    );
  }, [actionRequiredPendingPayments, history, account]);

  const title = useMemo(() => {
    return `Payment confirmation needed${
      isGettingActionRequiredPendingPayments
        ? ''
        : ` (${
            actionRequiredItems?.length === 20
              ? '20+'
              : actionRequiredItems?.length || 0
          })`
    }`;
  }, [actionRequiredItems, isGettingActionRequiredPendingPayments]);

  return (
    <div className="action-required-pending-payments-carousel">
      <DashboardActionRequiredCarousel
        actionRequiredItems={
          isGettingActionRequiredPendingPayments
            ? undefined
            : actionRequiredItems
        }
        title={title}
        description="Your customer says they've transferred you money - confirm whether you've received it"
        isLoading={isGettingActionRequiredPendingPayments}
      />
    </div>
  );
};

export default ActionRequiredPendingPaymentsCarousel;
