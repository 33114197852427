// ======================================================================================================
// Industry register form
// ======================================================================================================

import React, { FC, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import BasicField from '@payaca/components/basicField/BasicField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';

import Button from '@payaca/components/button/Button';
import { ButtonColourVariant, ButtonStyleVariant } from '@payaca/components/button/enums';
import {
  getIsRequiredFieldValidator,
  getIsRequiredIfTrueConditionValidator,
} from '@payaca/helpers/fieldValidationHelper';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { IndustryTypes } from '@payaca/types/companyInformationTypes';

import {
  INDUSTRY_OPTIONS,
  NUMBER_OF_EMPLOYEES_OPTIONS,
} from '@payaca/constants/companySetupConstants';
import { useSelector } from '@/api/state';

interface Props {
  onIndustryInfoChanged: (companyInfo: {
    [key: string]: string | undefined;
  }) => void;
  onNextButton: () => void;
}
const IndustryRegisterForm: FC<Props> = ({
  onIndustryInfoChanged,
  onNextButton,
}: Props): JSX.Element | null => {
  const location = useLocation();
  const [triggerValidationAt, setTriggerValidationAt] = useState<Date>();

  const myProfile = useSelector((state: any) => state.users.myProfile);

  const initialFormState = useMemo(() => {
    if (!myProfile) {
      return {};
    }
    return {
      numberOfEmployees: null,
      industryType: null,
      industryTypeOther: null,
    };
  }, [myProfile, location]);

  const fieldValidators = useMemo(() => {
    const isRequiredFieldValidator = getIsRequiredFieldValidator();

    return {
      numberOfEmployees: [isRequiredFieldValidator],
      industryType: [isRequiredFieldValidator],
      industryTypeOther: [
        getIsRequiredIfTrueConditionValidator(
          (formState: any) => formState.industryType === IndustryTypes.OTHER
        ),
      ],
    };
  }, []);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          {/* Number of employees */}
          <ValidatedFieldWrapper
            validationResult={validationState.numberOfEmployees}
            isTouched={touchedState.numberOfEmployees || false}
          >
            <DropdownField
              label="Number of employees"
              isRequired={true}
              name="numberOfEmployees"
              onChange={(change: any) => {
                onFieldChange(change);
                onIndustryInfoChanged(change);
              }}
              options={NUMBER_OF_EMPLOYEES_OPTIONS}
              styleVariant={InputStyleVariant.OUTSIZE}
              value={formState.numberOfEmployees}
            />
          </ValidatedFieldWrapper>

          {/* Industry */}
          <ValidatedFieldWrapper
            validationResult={validationState.industryType}
            isTouched={touchedState.industryType || false}
          >
            <DropdownField
              label="Industry"
              isRequired={true}
              name="industryType"
              onChange={(change: any) => {
                const update = { ...change };
                // clear industryTypeOther if picking predefind industry type
                if (change.industryType !== IndustryTypes.OTHER) {
                  update.industryTypeOther = null;
                }
                onFieldChange(update);
                onIndustryInfoChanged(update);
              }}
              options={INDUSTRY_OPTIONS}
              styleVariant={InputStyleVariant.OUTSIZE}
              value={formState.industryType}
            />
          </ValidatedFieldWrapper>
          {formState.industryType === IndustryTypes.OTHER && (
            <ValidatedFieldWrapper
              validationResult={validationState.industryTypeOther}
              isTouched={touchedState.industryTypeOther || false}
            >
              <BasicField
                name="industryTypeOther"
                isRequired={true}
                onChange={(change: any) => {
                  onFieldChange(change);
                  onIndustryInfoChanged(change);
                }}
                styleVariant={InputStyleVariant.OUTSIZE}
                value={formState.industryTypeOther}
                additionalInputProps={{ placeholder: 'Enter industry...' }}
              />
            </ValidatedFieldWrapper>
          )}
          <div className="button-wrapper">
            <Button
              onClickDisabled={() => setTriggerValidationAt(new Date())}
              onClick={onNextButton}
              styleVariant={ButtonStyleVariant.OUTSIZE}
              colourVariant={ButtonColourVariant.PRIMARY}
              isDisabled={!isValid}
            >
              Next
            </Button>
          </div>
        </>
      );
    },
    [onIndustryInfoChanged, onNextButton]
  );

  return (
    <div className="industry-register-form">
      <ValidatedForm<{ [key: string]: any }>
        triggerValidationAt={triggerValidationAt}
        fieldValidators={fieldValidators}
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
      />
    </div>
  );
};

export default IndustryRegisterForm;
