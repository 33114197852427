import get from 'lodash.get';
import includes from 'lodash.includes';
import validator from 'validator';
import moment from 'moment-timezone';

import {
  EMPTY_STRING_REGEX,
  VALID_EMAIL_REGEX,
  VALID_PHONE_NUMBER_REGEX_STRICT,
} from '@payaca/constants/regexConstants';
import {
  FieldValidator,
  FieldValidationOptions,
} from '@payaca/types/fieldValidationTypes';
import { getAddressAsString } from './locationHelper';

const hasValue = (value: any) => {
  if (value === null || value === undefined || value === '') {
    return false;
  }
  if (EMPTY_STRING_REGEX.test(value)) {
    return false;
  }
  return true;
};

export const getAllowEmptyValidator = (fieldValidator: FieldValidator) => {
  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName);

    if (!hasValue(value)) {
      return {
        isValid: true,
      };
    }

    return fieldValidator(fieldName, formState);
  };
};

export const getIsRequiredFieldValidator = (
  options?: FieldValidationOptions
): FieldValidator => {
  const errorMessage =
    options?.customErrorMessage ||
    `${
      options?.readableName ? options?.readableName : 'This'
    } is a required field`;

  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName);

    if (!hasValue(value)) {
      return {
        isValid: false,
        errors: [errorMessage],
      };
    }

    return {
      isValid: true,
    };
  };
};

export const getLengthFieldValidator = (
  {
    min = 0,
    max = 10000,
  }: {
    min?: number;
    max?: number;
  },
  options?: FieldValidationOptions
): FieldValidator => {
  const errorMessage =
    options?.customErrorMessage ||
    `${
      options?.readableName ? options?.readableName : 'This'
    } must be between ${min} and ${max} characters`;

  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName) || '';

    if (typeof value !== 'string') {
      return {
        isValid: false,
        errors: ['Please enter a valid value for this field'],
      };
    }

    if (value.length < min || value.length > max) {
      return {
        isValid: false,
        errors: [errorMessage],
      };
    }

    return {
      isValid: true,
    };
  };
};

export const getRegexMatchFieldValidator = <
  T extends Record<string, any> = Record<string, any>,
>(
  regex: RegExp,
  options?: FieldValidationOptions
): FieldValidator<T> => {
  const errorMessage =
    options?.customErrorMessage ||
    `${
      options?.readableName ? options?.readableName : 'This field'
    } must contain valid input`;

  return (fieldName: string, formState: T) => {
    const value = get(formState, fieldName);
    const regexMatch = regex.test(value);

    if (!regexMatch) {
      return {
        isValid: false,
        errors: [errorMessage],
      };
    }

    return {
      isValid: true,
    };
  };
};

export const getEmailFieldValidator = (
  options?: FieldValidationOptions
): FieldValidator => {
  const regex = VALID_EMAIL_REGEX;

  return getRegexMatchFieldValidator(regex, options);
};

export const getMultipleEmailsFieldValidator = (
  options?: FieldValidationOptions
): FieldValidator => {
  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName);
    const splitEmails = value.split(',');

    const invalidEmails = splitEmails.filter((email: string) => {
      const regex = VALID_EMAIL_REGEX;
      const regexMatch = regex.test(email.trim());
      return !regexMatch;
    });

    if (invalidEmails?.length) {
      return {
        isValid: false,
        errors: ['This field must contain valid input'],
      };
    }
    return { isValid: true };
  };
};

export const getPhoneNumberFieldValidator = (
  options?: FieldValidationOptions
): FieldValidator => {
  const regex = VALID_PHONE_NUMBER_REGEX_STRICT;

  return getRegexMatchFieldValidator(regex, options);
};

export const getArrayMustNotBeEmptyFieldValidator = (
  options?: FieldValidationOptions
): FieldValidator => {
  const errorMessage =
    options?.customErrorMessage ||
    `You must provide at least one ${
      options?.readableName ? options?.readableName : 'item'
    }`;

  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName);

    if (!(value instanceof Array) || value.length <= 0) {
      return {
        isValid: false,
        errors: [errorMessage],
      };
    }

    return {
      isValid: true,
    };
  };
};

export const getIsRequiredIfTrueConditionValidator = (
  executeCondition: (formState: { [fieldname: string]: any }) => boolean,
  options?: FieldValidationOptions
): FieldValidator => {
  const isRequiredFieldValidator = getIsRequiredFieldValidator(options);

  return (fieldName: string, formState: { [key: string]: any }) => {
    const conditionResult = executeCondition(formState);

    if (conditionResult) {
      return isRequiredFieldValidator(fieldName, formState);
    }

    return {
      isValid: true,
    };
  };
};

export const getConditionalValidator = <
  T extends Record<string, any> = Record<string, any>,
>(
  executeCondition: (formState: T) => boolean,
  fieldValidator: FieldValidator<T>
) => {
  return (fieldName: string, formState: T) => {
    const conditionResult = executeCondition(formState);

    if (conditionResult) {
      return fieldValidator(fieldName, formState);
    }

    return {
      isValid: true,
    };
  };
};

export const getMustEqualValueValidator = (
  mustEqualValue: any,
  options?: FieldValidationOptions
) => {
  const errorMessage =
    options?.customErrorMessage ||
    `The value of ${
      options?.readableName ? options?.readableName : 'this field'
    } must be ${
      mustEqualValue?.toString ? mustEqualValue.toString() : mustEqualValue
    }`;

  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName);

    if (value !== mustEqualValue) {
      return {
        isValid: false,
        errors: [errorMessage],
      };
    }

    return {
      isValid: true,
    };
  };
};

export const getNumericalRangeFieldValidator = (
  minValue?: number,
  maxValue?: number,
  readableName?: string,
  minValueExceededCustomErrorMessage?: string,
  maxValueExceededCustomErrorMessage?: string
): FieldValidator => {
  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName);
    if (minValue !== undefined && value <= minValue) {
      return {
        isValid: false,
        errors: [
          minValueExceededCustomErrorMessage ||
            `${
              readableName ? readableName : 'This field'
            } must be greater than ${minValue}`,
        ],
      };
    } else if (maxValue !== undefined && value >= maxValue) {
      return {
        isValid: false,
        errors: [
          maxValueExceededCustomErrorMessage ||
            `${
              readableName ? readableName : 'This field'
            } must be less than ${maxValue}`,
        ],
      };
    }

    return {
      isValid: true,
    };
  };
};

export const getDateRangeFieldValidator = (
  minValue?: Date,
  maxValue?: Date,
  readableName?: string,
  errorMessageMomentFormat = 'DD MMMM yyyy',
  minValueExceededCustomErrorMessage?: string,
  maxValueExceededCustomErrorMessage?: string
): FieldValidator => {
  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName);
    if (minValue !== undefined && value <= minValue) {
      return {
        isValid: false,
        errors: [
          minValueExceededCustomErrorMessage ||
            `${
              readableName ? readableName : 'This field'
            } must be later than ${moment(minValue).format(
              errorMessageMomentFormat
            )}`,
        ],
      };
    } else if (maxValue !== undefined && value >= maxValue) {
      return {
        isValid: false,
        errors: [
          maxValueExceededCustomErrorMessage ||
            `${
              readableName ? readableName : 'This field'
            } must be earlier than ${moment(maxValue).format(
              errorMessageMomentFormat
            )}`,
        ],
      };
    }

    return {
      isValid: true,
    };
  };
};

type ElementComparisonOptions = {
  caseSensitive: boolean;
};

export const getValueMustNotExistInArrayFieldValidator = (
  disallowedValueArray: any[],
  options?: FieldValidationOptions,
  elementComparisonOptions: ElementComparisonOptions = { caseSensitive: true }
) => {
  const errorMessage =
    options?.customErrorMessage ||
    `The provided value of ${
      options?.readableName ? options?.readableName : 'this field'
    } is not allowed`;

  const dva = elementComparisonOptions.caseSensitive
    ? disallowedValueArray
    : disallowedValueArray.map((value) => value.toLowerCase());

  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName);

    const v = elementComparisonOptions.caseSensitive
      ? value
      : value.toLowerCase();

    if (dva.includes(v)) {
      return {
        isValid: false,
        errors: [errorMessage],
      };
    }

    return {
      isValid: true,
    };
  };
};

export const getValueMustExistInArrayFieldValidator = (
  valueArray: any[],
  options?: FieldValidationOptions
) => {
  const errorMessage =
    options?.customErrorMessage ||
    `The provided value of ${
      options?.readableName ? options?.readableName : 'this field'
    } is not allowed`;

  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName);

    if (!includes(valueArray, value)) {
      return {
        isValid: false,
        errors: [errorMessage],
      };
    }

    return {
      isValid: true,
    };
  };
};

export const getFieldValuesMustMatchValidator = (
  fieldNameToMatch: string,
  options?: FieldValidationOptions
): FieldValidator => {
  const errorMessage =
    options?.customErrorMessage ||
    `${
      options?.readableName ? options?.readableName : 'This'
    } must match the value of ${fieldNameToMatch}`;

  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName);
    const valueToMatch = get(formState, fieldNameToMatch);

    if (value !== valueToMatch) {
      return {
        isValid: false,
        errors: [errorMessage],
      };
    }

    return {
      isValid: true,
    };
  };
};

export const getMustBeTruthyValidator = () => {
  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName);

    return {
      isValid: !!value,
    };
  };
};

export const getAddressMustNotBeEmptyValidator = (
  options?: FieldValidationOptions
) => {
  const errorMessage =
    options?.customErrorMessage ||
    `${
      options?.readableName ? options?.readableName : 'This'
    } must not be empty`;

  return (fieldName: string, formState: { [key: string]: any }) => {
    const address = get(formState, fieldName);

    const addressAsString = getAddressAsString(address);

    if (hasValue(addressAsString)) {
      return {
        isValid: true,
      };
    }

    return {
      errors: [errorMessage],
      isValid: false,
    };
  };
};

export const getMustBeUrlValidator = (options?: FieldValidationOptions) => {
  const errorMessage =
    options?.customErrorMessage ||
    `${
      options?.readableName ? options?.readableName : 'This field'
    } must contain valid input`;
  return (fieldName: string, formState: { [key: string]: any }) => {
    const value = get(formState, fieldName);
    const isValidUrl =
      !!value &&
      !!(value.startsWith('http://') || value.startsWith('https://')) &&
      validator.isURL(value);
    if (!isValidUrl) {
      return {
        isValid: false,
        errors: [errorMessage],
      };
    }
    return {
      isValid: true,
    };
  };
};
