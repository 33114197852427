import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import { clstx } from '../utils';

export type ChartTooltipKey = { label: string; value: string; colour: string };

interface IProps extends ComponentPropsWithoutRef<'div'> {
  title: string;
  keys: ChartTooltipKey[];
  removeWrapperStyles?: boolean;
}

const ChartTooltip = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const {
    title,
    keys,
    className,
    removeWrapperStyles = false,
    ...rest
  } = props;

  return (
    <div
      className={clstx(
        'prose bg-white text-sm text-blue-900',
        !removeWrapperStyles &&
          'overflow-hidden rounded-lg border border-gray-200 shadow',
        className
      )}
      ref={ref}
      {...rest}
    >
      <div className="bg-gray-50 px-3.5 py-2 font-semibold">{title}</div>

      <table className="px-3.5 py-2">
        <tbody>
          {keys.map((key) => (
            <tr key={key.label}>
              <td className="pr-2">
                <div
                  className="h-4 w-4 rounded-full"
                  style={{ backgroundColor: key.colour }}
                />
              </td>
              <td className="pr-2">{key.label}</td>
              <td className="text-right">{key.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default ChartTooltip;
