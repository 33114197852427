export enum RegionalStrings {
  CURRENCY_SYMBOL = 'regional.currencySymbol',
  VALUE_ADDED_TAX = 'regional.vat',
  VALUE_ADDED_TAX_NUMBER = 'regional.vatNumber',
  VALUE_ADDED_TAX_PERCENTAGE = 'regional.vatPercentage',
  VALUE_ADDED_TAX_CSV_HELPER = 'regional.vatCsvHelper',
  VALUE_ADDED_TAX_CSV_HELPER_NO_RC = 'regional.vatCsvHelperNoRc',
  SORT_CODE = 'regional.sortCode',
  POSTCODE = 'regional.postcode',
  EMAIL_CUSTOMISATION = 'regional.emailCustomisation',
}
