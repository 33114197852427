import { BaseElement } from 'slate';

export type TFormats =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strikethrough'
  | 'code';

export type TElementTypes = 'variable' | 'paragraph';

export type RichTextEditorVariable<T = string> = {
  name: T;
  displayName: string;
};

export type RichTextEditorVariables<T = string> = RichTextEditorVariable<T>[];

export enum ServicePlanPeriodReminderTemplateVariables {
  ACCOUNT_NAME = 'account.companyName',
  CUSTOMER_NAME = 'customer.primaryContact.name',
  SERVICE_PLAN_NAME = 'servicePlan.name',
  SERVICE_PLAN_PERIOD_START = 'servicePlanPeriod.startsAt',
  SERVICE_PLAN_PERIOD_END = 'servicePlanPeriod.endsAt',
}

export type Element = BaseElement & {
  type?: TElementTypes;
  name?: string;
  displayName?: string;
};

export type CustomElement = Element;

export type CustomText = {
  [K in TFormats]?: true;
} & { text: string };
