import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import './DealCostsMargins.sass';

import { getDeal, getMaterialsList, getRegion } from '@/utils/stateAccessors';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { useSelector } from '@/api/state';
import { clstx } from '@payaca/components/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const formatPercentage = (percentage: number): string =>
  `${parseFloat(percentage.toFixed(1))}%`;

interface Props {
  dealId: number;
  className?: string;
}

export const DealCostsMargins: FC<Props> = ({ dealId, className }: Props) => {
  const region = useSelector(getRegion);

  const deal = useSelector((state) => getDeal(state, dealId));
  const materialsList = useSelector((state) => {
    if (!deal?.materialsListId) return;

    return getMaterialsList(state, deal?.materialsListId);
  });

  const margin = useMemo(() => {
    if (!deal?.proposedValueExcTax || !materialsList) return;

    return deal.proposedValueExcTax - materialsList.predictedValueExcludingTax;
  }, [materialsList, deal]);

  const marginPercentage = useMemo(() => {
    if (!deal?.proposedValueExcTax || !margin) return;

    return (margin / deal?.proposedValueExcTax) * 100;
  }, [margin, deal]);

  if (!materialsList) return null;

  return (
    <ResponsiveViewWrapper
      className={clstx('deal-costs-margins', className)}
      downBreakpointSm={500}
    >
      <strong className="title">
        {materialsList?.isFullyPurchased ? (
          <span className="fully-purchased-panel-title">
            <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
            Purchased (ex tax)
          </span>
        ) : (
          `Forecast (ex tax)`
        )}
      </strong>

      <dl>
        {!!deal?.proposedValueExcTax && (
          <>
            <dt>
              {deal?.proposedValue === deal?.acceptedValue
                ? 'Accepted'
                : 'Proposed'}{' '}
              Project value:
            </dt>
            <dd>{currencyPrice(deal?.proposedValueExcTax, region)}</dd>
          </>
        )}
        <dt>Cost of materials:</dt>
        <dd>
          {currencyPrice(materialsList.predictedValueExcludingTax, region)}
        </dd>
        {!!margin && (
          <>
            <dt>Profit:</dt>
            <dd>{currencyPrice(margin, region)}</dd>
            {marginPercentage && (
              <>
                <dt>Margin(%):</dt>
                <dd>{formatPercentage(marginPercentage)}</dd>
              </>
            )}
          </>
        )}
      </dl>
    </ResponsiveViewWrapper>
  );
};

export default DealCostsMargins;
