import React, { FC, useMemo, useState } from 'react';
import {
  defaultPipelineAutomations,
  DefaultPipelineStages,
  defaultPipelineStages,
  Pipeline,
  PipelineStage,
  PipelineUpdates,
} from '@payaca/types/pipelineTypes';
import { Delete, Lock } from 'react-iconly';
import BasicField from '@payaca/components/basicField/BasicField';
import Tooltip from '@payaca/components/tooltip/Tooltip';
import TypeToSearchField from '@payaca/components/typeToSearchField/TypeToSearchField';
// @ts-ignore
import Board from 'react-trello';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { DeletePipelineModal } from '@/ui/components/listedDealsPipeline/DeletePipelineModal';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import { useSelector } from '@/api/state';
import './EditPipelineControl.sass';

const MAX_PIPELINE_STAGES = 25;

interface Props {
  onEditPipeline: (
    action: PipelineUpdates,
    value: any,
    stageIndex?: number
  ) => void;
  editPipelineData: Omit<Pipeline, 'stages'> & {
    stages: Partial<PipelineStage>[];
  };
  onPipelineChangesSaved: () => void;
  onPipelineChangesDiscarded: () => void;
  onPipelineDeleted: () => void;
}

export const EditPipelineControl: FC<Props> = ({
  onEditPipeline,
  editPipelineData,
  onPipelineChangesSaved,
  onPipelineChangesDiscarded,
  onPipelineDeleted,
}) => {
  const [showDeletePipelineModal, setShowDeletePipelineModal] = useState(false);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const shortDateRegionalFormat = useMemo(
    () =>
      getInternationalMomentDateFormatByRegion(
        DateFormats.SHORT,
        account.region
      ),
    [account]
  );

  const hasReachedPipelineStageLimit = useMemo(
    () => editPipelineData.stages.length >= MAX_PIPELINE_STAGES,
    [editPipelineData]
  );

  const hasInvalidNewLead = useMemo(() => {
    return !!editPipelineData.stages
      .slice(1)
      .find((stage) => stage.title === DefaultPipelineStages.NEW_LEAD);
  }, [editPipelineData]);

  const isPipelineValid = useMemo(() => {
    if (!editPipelineData) return false;

    return (
      editPipelineData.title?.length < 25 &&
      !hasInvalidNewLead &&
      !editPipelineData.stages.find(
        (stage) => stage?.title?.length && stage.title.length > 25
      )
    );
  }, [editPipelineData, hasInvalidNewLead]);

  const editData = useMemo(() => {
    return {
      lanes: editPipelineData?.stages?.map((stage, i) => ({
        id: i,
        title: defaultPipelineStages.includes(stage?.title as any) ? (
          <div className={'pipeline-locked-column-header'}>
            <Lock /> <span className={'column-title'}>{stage?.title}</span>
            <Tooltip
              placement={'bottom'}
              text={
                'You can delete our default columns, but these cannot be edited'
              }
            />
          </div>
        ) : (
          <>
            <TypeToSearchField
              value={stage?.title}
              options={[
                ...defaultPipelineStages.map((label: string) => ({
                  label,
                  value: label,
                })),
                { label: stage?.title, value: stage?.title },
              ]}
              onSearchTermChange={(searchTerm: string) =>
                onEditPipeline(PipelineUpdates.RENAME_COLUMN, searchTerm, i)
              }
              onSelectOption={({ value }: any) =>
                onEditPipeline(PipelineUpdates.RENAME_COLUMN, value, i)
              }
              additionalInputProps={{
                onKeyDown: (e: any) => {
                  if (e.key === 'Enter') {
                    // 'Enter' causes a submission + page crash, we'll prevent that
                    e.stopPropagation();
                  }
                },
              }}
            />
            {(stage?.title?.length || 0) > 25 && (
              <span className={'pipeline-error'}>
                Title must be less than 25 characters
              </span>
            )}
          </>
        ),
        label: (
          <div className={'pipeline-column-options'}>
            {hasReachedPipelineStageLimit ? (
              <div className={'pipeline-add-column disabled'}>
                <Tooltip
                  text={`You can have a maximum of ${MAX_PIPELINE_STAGES} stages`}
                />
                +
              </div>
            ) : (
              <div
                className={'pipeline-add-column'}
                onClick={() =>
                  onEditPipeline(PipelineUpdates.ADD_COLUMN, null, i)
                }
              >
                +
              </div>
            )}
            {i < editPipelineData?.stages.length - 1 && (
              <div className={'pipeline-move-column'}>
                {i === 0 &&
                editPipelineData?.stages[i].title ===
                  DefaultPipelineStages.NEW_LEAD ? (
                  <div className={'disabled'}>
                    <Tooltip
                      text={'"New lead" can only be used as the first column'}
                    >
                      <FontAwesomeIcon
                        icon={faArrowRightArrowLeft}
                        size={'sm'}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      onEditPipeline(PipelineUpdates.MOVE_COLUMN, null, i)
                    }
                  >
                    <FontAwesomeIcon icon={faArrowRightArrowLeft} size={'sm'} />
                  </div>
                )}
              </div>
            )}
            <div
              className={'pipeline-delete-column'}
              onClick={() =>
                onEditPipeline(PipelineUpdates.DELETE_COLUMN, null, i)
              }
            >
              <Delete size={'medium'} />
            </div>
          </div>
        ),
        cards: [
          {
            id: i,
            title: 'A Project will appear here when:',
            description: defaultPipelineAutomations[stage.title || ''] ? (
              <ul>
                {defaultPipelineAutomations[stage.title || ''].triggers.map(
                  (trigger: string, i: number) => (
                    <li key={i}>{trigger}</li>
                  )
                )}
              </ul>
            ) : (
              <>
                <ul>
                  <li>You drag-and-drop it from an earlier column.</li>
                </ul>
                <p>
                  Tip: You cannot skip over any default Payaca columns by
                  dragging-and-dropping.
                </p>
              </>
            ),
          },
          ...(defaultPipelineAutomations[stage?.title || '']
            ? [
                {
                  id: i,
                  title: 'Automated Actions',
                  description: (
                    <ul>
                      {defaultPipelineAutomations[
                        stage?.title || ''
                      ].actions.map((action: string, i: number) => (
                        <li key={i}>{action}</li>
                      ))}
                    </ul>
                  ),
                },
              ]
            : []),
        ],
      })),
    };
  }, [
    editPipelineData,
    onEditPipeline,
    shortDateRegionalFormat,
    hasReachedPipelineStageLimit,
  ]);

  return (
    <div
      className={
        'edit-pipeline-control relative flex h-full grow flex-col gap-2'
      }
    >
      <div
        className={'bg-pc-blue-mid flex items-center justify-between px-4 py-2'}
      >
        <div className={'flex items-center gap-4'}>
          <BasicField
            name={'title'}
            value={editPipelineData.title}
            onChange={({ title }) =>
              onEditPipeline(PipelineUpdates.RENAME_COLUMN, title)
            }
          />{' '}
          {editPipelineData.title.length > 25 && (
            <span className={'text-white'}>
              Title must be less than 25 characters
            </span>
          )}
          {editPipelineData.title.length < 25 && hasInvalidNewLead && (
            <span className={'text-white'}>
              {'"New lead" must be the first column'}
            </span>
          )}
        </div>
        <div className={'flex items-center gap-4'}>
          <Button
            colourVariant={ButtonColourVariant.PRIMARY}
            onClick={onPipelineChangesSaved}
            isDisabled={!isPipelineValid}
          >
            Save
          </Button>
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            colourVariant={ButtonColourVariant.WHITE}
            onClick={onPipelineChangesDiscarded}
          >
            Discard changes
          </Button>
        </div>

        <div>
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            colourVariant={ButtonColourVariant.WHITE}
            onClick={() => setShowDeletePipelineModal(true)}
          >
            Delete pipeline
          </Button>
        </div>
      </div>
      <Board
        data={editData}
        hideCardDeleteIcon={true}
        laneDraggable={false}
        cardDraggable={false}
        laneStyle={{ backgroundColor: '#edf2f7' }}
        className="min-h-[500px]"
        style={{
          paddingTop: 30,
          backgroundColor: 'transparent',
          paddingLeft: '2rem',
          paddingRight: '4rem',
        }}
      />
      <DeletePipelineModal
        isOpen={showDeletePipelineModal}
        selectedPipelineId={editPipelineData.id}
        onClose={() => setShowDeletePipelineModal(false)}
        onPipelineDeleted={() => {
          setShowDeletePipelineModal(false);
          onPipelineDeleted();
        }}
      />
    </div>
  );
};
