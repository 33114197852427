import { action } from 'typesafe-actions';

import { ListedSupplierListViewPage } from '@payaca/types/listedSupplierTypes';
import {
  ActionType,
  GetListedSuppliersRequestData,
} from './listedSuppliersTypes';

export const requestGetListedSuppliersPage = (
  getListedSuppliersRequestData: GetListedSuppliersRequestData,
  callback?: () => void
) => {
  return action(ActionType.REQUEST_GET_LISTED_SUPPLIERS_PAGE, {
    getListedSuppliersRequestData,
    callback,
  });
};

export const clearListedSuppliersPage = () => {
  return action(ActionType.CLEAR_LISTED_SUPPLIERS_PAGE);
};

export const getListedSuppliersPageSuccess = (
  listedSuppliersPage: ListedSupplierListViewPage
) => {
  return action(ActionType.GET_LISTED_SUPPLIERS_PAGE_SUCCESS, {
    listedSuppliersPage,
  });
};

export const getListedSuppliersPageFailure = (error: Error) => {
  return action(
    ActionType.GET_LISTED_SUPPLIERS_PAGE_FAILURE,
    null,
    null,
    error
  );
};
