import React, { FC, Fragment } from 'react';
import { faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ContentPanel from '@payaca/components/contentPanel/ContentPanel';

import { LineItemGroup } from '@payaca/types/lineItemGroupTypes';

import './ListedItemGroupControl.css';

type Props = {
  itemGroup?: LineItemGroup;
  onClick: () => void;
  onArchiveItemGroup?: (itemGroupId: number) => void;
};
const ListedItemGroupControl: FC<Props> = ({
  itemGroup,
  onClick,
}: Props): JSX.Element => {
  return (
    <ContentPanel className="listed-item-group-control">
      <div
        className={`listed-item-group-control-inner${
          itemGroup ? '' : ' add-group'
        }`}
        onClick={onClick}
      >
        {itemGroup ? (
          <Fragment>
            <span className="bold-weight group-name">
              {itemGroup.name || 'Unnamed group'}
            </span>
            <div className="listed-item-group-control-name-description">
              <span className="group-description">{itemGroup.description}</span>
            </div>

            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <span className="bold-weight add-group-name">Add new group</span>
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </Fragment>
        )}
      </div>
    </ContentPanel>
  );
};

export default ListedItemGroupControl;
