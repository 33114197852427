import { createReducer } from '../utils';
import { ActionType, AnyAction, State } from './types';

const initialState: State = {
  isGettingTaxRates: false,
  isCreatingTaxRate: false,
  busyTaxRateIds: [],
  store: {},
};

export default createReducer<State, ActionType, AnyAction>(initialState, {
  [ActionType.GET_TAX_RATES_REQUEST]: (state) => ({
    ...state,
    isGettingTaxRates: true,
  }),
  [ActionType.GET_TAX_RATES_SUCCESS]: (state, action) => ({
    ...state,
    isGettingTaxRates: false,
    store: action.payload.reduce(
      (acc, x) => {
        acc[x.id] = x;
        return acc;
      },
      {} as State['store']
    ),
  }),
  [ActionType.GET_TAX_RATES_FAILURE]: (state) => ({
    ...state,
    isGettingTaxRates: false,
  }),

  [ActionType.CREATE_TAX_RATE_REQUEST]: (state) => ({
    ...state,
    isCreatingTaxRate: true,
  }),
  [ActionType.CREATE_TAX_RATE_SUCCESS]: (state, action) => ({
    ...state,
    isCreatingTaxRate: false,
    store: {
      ...state.store,
      [action.payload.id]: action.payload,
    },
  }),
  [ActionType.CREATE_TAX_RATE_FAILURE]: (state) => ({
    ...state,
    isCreatingTaxRate: false,
  }),

  [ActionType.UPDATE_TAX_RATE_REQUEST]: (state, action) => ({
    ...state,
    busyTaxRateIds: [...(state.busyTaxRateIds ?? []), action.payload.id],
  }),
  [ActionType.UPDATE_TAX_RATE_SUCCESS]: (state, action) => ({
    ...state,
    store: {
      ...state.store,
      [action.payload.id]: action.payload,
    },
    busyTaxRateIds:
      state.busyTaxRateIds?.filter((id) => id !== action.payload.id) ?? [],
  }),
  [ActionType.UPDATE_TAX_RATE_FAILURE]: (state, action) => ({
    ...state,
    busyTaxRateIds:
      state.busyTaxRateIds?.filter((id) => id !== action.payload.id) ?? [],
  }),

  [ActionType.DELETE_TAX_RATE_REQUEST]: (state, action) => ({
    ...state,
    busyTaxRateIds: [...(state.busyTaxRateIds ?? []), action.payload],
  }),
  [ActionType.DELETE_TAX_RATE_SUCCESS]: (state, action) => ({
    ...state,
    store: Object.keys(state.store)
      .map(Number)
      .reduce(
        (acc, x) => {
          if (x !== action.payload) {
            acc[x] = state.store[x];
          }
          return acc;
        },
        {} as State['store']
      ),
    busyTaxRateIds:
      state.busyTaxRateIds?.filter((id) => id !== action.payload) ?? [],
  }),
  [ActionType.DELETE_TAX_RATE_FAILURE]: (state, action) => ({
    ...state,
    busyTaxRateIds:
      state.busyTaxRateIds?.filter((id) => id !== action.payload) ?? [],
  }),
});
