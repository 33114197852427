import React, { FC, Fragment, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer } from '@fortawesome/free-solid-svg-icons';

import { actions as appActions } from '@/api/app';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

import './MaintenanceBanner.sass';
import { useSelector } from '@/api/state';

const MaintenanceBanner: FC = (): JSX.Element | null => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.requestGetMaintenance());
  }, []);

  const maintenanceSchedule = useSelector(
    (state: any) => state.app.maintenanceSchedule
  );

  const hideMaintenanceBanner = useSelector(
    (state: any) => state.app.hideMaintenanceBanner
  );
  const lastReadFutureMaintenaceMessageDownTimeAt = useSelector(
    (state: any) => state.app.lastReadFutureMaintenaceMessageDownTimeAt
  );
  const lastReadPastMaintenanceUpTimeAt = localStorage.getItem(
    'payaca-pastMaintenaceMessageUpTimeAt'
  );

  const hasUnreadPastMessage = useMemo(() => {
    if (!maintenanceSchedule?.past) {
      return false;
    }
    // show past maintenace message if user has not closed banner with it in (stored in local storage)
    return (
      maintenanceSchedule.past.upTimeAt !== lastReadPastMaintenanceUpTimeAt
    );
  }, [lastReadPastMaintenanceUpTimeAt, maintenanceSchedule]);

  const canHideFutureMessage = useMemo(() => {
    if (!maintenanceSchedule?.future) {
      return true;
    }
    // can hide future maintenance message if user has seen it and closed it (saved to store)
    return (
      maintenanceSchedule.future.downTimeAt ===
      lastReadFutureMaintenaceMessageDownTimeAt
    );
  }, [lastReadFutureMaintenaceMessageDownTimeAt, maintenanceSchedule]);

  // user has closed banner, future message has been read and no unread past message
  const hideBanner = useMemo(() => {
    return (
      hideMaintenanceBanner && canHideFutureMessage && !hasUnreadPastMessage
    );
  }, [canHideFutureMessage, hasUnreadPastMessage, hideMaintenanceBanner]);

  // no unread past message and no future message
  if (!hasUnreadPastMessage && !maintenanceSchedule?.future) {
    return null;
  }

  return (
    <div className={`maintenance-banner${hideBanner ? ' hidden' : ''}`}>
      {maintenanceSchedule?.future && (
        <span
          className="hammer-icon-wrapper"
          onClick={() => dispatch(appActions.hideMaintenanceBanner(false))}
        >
          <FontAwesomeIcon icon={faHammer} className="hammer-icon" />
        </span>
      )}
      {!hideBanner && (
        <Fragment>
          <div className="inner-banner-wrapper">
            {hasUnreadPastMessage && (
              <div className="maintenace-message past">
                We made some software updates, click refresh to see whats new.
                <Button
                  className="reload"
                  onClick={() => {
                    localStorage.setItem(
                      'payaca-pastMaintenaceMessageUpTimeAt',
                      maintenanceSchedule.past.upTimeAt
                    );
                    window.location.replace(window.location.href);
                  }}
                >
                  Refresh
                </Button>
              </div>
            )}
            {maintenanceSchedule?.future && !hasUnreadPastMessage && (
              <div className="maintenace-message future">
                We have scheduled maintenance from{' '}
                <span className="bold-weight">
                  {maintenanceSchedule.future.downTimeAt}
                </span>{' '}
                until{' '}
                <span className="bold-weight">
                  {maintenanceSchedule.future.upTimeAt}
                </span>{' '}
                in which our site will be unavailable. We are sorry for any
                inconvenience, have a nice day.
                <Button
                  className="dismiss"
                  styleVariant={ButtonStyleVariant.ANCHOR}
                  onClick={() => {
                    if (maintenanceSchedule?.future) {
                      dispatch(
                        appActions.setLastReadFutureMaintenaceMessageDownTimeAt(
                          maintenanceSchedule.future.downTimeAt
                        )
                      );
                    }
                    dispatch(appActions.hideMaintenanceBanner(true));
                  }}
                >
                  Dismiss
                </Button>
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default MaintenanceBanner;
