import React from 'react';

const PlusInCircleIcon: React.FC = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm0,18.5A8.5,8.5,0,1,1,18.5,10,8.51,8.51,0,0,1,10,18.5Z" />
          <path d="M13.68,9.25H10.75V6.33a.75.75,0,1,0-1.5,0V9.25H6.32a.75.75,0,0,0,0,1.5H9.25v2.93h0a.75.75,0,0,0,1.5,0V10.75h2.93a.75.75,0,0,0,0-1.5Z" />
        </g>
      </g>
    </svg>
  );
};

export default PlusInCircleIcon;
