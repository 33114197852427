import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import purchaseOrderKeys from './keyFactory';

const GetPurchaseOrderAddress = graphql(`
  query GetPurchaseOrderAddress($purchaseOrderId: ID!) {
    purchaseOrder(id: $purchaseOrderId) {
      deliveryAddress {
        city
        coordinates {
          latitude
          longitude
        }
        country
        line1
        line2
        postalCode
        id
      }
    }
  }
`);

const useGetPurchaseOrderDeliveryAddress = (id?: number) => {
  return useQuery({
    enabled: !!id,
    queryKey: purchaseOrderKeys.purchaseOrderAddress(id!),
    queryFn: async () => {
      const p = await gqlClient.request(GetPurchaseOrderAddress, {
        purchaseOrderId: `${id}`,
      });

      return p.purchaseOrder.deliveryAddress || null;
    },
  });
};

export default useGetPurchaseOrderDeliveryAddress;
