import { Format } from './types';
import { useCallback } from 'react';
import { RenderLeafProps, useSlate } from 'slate-react';
import { TFormats } from '@payaca/types/richTextEditorVariables';

export const useHotkeys = (formats: Format[]) => {
  return useCallback(
    () =>
      formats.reduce<Record<string, TFormats>>((acc, current) => {
        if (current.hotkey) {
          acc[current.hotkey] = current.type;
        }
        return acc;
      }, {}),
    [formats]
  );
};
export const useRenderLeafs = (formats: Format[]) => {
  return useCallback(
    ({ attributes, children, leaf }: RenderLeafProps) => {
      formats.forEach((format) => {
        if (leaf[format.type] && format.leafRender) {
          children = format.leafRender({ children });
        }
      });

      return <span {...attributes}>{children}</span>;
    },
    [formats]
  );
};
export const useIsMarkActive = () => {
  const editor = useSlate();
  return useCallback(
    (format: string) => (editor.getMarks() as any)?.[format] === true,
    [editor]
  );
};
export const useToggleFormat = () => {
  const editor = useSlate();
  const isMarkActive = useIsMarkActive();

  return useCallback(
    (format: string) => {
      const isActive = isMarkActive(format);
      if (isActive) {
        editor.removeMark(format);
      } else {
        editor.addMark(format, true);
      }
    },
    [editor]
  );
};
