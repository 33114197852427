import {
  AprysePdfViewer,
  Props as AprysePdfViewerProps,
} from './AprysePdfViewer';

type Props = Pick<AprysePdfViewerProps, 'pdfUrl'>;

export const PdfFormPreview = ({ pdfUrl }: Props) => (
  <AprysePdfViewer readOnly pdfUrl={pdfUrl} />
);
