import { Reducer } from 'redux';

import { State } from './userAssignmentsTypes';

export const initialState: State = {};

const userAssginmentsReducer: Reducer<State> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default userAssginmentsReducer;
