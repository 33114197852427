export type Sortable<TKey extends string> = { [key in TKey]: number };
export type SortableDate<TKey extends string> = { [key in TKey]: Date };

export const sortByKey =
  <TKey extends string>(sortKey: TKey) =>
  (a: Sortable<TKey>, b: Sortable<TKey>) => {
    if (a[sortKey] < b[sortKey]) {
      return -1;
    }
    if (a[sortKey] > b[sortKey]) {
      return 1;
    }
    return 0;
  };

/**
 *
 * @param sortKey - key on object
 * @param ascending - true: oldest->newest
 * @returns
 */
export const sortByDateKey =
  <TKey extends string>(sortKey: TKey, ascending = true) =>
  (a: SortableDate<TKey>, b: SortableDate<TKey>) => {
    if (ascending) {
      return new Date(a[sortKey]).getTime() - new Date(b[sortKey]).getTime();
    } else {
      return new Date(b[sortKey]).getTime() - new Date(a[sortKey]).getTime();
    }
  };
