import React, { FC, useMemo } from 'react';

import { PurchaseOrderStatus } from '@payaca/types/materialsListTypes';
import StatusBadge, { StatusBadgeState } from '@payaca/components/statusBadge/StatusBadge';

type Props = {
  status: PurchaseOrderStatus;
};

const PurchaseOrderStatusBadge: FC<Props> = ({
  status,
}: Props): JSX.Element => {
  const state = useMemo(() => {
    switch (status) {
      case PurchaseOrderStatus.PURCHASED:
        return StatusBadgeState.GREEN_SUCCESS;
        break;
      case PurchaseOrderStatus.SENT:
        return StatusBadgeState.AMBER_PENDING;
        break;
      case PurchaseOrderStatus.VOID:
        return StatusBadgeState.RED_FAILURE;
        break;
      case PurchaseOrderStatus.DRAFT:
        return StatusBadgeState.GREY_INITIAL;
        break;
      default:
        return StatusBadgeState.BLUE_NEUTRAL;
        break;
    }
  }, [status]);

  return <StatusBadge state={state}>{status}</StatusBadge>;
};

export default PurchaseOrderStatusBadge;
