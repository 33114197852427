import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import EditJobSection from '../editJob/EditJobSection';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import JobLineItemControl from '../jobLineItemControl/JobLineItemControl';
import SelectItemModal from '../selectItemModal/SelectItemModal';
import AddJobLineItemControl from '../jobLineItemsControl/AddJobLineItemControl';

import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';

import { JobLineItem, JobLineItemBase } from '@payaca/types/jobContentTypes';

import {
  useChangeProposal,
  useJobLineItemsForChangeProposal,
} from '@payaca/store/hooks/appState';
import { requestGetJobLineItem } from '@payaca/store/jobContent/jobContentActions';
import { requestGetChangeProposal } from '@payaca/store/proposals/proposalsActions';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';

import { getEditChangeProposalBaseLocationPath } from '../../../helpers/jobHelper';

import './ChangeProposalJobLineItemsControl.sass';
import DealMaterialsListDrawer from '../dealMaterialsListDrawer/DealMaterialsListDrawer';

type Props = {
  changeProposalId: number;
};

const ChangeProposalJobLineItemsControl: FC<Props> = ({
  changeProposalId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showLineItemSearchModal, setShowLineItemSearchModal] = useState(false);
  const [isCreatingJobLineItem, setIsCreatingJobLineItem] = useState(false);
  const [isAddingExistingJobLineItem, setIsAddingExistingJobLineItem] =
    useState(false);

  const changeProposal = useChangeProposal(changeProposalId);

  const sortedJobLineItemIds = useMemo(() => {
    return changeProposal?.jobLineItemIds
      ? [...changeProposal.jobLineItemIds].sort((a, b) => a - b)
      : [];
  }, [changeProposal]);

  const jobLineItems = useJobLineItemsForChangeProposal(changeProposalId);

  const sortedFilteredJobLineItems = useMemo(() => {
    return sortedJobLineItemIds
      .map((x) => jobLineItems.find((y) => y.id === x))
      .filter((x) => !!x && !x?.amendmentParentId) as JobLineItem[];
  }, [sortedJobLineItemIds, jobLineItems]);

  const createJobLineItem = useCallback(
    (
      lineItemId: number | null,
      initialItemData?: Partial<JobLineItemBase>,
      callback?: (jobLineItemId: number) => void
    ) => {
      if (lineItemId) {
        setIsAddingExistingJobLineItem(true);
      } else {
        setIsCreatingJobLineItem(true);
      }

      dispatch(
        jobContentActions.requestCreateJobLineItem(
          {
            ...initialItemData,
            changeProposalId,
            lineItemId,
          },
          (jobLineItemId: number) => {
            setIsCreatingJobLineItem(false);
            setIsAddingExistingJobLineItem(false);
            dispatch(requestGetJobLineItem(jobLineItemId));
            dispatch(requestGetChangeProposal(changeProposalId));
            changeProposal && dispatch(requestGetDeal(changeProposal.dealId));
            callback && callback(jobLineItemId);
          }
        )
      );
    },
    [changeProposalId]
  );

  const createJobLineItemAndGoToEditJobLineItemPage = (
    lineItemId: number | null,
    initialItemData?: Partial<JobLineItemBase>
  ) => {
    // create job line item
    createJobLineItem(lineItemId, initialItemData, (jobLineItemId: number) => {
      if (!lineItemId) {
        // open edit item modal if new item not from li
        goToEditJobLineItemPage(jobLineItemId);
      }
    });
  };

  const goToEditJobLineItemPage = (jobLineItemId: number) => {
    history.push(
      `${getEditChangeProposalBaseLocationPath(
        location.pathname
      )}/items/${jobLineItemId}`
    );
  };

  return (
    <EditJobSection
      title={'New Items'}
      className="change-proposal-job-line-items-control"
      description={
        <p>
          Add an existing item or{' '}
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => {
              if (!isCreatingJobLineItem) {
                createJobLineItemAndGoToEditJobLineItemPage(null);
              }
            }}
            isDisabled={isCreatingJobLineItem}
          >
            create a new Item
          </Button>
        </p>
      }
    >
      <ContentPanel hasBoxShadow={false}>
        <>
          {sortedFilteredJobLineItems?.map((jobLineItem, index) => {
            if (!jobLineItem) return;
            return (
              <JobLineItemControl
                jobLineItemId={jobLineItem.id}
                key={`job-line-item-control-${jobLineItem.id}`}
                handleOpenCreateJobLineItemModal={goToEditJobLineItemPage}
              />
            );
          })}
          <div className="create-or-add-existing-container">
            <AddJobLineItemControl
              handleOpenAdvancedSearchLineItemModal={() =>
                setShowLineItemSearchModal(true)
              }
              handleCreateJobLineItemAndOpenCreateJobLineItemModal={
                createJobLineItemAndGoToEditJobLineItemPage
              }
              disabledItemIds={[]}
            />
          </div>
        </>
      </ContentPanel>

      {/* search item modal */}
      <SelectItemModal
        isOpen={!!showLineItemSearchModal}
        onClose={() => setShowLineItemSearchModal(false)}
        onSelectItem={(lineItemId: number) => {
          if (
            !isCreatingJobLineItem &&
            !isAddingExistingJobLineItem &&
            showLineItemSearchModal
          ) {
            // close select item modal
            setShowLineItemSearchModal(false);
            // create job line item and open edit modal
            createJobLineItemAndGoToEditJobLineItemPage(lineItemId);
          }
        }}
        disabledItemIds={[]}
      />
    </EditJobSection>
  );
};
export default ChangeProposalJobLineItemsControl;
