import { createStore, combineReducers, applyMiddleware } from 'redux';
import SagaMiddleware from 'redux-saga';
import { reducer as form } from 'redux-form';

import { getTaxRateIdLocalStorageKey } from '@/helpers/localStorageKeyHelper';

import { reducer as app, sagaCreator as appSaga } from './app';
import { reducer as users, saga as usersSaga } from './users';
import { reducer as jobs, saga as jobsSaga } from './jobs';
import {
  reducer as accountSettings,
  saga as accountSettingsSaga,
} from './accountSettings';
import React, { useRef, useEffect, useState } from 'react';
import dashboardReducer from '@payaca/store/dashboard/dashboardReducer';
import dashboardSagaCreator from '@payaca/store/dashboard/dashboardSaga';
import analyticsReducer from '@payaca/store/analytics/analyticsReducer';
import analyticsSagaCreator from '@payaca/store/analytics/analyticsSaga';
import subscriptionReducer from '@payaca/store/subscription/subscriptionReducer';
import subscriptionSagaCreator from '@payaca/store/subscription/subscriptionSaga';
import accountReducer from '@payaca/store/account/accountReducer';
import accountSagaCreator from '@payaca/store/account/accountSaga';
import authReducer from '@payaca/store/auth/authReducer';
import authSagaCreator from '@payaca/store/auth/authSaga';
import listedItemsReducer from '@payaca/store/listedItems/listedItemsReducer';
import listedItemsSagaCreator from '@payaca/store/listedItems/listedItemsSaga';
import listedUsersReducer from '@payaca/store/listedUsers/listedUsersReducer';
import listedUsersSagaCreator from '@payaca/store/listedUsers/listedUsersSaga';
import jobPaymentsReducer from '@payaca/store/jobPayments/jobPaymentsReducer';
import jobPaymentsSagaCreator from '@payaca/store/jobPayments/jobPaymentsSaga';
import jobsStoreReducer from '@payaca/store/jobs/jobsReducer';
import jobsStoreSagaCreator from '@payaca/store/jobs/jobsSaga';
import lineItemsReducer from '@payaca/store/lineItems/lineItemsReducer';
import lineItemsSagaCreator from '@payaca/store/lineItems/lineItemsSaga';
import customerReducer from '@payaca/store/customer/customerReducer';
import customerSagaCreator from '@payaca/store/customer/customerSaga';
import userReducer from '@payaca/store/user/userReducer';
import userSagaCreator from '@payaca/store/user/userSaga';
import dealActivityReducer from '@payaca/store/dealActivity/dealActivityReducer';
import dealActivitySagaCreator from '@payaca/store/dealActivity/dealActivitySaga';
import lineItemGroupsReducer from '@payaca/store/lineItemGroups/lineItemGroupsReducer';
import lineItemGroupsSagaCreator from '@payaca/store/lineItemGroups/lineItemGroupsSaga';
import lineItemsV2Reducer from '@payaca/store/lineItemsV2/lineItemsReducer';
import lineItemsV2SagaCreator from '@payaca/store/lineItemsV2/lineItemsSaga';
import dealsReducer from '@payaca/store/deals/dealsReducer';
import dealsSagaCreator from '@payaca/store/deals/dealsSaga';
import fileDownloadReducer from '@payaca/store/fileDownload/fileDownloadReducer';
import fileDownloadSagaCreator from '@payaca/store/fileDownload/fileDownloadSaga';
import scheduledEventsReducer from '@payaca/store/scheduledEvents/scheduledEventsReducer';
import scheduledEventsSagaCreator from '@payaca/store/scheduledEvents/scheduledEventsSaga';
import jobContentReducer from '@payaca/store/jobContent/jobContentReducer';
import jobContentSagaCreator from '@payaca/store/jobContent/jobContentSaga';
import documentsReducer from '@payaca/store/documents/documentReducer';
import documentsSagaCreator from '@payaca/store/documents/documentSaga';
import formsReducer from '@payaca/store/forms/formsReducer';
import formsSagaCreator from '@payaca/store/forms/formsSaga';
import notesReducer from '@payaca/store/notes/notesReducer';
import notesSagaCreator from '@payaca/store/notes/notesSaga';
import tagsReducer from '@payaca/store/tags/tagsReducer';
import tagsSagaCreator from '@payaca/store/tags/tagsSaga';
import invoicesReducer from '@payaca/store/invoices/invoicesReducer';
import invoicesSagaCreator from '@payaca/store/invoices/invoicesSaga';
import automationsReducer from '@payaca/store/automations/automationReducer';
import automationsSagaCreator from '@payaca/store/automations/automationSaga';
import userRolesReducer from '@payaca/store/userRoles/userRolesReducer';
import userRolesSagaCreator from '@payaca/store/userRoles/userRolesSaga';
import tasksReducer from '@payaca/store/tasks/tasksReducer';
import tasksSagaCreator from '@payaca/store/tasks/tasksSaga';
import pipelinesReducer from '@payaca/store/pipelines/pipelineReducer';
import pipelinesSagaCreator from '@payaca/store/pipelines/pipelineSaga';
import listedTasksReducer from '@payaca/store/listedTasks/listedTasksReducer';
import listedTasksSagaCreator from '@payaca/store/listedTasks/listedTasksSaga';
import supplierReducer from '@payaca/store/suppliers/suppliersReducer';
import supplierSagaCreator from '@payaca/store/suppliers/suppliersSaga';
import listedSuppliersReducer from '@payaca/store/listedSuppliers/listedSuppliersReducer';
import listedSuppliersSagaCreator from '@payaca/store/listedSuppliers/listedSuppliersSaga';
import uploadsReducer from '@payaca/store/uploads/uploadsReducer';
import uploadsSagaCreator from '@payaca/store/uploads/uploadsSaga';
import materialReducer from '@payaca/store/materials/materialsReducer';
import materialSagaCreator from '@payaca/store/materials/materialsSaga';
import listedMaterialsReducer from '@payaca/store/listedMaterials/listedMaterialsReducer';
import listedMaterialsSagaCreator from '@payaca/store/listedMaterials/listedMaterialsSaga';
import listedSupplierMaterialsReducer from '@payaca/store/listedSupplierMaterials/listedSupplierMaterialsReducer';
import listedSupplierMaterialsSagaCreator from '@payaca/store/listedSupplierMaterials/listedSupplierMaterialsSaga';
import csvUploadReducer from '@payaca/store/csvUpload/csvUploadReducer';
import csvUploadSagaCreator from '@payaca/store/csvUpload/csvUploadSaga';
import listedMaterialLineItemsReducer from '@payaca/store/listedMaterialLineItems/listedMaterialLineItemsReducer';
import listedMaterialLineItemsSagaCreator from '@payaca/store/listedMaterialLineItems/listedMaterialLineItemsSaga';
import materialsListReducer from '@payaca/store/materialsList/materialsListReducer';
import materialsListSagaCreator from '@payaca/store/materialsList/materialsListSaga';
import dealTemplateReducer from '@payaca/store/dealTemplates/dealTemplateReducer';
import dealTemplateSagaCreator from '@payaca/store/dealTemplates/dealTemplateSaga';
import validationReducer from '@payaca/store/validation/validationReducer';
import validationSagaCreator from '@payaca/store/validation/validationSaga';
import proposalsReducer from '@payaca/store/proposals/proposalsReducer';
import proposalsSagaCreator from '@payaca/store/proposals/proposalsSaga';
import clientReducer from '@payaca/store/client/clientReducer';
import clientSagaCreator from '@payaca/store/client/clientSaga';
import pdfDocumentDataSagaCreator from '@payaca/store/pdfDocumentData/pdfDocumentDataSaga';
import taxRatesReducer from '@payaca/store/tax-rates/reducer';
import taxRatesSagaCreator from '@payaca/store/tax-rates/saga';
import countriesReducer from '@payaca/store/countries/reducer';
import countriesSagaCreator from '@payaca/store/countries/saga';
import userAssignmentsEntitiesReducer from '@payaca/store/userAssignments/userAssignments';
import userAssignmentsEntitiesSagaCreator from '@payaca/store/userAssignments/userAssignmentsSaga';
import listedFilesReducer from '@payaca/store/listedFiles/listedFilesReducer';
import listedFilesSagaCreator from '@payaca/store/listedFiles/listedFilesSaga';
import servicePlansReducer from '@payaca/store/servicePlans/servicePlansReducer';
import servicePlansSagaCreator from '@payaca/store/servicePlans/servicePlansSaga';
import dispatchReducer from '@payaca/store/dispatch/dispatchReducer';
import dispatchSagaCreator from '@payaca/store/dispatch/dispatchSaga';
import templatesReducer from '@payaca/store/templates/templatesReducer';
import templatesSagaCreator from '@payaca/store/templates/templatesSaga';
import timelogsReducer from '@payaca/store/timelogs/timelogsReducer';
import timelogsSagaCreator from '@payaca/store/timelogs/timelogsSaga';

export const TOKEN_KEY = 'payacav1';
export const REFRESH_TOKEN_KEY = 'payacav1-refreshToken';

const sagaMiddleware = new SagaMiddleware();

const reducer = combineReducers({
  app,
  users,
  jobs,
  accountSettings,
  form,
  analytics: analyticsReducer,
  account: accountReducer,
  auth: authReducer,
  listedUsers: listedUsersReducer,
  listedItems: listedItemsReducer,
  jobPayments: jobPaymentsReducer,
  jobsStore: jobsStoreReducer,
  lineItems: lineItemsReducer,
  subscription: subscriptionReducer,
  customer: customerReducer,
  user: userReducer,
  deals: dealsReducer,
  dealActivity: dealActivityReducer,
  lineItemGroups: lineItemGroupsReducer,
  lineItemsV2: lineItemsV2Reducer,
  fileDownload: fileDownloadReducer,
  scheduledEvents: scheduledEventsReducer,
  dashboard: dashboardReducer,
  jobContent: jobContentReducer,
  documents: documentsReducer,
  forms: formsReducer,
  notes: notesReducer,
  tags: tagsReducer,
  invoices: invoicesReducer,
  userRoles: userRolesReducer,
  tasks: tasksReducer,
  pipelines: pipelinesReducer,
  automations: automationsReducer,
  listedTasks: listedTasksReducer,
  suppliers: supplierReducer,
  listedSuppliers: listedSuppliersReducer,
  uploads: uploadsReducer,
  materials: materialReducer,
  listedMaterials: listedMaterialsReducer,
  listedSupplierMaterials: listedSupplierMaterialsReducer,
  csvUpload: csvUploadReducer,
  listedMaterialLineItems: listedMaterialLineItemsReducer,
  materialsLists: materialsListReducer,
  dealTemplates: dealTemplateReducer,
  validation: validationReducer,
  proposals: proposalsReducer,
  client: clientReducer,
  taxRates: taxRatesReducer,
  countries: countriesReducer,
  userAssignmentsEntities: userAssignmentsEntitiesReducer,
  listedFiles: listedFilesReducer,
  servicePlans: servicePlansReducer,
  dispatch: dispatchReducer,
  templates: templatesReducer,
  timelogs: timelogsReducer,
});

const logger = () => {
  return (next) => (action) => {
    if (import.meta.env.VITE_NODE_ENV === 'development') {
      // console.log('[ACTION]:', action);
    }
    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);
    // Likely the action itself, unless a middleware further in chain changed it.
    return returnValue;
  };
};

export const store = createStore(
  reducer,
  applyMiddleware(sagaMiddleware, logger)
);

const basicSagaOptions = {
  apiBaseurl: import.meta.env.VITE_API_HOST,
  getAuthHeader: () => `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
};

const sagas = [
  ...[
    analyticsSagaCreator,
    subscriptionSagaCreator,
    listedItemsSagaCreator,
    jobPaymentsSagaCreator,
    customerSagaCreator,
    listedUsersSagaCreator,
    userSagaCreator,
    dealsSagaCreator,
    dealActivitySagaCreator,
    dashboardSagaCreator,
    documentsSagaCreator,
    formsSagaCreator,
    notesSagaCreator,
    tagsSagaCreator,
    userRolesSagaCreator,
    tasksSagaCreator,
    pipelinesSagaCreator,
    automationsSagaCreator,
    listedTasksSagaCreator,
    supplierSagaCreator,
    listedSuppliersSagaCreator,
    uploadsSagaCreator,
    materialSagaCreator,
    listedMaterialsSagaCreator,
    listedSupplierMaterialsSagaCreator,
    csvUploadSagaCreator,
    listedMaterialLineItemsSagaCreator,
    materialsListSagaCreator,
    dealTemplateSagaCreator,
    validationSagaCreator,
    proposalsSagaCreator,
    taxRatesSagaCreator,
    clientSagaCreator,
    pdfDocumentDataSagaCreator,
    countriesSagaCreator,
    templatesSagaCreator,
    timelogsSagaCreator,
  ].map((sagaCreator) => sagaCreator(basicSagaOptions)),

  ...[
    accountSagaCreator,
    scheduledEventsSagaCreator,
    fileDownloadSagaCreator,
    invoicesSagaCreator,
    lineItemGroupsSagaCreator,
    lineItemsSagaCreator,
    userAssignmentsEntitiesSagaCreator,
    listedFilesSagaCreator,
    servicePlansSagaCreator,
    dispatchSagaCreator,
  ].map((sagaCreator) =>
    sagaCreator({
      ...basicSagaOptions,
      isNativeApp: false,
    })
  ),

  authSagaCreator({
    ...basicSagaOptions,
    getRefreshToken: async () => {
      return localStorage.getItem(REFRESH_TOKEN_KEY);
    },
    storeAuthTokens: async (authTokens) => {
      localStorage.setItem(TOKEN_KEY, authTokens.token);
      localStorage.setItem(REFRESH_TOKEN_KEY, authTokens.refreshToken);
    },
  }),

  jobsStoreSagaCreator({
    ...basicSagaOptions,
    isNativeApp: false,
    storage: {
      setItem: async (key, value) => localStorage.setItem(key, value),
      getItem: async (key) => localStorage.getItem(key),
    },
  }),

  jobContentSagaCreator({
    ...basicSagaOptions,
    storage: {
      setItem: async (key, value) => localStorage.setItem(key, value),
      getItem: async (key) => localStorage.getItem(key),
    },
    storageKeys: {
      taxRateId: getTaxRateIdLocalStorageKey(),
    },
  }),
  lineItemsV2SagaCreator({
    ...basicSagaOptions,
    storage: {
      setItem: async (key, value) => localStorage.setItem(key, value),
      getItem: async (key) => localStorage.getItem(key),
    },
    storageKeys: {
      taxRateId: getTaxRateIdLocalStorageKey(),
    },
  }),

  appSaga,
  usersSaga,
  jobsSaga,
  accountSettingsSaga,
];

sagas.forEach((saga) => sagaMiddleware.run(saga));
