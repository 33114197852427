import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import EditJobSection from '../editJob/EditJobSection';
import JobLineItemsControl from '../jobLineItemsControl/JobLineItemsControl';
import ConfirmModal from '../modal/ConfirmModal';
import SelectItemGroupModal from '../selectItemGroupModal/SelectItemGroupModal';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import SelectItemModal from '../selectItemModal/SelectItemModal';

import { getModal } from '@/helpers/modalHelper';
import { getEditJobBaseLocationPath } from '../../../helpers/jobHelper';

import { getJobContent } from '@/utils/stateAccessors';

import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';

import { JobContent, JobLineItemBase } from '@payaca/types/jobContentTypes';

import { useSelector } from '@/api/state';

import './InvoiceLineItemsControl.sass';

type Props = {
  jobIsInvoice: boolean;
  jobContentId: number;
};

const InvoiceLineItemsControl: FC<Props> = ({
  jobIsInvoice,
  jobContentId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [
    showLineItemSearchModalForGroupId,
    setShowLineItemSearchModalForGroupId,
  ] = useState<null | number>();
  const [isCreatingJobLineItem, setIsCreatingJobLineItem] = useState(false);
  const [isAddingExistingJobLineItem, setIsAddingExistingJobLineItem] =
    useState(false);

  const jobContent: JobContent | undefined = useSelector((state) =>
    getJobContent(state, jobContentId)
  );

  const isAddingLineItemGroupToJobContent = useSelector((state) => {
    return state.jobContent.isAddingLineItemGroupToJobContent;
  });

  const lineItemGroups = useSelector(
    (state) => state.lineItemGroups.lineItemGroups
  );

  const [showLineItemGroupModal, setShowLineItemGroupModal] =
    useState<boolean>(false);
  const [showItemGroupConfirmation, setShowItemGroupConfirmation] =
    useState<boolean>(false);

  const [lineItemGroupToAdd, setLineItemGroupToAdd] = useState<number | null>(
    null
  );

  const hasAnyLineItemGroups = useMemo(() => {
    return !!Object.values(lineItemGroups)?.length;
  }, [lineItemGroups]);

  const addLineItemGroupToJobContent = useCallback(
    (lineItemGroupId: number) => {
      dispatch(
        jobContentActions.requestAddLineItemGroupToJobContent(
          jobContentId,
          lineItemGroupId,
          () => {
            dispatch(
              jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
                jobContentId
              )
            );
          }
        )
      );
    },
    [jobContentId]
  );

  const createJobLineItem = useCallback(
    (
      jobLineItemGroupId: number,
      lineItemId: number | null,
      initialItemData?: Partial<JobLineItemBase>,
      callback?: (jobLineItemId: number) => void
    ) => {
      if (lineItemId) {
        setIsAddingExistingJobLineItem(true);
      } else {
        setIsCreatingJobLineItem(true);
      }

      dispatch(
        jobContentActions.requestCreateJobLineItem(
          {
            ...initialItemData,
            jobContentId,
            jobLineItemGroupId,
            lineItemId,
          },
          (jobLineItemId: number) => {
            setIsCreatingJobLineItem(false);
            setIsAddingExistingJobLineItem(false);
            dispatch(
              jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
                jobContentId
              )
            );
            callback && callback(jobLineItemId);
          }
        )
      );
    },
    [jobContentId]
  );

  const createJobLineItemAndGoToEditJobLineItemPage = (
    jobLineItemGroupdId: number,
    lineItemId: number | null,
    initialItemData?: Partial<JobLineItemBase>
  ) => {
    if (jobLineItemGroupdId) {
      // create job line item
      createJobLineItem(
        jobLineItemGroupdId,
        lineItemId,
        initialItemData,
        (jobLineItemId: number) => {
          if (!lineItemId) {
            // open edit item modal if new item not from li
            goToEditJobLineItemPage(jobLineItemId);
          }
        }
      );
    }
  };

  const goToEditJobLineItemPage = (jobLineItemId: number) => {
    history.push(
      `${getEditJobBaseLocationPath(location.pathname)}/items/${jobLineItemId}`
    );
  };

  return (
    <EditJobSection
      title={jobIsInvoice ? 'Invoice lines' : 'Items'}
      className="invoice-line-items-control"
      description={
        <p>
          Add an existing item or{' '}
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => {
              const jobLineItemGroupId = jobContent?.jobLineItemGroupIds[0];
              if (jobLineItemGroupId && !isCreatingJobLineItem) {
                createJobLineItemAndGoToEditJobLineItemPage(
                  jobLineItemGroupId,
                  null
                );
              }
            }}
            isDisabled={
              !jobContent?.jobLineItemGroupIds[0] || isCreatingJobLineItem
            }
          >
            create a new Item
          </Button>
        </p>
      }
    >
      <ContentPanel hasBoxShadow={false}>
        <>
          {jobContent?.jobLineItemGroupIds?.map((jobLineItemGroupId, index) => (
            <JobLineItemsControl
              jobIsInvoice={jobIsInvoice}
              jobLineItemGroupId={jobLineItemGroupId}
              key={`job-line-items-control-${index}`}
              handleOpenCreateJobLineItemModal={goToEditJobLineItemPage}
              handleOpenAdvancedSearchLineItemModal={() =>
                setShowLineItemSearchModalForGroupId(jobLineItemGroupId)
              }
              handleCreateJobLineItemAndOpenCreateJobLineItemModal={(
                lineItemId: number | null,
                initialItemData?: Partial<JobLineItemBase>
              ) =>
                createJobLineItemAndGoToEditJobLineItemPage(
                  jobLineItemGroupId,
                  lineItemId,
                  initialItemData
                )
              }
              disabledItemIds={jobContent?.lineItemIds || []}
            />
          ))}
        </>
      </ContentPanel>

      {hasAnyLineItemGroups && (
        <div className="create-or-add-existing-container line-item-group">
          <Button
            onClick={() => setShowLineItemGroupModal(true)}
            isProcessing={isAddingLineItemGroupToJobContent}
            styleVariant={ButtonStyleVariant.ANCHOR}
          >
            Search existing groups
          </Button>
        </div>
      )}
      <SelectItemGroupModal
        isOpen={showLineItemGroupModal}
        onClose={() => setShowLineItemGroupModal(false)}
        onSelectItemGroup={(lineItemGroup: any) => {
          if (lineItemGroupToAdd) {
            // don't allow selecting a group if already adding one
            return;
          }

          const hasOptionalMultipleChoiceItems =
            lineItemGroup.lineItemRelations.filter(
              (lir: any) => lir.isOptional || lir.isSelected
            ).length;

          if (!hasOptionalMultipleChoiceItems) {
            addLineItemGroupToJobContent(lineItemGroup.id);
          } else {
            setLineItemGroupToAdd(lineItemGroup.id);
            setShowItemGroupConfirmation(true);
          }

          setShowLineItemGroupModal(false);
        }}
      />
      {/* search item modal */}
      <SelectItemModal
        isOpen={!!showLineItemSearchModalForGroupId}
        onClose={() => setShowLineItemSearchModalForGroupId(null)}
        onSelectItem={(lineItemId: number) => {
          if (
            !isCreatingJobLineItem &&
            !isAddingExistingJobLineItem &&
            showLineItemSearchModalForGroupId
          ) {
            // close select item modal
            setShowLineItemSearchModalForGroupId(null);
            // create job line item and open edit modal
            createJobLineItemAndGoToEditJobLineItemPage(
              showLineItemSearchModalForGroupId,
              lineItemId
            );
          }
        }}
        disabledItemIds={jobContent?.lineItemIds || []}
      />

      <ConfirmModal
        {...getModal('ADD_ITEM_GROUP_WITH_OPTIONAL_MULTIPLE_CHOICE')}
        onClose={() => {
          setLineItemGroupToAdd(null);
          setShowItemGroupConfirmation(false);
        }}
        secondaryAction={() => {
          setLineItemGroupToAdd(null);
          setShowItemGroupConfirmation(false);
        }}
        primaryAction={() => {
          addLineItemGroupToJobContent(lineItemGroupToAdd as number);
          setLineItemGroupToAdd(null);
          setShowItemGroupConfirmation(false);
        }}
        open={showItemGroupConfirmation}
      />
    </EditJobSection>
  );
};
export default InvoiceLineItemsControl;
