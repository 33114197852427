import React, { ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';
import { clstx } from '../utils';

export interface IProps<T extends Record<string, any>>
  extends Omit<ComponentPropsWithoutRef<'div'>, 'prefix'> {
  items: T[];
  item: (item: T) => React.ReactNode;
  prefix?: React.ReactNode;
}

const List = <T extends Record<string, any>>(
  props: PropsWithChildren<IProps<T>>
) => {
  const { items, item, className, children, prefix, ...rest } = props;

  return (
    <div className={clstx('space-y-2.5', className)} {...rest}>
      {prefix}

      {items.map(item)}

      {children}
    </div>
  );
};

export default List;
