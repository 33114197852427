import React, { FC } from 'react';

import BasicField from '@payaca/components/basicField/BasicField';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';

type Props = {
  isReadOnly?: boolean;
  name: string;
  onChange: (value: { [key: string]: any }) => void;
  value: string;
};
const AccountNameField: FC<Props> = ({
  isReadOnly = false,
  name,
  onChange,
  value,
}: Props): JSX.Element => {
  return (
    <BasicField
      name={name}
      label="Account name"
      value={value}
      styleVariant={InputStyleVariant.OUTSIZE}
      onChange={onChange}
      isDisabled={isReadOnly}
    />
  );
};

export default AccountNameField;
