import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { InvoiceLine } from '@payaca/types/invoiceTypes';
import { JobLineItem } from '@payaca/types/jobContentTypes';
import './InvoiceBody.sass';
import { currencyPrice, formatPercentage } from '@payaca/helpers/financeHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import { BrandingColourContext } from '@payaca/types/accountBrandTypes';
import ResponsiveViewWrapper from '../responsiveViewWrapper/ResponsiveViewWrapper';
import { isNullish } from '@payaca/utilities/guards';
import MarkdownLabel from '../markdownLabel/MarkdownLabel';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import {
  invoiceLineItemGroupPositionIndexSort,
  invoiceLineItemPositionIndexSort,
} from '@payaca/helpers/invoiceHelper';

const vatFormatter = new Intl.NumberFormat('en-GB');

interface InvoiceLineWithJobLineItem extends InvoiceLine {
  jobLineItem?: JobLineItem;
}

type Props = {
  invoiceLines: InvoiceLine[];
  jobLineItems: JobLineItem[];
  region?: AccountRegions;
  brandingColourContext?: BrandingColourContext;
  hideItemPrices?: boolean;
  showZeroValueLines?: boolean;
  showCancelledLines?: boolean;
};

const InvoiceBody: FunctionComponent<React.PropsWithChildren<Props>> = ({
  invoiceLines,
  jobLineItems,
  region,
  brandingColourContext,
  hideItemPrices = false,
  showZeroValueLines = false,
  showCancelledLines = true,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const invoiceLinesWithJobLineItem: InvoiceLineWithJobLineItem[] =
    useMemo(() => {
      return invoiceLines
        .map((x) => {
          const jobLineItem = jobLineItems.find(
            (y) => y.id === x.jobLineItemId
          );
          return {
            ...x,
            jobLineItem: jobLineItem,
          };
        })
        .sort(invoiceLineItemPositionIndexSort)
        .sort(invoiceLineItemGroupPositionIndexSort);
    }, [invoiceLines, jobLineItems]);

  const cancelledLineIds = useMemo(() => {
    const acc = invoiceLinesWithJobLineItem.reduce(
      (
        acc: Record<
          JobLineItem['id'],
          {
            invoiceLines: InvoiceLineWithJobLineItem[];
            value: number;
          }
        >,
        invoiceLineWithJobLineItem
      ) => {
        const jobLineItemId =
          invoiceLineWithJobLineItem.jobLineItem?.amendmentParentId ||
          invoiceLineWithJobLineItem.jobLineItem?.id;

        if (!jobLineItemId) return acc;

        if (!(jobLineItemId in acc)) {
          acc[jobLineItemId] = {
            invoiceLines: [],
            value: 0,
          };
        }

        acc[jobLineItemId].invoiceLines.push(invoiceLineWithJobLineItem);
        acc[jobLineItemId].value += invoiceLineWithJobLineItem.value;

        return acc;
      },
      {}
    );

    return Object.values(acc).flatMap((x) => {
      if (x.value != 0) return [];
      if (x.invoiceLines.every((y) => y.value == 0)) return [];
      return x.invoiceLines.map((y) => y.id);
    });
  }, [invoiceLinesWithJobLineItem]);

  const anyItemsHaveCisDeduction = invoiceLinesWithJobLineItem.some(
    (x) => !!x.jobLineItem?.cisDeductionRate
  );

  const anyItemsHaveDiscount = invoiceLinesWithJobLineItem.some(
    (x) => !!x.jobLineItem?.discountPercentage
  );

  const anyItemsRepresentFractionalValue = useMemo(() => {
    return invoiceLinesWithJobLineItem.some((x) => {
      const jobLineItem = x.jobLineItem;
      const total = jobLineItem?.total || 0;
      const duePercentage = (x.value / total) * 100;

      return duePercentage < 100;
    });
  }, [invoiceLinesWithJobLineItem]);

  const columnCount = useMemo(() => {
    return (
      1 +
      (anyItemsRepresentFractionalValue ? 1 : 0) +
      (hideItemPrices ? 0 : 4) +
      (anyItemsHaveCisDeduction ? 1 : 0) +
      (anyItemsHaveDiscount ? 1 : 0)
    );
  }, [
    anyItemsHaveCisDeduction,
    hideItemPrices,
    anyItemsRepresentFractionalValue,
    anyItemsHaveDiscount,
  ]);

  const vatRegionalLabel = useMemo(
    () => getRegionalTextString(region, RegionalStrings.VALUE_ADDED_TAX),
    [region]
  );

  const renderInvoiceLine = useCallback(
    (invoiceLine: InvoiceLineWithJobLineItem, i: number) => {
      const jobLineItem = invoiceLine.jobLineItem;

      const total = jobLineItem?.total || 0;

      const duePercentage =
        total === 0 ? 100 : (invoiceLine.value / total) * 100;

      if (
        invoiceLine.value === 0 &&
        jobLineItem?.total != 0 &&
        !showZeroValueLines
      )
        return null;

      if (cancelledLineIds.includes(invoiceLine.id) && !showCancelledLines) {
        return null;
      }

      return (
        <tbody key={i}>
          {jobLineItem && (
            <tr className="description-container">
              <td className="description-table-cell" colSpan={columnCount}>
                <MarkdownLabel
                  markdown={jobLineItem.description}
                  className="description"
                />
              </td>
            </tr>
          )}
          <tr className="line-value-container">
            {!hideItemPrices && (
              <td className="unit-price-table-cell" align="right">
                <span className="sm-view-label">Unit price</span>
                {jobLineItem &&
                  currencyPrice(jobLineItem.priceIncMarkup, region)}
              </td>
            )}
            <td className="qty-table-cell" align="right">
              <span className="sm-view-label">Qty</span>
              {jobLineItem && jobLineItem.quantity}
            </td>
            {!!anyItemsHaveDiscount && (
              <td className="discount-table-cell" align="right">
                <span className="sm-view-label">Discount</span>
                {jobLineItem &&
                  (jobLineItem.discountPercentage
                    ? `${vatFormatter.format(jobLineItem.discountPercentage)}%`
                    : '-')}
              </td>
            )}
            {!hideItemPrices && (
              <td className="vat-table-cell" align="right">
                <span className="sm-view-label">{vatRegionalLabel}</span>
                {jobLineItem &&
                  (jobLineItem.vatIncluded
                    ? `${vatFormatter.format(jobLineItem.vatAmount)}%${
                        jobLineItem.isReverseChargeVat ? ' (RC)' : ''
                      }`
                    : `No ${vatRegionalLabel}`)}
              </td>
            )}
            {anyItemsHaveCisDeduction && (
              <td className="cis-table-cell" align="right">
                <span className="sm-view-label">CIS</span>
                {jobLineItem?.cisDeductionRate
                  ? `${formatPercentage(jobLineItem.cisDeductionRate)}%`
                  : '-'}
              </td>
            )}
            {!hideItemPrices && (
              <td className="total-table-cell" align="right">
                <span className="sm-view-label">Total</span>
                {jobLineItem && currencyPrice(total, region)}
              </td>
            )}
            {anyItemsRepresentFractionalValue && (
              <td className="due-percentage-table-cell" align="right">
                <span className="sm-view-label">% Due</span>
                {isNullish(duePercentage)
                  ? '-'
                  : formatPercentage(duePercentage)}
                %
              </td>
            )}
            {!hideItemPrices && (
              <td className="due-amount-table-cell" align="right">
                <span className="sm-view-label">Due</span>
                {currencyPrice(invoiceLine.value, region)}
              </td>
            )}
          </tr>
        </tbody>
      );
    },
    [
      columnCount,
      anyItemsHaveCisDeduction,
      region,
      hideItemPrices,
      anyItemsRepresentFractionalValue,
      showZeroValueLines,
      anyItemsHaveDiscount,
      cancelledLineIds,
      showCancelledLines,
    ]
  );

  return (
    <ResponsiveViewWrapper className="invoice-body" downBreakpointSm={650}>
      <div>
        <table
          style={{
            borderColor: brandingColourContext?.brandColour,
          }}
        >
          <thead
            style={{
              backgroundColor: brandingColourContext?.brandColour,
              color: brandingColourContext?.brandReadableTextColour,
            }}
          >
            <tr>
              <th className="description-table-cell"></th>
              {!hideItemPrices && (
                <th className="unit-price-table-cell">Unit price</th>
              )}
              <th className="qty-table-cell">Qty</th>
              {!!anyItemsHaveDiscount && (
                <th className="discount-table-cell">Discount</th>
              )}
              {!hideItemPrices && (
                <th className="vat-table-cell">{vatRegionalLabel}</th>
              )}
              {anyItemsHaveCisDeduction && (
                <th className="cis-table-cell">CIS</th>
              )}
              {!hideItemPrices && <th className="total-table-cell">Total</th>}
              {anyItemsRepresentFractionalValue && (
                <th className="due-percentage-table-cell">% Due</th>
              )}
              {!hideItemPrices && (
                <th className="due-amount-table-cell">Due</th>
              )}
            </tr>
          </thead>
          {invoiceLinesWithJobLineItem.map(renderInvoiceLine)}
        </table>
      </div>
    </ResponsiveViewWrapper>
  );
};

export default InvoiceBody;
