import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useDeal } from '@payaca/store/hooks/appState';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import {
  requestGetDeal,
  requestUpdateDeal,
} from '@payaca/store/deals/dealsActions';

interface Props {
  dealId: number;
}

export const DealHideItemPricesControl: FC<Props> = ({ dealId }: Props) => {
  const dispatch = useDispatch();

  const [hideItemPrices, setHideItemPrices] = useState(false);

  const deal = useDeal(dealId);

  useEffect(() => {
    if (deal) {
      setHideItemPrices(!!deal.hideItemPrices);
    }
  }, [deal?.id]);

  const handlePersist = useCallback(
    (hideItemPrices: boolean) => {
      dispatch(
        requestUpdateDeal(dealId, { hideItemPrices }, () => {
          dispatch(requestGetDeal(dealId));
        })
      );
    },
    [dealId]
  );

  if (!deal) return null;

  return (
    <div className="deal-hide-item-prices-control">
      <CheckboxField
        name="hideItemPrices"
        value={hideItemPrices}
        label="Hide item prices"
        onChange={(value) => {
          setHideItemPrices(value.hideItemPrices);
          handlePersist(value.hideItemPrices);
        }}
      />
    </div>
  );
};
