import {
  takeEvery,
  call,
  put,
  race,
  delay,
  takeLatest,
} from 'redux-saga/effects';

import { Req } from '@payaca/helpers/storeHelper';

import { PayloadAction } from 'typesafe-actions';

import { DocumentSenderContext } from '@payaca/types/accountBrandTypes';
import {
  SagaConfig,
  ActionType,
  CreatePaymentRecordRequestData,
  GetChangeProposalAction,
  GetChangeProposalsAction,
  GetJobLineItemsAction,
  GetJobLineItemsForInvoiceAction,
  AcceptChangeProposalAction,
  DeclineChangeProposalAction,
  GetInvoiceAction,
  RecordViewAction,
  GetServicePlanPayloadAction,
  CreateServicePlanSubscriptionAction,
  SendServicePlanSubscriptionManageBillingEmailAction,
  GetServicePlanSubscriptionPayloadAction,
  GetGeneratedPdfUrlForInvoice,
} from './clientTypes';

import { handleAsyncAction } from '../utils';

import {
  cancelStripePaymentIntentFailure,
  cancelStripePaymentIntentSuccess,
  clearDocumentSenderContext,
  clearCustomer,
  clearDeal,
  clearInvoiceLines,
  clearInvoices,
  clearJobContents,
  clearJobLineItemGroups,
  clearJobLineItems,
  clearJobPayments,
  clearProposals,
  getDocumentSenderContextFailure,
  getDocumentSenderContextSuccess,
  getCustomerFailure,
  getCustomerSuccess,
  getDealFailure,
  getDealSuccess,
  getInvoiceLineSuccess,
  getInvoicesFailure,
  getInvoiceSuccess,
  getJobContentSuccess,
  getJobLineItemGroupSuccess,
  getJobLineItemSuccess,
  getJobPaymentSuccess,
  getProposalsFailure,
  getProposalSuccess,
  createStripePaymentIntentFailure,
  createStripePaymentIntentSuccess,
  requestGetDocumentSenderContext,
  requestGetCustomer,
  requestGetDeal,
  requestGetInvoices,
  requestGetJobContents,
  requestGetJobPaymentsWithReconciliationRecords,
  requestGetProposals,
  createPaymentRecordFailure,
  createPaymentRecordSuccess,
  getJobContentsSuccess,
  getJobContentsFailure,
  clearPaymentMethodConfig,
  requestGetPaymentMethodConfig,
  getPaymentMethodConfigFailure,
  getPaymentMethodConfigSuccess,
  clearPaymentReconciliationRecords,
  getPaymentReconciliationRecordSuccess,
  getChangeProposal,
  getChangeProposals,
  clearChangeProposals,
  getJobLineItems,
  getJobLineItemsForInvoice,
  acceptChangeProposal,
  declineChangeProposal,
  getInvoice,
  recordView,
  getServicePlanPayload,
  createServicePlanSubscription,
  sendServicePlanSubscriptionManageBillingEmail,
  getServicePlanSubscriptionPayload,
  updateServicePlanSubscriptionStatus,
  getServicePlanDiscountCodeByClientCode,
  getGeneratedPdfUrlForInvoice,
} from './clientActions';
import {
  ClientDeal,
  PaymentMethodConfig,
  StripePaymentIntentInformation,
} from '@payaca/types/clientTypes';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const clientSagaCreator = ({ apiBaseurl, getAuthHeader }: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, false);

  function* handleCreatePaymentRecord(
    action: PayloadAction<
      ActionType.CREATE_PAYMENT_RECORD_REQUEST,
      {
        createPaymentRecordRequestData: CreatePaymentRecordRequestData;
        callback?: () => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(
          createPaymentRecord,
          action.payload.createPaymentRecordRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          createPaymentRecordFailure(
            new Error('Create payment record timed out.')
          )
        );
      } else {
        yield put(createPaymentRecordSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(createPaymentRecordFailure(error));
    }
  }

  const createPaymentRecord = async (
    createPaymentRecordRequestData: CreatePaymentRecordRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/client/${createPaymentRecordRequestData.token}/job_payments`,
      {
        method: 'POST',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
        body: JSON.stringify({
          invoiceId: createPaymentRecordRequestData.invoiceId,
          stripeAccountId: createPaymentRecordRequestData.stripeAccountId,
          paymentIntentId: createPaymentRecordRequestData.paymentIntentId,
          paymentMethod: createPaymentRecordRequestData.paymentMethod,
          paymentValue: createPaymentRecordRequestData.paymentValue,
        }),
      }
    ).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `Create payment record failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleCancelStripePaymentIntent(
    action: PayloadAction<
      ActionType.CANCEL_STRIPE_PAYMENT_INTENT_REQUEST,
      {
        paymentIntentId: string;
        previewToken: string;
        type: 'quote' | 'invoice' | 'deal';
        callback?: () => void;
      }
    >
  ) {
    try {
      const { timeout } = yield race({
        response: call(
          cancelStripePaymentIntent,
          action.payload.paymentIntentId,
          action.payload.previewToken,
          action.payload.type
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          cancelStripePaymentIntentFailure(
            new Error('Get payment intent timed out.')
          )
        );
      } else {
        yield put(cancelStripePaymentIntentSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(cancelStripePaymentIntentFailure(error));
    }
  }

  const cancelStripePaymentIntent = async (
    paymentIntentId: string,
    previewToken: string,
    type: 'quote' | 'invoice' | 'deal'
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/client/${previewToken}/payment_intent/cancel?type=${type}`,
      {
        method: 'PUT',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
        body: JSON.stringify({
          paymentIntentId: paymentIntentId,
        }),
      }
    ).then((response) => {
      if (response.ok) {
        return response;
      } else {
        throw new Error(
          `Cancel payment intent failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetStripePaymentIntent(
    action: PayloadAction<
      ActionType.CREATE_STRIPE_PAYMENT_INTENT_REQUEST,
      {
        paymentValue: number;
        previewToken: string;
        type: 'quote' | 'invoice' | 'deal';
        callback?: (
          paymentIntentInformation: StripePaymentIntentInformation
        ) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(
          getStripePaymentIntent,
          action.payload.paymentValue,
          action.payload.previewToken,
          action.payload.type
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          createStripePaymentIntentFailure(
            new Error('Get payment intent timed out.')
          )
        );
      } else {
        yield put(createStripePaymentIntentSuccess());
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(createStripePaymentIntentFailure(error));
    }
  }

  const getStripePaymentIntent = async (
    paymentValue: number,
    previewToken: string,
    type: 'quote' | 'invoice' | 'deal'
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/client/${previewToken}/payment_intent?type=${type}`,
      {
        method: 'POST',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
        body: JSON.stringify({ paymentValue: paymentValue }),
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get payment intent failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetPaymentMethodConfig(
    action: PayloadAction<
      ActionType.GET_PAYMENT_METHOD_CONFIG_REQUEST,
      {
        previewToken: string;
        callback?: (paymentMethodConfig: PaymentMethodConfig) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(getPaymentMethodConfig, action.payload.previewToken),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getPaymentMethodConfigFailure(
            new Error('Get payment method config timed out.')
          )
        );
      } else {
        yield put(getPaymentMethodConfigSuccess(response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(getPaymentMethodConfigFailure(error));
    }
  }

  const getPaymentMethodConfig = async (previewToken: string) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/client/${previewToken}/payment_method_config`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get payment method config failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetDocumentSenderContext(
    action: PayloadAction<
      ActionType.GET_DOCUMENT_ORIGIN_CONTEXT_REQUEST,
      {
        previewToken: string;
        callback?: (documentSenderContext: DocumentSenderContext) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(getDocumentSenderContext, action.payload.previewToken),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getDocumentSenderContextFailure(
            new Error('Get branding context timed out.')
          )
        );
      } else {
        yield put(getDocumentSenderContextSuccess(response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(getDocumentSenderContextFailure(error));
    }
  }

  const getDocumentSenderContext = async (previewToken: string) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/client/${previewToken}/document_sender_context`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get branding context failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetDeal(
    action: PayloadAction<
      ActionType.GET_DEAL_REQUEST,
      {
        previewToken: string;
        callback?: (deal: ClientDeal) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(getDeal, action.payload.previewToken),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(getDealFailure(new Error('Get Project timed out.')));
      } else {
        yield put(getDealSuccess(response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(getDealFailure(error));
    }
  }

  const getDeal = async (previewToken: string) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/client/${previewToken}/deal`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get Project failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetCustomer(
    action: PayloadAction<
      ActionType.GET_CUSTOMER_REQUEST,
      {
        previewToken: string;
        callback?: (deal: ClientDeal) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(getCustomer, action.payload.previewToken),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(getCustomerFailure(new Error('Get customer timed out.')));
      } else {
        yield put(getCustomerSuccess(response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(getCustomerFailure(error));
    }
  }

  const getCustomer = async (previewToken: string) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/client/${previewToken}/customer`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get customer failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetInvoices(
    action: PayloadAction<
      ActionType.GET_INVOICES_REQUEST,
      {
        previewToken: string;
        callback?: () => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(getInvoices, action.payload.previewToken),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(getInvoicesFailure(new Error('Get invoices timed out.')));
      } else {
        for (const invoice of response) {
          yield put(getInvoiceSuccess(invoice.id, invoice));
        }
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(getInvoicesFailure(error));
    }
  }

  const getInvoices = async (previewToken: string) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/client/${previewToken}/invoices`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get invoices failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetProposals(
    action: PayloadAction<
      ActionType.GET_PROPOSALS_REQUEST,
      {
        previewToken: string;
        callback?: () => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(getProposals, action.payload.previewToken),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(getProposalsFailure(new Error('Get proposals timed out.')));
      } else {
        for (const proposal of response) {
          yield put(getProposalSuccess(proposal.id, proposal));
        }
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(getProposalsFailure(error));
    }
  }

  const getProposals = async (previewToken: string) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/client/${previewToken}/proposals`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get proposals failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetJobContents(
    action: PayloadAction<
      ActionType.GET_JOB_CONTENTS_REQUEST,
      {
        previewToken: string;
        callback?: () => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(getJobContents, action.payload.previewToken),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getJobContentsFailure(new Error('Get job contents timed out.'))
        );
      } else {
        for (const jobContent of response) {
          yield put(getJobContentSuccess(jobContent.id, jobContent));
        }
        yield put(getJobContentsSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(getJobContentsFailure(error));
    }
  }

  const getJobContents = async (previewToken: string) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/client/${previewToken}/job_contents`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get job contents failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetJobPaymentsWithReconciliationRecords(
    action: PayloadAction<
      ActionType.GET_JOB_PAYMENTS_WITH_RECONCILIATION_RECORDS_REQUEST,
      {
        previewToken: string;
        callback?: () => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(
          getJobPaymentsWithReconciliationRecords,
          action.payload.previewToken
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        //
      } else {
        for (const jobPayment of response.jobPayments) {
          yield put(getJobPaymentSuccess(jobPayment.id, jobPayment));
        }
        for (const paymentReconciliationRecord of response.paymentReconciliationRecords) {
          yield put(
            getPaymentReconciliationRecordSuccess(
              paymentReconciliationRecord.id,
              paymentReconciliationRecord
            )
          );
        }
        action.payload.callback && action.payload.callback();
      }
    } catch (error) {
      //
    }
  }

  const getJobPaymentsWithReconciliationRecords = async (
    previewToken: string
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/client/${previewToken}/job_payments_with_reconciliation_records`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get job payments failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetJobLineItemsForJobContent(
    action: PayloadAction<
      ActionType.GET_JOB_LINE_ITEMS_FOR_JOB_CONTENT_REQUEST,
      {
        jobContentId: number;
        previewToken: string;
        callback?: () => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(
          getJobLineItemsForJobContent,
          action.payload.previewToken,
          action.payload.jobContentId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        //
      } else {
        for (const jobLineItem of response) {
          yield put(getJobLineItemSuccess(jobLineItem.id, jobLineItem));
        }
        action.payload.callback && action.payload.callback();
      }
    } catch (error) {
      //
    }
  }

  const getJobLineItemsForJobContent = async (
    previewToken: string,
    jobContentId: number
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/client/${previewToken}/job_contents/${jobContentId}/job_line_items`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get job line items for job content failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetJobLineItemGroupsForJobContent(
    action: PayloadAction<
      ActionType.GET_JOB_LINE_ITEM_GROUPS_FOR_JOB_CONTENT_REQUEST,
      {
        jobContentId: number;
        previewToken: string;
        callback?: () => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(
          getJobLineItemGroupsForJobContent,
          action.payload.previewToken,
          action.payload.jobContentId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        //
      } else {
        for (const jobLineItemGroup of response) {
          yield put(
            getJobLineItemGroupSuccess(jobLineItemGroup.id, jobLineItemGroup)
          );
        }
        action.payload.callback && action.payload.callback();
      }
    } catch (error) {
      //
    }
  }

  const getJobLineItemGroupsForJobContent = async (
    previewToken: string,
    jobContentId: number
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/client/${previewToken}/job_contents/${jobContentId}/job_line_item_groups`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get job line item groups for job content failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetInvoiceLinesForInvoice(
    action: PayloadAction<
      ActionType.GET_INVOICE_LINES_FOR_INVOICE_REQUEST,
      {
        invoiceId: number;
        previewToken: string;
        callback?: () => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(
          getInvoiceLinesForInvoice,
          action.payload.previewToken,
          action.payload.invoiceId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        //
      } else {
        for (const invoiceLine of response) {
          yield put(getInvoiceLineSuccess(invoiceLine.id, invoiceLine));
        }
        action.payload.callback && action.payload.callback();
      }
    } catch (error) {
      //
    }
  }

  const getInvoiceLinesForInvoice = async (
    previewToken: string,
    invoiceId: number
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/client/${previewToken}/invoices/${invoiceId}/invoice_lines`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Get invoice lines for invoice failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const handleGetChangeProposal = handleAsyncAction<GetChangeProposalAction>(
    getChangeProposal,
    async (payload) => {
      const authHeader = await getAuthHeader();
      const response = await fetch(
        `${apiBaseurl}/api/client/${payload.previewToken}/change_proposals/${payload.changeProposalId}`,
        {
          method: 'GET',
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
            'X-Simple-Job': 'true',
          },
        }
      );
      if (response.ok) {
        return { changeProposal: await response.json() };
      } else {
        throw new Error('Failed to mark change proposal as accepted');
      }
    }
  );

  const handleGetChangeProposals = handleAsyncAction<GetChangeProposalsAction>(
    getChangeProposals,
    async (payload) => {
      const authHeader = await getAuthHeader();
      const response = await fetch(
        `${apiBaseurl}/api/client/${payload.previewToken}/change_proposals`,
        {
          method: 'GET',
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
            'X-Simple-Job': 'true',
          },
        }
      );
      if (response.ok) {
        return { changeProposals: await response.json() };
      } else {
        throw new Error('Failed to mark change proposal as accepted');
      }
    }
  );

  const handleGetJobLineItems = handleAsyncAction<GetJobLineItemsAction>(
    getJobLineItems,
    async (payload) => {
      const authHeader = await getAuthHeader();
      const response = await fetch(
        `${apiBaseurl}/api/client/${payload.previewToken}/job_line_items`,
        {
          method: 'GET',
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
            'X-Simple-Job': 'true',
          },
        }
      );
      if (response.ok) {
        return { jobLineItems: await response.json() };
      } else {
        throw new Error('Failed to mark change proposal as accepted');
      }
    }
  );

  const handleGetJobLineItemsForInvoice =
    handleAsyncAction<GetJobLineItemsForInvoiceAction>(
      getJobLineItemsForInvoice,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/client/${payload.previewToken}/invoices/${payload.invoiceId}/job_line_items`,
          {
            method: 'GET',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
          }
        );
        if (response.ok) {
          return { jobLineItems: await response.json() };
        } else {
          throw new Error('Failed to get job line items for invoice');
        }
      }
    );

  const handleGetGeneratedPdfUrlForInvoice =
    handleAsyncAction<GetGeneratedPdfUrlForInvoice>(
      getGeneratedPdfUrlForInvoice,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/client/${payload.previewToken}/invoices/${payload.invoiceId}/pdf_url`,
          {
            method: 'GET',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
          }
        );
        return response.json();
      },
      (response, requestData) => {
        requestData.payload.callback?.(response as string);
      }
    );

  const handleAcceptChangeProposal =
    handleAsyncAction<AcceptChangeProposalAction>(
      acceptChangeProposal,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/client/${payload.previewToken}/change_proposals/${payload.changeProposalId}/accept`,
          {
            method: 'POST',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
            body: JSON.stringify({
              signatureImage: payload.signatureImage,
            }),
          }
        );
        if (response.ok) {
          return;
        } else {
          throw new Error('Failed to accept change proposal');
        }
      },
      (_, requestData) => {
        requestData.payload.callback?.();
      },
      (err, requestData) => {
        requestData.payload.onErrorCallback?.();
      }
    );

  const handleDeclineChangeProposal =
    handleAsyncAction<DeclineChangeProposalAction>(
      declineChangeProposal,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/client/${payload.previewToken}/change_proposals/${payload.changeProposalId}/decline`,
          {
            method: 'POST',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
            body: JSON.stringify({
              declineReason: payload.declineReason,
            }),
          }
        );
        if (response.ok) {
          return;
        } else {
          throw new Error('Failed to decline change proposal');
        }
      },
      (_, requestData) => {
        requestData.payload.callback?.();
      },
      (err, requestData) => {
        requestData.payload.onErrorCallback?.();
      }
    );

  const handleGetInvoice = handleAsyncAction<GetInvoiceAction>(
    getInvoice,
    async (payload) => {
      const authHeader = await getAuthHeader();
      const response = await fetch(
        `${apiBaseurl}/api/client/${payload.previewToken}/invoices/${payload.invoiceId}`,
        {
          method: 'GET',
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
            'X-Simple-Job': 'true',
          },
        }
      );
      if (response.ok) {
        return {
          invoiceId: payload.invoiceId,
          invoice: await response.json(),
        };
      } else {
        throw new Error('Failed to get invoice');
      }
    },
    (response, requestData) => {
      requestData.payload.callback?.(response.invoice);
    }
  );

  const handleGetServicePlanPayload =
    handleAsyncAction<GetServicePlanPayloadAction>(
      getServicePlanPayload,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/service-plans/client-service-plans/${
            payload.servicePlanId
          }?offerToken=${payload.offerToken || ''}`,
          {
            method: 'GET',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
          }
        );
        if (response.ok) {
          return await response.json();
        } else {
          throw new Error('Failed to get service plan payload');
        }
      },
      (response, requestData) => {
        requestData.payload.callback?.(response);
      },
      (response, requestData) => {
        requestData.payload.onErrorCallback?.();
      }
    );

  const handleGetServicePlanDiscountCodeByClientCode = handleAsyncAction(
    getServicePlanDiscountCodeByClientCode,
    async (payload) => {
      try {
        const response = await req.get(
          `/service-plans/${payload.publicId}/discount-codes/${payload.clientCode}`
        );
        return await response.json();
      } catch (err) {
        console.log(
          `Get service plan discount code by client code failed: ${JSON.stringify(
            err
          )}`
        );

        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response);
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleGetServicePlanSubscriptionPayload =
    handleAsyncAction<GetServicePlanSubscriptionPayloadAction>(
      getServicePlanSubscriptionPayload,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/service-plans/client-service-plan/subscription/${payload.servicePlanSubscriptionId}`,
          {
            method: 'GET',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
          }
        );
        if (response.ok) {
          return await response.json();
        } else {
          throw new Error('Failed to get service plan subscription payload');
        }
      },
      (_response, requestData) => {
        requestData.payload.callback?.();
      },
      (_response, requestData) => {
        requestData.payload.onErrorCallback?.();
      }
    );

  const handleSendServicePlanSubscriptionManageBillingEmail =
    handleAsyncAction<SendServicePlanSubscriptionManageBillingEmailAction>(
      sendServicePlanSubscriptionManageBillingEmail,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/service-plans/client-service-plan/subscription/${payload.servicePlanSubscriptionId}/send-customer-portal-email`,
          {
            method: 'POST',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
          }
        );
        if (response.ok) {
          return await response;
        } else {
          throw new Error(
            'Failed to send service plan subscription manage billing email'
          );
        }
      },
      (_response, requestData) => {
        requestData.payload.callback?.();
      },
      (_response, requestData) => {
        requestData.payload.onErrorCallback?.();
      }
    );

  const handleCreateServicePlanSubscription =
    handleAsyncAction<CreateServicePlanSubscriptionAction>(
      createServicePlanSubscription,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/service-plans/subscriptions`,
          {
            method: 'POST',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
            body: JSON.stringify({
              servicePlanPricePublicId: payload.servicePlanPriceId,
              servicePlanDiscountCodePublicId:
                payload.servicePlanDiscountCodePublicId,
              offerToken: payload.offerToken,
              customer: payload.customer,
            }),
          }
        );
        if (response.ok) {
          return await response.json();
        } else {
          throw new Error('Failed to create service plan subscription');
        }
      },
      (response, requestData) => {
        const r = response as Record<string, any>;

        requestData.payload.callback?.({
          stripeClientSecret: r.stripeClientSecret as string,
          servicePlanSubscriptionId:
            r.servicePlanSubscriptionPublicId as string,
          stripeAccountId: r.stripeAccountId as string,
        });
      }
    );

  const handleRecordView = handleAsyncAction<RecordViewAction>(
    recordView,
    async (payload) => {
      const authHeader = await getAuthHeader();

      let url = '';

      switch (payload.entityType) {
        case 'invoice':
          url = `${apiBaseurl}/api/client/${payload.token}/invoices/${payload.entityId}/view`;
          break;
        case 'changeProposal':
          url = `${apiBaseurl}/api/client/${payload.token}/change_proposals/${payload.entityId}/view`;
          break;
        case 'proposal':
          url = `${apiBaseurl}/api/client/${payload.token}/proposals/${payload.entityId}/view`;
          break;
        default:
          throw new Error('Not implemented');
      }

      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload.clientIdentifier),
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      });
      if (response.ok) {
        // No op
      } else {
        throw new Error('Failed to record view');
      }
    }
  );

  function* handleClearClientContext() {
    yield put(clearDocumentSenderContext());
    yield put(clearPaymentMethodConfig());
    yield put(clearDeal());
    yield put(clearInvoices());
    yield put(clearProposals());
    yield put(clearJobContents());
    yield put(clearJobPayments());
    yield put(clearPaymentReconciliationRecords());
    yield put(clearJobLineItems());
    yield put(clearJobLineItemGroups());
    yield put(clearInvoiceLines());
    yield put(clearCustomer());
    yield put(clearChangeProposals());
  }

  function* handleGetClientContext(
    action: PayloadAction<
      ActionType.GET_CLIENT_CONTEXT_REQUEST,
      {
        previewToken: string;
      }
    >
  ) {
    yield put(requestGetDocumentSenderContext(action.payload.previewToken));
    yield put(requestGetDeal(action.payload.previewToken));
    yield put(requestGetCustomer(action.payload.previewToken));
    yield put(requestGetInvoices(action.payload.previewToken));
    yield put(requestGetProposals(action.payload.previewToken));
    yield put(requestGetJobContents(action.payload.previewToken));
    yield put(getChangeProposals.request(action.payload.previewToken));
    yield put(
      requestGetJobPaymentsWithReconciliationRecords(
        action.payload.previewToken
      )
    );
    yield put(requestGetPaymentMethodConfig(action.payload.previewToken));
  }

  return function* () {
    yield takeLatest(
      ActionType.GET_DOCUMENT_ORIGIN_CONTEXT_REQUEST,
      handleGetDocumentSenderContext
    );
    yield takeLatest(ActionType.GET_CUSTOMER_REQUEST, handleGetCustomer);
    yield takeLatest(ActionType.GET_DEAL_REQUEST, handleGetDeal);
    yield takeLatest(
      ActionType.GET_PAYMENT_METHOD_CONFIG_REQUEST,
      handleGetPaymentMethodConfig
    );
    yield takeLatest(ActionType.GET_INVOICES_REQUEST, handleGetInvoices);
    yield takeLatest(ActionType.GET_PROPOSALS_REQUEST, handleGetProposals);
    yield takeLatest(ActionType.GET_JOB_CONTENTS_REQUEST, handleGetJobContents);
    yield takeLatest(
      ActionType.GET_JOB_PAYMENTS_WITH_RECONCILIATION_RECORDS_REQUEST,
      handleGetJobPaymentsWithReconciliationRecords
    );
    yield takeLatest(
      ActionType.GET_JOB_LINE_ITEMS_FOR_JOB_CONTENT_REQUEST,
      handleGetJobLineItemsForJobContent
    );
    yield takeLatest(
      ActionType.GET_JOB_LINE_ITEM_GROUPS_FOR_JOB_CONTENT_REQUEST,
      handleGetJobLineItemGroupsForJobContent
    );
    yield takeLatest(
      ActionType.GET_INVOICE_LINES_FOR_INVOICE_REQUEST,
      handleGetInvoiceLinesForInvoice
    );
    yield takeLatest(
      ActionType.GET_CLIENT_CONTEXT_REQUEST,
      handleGetClientContext
    );
    yield takeEvery(
      ActionType.CREATE_STRIPE_PAYMENT_INTENT_REQUEST,
      handleGetStripePaymentIntent
    );
    yield takeEvery(
      ActionType.CANCEL_STRIPE_PAYMENT_INTENT_REQUEST,
      handleCancelStripePaymentIntent
    );
    yield takeEvery(
      ActionType.CREATE_PAYMENT_RECORD_REQUEST,
      handleCreatePaymentRecord
    );
    yield takeLatest(
      ActionType.GET_CHANGE_PROPOSAL_REQUEST,
      handleAsyncAction(
        getChangeProposal,
        async (payload) => {
          const authHeader = await getAuthHeader();
          const response = await fetch(
            `${apiBaseurl}/api/client/${payload.previewToken}/change_proposals/${payload.changeProposalId}`,
            {
              method: 'GET',
              headers: {
                Authorization: authHeader,
                'Content-Type': 'application/json',
                'X-Simple-Job': 'true',
              },
            }
          );
          if (response.ok) {
            return { changeProposal: await response.json() };
          } else {
            throw new Error('Failed to mark change proposal as accepted');
          }
        },
        (response, requestData) => {
          requestData.payload.callback?.(response.changeProposal);
        }
      )
    );
    yield takeLatest(
      ActionType.GET_CHANGE_PROPOSALS_REQUEST,
      handleAsyncAction(getChangeProposals, async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/client/${payload.previewToken}/change_proposals`,
          {
            method: 'GET',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
          }
        );
        if (response.ok) {
          return { changeProposals: await response.json() };
        } else {
          throw new Error('Failed to mark change proposal as accepted');
        }
      })
    );
    yield takeLatest(
      ActionType.GET_JOB_LINE_ITEMS_REQUEST,
      handleAsyncAction(getJobLineItems, async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/client/${payload.previewToken}/job_line_items`,
          {
            method: 'GET',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
          }
        );
        if (response.ok) {
          return { jobLineItems: await response.json() };
        } else {
          throw new Error('Failed to mark change proposal as accepted');
        }
      })
    );
    yield takeLatest(
      ActionType.GET_JOB_LINE_ITEMS_FOR_INVOICE_REQUEST,
      handleGetJobLineItemsForInvoice
    );
    yield takeLatest(
      ActionType.ACCEPT_CHANGE_PROPOSAL_REQUEST,
      handleAcceptChangeProposal
    );
    yield takeLatest(
      ActionType.DECLINE_CHANGE_PROPOSAL_REQUEST,
      handleDeclineChangeProposal
    );
    yield takeEvery(ActionType.RECORD_VIEW_REQUEST, handleRecordView);
    yield takeLatest(ActionType.GET_INVOICE_REQUEST, handleGetInvoice);
    yield takeLatest(
      ActionType.GET_SERVICE_PLAN_PAYLOAD_REQUEST,
      handleGetServicePlanPayload
    );
    yield takeEvery(
      ActionType.GET_DISCOUNT_CODE_BY_CLIENT_CODE_REQUEST,
      handleGetServicePlanDiscountCodeByClientCode
    );
    yield takeLatest(
      ActionType.GET_SERVICE_PLAN_SUBSCRIPTION_PAYLOAD_REQUEST,
      handleGetServicePlanSubscriptionPayload
    );
    yield takeLatest(
      ActionType.SEND_SERVICE_PLAN_SUBSCRIPTION_MANAGE_BILLING_EMAIL_REQUEST,
      handleSendServicePlanSubscriptionManageBillingEmail
    );
    yield takeLatest(
      ActionType.CREATE_SERVICE_PLAN_SUBSCRIPTION_REQUEST,
      handleCreateServicePlanSubscription
    );
    yield takeEvery(ActionType.CLEAR_CLIENT_CONTEXT, handleClearClientContext);
    yield takeEvery(
      ActionType.GET_GENERATED_PDF_URL_FOR_INVOICE_REQUEST,
      handleGetGeneratedPdfUrlForInvoice
    );

    yield takeEvery(
      ActionType.UPDATE_SERVICE_PLAN_SUBSCRIPTION_STATUS_REQUEST,
      handleAsyncAction(
        updateServicePlanSubscriptionStatus,
        async (payload) => {
          const authHeader = await getAuthHeader();
          const response = await fetch(
            `${apiBaseurl}/api/service-plans/subscriptions/${payload.servicePlanSubscriptionPublicId}/status`,
            {
              method: 'PUT',
              headers: {
                Authorization: authHeader,
                'Content-Type': 'application/json',
                'X-Simple-Job': 'true',
              },
              body: JSON.stringify({
                status: payload.status,
              }),
            }
          );
          if (!response.ok) {
            throw new Error(
              'Failed to mark service plan subscription as pending'
            );
          }
        }
      )
    );
  };
};

export default clientSagaCreator;
