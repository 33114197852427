import { MaterialsPermissions } from './materials.permissions';
import { MaterialsRoles } from './materials.roles';

export const materialsMappings = {
  [MaterialsPermissions.GET_MATERIALS]: [MaterialsRoles.VIEWER],
  [MaterialsPermissions.GET_SYSTEM_MANAGED_MATERIALS]: [
    MaterialsRoles.SYSTEM_MANAGED_VIEWER,
  ],
  [MaterialsPermissions.PERSIST_MATERIAL]: [MaterialsRoles.PERSISTER],
  [MaterialsPermissions.ARCHIVE_MATERIAL]: [MaterialsRoles.ARCHIVER],
  [MaterialsPermissions.GET_COLLECTION_MANAGEMENT_VIEW]: [
    MaterialsRoles.COLLECTION_MANAGER,
  ],
  [MaterialsPermissions.GET_LINE_ITEM_MATERIALS]: [
    MaterialsRoles.LINE_ITEM_MATERIALS_VIEWER,
  ],
  [MaterialsPermissions.PERSIST_LINE_ITEM_MATERIAL]: [
    MaterialsRoles.LINE_ITEM_MATERIALS_PERSISTER,
  ],
  [MaterialsPermissions.DELETE_LINE_ITEM_MATERIAL]: [
    MaterialsRoles.LINE_ITEM_MATERIALS_DELETER,
  ],

  [MaterialsPermissions.GET_SUPPLIER_MATERIALS]: [
    MaterialsRoles.SUPPLIER_MATERIALS_VIEWER,
  ],
  [MaterialsPermissions.PERSIST_SUPPLIER_MATERIAL]: [
    MaterialsRoles.SUPPLIER_MATERIALS_PERSISTER,
  ],
  [MaterialsPermissions.DELETE_SUPPLIER_MATERIAL]: [
    MaterialsRoles.SUPPLIER_MATERIALS_DELETER,
  ],

  [MaterialsPermissions.BULK_IMPORT_MATERIALS]: [MaterialsRoles.BULK_IMPORTER],
  [MaterialsPermissions.BULK_IMPORT_SUPPLIER_MATERIALS]: [
    MaterialsRoles.SUPPLIER_MATERIALS_BULK_IMPORTER,
  ],
};
