export const createAutoFifo = <T>({
  flush,
  maxBatchSize,
  idleMillis = 1000,
}: {
  flush: (items: Array<T>) => Promise<void>;
  maxBatchSize: number;
  idleMillis?: number;
}) => {
  const buffer: Array<T> = [];

  let isFlushing = false;
  let isDestroyed = false;

  let onFinished = () => {};
  const finished = new Promise<void>((resolve) => {
    onFinished = resolve;
  });

  const doFlush = async () => {
    // if we're already flushing, or there's nothing to flush, bail out
    if (isFlushing || !buffer.length) {
      return;
    }

    isFlushing = true;
    const itemsToFlush = buffer.splice(0, maxBatchSize);
    try {
      await flush(itemsToFlush);

      if (isDestroyed && buffer.length === 0) {
        onFinished();
      }
    } catch (err) {
      buffer.unshift(...itemsToFlush);
      // something went wrong, but we don't want to lose the items
      // that we failed to flush, so do nothing...
      if (isDestroyed) {
        // ...unless we're already destroyed, in which case we just want to return the unflushed items
        onFinished();
        isFlushing = false;
        return;
      }
    }

    // idle
    await new Promise((resolve) => setTimeout(resolve, idleMillis));

    // done flushing
    isFlushing = false;

    // call ourselves again to flush any remaining items
    doFlush();
  };

  return {
    isDestroyed: () => isDestroyed,

    enqueue(items: Array<T>) {
      if (isDestroyed) {
        throw new Error(
          'Auto FIFO is destroyed and further enqueue operations are forbidden'
        );
      }
      buffer.push(...items);
      doFlush();
    },

    async destroy(): Promise<Array<T>> {
      // set the flag to prevent further enqueues and flushes
      isDestroyed = true;
      await finished;
      // return any unflushed items
      return [...buffer];
    },
  };
};
