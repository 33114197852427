import {
  throttle,
  takeEvery,
  call,
  put,
  race,
  delay,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import { SagaConfig, ActionType } from './dealTemplateTypes';
import { DealTemplateItem } from '@payaca/types/dealTemplateTypes';

import {
  applyDealTemplateFailure,
  applyDealTemplateSuccess,
  clearDealTemplates,
  getDealTemplateItemsFailure,
  getDealTemplateItemsSuccess,
  getDealTemplatesFailure,
  getDealTemplatesSuccess,
  persistDealTemplateFailure,
  persistDealTemplateSuccess,
} from './dealTemplateActions';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const dealTemplateSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  function* handleGetDealTemplates(
    action: PayloadAction<
      ActionType.REQUEST_GET_DEAL_TEMPLATES,
      {
        searchTerms: string;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getDealTemplates, action.payload.searchTerms),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get Project templates timed out.';
        yield put(getDealTemplatesFailure(new Error(errorMessage)));
      } else {
        yield put(getDealTemplatesSuccess(response));
      }
    } catch (error: any) {
      yield put(getDealTemplatesFailure(error));
    }
  }

  function* handleGetDealTemplateItems(
    action: PayloadAction<
      ActionType.REQUEST_GET_DEAL_TEMPLATE_ITEMS,
      {
        dealId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getDealTemplateItems, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get Project template items timed out.';
        yield put(
          getDealTemplateItemsFailure(
            action.payload.dealId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(getDealTemplateItemsSuccess(response));
      }
    } catch (error: any) {
      yield put(getDealTemplateItemsFailure(action.payload.dealId, error));
    }
  }

  function* handlePersistDealTemplate(
    action: PayloadAction<
      ActionType.REQUEST_PERSIST_DEAL_TEMPLATE,
      {
        dealTemplateTitle: string;
        dealTemplateItems: DealTemplateItem[];
        dealTemplateId: number | null;
        callback: any;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(
          persistDealTemplate,
          action.payload.dealTemplateTitle,
          action.payload.dealTemplateItems,
          action.payload.dealTemplateId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Persist Project template timed out.';
        yield put(persistDealTemplateFailure(new Error(errorMessage)));
      } else {
        yield put(persistDealTemplateSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(persistDealTemplateFailure(error));
    }
  }

  const getDealTemplates = async (searchTerms: string) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/deal_templates?searchTerms=${encodeURI(searchTerms)}`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getDealTemplates failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getDealTemplateItems = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/deals/${dealId}/template_items`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getDealTemplateItems failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const persistDealTemplate = async (
    dealTemplateTitle: string,
    dealTemplateItems: DealTemplateItem[],
    dealTemplateId: number | null
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/deal_templates`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
      body: JSON.stringify({
        dealTemplateTitle,
        dealTemplateItems,
        dealTemplateId,
      }),
    }).then(async (response) => {
      if (response.ok) {
        return response;
      } else {
        throw new Error(
          `persistDealTemplate failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleApplyDealTemplate(
    action: PayloadAction<
      ActionType.REQUEST_APPLY_DEAL_TEMPLATE,
      {
        dealId: number;
        dealTemplateId: number;
        callback: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(
          applyDealTemplate,
          action.payload.dealId,
          action.payload.dealTemplateId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Apply Project template timed out.';
        yield put(applyDealTemplateFailure(new Error(errorMessage)));
      } else {
        yield put(applyDealTemplateSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(applyDealTemplateFailure(error));
    }
  }

  const applyDealTemplate = async (dealId: number, dealTemplateId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/deals/${dealId}/template/${dealTemplateId}`,
      {
        method: 'POST',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return response;
      } else {
        throw new Error(
          `applyDealTemplate failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleAppLogout() {
    yield put(clearDealTemplates());
  }

  return function* () {
    yield throttle(
      1200,
      ActionType.REQUEST_GET_DEAL_TEMPLATES,
      handleGetDealTemplates
    );
    yield takeLatest(
      ActionType.REQUEST_GET_DEAL_TEMPLATE_ITEMS,
      handleGetDealTemplateItems
    );
    yield takeLatest(
      ActionType.REQUEST_PERSIST_DEAL_TEMPLATE,
      handlePersistDealTemplate
    );
    yield takeLatest(
      ActionType.REQUEST_APPLY_DEAL_TEMPLATE,
      handleApplyDealTemplate
    );
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default dealTemplateSagaCreator;
