import {
  takeEvery,
  call,
  put,
  race,
  delay,
  takeLatest,
} from 'redux-saga/effects';
import { handleAsyncAction } from '../utils';

import { PayloadAction } from 'typesafe-actions';

import {
  SagaConfig,
  ActionType,
  CreateInvoiceRequestData,
  UpdateInvoiceRequestData,
  SendInvoiceRequestData,
  VoidInvoiceAction,
  GetInvoiceGeneratedPdfUrl,
  GetUpcomingReference,
  PushInvoiceToAccountingIntegrationsAction,
  GetListedInvoices,
} from './invoicesTypes';
import {
  clearInvoices,
  clearInvoiceLines,
  getInvoiceFailure,
  getInvoiceSuccess,
  getInvoicesForDealSuccess,
  getInvoicesForDealFailure,
  getInvoiceLineFailure,
  getInvoiceLineSuccess,
  getInvoiceLinesForDealSuccess,
  getInvoiceLinesForDealFailure,
  getInvoiceLinesForInvoiceFailure,
  getInvoiceLinesForInvoiceSuccess,
  markInvoiceAsSentFailure,
  sendInvoiceFailure,
  createInvoiceFailure,
  clearPaymentReconciliationRecords,
  createInvoiceSuccess,
  updateInvoiceFailure,
  updateInvoiceSuccess,
  getInvoiceValidationResultFailure,
  getInvoiceValidationResultSuccess,
  markInvoiceAsSentSuccess,
  sendInvoiceSuccess,
  voidInvoice,
  getInvoiceGeneratedPdfUrl,
  getPaymentReconciliationRecordsForDealSuccess,
  getUpcomingReference,
  pushInvoiceToAccountingIntegrations,
  getListedInvoices,
} from './invoicesActions';

import {
  passResponseErrorMessagesToErrorCallback,
  Req,
} from '@payaca/helpers/storeHelper';

import {
  Invoice,
  InvoiceLine,
  ListedInvoice,
} from '@payaca/types/invoiceTypes';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { ListViewPage } from '@payaca/types/listViewTypes';

const invoicesSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleUpdateInvoice(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_INVOICE,
      {
        callback: () => void;
        updateInvoiceRequestData: UpdateInvoiceRequestData;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { timeout } = yield race({
        response: call(updateInvoice, action.payload.updateInvoiceRequestData),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Update invoice timed out.';
        yield put(
          updateInvoiceFailure(
            action.payload.updateInvoiceRequestData.invoiceId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(
          updateInvoiceSuccess(
            action.payload.updateInvoiceRequestData.invoiceId
          )
        );
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(
        updateInvoiceFailure(
          action.payload.updateInvoiceRequestData.invoiceId,
          error
        )
      );
    }
  }

  const updateInvoice = async (
    updateInvoiceRequestData: UpdateInvoiceRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/invoices/${updateInvoiceRequestData.invoiceId}`,
      {
        method: 'PUT',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Invoice': 'true',
        },
        body: JSON.stringify(updateInvoiceRequestData),
      }
    ).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `updateInvoice failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetInvoice(
    action: PayloadAction<
      ActionType.REQUEST_GET_INVOICE,
      {
        invoiceId: number;
        callback: (invoice: Invoice) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getInvoice, action.payload.invoiceId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get invoice timed out.';
        yield put(
          getInvoiceFailure(action.payload.invoiceId, new Error(errorMessage))
        );
      } else {
        yield put(getInvoiceSuccess(action.payload.invoiceId, response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(getInvoiceFailure(action.payload.invoiceId, error));
    }
  }

  const getInvoice = async (invoiceId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/invoices/${invoiceId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Invoice': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getInvoice failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetInvoicesForDeal(
    action: PayloadAction<
      ActionType.REQUEST_GET_INVOICES_FOR_DEAL,
      {
        dealId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getInvoicesForDeal, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get invoices for Project timed out.';
        yield put(getInvoicesForDealFailure(new Error(errorMessage)));
      } else {
        yield put(getInvoicesForDealSuccess(response));
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(getInvoicesForDealFailure(error));
    }
  }

  const getInvoicesForDeal = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/invoices/deal/${dealId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getInvoicesForDeal failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetPaymentReconciliationRecordsForDeal(
    action: PayloadAction<
      ActionType.REQUEST_GET_PAYMENT_RECONCILIATION_RECORDS_FOR_DEAL,
      {
        dealId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getPaymentReconciliationRecordsForDeal,
          action.payload.dealId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error(
          'getPaymentReconciliationRecordsForDeal request timed out'
        );
      }

      yield put(getPaymentReconciliationRecordsForDealSuccess(response));
      action.payload.callback && action.payload.callback();
    } catch (error: any) {}
  }

  const getPaymentReconciliationRecordsForDeal = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/invoices/payment_reconciliation_records/deal/${dealId}`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getPaymentReconciliationRecordsForDeal failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetInvoiceLine(
    action: PayloadAction<
      ActionType.REQUEST_GET_INVOICE_LINE,
      {
        invoiceLineId: number;
        callback: (invoiceLine: InvoiceLine) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getInvoiceLine, action.payload.invoiceLineId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get invoice line timed out.';
        yield put(
          getInvoiceLineFailure(
            action.payload.invoiceLineId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(
          getInvoiceLineSuccess(action.payload.invoiceLineId, response)
        );
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(getInvoiceLineFailure(action.payload.invoiceLineId, error));
    }
  }

  const getInvoiceLine = async (invoiceLineId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/invoices/invoice_lines/${invoiceLineId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Invoice': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getInvoiceLine failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetInvoiceLinesForDeal(
    action: PayloadAction<
      ActionType.REQUEST_GET_INVOICE_LINES_FOR_DEAL,
      {
        dealId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getInvoiceLinesForDeal, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get invoice lines for Project timed out.';
        yield put(getInvoiceLinesForDealFailure(new Error(errorMessage)));
      } else {
        yield put(getInvoiceLinesForDealSuccess(response));
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(getInvoiceLinesForDealFailure(error));
    }
  }

  const getInvoiceLinesForDeal = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/invoices/invoice_lines/deal/${dealId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getInvoiceLinesForDeal failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetInvoiceLinesForInvoice(
    action: PayloadAction<
      ActionType.REQUEST_GET_INVOICE_LINES_FOR_INVOICE,
      {
        invoiceId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getInvoiceLinesForInvoice, action.payload.invoiceId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get invoice lines for invoice timed out.';
        yield put(getInvoiceLinesForInvoiceFailure(new Error(errorMessage)));
      } else {
        yield put(getInvoiceLinesForInvoiceSuccess(response));
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(getInvoiceLinesForInvoiceFailure(error));
    }
  }

  const getInvoiceLinesForInvoice = async (invoiceId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/invoices/${invoiceId}/invoice_lines`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getInvoiceLinesForInvoice failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleMarkInvoiceAsSent(
    action: PayloadAction<
      ActionType.REQUEST_MARK_INVOICE_AS_SENT,
      {
        invoiceId: number;
        callback: () => void;
        errorCallback?: (errorMessages: string[]) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { timeout } = yield race({
        response: call(
          markInvoiceAsSent,
          action.payload.invoiceId,
          action.payload.errorCallback
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Mark invoice as sent timed out.';
        yield put(markInvoiceAsSentFailure(new Error(errorMessage)));
        action.payload.errorCallback &&
          action.payload.errorCallback([errorMessage]);
      } else {
        yield put(markInvoiceAsSentSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(markInvoiceAsSentFailure(error));
    }
  }

  const markInvoiceAsSent = async (
    invoiceId: number,
    errorCallback?: (errorMessages: string[]) => void
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/invoices/${invoiceId}/mark_as_sent`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Invoice': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        passResponseErrorMessagesToErrorCallback(response, errorCallback);
        throw new Error(
          `markInvoiceAsSent failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleSendInvoice(
    action: PayloadAction<
      ActionType.REQUEST_SEND_INVOICE,
      {
        sendInvoiceRequestData: SendInvoiceRequestData;
        callback?: () => void;
        errorCallback?: (errorMessages: string[]) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          sendInvoice,
          action.payload.sendInvoiceRequestData,
          action.payload.errorCallback
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Send invoice timed out.';
        yield put(sendInvoiceFailure(new Error(errorMessage)));
        action.payload.errorCallback &&
          action.payload.errorCallback([errorMessage]);
      } else {
        yield put(sendInvoiceSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(sendInvoiceFailure(error));
    }
  }

  const sendInvoice = async (
    sendInvoiceRequestData: SendInvoiceRequestData,
    errorCallback?: (errorMessages: string[]) => void
  ) => {
    const authHeader = await getAuthHeader();
    return fetch(
      `${apiBaseurl}/api/invoices/${sendInvoiceRequestData.invoiceId}/send`,
      {
        method: 'POST',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
        body: JSON.stringify(sendInvoiceRequestData),
      }
    ).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        passResponseErrorMessagesToErrorCallback(response, errorCallback);
        throw new Error(
          `sendInvoice failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleCreateInvoice(
    action: PayloadAction<
      ActionType.REQUEST_CREATE_INVOICE,
      {
        createInvoiceRequestData: CreateInvoiceRequestData;
        callback: (invoiceId: number) => void;
        onErrorCallback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(createInvoice, action.payload.createInvoiceRequestData),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Create invoice timed out.';
        yield put(createInvoiceFailure(new Error(errorMessage)));
        action.payload?.onErrorCallback?.();
      } else {
        yield put(createInvoiceSuccess());
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(createInvoiceFailure(error));
      action.payload?.onErrorCallback?.();
    }
  }

  const createInvoice = async (
    createInvoiceRequestData: CreateInvoiceRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/invoices`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
      body: JSON.stringify(createInvoiceRequestData),
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `createInvoice failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetInvoiceValidationResult(
    action: PayloadAction<
      ActionType.REQUEST_GET_INVOICE_VALIDATION_RESULT,
      {
        invoiceId: number;
        callback: (validationResult: {
          isValid: boolean;
          errors: string[];
        }) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getInvoiceValidationResult, action.payload.invoiceId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get invoice validation result timed out.';
        yield put(getInvoiceValidationResultFailure(new Error(errorMessage)));
      } else {
        yield put(getInvoiceValidationResultSuccess(response));

        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(getInvoiceValidationResultFailure(error));
    }
  }

  const getInvoiceValidationResult = async (invoiceId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/invoices/${invoiceId}/validation_result`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getInvoiceValidationResult failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const handleVoidInvoice = handleAsyncAction<VoidInvoiceAction>(
    voidInvoice,
    async (payload) => {
      const authHeader = await getAuthHeader();
      const response = await fetch(
        `${apiBaseurl}/api/invoices/${payload.invoiceId}/void`,
        {
          method: 'POST',
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
            'X-Simple-Job': 'true',
            'X-Native-App': `${isNativeApp}`,
          },
        }
      );
      if (response.ok) {
        return;
      } else {
        throw new Error('Failed to void invoice');
      }
    },
    (_, payload) => {
      payload.payload?.callback?.();
    },
    (error, payload) => {
      payload.payload?.onErrorCallback?.(error);
    }
  );

  const handleGetInvoiceGeneratedPdfUrl =
    handleAsyncAction<GetInvoiceGeneratedPdfUrl>(
      getInvoiceGeneratedPdfUrl,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/invoices/${payload.invoiceId}/generated_pdf_url`,
          {
            method: 'GET',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
              'X-Native-App': `${isNativeApp}`,
            },
          }
        );
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to get change proposal generated pdf url');
        }
      },
      (response, payload) => {
        payload.payload?.callback?.(response as string);
      },
      (error, payload) => {
        payload.payload?.onErrorCallback?.(error);
      }
    );

  const handleGetUpcomingReference = handleAsyncAction<GetUpcomingReference>(
    getUpcomingReference,
    async () => {
      const response = await req.get(`/invoices/upcoming_reference`);
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to get upcoming invoice reference');
      }
    },
    (response, payload) => {
      payload.payload?.callback?.(+(response as number));
    },
    (error, payload) => {
      payload.payload?.onErrorCallback?.(error);
    }
  );

  const handleGetListedInvoices = handleAsyncAction<GetListedInvoices>(
    getListedInvoices,
    async (payload) => {
      const searchParams = new URLSearchParams();
      if (payload.requestData.pageNumber) {
        searchParams.set(
          'pageNumber',
          payload.requestData.pageNumber.toString()
        );
      }
      if (payload.requestData.pageSize) {
        searchParams.set('pageSize', payload.requestData.pageSize.toString());
      }
      if (payload.requestData.searchTerm) {
        searchParams.set('searchTerm', payload.requestData.searchTerm);
      }
      if (payload.requestData.sortBy) {
        searchParams.set('sortBy', payload.requestData.sortBy);
      }
      if (payload.requestData.sortDirection) {
        searchParams.set('sortDirection', payload.requestData.sortDirection);
      }
      if (payload.requestData.statuses) {
        payload.requestData.statuses.forEach((status, i) => {
          searchParams.set(`statuses[${i}]`, status);
        });
      }

      const response = await req.get(`/invoices?${searchParams.toString()}`);
      return response.json();
    },
    (response, payload) => {
      payload.payload?.callback?.(response as ListViewPage<ListedInvoice>);
    },
    (error, payload) => {
      payload.payload?.onErrorCallback?.(error);
    }
  );

  function* handleAppLogout() {
    yield put(clearInvoices());
    yield put(clearInvoiceLines());
    yield put(clearPaymentReconciliationRecords());
  }

  return function* () {
    yield takeEvery(ActionType.REQUEST_GET_INVOICE, handleGetInvoice);
    yield takeEvery(
      ActionType.REQUEST_GET_INVOICES_FOR_DEAL,
      handleGetInvoicesForDeal
    );
    yield takeEvery(ActionType.REQUEST_GET_INVOICE_LINE, handleGetInvoiceLine);
    yield takeEvery(
      ActionType.REQUEST_GET_INVOICE_LINES_FOR_DEAL,
      handleGetInvoiceLinesForDeal
    );
    yield takeEvery(
      ActionType.REQUEST_GET_INVOICE_LINES_FOR_INVOICE,
      handleGetInvoiceLinesForInvoice
    );
    yield takeEvery(
      ActionType.REQUEST_MARK_INVOICE_AS_SENT,
      handleMarkInvoiceAsSent
    );
    yield takeEvery(ActionType.REQUEST_UPDATE_INVOICE, handleUpdateInvoice);
    yield takeEvery(
      ActionType.REQUEST_GET_PAYMENT_RECONCILIATION_RECORDS_FOR_DEAL,
      handleGetPaymentReconciliationRecordsForDeal
    );
    yield takeEvery(ActionType.REQUEST_SEND_INVOICE, handleSendInvoice);
    yield takeEvery(ActionType.REQUEST_CREATE_INVOICE, handleCreateInvoice);
    yield takeEvery(
      ActionType.REQUEST_GET_INVOICE_VALIDATION_RESULT,
      handleGetInvoiceValidationResult
    );
    yield takeLatest(ActionType.REQUEST_VOID_INVOICE, handleVoidInvoice);
    yield takeLatest(
      ActionType.REQUEST_GET_INVOICE_GENERATED_PDF_URL,
      handleGetInvoiceGeneratedPdfUrl
    );
    yield takeLatest(
      ActionType.GET_UPCOMING_REFERENCE_REQUEST,
      handleGetUpcomingReference
    );
    yield takeEvery(
      ActionType.PUSH_INVOICE_TO_ACCOUNTING_INTEGRATIONS_REQUEST,
      handleAsyncAction<PushInvoiceToAccountingIntegrationsAction>(
        pushInvoiceToAccountingIntegrations,
        async (payload) => {
          try {
            await req.post(`/invoices/${payload.invoiceId}/sync`, {});
          } catch (err) {
            console.error(
              'Failed to push invoice to accounting integrations',
              err
            );
          }
        }
      )
    );
    yield takeLatest(
      ActionType.GET_LISTED_INVOICES_REQUEST,
      handleGetListedInvoices
    );
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default invoicesSagaCreator;
