import {
  takeEvery,
  call,
  put,
  race,
  delay,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import {
  SagaConfig,
  ActionType,
  AddDealAddressRequestData,
  SetDealPipelineAction,
  GetDealAcceptedItemsCopy,
  GetListedPipelineDealsAction,
} from './dealsTypes';
import {
  Deal,
  DealAddresses,
  NewDealConfig,
  UpdateDealRequestData,
} from '@payaca/types/dealTypes';
import {
  GetListedDealsRequestData,
  ListedDealsListViewPage,
  PipelineListedDeals,
} from '@payaca/types/listedDealTypes';

import {
  activateDealViewFailure,
  activateDealViewSuccess,
  archiveDealsFailure,
  archiveDealsSuccess,
  clearDeals,
  createDealFailure,
  createDealSuccess,
  getDealFailure,
  getDealSuccess,
  getListedDealsPageFailure,
  getListedDealsPageSuccess,
  requestGetDeal,
  setDealCustomerFailure,
  setDealCustomerSuccess,
  unsetDealCustomerFailure,
  unsetDealCustomerSuccess,
  updateDealFailure,
  updateDealSuccess,
  addDealAddressFailure,
  addDealAddressSuccess,
  removeDealAddressFailure,
  removeDealAddressSuccess,
  restoreDealsSuccess,
  restoreDealsFailure,
  setDealPipeline,
  getDealAcceptedItemsCopy,
  getListedPipelineDeals,
} from './dealsActions';

import { handleAsyncAction } from '../utils';
import { isNullish } from '@payaca/utilities/guards';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const dealsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  function* handleGetDeal(
    action: PayloadAction<
      ActionType.REQUEST_GET_DEAL,
      {
        dealId: number;
        callback?: (deal: Deal) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getDeal, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get Project timed out.';
        yield put(
          getDealFailure(action.payload.dealId, new Error(errorMessage))
        );
      } else {
        yield put(getDealSuccess(action.payload.dealId, response));
        action.payload.callback?.(response);
      }
    } catch (error: any) {
      yield put(getDealFailure(action.payload.dealId, error));
    }
  }

  function* handleUpdateDeal(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_DEAL,
      {
        dealId: number;
        dealUpdates: UpdateDealRequestData;
        callback?: (deal: Deal) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          updateDeal,
          action.payload.dealId,
          action.payload.dealUpdates
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Update Project timed out.';
        yield put(
          updateDealFailure(action.payload.dealId, new Error(errorMessage))
        );
      } else {
        yield put(updateDealSuccess(action.payload.dealId, response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(updateDealFailure(action.payload.dealId, error));
    }
  }

  function* handleActivateDealView(
    action: PayloadAction<
      ActionType.REQUEST_ACTIVATE_DEAL_VIEW,
      { accountId: number; onActivated: any }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { timeout } = yield race({
        response: call(activateDealView, action.payload.accountId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Activate Project view timed out.';
        yield put(activateDealViewFailure(new Error(errorMessage)));
      } else {
        yield put(activateDealViewSuccess());
        action.payload.onActivated && action.payload.onActivated();
      }
    } catch (error: any) {
      yield put(activateDealViewFailure(error));
    }
  }

  function* handleCreateDeal(
    action: PayloadAction<
      ActionType.REQUEST_CREATE_DEAL,
      {
        onCreateDealSuccess: (dealId: number) => void;
        config: NewDealConfig;
        onCreateDealFailure: (error: Error) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(createDeal, action.payload.config),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Create Project timed out.';
        yield put(createDealFailure(new Error(errorMessage)));
      } else {
        const { dealId } = response;
        yield put(createDealSuccess(dealId));
        action.payload.onCreateDealSuccess?.(dealId);
      }
    } catch (error: any) {
      yield put(createDealFailure(error));
      action.payload.onCreateDealFailure?.(error);
    }
  }

  function* handleSetDealCustomer(
    action: PayloadAction<
      ActionType.REQUEST_SET_DEAL_CUSTOMER,
      {
        dealId: number;
        customerId?: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          setDealCustomer,
          action.payload.dealId,
          action.payload.customerId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Set Project customer timed out.';
        yield put(setDealCustomerFailure(new Error(errorMessage)));
      } else {
        yield put(setDealCustomerSuccess(action.payload.dealId));
      }
    } catch (error: any) {
      yield put(setDealCustomerFailure(error));
    }
  }

  function* handleUnsetDealCustomer(
    action: PayloadAction<
      ActionType.REQUEST_UNSET_DEAL_CUSTOMER,
      {
        dealId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(unsetDealCustomer, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Unset Project customer timed out.';
        yield put(unsetDealCustomerFailure(new Error(errorMessage)));
      } else {
        yield put(unsetDealCustomerSuccess(action.payload.dealId));
      }
    } catch (error: any) {
      yield put(unsetDealCustomerFailure(error));
    }
  }

  function* handleAddDealAddress(
    action: PayloadAction<
      ActionType.REQUEST_ADD_DEAL_ADDRESS,
      {
        addDealAddressRequestData: AddDealAddressRequestData;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          addDealAddress,
          action.payload.addDealAddressRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Add Project address timed out.';
        yield put(addDealAddressFailure(new Error(errorMessage)));
      } else {
        yield put(addDealAddressSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(addDealAddressFailure(error));
    }
  }

  function* handleRemoveDealAddress(
    action: PayloadAction<
      ActionType.REQUEST_REMOVE_DEAL_ADDRESS,
      {
        dealId: number;
        dealAddressId: number;
        callback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          removeDealAddress,
          action.payload.dealId,
          action.payload.dealAddressId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Remove Project address timed out.';
        yield put(removeDealAddressFailure(new Error(errorMessage)));
      } else {
        yield put(removeDealAddressSuccess());
        action.payload.callback && action.payload.callback();
      }
    } catch (error: any) {
      yield put(removeDealAddressFailure(error));
    }
  }

  const addDealAddress = async (
    addDealAddressRequestData: AddDealAddressRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/deals/${addDealAddressRequestData.dealId}/addresses`,
      {
        method: 'POST',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
        body: JSON.stringify({
          type: addDealAddressRequestData.dealAddressType,
          address: addDealAddressRequestData.address,
          addAddressToDealCustomer:
            addDealAddressRequestData.addAddressToDealCustomer,
          contacts: addDealAddressRequestData.contacts,
        }),
      }
    ).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `addDealAddress failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const removeDealAddress = async (dealId: number, dealAddressId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/deals/${dealId}/addresses/${dealAddressId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Deal': 'true',
        },
      }
    ).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `removeDealAddress failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const activateDealView = async (accountId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/accounts/${accountId}/activate_deal_view`, {
      method: 'PATCH',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `activateDealView failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getDeal = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/deals/${dealId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getDeal failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const createDeal = async (config: NewDealConfig) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/deals`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
      body: JSON.stringify(config),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `createDeal failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const updateDeal = async (
    dealId: number,
    dealUpdates: UpdateDealRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/deals/${dealId}`, {
      method: 'PUT',
      body: JSON.stringify(dealUpdates),
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `updateDeal failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const setDealCustomer = async (dealId: number, customerId?: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/deals/${dealId}/customer`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
      body: JSON.stringify({ customerId: customerId }),
    }).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `setDealCustomer failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const unsetDealCustomer = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/deals/${dealId}/customer`, {
      method: 'DELETE',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `unsetDealCustomer failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleGetListedDealsPage(
    action: PayloadAction<
      ActionType.REQUEST_GET_LISTED_DEALS_PAGE,
      {
        getListedDealsRequestData: GetListedDealsRequestData;
        callback?: (listedDealsPage: ListedDealsListViewPage) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);

    try {
      const { response, timeout } = yield race({
        response: call(
          getListedDealsPage,
          action.payload.getListedDealsRequestData
        ),
        timeout: delay(60000),
      });

      if (timeout) {
        yield put(
          getListedDealsPageFailure(
            new Error('Get listed Projects request timed out.')
          )
        );
      } else {
        yield put(getListedDealsPageSuccess(response));
        action.payload.callback?.(response);
      }
    } catch (error: any) {
      yield put(getListedDealsPageFailure(error));
    }
  }

  const getListedDealsPage = async (
    getListedDealsRequestData: GetListedDealsRequestData
  ) => {
    return fetch(
      `${apiBaseurl}/api/listed_deals?pageNumber=${
        getListedDealsRequestData.pageNumber
      }&pageSize=${getListedDealsRequestData.pageSize}&searchTerm=${
        getListedDealsRequestData.searchTerm || ''
      }&sortBy=${getListedDealsRequestData.sortBy || ''}&sortDirection=${
        getListedDealsRequestData.sortDirection || ''
      }&types=${
        getListedDealsRequestData.types
          ? getListedDealsRequestData.types.join(',')
          : ''
      }&readableStatuses=${
        getListedDealsRequestData.readableStatuses
          ? getListedDealsRequestData.readableStatuses.join(',')
          : ''
      }&userAssignments=${
        getListedDealsRequestData.userAssignments
          ? getListedDealsRequestData.userAssignments.join(',')
          : ''
      }&customerIds=${
        getListedDealsRequestData.customerIds
          ? getListedDealsRequestData.customerIds.join(',')
          : ''
      }&tagIds=${
        getListedDealsRequestData.tagIds
          ? getListedDealsRequestData.tagIds.join(',')
          : ''
      }&pipelineStages=${
        getListedDealsRequestData.pipelineStages
          ? getListedDealsRequestData.pipelineStages.join(',')
          : ''
      }&pipelineId=${
        isNullish(getListedDealsRequestData.pipelineId)
          ? ''
          : getListedDealsRequestData.pipelineId
      }&archived=${!!getListedDealsRequestData?.archived}&searchTarget=${
        getListedDealsRequestData.searchTarget || ''
      }&excludeDealsWithUpcomingEvents=${!!getListedDealsRequestData?.excludeDealsWithUpcomingEvents}`,
      {
        method: 'GET',
        headers: {
          Authorization: await getAuthHeader(),
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `GetListedDealsPage failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const archiveDeal = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/deals/${dealId}/archive`, {
      method: 'PUT',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Archive Project failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const archiveDeals = async (dealIds: number[]) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/deals/bulk_archive`, {
      method: 'PUT',
      body: JSON.stringify({
        dealIds: dealIds,
      }),
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `Archive Projects failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleArchiveDeals(
    action: PayloadAction<
      ActionType.REQUEST_ARCHIVE_DEALS,
      {
        dealIds: number[];
        onArchiveSuccess?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    const isSingleDeal = action.payload.dealIds?.length === 1;

    try {
      const { response, timeout } = yield race({
        response: isSingleDeal
          ? call(archiveDeal, action.payload.dealIds[0])
          : call(archiveDeals, action.payload.dealIds),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          archiveDealsFailure(new Error('Archive Projects timed out.'))
        );
      } else {
        action.payload.onArchiveSuccess && action.payload.onArchiveSuccess();
        yield put(getDealSuccess(response.id, response));
        yield put(archiveDealsSuccess());
      }
    } catch (error: any) {
      yield put(archiveDealsFailure(error));
    }
  }

  const restoreDeal = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/deals/${dealId}/restore`, {
      method: 'PUT',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `Restore Project failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const restoreDeals = async (dealIds: number[]) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/deals/bulk_restore`, {
      method: 'PUT',
      body: JSON.stringify({
        dealIds: dealIds,
      }),
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Job': 'true',
      },
    }).then((response) => {
      if (response.ok) {
        return;
      } else {
        throw new Error(
          `Restore Projects failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleRestoreDeals(
    action: PayloadAction<
      ActionType.REQUEST_RESTORE_DEALS,
      {
        dealIds: number[];
        onRestoreSuccess?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    const isSingleDeal = action.payload.dealIds?.length === 1;

    try {
      const { response, timeout } = yield race({
        response: isSingleDeal
          ? call(restoreDeal, action.payload.dealIds[0])
          : call(restoreDeals, action.payload.dealIds),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          restoreDealsFailure(new Error('Restore Projects timed out.'))
        );
      } else {
        action.payload.onRestoreSuccess && action.payload.onRestoreSuccess();
        yield put(getDealSuccess(response.id, response));
        yield put(restoreDealsSuccess());
      }
    } catch (error: any) {
      yield put(restoreDealsFailure(error));
    }
  }

  function* handleSetCustomerSuccess(
    action: PayloadAction<
      ActionType.SET_DEAL_CUSTOMER_SUCCESS,
      {
        dealId: number;
      }
    >
  ) {
    yield put(requestGetDeal(action.payload.dealId));
  }

  function* handleUnsetCustomerSuccess(
    action: PayloadAction<
      ActionType.UNSET_DEAL_CUSTOMER_SUCCESS,
      {
        dealId: number;
      }
    >
  ) {
    yield put(requestGetDeal(action.payload.dealId));
  }

  const handleSetDealPipeline = handleAsyncAction<SetDealPipelineAction>(
    setDealPipeline,
    async (payload) => {
      const authHeader = await getAuthHeader();
      const response = await fetch(
        `${apiBaseurl}/api/deals/${payload.dealId}/pipeline`,
        {
          method: 'POST',
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
            'X-Simple-Job': 'true',
            'X-Native-App': `${isNativeApp}`,
          },
          body: JSON.stringify({ pipelineId: payload.pipelineId }),
        }
      );
      if (response.ok) {
        return;
      } else {
        throw new Error('Failed to set Project pipeline');
      }
    },
    (_, payload) => {
      payload.payload?.callback?.();
    }
  );

  const handleGetDealAcceptedItemsCopy =
    handleAsyncAction<GetDealAcceptedItemsCopy>(
      getDealAcceptedItemsCopy,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/deals/${payload.dealId}/accepted_items_copy`,
          {
            method: 'GET',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Deal': 'true',
            },
          }
        );
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to get accepted items copy');
        }
      },
      (result, payload) => {
        payload.payload?.callback?.(result as string);
      }
    );

  const handleGetPipelineListedDeals =
    handleAsyncAction<GetListedPipelineDealsAction>(
      getListedPipelineDeals,
      async ({ args }) => {
        const response = await fetch(
          `${apiBaseurl}/api/listed_pipeline_deals?pageNumber=${
            args.pageNumber
          }&pageSize=${args.pageSize}&searchTerm=${
            args.searchTerm || ''
          }&sortBy=${args.sortBy || ''}&sortDirection=${
            args.sortDirection || ''
          }&types=${args.types ? args.types.join(',') : ''}&readableStatuses=${
            args.readableStatuses ? args.readableStatuses.join(',') : ''
          }&userAssignments=${
            args.userAssignments ? args.userAssignments.join(',') : ''
          }&customerIds=${
            args.customerIds ? args.customerIds.join(',') : ''
          }&tagIds=${args.tagIds ? args.tagIds.join(',') : ''}&pipelineStages=${
            args.pipelineStages ? args.pipelineStages.join(',') : ''
          }&pipelineId=${
            isNullish(args.pipelineId) ? '0' : args.pipelineId
          }&archived=${!!args?.archived}&searchTarget=${
            args.searchTarget || ''
          }&excludeDealsWithUpcomingEvents=${!!args?.excludeDealsWithUpcomingEvents}`,
          {
            method: 'GET',
            headers: {
              Authorization: await getAuthHeader(),
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
            },
          }
        );

        return response.json();
      },
      (_response, requestData) => {
        requestData.payload.callback?.(_response as PipelineListedDeals);
      },
      (_err, requestData) => {
        requestData.payload.errorCallback?.(_err);
      }
    );

  function* handleAppLogout() {
    yield put(clearDeals());
  }

  return function* () {
    yield takeLatest(
      ActionType.REQUEST_ACTIVATE_DEAL_VIEW,
      handleActivateDealView
    );
    yield takeEvery(ActionType.REQUEST_GET_DEAL, handleGetDeal);
    yield takeLatest(ActionType.REQUEST_UPDATE_DEAL, handleUpdateDeal);
    yield takeLatest(ActionType.REQUEST_CREATE_DEAL, handleCreateDeal);
    yield takeLatest(
      ActionType.REQUEST_SET_DEAL_CUSTOMER,
      handleSetDealCustomer
    );
    yield takeLatest(
      ActionType.SET_DEAL_CUSTOMER_SUCCESS,
      handleSetCustomerSuccess
    );
    yield takeLatest(
      ActionType.REQUEST_UNSET_DEAL_CUSTOMER,
      handleUnsetDealCustomer
    );
    yield takeLatest(
      ActionType.UNSET_DEAL_CUSTOMER_SUCCESS,
      handleUnsetCustomerSuccess
    );
    yield takeLatest(
      ActionType.REQUEST_GET_LISTED_DEALS_PAGE,
      handleGetListedDealsPage
    );
    yield takeEvery(ActionType.REQUEST_ARCHIVE_DEALS, handleArchiveDeals);
    yield takeEvery(ActionType.REQUEST_RESTORE_DEALS, handleRestoreDeals);
    yield takeEvery('auth.logout', handleAppLogout);
    yield takeLatest(ActionType.REQUEST_ADD_DEAL_ADDRESS, handleAddDealAddress);
    yield takeLatest(
      ActionType.REQUEST_REMOVE_DEAL_ADDRESS,
      handleRemoveDealAddress
    );
    yield takeLatest(
      ActionType.SET_DEAL_PIPELINE_REQUEST,
      handleSetDealPipeline
    );
    yield takeLatest(
      ActionType.GET_DEAL_ACCEPTED_ITEMS_COPY_REQUEST,
      handleGetDealAcceptedItemsCopy
    );
    yield takeLatest(
      ActionType.GET_LISTED_PIPELINE_DEALS_REQUEST,
      handleGetPipelineListedDeals
    );
    // yield takeLatest(
    //   ActionType.GET_LISTED_PIPELINE_DEALS_REQUEST,
    //   handleAsyncAction<GetListedPipelineDealsAction>(getListedPipelineDeals, async ({ args, callback, errorCallback }) => {
    //     try {
    //       const response = await fetch(
    //         `${apiBaseurl}/api/listed_pipeline_deals?pageNumber=${
    //           args.pageNumber
    //         }&pageSize=${args.pageSize}&searchTerm=${
    //           args.searchTerm || ''
    //         }&sortBy=${args.sortBy || ''}&sortDirection=${
    //           args.sortDirection || ''
    //         }&types=${
    //           args.types
    //             ? args.types.join(',')
    //             : ''
    //         }&readableStatuses=${
    //           args.readableStatuses
    //             ? args.readableStatuses.join(',')
    //             : ''
    //         }&userAssignments=${
    //           args.userAssignments
    //             ? args.userAssignments.join(',')
    //             : ''
    //         }&customerIds=${
    //           args.customerIds
    //             ? args.customerIds.join(',')
    //             : ''
    //         }&tagIds=${
    //           args.tagIds
    //             ? args.tagIds.join(',')
    //             : ''
    //         }&pipelineStages=${
    //           args.pipelineStages
    //             ? args.pipelineStages.join(',')
    //             : ''
    //         }&pipelineId=${
    //           isNullish(args.pipelineId)
    //             ? '0'
    //             : args.pipelineId
    //         }&archived=${!!args?.archived}&searchTarget=${
    //           args.searchTarget || ''
    //         }&excludeDealsWithUpcomingEvents=${!!args?.excludeDealsWithUpcomingEvents}`,
    //         {
    //           method: 'GET',
    //           headers: {
    //             Authorization: await getAuthHeader(),
    //             'Content-Type': 'application/json',
    //             'X-Simple-Job': 'true',
    //           },
    //         }
    //       );

    //       if (response.ok) {
    //         console.log('i am here...')
    //         const result = await response.json();
    //         console.log(result);
    //         callback(result as any);
    //       } else {
    //         throw new Error('Failed to get listed deals for pipeline');
    //       }
    //     } catch (err) {
    //       errorCallback(err as Error);
    //     }
    //   }
    // ));
  };
};

export default dealsSagaCreator;
