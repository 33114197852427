// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import {
  GetServicePlansSubscriptionsInput,
  PaginationInput,
} from '@/gql/graphql';

const servicePlanKeys = {
  all: ['service-plan'] as const,
  servicePlansSubscribers: (
    input?: GetServicePlansSubscriptionsInput,
    pagination?: PaginationInput
  ) => {
    if (!input) {
      return [...servicePlanKeys.all, 'service-plans-subscriptions'] as const;
    }

    if (!pagination) {
      return [
        ...servicePlanKeys.all,
        'service-plans-subscriptions',
        input,
      ] as const;
    }

    return [
      ...servicePlanKeys.all,
      'service-plans-subscriptions',
      input,
      pagination,
    ] as const;
  },
};

export default servicePlanKeys;
