import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash-es';

import { JobLineItem, JobLineItemBase } from '@payaca/types/jobContentTypes';
import { UpdateJobLineItemRequestData } from '@payaca/store/jobContent/jobContentTypes';

import JobLineItemControl from '../jobLineItemControl/JobLineItemControl';
import AddJobLineItemControl from './AddJobLineItemControl';
import SortableList from '@payaca/components/sortableList/SortableList';

import { getJobLineItem, getJobLineItemGroup } from '@/utils/stateAccessors';
import { sortByKey } from '@payaca/utilities/sortable';

import { useSelector } from '@/api/state';

import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';

import { getSortedRequiredMultipleOptionalItems } from '@payaca/helpers/jobLineItemHelper';

import './JobLineItemsControl.sass';

type Props = {
  jobLineItemGroupId: number;
  jobIsInvoice: boolean;
  handleOpenCreateJobLineItemModal: (
    jobLineItemId: number,
    initialItemData?: Partial<JobLineItemBase>
  ) => void;
  handleCreateJobLineItemAndOpenCreateJobLineItemModal: (
    lineItemId: number | null,
    initialItemData?: Partial<JobLineItemBase>
  ) => void;
  handleOpenAdvancedSearchLineItemModal: (searchTerm?: string) => void;
  disabledItemIds: number[];
};

const JobLineItemsControl: FC<Props> = ({
  jobLineItemGroupId,
  jobIsInvoice,
  handleOpenCreateJobLineItemModal,
  handleCreateJobLineItemAndOpenCreateJobLineItemModal,
  handleOpenAdvancedSearchLineItemModal,
  disabledItemIds,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const jobLineItemGroup = useSelector((state) =>
    getJobLineItemGroup(state, jobLineItemGroupId)
  );

  const jobLineItemIds = jobLineItemGroup?.jobLineItemIds ?? [];

  const sortedJobLineItems = useSelector((state) => {
    const jlis = jobLineItemIds
      .map((id: number) => getJobLineItem(state, id))
      .filter(Boolean) as Array<JobLineItem>;
    return jlis.sort(sortByKey('positionIndex'));
  });

  const [isUpdatingPositions, setIsUpdatingPositions] = useState(false);

  const [pendingSortedJobLineItems, setPendingSortedJobLineItems] =
    useState(null);

  const onDragEnd = (
    activeItem: any,
    destinationIndex: number,
    updatedItems: any
  ) => {
    if (activeItem && jobLineItemGroup?.jobContentId) {
      setIsUpdatingPositions(true);

      // validate positions - i.e. items in required, multiple choice, optional order
      const orderedUpdatedItems = Object.values(
        getSortedRequiredMultipleOptionalItems(updatedItems)
      ).flat();

      if (isEqual(orderedUpdatedItems, updatedItems)) {
        // update local sorted jlis
        setPendingSortedJobLineItems(updatedItems);

        dispatch(
          jobContentActions.requestUpdateJobLineItem(
            {
              ...activeItem,
              positionIndex: destinationIndex,
              updateLineItem: false,
            } as UpdateJobLineItemRequestData,
            () => {
              // get updated group
              dispatch(
                jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
                  jobLineItemGroup.jobContentId,
                  () => {
                    setIsUpdatingPositions(false);
                    // clear pending sorted job line items, so we can now use the saved fetched job line items (or revert if failed to save)
                    setPendingSortedJobLineItems(null);
                  }
                )
              );
            }
          )
        );
      } else {
        // invalid order set
        setIsUpdatingPositions(false);
      }
    }
  };

  return (
    <div className="job-line-items-control">
      <div className="job-line-item-group-controls-container">
        <SortableList
          id={`job-line-items-control-${jobLineItemGroup?.id}`}
          items={pendingSortedJobLineItems || sortedJobLineItems}
          onDragEnd={onDragEnd}
          isDisabled={isUpdatingPositions}
          renderItem={({ jobLineItemId }) => (
            <JobLineItemControl
              jobLineItemId={jobLineItemId}
              key={`job-line-item-control-${jobLineItemId}`}
              handleOpenCreateJobLineItemModal={
                handleOpenCreateJobLineItemModal
              }
            />
          )}
        />
      </div>

      <div className="create-or-add-existing-container">
        <AddJobLineItemControl
          handleOpenAdvancedSearchLineItemModal={
            handleOpenAdvancedSearchLineItemModal
          }
          handleCreateJobLineItemAndOpenCreateJobLineItemModal={
            handleCreateJobLineItemAndOpenCreateJobLineItemModal
          }
          disabledItemIds={disabledItemIds}
        />
      </div>
    </div>
  );
};
export default JobLineItemsControl;
