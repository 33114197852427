import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '@/api/state';
import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';
import Button from '@payaca/components/button/Button';
import { ButtonColourVariant, ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import Table, { ITableProps } from '@payaca/components/plTable/Table';
import Tooltip from '@payaca/components/tooltip/Tooltip';
import { PublicHydratedServicePlanDiscountCode } from '@payaca/types/service-plans';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
import { ServicePlanPermissions } from '@payaca/permissions/service-plans/service-plans.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { getUserRoles } from '@/utils/stateAccessors';

const ServicePlanDiscountCodesListed: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const [isDeletingServicePlan, setIsDeletingServicePlan] = useState(false);
  const [showDeleteDiscountCodeModal, setShowDeleteDiscountCodeModal] =
    useState<false | PublicHydratedServicePlanDiscountCode['publicId']>(false);

  const isGettingListedDiscountCodes = useSelector(
    (state) => state.servicePlans.isGettingListedDiscountCodes
  );
  const listedDiscountCodes = useSelector(
    (state) => state.servicePlans.listedDiscountCodes
  );

  const userRoles = useSelector(getUserRoles);

  useEffect(() => {
    dispatch(servicePlansActions.getListedDiscountCodes.request({}));
  }, []);

  const tableQuickActions = useMemo(() => {
    const actions: ITableProps<PublicHydratedServicePlanDiscountCode>['quickActions'] =
      [
        {
          name: 'Copy Code',
          onClick: (row) =>
            navigator.clipboard.writeText(row.code).then(() =>
              showDynamicFeedbackMessage({
                lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
                title: 'Copied!',
                feedbackLevel: FeedbackLevel.NEUTRAL,
                isCancellable: true,
              })
            ),
        },
      ];

    if (
      userHasRequiredPermission(userRoles, [
        ServicePlanPermissions.PERSIST_DISCOUNT,
      ])
    ) {
      actions.push({
        name: 'Delete',
        onClick: (row) => {
          setShowDeleteDiscountCodeModal(row.publicId);
        },
      });
    }

    return actions;
  }, [userRoles]);

  return (
    <>
      <Table
        uniqueKey="publicId"
        isLoading={isGettingListedDiscountCodes}
        data={listedDiscountCodes}
        heading="Discount Codes"
        createButton={
          userHasRequiredPermission(userRoles, [
            ServicePlanPermissions.PERSIST_DISCOUNT,
          ])
            ? {
                label: 'Create Discount Code',
                onClick: () =>
                  history.push('/service-plans/discount-codes/new'),
              }
            : undefined
        }
        quickActions={tableQuickActions}
      >
        <Table.Column header="Name" field="name" />
        <Table.Column
          header="PercentOff"
          field="percentOff"
          render={(field) => field + '%'}
        />
        <Table.Column
          header="Duration"
          field="duration"
          render={(field) => field.charAt(0).toUpperCase() + field.slice(1)}
        />
        <Table.Column header="Duration in Months" field="durationInMonths" />
        <Table.Column<PublicHydratedServicePlanDiscountCode, 'expiryDate'>
          header="Expiries"
          field="expiryDate"
          render={(expiryDate) =>
            expiryDate ? moment(expiryDate).format('DD MMMM YYYY') : undefined
          }
        />
        <Table.Column<PublicHydratedServicePlanDiscountCode, 'appliesTo'>
          header="Appiles To"
          field="appliesTo"
          render={(appliesTo) =>
            appliesTo.length ? (
              <Tooltip text={appliesTo.map((i) => i.name).join(', ')}>
                <>{`${appliesTo.length} service plan${
                  appliesTo.length ? 's' : ''
                }`}</>
              </Tooltip>
            ) : (
              'All service plans'
            )
          }
        />
        <Table.Column header="Code" field="code" />
      </Table>

      <Modal
        title="Delete Discount Code"
        isOpen={!!showDeleteDiscountCodeModal}
        onClose={() => setShowDeleteDiscountCodeModal(false)}
        actions={
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            colourVariant={ButtonColourVariant.RED}
            isProcessing={isDeletingServicePlan}
            isDisabled={isDeletingServicePlan}
            onClick={() => {
              if (!showDeleteDiscountCodeModal) return;

              setIsDeletingServicePlan(true);

              dispatch(
                servicePlansActions.deleteServicePlanDiscountCode.request({
                  servicePlanDiscountCodePublicId: showDeleteDiscountCodeModal,
                  callback: () => {
                    dispatch(
                      servicePlansActions.getListedDiscountCodes.request({})
                    );
                    setIsDeletingServicePlan(false);
                    setShowDeleteDiscountCodeModal(false);
                  },
                  onErrorCallback: () => {
                    showDynamicFeedbackMessage({
                      lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
                      title:
                        'Failed to delete discount code, try again later or contact support.',
                      feedbackLevel: FeedbackLevel.NEUTRAL,
                      isCancellable: true,
                    });
                    setIsDeletingServicePlan(false);
                    setShowDeleteDiscountCodeModal(false);
                  },
                })
              );
            }}
          >
            Delete
          </Button>
        }
      >
        <p>Are you sure you want to delete this service plan discount code?</p>

        <p>
          Deleting a discount code does not affect any customers who have
          already applied the discount; it means that new customers can’t redeem
          the discount.
        </p>
      </Modal>
    </>
  );
};

export default ServicePlanDiscountCodesListed;
