import { Tag } from '@payaca/types/tagTypes';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import TagControl, {
  TagControlAlignment,
} from '@payaca/components/tagControl/TagControl';
import { useDispatch } from 'react-redux';
import * as tagsActions from '@payaca/store/tags/tagsActions';
import * as pipelineActions from '@payaca/store/pipelines/pipelineActions';
import { useSelector } from '@/api/state';

type Props = {
  label?: string;
  selectedTagIds: number[];
  isReadOnly?: boolean;
  onSelectedTagsChange: (selectedTagIds: number[]) => void;
  alignment?: TagControlAlignment;
};
const TagSelectionControl: FC<Props> = ({
  label,
  selectedTagIds,
  isReadOnly = false,
  onSelectedTagsChange,
  alignment,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const existingTags = useSelector((state) => state.tags.tags);

  const selectedTags = useMemo(() => {
    return selectedTagIds
      .map((x) => existingTags.find((y: Tag) => y.id === x))
      .filter((x) => !!x) as Array<Tag>;
  }, [existingTags, selectedTagIds]);

  const availableTags = useMemo(() => {
    return existingTags.filter((x: Tag) => {
      if (selectedTagIds.includes(x.id)) return false;
      return true;
    });
  }, [selectedTagIds, existingTags]);

  useEffect(() => {
    dispatch(tagsActions.requestGetTags());
    dispatch(pipelineActions.requestGetAccountPipelines());
  }, []);

  const addExistingTag = useCallback(
    (tagId: number) => {
      onSelectedTagsChange([...selectedTagIds, tagId]);
    },
    [selectedTagIds, onSelectedTagsChange]
  );

  const removeTag = useCallback(
    (tagId: number) => {
      onSelectedTagsChange(selectedTagIds.filter((x) => x != tagId));
    },
    [selectedTagIds, onSelectedTagsChange]
  );

  return (
    <div className="entity-tag-control">
      <TagControl
        label={label}
        tags={selectedTags}
        availableTags={availableTags}
        isReadOnly={isReadOnly}
        isRemovingTag={false}
        onAddAvailableTag={addExistingTag}
        onRemoveTag={removeTag}
        alignment={alignment}
      />
    </div>
  );
};

export default TagSelectionControl;
