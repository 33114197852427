import { Reducer } from 'redux';

import { mergeFetchedEntities } from '../utils';

import { ActionType, State } from './jobsTypes';

export const initialState: State = {
  isResendingJob: false,
  isResentJobSuccessfully: undefined,
  resendJobErrorMessage: undefined,

  isMarkingJobAsSent: false,
  isJobMarkedAsSentSuccessfully: undefined,
  markJobAsSentErrorMessage: undefined,

  isEditingJob: false,
  editJobSuccess: undefined,
  editJobErrorMessage: undefined,

  isUpdatingJob: false,
  isUpdatingJobAttachment: false,

  isGettingJobValidationResult: false,

  jobs: {},
  jobAttachments: {},

  isGettingJobsForDeal: false,

  jobUpdateResults: {},

  isSendingQuoteToCustomer: false,
  isSendingInvoiceToCustomer: false,

  isArchivingJob: false,
  isAccpetingQuote: false,
  isDecliningJob: false,
  isConvertingJobToInvoice: false,
  isDuplicatingJob: false,

  isCreatingJob: false,
};

const jobsReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_JOBS_FOR_DEAL:
      return {
        ...state,
        isGettingJobsForDeal: true,
      };
    case ActionType.GET_JOBS_FOR_DEAL_SUCCESS:
      return {
        ...state,
        jobs: mergeFetchedEntities({
          cache: state.jobs,
          values: action.payload,
        }),
        isGettingJobsForDeal: false,
      };
    case ActionType.GET_JOBS_FOR_DEAL_FAILURE:
      return {
        ...state,
        isGettingJobsForDeal: false,
      };
    case ActionType.REQUEST_GET_JOB_VALIDATION_RESULT:
      return {
        ...state,
        isGettingJobValidationResult: true,
      };
    case ActionType.GET_JOB_VALIDATION_RESULT_FAILURE:
    case ActionType.GET_JOB_VALIDATION_RESULT_SUCCESS:
      return {
        ...state,
        isGettingJobValidationResult: false,
      };
    // RESEND JOB
    case ActionType.REQUEST_RESEND_JOB:
      return {
        ...state,
        isResendingJob: true,
        isResentJobSuccessfully: undefined,
        resendJobErrorMessage: undefined,
      };
    case ActionType.RESEND_JOB_SUCCESS:
      return {
        ...state,
        isResendingJob: false,
        isResentJobSuccessfully: true,
        resendJobErrorMessage: undefined,
      };
    case ActionType.RESEND_JOB_FAILURE:
      return {
        ...state,
        isResendingJob: false,
        isResentJobSuccessfully: false,
        resendJobErrorMessage: action.payload.errorMessage,
      };
    case ActionType.CLEAR_RESEND_JOB:
      return {
        ...state,
        isResentJobSuccessfully: undefined,
        resendJobErrorMessage: undefined,
      };

    // EDIT JOB
    case ActionType.REQUEST_EDIT_JOB:
      return {
        ...state,
        isEditingJob: true,
        editJobSuccess: undefined,
        editJobErrorMessage: undefined,
      };
    case ActionType.EDIT_JOB_SUCCESS:
      return {
        ...state,
        isEditingJob: false,
        editJobSuccess: action.payload.newJob,
        editJobErrorMessage: undefined,
      };
    case ActionType.EDIT_JOB_FAILURE:
      return {
        ...state,
        isEditingJob: false,
        editJobSuccess: undefined,
        editJobErrorMessage: action.payload.errorMessage,
      };
    case ActionType.CLEAR_EDIT_JOB:
      return {
        ...state,
        isEditingJob: false,
        editJobSuccess: undefined,
        editJobErrorMessage: undefined,
      };

    // MARK JOB AS SENT
    case ActionType.REQUEST_MARK_JOB_AS_SENT:
      return {
        ...state,
        isMarkingJobAsSent: true,
        isJobMarkedAsSentSuccessfully: undefined,
        markJobAsSentErrorMessage: undefined,
      };
    case ActionType.MARK_JOB_AS_SENT_SUCCESS:
      return {
        ...state,
        isMarkingJobAsSent: false,
        isJobMarkedAsSentSuccessfully: true,
        markJobAsSentErrorMessage: undefined,
      };
    case ActionType.MARK_JOB_AS_SENT_FAILURE:
      return {
        ...state,
        isMarkingJobAsSent: false,
        isJobMarkedAsSentSuccessfully: false,
        markJobAsSentErrorMessage: action.payload.errorMessage,
      };
    case ActionType.CLEAR_MARK_JOB_AS_SENT:
      return {
        ...state,
        isJobMarkedAsSentSuccessfully: undefined,
        markJobAsSentErrorMessage: undefined,
      };

    case ActionType.REQUEST_GET_JOB: {
      const fetchedEntity = state.jobs && state.jobs[action.payload.jobId];

      return {
        ...state,
        jobs: {
          ...state.jobs,
          [action.payload.jobId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_JOB_SUCCESS: {
      const fetchedEntity = state.jobs && state.jobs[action.payload.jobId];

      return {
        ...state,
        jobs: {
          ...state.jobs,
          [action.payload.jobId]: {
            ...fetchedEntity,
            entity: action.payload.job,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.GET_JOB_FAILURE: {
      const fetchedEntity = state.jobs && state.jobs[action.payload.jobId];

      return {
        ...state,
        jobs: {
          ...state.jobs,
          [action.payload.jobId]: {
            ...fetchedEntity,
            fetchFailedAtAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.CLEAR_JOB: {
      const jobs = state.jobs || {};
      delete jobs[action.payload.jobId];

      return {
        ...state,
        jobs: jobs,
      };
    }
    case ActionType.CLEAR_JOBS: {
      return {
        ...state,
        jobs: {},
      };
    }

    // UPDATE JOB
    case ActionType.REQUEST_UPDATE_JOB:
      return {
        ...state,
        isUpdatingJob: true,
        jobUpdateResults: {
          ...state.jobUpdateResults,
          [action.payload.updateJobRequestData.jobId]: {
            isUpdatedSuccessfully: undefined,
            updateError: undefined,
          },
        },
      };

    case ActionType.PARTIAL_UPDATE_JOB_REQUEST:
      return {
        ...state,
        isUpdatingJob: true,
        jobUpdateResults: {
          ...state.jobUpdateResults,
          [action.payload.jobId]: {
            isUpdatedSuccessfully: undefined,
            updateError: undefined,
          },
        },
      };
    case ActionType.UPDATE_JOB_SUCCESS:
      return {
        ...state,
        isUpdatingJob: false,
        jobUpdateResults: {
          ...state.jobUpdateResults,
          [action.payload.jobId]: {
            isUpdatedSuccessfully: true,
            updateError: undefined,
          },
        },
      };
    case ActionType.UPDATE_JOB_FAILURE:
      return {
        ...state,
        isUpdatingJob: false,
        jobUpdateResults: {
          ...state.jobUpdateResults,
          [action.payload.jobId]: {
            isUpdatedSuccessfully: false,
            updateError: action.error,
          },
        },
      };

    case ActionType.GET_JOB_ATTACHMENT_SUCCESS: {
      const fetchedEntity =
        state.jobAttachments &&
        state.jobAttachments[action.payload.jobAttachmentId];

      return {
        ...state,
        jobAttachments: {
          ...state.jobAttachments,
          [action.payload.jobAttachmentId]: {
            ...fetchedEntity,
            entity: action.payload.jobAttachment,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.GET_JOB_ATTACHMENT_FAILURE: {
      const fetchedEntity =
        state.jobAttachments && state.jobAttachments[action.payload.jobId];

      return {
        ...state,
        jobAttachments: {
          ...state.jobAttachments,
          [action.payload.jobAttachmentId]: {
            ...fetchedEntity,
            fetchFailedAtAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.CLEAR_JOB_ATTACHMENT: {
      const jobAttachments = state.jobAttachments || {};
      delete jobAttachments[action.payload.jobAttachmentId];

      return {
        ...state,
        jobAttachments: jobAttachments,
      };
    }
    case ActionType.CLEAR_JOB_ATTACHMENTS: {
      return {
        ...state,
        jobAttachments: {},
      };
    }

    // UPDATE JOB
    case ActionType.REQUEST_UPDATE_JOB_ATTACHMENT:
      return {
        ...state,
        isUpdatingJobAttachment: true,
      };
    case ActionType.UPDATE_JOB_ATTACHMENT_SUCCESS:
    case ActionType.UPDATE_JOB_ATTACHMENT_FAILURE:
      return {
        ...state,
        isUpdatingJobAttachment: false,
      };

    case ActionType.CLEAR_JOB_UPDATE_RESULTS:
      return {
        ...state,
        jobUpdateResults: {},
      };

    // SEND QUOTE
    case ActionType.REQUEST_SEND_QUOTE_TO_CUSTOMER:
      return {
        ...state,
        isSendingQuoteToCustomer: true,
      };
    case ActionType.SEND_QUOTE_TO_CUSTOMER_SUCCESS:
      return {
        ...state,
        isSendingQuoteToCustomer: false,
      };
    case ActionType.SEND_QUOTE_TO_CUSTOMER_FAILURE:
      return {
        ...state,
        isSendingQuoteToCustomer: false,
      };

    // SEND INVOICE
    case ActionType.REQUEST_SEND_INVOICE_TO_CUSTOMER:
      return {
        ...state,
        isSendingInvoiceToCustomer: true,
      };
    case ActionType.SEND_INVOICE_TO_CUSTOMER_SUCCESS:
      return {
        ...state,
        isSendingInvoiceToCustomer: false,
      };
    case ActionType.SEND_INVOICE_TO_CUSTOMER_FAILURE:
      return {
        ...state,
        isSendingInvoiceToCustomer: false,
      };

    // ARCHIVE JOB
    case ActionType.REQUEST_ARCHIVE_JOB:
      return {
        ...state,
        isArchivingJob: true,
      };
    case ActionType.ARCHIVE_JOB_SUCCESS:
      return {
        ...state,
        isArchivingJob: false,
      };
    case ActionType.ARCHIVE_JOB_FAILURE:
      return {
        ...state,
        isArchivingJob: false,
      };

    // ACCEPT QUOTE
    case ActionType.REQUEST_ACCEPT_QUOTE:
      return {
        ...state,
        isAccpetingQuote: true,
      };
    // eslint-disable-next-line no-duplicate-case
    case ActionType.SEND_INVOICE_TO_CUSTOMER_SUCCESS:
      return {
        ...state,
        isAccpetingQuote: false,
      };
    // eslint-disable-next-line no-duplicate-case
    case ActionType.SEND_INVOICE_TO_CUSTOMER_FAILURE:
      return {
        ...state,
        isAccpetingQuote: false,
      };

    // DECLINE JOB
    case ActionType.REQUEST_DECLINE_JOB:
      return {
        ...state,
        isDecliningJob: true,
      };
    // eslint-disable-next-line no-duplicate-case
    case ActionType.SEND_INVOICE_TO_CUSTOMER_SUCCESS:
      return {
        ...state,
        isDecliningJob: false,
      };
    // eslint-disable-next-line no-duplicate-case
    case ActionType.SEND_INVOICE_TO_CUSTOMER_FAILURE:
      return {
        ...state,
        isDecliningJob: false,
      };

    // CONVERT JOB TO INVOICE
    case ActionType.REQUEST_CONVERT_JOB_TO_INVOICE:
      return {
        ...state,
        isConvertingJobToInvoice: true,
      };
    // eslint-disable-next-line no-duplicate-case
    case ActionType.SEND_INVOICE_TO_CUSTOMER_SUCCESS:
      return {
        ...state,
        isConvertingJobToInvoice: false,
      };
    // eslint-disable-next-line no-duplicate-case
    case ActionType.SEND_INVOICE_TO_CUSTOMER_FAILURE:
      return {
        ...state,
        isConvertingJobToInvoice: false,
      };

    // DUPLICATE JOB
    case ActionType.REQUEST_DUPLICATE_JOB:
      return {
        ...state,
        isDuplicatingJob: true,
      };
    case ActionType.DUPLICATE_JOB_SUCCESS:
      return {
        ...state,
        isDuplicatingJob: false,
      };
    case ActionType.DUPLICATE_JOB_FAILURE:
      return {
        ...state,
        isDuplicatingJob: false,
      };

    // DUPLICATE JOB
    case ActionType.REQUEST_CREATE_JOB:
      return {
        ...state,
        isCreatingJob: true,
      };
    case ActionType.CREATE_JOB_SUCCESS:
      return {
        ...state,
        isCreatingJob: false,
      };
    case ActionType.CREATE_JOB_FAILURE:
      return {
        ...state,
        isCreatingJob: false,
      };

    case ActionType.GET_JOB_ATTACHMENTS_FOR_JOB_SUCCESS:
      return {
        ...state,
        jobAttachments: mergeFetchedEntities({
          cache: state.jobAttachments,
          values: action.payload,
        }),
      };

    default:
      return state;
  }
};

export default jobsReducer;
