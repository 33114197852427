import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  UpdateCustomerInput,
  UpdateCustomerMutation as _UpdateCustomerMutation,
} from '@/gql/graphql';

const UpdateCustomerMutation = graphql(`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
    }
  }
`);

const useUpdateCustomer = (
  options?: UseMutationOptions<
    _UpdateCustomerMutation,
    unknown,
    UpdateCustomerInput
  >
) => {
  return useMutation({
    mutationFn: (input: UpdateCustomerInput) => {
      return gqlClient.request(UpdateCustomerMutation, { input });
    },
    ...options,
  });
};

export default useUpdateCustomer;
