import { Reducer } from 'redux';

import { ActionType, State } from './listedUsersTypes';

export const initialState: State = {
  isGettingListedUsersPage: false,
  listedUsersPage: null,
};

const listedUsersReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_LISTED_USERS_PAGE:
      return { ...state, isGettingListedUsersPage: true };
    case ActionType.GET_LISTED_USERS_PAGE_FAILURE:
      return {
        ...state,
        listedUsersPage: null,
        isGettingListedUsersPage: false,
      };
    case ActionType.GET_LISTED_USERS_PAGE_SUCCESS:
      return {
        ...state,
        listedUsersPage: action.payload.listedUsersPage,
        isGettingListedUsersPage: false,
      };
    case ActionType.CLEAR_LISTED_USERS_PAGE:
      return { ...state, listedUsersPage: null };
    default:
      return state;
  }
};

export default listedUsersReducer;
