import { Req } from '@payaca/helpers/storeHelper';

import { put, takeEvery, takeLatest } from 'redux-saga/effects';

import { handleAsyncAction } from '../utils';
import {
  createTemplate,
  deleteTemplate,
  getListedTemplatesPage,
  getTemplate,
  updateTemplate,
} from './templatesActions';

import { SagaConfig, ActionType } from './templatesTypes';
import { ListedTemplatesPage } from '@payaca/types/listedTemplateTypes';
import {
  EntityTemplate,
  HydratedEntityTemplate,
  PublicEntityTemplate,
  PublicHydratedEntityTemplate,
  TemplatableEntity,
} from '@payaca/types/entity-templates';
import { getUploadsForEntitySuccess } from '../uploads/uploadsActions';
import { PayloadAction } from 'typesafe-actions';

const templatesSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);
  const providerReq = Req(
    `${apiBaseurl}/provider/rest`,
    getAuthHeader,
    isNativeApp
  );

  const handleGetListedTemplatesPage = handleAsyncAction(
    getListedTemplatesPage,
    async (args) => {
      const response = await req.get(
        `/entity-templates/listed-entity-templates?${(
          args.queryParams.entityTypes || []
        )
          .map((et) => `entityTypes[]=${et}`)
          .join('&')}&searchTerm=${
          args.queryParams.searchTerm || ''
        }&includeServicePlanTemplates=${
          args.queryParams.includeServicePlanTemplates || false
        }&pageNumber=${args.queryParams.pageNumber}&pageSize=${
          args.queryParams.pageSize
        }&sortBy=${args.queryParams.sortBy}&sortDirection=${
          args.queryParams.sortDirection
        }`
      );
      return response.json();
    },
    (response, requestData) => {
      requestData.payload.callback?.(response as ListedTemplatesPage);
    },
    (err, requestData) => {
      requestData.payload.onErrorCallback?.(err);
    }
  );

  const handleGetTemplate = handleAsyncAction(
    getTemplate,
    async (args) => {
      const response = await providerReq.get(`/templates/${args.publicId}`);
      return response.json();
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as EntityTemplate<TemplatableEntity>
      );
    },
    (_err, requestData) => {
      requestData.payload.onErrorCallback?.(_err);
    }
  );

  const handleUpdateTemplate = handleAsyncAction(
    updateTemplate,
    async (args) => {
      try {
        const response = await req.put(
          `/entity-templates/${args.publicId}`,
          args.template
        );
        return response.json();
      } catch (err) {
        console.log(`Update template failed: ${JSON.stringify(err)}`);
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as PublicHydratedEntityTemplate<TemplatableEntity>
      );
    }
  );

  const handleCreateTemplate = handleAsyncAction(
    createTemplate,
    async (args) => {
      try {
        const response = await req.post(`/entity-templates`, args.template);
        return response.json();
      } catch (err) {
        console.log(`Create template failed: ${JSON.stringify(err)}`);
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as PublicHydratedEntityTemplate<TemplatableEntity>
      );
    }
  );

  const handleDeleteTemplate = handleAsyncAction(
    deleteTemplate,
    async (args) => {
      try {
        const response = await req.del(`/entity-templates/${args.publicId}`);
        return response;
      } catch (err) {
        console.log(`Delete template failed: ${JSON.stringify(err)}`);
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    }
  );

  function* handleGetListedTemplatesPageSuccess({
    payload,
  }: PayloadAction<
    ActionType.GET_LISTED_TEMPLATES_PAGE_SUCCESS,
    ListedTemplatesPage
  >) {
    const uploads = payload.items.flatMap((item) => item.uploads);
    yield put(getUploadsForEntitySuccess(uploads));
  }

  function* handleGetTemplateSuccess({
    payload,
  }: PayloadAction<
    ActionType.UPDATE_TEMPLATE_SUCCESS | ActionType.CREATE_TEMPLATE_SUCCESS,
    PublicHydratedEntityTemplate<TemplatableEntity>
  >) {
    yield put(getUploadsForEntitySuccess(payload.uploads));
  }

  return function* () {
    yield takeEvery(
      ActionType.GET_LISTED_TEMPLATES_PAGE_REQUEST,
      handleGetListedTemplatesPage
    );
    yield takeLatest(ActionType.UPDATE_TEMPLATE_REQUEST, handleUpdateTemplate);
    yield takeLatest(ActionType.CREATE_TEMPLATE_REQUEST, handleCreateTemplate);
    yield takeLatest(ActionType.DELETE_TEMPLATE_REQUEST, handleDeleteTemplate);
    yield takeEvery(
      ActionType.GET_LISTED_TEMPLATES_PAGE_SUCCESS,
      handleGetListedTemplatesPageSuccess
    );
    yield takeEvery(ActionType.GET_TEMPLATE_REQUEST, handleGetTemplate);
    yield takeEvery(
      ActionType.UPDATE_TEMPLATE_SUCCESS,
      handleGetTemplateSuccess
    );
    yield takeEvery(
      ActionType.CREATE_TEMPLATE_SUCCESS,
      handleGetTemplateSuccess
    );
  };
};

export default templatesSagaCreator;
