import React, { FC } from 'react';
import CompanyLogo from '../companyLogo/CompanyLogo';
import moment from 'moment-timezone';
import './FormTemplateElements.sass';
import './BoilerServiceRecord.sass';
import OutlinedContentPanel, {
  ContentPanelPaddingVariant,
  ContentPanelTitleStyleVariant,
} from '../outlinedContentPanel/OutlinedContentPanel';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import {
  useCertificateNumber,
  useCommonFormAccountData,
  useInspectionFormAddresses,
} from './hooks';
import { formatAnswer } from '@payaca/helpers/formHelper';

import GasSafeLogo from './gas-safe.png';

interface Props {
  data: any;
  account: any;
}

const applianceRows = [
  { label: 'Location', key: 'location' },
  { label: 'Make', key: 'boiler_model_search.make', historicKey: 'make' },
  { label: 'Model', key: 'boiler_model_search.model', historicKey: 'model' },
  { label: 'Type', key: 'boiler_model_search.type', historicKey: 'type' },
  { label: 'Serial Number', key: 'serial_number' },
  { label: 'GC Number', key: 'gc_number' },
  { label: 'Flue Type', key: 'flue_type' },
  {
    label: 'Operating Pressure (mBar) or heat input (kW/h or Btu/h)',
    key: 'pressure',
  },
  { label: 'Operating Pressure (mBar)', key: 'operating_pressure' },
  { label: 'Heat input (kW/h or Btu/h)', key: 'heat_input' },
];

const applianceReadings = [
  {
    label: 'FGA min',
    key: 'initial_combustion_reading',
    cols: [
      { label: 'Ratio', key: 'initial_combustion_ratio' },
      { label: 'CO ppm', key: 'initial_combustion_co_ppm' },
      { label: 'CO2 (%)', key: 'initial_combustion_co2_percentage' },
    ],
  },
  {
    label: 'FGA max',
    key: 'final_combustion_reading',
    cols: [
      { label: 'Ratio', key: 'final_combustion_ratio' },
      { label: 'CO ppm', key: 'final_combustion_co_ppm' },
      { label: 'CO2 (%)', key: 'final_combustion_co2_percentage' },
    ],
  },
];

const inspections = [
  {
    field: 'electrical_bonding',
    title: 'Electrical bonding',
    text: 'electrical_bonding_defects',
  },
  {
    field: 'heat_exchanger',
    title: 'Heat exchanger',
    text: 'heat_exchanger_defects',
  },
  {
    field: 'burner_injectors',
    title: 'Burner',
    text: 'burner_injectors_defects',
  },
  {
    field: 'flame_picture',
    title: 'Flame picture',
    text: 'flame_picture_defects',
  },
  {
    field: 'ignition',
    title: 'Ignition',
    text: 'ignition_defects',
  },
  {
    field: 'electrics',
    title: 'Electrics',
    text: 'electrics_defects',
  },
  {
    field: 'controls',
    title: 'Controls',
    text: 'controls_defects',
  },
  {
    field: 'leaks_gas_water',
    title: 'Leak checks (gas/water)',
    text: 'leaks_gas_water_defects',
  },
  {
    field: 'gas_connections',
    title: 'Gas connections',
    text: 'gas_connections_defects',
  },
  {
    field: 'seals',
    title: 'Seals',
    text: 'seals_defects',
  },
  {
    field: 'pipework',
    title: 'Pipework',
    text: 'pipework_defects',
  },
  {
    field: 'fans',
    title: 'Fans',
    text: 'fans_defects',
  },
  {
    field: 'fan_pressure',
    title: 'Fan pressure',
    text: 'fan_pressure_defects',
  },
  {
    field: 'fireplace',
    title: 'Fireplace',
    text: 'fireplace_defects',
  },
  {
    field: 'closure_plate',
    title: 'Closure plate',
    text: 'closure_plate_defects',
  },
  {
    field: 'return_air_plenum',
    title: 'Return air / plenum',
    text: 'return_air_defects',
  },
  {
    field: 'ventilation',
    title: 'Ventilation',
    text: 'ventilation_defects',
  },
  {
    field: 'flue_termination',
    title: 'Flue termination',
    text: 'flue_termination_defects',
  },
  {
    field: 'smoke_pellet_flue_flow_test',
    title: 'Smoke pellet flue flow test',
    text: 'smoke_pellet_flue_flow_defects',
  },
  {
    field: 'smoke_match_spillage_test',
    title: 'Smoke match spillage test',
    text: 'smoke_match_spillage_defects',
  },
  {
    field: 'working_pressure',
    title: 'Working pressure',
    text: 'working_pressure_defects',
  },
  {
    field: 'safety_devices',
    title: 'Safety devices',
    text: 'safety_devices_defects',
  },
  {
    field: 'other_regulations',
    title: 'Other - Regulations etc',
    text: 'other_regulations_defects',
  },
  {
    field: 'gas_tightness_test',
    title: 'Gas tightness test',
    text: 'gas_tightness_defects',
  },
];

const inspectionsHalfIndex = Math.ceil(inspections.length / 2);

const BoilerServiceRecordFormTemplate: FC<Props> = ({ data, account }) => {
  const { brandColour } = useCommonFormAccountData(account);
  const certificateNumber = useCertificateNumber(data);
  const {
    accountAddressString,
    inspectionAddressString,
    landlordAddressString,
  } = useInspectionFormAddresses(account, data);

  if (!data || !account) return <></>;

  return (
    <div className={'form-template-output boiler-service-record'}>
      <div className={'form-template-header'}>
        <div className={'flex-container'}>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <h3>Boiler Service/Maintenance Record</h3>
        </div>
        <div className={'flex-container flex-center'}>
          <div className="header-details-container">
            <LabelValuePair label="Cert No" value={certificateNumber} />
          </div>
          {data.gas_safe_reg_number && (
            <img
              className="gas-safe-logo"
              src={GasSafeLogo}
              alt={'Gas Safe Logo'}
            />
          )}
        </div>
      </div>
      <div className={'form-template-row no-page-break'}>
        <OutlinedContentPanel
          className="business-details"
          title="Business Details"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label={'Engineer Name'} value={data.completed_by} />
          <LabelValuePair label={'Company'} value={account?.companyName} />
          <LabelValuePair label={'Address'} value={accountAddressString} />
          <LabelValuePair label={'Contact'} value={account?.contactNumber} />
          {data.gas_safe_reg_number && (
            <LabelValuePair
              label={'Gas Safe Reg'}
              value={data.gas_safe_reg_number}
            />
          )}
          {data.id_card_number && (
            <LabelValuePair
              label={'ID Card Number'}
              value={data.id_card_number}
            />
          )}
          <LabelValuePair label={'E-mail'} value={account?.email} />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          className="site-details"
          title="Inspection Details"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label={'Name'} value={data.name} />
          <LabelValuePair label={'Address'} value={inspectionAddressString} />
          <LabelValuePair label={'E-mail'} value={data.email} />
          <LabelValuePair label={'Contact'} value={data.contact} />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          className="client-details"
          title="Landlord/Agent Details"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label={'Name'} value={data.landlord_name} />
          <LabelValuePair label={'Address'} value={landlordAddressString} />
          <LabelValuePair label={'E-mail'} value={data.landlord_email} />
          <LabelValuePair label={'Contact'} value={data.landlord_contact} />
        </OutlinedContentPanel>
      </div>
      <div className={'form-template-row no-page-break'}>
        <OutlinedContentPanel
          className="appliance"
          title="Appliance Details and Readings"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <>
            {applianceRows.map((applianceRow, index) => {
              return (
                formatAnswer(
                  data,
                  applianceRow.key,
                  applianceRow.historicKey
                ) && (
                  <LabelValuePair
                    key={`appliance-row-${index}`}
                    label={applianceRow.label}
                    value={formatAnswer(
                      data,
                      applianceRow.key,
                      applianceRow.historicKey
                    )}
                  />
                )
              );
            })}
            {applianceReadings.map((applianceReading, index) => {
              return (
                <LabelValuePair
                  key={`appliance-reading-${index}`}
                  label={applianceReading.label}
                  value={
                    <div>
                      {applianceReading.cols.map(
                        (applianceReadingColumn, columnIndex) => {
                          return (
                            formatAnswer(
                              data,
                              `${applianceReading.key}.${applianceReadingColumn.key}`
                            ) && (
                              <LabelValuePair
                                key={`appliance-reading-${index}-column-${columnIndex}`}
                                label={applianceReadingColumn.label}
                                value={formatAnswer(
                                  data,
                                  `${applianceReading.key}.${applianceReadingColumn.key}`
                                )}
                              />
                            )
                          );
                        }
                      )}
                    </div>
                  }
                />
              );
            })}
          </>
        </OutlinedContentPanel>
      </div>
      <div className={'form-template-row'}>
        <OutlinedContentPanel
          className="inspections-defects-remedies"
          title="Inspections, Defects and Remedies"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          colourOverride={brandColour}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="flex-container">
            <table className="inspections-table">
              <thead style={{ borderColor: brandColour }}>
                <tr>
                  <td></td>
                  <td>Satisfactory?</td>
                  <td>Comments</td>
                </tr>
              </thead>
              <tbody>
                {inspections.slice(0, inspectionsHalfIndex).map(
                  (row: any, i: number) =>
                    data[row.field] && (
                      <tr key={i}>
                        <td>{row.title}</td>
                        <td>{formatAnswer(data, row.field)}</td>
                        <td>{data[row.text]}</td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
            <table className="inspections-table">
              <thead style={{ borderColor: brandColour }}>
                <tr>
                  <td></td>
                  <td>Satisfactory?</td>
                  <td>Comments</td>
                </tr>
              </thead>
              <tbody>
                {inspections.slice(-inspectionsHalfIndex).map(
                  (row: any, i: number) =>
                    data[row.field] && (
                      <tr key={i}>
                        <td>{row.title}</td>
                        <td>{formatAnswer(data, row.field)}</td>
                        <td>{data[row.text]}</td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row no-page-break">
        <OutlinedContentPanel
          className="findings-actions"
          colourOverride={brandColour}
          title={'Findings and Actions'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="Has the installation and appliance been deemed safe to use?"
            value={formatAnswer(data, 'safe_to_use')}
          />
          <LabelValuePair
            label="Has a warning notice been raised and warning notice labels or
            stickers been attached?"
            value={formatAnswer(data, 'warning_notice')}
          />
          <LabelValuePair
            label="Satisfactory installation"
            value={formatAnswer(data, 'satisfactory_installation')}
          />
          <LabelValuePair
            label="Necessary remedial work required"
            value={data.necessary_remedial_work || 'N/A'}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Completed by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Name" value={data.completed_by} />
          <LabelValuePair
            label="Signed"
            value={
              <div className={'form-template-signature'}>
                {data.engineer_signature && (
                  <img src={data.engineer_signature} />
                )}
              </div>
            }
          />
          <LabelValuePair
            label="Inspection conducted"
            value={moment(data.issue_date).format('DD/MM/YYYY')}
            noBreak={true}
          />
          <LabelValuePair
            label="Next Inspection Date"
            value={
              data.work_type === 'service'
                ? moment(data.next_inspection_due_date).format('DD/MM/YYYY')
                : 'not set'
            }
            noBreak={true}
          />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="Received by"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
          className="customer-signature"
        >
          <div>
            <LabelValuePair label="Name" value={data.name} />
            <LabelValuePair
              label="Signed"
              value={
                <div className={'form-template-signature'}>
                  {data.customer_signature && (
                    <img src={data.customer_signature} />
                  )}
                </div>
              }
            />
          </div>
          {data.customer_not_present && <p>Customer not present</p>}
        </OutlinedContentPanel>
      </div>
    </div>
  );
};

export default BoilerServiceRecordFormTemplate;
