import {
  FieldValidator,
  FieldValidationResult,
} from '@payaca/types/fieldValidationTypes';

import set from 'lodash.set';

import { validateForm as validateFormHelper } from '@payaca/helpers/formValidationHelper';

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export type TRenderFormContents<TFormState extends Record<string, any>> = (
  isValid: boolean,
  formState: TFormState,
  validationState: { [key: string]: FieldValidationResult },
  touchedState: { [key: string]: boolean },
  onFieldChange: (value: { [key: string]: any }) => void,
  onFieldTouch: (fieldName: string | string[]) => void
) => JSX.Element | JSX.Element[];

export type TFieldValidators<TFormState extends Record<string, any>> = {
  [key: string]: FieldValidator<TFormState>[];
};

export type TOnFormSubmit<TFormState extends Record<string, any>> = (
  formState: TFormState,
  e: React.FormEvent<HTMLFormElement>
) => void;

type Props<TFormState extends Record<string, any> = Record<string, any>> = {
  // Must be memorised
  onFormStateChange?: (newState: { [key: string]: any }) => void;
  onFormSubmit?: TOnFormSubmit<TFormState>;
  triggerValidationAt?: Date;
  // Must be memorised
  initialFormState: TFormState;
  // Must be memorised
  fieldValidators?: TFieldValidators<TFormState>;
  renderFormContents: TRenderFormContents<TFormState>;
  className?: string
};

const ValidatedForm = <T extends object = { [key: string]: any }>({
  onFormStateChange,
  onFormSubmit,
  triggerValidationAt,
  initialFormState,
  fieldValidators,
  renderFormContents,
  className
}: Props<T>): JSX.Element => {
  const [formState, setFormState] = useState(initialFormState);
  const [validationState, setValidationState] = useState<{
    [key: string]: FieldValidationResult;
  }>({});
  const [touchedState, setTouchedState] = useState<{ [key: string]: boolean }>(
    {}
  );
  const previousTriggerValidationAt = usePrevious(triggerValidationAt);

  const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    onFormSubmit?.(formState, e);
  };

  const validateForm = useCallback(() => {
    setValidationState(validateFormHelper<T>(formState, fieldValidators));
  }, [fieldValidators, formState]);

  const onFieldChange = useCallback(
    (value: { [key: string]: any }, callback?: any) => {
      setFormState((formState) => {
        const modifiedFormState = { ...formState };

        for (const fieldName in value) {
          set(modifiedFormState, fieldName, value[fieldName]);
        }

        callback && callback(modifiedFormState);
        onFormStateChange && onFormStateChange(modifiedFormState);

        return modifiedFormState;
      });
    },
    [validationState, onFormStateChange]
  );

  const onFieldTouch = useCallback((fieldName: string | string[]) => {
    if (Array.isArray(fieldName)) {
      setTouchedState((touchedState) => {
        return {
          ...touchedState,
          ...fieldName.reduce((acc: Record<string, boolean>, curr) => {
            acc[curr] = true;
            return acc;
          }, {}),
        };
      });
    } else {
      setTouchedState((touchedState) => {
        return {
          ...touchedState,
          [fieldName]: true,
        };
      });
    }
  }, []);

  const onTriggerValidate = useCallback(() => {
    const modifiedTouchedState: { [key: string]: boolean } = {};
    for (const fieldName in fieldValidators) {
      modifiedTouchedState[fieldName] = true;
    }

    setTouchedState((touchedState) => {
      return {
        ...touchedState,
        ...modifiedTouchedState,
      };
    });

    validateForm();
  }, [fieldValidators, validateForm]);

  const isValid = useMemo(() => {
    let isValid = true;
    for (const fieldName in fieldValidators) {
      const fieldValidationResult = validationState[fieldName];
      if (!fieldValidationResult || !fieldValidationResult.isValid) {
        isValid = false;
      }
    }

    return isValid;
  }, [fieldValidators, validationState]);

  useEffect(() => {
    if (
      triggerValidationAt &&
      triggerValidationAt !== previousTriggerValidationAt
    ) {
      onTriggerValidate();
    }
  }, [triggerValidationAt, previousTriggerValidationAt, onTriggerValidate]);

  useEffect(() => {
    validateForm();
  }, [formState, validateForm]);

  useEffect(() => {
    if (initialFormState) {
      setFormState(initialFormState);
    }
  }, [initialFormState]);

  return (
    <form className={`validated-form${className ? ` ${className}` : ''}`} onSubmit={handleSubmitForm}>
      {renderFormContents(
        isValid,
        formState,
        validationState,
        touchedState,
        onFieldChange,
        onFieldTouch
      )}
    </form>
  );
};

export default ValidatedForm;
