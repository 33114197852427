import { AvailableForm } from '@payaca/types/formTypes';
export interface State {
  availableForms: AvailableForm[];
  isGettingForms: boolean;
  formData: any;
  formProgress: any;
  searchItemResults: [];
  isSearchingFormItems: boolean;
  isGettingFormData: boolean;
  isSubmittingForm: boolean;
  lastUpdated: Date | null;
}

export enum ActionType {
  REQUEST_GET_AVAILABLE_FORMS = 'forms.requestGetAvailableForms',
  GET_AVAILABLE_FORMS_SUCCESS = 'forms.getAvailableFormsSuccess',
  GET_AVAILABLE_FORMS_FAILURE = 'forms.getAvailableFormsFailure',

  REQUEST_GET_FORM_DATA = 'forms.requestGetFormData',
  GET_FORM_DATA_SUCCESS = 'forms.getFormDataSuccess',
  GET_FORM_DATA_FAILURE = 'forms.getFormDataFailure',
  CLEAR_FORM_DATA = 'forms.clearFormData',

  SET_FORM_PROGRESS = 'forms.setFormProgress',

  REQUEST_CREATE_FORM_INSTANCE = 'forms.requestCreateFormInstance',
  CREATE_FORM_INSTANCE_SUCCESS = 'forms.createFormInstanceSuccess',
  CREATE_FORM_INSTANCE_FAILURE = 'forms.createFormInstanceFailure',

  REQUEST_UPDATE_FORM_DATA = 'forms.requestUpdateFormData',
  UPDATE_FORM_DATA_SUCCESS = 'forms.updateFormDataSuccess',
  UPDATE_FORM_DATA_FAILURE = 'forms.updateFormDataFailure',

  REQUEST_SEARCH_FORM_ITEMS = 'forms.requestSearchFormItems',
  SEARCH_FORM_ITEMS_SUCCESS = 'forms.searchFormItemsSuccess',
  SEARCH_FORM_ITEMS_FAILURE = 'forms.searchFormItemsFailure',

  REQUEST_SUBMIT_FORM = 'forms.requestSubmitForm',
  SUBMIT_FORM_SUCCESS = 'forms.submitFormSuccess',
  SUBMIT_FORM_FAILURE = 'forms.submitFormFailure',

  REQUEST_SEND_FORM = 'documents.requestSendForm',
  SEND_FORM_SUCCESS = 'documents.sendFormSuccess',
  SEND_FORM_FAILURE = 'documents.sendFormFailure',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}
