import { Reducer } from 'redux';

import { ActionType, State } from './listedMaterialLineItemsTypes';

export const initialState: State = {
  isGettingListedMaterialLineItemsPage: false,
  listedMaterialLineItemsPage: null,
};

const listedSupplierMaterialsReducer: Reducer<State> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_LISTED_MATERIAL_ITEMS_PAGE:
      return { ...state, isGettingListedMaterialLineItemsPage: true };
    case ActionType.GET_LISTED_MATERIAL_ITEMS_PAGE_FAILURE:
      return {
        ...state,
        listedMaterialLineItemsPage: null,
        isGettingListedMaterialLineItemsPage: false,
      };
    case ActionType.GET_LISTED_MATERIAL_ITEMS_PAGE_SUCCESS:
      return {
        ...state,
        listedMaterialLineItemsPage: action.payload.listedMaterialLineItemsPage,
        isGettingListedMaterialLineItemsPage: false,
      };
    case ActionType.CLEAR_LISTED_MATERIAL_ITEMS_PAGE:
      return {
        ...state,
        listedMaterialLineItemsPage: null,
        isGettingListedMaterialLineItemsPage: false,
      };
    default:
      return state;
  }
};

export default listedSupplierMaterialsReducer;
