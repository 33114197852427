export interface JobPayment {
  id: number;
  dealId: number;
  invoiceId?: number;
  paymentMethod: PaymentMethodType;
  paymentValue: number;
  isDepositPayment: boolean;
  stripePaymentIntent: string;
  paymentCompletedConfirmationAt: Date;
  paymentFailedConfirmationAt: Date;
  stripePaymentAttemptedAt: Date;
  bacsPaymentMadeAt: Date;
  source: JobPaymentSource;
}

export enum PaymentMethodType {
  STRIPE = 'STRIPE',
  BACS = 'BACS',
  FINANCE_PAYOUT = 'FINANCE_PAYOUT',
  UNKNOWN = 'UNKNOWN',
  OTHER = 'OTHER',
  CASH = 'CASH',
  CARD = 'CARD',
  CHEQUE = 'CHEQUE',
}

export interface StripePaymentIntentInformation {
  stripeAccountId: string;
  paymentIntentId: string;
  paymentIntentClientSecret: string;
}

export enum JobPaymentSource {
  CLIENT = 'CLIENT',
  WEB = 'WEB',
  MOBILE = 'MOBILE',
}
// add comment to force re-version
