import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Modal from '@payaca/components/modal/Modal';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

type Props = {
  classes?: string;
  open: boolean;
  onClose: () => void;
  primaryAction?: () => void;
  primaryText?: string;
  primaryLink?: string;
  primaryTo?: string;
  hidePrimaryButton?: boolean;
  secondaryAction?: () => void;
  secondaryText?: string;
  tertiaryAction?: () => void;
  tertiaryText?: string;
  text?: string;
  title?: string;
  size?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl';
};
const ConfirmModal: FC<Props> = ({
  classes,
  open,
  onClose,
  primaryAction,
  primaryText,
  primaryLink,
  primaryTo,
  hidePrimaryButton,
  secondaryAction,
  secondaryText,
  tertiaryAction,
  tertiaryText,
  text,
  title = '',
  size,
}: Props): JSX.Element => {
  const onCloseModal = () => {
    return onClose ? onClose() : null;
  };
  const modalClass = 'confirm-modal' + (classes ? ` ${classes}` : '');

  const actions = useMemo(() => {
    return (
      <>
        {primaryLink && (
          <a
            target="_blank"
            rel="noreferrer"
            href={primaryLink}
            onClick={onClose}
          >
            <Button styleVariant={ButtonStyleVariant.OUTSIZE}>
              {primaryText || 'Yes'}
            </Button>
          </a>
        )}

        {primaryTo && (
          <Link to={primaryTo} onClick={onClose} target="_blank">
            <Button styleVariant={ButtonStyleVariant.OUTSIZE}>
              {primaryText || 'Yes'}
            </Button>
          </Link>
        )}
        {!hidePrimaryButton && (
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={primaryAction}
          >
            {primaryText || 'Yes'}
          </Button>
        )}
        {secondaryAction && (
          <Button
            onClick={secondaryAction}
            styleVariant={ButtonStyleVariant.ANCHOR}
          >
            {secondaryText || 'No'}
          </Button>
        )}
        {tertiaryAction && (
          <Button
            onClick={tertiaryAction}
            styleVariant={ButtonStyleVariant.ANCHOR}
          >
            {tertiaryText || 'No'}
          </Button>
        )}
      </>
    );
  }, [
    tertiaryAction,
    secondaryAction,
    hidePrimaryButton,
    primaryAction,
    primaryLink,
    onClose,
    primaryTo,
    primaryText,
  ]);

  return (
    <Modal
      isOpen={open}
      title={title}
      onClose={onCloseModal}
      size={size || 'xs'}
      className={modalClass}
      actions={actions}
    >
      {text && <div>{text}</div>}
    </Modal>
  );
};

export default ConfirmModal;
