import React, { FC } from 'react';
import moment from 'moment-timezone';

import './SignatureElement.sass';
import ResponsiveViewWrapper from '../responsiveViewWrapper/ResponsiveViewWrapper';

type Props = {
  name?: string;
  date?: Date;
  label?: string;
  position?: string;
  signatureBase64: string;
};

export const SignatureElement: FC<Props> = ({
  name,
  date,
  position,
  label,
  signatureBase64,
}) => {
  return (
    <ResponsiveViewWrapper className="signature-element" downBreakpointSm={300}>
      {label && <span className="label">{label}</span>}
      {signatureBase64 && <img className="signature" src={signatureBase64} />}
      {name && (
        <span className="name">
          Name: <span className="value">{name.toLocaleUpperCase()}</span>
        </span>
      )}
      {position && (
        <span className="position">
          Position: <span className="value">{position}</span>
        </span>
      )}
      {date && (
        <span className="date">
          Date:{' '}
          <span className="value">{moment(date).format('DD/MM/YYYY')}</span>
        </span>
      )}
    </ResponsiveViewWrapper>
  );
};
