import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import Button from '../plButton/Button';
import { EBtnVariant } from '../plButton/useButtonClassName';
import {
  DPDay,
  useContextCalendars,
  useContextDatePickerOffsetPropGetters,
  useContextDaysPropGetters,
  useDatePickerStateContext,
} from '@rehookify/datepicker';
import classnames from 'classnames';

import UntitledIcon from '@payaca/untitled-icons';
import TimeSelect from './TimeSelect';

type Props = {
  onClose: () => void;
  onChange: (date: Date) => void;
  showTimeSelect: boolean;
  defaultDate: Date;
};
export const getDayClassName = (
  className: string,
  { selected, disabled, inCurrentMonth, now }: DPDay
) =>
  classnames(className, {
    'bg-blue-500 text-white hover:text-white opacity-100 border-blue-500':
      selected,
    'opacity-25 cursor-not-allowed text-gray-300 pointer-events-none': disabled,
    'opacity-50': !inCurrentMonth,
    'border border-slate-500': now,
  });
const monthButtonClasses =
  'w-8 h-8 flex justify-center items-center text-gray-800 bg-white hover:bg-gray-100 rounded-full cursor-pointer disabled:opacity-50 disabled:pointer-events-none';

const DatePickerBody: FC<PropsWithChildren<Props>> = ({
  onClose,
  onChange,
  showTimeSelect,
  defaultDate,
}) => {
  const { calendars, weekDays } = useContextCalendars();
  const { addOffset, subtractOffset } = useContextDatePickerOffsetPropGetters();
  const { selectedDates } = useDatePickerStateContext();
  const { dayButton } = useContextDaysPropGetters();

  const { days, month, year } = calendars[0];

  const monthYearTitle = useMemo(() => {
    return `${month} ${year}`;
  }, [month, year]);

  useEffect(() => {
    // programatically select default date
    const contextDay = days.find((d) => {
      return (
        d.$date.getFullYear() === defaultDate.getFullYear() &&
        d.$date.getMonth() === defaultDate.getMonth() &&
        d.$date.getDate() === defaultDate.getDate()
      );
    });
    if (contextDay) {
      // @ts-ignore - don't have or need e here
      dayButton(contextDay)?.onClick();
    }
  }, []);

  return (
    <div>
      <div className="p-3">
        {/* Header */}
        <div className="mx-1.5 grid grid-cols-5 items-center gap-x-3 pb-3">
          {/* prev */}
          <div className="col-span-1">
            <button
              type="button"
              className={monthButtonClasses}
              {...subtractOffset({ months: 1 })}
            >
              <UntitledIcon name="chevron-left" className="h-8 w-8" />
            </button>
          </div>
          {/* month/year label */}
          <div className="col-span-3 flex items-center justify-center gap-x-1">
            <span className="text-center text-lg text-gray-800">
              {monthYearTitle}
            </span>
          </div>
          {/* next */}
          <div className="col-span-1 flex justify-end">
            <button
              type="button"
              className={monthButtonClasses}
              {...addOffset({ months: 1 })}
            >
              <UntitledIcon name="chevron-right" className="h-8 w-8" />
            </button>
          </div>
        </div>

        {/* Weeks */}
        <div className="grid grid-cols-7 pb-1.5">
          {weekDays.map((d, i) => (
            <span
              className="m-px block w-10 text-center text-base text-gray-500"
              key={`day-${i}`}
            >
              {d}
            </span>
          ))}
        </div>

        {/* Days */}
        <div className="grid grid-cols-7">
          {days.map((d) => (
            <div key={d.$date.toString()} className="col-span-1 pb-1">
              <div
                {...dayButton(d)}
                className={getDayClassName(
                  'flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border border-2 border-transparent text-base hover:border-blue-500 hover:text-blue-500',
                  d
                )}
              >
                {d.day}
              </div>
            </div>
          ))}
        </div>

        {/* time */}
        {showTimeSelect && (
          <TimeSelect
            selectedDate={selectedDates[0]}
            defaultDate={defaultDate}
          />
        )}
      </div>

      {/* confirmation buttons */}
      <div className="flex items-center justify-end gap-x-2 border-t p-3 dark:border-gray-700">
        <Button onClick={onClose} variant={EBtnVariant.White}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            // select date
            onChange(selectedDates[0]);
            onClose();
          }}
          disabled={selectedDates.length === 0}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default DatePickerBody;
