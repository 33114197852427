import { createReducer } from '../utils';

import { ActionType, AnyAction, CsvUploadState } from './csvUploadTypes';

const initialState: CsvUploadState = {
  isGettingCsvTemplate: false,
  csvDownloadUrl: undefined,
  csvGuidelines: null,
  csvTemplateError: null,

  // isUploadingCsv: false,
  // uploadCsvError: null,
  // uploadCsvWarning: null,

  store: {},
};

const setGettingCsvTemplateFlag = (state: CsvUploadState): CsvUploadState => ({
  ...state,
  csvDownloadUrl: undefined,
  csvGuidelines: null,
  isGettingCsvTemplate: true,
});

export default createReducer<CsvUploadState, ActionType, AnyAction>(
  initialState,
  {
    // GET CSV TEMPLATE
    [ActionType.REQUEST_GET_CSV_TEMPLATE]: setGettingCsvTemplateFlag,
    [ActionType.REQUEST_GET_CSV_UPDATE_TEMPLATE]: setGettingCsvTemplateFlag,

    [ActionType.GET_CSV_TEMPLATE_SUCCESS]: (state, action: any) => ({
      ...state,
      isGettingCsvTemplate: false,
      csvDownloadUrl: action.payload.downloadUrl,
      csvGuidelines: action.payload.guidelines,
      csvTemplateError: null,
    }),

    [ActionType.GET_CSV_TEMPLATE_FAILURE]: (state, action: any) => ({
      ...state,
      isGettingCsvTemplate: false,
      csvTemplateError: action.payload.errorMessage,
    }),

    // UPLOAD CSV
    [ActionType.REQUEST_UPLOAD_CSV]: (state) => ({
      ...state,
      isUploadingCsv: true,
      uploadCsvError: null,
      uploadCsvWarning: null,
    }),
    [ActionType.UPLOAD_CSV_SUCCESS]: (state) => ({
      ...state,
      isUploadingCsv: false,
      uploadCsvError: null,
      uploadCsvWarning: null,
    }),
    [ActionType.UPLOAD_CSV_FAILURE]: (state, action: any) => ({
      ...state,
      isUploadingCsv: false,
      uploadCsvError:
        typeof action.payload.errorMessage === 'string'
          ? action.payload.errorMessage
          : action.payload.errorMessage?.errors,
      uploadCsvWarning: action.payload.errorMessage?.warnings,
    }),
    [ActionType.CLEAR_CSV]: (state) => ({
      ...state,
      isGettingCsvTemplate: false,
      isUploadingCsv: false,
      uploadCsvError: null,
      uploadCsvWarning: null,
      csvTemplateError: null,
      csvGuidelines: null,
      csvDownloadUrl: undefined,
    }),

    [ActionType.GET_CSV_UPLOADS_REQUEST]: (state, action) => ({
      ...state,
      // TODO: loading flag
    }),
    [ActionType.GET_CSV_UPLOADS_SUCCESS]: (state, action) => ({
      ...state,
      store: action.payload.reduce(
        (acc, x) => ({
          ...acc,
          [x.id]: x,
        }),
        {}
      ),
    }),
  }
);
