import { Contact } from './contactTypes';

export interface Address {
  id: number;
  line1: string | null;
  line2: string | null;
  city: string | null;
  postcode: string | null;
  country: string | null;
  latitude: number | null;
  longitude: number | null;
}

export type PartialAddress = Partial<
  Pick<Address, 'line1' | 'line2' | 'city' | 'postcode' | 'country'>
>;

export enum LocationOptionType {
  SITE = 'SITE',
  CUSTOMER = 'CUSTOMER',
}

export type LocationOption = {
  id: number;
  type?: LocationOptionType;
  address: Address;
  contacts: Contact[];
};
