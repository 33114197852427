import { useQueryClient, UseMutationOptions } from '@tanstack/react-query';

import useUpdateAddress from '@/api/mutations/addresses/useUpdateAddress';
import projectKeys from '@/api/queries/project/keyFactory';
import {
  MyCustomFieldGroupsQuery,
  CreateCustomFieldGroupMutation as _CreateCustomFieldGroupMutation,
  CreateCustomFieldGroupInput,
} from '@/gql/graphql';
import useCreateCustomFieldGroup from '../useCreateCustomFieldGroup';
import meKeys from '@/api/queries/me/keyFactory';

const useOptimisticCreateCustomFieldGroup = (
  options?: UseMutationOptions<
    _CreateCustomFieldGroupMutation,
    unknown,
    CreateCustomFieldGroupInput
  >
) => {
  const queryClient = useQueryClient();

  return useCreateCustomFieldGroup({
    ...options,
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: meKeys.customFieldGroups(),
      });

      // Snapshot the previous value
      const previousCustomFieldGroups = queryClient.getQueryData(
        meKeys.customFieldGroups()
      );

      const newCustomFieldGroup = {
        ...variables,
        id: variables.id || 'temp-id',
        customFields: [],
      };

      // Optimistically update to the new value
      queryClient.setQueryData<MyCustomFieldGroupsQuery>(
        meKeys.customFieldGroups(),
        (old) => {
          console.log({ old, newCustomFieldGroup });
          if (!old) return;
          return {
            me: {
              user: {
                account: {
                  customFieldGroups: [
                    ...old.me.user.account.customFieldGroups,
                    newCustomFieldGroup,
                  ],
                },
              },
            },
          };
        }
      );

      options?.onMutate?.(variables);

      // Return a context object with the snapshotted value
      return { previousCustomFieldGroups: previousCustomFieldGroups };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        meKeys.customFieldGroups(),
        // @ts-ignore
        context.previousProjectOverview
      );

      options?.onError?.(err, newTodo, context);
    },
    // Always refetch after error or success:
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: meKeys.customFieldGroups(),
      });

      options?.onSettled?.(...args);
    },
  });
};

export default useOptimisticCreateCustomFieldGroup;
