export const WHITE = '#ffffff';
export const BLACK = '#000000';

export const GREY_MID = '#919191';
export const GREY_LIGHT = '#D2D2D2';
export const GREY_LIGHTER = '#F5F5F5';

export const YELLOW_LIGHTER = '#fff2cc';
export const YELLOW = '#ffca2d';
export const YELLOW_DARK = '#fabb00';

export const BLUE_LIGHTER = '#edf2f7';
export const BLUE_LIGHT = '#dbe5f0';
export const BLUE = '#0872ED';
export const BLUE_DARK = '#36577d';
export const BLUE_DARKER = '#263e59';
export const BLUE_FACEBOOK = '#4968AD';
export const BLUE_XERO = '#1AB4D7';
export const BLUE_XERO_DARK = '#019BBE';

export const GREEN = '#75e582';
export const GREEN_DARK = '#4ea83d';
export const GREEN_QUICKBOOKS = '#399536';
export const GREEN_QUICKBOOKS_DARK = '#207C1D';

export const RED = '#d93a3a';
export const RED_ZAPIER = '#FF4A00';
export const RED_ZAPIER_DARK = '#E63100';

export const PURPLE_STRIPE = '#6772E5';
export const PURPLE_STRIPE_DARK = '#4E59CC';

/* Primary and secondary colours */

export const COL_PRIMARY_LIGHTER = YELLOW_LIGHTER;
export const COL_PRIMARY = YELLOW;
export const COL_PRIMARY_DARK = YELLOW_DARK;

export const COL_SECONDARY = BLUE_DARK;
export const COL_SECONDARY_DARK = BLUE_DARKER;
