import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import {
  getBlockedEmailsFailure,
  getBlockedEmailsSuccess,
  lookupAddressBySearchTermFailure,
  lookupAddressBySearchTermSuccess,
  retrieveAddressFailure,
  retrieveAddressSuccess,
  validateEmailFailure,
  validateEmailSuccess,
  validatePhoneFailure,
  validatePhoneSuccess,
} from './validationActions';
import { ActionType, SagaConfig } from './validationTypes';

import { Req } from '@payaca/helpers/storeHelper';

import { AccountRegions } from '@payaca/types/accountTypes';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const userSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleValidateEmail(
    action: PayloadAction<
      ActionType.REQUEST_VALIDATE_EMAIL,
      {
        email: string;
        callback: (error: any, isValid: boolean) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(validateEmail, action.payload.email),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(validateEmailFailure(new Error('Validate email timed out.')));
      } else {
        yield put(validateEmailSuccess());
        action.payload.callback(null, response.isValid);
      }
    } catch (error: any) {
      yield put(validateEmailFailure(error));
    }
  }
  const validateEmail = async (email: string) => {
    const response = await req.get(`/validate/email?email=${email}`);
    return await response.json();
  };

  function* handleValidatePhone(
    action: PayloadAction<
      ActionType.REQUEST_VALIDATE_PHONE,
      {
        phone: string;
        callback: (error: any, isValid: boolean) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(validatePhone, action.payload.phone),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(validatePhoneFailure(new Error('Validate phone timed out.')));
      } else {
        yield put(validatePhoneSuccess());
        action.payload.callback(null, response.isValid);
      }
    } catch (error: any) {
      yield put(validatePhoneFailure(error));
    }
  }
  const validatePhone = async (phone: string) => {
    const response = await req.get(`/validate/phone?phone=${phone}`);
    return await response.json();
  };

  function* handleLookupAddressBySearchTerm(
    action: PayloadAction<
      ActionType.REQUEST_LOOKUP_ADDRESS_BY_SEARCH_TERM,
      {
        searchTerm: string;
        containerId: string;
        region: AccountRegions;
        callback: (error: any, isValid: boolean) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(
          lookupAddressBySearchTerm,
          action.payload.searchTerm,
          action.payload.containerId,
          action.payload.region
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          lookupAddressBySearchTermFailure(
            new Error('Lookup postcode timed out.')
          )
        );
      } else {
        yield put(lookupAddressBySearchTermSuccess(response));
        action.payload.callback(null, response);
      }
    } catch (error: any) {
      yield put(lookupAddressBySearchTermFailure(error));
    }
  }
  const lookupAddressBySearchTerm = async (
    searchTerm: string,
    containerId: string,
    region: AccountRegions
  ) => {
    const response = await req.get(
      `/addresses/lookup?searchTerm=${encodeURIComponent(
        searchTerm
      )}&region=${region}&containerId=${containerId}`
    );
    return await response.json();
  };

  function* handleRetrieveAddress(
    action: PayloadAction<
      ActionType.REQUEST_RETRIEVE_ADDRESS,
      {
        addressId: string;
        callback: (error: any, isValid: boolean) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(lookupAddress, action.payload.addressId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          retrieveAddressFailure(new Error('Lookup address timed out.'))
        );
      } else {
        yield put(retrieveAddressSuccess());
        action.payload.callback(null, response.address);
      }
    } catch (error: any) {
      yield put(retrieveAddressFailure(error));
    }
  }
  const lookupAddress = async (addressId: string) => {
    const response = await req.get(`/addresses/retrieve?id=${addressId}`);
    return await response.json();
  };

  function* handleGetBlockedEmails(
    action: PayloadAction<
      ActionType.REQUEST_GET_BLOCKED_EMAILS,
      {
        callback?: (error: any, blockedEmails: any[]) => void;
      }
    >
  ) {
    try {
      const { response, timeout } = yield race({
        response: call(getBlockedEmails),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getBlockedEmailsFailure(new Error('Get blocked emails timed out.'))
        );
      } else {
        const responseList = response && response.suppressionList;
        const responseEmails =
          responseList && responseList.map((l: any) => l.EmailAddress);
        yield put(getBlockedEmailsSuccess(responseEmails));
        action?.payload?.callback &&
          action.payload.callback(null, responseEmails);
      }
    } catch (error: any) {
      yield put(getBlockedEmailsFailure(error));
    }
  }
  const getBlockedEmails = async () => {
    const response = await req.get('/validate/blocked_outbound');
    return await response.json();
  };

  return function* () {
    yield takeEvery(ActionType.REQUEST_VALIDATE_EMAIL, handleValidateEmail);
    yield takeEvery(ActionType.REQUEST_VALIDATE_PHONE, handleValidatePhone);
    yield takeLatest(
      ActionType.REQUEST_LOOKUP_ADDRESS_BY_SEARCH_TERM,
      handleLookupAddressBySearchTerm
    );
    yield takeEvery(ActionType.REQUEST_RETRIEVE_ADDRESS, handleRetrieveAddress);
    yield takeEvery(
      ActionType.REQUEST_GET_BLOCKED_EMAILS,
      handleGetBlockedEmails
    );
  };
};

export default userSagaCreator;
