import Button from '@payaca/components/button/Button';
import { ButtonColourVariant } from '@payaca/components/button/enums';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import './HeaderNavigationControl.sass';
import HeaderNavigation from '../headerNavigation/HeaderNavigation';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Setting } from 'react-iconly';
import ResponsivePopperAndTrigger from '../responsivePopperAndTrigger/ResponsivePopperAndTrigger';
import { useSelector } from '@/api/state';

type Props = {
  isSmallView?: boolean;
};
const HeaderNavigationControl: FC<Props> = ({
  isSmallView = false,
}: Props): JSX.Element => {
  const myProfile = useSelector((state: any) => state.users.myProfile);
  const companyName = useMemo(() => {
    return myProfile?.accounts?.[0]?.companyName;
  }, [myProfile]);
  const logoUrl = useMemo(() => {
    return myProfile?.accounts?.[0]?.logoUrl;
  }, [myProfile]);

  const renderTriggerContent = useCallback(
    (isSmallView?: boolean) => {
      if (!isSmallView)
        return (
          <Button
            colourVariant={ButtonColourVariant.WHITE}
            iconAfter={faChevronDown}
            hasBoxShadow={true}
            className={logoUrl ? 'with-logo' : undefined}
          >
            {logoUrl && <img className="logo" src={logoUrl} />}
            <span className="company-name">{companyName}</span>
          </Button>
        );

      if (isSmallView) return <Setting set="light" />;
    },
    [companyName]
  );

  const renderPopperContent = useCallback(
    (isSmallView?: boolean) => {
      if (!isSmallView) return <HeaderNavigation />;

      if (isSmallView)
        return (
          <>
            <div className="account-user-indicator">
              <span className="account-name">{companyName}</span>
              <span className="user-name">{`${myProfile.firstname} ${myProfile.lastname}`}</span>
            </div>
            <HeaderNavigation />
          </>
        );
    },
    [myProfile, companyName]
  );

  return (
    <div
      className={`header-navigation-control ${isSmallView ? 'sm-view' : ''}`}
    >
      <ResponsivePopperAndTrigger
        isSmallView={isSmallView}
        renderTriggerContent={renderTriggerContent}
        renderPopperContent={renderPopperContent}
        popperClassName="header-navigation-control-popper"
        drawerClassName="header-navigation-control-drawer"
      />
    </div>
  );
};

export default HeaderNavigationControl;
