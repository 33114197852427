import { Reducer } from 'redux';

import { mergeFetchedEntities } from '../utils';

import { ActionType, State } from './jobPaymentsTypes';

export const initialState: State = {
  isRecordingJobPayment: false,
  isJobPaymentRecordedSuccessfully: undefined,
  isConfirmingJobPayment: false,
  isJobPaymentConfirmedSuccessfully: undefined,
  jobPayments: {},

  isPersistingJobPaymentIntent: false,
  isCancellingJobPaymentIntent: false,
  isPersistingJobPayment: false,
};

const jobPaymentsReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_RECORD_JOB_PAYMENT:
      return {
        ...state,
        isRecordingJobPayment: true,
        isJobPaymentRecordedSuccessfully: undefined,
      };
    case ActionType.RECORD_JOB_PAYMENT_SUCCESS:
      return {
        ...state,
        isRecordingJobPayment: false,
        isJobPaymentRecordedSuccessfully: true,
      };
    case ActionType.RECORD_JOB_PAYMENT_FAILURE:
      return {
        ...state,
        isRecordingJobPayment: false,
        isJobPaymentRecordedSuccessfully: false,
      };
    case ActionType.REQUEST_CONFIRM_JOB_PAYMENT:
      return {
        ...state,
        isConfirmingJobPayment: true,
        isJobPaymentConfirmedSuccessfully: undefined,
      };
    case ActionType.CONFIRM_JOB_PAYMENT_SUCCESS:
      return {
        ...state,
        isConfirmingJobPayment: false,
        isJobPaymentConfirmedSuccessfully: true,
      };
    case ActionType.CONFIRM_JOB_PAYMENT_FAILURE:
      return {
        ...state,
        isConfirmingJobPayment: false,
        isJobPaymentConfirmedSuccessfully: false,
      };

    case ActionType.REQUEST_GET_JOB_PAYMENT: {
      const fetchedEntity =
        state.jobPayments && state.jobPayments[action.payload.jobPaymentId];

      return {
        ...state,
        jobPayments: {
          ...state.jobPayments,
          [action.payload.jobPaymentId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_JOB_PAYMENT_SUCCESS: {
      const fetchedEntity =
        state.jobPayments && state.jobPayments[action.payload.jobPaymentId];

      return {
        ...state,
        jobPayments: {
          ...state.jobPayments,
          [action.payload.jobPaymentId]: {
            ...fetchedEntity,
            entity: action.payload.jobPayment,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.GET_JOB_PAYMENT_FAILURE: {
      const fetchedEntity =
        state.jobPayments && state.jobPayments[action.payload.jobPaymentId];

      return {
        ...state,
        jobPayments: {
          ...state.jobPayments,
          [action.payload.jobPaymentId]: {
            ...fetchedEntity,
            fetchFailedAtAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.CLEAR_JOB_PAYMENT: {
      const jobPayments = state.jobPayments || {};
      delete jobPayments[action.payload.jobPaymentId];

      return {
        ...state,
        jobPayments: jobPayments,
      };
    }
    case ActionType.CLEAR_JOB_PAYMENTS: {
      return {
        ...state,
        jobPayments: {},
      };
    }

    case ActionType.PERSIST_JOB_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        isPersistingJobPaymentIntent: true,
      };
    case ActionType.PERSIST_JOB_PAYMENT_INTENT_FAILURE:
    case ActionType.PERSIST_JOB_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        isPersistingJobPaymentIntent: false,
      };

    case ActionType.CANCEL_JOB_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        isCancellingJobPaymentIntent: true,
      };
    case ActionType.CANCEL_JOB_PAYMENT_INTENT_FAILURE:
    case ActionType.CANCEL_JOB_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        isCancellingJobPaymentIntent: false,
      };

    case ActionType.PERSIST_JOB_PAYMENT_REQUEST:
      return {
        ...state,
        isPersistingJobPayment: true,
      };
    case ActionType.PERSIST_JOB_PAYMENT_FAILURE:
    case ActionType.PERSIST_JOB_PAYMENT_SUCCESS:
      return {
        ...state,
        isPersistingJobPayment: false,
      };

    case ActionType.GET_JOB_PAYMENTS_FOR_DEAL_SUCCESS:
      return {
        ...state,
        jobPayments: mergeFetchedEntities({
          cache: state.jobPayments,
          values: action.payload,
        }),
      };

    default:
      return state;
  }
};

export default jobPaymentsReducer;
