import React from 'react';

import { CsvGuidelines } from '@payaca/types/csvUploadTypes';

type Props = {
  csvGuidelines: CsvGuidelines;
};
const BulkCsvGuidelines = ({ csvGuidelines }: Props): JSX.Element => {
  return (
    <>
      {csvGuidelines &&
        csvGuidelines.map((bulkUploadCsvGuideline, i) => {
          return (
            <li key={`bulk-upload-instruction-guideline-${i}`}>
              <span>{bulkUploadCsvGuideline.header}</span>
              <span>: {bulkUploadCsvGuideline.guideline}</span>
            </li>
          );
        })}
    </>
  );
};

export default BulkCsvGuidelines;
