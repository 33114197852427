import React, { FC, useCallback, useMemo } from 'react';

import { ListedMaterial } from '@payaca/types/listedMaterialTypes';

import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import PaginationControl from '@payaca/components/paginationControl/PaginationControl';

import './ListedSupplierMaterials.sass';
import { ListedSupplierMaterial } from '@payaca/types/listedSupplierMaterialTypes';
import SupplierMaterialControl, {
  SupplierMaterialControlContext,
} from '../supplierMaterialControl/SupplierMaterialControl';
import EmptyState from '../emptyState/EmptyState';
import { useSelector } from '@/api/state';

type Props = {
  onSelectPage: (pageNumber: number) => void;
  context: SupplierMaterialControlContext;
  onPersistSupplierMaterialSuccess?: () => void;
  onDeleteSupplierMaterialSuccess?: () => void;
};
const ListedSupplierMaterials: FC<Props> = ({
  onSelectPage,
  context,
  onPersistSupplierMaterialSuccess,
  onDeleteSupplierMaterialSuccess,
}: Props): JSX.Element => {
  const listedSupplierMaterialsPage = useSelector((state) => {
    return state.listedSupplierMaterials.listedSupplierMaterialsPage;
  });
  const isGettingListedSupplierMaterialsPage = useSelector(
    (state) =>
      state.listedSupplierMaterials.isGettingListedSupplierMaterialsPage
  );

  const paginationControl = useMemo(() => {
    if (!listedSupplierMaterialsPage) return null;
    return (
      <PaginationControl
        pageNumber={listedSupplierMaterialsPage.pageNumber}
        pageSize={listedSupplierMaterialsPage.pageSize}
        totalItemCount={listedSupplierMaterialsPage.totalItemCount}
        onSelectPage={onSelectPage}
        allowPageSizeSelection={false}
      />
    );
  }, [listedSupplierMaterialsPage, onSelectPage]);

  return (
    <div className="listed-supplier-materials">
      <div className="supplier-materials-list">
        {isGettingListedSupplierMaterialsPage && (
          <div className="loader-container">
            <MiniLoader />
          </div>
        )}
        {!isGettingListedSupplierMaterialsPage &&
          !listedSupplierMaterialsPage?.items?.length && (
            <EmptyState
              configName={'supplier-materials'}
              arrowIndication={false}
            />
          )}
        {listedSupplierMaterialsPage?.items?.map(
          (listedSupplierMaterial: ListedSupplierMaterial, index: number) => {
            return (
              <SupplierMaterialControl
                key={`listed-supplier-material-${index}`}
                supplierMaterial={listedSupplierMaterial}
                supplier={listedSupplierMaterial.supplier}
                material={listedSupplierMaterial.material}
                context={context}
                onDeleteSupplierMaterialSuccess={
                  onDeleteSupplierMaterialSuccess
                }
                onPersistSupplierMaterialSuccess={
                  onPersistSupplierMaterialSuccess
                }
              />
            );
          }
        )}
      </div>
      {paginationControl}
    </div>
  );
};

export default ListedSupplierMaterials;
