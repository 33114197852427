import React, { ComponentPropsWithoutRef } from 'react';
import { Editor } from '@tiptap/react';

import { RichTextEditorVariables } from '@payaca/types/richTextEditorVariables';

export interface IProps<T = string> extends ComponentPropsWithoutRef<'div'> {
  variables?: RichTextEditorVariables<T>;
  editor: Editor | null;
}

const VariableList = <T extends string>(props: IProps<T>) => {
  const { variables, className, editor, ...rest } = props;

  if (!variables || variables.length === 0 || !editor) {
    return null;
  }

  return (
    <div className={className} {...rest}>
      <div className="h-full max-h-[244px] overflow-y-auto rounded-xl border border-gray-200 dark:border-neutral-700">
        <ul className="m-0 flex list-none flex-col gap-1 p-[0.75rem]">
          {variables.map((variable) => (
            <li key={variable.name}>
              <button
                className="rich-text-editor-variable"
                type="button"
                onClick={() => editor.commands.addVariable(variable)}
              >
                {variable.displayName}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default VariableList;
