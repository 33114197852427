import React, { FC, useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import * as uploadsActions from '@payaca/store/uploads/uploadsActions';
import FileUploadPersistRemoveControl from '@payaca/components/fileUploadPersistRemoveControl/FileUploadPersistRemoveControl';
import { getUploadsByIds } from '@/utils/stateAccessors';
import { UPLOAD_MAX_FILESIZE_LIMIT } from '@payaca/types/uploadTypes';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from '@/api/state';

type Props = {
  label?: string;
  description?: string;
  uploadIds: number[];
  onUploadCollectionChange: (uploadIds: number[]) => void;
  isDisabled?: boolean;
  acceptFileTypes?: string[];
  allowMultipleUploads?: boolean;
  fileSizeLimitBytes?: number;
  uploadTriggerIcon?: IconDefinition;
  showFileNames?: boolean;
  canRemove?: boolean;
  customPersistUpload?: (file: File) => Promise<any>;
  customRemoveUpload?: (uploadId: number) => Promise<any>;
  canAdd?: boolean;
};

const UploadCollectionControl: FC<Props> = ({
  label,
  description,
  uploadIds,
  onUploadCollectionChange,
  isDisabled = false,
  acceptFileTypes,
  allowMultipleUploads = true,
  fileSizeLimitBytes = UPLOAD_MAX_FILESIZE_LIMIT,
  uploadTriggerIcon,
  showFileNames = true,
  canRemove,
  canAdd,
  customPersistUpload,
  customRemoveUpload,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const uploads = useSelector((state) =>
    getUploadsByIds(state, uploadIds || [])
  );

  useEffect(() => {
    if (uploadIds.length) {
      dispatch(uploadsActions.getUploads.request({ uploadIds }));
    }
  }, [uploadIds?.length]);

  const onPersistUploadsSuccess = useCallback(
    (newUploadIds: number[]) => {
      newUploadIds.map((newUploadId) =>
        dispatch(uploadsActions.requestGetUpload(newUploadId))
      );
      onUploadCollectionChange([...uploadIds, ...newUploadIds]);
    },
    [onUploadCollectionChange, uploadIds]
  );

  const onPersistUpload = useCallback(
    (file: File) => {
      if (file.size > Math.min(fileSizeLimitBytes, UPLOAD_MAX_FILESIZE_LIMIT))
        return new Promise<any>((resolve, reject) => {
          reject(new Error());
        });

      if (customPersistUpload) {
        return customPersistUpload(file);
      }
      return new Promise<any>((resolve, reject) => {
        dispatch(
          uploadsActions.requestPersistUpload(
            { file: file },
            (uploadId: number) => {
              resolve(uploadId);
            }
          )
        );
      });
    },
    [customPersistUpload]
  );

  const onRemovePersistedUpload = useCallback(
    (uploadId: number) => {
      if (customRemoveUpload) {
        return customRemoveUpload(uploadId);
      }
      return new Promise<void>((resolve) => {
        onUploadCollectionChange(uploadIds.filter((x) => x != uploadId));
        resolve();
      });
    },
    [customRemoveUpload, onUploadCollectionChange, uploadIds]
  );

  return (
    <div className="upload-collection-control">
      <FileUploadPersistRemoveControl
        persistedFiles={uploads.map((x) => {
          return {
            fileName: x.fileName,
            identifier: x.id,
            mimeType: x.mimeType,
            thumbnailUrl: x.thumbnailUrl,
            downloadUrl: x.url,
          };
        })}
        persistFile={onPersistUpload}
        removePersistedFile={onRemovePersistedUpload}
        onPersistFilesSuccess={onPersistUploadsSuccess}
        canRemove={canRemove}
        canAdd={canAdd}
        allowFileNameModification={false}
        allowMultipleUploads={allowMultipleUploads}
        acceptFileTypes={acceptFileTypes}
        isDisabled={isDisabled}
        label={label}
        description={description}
        uploadTriggerIcon={uploadTriggerIcon}
        showFileNames={showFileNames}
        enableDragAndDrop={true}
      />
    </div>
  );
};

export default UploadCollectionControl;
