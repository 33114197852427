import { OptionGroup, SelectOption } from '@payaca/components/plSelect/Select';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import {
  LocationOption,
  LocationOptionType,
} from '@payaca/types/locationTypes';
import { useMemo } from 'react';

export enum AddressSelectValue {
  ADD_NEW_ADDRESS = 'add-new-address',
  NO_LOCATION = 'no-location',
}

export const useAddressSelectOptionsAndGroups = (
  locationOptions?: LocationOption[],
  allowNoLocation = true
) => {
  const addressSelectOptions = useMemo(() => {
    const o: SelectOption<string | number, LocationOption>[] =
      locationOptions?.map((x) => ({
        groupId: x.type,
        label: getAddressAsString(x.address) || '-',
        value: x.id,
        metadata: x,
      })) || [];

    if (allowNoLocation) {
      o.push({
        groupId: 'other',
        label: 'No location',
        value: AddressSelectValue.NO_LOCATION,
      });
    }

    o.push({
      groupId: 'other',
      label: 'Add a different address',
      value: AddressSelectValue.ADD_NEW_ADDRESS,
    });

    return o;
  }, [locationOptions, allowNoLocation]);

  const addressSelectOptionGroups = useMemo(() => {
    const og: OptionGroup[] = [];

    if (locationOptions?.some((x) => x.type === LocationOptionType.SITE)) {
      og.push({ label: 'Site addresses', id: LocationOptionType.SITE });
    }

    if (locationOptions?.some((x) => x.type === LocationOptionType.CUSTOMER)) {
      og.push({ label: 'Customer addresses', id: LocationOptionType.CUSTOMER });
    }

    if (locationOptions?.length) {
      og.push({ label: 'Other', id: 'other' });
    }

    return og;
  }, [locationOptions]);

  return { addressSelectOptions, addressSelectOptionGroups };
};
