import React, { FC, useCallback, useMemo } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import useGetCustomer from '@/api/queries/customers/useGetCustomer';
import { CustomerContact } from '@/gql/graphql';
import AdvancedTable, {
  TTableRowAction,
} from '@payaca/components/plAdvancedTable/AdvancedTable';
import UntitledIcon from '@payaca/untitled-icons';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import AddEditContactModal, {
  IProps as IAddEditContactModalProps,
} from '@/ui/components/addEditContactControl/AddEditContactModal';
import LinkButton from '@payaca/components/plButton/LinkButton';
import Modal from '@payaca/components/plModal/Modal';
import useOptimisticUpdateCustomerContactOnCustomer from '@/api/mutations/customers/optimistic/useOptimisticUpdateCustomerContactOnCustomer';
import useOptimisticDeleteCustomerContactOnCustomer from '@/api/mutations/customers/optimistic/useOptimisticDeleteCustomerContactOnCustomer';
import useOptimisticCreateCustomerContactOnCustomer from '@/api/mutations/customers/optimistic/useOptimisticCreateCustomerContactOnCustomer';
import useOptimisticSetCustomerPrimaryContact from '@/api/mutations/customers/optimistic/useOptimisticSetCustomerPrimaryContact';

type TableData = CustomerContact;

const CustomerContactsTable: FC = () => {
  const { customerId } = useParams<{ customerId: string }>();

  /**
   * React router
   */
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const isAddingContact = useRouteMatch({
    path: `${path}/add`,
    exact: true,
  });
  const isEditingContact = useRouteMatch<{
    contactId: string;
  }>({
    path: `${path}/:contactId/edit`,
    exact: true,
  });
  const isDeletingContact = useRouteMatch<{
    contactId: string;
  }>({
    path: `${path}/:contactId/delete`,
    exact: true,
  });

  /**
   * Queries
   */
  const { data: customerData } = useGetCustomer(customerId);

  /**
   * Mutations
   */
  const { mutate: createCustomerContact } =
    useOptimisticCreateCustomerContactOnCustomer(customerId);
  const { mutate: updateCustomerContact } =
    useOptimisticUpdateCustomerContactOnCustomer(customerId);
  const { mutate: deleteCustomerContact } =
    useOptimisticDeleteCustomerContactOnCustomer(customerId);
  const { mutate: setPrimaryContact } =
    useOptimisticSetCustomerPrimaryContact(customerId);

  const rowActions = useMemo<(row: TableData) => TTableRowAction<TableData>[]>(
    () => (row) => {
      const actions: TTableRowAction<TableData>[] = [
        {
          label: 'Edit',
          onClick: (contact) => history.push(`${url}/${contact.id}/edit`),
        },
        {
          label: 'Delete',
          onClick: (contact) => history.push(`${url}/${contact.id}/delete`),
        },
      ];

      if (!row.isPrimary) {
        actions.splice(1, 0, {
          label: 'Make primary Contact',
          onClick: () => setPrimaryContact({ contactId: row.id, customerId }),
        });
      }

      return actions;
    },
    [history, url, setPrimaryContact, customerId]
  );

  const handleAddEditCustomerContact = useCallback<
    Required<IAddEditContactModalProps>['onSave']
  >(
    (contact) => {
      const { id, ...rest } = contact;

      if (id) {
        updateCustomerContact({
          customerContactId: id,
          ...rest,
        });
      } else {
        createCustomerContact({ customerId, ...rest });
      }

      history.push(url);
    },
    [updateCustomerContact, history, url]
  );

  const handleDeleteCustomerContact = useCallback(() => {
    if (!isDeletingContact) {
      // should never get here
      return;
    }

    deleteCustomerContact({
      customerContactId: isDeletingContact?.params.contactId,
    });

    history.push(url);
  }, [isDeletingContact, history, url, deleteCustomerContact]);

  return (
    <>
      <AdvancedTable<TableData>
        data={customerData?.customer.contacts || []}
        uniqueKey="id"
        searchField="name"
        rowActions={rowActions}
        headerContent={{
          heading: 'Customer Contacts',
          buttons: (
            <LinkButton to={`${url}/add`} size={EBtnSize.Small}>
              Add Contact
            </LinkButton>
          ),
        }}
      >
        <AdvancedTable.Column header="Name" field="name" />
        <AdvancedTable.Column<TableData, 'email'>
          header="Email"
          field="email"
          render={(field) => {
            return <a href={`mailTo:${field}`}>{field}</a>;
          }}
        />
        <AdvancedTable.Column<TableData, 'phone'>
          header="Telephone"
          field="phone"
          render={(field) => {
            return <a href={`tel:${field}`}>{field}</a>;
          }}
        />
        <AdvancedTable.Column
          className="max-w-[400px] truncate"
          header="Description"
          field="description"
        />
        <AdvancedTable.Column<TableData, 'isPrimary'>
          field="isPrimary"
          header="Primary Contact"
          render={(isPrimary) => {
            if (isPrimary) {
              return <UntitledIcon className="h-6 w-6" name="check" />;
            }
          }}
        />
      </AdvancedTable>

      <AddEditContactModal
        title="Add Customer Contact"
        isOpen={!!isAddingContact}
        onClose={() => history.push(url)}
        onSave={handleAddEditCustomerContact}
      />

      <AddEditContactModal
        title="Edit Customer Contact"
        isOpen={!!isEditingContact}
        onClose={() => history.push(url)}
        contact={customerData?.customer.contacts.find(
          (contact) => contact.id === isEditingContact?.params.contactId
        )}
        onSave={handleAddEditCustomerContact}
      />

      <Modal
        title="Delete Customer Contact"
        isOpen={!!isDeletingContact}
        onClose={() => history.push(url)}
      >
        <Modal.Body>
          <p>Are you sure you want to delete this Contact?</p>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Actions>
            <Button
              variant={EBtnVariant.Outline}
              onClick={() => history.push(url)}
            >
              Cancel
            </Button>
            <Button
              colour={EBtnColour.Red}
              onClick={handleDeleteCustomerContact}
            >
              Delete
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerContactsTable;
