import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DayHeaderContentArg, SlotLabelContentArg } from '@fullcalendar/react';

import moment from 'moment-timezone';

import './SlotLabel.sass';

type Props = {
  slotLabelContentArg: SlotLabelContentArg;
};
const SlotLabel: FC<Props> = ({ slotLabelContentArg }: Props): JSX.Element => {
  const momentDate = useMemo(() => {
    return moment(slotLabelContentArg.date);
  }, [slotLabelContentArg.date]);

  const hourOfDay = useMemo(() => {
    return <span className="hour">{momentDate.format('ha')}</span>;
  }, [momentDate]);

  const dayOfWeekShort = useMemo(() => {
    return (
      <span className="day-of-week-short">{momentDate.format('ddd')}</span>
    );
  }, [momentDate]);

  const dayNumber = useMemo(() => {
    return <span className="day-number">{momentDate.format('D')}</span>;
  }, [momentDate]);

  return (
    <div className={`slot-label ${slotLabelContentArg.view.type}`}>
      {['timelineDay', 'resourceTimelineDay'].includes(
        slotLabelContentArg.view.type
      ) && hourOfDay}
      {['timelineMonth', 'resourceTimelineMonth'].includes(
        slotLabelContentArg.view.type
      ) && (
        <>
          {dayOfWeekShort}
          {dayNumber}
        </>
      )}
      {['timelineWeek', 'resourceTimelineWeek'].includes(
        slotLabelContentArg.view.type
      ) && (
        <>
          {slotLabelContentArg.level === 1 && hourOfDay}
          {slotLabelContentArg.level === 0 && (
            <div className="flex-container flex-center">
              {dayNumber}
              {dayOfWeekShort}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SlotLabel;
