import { Pipeline } from '@payaca/types/pipelineTypes';
export interface State {
  pipelines: Pipeline[];
  isPerformingPipelineAction: boolean;
}

export enum ActionType {
  REQUEST_GET_ACCOUNT_PIPELINES = 'pipelines.requestGetAccountPipelines',
  GET_ACCOUNT_PIPELINES_SUCCESS = 'pipelines.getAccountPipelinesSuccess',
  GET_ACCOUNT_PIPELINES_FAILURE = 'pipelines.getAccountPipelinesFailure',

  REQUEST_CREATE_PIPELINE = 'pipelines.requestCreatePipeline',
  CREATE_PIPELINE_SUCCESS = 'pipelines.createPipelineSuccess',
  CREATE_PIPELINE_FAILURE = 'pipelines.createPipelineFailure',

  REQUEST_UPDATE_PIPELINE = 'pipelines.requestUpdatePipeline',
  UPDATE_PIPELINE_SUCCESS = 'pipelines.updatePipelineSuccess',
  UPDATE_PIPELINE_FAILURE = 'pipelines.updatePipelineFailure',

  REQUEST_DELETE_PIPELINE = 'pipelines.requestDeletePipeline',
  DELETE_PIPELINE_SUCCESS = 'pipelines.deletePipelineSuccess',
  DELETE_PIPELINE_FAILURE = 'pipelines.deletePipelineFailure',

  CLEAR_PIPELINES = 'pipelines.clearPipelines',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}
