export enum MaterialsRoles {
  VIEWER = 'roles.materialsViewer',
  SYSTEM_MANAGED_VIEWER = 'roles.systemManagedMaterialsViewer',
  PERSISTER = 'roles.materialsPersister',
  ARCHIVER = 'roles.materialsArchiver',
  COLLECTION_MANAGER = 'roles.materialsCollectionManager',

  LINE_ITEM_MATERIALS_VIEWER = 'roles.lineItemMaterialsViewer',
  LINE_ITEM_MATERIALS_PERSISTER = 'roles.lineItemMaterialsPersister',
  LINE_ITEM_MATERIALS_DELETER = 'roles.lineItemMaterialsDeleter',

  SUPPLIER_MATERIALS_VIEWER = 'roles.supplierMaterialsViewer',
  SUPPLIER_MATERIALS_PERSISTER = 'roles.supplierMaterialsPersister',
  SUPPLIER_MATERIALS_DELETER = 'roles.supplierMaterialsDeleter',

  BULK_IMPORTER = 'roles.materialsBulkImporter',
  SUPPLIER_MATERIALS_BULK_IMPORTER = 'roles.supplierMaterialsBulkImporter',
}
