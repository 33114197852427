import React, { FC, useCallback, useMemo, useState } from 'react';

import ConfirmModal from '../modal/ConfirmModal';
import SelectItemControl from '../selectItemControl/SelectItemControl';
import Modal from '@payaca/components/modal/Modal';

import { getModal } from '@/helpers/modalHelper';
import Button from '@payaca/components/button/Button';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { useBlockedEmails } from '@/utils/customHooks';
import { useCustomer, useSelector } from '@payaca/store/hooks/appState';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import { getCustomerContactEmailFieldValidator } from '@payaca/helpers/customerValidationHelper';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { CustomerContact } from '@payaca/types/customerTypes';
import { useDispatch } from 'react-redux';
import * as customerActions from '@payaca/store/customer/customerActions';
import SendableDocumentEmailControl from '../previewAndSendSendableDocument/SendableDocumentEmailControl';
import { get } from 'lodash-es';
import './BounceResendModal.sass';
import ValidationFeedbackBlock from '@payaca/components/validationFeedbackBlock/ValidationFeedbackBlock';
import { sendChangeProposal } from '@payaca/store/proposals/proposalsActions';
import useGetExistingCustomerContactEmails from '@/api/queries/customers/useGetExistingCustomerContactEmails';

const emailCopyValidator = (
  fieldName: string,
  formState: { [key: string]: any }
) => {
  const value = get(formState, fieldName);

  if (!value.preButton?.trim().length && !value.postButton?.trim().length) {
    return {
      isValid: false,
      errors: ['The email must have some content'],
    };
  }

  return {
    isValid: true,
  };
};

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  customerId: number;
  customerContactId?: number;
  resendConfig: {
    handleResend: (requestData: {
      sendMeACopy?: boolean;
      emailCopy: {
        preButton: string;
        postButton: string;
      };
    }) => Promise<void>;
    prompt?: string;
    documentType: 'invoice' | 'quote' | 'estimate' | 'change proposal';
    emailConfig: {
      subject: string;
      bodyTemplate: string;
      substitutions?: { find: string; replaceWith: string }[];
    };
  };
};
const BounceResendModal: FC<Props> = ({
  title,
  isOpen,
  onClose,
  customerId,
  customerContactId,
  resendConfig,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const blockedEmails = useBlockedEmails();

  const [isProcessing, setIsProcessing] = useState(false);
  const [showValidationFeedback, setShowValidationFeedback] = useState(false);
  const customer = useCustomer(customerId);

  const customerContact = useMemo(
    () =>
      customer
        ? getJobContactFromCustomer(customer, customerContactId || null)
        : undefined,
    [customer, customerContactId]
  );

  const { data: existingCustomerContactEmails, isLoading } =
    useGetExistingCustomerContactEmails();

  const fieldValidators = useMemo(() => {
    const customerContactEmailFieldValidator =
      getCustomerContactEmailFieldValidator({
        blockedEmails,
        existingCustomerContactEmails,
      });

    return {
      emailAddress: [
        getIsRequiredFieldValidator({ readableName: 'Customer email' }),
        customerContactEmailFieldValidator,
      ],
      emailCopy: [emailCopyValidator],
    };
  }, [blockedEmails, existingCustomerContactEmails]);

  const initialFormState = useMemo(() => {
    let bodyTemplate = resendConfig.emailConfig.bodyTemplate;

    resendConfig.emailConfig.substitutions?.forEach((substitution) => {
      bodyTemplate = bodyTemplate.replaceAll(
        `[${substitution.find}]`,
        substitution.replaceWith
      );
    });

    let splitTemplate: string[];
    if (bodyTemplate.includes('\n[button]\n')) {
      splitTemplate = bodyTemplate.split('\n[button]\n');
    } else {
      splitTemplate = bodyTemplate.split('[button]');
    }

    return {
      emailAddress: customerContact?.emailAddress,
      emailCopy: {
        preButton: splitTemplate[0],
        postButton: splitTemplate[1],
      },
      sendMeACopy: true,
    };
  }, [customer, customerContact, resendConfig.emailConfig]);

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      if (!customer) return;
      setIsProcessing(true);
      const contacts = customer.contacts.map(
        (customerContact: CustomerContact, index: number) => {
          if (customerContact.id === customerContact?.id) {
            // update email on job contact
            return {
              ...customerContact,
              emailAddress: formState.emailAddress,
            };
          } else {
            return customerContact;
          }
        }
      );

      const customerToPersist = {
        ...customer,
        contacts: contacts,
      };

      dispatch(
        customerActions.requestPersistCustomer(customerToPersist, async () => {
          resendConfig
            .handleResend({
              sendMeACopy: formState.sendMeACopy,
              emailCopy: formState.emailCopy,
            })
            .then(() => {
              setIsProcessing(false);
              onClose();
            });
        })
      );
    },
    [customer, customerContact]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <div className="form-content">
          <ValidatedFieldWrapper
            validationResult={{
              isValid: !!validationState.emailAddress?.isValid,
            }}
            isTouched={touchedState['emailAddress'] || false}
          >
            <LoqateAdvisoryEmailInputField
              label={'Update customer email address'}
              value={formState.emailAddress}
              name="emailAddress"
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              disableValidation={
                validationState['emailAddress']
                  ? !validationState['emailAddress'].isValid
                  : false
              }
              styleVariant={InputStyleVariant.OUTSIZE}
            />
          </ValidatedFieldWrapper>
          {customerContact && (
            <SendableDocumentEmailControl
              buttonText={`View ${resendConfig.documentType}`}
              emailSubject={resendConfig.emailConfig.subject}
              emailState={{
                sendMeACopy: formState.sendMeACopy,
                emailCopy: formState.emailCopy,
              }}
              onChange={(value) => value && onFieldChange(value)}
            />
          )}

          <div className="actions-container">
            {showValidationFeedback && (
              <ValidationFeedbackBlock
                validationResults={Object.values(validationState)}
                isDismissable={true}
                onDismissed={() => setShowValidationFeedback(false)}
              />
            )}
            <Button
              onClick={() =>
                isValid
                  ? !isProcessing && onSubmit(formState)
                  : setShowValidationFeedback(true)
              }
              isProcessing={isProcessing}
              styleVariant={ButtonStyleVariant.OUTSIZE}
            >
              {resendConfig.prompt || 'Update and resend'}
            </Button>
          </div>
        </div>
      );
    },
    [isProcessing, onSubmit, showValidationFeedback]
  );

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      size={'sm'}
      className="bounce-resend-modal"
    >
      <ValidatedForm<{ [key: string]: any }>
        fieldValidators={fieldValidators}
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
      />
    </Modal>
  );
};

export default BounceResendModal;
