import React, { FunctionComponent } from 'react';
import './CategoriesKey.css';

type Props = {
  categories?: { [key: string]: string };
};

const CategoriesKey: FunctionComponent<Props> = ({
  categories,
}: Props): JSX.Element => {
  return (
    <div className="categories-key flex-container flex-center">
      <span>Key:</span>
      <div className="key-items-container flex-container">
        {categories &&
          Object.keys(categories).map((categoryLabel, i) => {
            return (
              <div
                key={i}
                className="key-item flex-container flex-center flex-contiguous"
              >
                <div className={`${categoryLabel} key-item-colour`}></div>
                <span className="key-item-label">
                  {categories[categoryLabel]}
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CategoriesKey;
