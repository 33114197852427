export interface AggregateValueChartAggregateDatapoint {
  categoryLabel: string;
  aggregateValue: number;
  value: number;
}
export interface AggregateValueChartDatapoint {
  categoryLabel: string;
  value: number;
  aggregateDatapoints: AggregateValueChartAggregateDatapoint[];
}

export interface AggregateValueChartData {
  maxValue: number;
  categories: { [key: string]: string };
  aggregateCategories: { [key: string]: string };
  datapoints: AggregateValueChartDatapoint[];
}
export interface SubscriptionUsageData {
  subscriptionPlanName: string;
  numberOfJobsPerMonth: number;
  currentMonthStartDate: Date;
  currentMonthJobsRemaining: number;
  currentMonthJobsUsed: number;
  lastMonthStartDate: Date | null;
  lastMonthJobsUsed: number | null;
  lastMonthJobsRemaining: number | null;
  numberOfUserSeats: number;
  numberOfActiveUsers: number;
}

export interface DataPeriod<T> {
  periodStartDate: Date;
  periodEndDate: Date;
  value: T;
}

export enum KeyIndicatorType {
  REVENUE_SECURED = 'revenueSecured',
  REVENUE_RECEIVED = 'revenueReceived',
  CONVERSION_RATE = 'conversionRate',
  AUTOMATION_EMAIL_NOTIFICATIONS = 'automationEmailNotifications',
  AUTOMATION_SMS_NOTIFICATIONS = 'automationSmsNotifications',
  AUTOMATION_DEAL_PROGRESSION = 'automationDealProgression',
}

export interface KeyIndicatorData<TIndicatorType extends KeyIndicatorType> {
  type: TIndicatorType;
  percentageChange: number | 'Infinity' | '-Infinity';
  sparkLineData?: number[];
  periodLengthInDays: number;
  currentPeriod?: DataPeriod<number>;
  previousPeriod?: DataPeriod<number>;
}

export interface KeyIndicatorsData {
  keyIndicatorData: KeyIndicatorData<KeyIndicatorType>[];
}
