import { ListedItemsListViewPage } from '@payaca/types/listedItemTypes';

export interface State {
  isGettingListedItemsPage: boolean;
  listedItemsPage: ListedItemsListViewPage | null;
}

export enum ActionType {
  REQUEST_GET_LISTED_ITEMS_PAGE = 'listedItems.requestGetListedItemsPage',
  GET_LISTED_ITEMS_PAGE_SUCCESS = 'listedItems.getListedItemsPageSuccess',
  GET_LISTED_ITEMS_PAGE_FAILURE = 'listedItems.getListedItemsPageFailure',
  CLEAR_LISTED_ITEMS_PAGE = 'listedItems.clearListedItemsPage',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
}
