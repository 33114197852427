import React, { FC } from 'react';

import './GroupedScheduledEventsList.sass';
import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';
import HeaderTag, { HeaderTagType } from '@payaca/components/headerTag/HeaderTag';

type Props = {
  groupedScheduledEvents: {
    groupName: string;
    scheduledEvents: ScheduledEvent[];
  }[];
  renderScheduledEvent: (scheduledEvent: ScheduledEvent) => React.ReactNode;
  groupHeaderTagType?: HeaderTagType;
};

const GroupedScheduledEventsList: FC<Props> = ({
  groupedScheduledEvents,
  renderScheduledEvent,
  groupHeaderTagType = HeaderTagType.H2,
}: Props): JSX.Element => {
  return (
    <div className="grouped-scheduled-events-list">
      {groupedScheduledEvents &&
        groupedScheduledEvents.map((groupedScheduledEvents, index: number) => {
          return (
            <div
              className="scheduled-events-group"
              key={`scheduled-events-group-${index}`}
            >
              <HeaderTag
                className="scheduled-events-group-title"
                type={groupHeaderTagType}
              >
                {groupedScheduledEvents.groupName}
              </HeaderTag>
              <div className="scheduled-events-container">
                {groupedScheduledEvents.scheduledEvents.map(
                  (scheduledEvent, i: number) => {
                    return (
                      <div key={`scheduled-event-container-${i}`}>
                        {renderScheduledEvent(scheduledEvent)}
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default GroupedScheduledEventsList;
