import { action } from 'typesafe-actions';

import { ListedUserListViewPage } from '@payaca/types/listedUserTypes';
import { ActionType, GetListedUsersRequestData } from './listedUsersTypes';

export const requestGetListedUsersPage = (
  getListedUsersRequestData: GetListedUsersRequestData
) => {
  return action(ActionType.REQUEST_GET_LISTED_USERS_PAGE, {
    getListedUsersRequestData,
  });
};

export const clearListedUsersPage = () => {
  return action(ActionType.CLEAR_LISTED_USERS_PAGE);
};

export const getListedUsersPageSuccess = (
  listedUsersPage: ListedUserListViewPage
) => {
  return action(ActionType.GET_LISTED_USERS_PAGE_SUCCESS, {
    listedUsersPage,
  });
};

export const getListedUsersPageFailure = (error: Error) => {
  return action(ActionType.GET_LISTED_USERS_PAGE_FAILURE, null, null, error);
};
