import { SendableEntity } from './sendableEntityTypes';
import { AssignableEntity } from './assignableEntityTypes';
import { TimestampedEntity } from './timestampedEntityTypes';
import { VoidableEntity } from './voidableEntityTypes';
import { ReferencedEntity } from './referencedEntityTypes';

export interface ChangeProposal
  extends BaseChangeProposal,
    ChangeProposalRelationships {}

export interface BaseChangeProposal
  extends TimestampedEntity,
    VoidableEntity,
    SendableEntity,
    AssignableEntity,
    ReferencedEntity {
  id: number;
  dealId: number;
  expiresAt?: Date;
  expiresInDays?: number;
  acceptedAt?: Date;
  declinedAt?: Date;
  contactId?: number;
  createdByUserId?: number;
  notes?: string;
  readableStatus: ReadableChangeProposalStatus;
  signatureImage?: string;
  declineReason?: string;
  valueChangeIncTax: number;
  valueChangeExcTax: number;
  valueChangeTaxAmount: number;
  latestClientViewLogAt?: Date;
}

export interface ChangeProposalRelationships {
  jobLineItemIds: number[];
  pdfUrl?: string;
  attachmentUploadIds: number[];
}

export enum ReadableChangeProposalStatus {
  SENT = 'Sent',
  VOID = 'Void',
  ACCEPTED = 'Accepted',
  DECLINED = 'Declined',
  DRAFT = 'Draft',
}

export enum ChangeProposalUploadRoles {
  GENERATED_PDF = 'generated-pdf',
  ATTACHMENT = 'attachment',
}
