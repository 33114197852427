import * as dealsActions from '@payaca/store/deals/dealsActions';
import * as invoicesActions from '@payaca/store/invoices/invoicesActions';
import * as jobPaymentsActions from '@payaca/store/jobPayments/jobPaymentsActions';
import { Invoice } from '@payaca/types/invoiceTypes';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import {
  getCustomerByDealId,
  getInvoice,
  getRegion,
  getUserRoles,
} from '@/utils/stateAccessors';
import './PreviewAndSendProtoInvoiceControl.sass';

import A4DocumentWrapper from '@payaca/components/a4DocumentWrapper/A4DocumentWrapper';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import * as accountActions from '@payaca/store/account/accountActions';
import moment from 'moment-timezone';
import { useHistory } from 'react-router';
import InvoiceDocument from '../invoiceDocument/InvoiceDocument';
import PreviewAndSendSendableDocument from '../previewAndSendSendableDocument/PreviewAndSendSendableDocument';
import { useSelector } from '@/api/state';
import { MOMENT_DATE_SHORT_READABLE_FORMAT } from '@payaca/constants/momentFormatConstants';
import {
  useDeal,
  useJobContent,
  useProposal,
} from '@payaca/store/hooks/appState';
import { Job } from '@payaca/types/jobTypesV2';
import ProtoInvoiceDocument from '../protoInvoiceDocument/ProtoInvoiceDocument';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import IconButton from '@payaca/components/button/IconButton';
import { InvoicesPermissions } from '@payaca/permissions/invoices/invoices.permissions';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import MarkInvoiceAsSentModal from '../markInvoiceAsSentModal/MarkInvoiceAsSentModal';
import { sendProtoInvoice } from '@payaca/store/proposals/proposalsActions';
import MarkProtoInvoiceAsSentModal from '../markProtoInvoiceAsSentModal/MarkProtoInvoiceAsSentModal';
import { getUpcomingReference } from '@payaca/store/invoices/invoicesActions';
import { buildEmailSubjectPrefixForProjectFromProject } from '@payaca/helpers/dealHelper';
import { TDropdownItem } from '@payaca/components/plDropdown/DropdownCore';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';

type Props = {
  protoInvoiceId: number;
};

const PreviewAndSendProtoInvoiceControl: FunctionComponent<Props> = ({
  protoInvoiceId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showMarkAsSentModal, setShowMarkAsSentModal] = useState(false);
  const [placeholderReference, setPlaceholderReference] = useState<number>();

  const userRoles = useSelector(getUserRoles);

  const companyName = useSelector(
    (state: any) => state.users.myProfile.accounts[0].companyName
  );

  const emailTemplates = useSelector((state) => state.account.emailTemplates);

  const emailTemplate = useMemo(() => {
    return emailTemplates?.sendInvoice || '';
  }, [emailTemplates]);

  const protoInvoice: Job | undefined = useProposal(protoInvoiceId);

  const jobContent = useJobContent(protoInvoice?.jobContentId);

  const deal = useDeal(protoInvoice?.dealId);

  const region = useSelector(getRegion);

  const customer = useSelector((state) => {
    if (!protoInvoice) return;
    return getCustomerByDealId(state, protoInvoice.dealId);
  });

  const customerContact = useMemo(() => {
    if (!customer) return;
    return getJobContactFromCustomer(customer, protoInvoice?.contactId || null);
  }, [customer?.contacts, protoInvoice?.contactId]);

  const currentAccount = useSelector(
    (state: any) => state.users?.myProfile?.accounts[0]
  );

  useEffect(() => {
    dispatch(accountActions.requestGetEmailTemplates(currentAccount.id, true));
    dispatch(
      getUpcomingReference.request({
        callback: setPlaceholderReference,
      })
    );

    return () => {
      dispatch(accountActions.clearEmailTemplates());
    };
  }, []);

  const navigateToDeal = useCallback(() => {
    history.push(`/deals/${protoInvoice?.dealId}/invoices-and-payments`);
  }, [history, protoInvoice]);

  const handleSendProtoInvoice = useCallback(
    (
      payload: {
        sendMeACopy: boolean;
        emailCopy: {
          preButton: string;
          postButton: string;
        };
      },
      callback: () => void,
      onErrorCallback: (errorMessages: string[]) => void
    ) => {
      dispatch(
        sendProtoInvoice.request({
          protoInvoiceId: protoInvoiceId,
          data: {
            isMarkAsSent: false,
            sendMeACopy: payload.sendMeACopy,
            emailCopy: payload.emailCopy,
          },
          callback: (invoiceId: number) => {
            callback();
            navigateToDeal();
          },
          onErrorCallback: (errorMessages: string[]) => {
            onErrorCallback(errorMessages);
          },
        })
      );
    },
    [protoInvoiceId, dispatch, navigateToDeal]
  );

  const dueDate = useMemo(() => {
    if (!protoInvoice) return;
    return moment()
      .add(protoInvoice.invoiceConfig?.dueInDays, 'days')
      .format(MOMENT_DATE_SHORT_READABLE_FORMAT);
  }, [protoInvoice]);

  const emailConfig = useMemo(() => {
    if (!protoInvoice) return;
    return {
      subject: `${
        deal && buildEmailSubjectPrefixForProjectFromProject(deal)
      }Your invoice from ${companyName}`,
      bodyTemplate: emailTemplate,
      substitutions: [
        {
          find: 'reference',
          replaceWith:
            protoInvoice.customReference || String(protoInvoice.reference),
        },
        { find: 'company_name', replaceWith: companyName },
        { find: 'customer_name', replaceWith: customerContact?.name },
        {
          find: 'amount_due',
          replaceWith: currencyPrice(jobContent?.total || 0, region),
        },
        {
          find: 'due_date',
          replaceWith: dueDate,
        },
      ],
    };
  }, [
    companyName,
    emailTemplate,
    protoInvoice,
    customerContact?.name,
    jobContent?.total,
    region,
    dueDate,
  ]);

  const availableContextMenuItems = useMemo(() => {
    const actions: TDropdownItem[] = [];

    if (
      userHasRequiredPermission(userRoles, [InvoicesPermissions.SEND_INVOICE])
    ) {
      actions.push({
        label: 'Mark as sent',
        onClick: () => setShowMarkAsSentModal(true),
      });
    }

    return actions;
  }, []);

  if (!customer || !protoInvoice || !emailConfig) return null;

  return (
    <>
      <PreviewAndSendSendableDocument
        documentType="invoice"
        title={`Invoice ${
          protoInvoice?.customReference
            ? `#${protoInvoice?.customReference}`
            : ''
        }`}
        documentPreview={
          <ProtoInvoiceDocument
            protoInvoiceId={protoInvoiceId}
            placeholderReference={placeholderReference}
          />
        }
        recipientConfig={{
          customer: customer,
          contactId: protoInvoice?.contactId || undefined,
        }}
        emailConfig={emailConfig}
        onSend={handleSendProtoInvoice}
        actions={availableContextMenuItems}
      />
      <MarkProtoInvoiceAsSentModal
        protoInvoiceId={protoInvoiceId}
        isOpen={showMarkAsSentModal}
        onClose={() => setShowMarkAsSentModal(false)}
        markAsSentCallback={navigateToDeal}
      />
    </>
  );
};

export default PreviewAndSendProtoInvoiceControl;
