import { call, put, race, delay, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions';
import { SagaConfig, ActionType } from './pipelineTypes';
import { Req } from '@payaca/helpers/storeHelper';

import {
  createPipelineFailure,
  createPipelineSuccess,
  deletePipelineSuccess,
  deletePipelineFailure,
  getAccountPipelinesFailure,
  getAccountPipelinesSuccess,
  updatePipelineFailure,
  updatePipelineSuccess,
} from './pipelineActions';
import { Pipeline } from '@payaca/types/pipelineTypes';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const pipelineSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleGetPipelines(
    action: PayloadAction<
      ActionType.REQUEST_GET_ACCOUNT_PIPELINES,
      { callback?: any }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getAccountPipelines),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (!timeout) {
        yield put(getAccountPipelinesSuccess(response));

        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(getAccountPipelinesFailure(error));
    }
  }

  const getAccountPipelines = async () => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/pipelines`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getAccountPipelines failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleCreatePipeline(
    action: PayloadAction<
      ActionType.REQUEST_CREATE_PIPELINE,
      { title: string; templatePipelineId: number; callback?: any }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          createPipeline,
          action.payload.title,
          action.payload.templatePipelineId
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Create pipeline timed out.';
        yield put(createPipelineFailure(new Error(errorMessage)));
      } else {
        yield put(createPipelineSuccess(response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(createPipelineFailure(error));
    }
  }

  const createPipeline = async (title: string, templatePipelineId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/pipelines`, {
      method: 'POST',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
      body: JSON.stringify({ title, templatePipelineId }),
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `createPipeline failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleUpdatePipeline(
    action: PayloadAction<
      ActionType.REQUEST_UPDATE_PIPELINE,
      { pipelineId: number; pipeline: Pipeline }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          updatePipeline,
          action.payload.pipelineId,
          action.payload.pipeline
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Update pipeline timed out.';
        yield put(
          updatePipelineFailure(
            action.payload.pipelineId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(updatePipelineSuccess(response));
      }
    } catch (error: any) {
      yield put(updatePipelineFailure(action.payload.pipelineId, error));
    }
  }

  const updatePipeline = async (pipelineId: number, pipeline: Pipeline) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/pipelines/${pipelineId}`, {
      method: 'PUT',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
      body: JSON.stringify(pipeline),
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `updatePipeline failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleDeletePipeline(
    action: PayloadAction<
      ActionType.REQUEST_DELETE_PIPELINE,
      {
        pipelineId: number;
        callback?: any;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(deletePipeline, action.payload.pipelineId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Delete pipeline timed out.';
        yield put(deletePipelineFailure(new Error(errorMessage)));
      } else {
        yield put(deletePipelineSuccess(response));
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(deletePipelineFailure(error));
    }
  }

  const deletePipeline = async (pipelineId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/pipelines/${pipelineId}`, {
      method: 'DELETE',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `DeletePipeline failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  return function* () {
    yield takeLatest(
      ActionType.REQUEST_GET_ACCOUNT_PIPELINES,
      handleGetPipelines
    );
    yield takeLatest(ActionType.REQUEST_CREATE_PIPELINE, handleCreatePipeline);
    yield takeLatest(ActionType.REQUEST_UPDATE_PIPELINE, handleUpdatePipeline);
    yield takeLatest(ActionType.REQUEST_DELETE_PIPELINE, handleDeletePipeline);
  };
};

export default pipelineSagaCreator;
