import { LineItem } from '@payaca/types/lineItemTypes';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { DeclareAsyncAction } from '../types';

export interface LineItemsState {
  lineItems: {
    [key: number]: FetchedEntity<LineItem>;
  };

  isGettingLineItem: boolean;
  isPersistingLineItem: boolean;
  gettingLineItemError: string | null;
}

export enum ActionType {
  CLEAR_LINE_ITEMS = 'lineItemsV2.clearLineItems',

  REQUEST_GET_LINE_ITEM = 'lineItemsV2.requestGetLineItem',
  GET_LINE_ITEM_SUCCESS = 'lineItemsV2.getLineItemSuccess',
  GET_LINE_ITEM_FAILURE = 'lineItemsV2.getLineItemFailure',

  REQUEST_GET_LINE_ITEM_WITH_RELATED_ENTITIES = 'lineItemsV2.requestGetLineItemWithRelatedEntities',
  GET_LINE_ITEM_WITH_RELATED_ENTITIES_SUCCESS = 'lineItemsV2.getLineItemWithRelatedEntitiesSuccess',

  REQUEST_GET_LINE_ITEMS_FOR_LINE_ITEM_GROUP = 'lineItemsV2.requestGetLineItemsForLineItemGroup',
  GET_LINE_ITEMS_FOR_LINE_ITEM_GROUP_SUCCESS = 'lineItemsV2.getLineItemsForLineItemGroupSuccess',

  REQUEST_PERSIST_LINE_ITEM = 'lineItemsV2.requestPersistLineItem',
  PERSIST_LINE_ITEM_SUCCESS = 'lineItemsV2.persistLineItemSuccess',
  PERSIST_LINE_ITEM_FAILURE = 'lineItemsV2.persistLineItemFailure',

  DUPLICATE_LINE_ITEM_REQUEST = 'lineItemsV2.duplicateLineItem:request',
  DUPLICATE_LINE_ITEM_SUCCESS = 'lineItemsV2.duplicateLineItem:success',
  DUPLICATE_LINE_ITEM_FAILURE = 'lineItemsV2.duplicateLineItem:failure',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
  storage: {
    getItem: (key: string) => Promise<string>;
    setItem: (key: string, value: string) => Promise<void>;
  };
  storageKeys?: {
    taxRateId: string;
  };
}

export interface PersistLineItemRequestData {
  id?: number;
  description?: string | null;
  name?: string | null;
  price?: number | null;
  markupPercentage?: number | null;
  markupAmount?: number | null;
  currency?: string | null;
  vatIncluded?: boolean;
  isReverseChargeVat?: boolean;
  vatAmount?: number | null;
  taxRateId?: number | null;
  cisDeductionRate?: number | null;
  lineItemMaterials?: {
    id?: number;
    materialId: number;
    materialQuantity: number;
  }[];
}

export type DuplicateLineItemAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.DUPLICATE_LINE_ITEM_REQUEST;
      payload: {
        itemId: number;
        callback?: (newItemId: number) => void;
      };
    };
    success: {
      type: ActionType.DUPLICATE_LINE_ITEM_SUCCESS;
    };
    failure: {
      type: ActionType.DUPLICATE_LINE_ITEM_FAILURE;
    };
  }
>;
