import React, { FC, useContext, useEffect, useMemo } from 'react';
import { AvatarSizeVariant } from '@payaca/components/plAvatar/Avatar';
import ScheduleFilters from '../scheduleFilters/ScheduleFilters';
import UserAvatarSelectionControl from '../userAvatarSelectionControl/UserAvatarSelectionControl';
import { ScheduleContext } from '../contextProviders/ScheduleContextProvider';
import ButtonRadioGroup from '@payaca/components/plButtonRadioGroup/ButtonRadioGroup';
import {
  EBtnColour,
  EBtnSize,
} from '@payaca/components/plButton/useButtonClassName';
import Button from '@payaca/components/plButton/Button';
import AddressLookupControl from '../addressLookupControl/AddressLookupControl';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import { DispatchPermissions } from '@payaca/permissions/dispatch/dispatch.permissions';
import { useSelector } from '@/api/state';
import LinkButton from '@payaca/components/plButton/LinkButton';
import { useRouteMatch } from 'react-router-dom';

const ScheduleDispatchFilters: FC = () => {
  const { url } = useRouteMatch();
  const requestData = useContext(ScheduleContext).requestData;
  const displayType = useContext(ScheduleContext).displayType;
  const hideCreateEventButton =
    useContext(ScheduleContext).hideCreateEventButton;
  const currentUserId = useSelector((state: any) => state.users.myProfile.id);

  const onUpdate = useContext(ScheduleContext).onUpdate;

  return (
    <div>
      <PermissionGuard
        renderIfHasPermissions={[
          ScheduledEventsPermissions.GET_MY_DEAL_EVENTS,
          ScheduledEventsPermissions.GET_EVENTS,
          DispatchPermissions.GET_EVENTS,
          DispatchPermissions.PREVIEW_DISPATCH,
          ScheduledEventsPermissions.ADD_EVENT,
          ScheduledEventsPermissions.ADD_MY_EVENT,
        ]}
      >
        <div className="mb-4 flex flex-wrap gap-8">
          <div className="mr-auto">
            <PermissionGuard
              renderIfHasPermissions={[
                ScheduledEventsPermissions.GET_MY_DEAL_EVENTS,
                ScheduledEventsPermissions.GET_EVENTS,
              ]}
            >
              <PermissionGuard
                renderIfHasPermissions={[AccountsPermissions.GET_USERS]}
              >
                <UserAvatarSelectionControl
                  selectedUserIds={requestData.assignedUserIds || []}
                  onChange={(userIds) => {
                    onUpdate({ selectedUserIds: userIds });
                  }}
                  sizeVariant={AvatarSizeVariant.SM}
                  allowSelectNone={true}
                />
              </PermissionGuard>
            </PermissionGuard>
          </div>
          <PermissionGuard
            renderIfHasPermissions={[
              DispatchPermissions.PREVIEW_DISPATCH,
              DispatchPermissions.GET_EVENTS,
            ]}
          >
            <ButtonRadioGroup
              value={displayType}
              onChange={(displayType) => {
                onUpdate({ displayType, location: null });
              }}
              options={[
                { label: 'Map', value: 'map' },
                { label: 'Calendar', value: 'calendar' },
              ]}
              sizeVariant={EBtnSize.Small}
            />
          </PermissionGuard>
          {displayType === 'calendar' && !hideCreateEventButton && (
            <>
              <PermissionGuard
                renderIfHasPermissions={[
                  ScheduledEventsPermissions.ADD_EVENT,
                  ScheduledEventsPermissions.ADD_MY_EVENT,
                ]}
              >
                <LinkButton
                  to={`${url}/new`}
                  colour={EBtnColour.Blue}
                  size={EBtnSize.Small}
                >
                  Create Event
                </LinkButton>
              </PermissionGuard>
            </>
          )}
          {displayType === 'map' && (
            <PermissionGuard
              renderIfHasPermissions={[
                ScheduledEventsPermissions.ADD_EVENT,
                ScheduledEventsPermissions.ADD_MY_EVENT,
              ]}
            >
              <AddressLookupControl
                onChange={(change) =>
                  onUpdate({
                    location: {
                      line1: change.line1,
                      line2: change.line2,
                      city: change.city,
                      country: change.country,
                      postcode: change.postcode,
                    },
                  })
                }
                placeholder="Location search"
              />
            </PermissionGuard>
          )}
        </div>
      </PermissionGuard>
      <ScheduleFilters />
    </div>
  );
};
export default ScheduleDispatchFilters;
