import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { Deal } from '@payaca/types/dealTypes';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import JobCustomerControl from '../editJob/JobCustomerControl';

import TextareaFieldFormatter from '@payaca/components/textareaField/TextareaFieldFormatter';
import { useChangeProposal, useDeal, useInvoice } from '@payaca/store/hooks/appState';
import EditJobSection from '../editJob/EditJobSection';
import './EditChangeProposalControl.sass';
import {
  ChangeProposal,
  ChangeProposalUploadRoles,
} from '@payaca/types/changeProposalTypes';
import ReferenceControl from '../referenceControl/ReferenceControl';
import { PersistChangeProposalRequestData } from '@payaca/store/proposals/proposalsTypes';
import {
  requestGetChangeProposal,
  requestPersistChangeProposal,
} from '@payaca/store/proposals/proposalsActions';
import ChangeProposalJobLineItemsControl from '../changeProposalJobLineItemsControl/ChangeProposalJobLineItemsControl';
import ChangeProposalAcceptedJobLineItemsControl from '../changeProposalAcceptedJobLineItemsControl/ChangeProposalAcceptedJobLineItemsControl';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import UploadCollectionControl from '../uploadCollectionControl/UploadCollectionControl';
import * as uploadsActions from '@payaca/store/uploads/uploadsActions';
import AttachmentUploadsControl from '../attachmentUploadsControl/AttachmentUploadsControl';

type Props = {
  changeProposalId: number;
};

const EditChangeProposalControl: FC<Props> = ({
  changeProposalId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isSaveRequired, setIsSaveRequired] = useState(false);

  const changeProposal: ChangeProposal | undefined =
    useChangeProposal(changeProposalId);

  const deal: Deal | undefined = useDeal(changeProposal?.dealId);

  const [
    persistChangeProposalRequestData,
    setPersistChangeProposalRequestData,
  ] = useState<PersistChangeProposalRequestData>({
    id: changeProposal?.id,
  });

  useEffect(() => {
    if (changeProposal?.id) {
      setPersistChangeProposalRequestData({
        id: changeProposal?.id,
        notes: changeProposal?.notes,
        contactId: changeProposal?.contactId,
        customReference: changeProposal?.customReference,
      });
    }
  }, [changeProposal?.id]);

  useEffect(() => {
    if (isSaveRequired) {
      setIsSaveRequired(false);
      dispatch(
        requestPersistChangeProposal(persistChangeProposalRequestData, () => {
          dispatch(requestGetChangeProposal(changeProposalId));
        })
      );
    }
  }, [isSaveRequired]);

  if (!changeProposal) return null;

  return (
    <ResponsiveViewWrapper
      className="edit-change-proposal-control"
      downBreakpointSm={700}
      downBreakpointXs={450}
    >
      <ReferenceControl
        reference={changeProposal.reference}
        customReference={
          persistChangeProposalRequestData.customReference ||
          changeProposal.customReference ||
          undefined
        }
        referencedEntityName={'Change proposal'}
        onChange={(value) => {
          setPersistChangeProposalRequestData((x) => ({
            ...x,
            ...value,
          }));
          setIsSaveRequired(true);
        }}
      />
      <JobCustomerControl
        canSelectCustomer={false}
        customerId={deal?.customerId}
        jobContactId={persistChangeProposalRequestData.contactId || null}
        onSelectContact={(contactId: number) => {
          setPersistChangeProposalRequestData((x) => ({
            ...x,
            contactId: contactId,
          }));
          setIsSaveRequired(true);
        }}
      />
      <ChangeProposalAcceptedJobLineItemsControl
        changeProposalId={changeProposalId}
      />
      <ChangeProposalJobLineItemsControl changeProposalId={changeProposalId} />
      <EditJobSection title={'Notes'}>
        <TextareaFieldFormatter
          name="notes"
          value={persistChangeProposalRequestData.notes}
          onChange={(value) => {
            setPersistChangeProposalRequestData((x) => ({
              ...x,
              ...value,
            }));
          }}
          placeholder={`Give yourself an edge over your competitors by adding additional information to your change proposal.`}
          changeTimeoutMs={2000}
          onChangeTimeout={() => setIsSaveRequired(true)}
          onBlur={() => setIsSaveRequired(true)}
        />
      </EditJobSection>
      <AttachmentUploadsControl
        entityType="changeProposal"
        entityId={changeProposalId}
        prompt={
          'Show off some credentials. Photos of your team, completed jobs, awards, logos, industry accreditations. It all helps to win you work.'
        }
        attachmentUploadRole={ChangeProposalUploadRoles.ATTACHMENT}
        attachmentUploadIds={changeProposal.attachmentUploadIds}
        onPersistUploadSuccess={() =>
          dispatch(requestGetChangeProposal(changeProposalId))
        }
      />
    </ResponsiveViewWrapper>
  );
};
export default EditChangeProposalControl;
