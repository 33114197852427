import { Customer } from '@payaca/types/customerTypes';
import { JobPayment } from '@payaca/types/jobPaymentTypes';
import {
  Invoice,
  PaymentReconciliationRecord,
} from '@payaca/types/invoiceTypes';
import { InvoiceActionType } from '@payaca/types/invoiceActionTypes';
import { getJobContactFromCustomer } from './customerHelper';
import { getBacsPendingPayments } from './jobPaymentHelper';
import { isSentInvoice } from './invoiceHelper';

export const getAvailableActionsForInvoice = (
  invoice: Invoice,
  customer: Customer,
  jobPayments: JobPayment[],
  paymentReconciliationRecords: PaymentReconciliationRecord[]
): InvoiceActionType[] => {
  const jobActionTypes: InvoiceActionType[] = [];

  if (invoice.voidedAt) return jobActionTypes;

  jobActionTypes.push(InvoiceActionType.ASSIGN);
  jobActionTypes.push(InvoiceActionType.UNASSIGN);

  const invoiceHasSentSuccessfully =
    !invoice.bouncedAt && isSentInvoice(invoice);

  const contact = getJobContactFromCustomer(
    customer,
    invoice.contactId || null
  );

  const pendingPayments = getBacsPendingPayments(jobPayments).filter(
    (x) => !x.invoiceId || x.invoiceId == invoice.id
  );

  if (invoiceHasSentSuccessfully) {
    if (contact?.emailAddress) {
      jobActionTypes.push(InvoiceActionType.RESEND);
    }
    if (pendingPayments.length) {
      jobActionTypes.push(InvoiceActionType.CONFIRM_PENDING_PAYMENT);
    }
    jobActionTypes.push(InvoiceActionType.RECORD_PAYMENT);
    if (!paymentReconciliationRecords.some((x) => x.invoiceId == invoice.id)) {
      jobActionTypes.push(InvoiceActionType.VOID);
    }
  } else if (!invoiceHasSentSuccessfully && !invoice.bouncedAt) {
    jobActionTypes.push(InvoiceActionType.EDIT);
    jobActionTypes.push(InvoiceActionType.SEND);
    jobActionTypes.push(InvoiceActionType.MARK_AS_SENT);
  }
  if (invoice.pdfUrl) {
    jobActionTypes.push(InvoiceActionType.PRINT);
  }

  return jobActionTypes;
};
