import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { getJobsByDealId } from '@/utils/stateAccessors';
import { ProposalsPermissions } from '@payaca/permissions/proposals/proposals.permissions';

import { isInvoice } from '@payaca/helpers/jobStatusHelper';

import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import * as changeProposalActions from '@payaca/store/proposals/proposalsActions';

import {
  canAddChangeProposalToDeal,
  canAddProposalToDeal,
} from '@payaca/helpers/dealHelper';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import './DealProposals.sass';

import SectionHeader from '@payaca/components/sectionHeader/SectionHeader';
import { useChangeProposalsForDeal, useDeal } from '@payaca/store/hooks/appState';
import { requestGetChangeProposalsForDeal } from '@payaca/store/proposals/proposalsActions';
import { ChangeProposal } from '@payaca/types/changeProposalTypes';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import ChangeProposalCard from '../changeProposalCard/ChangeProposalCard';
import DealProposalHistoryTable from '../dealProposalHistoryTable/DealProposalHistoryTable';
import EditJobModal from '../editJobModal/EditJobModal';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import ProposalCard from '../proposalCard/ProposalCard';
import { useSelector } from '@/api/state';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import { Link } from 'react-router-dom';

interface Props {
  dealId: number;
  onDealUpdateSuccess: (updatedJobIds?: number[]) => void;
}

export const DealProposals: FC<Props> = ({
  dealId,
  onDealUpdateSuccess,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showEditWarningModal, setShowEditWarningModal] = useState(false);
  const [isCreatingChangeProposal, setIsCreatingChangeProposal] =
    useState(false);

  const jobs = useSelector((state) => {
    return getJobsByDealId(state, dealId);
  });

  const deal = useDeal(dealId);

  const proposals = useMemo(() => {
    return jobs?.filter((x) => !isInvoice(x.status) && !x.isProtoInvoice);
  }, [jobs, jobs?.length]);

  const activeProposal = useMemo(() => {
    return proposals.find((x) => !x.archivedAt && !x.inactivatedAt);
  }, [proposals]);

  const changeProposals = useChangeProposalsForDeal(dealId);

  useEffect(() => {
    dispatch(requestGetChangeProposalsForDeal(dealId));
  }, [dealId]);

  useEffect(() => {
    if (activeProposal?.jobContentId) {
      dispatch(
        jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
          activeProposal.jobContentId
        )
      );
    }
  }, [activeProposal?.jobContentId]);

  const navigateToProposal = (proposalId: number) => {
    history.push(`/quotes/${proposalId}`);
  };

  const navigateToChangeProposal = (
    changeProposalId: number,
    isEdit?: boolean
  ) => {
    history.push(
      `/deals/${dealId}/changeproposals/${changeProposalId}${
        isEdit ? '/edit' : ''
      }`
    );
  };

  const acceptedProposalElement = useMemo(() => {
    const acceptedChangeProposal = changeProposals
      .sort(
        (a: ChangeProposal, b: ChangeProposal) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .find((cp: ChangeProposal) => {
        return !cp.voidedAt && !cp.declinedAt && cp.acceptedAt;
      });

    if (acceptedChangeProposal)
      return (
        <ChangeProposalCard
          changeProposalId={acceptedChangeProposal.id}
          onClick={() => navigateToChangeProposal(acceptedChangeProposal.id)}
        />
      );
    const acceptedProposal = proposals.find(
      (x) => !x.archivedAt && !x.inactivatedAt && x.acceptedAt
    );

    return acceptedProposal ? (
      <ProposalCard
        proposalId={acceptedProposal.id}
        onClick={() => navigateToProposal(acceptedProposal.id)}
        onDealUpdateSuccess={onDealUpdateSuccess}
      />
    ) : undefined;
  }, [
    proposals,
    changeProposals,
    navigateToProposal,
    onDealUpdateSuccess,
    navigateToChangeProposal,
  ]);

  const pendingProposalElement = useMemo(() => {
    const pendingChangeProposal = changeProposals
      .sort(
        (a: ChangeProposal, b: ChangeProposal) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .find((cp: ChangeProposal) => {
        return !cp.voidedAt && !cp.declinedAt && !cp.acceptedAt;
      });

    if (pendingChangeProposal)
      return (
        <ChangeProposalCard
          changeProposalId={pendingChangeProposal.id}
          onClick={() => navigateToChangeProposal(pendingChangeProposal.id)}
        />
      );
    const pendingProposal = proposals.find(
      (x) => !x.archivedAt && !x.inactivatedAt && !x.acceptedAt
    );

    return pendingProposal ? (
      <ProposalCard
        proposalId={pendingProposal.id}
        onClick={() => navigateToProposal(pendingProposal.id)}
        onDealUpdateSuccess={onDealUpdateSuccess}
      />
    ) : undefined;
  }, [
    proposals,
    changeProposals,
    navigateToProposal,
    navigateToChangeProposal,
    onDealUpdateSuccess,
  ]);

  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);

  const handleProposeChange = useCallback(() => {
    if (!deal) {
      return;
    }
    if (canAddChangeProposalToDeal(deal, proposals, changeProposals)) {
      setIsCreatingChangeProposal(true);
      dispatch(
        changeProposalActions.requestPersistChangeProposal(
          { dealId: deal.id },
          (changeProposalId: number) => {
            setIsCreatingChangeProposal(false);
            navigateToChangeProposal(changeProposalId, true);
          },
          () => {
            setIsCreatingChangeProposal(false);
          }
        )
      );
    } else {
      showDynamicFeedbackMessage({
        title: 'Unable to propose a change',
        body: 'You cannot propose a change whilst there are outstanding pending changes',
        isCancellable: true,
        lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
        feedbackLevel: FeedbackLevel.ALERT,
      });
    }
  }, [deal, proposals, changeProposals, navigateToChangeProposal]);

  const actionsComponent = useMemo(() => {
    if (canAddProposalToDeal(deal) || !proposals?.length) {
      return (
        <Link to={`/quotes/new?dealId=${dealId}`}>
          <Button styleVariant={ButtonStyleVariant.ANCHOR}>
            Create Proposal
          </Button>
        </Link>
      );
    }

    if (activeProposal && !deal?.changeProposalIds.length) {
      if (!activeProposal.sentAt) {
        return (
          <Link to={`/quotes/${activeProposal.id}`}>
            <Button styleVariant={ButtonStyleVariant.ANCHOR}>
              Edit Proposal
            </Button>
          </Link>
        );
      } else if (!activeProposal.acceptedAt) {
        return (
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => setShowEditWarningModal(true)}
          >
            Edit Proposal
          </Button>
        );
      }
    }

    return (
      <PermissionGuard
        renderIfHasPermissions={[ProposalsPermissions.PERSIST_CHANGE_PROPOSAL]}
      >
        <Button
          styleVariant={ButtonStyleVariant.ANCHOR}
          onClick={isCreatingChangeProposal ? undefined : handleProposeChange}
          isProcessing={isCreatingChangeProposal}
        >
          Propose change
        </Button>
      </PermissionGuard>
    );
  }, [handleProposeChange, deal, isCreatingChangeProposal]);

  return (
    <div className="deal-proposals">
      <>
        <section className="proposals">
          <SectionHeader
            headerComponent={<h3>Proposals</h3>}
            actionsComponent={actionsComponent}
          />
          <div className="proposals-cards-wrapper">
            {acceptedProposalElement}
            {pendingProposalElement}
          </div>
        </section>
        {!!proposals?.length && (
          <section className="history">
            <SectionHeader headerComponent={<h3>History</h3>} />
            <DealProposalHistoryTable
              dealId={dealId}
              navigateToProposal={navigateToProposal}
              navigateToChangeProposal={navigateToChangeProposal}
            />
          </section>
        )}
        {activeProposal && (
          <EditJobModal
            isOpen={showEditWarningModal}
            onClose={() => setShowEditWarningModal(false)}
            jobId={activeProposal.id}
          />
        )}
      </>
    </div>
  );
};
