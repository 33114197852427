export enum PayacaPagesTitles {
  NEW_LEAD = 'New Lead Capture',
}
export enum PayacaPagesTemplates {
  NEW_LEAD = 'new_lead',
}

export interface PayacaPagesConfig {
  token: string;
  template: PayacaPagesTemplates;
  isEmbedded: boolean;
  tagIds?: number[];
  pipelineId?: number;
  logoURL?: string;
  color: string;
  questions: string[];
  cellPadding: number;
  cellSpacing: number;
  borderWidth: number;
  borderRadius: number;
}

export interface PayacaPageTemplateProps extends PayacaPagesConfig {
  onSubmit: (data: any) => void;
  isComplete: boolean;
  isLoading: boolean;
}

export enum PayacaPagesQuestionTypes {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  RADIO_GROUP = 'radio',
  CHECKBOX = 'checkbox',
  ADDRESS = 'address',
}

/**
 * optionGroupId
 *    1 - General
 *    2 - Plumbing & Heating
 */

export const PayacaPagesQuestionOptions: Array<{
  id: string;
  label: string;
  type: PayacaPagesQuestionTypes;
  suffix?: string;
  options?: string[];
  placeholder?: string;
  optionGroupId?: number;
}> = [
  {
    id: '0',
    label: 'How can we help you?',
    placeholder: `Describe your requirements and we'll review it before getting back to you`,
    type: PayacaPagesQuestionTypes.TEXTAREA,
    optionGroupId: 1,
  },
  {
    id: '1',
    label: 'Are you an existing customer?',
    type: PayacaPagesQuestionTypes.RADIO_GROUP,
    options: ['Existing customer', 'New customer'],
    optionGroupId: 1,
  },
  {
    id: '15',
    label: 'Are you a Service Plan member?',
    type: PayacaPagesQuestionTypes.RADIO_GROUP,
    options: ['Yes', 'No'],
    optionGroupId: 1,
  },
  {
    id: '16',
    label: 'Is an emergency call out required?',
    type: PayacaPagesQuestionTypes.RADIO_GROUP,
    options: ['Yes', 'No'],
    optionGroupId: 1,
  },
  {
    id: '6',
    label: 'Postcode of the property',
    type: PayacaPagesQuestionTypes.TEXT,
    optionGroupId: 1,
  },
  {
    id: '17',
    label: 'Address of the property',
    type: PayacaPagesQuestionTypes.ADDRESS,
    optionGroupId: 1,
  },
  {
    id: '7',
    label: 'Preferred contact method',
    type: PayacaPagesQuestionTypes.RADIO_GROUP,
    optionGroupId: 1,
    options: ['Phone', 'SMS', 'Email'],
  },
  {
    id: '2',
    label: 'Do you have a combi boiler?',
    type: PayacaPagesQuestionTypes.RADIO_GROUP,
    options: [
      'Yes, I have a combi boiler',
      'No, I do not have a combi boiler',
      'Not sure',
    ],
    optionGroupId: 2,
  },
  {
    id: '3',
    label: 'Approximate age of the boiler?',
    suffix: `years old`,
    type: PayacaPagesQuestionTypes.NUMBER,
    optionGroupId: 2,
  },
  {
    id: '4',
    label: 'Is the boiler under warranty?',
    type: PayacaPagesQuestionTypes.RADIO_GROUP,
    options: [
      'Yes, the boiler is under warranty',
      'No, the boiler is not under warranty',
      'Not sure',
    ],
    optionGroupId: 2,
  },
  {
    id: '5',
    label: 'Are you currently experiencing any problems?',
    placeholder: `Describe your issue and we'll review it before getting back to you`,
    type: PayacaPagesQuestionTypes.TEXTAREA,
    optionGroupId: 2,
  },
  {
    id: '8',
    label: 'What type of installation are you interested in?',
    type: PayacaPagesQuestionTypes.RADIO_GROUP,
    optionGroupId: 2,
    options: ['Gas boiler', 'Heat pump', 'Not sure yet'],
  },
  {
    id: '9',
    label: 'Do you have a hot water cylinder?',
    type: PayacaPagesQuestionTypes.RADIO_GROUP,
    optionGroupId: 2,
    options: ['Yes', 'No'],
  },
  {
    id: '10',
    label: 'What type of heating system do you have?',
    type: PayacaPagesQuestionTypes.RADIO_GROUP,
    optionGroupId: 2,
    options: ['Combi boiler', 'Boiler and hot water cylinder', "I'm unsure"],
  },
  {
    id: '11',
    label: 'Do you have an electric shower?',
    type: PayacaPagesQuestionTypes.RADIO_GROUP,
    optionGroupId: 2,
    options: ['Yes', 'No'],
  },
  {
    id: '12',
    label: 'Is the current boiler fully working?',
    type: PayacaPagesQuestionTypes.RADIO_GROUP,
    optionGroupId: 2,
    options: [
      "Yes, it's fully working",
      'It has intermittent faults',
      'No, please help! I have no heat or hot water!',
    ],
  },
  {
    id: '13',
    label: 'Is the boiler under warranty/guarantee?',
    type: PayacaPagesQuestionTypes.RADIO_GROUP,
    optionGroupId: 2,
    options: [
      'No, there is no warranty/guarantee',
      'Yes, it is still under warranty/guarantee',
      "I'm unsure it was here when we moved in",
    ],
  },
  {
    id: '14',
    label: 'What do you need help with?',
    type: PayacaPagesQuestionTypes.RADIO_GROUP,
    optionGroupId: 2,
    options: [
      'A non-emergency fault with my heating',
      'Emergency! Help is needed!',
      'Book my annual boiler service',
      "I'm a service plan/care customer",
      'Standard annual boiler service',
      'Landlord gas safety certificate',
    ],
  },
];
