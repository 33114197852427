import React, { FC, useCallback, useMemo } from 'react';

import { JobLineItem, JobLineItemGroup } from '@payaca/types/jobContentTypes';
import { JobLineItemAttachment } from '@payaca/types/jobTypes';
import { Account } from '@payaca/types/accountTypes';
import { BrandingColourContext } from '@payaca/types/accountBrandTypes';
import JobDocumentItemsSection from './JobDocumentItemsSection';
import './JobDocumentItemGroup.sass';
import { Deal } from '@payaca/types/dealTypes';
import DocumentCard from '../clientDocument/DocumentCard';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import { currencyPrice } from '@payaca/helpers/financeHelper';

type Props = {
  account: Pick<Account, 'region'>;
  hideItemPrices: boolean;
  hideVat: boolean;
  isEditable: boolean;
  isProposition?: boolean;
  jobLineItemGroup: JobLineItemGroup;
  jobLineItemAttachments: JobLineItemAttachment[];
  jobLineItems: JobLineItem[];
  onUpdate: (payload: {
    jobLineItems: { jobLineItemId: number; isSelected: boolean }[];
  }) => void;
  brandingColourContext?: BrandingColourContext;
  dealVersion: Deal['version'];
  isAnyVatIncluded?: boolean;
};

const JobDocumentItemGroup: FC<Props> = ({
  account,
  hideItemPrices,
  hideVat,
  isEditable,
  isProposition = false,
  jobLineItemGroup,
  jobLineItems,
  jobLineItemAttachments,
  onUpdate,
  brandingColourContext,
  dealVersion,
  isAnyVatIncluded = true,
}: Props): JSX.Element => {
  const onOptionalClick = useCallback(
    (item: JobLineItem) => {
      onUpdate({
        jobLineItems: [
          {
            jobLineItemId: item.id,
            isSelected: !item.isSelected,
          },
        ],
      });
    },
    [onUpdate]
  );

  const onMultipleChoiceClick = useCallback(
    (selectedItem: JobLineItem) => {
      //  get all multple choice items in group and update isSelected
      const itemsToUpdate = jobLineItems
        .filter((i) => i.isMultipleChoice)
        .map((jobLineItem) => {
          return {
            jobLineItemId: jobLineItem.id,
            isSelected: jobLineItem.id === selectedItem.id,
          };
        });
      onUpdate({ jobLineItems: itemsToUpdate });
    },
    [jobLineItems, onUpdate]
  );

  const onItemClick = useCallback(
    (item: JobLineItem) => {
      if (item.isOptional) {
        onOptionalClick(item);
      } else if (item.isMultipleChoice) {
        onMultipleChoiceClick(item);
      }
    },
    [onOptionalClick, onMultipleChoiceClick]
  );

  const requiredItems = useMemo(() => {
    return jobLineItems
      ? jobLineItems.filter(
          (jobLineItem) =>
            !jobLineItem.isOptional && !jobLineItem.isMultipleChoice
        )
      : [];
  }, [jobLineItems]);

  const optionalItems = useMemo(() => {
    return jobLineItems
      ? jobLineItems.filter((jobLineItem) => jobLineItem.isOptional)
      : [];
  }, [jobLineItems]);

  const multipleChoiceItems = useMemo(() => {
    return jobLineItems
      ? jobLineItems.filter((jobLineItem) => jobLineItem.isMultipleChoice)
      : [];
  }, [jobLineItems]);

  const groupSubTotal = useMemo(
    () =>
      jobLineItems.reduce((acc, lineItem) => {
        if (
          lineItem.isSelected ||
          (!lineItem.isOptional && !lineItem.isMultipleChoice)
        ) {
          return acc + lineItem.total;
        }

        return acc;
      }, 0),
    [jobLineItems]
  );

  const vatRegionalLabel = useMemo(
    () =>
      getRegionalTextString(account?.region, RegionalStrings.VALUE_ADDED_TAX),
    [account]
  );

  const allItemsHaveNoVAT = useMemo(() => {
    // no items were found that included VAT
    return !jobLineItems.find((jli: JobLineItem) => jli.vatIncluded);
  }, [jobLineItems]);

  const hasAnyReverseChargeVat = useMemo(() => {
    // hide vat if no items have any vat included
    return jobLineItems.some((jli: JobLineItem) => jli.isReverseChargeVat);
  }, [jobLineItems]);

  const invoiceSelectedItems = useMemo(() => {
    return jobLineItems
      ? jobLineItems.filter(
          (jobLineItem) =>
            (!jobLineItem.isOptional && !jobLineItem.isMultipleChoice) ||
            jobLineItem.isSelected
        )
      : [];
  }, [jobLineItems]);

  const showDescription = useMemo(
    () => isProposition && jobLineItemGroup.description,
    [isProposition, jobLineItemGroup.description]
  );

  return (
    <DocumentCard
      title={showDescription ? jobLineItemGroup.description : undefined}
      avoidPrintBreak={false}
    >
      {/* items */}
      {isProposition ? (
        <div className="flex flex-col gap-y-6 print:block">
          {requiredItems.length > 0 && (
            <JobDocumentItemsSection
              title="Included items"
              icon="file-check-01.3"
              account={account}
              jobLineItems={requiredItems}
              jobLineItemAttachments={jobLineItemAttachments}
              onItemClick={onItemClick}
              isEditable={isEditable}
              isProposition={isProposition}
              hideItemPrices={hideItemPrices}
              hideVat={hideVat}
              brandingColourContext={brandingColourContext}
              dealVersion={dealVersion}
              isAnyVatIncluded={isAnyVatIncluded}
            />
          )}
          {multipleChoiceItems.length > 0 && (
            <JobDocumentItemsSection
              title="Multiple choice"
              icon="server-01.3"
              account={account}
              jobLineItems={multipleChoiceItems}
              jobLineItemAttachments={jobLineItemAttachments}
              onItemClick={onItemClick}
              isEditable={isEditable}
              isProposition={isProposition}
              hideItemPrices={hideItemPrices}
              hideVat={hideVat}
              brandingColourContext={brandingColourContext}
              dealVersion={dealVersion}
              isAnyVatIncluded={isAnyVatIncluded}
            />
          )}
          {optionalItems.length > 0 && (
            <JobDocumentItemsSection
              title="Optional Extras"
              icon="plus-square.3"
              account={account}
              jobLineItems={optionalItems}
              jobLineItemAttachments={jobLineItemAttachments}
              onItemClick={onItemClick}
              isEditable={isEditable}
              isProposition={isProposition}
              hideItemPrices={hideItemPrices}
              hideVat={hideVat}
              brandingColourContext={brandingColourContext}
              dealVersion={dealVersion}
              isAnyVatIncluded={isAnyVatIncluded}
            />
          )}
        </div>
      ) : (
        <JobDocumentItemsSection
          account={account}
          jobLineItems={invoiceSelectedItems}
          jobLineItemAttachments={jobLineItemAttachments}
          isEditable={isEditable}
          isProposition={isProposition}
          onItemClick={onItemClick}
          hideItemPrices={hideItemPrices}
          hideVat={hideVat}
          brandingColourContext={brandingColourContext}
          dealVersion={dealVersion}
          isAnyVatIncluded={isAnyVatIncluded}
        />
      )}

      {!hideItemPrices && (
        <div className="mt-3 text-right">
          <p className="mb-0 text-base">
            Subtotal:{' '}
            <b className="text-base">
              {currencyPrice(groupSubTotal, account?.region)}
            </b>
          </p>
          {!allItemsHaveNoVAT && !hasAnyReverseChargeVat && !hideVat && (
            <p className="text-sm leading-[1rem]">Inc {vatRegionalLabel}</p>
          )}
        </div>
      )}
    </DocumentCard>
  );
};

export default JobDocumentItemGroup;
