import React, { FunctionComponent, useCallback } from 'react';

import FieldLabel from '../fieldLabel/FieldLabel';

import './PillSelectionField.sass';

export enum PillSelectionStyleVariant {
  STANDARD = 'standard',
  OUTSIZE = 'outsize',
}

type Props = {
  styleVariant?: PillSelectionStyleVariant;
  name: string;
  value?: any;
  label?: string;
  description?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange?: (value: { [key: string]: string }) => void;
  onTouch?: (fieldName: string) => void;
  options: { value: any; label: React.ReactNode }[];
  hasBoxShadow?: boolean;
  hasBorder?: boolean;
};

const PillSelectionField: FunctionComponent<React.PropsWithChildren<Props>> = ({
  styleVariant = PillSelectionStyleVariant.STANDARD,
  name,
  value,
  label,
  description,
  isDisabled = false,
  isRequired = false,
  onChange,
  onTouch,
  options,
  hasBoxShadow = false,
  hasBorder = false,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const onClick = useCallback(
    (optionValue: any) => {
      if (isDisabled) return;

      onChange && onChange({ [name]: optionValue });
      onTouch && onTouch(name);
    },
    [name, isDisabled, onChange, onTouch]
  );

  return (
    <div
      className={`pill-selection-field pill-selection-style-${styleVariant} ${
        hasBoxShadow ? 'box-shadow' : ''
      } ${hasBorder ? 'pill-border' : ''} ${isDisabled ? 'disabled' : ''}`}
    >
      <FieldLabel
        label={label}
        isRequired={isRequired}
        description={description}
      />
      <div className="pill-selection-field-inner">
        {options.map((option, i: number) => {
          return (
            <button
              type="button"
              className={`pill-selection-option ${
                value === option.value ? 'selected' : ''
              }`}
              key={`pill-selection-option-${i}`}
              onClick={() => onClick(option.value)}
            >
              <span>{option.label}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default PillSelectionField;
