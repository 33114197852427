export interface State {
  isValidatingEmail: boolean;
  isValidatingPhone: boolean;

  isLookingUpAddressBySearchTerm: boolean;
  isRetrievingAddress: boolean;

  isGettingBlockedEmails: boolean;
  blockedEmails: string[];
}
export enum ActionType {
  REQUEST_VALIDATE_EMAIL = 'validation.requestValidateEnail',
  VALIDATE_EMAIL_SUCCESS = 'validation.validateEmailSuccess',
  VALIDATE_EMAIL_FAILURE = 'validation.validateEmailFailure',

  REQUEST_VALIDATE_PHONE = 'validation.requestValidatePhone',
  VALIDATE_PHONE_SUCCESS = 'validation.validatePhoneSuccess',
  VALIDATE_PHONE_FAILURE = 'validation.validatePhoneFailure',

  REQUEST_LOOKUP_ADDRESS_BY_SEARCH_TERM = 'validation.lookupAddressBySearchTerm',
  LOOKUP_ADDRESS_BY_SEARCH_TERM_SUCCESS = 'validation.lookupAddressBySearchTermSuccess',
  LOOKUP_ADDRESS_BY_SEARCH_TERM_FAILURE = 'validation.lookupAddressBySearchTermFailure',

  REQUEST_RETRIEVE_ADDRESS = 'validation.retrieveAddress',
  RETRIEVE_ADDRESS_SUCCESS = 'validation.retrieveAddressSuccess',
  RETRIEVE_ADDRESS_FAILURE = 'validation.retrieveAddressFailure',

  REQUEST_GET_BLOCKED_EMAILS = 'validation.requestGetBlockedEmails',
  GET_BLOCKED_EMAILS_SUCCESS = 'validation.getBlockedEmailsSuccess',
  GET_BLOCKED_EMAILS_FAILURE = 'validation.getBlockedEmailsFailure',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}
