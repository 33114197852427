import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  GetServicePlansSubscriptionsInput,
  PaginationInput,
} from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import servicePlanKeys from './keyFactory';

const GetServicePlansSubscriptions = graphql(`
  query ServicePlansSubscribers(
    $input: GetServicePlansSubscriptionsInput
    $pagination: PaginationInput!
  ) {
    servicePlansSubscriptions(input: $input, pagination: $pagination) {
      items {
        __typename
        ... on IEntity {
          id
        }
        stripeSubscriptionId
        status
        calculatedStatus
        validFrom
        validTo
        customer {
          id
          name
        }
        servicePlan {
          name
          id
        }
        servicePlanPrice {
          basicPrice
          billingInterval
          currencyCode
        }
      }
      offset
      totalCount
      limit
    }
  }
`);

const useGetServicePlanSubscriptions = (
  pagination: PaginationInput,
  input?: GetServicePlansSubscriptionsInput
) => {
  return useQuery({
    queryKey: servicePlanKeys.servicePlansSubscribers(input, pagination),
    queryFn: () => {
      return gqlClient.request(GetServicePlansSubscriptions, {
        input,
        pagination: pagination || PAGINATION_ARG_DEFAULTS,
      });
    },
  });
};

export default useGetServicePlanSubscriptions;
