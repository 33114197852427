import {
  ComponentProps,
  FC,
  PropsWithChildren,
  useContext,
  useRef,
} from 'react';
import Input from '../plInput/Input';
import { FieldContext } from '../plField/Field';

type TProps = Pick<
  ComponentProps<typeof Input>,
  'value' | 'onChange' | 'onTouch'
>;

export const ColourInput: FC<PropsWithChildren<TProps>> = ({
  value,
  onChange,
  onTouch,
  children,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { id, name, validationState } = useContext(FieldContext);

  return (
    <>
      {children && (
        <div
          className="inline-block cursor-pointer"
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          {children}
        </div>
      )}
      <input
        name={name}
        id={id}
        ref={inputRef}
        value={value}
        onChange={(e) => {
          onChange?.(e?.target.value);
          onTouch?.();
        }}
        type="color"
        className={`${
          children
            ? 'collapse h-0 w-0 border-none p-0'
            : 'h-10 w-14 border  border-solid p-1'
        } block  cursor-pointer rounded-lg border-gray-200 border-gray-200 bg-white focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 dark:border-neutral-700 dark:bg-neutral-900`}
      />
    </>
  );
};
