import { takeEvery, call, put, race, delay } from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions';

import {
  SagaConfig,
  ActionType,
  GetListedMaterialsRequestData,
} from './listedMaterialsTypes';
import {
  clearListedMaterialsPage,
  getListedMaterialsPageFailure,
  getListedMaterialsPageSuccess,
} from './listedMaterialsActions';

import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const listedMaterialsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleGetListedMaterialsPage(
    action: PayloadAction<
      ActionType.REQUEST_GET_LISTED_MATERIALS_PAGE,
      {
        getListedMaterialsRequestData: GetListedMaterialsRequestData;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getListedMaterialsPage,
          action.payload.getListedMaterialsRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getListedMaterialsPageFailure(
            new Error('Get listed materials page timed out.')
          )
        );
      } else {
        yield put(getListedMaterialsPageSuccess(response));
      }
    } catch (error: any) {
      yield put(getListedMaterialsPageFailure(error as Error));
    }
  }

  function* handleAppLogout() {
    yield put(clearListedMaterialsPage());
  }

  const getListedMaterialsPage = async (
    getListedMaterialsRequestData: GetListedMaterialsRequestData
  ) => {
    const response = await req.get(
      `/materials/list?pageNumber=${
        getListedMaterialsRequestData.pageNumber
      }&pageSize=${getListedMaterialsRequestData.pageSize}&searchTerm=${
        getListedMaterialsRequestData.searchTerm || ''
      }&sortDirection=${
        getListedMaterialsRequestData.sortDirection || ''
      }&sortBy=${getListedMaterialsRequestData.sortBy || ''}&categoryIds=${
        getListedMaterialsRequestData.categoryIds
          ? getListedMaterialsRequestData.categoryIds.join(',')
          : ''
      }&supplierIds=${
        getListedMaterialsRequestData.supplierIds
          ? getListedMaterialsRequestData.supplierIds.join(',')
          : ''
      }&managedBy=${
        getListedMaterialsRequestData.managedBy
          ? getListedMaterialsRequestData.managedBy.join(',')
          : ''
      }`
    );
    return await response.json();
  };

  return function* () {
    yield takeEvery(
      ActionType.REQUEST_GET_LISTED_MATERIALS_PAGE,
      handleGetListedMaterialsPage
    );
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default listedMaterialsSagaCreator;
