export type ElectricalFormVersion = '18' | '18.2';

export const overcurrentProtectionDeviceBss = [
  'BSEN 60947-3 Isolator',
  'BS 5419 Isolator',
  'BS 3036 Fuse',
  'BS 4265',
  'BS 60269-2',
  'BS 1361 Fuse HBC Domestic Type 1',
  'BS 1361 Fuse HBC Domestic Type 2',
  'BS 1362 Fuse Domestic',
  'BS 88-2 Fuse System E (bolted)',
  'BS 88-2 Fuse System G (clip-in)',
  'BS 88-2 Fuse HRC gM (Motor)',
  'BS 88-2 Fuse HRC gG (General)',
  'BS 88-3 Fuse System C',
  'BSEN 60898 MCB Type B',
  'BSEN 60898 MCB Type C',
  'BSEN 60898 MCB Type D',
  'BSEN 60947-2',
  'BSEN 60947-2 MCB Type B',
  'BSEN 60947-2 MCB Type C',
  'BSEN 60947-2 MCB Type D',
  'BSEN 60947-2 MCCB',
  'BSEN 60947-2 ACB',
  'BS 3871 MCB Type 1',
  'BS 3871 MCB Type 2',
  'BS 3871 MCB Type 3',
  'BS 3871 MCB Type 4',
  'BSEN 4752-1 Type B',
  'BSEN 4752-1 Type D',
  'BSEN 7288 RCD',
  'BSEN 4293 RCD',
  'BSEN 61008-1 RCD',
  'BSEN 61008-1 AC RCD',
  'BSEN 61008-1 A RCD',
  'BSEN 61008-1 B RCD',
  'BSEN 61008-1 F RCD',
  'BSEN 61009-1 RCBO B (OCPD Type)',
  'BSEN 61009-1 RCBO C (OCPD Type)',
  'BSEN 61009-1 RCBO D (OCPD Type)',
  'BSEN 61009-1 RCBO AC (RCD Type)',
  'BSEN 61009-1 RCBO A (RCD Type)',
  'BSEN 61009-1 RCBO B (RCD Type)',
  'BSEN 61009-1 RCBO F (RCD Type)',
  'BSEN 62606 B RCD (OCPD Type)',
  'BSEN 62606 C RCD (OCPD Type)',
  'BSEN 62606 D RCD (OCPD Type)',
  'BSEN 62606 AC RCD (RCD Type)',
  'BSEN 62606 A RCD (RCD Type)',
  'BSEN 62606 F RCD (RCD Type)',
  'BSEN 62606 B RCD (RCD Type)',
  'BSEN 62423 B RCD (OCPD Type)',
  'BSEN 62423 C RCD (OCPD Type)',
  'BSEN 62423 D RCD (OCPD Type)',
  'BSEN 62423 B RCD (RCD Type)',
  'BSEN 62423 F RCD (RCD Type)',
  'N/A',
  'BSEN 62602 (No Type)',
  '61643 Type 1 SPD',
  '61643 Type 2 SPD',
  '61643 Type 3 SPD',
  '61643 Type NA SPD',
  'Non-verifiable',
  'Limitation',
];
type OvercurrentProtectionDeviceBs =
  typeof overcurrentProtectionDeviceBss[number];

export const overcurrentProtectionNominalVoltages = [
  '110',
  '230',
  '240',
  '400',
  '415',
];
type OvercurrentProtectionNominalVoltage =
  typeof overcurrentProtectionNominalVoltages[number];

export const ratings = [
  1,
  2,
  2.5,
  3,
  4,
  5,
  6,
  7.5,
  8,
  10,
  13,
  15,
  16,
  20,
  25,
  30,
  32,
  40,
  45,
  50,
  60,
  63,
  70,
  80,
  90,
  100,
  113,
  125,
  150,
  160,
  175,
  200,
  225,
  250,
  300,
  315,
  320,
  350,
  400,
  480,
  500,
  600,
  630,
  800,
  1000,
  1200,
  1250,
  1600,
  2000,
  2500,
  2600,
  3000,
  3200,
  4000,
  'LIM',
  'N/A',
];
type Rating = typeof ratings[number];

export const rcdBss = [
  'BSEN 7288 RCD',
  'BSEN 4293 RCD',
  'BSEN 61008-1 RCD',
  'BSEN 61008-1 AC RCD',
  'BSEN 61008-1 A RCD',
  'BSEN 61008-1 B RCD',
  'BSEN 61008-1 F RCD',
  'BSEN 61009-1 RCBO B (OCPD Type)',
  'BSEN 61009-1 RCBO C (OCPD Type)',
  'BSEN 61009-1 RCBO D (OCPD Type)',
  'BSEN 61009-1 RCBO AC (RCD Type)',
  'BSEN 61009-1 RCBO A (RCD Type)',
  'BSEN 61009-1 RCBO B (RCD Type)',
  'BSEN 61009-1 RCBO F (RCD Type)',
  'BSEN 62606 B RCD (OCPD Type)',
  'BSEN 62606 C RCD (OCPD Type)',
  'BSEN 62606 D RCD (OCPD Type)',
  'BSEN 62606 AC RCD (RCD Type)',
  'BSEN 62606 A RCD (RCD Type)',
  'BSEN 62606 F RCD (RCD Type)',
  'BSEN 62606 B RCD (RCD Type)',
  'BSEN 62423 B RCD (OCPD Type)',
  'BSEN 62423 C RCD (OCPD Type)',
  'BSEN 62423 D RCD (OCPD Type)',
  'BSEN 62423 B RCD (RCD Type)',
  'BSEN 62423 F RCD (RCD Type)',
  'N/A',
];
type RcdBs = typeof rcdBss[number];

export const rcdTypes = [
  'AC',
  'A',
  'B',
  'F',
  'AC(S)',
  'A(S)',
  'B(S)',
  'F(S)',
  'N/A',
];
type RcdType = typeof rcdTypes[number];

export const currents = [
  '10',
  '100',
  '300',
  '500',
  '30',
  '100s',
  '300S',
  '500s',
  '1000',
  'LIM',
  'N/A',
];
type Current = typeof currents[number];

export const completionStatuses = ['✓', 'N/A', 'Lim'];
export type CompletionStatus = typeof completionStatuses[number];

export type DistributionBoard = {
  location?: string;
  designation?: string;
  testEngineerName?: string;
  testEngineerPosition?: string;
  testEngineerSignatureBase64?: string;
  testEngineerDate?: string;
  multiFunctionTestInstrumentSerialNumber?: string;
  continuityTestInstrumentSerialNumber?: string;
  insulationResistanceTestInstrumentSerialNumber?: string;
  earthFaultLoopImpedanceTestInstrumentSerialNumber?: string;
  earthElectrodeResistanceTestInstrumentSerialNumber?: string;
  rcdTestInstrumentSerialNumber?: string;
  circuits: Array<FinalCircuit>;
  prospectiveFaultCurrent?: number;

  zDb?: number;

  confirmationOfSupplyPolarity?: CompletionStatus;
  phaseSequenceConfirmed?: CompletionStatus;
  spdDetailsT1?: CompletionStatus;
  spdDetailsT2?: CompletionStatus;
  spdDetailsT3?: CompletionStatus;
  spdDetailsT4?: CompletionStatus;
  statusIndicatorChecked?: CompletionStatus;

  isNotConnectedToOrigin?: boolean;
  supplyIsFrom?: string;

  overcurrentProtectionDeviceBs?: OvercurrentProtectionDeviceBs;
  overcurrentProtectionNominalVoltage?: OvercurrentProtectionNominalVoltage;
  overcurrentProtectionRating?: Rating;
  overcurrentProtectionNoOfPhases?: number;

  associatedRcdBs?: RcdBs;
  associatedRcdType?: RcdType;
  associatedRcdResidualCurrent?: Current;
  associatedRcdNoOfPoles?: string;
  associatedRcdOperatingTime?: string;
};

export type FinalCircuit = {
  number?: string;
  description?: string;
  wiringType?: string;
  wiringTypeOther?: string;
  referenceMethod?: string;
  numPointsServed?: number;
  liveConductorCsa?: string;
  cpcConductorCsa?: string;
  maxDisconnectionTime?: string;
  overcurrentProtectiveDevice?: string;
  overcurrentProtectiveDeviceType?: string;
  currentRating?: string;
  shortCircuitCapacity?: string;
  rcdOperatingCurrent?: Current;
  maxPermittedZs?: number;
  lineImpedance?: number;
  neutralImpedance?: number;
  cpcImpedance?: number;
  r1PlusR2Impedance?: number;
  r2Impedance?: number;
  liveLiveInsulationResistance?: number;
  liveEarthInsulationResistance?: number;
  testVoltage?: number;
  polarity?: string;
  maxMeasuredEarthFaultLoopImpedance?: number;
  rcdOperatingTime?: number;
  rcdTestButtonOperation?: string;
  afddTestButtonOperation?: string;
  vulnerableToDamage?: boolean;

  rcdBs?: RcdBs;
  rcdType?: RcdType;
  rcdRating?: Rating;
  comments?: string;
};
