import React from 'react';

import Button from '@payaca/components/button/Button';

type Props = {
  uploadCsvWarning: string[] | null;
  onConfirmContinueUpload: () => void;
};
const BulkCsvWarnings = ({
  onConfirmContinueUpload,
  uploadCsvWarning,
}: Props) => {
  if (!uploadCsvWarning) {
    return null;
  }

  return (
    <div>
      There was some warnings from your CSV file, please acknowledge the
      following before confirming to continue the upload.
      <ul className="warnings-wrapper">
        {uploadCsvWarning.map(
          (bulkUploadWarning: any, bulkUploadWarningIndex: number) => {
            return (
              <li key={bulkUploadWarningIndex}>
                <span>
                  Error on row {bulkUploadWarning.rowNumber} with{' '}
                  <strong>{bulkUploadWarning.columnHeader}</strong>:{' '}
                </span>
                {bulkUploadWarning.warnings.map(
                  (warning: string, bulkUploadWarningIndex: number) => {
                    return <span key={bulkUploadWarningIndex}>{warning}</span>;
                  }
                )}
              </li>
            );
          }
        )}
      </ul>
      <Button onClick={onConfirmContinueUpload}>
        I understand, continue upload
      </Button>
    </div>
  );
};

export default BulkCsvWarnings;
