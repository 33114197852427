import StatusBadge, { StatusBadgeState } from '../statusBadge/StatusBadge';
import { DefaultPipelineStages } from '@payaca/types/pipelineTypes';
import React, { FC, useMemo } from 'react';

interface Props {
  pipelineStage: string;
}

export const PipelineStageBadge: FC<Props> = ({ pipelineStage }: Props) => {
  const pipelineStageBadge = useMemo(() => {
    let badgeText = '';
    let state = StatusBadgeState.BLUE_NEUTRAL;

    switch (pipelineStage) {
      case DefaultPipelineStages.INVOICED:
        badgeText = 'Invoiced';
        state = StatusBadgeState.AMBER_PENDING;
        break;
      case DefaultPipelineStages.PROPOSAL_SENT:
        state = StatusBadgeState.BLUE_NEUTRAL;
        badgeText = 'Proposal sent';
        break;
      case DefaultPipelineStages.PROPOSAL_ACCEPTED:
        badgeText = 'Proposal accepted';
        state = StatusBadgeState.AMBER_PENDING;
        break;
      case DefaultPipelineStages.COMPLETE:
        badgeText = 'Complete';
        state = StatusBadgeState.GREEN_SUCCESS;
        break;
      case DefaultPipelineStages.EXPIRED_DECLINED:
        state = StatusBadgeState.RED_FAILURE;
        badgeText = 'Expired / Declined';
        break;
      case DefaultPipelineStages.NEW_LEAD:
        state = StatusBadgeState.GREY_INITIAL;
        badgeText = 'New lead';
        break;
      default:
        badgeText = pipelineStage;
        break;
    }

    return <StatusBadge state={state}>{badgeText}</StatusBadge>;
  }, [pipelineStage]);

  return pipelineStageBadge || null;
};
