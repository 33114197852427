import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import taskKeys from './keyFactory';

const GetTask = graphql(`
  query Task($taskId: ID!) {
    task(id: $taskId) {
      __typename
      ... on IEntity {
        id
      }
      createdBy {
        id
      }
      name
      completedAt
      deadline
      completionStatus
      assignee {
        id
        firstName
        lastName
        fullName
        colour
        email
        avatarUrl
      }
      project {
        id
        reference
      }
      events {
        id
        name
      }
      ... on LegacyFormTask {
        documentId
        formRevisions {
          id
        }
        formTemplate {
          id
        }
      }
      ... on FormTask {
        formRevisions {
          id
        }
        formTemplate {
          id
        }
      }
      ... on ChecklistTask {
        checklistItems {
          id
          name
        }
      }
      ... on MaterialsListTask {
        materialsListMaterials {
          quantity
          material {
            id
            name
            description
          }
        }
      }
    }
  }
`);

const useGetTask = (id?: number) => {
  const { data, ...rest } = useQuery({
    enabled: !!id,
    queryKey: taskKeys.task(id!),
    queryFn: () => {
      return gqlClient.request(GetTask, {
        taskId: `${id}`,
      });
    },
  });

  const task = data?.task;

  return { task: task, data, ...rest };
};

export default useGetTask;
