import React, { FC, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import Button from '../button/Button';
import { ButtonStyleVariant } from '../button/enums';
import './EditableElementControl.sass';

interface Props {
  initialIsEditingState?: boolean;
  value?: string;
  addText: string;
  editTemplate: JSX.Element;
  onEdit?: () => void;
  onDelete?: () => void;
  onSave?: () => void;
  customIsEditingState?: boolean; // override for internal state
  renderValue?: (value: string) => JSX.Element;
  className?: string;
  isSaveDisabled?: boolean;
  canDelete?: boolean;
}

const DELETE_PROMPT_TIMEOUT = 5000; // 5s

const EditableElementControl: FC<Props> = ({
  initialIsEditingState = false,
  value,
  editTemplate,
  onEdit,
  onSave,
  customIsEditingState,
  addText,
  renderValue,
  className,
  isSaveDisabled,
  canDelete,
  onDelete,
}) => {
  const [isEditing, setIsEditing] = useState(initialIsEditingState);
  const [isShowingDeletePrompt, setIsShowingDeletePrompt] = useState(false);
  const deBounceId = useRef<NodeJS.Timeout | undefined>(undefined);

  // Ensure the Timeout is cleared whenever isEditing state changes or the
  // component de-mounts
  useEffect(() => {
    setIsShowingDeletePrompt(false);

    return () => clearTimeout(deBounceId.current);
  }, [isEditing]);

  useEffect(() => {
    // provide override for internal state
    if (typeof customIsEditingState === 'boolean') {
      setIsEditing(customIsEditingState);
    }
  }, [customIsEditingState]);

  const handleDelete = () => {
    if (isShowingDeletePrompt) {
      clearTimeout(deBounceId.current);

      setIsShowingDeletePrompt(false);
      onDelete?.();
    } else {
      setIsShowingDeletePrompt(true);

      deBounceId.current = setTimeout(
        () => setIsShowingDeletePrompt(false),
        DELETE_PROMPT_TIMEOUT
      );
    }
  };

  return (
    <div
      className={`editable-element-control flex-container${
        isEditing ? ' editing' : ''
      }${className ? ` ${className}` : ''}`}
    >
      {isEditing ? (
        <>
          {editTemplate}
          <Button
            className={'save-button'}
            styleVariant={ButtonStyleVariant.ANCHOR}
            isDisabled={isSaveDisabled}
            onClick={() => {
              // are we controlling the isEditing state internally?
              if (typeof customIsEditingState !== 'boolean') {
                setIsEditing(false);
              }
              onSave && onSave();
            }}
          >
            Save
          </Button>
        </>
      ) : (
        <>
          {!!value && (renderValue ? renderValue(value) : <span>{value}</span>)}
          <Button
            className={`edit-button${value ? '' : ' empty-value'}`}
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => {
              // provide override for internal state
              if (typeof customIsEditingState !== 'boolean') {
                setIsEditing(true);
              }
              onEdit && onEdit();
            }}
          >
            {value ? (
              <FontAwesomeIcon icon={faPen} className="pen-icon" />
            ) : (
              addText
            )}
          </Button>

          {canDelete && (
            <Button
              className="delete-button"
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={handleDelete}
            >
              <FontAwesomeIcon icon={faTrash} className="delete-button-svg" />
              {isShowingDeletePrompt && <span> Yes, I&apos;m sure</span>}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default EditableElementControl;
