import {
  ScheduledEvent,
  ScheduledEventLocation,
  TContactToNotify,
} from '@payaca/types/scheduledEventsTypes';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { Nullish } from '@payaca/utilities/types';
import { DeclareAsyncAction } from '../types';

export interface State {
  scheduledEvents: {
    [key: number]: FetchedEntity<ScheduledEvent>;
  };

  isGettingScheduledEvents: boolean;
  isCreatingScheduledEvent: boolean;
  hasCreatedScheduledEventSuccessfully: null | boolean;
  createScheduledEventErrorMessage: null | { errors: string };

  isUpdatingScheduledEvent: boolean;
  hasUpdatedScheduledEventSuccessfully: null | boolean;
  updateScheduledEventErrorMessage: null | { errors: string };

  isArchivingScheduledEvent: boolean;
  hasArchivedScheduledEventSuccessfully: null | boolean;
  archiveScheduledEventErrorMessage: null | { errors: string };

  isGettingScheduledEventsForTask: boolean;
  isGettingScheduledEventsForDeal: boolean;
}

export enum ActionType {
  REQUEST_GET_LISTED_SCHEDULED_EVENTS = 'scheduledEvents.requestGetListedScheduledEvents',
  GET_LISTED_SCHEDULED_EVENTS_SUCCESS = 'scheduledEvents.getListedScheduledEventsSuccess',
  GET_LISTED_SCHEDULED_EVENTS_FAILURE = 'scheduledEvents.getListedScheduledEventsFailure',

  REQUEST_CREATE_SCHEDULED_EVENT = 'scheduledEvents.requestCreateScheduledEvent',
  CREATE_SCHEDULED_EVENT_SUCCESS = 'scheduledEvents.createScheduledEventSuccess',
  CREATE_SCHEDULED_EVENT_FAILURE = 'scheduledEvents.createScheduledEventFailure',

  REQUEST_UPDATE_SCHEDULED_EVENT = 'scheduledEvents.requestupdateScheduledEvent',
  UPDATE_SCHEDULED_EVENT_SUCCESS = 'scheduledEvents.updateScheduledEventSuccess',
  UPDATE_SCHEDULED_EVENT_FAILURE = 'scheduledEvents.updateScheduledEventFailure',

  REQUEST_GET_SCHEDULED_EVENT = 'scheduledEvents.requestGetScheduledEvent',
  GET_SCHEDULED_EVENT_SUCCESS = 'scheduledEvents.getScheduledEventSuccess',
  GET_SCHEDULED_EVENT_FAILURE = 'scheduledEvents.getScheduledEventFailure',

  REQUEST_ARCHIVE_SCHEDULED_EVENT = 'scheduledEvents.requestArchiveScheduledEvent',
  ARCHIVE_SCHEDULED_EVENT_SUCCESS = 'scheduledEvents.archiveScheduledEventSuccess',
  ARCHIVE_SCHEDULED_EVENT_FAILURE = 'scheduledEvents.archiveScheduledEventFailure',

  REQUEST_GET_SCHEDULED_EVENTS_FOR_DEAL = 'scheduledEvents.requestGetScheduledEventsForDeal',
  REQUEST_GET_SCHEDULED_EVENTS_FOR_TASK = 'scheduledEvents.requestGetScheduledEventsForTask',
  GET_SCHEDULED_EVENTS_FOR_TASK_SUCCESS = 'scheduledEvents.getScheduledEventsForTaskSuccess',
  GET_SCHEDULED_EVENTS_FOR_TASK_FAILURE = 'scheduledEvents.getScheduledEventsForTaskFailure',
  GET_SCHEDULED_EVENTS_FOR_DEAL_SUCCESS = 'scheduledEvents.getScheduledEventsForDealSuccess',
  GET_SCHEDULED_EVENTS_FOR_DEAL_FAILURE = 'scheduledEvents.getScheduledEventsForDealFailure',

  SEND_SCHEDULED_EVENT_CONFIRMATION_REQUEST = 'scheduledEvents.sendScheduledEventConfirmationRequest',

  CLEAR_SCHEDULED_EVENT_REQUESTS = 'scheduledEvents.clearScheduledEventRequests',
  CLEAR_SCHEDULED_EVENTS = 'scheduledEvents.clearScheduledEvents',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export type SendScheduledEventConfirmation = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.SEND_SCHEDULED_EVENT_CONFIRMATION_REQUEST;
      payload: {
        data: SendScheduledEventConfirmationData;
        callback?: () => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export interface SendScheduledEventConfirmationData {
  scheduledEventId: ScheduledEvent['id'];
  to: string[];
  emailSubject: string;
  emailBody: string;
}

export interface CreateScheduledEventRequestData {
  name: string;
  description?: string;
  beginAt: Date;
  endAt: Date;
  userAssignments?: number[];
  dealId?: number;
  tagIds?: number[];
  taskIds?: number[];
  uploadIds?: number[];
  includeCustomerInformation?: boolean;
  location?: ScheduledEventLocation;
  tasksToCreate?: {
    name?: string;
    notes?: string;
    checklistItems?: string[];
    formTemplateId?: number;
    deadlineDate?: Date;
  }[];
  customerIds?: number[];
  createdByUserId?: Nullish<number>;
  contactsToNotify?: {
    id?: TContactToNotify['id'];
    type: TContactToNotify['type'];
    name?: TContactToNotify['name'];
    emailAddress?: TContactToNotify['emailAddress'];
    telephoneNumber?: TContactToNotify['telephoneNumber'];
    description?: TContactToNotify['description'];
  }[];
}

export interface UpdateScheduledEventRequestData {
  id: number;
  name?: string;
  description?: string;
  beginAt?: Date;
  endAt?: Date;
  userAssignments?: number[];
  dealId?: number;
  tagIds?: number[];
  taskIds?: number[];
  includeCustomerInformation?: boolean;
  location?: ScheduledEventLocation;
  uploadIds?: number[];
  customerIds?: number[];
  contactsToNotify?: {
    id?: TContactToNotify['id'];
    type: TContactToNotify['type'];
    name?: TContactToNotify['name'];
    emailAddress?: TContactToNotify['emailAddress'];
    telephoneNumber?: TContactToNotify['telephoneNumber'];
    description?: TContactToNotify['description'];
  }[];
}
