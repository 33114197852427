import { takeEvery, call, put, race, delay } from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import {
  SagaConfig,
  ActionType,
  GetListedUsersRequestData,
} from './listedUsersTypes';
import {
  clearListedUsersPage,
  getListedUsersPageFailure,
  getListedUsersPageSuccess,
} from './listedUsersActions';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const listedUsersSagaCreator = ({ apiBaseurl, getAuthHeader }: SagaConfig) => {
  function* handleGetListedUsersPage(
    action: PayloadAction<
      ActionType.REQUEST_GET_LISTED_USERS_PAGE,
      {
        getListedUsersRequestData: GetListedUsersRequestData;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getListedUsersPage,
          action.payload.getListedUsersRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getListedUsersPageFailure(
            new Error('Get listed users page timed out.')
          )
        );
      } else {
        yield put(getListedUsersPageSuccess(response));
      }
    } catch (error: any) {
      yield put(getListedUsersPageFailure(error));
    }
  }

  function* handleAppLogout() {
    yield put(clearListedUsersPage());
  }

  const getListedUsersPage = async (
    getListedUsersRequestData: GetListedUsersRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/listed_users?pageNumber=${
        getListedUsersRequestData.pageNumber
      }&pageSize=${
        getListedUsersRequestData.pageSize
      }&includeDeactivatedUsers=${
        getListedUsersRequestData.includeDeactivatedUsers
      }&searchTerm=${getListedUsersRequestData.searchTerm || ''}`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-User': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getListedUsersPage failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  return function* () {
    yield takeEvery(
      ActionType.REQUEST_GET_LISTED_USERS_PAGE,
      handleGetListedUsersPage
    );
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default listedUsersSagaCreator;
