import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  UpdateMaterialsListMaterialsInput,
  UpdateMaterialsListMaterialsMutation,
} from '@/gql/graphql';

const UPDATE_MATERIALS_LIST_MATERIALS_MUTATION = graphql(`
  mutation UpdateMaterialsListMaterials(
    $input: UpdateMaterialsListMaterialsInput!
  ) {
    updateMaterialsListMaterials(input: $input) {
      id
    }
  }
`);

const useUpdateMaterialsListMaterials = (
  options?: UseMutationOptions<
    UpdateMaterialsListMaterialsMutation,
    unknown,
    UpdateMaterialsListMaterialsInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UPDATE_MATERIALS_LIST_MATERIALS_MUTATION, {
        input,
      });
    },
    ...options,
  });
};

export default useUpdateMaterialsListMaterials;
