import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import './CreateEditMaterialControl.sass';
import BasicField from '@payaca/components/basicField/BasicField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import ProgressiveDisclosure from '@payaca/components/progressiveDisclosure/ProgressiveDisclosure';
import TextareaField from '@payaca/components/textareaField/TextareaField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import {
  FieldValidationResult,
  FieldValidator,
} from '@payaca/types/fieldValidationTypes';
import {
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
  getNumericalRangeFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import {
  Material,
  MaterialCategory,
  SupplierMaterial,
} from '@payaca/types/materialTypes';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { PersistMaterialRequestData } from '@payaca/store/materials/materialsTypes';
import {
  requestGetMaterialCategories,
  requestPersistMaterial,
} from '@payaca/store/materials/materialsActions';
import SupplierMaterialControl from './SupplierMaterialControl';
import TagSelectionControl from '../tagSelectionControl/TagSelectionControl';
import UploadCollectionControl from '../uploadCollectionControl/UploadCollectionControl';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from '@/api/state';
import { ImageFileExtensions } from '@payaca/helpers/fileHelper';
import { useDefaultTaxRate } from '@payaca/store/hooks/appState';

const isRequiredFieldValidator = getIsRequiredFieldValidator();
const supplierIsRequiredFieldValidator = getIsRequiredFieldValidator({
  readableName: 'Supplier',
});
const priceIsRequiredFieldValidator = getIsRequiredFieldValidator({
  readableName: 'Cost',
});
const priceRangeFieldvalidator = getNumericalRangeFieldValidator(0);
const materialNameLengthFieldValidator = getLengthFieldValidator({ max: 500 });
const referenceLengthFieldValidator = getLengthFieldValidator({ max: 250 });
const urlLengthFieldValidator = getLengthFieldValidator({ max: 500 });

const getFieldValidators = (supplierMaterialsCount: number) => {
  const fieldValidators: { [fieldName: string]: FieldValidator[] } = {
    name: [isRequiredFieldValidator, materialNameLengthFieldValidator],
  };

  for (let i = 0; i < supplierMaterialsCount; i++) {
    fieldValidators[`supplierMaterials[${i}].supplierId`] = [
      supplierIsRequiredFieldValidator,
    ];
    fieldValidators[`supplierMaterials[${i}].url`] = [urlLengthFieldValidator];
    fieldValidators[`supplierMaterials[${i}].reference`] = [
      referenceLengthFieldValidator,
    ];
    fieldValidators[`supplierMaterials[${i}].price`] = [
      priceIsRequiredFieldValidator,
      priceRangeFieldvalidator,
    ];
  }

  return fieldValidators;
};

type Props = {
  material?: Material;
  onPersistMaterialSuccess?: (taskId: number) => void;
  enableSupplierMaterialInput?: boolean;
};
const CreateEditMaterialControl: FunctionComponent<Props> = ({
  material,
  onPersistMaterialSuccess,
  enableSupplierMaterialInput = false,
}: Props): JSX.Element => {
  const [supplierMaterialsCount, setSupplierMaterialsCount] = useState(0);
  const [showDescription, setShowDescription] = useState(
    !!material?.description
  );
  const dispatch = useDispatch();
  const defaultTaxRate = useDefaultTaxRate();

  const initialFormState = useMemo(() => {
    return {
      id: material?.id,
      name: material?.name || null,
      description: material?.description || null,
      tagIds: material?.id ? undefined : [],
      // There will only ever be a new supplierMaterial when the supplier material section is showing
      supplierMaterials: enableSupplierMaterialInput
        ? [
            {
              taxRateId: defaultTaxRate?.id,
            },
          ]
        : undefined,
      uploadIds: material?.uploadIds || [],
      categoryId: material?.category?.id,
    };
  }, [material, enableSupplierMaterialInput]);

  const fieldValidators = useMemo(() => {
    return getFieldValidators(supplierMaterialsCount);
  }, [supplierMaterialsCount]);

  const isPersistingMaterial = useSelector((state) => {
    return state.materials.isPersistingMaterial;
  });

  const persistMaterial = useCallback(
    (persistMaterialData: PersistMaterialRequestData) => {
      dispatch(
        requestPersistMaterial(persistMaterialData, onPersistMaterialSuccess)
      );
    },
    [dispatch, onPersistMaterialSuccess]
  );

  useEffect(() => {
    dispatch(requestGetMaterialCategories());
  }, []);

  const materialCategories = useSelector((state) => {
    return state.materials.materialCategories || [];
  });

  const materialCategoryOptions = useMemo(() => {
    return materialCategories.map((x: MaterialCategory) => {
      return {
        label: x.label,
        value: x.id,
      };
    });
  }, [materialCategories]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      setSupplierMaterialsCount(
        formState.supplierMaterials ? formState.supplierMaterials.length : 0
      );

      return (
        <React.Fragment>
          <div className="form-body">
            <div className="form-section">
              {!material?.id && (
                <TagSelectionControl
                  selectedTagIds={formState.tagIds || []}
                  onSelectedTagsChange={(tagIds) =>
                    onFieldChange({ tagIds: tagIds })
                  }
                />
              )}
              <div className="upload-name-description-container">
                <UploadCollectionControl
                  uploadIds={formState.uploadIds || []}
                  onUploadCollectionChange={(uploadIds: number[]) => {
                    onFieldChange({ uploadIds: uploadIds });
                  }}
                  allowMultipleUploads={false}
                  // 10mb
                  fileSizeLimitBytes={10 * 1024 * 1024}
                  acceptFileTypes={ImageFileExtensions}
                  showFileNames={false}
                  uploadTriggerIcon={faImage}
                />
                <div className="name-description-container">
                  <ValidatedFieldWrapper
                    validationResult={validationState['name']}
                    isTouched={touchedState['name'] || false}
                  >
                    <BasicField
                      isRequired={true}
                      name="name"
                      label="Material name"
                      value={formState.name}
                      onTouch={onFieldTouch}
                      onChange={onFieldChange}
                      styleVariant={InputStyleVariant.OUTSIZE}
                    />
                  </ValidatedFieldWrapper>
                  <ProgressiveDisclosure
                    title="Add description"
                    setIsRevealed={() => setShowDescription(true)}
                    isRevealed={showDescription}
                  >
                    <ValidatedFieldWrapper
                      validationResult={validationState['description']}
                      isTouched={touchedState['description'] || false}
                    >
                      <TextareaField
                        name="description"
                        label="Description"
                        value={formState.description}
                        onTouch={onFieldTouch}
                        onChange={onFieldChange}
                        styleVariant={InputStyleVariant.OUTSIZE}
                      />
                    </ValidatedFieldWrapper>
                  </ProgressiveDisclosure>
                  <DropdownField
                    value={formState.categoryId}
                    name="categoryId"
                    label={'Category'}
                    styleVariant={InputStyleVariant.OUTSIZE}
                    options={materialCategoryOptions}
                    onChange={onFieldChange}
                  />
                  {enableSupplierMaterialInput && (
                    <SupplierMaterialControl
                      supplierMaterial={formState.supplierMaterials[0]}
                      fieldNamePrefix="supplierMaterials[0]"
                      onChange={onFieldChange}
                      onTouch={onFieldTouch}
                      validationState={validationState}
                      touchedState={touchedState}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="actions-container">
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isDisabled={!isValid}
              isProcessing={isPersistingMaterial}
              onClick={() =>
                !isPersistingMaterial &&
                persistMaterial(formState as PersistMaterialRequestData)
              }
            >
              Save
            </Button>
          </div>
        </React.Fragment>
      );
    },
    [
      isPersistingMaterial,
      material,
      materialCategoryOptions,
      showDescription,
      enableSupplierMaterialInput,
    ]
  );

  return (
    <div className="create-edit-material-control">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
      />
    </div>
  );
};

export default CreateEditMaterialControl;
