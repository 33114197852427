import React, { FunctionComponent } from 'react';
import { default as MaterialUiRadioButton } from '@material-ui/core/Radio';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useFieldId } from '@payaca/hooks/useFieldId';

import './RadioButton.sass';

type Props = {
  className?: string;
  isDisabled?: boolean;
  isSelected: boolean;
  onClick: () => void;
  id?: string;
  size?: any;
  name?: string;
};

const RadioButton: FunctionComponent<Props> = ({
  className,
  isDisabled = false,
  isSelected,
  onClick,
  id,
  size = 'medium',
  name,
}: Props): JSX.Element => {
  const uniqueId = id || useFieldId(name || 'radio-button');

  return (
    <div className={'radio-button' + (className ? ' ' + className : '')}>
      <MaterialUiRadioButton
        color="default"
        checked={isSelected}
        onChange={onClick}
        inputProps={{ id: uniqueId, name }}
        disabled={isDisabled}
        checkedIcon={
          <div className="radio-wrapper">
            <FontAwesomeIcon icon={faCircle} />
          </div>
        }
        icon={<div className="radio-wrapper"></div>}
        size={size}
      />
    </div>
  );
};

export default RadioButton;
