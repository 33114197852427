import {
  takeEvery,
  call,
  put,
  race,
  delay,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import { SagaConfig, ActionType } from './fileDownloadTypes';

import { FileDownloadTypes } from '@payaca/types/fileDownloadTypes';
import {
  clearFileDownloads,
  fileDownloadFailure,
  fileDownloadSuccess,
} from './fileDownloadActions';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const fileDownloadSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: SagaConfig) => {
  function* handleGetFile(
    action: PayloadAction<
      ActionType.REQUEST_FILE_DOWNLOAD,
      {
        entityId: number;
        type: FileDownloadTypes;
        callback?: (value: string) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getFile, action.payload.entityId, action.payload.type),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'getFile timed out.';
        yield put(
          fileDownloadFailure(
            action.payload.entityId,
            action.payload.type,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(
          fileDownloadSuccess(
            action.payload.entityId,
            action.payload.type,
            response.data
          )
        );
        action.payload.callback && action.payload.callback(response.data);
      }
    } catch (error: any) {
      yield put(
        fileDownloadFailure(action.payload.entityId, action.payload.type, error)
      );
    }
  }

  const getFile = async (entityId: number, type: FileDownloadTypes) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/files/${type}/${entityId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getFile failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handleAppLogout() {
    yield put(clearFileDownloads());
  }

  return function* () {
    yield takeLatest(ActionType.REQUEST_FILE_DOWNLOAD, handleGetFile);
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default fileDownloadSagaCreator;
