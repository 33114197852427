import { ActionType, AnyAction, State } from './dispatchTypes';
import { createReducer } from '../utils';

export const initialState: State = {};

const dispatchReducer = createReducer<State, ActionType, AnyAction>(
  initialState,
  {}
);

export default dispatchReducer;
