import React, { FunctionComponent, useCallback } from 'react';

import './LinkCheckboxField.sass';
import FieldLabel from '../fieldLabel/FieldLabel';
import LinkCheckbox from '../linkCheckbox/LinkCheckbox';

type Props = {
  name: string;
  value?: boolean;
  label?: string | JSX.Element;
  description?: string | JSX.Element;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange?: (value: { [key: string]: boolean }) => void;
  onTouch?: (fieldName: string) => void;
  preventClickPropagation?: boolean;
};

const LinkCheckboxField: FunctionComponent<Props> = ({
  name,
  value = false,
  label,
  description,
  isDisabled,
  isRequired,
  onChange,
  onTouch,
  preventClickPropagation,
}: Props): JSX.Element => {
  const onCheckboxChange = useCallback(() => {
    onChange && onChange({ [name]: !value });
    onTouch && onTouch(name);
  }, [onChange, onTouch, value, name]);
  return (
    <div className="link-checkbox-field">
      <div className="link-checkbox-wrapper">
        <LinkCheckbox
          isChecked={value}
          isDisabled={isDisabled}
          onChange={onCheckboxChange}
          preventClickPropagation={preventClickPropagation}
        />
      </div>
      <FieldLabel
        label={label}
        description={description}
        isRequired={isRequired}
      />
    </div>
  );
};

export default LinkCheckboxField;
