import {
  PaymentMethodType,
  JobPayment,
  StripePaymentIntentInformation,
} from '@payaca/types/jobPaymentTypes';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { DeclareAsyncAction } from '../types';
import { Customer } from '@payaca/types/customerTypes';
import { Nullish } from '@payaca/utilities/types';
import { Invoice } from '@payaca/types/invoiceTypes';

export interface State {
  isRecordingJobPayment: boolean;
  isJobPaymentRecordedSuccessfully?: boolean;
  isConfirmingJobPayment: boolean;
  isJobPaymentConfirmedSuccessfully?: boolean;

  jobPayments: { [key: number]: FetchedEntity<JobPayment> };

  isPersistingJobPaymentIntent: boolean;
  isCancellingJobPaymentIntent: boolean;
  isPersistingJobPayment: boolean;
}

export enum ActionType {
  REQUEST_RECORD_JOB_PAYMENT = 'jobPayments.requestRecordJobPayment',
  RECORD_JOB_PAYMENT_SUCCESS = 'jobPayments.recordJobPaymentSuccess',
  RECORD_JOB_PAYMENT_FAILURE = 'jobPayments.recordJobPaymentFailure',

  REQUEST_CONFIRM_JOB_PAYMENT = 'jobPayments.requestConfirmJobPayment',
  CONFIRM_JOB_PAYMENT_SUCCESS = 'jobPayments.confirmJobPaymentSuccess',
  CONFIRM_JOB_PAYMENT_FAILURE = 'jobPayments.confirmJobPaymentFailure',

  REQUEST_GET_JOB_PAYMENTS_FOR_DEAL = 'jobPayments.requestGetJobPaymentsForDeal',
  GET_JOB_PAYMENTS_FOR_DEAL_SUCCESS = 'jobPayments.getJobPaymentsForDealSuccess',

  REQUEST_GET_JOB_PAYMENT = 'jobPayments.requestGetJobPayment',
  GET_JOB_PAYMENT_SUCCESS = 'jobPayments.getJobPaymentSuccess',
  GET_JOB_PAYMENT_FAILURE = 'jobPayments.getJobPaymentFailure',
  CLEAR_JOB_PAYMENT = 'jobPayments.clearJobPayment',
  CLEAR_JOB_PAYMENTS = 'jobPayments.clearJobPayments',

  PERSIST_JOB_PAYMENT_INTENT_REQUEST = 'jobPayments.persistJobPaymentIntentRequest',
  PERSIST_JOB_PAYMENT_INTENT_SUCCESS = 'jobPayments.persistJobPaymentIntentSuccess',
  PERSIST_JOB_PAYMENT_INTENT_FAILURE = 'jobPayments.persistJobPaymentIntentFailure',

  CANCEL_JOB_PAYMENT_INTENT_REQUEST = 'jobPayments.cancelJobPaymentIntentRequest',
  CANCEL_JOB_PAYMENT_INTENT_SUCCESS = 'jobPayments.cancelJobPaymentIntentSuccess',
  CANCEL_JOB_PAYMENT_INTENT_FAILURE = 'jobPayments.cancelJobPaymentIntentFailure',

  PERSIST_JOB_PAYMENT_REQUEST = 'jobPayments.persistJobPaymentRequest',
  PERSIST_JOB_PAYMENT_SUCCESS = 'jobPayments.persistJobPaymentSuccess',
  PERSIST_JOB_PAYMENT_FAILURE = 'jobPayments.persistJobPaymentFailure',

  PAY_INVOICES_REQUEST = 'jobPayments.payInvoices:request',
}

export type PayInvoicesRequestData = {
  customerId: Customer['id'];
  invoiceIds: Invoice['id'][];
  paymentMethodType: PaymentMethodType;
  paymentDate: Date;
  paymentNote?: Nullish<string>;
  sendReceipt?: boolean;
};

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}

export interface RecordJobPaymentRequestData {
  dealId: number;
  invoiceId?: number;
  sendReceipt: boolean;
  isDepositPayment: boolean;
  paymentMethod: PaymentMethodType;
  paymentValue: number;
  note?: string;
  paymentDate?: Date;
}

export interface ConfirmJobPaymentRequestData {
  jobPaymentId: number;
  isPaymentReceived: boolean;
  note?: string;
}

export interface PersistJobPaymentRequestData {
  dealId: number;
  paymentValue: number;
  isDepositPayment: boolean;
  invoiceId?: number;
  note?: string;
}

export type PersistJobPaymentIntent = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.PERSIST_JOB_PAYMENT_INTENT_REQUEST;
      payload: {
        paymentValue: number;
        dealId: number;
        paymentIntentId?: string;
        callback?: (
          paymentIntentInformation?: StripePaymentIntentInformation
        ) => void;
      };
    };
    success: {
      type: ActionType.PERSIST_JOB_PAYMENT_INTENT_SUCCESS;
    };
    failure: {
      type: ActionType.PERSIST_JOB_PAYMENT_INTENT_FAILURE;
    };
  }
>;

export type PayInvoices = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.PAY_INVOICES_REQUEST;
      payload: {
        requestData: PayInvoicesRequestData;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
  }
>;

export type CancelJobPaymentIntent = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CANCEL_JOB_PAYMENT_INTENT_REQUEST;
      payload: {
        dealId: number;
        paymentIntentId: string;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
    success: {
      type: ActionType.CANCEL_JOB_PAYMENT_INTENT_SUCCESS;
    };
    failure: {
      type: ActionType.CANCEL_JOB_PAYMENT_INTENT_FAILURE;
    };
  }
>;

export type PersistJobPayment = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.PERSIST_JOB_PAYMENT_REQUEST;
      payload: {
        dealId: number;
        isDepositPayment: boolean;
        paymentValue: number;
        paymentIntentId: string;
        invoiceId?: number;
        note?: string;
        callback?: () => void;
      };
    };
    success: {
      type: ActionType.PERSIST_JOB_PAYMENT_SUCCESS;
    };
    failure: {
      type: ActionType.PERSIST_JOB_PAYMENT_FAILURE;
    };
  }
>;
