import React from 'react';

import './MiniLoader.sass';

const MiniLoader = () => {
  return (
    <div className="mini-loader">
      <span className="sr-only">Loading</span>
    </div>
  );
};

export default MiniLoader;
