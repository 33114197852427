import React, { FC } from 'react';

import ConfirmDeleteModal, {
  ConfirmDeleteModalProps,
} from '../confirm-delete-modal/ConfirmDeleteModal';

const ConfirmDeleteUserModal: FC<
  Omit<ConfirmDeleteModalProps, 'entityName'>
> = (props) => (
  <ConfirmDeleteModal
    entityName={{ singular: 'User', plural: 'Users' }}
    {...props}
  />
);

export default ConfirmDeleteUserModal;
