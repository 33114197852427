import React, { FunctionComponent } from 'react';

import {
  getCustomerByDealId,
  getDeal,
  getInvoice,
  getInvoiceLinesByInvoiceId,
  getInvoicesByDealId,
  getJobLineItemsByInvoiceId,
} from '@/utils/stateAccessors';

import InvoiceDocumentComponent from '@payaca/components/invoiceDocument/InvoiceDocument';
import { Address } from '@payaca/types/locationTypes';
import { useSelector } from '@/api/state';
import { getDocumentSenderContextFromAccount } from '@payaca/helpers/documentSenderContextHelper';
import Button from '@payaca/components/plButton/Button';
import { EBtnColour } from '@payaca/components/plButton/useButtonClassName';
import { ReadableInvoiceStatus } from '@payaca/types/invoiceTypes';
import { useUploads } from '@payaca/store/hooks/appState';
import ClientDocumentLoading from '@payaca/components/clientDocument/ClientDocumentLoading';

type Props = {
  invoiceId: number;
  isPreview?: boolean;
};

const InvoiceDocument: FunctionComponent<Props> = ({
  invoiceId,
  isPreview = false,
}: Props): JSX.Element | null => {
  const invoice = useSelector((state) => {
    return getInvoice(state, invoiceId);
  });

  const attachmentUploads = useUploads(invoice?.attachmentUploadIds);

  const deal = useSelector((state) => {
    return invoice ? getDeal(state, invoice.dealId) : undefined;
  });

  const customer = useSelector((state) => {
    return invoice ? getCustomerByDealId(state, invoice.dealId) : undefined;
  });

  const invoices = useSelector((state) =>
    invoice ? getInvoicesByDealId(state, invoice.dealId) : undefined
  );

  const jobLineItems = useSelector((state) => {
    return getJobLineItemsByInvoiceId(state, invoiceId);
  });

  const invoiceLines = useSelector((state) => {
    return getInvoiceLinesByInvoiceId(state, invoiceId);
  });

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  if (!deal || !customer || !invoices?.length) return <ClientDocumentLoading />;

  return (
    <InvoiceDocumentComponent
      isPreview={isPreview}
      invoices={invoices}
      invoiceId={invoiceId}
      dealTotals={deal}
      siteAddresses={deal.siteAddresses || []}
      isStraightToInvoice={deal.isStraightToInvoice}
      customer={customer}
      jobLineItems={jobLineItems}
      invoiceLines={invoiceLines}
      documentSenderContext={getDocumentSenderContextFromAccount(account)}
      hideItemPrices={deal.hideItemPrices}
      attachments={attachmentUploads}
      paymentMethodConfig={{
        allowStripePayment: deal.allowStripePayment,
        allowBankTransferPayment: deal.allowBankTransferPayment,
        bankTransferPaymentSettings: {
          accountNumber: account.accountNumberRaw || '',
          accountName: account.accountNameRaw || '',
          sortCode: account.sortCodeRaw || '',
        },
      }}
      CTA={
        invoice?.readableStatus !== ReadableInvoiceStatus.PAID &&
        invoice?.readableStatus !== ReadableInvoiceStatus.VOID ? (
          <>
            <hr className="hr my-2" />

            <Button
              className="mt-2 w-full print:hidden"
              colour={EBtnColour.JobBrand}
              disabled
            >
              Make a payment
            </Button>
          </>
        ) : null
      }
    />
  );
};

export default InvoiceDocument;
