import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  useMaterialsList,
  useMaterialsListMaterialsForMaterialsList,
} from '@payaca/store/hooks/appState';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CreatePurchaseOrderModal from '../createPurchaseOrderModal/CreatePurchaseOrderModal';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import PurchaseOrderCard from '../purchaseOrderCard/PurchaseOrderCard';

import './CreateEditTimelogTypeControl.sass';
import BasicField from '@payaca/components/basicField/BasicField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { PublicTimelogType } from '@payaca/types/timelogs';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import {
  createTimelogType,
  updateTimelogType,
} from '@payaca/store/timelogs/timelogsActions';
import { useDispatch } from 'react-redux';

type Props = {
  timelogType?: PublicTimelogType;
  onCreateEditSuccess?: () => void;
};

const CreateEditTimelogTypeControl: FunctionComponent<Props> = ({
  timelogType,
  onCreateEditSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const initialFormState = useMemo(() => {
    return {
      type: timelogType?.type || '',
      description: timelogType?.description || '',
    };
  }, [timelogType]);
  const [isProcessing, setIsProcessing] = useState(false);

  const onFormSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      setIsProcessing(true);
      if (timelogType?.publicId) {
        dispatch(
          updateTimelogType.request({
            timelogType: {
              publicId: timelogType.publicId,
              type: formState.type,
              description: formState.description,
            },
            callback: () => {
              setIsProcessing(false);
              onCreateEditSuccess?.();
            },
          })
        );
      } else {
        dispatch(
          createTimelogType.request({
            timelogType: {
              type: formState.type,
              description: formState.description,
            },
            callback: () => {
              setIsProcessing(false);
              onCreateEditSuccess?.();
            },
          })
        );
      }
    },
    [timelogType?.publicId]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <div className="type-field">
            <ValidatedFieldWrapper
              validationResult={validationState.type}
              isTouched={touchedState.type || false}
            >
              <BasicField
                name="type"
                value={formState.type || ''}
                isRequired={true}
                onChange={onFieldChange}
                onTouch={onFieldTouch}
                label="Type"
              />
            </ValidatedFieldWrapper>
          </div>
          <div className="description-field">
            <ValidatedFieldWrapper
              validationResult={validationState.description}
              isTouched={touchedState.description || false}
            >
              <BasicField
                name="description"
                value={formState.description || ''}
                onChange={onFieldChange}
                onTouch={onFieldTouch}
                label="Description"
              />
            </ValidatedFieldWrapper>
          </div>
          <Button
            isProcessing={isProcessing}
            isDisabled={!isValid}
            onClick={() => !isProcessing && onFormSubmit(formState)}
            styleVariant={ButtonStyleVariant.ANCHOR}
          >
            Save
          </Button>
        </>
      );
    },
    [isProcessing, onFormSubmit]
  );

  return (
    <div className="create-edit-timelog-type-control">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={{
          type: [getIsRequiredFieldValidator()],
        }}
      />
    </div>
  );
};

export default CreateEditTimelogTypeControl;
