import React, { FC, useCallback, useMemo, useState } from 'react';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import Conditional from '@payaca/components/conditional/Conditional';
import Combobox from '@payaca/components/plCombobox/Combobox';
import { OptionGroup } from '@payaca/components/plSelect/Select';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import AddEditAddressContactsControl from '@/ui/components/addressControls/AddEditAddressContactsControl';
import ManuallyAddEditAddressControl from '@/ui/components/addressControls/ManualAddEditAddressControl';
import { Address as _Address, UpdateAddressContactInput } from '@/gql/graphql';

type Address = Pick<
  _Address,
  | 'id'
  | 'line1'
  | 'line2'
  | 'city'
  | 'country'
  | 'postalCode'
  | 'fullLocalAddress'
  | 'contacts'
>;

export interface IProps extends Omit<IModalProps, 'onBack' | 'title'> {
  title?: string;
  selectedAddress?: Address;
  addresses: { label: string; value: string }[];
  onAddressChange?: (value: string) => void;
  onChange?: (
    address: Omit<Address, 'contacts'> & {
      contacts: UpdateAddressContactInput[];
    }
  ) => void;
  onAddNewAddress?: () => void;
  disableAddressChange?: boolean;
  onDone?: () => void;
}

const ADD_DIFFERENT_ADDRESS = 'add-different-address';

const SelectEditAddressAndAddressContactsModal: FC<IProps> = (props) => {
  const {
    title = 'Edit Address',
    selectedAddress,
    addresses,
    onAddressChange,
    onChange,
    onAddNewAddress,
    disableAddressChange = false,
    onDone,
    ...rest
  } = props;
  const [isManualEdit, setIsManualEdit] = useState(false);

  const [comboBoxOptions, comboBoxGroups] = useMemo(() => {
    const _comboBoxOptions = addresses.map((address) => ({
      groupId: 'customerAddresses',
      ...address,
    }));
    let _comboBoxGroups: OptionGroup[] = [];

    if (onAddNewAddress) {
      _comboBoxOptions.push({
        groupId: 'add-customer-address',
        label: 'Add a new customer address',
        value: ADD_DIFFERENT_ADDRESS,
      });

      _comboBoxGroups = [
        { id: 'customerAddresses', label: 'Customer Addresses' },
        { id: 'add-customer-address', label: 'Other' },
      ];
    }

    return [_comboBoxOptions, _comboBoxGroups];
  }, [addresses, onAddNewAddress]);

  const handleUpdateContacts = useCallback(
    (updatedContacts: UpdateAddressContactInput[]) => {
      if (selectedAddress) {
        onChange?.({
          ...selectedAddress,
          contacts: updatedContacts,
        });
      }
    },
    [selectedAddress, onChange]
  );

  return (
    <Modal
      title={title}
      onBack={isManualEdit ? () => setIsManualEdit(false) : undefined}
      {...rest}
    >
      <Conditional
        condition={!isManualEdit}
        fallback={
          <ManuallyAddEditAddressControl
            isInModal
            address={selectedAddress}
            onSave={(_addressWithNotContacts) => {
              if (selectedAddress) {
                onChange?.({
                  ...selectedAddress,
                  ..._addressWithNotContacts,
                });

                setIsManualEdit(false);
              }
            }}
            onClose={() => {
              setIsManualEdit(false);
            }}
          />
        }
      >
        <Modal.Body>
          <div className="flex flex-col gap-4">
            <Combobox<string, false>
              value={selectedAddress?.id}
              optionGroups={comboBoxGroups}
              disabled={disableAddressChange}
              placeholder="Type to search"
              inputPlaceholder="Type to search"
              options={comboBoxOptions}
              customButtonContent={selectedAddress?.fullLocalAddress}
              onChange={(value) => {
                if (value === 'add-different-address') {
                  onAddNewAddress?.();
                  return;
                }

                onAddressChange?.(value);
              }}
            />

            <Conditional condition={!selectedAddress && !!onAddNewAddress}>
              <div>
                <Button
                  variant={EBtnVariant.LinkInline}
                  onClick={() => {
                    onAddNewAddress?.();
                  }}
                >
                  Add a different Address
                </Button>
              </div>
            </Conditional>

            <Conditional condition={!!selectedAddress}>
              <div>
                <Button
                  variant={EBtnVariant.LinkInline}
                  onClick={() => {
                    setIsManualEdit(true);
                  }}
                >
                  Edit Address
                </Button>
              </div>

              <AddEditAddressContactsControl
                contacts={selectedAddress?.contacts || []}
                onUpdate={handleUpdateContacts}
              />
            </Conditional>
          </div>
        </Modal.Body>

        {onDone && (
          <Modal.Footer>
            <Modal.Footer.Actions>
              <Button onClick={onDone}>Done</Button>
            </Modal.Footer.Actions>
          </Modal.Footer>
        )}
      </Conditional>
    </Modal>
  );
};

export default SelectEditAddressAndAddressContactsModal;
