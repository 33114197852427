// ======================================================================================================
// Job helper functions
// ======================================================================================================
import { get } from 'lodash-es';
import { JobStatus } from '@payaca/types/jobTypes';
import {
  isInvoice,
  isQuote,
  isSentQuoteEstimate,
  jobHasSent,
} from '@payaca/helpers/jobStatusHelper';
import { isEqual } from 'lodash-es';

import { parseCustomer } from '@/helpers/customerHelper';
import { FormValidations, getFieldErrors } from '@/helpers/formHelper';
import { removeEmptyAttributes } from '@/utils';
import { omit } from 'lodash-es';

const JobTypeSpecificText = {
  ESTIMATE: 'estimate',
  QUOTE: 'quote',
  INVOICE: 'invoice',
};

const EmailViewsType = {
  QUOTE: 'quote',
  ESTIMATE: 'estimate',
  INVOICE: 'invoice',
};

const JobType = {
  QUOTE: [
    JobStatus.NEW_QUOTE,
    JobStatus.QUOTED,
    JobStatus.NEW_ESTIMATE,
    JobStatus.ESTIMATED,
    JobStatus.ACCEPTED, // LEGACY: Not being used to track accepted state of job anymore
  ],
  INVOICE: [JobStatus.NEW_INVOICE, JobStatus.INVOICED, JobStatus.PAID],
};

const JobTypeSpecific = {
  ESTIMATE: [JobStatus.NEW_ESTIMATE, JobStatus.ESTIMATED],
  QUOTE: [JobStatus.NEW_QUOTE, JobStatus.QUOTED, JobStatus.ACCEPTED],
  INVOICE: [JobStatus.NEW_INVOICE, JobStatus.INVOICED, JobStatus.PAID],
};

const filterJobType = (jobs, type) => {
  return jobs.filter(
    (job) => !job.archivedAt && JobType[type].includes(job.status)
  );
};

const getSpecificJobType = (status) => {
  return Object.entries(JobTypeSpecific).reduce(
    (acc, curr) => (curr[1].find((s) => s === status) ? curr[0] : acc),
    ''
  );
};

const getSpecificJobTypeText = (status) => {
  return JobTypeSpecificText[getSpecificJobType(status)];
};

const getManagedAttachments = (existing, editing) => {
  // find files which require removing
  const attachmentsToRemove = existing.filter((extA) => {
    return !editing.find((newA) => {
      // omit isUploading key on comparison
      return isEqual(omit(newA, ['isUploading']), extA);
    });
  });
  // find files which require adding
  const attachmentsToAdd = editing.filter(
    (newA) => !existing.find((extA) => isEqual(extA, newA))
  );

  return {
    toAdd: attachmentsToAdd,
    toRemove: attachmentsToRemove,
  };
};

const RemindersList = [
  {
    fieldCheck: ['address', 'city', 'postcode', 'contactNumber', 'email'],
    text: 'Business details',
  },
  {
    fieldCheck: ['logoUrl'],
    text: 'Your logo',
  },
  {
    fieldCheck: ['accountNameRaw', 'accountNumberRaw', 'sortCodeRaw'],
    text: 'Payment information',
  },
  {
    fieldCheck: ['anyBusinessTerms'],
    text: 'Terms of business',
  },
];

const getUpdateReminders = (business, termsOfBusiness) => {
  // strip empty business fields
  const completedBusinessFields = removeEmptyAttributes({
    ...business,
    anyBusinessTerms:
      business.businessNotes || !!(termsOfBusiness && termsOfBusiness.length),
  });
  // get reminder text if none of fieldCheck fields are filled
  const reminders = RemindersList.reduce((acc, curr) => {
    // find field that doesnt exist
    const unexistantField = curr.fieldCheck.find(
      (f) => !get(completedBusinessFields, f)
    );
    if (unexistantField) {
      // a field doesn't exist - add reminder text
      acc.push(curr.text);
    }
    return acc;
  }, []);
  return reminders;
};

const getReadEmail = (emailViews, status) => {
  return (
    emailViews &&
    emailViews.openedAt &&
    emailViews.type &&
    // readQuote
    ((isSentQuoteEstimate(status) &&
      emailViews.type === EmailViewsType.QUOTE) ||
      // readInvoice
      (status === JobStatus.INVOICED &&
        emailViews.type === EmailViewsType.INVOICE))
  );
};

const parseJobUser = (user) => (user?.id ? user : {});
const parseJobCustomer = (customer) =>
  customer ? parseCustomer(customer) : null;

/**
 * Get alphabetically sorted items list (by name) excluding current item and and disabled items
 * @param {Array} items
 * @param {Array} disableItems
 * @param {Object} currentItem
 *
 * @return {Array} sorted items list
 */
const sortedItemsList = (lineItems, disableItems, currentItem) => {
  const currentLineItemName = currentItem.name || '';
  return lineItems
    ? lineItems
        .filter((lineItem) => {
          // filter out already selected items
          const itemAlreadyOnJob = disableItems.find(
            (disableItem) =>
              lineItem.id === (disableItem.lineItemId || disableItem.id)
          );
          return (
            lineItem.name &&
            lineItem.name
              .toLowerCase()
              .indexOf(currentLineItemName.toLowerCase()) !== -1 &&
            !itemAlreadyOnJob
          );
        })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        .map((lineItem) => {
          return { title: lineItem.name, payload: lineItem };
        })
    : [];
};

/**
 * Get base url location for edit job i.e. /quotes/:quoteId
 */
export const getEditJobBaseLocationPath = (pathname) =>
  pathname.split('/create')[0];
export const getEditChangeProposalBaseLocationPath = (pathname) =>
  pathname.split('/edit')[0];

export {
  filterJobType,
  getManagedAttachments,
  getSpecificJobTypeText,
  getUpdateReminders,
  jobHasSent,
  isInvoice,
  isQuote,
  isSentQuoteEstimate,
  JobStatus,
  JobTypeSpecificText,
  parseJobCustomer,
  parseJobUser,
  getReadEmail,
  sortedItemsList,
};
