export enum AutomationsRoles {
  VIEWER = 'roles.automationsViewer',
  DEFAULT_VIEWER = 'roles.automationsDefaultViewer',
  DEFAULT_MANAGER = 'roles.automationsDefaultManager',

  // only create/edit from automation template
  SIMPLE_EDITOR = 'roles.automationsSimpleEditor',
  SIMPLE_CREATOR = 'roles.automationsSimpleCreator',
  // can create custom automations - requires subscription feature hasCustomAutomations
  CREATOR = 'roles.automationsCreator',
  EDITOR = 'roles.automationsEditor',
}
