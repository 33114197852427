import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSupplier, useDeal } from '@payaca/store/hooks/appState';
import Select, {
  OptionGroup,
  SelectOption,
} from '@payaca/components/plSelect/Select';
import { Address, PartialAddress } from '@payaca/types/locationTypes';
import { Supplier } from '@payaca/types/supplierTypes';
import { Deal } from '@payaca/types/dealTypes';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import { AddressSelectExistingOrInputNew } from '../addressSelectExistingOrInputNew/AddressSelectExistingOrInputNew';
import { PurchaseOrder } from '@payaca/types/materialsListTypes';
import useGetPurchaseOrderDeliveryAddress from '@/api/queries/purchase-orders/useGetPurchaseOrderAddress';
import { useAccount } from '@/utils/storeHooks';
import { AddressLookupOrInputModal } from '../addressLookupOrInputModal/AddressLookupOrInputModal';
import useCreateAddress from '@/api/mutations/addresses/useCreateAddress';

export const IsolatedPurchaseOrderDeliveryAddressControl: FC<{
  projectId?: Deal['id'];
  supplierId?: Supplier['id'];
  purchaseOrderId?: PurchaseOrder['id'];
  value: {
    deliveryAddressId?: Address['id'];
  };
  onChange?: (value: { deliveryAddressId?: Address['id'] }) => void;
}> = ({ projectId, supplierId, value, onChange, purchaseOrderId }) => {
  const supplier = useSupplier(supplierId);
  const project = useDeal(projectId);

  const { data: deliveryAddress } =
    useGetPurchaseOrderDeliveryAddress(purchaseOrderId);

  const account = useAccount();

  const accountAddress = useMemo(() => {
    const accountAddress: PartialAddress = {
      line1: account?.address || null,
      city: account.city,
      postcode: account.postcode,
    };

    return accountAddress;
  }, [account]);

  const [showAddressInputModal, setShowAddressInputModal] = useState(false);

  const {
    options,
    optionGroups,
  }: {
    options: SelectOption<number | string, PartialAddress>[];
    optionGroups: OptionGroup[];
  } = useMemo(() => {
    const og: OptionGroup[] = [];
    if (supplier?.addresses?.length) {
      og.push({
        label: 'supplier',
        id: 'supplier',
      });
    }
    if (project?.siteAddresses?.length) {
      og.push({
        label: 'project',
        id: 'project',
      });
    }

    const o: SelectOption<number | string, PartialAddress>[] = [
      ...(supplier?.addresses?.map((a) => {
        return {
          value: a.address.id,
          label: getAddressAsString(a.address) || '',
          metadata: a.address,
          groupId: 'supplier',
        };
      }) || []),
      ...(project?.siteAddresses?.map((a) => {
        return {
          value: a.address.id,
          label: getAddressAsString(a.address) || '',
          metadata: a.address,
          groupId: 'project',
        };
      }) || []),
    ];

    const accountAddressString = getAddressAsString(accountAddress);

    if (accountAddressString?.length) {
      o.push({
        value: 0,
        label: accountAddressString,
        metadata: accountAddress,
      });

      og.push({
        label: 'account',
        id: 'account',
      });
    }

    if (deliveryAddress && !o.find((a) => a.value === +deliveryAddress.id)) {
      const address: PartialAddress = {
        line1: deliveryAddress.line1 || null,
        line2: deliveryAddress.line2 || null,
        city: deliveryAddress.city || null,
        postcode: deliveryAddress.postalCode || null,
        country: deliveryAddress.country || null,
      };

      o.push({
        value: +deliveryAddress.id,
        label: getAddressAsString(address) || '',
        metadata: address,
      });
    }

    o.push({
      value: 'add-new',
      label: 'Add a different address',
      groupId: '',
    });

    return {
      options: o,
      optionGroups: og,
    };
  }, [supplier, project, deliveryAddress, accountAddress]);

  const { mutateAsync: mutateCreateAddress } = useCreateAddress();

  const handleCreateAddress = useCallback(
    async (address: PartialAddress) => {
      const {
        createAddress: { id: addressId },
      } = await mutateCreateAddress({
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        postalCode: address.postcode,
        country: address.country,
      });

      onChange?.({ deliveryAddressId: +addressId });
      setShowAddressInputModal(false);
    },
    [onChange, mutateCreateAddress]
  );

  return (
    <div>
      <Select
        value={value.deliveryAddressId}
        options={options}
        optionGroups={optionGroups}
        onChange={(value) => {
          if (value === 'add-new') {
            setShowAddressInputModal(true);
          } else if (value === 0) {
            // account address
            const address = options.find((o) => o.value === 0)?.metadata;
            address && handleCreateAddress(address);
          } else {
            onChange?.({ deliveryAddressId: value as number });
          }
        }}
      />
      <AddressLookupOrInputModal
        isOpen={showAddressInputModal}
        onClose={() => setShowAddressInputModal(false)}
        onSubmit={handleCreateAddress}
        title="Add a new address"
      />
    </div>
  );
};
