import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  ArchiveCustomFieldMutation as _ArchiveCustomFieldMutation,
  ArchiveCustomFieldInput,
} from '@/gql/graphql';

const ArchiveCustomFieldMutation = graphql(`
  mutation ArchiveCustomField($input: ArchiveCustomFieldInput!) {
    archiveCustomField(input: $input) {
      role
      id
      customFields {
        archivedAt
        id
        identifier
        isRequired
        label
        type
        options
      }
    }
  }
`);

const useArchiveCustomField = (
  options?: UseMutationOptions<
    _ArchiveCustomFieldMutation,
    unknown,
    ArchiveCustomFieldInput
  >
) => {
  return useMutation({
    mutationFn: (variables) => {
      return gqlClient.request(ArchiveCustomFieldMutation, {
        input: variables,
      });
    },
    ...options,
  });
};

export default useArchiveCustomField;
