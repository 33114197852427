import React, { FC, useMemo, useRef, useState } from 'react';

import './MaterialSuppliersCondensed.sass';
import { getRegion, getUserRoles } from '@/utils/stateAccessors';
import { currencyPrice } from '@payaca/helpers/financeHelper';

import {
  BaseSupplierMaterial,
  SupplierMaterial,
} from '@payaca/types/materialTypes';
import { getPreferredSupplierMaterial } from '@payaca/helpers/supplierMaterialsHelper';
import { BaseSupplier, Supplier } from '@payaca/types/supplierTypes';
import { Grow, Paper, Popper } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useSelector } from '@/api/state';

interface Props {
  preferredSupplierId?: number;
  suppliers: BaseSupplier[];
  supplierMaterials: BaseSupplierMaterial[];
  enableSupplierLink?: boolean;
}

const MaterialSuppliersCondensed: FC<Props> = ({
  preferredSupplierId,
  suppliers,
  supplierMaterials,
  enableSupplierLink = false,
}: Props): JSX.Element | null => {
  const ref = useRef<HTMLSpanElement>(null);
  const [showPopper, setShowPopper] = useState(false);
  const region = useSelector(getRegion);

  const preferredSupplierMaterial = useMemo(() => {
    return getPreferredSupplierMaterial(supplierMaterials, preferredSupplierId);
  }, [supplierMaterials, preferredSupplierId]);

  const preferredSupplier = useMemo(() => {
    if (!preferredSupplierMaterial) return;
    return suppliers.find((s) => s.id === preferredSupplierMaterial.supplierId);
  }, [preferredSupplierMaterial?.supplierId, suppliers]);

  const additionalSuppliersCount = useMemo(() => {
    if (!suppliers?.length) return 0;
    return suppliers.length - 1;
  }, [suppliers?.length]);

  const sortedSupplierMaterials = useMemo(() => {
    return supplierMaterials.sort((a, b) => {
      return a.price - b.price;
    });
  }, [supplierMaterials]);

  const popperContent = useMemo(() => {
    if (!suppliers?.length) return;
    return (
      <div className="material-suppliers-popper-content">
        <ul>
          {sortedSupplierMaterials.map((supplierMaterial, index) => {
            const supplier = suppliers.find(
              (s) => s.id === supplierMaterial.supplierId
            );
            if (!supplier) return;
            return (
              <li
                key={`material-supplier-${index}`}
                className={
                  supplierMaterial.id === preferredSupplierMaterial?.id
                    ? 'preferred'
                    : ''
                }
              >
                <span className="supplier-name">
                  {supplier.name}{' '}
                  {supplier.id === preferredSupplierId && (
                    <FontAwesomeIcon icon={faStar} />
                  )}
                </span>

                <span className="price">
                  {currencyPrice(supplierMaterial.price, region)}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }, [
    sortedSupplierMaterials,
    suppliers,
    preferredSupplierId,
    region,
    preferredSupplierMaterial?.id,
  ]);

  return (
    <>
      <small className="material-suppliers-condensed">
        {preferredSupplier && enableSupplierLink && (
          <Link to={`/suppliers/${preferredSupplier.id}`}>
            {preferredSupplier.name}
            {preferredSupplierMaterial?.reference?.length
              ? `(${preferredSupplierMaterial.reference})`
              : ''}
          </Link>
        )}
        {preferredSupplier && !enableSupplierLink && (
          <span>
            {preferredSupplier.name}{' '}
            {preferredSupplierMaterial?.reference?.length
              ? `(${preferredSupplierMaterial.reference})`
              : ''}
          </span>
        )}
        {!!additionalSuppliersCount && (
          <span
            className="more-suppliers-indicator"
            ref={ref}
            onMouseEnter={() => setShowPopper(true)}
            onMouseLeave={() => setShowPopper(false)}
          >
            + {additionalSuppliersCount} more
          </span>
        )}
      </small>
      <Popper
        open={showPopper && !!ref.current}
        anchorEl={ref.current}
        role={undefined}
        transition
        placement={'bottom'}
        className={`material-suppliers-popper`}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <Paper>
              <div>{popperContent}</div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default MaterialSuppliersCondensed;
