import React, { FC } from 'react';
import moment from 'moment-timezone';

import CompanyLogo from '../companyLogo/CompanyLogo';
import './FormTemplateElements.sass';
import './SalvumTemplate.sass';

interface Props {
  data: any;
  account: any;
}

const SalvumAsbestosDesktopStudyTemplate: FC<Props> = ({ data, account }) => {
  if (!data || !account) return <></>;
  return (
    <div className={'form-template-output salvum'}>
      <div className={'form-template-header'}>
        <div>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <h3>Asbestos - Desktop Study</h3>
        </div>
        <div>
          <span>Doc No</span>: {data.certificate_number}
        </div>
      </div>
      <div className={'form-template-row no-page-break'}>
        <div
          className={'form-template-bubble'}
          style={{ borderColor: account?.brandColour }}
        >
          <ul>
            <li>
              <span>Client:</span> {data.customer_name}
            </li>
            <li>
              <span>Client Address:</span> {data.customer_address}
            </li>
            <li>
              <span>Client E-mail:</span> {data.customer_email}
            </li>
            <li>
              <span>Client Telephone:</span> {data.customer_telephone}
            </li>
            <li>
              <span>Main Contact:</span> {data.contact_name}
            </li>
          </ul>
        </div>
        <div
          className={'form-template-bubble'}
          style={{ borderColor: account?.brandColour }}
        >
          <ul>
            <li>
              <span>Site Address:</span> {data.site_address}
            </li>
            <li>
              <span>Work Type:</span> {data.work_type}
            </li>
            <li>
              <span>Details of Work:</span> {data.work_details}
            </li>
            <li>
              <span>Price:</span> {data.work_price}
            </li>
          </ul>
        </div>
      </div>
      <div className={'form-template-row no-page-break'}>
        <div
          className={'form-template-bubble'}
          style={{ borderColor: account?.brandColour }}
        >
          <ul>
            <li>
              <span>Scope of Works:</span> {data.scope_of_works}
            </li>
            <li>
              <span>Survey Type:</span> {data.survey_type}
            </li>
            <li>
              <span>Exclusions:</span> {data.exclusions}
            </li>
            <li>
              <span>Notes, site and safety arrangements:</span>{' '}
              {data.notes_and_safety_arrangements}
            </li>

            <li>
              <span>Number of Floors:</span> {data.num_floors}
            </li>
            <li>
              <span>Approx floor area:</span> {data.floor_area}
            </li>
            <li>
              <span>Number of Bedrooms (if residential):</span>{' '}
              {data.num_bedrooms}
            </li>
            <li>
              <span>Are there any out buildings?:</span> {data.outbuildings}
            </li>
            <li>
              <span>Age of Property (approx):</span> {data.age_of_property}
            </li>
            <li>
              <span>Building Status:</span> {data.building_status}
            </li>
            <li>
              <span>Pre Survey Information Received / Requested:</span>{' '}
              {data.pre_survey_information}
            </li>
            <li>
              <span>Required Resource:</span> {data.required_resource}
            </li>
            <li>
              <span>Site Visit Required:</span> {data.site_visit_required}
            </li>
            <li>
              <span>Terms and Conditions of Business:</span>{' '}
              {data.terms_and_conditions}
            </li>
          </ul>
        </div>{' '}
        <div
          className={'form-template-bubble'}
          style={{ borderColor: account?.brandColour }}
        >
          <ul>
            <li>
              <span>
                If temporary lighting will be required, how will this be
                provided?:
              </span>
              {data.temporary_lighting_provision}
            </li>
            <li>
              <span>
                If there are any Confined Spaces, what equipment will be
                required?:
              </span>
              {data.confined_spaces_provision}
            </li>
            <li>
              <span>
                If a Lift or Escalator Engineer will be required, who will
                provide this?:
              </span>
              {data.lift_escalator_engineer_provision}
            </li>
            <li>
              <span>
                If access equipment will be required for areas above 3m high,
                what type of equipment and how will this be provided?:
              </span>
              {data.height_access_provision}
            </li>
            <li>
              <span>
                If the the Isolation of Services will be required, what and by
                whom?:
              </span>
              {data.isolation_of_services_provision}
            </li>
            <li>
              <span>
                Are there any known hazards and risks on site? If so, what are
                they and how will they be controlled?:
              </span>
              {data.hazard_risk_provision}
            </li>
            <li>
              <span>
                If a General Builder will be required to make good after the
                survey, who will provide this?:
              </span>
              {data.general_builder_provision}
            </li>
            <li>
              <span>
                If a Decontamination Unit is required, who will provide this?:
              </span>
              {data.decontamination_unit_provision}
            </li>
            <li>
              <span>Online Chat Reference Number:</span>{' '}
              {data.online_chat_reference_num}
            </li>
            <li>
              <span>Purchase Order Number:</span> {data.purchase_order_number}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SalvumAsbestosDesktopStudyTemplate;
