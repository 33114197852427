import React, { FC } from 'react';
import Badge, { Props as BadgeProps } from './Badge';
import Conditional from '../conditional/Conditional';
import FloatingTooltip from '../plTooltip/FloatingTooltip';
import TooltipCore from '../plTooltip/TooltipCore';
import Tooltip from '../plTooltip/Tooltip';

export type EclipseBadgeProps = {
  badges: string[];
} & BadgeProps;

const EclipseBadge: FC<EclipseBadgeProps> = (props) => {
  const { badges, ...rest } = props;

  if (badges.length === 0) {
    return null;
  }

  return (
    <div className="flex gap-2.5">
      <Conditional condition={badges.length >= 1}>
        <Badge {...rest}>{badges[0]}</Badge>
      </Conditional>

      <Conditional condition={badges.length >= 2}>
        <Tooltip tooltipContent={badges.slice(1).join(', ')}>
          <Badge {...rest}>+{badges.length - 1}</Badge>
        </Tooltip>
      </Conditional>
    </div>
  );
};

export default EclipseBadge;
