import Modal, { Props as TModalProps } from '@payaca/components/modal/Modal';
import {
  ListedServicePlan,
  PublicHydratedServicePlanDiscountCode,
  PublicServicePlan,
  PublicServicePlanDiscountCode,
} from '@payaca/types/service-plans';
import * as clipboard from 'clipboard-polyfill/text';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import './CopyServicePlanPublicLinkModal.sass';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';

interface IProps extends Omit<TModalProps, 'title'> {
  servicePlanPublicId: ListedServicePlan['publicId'];
  servicePlanDiscountCodes: PublicHydratedServicePlanDiscountCode[];
}

export const useCopyServicePlanPublicLinkToClipboard = () => {
  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);

  return useCallback(
    async (
      servicePlanPublicId: PublicServicePlan['publicId'],
      discountCodeClientCode?: PublicServicePlanDiscountCode['code']
    ) => {
      let publicLink = new URL(
        `${import.meta.env.VITE_CLIENT_APP_URL}/service-plan/${servicePlanPublicId}`
      );

      if (discountCodeClientCode) {
        const urlParams = new URLSearchParams({
          discount_code: discountCodeClientCode,
        });

        publicLink = new URL(publicLink.href + '?' + urlParams.toString());
      }

      await clipboard.writeText(publicLink.href);

      showDynamicFeedbackMessage({
        lifespanMs: DynamicFeedbackLifespanMs.MEDIUM,
        title: 'Copied!',
        body: 'You can now paste your link into any e-mails/your social media accounts',
        feedbackLevel: FeedbackLevel.NEUTRAL,
        isCancellable: true,
      });
    },
    []
  );
};

const CopyServicePlanPublicLinkModal: FC<IProps> = (props) => {
  const {
    servicePlanDiscountCodes,
    className,
    isOpen,
    onClose,
    servicePlanPublicId,
    ...rest
  } = props;

  const [selectedDiscountCodeClientId, setSelectedDiscountCodeClientId] =
    useState<PublicHydratedServicePlanDiscountCode['code'] | undefined>();
  const copyServicePlanPublicLinkToClipboard =
    useCopyServicePlanPublicLinkToClipboard();

  const discountCodeOptions = useMemo(
    () =>
      servicePlanDiscountCodes.map((i) => ({
        label: i.name,
        value: i.code,
      })),
    [servicePlanDiscountCodes]
  );

  useEffect(() => {
    if (!isOpen) {
      setSelectedDiscountCodeClientId(undefined);
    }
  }, [isOpen]);

  const handleCopyLink = async () => {
    await copyServicePlanPublicLinkToClipboard(
      servicePlanPublicId,
      selectedDiscountCodeClientId
    );
    onClose?.();
  };

  return (
    <Modal
      className={
        'copy-service-plan-public-link-modal' +
        (className ? ' ' + className : '')
      }
      isOpen={isOpen}
      title="Copy service plan public link"
      onClose={onClose}
      {...rest}
    >
      {!!servicePlanDiscountCodes?.length && (
        <DropdownField
          label="Apply a discount"
          value={selectedDiscountCodeClientId || ''}
          name="discountCodeId"
          options={discountCodeOptions}
          onChange={(value) => {
            setSelectedDiscountCodeClientId(value.discountCodeId);
          }}
          emptyText="Choose a Discount"
          additionalSelectProps={{
            displayEmpty: true,
          }}
          hasBoxShadow={false}
        />
      )}

      <Button
        className="copy-service-plan-public-link-modal__btn"
        styleVariant={ButtonStyleVariant.FULLWIDTH}
        onClick={handleCopyLink}
      >
        Copy Public Link
      </Button>
    </Modal>
  );
};

export default CopyServicePlanPublicLinkModal;
