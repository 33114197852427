import React, { FC, useMemo } from 'react';
import tinycolor from 'tinycolor2';

import {
  StatusBadgeState,
  StatusBadgeConfigMap,
} from '@payaca/helpers/statusBadgeHelper';

export { StatusBadgeState };

import './StatusBadge.sass';

type Props = {
  children: string;
  state?: StatusBadgeState;
  colour?: string;
  backgroundColour?: string;
};

const StatusBadge: FC<Props> = ({
  children,
  state,
  colour = StatusBadgeConfigMap[StatusBadgeState.BLUE_NEUTRAL].colour,
  backgroundColour,
}: Props): JSX.Element => {
  const badgeStyle = useMemo(() => {
    const style: any = {};

    let bgc = backgroundColour;
    let c = colour;

    if (state) {
      const config = StatusBadgeConfigMap[state];
      c = config?.colour;
      bgc = config?.backgroundColour;
    }

    if (c && bgc) {
      (style.color = c), (style.backgroundColor = bgc);
    } else {
      const hsl = tinycolor(c).toHsl();
      style.color = tinycolor({
        h: hsl.h,
        s: hsl.s,
        l: 25,
      });
      style.backgroundColor = tinycolor({
        h: hsl.h,
        s: hsl.s,
        l: 90,
      });
    }

    return style;
  }, [colour, backgroundColour, state]);

  return (
    <span className="status-badge" style={badgeStyle}>
      {children}
    </span>
  );
};

export default StatusBadge;
