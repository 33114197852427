import React, {
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';

import { actions as appActions } from '@/api/app';

import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import LoqateAdvisoryEmailInputField from '@/ui/components/loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import Modal from '@payaca/components/modal/Modal';

import {
  getEmailFieldValidator,
  getIsRequiredFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import Button from '@payaca/components/button/Button';
import { ButtonColourVariant, ButtonStyleVariant } from '@payaca/components/button/enums';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

const initialFormState = {};

const ForgotPasswordForm: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch();

  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [resetPasswordErrorMessage, setResetPasswordErrorMessage] =
    useState<string>();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const passwordResetFeedbackModal = useMemo(() => {
    return (
      <Modal
        isOpen={showFeedbackModal}
        size="xs"
        title="Reset password"
        onClose={() => setShowFeedbackModal(false)}
        actions={
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={() => setShowFeedbackModal(false)}
          >
            Ok
          </Button>
        }
      >
        <p style={{ textAlign: 'center', whiteSpace: 'pre' }}>
          {
            'Please check your emails for instructions to reset\n your password.'
          }
        </p>
      </Modal>
    );
  }, [showFeedbackModal]);

  const onSubmit = useCallback(
    (emailAddress: string) => {
      setIsResettingPassword(true);
      setResetPasswordErrorMessage(undefined);

      dispatch(
        appActions.sendPasswordResetEmail(emailAddress, (error: any) => {
          setIsResettingPassword(false);

          if (error) {
            setResetPasswordErrorMessage(
              'Something went wrong. Please try again'
            );
          } else {
            setShowFeedbackModal(true);
          }
        })
      );
    },
    [dispatch]
  );

  const fieldValidators = useMemo(() => {
    return {
      emailAddress: [getEmailFieldValidator(), getIsRequiredFieldValidator()],
    };
  }, []);

  return (
    <div className="forgot-password-form-container">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        fieldValidators={fieldValidators}
        renderFormContents={(
          isValid: boolean,
          formState: {
            [key: string]: any;
          },
          validationState: {
            [key: string]: FieldValidationResult;
          },
          touchedState: {
            [key: string]: boolean;
          },
          onFieldChange: (value: { [key: string]: any }) => void,
          onFieldTouch: (fieldName: string) => void
        ) => {
          return (
            <>
              <ValidatedFieldWrapper
                validationResult={validationState['emailAddress']}
                isTouched={touchedState['emailAddress'] || false}
              >
                <LoqateAdvisoryEmailInputField
                  styleVariant={InputStyleVariant.OUTSIZE}
                  label={'Email'}
                  isRequired={true}
                  value={formState.emailAddress}
                  name={'emailAddress'}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  disableValidation={
                    validationState['emailAddress']
                      ? !validationState['emailAddress'].isValid
                      : false
                  }
                />
              </ValidatedFieldWrapper>
              <div className="button-wrapper">
                <Button
                  type="submit"
                  styleVariant={ButtonStyleVariant.OUTSIZE}
                  colourVariant={ButtonColourVariant.PRIMARY}
                  isDisabled={!isValid}
                  isProcessing={isResettingPassword}
                  onClick={() =>
                    !isResettingPassword && onSubmit(formState.emailAddress)
                  }
                >
                  Email me a reset link
                </Button>
              </div>
              {resetPasswordErrorMessage && (
                <ErrorMessage message={resetPasswordErrorMessage} />
              )}
            </>
          );
        }}
      />
      {passwordResetFeedbackModal}
    </div>
  );
};

export default ForgotPasswordForm;
