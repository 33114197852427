import React, { FC } from 'react';

import './TaskChip.sass';
import ChecklistItemsProgressIndicator from '../checklistItemsProgressIndicator/ChecklistItemsProgressIndicator';

import { Task } from '@payaca/types/taskTypes';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';

type Props = {
  task: Task;
  onClick?: () => void;
};
const TaskChip: FC<Props> = ({ task, onClick }: Props): JSX.Element | null => {
  return (
    <div
      className={`task-chip ${onClick ? 'clickable' : ''}`}
      onClick={onClick}
    >
      <div className="task-chip-inner flex-container flex-center">
        <div>
          <CheckboxField name="isCompleted" value={!!task.completedAt} />
        </div>
        <div>
          <strong>{task.name}</strong>
        </div>
        <div className="task-details-container flex-container flex-center">
          <div className="checklist-container">
            <ChecklistItemsProgressIndicator
              checklistItems={task.checklistItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskChip;
