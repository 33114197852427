import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { User } from '@payaca/types/userTypes';
import userKeys from './keyFactory';

const GetUser = graphql(`
  query User($userId: ID!) {
    user(id: $userId) {
      id
      fullName
      systemRole
      deactivatedAt
      colour
      avatarUrl
      phone
      email
      firstName
      lastName
      status
      isPrimaryUser
      sortOrder
      costPerHour {
        value
        currency {
          id
          code
          exponent
        }
      }
    }
  }
`);

export const useGetUser = (userId?: User['id']) => {
  return useQuery({
    enabled: !!userId,
    queryKey: userKeys.user(userId!),
    queryFn: () => {
      return gqlClient.request(GetUser, {
        userId: userId?.toString() || '',
      });
    },
  });
};
