import React, { FC, PropsWithChildren, ReactElement, useMemo } from 'react';

import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import UntitledIcon, { TIconName } from '@payaca/untitled-icons';
import ConditionalWrapper from '@payaca/components/conditionalWrapper/ConditionalWrapper';
import DateTab from '@payaca/components/dateTab/DateTab';
import CardButton from '@payaca/components/plCard/CardButton';

export interface IProps {
  iconName?: TIconName;
  title: ReactElement;
  titleSuffix?: ReactElement;
  whiteSpacePreWrap?: boolean;
  onClick?: () => void;
  date?: Date;
}

const DealActivityCard: FC<PropsWithChildren<IProps>> = (props) => {
  const {
    iconName,
    title,
    titleSuffix,
    whiteSpacePreWrap = false,
    onClick,
    date,
    children,
  } = props;

  const cardProps = useMemo(
    () => ({
      className: 'relative items-stretch',
      sizeVariant: CardSizeVariant.SM,
      onClick,
    }),
    [onClick]
  );

  return (
    <ConditionalWrapper
      condition={!!onClick}
      Wrapper={CardButton}
      wrapperProps={cardProps}
      FallbackWrapper={Card}
      fallbackWrapperProps={cardProps}
    >
      <Card.Body className="prose flex items-start gap-4 md:gap-5">
        {date && <DateTab date={date} />}
        {iconName && (
          <div className="flex items-center justify-center rounded-full bg-cyan-500 bg-opacity-10 p-2">
            <UntitledIcon name={iconName} className="h-5 w-5 text-blue-500" />
          </div>
        )}
        <div
          className={`markdown-font-size-reset-sm supporting-body flex-auto${
            whiteSpacePreWrap ? ' whitespace-pre-wrap' : ''
          }`}
        >
          <div className="flex w-full flex-col-reverse gap-1.5 bg-transparent p-0 pt-1.5 md:flex-row md:gap-2">
            <div className="text-left">{title}</div>

            <div className="ml-auto">{titleSuffix}</div>
          </div>

          {children}
        </div>
      </Card.Body>
    </ConditionalWrapper>
  );
};

export default DealActivityCard;
