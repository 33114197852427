import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';

import './ServicePlanPeriodPage.sass';
import {
  useServicePlan,
  useServicePlanPeriod,
} from '@payaca/store/hooks/appState';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import moment from 'moment-timezone';
import ServicePlanPeriodCommitment from '@/ui/components/servicePlanPeriodCommitment/ServicePlanPeriodCommitment';
import ScheduledEventReadDrawer from '@/ui/components/scheduledEventReadDrawer/ScheduledEventReadDrawer';
import TaskReadCompleteDrawer from '@/ui/components/taskReadCompleteDrawer/TaskReadCompleteDrawer';
import {
  getServicePlanPeriod,
  updateServicePlanPeriod,
} from '@payaca/store/servicePlans/servicePlansActions';
import { useAccount } from '@/utils/storeHooks';
import { ServicePlanPeriodStatusBadge } from '@/ui/components/servicePlanPeriodStatusBadge/ServicePlanPeriodStatusBadge';
import QuickActionsElement from '@payaca/components/quickActionsTableCell/QuickActionsElement';

type Props = {
  servicePlanPeriodId: string;
};

const statusOrder = {
  fulfilled: 2,
  arranged: 1,
  unarranged: 0,
};

const ServicePlanPeriodPage: FC<Props> = ({
  servicePlanPeriodId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isMarkingAsFulfilled, setIsMarkingAsFulfilled] = useState(false);

  const [newTaskId, setNewTaskId] = useState<number>();
  const [newEventId, setNewEventId] = useState<number>();

  const servicePlanPeriod = useServicePlanPeriod(servicePlanPeriodId);
  const servicePlan = useServicePlan(servicePlanPeriod?.servicePlanPublicId);

  useEffect(() => {
    dispatch(
      servicePlansActions.getServicePlanPeriod.request({
        publicId: servicePlanPeriodId,
      })
    );
  }, [servicePlanPeriodId]);

  useEffect(() => {
    if (servicePlanPeriod) {
      dispatch(
        servicePlansActions.getServicePlan.request({
          publicId: servicePlanPeriod.servicePlanPublicId,
        })
      );
    }
  }, [servicePlanPeriodId]);

  const account = useAccount();

  const timestampShortDateRegionalFormat =
    getInternationalMomentDateFormatByRegion(DateFormats.MID, account.region);

  const handleMarkAsFulfilled = useCallback(() => {
    setIsMarkingAsFulfilled(true);
    dispatch(
      updateServicePlanPeriod.request({
        servicePlanPeriod: {
          publicId: servicePlanPeriodId,
          isFulfilledOverride: true,
        },
        callback: () => {
          setIsMarkingAsFulfilled(false);
          dispatch(
            servicePlansActions.getServicePlanPeriod.request({
              publicId: servicePlanPeriodId,
            })
          );
        },
        onErrorCallback: () => setIsMarkingAsFulfilled(false),
      })
    );
  }, [getServicePlanPeriod, servicePlanPeriodId]);

  const quickActions = useMemo(() => {
    if (servicePlanPeriod && servicePlanPeriod.status !== 'fulfilled') {
      return [
        {
          actionName: 'Mark as complete',
          isActionProcessing: isMarkingAsFulfilled,
          actionBehaviour: handleMarkAsFulfilled,
        },
      ];
    }

    return [];
  }, [handleMarkAsFulfilled, isMarkingAsFulfilled, servicePlanPeriod?.status]);

  return (
    <AuthenticatedPageWrapper
      className="service-plan-period-page"
      title={
        <div className="service-plan-period-page-header">
          <h1>Service Plan Period</h1>
          {servicePlanPeriod && (
            <>
              <ServicePlanPeriodStatusBadge status={servicePlanPeriod.status} />
              <QuickActionsElement
                recordId={servicePlanPeriodId}
                renderDisabledIfNoActions={true}
                quickActions={quickActions}
              />
            </>
          )}
        </div>
      }
      previousPageNavigationConfig={{
        route: `/service-plans/service-periods`,
        navigationPromptName: 'Back',
      }}
    >
      {servicePlanPeriod && (
        <>
          <div>
            <span className="service-plan-name">{servicePlan?.name}</span>
            <h2>{servicePlanPeriod.customerName}</h2>
            <dl className="service-plan-details">
              <dt>Start</dt>
              <dd>
                {moment(servicePlanPeriod.startsAt).format(
                  timestampShortDateRegionalFormat
                )}
              </dd>
              <dt>End</dt>
              <dd>
                {moment(servicePlanPeriod.endsAt).format(
                  timestampShortDateRegionalFormat
                )}
              </dd>
            </dl>
          </div>

          <div>
            <h2>Commitments</h2>
            {servicePlanPeriod.commitments.length === 0 && (
              <em>No commitments</em>
            )}
            <div className="commitments">
              {servicePlanPeriod.commitments
                .sort((a, b) => {
                  return statusOrder[a.status] - statusOrder[b.status];
                })
                .map((commitment, i) => {
                  return (
                    <ServicePlanPeriodCommitment
                      key={`service-plan-period-commitment-${i}`}
                      commitment={commitment}
                      onCommitmentUpdate={(newEntity) => {
                        if (newEntity?.type === 'task') {
                          setNewTaskId(newEntity.id);
                        } else if (newEntity?.type === 'scheduledEvent') {
                          setNewEventId(newEntity.id);
                        }

                        dispatch(
                          getServicePlanPeriod.request({
                            publicId: servicePlanPeriodId,
                          })
                        );
                      }}
                    />
                  );
                })}
            </div>
          </div>
        </>
      )}
      <ScheduledEventReadDrawer
        scheduledEventId={newEventId}
        isOpen={!!newEventId}
        onClose={() => setNewEventId(undefined)}
      />
      <TaskReadCompleteDrawer
        taskId={newTaskId}
        isOpen={!!newTaskId}
        onClose={() => setNewTaskId(undefined)}
      />
    </AuthenticatedPageWrapper>
  );
};

export default ServicePlanPeriodPage;
