import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiFetch } from '@/utils/apiFetch';
import { ConnectionApplication as _ConnectionApplication } from '@payaca/types/connectionApplication';

import { PersistMaterialPurchaseRecordRequestData } from '@payaca/store/materialsList/materialsListTypes';

const useCreateMaterialPurchaseRecord = () => {
  const queryClient = useQueryClient();

  return useMutation<
    { id: string },
    unknown,
    PersistMaterialPurchaseRecordRequestData
  >({
    onSuccess: (data) => {},
    mutationFn: async (data) => {
      const response = await apiFetch(`/api/material_purchase_record`, {
        method: 'POST',
        body: JSON.stringify(data),
      });

      return response.json();
    },
  });
};

export default useCreateMaterialPurchaseRecord;
