import {
  call,
  delay,
  put,
  race,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import {
  ActionType,
  GetChangeProposalGeneratedPdfUrl,
  GetChangeProposalValidationResultAction,
  MarkChangeProposalAsSent,
  PersistChangeProposalRequestData,
  ProgressProposalToInvoice,
  SendChangeProposal,
} from './proposalsTypes';

import {
  clearChangeProposals,
  getChangeProposalFailure,
  getChangeProposalGeneratedPdfUrl,
  getChangeProposalsForDealFailure,
  getChangeProposalsForDealSuccess,
  getChangeProposalSuccess,
  getChangeProposalValidationResult,
  markChangeProposalAccepted,
  markChangeProposalAsSent,
  markChangeProposalDeclined,
  persistChangeProposalFailure,
  persistChangeProposalSuccess,
  progressProposalToInvoice,
  sendChangeProposal,
  sendProtoInvoice,
  setChangeProposalDiscount,
  setProposalDiscount,
  voidChangeProposal,
} from './proposalsActions';

import {
  passResponseErrorMessagesToErrorCallback,
  Req,
} from '@payaca/helpers/storeHelper';

import { ChangeProposal } from '@payaca/types/changeProposalTypes';
import { DefaultSagaConfig } from '../types';
import { handleAsyncAction } from '../utils';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';
import { Invoice } from '@payaca/types/invoiceTypes';

const changeProposalsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp,
}: DefaultSagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  function* handleGetChangeProposal(
    action: PayloadAction<
      ActionType.GET_CHANGE_PROPOSAL_REQUEST,
      {
        changeProposalId: number;
        callback: (changeProposal: ChangeProposal) => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getChangeProposal, action.payload.changeProposalId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        const errorMessage = 'Get changeProposal timed out.';
        yield put(
          getChangeProposalFailure(
            action.payload.changeProposalId,
            new Error(errorMessage)
          )
        );
      } else {
        yield put(
          getChangeProposalSuccess(action.payload.changeProposalId, response)
        );
        action.payload.callback && action.payload.callback(response);
      }
    } catch (error: any) {
      yield put(
        getChangeProposalFailure(action.payload.changeProposalId, error)
      );
    }
  }

  function* handleGetChangeProposalsForDeal(
    action: PayloadAction<
      ActionType.GET_CHANGE_PROPOSALS_FOR_DEAL_REQUEST,
      {
        dealId: number;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(getChangeProposalsForDeal, action.payload.dealId),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        throw new Error('getChangeProposalsForDeal request timed out');
      }

      yield put(getChangeProposalsForDealSuccess(response));
    } catch (error: any) {
      yield put(getChangeProposalsForDealFailure(error));
    }
  }

  const getChangeProposalsForDeal = async (dealId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/change_proposals/deal/${dealId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-Deal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getChangeProposalsForDeal failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  const getChangeProposal = async (changeProposalId: number) => {
    const authHeader = await getAuthHeader();

    return fetch(`${apiBaseurl}/api/change_proposals/${changeProposalId}`, {
      method: 'GET',
      headers: {
        Authorization: authHeader,
        'Content-Type': 'application/json',
        'X-Simple-ChangeProposal': 'true',
      },
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getChangeProposal failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  function* handlePersistChangeProposal(
    action: PayloadAction<
      ActionType.PERSIST_CHANGE_PROPOSAL_REQUEST,
      {
        persistChangeProposalRequestData: PersistChangeProposalRequestData;
        callback?: (changeProposalId: number) => void;
        onErrorCallback?: () => void;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          persistChangeProposal,
          action.payload.persistChangeProposalRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (!timeout) {
        yield put(persistChangeProposalSuccess(response));
        action.payload.callback?.(response);
      } else {
        yield put(
          persistChangeProposalFailure(
            new Error('Timeout'),
            action.payload.persistChangeProposalRequestData.id
          )
        );
        action.payload.onErrorCallback?.();
      }
    } catch (error: any) {
      yield put(
        persistChangeProposalFailure(
          error,
          action.payload.persistChangeProposalRequestData.id
        )
      );
      action.payload.onErrorCallback?.();
    }
  }

  const persistChangeProposal = async (
    persistChangeProposalRequestData: PersistChangeProposalRequestData
  ) => {
    const response = await req.post(
      `/change_proposals`,
      persistChangeProposalRequestData
    );
    return await response.json();
  };

  const handleGetChangeProposalValidationResult =
    handleAsyncAction<GetChangeProposalValidationResultAction>(
      getChangeProposalValidationResult,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/change_proposals/${payload.changeProposalId}/validation_result`,
          {
            method: 'GET',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
              'X-Native-App': `${isNativeApp}`,
            },
          }
        );
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to get change proposal validation result');
        }
      },
      (response, payload) => {
        payload.payload?.callback?.(
          response as {
            isValid: boolean;
            errors: string[];
          }
        );
      },
      (error, payload) => {
        payload.payload?.onErrorCallback?.(error);
      }
    );

  const handleGetChangeProposalGeneratedPdfUrl =
    handleAsyncAction<GetChangeProposalGeneratedPdfUrl>(
      getChangeProposalGeneratedPdfUrl,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/change_proposals/${payload.changeProposalId}/generated_pdf_url`,
          {
            method: 'GET',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
              'X-Native-App': `${isNativeApp}`,
            },
          }
        );
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to get change proposal generated pdf url');
        }
      },
      (response, payload) => {
        payload.payload?.callback?.(response as string);
      },
      (error, payload) => {
        payload.payload?.onErrorCallback?.(error);
      }
    );

  const handleSendChangeProposal = handleAsyncAction<SendChangeProposal>(
    sendChangeProposal,
    async (payload) => {
      const authHeader = await getAuthHeader();
      const response = await fetch(
        `${apiBaseurl}/api/change_proposals/${payload.changeProposalId}/send`,
        {
          method: 'POST',
          headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json',
            'X-Simple-Job': 'true',
            'X-Native-App': `${isNativeApp}`,
          },
          body: JSON.stringify({
            isResend: payload.isResend,
            changeProposalId: payload.changeProposalId,
            sendMeACopy: payload.sendMeACopy,
            emailCopy: payload.emailCopy,
          }),
        }
      );
      if (response.ok) {
        return;
      } else {
        passResponseErrorMessagesToErrorCallback(
          response,
          payload.onErrorCallback
        );
        throw new Error('Failed to send change proposal');
      }
    },
    (response, payload) => {
      payload.payload?.callback?.();
    }
  );

  const handleMarkChangeProposalAsSent =
    handleAsyncAction<MarkChangeProposalAsSent>(
      markChangeProposalAsSent,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/change_proposals/${payload.changeProposalId}/mark_as_sent`,
          {
            method: 'POST',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
              'X-Native-App': `${isNativeApp}`,
            },
            body: JSON.stringify({
              changeProposalId: payload.changeProposalId,
            }),
          }
        );
        if (response.ok) {
          return;
        } else {
          passResponseErrorMessagesToErrorCallback(
            response,
            payload.onErrorCallback
          );
          throw new Error('Failed to mark change proposal as sent');
        }
      },
      (response, payload) => {
        payload.payload?.callback?.();
      }
    );

  const handleProgressProposalToInvoice =
    handleAsyncAction<ProgressProposalToInvoice>(
      progressProposalToInvoice,
      async (payload) => {
        const authHeader = await getAuthHeader();
        const response = await fetch(
          `${apiBaseurl}/api/proposals/${payload.proposalId}/progress_to_invoice`,
          {
            method: 'POST',
            headers: {
              Authorization: authHeader,
              'Content-Type': 'application/json',
              'X-Simple-Job': 'true',
              'X-Native-App': `${isNativeApp}`,
            },
          }
        );
        if (response.ok) {
          return response.json();
        } else {
          passResponseErrorMessagesToErrorCallback(
            response,
            payload.onErrorCallback
          );
          throw new Error('Failed to progress proposal to invoice');
        }
      },
      (response, payload) => {
        const { invoiceId } = response as { invoiceId: number };
        payload.payload?.callback?.(invoiceId);
      },
      (error, payload) => null
    );

  const handleSetProposalDiscount = handleAsyncAction(
    setProposalDiscount,
    async (args) => {
      try {
        const response = await req.patch(
          `/proposals/${args.proposalId}/discount`,
          {
            discountPercentage: args.discountPercentage,
            discountDescription: args.discountDescription,
          }
        );
        return response;
      } catch (err) {
        console.log(`Set proposal discount failed: ${JSON.stringify(err)}`);
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  const handleSendProtoInvoice = handleAsyncAction(
    sendProtoInvoice,
    async (args) => {
      try {
        const response = await req.post(
          `/proposals/proto_invoice/${args.protoInvoiceId}/send`,
          args.data
        );

        return response.json();
      } catch (err) {
        throw new Error(JSON.stringify((err as any).message));
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as Invoice['id']);
    },
    (_response, requestData) => {
      const message = JSON.parse(_response.message);
      requestData.payload.onErrorCallback?.(
        message?.errorMessages || ['Something went wrong']
      );
    }
  );

  const handleSetChangeProposalDiscount = handleAsyncAction(
    setChangeProposalDiscount,
    async (args) => {
      try {
        const response = await req.patch(
          `/change_proposals/${args.changeProposalId}/discount`,
          {
            discountPercentage: args.discountPercentage,
            discountDescription: args.discountDescription,
          }
        );
        return response;
      } catch (err) {
        console.log(
          `Set change proposal discount failed: ${JSON.stringify(err)}`
        );
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    },
    (_response, requestData) => {
      requestData.payload.onErrorCallback?.();
    }
  );

  function* handleAppLogout() {
    yield put(clearChangeProposals());
  }

  return function* () {
    yield takeEvery(
      ActionType.GET_CHANGE_PROPOSAL_REQUEST,
      handleGetChangeProposal
    );

    yield takeLatest(
      ActionType.GET_CHANGE_PROPOSALS_FOR_DEAL_REQUEST,
      handleGetChangeProposalsForDeal
    );

    yield takeLatest(
      ActionType.PERSIST_CHANGE_PROPOSAL_REQUEST,
      handlePersistChangeProposal
    );

    yield takeLatest(
      ActionType.MARK_CHANGE_PROPOSAL_ACCEPTED_REQUEST,
      handleAsyncAction(
        markChangeProposalAccepted,
        async (payload) => {
          const authHeader = await getAuthHeader();
          const response = await fetch(
            `${apiBaseurl}/api/change_proposals/${payload.changeProposalId}/mark_accepted`,
            {
              method: 'POST',
              headers: {
                Authorization: authHeader,
                'Content-Type': 'application/json',
                'X-Simple-Job': 'true',
                'X-Native-App': `${isNativeApp}`,
              },
            }
          );
          if (response.ok) {
            return;
          } else {
            throw new Error('Failed to mark change proposal as accepted');
          }
        },
        (_, requestData) => {
          requestData.payload.callback?.();
        },
        (err, requestData) => {
          requestData.payload.onErrorCallback?.(err);
        }
      )
    );

    yield takeLatest(
      ActionType.MARK_CHANGE_PROPOSAL_DECLINED_REQUEST,
      handleAsyncAction(
        markChangeProposalDeclined,
        async (payload) => {
          const authHeader = await getAuthHeader();
          const response = await fetch(
            `${apiBaseurl}/api/change_proposals/${payload.changeProposalId}/mark_declined`,
            {
              method: 'POST',
              headers: {
                Authorization: authHeader,
                'Content-Type': 'application/json',
                'X-Simple-Job': 'true',
                'X-Native-App': `${isNativeApp}`,
              },
            }
          );
          if (response.ok) {
            return;
          } else {
            throw new Error('Failed to mark change proposal as declined');
          }
        },
        (_, requestData) => {
          requestData.payload.callback?.();
        },
        (err, requestData) => {
          requestData.payload.onErrorCallback?.(err);
        }
      )
    );

    yield takeLatest(
      ActionType.VOID_CHANGE_PROPOSAL_REQUEST,
      handleAsyncAction(
        voidChangeProposal,
        async (payload) => {
          const authHeader = await getAuthHeader();
          const response = await fetch(
            `${apiBaseurl}/api/change_proposals/${payload.changeProposalId}/void`,
            {
              method: 'POST',
              headers: {
                Authorization: authHeader,
                'Content-Type': 'application/json',
                'X-Simple-Job': 'true',
                'X-Native-App': `${isNativeApp}`,
              },
            }
          );
          if (response.ok) {
            return;
          } else {
            throw new Error('Failed to void change proposal');
          }
        },
        (_, requestData) => {
          requestData.payload.callback?.();
        },
        (err, requestData) => {
          requestData.payload.onErrorCallback?.(err);
        }
      )
    );

    yield takeLatest(
      ActionType.GET_CHANGE_PROPOSAL_VALIDATION_RESULT_REQUEST,
      handleGetChangeProposalValidationResult
    );
    yield takeLatest(
      ActionType.GET_CHANGE_PROPOSAL_GENERATED_PDF_URL,
      handleGetChangeProposalGeneratedPdfUrl
    );
    yield takeLatest(
      ActionType.SEND_CHANGE_PROPOSAL_REQUEST,
      handleSendChangeProposal
    );
    yield takeLatest(
      ActionType.MARK_CHANGE_PROPOSAL_AS_SENT_REQUEST,
      handleMarkChangeProposalAsSent
    );
    yield takeLatest(
      ActionType.PROGRESS_PROPOSAL_TO_INVOICE_REQUEST,
      handleProgressProposalToInvoice
    );
    yield takeLatest(
      ActionType.SET_PROPOSAL_DISCOUNT_REQUEST,
      handleSetProposalDiscount
    );
    yield takeLatest(
      ActionType.SET_CHANGE_PROPOSAL_DISCOUNT_REQUEST,
      handleSetChangeProposalDiscount
    );
    yield takeLatest(
      ActionType.SEND_PROTO_INVOICE_REQUEST,
      handleSendProtoInvoice
    );

    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default changeProposalsSagaCreator;
