import { createAsyncAction } from '../utils';

import {
  ActionType,
  ArchiveTimelog,
  ArchiveTimelogType,
  BatchCreateTimelogs,
  ClearActiveTimelog,
  BatchCreateQueuedTimelogs,
  CreateTimelog,
  CreateTimelogType,
  DequeueTimelogsToCreate,
  EnqueueTimelogToCreate,
  GetAssigneeGroupedListedTimelogs,
  GetListedTimelogsPage,
  GetTimelogTypes,
  InitiateActiveTimelog,
  UpdateActiveTimelog,
  UpdateTimelog,
  UpdateTimelogType,
  ClearTimelogsToCreate,
} from './timelogsTypes';

export const createTimelog = createAsyncAction<CreateTimelog>({
  request: ActionType.CREATE_TIMELOG_REQUEST,
  success: ActionType.CREATE_TIMELOG_SUCCESS,
})((payload: CreateTimelog['request']['payload']) => ({
  payload,
}));

export const updateTimelog = createAsyncAction<UpdateTimelog>({
  request: ActionType.UPDATE_TIMELOG_REQUEST,
})((payload: UpdateTimelog['request']['payload']) => ({
  payload,
}));

export const archiveTimelog = createAsyncAction<ArchiveTimelog>({
  request: ActionType.ARCHIVE_TIMELOG_REQUEST,
})((payload: ArchiveTimelog['request']['payload']) => {
  return {
    payload,
  };
});

export const getTimelogTypes = createAsyncAction<GetTimelogTypes>({
  request: ActionType.GET_TIMELOG_TYPES_REQUEST,
})((payload: GetTimelogTypes['request']['payload']) => {
  return {
    payload,
  };
});

export const getListedTimelogsPage = createAsyncAction<GetListedTimelogsPage>({
  request: ActionType.GET_LISTED_TIMELOGS_PAGE_REQUEST,
  success: ActionType.GET_LISTED_TIMELOGS_PAGE_SUCCESS,
})((payload: GetListedTimelogsPage['request']['payload']) => {
  return {
    payload,
  };
});

export const getAssigneeGroupedListedTimelogs =
  createAsyncAction<GetAssigneeGroupedListedTimelogs>({
    request: ActionType.GET_ASSIGNEE_GROUPED_LISTED_TIMELOGS_REQUEST,
  })((payload: GetAssigneeGroupedListedTimelogs['request']['payload']) => {
    return {
      payload,
    };
  });

export const createTimelogType = createAsyncAction<CreateTimelogType>({
  request: ActionType.CREATE_TIMELOG_TYPE_REQUEST,
})((payload: CreateTimelogType['request']['payload']) => {
  return {
    payload,
  };
});

export const updateTimelogType = createAsyncAction<UpdateTimelogType>({
  request: ActionType.UPDATE_TIMELOG_TYPE_REQUEST,
})((payload: UpdateTimelogType['request']['payload']) => {
  return {
    payload,
  };
});

export const archiveTimelogType = createAsyncAction<ArchiveTimelogType>({
  request: ActionType.ARCHIVE_TIMELOG_TYPE_REQUEST,
})((payload: ArchiveTimelogType['request']['payload']) => {
  return {
    payload,
  };
});

export const initiateActiveTimelog = createAsyncAction<InitiateActiveTimelog>({
  request: ActionType.INITIATE_ACTIVE_TIMELOG,
})((payload: InitiateActiveTimelog['request']['payload']) => {
  return {
    payload,
  };
});

export const clearActiveTimelog = createAsyncAction<ClearActiveTimelog>({
  request: ActionType.CLEAR_ACTIVE_TIMELOG,
})();

export const clearTimelogsToCreate = createAsyncAction<ClearTimelogsToCreate>({
  request: ActionType.CLEAR_TIMELOGS_TO_CREATE,
})();

export const updateActiveTimelog = createAsyncAction<UpdateActiveTimelog>({
  request: ActionType.UPDATE_ACTIVE_TIMELOG,
})((payload: UpdateActiveTimelog['request']['payload']) => {
  return {
    payload,
  };
});

export const enqueueTimelogToCreate = createAsyncAction<EnqueueTimelogToCreate>(
  {
    request: ActionType.ENQUEUE_TIMELOG_TO_CREATE,
  }
)((payload: EnqueueTimelogToCreate['request']['payload']) => {
  return {
    payload,
  };
});

export const dequeueTimelogsToCreate =
  createAsyncAction<DequeueTimelogsToCreate>({
    request: ActionType.DEQUEUE_TIMELOGS_TO_CREATE,
  })((payload: DequeueTimelogsToCreate['request']['payload']) => {
    return {
      payload,
    };
  });

export const batchCreateQueuedTimelogs =
  createAsyncAction<BatchCreateQueuedTimelogs>({
    request: ActionType.BATCH_CREATE_QUEUED_TIMELOGS_REQUEST,
  })((payload: BatchCreateQueuedTimelogs['request']['payload']) => ({
    payload,
  }));

export const batchCreateTimelogs = createAsyncAction<BatchCreateTimelogs>({
  request: ActionType.BATCH_CREATE_TIMELOGS_REQUEST,
  success: ActionType.BATCH_CREATE_TIMELOGS_SUCCESS,
  failure: ActionType.BATCH_CREATE_TIMELOGS_FAILURE,
})((payload: BatchCreateTimelogs['request']['payload']) => ({
  payload,
}));
