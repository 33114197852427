import React, { FC } from 'react';

type Props = {
  className?: string;
};
const BrokenLinkIcon: FC<Props> = ({ className }: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.98 20.94"
      className={`broken-link-icon ${className ? className : ''}`}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M11.12,14.75H11a1.13,1.13,0,0,0-.52.29L9,16.45c-.64.65-1.29,1.3-1.95,1.94a3.2,3.2,0,1,1-4.43-4.62l3.28-3.28a.85.85,0,0,0,.28-.61A.8.8,0,0,0,6,9.32a.78.78,0,0,0-.55-.23.88.88,0,0,0-.61.27l-.39.39-3,3a4.19,4.19,0,0,0-1,1.6c-.09.29-.16.59-.23.89a5.13,5.13,0,0,0,0,1.59,2.69,2.69,0,0,0,.18.73,4.74,4.74,0,0,0,4.05,3.28,8.73,8.73,0,0,0,1,0,4.83,4.83,0,0,0,3-1.48l2.21-2.22,1-1,.15-.16a.79.79,0,0,0,0-.87A.72.72,0,0,0,11.12,14.75Z" />
          <path d="M19.61,8.14a4,4,0,0,0,1-1.59,8.75,8.75,0,0,0,.23-.88,6.85,6.85,0,0,0,.05-1.5c0-.17-.06-.34-.1-.5a4.81,4.81,0,0,0-8-2.25C12,2.19,11.2,3,10.42,3.76L9.29,4.89A.8.8,0,0,0,9.33,6a.77.77,0,0,0,.57.25A.83.83,0,0,0,10.42,6l.17-.16,1-1c.75-.75,1.5-1.5,2.26-2.24a3.24,3.24,0,0,1,2.28-.95A3.2,3.2,0,0,1,18.77,3a3.16,3.16,0,0,1-.39,4.08c-.7.71-1.4,1.41-2.11,2.11L15,10.46a.8.8,0,0,0,0,1.16.79.79,0,0,0,.56.23.84.84,0,0,0,.6-.26Z" />
          <path d="M.75,7.18H3.33a.75.75,0,0,0,0-1.5H.75a.75.75,0,0,0,0,1.5Z" />
          <path d="M3.71,4.77a.75.75,0,0,0,1.06,0,.75.75,0,0,0,0-1.06L3,1.89A.75.75,0,0,0,1.89,3Z" />
          <path d="M6.44,4.08a.75.75,0,0,0,.75-.75V.75a.75.75,0,0,0-1.5,0V3.33A.76.76,0,0,0,6.44,4.08Z" />
          <path d="M20.23,13.76H17.65a.75.75,0,0,0,0,1.5h2.58a.75.75,0,0,0,0-1.5Z" />
          <path d="M17.27,16.17a.75.75,0,0,0-1.06,0,.74.74,0,0,0,0,1.06L18,19.06a.77.77,0,0,0,.53.22.79.79,0,0,0,.53-.22.75.75,0,0,0,0-1.06Z" />
          <path d="M14.53,16.87a.75.75,0,0,0-.75.75v2.57a.75.75,0,0,0,1.5,0V17.62A.74.74,0,0,0,14.53,16.87Z" />
        </g>
      </g>
    </svg>
  );
};

export default BrokenLinkIcon;
