import { Reducer } from 'redux';

import { ActionType, State } from './listedTasksTypes';

export const initialState: State = {
  isGettingListedTasksPage: false,
  listedTasksPage: null,
};

const listedTasksReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_LISTED_TASKS_PAGE:
      return { ...state, isGettingListedTasksPage: true };
    case ActionType.GET_LISTED_TASKS_PAGE_FAILURE:
      return {
        ...state,
        listedTasksPage: null,
        isGettingListedTasksPage: false,
      };
    case ActionType.GET_LISTED_TASKS_PAGE_SUCCESS:
      return {
        ...state,
        listedTasksPage: action.payload.listedTasksPage,
        isGettingListedTasksPage: false,
      };
    case ActionType.CLEAR_LISTED_TASKS_PAGE:
      return {
        ...state,
        listedTasksPage: null,
        isGettingListedTasksPage: false,
      };
    default:
      return state;
  }
};

export default listedTasksReducer;
