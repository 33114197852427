import {
  AddOnProductPrice,
  BillingScheme,
  PublicAddOnProductPrice,
} from '@payaca/types/add-on-products';

export const isPerUnitPrice = (
  price: PublicAddOnProductPrice<BillingScheme>
): price is PublicAddOnProductPrice<'per_unit'> => {
  return price.billingScheme === 'per_unit';
};

export const isGraduatedPrice = (
  price: PublicAddOnProductPrice<BillingScheme>
): price is PublicAddOnProductPrice<'graduated'> => {
  return price.billingScheme === 'graduated';
};

export const isVolumePrice = (
  price: PublicAddOnProductPrice<BillingScheme>
): price is PublicAddOnProductPrice<'volume'> => {
  return price.billingScheme === 'volume';
};

export const getCostsForPriceAndQuantity = (
  price: PublicAddOnProductPrice<BillingScheme>,
  quantity: number
) => {
  let priceExcludingTax: number;
  if (isPerUnitPrice(price)) {
    priceExcludingTax = price.unitAmount * quantity;
  } else if (isGraduatedPrice(price)) {
    const sortedTiers = price.tiers.sort((a, b) => a.flatAmount - b.flatAmount);
    const tier = sortedTiers.find(
      (tier) =>
        (tier.minQty === null || tier.minQty <= quantity) &&
        (tier.maxQty === null || tier.maxQty >= quantity)
    );
    if (!tier) {
      throw new Error('failed to find tier');
    }
    priceExcludingTax = tier.flatAmount;
  } else if (isVolumePrice(price)) {
    const sortedTiers = price.tiers.sort((a, b) => a.unitAmount - b.unitAmount);
    const tier = sortedTiers.find(
      (tier) =>
        (tier.minQty === null || tier.minQty <= quantity) &&
        (tier.maxQty === null || tier.maxQty >= quantity)
    );
    if (!tier) {
      throw new Error('failed to find tier');
    }
    priceExcludingTax = tier.unitAmount * quantity;
  } else {
    priceExcludingTax = price.basicPrice;
  }
  const taxAmount = price.taxPercentage
    ? priceExcludingTax * (price.taxPercentage / 100)
    : 0;

  return {
    priceExcludingTax,
    priceIncludingTax: priceExcludingTax + taxAmount,
    taxAmount,
  };
};

export const isPriceTierActive = (
  tier: AddOnProductPrice<'graduated' | 'volume'>['tiers'][number],
  quantity: number
) => {
  return (
    (tier.minQty === null || tier.minQty <= quantity) &&
    (tier.maxQty === null || tier.maxQty >= quantity)
  );
};
