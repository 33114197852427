import { createReducer, mergeFetchedEntities } from '../utils';

import { ActionType, AnyAction, State } from './templatesTypes';

export const initialState: State = {};

const templatesReducer = createReducer<State, ActionType, AnyAction>(
  initialState,
  {}
);

export default templatesReducer;
