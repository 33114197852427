import React, { FC } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import ModalCore, { IModalCoreProps } from '../plModal/ModalCore';
import MiniLoader from '../miniLoader/MiniLoader';
import FileDropIcon from './FileDropIcon.svg?react';
import Alert, { EAlertColour } from '../plAlert/Alert';

export interface IProps extends IModalCoreProps {
  isLoading?: boolean;
  hasError?: boolean;
  dropzoneOptions?: DropzoneOptions;
}

const FileDropZoneModal: FC<IProps> = (props) => {
  const {
    isLoading = false,
    hasError = false,
    className,
    dropzoneOptions,
    ...rest
  } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: dropzoneOptions?.disabled || isLoading,
    ...dropzoneOptions,
  });

  return (
    <ModalCore
      className={'m-auto' + (className ? ' ' + className : '')}
      {...rest}
    >
      <div
        className={
          'relative m-auto cursor-pointer rounded-xl border border-dashed p-10 shadow-sm' +
          (isDragActive
            ? ' border-blue-600 bg-blue-50'
            : ' border-transparent bg-white')
        }
        {...getRootProps()}
      >
        <div
          className={
            'prose flex flex-col items-center text-center' +
            (isLoading ? ' invisible' : '')
          }
        >
          <input {...getInputProps()} />

          {hasError && (
            <Alert className="mb-4" colour={EAlertColour.SOFT_RED}>
              An error occurred - please try again later
            </Alert>
          )}
          <FileDropIcon className="mb-4" />
          <h3 className="mb-1">
            Drop your file{dropzoneOptions?.maxFiles === 1 ? '' : 's'} here or{' '}
            <b className="font-semibold text-cyan-800 underline underline-offset-2">
              browse
            </b>
          </h3>
          <p>Maximum size: 50MB</p>
        </div>

        {isLoading ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <MiniLoader />
          </div>
        ) : null}
      </div>
    </ModalCore>
  );
};

export default FileDropZoneModal;
