import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import { ArchiveTagMutation, ArchiveTagMutationVariables } from '@/gql/graphql';

const ArchiveTag = graphql(`
  mutation ArchiveTag($input: ArchiveTagInput!) {
    archiveTag(input: $input) {
      id
    }
  }
`);

const useArchiveTag = (
  options?: UseMutationOptions<
    ArchiveTagMutation,
    unknown,
    ArchiveTagMutationVariables['input']
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(ArchiveTag, {
        input,
      });
    },
    ...options,
  });
};

export default useArchiveTag;
