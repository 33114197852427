import { LineItem } from '@payaca/types/lineItemTypes';

export interface LineItemsState {
  currentLineItem: any;

  // isGettingLineItems: boolean;
  // lineItems: LineItem[] | null;
  // gettingLineItemsError: string | null;

  isGettingLineItem: boolean;
  gettingLineItemError: string | null;

  isDeletingLineItems: boolean;
  deletingLineItemsError: string | null;

  isCreatingUpdatingLineItemAttachments: boolean;
  creatingUpdatingLineItemAttachmentsError: string | null;
  isLineItemAttachmentsSuccessfullyCreatedUpdated: boolean | null;
}

export enum ActionType {
  CLEAR_LINE_ITEMS = 'lineItems.clearLineItems',
  CLEAR_CURRENT_LINE_ITEM = 'lineItems.clearCurrentLineItem',
  STORE_CURRENT_LINE_ITEM = 'lineItems.storeCurrentLineItem',
  UPDATE_CURRENT_LINE_ITEM = 'lineItems.updateCurrentLineItem',
  INITIALISE_NEW_LINE_ITEM = 'lineItems.initialiseNewLineItem',

  REQUEST_GET_LINE_ITEM = 'lineItems.requestGetLineItem',
  GET_LINE_ITEM_SUCCESS = 'lineItems.getLineItemSuccess',
  GET_LINE_ITEM_FAILURE = 'lineItems.getLineItemFailure',

  REQUEST_DELETE_LINE_ITEMS = 'lineItems.requestDeleteLineItems',
  DELETE_LINE_ITEMS_SUCCESS = 'lineItems.deleteLineItemsSuccess',
  DELETE_LINE_ITEMS_FAILURE = 'lineItems.deleteLineItemsFailure',

  REQUEST_CREATE_UPDATE_LINE_ITEM_ATTACHMENTS = 'lineItems.requestCreateUpdateLineItemAttachments',
  CREATE_UPDATE_LINE_ITEM_ATTACHMENTS_SUCCESS = 'lineItems.createUpdateLineItemAttachmentsSuccess',
  CREATE_UPDATE_LINE_ITEM_ATTACHMENTS_FAILURE = 'lineItems.createUpdateLineItemAttachmentsFailure',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}
