import { MaterialCategory } from '@payaca/types/materialTypes';
import {
  LineItemMaterial,
  Material,
  SupplierMaterial,
} from '@payaca/types/materialTypes';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { FilterOption } from '@payaca/types/filterTypes';
export interface State {
  isPersistingMaterial: boolean;
  isPersistingSupplierMaterial: boolean;
  isDeletingSupplierMaterial: boolean;
  isPersistingLineItemMaterial: boolean;
  isDeletingLineItemMaterial: boolean;
  isFetchingMaterialCategories: boolean;
  isFetchingMaterialSuppliers: boolean;

  materialCategories: MaterialCategory[];
  materialSuppliers: FilterOption[];
  materials: { [key: number]: FetchedEntity<Material> };
  supplierMaterials: { [key: number]: FetchedEntity<SupplierMaterial> };
  lineItemMaterials: { [key: number]: FetchedEntity<LineItemMaterial> };

  isArchivingMaterials: boolean;
  areMaterialsArchivedSuccessfully?: boolean;
}

export enum ActionType {
  REQUEST_PERSIST_MATERIAL = 'materials.requestPersistMaterial',
  PERSIST_MATERIAL_SUCCESS = 'materials.persistMaterialSuccess',
  PERSIST_MATERIAL_FAILURE = 'materials.persistMaterialFailure',

  REQUEST_PERSIST_SUPPLIERMATERIAL = 'materials.requestPersistSupplierMaterial',
  PERSIST_SUPPLIERMATERIAL_SUCCESS = 'materials.persistSupplierMaterialSuccess',
  PERSIST_SUPPLIERMATERIAL_FAILURE = 'materials.persistSupplierMaterialFailure',

  REQUEST_PERSIST_LINEITEMMATERIAL = 'materials.requestPersistLineItemMaterial',
  PERSIST_LINEITEMMATERIAL_SUCCESS = 'materials.persistLineItemMaterialSuccess',
  PERSIST_LINEITEMMATERIAL_FAILURE = 'materials.persistLineItemMaterialFailure',

  REQUEST_PERSIST_LINEITEMMATERIALS = 'materials.requestPersistLineItemMaterials',
  PERSIST_LINEITEMMATERIALS_SUCCESS = 'materials.persistLineItemMaterialsSuccess',
  PERSIST_LINEITEMMATERIALS_FAILURE = 'materials.persistLineItemMaterialsFailure',

  REQUEST_DELETE_SUPPLIERMATERIAL = 'materials.requestDeleteSupplierMaterial',
  DELETE_SUPPLIERMATERIAL_SUCCESS = 'materials.deleteSupplierMaterialSuccess',
  DELETE_SUPPLIERMATERIAL_FAILURE = 'materials.deleteSupplierMaterialFailure',

  REQUEST_DELETE_LINEITEMMATERIAL = 'materials.requestDeleteLineItemMaterial',
  DELETE_LINEITEMMATERIAL_SUCCESS = 'materials.deleteLineItemMaterialSuccess',
  DELETE_LINEITEMMATERIAL_FAILURE = 'materials.deleteLineItemMaterialFailure',

  REQUEST_ARCHIVE_MATERIALS = 'materials.requestArchiveMaterials',
  ARCHIVE_MATERIALS_SUCCESS = 'materials.archiveMaterialsSuccess',
  ARCHIVE_MATERIALS_FAILURE = 'materials.archiveMaterialsFailure',
  CLEAR_ARCHIVE_MATERIALS = 'materials.clearArchiveMaterials',

  REQUEST_GET_MATERIAL = 'materials.requestGetMaterial',
  GET_MATERIAL_SUCCESS = 'materials.getMaterialSuccess',
  GET_MATERIAL_FAILURE = 'materials.getMaterialFailure',

  REQUEST_GET_MATERIALCATEGORIES = 'materials.requestGetMaterialCategories',
  GET_MATERIALCATEGORIES_SUCCESS = 'materials.getMaterialCategoriesSuccess',
  GET_MATERIALCATEGORIES_FAILURE = 'materials.getMaterialCategoriesFailure',

  REQUEST_GET_MATERIALSUPPLIERS = 'materials.requestGetMaterialSuppliers',
  GET_MATERIALSUPPLIERS_SUCCESS = 'materials.getMaterialSuppliersSuccess',
  GET_MATERIALSUPPLIERS_FAILURE = 'materials.getMaterialSuppliersFailure',

  REQUEST_GET_SUPPLIERMATERIALS_FOR_MATERIAL = 'materials.requestGetSupplierMaterialsForMaterial',
  GET_SUPPLIERMATERIALS_FOR_MATERIAL_SUCCESS = 'materials.getSupplierMaterialsForMaterialSuccess',

  REQUEST_GET_SUPPLIERMATERIAL = 'materials.requestGetSupplierMaterial',
  GET_SUPPLIERMATERIAL_SUCCESS = 'materials.getSupplierMaterialSuccess',
  GET_SUPPLIERMATERIAL_FAILURE = 'materials.getSupplierMaterialFailure',

  REQUEST_GET_LINEITEMMATERIAL = 'materials.requestGetLineItemMaterial',
  GET_LINEITEMMATERIAL_SUCCESS = 'materials.getLineItemMaterialSuccess',
  GET_LINEITEMMATERIAL_FAILURE = 'materials.getLineItemMaterialFailure',

  CLEAR_MATERIALS = 'materials.clearMaterials',
  CLEAR_MATERIALCATEGORIES = 'materials.clearMaterialCategories',
  CLEAR_MATERIALSUPPLIERS = 'materials.clearMaterialSuppliers',
  CLEAR_SUPPLIERMATERIALS = 'materials.clearSupplierMaterials',
  CLEAR_LINEITEMMATERIALS = 'materials.clearLineItemMaterials',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export interface PersistMaterialRequestData {
  id?: number;
  name?: string;
  description?: string | null;
  tagIds?: number[];
  supplierMaterials?: {
    id?: number;
    supplierId: number;
    price: number;
    reference?: string;
    url?: string;
    taxPercentage: number | null;
    isTaxIncluded: boolean;
    taxRateId: number;
  }[];
  uploadIds?: number[];
  preferredSupplierId?: number | null;
  categoryId?: number;
}

export interface PersistSupplierMaterialRequestData {
  id?: number;
  supplierId?: number;
  materialId?: number;
  price?: number;
  reference?: string | null;
  url?: string | null;
  taxPercentage?: number | null;
  isTaxIncluded?: boolean;
  taxRateId?: number;
}

export interface PersistLineItemMaterialRequestData {
  id?: number;
  lineItemId?: number;
  materialId?: number;
  materialQuantity?: number;
}
