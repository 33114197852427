import React, { FunctionComponent } from 'react';
import Modal from '@payaca/components/modal/Modal';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmDeleteScheduledEvent: () => void;
};

const ConfirmDeleteScheduledEventModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  onConfirmDeleteScheduledEvent,
}: Props): JSX.Element | null => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Event"
      size="xs"
      actions={
        <>
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={onConfirmDeleteScheduledEvent}
          >
            Delete
          </Button>
          <Button styleVariant={ButtonStyleVariant.ANCHOR} onClick={onClose}>
            Cancel
          </Button>
        </>
      }
    >
      <p style={{ textAlign: 'center' }}>
        Are you sure you want to delete this Event?
      </p>
    </Modal>
  );
};

export default ConfirmDeleteScheduledEventModal;
