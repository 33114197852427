import { Nullable, Nullish } from '@payaca/utilities/types';

import { AccountRegions } from './accountTypes';

export interface AvailableForm {
  label: string;
  value: number;
  regions: AccountRegions[];
  category: FormCategoryType;
  type: 'pdf' | 'legacy';
  archivedAt?: Nullable<Date>;
}

export enum AccountSpecificFormOptions {
  USERS = 'users',
}

export enum FormCategoryType {
  HEATING = 'Heating',
  GENERAL = 'General',
  ELECTRICAL = 'Electrical',
}

export const FormOptionGroups = [
  { name: FormCategoryType.GENERAL, id: 1 },
  { name: FormCategoryType.HEATING, id: 2 },
  { name: FormCategoryType.ELECTRICAL, id: 3 },
];
