export enum ActionType {
  CLEAR_JOBS = 'jobs.clearJobsData',
  CLEAR_JOB = 'jobs.clearJobData',
  UPDATE_JOB = 'jobs.updateJob',
  CREATE_JOB = 'jobs.createJob',
  SELECT_JOB = 'jobs.selectJob',
  GET_JOB = 'jobs.getJob',
  GET_JOB_PAYMENTS = 'jobs.getJobPayments',
  STORE_JOB_LINE_ITEM_GROUPS = 'jobs.storeJobLineItemGroups',
  EDIT_CURRENT_JOB = 'jobs.editCurrentJob',
  SEND_QUOTE_TO_CUSTOMER = 'jobs.sendQuoteToCustomer',
  ARCHIVE_JOB = 'jobs.archiveJob',
  ARCHIVE_JOBS = 'jobs.archiveJobs',
  UNARCHIVE_JOB = 'jobs.unarchiveJob',
  UNARCHIVE_JOBS = 'jobs.unarchiveJobs',
  ACCEPT_QUOTE = 'jobs.acceptQuote',
  UNACCEPT_QUOTE = 'jobs.unacceptQuote',
  DECLINE_JOB = 'jobs.declineJob',
  CONVERT_JOB_TO_INVOICE = 'jobs.convertJobToInvoice',
  DELETE_JOB = 'jobs.deleteJob',
  DELETE_JOBS = 'jobs.deleteJobs',
  SEND_INVOICE_TO_CUSTOMER = 'jobs.sendInvoiceToCustomer',
  STORE_SEND_INVOICE_TO_CUSTOMER_ERROR = 'jobs.storeSendInvoiceToCustomerError',
  DUPLICATE_JOB = 'jobs.duplicateJob',
  STORE_CURRENT_LINE_ITEM = 'jobs.storeCurrentLineItem',
  UPDATE_CURRENT_LINE_ITEM = 'jobs.updateCurrentLineItem',
  CLEAR_CURRENT_LINE_ITEM = 'jobs.clearCurrentLineItem',
  ADD_LINE_ITEM_TO_NEW_JOB = 'jobs.addLineItemToNewJob',
  ADD_LINE_ITEM_TO_PENDING_JOB = 'jobs.addLineItemToPendingJob',
  UPDATE_ITEM_GROUP = 'jobs.updateItemGroup',
  REMOVE_ITEM_GROUP = 'jobs.removeItemGroup',
  UPDATE_ITEM = 'jobs.updateItem',
  ADD_ITEM = 'jobs.addItem',
  REMOVE_ITEM = 'jobs.removeItem',
  ADD_ATTACHMENT_TO_JOB = 'jobs.addAttachmentToJob',
  REMOVE_ATTACHMENT_FROM_JOB = 'jobs.removeAttachmentFromJob',
  ADD_ATTACHMENT_TO_CURRENT_JOB = 'jobs.addAttachmentToCurrentJob',
  REMOVE_ATTACHMENT_FROM_CURRENT_JOB = 'jobs.removeAttachmentFromCurrentJob',
}
