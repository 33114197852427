import React, { FC, useMemo } from 'react';

export interface IProps {
  address?: {
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    postcode?: string | null;
  };
}

const Address: FC<IProps> = (props) => {
  const { address } = props;

  const addressElements = useMemo(() => {
    if (!address) return [];

    const addressEl = [];
    addressEl.push(address.line1);
    addressEl.push(address.line2);
    addressEl.push(address.city);
    addressEl.push(address.postcode);

    return addressEl.filter(Boolean);
  }, [address]);

  return (
    <>
      {addressElements.filter(Boolean).map((el, index) => (
        <React.Fragment key={index}>
          <span className="whitespace-nowrap">{el}</span>
          {index < addressElements.length - 1 && ', '}
        </React.Fragment>
      ))}
    </>
  );
};

export default Address;
