import BasicField from '@payaca/components/basicField/BasicField';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import DateField from '@payaca/components/dateField/DateField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import RadioGroupField from '@payaca/components/radioGroupField/RadioGroupField';
import TextareaField from '@payaca/components/textareaField/TextareaField';
import FormattedNumberField from '@payaca/components/formattedNumberField/FormattedNumberField';
import React from 'react';
import { FormElement } from '@payaca/types/formElementTypes';
import FormSignatureCaptureControl from '@payaca/components/formSignatureCaptureControl/FormSignatureCaptureControl';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import LoqateAdvisoryPhoneInputField from '@/ui/components/loqateAdvisoryPhoneInputField/LoqateAdvisoryPhoneInputField';
import { NumberFormatVariant } from '@payaca/components/formattedNumberField/FormattedNumberField';
import { selectOptionsPresets } from '@payaca/constants/form-fields';

const generateLabel = (title = '', isOptional: boolean) => (
  <div className={'field-label'}>
    {title}
    {!isOptional && <span className={'required-indicator'}>*</span>}
  </div>
);

export const getFormElement = (
  {
    id,
    type,
    title,
    description,
    icon,
    validation = [],
    options = [],
    optionsPreset,
    children = [],
    isOptional = false,
    item_type,
    item_category,
    populate_date_field,
    placeholder,
  }: FormElement,
  value: any,
  onChange: any,
  onTouch: any,
  uiHandlers: any,
  name = id
) => {
  switch (type) {
    case 'text':
    case 'number':
      return (
        <BasicField
          name={name}
          type={type}
          label={title}
          description={description}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onTouch={onTouch}
          isRequired={!isOptional}
          styleVariant={InputStyleVariant.OUTSIZE}
        />
      );
    case 'gc-number': // TODO: change to "formatted-number" type
      return (
        <FormattedNumberField
          name={name}
          label={title}
          value={value}
          styleVariant={InputStyleVariant.OUTSIZE}
          onChange={onChange}
          formatVariant={NumberFormatVariant.GC_NUMBER}
        />
      );
    case 'time':
      return (
        <DateField
          name={name}
          label={title}
          value={value}
          onChange={onChange}
          onTouch={onTouch}
          isRequired={!isOptional}
          styleVariant={InputStyleVariant.OUTSIZE}
          additionalPickerProps={{
            shouldDisableDate: () => true,
            shouldDisableYear: () => true,
            openTo: 'hours',
            format: 'HH:mm',
            views: ['hours', 'minutes'],
            ampm: false,
          }}
        />
      );
    case 'date':
      return (
        <DateField
          name={name}
          label={title}
          value={value}
          description={description}
          onChange={onChange}
          onTouch={onTouch}
          isRequired={!isOptional}
          styleVariant={InputStyleVariant.OUTSIZE}
        />
      );
    case 'phone':
      return (
        <LoqateAdvisoryPhoneInputField
          name={name}
          label={title}
          value={value}
          onChange={onChange}
          onTouch={onTouch}
          isRequired={!isOptional}
          styleVariant={InputStyleVariant.OUTSIZE}
          inputType="basic"
          disableValidation={true}
        />
      );
    case 'radio':
      return (
        <RadioGroupField
          name={name}
          label={title}
          options={options}
          value={value}
          isRequired={!isOptional}
          onChange={(value: any) => onChange(value)}
          onTouch={onTouch}
        />
      );
    case 'select':
      return (
        <DropdownField
          name={name}
          label={title}
          value={value}
          options={[
            { label: 'Please select...', value: undefined },
            ...((optionsPreset && selectOptionsPresets[optionsPreset]) || []),
            ...options,
          ]}
          onChange={(value: any) => onChange(value)}
          isRequired={!isOptional}
          styleVariant={InputStyleVariant.OUTSIZE}
        />
      );
    case 'multi-select': {
      const valueIsArray = Array.isArray(value);

      return (
        <DropdownField
          multiple={true}
          name={name}
          label={title}
          value={value ? (valueIsArray ? value : [value]) : []}
          options={[
            { label: 'Please select...', value: undefined },
            ...((optionsPreset && selectOptionsPresets[optionsPreset]) || []),
            ...options,
          ]}
          onChange={(value: any) => onChange(value)}
          isRequired={!isOptional}
        />
      );
    }
    case 'textarea':
      return (
        <TextareaField
          name={name}
          value={value}
          label={title}
          isRequired={!isOptional}
          onChange={(value: any) => onChange(value)}
          styleVariant={InputStyleVariant.OUTSIZE}
        />
      );
    case 'checkbox':
      return (
        <CheckboxField
          name={name}
          value={value}
          label={title}
          description={description}
          onChange={(value: any) => onChange(value)}
          isRequired={!isOptional}
        />
      );
    case 'signature':
      return (
        <div className={'form-signature-container'}>
          <h3>{generateLabel(title, isOptional)}</h3>
          <FormSignatureCaptureControl
            label={description || 'This will be added to the final document:'}
            currentSignature={value}
            onResponseCaptureComplete={(value: any) => {
              const newValue = { [name || id]: value };
              if (populate_date_field) {
                newValue[populate_date_field] = new Date();
              }
              onChange(newValue);
            }}
          />
        </div>
      );
    default:
      return <></>;
  }
};
