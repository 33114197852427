import { Reducer } from 'redux';

import { ActionType, State } from './customerTypes';

export const initialState: State = {
  isGettingCustomer: false,
  isPersistingCustomer: false,
  isCustomerPersistedSuccessfully: undefined,
  currentCustomer: undefined,
  listedCustomersPage: null,
  isGettingListedCustomersPage: false,
  isArchivingCustomers: false,
  areCustomersArchivedSuccessfully: undefined,

  customers: {},
};

const customerReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.CLEAR_CUSTOMERS:
      return {
        ...state,
        customers: {},
      };
    case ActionType.REQUEST_PERSIST_CUSTOMER:
      return {
        ...state,
        isPersistingCustomer: true,
        isCustomerPersistedSuccessfully: undefined,
      };
    case ActionType.PERSIST_CUSTOMER_SUCCESS:
      return {
        ...state,
        isPersistingCustomer: false,
        isCustomerPersistedSuccessfully: true,
      };
    case ActionType.PERSIST_CUSTOMER_FAILURE:
      return {
        ...state,
        isPersistingCustomer: false,
        isCustomerPersistedSuccessfully: false,
      };
    case ActionType.REQUEST_GET_CUSTOMER: {
      const fetchedEntity =
        state.customers && state.customers[action.payload.customerId];

      return {
        ...state,
        isGettingCustomer: true,
        customers: {
          ...state.customers,
          [action.payload.customerId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_CUSTOMER_SUCCESS: {
      const fetchedEntity =
        state.customers && state.customers[action.payload.customer.id];

      return {
        ...state,
        isGettingCustomer: false,
        customers: {
          ...state.customers,
          [action.payload.customer.id]: {
            ...fetchedEntity,
            entity: action.payload.customer,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.GET_CUSTOMER_FAILURE: {
      const fetchedEntity =
        state.customers && state.customers[action.payload.customerId];

      return {
        ...state,
        isGettingCustomer: false,
        customers: {
          ...state.customers,
          [action.payload.customerId]: {
            ...fetchedEntity,
            fetchFailedAtAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }

    case ActionType.SET_CURRENT_CUSTOMER:
      return {
        ...state,
        currentCustomer: action.payload.customer,
      };
    case ActionType.CLEAR_CURRENT_CUSTOMER:
      return {
        ...state,
        currentCustomer: undefined,
      };

    case ActionType.REQUEST_GET_LISTED_CUSTOMERS_PAGE:
      return { ...state, isGettingListedCustomersPage: true };
    case ActionType.GET_LISTED_CUSTOMERS_PAGE_FAILURE:
      return {
        ...state,
        listedCustomersPage: null,
        isGettingListedCustomersPage: false,
      };
    case ActionType.GET_LISTED_CUSTOMERS_PAGE_SUCCESS:
      return {
        ...state,
        listedCustomersPage: action.payload.listedCustomersPage,
        isGettingListedCustomersPage: false,
      };
    case ActionType.CLEAR_LISTED_CUSTOMERS_PAGE:
      return { ...state, listedCustomersPage: null };

    case ActionType.REQUEST_ARCHIVE_CUSTOMERS:
      return {
        ...state,
        isArchivingCustomers: true,
        areCustomersArchivedSuccessfully: undefined,
      };
    case ActionType.ARCHIVE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isArchivingCustomers: false,
        areCustomersArchivedSuccessfully: true,
      };
    case ActionType.ARCHIVE_CUSTOMERS_FAILURE:
      return {
        ...state,
        isArchivingCustomers: false,
        areCustomersArchivedSuccessfully: false,
      };
    case ActionType.PATCH_CUSTOMER_REQUEST: {
      const fetchedEntity =
        state.customers && state.customers[action.payload.customerId];

      return {
        ...state,
        isGettingCustomer: true,
        customers: {
          ...state.customers,
          [action.payload.customerId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.PATCH_CUSTOMER_SUCCESS: {
      const fetchedEntity =
        state.customers && state.customers[action.payload.id];

      return {
        ...state,
        isGettingCustomer: false,
        customers: {
          ...state.customers,
          [action.payload.id]: {
            ...fetchedEntity,
            entity: action.payload,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.PATCH_CUSTOMER_FAILURE: {
      const fetchedEntity =
        state.customers && state.customers[action.payload.customerId];

      return {
        ...state,
        isGettingCustomer: false,
        customers: {
          ...state.customers,
          [action.payload.customerId]: {
            ...fetchedEntity,
            fetchFailedAtAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default customerReducer;
