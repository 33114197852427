import moment from 'moment-timezone';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getTasksByDealId, getUserRoles } from '@/utils/stateAccessors';

import AssignedUsersIndicator from '@payaca/components/assignedUsersIndicator/AssignedUsersIndicator';
import QuickActionsElement from '@payaca/components/quickActionsTableCell/QuickActionsElement';
import ConfirmDeleteScheduledEventModal from '../confirmDeleteScheduledEventModal/ConfirmDeleteScheduledEventModal';
import ScheduledEventLinkedTasksModal from '../scheduledEventLinkedTasksModal/ScheduledEventLinkedTasksModal';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';

import { roundToDecimalPlaces } from '@payaca/helpers/numberHelper';

import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';

import { useScheduledEvent } from '@payaca/store/hooks/appState';
import * as scheduledEventActions from '@payaca/store/scheduledEvents/scheduledEventsActions';
import { requestGetTasksForScheduledEvent } from '@payaca/store/tasks/tasksActions';

import { getTimeDuration } from '@payaca/utilities/timeUtilities';

import './ScheduledEventControl.sass';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { useSelector } from '@/api/state';
import DateTab from '@payaca/components/dateTab/DateTab';
import { EditScheduledEventModal } from '../editScheduledEventModal/EditScheduledEventModal';
import ConditionalWrapper from '@payaca/components/conditionalWrapper/ConditionalWrapper';
import CardLink from '@payaca/components/plCard/CardLink';
import { useRouteMatch } from 'react-router-dom';

type Props = {
  scheduledEventId: number;
  hideQuickActions?: boolean;
  onPersistScheduledEventSuccess?: () => void;
  onDeleteScheduledEventSuccess?: () => void;
  hideLinkedTasks?: boolean;
  hideAssignedUsers?: boolean;
  disableShowReadDrawer?: boolean;
};
const ScheduledEventControl: FC<Props> = ({
  scheduledEventId,
  hideQuickActions = true,
  onPersistScheduledEventSuccess,
  onDeleteScheduledEventSuccess,
  hideAssignedUsers = false,
  hideLinkedTasks = false,
  disableShowReadDrawer = false,
}: Props): JSX.Element | null => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const [showLinkedTasksModal, setShowLinkedTasksModal] = useState(false);
  const [showEditScheduledEventModal, setShowEditScheduledEventModal] =
    useState(false);

  const currentUserId = useSelector((state: any) => state.users.myProfile.id);

  const accountUsers: any[] = useSelector(
    (state: any) => state.users.accountUsers
  );

  const scheduledEvent = useScheduledEvent(scheduledEventId);

  const archiveEvent = useCallback(
    (scheduledEventId: number) => {
      dispatch(
        scheduledEventActions.requestArchiveScheduledEvent(scheduledEventId)
      );
    },
    [dispatch]
  );

  const [confirmDeleteScheduledEvent, setConfirmDeleteScheduledEvent] =
    useState<number | null>(null);

  const userRoles = useSelector(getUserRoles);

  const isArchivingScheduledEvent = useSelector((state) => {
    return state.scheduledEvents.isArchivingScheduledEvent;
  });

  const duration = useMemo(() => {
    if (!scheduledEvent) return;
    return getTimeDuration(scheduledEvent.beginAt, scheduledEvent.endAt);
  }, [scheduledEvent]);

  const startTime = useMemo(() => {
    if (!scheduledEvent) return;
    return moment(scheduledEvent.beginAt).format('HH:mm');
  }, [scheduledEvent]);

  const endTime = useMemo(() => {
    if (!scheduledEvent) return;
    return moment(scheduledEvent.endAt).format('HH:mm');
  }, [scheduledEvent]);

  const startDate = useMemo(() => {
    if (!scheduledEvent) return;
    return moment(scheduledEvent.beginAt).format('MMM D');
  }, [scheduledEvent]);

  const fullDate = useMemo(() => {
    if (!scheduledEvent) return;
    return moment(scheduledEvent.beginAt).format('dddd Do MMM YYYY');
  }, [scheduledEvent]);

  const quickActions = useMemo(() => {
    if (hideQuickActions) return [];

    const qa = [];

    if (
      !hideLinkedTasks &&
      userHasRequiredPermission(userRoles, [
        TasksPermissions.GET_TASKS,
        ...(scheduledEvent?.dealId
          ? [TasksPermissions.GET_SELF_ASSIGNED_DEAL_TASKS]
          : []),
      ]) &&
      userHasRequiredPermission(userRoles, [
        ScheduledEventsPermissions.ADD_EVENT,
        ...(scheduledEvent?.dealId
          ? [ScheduledEventsPermissions.ADD_MY_DEAL_EVENT]
          : []),
      ])
    ) {
      qa.push({
        actionName: 'Link a task',
        actionBehaviour: () => setShowLinkedTasksModal(true),
        isActionProcessing: false,
      });
    }

    if (
      userHasRequiredPermission(userRoles, [
        ScheduledEventsPermissions.UPDATE_EVENT,
        ...(scheduledEvent?.dealId
          ? [ScheduledEventsPermissions.UPDATE_MY_DEAL_EVENT]
          : []),
        ...(scheduledEvent?.createdByUserId === currentUserId
          ? [ScheduledEventsPermissions.UPDATE_MY_EVENT]
          : []),
      ])
    ) {
      qa.push({
        actionName: 'Edit',
        actionBehaviour: () => setShowEditScheduledEventModal(true),
        isActionProcessing: false,
      });

      qa.push({
        actionName: 'Delete',
        actionBehaviour: () => setConfirmDeleteScheduledEvent(scheduledEventId),
        isActionProcessing: isArchivingScheduledEvent,
      });
    }

    return qa;
  }, [
    hideLinkedTasks,
    hideQuickActions,
    scheduledEventId,
    isArchivingScheduledEvent,
    userRoles,
    currentUserId,
  ]);

  const tasks = useSelector((state: any) =>
    scheduledEvent?.dealId ? getTasksByDealId(state, scheduledEvent.dealId) : []
  );

  const linkedTasks = useMemo(() => {
    if (!scheduledEvent?.taskIds) return [];
    return tasks.filter((x) => scheduledEvent.taskIds.includes(x.id));
  }, [scheduledEvent?.taskIds, tasks]);

  const assignedUsers = useMemo(() => {
    if (!scheduledEvent?.userAssignments) return [];
    return scheduledEvent.userAssignments
      .map((userId) => {
        return accountUsers.find((x) => x.id === userId);
      })
      .filter((x) => !!x);
  }, [scheduledEvent, accountUsers]);

  const CardProps = useMemo(
    () => ({
      className: 'mb-4',
      sizeVariant: CardSizeVariant.SM,
      to: disableShowReadDrawer ? undefined : `${url}/${scheduledEventId}`,
    }),
    [disableShowReadDrawer, url, scheduledEventId]
  );

  if (!scheduledEvent) return null;

  return (
    <>
      <ConditionalWrapper
        condition={disableShowReadDrawer}
        Wrapper={Card}
        wrapperProps={CardProps}
        FallbackWrapper={CardLink}
        // @ts-expect-error
        fallbackWrapperProps={CardProps}
      >
        <Card.Body>
          <div
            className={`scheduled-event-control ${
              disableShowReadDrawer ? '' : 'clickable'
            }`}
          >
            <ResponsiveViewWrapper
              downBreakpointSm={350}
              className="scheduled-event-control-inner"
            >
              {scheduledEvent && <DateTab date={scheduledEvent.beginAt} />}
              <div className="scheduled-event-body">
                <small className="text-xs text-gray-500">{fullDate}</small>
                <span
                  title={scheduledEvent.name}
                  className="scheduled-event-name"
                >
                  {scheduledEvent.name}
                </span>
                <small className="text-xs text-gray-500">
                  {`${startTime} - ${endTime} `}
                  {duration && (
                    <span className="scheduled-event-duration">
                      ({roundToDecimalPlaces(duration.value, 2)}{' '}
                      {duration.label})
                    </span>
                  )}{' '}
                </small>
              </div>
              {!hideAssignedUsers && (
                <div className="assigned-users-container">
                  <AssignedUsersIndicator
                    assignedUsers={assignedUsers.map((x) => ({
                      firstName: x.firstname,
                      lastName: x.lastname,
                      emailAddress: x.email,
                      userColour: x.userColour,
                      imgSrc: x.avatarUrl,
                    }))}
                  />
                </div>
              )}
              {!!quickActions.length && (
                <QuickActionsElement
                  recordId={scheduledEventId}
                  quickActions={quickActions}
                />
              )}
            </ResponsiveViewWrapper>
          </div>
        </Card.Body>
      </ConditionalWrapper>
      {showLinkedTasksModal && (
        <ScheduledEventLinkedTasksModal
          isOpen={showLinkedTasksModal}
          onClose={() => setShowLinkedTasksModal(false)}
          scheduledEvent={scheduledEvent}
          onPersistScheduledEventSuccess={() => {
            scheduledEvent.dealId &&
              dispatch(requestGetTasksForScheduledEvent(scheduledEventId));
            onPersistScheduledEventSuccess && onPersistScheduledEventSuccess();
            setShowLinkedTasksModal(false);
          }}
        />
      )}
      <ConfirmDeleteScheduledEventModal
        onClose={() => setConfirmDeleteScheduledEvent(null)}
        isOpen={!!confirmDeleteScheduledEvent}
        onConfirmDeleteScheduledEvent={() => {
          confirmDeleteScheduledEvent &&
            archiveEvent(confirmDeleteScheduledEvent);
          setConfirmDeleteScheduledEvent(null);
        }}
      />
      <EditScheduledEventModal
        isOpen={showEditScheduledEventModal}
        onClose={() => setShowEditScheduledEventModal(false)}
        event={scheduledEvent}
        onSuccess={() => {
          setShowEditScheduledEventModal(false);
          dispatch(
            scheduledEventActions.requestGetScheduledEvent(scheduledEventId)
          );
          onPersistScheduledEventSuccess?.();
        }}
      />
    </>
  );
};

export default ScheduledEventControl;
