import React, { FC } from 'react';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import Button from '@payaca/components/plButton/Button';
import { EBtnColour } from '@payaca/components/plButton/useButtonClassName';

interface IProps extends Omit<IModalProps, 'title'> {
  isProcessing?: boolean;
  onConfirm?: () => void;
}

const ConfirmDeleteNoteModal: FC<IProps> = (props) => {
  const { isOpen, onClose, onConfirm, isProcessing = false, ...rest } = props;

  return (
    <Modal isOpen={isOpen} title="Delete Note" onClose={onClose} {...rest}>
      <Modal.Body>
        <p>Are you sure you want to delete this Note?</p>
      </Modal.Body>

      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            isProcessing={isProcessing}
            onClick={() => {
              onConfirm?.();
            }}
            colour={EBtnColour.Red}
          >
            Delete
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmDeleteNoteModal;
