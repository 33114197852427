import { Reducer } from 'redux';
import { ActionType } from './userRolesTypes';

export const initialState = {
  isFetchingUserRoles: false,
  roles: [],
};

const userRolesReducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_USER_ROLES:
    case ActionType.CLEAR_USER_ROLES:
      return {
        isFetchingUserRoles: true,
        roles: [],
      };
    case ActionType.GET_USER_ROLES_SUCCESS:
      return {
        isFetchingUserRoles: false,
        roles: [...action.payload.roles],
      };
    case ActionType.GET_USER_ROLES_FAILURE:
      return {
        isFetchingUserRoles: false,
        roles: [],
      };
    default:
      return state;
  }
};

export default userRolesReducer;
