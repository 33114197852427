import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import './ChangePasswordForm.sass';
import { useDispatch } from 'react-redux';
import { actions as userActions } from '@/api/users';
import {
  getIsRequiredFieldValidator,
  getFieldValuesMustMatchValidator,
} from '@payaca/helpers/fieldValidationHelper';
import PrivateField from '@payaca/components/privateField/PrivateField';
import {
  minimumLengthFieldValidator,
  mustContainLetterFieldValidator,
  mustContainNumberFieldValidator,
} from '@payaca/helpers/passwordValidationHelper';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import CollapsiblePanel, {
  CollapsiblePanelStyleVariant,
} from '@payaca/components/collapsiblePanel/CollapsiblePanel';
import PasswordValidationFeedback from '../passwordValidationFeedback/PasswordValidationFeedback';
import { useSelector } from '@/api/state';
import Modal from '@payaca/components/plModal/Modal';

type Props = {
  onSuccessCallback?: () => void;
};

const ChangePasswordForm: FunctionComponent<Props> = ({
  onSuccessCallback,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showPasswordValidationFeedback, setShowPasswordValidationFeedback] =
    useState(false);

  const myProfile = useSelector((state: any) => {
    return state.users.myProfile;
  });

  const fieldValidators = useMemo(() => {
    const isRequiredFieldValidator = getIsRequiredFieldValidator();

    return {
      currentPassword: [isRequiredFieldValidator],
      newPassword: [
        isRequiredFieldValidator,
        minimumLengthFieldValidator,
        mustContainLetterFieldValidator,
        mustContainNumberFieldValidator,
      ],

      confirmNewPassword: [
        isRequiredFieldValidator,
        getFieldValuesMustMatchValidator('newPassword', {
          customErrorMessage: 'The passwords entered do not match',
        }),
      ],
    };
  }, []);

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      setIsProcessing(true);
      setErrorMessage(undefined);

      const payload = {
        email: myProfile.email,
        currentPassword: formState.currentPassword,
        newPassword: formState.newPassword,
      };

      dispatch(
        userActions.updatePassword(payload, (error: any) => {
          setIsProcessing(false);
          error
            ? setErrorMessage(
                'Sorry, there was an error updating your password. Please try again.'
              )
            : onSuccessCallback && onSuccessCallback();
        })
      );
    },
    [dispatch, onSuccessCallback, myProfile?.email]
  );

  const initialFormState = useMemo(() => {
    return {
      newPassword: '',
    };
  }, []);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <Modal.Body>
            <ValidatedFieldWrapper
              validationResult={{
                ...validationState['currentPassword'],
                errors: [],
              }}
              isTouched={touchedState['currentPassword'] || false}
            >
              <PrivateField
                styleVariant={InputStyleVariant.OUTSIZE}
                name="currentPassword"
                value={formState.currentPassword}
                label={'Current password'}
                isRequired={true}
                onChange={onFieldChange}
                onTouch={onFieldTouch}
              />
            </ValidatedFieldWrapper>
            <div className="new-password-input-container">
              <div>
                <ValidatedFieldWrapper
                  validationResult={{
                    ...validationState['newPassword'],
                    errors: [],
                  }}
                  isTouched={touchedState['newPassword'] || false}
                >
                  <PrivateField
                    styleVariant={InputStyleVariant.OUTSIZE}
                    name="newPassword"
                    value={formState.newPassword}
                    label={'New password'}
                    isRequired={true}
                    onChange={onFieldChange}
                    onTouch={onFieldTouch}
                    additionalInputProps={{
                      onFocus: () => setShowPasswordValidationFeedback(true),
                    }}
                  />
                </ValidatedFieldWrapper>
                <CollapsiblePanel
                  isOpen={showPasswordValidationFeedback}
                  styleVariant={CollapsiblePanelStyleVariant.UNSTYLED}
                  shouldRenderHeader={false}
                >
                  <PasswordValidationFeedback
                    password={formState.newPassword}
                  />
                </CollapsiblePanel>
              </div>
              <ValidatedFieldWrapper
                validationResult={validationState['confirmNewPassword']}
                isTouched={touchedState['confirmNewPassword'] || false}
              >
                <PrivateField
                  styleVariant={InputStyleVariant.OUTSIZE}
                  name="confirmNewPassword"
                  value={formState.confirmNewPassword}
                  label={'Confirm password'}
                  isRequired={true}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                />
              </ValidatedFieldWrapper>
            </div>
          </Modal.Body>

          <Modal.Footer>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <Modal.Footer.Actions>
              <Button
                styleVariant={ButtonStyleVariant.OUTSIZE}
                isDisabled={!isValid}
                isProcessing={isProcessing}
                onClick={() => !isProcessing && onSubmit(formState)}
              >
                Save
              </Button>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </>
      );
    },
    [onSubmit, isProcessing, errorMessage, showPasswordValidationFeedback]
  );

  return (
    <div className="change-password-form-container">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
      />
    </div>
  );
};

export default ChangePasswordForm;
