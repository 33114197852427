import { Reducer } from 'redux';

import { mergeFetchedEntities } from '../utils';

import { ActionType, State } from './invoicesTypes';

export const initialState: State = {
  isCreatingInvoice: false,
  isInvoiceCreatedSuccessfully: undefined,

  isMarkingInvoiceAsSent: false,
  isInvoiceMarkedAsSentSuccessfully: undefined,

  isSendingInvoice: false,
  isInvoiceSentSuccessfully: undefined,

  invoices: {},
  invoiceLines: {},
  paymentReconciliationRecords: {},

  isGettingInvoicesForDeal: false,
  isGettingInvoiceLinesForDeal: false,
  isGettingInvoiceLinesForInvoice: false,

  isUpdatingInvoice: false,
  invoiceUpdateResults: {},

  isGettingInvoiceValidationResult: false,
};

const jobsReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_INVOICE: {
      const fetchedEntity =
        state.invoices && state.invoices[action.payload.invoiceId];

      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action.payload.invoiceId]: {
            ...fetchedEntity,
            fetchAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_INVOICE_SUCCESS: {
      const fetchedEntity =
        state.invoices && state.invoices[action.payload.invoiceId];

      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action.payload.invoiceId]: {
            ...fetchedEntity,
            entity: action.payload.invoice,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.GET_INVOICE_FAILURE: {
      const fetchedEntity =
        state.invoices && state.invoices[action.payload.invoiceId];

      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action.payload.invoiceId]: {
            ...fetchedEntity,
            fetchFailedAtAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.REQUEST_GET_INVOICE_LINE: {
      const fetchedEntity =
        state.invoiceLines && state.invoiceLines[action.payload.invoiceLineId];

      return {
        ...state,
        invoiceLines: {
          ...state.invoiceLines,
          [action.payload.invoiceLineId]: {
            ...fetchedEntity,
            fetchAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_INVOICE_LINE_SUCCESS: {
      const fetchedEntity =
        state.invoiceLines && state.invoiceLines[action.payload.invoiceLineId];

      return {
        ...state,
        invoiceLines: {
          ...state.invoiceLines,
          [action.payload.invoiceLineId]: {
            ...fetchedEntity,
            entity: action.payload.invoiceLine,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.GET_INVOICE_LINE_FAILURE: {
      const fetchedEntity =
        state.invoiceLines && state.invoiceLines[action.payload.invoiceLineId];

      return {
        ...state,
        invoiceLines: {
          ...state.invoiceLines,
          [action.payload.invoiceLineId]: {
            ...fetchedEntity,
            fetchFailedAtAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }

    case ActionType.REQUEST_GET_INVOICES_FOR_DEAL:
      return {
        ...state,
        isGettingInvoicesForDeal: true,
      };
    case ActionType.GET_INVOICES_FOR_DEAL_SUCCESS:
      return {
        ...state,
        invoices: mergeFetchedEntities({
          cache: state.invoices,
          values: action.payload,
        }),
        isGettingInvoicesForDeal: false,
      };
    case ActionType.GET_INVOICES_FOR_DEAL_FAILURE:
      return {
        ...state,
        isGettingInvoicesForDeal: false,
      };

    case ActionType.REQUEST_GET_INVOICE_LINES_FOR_DEAL:
      return {
        ...state,
        isGettingInvoiceLinesForDeal: true,
      };
    case ActionType.GET_INVOICE_LINES_FOR_DEAL_SUCCESS:
      return {
        ...state,
        invoiceLines: mergeFetchedEntities({
          cache: state.invoiceLines,
          values: action.payload,
        }),
        isGettingInvoiceLinesForDeal: false,
      };
    case ActionType.GET_INVOICE_LINES_FOR_DEAL_FAILURE:
      return {
        ...state,
        isGettingInvoiceLinesForDeal: false,
      };

    case ActionType.REQUEST_GET_INVOICE_LINES_FOR_INVOICE:
      return {
        ...state,
        isGettingInvoiceLinesForInvoice: true,
      };
    case ActionType.GET_INVOICE_LINES_FOR_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceLines: mergeFetchedEntities({
          cache: state.invoiceLines,
          values: action.payload,
        }),
        isGettingInvoiceLinesForInvoice: false,
      };
    case ActionType.GET_INVOICE_LINES_FOR_INVOICE_FAILURE:
      return {
        ...state,
        isGettingInvoiceLinesForInvoice: false,
      };

    case ActionType.CLEAR_INVOICE: {
      const invoices = state.invoices || {};
      delete invoices[action.payload.invoiceId];

      return {
        ...state,
        invoices: invoices,
      };
    }
    case ActionType.CLEAR_INVOICES: {
      return {
        ...state,
        invoices: {},
      };
    }

    case ActionType.CLEAR_INVOICE_LINE: {
      const invoiceLines = state.invoiceLines || {};
      delete invoiceLines[action.payload.invoiceLineId];

      return {
        ...state,
        invoiceLines: invoiceLines,
      };
    }
    case ActionType.CLEAR_INVOICE_LINES: {
      return {
        ...state,
        invoiceLines: {},
      };
    }

    // SEND INVOICE
    case ActionType.REQUEST_SEND_INVOICE:
      return {
        ...state,
        isSendingInvoice: true,
        isInvoiceSentSuccessfully: undefined,
      };
    case ActionType.SEND_INVOICE_SUCCESS:
      return {
        ...state,
        isSendingInvoice: false,
        isInvoiceSentSuccessfully: true,
      };
    case ActionType.SEND_INVOICE_FAILURE:
      return {
        ...state,
        isSendingInvoice: false,
        isInvoiceSentSuccessfully: false,
      };
    // MARK INVOICE AS SENT
    case ActionType.REQUEST_MARK_INVOICE_AS_SENT:
      return {
        ...state,
        isMarkingInvoiceAsSent: true,
        isInvoiceMarkedAsSentSuccessfully: undefined,
      };
    case ActionType.MARK_INVOICE_AS_SENT_SUCCESS:
      return {
        ...state,
        isMarkingInvoiceAsSent: false,
        isInvoiceMarkedAsSentSuccessfully: true,
      };
    case ActionType.MARK_INVOICE_AS_SENT_FAILURE:
      return {
        ...state,
        isMarkingInvoiceAsSent: false,
        isInvoiceMarkedAsSentSuccessfully: false,
      };
    // CREATE INVOICE
    case ActionType.REQUEST_CREATE_INVOICE:
      return {
        ...state,
        isCreatingInvoice: true,
        isInvoiceCreatedSuccessfully: undefined,
      };
    case ActionType.CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        isCreatingInvoice: false,
        isInvoiceCreatedSuccessfully: true,
      };
    case ActionType.CREATE_INVOICE_FAILURE:
      return {
        ...state,
        isCreatingInvoice: false,
        isInvoiceCreatedSuccessfully: false,
      };
    case ActionType.GET_PAYMENT_RECONCILIATION_RECORD_SUCCESS: {
      const fetchedEntity =
        state.paymentReconciliationRecords &&
        state.paymentReconciliationRecords[
          action.payload.paymentReconciliationRecordId
        ];

      return {
        ...state,
        paymentReconciliationRecords: {
          ...state.paymentReconciliationRecords,
          [action.payload.paymentReconciliationRecordId]: {
            ...fetchedEntity,
            entity: action.payload.paymentReconciliationRecord,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.CLEAR_PAYMENT_RECONCILIATION_RECORDS: {
      return {
        ...state,
        paymentReconciliationRecords: {},
      };
    }

    // UPDATE INVOICE
    case ActionType.REQUEST_UPDATE_INVOICE:
      return {
        ...state,
        isUpdatingInvoice: true,
        invoiceUpdateResults: {
          ...state.invoiceUpdateResults,
          [action.payload.updateInvoiceRequestData.invoiceId]: {
            isUpdatedSuccessfully: undefined,
            updateError: undefined,
          },
        },
      };
    case ActionType.UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        isUpdatingInvoice: false,
        invoiceUpdateResults: {
          ...state.invoiceUpdateResults,
          [action.payload.invoiceId]: {
            isUpdatedSuccessfully: true,
            updateError: undefined,
          },
        },
      };
    case ActionType.UPDATE_INVOICE_FAILURE:
      return {
        ...state,
        isUpdatingInvoice: false,
        invoiceUpdateResults: {
          ...state.invoiceUpdateResults,
          [action.payload.invoiceId]: {
            isUpdatedSuccessfully: false,
            updateError: action.error,
          },
        },
      };

    case ActionType.REQUEST_GET_INVOICE_VALIDATION_RESULT:
      return {
        ...state,
        isGettingInvoiceValidationResult: true,
      };
    case ActionType.GET_INVOICE_VALIDATION_RESULT_FAILURE:
    case ActionType.GET_INVOICE_VALIDATION_RESULT_SUCCESS:
      return {
        ...state,
        isGettingInvoiceValidationResult: false,
      };

    case ActionType.GET_PAYMENT_RECONCILIATION_RECORDS_FOR_DEAL_SUCCESS:
      return {
        ...state,
        paymentReconciliationRecords: mergeFetchedEntities({
          cache: state.paymentReconciliationRecords,
          values: action.payload,
        }),
      };

    default:
      return state;
  }
};

export default jobsReducer;
