import { ClickAwayListener, Drawer, Paper } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ScheduledEventReadView from '../scheduledEventReadView/ScheduledEventReadView';
import SideDrawer from '@payaca/components/sideDrawer/SideDrawer';
import './EntityTemplateReadDrawer.sass';
import EntityTemplateReadView from '../entityTemplateReadView/EntityTemplateReadView';
import {
  PublicHydratedEntityTemplate,
  TemplatableEntity,
} from '@payaca/types/entity-templates';
import { WithOptional } from '@payaca/utilities/types';

type Props = {
  entityTemplate: PublicHydratedEntityTemplate<TemplatableEntity>;
  onDeleteEntityTemplateSuccess?: () => void;
  onUpdateEntityTemplateSuccess?: (
    updatedEntityTemplate: PublicHydratedEntityTemplate<TemplatableEntity>
  ) => void;
  isOpen: boolean;
  onClose?: () => void;
  allowModifyTemplateNameDescription?: boolean;
};

const EntityTemplateReadDrawer: FC<Props> = ({
  entityTemplate,
  onDeleteEntityTemplateSuccess,
  onUpdateEntityTemplateSuccess,
  isOpen,
  onClose,
  allowModifyTemplateNameDescription,
}: Props): JSX.Element | null => {
  return (
    <SideDrawer
      isOpen={isOpen}
      onClose={onClose}
      className="entity-template-read-drawer"
    >
      {entityTemplate && (
        <EntityTemplateReadView
          allowModifyTemplateNameDescription={
            allowModifyTemplateNameDescription
          }
          initialEntityTemplate={entityTemplate}
          onUpdateEntityTemplateSuccess={onUpdateEntityTemplateSuccess}
          onDeleteEntityTemplateSuccess={onDeleteEntityTemplateSuccess}
        />
      )}
    </SideDrawer>
  );
};
export default EntityTemplateReadDrawer;
