import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Download, Upload } from 'react-iconly';
import TooltipUI from '@material-ui/core/Tooltip';

import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';
import { getRegion, getSupplier } from '@/utils/stateAccessors';

import './SupplierSupplierMaterials.sass';
import { SupplierMaterialControlContext } from '../supplierMaterialControl/SupplierMaterialControl';
import CreateEditSupplierMaterialModal from '../createEditSupplierMaterialModal/CreateEditSupplierMaterialModal';
import { SupplierMaterial } from '@payaca/types/materialTypes';
import { GetListedSupplierMaterialsRequestData } from '@payaca/store/listedSupplierMaterials/listedSupplierMaterialsTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import { SortBy } from '@payaca/types/listedSupplierMaterialTypes';
import * as listedSupplierMaterialsActions from '@payaca/store/listedSupplierMaterials/listedSupplierMaterialsActions';
import ListedSupplierMaterials from '../listedSupplierMaterials/ListedSupplierMaterials';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import Button from '@payaca/components/button/Button';
import SearchField from '../searchField/SearchField';
import { InputWidth } from '@payaca/components/inputWrapper/InputWrapper';
import { getRegionalDefaultTax } from '@payaca/helpers/internationalHelper';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import { useSelector } from '@/api/state';
import { useDefaultTaxRate } from '@payaca/store/hooks/appState';
import BulkUploadModal from '../bulkUploadDownloadModals/BulkUploadModal';
import BulkUpdateModal from '../bulkUploadDownloadModals/BulkUpdateModal';
import { useHashFragment } from '@/utils/customHooks';
import { useAccount } from '@/utils/storeHooks';

type Props = {
  supplierId: number;
  onSupplierUpdateSuccess: () => void;
};
const SupplierSupplierMaterials: FC<Props> = ({
  supplierId,
  onSupplierUpdateSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const [showBulkUploadModal, toggleBulkUploadModal] =
    useHashFragment('#bulk-upload');
  const [showBulkUpdateModal, toggleBulkUpdateModal] =
    useHashFragment('#bulk-update');
  const [
    getListedSupplierMaterialsRequestData,
    setGetListedSupplierMaterialsRequestData,
  ] = useState<GetListedSupplierMaterialsRequestData>({
    pageSize: 30,
    pageNumber: 1,
    supplierId: supplierId,
    sortDirection: SortDirection.ASCENDING,
    sortBy: SortBy.MATERIAL_NAME,
  });

  useEffect(() => {
    return () => {
      dispatch(
        listedSupplierMaterialsActions.clearListedSupplierMaterialsPage()
      );
    };
  }, []);

  useEffect(() => {
    requestGetListedSupplierMaterialsPage();
  }, [getListedSupplierMaterialsRequestData]);

  const requestGetListedSupplierMaterialsPage = useCallback(() => {
    dispatch(
      listedSupplierMaterialsActions.requestGetListedSupplierMaterialsPage(
        getListedSupplierMaterialsRequestData
      )
    );
  }, [getListedSupplierMaterialsRequestData, dispatch]);

  const [showCreateSupplierMaterialModal, setShowCreateSupplierMaterialModal] =
    useState(false);

  const supplier = useSelector((state) => getSupplier(state, supplierId));

  const isPayacaAdmin = useSelector(
    (state: any) => state.users.myProfile.isPayacaAdmin
  );

  const region = useSelector(getRegion);

  const regionalDefaultTax = useMemo(() => {
    return getRegionalDefaultTax(region);
  }, [region]);

  const isGettingListedSupplierMaterialsPage = useSelector(
    (state) =>
      state.listedSupplierMaterials.isGettingListedSupplierMaterialsPage
  );

  const defaultTaxRate = useDefaultTaxRate();

  if (!supplier) return null;

  return (
    <>
      <div className="supplier-supplier-materials">
        <PermissionGuard
          renderIfHasPermissions={[
            MaterialsPermissions.PERSIST_SUPPLIER_MATERIAL,
          ]}
        >
          <div className="additional-tab-header-content">
            {!supplier.isSystemManaged ? (
              <Button onClick={() => setShowCreateSupplierMaterialModal(true)}>
                Add Material
              </Button>
            ) : (
              <div />
            )}
            <div className="download-upload-wrapper">
              <PermissionGuard
                requireAllPermissions
                renderIfHasPermissions={[
                  MaterialsPermissions.BULK_IMPORT_SUPPLIER_MATERIALS,
                ]}
              >
                <>
                  {(!supplier.isSystemManaged || isPayacaAdmin) && (
                    <TooltipUI
                      title="Download and update materials from CSV data"
                      arrow
                      placement="left"
                    >
                      <div>
                        <Button
                          onClick={toggleBulkUpdateModal}
                          className="download-button"
                          hasBoxShadow={false}
                        >
                          <Download set="light" />
                        </Button>
                      </div>
                    </TooltipUI>
                  )}
                  {!supplier.isSystemManaged && (
                    <TooltipUI
                      title="Upload materials from CSV data"
                      arrow
                      placement="left"
                    >
                      <div>
                        <Button
                          onClick={toggleBulkUploadModal}
                          className="upload-button"
                          hasBoxShadow={false}
                        >
                          <Upload set="light" />
                        </Button>
                      </div>
                    </TooltipUI>
                  )}
                </>
              </PermissionGuard>
            </div>
          </div>
        </PermissionGuard>
        <div className="search-field-container">
          <SearchField
            inputWidthVariant={InputWidth.MD}
            placeholder="Search Materials"
            onSearchTermChange={(searchTerm?: string | undefined) =>
              setGetListedSupplierMaterialsRequestData(
                (getListedSupplierMaterialsRequestData) => ({
                  ...getListedSupplierMaterialsRequestData,
                  pageNumber: 1,
                  materialSearchTerm: searchTerm,
                })
              )
            }
          />
          {isGettingListedSupplierMaterialsPage && <MiniLoader />}
        </div>
        <ListedSupplierMaterials
          onSelectPage={(pageNumber: number) => {
            setGetListedSupplierMaterialsRequestData(
              (getListedSupplierMaterialsRequestData) => ({
                ...getListedSupplierMaterialsRequestData,
                pageNumber: pageNumber,
              })
            );
          }}
          context={SupplierMaterialControlContext.SUPPLIER}
          onDeleteSupplierMaterialSuccess={() => {
            requestGetListedSupplierMaterialsPage();
            onSupplierUpdateSuccess && onSupplierUpdateSuccess();
          }}
          onPersistSupplierMaterialSuccess={
            requestGetListedSupplierMaterialsPage
          }
        />
      </div>
      <CreateEditSupplierMaterialModal
        isOpen={showCreateSupplierMaterialModal}
        onClose={() => setShowCreateSupplierMaterialModal(false)}
        supplierMaterial={
          {
            supplierId: supplierId,
            taxPercentage: defaultTaxRate?.percentage ?? 0,
            isTaxIncluded: defaultTaxRate?.isIncluded ?? false,
            taxRateId: defaultTaxRate?.id ?? null,
          } as unknown as SupplierMaterial
        }
        isSupplierSelectionDisabled={true}
        onPersistSupplierMaterialSuccess={() => {
          setShowCreateSupplierMaterialModal(false);
          onSupplierUpdateSuccess();
          requestGetListedSupplierMaterialsPage();
        }}
      />
      {supplierId && (
        <>
          <BulkUploadModal
            isOpen={showBulkUploadModal}
            onClose={toggleBulkUploadModal}
            title="Upload materials"
            dataType="supplierMaterials"
            metadata={{ supplierId }}
          ></BulkUploadModal>
          <BulkUpdateModal
            isOpen={showBulkUpdateModal}
            onClose={toggleBulkUpdateModal}
            title="Update materials"
            dataType="supplierMaterials"
            metadata={{ supplierId }}
            additionalMessage={
              supplier.isSystemManaged && (
                <>
                  <p>
                    <strong>Warning!</strong> Uploading a CSV file will update
                    system-managed supplier materials for all accounts. Please
                    do not proceed unless you are absolutely sure you want to do
                    this!
                  </p>
                </>
              )
            }
          ></BulkUpdateModal>
        </>
      )}
    </>
  );
};

export default SupplierSupplierMaterials;
