import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import qs from 'qs';
import { useHistory } from 'react-router';

import * as authActions from '@payaca/store/auth/authActions';

import BasicPageWrapper from '../pageWrappers/basicPageWrapper/BasicPageWrapper';
import { useSelector } from '@/api/state';
import useLogout from '@/hooks/auth/useLogout';

const AppleCallbackPage: FunctionComponent = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const logout = useLogout();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isLoggingIn = useSelector((state) => state.auth.isLoggingIn);
  const isLoggedInSuccessfully = useSelector(
    (state) => state.auth.isLoggedInSuccessfully
  );

  useEffect(() => {
    logout();
  }, []);

  useEffect(() => {
    if (isLoggedInSuccessfully && isSubmitted) {
      history.push('/');
    } else if (isSubmitted && !isLoggedInSuccessfully && !isLoggingIn) {
      history.push(
        '/?error=An error occurred logging into Apple, please try again or use an alternative method.'
      );
    }
  }, [isLoggedInSuccessfully, isSubmitted, history, isLoggingIn]);

  useEffect(() => {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (query.code) {
      setIsSubmitted(true);
      dispatch(authActions.requestLoginWithApple({ code: query.code }));
    }
  }, []);

  return (
    //@ts-ignore
    <BasicPageWrapper>
      <div></div>
    </BasicPageWrapper>
  );
};

export default AppleCallbackPage;
