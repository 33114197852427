import useGetMyCustomFieldGroups from '@/api/queries/me/useGetMyCustomFieldGroups';
import { useMemo } from 'react';

export const useProjectCustomFields = () => {
  const { data: customFieldGroups } = useGetMyCustomFieldGroups();
  const projectCustomFields = useMemo(() => {
    return (
      customFieldGroups
        ?.find((x) => x.role === 'project')
        ?.customFields.filter((x) => !x.archivedAt) || []
    );
  }, [customFieldGroups]);

  return projectCustomFields;
};
