import React, { FC, useMemo } from 'react';
import { Job } from '@payaca/types/jobTypesV2';
import './DealPaymentSummary.sass';
import { currencyPrice } from '@/helpers/financeHelper';
import { getTotalCompletedPaymentValueFromJobPayments } from '@payaca/helpers/jobPaymentHelper';
import { isInvoice } from '@payaca/helpers/jobStatusHelper';
import {
  getActiveJobContentByDealId,
  getJobPaymentsByDealId,
  getJobsByDealId,
} from '@/utils/stateAccessors';
import { useSelector } from '@/api/state';

interface Props {
  dealId: number;
}

const DealPaymentSummary: FC<Props> = ({ dealId }: Props) => {
  const jobs = useSelector((state) => {
    return getJobsByDealId(state, dealId);
  });

  const jobPayments = useSelector((state) => {
    return getJobPaymentsByDealId(state, dealId);
  });

  const activeJobContent = useSelector((state) => {
    return getActiveJobContentByDealId(state, dealId);
  });

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const activeJob = useMemo(() => {
    if (!jobs || !jobs.length || !activeJobContent) return;

    const filteredJobs = jobs.filter(
      (x) => x.jobContentId === activeJobContent.id && !x.inactivatedAt
    );

    if (!filteredJobs || !filteredJobs.length) return;
    //...sort jobs by createdat date, most recently created first
    const sortedJobs = filteredJobs.sort((j1: Job, j2: Job) =>
      j1.createdAt > j2.createdAt ? -1 : 1
    );
    const invoice = sortedJobs.find((x) => isInvoice(x.status));
    if (invoice) return invoice;

    return sortedJobs[0];
  }, [jobs, activeJobContent]);

  const receivedPaymentsTotal = useMemo(() => {
    if (!jobPayments?.length) return 0;
    return getTotalCompletedPaymentValueFromJobPayments(jobPayments);
  }, [jobPayments]);

  const isAccepted = useMemo(() => {
    return !!activeJob?.acceptedAt;
  }, [activeJob]);

  if (
    !activeJob ||
    !activeJobContent ||
    (!isInvoice(activeJob.status) && !isAccepted)
  )
    return (
      <div className={'deal-payment-summary flex-container'}>
        <div className={'deal-payment-summary-amount'}>
          <h3>Accepted total</h3>
          <div>-</div>
        </div>
        <div className={'deal-payment-summary-amount'}>
          <h3>Received</h3>
          <div>
            {receivedPaymentsTotal
              ? currencyPrice(receivedPaymentsTotal, account.region)
              : '-'}
          </div>
        </div>
        <div className={'deal-payment-summary-amount'}>
          <h3>Outstanding</h3>
          <div>-</div>
        </div>
      </div>
    );

  return (
    <div className={'deal-payment-summary flex-container'}>
      <div className={'deal-payment-summary-amount'}>
        <h3>Accepted Total</h3>
        <div>{currencyPrice(activeJobContent.total, account.region)}</div>
      </div>

      <div className={'deal-payment-summary-amount'}>
        <h3>Received</h3>
        <div>{currencyPrice(receivedPaymentsTotal, account.region)}</div>
      </div>
      <div className={'deal-payment-summary-amount'}>
        <h3>Outstanding</h3>
        <div>
          {currencyPrice(
            activeJobContent.total - receivedPaymentsTotal,
            account.region
          )}
        </div>
      </div>
    </div>
  );
};

export default DealPaymentSummary;
