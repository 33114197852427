import { action } from 'typesafe-actions';

import { ActionType } from './listedItemsTypes';
import {
  GetListedItemsRequestData,
  ListedItemsListViewPage,
} from '@payaca/types/listedItemTypes';

export const requestGetListedItemsPage = (
  getListedItemsRequestData: GetListedItemsRequestData
) => {
  return action(ActionType.REQUEST_GET_LISTED_ITEMS_PAGE, {
    getListedItemsRequestData,
  });
};

export const clearListedItemsPage = () => {
  return action(ActionType.CLEAR_LISTED_ITEMS_PAGE);
};

export const getListedItemsPageSuccess = (
  listedItemsPage: ListedItemsListViewPage
) => {
  return action(ActionType.GET_LISTED_ITEMS_PAGE_SUCCESS, {
    listedItemsPage,
  });
};

export const getListedItemsPageFailure = (error: Error) => {
  return action(ActionType.GET_LISTED_ITEMS_PAGE_FAILURE, null, null, error);
};
