import React, { FC } from 'react';

import Modal from '@payaca/components/modal/Modal';
import CreateEditMaterialControl from '../createEditMaterialControl/CreateEditMaterialControl';

import { Material } from '@payaca/types/materialTypes';

import './CreateEditMaterialModal.sass';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onPersistMaterialSuccess?: (materialId: number) => void;
  material?: Material;
  enableSupplierMaterialInput?: boolean;
};
const CreateEditMaterialModal: FC<Props> = ({
  isOpen,
  onClose,
  onPersistMaterialSuccess,
  material,
  enableSupplierMaterialInput,
}: Props): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      className="create-edit-material-modal"
      title={material?.id ? 'Edit Material' : 'Create Material'}
      onClose={onClose}
      size={'sm'}
      disableBackdropClick={true}
    >
      <CreateEditMaterialControl
        material={material}
        onPersistMaterialSuccess={(materialId: number) => {
          onClose();
          onPersistMaterialSuccess && onPersistMaterialSuccess(materialId);
        }}
        enableSupplierMaterialInput={enableSupplierMaterialInput}
      />
    </Modal>
  );
};
export default CreateEditMaterialModal;
