import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';

import Toast, { TProps as TToast } from './Toast';

export type { TToast };

type TToastContext = {
  toasts: TToast[];
  pushToast: (toast: TToast) => void;
};

const ToastContext = createContext<TToastContext>({
  toasts: [],
  pushToast: () => {},
});

export const useToastContext = () => {
  return React.useContext(ToastContext);
};

const ToastContextProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [toasts, setToasts] = useState<TToastContext['toasts']>([]);

  const pushToast = useCallback(
    (toast: TToast) => {
      setToasts((prevToasts) => {
        return [...(prevToasts || []), toast];
      });
    },
    [setToasts]
  );

  // While there are toasts, remove the first toast after 5 seconds
  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => {
        setToasts((prevToasts) => {
          return prevToasts.slice(1);
        });
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [toasts]);

  return (
    <ToastContext.Provider value={{ toasts, pushToast }}>
      {children}

      {!!toasts.length && (
        <>
          {createPortal(
            <div className="z-toast fixed bottom-0 right-0 space-y-4 p-8">
              {toasts.map((toast, index) => (
                <Transition
                  key={index}
                  show
                  appear
                  enter="transition-opacity transition-transform duration-75"
                  enterFrom="opacity-0 translate-x-full"
                  enterTo="opacity-100"
                >
                  <Toast
                    {...toast}
                    onDismiss={() => {
                      toast.onDismiss?.();

                      setToasts((prevToasts) => {
                        return prevToasts.filter((_, i) => i !== index);
                      });
                    }}
                  />
                </Transition>
              ))}
            </div>,
            document.body
          )}
        </>
      )}
    </ToastContext.Provider>
  );
};

export default ToastContextProvider;
