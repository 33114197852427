import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  AddNewCustomerAddressInput,
  AddNewCustomerAddressMutation as _AddNewCustomerAddressMutation,
} from '@/gql/graphql';

const AddNewCustomerAddressMutation = graphql(`
  mutation AddNewCustomerAddress($input: AddNewCustomerAddressInput!) {
    addNewCustomerAddress(input: $input) {
      id
    }
  }
`);

const useAddNewCustomerAddress = (
  options?: UseMutationOptions<
    _AddNewCustomerAddressMutation,
    unknown,
    AddNewCustomerAddressInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(AddNewCustomerAddressMutation, { input });
    },
    ...options,
  });
};

export default useAddNewCustomerAddress;
