import React, { FunctionComponent } from 'react';

import './SectionHeader.sass';

type Props = {
  className?: string;
  headerComponent: React.ReactNode;
  actionsComponent?: React.ReactNode;
};

const SectionHeader: FunctionComponent<Props> = ({
  className,
  headerComponent,
  actionsComponent,
}: Props): JSX.Element => {
  return (
    <div className={`section-header ${className ? className : ''}`}>
      <div className="header-container">{headerComponent}</div>
      {actionsComponent && (
        <div className="actions-container">{actionsComponent}</div>
      )}
    </div>
  );
};

export default SectionHeader;
