import { useSelector } from '@/api/state';
import { getUserRoles } from '@/utils/stateAccessors';
import {
  userHasAllRequiredPermissions as _userHasAllRequiredPermissions,
  userHasRequiredPermission as _userHasRequiredPermission,
  userHasRequiredRole as _userHasRequiredRole,
} from '@payaca/permissions/permissions.utils';
import { useCallback, useMemo } from 'react';

export const usePermissions = () => {
  const userRoles = useSelector(getUserRoles);

  const userHasAllRequiredPermissions = useCallback(
    (permissions: string[]) =>
      _userHasAllRequiredPermissions(userRoles, permissions),
    [userRoles]
  );

  const userHasRequiredPermission = useCallback(
    (permissions: string[]) =>
      _userHasRequiredPermission(userRoles, permissions),
    [userRoles]
  );

  const userHasRequiredRole = useCallback(
    (roles: string[]) => _userHasRequiredRole(userRoles, roles),
    [userRoles]
  );

  return {
    userHasAllRequiredPermissions,
    userHasRequiredPermission,
    userHasRequiredRole,
  };
};

export interface IUseUserHasPermissionArgs {
  permissions?: string[];
  requiresAllPermissions?: boolean;
}

export const useUserHasPermission = (args: IUseUserHasPermissionArgs) => {
  const { permissions, requiresAllPermissions = false } = args;

  const { userHasAllRequiredPermissions, userHasRequiredPermission } =
    usePermissions();

  return useMemo(() => {
    let result = true;

    if (permissions?.length) {
      result = requiresAllPermissions
        ? userHasAllRequiredPermissions(permissions)
        : userHasRequiredPermission(permissions);
    }

    return result;
  }, [
    userHasAllRequiredPermissions,
    userHasRequiredPermission,
    permissions,
    requiresAllPermissions,
  ]);
};

export interface IUseUserHasRoleArgs {
  roles?: string[];
}

export const useUserHasRole = (args: IUseUserHasRoleArgs) => {
  const { roles } = args;

  const { userHasRequiredRole } = usePermissions();

  return useMemo(() => {
    let result = true;

    if (roles?.length) {
      result = userHasRequiredRole(roles);
    }

    return result;
  }, [userHasRequiredRole, roles]);
};
