import { action } from 'typesafe-actions';

import { ListedMaterialLineItemListViewPage } from '@payaca/types/listedMaterialLineItemTypes';
import {
  ActionType,
  GetListedMaterialLineItemsRequestData,
} from './listedMaterialLineItemsTypes';

export const requestGetListedMaterialLineItemsPage = (
  getListedMaterialLineItemsRequestData: GetListedMaterialLineItemsRequestData
) => {
  return action(ActionType.REQUEST_GET_LISTED_MATERIAL_ITEMS_PAGE, {
    getListedMaterialLineItemsRequestData,
  });
};

export const clearListedMaterialLineItemsPage = () => {
  return action(ActionType.CLEAR_LISTED_MATERIAL_ITEMS_PAGE);
};

export const getListedMaterialLineItemsPageSuccess = (
  listedMaterialLineItemsPage: ListedMaterialLineItemListViewPage
) => {
  return action(ActionType.GET_LISTED_MATERIAL_ITEMS_PAGE_SUCCESS, {
    listedMaterialLineItemsPage,
  });
};

export const getListedMaterialLineItemsPageFailure = (error: Error) => {
  return action(
    ActionType.GET_LISTED_MATERIAL_ITEMS_PAGE_FAILURE,
    null,
    null,
    error
  );
};
