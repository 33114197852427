import { ScheduledEventsPermissions } from './scheduled-events.permissions';
import { ScheduledEventsRoles } from './scheduled-events.roles';

export const scheduledEventsMappings = {
  [ScheduledEventsPermissions.GET_MY_EVENTS]: [
    ScheduledEventsRoles.ASSIGNEE_VIEWER,
  ],
  [ScheduledEventsPermissions.GET_EVENTS]: [ScheduledEventsRoles.VIEWER],
  [ScheduledEventsPermissions.ADD_EVENT]: [ScheduledEventsRoles.CREATOR],
  [ScheduledEventsPermissions.UPDATE_EVENT]: [ScheduledEventsRoles.EDITOR],
  [ScheduledEventsPermissions.GET_MY_DEAL_EVENTS]: [
    ScheduledEventsRoles.DEAL_ASSIGNEE_VIEWER,
  ],
  [ScheduledEventsPermissions.UPDATE_MY_EVENT]: [
    ScheduledEventsRoles.ASSIGNEE_EDITOR,
  ],
  [ScheduledEventsPermissions.ADD_MY_EVENT]: [
    ScheduledEventsRoles.ASSIGNEE_CREATOR,
  ],
  [ScheduledEventsPermissions.UPDATE_MY_DEAL_EVENT]: [
    ScheduledEventsRoles.DEAL_ASSIGNEE_EDITOR,
  ],
  [ScheduledEventsPermissions.ADD_MY_DEAL_EVENT]: [
    ScheduledEventsRoles.DEAL_ASSIGNEE_CREATOR,
  ],
  [ScheduledEventsPermissions.TAG_EVENT]: [ScheduledEventsRoles.TAGGER],
  [ScheduledEventsPermissions.BULK_IMPORT_EVENTS]: [
    ScheduledEventsRoles.BULK_IMPORTER,
  ],
};
