// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import { PaginationInput } from '../../../gql/graphql';

const notificationKeys = {
  all: ['notification'] as const,
  notificationCount: () =>
    [...notificationKeys.all, 'notificationCount'] as const,
  notifications: (pagination?: PaginationInput) =>
    [...notificationKeys.all, pagination, 'notifications'] as const,
};

export default notificationKeys;
