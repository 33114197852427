import React, { FC, useMemo, useState } from 'react';
import Modal from '@payaca/components/modal/Modal';
import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

import './TermsAndPrivacyUpdatesModal.sass';

type Props = {
  isAgreeingToTermsAndPrivacyVersion: boolean;
  isOpen: boolean;
  onAgree: () => void;
  updatedTerms: string;
  updatedPrivacy: string;
};
const TermsAndPrivacyUpdatesModal: FC<Props> = ({
  isAgreeingToTermsAndPrivacyVersion,
  isOpen,
  onAgree,
  updatedPrivacy,
  updatedTerms,
}: Props) => {
  const errorAgreeingToTermsAndPrivacy = useState<string | null>(null);
  const title = useMemo(() => {
    if (updatedPrivacy && updatedTerms) {
      return 'Our updated terms of business and privacy policy';
    } else if (updatedPrivacy) {
      return 'Our updated privacy policy';
    } else {
      return 'Our updated terms of business';
    }
  }, [updatedPrivacy, updatedTerms]);

  return (
    <Modal
      className="terms-and-privacy-updates-modal"
      isOpen={isOpen}
      showCloseControl={false}
      size="md"
      title={title}
      actions={
        <Button
          onClick={onAgree}
          isProcessing={isAgreeingToTermsAndPrivacyVersion}
          styleVariant={ButtonStyleVariant.OUTSIZE}
        >
          I agree
        </Button>
      }
    >
      <p>
        Please review the following and confirm you agree to continue using
        Payaca
      </p>
      {updatedTerms && (
        <>
          <h4>Terms of Business</h4>
          <div className="agreement-wrapper">
            <MarkdownLabel markdown={updatedTerms} />
          </div>
        </>
      )}
      {updatedPrivacy && (
        <>
          <h4>Privacy &amp; Cookie Policy</h4>
          <div className="agreement-wrapper">
            <MarkdownLabel markdown={updatedPrivacy} />
          </div>
        </>
      )}
      {typeof errorAgreeingToTermsAndPrivacy === 'string' && (
        <p>{errorAgreeingToTermsAndPrivacy}</p>
      )}
    </Modal>
  );
};

export default TermsAndPrivacyUpdatesModal;
