import { Reducer } from 'redux';

import { mergeFetchedEntities } from '../utils';

import { ActionType, LineItemsState } from './lineItemsTypes';

export const initialState: LineItemsState = {
  lineItems: {},

  isGettingLineItem: false,
  gettingLineItemError: null,
  isPersistingLineItem: false,
};

const lineItemsReducer: Reducer<LineItemsState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionType.REQUEST_PERSIST_LINE_ITEM:
      return {
        ...state,
        isPersistingLineItem: true,
      };
    case ActionType.PERSIST_LINE_ITEM_FAILURE:
    case ActionType.PERSIST_LINE_ITEM_SUCCESS:
      return {
        ...state,
        isPersistingLineItem: false,
      };
    //  GETTING LINE ITEM
    case ActionType.REQUEST_GET_LINE_ITEM:
    case ActionType.REQUEST_GET_LINE_ITEM_WITH_RELATED_ENTITIES: {
      const fetchedEntity =
        state.lineItems && state.lineItems[action.payload.lineItemId];

      return {
        ...state,
        lineItems: {
          ...state.lineItems,
          [action.payload.lineItemId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_LINE_ITEM_SUCCESS: {
      const fetchedEntity =
        state.lineItems && state.lineItems[action.payload.lineItemId];

      return {
        ...state,
        isGettingLineItem: false,
        gettingLineItemError: null,
        lineItems: {
          ...state.lineItems,
          [action.payload.lineItemId]: {
            ...fetchedEntity,
            entity: action.payload.lineItem,
            isFetching: false,
            fetchSucceededAt: new Date(),
          },
        },
      };
    }
    case ActionType.GET_LINE_ITEM_FAILURE:
      return {
        ...state,
        isGettingLineItem: false,
        gettingLineItemError: action.payload.errorMessage,
      };

    // CLEAR LINE ITEMS
    case ActionType.CLEAR_LINE_ITEMS:
      return {
        ...initialState,
      };

    case ActionType.GET_LINE_ITEMS_FOR_LINE_ITEM_GROUP_SUCCESS:
      return {
        ...state,
        lineItems: mergeFetchedEntities({
          cache: state.lineItems,
          values: action.payload,
        }),
        isGettingLineItem: false,
        gettingLineItemError: null,
      };

    case ActionType.GET_LINE_ITEM_WITH_RELATED_ENTITIES_SUCCESS:
      return {
        ...state,
        lineItems: mergeFetchedEntities({
          cache: state.lineItems,
          values: [action.payload.lineItem],
        }),
        isGettingLineItem: false,
        gettingLineItemError: null,
      };

    default:
      return state;
  }
};

export default lineItemsReducer;
