import { Reducer } from 'redux';

import { ActionType, State } from './fileDownloadTypes';

export const initialState: State = {
  fileDownloads: {},

  isDownloadingFile: false,
};

const fileDownloadReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_FILE_DOWNLOAD: {
      const fetchedEntity =
        state.fileDownloads && state.fileDownloads[action.payload.entityId];
      const fetchEntityType =
        fetchedEntity && fetchedEntity[action.payload.type];

      return {
        ...state,
        isDownloadingFile: true,
        fileDownloads: {
          ...state.fileDownloads,
          [action.payload.entityId]: {
            ...fetchedEntity,
            [action.payload.type]: {
              ...fetchEntityType,
              fetchAttemptedAttemptedAt: new Date(),
              fetchSucceededAt: undefined,
              fetchFailedAt: undefined,
              isFetching: true,
              fetchError: undefined,
            },
          },
        },
      };
    }
    case ActionType.FILE_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        isDownloadingFile: false,
        fileDownloads: {
          ...state.fileDownloads,
          [action.payload.entityId]: {
            ...state.fileDownloads[action.payload.entityId],
            [action.payload.type]: {
              entity: action.payload.value,
              fetchSucceededAt: new Date(),
              isFetching: false,
            },
          },
        },
      };
    }
    case ActionType.FILE_DOWNLOAD_FAILURE: {
      const fetchedEntity =
        state.fileDownloads && state.fileDownloads[action.payload.entityId];
      const fetchEntityType =
        fetchedEntity && fetchedEntity[action.payload.type];

      return {
        ...state,
        isDownloadingFile: false,
        fileDownloads: {
          ...state.fileDownloads,
          [action.payload.entityId]: {
            ...fetchedEntity,
            [action.payload.type]: {
              ...fetchEntityType,
              fetchFailedAt: new Date(),
              isFetching: false,
              fetchError: action.error,
            },
          },
        },
      };
    }
    case ActionType.CLEAR_FILE_DOWNLOADS:
      return { ...initialState };
    default:
      return state;
  }
};

export default fileDownloadReducer;
