import { ImageEditConfig } from '@payaca/types/uploadTypes';

export const Req = (
  apiBaseUrl: string,
  authHeader?: () => Promise<string>,
  isNativeApp = false
) => {
  async function request(path: string, settings: any) {
    const response = await fetch(`${apiBaseUrl}${path}`, settings);

    if (response.status == 401) {
      throw new Error('UNAUTHORISED');
    }
    if (response.ok) {
      return response;
    } else {
      let error;
      try {
        error = await response.json();
      } catch (e) {
        error = `${path} failed: ${response.status} ${response.statusText}`;
      }
      throw {
        message: error,
        error: new Error(error),
      };
    }
  }

  return {
    get: async (path: string, options: Parameters<typeof fetch>[1] = {}) => {
      return await request(path, {
        ...options,
        method: 'GET',
        headers: {
          Authorization: authHeader ? await authHeader() : undefined,
          'X-Native-App': isNativeApp,
          'X-Simple-Job': true,
          ...(options.headers ?? {}),
        },
      });
    },
    del: async (path: string, options: Parameters<typeof fetch>[1] = {}) => {
      return await request(path, {
        ...options,
        method: 'DELETE',
        headers: {
          Authorization: authHeader ? await authHeader() : undefined,
          'X-Native-App': isNativeApp,
          'X-Simple-Job': true,
          ...(options.headers ?? {}),
        },
      });
    },
    post: async (
      path: string,
      body: any,
      options: Parameters<typeof fetch>[1] = {}
    ) => {
      return await request(path, {
        ...options,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHeader ? await authHeader() : undefined,
          'X-Native-App': isNativeApp,
          'X-Simple-Job': true,
          ...(options.headers ?? {}),
        },
        body: JSON.stringify(body),
      });
    },
    patch: async (
      path: string,
      body: any,
      options: Parameters<typeof fetch>[1] = {}
    ) => {
      return await request(path, {
        ...options,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHeader ? await authHeader() : undefined,
          'X-Native-App': isNativeApp,
          'X-Simple-Job': true,
          ...(options.headers ?? {}),
        },
        body: JSON.stringify(body),
      });
    },
    put: async (
      path: string,
      body?: any,
      options: Parameters<typeof fetch>[1] = {}
    ) => {
      return await request(path, {
        ...options,
        method: 'PUT',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: authHeader ? await authHeader() : undefined,
          'X-Native-App': isNativeApp,
          'X-Simple-Job': true,
          ...(options.headers ?? {}),
        },
        body: JSON.stringify(body),
      });
    },
    postFile: async (
      path: string,
      fileData: {
        file: File | MobileFilePayload;
        fileName: string;
        editConfig?: ImageEditConfig;
      },
      isNativeApp = false
    ) => {
      const formData = new FormData();
      if (isNativeApp) {
        // mobile formdata upload
        const file = fileData?.file as MobileFilePayload;
        const localUri = file.uri;
        const filename = file.originalName.split('/').pop() || '';

        // Infer the type of the image
        const match = /\.(\w+)$/.exec(filename);

        let type = '';
        if (match?.[1] === 'pdf') {
          type = 'application/pdf';
        } else {
          type = match
            ? `image/${match[1] === 'jpg' ? 'jpeg' : match[1]}`
            : 'image';
        }
        formData.append('file', {
          uri: localUri,
          name: file.fileName || file.originalName,
          type,
        } as any);
      } else {
        formData.append('file', fileData.file as File, fileData.fileName);
      }
      if (fileData.editConfig) {
        formData.append('editConfig', JSON.stringify(fileData.editConfig));
      }

      return await request(path, {
        method: 'POST',
        enctype: 'multipart/form-data',
        headers: {
          Authorization: authHeader ? await authHeader() : undefined,
        },
        body: formData,
      });
    },
    putForm: async (path: string, formData: any) => {
      return await request(path, {
        method: 'PUT',
        headers: {
          Accept: 'application/json, text/plain, */*',
          Authorization: authHeader ? await authHeader() : undefined,
          'X-Native-App': isNativeApp,
          'X-Simple-Job': true,
        },
        body: formData,
      });
    },
  };
};

export const passResponseErrorMessagesToErrorCallback = async (
  response: Response,
  errorCallback?: (errorMessages: string[]) => void
) => {
  if (response.ok) {
    return;
  }
  if (errorCallback) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const result = await response.json();
      if (result.errorMessages?.length) {
        errorCallback(result.errorMessages);
      } else {
        errorCallback(['Something went wrong']);
      }
    } else {
      errorCallback(['Something went wrong']);
    }
  }
};

type MobileFilePayload = {
  fileName: string;
  fileSize: number;
  height: number;
  isVertical: boolean;
  latitude: number;
  longitude: number;
  origURL: string;
  originalName: string;
  timestamp: string;
  type: string;
  uri: string;
  width: number;
};
