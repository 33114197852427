import Card, { CardSizeVariant } from '../plCard/Card';
import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import SparkLine from '../sparkLine/SparkLine';
import { DataPeriod } from '@payaca/types/analyticsTypes';
import ValueChangeBadge from '../valueChangeBadge/ValueChangeBadge';
import { formatPercentage } from '@payaca/helpers/financeHelper';
import UntitledIcon, { TIconName } from '@payaca/untitled-icons';

type Props = {
  periodLengthInDays: number;
  percentageChange: number | 'Infinity' | '-Infinity';
  sparkLineData?: number[];
  currentPeriod?: DataPeriod<number>;
  title: string;
  iconName?: TIconName;
  CustomLabelledValue?: FunctionComponent<{ value: number }>;
};

const DataIndicatorCard: FunctionComponent<Props> = ({
  periodLengthInDays,
  percentageChange,
  sparkLineData,
  currentPeriod,
  title,
  iconName,
  CustomLabelledValue,
}): JSX.Element | null => {
  // sparkLineData exists and has at least one non-zero value
  const hasSparklineData = sparkLineData?.some((value) => !!value);

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <div className="flex">
          {iconName && (
            <UntitledIcon
              name={iconName}
              className="mr-2 h-6 w-6"
              vectorEffect={'non-scaling-stroke'}
            />
          )}
          <div className="rows-2">
            <h3 className="mb-0 text-lg font-semibold dark:text-white">
              {title}
            </h3>
            <span className="text-2xs text-gray-500 dark:text-gray-500">
              in the last {periodLengthInDays} days
            </span>
          </div>
        </div>
        <hr className="mb-3 mt-3"></hr>
        <div className="flex flex-row">
          <div className="flex grow flex-wrap gap-x-8 gap-y-2">
            {currentPeriod && (
              <>
                {CustomLabelledValue ? (
                  <CustomLabelledValue value={currentPeriod.value} />
                ) : (
                  <LabelledValue>
                    <ValueLabel>Value</ValueLabel>
                    <Value>{currentPeriod?.value}</Value>
                  </LabelledValue>
                )}
              </>
            )}
          </div>
          {!!hasSparklineData && (
            <div className="ml-8 min-w-[10rem] shrink">
              <SparkLine
                data={sparkLineData || []}
                isSmooth={true}
                hasArea={true}
              />
            </div>
          )}
        </div>
        <div className="mt-8 flex items-center gap-2">
          {percentageChange == 'Infinity' || percentageChange == '-Infinity' ? (
            <>
              <span className="text-gray-500">
                no comparison data available
              </span>
            </>
          ) : (
            <>
              <ValueChangeBadge
                valueChange={percentageChange}
                valueChangeFormatter={(valueChange) => {
                  if (valueChange == 'Infinity' || valueChange == '-Infinity') {
                    return '-%';
                  }

                  return `${
                    typeof valueChange == 'number'
                      ? formatPercentage(valueChange * 100, 1)
                      : valueChange
                  }%`;
                }}
              />
              <span>vs previous {periodLengthInDays} days</span>
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

const LabelledValue: FunctionComponent<
  PropsWithChildren<Record<never, any>>
> = ({ children }): JSX.Element | null => {
  return <div className="flex flex-col">{children}</div>;
};

const ValueLabel: FunctionComponent<PropsWithChildren<Record<never, any>>> = ({
  children,
}): JSX.Element | null => {
  return <span className="text-base">{children}</span>;
};

const Value: FunctionComponent<PropsWithChildren<Record<never, any>>> = ({
  children,
}): JSX.Element | null => {
  return <span className="text-2xl font-semibold">{children}</span>;
};

export default Object.assign(DataIndicatorCard, {
  LabelledValue,
  ValueLabel,
  Value,
});
