import { useQueryClient, UseMutationOptions } from '@tanstack/react-query';

import useAddNewSiteAddressToProject from '@/api/mutations/project/useAddNewSiteAddressToProject';
import projectKeys from '@/api/queries/project/keyFactory';
import {
  AddNewSiteAddressToProjectMutation,
  AddNewSiteAddressToProjectMutationVariables,
  ProjectQuery,
} from '@/gql/graphql';
import complianceKeys from '@/api/queries/compliance/keyFactory';

const useOptimisticAddNewSiteAddressToProject = (
  projectId: number,
  options?: UseMutationOptions<
    AddNewSiteAddressToProjectMutation,
    unknown,
    {
      unlinkSiteAddressFromProjectInput?: AddNewSiteAddressToProjectMutationVariables['unlinkSiteAddressFromProjectInput'];
      addNewSiteAddressToProjectInput: AddNewSiteAddressToProjectMutationVariables['addNewSiteAddressToProjectInput'];
    }
  >
) => {
  const queryClient = useQueryClient();

  return useAddNewSiteAddressToProject({
    ...options,
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: projectKeys.overview(projectId),
      });

      // Snapshot the previous value
      const previousProjectOverview = queryClient.getQueryData(
        projectKeys.overview(projectId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData<ProjectQuery>(
        projectKeys.overview(projectId),
        (old) => {
          if (!old) return;

          const newAddress = {
            ...variables.addNewSiteAddressToProjectInput.address,
            id: 'temp-id',
            fullLocalAddress: [
              variables.addNewSiteAddressToProjectInput.address.line1,
              variables.addNewSiteAddressToProjectInput.address.line2,
              variables.addNewSiteAddressToProjectInput.address.city,
              variables.addNewSiteAddressToProjectInput.address.postalCode,
              variables.addNewSiteAddressToProjectInput.address.country,
            ].join(', '),
            contacts: [],
          };

          return {
            ...old,
            project: {
              ...old.project,
              addresses: {
                ...old.project.addresses,
                site: [
                  ...old.project.addresses.site.filter(
                    (site) =>
                      site.id !==
                      variables.unlinkSiteAddressFromProjectInput?.addressId
                  ),
                  newAddress,
                ],
              },
              customer: old.project.customer
                ? {
                    ...old.project.customer,
                    addresses: [
                      ...(old.project.customer?.addresses || []),
                      newAddress,
                    ],
                  }
                : undefined,
            },
          };
        }
      );

      options?.onMutate?.(variables);

      // Return a context object with the snapshotted value
      return { previousProjectOverview };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        projectKeys.overview(projectId),
        // @ts-ignore
        context.previousProjectOverview
      );

      options?.onError?.(err, newTodo, context);
    },
    // Always refetch after error or success:
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.overview(projectId),
      });
      queryClient.invalidateQueries({
        queryKey: complianceKeys.formPrefill(projectId),
      });

      options?.onSettled?.(...args);
    },
  });
};

export default useOptimisticAddNewSiteAddressToProject;
