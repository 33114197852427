import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import ValidationFeedbackBlock from '@payaca/components/validationFeedbackBlock/ValidationFeedbackBlock';
import { getArrayMustNotBeEmptyFieldValidator } from '@payaca/helpers/fieldValidationHelper';

import { ConfirmPurchaseOrderRequestData } from '@payaca/store/materialsList/materialsListTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { useDispatch } from 'react-redux';
import './ConfirmPurchaseOrderControl.sass';
import PurchaseOrderMaterialPurchaseRecordsControl from './PurchaseOrderMaterialPurchaseRecordsControl';
import { requestConfirmPurchaseOrder } from '@payaca/store/materialsList/materialsListActions';
import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { HydratedPurchaseOrder } from '@payaca/types/materialsListTypes';

const fieldValidators = {
  materialPurchaseRecords: [
    getArrayMustNotBeEmptyFieldValidator({
      customErrorMessage: 'You must mark at least one material as purchased',
    }),
  ],
};

type Props = {
  purchaseOrder: HydratedPurchaseOrder;
  onConfirmPurchaseOrderSuccess?: () => void;
};
const ConfirmPurchaseOrderControl: FC<Props> = ({
  purchaseOrder,
  onConfirmPurchaseOrderSuccess,
}: PropsWithChildren<Props>): JSX.Element => {
  const dispatch = useDispatch();
  const [showValidationFeedback, setShowValidationFeedback] = useState(false);
  const [submissionError, setSubmissionError] = useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);

  const materialPurchaseIntents = purchaseOrder.materialPurchaseIntents;

  const initialFormState = useMemo(() => {
    return {
      id: purchaseOrder.id,
      materialPurchaseRecords: materialPurchaseIntents.map((mpi) => {
        return {
          materialsListMaterialId: mpi.materialsListMaterialId,
          materialQuantity: mpi.materialQuantity,
          price: mpi.predictedUnitPriceExcludingTax,
          taxRateId: mpi.predictedTaxRateId,
        };
      }),
    };
    // Do not need any other dependencies here; materialPurchaseIntents and supplierMaterials will not change, and including them as deps causes unwanted re-renders
  }, [purchaseOrder.id]);

  const onSubmit = useCallback(
    (formState: ConfirmPurchaseOrderRequestData) => {
      setIsProcessing(true);
      dispatch(
        requestConfirmPurchaseOrder(
          formState,
          () => {
            setIsProcessing(false);
            onConfirmPurchaseOrderSuccess && onConfirmPurchaseOrderSuccess();
          },
          () => {
            setIsProcessing(false);
            setSubmissionError(
              'Something went wrong confirming this purchase order.'
            );
          }
        )
      );
    },
    [onConfirmPurchaseOrderSuccess]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <div className="form-body">
            <p>
              Select all materials which have been purchased from the supplier
            </p>
            <PurchaseOrderMaterialPurchaseRecordsControl
              materialPurchaseRecords={formState.materialPurchaseRecords}
              materialPurchaseIntents={purchaseOrder.materialPurchaseIntents}
              onChange={(materialPurchaseRecords) => {
                onFieldChange({
                  materialPurchaseRecords: materialPurchaseRecords,
                });
              }}
            />
            {showValidationFeedback && (
              <ValidationFeedbackBlock
                validationResults={[validationState.materialPurchaseRecords]}
                isDismissable={true}
                onDismissed={() => setShowValidationFeedback(false)}
              />
            )}
            {submissionError && (
              <FeedbackBlock
                isDismissable={true}
                onDismissed={() => setSubmissionError('')}
                feedbackLevel={FeedbackLevel.ERROR}
              >
                <span>{submissionError}</span>
              </FeedbackBlock>
            )}
          </div>
          <div className="buttons-container">
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isProcessing={isProcessing}
              onClick={() =>
                isValid
                  ? !isProcessing &&
                    onSubmit(formState as ConfirmPurchaseOrderRequestData)
                  : setShowValidationFeedback(true)
              }
            >
              Confirm
            </Button>
          </div>
        </>
      );
    },
    [
      purchaseOrder.id,
      showValidationFeedback,
      isProcessing,
      onSubmit,
      submissionError,
    ]
  );

  return (
    <div className="confirm-purchase-order-control">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
      />
    </div>
  );
};

export default ConfirmPurchaseOrderControl;
