import React, { FC, PropsWithChildren } from 'react';

export interface IProps {
  fullLocalAddress?: string;
}

const GoogleMapAnchor: FC<PropsWithChildren<IProps>> = (props) => {
  const { fullLocalAddress, children } = props;

  if (!fullLocalAddress) {
    return <>{children}</>;
  }

  return (
    <a
      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        fullLocalAddress
      )}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default GoogleMapAnchor;
