export interface State {
  loaded: boolean;
  currentTab: any;
  banner: any;
  dismissMobileMode: string | boolean;
  modal: any;
  showNavbar: boolean;
  maintenanceSchedule: any;
  hideMaintenanceBanner: boolean;
  lastReadFutureMaintenaceMessageDownTimeAt: any;
  navigationSidebarIsHidden: boolean;
  navigationSidebarIsCollapsed: boolean;
  navigationSidebarAutoExpandedAfterDeploy: boolean;

  videoOverlay: {
    src: string;
    onClose?: () => void;
  } | null;

  hasHiddenWelcomeVideo: boolean;
}
export enum ActionType {
  START = 'app.start',
  // this can be removed soon after it gets deployed
  SET_NAVIGATION_SIDEBAR_AUTO_EXPANDED_AFTER_DEPLOY = 'app.setNavigationSidebarAutoExpandedAfterDelpoy',
  SHOW_NAVIGATION_SIDEBAR = 'app.showNavigationSidebar',
  HIDE_NAVIGATION_SIDEBAR = 'app.hideNavigationSidebar',
  EXPAND_NAVIGATION_SIDEBAR = 'app.expandNavigationSidebar',
  COLLAPSE_NAVIGATION_SIDEBAR = 'app.collapseNavigationSidebar',
  STORE_PREVIOUS_PATHNAME = 'app.storePreviousPathname',
  STORE_PATHNAME = 'app.storePathname',
  TOGGLE_SHOW_NAVBAR = 'app.toggleShowNavbar',
  HIDE_NAVBAR = 'app.hideNavbar',
  LOADED = 'app.loaded',
  SHOW_MODAL = 'app.showModal',
  HIDE_MODAL = 'app.hideModal',
  SHOW_BANNER = 'app.showBanner',
  HIDE_BANNER = 'app.hideBanner',
  DISMISS_MOBILE_MODE = 'app.dismissMobileMode',
  HIDE_MAINTENANCE_BANNER = 'app.hideMaintenanceBanner',
  REQUEST_GET_MAINTENANCE = 'app.requestGetMaintenance',
  GET_MAINTENANCE_SUCCESS = 'app.getMaintenanceSuccess',
  GET_MAINTENANCE_FAILURE = 'app.getMaintenanceFailure',
  SET_LAST_READ_FUTURE_MAINTENACE_MESSAGE_DOWN_TIME_AT = 'app.setLastReadFutureMaintenaceMessageDownTimeAt',
  VALID_USER_INVITE_TOKEN = 'app.validUserInviteToken',
  REGISTER_INVITED_USER = 'app.registerInvitedUser',
  RESET_PASSWORD = 'app.resetPassword',
  VALID_RESET_TOKEN = 'app.validResetToken',
  SEND_PASSWORD_RESET_EMAIL = 'app.sendPasswordResetEmail',

  TOGGLE_VIDEO_OVERLAY = 'app.toggleVideoOverlay',

  HIDE_WELCOME_VIDEO = 'app.hideWelcomeVideo',

  CLEAR_APP = 'app.clearApp',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}
