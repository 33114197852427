import { AccountRegions } from './accountTypes';
import { Tag, TaggableEntityType } from './tagTypes';

export enum MetricType {
  TAG_USAGE_SUMMARY = 'tagUsageSummary',
  DEAL_VALUE = 'dealValue',
  TIME_TO_ACCEPTED = 'averageTimeToAccepted',
}

export type RangeData = {
  min: number;
  q1: number;
  q2: number;
  q3: number;
  max: number;
  minLim: number;
  maxLim: number;
  outliers?: number[];
};

export type DataType = RangeData | number;

type Datapoint<T extends DataType> = {
  tag: Pick<Tag, 'tagText' | 'tagColour'>;
  data: T;
};

export type RangeDatapoint = Datapoint<RangeData>;

export type ValueDatapoint = Datapoint<number>;

export type TagMetricAnalyticsData<T extends DataType> = {
  entityType: TaggableEntityType;
  metricType: MetricType;
  datapoints: Datapoint<T>[];
};

export type MetricConfiguration = {
  name: string;
  description?: string;
  axisTickFormatter?: (value: number, region?: AccountRegions) => string;
  valueFormatter?: (value: number, region?: AccountRegions) => string;
  isDiscrete?: boolean;
};

export type TagMetricConfigurations = {
  [key in TaggableEntityType]?: {
    [key in MetricType]?: MetricConfiguration;
  };
};
