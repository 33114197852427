import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ActionRequiredPaymentOutstandingInvoice } from '@payaca/types/dashboardTypes';
import * as dashboardActions from '@payaca/store/dashboard/dashboardActions';
import moment from 'moment-timezone';
import DashboardActionRequiredCarousel from '../dashboardActionRequiredCarousel/DashboardActionRequiredCarousel';
import DashboardJobActionRequiredItem from '../dashboardJobActionRequiredItem/DashboardJobActionRequiredItem';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { useSelector } from '../../../api/state';

const ActionRequiredPaymentOutstandingInvoicesCarousel: FC =
  (): JSX.Element | null => {
    const dispatch = useDispatch();
    const history = useHistory();

    const account = useSelector(
      (state: any) => state.users.myProfile.accounts[0]
    );

    const {
      actionRequiredPaymentOutstandingInvoices,
      isGettingActionRequiredPaymentOutstandingInvoices,
    } = useSelector((state) => state.dashboard);

    useEffect(() => {
      dispatch(
        dashboardActions.requestGetActionRequiredPaymentOutstandingInvoices()
      );
    }, [dispatch]);

    const actionRequiredItems = useMemo(() => {
      const nowMoment = moment();
      return actionRequiredPaymentOutstandingInvoices?.map(
        (
          actionRequiredPaymentOutstandingInvoice: ActionRequiredPaymentOutstandingInvoice,
          index: number
        ) => {
          const paymentDueAtMoment = moment(
            actionRequiredPaymentOutstandingInvoice.paymentDueAt
          );
          const daysBetween = paymentDueAtMoment.diff(nowMoment, 'days');
          const isOverdue = paymentDueAtMoment.isBefore(nowMoment);

          return (
            <DashboardJobActionRequiredItem
              tooltipContent={
                daysBetween > 0
                  ? `Due in ${daysBetween} days`
                  : `Due ${-daysBetween} days ago`
              }
              isFlagged={isOverdue}
              key={index}
              onClick={() =>
                history.push(
                  `deals/${actionRequiredPaymentOutstandingInvoice.dealId}`
                )
              }
              reference={`Invoice ${actionRequiredPaymentOutstandingInvoice.invoiceReference}`}
              value={currencyPrice(
                actionRequiredPaymentOutstandingInvoice.outstandingPaymentValue,
                account.region,
                false
              )}
              customerName={
                actionRequiredPaymentOutstandingInvoice.customerName
              }
            />
          );
        }
      );
    }, [actionRequiredPaymentOutstandingInvoices, history, account]);

    const title = useMemo(() => {
      return `Awaiting payment${
        isGettingActionRequiredPaymentOutstandingInvoices
          ? ''
          : ` (${
              actionRequiredItems?.length === 20
                ? '20+'
                : actionRequiredItems?.length || 0
            })`
      }`;
    }, [
      actionRequiredItems,
      isGettingActionRequiredPaymentOutstandingInvoices,
    ]);

    return (
      <div className="action-required-payment-outstanding-jobs-carousel">
        <DashboardActionRequiredCarousel
          actionRequiredItems={
            isGettingActionRequiredPaymentOutstandingInvoices
              ? undefined
              : actionRequiredItems
          }
          title={title}
          description="Your customers owe you money against these invoices"
          isLoading={isGettingActionRequiredPaymentOutstandingInvoices}
        />
      </div>
    );
  };

export default ActionRequiredPaymentOutstandingInvoicesCarousel;
