import React, { FC } from 'react';

import ProgressIndicator from '@payaca/components/progressIndicator/ProgressIndicator';

import { ChecklistItem } from '@payaca/types/taskTypes';

import './ChecklistItemsProgressIndicator.sass';

type Props = {
  checklistItems: ChecklistItem[];
};
const ChecklistItemsProgressIndicator: FC<Props> = ({
  checklistItems,
}: Props): JSX.Element => {
  return (
    <div className="checklist-items-progress-indicator">
      <div className="checklist-items-progress-indicator-inner">
        {checklistItems.map((checklistItem, i) => {
          return (
            <ProgressIndicator
              key={`checklist-item-indicator-${i}`}
              className={checklistItem.completedAt ? 'completed' : ''}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ChecklistItemsProgressIndicator;
