import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import JobLineItemGroupControl from '../jobLineItemGroupControl/JobLineItemGroupControl';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import SelectItemGroupModal from '../selectItemGroupModal/SelectItemGroupModal';
import EditJobSection from '../editJob/EditJobSection';
import SelectItemModal from '../selectItemModal/SelectItemModal';

import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';

import {
  getJobContent as getJobContentFromState,
  getJobLineItemGroup,
} from '@/utils/stateAccessors';

import {
  JobContent,
  JobLineItemBase,
  JobLineItemGroup,
} from '@payaca/types/jobContentTypes';
import { UpdateJobRequestData } from '@payaca/types/jobRequestTypes';

import { sortByKey } from '@payaca/utilities/sortable';

import { getEditJobBaseLocationPath } from '../../../helpers/jobHelper';

import { useSelector } from '@/api/state';

import './JobLineItemGroupsControl.sass';
import DealMaterialsListDrawer from '../dealMaterialsListDrawer/DealMaterialsListDrawer';

type Props = {
  jobIsInvoice: boolean;
  jobContentId: number;
  updateJobRequestData?: UpdateJobRequestData;
  updateJob: (changes: any) => void;
};

const JobLineItemGroupsControl: FC<Props> = ({
  jobContentId,
  jobIsInvoice,
  updateJobRequestData,
  updateJob,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [showMaterialsListDrawer, setShowMaterialsListDrawer] = useState(false);

  const [
    showLineItemSearchModalForGroupId,
    setShowLineItemSearchModalForGroupId,
  ] = useState<null | number>();
  const [isCreatingJobLineItem, setIsCreatingJobLineItem] = useState(false);
  const [isAddingExistingJobLineItem, setIsAddingExistingJobLineItem] =
    useState(false);

  const jobContent: JobContent | undefined = useSelector((state) =>
    getJobContentFromState(state, jobContentId)
  );

  const isCreatingJobLineItemGroup = useSelector((state) => {
    return state.jobContent.isCreatingJobLineItemGroup;
  });

  const isAddingLineItemGroupToJobContent = useSelector((state) => {
    return state.jobContent.isAddingLineItemGroupToJobContent;
  });

  const [showLineItemGroupModal, setShowLineItemGroupModal] =
    useState<boolean>(false);

  const [lineItemGroupToAdd, setLineItemGroupToAdd] = useState<number | null>(
    null
  );

  const getJobLineItems = useCallback(() => {
    dispatch(
      jobContentActions.requestGetJobLineItemsForJobContent(jobContentId)
    );
  }, [dispatch, jobContentId]);

  const getJobLineItemGroups = useCallback(() => {
    dispatch(
      jobContentActions.requestGetJobLineItemGroupsForJobContent(jobContentId)
    );
  }, [dispatch, jobContentId]);

  const getJobContent = useCallback(() => {
    dispatch(jobContentActions.requestGetJobContent(jobContentId));
  }, [dispatch, jobContentId]);

  const createJobLineItemGroup = useCallback(() => {
    dispatch(
      jobContentActions.requestCreateJobLineItemGroup(
        { jobContentId: jobContentId },
        () => {
          getJobLineItemGroups();
          getJobContent();
        }
      )
    );
  }, [dispatch, getJobContent, getJobLineItemGroups, jobContentId]);

  const addLineItemGroupToJobContent = useCallback(
    (lineItemGroupId: number) => {
      if (!jobContentId) return;
      dispatch(
        jobContentActions.requestAddLineItemGroupToJobContent(
          jobContentId,
          lineItemGroupId,
          () => {
            dispatch(
              jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
                jobContentId
              )
            );
          }
        )
      );
    },
    [jobContentId, getJobLineItems]
  );

  const canDeleteItemGroup = useMemo(() => {
    if (!jobContent) return false;
    const jobHasLineItems = !!jobContent?.jobLineItemIds.length;
    const jobHasMultipleItemGroups = jobContent?.jobLineItemGroupIds.length > 1;

    return jobHasLineItems || jobHasMultipleItemGroups;
  }, [jobContent]);

  useEffect(() => {
    // if the last remaining group is deleted, we'll add a new empty one
    if (
      jobContent?.jobLineItemGroupIds &&
      jobContent.jobLineItemGroupIds.length === 0
    ) {
      createJobLineItemGroup();
    }
  }, [jobContent]);

  const createJobLineItem = useCallback(
    (
      jobLineItemGroupId: number,
      lineItemId: number | null,
      initialData?: Partial<JobLineItemBase>,
      callback?: (jobLineItemId: number) => void
    ) => {
      if (lineItemId) {
        setIsAddingExistingJobLineItem(true);
      } else {
        setIsCreatingJobLineItem(true);
      }

      dispatch(
        jobContentActions.requestCreateJobLineItem(
          {
            ...initialData,
            jobContentId,
            jobLineItemGroupId,
            lineItemId,
          },
          (jobLineItemId: number) => {
            setIsCreatingJobLineItem(false);
            setIsAddingExistingJobLineItem(false);
            dispatch(
              jobContentActions.requestGetJobContentWithJobGroupsAndJobLineItems(
                jobContentId
              )
            );
            callback && callback(jobLineItemId);
          }
        )
      );
    },
    [jobContentId]
  );

  const goToEditJobLineItemPage = (jobLineItemId: number) => {
    history.push(
      `${getEditJobBaseLocationPath(location.pathname)}/items/${jobLineItemId}`
    );
  };

  const createJobLineItemAndGoToEditJobLineItemPage = (
    jobLineItemGroupId: number,
    lineItemId: number | null,
    initialItemData?: Partial<JobLineItemBase>
  ) => {
    if (jobLineItemGroupId) {
      // create job line item
      createJobLineItem(
        jobLineItemGroupId,
        lineItemId,
        initialItemData,
        (jobLineItemId: number) => {
          if (!lineItemId) {
            // go to edit item page if new item not from li
            goToEditJobLineItemPage(jobLineItemId);
          }
        }
      );
    }
  };

  const jobLineItemGroupIds = jobContent?.jobLineItemGroupIds ?? [];

  const jobLineItemGroups = useSelector((state) =>
    jobLineItemGroupIds.map((id) => getJobLineItemGroup(state, id))
  ).filter(Boolean) as Array<JobLineItemGroup>;

  const sortedJobLineItemGroups = jobLineItemGroups.sort(
    sortByKey('positionIndex')
  );

  const jobLineItemGroupElements = useMemo(() => {
    return sortedJobLineItemGroups.map(({ id: jobLineItemGroupId }, index) => (
      <ContentPanel
        key={`job-line-item-group-control-${index}`}
        hasBoxShadow={false}
      >
        <JobLineItemGroupControl
          jobContentId={jobContentId}
          canDeleteItemGroup={canDeleteItemGroup}
          jobIsInvoice={jobIsInvoice}
          jobLineItemGroupId={jobLineItemGroupId}
          handleOpenCreateJobLineItemModal={goToEditJobLineItemPage}
          handleOpenAdvancedSearchLineItemModal={() =>
            setShowLineItemSearchModalForGroupId(jobLineItemGroupId)
          }
          handleCreateJobLineItemAndOpenCreateJobLineItemModal={(
            lineItemId: number | null,
            initialItemData?: Partial<JobLineItemBase>
          ) =>
            createJobLineItemAndGoToEditJobLineItemPage(
              jobLineItemGroupId,
              lineItemId,
              initialItemData
            )
          }
        />
      </ContentPanel>
    ));
  }, [
    jobContent?.jobLineItemGroupIds,
    jobContentId,
    canDeleteItemGroup,
    jobIsInvoice,
    goToEditJobLineItemPage,
  ]);

  return (
    <EditJobSection
      title={
        <div className="title-wrapper">
          <h2>Items</h2>
          {!jobIsInvoice && (
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              onClick={() => setShowMaterialsListDrawer(true)}
            >
              Show Materials required
            </Button>
          )}
        </div>
      }
      description={
        <p>
          Add an existing item or{' '}
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => {
              const jobLineItemGroupId = jobContent?.jobLineItemGroupIds[0];
              if (jobLineItemGroupId && !isCreatingJobLineItem) {
                createJobLineItemAndGoToEditJobLineItemPage(
                  jobLineItemGroupId,
                  null
                );
              }
            }}
            isDisabled={
              !jobContent?.jobLineItemGroupIds[0] || isCreatingJobLineItem
            }
          >
            create a new Item
          </Button>
        </p>
      }
      className="job-line-item-groups-control"
    >
      <div className="job-line-item-group-controls-container">
        {jobLineItemGroupElements}
      </div>
      <div className="create-or-add-existing-container line-item-group">
        <Button
          styleVariant={ButtonStyleVariant.ANCHOR}
          isProcessing={isAddingLineItemGroupToJobContent}
          onClick={() => setShowLineItemGroupModal(true)}
        >
          Search existing groups
        </Button>
        <span>or</span>
        <Button
          styleVariant={ButtonStyleVariant.ANCHOR}
          isProcessing={isCreatingJobLineItemGroup}
          onClick={createJobLineItemGroup}
        >
          Create new group
        </Button>
      </div>
      <SelectItemGroupModal
        isOpen={showLineItemGroupModal}
        onClose={() => setShowLineItemGroupModal(false)}
        onSelectItemGroup={(lineItemGroup: any) => {
          if (lineItemGroupToAdd) {
            // don't allow selecting a group if already adding one
            return;
          }

          addLineItemGroupToJobContent(lineItemGroup.id);
          setShowLineItemGroupModal(false);
        }}
      />

      {/* search item modal */}
      <SelectItemModal
        isOpen={!!showLineItemSearchModalForGroupId}
        onClose={() => setShowLineItemSearchModalForGroupId(null)}
        onSelectItem={(lineItemId: number) => {
          if (
            !isCreatingJobLineItem &&
            !isAddingExistingJobLineItem &&
            showLineItemSearchModalForGroupId
          ) {
            // close select item modal
            setShowLineItemSearchModalForGroupId(null);
            // create job line item and open edit modal
            createJobLineItemAndGoToEditJobLineItemPage(
              showLineItemSearchModalForGroupId,
              lineItemId
            );
          }
        }}
        disabledItemIds={jobContent?.lineItemIds || []}
      />
      {jobContent?.dealId && (
        <DealMaterialsListDrawer
          isOpen={showMaterialsListDrawer}
          onClose={() => setShowMaterialsListDrawer(false)}
          dealId={jobContent.dealId}
        />
      )}
    </EditJobSection>
  );
};
export default JobLineItemGroupsControl;
