import React, { useCallback, useMemo, useState } from 'react';

import EntityCard from '@payaca/components/entityCard/EntityCard';
import { Calendar } from 'react-iconly';
import TasksIcon from '../../components/navigationSidebar/icon--tasks.svg?react';
import './ServicePlanPeriodCommitmentEntityCard.sass';
import { ServicePlanPeriodCommitment } from '@payaca/types/service-plans';
import { ServicePlanPeriodCommitmentStatusBadge } from '../servicePlanPeriodCommitmentStatusBadge/ServicePlanPeriodCommitmentStatusBadge';
import { useDispatch } from 'react-redux';
import {
  deleteServicePlanPeriodCommitment,
  updateServicePlanPeriodCommitment,
} from '@payaca/store/servicePlans/servicePlansActions';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';

const ServicePlanPeriodCommitmentEntityCard = ({
  children,
  commitmentId,
  onClick,
  entityType,
  status,
  className,
  onCommitmentUpdate,
}: React.PropsWithChildren<{
  children: React.ReactNode;
  commitmentId: string;
  entityType: ServicePlanPeriodCommitment['entityType'];
  status: ServicePlanPeriodCommitment['status'];
  className?: string;
  onClick?: () => void;
  onCommitmentUpdate?: () => void;
}>) => {
  const dispatch = useDispatch();
  const [isMarkingAsFulfilled, setIsMarkingAsFulfilled] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const handleMarkAsFulfilled = useCallback(() => {
    setIsMarkingAsFulfilled(true);
    dispatch(
      updateServicePlanPeriodCommitment.request({
        servicePlanPeriodCommitment: {
          publicId: commitmentId,
          isFulfilledOverride: true,
        },
        callback: () => {
          setIsMarkingAsFulfilled(false);
          onCommitmentUpdate?.();
        },
        onErrorCallback: () => setIsMarkingAsFulfilled(false),
      })
    );
  }, [onCommitmentUpdate, commitmentId]);

  const handleDelete = useCallback(() => {
    setIsDeleting(true);
    dispatch(
      deleteServicePlanPeriodCommitment.request({
        servicePlanPeriodCommitmentId: commitmentId,
        callback: () => {
          setIsDeleting(false);
          onCommitmentUpdate?.();
          setShowConfirmDeleteModal(false);
        },
        onErrorCallback: () => setIsDeleting(false),
      })
    );
  }, [onCommitmentUpdate, setIsDeleting]);

  const quickActions = useMemo(() => {
    const qa = [
      {
        actionName: 'Delete',
        actionBehaviour: () => setShowConfirmDeleteModal(true),
        isActionProcessing: false,
      },
    ];

    if (status === 'arranged') {
      qa.push({
        actionName: 'Mark as complete',
        actionBehaviour: handleMarkAsFulfilled,
        isActionProcessing: isMarkingAsFulfilled,
      });
    }

    return qa;
  }, [isMarkingAsFulfilled, handleMarkAsFulfilled]);

  return (
    <>
      <Modal
        isOpen={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        title="Are you sure you want to delete this commitment?"
        actions={
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={handleDelete}
            isProcessing={isDeleting}
          >
            Confirm
          </Button>
        }
      ></Modal>
      <EntityCard
        quickActionsConfig={{
          recordId: commitmentId,
          quickActions: quickActions,
        }}
        onClick={onClick}
        className={`service-plan-period-commitment-entity-card ${
          className || ''
        }`}
      >
        {entityType === 'scheduledEvent' && <Calendar size="medium" />}
        {entityType === 'task' && <TasksIcon width={20} />}
        <div className="content">{children}</div>
        <ServicePlanPeriodCommitmentStatusBadge status={status} />
      </EntityCard>
    </>
  );
};

export default ServicePlanPeriodCommitmentEntityCard;
