import {
  getAllowEmptyValidator,
  getEmailFieldValidator,
  getIsRequiredFieldValidator,
  getMustBeTruthyValidator,
  getRegexMatchFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { FormElement } from '@payaca/types/formElementTypes';

export const buildValidation = (
  formElements: FormElement[],
  validationObj: any,
  path: any[] = [],
  formData: any
) => {
  formElements.forEach((formElement: FormElement) => {
    if (formElement.children) {
      if (formElement.isOptional && !formData?.[formElement.id]?.length) {
        return validationObj;
      }

      buildValidation(
        formElement.children,
        validationObj,
        formElement.id
          ? [
              ...path,
              formElement.id,
              formElement.type === 'multi-items' ? 'X' : undefined,
            ].filter((i) => !!i)
          : path,
        formData
      );
    }

    if (!formElement.id) {
      return validationObj;
    }

    let validators: any = [];
    if (formElement.validation) {
      validators = formElement.validation
        .map((validator: string) => {
          switch (validator) {
            case 'email':
              return getEmailFieldValidator({
                customErrorMessage: 'This must be a valid e-mail address',
              });
            case 'phone':
              return getRegexMatchFieldValidator(/^[0-9+() -]{3,14}$/, {
                customErrorMessage: 'This must be a valid contact number',
              });
            default:
              return null;
          }
        })
        .filter((fn: any) => !!fn);
    }

    if (formElement.isOptional) {
      validators = validators.map((fn: any) => getAllowEmptyValidator(fn));
    } else {
      // not optional, so it must be "required" (default)
      validators.push(
        formElement.type === 'checkbox'
          ? getMustBeTruthyValidator()
          : getIsRequiredFieldValidator()
      );
    }

    if (path[1] === 'X') {
      for (let i = 0; i < (formData?.[path[0]]?.length || 1); i++) {
        validationObj[
          [
            path[0],
            i,
            ...path.slice(2).filter((i) => !!i),
            formElement.id,
          ].join('.')
        ] = validators;
      }
      return validationObj;
    }
    return (validationObj[[...path, formElement.id].join('.')] = validators);
  });

  return validationObj;
};
