import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import SwitchField from '@payaca/components/switchField/SwitchField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';

import './DefaultAutomationControl.sass';
import {
  Automation,
  AutomationActionTypes,
  DefaultAutomationDefinition,
  EmailNotificationActionConfig,
  PublicDefaultAutomationDefinition,
  PublicHydratedDefaultAutomationDefinition,
  SMSActionConfig,
  UpdateDefaultAutomationData,
} from '@payaca/types/automationTypes';
import {
  FieldValidationResult,
  FieldValidator,
} from '@payaca/types/fieldValidationTypes';
import { useFormState } from '@/hooks/useFormState';
import { AutomationDataAccessType } from '@payaca/types/automationDataAccessTypes';
import AutomationConditionValueField from '../automationConditionValueField/AutomationConditionValueField';
import { useDispatch } from 'react-redux';
import { updateDefaultAutomation } from '@payaca/store/automations/automationActions';
import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import AutomationActionConfigFieldset from '../automationActionConfigFieldset/AutomationActionConfigFieldset';

import {
  getFieldValidatorsForDefaultAutomation,
  isAutomationActionConfigValid,
} from '@payaca/helpers/automationValidationHelper';

const basicEmailNotifictionActionConfig: EmailNotificationActionConfig = {
  sendToEmail: AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_EMAILADDRESS,
  emailBody: '',
  emailSubject: '',
};

const basicSmsActionConfig: SMSActionConfig = {
  sendToNumber:
    AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_TELEPHONENUMBER,
  messageBody: '',
};

type Props = {
  defaultAutomationDefinition: PublicHydratedDefaultAutomationDefinition<AutomationActionTypes>;
  onUpdateAutomationSuccess?: (
    automation: Automation<AutomationActionTypes>
  ) => void;
};

const DefaultAutomationControl: FC<Props> = ({
  defaultAutomationDefinition,
  onUpdateAutomationSuccess,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { automation, ...automationDefinition } = useMemo(
    () => defaultAutomationDefinition,
    []
  );
  const dispatch = useDispatch();

  const initialFormState = useMemo(() => {
    return {
      isActive: automation?.isActive || false,
      conditions: automationDefinition.conditionValueCustomisationConfig.map(
        (c) => ({
          conditionIndex: c.conditionIndex,
          value: automation?.conditions[c.conditionIndex].value || '',
        })
      ),
      action: {
        type: automation?.actions?.length
          ? automation.actions[0].type
          : AutomationActionTypes.EMAIL_NOTIFICATION,
        config: automation?.actions?.length
          ? automation.actions[0].config
          : basicEmailNotifictionActionConfig,
        uploadIds: automation?.actions?.length
          ? automation.actions[0].uploadIds
          : [],
      },
    };
  }, [automation]);

  const fieldValidators: Record<
    string,
    FieldValidator<UpdateDefaultAutomationData<AutomationActionTypes>>[]
  > = useMemo(() => {
    return getFieldValidatorsForDefaultAutomation(automationDefinition);
  }, [automationDefinition.conditionValueCustomisationConfig]);

  const onSubmit = useCallback(
    (
      isValid: boolean,
      formState: UpdateDefaultAutomationData<AutomationActionTypes>
    ) => {
      if (!isValid && formState.isActive) {
        setErrorMessage(
          'Automation cannot be switched on whilst config is not valid'
        );
        return;
      }

      setIsSubmitting(true);
      setErrorMessage(undefined);
      dispatch(
        updateDefaultAutomation.request({
          defaultAutomation: {
            ...formState,
            definitionPublicId: automationDefinition.publicId,
          },
          callback: (automation) => {
            onUpdateAutomationSuccess?.(automation);
            setIsSubmitting(false);
          },
          onErrorCallback: () => {
            setIsSubmitting(false);
            setErrorMessage('Something went wrong updating this automation');
          },
        })
      );
    },
    [automationDefinition.publicId]
  );

  if (!automation) return null;

  return (
    <ResponsiveViewWrapper
      className="default-automation-control"
      downBreakpointSm={87 * 12}
      downBreakpointXs={600}
    >
      <ValidatedForm<UpdateDefaultAutomationData<AutomationActionTypes>>
        initialFormState={initialFormState}
        fieldValidators={fieldValidators}
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => (
          <FormContents
            isValid={isValid}
            formState={formState}
            validationState={validationState}
            touchedState={touchedState}
            onFieldChange={onFieldChange}
            onFieldTouch={onFieldTouch}
            automationDefinition={automationDefinition}
            automation={automation}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            errorMessage={errorMessage}
          />
        )}
      />
    </ResponsiveViewWrapper>
  );
};

const FormContents: FC<{
  isValid: boolean;
  formState: UpdateDefaultAutomationData<AutomationActionTypes>;
  validationState: {
    [key: string]: FieldValidationResult;
  };
  touchedState: {
    [key: string]: boolean;
  };
  onFieldChange: (value: { [key: string]: any }) => void;
  onFieldTouch: (fieldName: string) => void;
  automationDefinition: PublicDefaultAutomationDefinition;
  automation: Automation<AutomationActionTypes>;
  onSubmit: (
    isValid: boolean,
    formState: UpdateDefaultAutomationData<AutomationActionTypes>
  ) => void;
  isSubmitting: boolean;
  errorMessage?: string;
}> = ({
  isValid,
  formState,
  validationState,
  touchedState,
  onFieldChange,
  onFieldTouch,
  automationDefinition,
  automation,
  onSubmit,
  isSubmitting,
  errorMessage,
}) => {
  const [
    showConfigValidationMessagesUnconditionally,
    setShowConfigValidationMessagesUnconditionally,
  ] = useState(false);

  const defaultEmailNotifictionActionConfig = useMemo(() => {
    return (
      automationDefinition?.automationConfig?.defaultActions?.find(
        (x) => x.type === AutomationActionTypes.EMAIL_NOTIFICATION
      )?.config || basicEmailNotifictionActionConfig
    );
  }, [automationDefinition.automationConfig]);

  const defaultSmsActionConfig = useMemo(() => {
    return (
      automationDefinition?.automationConfig?.defaultActions?.find(
        (x) => x.type === AutomationActionTypes.SMS_NOTIFICATION
      )?.config || basicSmsActionConfig
    );
  }, [automationDefinition.automationConfig]);

  return (
    <>
      <div className="form-contents">
        <div className="intro">
          <h1>{automationDefinition.title}</h1>
        </div>
        <div className="submit">
          <div className="toggle">
            <SwitchField
              name="isActive"
              value={formState.isActive}
              onChange={(value) => {
                onFieldChange(value);
                setShowConfigValidationMessagesUnconditionally(value.isActive);
              }}
              label="Off/On"
            />
          </div>
          {/* save button and feedback */}
          <Button
            className="submit-button"
            onClick={() => onSubmit(isValid, formState)}
            isProcessing={isSubmitting}
            styleVariant={ButtonStyleVariant.OUTSIZE}
          >
            Save
          </Button>
          {errorMessage?.length && (
            <div className="feedback">
              <FeedbackBlock feedbackLevel={FeedbackLevel.ERROR}>
                {errorMessage}
              </FeedbackBlock>
            </div>
          )}
        </div>
        <div className="main-fields">
          <hr />
          {!!formState.conditions.length && (
            <>
              {!!automationDefinition.description?.length && (
                <>
                  <h2>{automationDefinition.description}</h2>
                </>
              )}
              {/* Conditions */}
              <ResponsiveViewWrapper
                className="simple-fields"
                downBreakpointSm={700}
                downBreakpointXs={500}
              >
                {formState.conditions.map((c, i) => {
                  const conditionValueCustomisationConfig =
                    automationDefinition.conditionValueCustomisationConfig.find(
                      (x) => x.conditionIndex === c.conditionIndex
                    );
                  const condition = automation.conditions[c.conditionIndex];

                  return (
                    <div key={c.conditionIndex}>
                      <ValidatedFieldWrapper
                        validationResult={
                          validationState[`conditions[${c.conditionIndex}]`]
                        }
                        isTouched={
                          showConfigValidationMessagesUnconditionally ||
                          touchedState[`conditions[${c.conditionIndex}]`] ||
                          false
                        }
                      >
                        <AutomationConditionValueField
                          labelConfig={{
                            label: conditionValueCustomisationConfig?.label,
                            description:
                              conditionValueCustomisationConfig?.description,
                          }}
                          value={c.value}
                          field={condition.field}
                          onConditionValueChange={(value) =>
                            onFieldChange({
                              [`conditions[${i}].value`]: value,
                            })
                          }
                          automationTriggerEvent={automation.triggerEvent}
                        />
                      </ValidatedFieldWrapper>
                    </div>
                  );
                })}
              </ResponsiveViewWrapper>
              <hr />
            </>
          )}
          <h2>
            {automationDefinition.description?.length &&
            !formState.conditions.length ? (
              <>{automationDefinition.description}, s</>
            ) : (
              'S'
            )}
            end a message to the customer
          </h2>
          <ResponsiveViewWrapper
            className="simple-fields"
            downBreakpointSm={700}
            downBreakpointXs={500}
          >
            {/* Action type */}
            <DropdownField
              label="Comms type"
              name="actionType"
              value={formState?.action?.type}
              options={[
                {
                  label: AutomationActionTypes.EMAIL_NOTIFICATION,
                  value: AutomationActionTypes.EMAIL_NOTIFICATION,
                },
                {
                  label: AutomationActionTypes.SMS_NOTIFICATION,
                  value: AutomationActionTypes.SMS_NOTIFICATION,
                },
              ]}
              onChange={(value) => {
                onFieldChange({
                  action: {
                    type: value.actionType,
                    config:
                      value.actionType ===
                      AutomationActionTypes.EMAIL_NOTIFICATION
                        ? defaultEmailNotifictionActionConfig
                        : defaultSmsActionConfig,
                  },
                });
              }}
            />
          </ResponsiveViewWrapper>
        </div>
        <div className="config-fields">
          {/* action config */}
          <AutomationActionConfigFieldset
            type={formState.action.type}
            config={formState.action.config}
            automationEntityType={
              automationDefinition.automationConfig.entityType
            }
            validationState={Object.entries(validationState).reduce(
              (acc: Record<string, FieldValidationResult>, [key, value]) => {
                if (key.startsWith('action.config.')) {
                  acc[key.replace('action.config.', '')] = value;
                }
                return acc;
              },
              {}
            )}
            onChange={(changeValue) => {
              const mappedChangeValue: { [key: string]: any } = {};
              for (const [key, value] of Object.entries(changeValue)) {
                mappedChangeValue[`action.config.${key}`] = value;
              }
              onFieldChange(mappedChangeValue);
            }}
            isDefaultAutomation={true}
            uploadIds={formState.action.uploadIds}
            onUploadCollectionChange={(uploadIds) => {
              onFieldChange({ ['action.uploadIds']: uploadIds });
            }}
            onTouch={(fieldName) => {
              onFieldTouch(`action.config.${fieldName}`);
            }}
            touchedState={
              formState.isActive
                ? Object.entries(touchedState).reduce(
                    (acc: Record<string, boolean>, [key, value]) => {
                      if (key.startsWith('action.config.')) {
                        acc[key.replace('action.config.', '')] = value;
                      }
                      return acc;
                    },
                    {}
                  )
                : {}
            }
            showFieldValidationMessagesUnconditionally={
              showConfigValidationMessagesUnconditionally
            }
          />
        </div>
      </div>
    </>
  );
};

export default DefaultAutomationControl;
