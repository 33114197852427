import { FunctionComponent } from 'react';

const PaymentMethodIcons: FunctionComponent = (): JSX.Element => {
  return (
    <div className="align-center flex gap-2">
      <img
        src="https://storage.googleapis.com/payaca-prod-assets/visa.svg"
        alt="visa icon"
      />
      <img
        src="https://storage.googleapis.com/payaca-prod-assets/mastercard.svg"
        alt="mastercard icon"
      />
      <img
        src="https://storage.googleapis.com/payaca-prod-assets/americanexpress.svg"
        alt="americanexpress icon"
      />
      <img
        src="https://storage.googleapis.com/payaca-prod-assets/applepay.svg"
        alt="applepay icon"
      />
      <img
        src="https://storage.googleapis.com/payaca-prod-assets/androidpay.svg"
        alt="androidpay icon"
      />
    </div>
  );
};

export default PaymentMethodIcons;
