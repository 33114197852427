export interface AuthState {
  isLoggingIn: boolean;
  isLoggedInSuccessfully?: boolean;

  isSigningUp: boolean;
  isSignedUpSuccessfully?: boolean;

  isResettingPassword: boolean;
  hasSuccessfullyResetPassword: boolean | null;
  signUpWithEmailError: string | null;
  isSigningUpWithEmail: boolean;
  isSignedUpWithEmailSuccessfully?: boolean | null;

  isResendingEmailVerification: boolean;

  isSigningUpAccontUpdate: boolean;

  isRequestingValidateVerificationToken: boolean;

  isVerifyingUserToken: boolean;
}

export enum AuthActionTypes {
  LOGOUT = 'auth.logout',
  CLEAR_ANALYTICS_USER_ID = 'auth.clearAnalyticsUserId',

  REQUEST_LOGIN = 'auth.requestLogin',
  REQUEST_LOGIN_WITH_XERO = 'auth.requestLoginWithXero',
  REQUEST_LOGIN_WITH_GOOGLE = 'auth.requestLoginWithGoogle',
  REQUEST_LOGIN_WITH_APPLE = 'auth.requestLoginWithApple',
  REQUEST_LOGIN_WITH_TOKEN = 'auth.requestLoginWithToken',
  LOGIN_SUCCESS = 'auth.loginSuccess',
  LOGIN_FAILURE = 'auth.loginFailure',

  REQUEST_SIGN_UP_WITH_XERO_V1 = 'auth.requestSignUpWithXeroV1',
  REQUEST_SIGN_UP_WITH_GOOGLE_V1 = 'auth.requestSignUpWithGoogleV1',
  REQUEST_SIGN_UP_WITH_APPLE_V1 = 'auth.requestSignUpWithAppleV1',

  REQUEST_SIGN_UP_WITH_XERO = 'auth.requestSignUpWithXero',
  REQUEST_SIGN_UP_WITH_GOOGLE = 'auth.requestSignUpWithGoogle',

  SIGN_UP_FAILURE = 'auth.signUpFailure',
  SIGN_UP_SUCCESS = 'auth.signUpSuccess',
  CLEAR_AUTH_LOGIN_SIGN_UP = 'auth.clearAuthLoginSignUp',

  REQUEST_RESET_PASSWORD = 'auth.requestResetPassword',
  RESET_PASSWORD_SUCCESS = 'auth.resetPasswordSuccess',
  RESET_PASSWORD_FAILURE = 'auth.resetPasswordFailure',
  CLEAR_RESET_PASSWORD = 'auth.clearResetPassword',

  REQUEST_REFRESH_AUTH_TOKEN = 'auth.requestRefreshAuthToken',
  REFRESH_AUTH_TOKEN_SUCCESS = 'auth.refreshAuthTokenSuccess',
  REFRESH_AUTH_TOKEN_FAILURE = 'auth.refreshAuthTokenFailure',
  STORE_TOKENS = 'auth.storeTokens',
  STORE_TOKENS_SUCCESS = 'auth.storeTokensSuccess',
  AUTHORISE = 'auth.authorise',
  REGISTER_O_AUTH_CLIENT = 'auth.registerOAuthClient',
  REMOVE_O_AUTH_CONNECTION = 'auth.removeOAuthConnection',
  VERIFY_O_AUTH = 'auth.verifyOAuth',

  REQUEST_SIGN_UP_WITH_EMAIL = 'auth.requestSignUpWithEmail',
  SIGN_UP_WITH_EMAIL_SUCCESS = 'auth.signUpWithEmailSuccess',
  SIGN_UP_WITH_EMAIL_FAILURE = 'auth.signUpWithEmailFailure',
  CLEAR_SIGN_UP_WITH_EMAIL = 'auth.clearSignUpWithEmail',

  REQUEST_SIGN_UP_WITH_EMAIL_V2 = 'auth.requestSignUpWithEmailV2',

  REQUEST_SIGN_UP_ACCOUNT_UPDATE_V2 = 'auth.requestSignUpAccountUpdateV2',
  SIGN_UP_ACCOUNT_UPDATE_V2_SUCCESS = 'auth.signUpAccountUpdateV2Success',
  SIGN_UP_ACCOUNT_UPDATE_V2_FAILURE = 'auth.signUpAccountUpdateV2Failure',
  CLEAR_SIGN_UP_ACCOUNT_UPDATE_V2 = 'auth.clearSignUpAccountUpdateV2',

  REQUEST_RESEND_EMAIL_VERIFICATION = 'auth.requestResendEmailVerification',
  RESEND_EMAIL_VERIFICATION_SUCCESS = 'auth.resendEmailVerificationSuccess',
  RESEND_EMAIL_VERIFICATION_FAILURE = 'auth.resendEmailVerificationFailure',

  REQUEST_VALIDATE_VERIFICATION_TOKEN = 'auth.requestValidateVerificationToken',
  VALIDATE_VERIFICATION_TOKEN_SUCCESS = 'auth.validateVerificationTokenSuccess',
  VALIDATE_VERIFICATION_TOKEN_FAILURE = 'auth.validateVerificationTokenFailure',

  REQUEST_VERIFY_USER_TOKEN = 'auth.requestVerifyUserToken',
  VERIFY_USER_TOKEN_SUCCESS = 'auth.verifyUserTokenSuccess',
  VERIFY_USER_TOKEN_FAILURE = 'auth.verifyUserTokenFailure',
}

export interface AuthSagaConfig {
  apiBaseurl: string;
  storeAuthTokens: (authTokens: {
    token: string;
    refreshToken: string;
  }) => Promise<void>;
  getRefreshToken: () => Promise<string>;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}
