import { TIconName } from '@payaca/untitled-icons';

export const getUntitledIconForFileType = (fileType: string): TIconName => {
  switch (fileType) {
    case 'image':
      return 'image-01.3';
    case 'video':
      return 'video-recorder.3';
    default:
      return 'file-attachment-03.3';
  }
};
