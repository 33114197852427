import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  CreateEventNoteInput,
  CreateEventNoteMutation as _CreateEventNoteMutation,
} from '@/gql/graphql';

const CreateEventNoteMutation = graphql(`
  mutation CreateEventNote($input: CreateEventNoteInput!) {
    createEventNote(input: $input) {
      id
    }
  }
`);

const useCreateEventNote = (
  options?: UseMutationOptions<
    _CreateEventNoteMutation,
    unknown,
    CreateEventNoteInput
  >
) => {
  return useMutation({
    mutationFn: (input: CreateEventNoteInput) => {
      return gqlClient.request(CreateEventNoteMutation, { input });
    },
    ...options,
  });
};

export default useCreateEventNote;
