import React, { FC, useMemo } from 'react';
import Modal from '@payaca/components/modal/Modal';
import { IntercomAPI } from 'react-intercom';
import AddUserForm from '../addUserForm/AddUserForm';
import { User } from '@payaca/types/userTypes';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { useHistory } from 'react-router-dom';
import './AddUserModal.css';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { useSelector } from '@/api/state';
import { hasUnlimitedUsers } from '@payaca/helpers/subscriptionHelper';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAddUserSuccess: () => void;
};
const AddUserModal: FC<Props> = ({
  isOpen,
  onClose,
  onAddUserSuccess,
}: Props): JSX.Element => {
  const history = useHistory();
  const numberOfUserSeats = useSelector((state) => {
    return (
      state.account?.accountAccessInformation?.features?.numberOfUserSeats ?? 0
    );
  });
  const hasActiveTrialPeriod = useSelector((state) => {
    const accountAccessInformation = state.account?.accountAccessInformation;
    if (!accountAccessInformation) return false;

    return (
      !accountAccessInformation.hasActiveSubscription &&
      !accountAccessInformation.isTrialPeriodExpired
    );
  });

  const numberOfActiveUsers = useSelector((state: any) => {
    return state.users.accountUsers.filter(
      (accountUser: User) =>
        !accountUser.deactivatedAt || accountUser.inviteToken
    ).length;
  });

  const canAddNewUser = useMemo(() => {
    return hasActiveTrialPeriod || numberOfUserSeats > numberOfActiveUsers;
  }, [numberOfUserSeats, numberOfActiveUsers, hasActiveTrialPeriod]);

  const userSeatsFilledComponent = useMemo(() => {
    return (
      <div className="user-seats-filled">
        <p>
          All the user spaces associated with your account have been filled.
        </p>
        <p>
          If you wish to add additional users, please upgrade your subscription
          plan or{' '}
          <strong
            className="contact-us-directly-trigger"
            onClick={() => IntercomAPI('show')}
            role="button"
          >
            contact us directly
          </strong>
          .
        </p>
        <PermissionGuard
          renderIfHasPermissions={[AccountsPermissions.UPDATE_SUBSCRIPTION]}
        >
          <div className="actions-container">
            <Button
              onClick={() => {
                history.push('/upgradeAccount');
              }}
              styleVariant={ButtonStyleVariant.OUTSIZE}
            >
              Manage subscription
            </Button>
          </div>
        </PermissionGuard>
      </div>
    );
  }, [history]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Add user">
      <div className="add-user-modal">
        {!hasActiveTrialPeriod && (
          <p>
            Currently using{' '}
            <strong>
              {numberOfActiveUsers} out of {hasUnlimitedUsers(numberOfUserSeats) ? 'unlimited' : numberOfUserSeats}
            </strong>{' '}
            available user spaces.
          </p>
        )}
        {canAddNewUser && <AddUserForm onAddUserSuccess={onAddUserSuccess} />}
        {!canAddNewUser && userSeatsFilledComponent}
      </div>
    </Modal>
  );
};

export default AddUserModal;
