import {
  ListedMaterialLineItemListViewPage,
  SortBy,
} from '@payaca/types/listedMaterialLineItemTypes';
import { SortDirection } from '@payaca/types/listViewTypes';

export interface State {
  isGettingListedMaterialLineItemsPage: boolean;
  listedMaterialLineItemsPage: ListedMaterialLineItemListViewPage | null;
}

export enum ActionType {
  REQUEST_GET_LISTED_MATERIAL_ITEMS_PAGE = 'listedMaterialLineItems.requestGetListedMaterialLineItemsPage',
  GET_LISTED_MATERIAL_ITEMS_PAGE_SUCCESS = 'listedMaterialLineItems.getListedMaterialLineItemsPageSuccess',
  GET_LISTED_MATERIAL_ITEMS_PAGE_FAILURE = 'listedMaterialLineItems.getListedMaterialLineItemsPageFailure',
  CLEAR_LISTED_MATERIAL_ITEMS_PAGE = 'listedMaterialLineItems.clearListedMaterialLineItemsPage',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export interface GetListedMaterialLineItemsRequestData {
  pageSize: number;
  pageNumber: number;
  lineItemSearchTerm?: string;
  materialSearchTerm?: string;
  sortDirection: SortDirection;
  sortBy: SortBy;
  lineItemId?: number;
  materialId?: number;
}
