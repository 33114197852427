import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { actions as appActions } from '@/api/app';

import UntitledIcon from '@payaca/untitled-icons';
import HeaderNavigationControl from '../headerNavigationControl/HeaderNavigationControl';
import HeaderSearchControl from '../headerSearchControl/HeaderSearchControl';
import HeaderInformationResourcesControl from '../headerInformationResourcesControl/HeaderInformationResourcesControl';
import HeaderNotificationsControl from './notifcationsControl/HeaderNotificationsControl';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

import './AuthenticatedPageHeader.sass';
import Breadcrumb, {
  TBreadcrumbItem,
} from '@payaca/components/plBreadcrumb/Breadcrumb';
import { FestiveEasterEggControl } from '../festiveEasterEggControl/FestiveEasterEggControl';

export type PreviousPageNavigationConfig =
  | {
      route: string;
      navigationPromptName?: string;
    }
  | {
      onClick: () => void;
      navigationPromptName?: string;
    };
type Props = {
  isSmallView?: boolean;
  enableNavigationSidebarToggle?: boolean;
  searchFieldConfig?: {
    onSearchTermChange: (searchTerm?: string) => void;
    placeholder?: string;
  };
  previousPageNavigationConfig?: PreviousPageNavigationConfig;
  breadcrumbItems?: TBreadcrumbItem[];
};
const AuthenticatedPageHeader: FC<Props> = ({
  enableNavigationSidebarToggle = false,
  isSmallView,
  searchFieldConfig,
  previousPageNavigationConfig,
  breadcrumbItems = [],
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [isSearchBarCollapsed, setIsSearchBarCollapsed] =
    useState<boolean>(true);

  const [isHeadwayUpdatesOpen, setIsHeadwayUpdatesOpen] =
    useState<boolean>(false);

  const headerSearchControl = useMemo(() => {
    if (!searchFieldConfig) return null;
    return (
      <HeaderSearchControl
        isSmallView={isSmallView}
        onSearchTermChange={searchFieldConfig.onSearchTermChange}
        placeholder={searchFieldConfig.placeholder}
        onToggleSearchBarCollapse={setIsSearchBarCollapsed}
      />
    );
  }, [searchFieldConfig, isSmallView]);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);
    const config = {
      selector: '.headway-widget-badge-container',
      account: 'xaNPe7',
      trigger: '.headway-widget-trigger',
      callbacks: {
        onShowWidget: function () {
          setIsHeadwayUpdatesOpen(true);
        },
        onHideWidget: function () {
          setIsHeadwayUpdatesOpen(false);
        },
      },
    };
    script.onload = function () {
      // @ts-ignore
      window.Headway.init(config);
    };
  }, []);

  return (
    <div
      className={`authenticated-page-header z-pageHeader ${
        isSmallView ? 'sm-view' : ''
      }`}
    >
      {enableNavigationSidebarToggle && isSearchBarCollapsed && (
        <div
          className="show-navigation-sidebar-toggle"
          onClick={() => dispatch(appActions.showNavigationSidebar())}
        >
          <FontAwesomeIcon icon={faBars} />
        </div>
      )}
      {previousPageNavigationConfig && !headerSearchControl && (
        <div className="header-previous-page-navigation-control-container">
          {'route' in previousPageNavigationConfig ? (
            <Link to={previousPageNavigationConfig.route}>
              {previousPageNavigationConfig.navigationPromptName || 'Back'}
            </Link>
          ) : (
            <Button
              onClick={previousPageNavigationConfig.onClick}
              styleVariant={ButtonStyleVariant.ANCHOR}
            >
              {previousPageNavigationConfig.navigationPromptName || 'Back'}
            </Button>
          )}
        </div>
      )}
      <Breadcrumb items={breadcrumbItems} />
      {!previousPageNavigationConfig && !headerSearchControl && (
        <div className="header-spacer"></div>
      )}
      {!isSmallView && headerSearchControl}

      <div className="info-actions-container">
        {isSmallView && (
          <div className="header-search-control-container">
            {headerSearchControl}
          </div>
        )}
        {(!isSmallView || isSearchBarCollapsed) && (
          <>
            <FestiveEasterEggControl />
            <HeaderNotificationsControl />
            <span
              className={`headway-widget-trigger${
                isHeadwayUpdatesOpen ? ' active' : ''
              }`}
            >
              <UntitledIcon
                name="announcement-03.3"
                className="h-6 w-6 text-blue-900"
              />
              <span className="headway-widget-badge-container"></span>
            </span>
            <HeaderInformationResourcesControl isSmallView={isSmallView} />
            <HeaderNavigationControl isSmallView={isSmallView} />
          </>
        )}
      </div>
    </div>
  );
};

export default AuthenticatedPageHeader;
