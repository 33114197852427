import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';

import './DashboardActionRequiredCarousel.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import Tooltip from '@payaca/components/tooltip/Tooltip';
// @ts-ignore
import scrollToWithAnimation from 'scrollto-with-animation';

type Props = {
  actionRequiredItems: React.ReactNode[] | undefined;
  title?: string | React.ReactNode;
  description?: string;
  isLoading?: boolean;
};
const DashboardActionRequiredCarousel: FC<Props> = ({
  actionRequiredItems,
  title,
  description,
  isLoading = false,
}: Props): JSX.Element => {
  const horizontalScrollContainerElement = useRef<HTMLDivElement>(null);
  const itemsContainerElement = useRef<HTMLDivElement>(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollInterval, setScrollInterval] = useState<NodeJS.Timeout>();

  const [horizontalScrollContainerWidth, setHorizontalScrollContainerWidth] =
    useState(0);

  const [itemsContainerWidth, setItemsContainerWidth] = useState(0);

  useLayoutEffect(() => {
    setHorizontalScrollContainerWidth(
      horizontalScrollContainerElement?.current?.offsetWidth || 0
    );
    setItemsContainerWidth(itemsContainerElement?.current?.scrollWidth || 0);
  }, [
    itemsContainerElement?.current?.scrollWidth,
    horizontalScrollContainerElement?.current?.offsetWidth,
    actionRequiredItems,
  ]);

  const scrollRequired = useMemo(() => {
    return itemsContainerWidth > horizontalScrollContainerWidth;
  }, [
    horizontalScrollContainerWidth,
    itemsContainerWidth,
    actionRequiredItems,
  ]);

  const scrollSegment = useCallback(
    (easing = 'linearTween') => {
      if (!itemsContainerElement?.current) return;

      const currentScroll = itemsContainerElement.current.scrollLeft || 0;
      scrollToWithAnimation(
        itemsContainerElement.current, // element to scroll
        'scrollLeft', // direction to scroll
        currentScroll + 100, // target scrollY (0 means top of the page)
        100, // duration in ms
        easing
      );
    },
    [itemsContainerElement?.current]
  );

  useEffect(() => {
    if (isScrolling && itemsContainerElement?.current) {
      scrollSegment('easeInCirc');
      setScrollInterval(
        setInterval(() => {
          scrollSegment();
        }, 100)
      );
    } else {
      setScrollInterval((x) => {
        if (x) {
          clearInterval(x);
          scrollSegment('easeOutCirc');
        }
        return undefined;
      });
    }
  }, [isScrolling, scrollSegment]);

  return (
    <div className="dashboard-action-required-carousel">
      {title && (
        <div className="title-container flex-container flex-center">
          <div className="flex-container flex-contiguous flex-center">
            {typeof title === 'string' ? <h2>{title}</h2> : title}
            {description && <Tooltip text={description} />}
          </div>
          {isLoading && <MiniLoader />}
        </div>
      )}
      {!!actionRequiredItems?.length && (
        <div
          className="horizontal-scroll-container"
          ref={horizontalScrollContainerElement}
        >
          <div
            className="flex-container flex-center items-container"
            ref={itemsContainerElement}
          >
            {actionRequiredItems}
            {scrollRequired && <div className="spacer" />}
          </div>
          {scrollRequired && (
            <div className="scroll-indicator-container">
              <div className="gradient-overlay"></div>
              <div
                className="arrow-container"
                // @ts-ignore
                onMouseDown={() => setIsScrolling(true)}
                onMouseUp={() => setIsScrolling(false)}
                onMouseLeave={() => setIsScrolling(false)}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DashboardActionRequiredCarousel;
