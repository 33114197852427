import { DealsPermissions } from './deals.permissions';
import { DealsRoles } from './deals.roles';

export const dealsMappings = {
  [DealsPermissions.GET_LISTED_DEALS]: [DealsRoles.VIEWER],
  [DealsPermissions.GET_MY_LISTED_DEALS]: [DealsRoles.ASSIGNEE_VIEWER],
  [DealsPermissions.GET_DEAL]: [DealsRoles.VIEWER],
  [DealsPermissions.GET_MY_DEAL]: [DealsRoles.ASSIGNEE_VIEWER],
  [DealsPermissions.ADD_DEAL]: [DealsRoles.EDITOR],
  [DealsPermissions.ADD_MY_DEAL]: [DealsRoles.ASSIGNEE_EDITOR],
  [DealsPermissions.UPDATE_DEAL]: [DealsRoles.EDITOR],
  [DealsPermissions.UPDATE_MY_DEAL]: [DealsRoles.ASSIGNEE_EDITOR],
  [DealsPermissions.SHARE_DEAL_FILES]: [
    DealsRoles.EDITOR,
    DealsRoles.ASSIGNEE_EDITOR,
  ],
  [DealsPermissions.UNSHARE_DEAL_FILES]: [DealsRoles.UNSHARE_DEAL_FILES],
  [DealsPermissions.RAISE_ADDITIONAL_WORK_REQUEST]: [
    DealsRoles.ADDITIONAL_WORK_REQUESTER,
  ],
  [DealsPermissions.GET_COMPLIANCE_FORMS]: [DealsRoles.COMPLIANCE_FORM_VIEWER],
};
