import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  CreateSubdomainMutation as _CreateSubdomainMutation,
  CreateSubdomainInput,
} from '@/gql/graphql';

const CreateSubdomainMutation = graphql(`
  mutation CreateSubdomain($input: CreateSubdomainInput!) {
    createSubdomain(input: $input) {
      __typename
      ... on CreateSubdomainResponseUnavailableError {
        message
      }
    }
  }
`);

const useCreateSubdomain = (
  options?: UseMutationOptions<
    _CreateSubdomainMutation,
    unknown,
    CreateSubdomainInput
  >
) => {
  return useMutation({
    mutationFn: (variables) => {
      return gqlClient.request(CreateSubdomainMutation, {
        input: variables,
      });
    },
    ...options,
  });
};

export default useCreateSubdomain;
