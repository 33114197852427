import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import * as jobsActions from '@payaca/store/jobs/jobsActions';
import { Job } from '@payaca/types/jobTypesV2';
import Button from '@payaca/components/plButton/Button';
import { getModal } from '@/helpers/modalHelper';
import { IntercomAPI } from 'react-intercom';
import * as customerActions from '@payaca/store/customer/customerActions';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import * as dealsActions from '@payaca/store/deals/dealsActions';
import * as jobPaymentsActions from '@payaca/store/jobPayments/jobPaymentsActions';

import {
  getJob,
  getJobContentByJobId,
  getJobPaymentsByJobId,
} from '@/utils/stateAccessors';
import {
  getJobStatusPLBadgeState,
  getJobType,
} from '@payaca/helpers/jobHelperV2';
import { AddCustomerEmailModal, PreviewUpdateReminders, SendJob } from '..';
import PreviewJobActionButtons from '../previewJobActionButtons/PreviewJobActionButtons';
import { useHistory } from 'react-router';
import { actions as appActions } from '@/api/app';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import VerifyAccountModal from '../verifyAccountModal/VerifyAccountModal';
import { useSelector } from '@/api/state';
import PreviewJobControl from '@/ui/components/previewJobControl/PreviewJobControl';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';

type Props = {
  jobId: number;
  sendJobToCustomer: (
    id: number,
    payload: {
      sendMeACopy: boolean;
      preButtonEmailText: string;
      postButtonEmailText: string;
    },
    cb: (err: any, response: any) => void
  ) => void;
};

const PreviewAndSendJobControl: FunctionComponent<Props> = ({
  jobId,
  sendJobToCustomer,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isSendJobDisabled, setIsSendJobDisabled] = useState(true);
  const [isSendingJob, setIsSendingJob] = useState(false);
  const [showAddCustomerEmailModal, setShowAddCustomerEmailModal] =
    useState(false);
  const [showVerifyAccountModal, setShowVerifyAccountModal] = useState(false);

  const [sendJobPayload, setSendJobPayload] = useState<{
    sendMeACopy: boolean;
    preButtonEmailText: string;
    postButtonEmailText: string;
  }>({
    sendMeACopy: true,
    preButtonEmailText: '',
    postButtonEmailText: '',
  });

  const job: Job | undefined = useSelector((state) => {
    return getJob(state, jobId);
  });

  const myProfile = useSelector((state: any) => state.users.myProfile);

  const jobContent = useSelector((state) => {
    return getJobContentByJobId(state, jobId);
  });

  const customer = useSelector((state) => {
    return state.customer.currentCustomer;
  });
  const jobPayments = useSelector((state) =>
    getJobPaymentsByJobId(state, jobId)
  );

  const onJobUpdateSuccess = useCallback(() => {
    dispatch(jobsActions.requestGetJob(jobId));
    job && dispatch(jobContentActions.requestGetJobContent(job.jobContentId));
  }, [dispatch, jobId]);

  const onDealPaymentsUpdateSuccess = useCallback(() => {
    job && dispatch(dealsActions.requestGetDeal(job.dealId));
    job &&
      dispatch(jobPaymentsActions.requestGetJobPaymentsForDeal(job.dealId));
  }, [dispatch, job]);

  const jobType = useMemo(() => {
    if (!job) return;
    return getJobType(job);
  }, [job]);

  const handleErrorMessageOnSendAttempt = useCallback(
    (errorMessage: string) => {
      if (!job) return;
      if (errorMessage === 'DEMO_ENDED') {
        dispatch(
          appActions.showModal(
            getModal('DEMO_ENDED', {
              primaryAction: () => {
                dispatch(appActions.hideModal());
                history.push('/upgradeAccount');
              },
              secondaryAction: () => {
                IntercomAPI('show');
                dispatch(appActions.hideModal());
              },
              onClose: () => dispatch(appActions.hideModal()),
            })
          )
        );
      } else if (errorMessage === 'You have exceeded your subscription plan') {
        dispatch(
          appActions.showModal(
            getModal('EXCEEDED_SUBSCRIPTION', {
              primaryAction: () => {
                dispatch(appActions.hideModal());
                history.push('/upgradeAccount');
              },
              secondaryAction: () => {
                IntercomAPI('show');
                dispatch(appActions.hideModal());
              },
              onClose: () => dispatch(appActions.hideModal()),
            })
          )
        );
      } else if (errorMessage === 'EMAIL_INACTIVE') {
        const primaryContact = getJobContactFromCustomer(
          customer!, // FIXME
          job?.contactId || null
        );
        dispatch(
          appActions.showModal(
            getModal('INACTIVE_EMAIL', {
              primaryAction: () => {
                dispatch(appActions.hideModal());
              },
              text: [primaryContact?.emailAddress],
              onClose: () => dispatch(appActions.hideModal()),
            })
          )
        );
      } else if (errorMessage === 'XERO_EXPIRED') {
        dispatch(
          appActions.showModal({
            title: 'Xero connection expired',
            text: 'Your connection with Xero has expired, please head over to the Connections page to reconnect.',
            primaryText: 'Reconnect',
            primaryAction: () => {
              dispatch(appActions.hideModal());
              history.push('/connections');
            },
            onClose: () => dispatch(appActions.hideModal()),
          })
        );
      } else if (errorMessage === 'QB_DUPLICATE_REF') {
        dispatch(
          appActions.showModal({
            title: 'Quickbooks reference already exists',
            text: `Invoice reference ${
              job.customReference || job.reference
            } already exists in Quickbooks, please set a unique custom reference and try again.`,
            onClose: () => dispatch(appActions.hideModal()),
          })
        );
      } else if (errorMessage === 'QB_INCORRECT_REF_LENGTH') {
        dispatch(
          appActions.showModal({
            title: 'Quickbooks reference too long',
            text: 'Your invoice reference is too long for Quickbooks, please set a custom reference 21 characters or less and try again.',
            onClose: () => dispatch(appActions.hideModal()),
          })
        );
      } else {
        dispatch(
          appActions.showModal({
            title: 'Something went wrong',
            text:
              errorMessage ||
              'Please try again. Contact us if you continue to have problems.',
            primaryText: 'Ok',
            primaryAction: () => {
              dispatch(appActions.hideModal());
            },
            onClose: () => dispatch(appActions.hideModal()),
          })
        );
      }
    },

    [dispatch, history, customer]
  );

  const navigateToDeal = useCallback(() => {
    history.push(`/deals/${job?.dealId}`);
  }, [history, job]);

  const sendJob = useCallback(() => {
    const payload = {
      sendMeACopy: sendJobPayload.sendMeACopy,
      preButtonEmailText: sendJobPayload.preButtonEmailText,
      postButtonEmailText: sendJobPayload.postButtonEmailText,
    };
    setIsSendingJob(true);
    sendJobToCustomer(jobId, payload, (error: any, response: any) => {
      setIsSendingJob(false);
      if (error) {
        if (error.message)
          handleErrorMessageOnSendAttempt(
            error.message.errors || error.message.error
          );
      } else {
        navigateToDeal();
      }
    });
  }, [
    jobId,
    sendJobPayload,
    dispatch,
    navigateToDeal,
    handleErrorMessageOnSendAttempt,
    sendJobToCustomer,
  ]);

  const sendJobCheck = useCallback(() => {
    if (!myProfile.verifiedAt) {
      setShowVerifyAccountModal(true);
    } else {
      sendJob();
    }
  }, [myProfile, sendJob]);

  return (
    <>
      <PreviewJobControl
        jobId={jobId}
        includeDownloadPDFAction
        onJobUpdateSuccess={onJobUpdateSuccess}
        onDealPaymentsUpdateSuccess={onDealPaymentsUpdateSuccess}
        markAsSentCallback={navigateToDeal}
        primaryAction={{
          disabled: isSendJobDisabled,
          isProcessing: isSendingJob,
          onClick: () => !isSendingJob && sendJobCheck(),
          children: 'Send',
        }}
        prefixContent={
          <div className="mb-4 space-y-4">
            <div className="flex justify-end">
              <PreviewUpdateReminders />
            </div>

            <h2>Preview email</h2>
            {!!job && !!jobContent && (
              <SendJob
                job={job}
                jobContent={jobContent}
                customer={customer! /* FIXME */}
                jobPayments={jobPayments}
                preButtonEmailText={sendJobPayload.preButtonEmailText}
                postButtonEmailText={sendJobPayload.postButtonEmailText}
                sendMeACopy={sendJobPayload.sendMeACopy}
                onUpdate={(value?: { [key: string]: any }) => {
                  if (!value) return;
                  setSendJobPayload((sendJobPayload) => {
                    return {
                      ...sendJobPayload,
                      ...value,
                    };
                  });
                }}
                onSetIsSendJobDisabled={setIsSendJobDisabled}
              />
            )}

            <h2>Preview {jobType}</h2>
          </div>
        }
      />

      {job && (
        <AddCustomerEmailModal
          customer={customer}
          isOpen={showAddCustomerEmailModal}
          contactId={job?.contactId}
          onClose={() => setShowAddCustomerEmailModal(false)}
          customerUpdateCallback={() => {
            dispatch(
              customerActions.requestGetAndSetCurrentCustomer(
                customer!.id /* FIXME */
              )
            );
            setShowAddCustomerEmailModal(false);
          }}
        />
      )}
      <VerifyAccountModal
        isOpen={showVerifyAccountModal}
        onClose={() => setShowVerifyAccountModal(false)}
      />
    </>
  );
};

export default PreviewAndSendJobControl;
