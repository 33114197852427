import { useMemo } from 'react';

export enum EBtnVariant {
  Solid = 'solid',
  Outline = 'outline',
  Dashed = 'dashed',
  Ghost = 'ghost',
  Soft = 'soft',
  White = 'white',
  Link = 'link',
  LinkInline = 'link-inline',
}

export enum EBtnSize {
  XSmall = 'xsmall',
  Small = 'small',
  Default = 'default',
  Large = 'large',
}

export enum EBtnColour {
  Black = 'black',
  Gray = 'gray',
  Teal = 'teal',
  Blue = 'blue',
  Red = 'red',
  Yellow = 'yellow',
  White = 'white',
  JobBrand = 'job-brand',
}

export enum EBtnShapeVariant {
  PILL = 'pill',
  ROUNDED = 'rounded',
}

export type TBtnVariantProps = {
  variant?: EBtnVariant;
  size?: EBtnSize;
  colour?: EBtnColour;
  shape?: EBtnShapeVariant;
};

const useButtonClassName = ({
  variant = EBtnVariant.Solid,
  size = EBtnSize.Default,
  colour = EBtnColour.Blue,
  shape = EBtnShapeVariant.ROUNDED,
}: TBtnVariantProps) => {
  const variantClass = useMemo(() => {
    switch (variant) {
      case EBtnVariant.Solid:
        return 'btn--solid';
      case EBtnVariant.Outline:
        return 'btn--outline';
      case EBtnVariant.Ghost:
        return 'btn--ghost';
      case EBtnVariant.Soft:
        return 'btn--soft';
      case EBtnVariant.White:
        return 'btn--white-variant';
      case EBtnVariant.Dashed:
        return 'btn--white-variant border-dashed';
      case EBtnVariant.Link:
        return 'btn--link';
      case EBtnVariant.LinkInline:
        return 'btn--link-inline';
    }
  }, [variant]);

  const sizeClass = useMemo(() => {
    switch (size) {
      case EBtnSize.XSmall:
        return 'btn--xs';
      case EBtnSize.Small:
        return 'btn--sm';
      case EBtnSize.Default:
        return '';
      case EBtnSize.Large:
        return 'btn--lg';
    }
  }, [size]);

  const colourClass = useMemo(() => {
    switch (colour) {
      case EBtnColour.Black:
        return 'btn--black';
      case EBtnColour.Gray:
        return 'btn--gray';
      case EBtnColour.Teal:
        return 'btn--teal';
      case EBtnColour.Blue:
        return 'btn--blue';
      case EBtnColour.Red:
        return 'btn--red';
      case EBtnColour.Yellow:
        return 'btn--yellow';
      case EBtnColour.White:
        return 'btn--white';
      case EBtnColour.JobBrand:
        return 'btn--job-brand';
    }
  }, [colour]);

  if (variant === EBtnVariant.LinkInline) {
    return 'btn--link-inline' + (sizeClass ? ` ${sizeClass}` : '');
  }

  return `btn ${variantClass} ${colourClass} ${sizeClass} ${
    shape === EBtnShapeVariant.PILL ? 'rounded-full' : 'rounded-lg'
  }`;
};

export default useButtonClassName;
