import { FetchedEntity } from '@payaca/types/storeTypes';
import { LineItemGroup } from '@payaca/types/lineItemGroupTypes';

export interface LineItemGroupsState {
  lineItemGroups: {
    [key: number]: FetchedEntityLineItemGroup;
  };

  isGettingLineItemGroups: boolean;

  isUpdatingLineItemGroup: boolean;
  updateLineItemGroupErrorMessage: null | string;
  isUpdateLineItemGroupSuccessful: null | boolean;

  isCreatingLineItemGroup: boolean;
  createLineItemGroupErrorMessage: null | string;
  createLineItemGroupSuccessId: number | null;

  isArchivingLineItemGroup: boolean;
  archiveLineItemGroupErrorMessage: null | string;
  isArchiveLineItemGroupSuccessful: null | boolean;
}

export enum ActionType {
  REQUEST_GET_LINE_ITEM_GROUPS = 'lineItemGroups.requestGetLineItemGroups',
  GET_LINE_ITEM_GROUPS_SUCCESS = 'lineItemGroups.getLineItemGroupsSuccess',
  GET_LINE_ITEM_GROUPS_FAILURE = 'lineItemGroups.getLineItemGroupsFailure',

  REQUEST_GET_LINE_ITEM_GROUP = 'lineItemGroups.requestGetLineItemGroup',
  GET_LINE_ITEM_GROUP_SUCCESS = 'lineItemGroups.getLineItemGroupSuccess',
  GET_LINE_ITEM_GROUP_FAILURE = 'lineItemGroups.getLineItemGroupFailure',

  REQUEST_CREATE_LINE_ITEM_GROUP = 'lineItemGroups.requestCreateLineItemGroup',
  CREATE_LINE_ITEM_GROUP_SUCCESS = 'lineItemGroups.createLineItemGroupSuccess',
  CREATE_LINE_ITEM_GROUP_FAILURE = 'lineItemGroups.createLineItemGroupFailure',
  CLEAR_CREATE_LINE_ITEM_GROUP = 'lineItemGroups.clearCreateLineItemGroup',

  REQUEST_UPDATE_LINE_ITEM_GROUP = 'lineItemGroups.requestUpdateLineItemGroup',
  UPDATE_LINE_ITEM_GROUP_SUCCESS = 'lineItemGroups.updateLineItemGroupSuccess',
  UPDATE_LINE_ITEM_GROUP_FAILURE = 'lineItemGroups.updateLineItemGroupFailure',
  CLEAR_UPDATE_LINE_ITEM_GROUP = 'lineItemGroups.clearUpdateLineItemGroup',

  REQUEST_ARCHIVE_LINE_ITEM_GROUP = 'lineItemGroups.requestArchiveLineItemGroup',
  ARCHIVE_LINE_ITEM_GROUP_SUCCESS = 'lineItemGroups.archiveLineItemGroupSuccess',
  ARCHIVE_LINE_ITEM_GROUP_FAILURE = 'lineItemGroups.archiveLineItemGroupFailure',
  CLEAR_ARCHIVE_LINE_ITEM_GROUP = 'lineItemGroups.clearArchiveLineItemGroup',

  CLEAR_LINE_ITEM_GROUPS = 'lineItemGroups.clearLineItemGroups',
}

export type FetchedEntityLineItemGroup = FetchedEntity<LineItemGroup>;

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}
