export const convertKeyValueToString = (key: string, value?: string): string =>
  `${key}${value ? `:\n${value}` : ''}`;

export const concatObjectToString = (
  obj: Record<string, string>,
  order: string[]
) => {
  return order
    .filter((key) => !!obj[key])
    .map((key) => convertKeyValueToString(key, obj[key]))
    .join('\n\n');
};

export const getUrlExtension = (url: string) => {
  // This regex looks for the last dot in the string followed by any characters
  // that are not a slash or dot, up until the end of the string
  // NB: This prevents "https://googlecloud.com/test" returning "com/test" (it should return no extension)
  const match = url.match(/\.([^./]+)$/);
  return match ? match[1] : null;
};

export const stripExtension = (filename: string) => {
  return filename.replace(/\.[^/.]+$/, '');
};
