import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import qs from 'qs';
import { flatMap, set, debounce, merge } from 'lodash-es';

import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import LoaderOverlay from '@payaca/components/loaderOverlay/LoaderOverlay';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import './FormPage.sass';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import * as formActions from '@payaca/store/forms/formsActions';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { FormElement } from '@payaca/types/formElementTypes';
import { buildValidation } from '@/ui/pages/formPage/formUtils/buildValidation';
import { createValidatedField } from '@/ui/pages/formPage/formUtils/createValidatedField';
import { EditSquare, TickSquare } from 'react-iconly';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';
import { IntercomAPI } from 'react-intercom';
import { FormCompleteActions } from '@/ui/pages/formPage/FormCompleteActions';
import { useSelector } from '@/api/state';
import { isNullish } from '@payaca/utilities/guards';

import PlButton from '@payaca/components/plButton/Button';

const actions: Array<any> = [];

let savingTimeout: any;
const triggerSavingNotification = (
  setShowSavingMessage: any,
  setShowSavedMessaged: any
) => {
  // clearTimeout(savingTimeout);
  // savingTimeout = setTimeout(() => {
  setShowSavedMessaged(false);
  setShowSavingMessage(true);
  // }, 1000);
};

const getElemKeys = (elems: FormElement[], path: any[]): any => {
  return flatMap(elems, (elem: FormElement) => {
    if (elem.children) {
      switch (elem.type) {
        case 'multi-items': {
          let output: any[] = [];
          for (let i = 0; i < 10; i++) {
            output = [
              ...output,
              ...getElemKeys(elem.children || [], [...path, elem.id, i]),
            ];
          }
          return output;
        }
        default:
          return getElemKeys(elem.children || [], [...path, elem.id]);
      }
    }
    return [
      ...path.filter((i: any) => typeof i === 'number' || !!i),
      elem.id,
    ].join('.');
  });
};

const calculateValidity = (
  inputs: FormElement[],
  path: any[],
  validationState: any
) => {
  return inputs.reduce((acc: boolean, elem: FormElement) => {
    if (!acc) {
      return acc;
    }
    const a = getElemKeys(elem.children || [], [...path, elem.id]);
    const isInvalid = a.find((a: string) => {
      return validationState[a]?.isValid === false;
    });
    return !isInvalid;
  }, true);
};

const FormPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ formPreviewToken: string; pageIndex: string }>();
  const [showSavingMessage, setShowSavingMessage] = useState(false);
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [formState, setFormState] = useState({});
  const [selectedMenuSection, setSelectedMenuSection] = useState<
    number | undefined
  >(undefined);
  const [formProgressStr, setFormProgressStr] = useState('false');
  const [showExternalFormCompleteMessage, setShowExternalFormCompleteMessage] =
    useState(false);
  const [formCompleteData, setFormCompleteData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formInstance = useSelector((state) => state.forms.formData);

  const formTemplate = useSelector(
    (state) => state.forms.formData?.formTemplate
  );
  const formData = useSelector((state) => state.forms.formData?.formData);
  const documentId = useSelector((state) => state.forms.formData?.documentId);
  const lastSavedTimestamp = useSelector((state) => state.forms.lastUpdated);

  const isLoading = useMemo(() => !formTemplate, [formTemplate]);
  const dealId = useMemo(
    () => getUrlSearchParam(location.search, 'dealId'),
    [location]
  );
  const isFromMobileApp = useMemo(
    () => !!getUrlSearchParam(location.search, 'isFromMobile'),
    [location]
  );

  const hasSavedCompleteFormData = useRef(false);

  useEffect(() => {
    dispatch(formActions.requestGetAvailableForms());
  }, []);

  useEffect(() => {
    IntercomAPI('update', {
      hide_default_launcher: true,
    });
  }, []);
  useEffect(() => {
    if (formData) {
      setFormState(formData);
    }
    // setShowSavingMessage(false);
    // setShowSavedMessage(false);
  }, [formData]);
  useEffect(() => {
    // setShowSavingMessage(false);
    setShowSavedMessage(true);
    setTimeout(() => {
      setShowSavedMessage(false);
    }, 3000);
  }, [lastSavedTimestamp]);

  useEffect(() => {
    if (!params?.pageIndex) {
      return;
    }
    setSelectedMenuSection(
      params.pageIndex === 'menu' ? undefined : +params.pageIndex
    );
  }, [params.pageIndex]);

  const formPreviewToken = useMemo(() => {
    return params.formPreviewToken;
  }, [params]);

  useEffect(() => {
    dispatch(formActions.clearFormData(formPreviewToken));
    dispatch(formActions.requestGetFormData(formPreviewToken));
  }, [formPreviewToken]);

  const formStructure: FormElement[] = useMemo(() => {
    if (isNullish(selectedMenuSection)) return null;
    return formTemplate?.pages?.[selectedMenuSection]?.children || [];
  }, [formTemplate, selectedMenuSection]);

  const fieldValidators = useMemo(() => {
    return buildValidation(formTemplate?.pages || [], {}, [], formState);
  }, [formTemplate, formState]);

  const isSearchingForItems = useSelector(
    (state: any) => state.forms.isSearchingForItems
  );
  const searchItemResults = useSelector((state) => {
    return state.forms.searchItemResults;
  });

  const taskId = useMemo(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    return query?.taskId;
  }, [location]);

  const uiHandlers = useMemo(
    () => ({
      selectMenuSection: (index: number) =>
        history.push(`/forms/${formPreviewToken}/${index}${location.search}`),
    }),
    [dealId, formPreviewToken, location, searchItemResults, isSearchingForItems]
  );

  const handleFormSubmit = useCallback(() => {
    setIsSubmitting(true);
    setFormCompleteData({
      documentId,
      title: formTemplate.title,
    });
    dispatch(
      formActions.requestSubmitForm(formPreviewToken, () => {
        setIsSubmitting(false);
        if (!isFromMobileApp) {
          return history.push(
            `/documents/${documentId}${taskId ? `?taskId=${taskId}` : ''}`
          );
        }
        setShowExternalFormCompleteMessage(true);
      })
    );
  }, [formPreviewToken, documentId, dealId, taskId]);

  const calculateFormCompleteness = useCallback(
    debounce(
      (validationState: any) => {
        const result = formTemplate?.pages?.reduce(
          (acc: any, page: any, index: number) => {
            acc[index] = calculateValidity(page.children, [], validationState);
            return acc;
          },
          {}
        );
        // TODO: move form progress inside the Validated form - this string prevents react state errors
        setFormProgressStr(JSON.stringify(result ?? false));
      },
      1000,
      {
        leading: true,
      }
    ),
    [formTemplate]
  );

  const formProgress = useMemo(() => {
    // TODO: move form progress inside the Validated form - this string prevents react state errors
    return JSON.parse(formProgressStr);
  }, [formProgressStr]);

  const numberOfCompleteSections = useMemo(() => {
    return formTemplate?.pages?.reduce(
      (acc: number, item: any, index: number) => {
        if (formProgress[index] && formData[`form_section_${index}_viewed`]) {
          return acc + 1;
        }
        return acc;
      },
      0
    );
  }, [formProgress, formTemplate, formData]);

  const performNavigation = useCallback(
    (newSectionIndex: number | 'menu') => {
      window.scrollTo(0, 0);
      history.push(
        `/forms/${formPreviewToken}/${newSectionIndex}${location.search}`
      );
    },
    [history, formPreviewToken, dealId, location, taskId]
  );

  const outstandingSectionsLabel = useMemo(() => {
    const sections = Object.values(formProgress).reduce(
      (acc: number[], sectionComplete: any, index: number) => {
        if (!sectionComplete) {
          return [...acc, index + 1];
        }
        return acc;
      },
      []
    );
    if (sections.length === 0) {
      return <MiniLoader />;
    }
    return `Missing answers in section${
      sections.length > 1
        ? `s ${sections.slice(0, -1).join(', ')} and ${
            sections[sections.length - 1]
          }`
        : ` ${sections?.[0]}`
    }`;
  }, [formProgress]);

  const saveFormData = useMemo(() => {
    let timeout: any;
    let batch: Array<any> = [];
    return (dataToSave: any, updatedFormState: any) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      batch.push(dataToSave);
      timeout = setTimeout(() => {
        const mergedData = batch.reduce((acc: any, item: any) => {
          return merge(acc, item);
        }, {});
        batch = [];
        // setShowSavedMessage(false);
        setShowSavingMessage(true);
        if (!hasSavedCompleteFormData.current) {
          dispatch(
            formActions.requestUpdateFormData(
              formPreviewToken,
              updatedFormState,
              () => {
                hasSavedCompleteFormData.current = true;
                setShowSavingMessage(false);
                // setShowSavedMessage(true);
                // setFormState(updatedFormState);
              }
            )
          );
        } else {
          dispatch(
            formActions.requestUpdateFormData(
              formPreviewToken,
              mergedData,
              () => {
                hasSavedCompleteFormData.current = true;
                setShowSavingMessage(false);
                // setFormState(updatedFormState);
              }
            )
          );
        }
      }, 1000);
    };
  }, []);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }, callback?: any) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      const changeHandler = (value: { [key: string]: any }) => {
        onFieldChange(value, (updatedFormState: any) => {
          setFormState(updatedFormState);
          const fragmentToSave = { id: updatedFormState.id } as any;
          Object.keys(value).forEach((key) => {
            const first = key.split('.')[0];
            fragmentToSave[first] = updatedFormState[first];
            set(fragmentToSave, key, value[key]);
          });
          // triggerSavingNotification(setShowSavingMessage, setShowSavedMessage);
          saveFormData(fragmentToSave, updatedFormState);
        });
      };
      calculateFormCompleteness(validationState);

      return (
        <div>
          <div className={'form-page-header'}>
            {typeof selectedMenuSection === 'number' ||
            (!!dealId && !isFromMobileApp) ? (
              <Button
                colourVariant={ButtonColourVariant.WHITE}
                onClick={() =>
                  typeof selectedMenuSection === 'number'
                    ? performNavigation('menu')
                    : history.push(
                        !taskId ? `/deals/${dealId}/files` : `/tasks`
                      )
                }
              >
                <span className={'form-title'}>
                  {typeof selectedMenuSection !== 'number' ? (
                    !!dealId && !taskId ? (
                      <>
                        <FontAwesome name={'chevron-left'} size={'lg'} />
                        <span>Back to Project</span>
                      </>
                    ) : taskId ? (
                      <>
                        <FontAwesome name={'chevron-left'} size={'lg'} />
                        <span>Back to Tasks</span>
                      </>
                    ) : (
                      ''
                    )
                  ) : (
                    <>
                      <FontAwesome name={'chevron-left'} size={'lg'} />
                      <span>Overview</span>
                    </>
                  )}
                </span>
              </Button>
            ) : (
              <div
                className={'mobile-menu-close-icon'}
                onClick={() => history.goBack()}
              >
                <FontAwesome name={'times'} size={'2x'} />
              </div>
            )}
            <div className={'form-page-header-rhs'}>
              <div className={'form-page-notifications'}>
                <div className={'form-page-notification'}>
                  {showSavingMessage && <MiniLoader />}
                  {showSavedMessage && (
                    <>
                      <TickSquare set={'bold'} primaryColor={'#4ea83d'} />
                      <small> {`Progress saved!`}</small>
                    </>
                  )}
                </div>
              </div>
              <div className={'company-brand'}>
                {formData.form_header_company_logo && (
                  <img
                    className="logo"
                    src={formData.form_header_company_logo}
                  />
                )}{' '}
                {formData.form_header_company_name &&
                  !showSavingMessage &&
                  !showSavedMessage && (
                    <span>{formData.form_header_company_name}</span>
                  )}
              </div>
            </div>
          </div>

          <div className={'form-body'}>
            {typeof selectedMenuSection !== 'number' ? (
              <div className={'form-menu'}>
                <div className={'form-menu-header'}>
                  <div className={'form-menu-overview'}>
                    <h4>Overview</h4>
                    <p>{`All of the following sections must be completed before your final document can be generated. All progress is saved automatically.`}</p>
                  </div>
                  <div className={'progress-summary'}>
                    ({numberOfCompleteSections ?? 0}/
                    {formTemplate?.pages?.length ?? 0} Complete)
                  </div>
                </div>

                <ContentPanel className={'menu-element'}>
                  {(formTemplate?.pages as any)?.map(
                    (child: FormElement, i: number) => (
                      <div className={'menu-item-container'} key={i}>
                        <div
                          className={'menu-item'}
                          onClick={() => {
                            changeHandler({
                              [`form_section_${i}_viewed`]: true,
                            });
                            performNavigation(i);
                          }}
                        >
                          <div className={'menu-item-status'}>
                            <TickSquare
                              set={'bold'}
                              primaryColor={
                                formProgress?.[i] &&
                                formState[`form_section_${i}_viewed`]
                                  ? 'green'
                                  : 'lightgray'
                              }
                              size={'large'}
                            />
                          </div>
                          <div></div>
                          <div>
                            <div className={'menu-item-icon'}>
                              {child.icon ? (
                                <FontAwesome name={child.icon + ' fa-2x'} />
                              ) : (
                                <EditSquare size={'xlarge'} />
                              )}
                            </div>
                            <div className={'menu-item-title'}>
                              {child?.menuTitle}
                            </div>
                          </div>
                          <div className={'menu-item-label'}>
                            Section {i + 1}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </ContentPanel>
                <div className={'form-footer'}>
                  <Button
                    styleVariant={ButtonStyleVariant.OUTSIZE}
                    onClick={() => !isSubmitting && handleFormSubmit()}
                    isDisabled={!isValid || showSavingMessage}
                    isProcessing={showSavingMessage || isSubmitting}
                  >
                    {!isValid ? outstandingSectionsLabel : `View Document`}
                  </Button>
                </div>
              </div>
            ) : (
              <div className={'form-page-section-container'}>
                <div className={'form-page-section-content'}>
                  <div className={'form-page-section-header'}>
                    <div>
                      {formTemplate?.pages?.[selectedMenuSection]?.title && (
                        <>
                          {formTemplate?.pages?.[selectedMenuSection]?.icon ? (
                            <FontAwesome
                              name={
                                (formTemplate?.pages?.[selectedMenuSection]
                                  ?.icon || 'edit') + ' fa-lg'
                              }
                            />
                          ) : (
                            <EditSquare />
                          )}
                          <h4>
                            {formTemplate?.pages?.[selectedMenuSection]?.title}
                          </h4>
                        </>
                      )}
                    </div>
                    <div>
                      Section {(selectedMenuSection ?? 0) + 1}/
                      {formTemplate?.pages?.length ?? 0}
                    </div>
                  </div>
                  <div className={'form-sections'}>
                    {flatMap(
                      formStructure,
                      (formElement: FormElement, i: number) => {
                        if (
                          formElement.type === 'section' &&
                          formElement.children
                        ) {
                          return (
                            <div key={i} className={'form-page-section'}>
                              <div>
                                <div className={'form-page-section-title'}>
                                  {formElement.title && (
                                    <>
                                      <div
                                        className={
                                          'form-page-section-title-main'
                                        }
                                      >
                                        {formElement.icon ? (
                                          <FontAwesome
                                            name={
                                              (formTemplate?.pages?.[
                                                selectedMenuSection
                                              ]?.icon || 'edit') + ' fa-lg'
                                            }
                                          />
                                        ) : (
                                          <EditSquare />
                                        )}
                                        <h4>{formElement.title} </h4>
                                      </div>
                                      {!!formElement?.titleSuffix && (
                                        <span>{formElement.titleSuffix}</span>
                                      )}
                                    </>
                                  )}
                                </div>
                                {formElement?.description && (
                                  <p>{formElement.description}</p>
                                )}
                                {formElement?.children?.map(
                                  (elem: FormElement, index: number) => {
                                    return (
                                      <>
                                        {(elem.id ===
                                          'next_inspection_due_date' ||
                                          elem.id ===
                                            'add_next_inspection_calendar_event') &&
                                        (formState?.work_type ===
                                          'maintenance' ||
                                          formState?.work_type ===
                                            'call-out') ? (
                                          <div></div>
                                        ) : (
                                          <div key={elem.id}>
                                            {createValidatedField(
                                              elem,
                                              formState,
                                              formState[elem.id],
                                              changeHandler,
                                              onFieldTouch,
                                              touchedState,
                                              validationState,
                                              uiHandlers,
                                              index
                                            )}
                                          </div>
                                        )}
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          );
                        }
                        return (
                          <div key={formElement.id}>
                            {createValidatedField(
                              formElement,
                              formState,
                              formState,
                              changeHandler,
                              onFieldTouch,
                              touchedState,
                              validationState,
                              uiHandlers,
                              i
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            )}
            {typeof selectedMenuSection === 'number' && (
              <div className={'form-navigation-options'}>
                {selectedMenuSection !== 0 && (
                  <Button
                    styleVariant={ButtonStyleVariant.OUTSIZE}
                    colourVariant={ButtonColourVariant.SECONDARY}
                    onClick={() => {
                      performNavigation(selectedMenuSection - 1);
                      changeHandler({
                        [`form_section_${selectedMenuSection - 1}_viewed`]:
                          true,
                      });
                    }}
                    isOutlined={true}
                  >
                    Previous
                  </Button>
                )}
                {selectedMenuSection !==
                  (formTemplate?.pages?.length ?? 0) - 1 && (
                  <Button
                    styleVariant={ButtonStyleVariant.OUTSIZE}
                    onClick={() => {
                      performNavigation(selectedMenuSection + 1);
                      changeHandler({
                        [`form_section_${selectedMenuSection + 1}_viewed`]:
                          true,
                      });
                    }}
                  >
                    Next section
                  </Button>
                )}
                {selectedMenuSection ===
                  (formTemplate?.pages?.length ?? 0) - 1 && (
                  <Button
                    styleVariant={ButtonStyleVariant.OUTSIZE}
                    onClick={() => !isSubmitting && handleFormSubmit()}
                    isDisabled={!isValid || showSavingMessage}
                    isProcessing={showSavingMessage || isSubmitting}
                  >
                    {!isValid ? outstandingSectionsLabel : 'Submit'}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      );
    },
    [
      formPreviewToken,
      formTemplate,
      formStructure,
      formProgressStr,
      showSavingMessage,
      showSavedMessage,
      uiHandlers,
      outstandingSectionsLabel,
      taskId,
      isSubmitting,
      handleFormSubmit,
    ]
  );

  const fifo = useAutoFifo(
    {
      flush: async (changedFields) => {
        const token = localStorage.getItem('payacav1'); // TOKEN_KEY
        // patch changes to the server
        if (formInstance.id && token) {
          await fetch(
            `${import.meta.env.VITE_API_HOST}/provider/rest/forms/revisions/${
              formInstance.id
            }`,
            {
              method: 'PATCH',
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(flattenChanges(changedFields as any)),
            }
          );
        }
      },
    },
    [formInstance]
  );

  useEffect(() => {
    const finaliseForm = async () => {
      const token = localStorage.getItem('payacav1'); // TOKEN_KEY
      console.log('FINALISE BUTTON CLICKED', {
        formInstanceId: formInstance.id,
        token,
      });
      // patch changes to the server
      if (formInstance.id && token) {
        console.log('FINALISE BUTTON CLICKED AND INSIDE CONDITIONAL BLOCK', {
          formInstanceId: formInstance.id,
          token,
        });
        const items: Array<any> = []; // (await fifo.destroy()) as unknown as Array<any>;
        console.log('destroyed fifo');
        if (items.length > 0) {
          // surface error somehow - there are unflushed changes
          console.error('unflushed items', items);
          return;
        }
        console.log('making request to finalise form');
        await fetch(
          `${import.meta.env.VITE_API_HOST}/provider/rest/forms/revisions/${
            formInstance.id
          }/finalise`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw new Error();
            }
            if (!isFromMobileApp) {
              return history.push(
                `/documents/${documentId}${taskId ? `?taskId=${taskId}` : ''}`
              );
              // return history.goBack();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };
    finaliseFormRef.current = finaliseForm;
  }, [formInstance, fifo]);

  const finaliseFormRef = useRef<any>();

  const [isFinaliseModalVisible, setIsFinaliseModalVisible] = useState(false);

  return (
    <ResponsiveViewWrapper
      className={'form-page-container'}
      downBreakpointSm={900}
    >
      {showExternalFormCompleteMessage ? (
        <FormCompleteActions
          isFromMobileApp={isFromMobileApp}
          // @ts-ignore
          dealId={dealId}
          // @ts-ignore
          documentData={formCompleteData}
        />
      ) : (
        <>
          {isLoading || !formTemplate ? (
            <LoaderOverlay />
          ) : Object.keys(formTemplate).length === 0 ? (
            <>
              <PdfFormEditor
                templatePdfBase64={formInstance?.templatePdfBase64}
                initialFormData={formInstance?.data}
                onFormFieldChanged={(changedField) => {
                  fifo.enqueue([changedField]);
                }}
                onFinalise={() => {
                  setIsFinaliseModalVisible(true);
                }}
              />
              <Modal
                title="Finalise Form"
                isOpen={isFinaliseModalVisible}
                onClose={() => setIsFinaliseModalVisible(false)}
              >
                <Modal.Body>
                  <p className="mb-2 text-base">
                    You are about to commit your changes and generate a
                    finalised, non-editable PDF, suitable for sharing with your
                    client.
                  </p>
                  <p className="mb-2 text-base">
                    If you need to make changes later, you can always create a
                    new revision of this form.
                  </p>
                </Modal.Body>

                <Modal.Footer>
                  <Modal.Footer.Actions>
                    <PlButton
                      variant={EBtnVariant.Outline}
                      onClick={() => setIsFinaliseModalVisible(false)}
                    >
                      Cancel
                    </PlButton>
                    <PlButton
                      onClick={() => {
                        finaliseFormRef.current?.();
                      }}
                    >
                      Finalise
                    </PlButton>
                  </Modal.Footer.Actions>
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <ValidatedForm<{ [key: string]: any }>
              renderFormContents={renderFormContents}
              fieldValidators={fieldValidators}
              initialFormState={formState}
            />
          )}
        </>
      )}
    </ResponsiveViewWrapper>
  );
};

export default FormPage;

import { createAutoFifo } from '@payaca/shared-isomorphic/fifo';
import Modal from '@payaca/components/plModal/Modal';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import { PdfFormEditor } from '@payaca/components/pdf-forms/PdfFormEditor';

export const useAutoFifo = <T,>(
  {
    flush,
    maxBatchSize = 100, // Flush a maximum of 100 items at a time
    idleMillis = 500, // Wait 500ms between flushes
  }: {
    flush: (items: Array<T>) => Promise<void>;
    maxBatchSize?: number;
    idleMillis?: number;
  },
  deps: Array<any>
) => {
  const fifo = useRef<ReturnType<typeof createAutoFifo<T>>>();

  useEffect(() => {
    fifo.current = createAutoFifo<T>({
      flush,
      maxBatchSize,
      idleMillis,
    });

    return () => {
      fifo.current?.destroy().then((_unflushed) => {
        // TODO: handle unflushed items...
        // Save in local storage for later retry?
      });
      fifo.current = undefined;
    };
  }, deps);

  return {
    enqueue: (items: Array<T>) => {
      if (fifo.current && !fifo.current.isDestroyed()) {
        fifo.current.enqueue(items);
      } else {
        console.error(
          'autoFifo is unset or destroyed; dropping form field edits!'
        );
      }
    },
    destroy: () => {
      if (fifo.current && !fifo.current.isDestroyed()) {
        return fifo.current?.destroy();
      } else {
        return Promise.resolve([] as Array<T>);
      }
    },
  };
};

// flatten multiple changes to the same field
const flattenChanges = (changedFields: Array<Record<string, string>>) =>
  changedFields.reduce(
    (acc, { name, value }) => {
      if (typeof value === 'boolean') {
        acc[name] = value;
      } else {
        acc[name] = `${value}`;
      }
      return acc;
    },
    {} as Record<string, string | boolean>
  );
