import React, { FunctionComponent } from 'react';
import PayacaPhoneLink from '@payaca/components/payacaPhoneLink/PayacaPhoneLink';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';

import './ContactUsCallout.sass';

type Props = {
  title?: string;
  children?: JSX.Element | JSX.Element[] | string;
};

const ContactUsCallout: FunctionComponent<Props> = ({
  title,
  children,
}: Props): JSX.Element => {
  return (
    <div className="contact-us-callout">
      {title && <h2>{title}</h2>}
      {children && <div className="children-container">{children}</div>}
      <div className="contact-us-prompt">
        <span className="flex-grow">Need to speak to us?</span>
        <PayacaPhoneLink
          showPhoneIcon={false}
          anchorContent={
            <Button styleVariant={ButtonStyleVariant.OUTSIZE}>
              0333 050 9475
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default ContactUsCallout;
