import React, { FC, useCallback, useState } from 'react';

import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  getDealByJobId,
  getJob,
  getJobContent,
  getJobContentByJobId,
} from '@/utils/stateAccessors';
import { Deal } from '@payaca/types/dealTypes';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import * as jobsActions from '@payaca/store/jobs/jobsActions';
import ValidationResultModal from '../validationResultModal/ValidationResultModal';
import { useSelector } from '@/api/state';

type Props = {
  jobId: number;
  proceedToPreview: () => void;
};
const EditJobPreviewButton: FC<Props> = ({
  jobId,
  proceedToPreview,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [showValidationResultModal, setShowValidationResultModal] =
    useState(false);
  const [validationResult, setValidationResult] = useState<{
    isValid: boolean;
    errors: string[];
  }>();

  const job = useSelector((state) => getJob(state, jobId));
  const jobContent = useSelector((state) => getJobContentByJobId(state, jobId));

  const deal: Deal | undefined = useSelector((state) =>
    getDealByJobId(state, jobId)
  );

  const isModifyingJobLineItemsOrJobLineItemGroups: boolean = useSelector(
    (state) => {
      return (
        state.jobContent.isUpdatingJobLineItem ||
        state.jobContent.isDeletingJobLineItem ||
        state.jobContent.isCreatingJobLineItem ||
        state.jobContent.isCreatingJobLineItemGroup ||
        state.jobContent.isUpdatingJobLineItemGroup ||
        state.jobContent.isDeletingJobLineItemGroup ||
        state.jobContent.isAddingLineItemGroupToJobContent ||
        state.jobContent.isAddingAttachmentToJobLineItem ||
        state.jobContent.isRemovingAttachmentFromJobLineItem
      );
    }
  );

  const isUpdatingJob: boolean = useSelector((state) => {
    return state.jobsStore.isUpdatingJob;
  });

  const isPersistingCustomer: boolean = useSelector((state) => {
    return state.customer.isPersistingCustomer;
  });

  const isSettingDealCustomer: boolean = useSelector((state) => {
    return state.deals.isSettingDealCustomer;
  });

  const isProcessing = useMemo(() => {
    return (
      isUpdatingJob ||
      isPersistingCustomer ||
      isSettingDealCustomer ||
      isModifyingJobLineItemsOrJobLineItemGroups
    );
  }, [
    isUpdatingJob,
    isPersistingCustomer,
    isSettingDealCustomer,
    isModifyingJobLineItemsOrJobLineItemGroups,
  ]);

  const isGettingJobValidationResult: boolean = useSelector((state) => {
    return state.jobsStore.isGettingJobValidationResult;
  });

  const isRequestPreviewEnabled = useMemo(() => {
    return (
      !isProcessing &&
      !!(deal?.customerId && jobContent?.jobLineItemIds?.length)
    );
  }, [jobContent?.jobLineItemIds?.length, deal?.customerId, isProcessing]);

  const onGetJobValidationResultSuccess = useCallback(
    (validationResult: { isValid: boolean; errors: string[] }) => {
      setValidationResult(validationResult);

      if (!validationResult.isValid) {
        setShowValidationResultModal(true);
      } else {
        proceedToPreview();
      }
    },
    [proceedToPreview]
  );

  const onRequestPreview = useCallback(() => {
    dispatch(
      jobsActions.requestGetJobValidationResult(
        jobId,
        onGetJobValidationResultSuccess
      )
    );
  }, [onGetJobValidationResultSuccess, jobId, dispatch]);

  return (
    <div className="edit-job-preview-button">
      <Button
        styleVariant={ButtonStyleVariant.OUTSIZE}
        isProcessing={isGettingJobValidationResult}
        isDisabled={!isRequestPreviewEnabled}
        onClick={() => !isGettingJobValidationResult && onRequestPreview()}
      >
        Preview
      </Button>
      {validationResult && showValidationResultModal && (
        <ValidationResultModal
          title={'Preview not available'}
          validationResult={validationResult}
          isOpen={showValidationResultModal}
          onClose={() => setShowValidationResultModal(false)}
        />
      )}
    </div>
  );
};

export default EditJobPreviewButton;
