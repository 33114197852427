import React, { useState } from 'react';

import { PublicHydratedServicePlanPeriodCommitment } from '@payaca/types/service-plans';

import { updateServicePlanPeriodCommitment } from '@payaca/store/servicePlans/servicePlansActions';
import { requestPersistTask } from '@payaca/store/tasks/tasksActions';
import { linkUploadsToEntity } from '@payaca/store/uploads/uploadsActions';
import { PublicEntityTemplate } from '@payaca/types/entity-templates';
import { roundDateTimeUpToHalfHour } from '@payaca/utilities/timeUtilities';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import './ServicePlanPeriodCommitment.sass';
import ServicePlanPeriodUnarrangedCommitmentEntityCard from './ServicePlanPeriodUnarrangedCommitmentEntityCard';
import { useCustomer } from '@payaca/store/hooks/appState';
import { getBillingAddressFromCustomer } from '@payaca/helpers/customerHelper';
import { Create } from '@material-ui/icons';
import { CreateScheduledEventModal } from '../createScheduledEventModal/CreateScheduledEventModal';
import { getContactsToNotifyFromLocationContacts } from '@payaca/helpers/scheduledEventsHelper';

type Props = {
  commitment: PublicHydratedServicePlanPeriodCommitment;
  onCommitmentUpdate: (newEntity?: {
    id: number;
    type: 'scheduledEvent' | 'task';
  }) => void;
};
const ServicePlanPeriodUnarrangedEventCommitment = ({
  commitment,
  onCommitmentUpdate,
}: Props) => {
  const dispatch = useDispatch();

  const [showCreateScheduledEventModal, setShowCreateScheduledEventModal] =
    useState(false);
  const templateData = (
    commitment.entityTemplate as PublicEntityTemplate<'scheduledEvent'>
  ).data;

  const beginAt = roundDateTimeUpToHalfHour(new Date()).toDate();
  const endAt = templateData.duration
    ? moment(beginAt).add(templateData.duration).toDate()
    : moment(beginAt).add(1, 'hours').toDate();

  const customer = useCustomer(commitment.customerId);
  const billingAddress = getBillingAddressFromCustomer(customer);

  return (
    <>
      <ServicePlanPeriodUnarrangedCommitmentEntityCard
        commitmentId={commitment.publicId}
        name={templateData.name}
        onArrange={() => setShowCreateScheduledEventModal(true)}
        entityType="scheduledEvent"
        onCommitmentUpdate={onCommitmentUpdate}
      />
      <CreateScheduledEventModal
        onClose={() => setShowCreateScheduledEventModal(false)}
        isOpen={showCreateScheduledEventModal}
        initialEventData={{
          name: templateData.name,
          description: templateData.description,
          includeCustomerInformation:
            templateData.includeCustomerInformation ?? true,
          tagIds: templateData.tagIds as number[] | undefined,
          taskIds: [],
          uploadIds: templateData.uploadIds || [],
          beginAt: roundDateTimeUpToHalfHour(new Date()).toDate(),
          endAt: endAt,
          customerIds: [commitment.customerId],
          location: billingAddress
            ? {
                id: billingAddress.address.id,
                address: billingAddress.address,
                contacts: billingAddress.contacts,
              }
            : undefined,
          tasksToCreate: templateData.tasks?.map((taskTemplate) => {
            const taskTemplateData = taskTemplate.data;
            return {
              name: taskTemplateData.name || 'unnamed task',
              notes: taskTemplateData.notes,
              checklistItems: taskTemplateData.checklistItems,
              formTemplateId: taskTemplateData.formTemplateId
                ? +taskTemplateData.formTemplateId
                : undefined,
            };
          }),
          contactsToNotify: getContactsToNotifyFromLocationContacts(
            billingAddress?.contacts || []
          ),
        }}
        hiddenFields={['dealId']}
        disableTemplateSelect={true}
        disabledFields={['customerIds']}
        onSuccess={(scheduledEventId) => {
          const promises: Promise<void>[] = [];

          setShowCreateScheduledEventModal(false);

          dispatch(
            updateServicePlanPeriodCommitment.request({
              servicePlanPeriodCommitment: {
                publicId: commitment.publicId,
                entityId: scheduledEventId.toString(),
              },
              callback: () =>
                onCommitmentUpdate({
                  id: scheduledEventId,
                  type: 'scheduledEvent',
                }),
            })
          );
        }}
      />
    </>
  );
};

export default ServicePlanPeriodUnarrangedEventCommitment;
