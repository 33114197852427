import { Reducer } from 'redux';

import { ActionType, State } from './userTypes';

export const initialState: State = {
  isMakingPrimaryUser: false,
  isPrimaryUserMadeSuccessfully: undefined,

  isGettingTermsAndPrivacyUpdates: false,
  updatedTermsAndPrivacy: null,

  isAgreeingToTermsAndPrivacyVersion: false,
  agreedToTermsAndPrivacyVersionSuccess: false,

  isUpdatingPassword: false,
  isUpdatedPasswordSuccessfully: null,
  updatePasswordError: null,

  isUpdatingProfile: false,

  isGettingAccountTerms: false,

  terms: [],
};

const userReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_MAKE_PRIMARY_USER:
      return {
        ...state,
        isMakingPrimaryUser: true,
        isPrimaryUserMadeSuccessfully: undefined,
      };
    case ActionType.MAKE_PRIMARY_USER_SUCCESS:
      return {
        ...state,
        isMakingPrimaryUser: false,
        isPrimaryUserMadeSuccessfully: true,
      };
    case ActionType.MAKE_PRIMARY_USER_FAILURE:
      return {
        ...state,
        isMakingPrimaryUser: false,
        isPrimaryUserMadeSuccessfully: false,
      };
    case ActionType.REQUEST_GET_TERMS_AND_PRIVACY_UPDATES:
      return {
        ...state,
        isGettingTermsAndPrivacyUpdates: true,
        updatedTermsAndPrivacy: null,
      };
    case ActionType.GET_TERMS_AND_PRIVACY_UPDATES_SUCCESS:
      return {
        ...state,
        isGettingTermsAndPrivacyUpdates: false,
        updatedTermsAndPrivacy: action.payload.updatedTermsAndPrivacy,
      };
    case ActionType.GET_TERMS_AND_PRIVACY_UPDATES_FAILURE:
      return {
        ...state,
        isGettingTermsAndPrivacyUpdates: false,
      };

    case ActionType.REQUEST_AGREE_TO_TERMS_AND_PRIVACY_VERSION:
      return {
        ...state,
        isAgreeingToTermsAndPrivacyVersion: true,
      };
    case ActionType.AGREE_TO_TERMS_AND_PRIVACY_VERSION_SUCCESS:
      return {
        ...state,
        isAgreeingToTermsAndPrivacyVersion: false,
        agreedToTermsAndPrivacyVersionSuccess: true,
      };
    case ActionType.AGREE_TO_TERMS_AND_PRIVACY_VERSION_FAILURE:
      return {
        ...state,
        isAgreeingToTermsAndPrivacyVersion: false,
        updatedTermsAndPrivacy: null,
        agreedToTermsAndPrivacyVersionSuccess: false,
      };

    case ActionType.CLEAR_TERMS_AND_PRIVACY_UPDATES:
      return {
        ...state,
        isGettingTermsAndPrivacyUpdates: false,
        updatedTermsAndPrivacy: null,
        isAgreeingToTermsAndPrivacyVersion: false,
        agreedToTermsAndPrivacyVersionSuccess: null,
      };

    case ActionType.REQUEST_UPDATE_PASSWORD:
      return {
        ...state,
        isUpdatingPassword: true,
        isUpdatedPasswordSuccessfully: null,
        updatePasswordError: null,
      };
    case ActionType.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isUpdatingPassword: false,
        isUpdatedPasswordSuccessfully: true,
        updatePasswordError: null,
      };
    case ActionType.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        isUpdatingPassword: false,
        updatePasswordError: action.payload.errorMessage,
        isUpdatedPasswordSuccessfully: false,
      };
    case ActionType.CLEAR_UPDATE_PASSWORD:
      return {
        ...state,
        isUpdatingPassword: false,
        isUpdatedPasswordSuccessfully: null,
        updatePasswordError: null,
      };

    case ActionType.REQUEST_GET_ACCOUNT_TERMS:
      return {
        ...state,
        isGettingAccountTerms: true,
      };
    case ActionType.GET_ACCOUNT_TERMS_SUCCESS:
      return {
        ...state,
        isGettingAccountTerms: false,
        terms: action.payload.terms,
      };
    case ActionType.GET_ACCOUNT_TERMS_FAILURE:
      return {
        ...state,
        isGettingAccountTerms: false,
      };

    case ActionType.REQUEST_UPDATE_PROFILE:
      return {
        ...state,
        isUpdatingProfile: true,
      };
    case ActionType.UPDATE_PROFILE_SUCCESS:
    case ActionType.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        isUpdatingProfile: false,
      };

    default:
      return state;
  }
};

export default userReducer;
