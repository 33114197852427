import React, { FunctionComponent, useEffect } from 'react';
import './SentQuotesChart.css';
import { AggregateValueBarChart, CategoriesKey } from '@/ui/components';
import { useDispatch } from 'react-redux';
import * as analyticsActions from '@payaca/store/analytics/analyticsActions';
import { AggregateValueChartData } from '@payaca/types/analyticsTypes';
import * as d3 from 'd3';
import { useSelector } from '@/api/state';

const formatXAxisTickValue = (value: number) => {
  return Number.isInteger(value) ? d3.format('d')(value) : '';
};

const SentQuotesChart: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch();

  const sentQuotesAggregateChartData: AggregateValueChartData | null =
    useSelector((state) => {
      return state.analytics.sentQuotesAggregateChartData;
    });

  useEffect(() => {
    dispatch(analyticsActions.requestGetSentQuotesAggregateChartData());
    return () => {
      dispatch(analyticsActions.clearSentQuotesAggregateChartData());
    };
  }, []);

  return (
    <div className={`sent-quotes-chart`}>
      <CategoriesKey
        categories={sentQuotesAggregateChartData?.aggregateCategories}
      />
      <AggregateValueBarChart
        data={sentQuotesAggregateChartData}
        xAxisLabel="Quotes sent"
        yAxisLabel="Month sent"
        overrideChartPadding={{ left: 75, bottom: 35, top: 0, right: 10 }}
        formatXAxisTickValue={formatXAxisTickValue}
      />
    </div>
  );
};

export default SentQuotesChart;
