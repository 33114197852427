import React, { FunctionComponent } from 'react';
import './UserSeatsExceededWarning.css';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

type Props = {
  selectedUserSeats: number;
  bonusAdditionalUserSeats: number;
  activeUsersCount: number;
};

const UserSeatsExceededWarning: FunctionComponent<Props> = ({
  selectedUserSeats,
  bonusAdditionalUserSeats,
  activeUsersCount,
}: Props): JSX.Element | null => {
  const history = useHistory();
  return (
    <div className="user-seats-exceeded-warning">
      <p>
        You are trying to select a subscription plan with{' '}
        <strong>
          {selectedUserSeats} Users{' '}
          {bonusAdditionalUserSeats
            ? `(plus ${bonusAdditionalUserSeats} bonus extra Users)`
            : ''}
        </strong>{' '}
        but there are currently <strong>{activeUsersCount} Users</strong>{' '}
        associated with your account.
      </p>
      <p>To resolve this, please either:</p>
      <ul>
        <li>Select a higher plan to accommodate all current Users</li>
        <li>
          Delete some of the Users associated with your account:{' '}
          <a
            onClick={() => {
              history.push('/users');
            }}
          >
            Go to Users <FontAwesomeIcon icon={faAngleRight} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default UserSeatsExceededWarning;
