import { TimelogsPermissions } from './timelogs.permissions';
import { TimelogsRoles } from './timelogs.roles';

export const timelogsMappings = {
  [TimelogsPermissions.PREVIEW_TIMELOGS]: [TimelogsRoles.PREVIEWER],
  [TimelogsPermissions.GET_TIMELOGS]: [TimelogsRoles.VIEWER],
  [TimelogsPermissions.GET_SELF_ASSIGNED_TIMELOGS]: [
    TimelogsRoles.VIEWER,
    TimelogsRoles.ASSIGNEE_VIEWER,
  ],
  [TimelogsPermissions.CREATE_TIMELOGS]: [TimelogsRoles.CREATOR],
  [TimelogsPermissions.CREATE_SELF_ASSIGNED_TIMELOGS]: [
    TimelogsRoles.CREATOR,
    TimelogsRoles.ASSIGNEE_CREATOR,
  ],
  [TimelogsPermissions.UPDATE_TIMELOGS]: [TimelogsRoles.UPDATER],
  [TimelogsPermissions.UPDATE_SELF_ASSIGNED_TIMELOGS]: [
    TimelogsRoles.UPDATER,
    TimelogsRoles.ASSIGNEE_UPDATER,
  ],
  [TimelogsPermissions.DELETE_TIMELOGS]: [TimelogsRoles.DELETER],
  [TimelogsPermissions.DELETE_SELF_ASSIGNED_TIMELOGS]: [
    TimelogsRoles.DELETER,
    TimelogsRoles.ASSIGNEE_DELETER,
  ],
  [TimelogsPermissions.GET_TIMELOG_TYPES]: [TimelogsRoles.TYPES_VIEWER],
  [TimelogsPermissions.MANAGE_TIMELOG_TYPES]: [TimelogsRoles.TYPES_MANAGER],
  [TimelogsPermissions.SET_TIMELOG_COST_PER_HOUR]: [
    TimelogsRoles.COST_ADMINISTRATOR,
  ],
  [TimelogsPermissions.GET_TIMELOG_COST_PER_HOUR]: [
    TimelogsRoles.COST_ADMINISTRATOR,
  ],
};
