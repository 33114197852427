import { Nullable, Nullish, Optional } from './types';

export const isNull = <T>(x: Nullable<T>): x is null => x === null;

export const isNotNull = <T>(x: Nullable<T>): x is T => x !== null;

export const isUndefined = <T>(x: Optional<T>): x is undefined =>
  x === undefined;

export const isDefined = <T>(x: Optional<T>): x is T => x !== undefined;

export const isNullish = <T>(x: Nullish<T>): x is null | undefined =>
  isNull(x) || isUndefined(x);

export const isNotNullish = <T>(x: Nullish<T>): x is T => !isNullish(x);
