import { getTasksByDealId } from '@/utils/stateAccessors';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';
import { UpdateScheduledEventRequestData } from '@payaca/store/scheduledEvents/scheduledEventsTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import * as scheduledEventActions from '@payaca/store/scheduledEvents/scheduledEventsActions';

import './ScheduledEventLinkedTasksControl.sass';
import TaskChip from '../taskChip/TaskChip';
import LinkCheckboxField from '@payaca/components/linkCheckboxField/LinkCheckboxField';
import { useSelector } from '@/api/state';

type Props = {
  scheduledEvent: ScheduledEvent;
  onPersistScheduledEventSuccess?: () => void;
};
const ScheduledEventLinkedTasksControl: FC<Props> = ({
  scheduledEvent,
  onPersistScheduledEventSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const isUpdatingScheduledEvent = useSelector((state) => {
    return state.scheduledEvents.isUpdatingScheduledEvent;
  });

  const tasks: any[] = useSelector((state) =>
    scheduledEvent.dealId ? getTasksByDealId(state, scheduledEvent.dealId) : []
  );

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      dispatch(
        scheduledEventActions.requestUpdateScheduledEvent(
          formState as UpdateScheduledEventRequestData,
          onPersistScheduledEventSuccess
        )
      );
    },
    [dispatch, onPersistScheduledEventSuccess]
  );

  const initialFormState = useMemo(() => {
    return {
      id: scheduledEvent.id,
      taskIds: scheduledEvent.taskIds,
      beginAt: scheduledEvent.beginAt,
      endAt: scheduledEvent.endAt,
      name: scheduledEvent.name,
    };
  }, [scheduledEvent]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <div className="form-body">
            {tasks.map((task, i) => {
              return (
                <div
                  className="flex-container"
                  key={`task-selection-control-${i}`}
                >
                  <LinkCheckboxField
                    value={formState.taskIds?.includes(task.id)}
                    name="isSelected"
                    onChange={(value) => {
                      const isSelected = value.isSelected;
                      if (isSelected) {
                        onFieldChange({
                          taskIds: [...formState.taskIds, task.id],
                        });
                      } else {
                        onFieldChange({
                          taskIds: formState.taskIds.filter(
                            (x: number) => x != task.id
                          ),
                        });
                      }
                    }}
                  />
                  <TaskChip task={task} />
                </div>
              );
            })}
          </div>
          <div className="actions-container">
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isProcessing={isUpdatingScheduledEvent}
              onClick={() => !isUpdatingScheduledEvent && onSubmit(formState)}
            >
              Save
            </Button>
          </div>
        </>
      );
    },
    [tasks, onSubmit, isUpdatingScheduledEvent]
  );

  return (
    <div className="scheduled-event-linked-tasks-control">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
      />
    </div>
  );
};

export default ScheduledEventLinkedTasksControl;
