import React, { FC, useMemo } from 'react';
import moment from 'moment-timezone';

import CompanyLogo from '../companyLogo/CompanyLogo';
import OutlinedContentPanel, {
  ContentPanelPaddingVariant,
  ContentPanelTitleStyleVariant,
} from '../outlinedContentPanel/OutlinedContentPanel';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import { useCommonFormAccountData } from './hooks';

import { isNotNullish } from '@payaca/utilities/guards';

import './FormTemplateElements.sass';
import { SignatureElement } from './SignatureElement';
import './ElectricalInstallationConditionReport18-2.sass';

import NiceicLogo from './niceic-logo.png';

interface Props {
  data: any;
  account: any;
}

const createAddressString = (addressComponents: any) =>
  [
    addressComponents.line_1,
    addressComponents.line_2,
    addressComponents.line_3,
    addressComponents.town,
    addressComponents.county,
    addressComponents.postcode,
  ]
    .filter(Boolean)
    .join(', ');
const defaultToNa = (value: string) =>
  isNotNullish(value) && value !== '' ? value : 'N/A';

const ElectricalInstallationConditionReport18x2: FC<Props> = ({
  data,
  account,
}) => {
  const { brandColour } = useCommonFormAccountData(account);

  const certificateNumber = useMemo(() => {
    if (data) {
      return `${data.serial_no}${data.revision ? `-${data.revision}` : ''}`;
    }
    return '';
  }, [data]);

  if (!data || !account) return <></>;

  const contractorAddressString = createAddressString(data.contractor_address);
  const clientAddressString = createAddressString(data.client_address);
  const installationAddressString = createAddressString(
    data.installation_address
  );

  console.log(data);

  return (
    <div
      className={
        'form-template-output electrical-installation-condition-report-template version-18-2'
      }
    >
      <div className={'form-template-header'}>
        <div>
          {account?.logoUrl && <CompanyLogo logoUrl={account.logoUrl} />}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3>
              Domestic Electrical Installation Condition Report (Single phase,
              up to 100A)
            </h3>
            <p>
              Issued in accordance with BS 7671, as amended - Requirements for
              Electrical Installations
            </p>
          </div>
        </div>
        <div className={'flex-container flex-center'}>
          <div className="header-details-container">
            <LabelValuePair label="Cert No" value={certificateNumber} />
          </div>
        </div>
        {data.niceic_reg_number && (
          <img className="niceic-logo" src={NiceicLogo} alt={'NICEIC Logo'} />
        )}
      </div>
      <div className="form-template-row company-inspection-landlord-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          className="company-details-content-panel"
          title={'PART 1a: Details of the contractor'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="company-details-content-panel-body">
            <LabelValuePair
              label="Registration number"
              value={data.contractor_reg_no}
            />
            <LabelValuePair
              label="Branch No."
              value={data.contractor_branch_no}
            />
            <LabelValuePair
              label="Trading Title"
              value={data.contractor_trading_title}
            />
            {data.niceic_reg_number && (
              <LabelValuePair
                label="NICEIC Reg. Number"
                value={data.niceic_reg_number}
              />
            )}
            <LabelValuePair label="Address" value={contractorAddressString} />
            <LabelValuePair label="Tel No." value={data?.contractor_tel_no} />
          </div>
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 1b: Details of the client'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="Contractor Reference Number (CRN)"
            value={data.client_contractor_ref_no}
          />
          <LabelValuePair label="Name" value={data.client_name} />
          <LabelValuePair label="Address" value={clientAddressString} />
          <LabelValuePair label="Tel No." value={data.client_tel_no} />
        </OutlinedContentPanel>
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 1c: Details of the installation'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair label="Occupier" value={`${data.occupier_name}`} />
          <LabelValuePair label="Address" value={installationAddressString} />
          <LabelValuePair label="Tel No." value={data.occupier_tel_no} />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 2: Purpose of the report'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="Purpose for which this report is required"
            value={data.report_purpose}
          />
          <div className="label-values-in-row">
            <div className="label-values-in-row-title">
              Date(s) when inspection and testing was carried out
            </div>
            <LabelValuePair
              label="From"
              value={moment(data.report_inspection_date.from).format(
                'DD/MM/YYYY'
              )}
            />
            <LabelValuePair
              label="To"
              value={moment(data.report_inspection_date.to).format(
                'DD/MM/YYYY'
              )}
            />
            <LabelValuePair
              label="Records available"
              value={data.report_records_available}
            />
            <LabelValuePair
              label="Previous inspection report available"
              value={data.report_prev_report_available}
            />
          </div>
          <LabelValuePair
            label="Previous report date"
            value={moment(data.report_prev_report_date).format('DD/MM/YYYY')}
          />
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 3: Summary of the condition of the installation'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="General condition of the installation (in terms of electrical safety)"
            value={data.installation_summary_condition}
          />
          <h2>Description of premises</h2>
          <LabelValuePair
            label="Dwelling"
            value={data.installation_summary_description_of_premises?.dwelling}
          />
          <LabelValuePair
            label="Commercial"
            value={
              data.installation_summary_description_of_premises?.commercial
            }
          />
          <LabelValuePair
            label="Industrial"
            value={
              data.installation_summary_description_of_premises?.industrial
            }
          />
          <LabelValuePair
            label="Other"
            value={data.installation_summary_description_of_premises?.other}
          />
          {/* hellooooooo new stuff here, description of premises, dwelling, commercial, industrial, other */}
          <div className="label-values-in-row">
            <LabelValuePair
              label="Estimated age of electrical installation"
              value={`${data.installation_summary_estimated_age} years`}
            />
            <LabelValuePair
              label="Evidence of additions or alterations"
              value={data.installation_summary_evidence_of_additions}
            />
            <LabelValuePair
              label="If yes, estimated age (years)"
              value={data.installation_summary_alterations_estimated_age}
            />
            <LabelValuePair
              label="Overall assessment of the installation is"
              value={data.installation_summary_overall_assessment}
            />
          </div>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row test-results-declaration no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title={'PART 4: Declaration'}
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
          className="declaration"
        >
          <div className="label-values-in-row-title">Inspection & testing</div>
          <p>
            I/We, being the person responsible for the inspection and testing of
            the electrical installation (as indicated by my/our signature
            below), particulars of which are described in PART 6, having
            exercised reasonable skill and care when carrying out the inspection
            and testing, hereby declare that the information in this report,
            including the observations (PART 5) and the attached Schedules,
            provides an accurate assessment of the condition of the electrical
            installation taking into account the stated extent and limitations
            in PART 6 of this report.
          </p>
          <div className="label-values-in-row">
            <LabelValuePair
              label="Name"
              value={data.declaration_inspector_name.toLocaleUpperCase()}
            />
            <LabelValuePair
              label="Signature"
              value={
                <div className={'form-template-signature'}>
                  {data.declaration_inspector_signature && (
                    <img src={data.declaration_inspector_signature} />
                  )}
                </div>
              }
            />
            <LabelValuePair
              label="Date"
              value={moment(data.declaration_inspector_date).format(
                'DD/MM/YYYY'
              )}
            />
          </div>
          <LabelValuePair
            label="I/We further RECOMMEND, subject to the necessary remedial action being taken, that the installation is inspected and tested by"
            value={data.declaration_inspector_followup_inspection_date}
          />
          <LabelValuePair
            label="Give reason for recommendation"
            value={data.declaration_inspector_followup_inspection_reason}
          />
          <div className="label-values-in-row-title">
            Reviewed by the registered qualified supervisor for the approved
            contractor
          </div>
          <div className="label-values-in-row">
            <LabelValuePair
              label="Name"
              value={data.declaration_reviewer_name.toLocaleUpperCase()}
            />
            <LabelValuePair
              label="Signature"
              value={
                <div className={'form-template-signature'}>
                  {data.declaration_reviewer_signature && (
                    <img src={data.declaration_reviewer_signature} />
                  )}
                </div>
              }
            />
            <LabelValuePair
              label="Date"
              value={moment(data.declaration_reviewer_date).format(
                'DD/MM/YYYY'
              )}
            />
          </div>
        </OutlinedContentPanel>
      </div>

      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 5: Observations"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="No remedial action is required"
            value={data.no_observations ? '✓' : '✗'}
          />
          {data.observations_and_recommendations && (
            <table className="data-table">
              <thead>
                <tr>
                  <th>Item No.</th>
                  <th>Observation(s)</th>
                  <th>Code</th>
                  <th>Location Reference</th>
                </tr>
              </thead>
              <tbody>
                {data.observations_and_recommendations?.map(
                  (observation: any, i: number) => (
                    <tr key={`observations-and-recommendations-${i}`}>
                      <td>{i + 1}</td>
                      <td>{observation.observation}</td>
                      <td>{observation.code}</td>
                      <td>{observation.location_reference}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          )}
          <div className="label-values-in-row">
            <LabelValuePair
              label="'Immediate action required' for items"
              value={
                data.observations_and_recommendations
                  ?.map((x: any, i: number) => ({ ...x, index: i + 1 }))
                  .filter((x: any) => x.code === 'C1')
                  .map((x: any) => x.index)
                  .join(', ') || 'None'
              }
            />
            <LabelValuePair
              label="'Improvement recommended' for items"
              value={
                data.observations_and_recommendations
                  ?.map((x: any, i: number) => ({ ...x, index: i + 1 }))
                  .filter((x: any) => x.code === 'C3')
                  .map((x: any) => x.index)
                  .join(', ') || 'None'
              }
            />
          </div>
          <div className="label-values-in-row">
            <LabelValuePair
              label="'Urgent remedial action required' for items"
              value={
                data.observations_and_recommendations
                  ?.map((x: any, i: number) => ({ ...x, index: i + 1 }))
                  .filter((x: any) => x.code === 'C2')
                  .map((x: any) => x.index)
                  .join(', ') || 'None'
              }
            />
            <LabelValuePair
              label="'Further investigation required without delay' for items"
              value={
                data.observations_and_recommendations
                  ?.map((x: any, i: number) => ({ ...x, index: i + 1 }))
                  .filter((x: any) => x.code === 'FI')
                  .map((x: any) => x.index)
                  .join(', ') || 'None'
              }
            />
          </div>
        </OutlinedContentPanel>
      </div>
      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 6: Details and limitations of the inspection and testing"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <LabelValuePair
            label="Details of the installation covered by this report"
            value={data.details_and_limitations_details}
          />
          <LabelValuePair
            label="Agreed limitations including the reasons, if any, on the inspection and testing"
            value={data.details_and_limitations_agreed_limitations}
          />
          <LabelValuePair
            label="Agreed with"
            value={data.details_and_limitations_agreed_with.toLocaleUpperCase()}
          />
          <LabelValuePair
            label="Extent of sampling"
            value={data.details_and_limitations_extent_of_sampling}
          />
          <LabelValuePair
            label="Operational limitations including the reasons"
            value={data.details_and_limitations_operational_limitations}
          />
        </OutlinedContentPanel>
      </div>

      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 7: Supply characteristics and earthing arrangements"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="columns-3">
            <LabelValuePair
              label="System type and earthing arrangements"
              value={data.supply_characteristics_system_type}
            />

            <section className="no-column-break">
              <h2>Supply protective device</h2>
              <LabelValuePair
                label="BS (EN)"
                value={
                  data.supply_characteristics_supplier_protective_device_bsen
                }
              />
              <LabelValuePair
                label="Type"
                value={
                  data.supply_characteristics_supplier_protective_device_type
                }
              />
              <LabelValuePair
                label="Rated current"
                value={`${data.supply_characteristics_supplier_protective_device_rated_current} A`}
              />
            </section>
            <h2>Number and type of live conductors</h2>

            <LabelValuePair
              label="AC"
              value={
                data.supply_characteristics_number_type_live_conductors?.ac
              }
            />
            <LabelValuePair
              label="DC"
              value={
                data.supply_characteristics_number_type_live_conductors?.dc
              }
            />
            <LabelValuePair
              label="Other (state)"
              value={
                data.supply_characteristics_number_type_live_conductors?.other
              }
            />

            <LabelValuePair
              label="Confirmation of supply polarity"
              value={data.supply_characteristics_supply_polarity_confirmation}
            />
            <section className="no-column-break">
              <h2>Other sources of supply</h2>

              {data.supply_characteristics_other_sources_of_supply?.map(
                (x: any, i: number) => (
                  <LabelValuePair
                    label={`${i + 1}`}
                    value={x.description}
                    key={`supply-characteristics-other-sources-of-supply-${i}`}
                  />
                )
              )}
            </section>
            <section className="no-column-break">
              <h2>Nature of supply parameters</h2>
              <LabelValuePair
                label="Nominal line voltage, U (V)"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .nominal_line_voltage
                }
              />
              <LabelValuePair
                label="Nominal line voltage to Earth, U0 (V)"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .nominal_line_voltage_to_earth
                }
              />
              <LabelValuePair
                label="Nominal frequency, f (Hz)"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .nominal_frequency
                }
              />
              <LabelValuePair
                label="Prospective fault current, Ipf (kA)"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .prospective_fault_current
                }
              />
              <LabelValuePair
                label="External loop impedance, Ze (Ohms)"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .external_loop_impedance
                }
              />
              <LabelValuePair
                label="Tick if Ze is by Enquiry"
                value={
                  data.supply_characteristics_nature_of_supply_parameters
                    .by_enquiry
                    ? '✓'
                    : '✗'
                }
              />
            </section>
          </div>
        </OutlinedContentPanel>
      </div>

      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 8: Particulars of installation referred to in this report"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="columns-4">
            <LabelValuePair
              label="Maximum demand (load)"
              value={`${data.maximum_demand} ${data.maximum_demand_unit}`}
            />
            <section className="no-column-break">
              <h2>Means of Earthing</h2>

              <LabelValuePair
                label="Means of Earthing"
                value={data.particulars_means_of_earthing}
              />
              <LabelValuePair
                label="Type"
                value={data?.particulars_earth_electrode?.type}
              />
              <LabelValuePair
                label="Location"
                value={data?.particulars_earth_electrode?.location}
              />
              <LabelValuePair
                label="Electrode resistance to Earth"
                value={data?.particulars_earth_electrode?.resistance}
              />
            </section>
            <section className="no-column-break">
              <h2>Main protective conductors</h2>
              <LabelValuePair
                label="Material"
                value={
                  data.particulars_main_protective_conductors_earthing_conductor
                    .material
                }
              />
              <LabelValuePair
                label="CSA"
                value={
                  data.particulars_main_protective_conductors_earthing_conductor
                    .csa
                }
              />
              <LabelValuePair
                label="Connection / continuity verified"
                value={
                  data.particulars_main_protective_conductors_earthing_conductor
                    .connection_verified
                }
              />
            </section>
            <section className="no-column-break">
              <h2>Main protective bonding conductors</h2>

              <LabelValuePair
                label="Material"
                value={
                  data
                    .particulars_main_protective_conductors_main_protective_bonding_conductors
                    .material
                }
              />
              <LabelValuePair
                label="CSA"
                value={
                  data
                    .particulars_main_protective_conductors_main_protective_bonding_conductors
                    .csa
                }
              />
              <LabelValuePair
                label="Connection / continuity verified"
                value={
                  data
                    .particulars_main_protective_conductors_main_protective_bonding_conductors
                    .connection_verified
                }
              />
            </section>
            <section className="no-column-break">
              <h2>Main protective bonding connections</h2>
              <LabelValuePair
                label="Water installation pipes"
                value={
                  data.particulars_main_protective_bonding_connections.water
                }
              />
              <LabelValuePair
                label="Gas installation pipes"
                value={data.particulars_main_protective_bonding_connections.gas}
              />
              <LabelValuePair
                label="Structural steel"
                value={
                  data.particulars_main_protective_bonding_connections.steel
                }
              />
              <LabelValuePair
                label="Oil installation pipes"
                value={data.particulars_main_protective_bonding_connections.oil}
              />
              <LabelValuePair
                label="Lightning protection"
                value={
                  data.particulars_main_protective_bonding_connections.lightning
                }
              />
              <LabelValuePair
                label="Other"
                value={
                  data.particulars_main_protective_bonding_connections.other
                }
              />
            </section>
            <section className="no-column-break">
              <h2>Main switch / Switch-fuse / Circuit-breaker / RCD</h2>

              <LabelValuePair
                label="Type (BS (EN))"
                value={data.particulars_main_switch_type}
              />
              <LabelValuePair
                label="Location"
                value={data.particulars_main_switch_location}
              />
              <LabelValuePair
                label="No. of poles"
                value={data.particulars_main_switch_num_poles}
              />
              <LabelValuePair
                label="Current rating"
                value={data.particulars_main_switch_current_rating}
              />
              <LabelValuePair
                label="Rating / setting of device"
                value={data.particulars_main_switch_rating_setting}
              />
              <LabelValuePair
                label="Voltage rating"
                value={data.particulars_main_switch_voltage_rating}
              />
              <LabelValuePair
                label="RCD rated residual operating current, IΔn"
                value={
                  data.particulars_main_switch_rcd.residual_operating_current
                }
              />
              <LabelValuePair
                label="RCD type"
                value={data.particulars_main_switch_rcd.rcd_type}
              />
              <LabelValuePair
                label="Measured operating time"
                value={data.particulars_main_switch_rcd.measured_operating_time}
              />
              <LabelValuePair
                label="Rated time delay"
                value={data.particulars_main_switch_rcd.rated_time_delay}
              />
            </section>
          </div>
        </OutlinedContentPanel>
      </div>
      {/* use h2s, h3s and dividers to differentiate between sections */}
      <div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 10: Schedule of items inspected"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="columns-3">
            <section>
              <h2>1.0 Intake equipment (visual inspection only)</h2>
              <section className="no-column-break">
                <h3>1.1 Distributor / supplier intake equipment</h3>
                <LabelValuePair
                  label="Service cable"
                  value={data['schedule__intake-equipment__service-cable']}
                />
                <LabelValuePair
                  label="Service head"
                  value={data['schedule__intake-equipment__service-head']}
                />
                <LabelValuePair
                  label="Earthing arrangement"
                  value={
                    data['schedule__intake-equipment__earthing-arrangements']
                  }
                />
                <LabelValuePair
                  label="Meter tails"
                  value={data['schedule__intake-equipment__meter-tails']}
                />
                <LabelValuePair
                  label="Metering equipment"
                  value={data['schedule__intake-equipment__metering-equipment']}
                />
                <LabelValuePair
                  label="Isolator (where present)"
                  value={data['schedule__intake-equipment__isolator']}
                />
              </section>
              <LabelValuePair
                label="1.2 Consumer's isolator (where present)"
                value={data['schedule__intake-equipment__consumers-isolator']}
              />
              <LabelValuePair
                label="1.3 Consumer's meter tails"
                value={
                  data['schedule__intake-equipment__consumers-meter-tails']
                }
              />
            </section>
            <hr />
            <section>
              <h2>2.0 Presence of adequate arrangements for other sources</h2>
              <LabelValuePair
                label="2.1 Adequate arrangements where a generating set operates as a
                  switched alternative to the public supply"
                value={
                  data[
                    'schedule-of-items__other-source-arrangements__generating-set-switched-alternative'
                  ]
                }
              />
              <LabelValuePair
                label="2.2 Adequate arrangements where generating set operates in parallel with the public supply"
                value={
                  data[
                    'schedule-of-items__other-source-arrangements__generating-set-parallel'
                  ]
                }
              />
            </section>
            <hr />
            <section>
              <h2>3.0 Automatic disconnection of supply</h2>
              <section className="no-column-break">
                <h3>3.1 Automatic disconnection of supply (ADS)</h3>
                <LabelValuePair
                  label="Main earthing / bonding arrangement"
                  value={
                    data['schedule-of-items__earthing-and-bonding__ads'][
                      'main-earthing-bonding-arrangement'
                    ]
                  }
                />
                <LabelValuePair
                  label="Presence of distributor’s earthing arrangement, or presence of installation earth electrode arrangement "
                  value={
                    data['schedule-of-items__earthing-and-bonding__ads'][
                      'presence-of-distributors-earthing-arrangement'
                    ]
                  }
                />
                <LabelValuePair
                  label="Adequacy of earthing conductor size"
                  value={
                    data['schedule-of-items__earthing-and-bonding__ads'][
                      'earthing-conductor-size'
                    ]
                  }
                />
                <LabelValuePair
                  label="Adequacy of earthing conductor connections"
                  value={
                    data['schedule-of-items__earthing-and-bonding__ads'][
                      'earthing-conductor-connections'
                    ]
                  }
                />
                <LabelValuePair
                  label="Accessibility of earthing conductor connections"
                  value={
                    data['schedule-of-items__earthing-and-bonding__ads'][
                      'earthing-conductor-connections-accessibility'
                    ]
                  }
                />
                <LabelValuePair
                  label="Adequacy of main protective bonding conductor sizes"
                  value={
                    data['schedule-of-items__earthing-and-bonding__ads'][
                      'adequacy-of-main-protective-bonding-conductor-sizes'
                    ]
                  }
                />

                <LabelValuePair
                  label="Adequacy and location of main protective bonding conductor connections"
                  value={
                    data['schedule-of-items__earthing-and-bonding__ads'][
                      'adequacy-and-location-of-main-protective-bonding-conductor-connections'
                    ]
                  }
                />
                <LabelValuePair
                  label="Accessibility of all protective bonding connections"
                  value={
                    data['schedule-of-items__earthing-and-bonding__ads'][
                      'accessibility-of-all-protective-bonding-connections'
                    ]
                  }
                />
                <LabelValuePair
                  label="Provision of earthing / bonding labels at all appropriate locations"
                  value={
                    data['schedule-of-items__earthing-and-bonding__ads'][
                      'provision-of-earthing-bonding-labels-at-all-appropriate-locations'
                    ]
                  }
                />
              </section>
              <LabelValuePair
                label="3.2 FELV - requirements satisfied"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__felv-requirements-satisfied'
                  ]
                }
              />
              <section className="no-column-break">
                <h3>3.3 Other methods of protection</h3>
                <LabelValuePair
                  label="Non-conducting location"
                  value={
                    data[
                      'schedule-of-items__earthing-and-bonding__other-protection-methods'
                    ]['non-conducting-location']
                  }
                />
                <LabelValuePair
                  label="Earth-free local equipotential bonding"
                  value={
                    data[
                      'schedule-of-items__earthing-and-bonding__other-protection-methods'
                    ]['earth-free-local-equipotential-bonding']
                  }
                />
                <LabelValuePair
                  label="Electrical separation"
                  value={
                    data[
                      'schedule-of-items__earthing-and-bonding__other-protection-methods'
                    ]['electrical-separation']
                  }
                />
                <LabelValuePair
                  label="Double insulation"
                  value={
                    data[
                      'schedule-of-items__earthing-and-bonding__other-protection-methods'
                    ]['double-insulation']
                  }
                />
                <LabelValuePair
                  label="Reinforced insulation"
                  value={
                    data[
                      'schedule-of-items__earthing-and-bonding__other-protection-methods'
                    ]['reinforced-insulation']
                  }
                />
                <LabelValuePair
                  label="Provisions where automatic disconnection of supply is not feasible"
                  value={
                    data[
                      'schedule-of-items__earthing-and-bonding__other-protection-methods'
                    ]['provisions']
                  }
                />
              </section>
            </section>
            <hr />
            <section>
              <h2>
                4.0 Distribution equipment, including consumer units and
                distribution boards
              </h2>
              <LabelValuePair
                label="4.1 Adequacy of working space / accessibility to equipment"
                value={data['schedule-of-items__consumer-units__accessibility']}
              />

              <LabelValuePair
                label="4.2 Security of fixing"
                value={data['schedule-of-items__consumer-units__security']}
              />

              <LabelValuePair
                label="4.3 Condition of insulation of live parts"
                value={
                  data[
                    'schedule-of-items__consumer-units__condition-of-insulation-of-live-parts'
                  ]
                }
              />

              <LabelValuePair
                label="4.4 Adequacy security of barriers or enclosures"
                value={
                  data[
                    'schedule-of-items__consumer-units__adequacy-security-of-barriers-or-enclosures'
                  ]
                }
              />
              <LabelValuePair
                label="4.5 Condition of enclosure(s) in terms of IP rating"
                value={data['schedule-of-items__consumer-units__enclosure-ip']}
              />
              <LabelValuePair
                label="4.6 Condition of enclosure(s) in terms of fire rating"
                value={
                  data['schedule-of-items__consumer-units__enclosure-fire']
                }
              />

              <LabelValuePair
                label="4.7 Enclosure not damaged / deteriorated so as to impair safety"
                value={
                  data['schedule-of-items__consumer-units__enclosure-damage']
                }
              />
              <LabelValuePair
                label="4.8 Presence and effectiveness of obstacles"
                value={
                  data[
                    'schedule-of-items__consumer-units__presence-effectiveness-of-obstacles'
                  ]
                }
              />
              <LabelValuePair
                label="4.9 Presence of main switch(es), linked where required"
                value={
                  data[
                    'schedule-of-items__consumer-units__presence-of-main-switch'
                  ]
                }
              />
              <LabelValuePair
                label="4.10 Operation of main switch(es) (functional check)"
                value={
                  data[
                    'schedule-of-items__consumer-units__operation-of-main-switches'
                  ]
                }
              />
              <LabelValuePair
                label="4.11 Manual operation of circuit-breakers, RCDs and AFDDs to prove functionality"
                value={
                  data[
                    'schedule-of-items__consumer-units__manual-operation-of-circuit-breakers'
                  ]
                }
              />
              <LabelValuePair
                label="4.12 Confirmation that integral test button / switch causes RCD(s) to trip when operated (functional check)"
                value={
                  data[
                    'schedule-of-items__consumer-units__confirmation-test-button-trips-rcds'
                  ]
                }
              />
              <LabelValuePair
                label="4.13 RCD(s) provided for fault protection - includes RCBOs"
                value={
                  data[
                    'schedule-of-items__consumer-units__rcds-provided-for-fault-protection'
                  ]
                }
              />
              <LabelValuePair
                label="4.14 RCD(s) provided for additional protection / requirements, where required - includes RCBOs"
                value={
                  data[
                    'schedule-of-items__consumer-units__rcds-provided-for-additional-protection'
                  ]
                }
              />
              <LabelValuePair
                label="4.15 Presence of RCD six-monthly test notice, where required"
                value={
                  data[
                    'schedule-of-items__consumer-units__presence-of-rcd-six-monthly-test-notice'
                  ]
                }
              />

              <LabelValuePair
                label="4.16 Confirmation that integral test button / switch, where present, causes AFDD to trip when operated"
                value={
                  data[
                    'schedule-of-items__consumer-units__confirmation-that-test-button-trips-afdd'
                  ]
                }
              />
              <LabelValuePair
                label="4.17 Presence of diagrams, charts or schedules at or near equipment, where required"
                value={
                  data[
                    'schedule-of-items__consumer-units__presence-of-diagrams-charts-schedules'
                  ]
                }
              />
              <LabelValuePair
                label="4.18 Presence of alternative supply warning notice at or near equipment, where required"
                value={
                  data[
                    'schedule-of-items__consumer-units__presence-of-alternative-supply-warning-notice'
                  ]
                }
              />

              <LabelValuePair
                label="4.19 Presence of next inspection recommendation label, where required"
                value={
                  data[
                    'schedule-of-items__consumer-units__presence-of-next-inspection-recommendation-label'
                  ]
                }
              />
              <LabelValuePair
                label="4.20 Presence of other required labelling"
                value={`${data['schedule-of-items__consumer-units__presence-of-other-required-labelling']}: ${data['schedule-of-items__consumer-units__presence-of-other-required-labelling-details']}`}
              />
              <LabelValuePair
                label="4.21 Compatibility of protective devices, bases and other components; correct type and rating (no signs of unacceptable thermal damage, arcing or overheating)"
                value={
                  data[
                    'schedule-of-items__consumer-units__protective-device-compatability'
                  ]
                }
              />

              <LabelValuePair
                label="4.22 Single-pole switching or protective devices in line conductors only"
                value={
                  data[
                    'schedule-of-items__consumer-units__single-pole-switching'
                  ]
                }
              />
              <LabelValuePair
                label="4.23 Protection against mechanical damage where cables enter equipment"
                value={
                  data[
                    'schedule-of-items__consumer-units__mechanical-damage-protection'
                  ]
                }
              />
              <LabelValuePair
                label="4.24 Protection against electromagnetic effects where cables enter ferromagnetic enclosures"
                value={
                  data[
                    'schedule-of-items__consumer-units__electromagnetic-effect-protection'
                  ]
                }
              />
            </section>
            <hr />
            <section>
              <h2>5.0 Distribution circuits</h2>
              <LabelValuePair
                label="5.1 Identification of conductors"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__conductor-identification'
                  ]
                }
              />
              <LabelValuePair
                label="5.2 Cables correctly supported throughout"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__cables-supproted'
                  ]
                }
              />
              <LabelValuePair
                label="5.3 Condition of insulation of live parts"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__insulation-condition'
                  ]
                }
              />

              <LabelValuePair
                label="5.4 Non-sheathed live conductors protected by enclosure in conduit, ducting or trunking"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__non-sheathed-live'
                  ]
                }
              />
              <LabelValuePair
                label="5.5 Suitability of containment systems for continued use (including flexible conduit)"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__containment-systems-suitability'
                  ]
                }
              />
              <LabelValuePair
                label="5.6 Cables correctly terminated in enclosures"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__cables-correctly-terminated-in-enclosures'
                  ]
                }
              />

              <LabelValuePair
                label="5.7 Confirmation that ALL conductor connections, including connections to busbars, are correctly located in terminals and are tight and secure"
                value={
                  data[
                    'schedule-of-items__consumer-units__conductor-connections-correctly-located'
                  ]
                }
              />
              <LabelValuePair
                label="5.8 Examination of cables for signs of unacceptable thermal or mechanical damage / deterioration"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__examination-of-cables'
                  ]
                }
              />
              <LabelValuePair
                label="5.9 Adequacy of cables for current-carrying capacity with regard for the type and nature of installation"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__adequacy-of-cables'
                  ]
                }
              />

              <LabelValuePair
                label="5.10 Adequacy of protective devices; type and rated current for fault protection"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__adequacy-of-protective-devices'
                  ]
                }
              />
              <LabelValuePair
                label="5.11 Presence and adequacy of circuit protective conductors"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__adequacy-of-circuit-protective-conductors'
                  ]
                }
              />
              <LabelValuePair
                label="5.12 Coordination between conductors and overload protective devices"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__conductors-protective-device-coordination'
                  ]
                }
              />
              <LabelValuePair
                label="5.13 Cable installation methods / practices with regard to the type and nature of installation and external influences"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__cable-installation-methods'
                  ]
                }
              />
              <LabelValuePair
                label="5.14 Where exposed to direct sunlight, cable of a suitable type"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__cable-type-sunlight-enclosure'
                  ]
                }
              />
              <section className="no-column-break">
                <h3>
                  5.15 Cables concealed under floors, above ceilings, in walls /
                  partitions, adequately protected against damage
                </h3>
                <LabelValuePair
                  label="Installed in prescribed zones (see Section D. Extent and limitations)"
                  value={
                    data[
                      'schedule-of-items__consumer-units__distribution-circuits__concealed-cables'
                    ]['installed-in-prescribed-zones']
                  }
                />
                <LabelValuePair
                  label="Incorporating earthed armour or sheath, or run within earthed wiring system, or otherwise protected against mechanical damage by nails, screws and the like (see Section D)"
                  value={
                    data[
                      'schedule-of-items__consumer-units__distribution-circuits__concealed-cables'
                    ]['incorporated-earthed-armour']
                  }
                />
              </section>
              <LabelValuePair
                label="5.16 Provision of fire barriers, sealing arrangements and protection against thermal effect"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__provision-of-fire-barriers'
                  ]
                }
              />
              <LabelValuePair
                label="5.17 Band II cables segregated / separated from Band I cables"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__band-separation'
                  ]
                }
              />
              <LabelValuePair
                label="5.18 Cables segregated / separated from non-electrical services"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__cable-separation'
                  ]
                }
              />

              <LabelValuePair
                label="5.19 Condition of circuit accessories"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__condition-of-circuit-accessories'
                  ]
                }
              />
              <LabelValuePair
                label="5.20 Suitability of circuit accessories for external influences"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__suitability-of-circuit-accessories'
                  ]
                }
              />
              <LabelValuePair
                label="5.21 Single-pole switching or protective devices in line conductors only"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__single-pole-switching'
                  ]
                }
              />

              <LabelValuePair
                label="5.22 Adequacy of connections, including cpcs, within accessories and to fixed and stationary equipment - identify / record numbers and locations of items inspected"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__adequacy-of-connections'
                  ]
                }
              />
              <LabelValuePair
                label="5.23 Presence, operation and correct location of appropriate devices for isolation and switching"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__presence-of-appropriate-isolation-switching-devices'
                  ]
                }
              />
              <LabelValuePair
                label="5.24 General condition of wiring system"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__condition-of-wiring-systems'
                  ]
                }
              />
              <LabelValuePair
                label="5.25 Temperature rating of cable insulation"
                value={
                  data[
                    'schedule-of-items__consumer-units__distribution-circuits__temperature-rating-of-cable-insulation'
                  ]
                }
              />
            </section>
            <hr />
            <section>
              <h2>6.0 Final circuits</h2>
              <LabelValuePair
                label="6.1 Identification of conductors"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__conductor-identification'
                  ]
                }
              />
              <LabelValuePair
                label="6.2 Cables correctly supported throughout their run"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__cables-correctly-supported'
                  ]
                }
              />
              <LabelValuePair
                label="6.3 Condition of insulation of live parts"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__condition-of-insulation-of-live-parts'
                  ]
                }
              />

              <LabelValuePair
                label="6.4 Non-sheathed cables protected by enclosure in conduit, ducting or trunking"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__non-sheathed-cables-protected'
                  ]
                }
              />

              <LabelValuePair
                label="6.5 Suitability of containment systems for continued use (including flexible conduit)"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__suitability-of-containment-systems'
                  ]
                }
              />
              <LabelValuePair
                label="6.6 dequacy of cables for current-carrying capacity with regard for the type and nature of installation"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__adequacy-of-cables'
                  ]
                }
              />

              <LabelValuePair
                label="6.7 Adequacy of protective devices; type and rated current for fault protection"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__adequacy-of-protective-devices'
                  ]
                }
              />

              <LabelValuePair
                label="6.8 Presence and adequacy of circuit protective conductors"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__adequacy-of-circuit-protective-conductors'
                  ]
                }
              />
              <LabelValuePair
                label="6.9 Co-ordination between conductors and overload protective devices"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__coordination-between-conductors-protective-devices'
                  ]
                }
              />

              <LabelValuePair
                label="6.10 Wiring system(s) appropriate for the type and nature of the installation and external influences"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__wiring-systems-appropriate-to-installation'
                  ]
                }
              />

              <LabelValuePair
                label="6.11 Where exposed to direct sunlight, cable of a suitable type"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__cable-type-sunlight-exposure'
                  ]
                }
              />

              <section className="no-column-break">
                <h3>
                  6.12 Cables concealed under floors, above ceilings, in walls /
                  partitions, adequately protected against damage
                </h3>
                <LabelValuePair
                  label="Installed in prescribed zones (see Section D. Extent and limitations)"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__concealed-cables'
                    ]['installed-in-prescribed-zones']
                  }
                />
                <LabelValuePair
                  label="Incorporating earthed armour or sheath, or run within earthed wiring system, or otherwise protected against mechanical damage by nails, screws and the like (see Section D)"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__concealed-cables'
                    ]['incorporated-earthed-armour']
                  }
                />
              </section>
              <section className="no-column-break">
                <h3>
                  6.13 Provision of additional protection by RCD having rated
                  residual operating current not exceeding 30 mA
                </h3>
                <LabelValuePair
                  label="For all socket-outlets of rating 32 A or less"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__additional-protection-provision'
                    ]['32a-or-less']
                  }
                />
                <LabelValuePair
                  label="For the supply of mobile equipment not exceeding 32 A rating for use outdoors"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__additional-protection-provision'
                    ]['32a-or-less-outdoors']
                  }
                />
                <LabelValuePair
                  label="For cables concealed in walls at a depth of less than 50 mm"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__additional-protection-provision'
                    ]['50mm-wall-concealment']
                  }
                />
                <LabelValuePair
                  label="For cables concealed in walls / partitions containing metal parts regardless of depth"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__additional-protection-provision'
                    ]['any-depth-wall-concealment']
                  }
                />
                <LabelValuePair
                  label="For final circuits supplying luminaires within domestic (household) premises"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__additional-protection-provision'
                    ]['luminaires']
                  }
                />
              </section>

              <LabelValuePair
                label="6.14 Provision of fire barriers, sealing arrangements and protection against thermal effect"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__provision-of-fire-barriers'
                  ]
                }
              />
              <LabelValuePair
                label="6.15 Band II cables segregated / separated from Band I cables"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__band-separation'
                  ]
                }
              />
              <LabelValuePair
                label="6.16 Cables segregated / separated from non-electrical services"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__cable-separation'
                  ]
                }
              />

              <section className="no-column-break">
                <h3>
                  6.17 Termination of cables at enclosures - identify / record
                  numbers and locations of items inspected
                </h3>
                <LabelValuePair
                  label="Connection under no undue strain"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__termination-of-cables'
                    ]['connection-under-no-undue-strain']
                  }
                />
                <LabelValuePair
                  label="No basic insulation of a conductor visible outside enclosure"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__termination-of-cables'
                    ]['no-basic-conductor-insulation-visible']
                  }
                />
                <LabelValuePair
                  label="Connections of live conductors adequately enclosed"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__termination-of-cables'
                    ]['connections-adequately-enclosed']
                  }
                />
                <LabelValuePair
                  label="Adequately connected at point of entry to enclosure (glands, bushes, etc.)"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__termination-of-cables'
                    ]['adequately-connected-at-point-of-entry']
                  }
                />
              </section>

              <LabelValuePair
                label="6.18 Condition of accessories including socket-outlets, switches and joint boxes"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__condition-of-accessories'
                  ]
                }
              />
              <LabelValuePair
                label="6.19 Suitability of circuit accessories for external influences"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__suitability-of-accessories'
                  ]
                }
              />
              <LabelValuePair
                label="6.20 Single-pole switching or protective devices in line conductors only"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__single-pole-switching'
                  ]
                }
              />
            </section>
            <hr />

            <section>
              <h2>7.0 Isolation and Switching</h2>
              <section className="no-column-break">
                <h3>7.1 Isolators</h3>
                <LabelValuePair
                  label="Presence and condition of appropriate devices"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__isolators'
                    ]['presence-and-condition-of-appropriate-devices']
                  }
                />

                <LabelValuePair
                  label="Acceptable location - state if local or remote from equipment in question"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__isolators'
                    ]['acceptable-location']
                  }
                />
                <LabelValuePair
                  label="Capable of being secured in the OFF position"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__isolators'
                    ]['capable-of-secured-off']
                  }
                />
                <LabelValuePair
                  label="Correct operation verified"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__isolators'
                    ]['correct-operation-verified']
                  }
                />
                <LabelValuePair
                  label="Clearly identified by position and / or durable marking"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__isolators'
                    ]['clearly-identified']
                  }
                />
                <LabelValuePair
                  label="Warning label posted in situations where live parts cannot be isolated by the operation of a single device"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__isolators'
                    ]['warning-label-posted']
                  }
                />
              </section>
              <section className="no-column-break">
                <h3>7.2 Switching off for mechanical maintenance</h3>
                <LabelValuePair
                  label="Presence and condition of appropriate devices"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__switching-off-for-mechanical-maintenance'
                    ]['presence-and-condition-of-appropriate-devices']
                  }
                />

                <LabelValuePair
                  label="Capable of being secured in the OFF position"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__switching-off-for-mechanical-maintenance'
                    ]['capable-of-secured-off']
                  }
                />
                <LabelValuePair
                  label="Correct operation verified"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__switching-off-for-mechanical-maintenance'
                    ]['correct-operation-verified']
                  }
                />
                <LabelValuePair
                  label="Clearly identified by position and / or durable marking"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__switching-off-for-mechanical-maintenance'
                    ]['clearly-identified']
                  }
                />
              </section>
              <section className="no-column-break">
                <h3>7.3 Emergency switching off</h3>
                <LabelValuePair
                  label="Presence and condition of appropriate devices"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__emergency-switching-off'
                    ]['presence-and-condition-of-appropriate-devices']
                  }
                />

                <LabelValuePair
                  label="Readily accessible for operation where danger might occur"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__emergency-switching-off'
                    ]['accessible-where-danger']
                  }
                />

                <LabelValuePair
                  label="Correct operation verified"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__emergency-switching-off'
                    ]['correct-operation-verified']
                  }
                />
                <LabelValuePair
                  label="Clearly identified by position and / or durable marking"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__emergency-switching-off'
                    ]['clearly-identified']
                  }
                />
              </section>
              <section className="no-column-break">
                <h3>7.4 Functional switching</h3>
                <LabelValuePair
                  label="Presence and condition of appropriate devices"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__functional-switching'
                    ]['presence-and-condition-of-appropriate-devices']
                  }
                />
                <LabelValuePair
                  label="Correct operation verified"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-and-switching__functional-switching'
                    ]['correct-operation-verified']
                  }
                />
              </section>
            </section>
            <hr />

            <section>
              <h2>8.0 Current-using equipment (permanently connected)</h2>
              <LabelValuePair
                label="8.1 Condition of equipment in terms of IP rating"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__ip'
                  ]
                }
              />
              <LabelValuePair
                label="8.2 Equipment does not constitute a fire hazard"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__fire-hazard'
                  ]
                }
              />
              <LabelValuePair
                label="8.3 Enclosure not damaged / deteriorated so as to impair safety"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__enclosure-not-damaged'
                  ]
                }
              />
              <LabelValuePair
                label="8.4 Suitability for the environment and external influences"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__suitability'
                  ]
                }
              />
              <LabelValuePair
                label="8.5 Security of fixing"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__fixing-security'
                  ]
                }
              />
              <LabelValuePair
                label="8.6 Cable entry holes in ceiling above luminaires, sized or sealed so as to restrict the spread of fire"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__entry-holes-sealed'
                  ]
                }
              />
              <section>
                <h3>8.7 Recessed luminaires (downlighters):</h3>
                <LabelValuePair
                  label="Correct type of lamps fitted"
                  value={
                    data[
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires'
                    ][
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires__correct-lamp'
                    ]
                  }
                />
                <LabelValuePair
                  label="Installed to minimise build-up of heat by use of “fire rated” fittings, insulation displacement box or similar"
                  value={
                    data[
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires'
                    ][
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires__minimise-heat'
                    ]
                  }
                />
                <LabelValuePair
                  label="No signs of overheating to surrounding building fabric"
                  value={
                    data[
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires'
                    ][
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires__overheating-signs'
                    ]
                  }
                />
                <LabelValuePair
                  label="No signs of overheating to conductors / terminations"
                  value={
                    data[
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires'
                    ][
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires_overheating-terminations'
                    ]
                  }
                />
              </section>
            </section>
            <hr />

            <section>
              <h2>9.0 Special locations and installations</h2>
              <section>
                <h3>9.1 Location(s) containing a bath or shower</h3>

                <LabelValuePair
                  label="Additional protection by RCD having rated residual operating current not exceeding 30 mA for all low voltage (LV) circuits serving the location or passing through zones 1 and / or 2 of the location"
                  value={
                    data[
                      'schedule-of-items__consumer-units__special-locations__bath-or-shower'
                    ]['rcd-additional-protection']
                  }
                />

                <LabelValuePair
                  label="Where used as a protective measure, requirements for SELV or PELV met"
                  value={
                    data[
                      'schedule-of-items__consumer-units__special-locations__bath-or-shower'
                    ]['rselv-pelv-requirements-met']
                  }
                />
                <LabelValuePair
                  label="Shaver supply units complying with BS EN 61558-2-5 formerly BS 3535"
                  value={
                    data[
                      'schedule-of-items__consumer-units__special-locations__bath-or-shower'
                    ]['shaver-supply-units-comply']
                  }
                />
                <LabelValuePair
                  label="Presence of supplementary bonding conductors, unless not required by BS 7671: 2018"
                  value={
                    data[
                      'schedule-of-items__consumer-units__special-locations__bath-or-shower'
                    ]['presence-of-supplementary-bonding-conductors']
                  }
                />
                {/*  */}
                <LabelValuePair
                  label="Low voltage (e.g. 230 volt) socket-outlets sited at least 2.5 m from zone 1"
                  value={
                    data[
                      'schedule-of-items__consumer-units__special-locations__bath-or-shower'
                    ]['low-voltage-socket-outlets']
                  }
                />
                <LabelValuePair
                  label="Suitability of equipment for external influences for installed location in terms of IP rating"
                  value={
                    data[
                      'schedule-of-items__consumer-units__special-locations__bath-or-shower'
                    ]['suitability-of-equipment']
                  }
                />
                <LabelValuePair
                  label="Suitability of accessories and controlgear etc. for a particular zone"
                  value={
                    data[
                      'schedule-of-items__consumer-units__special-locations__bath-or-shower'
                    ]['suitability-of-accessories']
                  }
                />
                <LabelValuePair
                  label="Suitability of current-using equipment for particular position within the location"
                  value={
                    data[
                      'schedule-of-items__consumer-units__special-locations__bath-or-shower'
                    ]['suitability-of-current-using-equipment']
                  }
                />
              </section>
              <section>
                <h2>9.2 Other special installations or locations</h2>
                {(data[
                  'schedule-of-items__consumer-units__special-locations__other-special-installations'
                ] && (
                  <table className="data-table">
                    <thead>
                      <tr>
                        <th>Location</th>
                        <th>Outcome</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data[
                        'schedule-of-items__consumer-units__special-locations__other-special-installations'
                      ]?.map((x: any, i: number) => (
                        <tr
                          key={`schedule-of-items-consumer-units-other-special-installations-${i}`}
                        >
                          <td>{x.location}</td>
                          <td>{x.outcome}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )) || <p>N/A</p>}
              </section>
            </section>
            <hr />
            <section>
              <h2>10 Prosumer’s low voltage installation</h2>
              <section>
                {(data[
                  'schedule-of-items__consumer-units__low-voltage-installation'
                ] && (
                  <table className="data-table">
                    <thead>
                      <tr>
                        <th>Location</th>
                        <th>Outcome</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data[
                        'schedule-of-items__consumer-units__low-voltage-installation'
                      ]?.map((x: any, i: number) => (
                        <tr
                          key={`schedule-of-items-consumer-units-low-voltage-installation-${i}`}
                        >
                          <td>{x.location}</td>
                          <td>{x.outcome}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )) || <p>N/A</p>}
              </section>
            </section>
            <section>
              <h2>Schedule of items inspected by</h2>
              <SignatureElement
                signatureBase64={
                  data['schedule-of-items__consumer-units__inspector-signature']
                }
                name={data['schedule-of-items__consumer-units__inspector-name']}
                date={data['schedule-of-items__consumer-units__inspector-date']}
              />
            </section>
          </div>
        </OutlinedContentPanel>
      </div>
      {data.distributionBoards && (
        <div className="form-template-row circuit-details no-page-break">
          <OutlinedContentPanel
            colourOverride={brandColour}
            title="PART 11: Schedule of circuit details and test results"
            titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
            paddingVariant={ContentPanelPaddingVariant.SMALL}
          >
            {data.distributionBoards?.map((db: any, i: number) => (
              <section
                className="distribution-board"
                key={`distribution-boards-${i}`}
              >
                <h5>Distribution board {db.designation}</h5>
                <div className="form-template-row circuit-details no-page-break">
                  <LabelValuePair
                    label="Location of consumer unit"
                    value={db.location}
                  />
                  <LabelValuePair label="Designation" value={db.designation} />
                  <LabelValuePair
                    label="Prospective fault current at consumer unit"
                    value={db.prospectiveFaultCurrent}
                  />
                  <LabelValuePair label="Zdb (Ω)" value={`${db.zDb}`} />
                </div>

                {db.circuits && (
                  <table className="electrical-circuits">
                    <thead>
                      <tr>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Circuit number</span>
                        </th>
                        <th rowSpan={3}>Circuit description</th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Type of wiring (see Codes)</span>
                        </th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Reference Method (BS 7671)</span>
                        </th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Number of points served</span>
                        </th>
                        <th colSpan={2}>Circuit conductor csa</th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Max. disconnection time (BS 7671)</span>
                        </th>
                        <th colSpan={5}>Protective device</th>
                        <th colSpan={4}>RCD</th>
                        <th colSpan={5}>Continuity (Ohms)</th>
                        <th colSpan={3}>Insulation resistance</th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>Polarity</span>
                        </th>
                        <th rowSpan={3} className="table-header--vertical">
                          <span>
                            Max. measured earth fault loop impedance, Zs
                          </span>
                        </th>
                        <th colSpan={2}>RCD</th>
                        <th rowSpan={3}>AFDD test button</th>
                        <th rowSpan={3}>Comments</th>
                      </tr>
                      <tr>
                        <td rowSpan={2}>Live (mm2)</td>
                        <td rowSpan={2}>cpc (mm2)</td>
                        <td rowSpan={2}>BS (EN)</td>
                        <td rowSpan={2}>Type</td>
                        <td rowSpan={2}>Rating (A)</td>
                        <td rowSpan={2}>Short-circuit capacity (kA)</td>
                        <td rowSpan={2}>Maximum permitted Zs</td>

                        <td rowSpan={2}>BS (EN)</td>
                        <td rowSpan={2}>Type</td>
                        <td rowSpan={2}>Rating</td>
                        <td rowSpan={2}>Operating current, IΔn (mA)</td>

                        <td colSpan={3}>
                          Ring final circuits only (measured end to end)
                        </td>
                        <td colSpan={2}>
                          All circuits (complete at least one column)
                        </td>
                        <td rowSpan={2}>Live / Live (MegaOhms)</td>
                        <td rowSpan={2}>Live / Earth (MegaOhms)</td>
                        <td rowSpan={2}>Test voltage DC (V)</td>

                        <td rowSpan={2}>Operating time (ms)</td>
                        <td rowSpan={2}>Test button</td>
                      </tr>
                      <tr>
                        <th>(Line) r1</th>
                        <th>(Neutral) rn</th>
                        <th>(cpc) r2</th>
                        <th>(R1 + R2)</th>
                        <th>R2</th>
                      </tr>
                    </thead>
                    <tbody>
                      {db.circuits?.map((circuit: any, i: number) => (
                        <tr key={`db-circuits-${i}`}>
                          <td>{circuit.number}</td>
                          <td>{circuit.description}</td>
                          <td>
                            {circuit.wiringType ?? circuit.wiringTypeOther}
                          </td>
                          <td>{circuit.referenceMethod}</td>
                          <td>{circuit.numPointsServed}</td>
                          <td>{circuit.liveConductorCsa}</td>
                          <td>{circuit.cpcConductorCsa}</td>
                          <td>{circuit.maxDisconnectionTime}</td>
                          <td>{circuit.overcurrentProtectiveDevice}</td>
                          <td>
                            {defaultToNa(
                              circuit.overcurrentProtectiveDeviceType
                            )}
                          </td>
                          <td>{circuit.currentRating}</td>
                          <td>{circuit.shortCircuitCapacity}</td>
                          <td>{circuit.maxPermittedZs}</td>

                          <td>{circuit.rcdBs}</td>
                          <td>{circuit.rcdType}</td>
                          <td>{circuit.rcdRating}</td>
                          <td>{circuit.rcdOperatingCurrent}</td>

                          <td>{defaultToNa(circuit.lineImpedance)}</td>
                          <td>{defaultToNa(circuit.neutralImpedance)}</td>
                          <td>{defaultToNa(circuit.cpcImpedance)}</td>
                          <td>{defaultToNa(circuit.r1PlusR2Impedance)}</td>
                          <td>{defaultToNa(circuit.r2Impedance)}</td>
                          <td>
                            {defaultToNa(circuit.liveLiveInsulationResistance)}
                          </td>
                          <td>
                            {defaultToNa(circuit.liveEarthInsulationResistance)}
                          </td>
                          <td>{circuit.testVoltage}</td>
                          <td>{circuit.polarity}</td>
                          <td>
                            {defaultToNa(
                              circuit.maxMeasuredEarthFaultLoopImpedance
                            )}
                          </td>
                          <td>{defaultToNa(circuit.rcdOperatingTime)}</td>
                          <td>{circuit.rcdTestButtonOperation}</td>
                          <td>{circuit.afddTestButtonOperation}</td>
                          <td>{circuit.comments}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <div className="columns-3">
                  <div>
                    <LabelValuePair
                      label="Confirmation of supply polarity"
                      value={`${db.confirmationOfSupplyPolarity}`}
                    />
                    <LabelValuePair
                      label="Phase sequence confirmed"
                      value={`${db.phaseSequenceConfirmed}`}
                    />
                  </div>
                  <section className="no-column-break">
                    <h5>SPD Details</h5>
                    <div>
                      <LabelValuePair label="T1" value={db.spdDetailsT1} />
                      <LabelValuePair label="T2" value={db.spdDetailsT2} />
                      <LabelValuePair label="T3" value={db.spdDetailsT3} />
                      <LabelValuePair label="T4" value={db.spdDetailsT4} />
                    </div>
                  </section>
                  {db.isNotConnectedToOrigin && (
                    <>
                      {' '}
                      <section className="no-column-break">
                        <h5>Not connected to origin</h5>
                        <LabelValuePair
                          label="Supply to DB is from"
                          value={db.supplyIsFrom}
                        />
                      </section>
                      <section className="no-column-break">
                        <h5>
                          Overcurrent protection device for the distribution
                          circuit
                        </h5>
                        <LabelValuePair
                          label="BS (EN)"
                          value={db.overcurrentProtectionDeviceBs}
                        />
                        <LabelValuePair
                          label="Nominal voltage (V)"
                          value={db.overcurrentProtectionNominalVoltage}
                        />
                        <LabelValuePair
                          label="Rating (A)"
                          value={db.overcurrentProtectionRating}
                        />
                        <LabelValuePair
                          label="No. of Phases"
                          value={db.overcurrentProtectionNoOfPhases}
                        />
                      </section>
                      <section className="no-column-break">
                        <h5>Associated RCD (if any)</h5>
                        <LabelValuePair
                          label="BS (EN)"
                          value={db.associatedRcdBs}
                        />
                        <LabelValuePair
                          label="RCD Type"
                          value={db.associatedRcdType}
                        />
                        <LabelValuePair
                          label="IΔn (mA)"
                          value={db.associatedRcdResidualCurrent}
                        />
                        <LabelValuePair
                          label="No. of poles"
                          value={db.associatedRcdNoOfPoles}
                        />
                        <LabelValuePair
                          label="Operating time (ms)"
                          value={db.associatedRcdOperatingTime}
                        />
                      </section>
                    </>
                  )}

                  <SignatureElement
                    label="Tested by"
                    name={db.testEngineerName}
                    signatureBase64={db.testEngineerSignatureBase64}
                    position={db.testEngineerPosition}
                    date={db.testEngineerDate}
                  />

                  <section className="no-column-break">
                    <h5>Test instruments</h5>
                    <LabelValuePair
                      label="Multi-function"
                      value={
                        db.multiFunctionTestInstrumentSerialNumber || 'N/A'
                      }
                    />
                    <LabelValuePair
                      label="Continuity"
                      value={db.continuityTestInstrumentSerialNumber || 'N/A'}
                    />
                    <LabelValuePair
                      label="Insulation resistance"
                      value={
                        db.insulationResistanceTestInstrumentSerialNumber ||
                        'N/A'
                      }
                    />
                    <LabelValuePair
                      label="Earth fault loop impedance"
                      value={
                        db.earthFaultLoopImpedanceTestInstrumentSerialNumber ||
                        'N/A'
                      }
                    />
                    <LabelValuePair
                      label="Earth electrode resistance"
                      value={
                        db.earthElectrodeResistanceTestInstrumentSerialNumber ||
                        'N/A'
                      }
                    />
                    <LabelValuePair
                      label="RCD"
                      value={db.rcdTestInstrumentSerialNumber || 'N/A'}
                    />
                  </section>
                </div>
              </section>
            )) ?? <p>N/A</p>}
          </OutlinedContentPanel>
        </div>
      )}

      {/*<div className="form-template-row circuit-details no-page-break">
        <OutlinedContentPanel
          colourOverride={brandColour}
          title="PART 10: Schedule of items inspected"
          titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
          paddingVariant={ContentPanelPaddingVariant.SMALL}
        >
          <div className="columns-3">
            <section>
              <h2>
                1. External condition of electrical intake equipment (visual
                inspection only)
              </h2>
              <LabelValuePair
                label="1.1 Service cable"
                value={data['schedule__intake-equipment__service-cable']}
              />
              <LabelValuePair
                label="1.2 Service head"
                value={data['schedule__intake-equipment__service-head']}
              />
              <LabelValuePair
                label="1.3 Earthing arrangement"
                value={
                  data['schedule__intake-equipment__earthing-arrangements']
                }
              />
              <section>
                <h2>1.4 Meter tails</h2>
                <LabelValuePair
                  label="a) Cutout fuse to meter"
                  value={
                    data['schedule__intake-equipment__meter-tails'][
                      'cutout-to-meter'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) Meter to consumer unit"
                  value={
                    data['schedule__intake-equipment__meter-tails'][
                      'meter-to-cu'
                    ]
                  }
                />
              </section>
              <LabelValuePair
                label="1.5 Metering equipment"
                value={data['schedule__intake-equipment__metering-equipment']}
              />
              <LabelValuePair
                label="1.6 Isolator (where present)"
                value={data['schedule__intake-equipment__isolator']}
              />
            </section>
            <section>
              <h2>2. Presence of adequate arrangements for other sources</h2>
              <LabelValuePair
                label="2.1 Adequate arrangements where a generating set operates as a switched alternative to the public supply"
                value={
                  data[
                    'schedule-of-items__other-source-arrangements__generating-set-switched-alternative'
                  ]
                }
              />
              <LabelValuePair
                label="2.2 Adequate arrangements where generating set operates in parallel with the public supply"
                value={
                  data[
                    'schedule-of-items__other-source-arrangements__generating-set-parallel'
                  ]
                }
              />
              <LabelValuePair
                label="2.3 Presence of alternative / additional supply warning notices"
                value={
                  data[
                    'schedule-of-items__other-source-arrangements__alternative-supply-notices'
                  ]
                }
              />
            </section>
            <section>
              <h2>3. Earthing and bonding arrangements</h2>
              <LabelValuePair
                label="3.1 Presence and condition of distributor’s earthing arrangement"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__distributor-earthing'
                  ]
                }
              />
              <LabelValuePair
                label="3.2 Presence and condition of earth electrode connection, where appropriate"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__earth-electrode'
                  ]
                }
              />
              <LabelValuePair
                label="3.3 Confirmation of adequate earthing conductor size"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__earthing-conductor-size'
                  ]
                }
              />
              <LabelValuePair
                label="3.4 Accessibility and condition of earthing conductor at Main Earthing Terminal (MET)"
                value={data['schedule-of-items__earthing-and-bonding__met']}
              />
              <LabelValuePair
                label="3.5 Confirmation of adequate main protective bonding conductor sizes"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__bonding-conductor-size'
                  ]
                }
              />
              <LabelValuePair
                label="3.6 Accessibility and condition of main protective bonding conductor connections"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__bonding-connections'
                  ]
                }
              />
              <LabelValuePair
                label="3.7 Accessibility and condition of other protective bonding connections"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__other-bonding-connections'
                  ]
                }
              />
              <LabelValuePair
                label="3.8 Provision of earthing and bonding labels at all appropriate locations"
                value={
                  data[
                    'schedule-of-items__earthing-and-bonding__earthing-bonding-labels'
                  ]
                }
              />
            </section>
            <section>
              <h2>4. Consumer unit(s) / Distribution board(s)</h2>
              <LabelValuePair
                label="4.1 Adequacy of working space / accessibility to consumer unit / distribution board"
                value={data['schedule-of-items__consumer-units__accessibility']}
              />
              <LabelValuePair
                label="4.2 Security of fixing"
                value={data['schedule-of-items__consumer-units__security']}
              />
              <LabelValuePair
                label="4.3 Condition of enclosure(s) in terms of IP rating"
                value={data['schedule-of-items__consumer-units__enclosure-ip']}
              />
              <LabelValuePair
                label="4.4 Condition of enclosure(s) in terms of fire rating"
                value={
                  data['schedule-of-items__consumer-units__enclosure-fire']
                }
              />
              <LabelValuePair
                label="4.5 Enclosure not damaged / deteriorated so as to impair safety"
                value={
                  data['schedule-of-items__consumer-units__enclosure-damage']
                }
              />
              <LabelValuePair
                label="4.6 Presence of linked main switch"
                value={
                  data['schedule-of-items__consumer-units__linked-main-switch']
                }
              />
              <LabelValuePair
                label="4.7 Operation of main switch(es) (functional check)"
                value={
                  data[
                    'schedule-of-items__consumer-units__operation-main0-switches'
                  ]
                }
              />
              <LabelValuePair
                label="4.8 Main switch capable of being secured in the OFF position"
                value={
                  data[
                    'schedule-of-items__consumer-units__main-switch-capable-secured-off'
                  ]
                }
              />
              <LabelValuePair
                label="4.9 Operation of circuit-breakers and RCDs to prove disconnection (functional check)"
                value={
                  data['schedule-of-items__consumer-units__breaker-operation']
                }
              />
              <LabelValuePair
                label="4.10 Correct identification of circuits and protective devices"
                value={
                  data[
                    'schedule-of-items__consumer-units__circuit-identification'
                  ]
                }
              />
              <section>
                <h2>
                  4.11 Presence of appropriate circuit charts, warning and other
                  notices
                </h2>
                <LabelValuePair
                  label="a) Provision of circuit charts/schedules or equivalent forms of information"
                  value={
                    data[
                      'schedule-of-items__consumer-units__appropriate-circuit-charts'
                    ]['provision']
                  }
                />
                <LabelValuePair
                  label="b) Warning notice of method of isolation where live parts not capable of being isolated by a single device"
                  value={
                    data[
                      'schedule-of-items__consumer-units__appropriate-circuit-charts'
                    ]['isolation-warning-notice']
                  }
                />
                <LabelValuePair
                  label="c) Periodic inspection and testing notice"
                  value={
                    data[
                      'schedule-of-items__consumer-units__appropriate-circuit-charts'
                    ]['inspection-notice']
                  }
                />
                <LabelValuePair
                  label="d) Presence of RCD six-monthly notice, where required"
                  value={
                    data[
                      'schedule-of-items__consumer-units__appropriate-circuit-charts'
                    ]['rcd-notice']
                  }
                />
                <LabelValuePair
                  label="e) Warning notice of non-standard (mixed) colours of conductors present"
                  value={
                    data[
                      'schedule-of-items__consumer-units__appropriate-circuit-charts'
                    ]['non-standard-colours-notice']
                  }
                />
                <LabelValuePair
                  label="f) All other required labelling provided"
                  value={
                    data[
                      'schedule-of-items__consumer-units__appropriate-circuit-charts'
                    ]['other-labelling']
                  }
                />
              </section>
              <LabelValuePair
                label="4.12 Compatibility of protective device(s), base(s) and other components; correct type and rating (no signs of unacceptable thermal damage, arcing or overheating)"
                value={
                  data[
                    'schedule-of-items__consumer-units__compatibility-of-devices'
                  ]
                }
              />
              <LabelValuePair
                label="4.13 Single-pole switching or protective devices in the line conductors only"
                value={
                  data[
                    'schedule-of-items__consumer-units__single-pole-switching-line-conductors'
                  ]
                }
              />
              <LabelValuePair
                label="4.14 Protection against mechanical damage where cables enter consumer unit / distribution board"
                value={
                  data[
                    'schedule-of-items__consumer-units__mechanical-damage-protection'
                  ]
                }
              />
              <LabelValuePair
                label="4.15 Protection against electromagnetic effects where cables enter metallic consumer unit / enclosure"
                value={
                  data[
                    'schedule-of-items__consumer-units__electromagnetic-effects'
                  ]
                }
              />
              <LabelValuePair
                label="4.16 RCDs provided for fault protection – includes RCBOs"
                value={data['schedule-of-items__consumer-units__rcds-provided']}
              />
              <LabelValuePair
                label="4.17 RCDs provided for additional protection – includes RCBOs"
                value={
                  data[
                    'schedule-of-items__consumer-units__rcds-provided-additional'
                  ]
                }
              />
              <LabelValuePair
                label="4.18 Confirmation of indication that SPD is functional"
                value={
                  data['schedule-of-items__consumer-units__spd-confirmation']
                }
              />
              <LabelValuePair
                label="4.19 Adequacy of AFDD(s), where specified"
                value={data['schedule-of-items__consumer-units__afdd-adequacy']}
              />
              <LabelValuePair
                label="4.20 Confirmation that conductor connections, including connections to busbars, are correctly located in terminals and are tight and secure"
                value={
                  data[
                    'schedule-of-items__consumer-units__connection-locations'
                  ]
                }
              />
            </section>
            <section>
              <h2>5. Distribution / final circuits</h2>
              <LabelValuePair
                label="5.1 Identification of conductors"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__conductor-identification'
                  ]
                }
              />
              <LabelValuePair
                label="5.2 Cables correctly supported throughout"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__cables-supproted'
                  ]
                }
              />
              <LabelValuePair
                label="5.3 Condition of insulation of live parts"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__insulation-condition'
                  ]
                }
              />
              <LabelValuePair
                label="5.4 Non-sheathed live conductors protected by enclosure in conduit, ducting or trunking (including confirmation of the integrity of conduit and trunking systems)"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__non-sheathed-live'
                  ]
                }
              />
              <LabelValuePair
                label="5.5 Adequacy of cables for current-carrying capacity with regard to the type and nature of installation"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__cable-adequacy'
                  ]
                }
              />
              <LabelValuePair
                label="5.6 Adequacy of protective devices; type and rated current for fault protection"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__protective-device-adequacy'
                  ]
                }
              />
              <LabelValuePair
                label="5.7 Presence and adequacy of circuit protective conductors"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__protective-conductor-adequacy'
                  ]
                }
              />
              <LabelValuePair
                label="5.8 Co-ordination between conductors and overload protection devices"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__device-coordination'
                  ]
                }
              />
              <LabelValuePair
                label="5.9 Wiring system(s) appropriate for the type and nature of the installation and external influences"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__appropriate-wiring-systems'
                  ]
                }
              />
              <LabelValuePair
                label="5.10 Cables adequately protected against mechanical damage and abrasion"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__cables-protected'
                  ]
                }
              />
              <section>
                <h2>5.11 Provision of additional protection by 30 ma RCD</h2>

                <LabelValuePair
                  label="a) For all socket-outlets with a rated current not exceeding 32 A"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection'
                    ][
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection__all-sockets'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) For mobile equipment not exceeding a rating of 32 A for use outdoors"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection'
                    ][
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection__mobile-equipment'
                    ]
                  }
                />
                <LabelValuePair
                  label="c) For cables installed in walls / partitions at a depth of less than 50 mm"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection'
                    ][
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection__cables-plastered'
                    ]
                  }
                />
                <LabelValuePair
                  label="d) For cables installed in walls / partitions containing metal parts regardless of depth"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection'
                    ][
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection__walls-metal-parts'
                    ]
                  }
                />
                <LabelValuePair
                  label="e) For all AC final circuits supplying luminaires"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection'
                    ][
                      'schedule-of-items__consumer-units__final-circuits_additional-rcd-protection__all-luminaire-circuits'
                    ]
                  }
                />
              </section>
              <LabelValuePair
                label="5.12 Provision of fire barriers, sealing arrangements and protection against thermal effects"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__fire-barriers'
                  ]
                }
              />
              <LabelValuePair
                label="5.13 Band II cables segregated / separated from Band I cables"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__band-separation'
                  ]
                }
              />
              <LabelValuePair
                label="5.14 Cables segregated / separated from communications cabling"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__comms-segregation'
                  ]
                }
              />
              <LabelValuePair
                label="5.15 Cables segregated / separated from non-electrical services"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__non-electrical-segregation'
                  ]
                }
              />
              <section>
                <h2>
                  5.16 Termination of cables at enclosures (extent of sampling
                  indicated in PART 7 of the report)
                </h2>
                <LabelValuePair
                  label="a) Connections soundly made and under no undue strain"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination'
                    ][
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination__connections-sound'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) No basic insulation of a conductor visible outside enclosure"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination'
                    ][
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination__no-insulation-visible'
                    ]
                  }
                />
                <LabelValuePair
                  label="c) Connection of live conductors adequately enclosed"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination'
                    ][
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination__connection-enclosed'
                    ]
                  }
                />
                <LabelValuePair
                  label="d) Adequately connected at point of entry to enclosure"
                  value={
                    data[
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination'
                    ][
                      'schedule-of-items__consumer-units__final-circuits__enclosure-termination__adequately-connected'
                    ]
                  }
                />
              </section>
              <LabelValuePair
                label="5.17 Condition of accessories including socket-outlets, switches and joint boxes"
                value={
                  data[
                    'schedule-of-items__consumer-units__final-circuits__condition-of-accessories'
                  ]
                }
              />
            </section>
            <section>
              <h2>
                6. Isolation and switching (isolation, switching off for
                mechanical maintenance and functional switching)
              </h2>
              <section>
                <h2>6.1 In general:</h2>

                <LabelValuePair
                  label="a) Presence and condition of appropriate devices"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-switching__presence'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) Correct operation verified"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-switching__operation-verified'
                    ]
                  }
                />
              </section>
              <section>
                <h2>
                  6.2 For isolation and switching for mechanical maintenance
                  only:
                </h2>
                <LabelValuePair
                  label="a) Capable of being secured in the OFF position, where appropriate"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-switching__mechanical__capable-secured-off'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) Acceptable location (local / remote)"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-switching__mechanical__acceptable-location'
                    ]
                  }
                />
                <LabelValuePair
                  label="c) Clearly identified by position and / or durable marking(s)"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-switching__mechanical__durable-markings'
                    ]
                  }
                />
              </section>
              <section>
                <h2>6.3 For isolation only:</h2>
                <LabelValuePair
                  label="a) Warning label(s) posted in situations where live parts cannot be isolated by the operation of a single device"
                  value={
                    data[
                      'schedule-of-items__consumer-units__isolation-switching__isolation-only__warning-labels'
                    ]
                  }
                />
              </section>
            </section>
            <section>
              <h2>7. Current-using equipment (permanently connected)</h2>
              <LabelValuePair
                label="7.1 Condition of equipment in terms of IP rating"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__ip'
                  ]
                }
              />
              <LabelValuePair
                label="7.2 Equipment does not constitute a fire hazard"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__fire-hazard'
                  ]
                }
              />
              <LabelValuePair
                label="7.3 Enclosure not damaged / deteriorated so as to impair safety"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__enclosure-not-damaged'
                  ]
                }
              />
              <LabelValuePair
                label="7.4 Suitability for the environment and external influences"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__suitability'
                  ]
                }
              />
              <LabelValuePair
                label="7.5 Security of fixing"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__fixing-security'
                  ]
                }
              />
              <LabelValuePair
                label="7.6 Cable entry holes in ceiling above luminaires, sized or sealed so as to restrict the spread of fire"
                value={
                  data[
                    'schedule-of-items__consumer-units__current-using-equipment__entry-holes-sealed'
                  ]
                }
              />
              <section>
                <h2>7.7 Recessed luminaires (downlighters):</h2>
                <LabelValuePair
                  label="a) Correct type of lamps fitted"
                  value={
                    data[
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires'
                    ][
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires__correct-lamp'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) Installed to minimise build-up of heat"
                  value={
                    data[
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires'
                    ][
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires__minimise-heat'
                    ]
                  }
                />
                <LabelValuePair
                  label="c) No signs of overheating to surrounding building fabric"
                  value={
                    data[
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires'
                    ][
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires__overheating-signs'
                    ]
                  }
                />
                <LabelValuePair
                  label="d) No signs of overheating to conductors / terminations"
                  value={
                    data[
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires'
                    ][
                      'schedule-of-items__consumer-units__current-using-equipment__luminaires_overheating-terminations'
                    ]
                  }
                />
              </section>
            </section>
            <section>
              <h2>8. Location(s) containing a bath or shower</h2>
              <section>
                <h2>8.1 Additional protection by RCD not exceeding 30 mA:</h2>
                <LabelValuePair
                  label="a) For low voltage circuits serving the location"
                  value={
                    data[
                      'schedule-of-items__consumer-units__wet-locations__rcd'
                    ][
                      'schedule-of-items__consumer-units__wet-locations__rcd-low-voltage'
                    ]
                  }
                />
                <LabelValuePair
                  label="b) For low voltage circuits passing through Zone 1 and Zone 2 not serving the location"
                  value={
                    data[
                      'schedule-of-items__consumer-units__wet-locations__rcd'
                    ][
                      'schedule-of-items__consumer-units__wet-locations__rcd-passing-through'
                    ]
                  }
                />
              </section>
              <LabelValuePair
                label="8.2 Where used as a protective measure, requirements for SELV or PELV are met"
                value={
                  data[
                    'schedule-of-items__consumer-units__wet-locations__selv-pelv'
                  ]
                }
              />
              <LabelValuePair
                label="8.3 Shaver sockets comply with BS EN 61558-2-5 (formerly BS 3535)"
                value={
                  data[
                    'schedule-of-items__consumer-units__wet-locations__shaver-sockets'
                  ]
                }
              />
              <LabelValuePair
                label="8.4 Presence of supplementary bonding conductors unless not required by BS 7671: 2018"
                value={
                  data[
                    'schedule-of-items__consumer-units__wet-locations__sup-bonding'
                  ]
                }
              />
              <LabelValuePair
                label="8.5 Low voltage (e.g. 230 volts) socket-outlets sited at least 3 m from Zone 1"
                value={
                  data[
                    'schedule-of-items__consumer-units__wet-locations__lv-at-least-3m'
                  ]
                }
              />
              <LabelValuePair
                label="8.6 Suitability of equipment for external influences for installed location in terms of IP rating"
                value={
                  data[
                    'schedule-of-items__consumer-units__wet-locations__suitability-ip'
                  ]
                }
              />
              <LabelValuePair
                label="8.7 Suitability of equipment for installation in a particular zone"
                value={
                  data[
                    'schedule-of-items__consumer-units__wet-locations__suitability-installation'
                  ]
                }
              />
            </section>
            <section>
              <h2>9. Other Part 7 special installations or locations</h2>
              {(data.schedule_of_items_other_special_installations && (
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>Location</th>
                      <th>Outcome</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.schedule_of_items_other_special_installations?.map(
                      (x: any, i: number) => (
                        <tr
                          key={`schedule-of-items-consumer-units-other-special-installations-${i}`}
                        >
                          <td>{x.location}</td>
                          <td>{x.outcome}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )) || <p>N/A</p>}
            </section>
          </div>
        </OutlinedContentPanel>
      </div>

      {data.distributionBoards && (
        <div className="form-template-row circuit-details no-page-break">
          <OutlinedContentPanel
            colourOverride={brandColour}
            title="PART 12: Schedule of circuit details and test results"
            titleStyleVariant={ContentPanelTitleStyleVariant.BORDER_ALIGNED}
            paddingVariant={ContentPanelPaddingVariant.SMALL}
          >
            {data.distributionBoards?.map((db: any) => (
              <>
                <section>
                  <h5>Distribution board {db.designation}</h5>
                  <div className="form-template-row circuit-details no-page-break">
                    <LabelValuePair
                      label="Location of consumer unit"
                      value={db.location}
                    />
                    <LabelValuePair
                      label="Designation"
                      value={db.designation}
                    />
                    <LabelValuePair
                      label="Prospective fault current at consumer unit (where applicable)"
                      value={db.prospectiveFaultCurrent}
                    />
                  </div>

                  {db.circuits && (
                    <table className="electrical-circuits">
                      <thead>
                        <tr>
                          <th rowSpan={3} className="table-header--vertical">
                            <span>Circuit number</span>
                          </th>
                          <th rowSpan={3}>Circuit description</th>
                          <th rowSpan={3} className="table-header--vertical">
                            <span>Type of wiring (see Codes)</span>
                          </th>
                          <th rowSpan={3} className="table-header--vertical">
                            <span>Reference Method (BS 7671)</span>
                          </th>
                          <th rowSpan={3} className="table-header--vertical">
                            <span>Number of points served</span>
                          </th>
                          <th colSpan={2}>Circuit conductor csa</th>
                          <th rowSpan={3} className="table-header--vertical">
                            <span>Max. disconnection time (BS 7671)</span>
                          </th>
                          <th colSpan={4}>Protective device</th>
                          <th rowSpan={3} className="table-header--vertical">
                            <span>RCD Operating current I∆n (mA)</span>
                          </th>
                          <th rowSpan={3} className="table-header--vertical">
                            <span>
                              Maximum permissted Zs for installed protective
                              device
                            </span>
                          </th>
                          <th colSpan={5}>Circuit impedances (Ohms)</th>
                          <th colSpan={3}>Insulation resistance</th>
                          <th rowSpan={3} className="table-header--vertical">
                            <span>Polarity</span>
                          </th>
                          <th rowSpan={3} className="table-header--vertical">
                            <span>
                              Max. measured earth fault loop impedance, Zs
                            </span>
                          </th>
                          <th rowSpan={3} className="table-header--vertical">
                            <span>RCD operating time (ms)</span>
                          </th>
                          <th rowSpan={2} colSpan={2}>
                            Test buttons
                          </th>
                          <th rowSpan={3} className="table-header--vertical">
                            Vulnerable to damage
                          </th>
                        </tr>
                        <tr>
                          <td rowSpan={2}>Live (mm2)</td>
                          <td rowSpan={2}>cpc (mm2)</td>
                          <td rowSpan={2}>BS (EN)</td>
                          <td rowSpan={2}>Type</td>
                          <td rowSpan={2}>Rating (A)</td>
                          <td rowSpan={2}>Short-circuit capacity (kA)</td>
                          <td colSpan={3}>
                            Ring final circuits only (measured end to end)
                          </td>
                          <td colSpan={2}>
                            All circuits (complete at least one column)
                          </td>
                          <td rowSpan={2}>Live / Live (MegaOhms)</td>
                          <td rowSpan={2}>Live / Earth (MegaOhms)</td>
                          <td rowSpan={2}>Test voltage DC (V)</td>
                        </tr>
                        <tr>
                          <th>(Line) r1</th>
                          <th>(Neutral) rn</th>
                          <th>(cpc) r2</th>
                          <th>(R1 + R2)</th>
                          <th>R2</th>
                          <td>RCD</td>
                          <td>AFDD</td>
                        </tr>
                      </thead>
                      <tbody>
                        {db.circuits?.map((circuit: any, i: number) => (
                          <tr key={`db-circuits-${i}`}>
                            <td>{circuit.number}</td>
                            <td>{circuit.description}</td>
                            <td>
                              {circuit.wiringType ?? circuit.wiringTypeOther}
                            </td>
                            <td>{circuit.referenceMethod}</td>
                            <td>{circuit.numPointsServed}</td>
                            <td>{circuit.liveConductorCsa}</td>
                            <td>{circuit.cpcConductorCsa}</td>
                            <td>{circuit.maxDisconnectionTime}</td>
                            <td>{circuit.overcurrentProtectiveDevice}</td>
                            <td>
                              {defaultToNa(
                                circuit.overcurrentProtectiveDeviceType
                              )}
                            </td>
                            <td>{circuit.currentRating}</td>
                            <td>{circuit.shortCircuitCapacity}</td>
                            <td>{circuit.rcdOperatingCurrent}</td>
                            <td>{defaultToNa(circuit.maxPermittedZs)}</td>
                            <td>{defaultToNa(circuit.lineImpedance)}</td>
                            <td>{defaultToNa(circuit.neutralImpedance)}</td>
                            <td>{defaultToNa(circuit.cpcImpedance)}</td>
                            <td>{defaultToNa(circuit.r1PlusR2Impedance)}</td>
                            <td>{defaultToNa(circuit.r2Impedance)}</td>
                            <td>
                              {defaultToNa(
                                circuit.liveLiveInsulationResistance
                              )}
                            </td>
                            <td>
                              {defaultToNa(
                                circuit.liveEarthInsulationResistance
                              )}
                            </td>
                            <td>{circuit.testVoltage}</td>
                            <td>{circuit.polarity}</td>
                            <td>
                              {defaultToNa(
                                circuit.maxMeasuredEarthFaultLoopImpedance
                              )}
                            </td>
                            <td>{defaultToNa(circuit.rcdOperatingTime)}</td>
                            <td>{circuit.rcdTestButtonOperation}</td>
                            <td>{circuit.afddTestButtonOperation}</td>
                            <td>{circuit.vulnerableToDamage ? '✓' : '✗'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  <h5>Tested by</h5>
                  <div className="form-template-row circuit-details no-page-break">
                    <LabelValuePair
                      label="Name"
                      value={db.testEngineerName.toLocaleUpperCase()}
                    />
                    <LabelValuePair
                      label="Position"
                      value={db.testEngineerPosition}
                    />
                    <LabelValuePair
                      label="Signature"
                      value={
                        <div className={'form-template-signature'}>
                          {db.testEngineerSignatureBase64 && (
                            <img src={db.testEngineerSignatureBase64} />
                          )}
                        </div>
                      }
                    />
                    <LabelValuePair
                      label="Date"
                      value={moment(db.testEngineerDate).format('DD/MM/YYYY')}
                    />
                  </div>
                  <h5>Test instruments</h5>
                  <div className="form-template-row circuit-details no-page-break">
                    <LabelValuePair
                      label="Multi-function"
                      value={
                        db.multiFunctionTestInstrumentSerialNumber || 'N/A'
                      }
                    />
                    <LabelValuePair
                      label="Continuity"
                      value={db.continuityTestInstrumentSerialNumber || 'N/A'}
                    />
                    <LabelValuePair
                      label="Insulation resistance"
                      value={
                        db.insulationResistanceTestInstrumentSerialNumber ||
                        'N/A'
                      }
                    />
                    <LabelValuePair
                      label="Earth fault loop impedance"
                      value={
                        db.earthFaultLoopImpedanceTestInstrumentSerialNumber ||
                        'N/A'
                      }
                    />
                    <LabelValuePair
                      label="Earth electrode resistance"
                      value={
                        db.earthElectrodeResistanceTestInstrumentSerialNumber ||
                        'N/A'
                      }
                    />
                    <LabelValuePair
                      label="RCD"
                      value={db.rcdTestInstrumentSerialNumber || 'N/A'}
                    />
                  </div>
                </section>
              </>
            )) ?? <p>N/A</p>}
          </OutlinedContentPanel>
        </div>
      )} */}
    </div>
  );
};

export default ElectricalInstallationConditionReport18x2;
