import React, { FC, PropsWithChildren } from 'react';

import AuthenticatedPageWrapper from '../authenticatedPageWrapper/AuthenticatedPageWrapper';
import PaginationControl from '@payaca/components/paginationControl/PaginationControl';

import './ListViewPageWrapper.sass';

type Props = {
  pageBanners?: JSX.Element[];
  className?: string;
  title?: string | JSX.Element;
  searchFieldConfig?: {
    onSearchTermChange: (searchTerm?: string) => void;
    placeholder?: string;
  };
  paginationConfig?: {
    pageNumber: number;
    totalItemCount: number;
    pageSize: number;
    allowPageSizeSelection?: boolean;
    availablePageSizes?: number[];
    onSelectPage: (pageNumber: number) => void;
    onSelectPageSize?: (pageSize: number) => void;
  };
  previousPageNavigationConfig?: {
    route: string;
    navigationPromptName?: string;
  };
};
const ListViewPageWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  pageBanners,
  title,
  searchFieldConfig,
  paginationConfig,
  previousPageNavigationConfig,
}: PropsWithChildren<Props>): JSX.Element => {
  return (
    <AuthenticatedPageWrapper
      previousPageNavigationConfig={previousPageNavigationConfig}
      pageBanners={pageBanners}
      className={`list-view-page-wrapper ${className ? className : ''}`}
      searchFieldConfig={searchFieldConfig}
      title={title}
    >
      <div className="list-view-content">{children}</div>
      {paginationConfig && (
        <div className="pagination-control-container">
          <PaginationControl {...paginationConfig} />
        </div>
      )}
    </AuthenticatedPageWrapper>
  );
};

export default ListViewPageWrapper;
