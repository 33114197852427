import React, { FC, useMemo } from 'react';

import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import PaginationControl from '@payaca/components/paginationControl/PaginationControl';
import ListedMaterialLineItemComponent from '../listedMaterialLineItem/ListedMaterialLineItem';
import EmptyState from '../emptyState/EmptyState';

import { ListedMaterialLineItem } from '@payaca/types/listedMaterialLineItemTypes';

import './ListedMaterialLineItems.sass';
import { useSelector } from '@/api/state';

type Props = {
  onSelectPage: (pageNumber: number) => void;
};
const ListedMaterialLineItems: FC<Props> = ({
  onSelectPage,
}: Props): JSX.Element => {
  const listedMaterialLineItemsPage = useSelector((state) => {
    return state.listedMaterialLineItems.listedMaterialLineItemsPage;
  });
  const isGettingListedMaterialLineItemsPage = useSelector(
    (state) =>
      state.listedMaterialLineItems.isGettingListedMaterialLineItemsPage
  );

  const paginationControl = useMemo(() => {
    if (!listedMaterialLineItemsPage) return null;
    return (
      <PaginationControl
        pageNumber={listedMaterialLineItemsPage.pageNumber}
        pageSize={listedMaterialLineItemsPage.pageSize}
        totalItemCount={listedMaterialLineItemsPage.totalItemCount}
        onSelectPage={onSelectPage}
        allowPageSizeSelection={false}
      />
    );
  }, [listedMaterialLineItemsPage, onSelectPage]);

  return (
    <div className="listed-material-line-items">
      <div className="material-line-items-list">
        {isGettingListedMaterialLineItemsPage && (
          <div className="loader-container">
            <MiniLoader />
          </div>
        )}
        {!isGettingListedMaterialLineItemsPage &&
          !listedMaterialLineItemsPage?.items?.length && (
            <EmptyState configName={'material-items'} arrowIndication={false} />
          )}
        {listedMaterialLineItemsPage?.items?.map(
          (listedMaterialLineItem: ListedMaterialLineItem, index: number) => {
            return (
              <ListedMaterialLineItemComponent
                key={`listed-material-line-item-${index}`}
                materialLineItem={listedMaterialLineItem}
                lineItem={listedMaterialLineItem.lineItem}
              />
            );
          }
        )}
      </div>
      {paginationControl}
    </div>
  );
};

export default ListedMaterialLineItems;
