import React, { FC } from 'react';

import { ModalWrapper } from '@/ui/components';
import ListedItemGroupsControl from '../listedItemGroupsControl/ListedItemGroupsControl';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSelectItemGroup: (itemGroupId: number) => void;
};
const SelectItemGroupModal: FC<Props> = ({
  isOpen,
  onClose,
  onSelectItemGroup,
}: Props): JSX.Element => {
  return (
    <React.Fragment>
      <ModalWrapper
        open={isOpen}
        title={'Select an item group'}
        onClose={onClose}
        classes={'select-item-group-modal'}
        size="md"
        showCloseIcon={true}
      >
        <ListedItemGroupsControl
          onClick={(lineItemGroup: any) => {
            onSelectItemGroup(lineItemGroup);
          }}
        />
      </ModalWrapper>
    </React.Fragment>
  );
};

export default SelectItemGroupModal;
