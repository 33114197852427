import React, { FC } from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import AuthenticatedPageWrapper from '@/ui/pages/pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { useTranslation } from '@/i18n';
import ServicePlanCustomerReminderControl from '@/ui/components/servicePlanCustomerReminderControl/ServicePlanCustomerReminderControl';

interface IProps {}

const ServicePlansCustomerReminders: FC<IProps> = () => {
  const { customerReminderId } = useParams<{ customerReminderId?: string }>();
  const translate = useTranslation();

  const isCreatingReminder = customerReminderId === 'new';

  return (
    <>
      <Helmet title={translate('pages.servicePlanCustomerReminders.title')} />
      <AuthenticatedPageWrapper
        className="service-plan-page"
        title={
          isCreatingReminder ? (
            <h1>New Customer Reminder</h1>
          ) : (
            <h1>Edit Customer Reminder</h1>
          )
        }
        previousPageNavigationConfig={{
          route: '/service-plans/customer-reminders',
          navigationPromptName: 'Back',
        }}
      >
        <ServicePlanCustomerReminderControl
          servicePlanCustomerReminderPublicId={
            !isCreatingReminder ? customerReminderId : undefined
          }
        />
      </AuthenticatedPageWrapper>
    </>
  );
};

export default ServicePlansCustomerReminders;
