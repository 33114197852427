import moment from 'moment-timezone';

export enum DurationUnit {
  SECONDS = 'seconds',
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
  MONTHS = 'months',
  YEARS = 'years',
}
enum DurationLabel {
  SECONDS = 'seconds',
  SECOND = 'second',
  MINUTES = 'minutes',
  MINUTE = 'minute',
  HOURS = 'hours',
  HOUR = 'hour',
  DAYS = 'days',
  DAY = 'day',
  MONTHS = 'months',
  MONTH = 'month',
  YEARS = 'years',
  YEAR = 'year',
}

const durationLabelMap = {
  [DurationUnit.SECONDS]: {
    singular: DurationLabel.SECOND,
    plural: DurationLabel.SECONDS,
    singularAbbr: 'sec',
    pluralAbbr: 'secs',
  },
  [DurationUnit.MINUTES]: {
    singular: DurationLabel.MINUTE,
    plural: DurationLabel.MINUTES,
    singularAbbr: 'min',
    pluralAbbr: 'mins',
  },
  [DurationUnit.HOURS]: {
    singular: DurationLabel.HOUR,
    plural: DurationLabel.HOURS,
    singularAbbr: 'hr',
    pluralAbbr: 'hrs',
  },
  [DurationUnit.DAYS]: {
    singular: DurationLabel.DAY,
    plural: DurationLabel.DAYS,
    singularAbbr: 'day',
    pluralAbbr: 'days',
  },
  [DurationUnit.MONTHS]: {
    singular: DurationLabel.MONTH,
    plural: DurationLabel.MONTHS,
    singularAbbr: 'mth',
    pluralAbbr: 'mths',
  },
  [DurationUnit.YEARS]: {
    singular: DurationLabel.YEAR,
    plural: DurationLabel.YEARS,
    singularAbbr: 'yr',
    pluralAbbr: 'yrs',
  },
};

/**
 * Returns value, unit and label of duration between start and end date
 */
export const getTimeDuration = (
  startDate: Date | undefined | string | null,
  endDate: Date | undefined | string | null
): {
  value: number;
  unit: DurationUnit;
  label: string;
} | null => {
  if (!endDate || !startDate) {
    return null;
  }
  const eventDuration = moment.duration(
    moment(endDate).diff(moment(startDate))
  );

  return getTimeDurationFromMomentDuration(eventDuration);
};

export const roundDateTimeUpToHalfHour = (dateTime: Date) => {
  const startAt = moment(dateTime);
  const remainder = 30 - (startAt.minute() % 30);

  return moment(startAt).add(remainder, 'minutes').set('seconds', 0);
};

export const formatToMinimumTwoDigits = (num: number) => {
  return num < 10 ? `0${num}` : num;
};

const getTimeDurationFromMomentDuration = (duration: moment.Duration) => {
  const monthsDuration = duration.asMonths();
  if (monthsDuration >= 1) {
    return {
      value: monthsDuration,
      unit: DurationUnit.MONTHS,
      label: monthsDuration === 1 ? DurationLabel.MONTH : DurationLabel.MONTHS,
    };
  } else {
    const daysDuration = duration.asDays();
    if (daysDuration >= 1) {
      return {
        value: daysDuration,
        unit: DurationUnit.DAYS,
        label: daysDuration === 1 ? DurationLabel.DAY : DurationLabel.DAYS,
      };
    } else {
      const hoursDuration = duration.asHours();
      if (hoursDuration >= 1) {
        return {
          value: hoursDuration,
          unit: DurationUnit.HOURS,
          label: hoursDuration === 1 ? DurationLabel.HOUR : DurationLabel.HOURS,
        };
      } else {
        const minutesDuration = duration.asMinutes();
        if (minutesDuration >= 1) {
          return {
            value: minutesDuration,
            unit: DurationUnit.MINUTES,
            label:
              minutesDuration === 1
                ? DurationLabel.MINUTE
                : DurationLabel.MINUTES,
          };
        } else {
          const secondsDuration = duration.asSeconds();
          return {
            value: secondsDuration,
            unit: DurationUnit.SECONDS,
            label:
              secondsDuration === 1
                ? DurationLabel.SECOND
                : DurationLabel.SECONDS,
          };
        }
      }
    }
  }
};

export const getTimeDurationFromDurationString = (durationString: string) => {
  return getTimeDurationFromMomentDuration(moment.duration(durationString));
};

export const durationUnitHierarchy = [
  DurationUnit.YEARS,
  DurationUnit.MONTHS,
  DurationUnit.DAYS,
  DurationUnit.HOURS,
  DurationUnit.MINUTES,
  DurationUnit.SECONDS,
];

export const getReadableStringFromDurationString = (
  durationString: string,
  config: {
    units?: DurationUnit[];
    precision?: number;
    abbreviate?: boolean;
    includeUnitsWithZeroValue?: boolean;
    allowSingular?: boolean;
  }
) => {
  const {
    units,
    precision,
    abbreviate,
    includeUnitsWithZeroValue,
    allowSingular,
  } = {
    units: [DurationUnit.DAYS, DurationUnit.HOURS, DurationUnit.MINUTES],
    precision: 0,
    abbreviate: false,
    includeUnitsWithZeroValue: false,
    allowSingular: true,
    ...config,
  };
  const duration = moment.duration(durationString);

  const readableStringComponents: string[] = [];

  durationUnitHierarchy.forEach((unit, i) => {
    if (units.includes(unit)) {
      const value = duration.as(unit);

      let roundedValue = 0;

      const isLastUnit = !units.some(
        (u) => durationUnitHierarchy.indexOf(u) > i
      );

      if (!isLastUnit) {
        roundedValue = Math.floor(value);
      } else {
        const precisionMultiplier = 10 ** precision;
        roundedValue =
          Math.round(value * precisionMultiplier) / precisionMultiplier;
      }

      if (roundedValue || includeUnitsWithZeroValue) {
        const x =
          roundedValue == 1 && allowSingular
            ? abbreviate
              ? 'singularAbbr'
              : 'singular'
            : abbreviate
            ? 'pluralAbbr'
            : 'plural';

        let valueString = roundedValue.toString();
        if (isLastUnit && roundedValue === 0 && value > 0) {
          valueString = `<${1 / 10 ** precision}`;
        }

        readableStringComponents.push(
          `${valueString}${abbreviate ? '' : ' '}${durationLabelMap[unit][x]}`
        );
        duration.subtract(moment.duration(roundedValue, unit));
      }
    }
  });

  return readableStringComponents.join(' ');
};
