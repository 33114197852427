import React, { FC, useEffect, useRef, useState } from 'react';

import './ResponsivePopperAndTrigger.sass';
import {
  ClickAwayListener,
  Drawer,
  Grow,
  Paper,
  Popper,
  PopperPlacementType,
} from '@material-ui/core';

type Props = {
  isSmallView?: boolean;
  useDrawerWhenInSmallView?: boolean;
  popperPlacement?: PopperPlacementType;
  popperClassName?: string;
  drawerClassName?: string;
  renderTriggerContent: (isSmallView: boolean) => React.ReactNode;
  renderPopperContent: (isSmallView: boolean) => React.ReactNode;
  hideTrigger?: any;
};
const ResponsivePopperAndTrigger: FC<Props> = ({
  isSmallView = false,
  popperPlacement = 'bottom-start',
  popperClassName,
  drawerClassName,
  renderTriggerContent,
  renderPopperContent,
  useDrawerWhenInSmallView = true,
  hideTrigger,
}: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [showPopper, setShowPopper] = useState(false);

  useEffect(() => {
    setShowPopper(false);
  }, [hideTrigger]);

  return (
    <div
      className={`responsive-popper-and-trigger ${
        isSmallView ? 'sm-view' : ''
      } ${showPopper ? 'active' : ''}`}
    >
      <div
        className="show-popper-trigger"
        ref={ref}
        onClick={() => setShowPopper(true)}
      >
        {renderTriggerContent(isSmallView)}
      </div>
      {(!isSmallView || !useDrawerWhenInSmallView) && (
        <Popper
          open={showPopper && !!ref.current}
          anchorEl={ref.current}
          role={undefined}
          transition
          placement={popperPlacement}
          className={`responsive-popper-and-trigger-popper ${
            popperClassName || ''
          }`}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    setShowPopper(false);
                  }}
                >
                  <div>{renderPopperContent(isSmallView)}</div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      {isSmallView && useDrawerWhenInSmallView && (
        <Drawer
          anchor="bottom"
          open={showPopper}
          className={`responsive-popper-and-trigger-drawer ${
            drawerClassName || ''
          }`}
        >
          <Paper>
            <ClickAwayListener
              onClickAway={() => {
                setShowPopper(false);
              }}
            >
              <div>{renderPopperContent(isSmallView)}</div>
            </ClickAwayListener>
          </Paper>
        </Drawer>
      )}
    </div>
  );
};

export default ResponsivePopperAndTrigger;
