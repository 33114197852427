import React, { FC, useEffect, useState } from 'react';
import Conditional from '@payaca/components/conditional/Conditional';
import Radio from '@payaca/components/plRadio/Radio';
import InputGroup, {
  InputGroupInput,
} from '@payaca/components/plInputGroup/InputGroup';
import { InputSizeVariant } from '@payaca/components/plInput/RawInput';
import CurrencyInput from '@payaca/components/plInput/CurrencyInput';
import { Field, FieldValidationState } from '@payaca/components/plField/Field';
import {
  calculateMarkupChange,
  MarkupType,
} from '@/ui/pages/listedItemsPage/components/utils';
import { clstx } from '@payaca/components/utils';

export interface IProps {
  hasLinkedMaterials: boolean;
  predictedMaterialCost?: number;
  markupTotals: MarkupTotals;
  onMarkupTotalChange: (markupTotals: MarkupTotals) => void;
}

type MarkupTotals = Record<MarkupType, number> & {
  markupType: MarkupType;
};

const LineItemPriceField: FC<IProps> = (props) => {
  const {
    hasLinkedMaterials,
    predictedMaterialCost,
    markupTotals,
    onMarkupTotalChange,
  } = props;

  const handleMarkupChange = (markupType: MarkupType) => (value: number) => {
    onMarkupTotalChange({
      markupType,
      ...calculateMarkupChange(markupType, value, predictedMaterialCost),
    });
  };

  return (
    <div
      className={clstx(
        hasLinkedMaterials && 'grid grid-cols-[auto_150px] items-center gap-2.5'
      )}
    >
      <Conditional condition={hasLinkedMaterials}>
        <div>
          <Field name="lineItemMarkupType">
            <Radio
              label="Profit %"
              value="profitPercentage"
              checked={markupTotals.markupType === 'profitPercentage'}
              onChange={(event) => {
                if (event.target.checked) {
                  onMarkupTotalChange({
                    ...markupTotals,
                    markupType: 'profitPercentage',
                  });
                }
              }}
            />
          </Field>
        </div>

        <div>
          <PercentageField
            inputClassName="text-right mr-2"
            value={markupTotals.profitPercentage || '0'}
            onFocus={() =>
              onMarkupTotalChange({
                ...markupTotals,
                markupType: 'profitPercentage',
              })
            }
            onChange={(value) => {
              handleMarkupChange('profitPercentage')(Number(value));
            }}
          />
        </div>

        <div>
          <Field name="lineItemMarkupType">
            <Radio
              label="Profit £"
              value="profitAmount"
              checked={markupTotals.markupType === 'profitAmount'}
              onChange={(event) => {
                if (event.target.checked) {
                  onMarkupTotalChange({
                    ...markupTotals,
                    markupType: 'profitAmount',
                  });
                }
              }}
            />
          </Field>
        </div>
        <div>
          <CurrencyInput
            sizeVariant={InputSizeVariant.SM}
            inputClassName="text-right"
            onFocus={() =>
              onMarkupTotalChange({
                ...markupTotals,
                markupType: 'profitAmount',
              })
            }
            value={markupTotals.profitAmount || 0}
            onChange={handleMarkupChange('profitAmount')}
          />
        </div>

        <div>
          <Field name="lineItemMarkupType">
            <Radio
              label="Price"
              value="price"
              checked={markupTotals.markupType === 'price'}
              onChange={(event) => {
                if (event.target.checked) {
                  onMarkupTotalChange({
                    ...markupTotals,
                    markupType: 'price',
                  });
                }
              }}
            />
          </Field>
        </div>
      </Conditional>

      <div>
        <CurrencyInput
          inputClassName="text-right"
          sizeVariant={InputSizeVariant.SM}
          onFocus={() =>
            onMarkupTotalChange({
              ...markupTotals,
              markupType: 'price',
            })
          }
          value={markupTotals.price || 0}
          onChange={handleMarkupChange('price')}
        />
      </div>
    </div>
  );
};

const PercentageField: FC<
  {
    onChange: (value: number) => void;
  } & Omit<InputGroupInput, 'onChange'>
> = (props) => {
  const { value, onChange, inputClassName, ...rest } = props;

  const [manualValue, setManualValue] = useState(value);
  const [validationState, setValidationState] =
    useState<FieldValidationState>();

  useEffect(() => {
    setManualValue(value);
  }, [value]);

  const handleManualValueChange = (value: string) => {
    setManualValue(value);

    const parsedValue = Number(value);
    if (isNaN(parsedValue) || parsedValue < 0) {
      setValidationState({
        isValid: false,
        validationMessages: ['Profit percentage must be 0 or more'],
      });
    } else if (parsedValue >= 100) {
      setValidationState({
        isValid: false,
        validationMessages: ['Profit percentage must be less than 100'],
      });
    } else {
      setValidationState(undefined);
      onChange(parsedValue);
    }
  };

  return (
    <Field validationState={validationState}>
      <InputGroup sizeVariant={InputSizeVariant.SM}>
        <InputGroup.Input
          type="number"
          inputClassName={clstx('pl-form-input--number', inputClassName)}
          value={manualValue}
          onChange={handleManualValueChange}
          {...rest}
        />
        <InputGroup.Icon name="percent-02.3" />
      </InputGroup>
    </Field>
  );
};

export default LineItemPriceField;
