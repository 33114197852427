import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import BasicField from '@payaca/components/basicField/BasicField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import TextareaField from '@payaca/components/textareaField/TextareaField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import ChecklistItemsControl from '../checklistItemsControl/ChecklistItemsControl';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import PillSelectionField from '@payaca/components/pillSelectionField/PillSelectionField';
import TextareaFieldMentionFormatter from '@payaca/components/textareaField/TextareaFieldMentionFormatter';

import { getLengthFieldValidator } from '@payaca/helpers/fieldValidationHelper';

import {
  FieldValidationResult,
  FieldValidator,
} from '@payaca/types/fieldValidationTypes';
import { PublicEntityTemplate } from '@payaca/types/entity-templates';
import { FormOptionGroups } from '@payaca/types/formTypes';

import * as formActions from '@payaca/store/forms/formsActions';

import { useSelector } from '@/api/state';

import './CreateEditTaskTemplateControl.sass';
import { isNullish } from '@payaca/utilities/guards';
import { sortedAvailableFormOptions } from '@payaca/helpers/formHelper';

enum TaskType {
  CHECKLIST = 'checklist',
  FORM = 'form',
}

const taskNameLengthFieldValidator = getLengthFieldValidator({ max: 250 });
const checklistItemLengthNameFieldValidator = getLengthFieldValidator({
  max: 500,
});

type Props = {
  taskTemplate?: Omit<PublicEntityTemplate<'task'>, 'publicId'>;
  onRequestSave?: (
    taskTemplate: Omit<PublicEntityTemplate<'task'>, 'publicId'>
  ) => void;
  allowModifyTemplateNameDescription?: boolean;
};
const CreateEditTaskTemplateControl: FunctionComponent<Props> = ({
  taskTemplate,
  onRequestSave,
  allowModifyTemplateNameDescription = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [checklistItemsCount, setChecklistItemsCount] = useState(0);

  const availableForms = useSelector((state) => state.forms.availableForms);

  const availableFormOptions = useMemo(
    () => availableForms && sortedAvailableFormOptions(availableForms),
    [availableForms]
  );

  const selectedFormTemplate = availableForms?.find((x) => {
    return (
      x.value.toString() === taskTemplate?.data?.formTemplateId?.toString()
    );
  });

  const [showAddTemplateNameDescription, setShowAddTemplateNameDescription] =
    useState<boolean>(
      allowModifyTemplateNameDescription &&
        (!!taskTemplate?.name?.length || !!taskTemplate?.description?.length)
    );

  useEffect(() => {
    // get available forms
    dispatch(formActions.requestGetAvailableForms());
  }, []);

  const initialFormState = useMemo(() => {
    return {
      name: taskTemplate?.name,
      description: taskTemplate?.description,
      entityType: taskTemplate?.entityType || 'task',
      taskType: !isNullish(taskTemplate?.data?.formTemplateId)
        ? TaskType.FORM
        : TaskType.CHECKLIST,
      data: {
        ...taskTemplate?.data,
        checklistItems: taskTemplate?.data?.checklistItems
          ? taskTemplate?.data?.checklistItems.map((x) => ({ name: x }))
          : [],
      },
    };
  }, [taskTemplate]);

  const fieldValidators = useMemo(() => {
    const fv: { [fieldName: string]: FieldValidator[] } = {
      name: [taskNameLengthFieldValidator],
    };

    for (let i = 0; i < checklistItemsCount; i++) {
      fv[`checklistItems[${i}].name`] = [checklistItemLengthNameFieldValidator];
    }

    return fv;
  }, [checklistItemsCount]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      setChecklistItemsCount(formState.data.checklistItems?.length || 0);

      return (
        <>
          {!showAddTemplateNameDescription &&
            allowModifyTemplateNameDescription && (
              <Button
                className="add-template-name-description-button"
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={() => setShowAddTemplateNameDescription(true)}
              >
                Add Template name and description
              </Button>
            )}
          {showAddTemplateNameDescription && (
            <>
              <ValidatedFieldWrapper
                validationResult={validationState['name']}
                isTouched={touchedState['name'] || false}
              >
                <BasicField
                  name="name"
                  value={formState.name || ''}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  label="Template name"
                  styleVariant={InputStyleVariant.OUTSIZE}
                />
              </ValidatedFieldWrapper>
              <ValidatedFieldWrapper
                validationResult={validationState['description']}
                isTouched={touchedState['description'] || false}
              >
                <TextareaField
                  name="description"
                  value={formState.description || ''}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  label="Template description"
                  styleVariant={InputStyleVariant.OUTSIZE}
                />
              </ValidatedFieldWrapper>
            </>
          )}
          <PillSelectionField
            options={[
              { value: TaskType.CHECKLIST, label: 'Checklist' },
              { value: TaskType.FORM, label: 'Form' },
            ]}
            name="taskType"
            value={formState.taskType}
            onChange={(value: { [key: string]: any }) => {
              const change = { ...value };

              if (value.taskType !== TaskType.CHECKLIST) {
                // changing away from checklist - clear checklist items
                change['data.checklistItems'] = [];
              } else if (value.taskType !== TaskType.FORM) {
                // changing away from form - clear formTemplateId
                change['data.formTemplateId'] = [];
              }
              change['data.name'] = '';

              onFieldChange(change);
            }}
            hasBorder={true}
          />
          {formState.taskType === TaskType.CHECKLIST && (
            <>
              <ValidatedFieldWrapper
                validationResult={validationState['data.name']}
                isTouched={touchedState['data.name'] || false}
              >
                <BasicField
                  name="data.name"
                  value={formState.data?.name}
                  label="Task name"
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  styleVariant={InputStyleVariant.OUTSIZE}
                />
              </ValidatedFieldWrapper>
              <div>
                <div className="checklist-items-label">
                  <small>
                    <FieldLabel label={'Checklist'} />
                  </small>
                </div>
                <ChecklistItemsControl
                  checklistItems={formState.data?.checklistItems}
                  fieldNamePrefix={'data.checklistItems'}
                  onChange={onFieldChange}
                  onTouch={onFieldTouch}
                  touchedState={touchedState}
                  validationState={validationState}
                />
              </div>
            </>
          )}
          {formState.taskType === TaskType.FORM && (
            <ValidatedFieldWrapper
              validationResult={
                selectedFormTemplate?.archivedAt
                  ? {
                      isValid: false,
                      errors: [
                        `The previously selected form, '${selectedFormTemplate.label}', has been archived. Please choose a different type of form.`,
                      ],
                    }
                  : validationState['data.formTemplateId']
              }
              isTouched={
                selectedFormTemplate?.archivedAt
                  ? true
                  : touchedState['data.formTemplateId'] || false
              }
            >
              {availableFormOptions?.length ? (
                <DropdownField
                  label="Form"
                  value={formState.data.formTemplateId}
                  name="data.formTemplateId"
                  options={availableFormOptions}
                  onChange={(value: { [key: string]: any }) => {
                    const change = { ...value };

                    const selectedFormOptionValue = availableFormOptions.find(
                      (i) => i.value === change['data.formTemplateId']
                    );

                    if (
                      formState.taskType === TaskType.FORM &&
                      selectedFormOptionValue
                    ) {
                      change['data.name'] = selectedFormOptionValue.label;
                    }

                    onFieldChange(change);
                  }}
                  styleVariant={InputStyleVariant.OUTSIZE}
                  optionGroups={FormOptionGroups}
                />
              ) : (
                <div className="loader-container">
                  <MiniLoader />
                </div>
              )}
            </ValidatedFieldWrapper>
          )}
          <TextareaFieldMentionFormatter
            label="Task notes"
            name="data.notes"
            onChange={onFieldChange}
            value={formState.data?.notes}
          />
          <div className="actions-container">
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isDisabled={!isValid}
              onClick={() => {
                const { taskType, ...taskTemplate } = formState;
                const { checklistItems, ...data } = taskTemplate.data;

                onRequestSave?.({
                  ...taskTemplate,
                  data: {
                    ...data,
                    checklistItems: checklistItems?.map((x: any) => x.name),
                  },
                } as Omit<PublicEntityTemplate<'task'>, 'publicId'>);
              }}
            >
              {taskTemplate ? 'Save' : 'Create'} Task Template
            </Button>
          </div>
        </>
      );
    },
    [
      selectedFormTemplate,
      availableFormOptions,
      taskTemplate,
      onRequestSave,
      showAddTemplateNameDescription,
      allowModifyTemplateNameDescription,
    ]
  );

  return (
    <div className="create-edit-task-template-control">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
      />
    </div>
  );
};

export default CreateEditTaskTemplateControl;
