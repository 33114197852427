import {
  LineItemGroup,
  LineItemGroupLineItem,
} from '@payaca/types/lineItemGroupTypes';
import { LineItem } from '@payaca/types/lineItemTypes';

interface LineItemTotals {
  subtotal: number;
  total: number;
  vatTotal: number;
}

// inc VAT
interface LineItemGroupTotals {
  total: number;
  minTotal: number;
  maxTotal: number;
  hasAnyNonRequiredItems?: boolean;
}

const getTotalSum = (
  lineItemIds: number[],
  lineItemsTotals: { [key: number]: LineItemTotals }
) => {
  const totalsArray = lineItemIds.map((x) => lineItemsTotals[x].total);
  return totalsArray.reduce((a, b) => a + b, 0);
};

const calculateMultipleChoiceTotals = (
  lineItemRelations: LineItemGroupLineItem[],
  lineItemsTotals: { [key: number]: LineItemTotals }
): LineItemGroupTotals => {
  const total = getTotalSum(
    lineItemRelations.filter((x) => x.isSelected).map((x) => x.lineItemId),
    lineItemsTotals
  );
  const itemTotals = lineItemRelations.map(
    (x) => lineItemsTotals[x.lineItemId].total
  );

  return {
    total: total,
    minTotal: itemTotals.length ? Math.min(...itemTotals) : 0,
    maxTotal: itemTotals.length ? Math.max(...itemTotals) : 0,
  };
};

const calculateOptionalTotals = (
  lineItemRelations: LineItemGroupLineItem[],
  lineItemsTotals: { [key: number]: LineItemTotals }
): LineItemGroupTotals => {
  const total = getTotalSum(
    lineItemRelations.filter((x) => x.isSelected).map((x) => x.lineItemId),
    lineItemsTotals
  );
  const maxTotal = getTotalSum(
    lineItemRelations.map((x) => x.lineItemId),
    lineItemsTotals
  );

  return {
    total: total,
    minTotal: 0,
    maxTotal: maxTotal,
  };
};

const calculateLineItemTotals = (
  lineItem: LineItem,
  quantity: number
): LineItemTotals => {
  const subtotal = (lineItem?.calculatedPrice || 0) * quantity;
  const vatTotal =
    lineItem?.vatIncluded && !lineItem?.isReverseChargeVat
      ? subtotal * ((lineItem?.vatAmount || 0) / 100)
      : 0;

  return {
    subtotal: subtotal,
    vatTotal: vatTotal,
    total: subtotal + vatTotal,
  };
};

const calculateLineItemsTotals = (
  lineItemRelations: LineItemGroupLineItem[],
  lineItems: { [key: number]: LineItem }
) => {
  const lineItemsTotals: { [key: number]: LineItemTotals } = {};

  lineItemRelations.forEach((lineItemRelation) => {
    const lineItemId = lineItemRelation.lineItemId;
    const lineItemTotals = calculateLineItemTotals(
      lineItems[lineItemId],
      lineItemRelation.quantity
    );
    lineItemsTotals[lineItemId] = lineItemTotals;
  });

  return lineItemsTotals;
};

export const calculateLineItemGroupTotals = (
  lineItemGroup: LineItemGroup,
  lineItems: LineItem[]
): LineItemGroupTotals => {
  const lineItemRelations = lineItemGroup.lineItemRelations || [];

  const lineItemsObject = Object.fromEntries(
    lineItems.map((lineItem) => [lineItem.id, lineItem])
  );

  const lineItemsTotals = calculateLineItemsTotals(
    lineItemRelations,
    lineItemsObject
  );

  const requiredLineItemRelations = lineItemRelations.filter(
    (lineItemRelation) =>
      !lineItemRelation.isMultipleChoice && !lineItemRelation.isOptional
  );
  const multipleChoiceLineItemRelations = lineItemRelations.filter(
    (lineItemRelation) => lineItemRelation.isMultipleChoice
  );
  const optionalLineItemRelations = lineItemRelations.filter(
    (lineItemRelation) => lineItemRelation.isOptional
  );

  const requiredTotal = getTotalSum(
    requiredLineItemRelations.map((x) => x.lineItemId),
    lineItemsTotals
  );
  const multipleChoiceTotals = calculateMultipleChoiceTotals(
    multipleChoiceLineItemRelations,
    lineItemsTotals
  );
  const optionalTotals = calculateOptionalTotals(
    optionalLineItemRelations,
    lineItemsTotals
  );

  return {
    total: requiredTotal + multipleChoiceTotals.total + optionalTotals.total,
    minTotal:
      requiredTotal + multipleChoiceTotals.minTotal + optionalTotals.minTotal,
    maxTotal:
      requiredTotal + multipleChoiceTotals.maxTotal + optionalTotals.maxTotal,
    hasAnyNonRequiredItems:
      !!multipleChoiceLineItemRelations.length ||
      !!optionalLineItemRelations.length,
  };
};
