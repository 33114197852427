import { Note } from '@payaca/types/notesTypes';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { DeclareAsyncAction } from '../types';

export interface State {
  notes: { [key: number]: FetchedEntity<Note> };
  isGettingNotes: boolean;

  isCreatingNote: boolean;
  hasCreatedNoteSuccessfully: null | boolean;
  createNoteErrorMessage: null | { errors: string };

  isUpdatingNote: boolean;
  hasUpdatedNoteSuccessfully: null | boolean;
  updateNoteErrorMessage: null | { errors: string };

  isArchivingNote: boolean;
  hasArchivedNoteSuccessfully: null | boolean;
  archiveNoteErrorMessage: null | { errors: string };

  isGettingNotesForScheduledEvent: boolean;
}

export enum ActionType {
  CREATE_SCHEDULED_EVENT_NOTE_REQUEST = 'notes.createScheduledEventNoteRequest',
  CREATE_SCHEDULED_EVENT_NOTE_SUCCESS = 'notes.createScheduledEventNoteSuccess',
  CREATE_SCHEDULED_EVENT_NOTE_FAILURE = 'notes.createScheduledEventNoteFailure',

  UPDATE_SCHEDULED_EVENT_NOTE_REQUEST = 'notes.updateScheduledEventNoteRequest',
  UPDATE_SCHEDULED_EVENT_NOTE_SUCCESS = 'notes.updateScheduledEventNoteSuccess',
  UPDATE_SCHEDULED_EVENT_NOTE_FAILURE = 'notes.updateScheduledEventNoteFailure',

  ARCHIVE_SCHEDULED_EVENT_NOTE_REQUEST = 'notes.archiveScheduledEventNoteRequest',
  ARCHIVE_SCHEDULED_EVENT_NOTE_SUCCESS = 'notes.archiveScheduledEventNoteSuccess',
  ARCHIVE_SCHEDULED_EVENT_NOTE_FAILURE = 'notes.archiveScheduledEventNoteFailure',

  REQUEST_CREATE_NOTE = 'notes.requestCreateNote',
  CREATE_NOTE_SUCCESS = 'notes.createNoteSuccess',
  CREATE_NOTE_FAILURE = 'notes.createNoteFailure',

  REQUEST_UPDATE_NOTE = 'notes.requestupdateNote',
  UPDATE_NOTE_SUCCESS = 'notes.updateNoteSuccess',
  UPDATE_NOTE_FAILURE = 'notes.updateNoteFailure',

  REQUEST_ARCHIVE_NOTE = 'notes.requestArchiveNote',
  ARCHIVE_NOTE_SUCCESS = 'notes.archiveNoteSuccess',
  ARCHIVE_NOTE_FAILURE = 'notes.archiveNoteFailure',

  CLEAR_NOTE = 'notes.clearNote',
  CLEAR_NOTES_STORE = 'notes.clearNotesStore',

  GET_NOTES_FOR_DEAL_REQUEST = 'notes.getNotesForDeal:request',
  GET_NOTES_FOR_DEAL_SUCCESS = 'notes.getNotesForDeal:success',
  GET_NOTES_FOR_DEAL_FAILURE = 'notes.getNotesForDeal:failure',

  GET_NOTES_FOR_SCHEDULED_EVENT_REQUEST = 'notes.getNotesForScheduledEvent:request',
  GET_NOTES_FOR_SCHEDULED_EVENT_SUCCESS = 'notes.getNotesForScheduledEvent:success',
  GET_NOTES_FOR_SCHEDULED_EVENT_FAILURE = 'notes.getNotesForScheduledEvent:failure',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export type GetNotesForDeal = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_NOTES_FOR_DEAL_REQUEST;
      payload: {
        dealId: number;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
    success: { type: ActionType.GET_NOTES_FOR_DEAL_SUCCESS };
    failure: { type: ActionType.GET_NOTES_FOR_DEAL_FAILURE };
  }
>;
export type GetNotesForScheduledEvent = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_NOTES_FOR_SCHEDULED_EVENT_REQUEST;
      payload: {
        scheduledEventId: number;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
    success: { type: ActionType.GET_NOTES_FOR_SCHEDULED_EVENT_SUCCESS };
    failure: { type: ActionType.GET_NOTES_FOR_SCHEDULED_EVENT_FAILURE };
  }
>;
export type CreateScheduledEventNote = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_SCHEDULED_EVENT_NOTE_REQUEST;
      payload: {
        scheduledEventId: number;
        note: Partial<Note>;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
    success: { type: ActionType.CREATE_SCHEDULED_EVENT_NOTE_SUCCESS };
    failure: { type: ActionType.CREATE_SCHEDULED_EVENT_NOTE_FAILURE };
  }
>;
export type UpdateScheduledEventNote = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_SCHEDULED_EVENT_NOTE_REQUEST;
      payload: {
        scheduledEventId: number;
        note: Partial<Note>;
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
    success: { type: ActionType.UPDATE_SCHEDULED_EVENT_NOTE_SUCCESS };
    failure: { type: ActionType.UPDATE_SCHEDULED_EVENT_NOTE_FAILURE };
  }
>;
export type ArchiveScheduledEventNote = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.ARCHIVE_SCHEDULED_EVENT_NOTE_REQUEST;
      payload: {
        scheduledEventId: number;
        noteId: Note['id'];
        callback?: () => void;
        onErrorCallback?: () => void;
      };
    };
    success: { type: ActionType.ARCHIVE_SCHEDULED_EVENT_NOTE_SUCCESS };
    failure: { type: ActionType.ARCHIVE_SCHEDULED_EVENT_NOTE_FAILURE };
  }
>;
