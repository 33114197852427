// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import { GetMaterialsInput, PaginationInput } from '../../../gql/graphql';

const materialKeys = {
  all: ['material'] as const,
  categories: ['material', 'categories'] as const,
  materials: (pagination?: PaginationInput, input?: GetMaterialsInput) => {
    if (!pagination) {
      return [...materialKeys.all, 'materials'] as const;
    }

    if (!input) {
      return [...materialKeys.all, 'materials', pagination] as const;
    }

    return [...materialKeys.all, 'materials', pagination, input] as const;
  },
  infiniteMaterials: (input?: GetMaterialsInput) => {
    if (!input) {
      return ['material', 'infiniteMaterials'] as const;
    }

    return ['material', 'infiniteMaterials', input] as const;
  },
};

export default materialKeys;
