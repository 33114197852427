import { JobPayment } from '@payaca/types/jobPaymentTypes';
import moment from 'moment-timezone';

export const getDepositPaymentMethodFromJobPayments = (
  jobPayments: JobPayment[]
) => {
  const depositPayment = jobPayments.find(
    (x: JobPayment) => x.isDepositPayment && !x.paymentFailedConfirmationAt
  );

  return depositPayment?.paymentMethod;
};

export const getInvoicePaymentMethodFromJobPayments = (
  jobPayments: JobPayment[]
) => {
  const invoicePayment = jobPayments.find(
    (x: JobPayment) => !x.isDepositPayment && !x.paymentFailedConfirmationAt
  );

  return invoicePayment?.paymentMethod;
};

export const getTotalCompletedPaymentValueFromJobPayments = (
  jobPayments: JobPayment[]
) => {
  const completedPayments = jobPayments.filter(
    (x: JobPayment) => !!x.paymentCompletedConfirmationAt
  );
  return completedPayments.reduce(
    (accumulator: number, x: JobPayment) => accumulator + x.paymentValue,
    0
  );
};

export const getCompletedPaymentValueExcludingDepositFromJobPayments = (
  jobPayments: JobPayment[]
) => {
  const completedPaymentsExcludingDeposit = jobPayments.filter(
    (x: JobPayment) => !!x.paymentCompletedConfirmationAt && !x.isDepositPayment
  );
  return completedPaymentsExcludingDeposit.reduce(
    (accumulator: number, x: JobPayment) => accumulator + x.paymentValue,
    0
  );
};

export const getCompletedDepositPaymentValueFromJobPayments = (
  jobPayments: JobPayment[]
) => {
  const completedDepositPayments = jobPayments.filter(
    (x: JobPayment) => !!x.paymentCompletedConfirmationAt && x.isDepositPayment
  );
  return completedDepositPayments.reduce(
    (accumulator: number, x: JobPayment) => accumulator + x.paymentValue,
    0
  );
};

export const getAttemptedDepositPaymentValueFromJobPayments = (
  jobPayments: JobPayment[]
) => {
  const depositPayments = jobPayments.filter(
    (x: JobPayment) => x.isDepositPayment && !x.paymentFailedConfirmationAt
  );
  return depositPayments.reduce(
    (accumulator: number, x: JobPayment) => accumulator + x.paymentValue,
    0
  );
};

export const getTotalAttemptedPaymentValueFromJobPayments = (
  jobPayments: JobPayment[]
) => {
  const attemptedPayments = jobPayments.filter(
    (x: JobPayment) => !x.paymentFailedConfirmationAt
  );

  return attemptedPayments.reduce(
    (accumulator: number, x: JobPayment) => accumulator + x.paymentValue,
    0
  );
};

export const getBacsPendingPayments = (jobPayments: JobPayment[]) => {
  return jobPayments.filter(
    (x: JobPayment) =>
      !!x.bacsPaymentMadeAt &&
      !x.paymentCompletedConfirmationAt &&
      !x.paymentFailedConfirmationAt
  );
};

export const getBacsPendingDepositPaymentsFromJobPayments = (
  jobPayments: JobPayment[]
) => {
  return jobPayments.filter(
    (x: JobPayment) =>
      !!x.bacsPaymentMadeAt &&
      !x.paymentCompletedConfirmationAt &&
      !x.paymentFailedConfirmationAt &&
      !!x.isDepositPayment
  );
};

export const getBacsPendingPaymentsExcludingDepositFromJobPayments = (
  jobPayments: JobPayment[]
) => {
  return jobPayments.filter(
    (x: JobPayment) =>
      !!x.bacsPaymentMadeAt &&
      !x.paymentCompletedConfirmationAt &&
      !x.paymentFailedConfirmationAt &&
      !x.isDepositPayment
  );
};

export const getLatestDateFromJobPayment = (jobPayment: JobPayment) => {
  const array = [
    jobPayment.bacsPaymentMadeAt,
    jobPayment.stripePaymentAttemptedAt,
    jobPayment.paymentCompletedConfirmationAt,
    jobPayment.paymentFailedConfirmationAt,
  ]
    .filter(Boolean)
    .map((x) => moment(x));
  return moment.max(array).toDate();
};

export const getBacsPendingPaymentsFromJobPayments = (
  jobPayments: JobPayment[]
) => {
  return jobPayments.filter(
    (x: JobPayment) =>
      !!x.bacsPaymentMadeAt &&
      !x.paymentCompletedConfirmationAt &&
      !x.paymentFailedConfirmationAt
  );
};
