export enum ActionType {
  REQUEST_GET_USER_ROLES = 'userRoles.requestGetUserRoles',
  GET_USER_ROLES_SUCCESS = 'userRoles.getUserRolesSuccess',
  GET_USER_ROLES_FAILURE = 'userRoles.getUserRolesFailure',

  CLEAR_USER_ROLES = 'userRoles.clearUserRoles',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp: boolean;
}
