import CollapsiblePanel, {
  CollapsiblePanelStyleVariant,
} from '@payaca/components/collapsiblePanel/CollapsiblePanel';
import Button from '@payaca/components/button/Button';
import { ButtonColourVariant, ButtonStyleVariant } from '@payaca/components/button/enums';
import { FormElement } from '@payaca/types/formElementTypes';
import { createValidatedField } from '@/ui/pages/formPage/formUtils/createValidatedField';
import React, { FC, useMemo, useState } from 'react';
import FontAwesome from 'react-fontawesome';
import { EditSquare } from 'react-iconly';

interface Props {
  elem: FormElement;
  formState: any;
  changeHandler: any;
  onFieldTouch: any;
  touchedState: any;
  validationState: any;
  uiHandlers: any;
}

export const MultiItemFormElement: FC<Props> = ({
  elem,
  formState,
  changeHandler,
  onFieldTouch,
  touchedState,
  validationState,
  uiHandlers,
}) => {
  const [selectedMultiItemIndex, setSelectedMultiItemIndex] = useState<
    number | undefined
  >(0);

  const items = useMemo(() => {
    return formState?.[elem.id] || [];
  }, [formState]);

  return (
    <div className={'form-multi-item-wrapper'}>
      <div className={'form-multi-item-wrapper-header'}>
        {elem.icon ? (
          <FontAwesome name={(elem.icon || 'edit') + ' fa-lg'} />
        ) : (
          <EditSquare />
        )}
        <h4>{elem.title}</h4>
      </div>
      <p>
        {`You can add as many items as you require by pressing the ${
          items.length === 0
            ? 'button below'
            : 'link at the bottom of your items'
        }`}
        .
      </p>
      {items.map((a: any, b: any) => (
        <CollapsiblePanel
          key={b}
          onOpen={() => {
            window.scrollTo(0, 300 + b * 60);
            setSelectedMultiItemIndex(b);
          }}
          onClose={() => setSelectedMultiItemIndex(-1)}
          title={`${elem?.itemNoun || elem?.title || 'Section'} #${b + 1}`}
          isOpen={b === selectedMultiItemIndex}
          styleVariant={CollapsiblePanelStyleVariant.OUTSIZE}
        >
          <div className={'multi-item-container'}>
            <div className={'remove-item'}>
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={() => {
                  setSelectedMultiItemIndex(-1);
                  changeHandler({
                    [elem.id]: [
                      ...formState[elem.id].filter(
                        (item: any, i: number) => i !== b
                      ),
                    ],
                  });
                }}
              >
                Remove this item
              </Button>
            </div>
            <div>
              {elem.children &&
                elem.children.map((childElem: FormElement, ind: number) => (
                  <div key={ind}>
                    {createValidatedField(
                      childElem,
                      formState,
                      formState?.[elem.id]?.[b]?.[childElem.id] || '',
                      changeHandler,
                      onFieldTouch,
                      touchedState,
                      validationState,
                      uiHandlers,
                      ind,
                      `${elem.id}.${b}.${childElem.id}`
                    )}
                  </div>
                ))}
            </div>
          </div>
        </CollapsiblePanel>
      ))}
      <div className={'flex-container align-center'}>
        <Button
          onClick={() =>
            changeHandler({
              [elem.id]: [...(formState?.[elem.id] || []), {}],
            })
          }
          styleVariant={
            items.length === 0
              ? ButtonStyleVariant.OUTSIZE
              : ButtonStyleVariant.ANCHOR
          }
          colourVariant={
            items.length === 0
              ? ButtonColourVariant.PRIMARY
              : ButtonColourVariant.MIDBLUE
          }
        >
          {items.length === 0 ? 'Add new' : 'Add additional'}
        </Button>
      </div>
    </div>
  );
};
