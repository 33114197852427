import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';

export interface IProps {
  sections: {
    label: string;
    slug: string;
    render: () => React.ReactNode;
  }[];
  scrollOffset?: number;
  scrollElement?: Element;
}

export interface IScrollSpyContext {
  sections: IProps['sections'];
  scrollOffset: number;
  scrollElement: Element;
  activeSection: string;
  setActiveSection: (sectionSlug: string) => void;
}

const ScrollSpyContext = React.createContext<IScrollSpyContext>({
  sections: [],
  scrollOffset: 0,
  scrollElement: document.body,
  activeSection: '',
  setActiveSection: () => {},
});

const ScrollSpy: FC<PropsWithChildren<IProps>> = (props) => {
  const {
    sections,
    scrollOffset = 0,
    scrollElement = document.body,
    children,
  } = props;
  const [activeSection, setActiveSection] = useState<string>(
    sections[0]?.slug || ''
  );

  return (
    <ScrollSpyContext.Provider
      value={{
        sections,
        scrollOffset,
        activeSection,
        scrollElement,
        setActiveSection,
      }}
    >
      {children}
    </ScrollSpyContext.Provider>
  );
};

type INavProps = {
  className: React.ComponentProps<'nav'>['className'];
};
const ScrollSpyNav: FC<INavProps> = (props) => {
  const { className } = props;
  const { sections, activeSection, scrollOffset, scrollElement } =
    React.useContext(ScrollSpyContext);

  const handleClick = (slug: string) => {
    const element = document.getElementById(slug);
    if (element && 'scrollTo' in scrollElement) {
      scrollElement.scrollTo({
        top: element.offsetTop - scrollOffset,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <nav
      className={clsx(
        'prose horizontal-scroll-shadows overflow-x-auto',
        className
      )}
      role="navigation"
    >
      <ul className="m-0 flex w-full list-none items-end gap-x-4 p-0">
        {sections.map(({ label, slug }, index) => (
          <li key={index}>
            <a
              href={`#${slug}`}
              className={clsx(
                'inline-flex cursor-pointer items-center justify-center gap-x-3 whitespace-nowrap border-b-2 border-transparent bg-transparent px-2 py-2.5 text-blue-900 hover:no-underline disabled:pointer-events-none disabled:opacity-50',
                activeSection === slug && '!border-blue-600 font-semibold'
              )}
              onClick={(event) => {
                event.preventDefault();
                handleClick(slug);
              }}
            >
              {label}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const ScrollSpySections: FC = () => {
  const { sections, setActiveSection, scrollOffset, scrollElement } =
    React.useContext(ScrollSpyContext);
  const sectionRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const fn =
      (section: HTMLDivElement) => (event: WindowEventMap['scroll']) => {
        const scrollableParentOffset = parseInt(
          String(scrollElement.getBoundingClientRect().top)
        );
        const topOffset =
          parseInt(String(section.getBoundingClientRect().top)) -
          scrollOffset -
          scrollableParentOffset;
        const height = section.offsetHeight;

        if (topOffset <= 0 && topOffset + height > 0) {
          setActiveSection(section.dataset.id || '');
        }
      };

    sectionRefs.current.map((section) => {
      scrollElement.addEventListener('scroll', fn(section), { passive: true });
    });

    return () => {
      sectionRefs.current.map((section) => {
        scrollElement.removeEventListener('scroll', fn(section));
      });
    };
  }, [sectionRefs, scrollElement, scrollOffset]);

  return (
    <>
      {sections.map(({ slug, render }, index) => (
        <div
          key={index}
          id={slug}
          data-id={slug}
          ref={(node) => {
            if (node) {
              sectionRefs.current[index] = node;
            }
          }}
        >
          {render()}
        </div>
      ))}
    </>
  );
};

export default Object.assign(ScrollSpy, {
  Nav: ScrollSpyNav,
  Sections: ScrollSpySections,
});
