import { Reducer } from 'redux';

import { ActionType, State } from './listedSuppliersTypes';

export const initialState: State = {
  isGettingListedSuppliersPage: false,
  listedSuppliersPage: null,
};

const listedSuppliersReducer: Reducer<State> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_LISTED_SUPPLIERS_PAGE:
      return { ...state, isGettingListedSuppliersPage: true };
    case ActionType.GET_LISTED_SUPPLIERS_PAGE_FAILURE:
      return {
        ...state,
        listedSuppliersPage: null,
        isGettingListedSuppliersPage: false,
      };
    case ActionType.GET_LISTED_SUPPLIERS_PAGE_SUCCESS:
      return {
        ...state,
        listedSuppliersPage: action.payload.listedSuppliersPage,
        isGettingListedSuppliersPage: false,
      };
    case ActionType.CLEAR_LISTED_SUPPLIERS_PAGE:
      return {
        ...state,
        listedSuppliersPage: null,
        isGettingListedSuppliersPage: false,
      };
    default:
      return state;
  }
};

export default listedSuppliersReducer;
