import { FC, PropsWithChildren } from 'react';

export const SectionDivider: FC = () => {
  return <hr className="m-0 my-2" />;
};

export const SectionTitle: FC<PropsWithChildren> = ({ children }) => {
  return <h3 className="mb-0 text-sm">{children}</h3>;
};

export const SectionBodyBlock: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="bg-pc-blue-lighter rounded-md p-[1rem]">{children}</div>
  );
};
