import {
  GetMaterialsListAuditLogsInput,
  GetMaterialsListOrderSummaryItemsInput,
  OrderStatus,
} from '@/gql/graphql';
import { useState } from 'react';
import { debounce } from 'lodash-es';

export const usePaginationState = (pageSize: number) => {
  const [currentPage, setCurrentPage] = useState(1);

  return {
    offset: (currentPage - 1) * pageSize,
    limit: pageSize,
    setCurrentPage,
  };
};

export const useAuditLogsInputPaginationState = (pageSize: number) => {
  const paginationState = usePaginationState(pageSize);
  const [input, setInput] = useState<GetMaterialsListAuditLogsInput>({});

  return {
    reset: () => {
      setInput({});
      paginationState.setCurrentPage(1);
    },
    pagination: paginationState,
    input: {
      ...input,
      setSearchTerm: debounce((searchTerm?: string) => {
        setInput({ ...input, searchTerm });
        paginationState.setCurrentPage(1);
      }, 300),
      setActingUserIds: (actingUserIds?: string[]) => {
        setInput({ ...input, actingUserIds });
        paginationState.setCurrentPage(1);
      },
    },
  };
};

export const useSummaryItemsInputPaginationState = (
  pageSize: number,
  defaultInputState?: GetMaterialsListOrderSummaryItemsInput
) => {
  const paginationState = usePaginationState(pageSize);
  const [input, setInput] = useState<GetMaterialsListOrderSummaryItemsInput>(
    defaultInputState || {}
  );

  return {
    reset: () => {
      setInput(defaultInputState || {});
      paginationState.setCurrentPage(1);
    },
    pagination: paginationState,
    input: {
      ...input,
      setSearchTerm: debounce((searchTerm?: string) => {
        setInput({ ...input, searchTerm });
        paginationState.setCurrentPage(1);
      }, 300),
      setStatuses: (statuses?: OrderStatus[]) => {
        setInput({ ...input, statuses });
        paginationState.setCurrentPage(1);
      },
    },
  };
};
