import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import './SupplierAddressInputControl.sass';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import BasicField from '@payaca/components/basicField/BasicField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';

import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';

import AddressLookupOrInputControl from '../addressLookupOrInputControl/AddressLookupOrInputControl';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Address } from '@payaca/types/locationTypes';

type Props = {
  inputStyleVariant?: InputStyleVariant;
  address: {
    id?: number;
    description?: string | null;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    postcode?: string | null;
    country?: string | null;
  };
  fieldNamePrefix?: string;
  touchedState?: { [fieldName: string]: boolean };
  validationState?: { [fieldName: string]: FieldValidationResult };
  showDescriptionField?: boolean;
  onChange: (value: { [key: string]: any }) => void;
  onTouch?: (fieldName: string) => void;
  onComponentRenderChange?: () => void;
};

const SupplierAddressInputControl: FunctionComponent<Props> = ({
  inputStyleVariant,
  address,
  fieldNamePrefix,
  touchedState,
  validationState,
  showDescriptionField = true,
  onTouch,
  onChange,
  onComponentRenderChange,
}: Props): JSX.Element | null => {
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const descriptionFieldName = useMemo(() => {
    return fieldNamePrefix ? `${fieldNamePrefix}.description` : 'description';
  }, [fieldNamePrefix]);

  const addressFieldName = useMemo(() => {
    return fieldNamePrefix;
  }, [fieldNamePrefix]);

  useEffect(() => {
    onComponentRenderChange && onComponentRenderChange();
  }, [isEditingDescription]);

  return (
    <ResponsiveViewWrapper
      downBreakpointSm={400}
      className="supplier-address-input-control"
    >
      <React.Fragment>
        {showDescriptionField && (
          <div className="description-container">
            {!isEditingDescription && !address?.description && (
              <span
                onClick={() => setIsEditingDescription(true)}
                className="supplier-address-add-description-trigger flex-container"
              >
                <span>Add address description</span>
                <FontAwesomeIcon icon={faPen} />
              </span>
            )}
            {!isEditingDescription && !!address?.description && (
              <span className="flex-container flex-center">
                <LabelValuePair
                  label="Description"
                  value={address.description}
                />
                <Button
                  onClick={() => setIsEditingDescription(true)}
                  styleVariant={ButtonStyleVariant.ANCHOR}
                >
                  <FontAwesomeIcon icon={faPen} />
                </Button>
              </span>
            )}
            {isEditingDescription && (
              <ValidatedFieldWrapper
                isTouched={
                  (touchedState && touchedState[descriptionFieldName]) || false
                }
                validationResult={
                  validationState && validationState[descriptionFieldName]
                }
              >
                <BasicField
                  label="Address description"
                  styleVariant={inputStyleVariant}
                  value={address?.description}
                  name={descriptionFieldName}
                  onChange={onChange}
                  onTouch={onTouch}
                  additionalInputProps={{ placeholder: 'Description' }}
                />
              </ValidatedFieldWrapper>
            )}
          </div>
        )}
        <AddressLookupOrInputControl
          inputStyleVariant={inputStyleVariant}
          address={address as Address}
          onChange={onChange}
          onTouch={onTouch}
          validationState={validationState}
          addressFieldName={addressFieldName}
          touchedState={touchedState}
          onComponentRenderChange={onComponentRenderChange}
        />
      </React.Fragment>
    </ResponsiveViewWrapper>
  );
};

export default SupplierAddressInputControl;
