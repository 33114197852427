import React, { FunctionComponent, useRef, useState } from 'react';
import QuickAction from './QuickAction';

import './QuickActionsElement.sass';

export type QuickActionConfig = {
  actionName: string;
  actionBehaviour: (recordId: any) => void;
  isActionProcessing?: boolean;
};
type Props = {
  recordId: any;
  quickActions: QuickActionConfig[];
  renderDisabledIfNoActions?: boolean;
};

const QuickActionsElement: FunctionComponent<Props> = ({
  recordId,
  quickActions,
  renderDisabledIfNoActions = false,
}: Props): JSX.Element | null => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  if (!quickActions?.length && !renderDisabledIfNoActions) return null;

  return (
    <div
      className={`quick-actions-element${
        quickActions.length ? ' clickable' : ''
      } ${!quickActions.length ? 'disabled' : ''}`}
      ref={ref}
      onClick={(e) => {
        setIsOpen(true);
        e.stopPropagation();
      }}
    >
      <QuickAction
        recordId={recordId}
        quickActions={quickActions}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        anchor={ref}
        renderDisabledIfNoActions={renderDisabledIfNoActions}
      />
    </div>
  );
};

export default QuickActionsElement;
