import React, {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  ElementType,
} from 'react';

type Props<T extends ElementType, K extends ElementType> = {
  condition: boolean;
  Wrapper: T;
  wrapperProps?: ComponentPropsWithoutRef<T>;
  FallbackWrapper?: K;
  fallbackWrapperProps?: ComponentPropsWithoutRef<K>;
};

const ConditionalWrapper = <T extends ElementType, K extends ElementType>({
  condition,
  Wrapper,
  wrapperProps,
  FallbackWrapper,
  children,
  fallbackWrapperProps,
}: PropsWithChildren<Props<T, K>>) => {
  if (condition) {
    // @ts-expect-error
    return <Wrapper {...wrapperProps}>{children}</Wrapper>;
  }

  if (FallbackWrapper) {
    return (
      // @ts-expect-error
      <FallbackWrapper {...fallbackWrapperProps}>{children}</FallbackWrapper>
    );
  }

  return <>{children}</>;
};

export default ConditionalWrapper;
