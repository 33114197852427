import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';

import Modal from '@payaca/components/modal/Modal';
import { Customer } from '@payaca/types/customerTypes';
import * as userActions from '@payaca/store/user/userActions';

import './ConfirmMakePrimaryUserModal.css';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { useDispatch } from 'react-redux';
import { useSelector } from '@/api/state';

type Props = {
  userId: number;
  isOpen: boolean;
  onClose?: () => void;
  onMakePrimaryUserSuccess?: () => void;
};

const ConfirmMakePrimaryUserModal: FunctionComponent<Props> = ({
  userId,
  isOpen,
  onClose,
  onMakePrimaryUserSuccess,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const isMakingPrimaryUser = useSelector((state) => {
    return state.user.isMakingPrimaryUser;
  });
  const isPrimaryUserMadeSuccessfully = useSelector((state) => {
    return state.user.isPrimaryUserMadeSuccessfully;
  });

  const onConfirm = useCallback(() => {
    setIsSubmitted(true);
    dispatch(userActions.requestMakePrimaryUser(userId));
  }, [userId, dispatch]);

  useEffect(() => {
    if (isSubmitted && isPrimaryUserMadeSuccessfully) {
      onMakePrimaryUserSuccess && onMakePrimaryUserSuccess();
    }
  }, [isSubmitted, isPrimaryUserMadeSuccessfully, onMakePrimaryUserSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      title={'Make primary user'}
      size="sm"
      onClose={onClose}
      className="confirm-make-primary-user-modal"
      actions={
        <Button
          onClick={() => !isMakingPrimaryUser && onConfirm()}
          isProcessing={isMakingPrimaryUser}
          styleVariant={ButtonStyleVariant.OUTSIZE}
        >
          Yes, confirm
        </Button>
      }
    >
      <ul>
        <li>
          The primary user&#39;s details will be associated with your
          subscription payment.
        </li>
        <li>
          The primary user is the first person we will contact in relation to
          your account.
        </li>
        <li>
          If you make someone else the primary user, you will not be able to
          undo this action.
        </li>
      </ul>
      <p style={{ textAlign: 'center' }}>
        <strong>Are you sure you want to change the primary user?</strong>
      </p>
    </Modal>
  );
};

export default ConfirmMakePrimaryUserModal;
