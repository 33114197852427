import React, { FC, useEffect, useState } from 'react';
import { InputSizeVariant } from './RawInput';
import InputGroup, { InputGroupInput } from '../plInputGroup/InputGroup';
import { EBtnVariant } from '../plButton/useButtonClassName';
import UntitledIcon from '@payaca/untitled-icons';

export interface IProps
  extends Omit<InputGroupInput, 'type' | 'value' | 'onChange'> {
  wrapperClassName?: string;
  value: number;
  onChange?: (quantity: number) => void;
  increment?: number;
  min?: number;
  max?: number;
}

const IncrementableNumberInput: FC<IProps> = (props) => {
  const {
    wrapperClassName,
    value,
    increment = 1,
    min,
    max,
    onChange,
    onBlur,
    ...rest
  } = props;

  const [manualQuantity, setManualQuantity] = useState(value.toString());

  useEffect(() => {
    setManualQuantity(value.toString());
  }, [value]);

  const validateNewQuantity = (newQuantity: number) => {
    if (typeof min === 'number' && newQuantity < min) {
      return false;
    }

    if (typeof max === 'number' && newQuantity > max) {
      return false;
    }

    return true;
  };

  const handleIncrement = (increment: number) => () => {
    const newValue = value + increment;

    if (validateNewQuantity(newValue)) {
      onChange?.(newValue);
    }
  };

  const handleManualQuantityChange = (newQuantity: number) => {
    if (validateNewQuantity(newQuantity)) {
      onChange?.(newQuantity);
    } else {
      setManualQuantity(value.toString());
    }
  };

  return (
    <>
      <InputGroup
        className={wrapperClassName}
        sizeVariant={InputSizeVariant.SM}
      >
        <InputGroup.Button
          variant={EBtnVariant.White}
          onClick={handleIncrement(-increment)}
        >
          <UntitledIcon name="minus.3" className="h-4 w-4" />
        </InputGroup.Button>
        <InputGroup.Input
          type="number"
          inputClassName="pl-form-input--number text-center"
          value={manualQuantity}
          onChange={setManualQuantity}
          onBlur={(e) => {
            onBlur?.(e);
            handleManualQuantityChange(parseInt(e.target.value, 10));
          }}
          {...rest}
        />
        <InputGroup.Button
          variant={EBtnVariant.White}
          onClick={handleIncrement(increment)}
        >
          <UntitledIcon name="plus.3" className="h-4 w-4" />
        </InputGroup.Button>
      </InputGroup>
    </>
  );
};

export default IncrementableNumberInput;
