import { usePipeline } from '@payaca/store/hooks/appState';
import React, { FC } from 'react';

import './PipelineBadge.sass';
import { Pipeline } from '@payaca/types/pipelineTypes';
import { useMagicSelector } from '@/api/magicSelector';

interface Props {
  pipelineId?: number;
  showTitle?: boolean;
}

export const PipelineBadge: FC<Props> = ({
  pipelineId,
  showTitle = true,
}: Props) => {
  const pipeline = useMagicSelector((state) =>
    state.pipelines?.pipelines?.find((p) => p.id === pipelineId)
  );

  return (
    <div className="pipeline-badge">
      <div
        className="colour-indicator shrink-0"
        style={{ backgroundColor: pipeline?.colour || '#99b1cc' }}
      ></div>
      {showTitle && <span>{pipelineId ? pipeline?.title : 'Default'}</span>}
    </div>
  );
};
