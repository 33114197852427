import {
  HeaderBarProps,
  ManageableItemsList,
} from '@payaca/components/plManageableItemsList/ManageableItemsList';
import useGetServicePlanSubscriptions from '../../../api/queries/service-plans/useGetServicePlanSubscriptions';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { useSelector } from '../../../api/state';
import { getUserRoles } from '../../../utils/stateAccessors';
import { CustomersPermissions } from '@payaca/permissions/customers/customers.permissions';
import { Link, useHistory } from 'react-router-dom';
import { FilterOption } from '@payaca/components/plManageableItemsList/components/MultiFilterSidebar';
import {
  GetServicePlansSubscriptionsInput,
  ServicePlanSubscription as ServicePlanSubscriptionGql,
} from '../../../gql/graphql';
import { format } from 'date-fns';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  DateFormats,
  getAccountRegionFromCurrencyCode,
  getInternationalDateFormatByLocale,
} from '@payaca/helpers/internationalHelper';
import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';

import {
  ServiceInterval,
  ServicePlanSubscription,
  ServicePlanSubscriptionCalculatedStatus,
} from '@payaca/types/service-plans';
import { useDispatch } from 'react-redux';
import { SortDirection } from '@payaca/types/listViewTypes';
import Badge from '@payaca/components/plBadge/Badge';
import { BadgeColourVariant } from '@payaca/types/plBadge';
import Conditional from '@payaca/components/conditional/Conditional';
import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';

const SubscriptionBillingIntervalMap: Record<ServiceInterval, string> = {
  year: 'Annually',
  month: 'Monthly',
  week: 'Weekly',
  day: 'Daily',
};
const SubscriptionStatusMap: Record<
  ServicePlanSubscriptionCalculatedStatus,
  { label: string; colour: BadgeColourVariant }
> = {
  active: { label: 'Active', colour: 'teal' },
  pending: { label: 'Pending', colour: 'blue' },
  cancelled: { label: 'Cancelled', colour: 'red' },
  draft: { label: 'Draft', colour: 'gray' },
  'set-to-cancel': { label: 'Set to cancel', colour: 'yellow' },
};
const statusFilterOption: FilterOption & {
  options: { label: string; value: ServicePlanSubscriptionCalculatedStatus }[];
} = {
  title: 'Status',
  name: 'statuses',
  type: 'checkbox',
  options: [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Cancelled',
      value: 'cancelled',
    },
    {
      label: 'Set to cancel',
      value: 'set-to-cancel',
    },
  ],
};
const paymentIntervalFilterOption: FilterOption & {
  options: {
    label: string;
    value: ServicePlanSubscriptionGql['servicePlanPrice']['billingInterval'];
  }[];
} = {
  title: 'Payment interval',
  name: 'billingIntervals',
  type: 'checkbox',
  options: [
    { label: 'Annual', value: 'year' },
    { label: 'Month', value: 'month' },
  ],
};

type ServicePlanSubscriptionsPaginationInput = {
  limit: number;
  offset: number;
};
type TServicePlanSubscription = {
  id: ServicePlanSubscriptionGql['id'];
  stripeSubscriptionId: ServicePlanSubscriptionGql['stripeSubscriptionId'];
  calculatedStatus: ServicePlanSubscriptionGql['calculatedStatus'];
  validFrom: ServicePlanSubscriptionGql['validFrom'];
  validTo: ServicePlanSubscriptionGql['validTo'];
  customer: {
    id: ServicePlanSubscriptionGql['customer']['id'];
    name: ServicePlanSubscriptionGql['customer']['name'];
  };
  servicePlan: {
    name: ServicePlanSubscriptionGql['servicePlan']['name'];
    id: ServicePlanSubscriptionGql['servicePlan']['id'];
  };
  servicePlanPrice: {
    basicPrice: ServicePlanSubscriptionGql['servicePlanPrice']['basicPrice'];
    billingInterval: ServicePlanSubscriptionGql['servicePlanPrice']['billingInterval'];
    currencyCode: ServicePlanSubscriptionGql['servicePlanPrice']['currencyCode'];
  };
};

interface IProps {
  customerIds?: [string];
  headerBar?: HeaderBarProps;
}

const ServicePlansSubscriptionsTable: FC<IProps> = (props) => {
  const { customerIds, headerBar } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [requiresPersistSearchTerm, setRequiresPersistSearchTerm] =
    useState<boolean>(false);
  const [
    getServicePlansSubscriptionsInput,
    setGetServicePlansSubscriptionsInput,
  ] = useState<GetServicePlansSubscriptionsInput>({
    searchTerm: '',
    sortBy: 'validFrom',
    sortDirection: 'desc',
    billingIntervals: [],
    statuses: [],
    servicePlanIds: [],
    customerIds: customerIds || [],
  });
  useEffect(() => {
    setGetServicePlansSubscriptionsInput((prevState) => ({
      ...prevState,
      customerIds: customerIds || [],
    }));
  }, [customerIds]);
  const [
    getServicePlansSubscriptionsPagination,
    setGetServicePlansSubscriptionsPagination,
  ] = useState<ServicePlanSubscriptionsPaginationInput>({
    limit: 20,
    offset: 0,
  });

  const userRoles = useSelector(getUserRoles);
  const { data, isLoading: isLoadingServicePlansSubscriptions } =
    useGetServicePlanSubscriptions(
      getServicePlansSubscriptionsPagination,
      getServicePlansSubscriptionsInput
    );
  const { data: accountRegionPreferences } = useGetMyRegionPreferences();

  const listedServicePlans = useSelector(
    (state) => state.servicePlans.listedServicePlans
  );

  useEffect(() => {
    dispatch(servicePlansActions.getListedServicePlans.request({}));
  }, []);

  const servicePlanNames = useMemo(() => {
    return listedServicePlans.map((servicePlan) => ({
      label: servicePlan.name,
      value: servicePlan.publicId,
    }));
  }, [listedServicePlans]);

  const servicePlansSubscriptions = useMemo(
    () =>
      (data?.servicePlansSubscriptions.items || []).map((item) => {
        return {
          id: item.id,
          stripeSubscriptionId: item.stripeSubscriptionId,
          calculatedStatus: item.calculatedStatus,
          validFrom: item.validFrom,
          validTo: item.validTo,
          customer: {
            id: item.customer.id,
            name: item.customer.name,
          },
          servicePlan: {
            name: item.servicePlan.name,
            id: item.servicePlan.id,
          },
          servicePlanPrice: {
            basicPrice: item.servicePlanPrice.basicPrice,
            billingInterval: item.servicePlanPrice.billingInterval,
            currencyCode: item.servicePlanPrice.currencyCode,
          },
        };
      }) as TServicePlanSubscription[],
    [data]
  );

  const canNavigateToCustomer = userHasRequiredPermission(userRoles, [
    CustomersPermissions.GET_CUSTOMERS,
  ]);

  useEffect(() => {
    if (requiresPersistSearchTerm) {
      setGetServicePlansSubscriptionsInput({
        ...getServicePlansSubscriptionsInput,
        searchTerm,
      });
      setRequiresPersistSearchTerm(false);
    }
  }, [requiresPersistSearchTerm, searchTerm]);

  const itemActions = useMemo(() => {
    return [
      {
        label: 'View in Stripe',
        onClick: (servicePlanSubscription: TServicePlanSubscription) => {
          window.open(
            `https://dashboard.stripe.com/subscriptions/${servicePlanSubscription.stripeSubscriptionId}`
          );
        },
      },
      {
        label: 'View Service Periods',
        onClick: (servicePlanSubscription: TServicePlanSubscription) => {
          history.push(
            `/service-plans/service-periods?customerId=${servicePlanSubscription.customer.id}&servicePlanPublicIds=${servicePlanSubscription.servicePlan.id}`
          );
        },
      },
    ];
  }, []);

  const filterOptions: FilterOption[] = useMemo(() => {
    return [
      {
        title: 'Plan name',
        name: 'servicePlanIds',
        type: 'checkbox',
        options: servicePlanNames,
      },
      paymentIntervalFilterOption,
      statusFilterOption,
    ];
  }, [servicePlanNames]);

  const onSort = useCallback(
    (field: string, sortDirection: SortDirection) => {
      setGetServicePlansSubscriptionsInput({
        ...getServicePlansSubscriptionsInput,
        sortBy: field,
        sortDirection: sortDirection
          ? sortDirection === SortDirection.ASCENDING
            ? 'asc'
            : 'desc'
          : undefined,
      });
    },
    [getServicePlansSubscriptionsInput]
  );

  return (
    <ManageableItemsList>
      <Conditional condition={!!headerBar}>
        <ManageableItemsList.HeaderBar {...headerBar} />
      </Conditional>
      <ManageableItemsList.ActionBar>
        <ManageableItemsList.ActionBar.SearchInput
          value={searchTerm}
          onChange={(value) => setSearchTerm(value)}
          onChangeTimeout={() => setRequiresPersistSearchTerm(true)}
          onBlur={() => setRequiresPersistSearchTerm(true)}
          changeTimeoutMs={300}
        />
        <ManageableItemsList.ActionBar.AdvancedFilter
          options={filterOptions}
          appliedFilters={{
            billingIntervals:
              getServicePlansSubscriptionsInput?.billingIntervals || [],
            statuses: getServicePlansSubscriptionsInput?.statuses || [],
            servicePlanIds:
              getServicePlansSubscriptionsInput?.servicePlanIds || [],
          }}
          onApplyFilters={(filters) => {
            setGetServicePlansSubscriptionsInput({
              ...filters,
              searchTerm,
            });
            // reset to page 1
            setGetServicePlansSubscriptionsPagination({
              ...getServicePlansSubscriptionsPagination,
              offset: 0,
            });
          }}
        />
      </ManageableItemsList.ActionBar>
      <ManageableItemsList.Table
        items={servicePlansSubscriptions}
        uniqueKey={'id'}
        itemActions={itemActions}
        isLoading={isLoadingServicePlansSubscriptions}
        sortBy={
          (getServicePlansSubscriptionsInput?.sortBy as keyof TServicePlanSubscription) ||
          undefined
        }
        sortDirection={
          getServicePlansSubscriptionsInput.sortDirection
            ? getServicePlansSubscriptionsInput.sortDirection === 'asc'
              ? SortDirection.ASCENDING
              : SortDirection.DESCENDING
            : undefined
        }
      >
        <ManageableItemsList.Table.Column
          header="Customer"
          field="customer"
          render={(customer, row) => {
            return (
              <div
                className="max-w-[140px] truncate"
                onClick={(e) => e.stopPropagation()}
              >
                {canNavigateToCustomer ? (
                  <Link to={`/customers/${customer.id}`}>{customer.name}</Link>
                ) : (
                  customer.name
                )}
              </div>
            );
          }}
          sortByName="customerName"
          onSort={onSort}
        />
        <ManageableItemsList.Table.Column
          header="Plan name"
          field="servicePlan"
          render={(servicePlan) => servicePlan.name}
          sortByName="servicePlanName"
          onSort={onSort}
        />
        <ManageableItemsList.Table.Column
          header="Price"
          field="servicePlanPrice"
          render={(servicePlanPrice) =>
            currencyPrice(
              servicePlanPrice.basicPrice,
              servicePlanPrice.currencyCode
                ? getAccountRegionFromCurrencyCode(
                    servicePlanPrice.currencyCode
                  )
                : undefined
            )
          }
          sortByName="price"
          onSort={onSort}
        />
        <ManageableItemsList.Table.Column
          header="Payment interval"
          field="servicePlanPrice"
          render={(servicePlanPrice) =>
            SubscriptionBillingIntervalMap[
              servicePlanPrice.billingInterval as ServiceInterval
            ]
          }
        />
        <ManageableItemsList.Table.Column
          header="Status"
          field="calculatedStatus"
          render={(calculatedStatus) => {
            const statusBadge =
              SubscriptionStatusMap[
                calculatedStatus as ServicePlanSubscriptionCalculatedStatus
              ];
            return (
              <Badge colour={statusBadge.colour} variant="soft">
                {statusBadge.label}
              </Badge>
            );
          }}
        />
        <ManageableItemsList.Table.Column
          header="Subscription start date"
          field="validFrom"
          render={(validFrom) =>
            validFrom
              ? format(
                  new Date(validFrom),
                  getInternationalDateFormatByLocale(
                    DateFormats.SHORT,
                    accountRegionPreferences?.locale
                  )
                )
              : '-'
          }
          onSort={onSort}
        />
        <ManageableItemsList.Table.Column
          header="Subscription end date"
          field="validTo"
          render={(validTo) =>
            validTo
              ? format(
                  new Date(validTo),
                  getInternationalDateFormatByLocale(
                    DateFormats.SHORT,
                    accountRegionPreferences?.locale
                  )
                )
              : '-'
          }
          onSort={onSort}
        />
      </ManageableItemsList.Table>
      <ManageableItemsList.PaginationBar
        pageSize={getServicePlansSubscriptionsPagination.limit}
        currentPage={
          getServicePlansSubscriptionsPagination.offset /
            getServicePlansSubscriptionsPagination.limit +
          1
        }
        totalItems={data?.servicePlansSubscriptions.totalCount || 0}
        onPageChange={(newPage) =>
          setGetServicePlansSubscriptionsPagination({
            ...getServicePlansSubscriptionsPagination,
            offset:
              (newPage - 1) * getServicePlansSubscriptionsPagination.limit,
          })
        }
      />
    </ManageableItemsList>
  );
};

export default ServicePlansSubscriptionsTable;
