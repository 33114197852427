import { LineItem } from './lineItemTypes';
import { LineItemMaterial, Material, SupplierMaterial } from './materialTypes';
import { Supplier } from './supplierTypes';
import { ListViewPage } from './listViewTypes';

export interface ListedMaterialLineItem extends LineItemMaterial {
  material: Material;
  lineItem: LineItem;
  suppliers: Supplier[];
  supplierMaterials: SupplierMaterial[];
}

export enum SortBy {
  COST = 'cost',
  ITEM_NAME = 'itemName',
  MATERIAL_NAME = 'materialName',
}

export interface ListedMaterialLineItemListViewPage
  extends ListViewPage<ListedMaterialLineItem> {}
