import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Helmet } from 'react-helmet';

import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import CreateServicePlanControl from '@/ui/components/createServicePlanControl/CreateServicePlanControl';
import ManageServicePlanControl from '@/ui/components/manageServicePlanControl/ManageServicePlanControl';

import * as servicePlansActions from '@payaca/store/servicePlans/servicePlansActions';

import { useServicePlan } from '@payaca/store/hooks/appState';

import { useTranslation } from '../../../i18n';

import './ServicePlanPage.sass';

const ServicePlanPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params: { servicePlanId: string } = useParams();
  const translate = useTranslation();

  const servicePlanId = useMemo(
    () => (params.servicePlanId !== 'new' ? params.servicePlanId : undefined),
    [params]
  );
  const servicePlan = useServicePlan(servicePlanId);

  useEffect(() => {
    if (servicePlanId) {
      dispatch(
        servicePlansActions.getServicePlan.request({
          publicId: servicePlanId,
        })
      );
    }
  }, [servicePlanId]);

  return (
    <>
      <Helmet
        title={translate('pages.servicePlan.title', {
          name: servicePlan?.name || '',
        })}
      />
      <AuthenticatedPageWrapper
        className="service-plan-page"
        title={!servicePlanId ? <h1>New Service Plan</h1> : undefined}
        previousPageNavigationConfig={{
          route: `/service-plans/listed-plans`,
          navigationPromptName: 'Back',
        }}
      >
        {!servicePlanId && (
          <CreateServicePlanControl
            onCreateSuccess={() => history.push('/service-plans/listed-plans')}
          />
        )}
        {servicePlanId && (
          <ManageServicePlanControl servicePlanId={servicePlanId} />
        )}
      </AuthenticatedPageWrapper>
    </>
  );
};

export default ServicePlanPage;
