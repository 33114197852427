import React, { FC, PropsWithChildren } from 'react';

import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import EntityCard from '@payaca/components/entityCard/EntityCard';
import { usePurchaseOrder, useSupplier } from '@payaca/store/hooks/appState';

import { getUserRoles } from '@/utils/stateAccessors';

import { useHistory } from 'react-router';
import PurchaseOrderStatusBadge from '../purchaseOrderStatusBadge/PurchaseOrderStatusBadge';
import './PurchaseOrderCard.sass';
import { useSelector } from '@/api/state';

interface Props {
  purchaseOrderId: number;
  onClick?: () => void;
}

const PurchaseOrderCard: FC<PropsWithChildren<Props>> = ({
  purchaseOrderId,
  onClick,
}) => {
  const history = useHistory();
  const userRoles = useSelector(getUserRoles);

  const purchaseOrder = usePurchaseOrder(purchaseOrderId);
  const supplier = useSupplier(purchaseOrder?.supplierId);

  if (!purchaseOrder) return null;

  return (
    <EntityCard className="purchase-order-card" onClick={onClick}>
      <ResponsiveViewWrapper
        className="purchase-order-card-content"
        downBreakpointSm={400}
      >
        <small className="reference">
          Purchase order {purchaseOrder.reference}
        </small>
        <span className="supplier-name">{supplier?.name}</span>
        <PurchaseOrderStatusBadge status={purchaseOrder.status} />
      </ResponsiveViewWrapper>
    </EntityCard>
  );
};

export default PurchaseOrderCard;
