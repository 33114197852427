import { EMAIL_VALIDATION_REGEX } from '../constants/regex';

import {
  CustomFieldDefinition,
  CustomFieldType,
  CustomFieldValueHelperType,
} from '../types';

export const getCustomFieldValidatorForDefinition = <T extends CustomFieldType>(
  definition: Pick<CustomFieldDefinition<T>, 'type' | 'isRequired' | 'options'>
): ((value?: CustomFieldValueHelperType[T] | null) => {
  isValid: boolean;
  validationMessages?: string[];
}) => {
  switch (definition.type) {
    case 'textarea':
    case 'text':
      return getCustomFieldValidatorForString(definition);
    case 'email':
      return getCustomFieldValidatorForEmail(definition);
    case 'select':
      return getCustomFieldValidatorForSelect(definition);
    default:
      return (value: any) => {
        return { isValid: false, validationMessages: ['Not implemented'] };
      };
  }
};

export const getCustomFieldValidatorForString = <T extends CustomFieldType>(
  definition: Pick<CustomFieldDefinition<T>, 'isRequired'>
): ((value?: CustomFieldValueHelperType[T] | null) => {
  isValid: boolean;
  validationMessages?: string[];
}) => {
  return (value: any) => {
    if (!value && definition.isRequired) {
      return { isValid: false, validationMessages: ['Required field'] };
    }
    if (value && typeof value !== 'string') {
      return { isValid: false, validationMessages: ['Invalid input'] };
    }
    if (definition.isRequired && value.trim() === '') {
      return { isValid: false, validationMessages: ['Required field'] };
    }
    return { isValid: true };
  };
};

export const getCustomFieldValidatorForEmail = <T extends CustomFieldType>(
  definition: Pick<CustomFieldDefinition<T>, 'isRequired'>
): ((value?: CustomFieldValueHelperType[T] | null) => {
  isValid: boolean;
  validationMessages?: string[];
}) => {
  const stringValidator = getCustomFieldValidatorForString(definition);
  const regex = new RegExp(EMAIL_VALIDATION_REGEX);

  return (value: any) => {
    const stringValidationResult = stringValidator(value);

    if (
      stringValidationResult.isValid &&
      value?.trim()?.length &&
      !regex.test(value)
    ) {
      return {
        isValid: false,
        validationMessages: ['Input must be a valid email address'],
      };
    }

    return stringValidationResult;
  };
};

export const getCustomFieldValidatorForSelect = <T extends CustomFieldType>(
  definition: Pick<CustomFieldDefinition<T>, 'isRequired' | 'options'>
): ((value?: CustomFieldValueHelperType[T] | null) => {
  isValid: boolean;
  validationMessages?: string[];
}) => {
  const stringValidator = getCustomFieldValidatorForString(definition);

  return (value: any) => {
    const stringValidationResult = stringValidator(value);

    if (
      stringValidationResult.isValid &&
      value?.trim()?.length &&
      !definition.options?.includes(value)
    ) {
      return {
        isValid: false,
        validationMessages: ['Input must be one of the available options'],
      };
    }

    return stringValidationResult;
  };
};

export const getCustomFieldValueValidationResult = <T extends CustomFieldType>(
  definition: Pick<CustomFieldDefinition<T>, 'type' | 'isRequired' | 'options'>,
  value?: CustomFieldValueHelperType[T] | null
) => {
  return getCustomFieldValidatorForDefinition(definition)(value);
};
