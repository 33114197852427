import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import TypeToSearchField from '@payaca/components/typeToSearchField/TypeToSearchField';
import { ListedMaterial, SortBy } from '@payaca/types/listedMaterialTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import * as materialActions from '@payaca/store/materials/materialsActions';
import * as listedMaterialActions from '@payaca/store/listedMaterials/listedMaterialsActions';

import './MaterialSelectionControl.sass';
import InputWrapper, {
  InputStyleVariant,
} from '@payaca/components/inputWrapper/InputWrapper';
import { getMaterial, getRegion } from '@/utils/stateAccessors';
import { Material, MaterialCategory } from '@payaca/types/materialTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import FieldLabel from '@payaca/components/fieldLabel/FieldLabel';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import CreateEditMaterialModal from '../createEditMaterialModal/CreateEditMaterialModal';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import IconButton from '@payaca/components/button/IconButton';
import ImageBlock from '@payaca/components/imageBlock/ImageBlock';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { LabelStyleVariant } from '@payaca/components/fieldLabel/FieldLabel';
import InputFilterPrefix from '@payaca/components/inputFilterPrefix/InputFilterPrefix';
import { requestGetMaterialCategories } from '@payaca/store/materials/materialsActions';
import { getMaterialFilterCategoryIdsLocalStorageKey } from '@/helpers/localStorageKeyHelper';
import { useSelector } from '@/api/state';

const materialFilterCategoryIdsLocalStorageKey =
  getMaterialFilterCategoryIdsLocalStorageKey();

interface Props {
  label?: string;
  labelStyleVariant?: LabelStyleVariant;
  selectedMaterialId?: number;
  onChange: (selectedMaterialId?: number | null) => void;
  enableMaterialCreation?: boolean;
  styleVariant?: InputStyleVariant;
  placeholder?: string;
  disabledMaterialIds?: number[];
  enableCategoryFiltering?: boolean;
}

const MaterialSelectionControl: FC<Props> = ({
  label,
  labelStyleVariant,
  selectedMaterialId,
  onChange,
  enableMaterialCreation = false,
  styleVariant = InputStyleVariant.OUTSIZE,
  placeholder,
  disabledMaterialIds,
  enableCategoryFiltering = true,
}: Props): JSX.Element => {
  const [showCreateMaterialModal, setShowCreateMaterialModal] = useState(false);
  const [newMaterialData, setNewMaterialData] = useState({});
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>();

  const [requiresGetListedMaterialsPage, setRequiresGetListedMaterialsPage] =
    useState(false);

  const storedCategoryIds = localStorage.getItem(
    materialFilterCategoryIdsLocalStorageKey
  );

  const [categoryIds, setCategoryIds] = useState<number[]>(
    storedCategoryIds?.length
      ? storedCategoryIds.split(',').map((x) => parseInt(x))
      : []
  );

  const region = useSelector(getRegion);

  const isGettingListedMaterialsPage = useSelector(
    (state) => !!state.listedMaterials?.isGettingListedMaterialsPage
  );

  const isPersistingMaterial = useSelector(
    (state) => !!state.materials?.isPersistingMaterial
  );

  const material: Material | undefined = useSelector((state) => {
    if (!selectedMaterialId) return;
    return getMaterial(state, selectedMaterialId);
  });

  const listedMaterials: ListedMaterial[] = useSelector((state) => {
    return state.listedMaterials?.listedMaterialsPage?.items || [];
  });

  const materialCategories = useSelector((state) => {
    return state.materials.materialCategories || [];
  });

  useEffect(() => {
    if (!materialCategories.length) {
      dispatch(requestGetMaterialCategories());
    }
  }, [materialCategories]);

  useEffect(() => {
    dispatch(listedMaterialActions.clearListedMaterialsPage());
  }, []);

  const materialCategoryOptions: { value: any; label: string }[] =
    useMemo(() => {
      const options = materialCategories.map((x: MaterialCategory) => {
        return {
          label: x.label,
          value: x.id,
        };
      });
      return [...options, { label: 'Uncategorised', value: -1 }];
    }, [materialCategories]);

  const requestGetListedMaterialsPage = useCallback(() => {
    !!searchTerm?.length &&
      dispatch(
        listedMaterialActions.requestGetListedMaterialsPage({
          sortDirection: SortDirection.DESCENDING,
          pageSize: 20,
          pageNumber: 1,
          searchTerm: searchTerm,
          sortBy: SortBy.NAME,
          categoryIds: categoryIds,
        })
      );
    setRequiresGetListedMaterialsPage(false);
  }, [searchTerm, categoryIds, dispatch]);

  useEffect(() => {
    if (selectedMaterialId) {
      dispatch(materialActions.requestGetMaterial(selectedMaterialId));
    }
  }, [selectedMaterialId]);

  useEffect(() => {
    if (requiresGetListedMaterialsPage) {
      requestGetListedMaterialsPage();
    }
  }, [requiresGetListedMaterialsPage]);

  const handleCreateMaterial = useCallback(
    (materialName: string) => {
      dispatch(
        materialActions.requestPersistMaterial(
          { name: materialName },
          (materialId: number) => {
            onChange(materialId);
          }
        )
      );
    },
    [onChange]
  );

  const renderListedMaterialOption = useCallback(
    (listedMaterial: ListedMaterial) => {
      const isAddNewOption = listedMaterial.id === -1;

      return (
        <div className="listed-material-option flex-container flex-center">
          {isAddNewOption && <IconButton size={'xs'} icon={faPlus} />}
          {!isAddNewOption && (
            <ImageBlock imageSrc={listedMaterial.thumbnailUrl} />
          )}
          <span title={listedMaterial.name} className="material-name">
            {listedMaterial.name}
          </span>
          {listedMaterial.predictedPriceExcludingTax && (
            <small className="material-price">
              {currencyPrice(listedMaterial.predictedPriceExcludingTax, region)}
            </small>
          )}
        </div>
      );
    },
    [region, disabledMaterialIds]
  );

  return (
    <div className="material-selection-control">
      {label && <FieldLabel label={label} styleVariant={labelStyleVariant} />}
      {!selectedMaterialId && (
        <div
          className={`material-selection-inputs-wrapper${
            enableCategoryFiltering ? ' category-filtering' : ''
          }`}
        >
          {enableCategoryFiltering && (
            <InputFilterPrefix
              filterGroups={{
                categoryIds: {
                  label: 'Categories',
                  options: materialCategoryOptions,
                },
              }}
              filterValues={{
                categoryIds: categoryIds,
              }}
              onChange={(value) => {
                setCategoryIds(value.categoryIds || []);
                setRequiresGetListedMaterialsPage(true);
              }}
            />
          )}

          <TypeToSearchField
            getOptionDisabled={(listedMaterial) =>
              !!disabledMaterialIds &&
              disabledMaterialIds.includes(listedMaterial.id)
            }
            styleVariant={styleVariant}
            onSearchTermChangeTimeout={() => {
              setRequiresGetListedMaterialsPage(true);
            }}
            autoHighlight={true}
            onSearchTermChange={setSearchTerm}
            options={
              enableMaterialCreation
                ? [
                    ...listedMaterials,
                    {
                      id: -1,
                      name: 'Create a new Material',
                    } as ListedMaterial,
                  ]
                : listedMaterials
            }
            isLoadingOptions={
              isGettingListedMaterialsPage || isPersistingMaterial
            }
            renderOption={renderListedMaterialOption}
            disableFilteringInComponent={true}
            onSelectOption={(listedMaterial?: ListedMaterial) => {
              dispatch(listedMaterialActions.clearListedMaterialsPage());
              if (listedMaterial?.id === -1 && enableMaterialCreation) {
                if (searchTerm) {
                  handleCreateMaterial(searchTerm);
                } else {
                  setNewMaterialData({ name: searchTerm });
                  setShowCreateMaterialModal(true);
                }
              } else {
                onChange(listedMaterial?.id);
              }
            }}
            placeholder={placeholder || 'Type here to search Materials'}
            noOptionsText={'No Materials found.'}
          />
        </div>
      )}

      {selectedMaterialId && (
        <div className="selected-material-container">
          <InputWrapper styleVariant={InputStyleVariant.OUTSIZE}>
            <>
              {material && (
                <>
                  <span className="selected-material-name">
                    {material.name}
                  </span>
                  <Button
                    styleVariant={ButtonStyleVariant.ANCHOR}
                    onClick={() => {
                      onChange(null);
                      setSearchTerm(undefined);
                      setRequiresGetListedMaterialsPage(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </>
              )}
              {!material && <MiniLoader />}
            </>
          </InputWrapper>
        </div>
      )}
      <CreateEditMaterialModal
        isOpen={showCreateMaterialModal}
        onClose={() => setShowCreateMaterialModal(false)}
        material={newMaterialData as Material}
        onPersistMaterialSuccess={(materialId: number) => {
          onChange(materialId);
          setShowCreateMaterialModal(false);
        }}
      />
    </div>
  );
};

export default MaterialSelectionControl;
