import {
  EntityTemplate,
  PublicEntityTemplate,
  PublicHydratedEntityTemplate,
  TemplatableEntity,
} from '@payaca/types/entity-templates';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { Tag } from '@payaca/types/tagTypes';
import { DeclareAsyncAction, UnpackAsyncAction } from '../types';
import {
  ListedTemplatesPage,
  GetListedTemplatesQueryParams,
} from '@payaca/types/listedTemplateTypes';
import { WithOptional } from '@payaca/utilities/types';

export interface State {
  // listedTemplatesPage?: ListedTemplatesPage;
}

export enum ActionType {
  GET_LISTED_TEMPLATES_PAGE_REQUEST = 'templates.getListedTemplatesPage:request',
  GET_LISTED_TEMPLATES_PAGE_SUCCESS = 'templates.getListedTemplatesPage:success',

  GET_TEMPLATE_REQUEST = 'templates.getTemplate:request',

  UPDATE_TEMPLATE_REQUEST = 'templates.updateTemplate:request',
  CREATE_TEMPLATE_REQUEST = 'templates.createTemplate:request',
  UPDATE_TEMPLATE_SUCCESS = 'templates.updateTemplate:success',
  CREATE_TEMPLATE_SUCCESS = 'templates.createTemplate:success',

  DELETE_TEMPLATE_REQUEST = 'templates.deleteTemplate:request',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export type GetListedTemplatesPage = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_LISTED_TEMPLATES_PAGE_REQUEST;
      payload: {
        queryParams: GetListedTemplatesQueryParams;
        callback?: (listedTemplatesPage: ListedTemplatesPage) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
    success: {
      type: ActionType.GET_LISTED_TEMPLATES_PAGE_SUCCESS;
      payload: ListedTemplatesPage;
    };
  }
>;

export type GetTemplate = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_TEMPLATE_REQUEST;
      payload: {
        publicId: EntityTemplate<TemplatableEntity>['publicId'];
        callback?: (template: EntityTemplate<TemplatableEntity>) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type UpdateTemplateInput<T extends TemplatableEntity> = Partial<
  Omit<PublicEntityTemplate<T>, 'publicId' | 'accountId'>
>;

export type UpdateTemplate = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_TEMPLATE_REQUEST;
      payload: {
        publicId: EntityTemplate<TemplatableEntity>['publicId'];
        template: UpdateTemplateInput<TemplatableEntity>;
        callback?: (
          template: PublicHydratedEntityTemplate<TemplatableEntity>
        ) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
    success: {
      type: ActionType.UPDATE_TEMPLATE_SUCCESS;
      payload: PublicHydratedEntityTemplate<TemplatableEntity>;
    };
  }
>;

export type CreateTemplateInput<T extends TemplatableEntity> = Partial<
  Omit<PublicEntityTemplate<T>, 'publicId' | 'accountId' | 'entityType'>
> & {
  entityType: TemplatableEntity;
};

export type CreateTemplate = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_TEMPLATE_REQUEST;
      payload: {
        template: CreateTemplateInput<TemplatableEntity>;
        callback?: (
          template: PublicHydratedEntityTemplate<TemplatableEntity>
        ) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
    success: {
      type: ActionType.CREATE_TEMPLATE_SUCCESS;
      payload: PublicHydratedEntityTemplate<TemplatableEntity>;
    };
  }
>;

export type DeleteTemplate = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.DELETE_TEMPLATE_REQUEST;
      payload: {
        publicId: EntityTemplate<TemplatableEntity>['publicId'];
        callback?: () => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type AnyAction = UnpackAsyncAction<
  GetListedTemplatesPage | UpdateTemplate | CreateTemplate | DeleteTemplate
>;
