import {
  ActionConfigHelperType,
  Automation,
  AutomationAction,
  AutomationActionTypes,
  AutomationCondition,
  DefaultAutomationDefinition,
  EmailNotificationActionConfig,
  PublicHydratedDefaultAutomationDefinition,
  SMSActionConfig,
  UpdateDefaultAutomationData,
} from '@payaca/types/automationTypes';
import { FieldValidator } from '@payaca/types/fieldValidationTypes';
import {
  getAllowEmptyValidator,
  getConditionalValidator,
  getIsRequiredFieldValidator,
  getIsRequiredIfTrueConditionValidator,
  getLengthFieldValidator,
  getRegexMatchFieldValidator,
} from './fieldValidationHelper';
import get from 'lodash.get';
import {
  VALID_PHONE_NUMBER_REGEX,
  VALID_PHONE_NUMBER_REGEX_STRICT,
} from '@payaca/constants';
import { AutomationDataAccessType } from '@payaca/types/automationDataAccessTypes';
import { validateForm } from './formValidationHelper';
import {
  COMMA_SEPARATED_EMAIL_LIST,
  VALID_EMAIL_REGEX,
} from '@payaca/constants/regexConstants';

const getConditionValidator = (
  conditionsValueCustomisationConfig: PublicHydratedDefaultAutomationDefinition<AutomationActionTypes>['conditionValueCustomisationConfig']
): FieldValidator<UpdateDefaultAutomationData<AutomationActionTypes>> => {
  return (
    fieldName: string,
    formState: UpdateDefaultAutomationData<AutomationActionTypes>
  ) => {
    const formCondition = get(formState, fieldName);

    const customisationConfig = conditionsValueCustomisationConfig.find((x) => {
      return x.conditionIndex === formCondition.conditionIndex;
    });

    if (customisationConfig?.isRequired && !formCondition.value?.length) {
      return {
        isValid: false,
        errors: ['This field is required'],
      };
    } else {
      return {
        isValid: true,
      };
    }
  };
};

const getActionConfigValidators = <TFormState extends Record<string, any>>(
  actionAccessKey: string
) => {
  const emailNotificationActionConfigValidators =
    getEmailNotificationActionConfigValidators((formState: TFormState) => {
      return get(formState, actionAccessKey)?.type ===
        AutomationActionTypes.EMAIL_NOTIFICATION
        ? (get(formState, actionAccessKey)
            .config as EmailNotificationActionConfig)
        : undefined;
    });

  const smsNotificationActionConfigValidators =
    getSmsNotificationActionConfigValidators((formState: TFormState) => {
      return get(formState, actionAccessKey)?.type ===
        AutomationActionTypes.SMS_NOTIFICATION
        ? (get(formState, actionAccessKey).config as SMSActionConfig)
        : undefined;
    });

  const dealProgressionActionConfigValidators =
    getDealProgressionActionConfigValidators();

  const validators: Record<string, FieldValidator<TFormState>[]> = {};

  Object.entries(emailNotificationActionConfigValidators).forEach(
    ([key, value]) => {
      if (!(`${actionAccessKey}.config.${key}` in validators)) {
        validators[`${actionAccessKey}.config.${key}`] = [];
      }

      validators[`${actionAccessKey}.config.${key}`].push(
        ...value.map((v) => {
          return getConditionalValidator<TFormState>(
            (formState: TFormState) => {
              return (
                get(formState, actionAccessKey)?.type ===
                AutomationActionTypes.EMAIL_NOTIFICATION
              );
            },
            v
          );
        })
      );
    }
  );

  Object.entries(smsNotificationActionConfigValidators).forEach(
    ([key, value]) => {
      if (!(`${actionAccessKey}.config.${key}` in validators)) {
        validators[`${actionAccessKey}.config.${key}`] = [];
      }

      validators[`${actionAccessKey}.config.${key}`].push(
        ...value.map((v) =>
          getConditionalValidator<TFormState>(
            (formState: TFormState) =>
              get(formState, actionAccessKey)?.type ===
              AutomationActionTypes.SMS_NOTIFICATION,
            v
          )
        )
      );
    }
  );

  Object.entries(dealProgressionActionConfigValidators).forEach(
    ([key, value]) => {
      if (!(`${actionAccessKey}.config.${key}` in validators)) {
        validators[`${actionAccessKey}.config.${key}`] = [];
      }

      validators[`${actionAccessKey}.config.${key}`].push(
        ...value.map((v) =>
          getConditionalValidator<TFormState>(
            (formState: TFormState) =>
              get(formState, actionAccessKey)?.type ===
              AutomationActionTypes.DEAL_PROGRESSION,
            v
          )
        )
      );
    }
  );

  return validators;
};

const getEmailNotificationActionConfigValidators = <
  TFormState extends Record<string, any>,
>(
  getConfigFromFormState: (
    formState: TFormState
  ) => EmailNotificationActionConfig | undefined
) => {
  return {
    sendToEmail: [getIsRequiredFieldValidator()],
    customEmail: [
      getConditionalValidator<TFormState>(
        (formState: TFormState) =>
          getConfigFromFormState(formState)?.sendToEmail ===
          AutomationDataAccessType.CUSTOM_EMAILADDRESS,
        getRegexMatchFieldValidator<TFormState>(VALID_EMAIL_REGEX)
      ),
    ],
    emailBody: [getIsRequiredFieldValidator()],
    emailSubject: [getIsRequiredFieldValidator()],
    ccEmails: [
      getAllowEmptyValidator(
        getRegexMatchFieldValidator(COMMA_SEPARATED_EMAIL_LIST, {
          customErrorMessage: 'This must be a comma separated list of emails',
        })
      ),
    ],
    bccEmails: [
      getAllowEmptyValidator(
        getRegexMatchFieldValidator(COMMA_SEPARATED_EMAIL_LIST, {
          customErrorMessage: 'This must be a comma separated list of emails',
        })
      ),
    ],
  };
};

const getSmsNotificationActionConfigValidators = <
  TFormState extends Record<string, any>,
>(
  getConfigFromFormState: (formState: TFormState) => SMSActionConfig | undefined
) => {
  return {
    sendToNumber: [getIsRequiredFieldValidator()],
    messageBody: [getIsRequiredFieldValidator()],
    customNumber: [
      getConditionalValidator<TFormState>(
        (formState: TFormState) =>
          getConfigFromFormState(formState)?.sendToNumber ===
          AutomationDataAccessType.CUSTOM_TELEPHONENUMBER,
        getRegexMatchFieldValidator<TFormState>(VALID_PHONE_NUMBER_REGEX_STRICT)
      ),
    ],
  };
};

const getDealProgressionActionConfigValidators = <
  TFormState extends Record<string, any>,
>() => {
  return {
    targetStage: [getIsRequiredFieldValidator()],
  };
};

export const getFieldValidatorsForDefaultAutomation = (
  automationDefinition: Pick<
    DefaultAutomationDefinition,
    'conditionValueCustomisationConfig'
  >
): Record<
  string,
  FieldValidator<UpdateDefaultAutomationData<AutomationActionTypes>>[]
> => {
  const validators: Record<
    string,
    FieldValidator<UpdateDefaultAutomationData<AutomationActionTypes>>[]
  > = {
    ...getActionConfigValidators('action'),
  };

  automationDefinition.conditionValueCustomisationConfig.forEach((c) => {
    validators[`conditions[${c.conditionIndex}]`] = [
      getConditionValidator(
        automationDefinition.conditionValueCustomisationConfig
      ),
    ];
  });

  return validators;
};

export const getFieldValidatorsForCustomAutomation = ({
  conditionsCount,
  actionsCount,
}: {
  conditionsCount: number;
  actionsCount: number;
}): Record<
  string,
  FieldValidator<Partial<Automation<AutomationActionTypes>>>[]
> => {
  const validators: Record<
    string,
    FieldValidator<Partial<Automation<AutomationActionTypes>>>[]
  > = {
    title: [
      getIsRequiredFieldValidator(),
      getLengthFieldValidator({ min: 0, max: 80 }),
    ],
  };

  // conditions
  for (let i = 0; i < conditionsCount; i++) {
    validators[`conditions[${i}].field`] = [getIsRequiredFieldValidator()];
    validators[`conditions[${i}].operator`] = [getIsRequiredFieldValidator()];
    validators[`conditions[${i}].value`] = [getIsRequiredFieldValidator()];
  }

  //actions
  for (let i = 0; i < actionsCount; i++) {
    const v = getActionConfigValidators(`actions[${i}]`);
    Object.entries(v).forEach(([key, value]) => {
      validators[key] = value;
    });
    validators[`actions[${i}].title`] = [
      getIsRequiredFieldValidator(),
      getLengthFieldValidator({ min: 0, max: 40 }),
    ];
  }

  return validators;
};

export const isAutomationActionConfigValid = <T extends AutomationActionTypes>(
  type: T,
  actionConfig: ActionConfigHelperType[T]
): boolean => {
  const validators = getActionConfigValidators('action');

  const formState = {
    action: {
      type,
      config: actionConfig,
    },
  };

  const result = validateForm(formState, validators);
  return Object.values(result).every((x) => x.isValid);
};

export const isCustomAutomationValid = (
  automation: Partial<Automation<AutomationActionTypes>>
): boolean => {
  const validators = getFieldValidatorsForCustomAutomation({
    conditionsCount: automation.conditions?.length || 0,
    actionsCount: automation.actions?.length || 0,
  });

  const result = validateForm(automation, validators);
  return Object.values(result).every((x) => x.isValid);
};
