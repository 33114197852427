import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import SideDrawer from '@payaca/components/sideDrawer/SideDrawer';
import ListedMaterials from '../listedMaterials/ListedMaterials';
import ListedMaterialsSearchFilterControl from '../listedMaterialsSearchFilterControl/ListedMaterialsSearchFilterControl';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CreateEditMaterialModal from '../createEditMaterialModal/CreateEditMaterialModal';

import * as listedMaterialsActions from '@payaca/store/listedMaterials/listedMaterialsActions';

import {
  getMaterialFilterCategoryIdsLocalStorageKey,
  getMaterialFilterManagedByLocalStorageKey,
  getMaterialFilterSupplierIdsLocalStorageKey,
} from '@/helpers/localStorageKeyHelper';

import { ManagedBy, SortBy } from '@payaca/types/listedMaterialTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import { Material } from '@payaca/types/materialTypes';

import { singularPlural } from '@payaca/utilities/stringUtilities';

import './SearchMaterialsDrawer.sass';

const materialFilterCategoryIdsLocalStorageKey =
  getMaterialFilterCategoryIdsLocalStorageKey();
const materialFilterSupplierIdsLocalStorageKey =
  getMaterialFilterSupplierIdsLocalStorageKey();
const materialFilterManagedByLocalStorageKey =
  getMaterialFilterManagedByLocalStorageKey();

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAddMaterials: (materialIds: Material['id'][]) => void;
  disabledMaterialIds?: number[];
  defaultFilters?: { categoryIds?: number[]; supplierIds?: number[] };
};

const SearchMaterialsDrawer: FC<Props> = ({
  isOpen,
  onClose,
  onAddMaterials,
  disabledMaterialIds,
  defaultFilters,
}) => {
  const dispatch = useDispatch();
  const storedCategoryIds = localStorage.getItem(
    materialFilterCategoryIdsLocalStorageKey
  );
  const storedSupplierIds = localStorage.getItem(
    materialFilterSupplierIdsLocalStorageKey
  );
  const storedManagedBy = localStorage.getItem(
    materialFilterManagedByLocalStorageKey
  );
  const [showCreateMaterialModal, setShowCreateMaterialModal] = useState(false);

  const [getListedMaterialsRequestData, setGetListedMaterialsRequestData] =
    useState({
      sortBy: SortBy.NAME,
      pageNumber: 1,
      pageSize: 50,
      searchTerm: '',
      sortDirection: SortDirection.ASCENDING,
      categoryIds:
        defaultFilters?.categoryIds ||
        storedCategoryIds
          ?.split(',')
          .filter(Boolean)
          .map((x) => parseInt(x)),
      supplierIds:
        defaultFilters?.supplierIds ||
        storedSupplierIds
          ?.split(',')
          .filter(Boolean)
          .map((x) => parseInt(x)),
      managedBy: storedManagedBy?.split(',').filter(Boolean) as ManagedBy[],
    });

  const requestGetListedMaterialsPage = useCallback(() => {
    dispatch(
      listedMaterialsActions.requestGetListedMaterialsPage(
        getListedMaterialsRequestData
      )
    );
  }, [getListedMaterialsRequestData]);

  useEffect(() => {
    requestGetListedMaterialsPage();
  }, [getListedMaterialsRequestData]);

  const onSelectPage = (pageNumber: number) => {
    setGetListedMaterialsRequestData((getListedMaterialsRequestData: any) => {
      return {
        ...getListedMaterialsRequestData,
        pageNumber,
      };
    });
  };

  const [selectedMaterialIds, setSelectedMaterialIds] = useState<number[]>([]);

  const onChangeFilters = (value: { [key: string]: any }) => {
    setGetListedMaterialsRequestData((getListedMaterialsRequestData: any) => {
      return {
        ...getListedMaterialsRequestData,
        ...value,
        pageNumber: 1,
      };
    });
  };

  const onCloseDrawer = () => {
    // clear selected materials
    setSelectedMaterialIds([]);
    onClose?.();
  };

  return (
    <SideDrawer
      isOpen={isOpen}
      onClose={onCloseDrawer}
      className="search-materials-drawer"
      disableBackdropClick={true}
    >
      <div>
        <ListedMaterialsSearchFilterControl
          getListedMaterialsRequestData={getListedMaterialsRequestData}
          initSearchTerm={getListedMaterialsRequestData.searchTerm}
          condensedLabels={true}
          onChange={onChangeFilters}
        />
        <div className="create-material-button-wrapper">
          <Button
            onClick={() => {
              setShowCreateMaterialModal(true);
            }}
            styleVariant={ButtonStyleVariant.ANCHOR}
          >
            Create new Material
          </Button>
          <span>
            {singularPlural(
              selectedMaterialIds.length,
              'Material',
              'Materials'
            )}{' '}
            selected
          </span>
        </div>
        <div className="materials-count-add-wrapper">
          <Button
            onClick={() => {
              onAddMaterials(selectedMaterialIds);
              onCloseDrawer();
            }}
          >
            Add selected Materials
          </Button>
        </div>
      </div>

      <ListedMaterials
        onClickMaterial={(materialId) => {
          const materialIdx = selectedMaterialIds.findIndex(
            (x) => x === materialId
          );
          if (materialIdx >= 0) {
            setSelectedMaterialIds([
              ...selectedMaterialIds.slice(0, materialIdx),
              ...selectedMaterialIds.slice(materialIdx + 1),
            ]);
          } else {
            setSelectedMaterialIds([...selectedMaterialIds, materialId]);
          }
        }}
        onSelectPage={onSelectPage}
        isMultiSelect={true}
        selectedMaterialIds={selectedMaterialIds}
        disabledMaterialIds={disabledMaterialIds}
      />
      <CreateEditMaterialModal
        isOpen={showCreateMaterialModal}
        onClose={() => setShowCreateMaterialModal(false)}
        onPersistMaterialSuccess={(materialId: number) => {
          // add material to selected materials
          setSelectedMaterialIds([...selectedMaterialIds, materialId]);
        }}
        enableSupplierMaterialInput={true}
      />
    </SideDrawer>
  );
};

export default SearchMaterialsDrawer;
