import React, { FC, useState } from 'react';
import Modal from '@payaca/components/modal/Modal';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { InvoicesPermissions } from '@payaca/permissions/invoices/invoices.permissions';
import Button from '@payaca/components/button/Button';
import { ButtonColourVariant, ButtonStyleVariant } from '@payaca/components/button/enums';
import { useDispatch } from 'react-redux';
import {
  requestGetInvoice,
  voidInvoice,
} from '@payaca/store/invoices/invoicesActions';
import { useInvoice } from '@payaca/store/hooks/appState';

type Props = {
  invoiceId: number;
};
const VoidInvoiceButton: FC<Props> = ({ invoiceId }: Props) => {
  const dispatch = useDispatch();
  const [isVoiding, setIsVoiding] = useState(false);
  const [showConfirmVoidInvoiceModal, setShowConfirmVoidInvoiceModal] =
    useState(false);

  const invoice = useInvoice();

  if (invoice?.voidedAt) return null;

  return (
    <>
      <PermissionGuard
        renderIfHasPermissions={[InvoicesPermissions.VOID_INVOICE]}
      >
        <div className="void-invoice-button-container">
          <Button
            colourVariant={ButtonColourVariant.RED}
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => setShowConfirmVoidInvoiceModal(true)}
          >
            Void this invoice
          </Button>
        </div>
      </PermissionGuard>
      <Modal
        isOpen={showConfirmVoidInvoiceModal}
        onClose={() => setShowConfirmVoidInvoiceModal(false)}
        title={'Are you sure you want to void the Invoice?'}
        actions={
          <>
            <Button
              colourVariant={ButtonColourVariant.RED}
              styleVariant={ButtonStyleVariant.OUTSIZE}
              onClick={() => {
                if (isVoiding) return;
                setIsVoiding(true);
                dispatch(
                  voidInvoice.request({
                    invoiceId: invoiceId,
                    callback: () => {
                      dispatch(requestGetInvoice(invoiceId));
                      setIsVoiding(false);
                      setShowConfirmVoidInvoiceModal(false);
                    },
                    onErrorCallback: () => setIsVoiding(false),
                  })
                );
              }}
              isProcessing={isVoiding}
            >
              Void
            </Button>
          </>
        }
      ></Modal>
    </>
  );
};

export default VoidInvoiceButton;
