import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import './DealMaterialsList.sass';

import { getDeal } from '@/utils/stateAccessors';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import * as dealsActions from '@payaca/store/deals/dealsActions';
import ManageableMaterialsList from '../manageableMaterialsList/ManageableMaterialsList';
import DealCostsMargins from '../dealCostsMargins/DealCostsMargins';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { useMaterialsList } from '@payaca/store/hooks/appState';
import { useSelector } from '@/api/state';

interface Props {
  dealId: number;
  enablePurchaseBehaviour?: boolean;
}

export const DealMaterialsList: FC<Props> = ({
  dealId,
  enablePurchaseBehaviour = true,
}: Props) => {
  const dispatch = useDispatch();

  const deal = useSelector((state) => getDeal(state, dealId));

  const materialsList = useMaterialsList(deal?.materialsListId);

  const handleCreateDealMaterialsList = useCallback(() => {
    dispatch(
      materialsListActions.requestCreateDealMaterialsList(
        dealId,
        (materialsListId: number) => {
          dispatch(dealsActions.requestGetDeal(dealId));
        }
      )
    );
  }, [dealId]);

  const handleGetMaterialsListWithRelatedEntities = useCallback(
    (materialsListId: number) => {
      dispatch(
        materialsListActions.requestGetMaterialsListWithRelatedEntities(
          materialsListId
        )
      );
    },
    []
  );

  useEffect(() => {
    if (deal) {
      if (!deal.materialsListId) {
        handleCreateDealMaterialsList();
      } else {
        handleGetMaterialsListWithRelatedEntities(deal.materialsListId);
      }
    }
  }, [deal?.materialsListId]);

  useEffect(() => {
    dispatch(dealsActions.requestGetDeal(dealId));
  }, [dealId]);

  const shouldShowDealCosts = useMemo(() => {
    return materialsList && !!materialsList.materialsListMaterialIds?.length;
  }, [materialsList?.materialsListMaterialIds?.length]);

  return (
    <div className="deal-materials-list">
      {shouldShowDealCosts && (
        <ResponsiveViewWrapper
          className="deal-totals-costs-container"
          downBreakpointSm={600}
          downBreakpointXs={450}
        >
          <div className="deal-totals-costs-container-inner">
            {shouldShowDealCosts && <DealCostsMargins dealId={dealId} />}
          </div>
        </ResponsiveViewWrapper>
      )}

      {!!deal?.materialsListId && (
        <ManageableMaterialsList
          materialsListId={deal?.materialsListId}
          dealContextId={dealId}
        />
      )}
    </div>
  );
};

export default DealMaterialsList;
