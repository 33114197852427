import React, { FunctionComponent, useMemo } from 'react';

import {
  getCustomerByDealId,
  getDeal,
  getInvoice,
  getInvoiceLinesByInvoiceId,
  getInvoicesByDealId,
  getJobLineItemsByInvoiceId,
} from '@/utils/stateAccessors';

import InvoiceDocumentComponent from '@payaca/components/invoiceDocument/InvoiceDocument';
import { Address } from '@payaca/types/locationTypes';
import { useSelector } from '@/api/state';
import { getDocumentSenderContextFromAccount } from '@payaca/helpers/documentSenderContextHelper';
import {
  useCustomerForDeal,
  useDeal,
  useJobContent,
  useJobLineItemsForJob,
  useProposal,
} from '@payaca/store/hooks/appState';
import moment from 'moment-timezone';
import { InvoiceLine } from '@payaca/types/invoiceTypes';

type Props = {
  protoInvoiceId: number;
  placeholderReference?: number;
};

const ProtoInvoiceDocument: FunctionComponent<Props> = ({
  protoInvoiceId,
  placeholderReference,
}: Props): JSX.Element | null => {
  const protoInvoice = useProposal(protoInvoiceId);

  const jobContent = useJobContent(protoInvoice?.jobContentId);

  const jobLineItems = useJobLineItemsForJob(protoInvoiceId);

  const deal = useDeal(protoInvoice?.dealId);

  const customer = useCustomerForDeal(protoInvoice?.dealId);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const invoice = useMemo(() => {
    if (!protoInvoice) return;

    return {
      id: protoInvoice.id,
      notes: protoInvoice.invoiceConfig?.notes || undefined,
      sentAt: undefined,
      voidedAt: protoInvoice.inactivatedAt,
      totalValue: jobContent?.total || 0,
      dueAt: moment()
        .add(protoInvoice.invoiceConfig?.dueInDays, 'days')
        .toDate(),
      reference: placeholderReference || 0,
      customReference: protoInvoice.customReference?.length
        ? protoInvoice.customReference
        : `${placeholderReference ? placeholderReference : ''}`,
      dueValue: jobContent?.total || 0,
      invoiceLineIds: jobLineItems?.map((item) => item.id) || [],
    };
  }, [protoInvoice, jobContent, jobLineItems]);

  const invoiceLines = useMemo(() => {
    return jobLineItems.map((jli) => {
      return {
        id: jli.id,
        invoiceId: protoInvoiceId,
        jobLineItemId: jli.id,
        value: jli.total,
      } as InvoiceLine;
    });
  }, [jobLineItems]);

  if (!deal || !customer || !protoInvoice || !invoice) return null;

  return (
    <InvoiceDocumentComponent
      invoices={[invoice]}
      invoiceId={protoInvoiceId}
      dealTotals={{
        acceptedValue: jobContent?.total || 0,
      }}
      isStraightToInvoice={deal.isStraightToInvoice}
      siteAddresses={deal.siteAddresses || []}
      customer={customer}
      jobLineItems={jobLineItems}
      invoiceLines={invoiceLines}
      documentSenderContext={getDocumentSenderContextFromAccount(account)}
      hideItemPrices={deal.hideItemPrices}
      paymentMethodConfig={{
        allowStripePayment: deal.allowStripePayment,
        allowBankTransferPayment: deal.allowBankTransferPayment,
        bankTransferPaymentSettings: {
          accountNumber: account.accountNumberRaw || '',
          accountName: account.accountNameRaw || '',
          sortCode: account.sortCodeRaw || '',
        },
      }}
    />
  );
};

export default ProtoInvoiceDocument;
