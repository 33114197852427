import React, { FC, PropsWithChildren } from 'react';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Button from '@payaca/components/plButton/Button';
import Conditional from '@payaca/components/conditional/Conditional';

export interface IProps {
  onAdd?: () => void;
}

const DeviceArrayCard: FC<PropsWithChildren<IProps>> = (props) => {
  const { onAdd, children } = props;

  return (
    <div className="space-y-4">
      {children}

      <Conditional condition={!!onAdd}>
        <div>
          <Button
            onClick={onAdd}
            variant={EBtnVariant.Outline}
            size={EBtnSize.Small}
          >
            Add
          </Button>
        </div>
      </Conditional>
    </div>
  );
};

export default DeviceArrayCard;
