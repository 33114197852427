import { FieldValidator } from '@payaca/types/fieldValidationTypes';
import {
  PaymentSchedule,
  PaymentScheduleStage,
} from '@payaca/types/payment-schedule';
import get from 'lodash.get';

export const isPaymentScheduleValid = (
  paymentSchedule: PaymentSchedule
): boolean => {
  if (paymentSchedule.stages.length === 0) {
    // Schedule doesn't have any stages
    return false;
  }

  const percentageSum = paymentSchedule.stages
    .map((stage) => stage.percentageDue)
    .reduce((a, b) => a + b, 0);

  if (percentageSum > 100) {
    // Schedule stage percentages sum to more than 100 (percentage sum can be less than 100)
    return false;
  }

  if (paymentSchedule.stages.some((stage) => !stage.percentageDue)) {
    // Schedule has a stage with no percentage
    return false;
  }

  const numDepositStages = paymentSchedule.stages.filter(
    (stage) => stage.isDeposit
  ).length;

  if (numDepositStages > 1) {
    // Schedule has more than one deposit stage
    return false;
  }

  if (numDepositStages === 1 && paymentSchedule.stages[0].isDeposit !== true) {
    // Schedule has a deposit stage that isn't the first stage
    return false;
  }

  return true;
};

export const getPaymentScheduleStagesWithValues = (
  paymentSchedule: PaymentSchedule,
  projectValue: number
): (PaymentScheduleStage & { value: number })[] => {
  const isValid = isPaymentScheduleValid(paymentSchedule);
  if (!isValid) throw new Error('Invalid payment schedule');

  let total = 0;
  const array: (PaymentScheduleStage & { value: number })[] = [];
  let totalPercentageDue = 0;

  paymentSchedule.stages.forEach((stage) => {
    let value = Math.round((projectValue * stage.percentageDue) / 100);

    if (totalPercentageDue + stage.percentageDue >= 100) {
      // last stage is reaminding value
      value = projectValue - total;
    }

    array.push({
      ...stage,
      value,
    });
    total += value;
    totalPercentageDue += stage.percentageDue;
  });

  return array;
};

export const getPaymentScheduleFieldValidator = (): FieldValidator => {
  return (fieldName: string, formState: { [key: string]: any }) => {
    const paymentSchedule = get(formState, fieldName) as PaymentSchedule;

    const validationMessages = [];
    if (
      paymentSchedule?.stages.reduce(
        (acc, curr) => acc + +curr.percentageDue,
        0
      ) > 100
    ) {
      validationMessages.push('Percentages must not add up to more than 100%');
    }
    if (paymentSchedule?.stages.some((stage) => !stage.percentageDue)) {
      validationMessages.push('Payment stage percentage is required');
    }
    if (paymentSchedule?.stages.some((stage) => +stage.percentageDue < 0)) {
      validationMessages.push('Payment stage percentage must be more than 0%');
    }
    if (paymentSchedule?.stages.some((stage) => !stage.description)) {
      validationMessages.push('Payment stage description is required');
    }
    if (paymentSchedule?.stages.filter((stage) => stage.isDeposit).length > 1) {
      validationMessages.push('Only one deposit stage is allowed');
    }
    return {
      isValid: !validationMessages.length,
      errors: validationMessages,
    };
  };
};
