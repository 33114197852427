import React, {
  FunctionComponent,
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';

import './ScalingContentWrapper.sass';

type Props = {
  className?: string;
  children?: (string | JSX.Element)[] | string | JSX.Element;
  naturalWidth?: number;
};

const ScalingContentWrapper: FunctionComponent<Props> = ({
  className,
  children,
  naturalWidth = 1000,
}: Props): JSX.Element => {
  const [scaleRatio, setScaleRatio] = useState(1);
  const [updateAspectRatioTrigger, setUpdateAspectRatioTrigger] =
    useState<Date>();

  const outerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const calculateScaleRatio = useCallback(() => {
    setScaleRatio(
      (outerRef?.current?.offsetWidth || 0) /
        (innerRef?.current?.offsetWidth || 0)
    );
  }, [outerRef?.current?.offsetWidth, innerRef?.current?.offsetWidth]);

  useEffect(() => {
    calculateScaleRatio();
    setUpdateAspectRatioTrigger(new Date());
  }, [
    outerRef?.current?.offsetWidth,
    outerRef?.current?.offsetHeight,
    innerRef?.current?.offsetWidth,
    innerRef?.current?.offsetHeight,
    children,
  ]);

  const aspectRatio = useMemo(() => {
    const offsetWidth = innerRef?.current?.offsetWidth || 0;

    if (offsetWidth == 0) return 1;

    return (
      (innerRef?.current?.offsetHeight || 0) /
      (innerRef?.current?.offsetWidth || 0)
    );
  }, [
    innerRef?.current?.offsetWidth,
    innerRef?.current?.offsetHeight,
    updateAspectRatioTrigger,
  ]);

  useEffect(() => {
    window.addEventListener('resize', calculateScaleRatio);

    return () => {
      window.removeEventListener('resize', calculateScaleRatio);
    };
  }, []);

  return (
    <div
      className={`scaling-content-wrapper ${className ? className : ''}`}
      ref={outerRef}
      style={{ paddingTop: `${aspectRatio * 100}%` }}
    >
      <div
        className="scaling-content-wrapper-inner"
        ref={innerRef}
        style={{ transform: `scale(${scaleRatio})`, width: naturalWidth }}
      >
        {children}
      </div>
    </div>
  );
};

export default ScalingContentWrapper;
