import { FunctionComponent, useCallback, useState } from 'react';

import { SortDirection } from '@payaca/types/listViewTypes';

import './SelectItemControl.sass';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import ListedLineItemsTable, {
  ListedLineItem,
} from '../listedLineItemsTable/ListedLineItemsTable';
import useGetLineItems from '../../../api/queries/lineItems/useGetLineItems';
import {
  GetLineItemsInput,
  GetLineItemsSortBy,
  PaginationInput,
} from '../../../gql/graphql';

type Props = {
  onSelectItem: (itemId: number) => void;
  onSelectDisabledItem: (itemId: number) => void;
  disabledItemIds: number[];
};

const SelectItemControl: FunctionComponent<Props> = ({
  onSelectItem,
  onSelectDisabledItem,
  disabledItemIds,
}: Props): JSX.Element => {
  const [getLineItemsPagination, setGetLineItemsPagination] =
    useState<PaginationInput>({ limit: 100, offset: 0 });

  const [getLineItemsInput, setGetLineItemsInput] = useState<GetLineItemsInput>(
    { sortBy: 'NAME', sortDirection: 'ASC' }
  );
  const [searchTerm, setSearchTerm] = useState('');

  const { data: lineItems, isLoading } = useGetLineItems(
    getLineItemsPagination,
    getLineItemsInput
  );

  const onSort = (field?: string, sortDirection?: SortDirection) => {
    const filters: Pick<GetLineItemsInput, 'sortBy' | 'sortDirection'> = {
      sortBy: field as GetLineItemsSortBy,
      sortDirection: sortDirection
        ? sortDirection === SortDirection.ASCENDING
          ? 'ASC'
          : 'DESC'
        : undefined,
    };

    setGetLineItemsInput((s) => ({
      ...s,
      ...filters,
    }));
  };

  return (
    <ManageableItemsList>
      <ManageableItemsList.ActionBar>
        <ManageableItemsList.ActionBar.SearchInput
          value={searchTerm}
          onChange={setSearchTerm}
          onChangeTimeout={(value) => {
            setGetLineItemsInput((s) => ({
              ...s,
              searchTerm: value,
            }));
            setGetLineItemsPagination((s) => ({
              ...s,
              offset: 0,
            }));
          }}
          changeTimeoutMs={250}
        />
      </ManageableItemsList.ActionBar>
      <ListedLineItemsTable
        isLoading={isLoading}
        lineItems={lineItems?.lineItems.items || []}
        onSort={onSort}
        onClickRow={(lineItem: ListedLineItem) =>
          disabledItemIds.includes(+lineItem.id)
            ? onSelectDisabledItem(+lineItem.id)
            : onSelectItem(+lineItem.id)
        }
      />
    </ManageableItemsList>
  );
};

export default SelectItemControl;
