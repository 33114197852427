import { AutomationDataAccessType } from '@payaca/types/automationDataAccessTypes';
import { AutomationEntityTypes } from '@payaca/types/automationTypes';

// Config for condtions and action variables
export const FIELD_OPTIONS: {
  label: string;
  entityColumns: { [key in AutomationEntityTypes]?: AutomationDataAccessType };
}[] = [
  {
    label: 'Customer name',
    entityColumns: {
      [AutomationEntityTypes.ESTIMATE]: AutomationDataAccessType.CUSTOMER_NAME,
      [AutomationEntityTypes.QUOTE]: AutomationDataAccessType.CUSTOMER_NAME,
      [AutomationEntityTypes.PROPOSAL]: AutomationDataAccessType.CUSTOMER_NAME,
      [AutomationEntityTypes.INVOICE]: AutomationDataAccessType.CUSTOMER_NAME,
      [AutomationEntityTypes.DEAL]: AutomationDataAccessType.CUSTOMER_NAME,
    },
  },
  {
    label: 'Location contact(s) e-mail',
    entityColumns: {
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.SCHEDULEDEVENT_LOCATION_CONTACTS_EMAILADDRESS_ARRAY,
    },
  },
  {
    label: 'Project site address contact(s) e-mail',
    entityColumns: {
      [AutomationEntityTypes.DEAL]:
        AutomationDataAccessType.DEAL_SITEADDRESSES_CONTACTS_EMAILADDRESS_ARRAY,
    },
  },
  {
    label: 'Project site address contact(s) phone number',
    entityColumns: {
      [AutomationEntityTypes.DEAL]:
        AutomationDataAccessType.DEAL_SITEADDRESSES_CONTACTS_TELEPHONENUMBER_ARRAY,
    },
  },
  {
    label: 'Project custom field',
    entityColumns: {
      [AutomationEntityTypes.DEAL]:
        AutomationDataAccessType.DEAL_CUSTOMFIELD_VALUE,
    },
  },
  {
    label: 'Location contact(s) phone number',
    entityColumns: {
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.SCHEDULEDEVENT_LOCATION_CONTACTS_TELEPHONENUMBER_ARRAY,
    },
  },
  {
    label: 'Customer e-mail',
    entityColumns: {
      [AutomationEntityTypes.ESTIMATE]:
        AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_EMAILADDRESS,
      [AutomationEntityTypes.QUOTE]:
        AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_EMAILADDRESS,
      [AutomationEntityTypes.PROPOSAL]:
        AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_EMAILADDRESS,
      [AutomationEntityTypes.INVOICE]:
        AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_EMAILADDRESS,
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_EMAILADDRESS,
      [AutomationEntityTypes.DEAL]:
        AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_EMAILADDRESS,
    },
  },
  {
    label: 'Customer phone number',
    entityColumns: {
      [AutomationEntityTypes.ESTIMATE]:
        AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_TELEPHONENUMBER,
      [AutomationEntityTypes.QUOTE]:
        AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_TELEPHONENUMBER,
      [AutomationEntityTypes.PROPOSAL]:
        AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_TELEPHONENUMBER,
      [AutomationEntityTypes.INVOICE]:
        AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_TELEPHONENUMBER,
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_TELEPHONENUMBER,
      [AutomationEntityTypes.DEAL]:
        AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_TELEPHONENUMBER,
    },
  },
  {
    label: 'Custom number',
    entityColumns: {
      [AutomationEntityTypes.ESTIMATE]:
        AutomationDataAccessType.CUSTOM_TELEPHONENUMBER,
      [AutomationEntityTypes.QUOTE]:
        AutomationDataAccessType.CUSTOM_TELEPHONENUMBER,
      [AutomationEntityTypes.PROPOSAL]:
        AutomationDataAccessType.CUSTOM_TELEPHONENUMBER,
      [AutomationEntityTypes.INVOICE]:
        AutomationDataAccessType.CUSTOM_TELEPHONENUMBER,
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.CUSTOM_TELEPHONENUMBER,
      [AutomationEntityTypes.DEAL]:
        AutomationDataAccessType.CUSTOM_TELEPHONENUMBER,
    },
  },
  {
    label: 'Custom email',
    entityColumns: {
      [AutomationEntityTypes.ESTIMATE]:
        AutomationDataAccessType.CUSTOM_EMAILADDRESS,
      [AutomationEntityTypes.QUOTE]:
        AutomationDataAccessType.CUSTOM_EMAILADDRESS,
      [AutomationEntityTypes.PROPOSAL]:
        AutomationDataAccessType.CUSTOM_EMAILADDRESS,
      [AutomationEntityTypes.INVOICE]:
        AutomationDataAccessType.CUSTOM_EMAILADDRESS,
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.CUSTOM_EMAILADDRESS,
      [AutomationEntityTypes.DEAL]:
        AutomationDataAccessType.CUSTOM_EMAILADDRESS,
    },
  },
  {
    label: 'Assignee e-mail',
    entityColumns: {
      [AutomationEntityTypes.DEAL]: AutomationDataAccessType.USER_EMAILADDRESS,
    },
  },
  {
    label: 'Assignee(s) e-mail',
    entityColumns: {
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.SCHEDULEDEVENT_ASSIGNEDUSERS_EMAILADDRESS_ARRAY,
    },
  },
  {
    label: 'Assignee(s) phone number',
    entityColumns: {
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.SCHEDULEDEVENT_ASSIGNEDUSERS_TELEPHONENUMBER_ARRAY,
    },
  },
  {
    label: 'Task status',
    entityColumns: {
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.SCHEDULEDEVENT_TASKSTATUS,
    },
  },
  {
    label: 'The newly-added Tag',
    entityColumns: {
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.TRIGGER_TAGTEXT_ARRAY,
      [AutomationEntityTypes.DEAL]:
        AutomationDataAccessType.TRIGGER_TAGTEXT_ARRAY,
    },
  },

  {
    label: 'Has a Tag',
    entityColumns: {
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.SCHEDULEDEVENT_TAGS_TAGTEXT_ARRAY,
      [AutomationEntityTypes.DEAL]:
        AutomationDataAccessType.DEAL_TAGS_TAGTEXT_ARRAY,
    },
  },
  {
    label: 'Does not have a Tag',
    entityColumns: {
      [AutomationEntityTypes.DEAL]:
        AutomationDataAccessType.DEAL_NOT_TAGS_TAGTEXT_ARRAY,
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.SCHEDULEDEVENT_NOT_TAGS_TAGTEXT_ARRAY,
    },
  },
  {
    label: 'Project pipeline',
    entityColumns: {
      [AutomationEntityTypes.DEAL]: AutomationDataAccessType.DEAL_PIPELINEID,
      [AutomationEntityTypes.EVENT]: AutomationDataAccessType.DEAL_PIPELINEID,
      [AutomationEntityTypes.ESTIMATE]:
        AutomationDataAccessType.DEAL_PIPELINEID,
      [AutomationEntityTypes.QUOTE]: AutomationDataAccessType.DEAL_PIPELINEID,
      [AutomationEntityTypes.PROPOSAL]:
        AutomationDataAccessType.DEAL_PIPELINEID,
      [AutomationEntityTypes.INVOICE]: AutomationDataAccessType.DEAL_PIPELINEID,
    },
  },
  {
    label: 'Project has a Tag',
    entityColumns: {
      [AutomationEntityTypes.ESTIMATE]:
        AutomationDataAccessType.DEAL_TAGS_TAGTEXT_ARRAY,
      [AutomationEntityTypes.QUOTE]:
        AutomationDataAccessType.DEAL_TAGS_TAGTEXT_ARRAY,
      [AutomationEntityTypes.PROPOSAL]:
        AutomationDataAccessType.DEAL_TAGS_TAGTEXT_ARRAY,
      [AutomationEntityTypes.INVOICE]:
        AutomationDataAccessType.DEAL_TAGS_TAGTEXT_ARRAY,
    },
  },
  {
    label: 'Project does not have a Tag',
    entityColumns: {
      [AutomationEntityTypes.ESTIMATE]:
        AutomationDataAccessType.DEAL_NOT_TAGS_TAGTEXT_ARRAY,
      [AutomationEntityTypes.QUOTE]:
        AutomationDataAccessType.DEAL_NOT_TAGS_TAGTEXT_ARRAY,
      [AutomationEntityTypes.PROPOSAL]:
        AutomationDataAccessType.DEAL_NOT_TAGS_TAGTEXT_ARRAY,
      [AutomationEntityTypes.INVOICE]:
        AutomationDataAccessType.DEAL_NOT_TAGS_TAGTEXT_ARRAY,
    },
  },
  {
    label: 'Project pipeline stage',
    entityColumns: {
      [AutomationEntityTypes.DEAL]: AutomationDataAccessType.DEAL_PIPELINESTAGE,
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.DEAL_PIPELINESTAGE,
      [AutomationEntityTypes.INVOICE]:
        AutomationDataAccessType.DEAL_PIPELINESTAGE,
    },
  },
  {
    label: 'The new Project pipeline stage',
    entityColumns: {
      [AutomationEntityTypes.DEAL]:
        AutomationDataAccessType.TRIGGER_PIPELINESTAGE_NEW,
    },
  },
  {
    label: 'The old Project pipeline stage',
    entityColumns: {
      [AutomationEntityTypes.DEAL]:
        AutomationDataAccessType.TRIGGER_PIPELINESTAGE_OLD,
    },
  },
  {
    label: 'Status',
    entityColumns: {
      [AutomationEntityTypes.ESTIMATE]:
        AutomationDataAccessType.PROPOSAL_READABLE_STATUS,
      [AutomationEntityTypes.QUOTE]:
        AutomationDataAccessType.PROPOSAL_READABLE_STATUS,
      [AutomationEntityTypes.PROPOSAL]:
        AutomationDataAccessType.PROPOSAL_READABLE_STATUS,
      [AutomationEntityTypes.INVOICE]:
        AutomationDataAccessType.INVOICE_READABLE_STATUS,
    },
  },
  {
    label: 'Automation triggered day-of-week',
    entityColumns: {
      [AutomationEntityTypes.ESTIMATE]:
        AutomationDataAccessType.TRIGGER_TRIGGEREDAT_DAYOFWEEK,
      [AutomationEntityTypes.QUOTE]:
        AutomationDataAccessType.TRIGGER_TRIGGEREDAT_DAYOFWEEK,
      [AutomationEntityTypes.PROPOSAL]:
        AutomationDataAccessType.TRIGGER_TRIGGEREDAT_DAYOFWEEK,
      [AutomationEntityTypes.INVOICE]:
        AutomationDataAccessType.TRIGGER_TRIGGEREDAT_DAYOFWEEK,
      [AutomationEntityTypes.EVENT]:
        AutomationDataAccessType.TRIGGER_TRIGGEREDAT_DAYOFWEEK,
      [AutomationEntityTypes.DEAL]:
        AutomationDataAccessType.TRIGGER_TRIGGEREDAT_DAYOFWEEK,
    },
  },
];

export const fieldOptionEntityMap = FIELD_OPTIONS.reduce(
  (acc: any, { label, type, entityColumns }: any) => {
    return Object.keys(entityColumns).reduce((acc: any, key: string) => {
      if (!acc[key]) acc[key] = {};
      acc[key][entityColumns[key]] = { label, type };
      return acc;
    }, acc);
  },
  {}
);

export const CONDITION_OPERATOR_OPTIONS = [
  {
    label: 'includes the following text',
    value: 'includes',
    allowableFieldValues: [
      AutomationDataAccessType.CUSTOMER_NAME,
      AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_EMAILADDRESS,
      AutomationDataAccessType.SCHEDULEDEVENT_TAGS_TAGTEXT_ARRAY,
      AutomationDataAccessType.SCHEDULEDEVENT_NOT_TAGS_TAGTEXT_ARRAY,
      AutomationDataAccessType.DEAL_TAGS_TAGTEXT_ARRAY,
      AutomationDataAccessType.DEAL_NOT_TAGS_TAGTEXT_ARRAY,
    ],
  },
  {
    label: 'equals',
    value: 'equals',
  },
  {
    label: 'does not equal',
    value: 'not-equals',
    allowableFieldValues: [
      AutomationDataAccessType.CUSTOMER_NAME,
      AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_EMAILADDRESS,
      AutomationDataAccessType.CUSTOMER_PRIMARYCONTACT_TELEPHONENUMBER,
      AutomationDataAccessType.USER_EMAILADDRESS,
      AutomationDataAccessType.TRIGGER_TAGTEXT_ARRAY,
      AutomationDataAccessType.DEAL_PIPELINEID,
      AutomationDataAccessType.DEAL_PIPELINESTAGE,
      AutomationDataAccessType.INVOICE_READABLE_STATUS,
      AutomationDataAccessType.PROPOSAL_READABLE_STATUS,
      AutomationDataAccessType.SCHEDULEDEVENT_ASSIGNEDUSERS_EMAILADDRESS_ARRAY,
      AutomationDataAccessType.SCHEDULEDEVENT_ASSIGNEDUSERS_TELEPHONENUMBER_ARRAY,
      AutomationDataAccessType.SCHEDULEDEVENT_TASKSTATUS,
      AutomationDataAccessType.TRIGGER_TRIGGEREDAT_DAYOFWEEK,
    ],
  },
];
export const conditionValueLabelMap = CONDITION_OPERATOR_OPTIONS.reduce(
  (acc: any, { label, value }: any) => ({ ...acc, [value]: label }),
  {}
);
