import React, { FC, useCallback, useMemo, useState } from 'react';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import {
  EntityTemplatePlaceholderVariables,
  ProposalTemplatePlaceholderVariables,
  PublicEntityTemplate,
} from '@payaca/types/entity-templates';
import Field from '@payaca/components/plField/Field';
import Input, { InputSizeVariant } from '@payaca/components/plInput/Input';
import { Textarea } from '@payaca/components/plTextarea/Textarea';
import { useAccount } from '@/utils/storeHooks';
import Button from '@payaca/components/plButton/Button';
import {
  getAllowEmptyValidator,
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
  getNumericalRangeFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import RichTextarea from '@payaca/components/plRichTextarea/RichTextarea';
import CreatePaymentScheduleControl from './CreatePaymentScheduleControl';
import {
  getPaymentScheduleFieldValidator,
  isPaymentScheduleValid,
} from '@payaca/helpers/paymentScheduleHelper';
import { useProjectCustomFields } from '@/hooks/useProjectCustomFields';

type Props = {
  proposalTemplate?: Omit<PublicEntityTemplate<'proposal'>, 'publicId'>;
  onRequestSave?: (
    proposalTemplate: Omit<PublicEntityTemplate<'proposal'>, 'publicId'>
  ) => void;
  allowModifyTemplateNameDescription?: boolean;
};
const CreateEditProposalTemplateControl: FC<Props> = ({
  proposalTemplate,
  onRequestSave,
  allowModifyTemplateNameDescription = true,
}: Props): JSX.Element => {
  const projectCustomFields = useProjectCustomFields();

  const editorVariables = useMemo(() => {
    const ev: {
      name: string;
      displayName: string;
    }[] = [
      {
        name: EntityTemplatePlaceholderVariables.CUSTOMER_NAME,
        displayName: 'Customer name',
      },
      {
        name: EntityTemplatePlaceholderVariables.PROJECT_SITEADDRESSES_ARRAYSTRING,
        displayName: 'Project site address(es)',
      },
    ];

    projectCustomFields
      .filter(
        (field) =>
          ['text', 'select', 'email', 'textarea'].includes(field.type) &&
          !field.archivedAt
      )
      .forEach((field) => {
        ev.push({
          name: `${EntityTemplatePlaceholderVariables.PROJECT_CUSTOMFIELD_VALUE}:${field.identifier}`,
          displayName: `Project custom field: ${field.identifier}`,
        });
      });

    return ev;
  }, [projectCustomFields?.length]);

  const fieldValidators = useMemo(() => {
    return {
      name: [
        getIsRequiredFieldValidator(),
        getLengthFieldValidator({ min: 0, max: 60 }),
      ],
      description: [getLengthFieldValidator({ min: 0, max: 255 })],
      'data.validForDays': [
        getAllowEmptyValidator(
          getNumericalRangeFieldValidator(
            -0.01,
            undefined,
            undefined,
            'This field must be a positive number'
          )
        ),
      ],
      'data.paymentSchedule': [getPaymentScheduleFieldValidator()],
      'data.summaryMessages[0].message': [
        getLengthFieldValidator({ min: 0, max: 125 }),
      ],
    };
  }, []);

  const account = useAccount();

  const initialFormState = useMemo(() => {
    return {
      name: proposalTemplate?.name,
      description: proposalTemplate?.description,
      entityType: 'proposal',
      data: {
        validForDays: account?.propositionValidForDays,
        paymentSchedule: null,
        ...proposalTemplate?.data,
        summaryMessages: proposalTemplate?.data.summaryMessages || [
          {
            level: 'alert',
            message: '',
          },
        ],
      },
    };
  }, [proposalTemplate]);

  const saveTemplate = (
    formState: Omit<PublicEntityTemplate<'proposal'>, 'publicId'>
  ) => {
    const formStateToSave = { ...formState };
    const validStages = (formState.data.paymentSchedule?.stages || [])
      .map((x) => ({ ...x, percentageDue: +x.percentageDue }))
      .filter((stage) => stage.description && stage.percentageDue);
    // must have description and percentageDue
    const cleanedPaymentSchedule = {
      explainer: validStages?.length
        ? formState.data.paymentSchedule?.explainer
        : null,
      stages: validStages,
    };
    const paymentScheduleToSave = isPaymentScheduleValid(cleanedPaymentSchedule)
      ? cleanedPaymentSchedule
      : null;

    formStateToSave.data.paymentSchedule = paymentScheduleToSave;
    onRequestSave?.(formStateToSave);
  };

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          {allowModifyTemplateNameDescription && (
            <>
              <Field
                name="name"
                validationState={
                  validationState?.['name']?.isValid === false &&
                  touchedState?.['name']
                    ? {
                        isValid: false,
                        validationMessages: validationState?.['name']?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>Template name</Field.Label>
                <Input
                  value={formState.name}
                  onChange={(value) => onFieldChange({ name: value })}
                  onBlur={() => onFieldTouch?.('name')}
                />
              </Field>
              <Field
                name="description"
                validationState={
                  validationState?.['description']?.isValid === false &&
                  touchedState?.['description']
                    ? {
                        isValid: false,
                        validationMessages:
                          validationState?.['description']?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>Template description</Field.Label>
                <Textarea
                  value={formState.description}
                  onChange={(value) => onFieldChange({ description: value })}
                  onBlur={() => onFieldTouch?.('description')}
                />
              </Field>
              <Field name="data.introduction">
                <Field.Label>Intro</Field.Label>
                <RichTextarea
                  value={formState.data?.introduction}
                  onChange={(value) =>
                    onFieldChange({ ['data.introduction']: value })
                  }
                  variables={editorVariables}
                />
              </Field>
              <Field name="data.notes">
                <Field.Label>Notes</Field.Label>
                <RichTextarea
                  value={formState.data?.notes}
                  onChange={(value) => onFieldChange({ ['data.notes']: value })}
                  variables={editorVariables}
                />
              </Field>
              <Field
                name="data.validForDays"
                validationState={
                  validationState?.['data.validForDays']?.isValid === false &&
                  touchedState?.['data.validForDays']
                    ? {
                        isValid: false,
                        validationMessages:
                          validationState?.['data.validForDays']?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>Valid for (days)</Field.Label>

                <Input
                  className={'max-w-[200px]'}
                  value={formState.data.validForDays}
                  onChange={(value) =>
                    onFieldChange({ ['data.validForDays']: value })
                  }
                  onBlur={() => onFieldTouch?.('data.validForDays')}
                  type="number"
                  step={1}
                />
              </Field>

              <CreatePaymentScheduleControl
                onChange={(change) =>
                  onFieldChange({ ['data.paymentSchedule']: change })
                }
                paymentSchedule={formState.data.paymentSchedule}
                fieldValidationState={
                  validationState
                    ? {
                        ...validationState?.['data.paymentSchedule'],
                        validationMessages:
                          validationState?.['data.paymentSchedule']?.errors,
                      }
                    : undefined
                }
              />
              <Field
                name="data.summaryMessages[0].message"
                validationState={
                  validationState?.['data.summaryMessages[0].message']
                    ?.isValid === false &&
                  touchedState?.['data.summaryMessages[0].message']
                    ? {
                        isValid: false,
                        validationMessages:
                          validationState?.['data.summaryMessages[0].message']
                            ?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>Payment summary alert</Field.Label>
                <p className="mb-2">
                  This text will show immediately below the &#34;Total payable
                  amount&#34; in the summary box on the right.
                </p>
                <Input
                  value={formState.data.summaryMessages[0].message}
                  onChange={(value) =>
                    onFieldChange({
                      ['data.summaryMessages[0].message']: value,
                    })
                  }
                  onBlur={() =>
                    onFieldTouch?.('data.summaryMessages[0].message')
                  }
                />
              </Field>
            </>
          )}
          <div className="actions-container">
            <Button
              onClick={() =>
                saveTemplate(
                  formState as Omit<
                    PublicEntityTemplate<'proposal'>,
                    'publicId'
                  >
                )
              }
              disabled={!isValid}
            >
              {proposalTemplate ? 'Save' : 'Create'} Proposal Template
            </Button>
          </div>
        </>
      );
    },
    [
      proposalTemplate,
      onRequestSave,
      allowModifyTemplateNameDescription,
      editorVariables,
    ]
  );

  return (
    <div className="create-edit-scheduled-event-template-control">
      <ValidatedForm<{ [key: string]: any }>
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
        initialFormState={initialFormState}
      ></ValidatedForm>
    </div>
  );
};
export default CreateEditProposalTemplateControl;
