import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { ListedDeal, SortBy } from '@payaca/types/listedDealTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import * as dealActions from '@payaca/store/deals/dealsActions';
import { getBillingAddressFromCustomer } from '@payaca/helpers/customerHelper';

import { Deal } from '@payaca/types/dealTypes';
import { Customer } from '@payaca/types/customerTypes';
import * as customerActions from '@payaca/store/customer/customerActions';
import Combobox from '@payaca/components/plCombobox/Combobox';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import { Address } from '@payaca/types/locationTypes';
import { SelectOption } from '@payaca/components/plSelect/Select';
import { useCustomer, useDeal } from '@payaca/store/hooks/appState';

interface Props {
  dealId?: Deal['id'];
  onChange: (
    dealId?: Deal['id'],
    metadata?: { deal?: Deal; customer?: Customer }
  ) => void;
  disabled?: boolean;
  showCardIfDisabled?: boolean;
}

const DealSelect: FC<Props> = ({
  dealId,
  onChange,
  disabled,
  showCardIfDisabled,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState<string>();
  const [requiresGetListedDealsPage, setRequiresGetListedDealsPage] =
    useState(false);
  const changeTimeout = useRef<NodeJS.Timeout | null>(null);
  const [isChanging, setIsChanging] = useState(false);

  const retrievedDeal = useDeal(dealId);
  const retrievedCustomer = useCustomer(retrievedDeal?.customerId);
  const [deal, setDeal] = useState<Deal>();

  const [customer, setCustomer] = useState<Customer>();

  const [listedDeals, setListedDeals] = useState<ListedDeal[]>([]);

  useEffect(() => {
    if (changeTimeout?.current) {
      clearTimeout(changeTimeout.current);
    }
    changeTimeout.current = setTimeout(() => {
      setRequiresGetListedDealsPage(true);
    }, 500);
  }, [query]);

  const requestGetListedDealsPage = useCallback(() => {
    dispatch(
      dealActions.requestGetListedDealsPage(
        {
          sortBy: SortBy.LAST_UPDATED,
          sortDirection: SortDirection.DESCENDING,
          pageSize: 20,
          pageNumber: 1,
          searchTerm: query,
        },
        (page) => {
          setListedDeals(page.items);
        }
      )
    );
    setRequiresGetListedDealsPage(false);
  }, [query, dispatch]);

  const getAndSetCustomer = (customerId?: Customer['id']) => {
    if (customerId) {
      dispatch(
        customerActions.requestGetCustomer(customerId, (customer) => {
          setCustomer(customer);
        })
      );
    }
  };

  useEffect(() => {
    if (!dealId) {
      setRequiresGetListedDealsPage(true);
    } else if (retrievedDeal) {
      setDeal(retrievedDeal);

      if (retrievedCustomer) {
        setCustomer(retrievedCustomer);
      } else {
        getAndSetCustomer(retrievedDeal.customerId);
      }
    } else {
      dispatch(
        dealActions.requestGetDeal(dealId, (deal) => {
          setDeal(deal);
          getAndSetCustomer(deal.customerId);
        })
      );
    }
  }, []);

  useEffect(() => {
    if (requiresGetListedDealsPage) {
      requestGetListedDealsPage();
    }
  }, [requiresGetListedDealsPage]);

  const options = useMemo(() => {
    const o: SelectOption<
      number,
      { address?: Address; customerName?: string }
    >[] = listedDeals.map((deal) => {
      return {
        label: deal.reference,
        value: deal.dealId,
        metadata: {
          address: deal.siteAddresses?.[0] || deal.customerBillingAddress,
          customerName: deal.customerName,
        },
      };
    });

    if (deal && !o.find((x) => x.value === deal.id)) {
      o.push({
        value: deal.id,
        label: deal.customReference || deal.reference?.toString() || '',
        metadata: {
          address:
            deal.siteAddresses?.[0]?.address ||
            getBillingAddressFromCustomer(customer)?.address ||
            undefined,
          customerName: customer?.name,
        },
      });
    }

    return o;
  }, [listedDeals, deal, customer]);

  const handleOnChange = useCallback(
    (value: any) => {
      const dealId = value as Deal['id'] | undefined;

      setDeal(undefined);
      setCustomer(undefined);

      if (!dealId) onChange();
      else {
        setIsChanging(true);
        dispatch(
          dealActions.requestGetDeal(dealId, (deal) => {
            setDeal(deal);
            if (deal.customerId) {
              dispatch(
                customerActions.requestGetCustomer(
                  deal.customerId,
                  (customer) => {
                    setCustomer(customer);
                    onChange(dealId, { deal, customer });
                    setIsChanging(false);
                  }
                )
              );
            } else {
              onChange(dealId, { deal });
              setIsChanging(false);
            }
          })
        );
      }
    },
    [onChange]
  );

  if (disabled && deal && showCardIfDisabled)
    return (
      <div className="rounded-md border border-gray-200 px-4 py-3 shadow-sm">
        <Option
          option={{
            value: deal.id,
            label: deal.customReference || deal.reference?.toString() || '',
            metadata: {
              address:
                deal.siteAddresses?.[0]?.address ||
                getBillingAddressFromCustomer(customer)?.address ||
                undefined,
              customerName: customer?.name,
            },
          }}
        />
      </div>
    );

  return (
    <Combobox
      disabled={disabled}
      onChange={handleOnChange}
      options={options}
      value={isChanging ? undefined : dealId}
      placeholder={isChanging || dealId ? '...' : undefined}
      query={query}
      setQuery={setQuery}
      filterFunction={() => true}
      CustomOption={Option}
      CustomSelected={({ selectedOptions }) => {
        const option = selectedOptions[0];

        if (!option) return null;

        return <Option option={option} />;
      }}
    />
  );
};

export default DealSelect;

const Option: FC<{
  option: SelectOption<number, { address?: Address; customerName?: string }>;
}> = ({ option }) => {
  return (
    <div className="w-full">
      <div className="flex items-baseline gap-2">
        <span className="text-base font-semibold">{option.label}</span>
        <span className="">{option.metadata?.customerName || '-'}</span>
      </div>
      {!!option.metadata?.address && (
        <div className="w-full truncate">
          <span className="min-w-0 text-base text-gray-500">
            {getAddressAsString(option.metadata.address)}
          </span>
        </div>
      )}
    </div>
  );
};
