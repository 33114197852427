import React, { FC } from 'react';
import PayacaLogo from '@/assets/images/icon.png';
import Button from '@payaca/components/button/Button';

import './StatusError.css';

type Props = {
  title: string;
  status?: number;
  errorName: string;
  errorDescriptionMessage: string;
  errorResolveMessage?: string;
  buttonTitle?: string;
  buttonOnClick?: () => void;
};
const StatusError: FC<Props> = ({
  title,
  status,
  errorName,
  errorDescriptionMessage,
  errorResolveMessage,
  buttonTitle,
  buttonOnClick,
}: Props): JSX.Element => {
  return (
    <div className="status-error">
      <img src={PayacaLogo}></img>
      <h1>{title}</h1>
      <h4>
        Error{status ? ` ${status}` : ''}: {errorName}
      </h4>
      <p>{errorDescriptionMessage}</p>
      {errorResolveMessage && <p>{errorResolveMessage}</p>}
      {buttonTitle && buttonOnClick && (
        <Button onClick={buttonOnClick}>{buttonTitle}</Button>
      )}
    </div>
  );
};

export default StatusError;
