export enum MaterialsPermissions {
  GET_MATERIALS = 'materials.getMaterials',
  GET_SYSTEM_MANAGED_MATERIALS = 'materials.getSystemManagedMaterials',
  PERSIST_MATERIAL = 'materials.persistMaterial',
  ARCHIVE_MATERIAL = 'materials.archiveMaterial',
  GET_LINE_ITEM_MATERIALS = 'materials.getLineItemMaterials',
  PERSIST_LINE_ITEM_MATERIAL = 'materials.persistLineItemMaterial',
  DELETE_LINE_ITEM_MATERIAL = 'materials.deleteLineItemMaterial',
  GET_SUPPLIER_MATERIALS = 'materials.getSupplierMaterials',
  PERSIST_SUPPLIER_MATERIAL = 'materials.persistSupplierMaterial',
  DELETE_SUPPLIER_MATERIAL = 'materials.deleteSupplierMaterial',
  BULK_IMPORT_MATERIALS = 'materials.bulkImport',
  BULK_IMPORT_SUPPLIER_MATERIALS = 'materials.bulkImportSupplierMaterials',
  GET_COLLECTION_MANAGEMENT_VIEW = 'materials.getCollectionManagementView',
}
