import React, { FunctionComponent } from 'react';

export enum HeaderTagType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
}

type Props = {
  children: React.ReactNode;
  className?: string;
  type: HeaderTagType;
};

const HeaderTag: FunctionComponent<Props> = ({
  children,
  className,
  type,
}: Props): JSX.Element => {
  if (type === HeaderTagType.H1) {
    return <h1 className={className}>{children}</h1>;
  }
  if (type === HeaderTagType.H2) {
    return <h2 className={className}>{children}</h2>;
  }
  if (type === HeaderTagType.H3) {
    return <h3 className={className}>{children}</h3>;
  }
  if (type === HeaderTagType.H4) {
    return <h4 className={className}>{children}</h4>;
  }
  return <h5 className={className}>{children}</h5>;
};

export default HeaderTag;
