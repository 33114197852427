import React, { useMemo, useRef, useState } from 'react';
import './UserIndicator.sass';
import tinycolor from 'tinycolor2';
import { Grow, Paper, Popper, PopperPlacementType } from '@material-ui/core';

type Props = {
  user: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    userColour?: string;
    imgSrc?: string;
  };
  size?: 'md' | 'sm' | 'xs';
  isUserInformationHoverPopperEnabled?: boolean;
  userInformationHoverPopperContentOverride?: React.ReactNode;
  tooltipPlacement?: PopperPlacementType;
  renderPointer?: (userColour?: string) => JSX.Element;
};

const UserIndicator = ({
  user,
  size = 'md',
  isUserInformationHoverPopperEnabled = false,
  userInformationHoverPopperContentOverride,
  tooltipPlacement = 'top',
  renderPointer,
}: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [showPopper, setShowPopper] = useState(false);

  const userInitials = useMemo(() => {
    const initialsArray: string[] = [];
    if (user.firstName?.length) initialsArray.push(user.firstName[0]);
    if (user.lastName?.length) initialsArray.push(user.lastName[0]);

    return initialsArray.join('').toUpperCase();
  }, [user]);

  const dimensionsPx = useMemo(() => {
    switch (size) {
      case 'xs':
        return 15;
      case 'sm':
        return 20;
      default:
        return 30;
    }
  }, [size]);

  const readableTextColour = useMemo(() => {
    if (!user.userColour) return undefined;

    return tinycolor
      .mostReadable(user.userColour, ['#263e59', 'white'], {
        includeFallbackColors: true,
      })
      .toHexString();
  }, [user.userColour]);

  const popperContent = useMemo(() => {
    if (userInformationHoverPopperContentOverride)
      return userInformationHoverPopperContentOverride;
    return (
      <div className="default-popper-content">
        <small>
          <strong>
            {user.firstName} {user.lastName}
          </strong>
        </small>
        <small>{user.emailAddress}</small>
      </div>
    );
  }, [user, userInformationHoverPopperContentOverride]);

  return (
    <>
      <div
        className={`user-indicator ${
          isUserInformationHoverPopperEnabled ? 'hoverable' : ''
        }`}
        style={{
          width: dimensionsPx,
          height: dimensionsPx,
        }}
        ref={ref}
        onMouseEnter={() => setShowPopper(true)}
        onMouseLeave={() => setShowPopper(false)}
      >
        {user?.imgSrc ? (
          <img
            src={user.imgSrc}
            className="rounded-full"
            style={{
              // tailwind does not support dynamic arbitrary values
              maxWidth: dimensionsPx,
              height: dimensionsPx,
              width: 'auto',
            }}
          />
        ) : (
          <div
            className="user-indicator-circle"
            style={{
              width: dimensionsPx,
              height: dimensionsPx,
              backgroundColor: user.userColour,
              fontSize: dimensionsPx / 2.5,
              color: readableTextColour,
            }}
          >
            <span>{userInitials}</span>
          </div>
        )}
        {isUserInformationHoverPopperEnabled && (
          <Popper
            open={showPopper && !!ref.current}
            anchorEl={ref.current}
            role={undefined}
            transition
            placement={tooltipPlacement}
            className={`user-indicator-popper`}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} timeout={350}>
                <Paper>
                  <div>{popperContent}</div>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </div>
      {renderPointer?.(user.userColour)}
    </>
  );
};

export default UserIndicator;
