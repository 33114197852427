import { ScopeData } from '@payaca/types/authTypes';
import {
  faFileInvoice,
  faUserFriends,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import ZapierLogo from '@/assets/images/oAuth/zapier.png';

enum Scopes {
  JOBS_READ = 'jobs:read',
  JOBS_WRITE = 'jobs:write',
  CUSTOMERS_READ = 'customers:read',
  CUSTOMERS_WRITE = 'customers:write',
  USERS_READ = 'users:read',
}

// Add scope details if oauth scopes expand
const ScopeInfo: { [key: string]: any } = {
  [Scopes.JOBS_READ]: {
    description: 'View relevant job information on your account',
    icon: faFileInvoice,
  },
  [Scopes.JOBS_WRITE]: {
    description: 'Edit relevant job information on your account',
    icon: faFileInvoice,
  },
  [Scopes.CUSTOMERS_READ]: {
    description: 'View relevant customer information on your account',
    icon: faUserFriends,
  },
  [Scopes.CUSTOMERS_WRITE]: {
    description: 'Edit relevant customer information on your account',
    icon: faUserFriends,
  },
  [Scopes.USERS_READ]: {
    description: 'View basic profile information on your account',
    icon: faUserCircle,
  },
};

const OAuthImage: any = {
  zapier: ZapierLogo,
  test: ZapierLogo,
};

const getScopeList = (scopeString: string | any): ScopeData[] => {
  // split scopes from query
  const splitScopes: string[] = scopeString ? scopeString.split(' ') : [];
  // get description and icon for scope
  const scopes: ScopeData[] = splitScopes.reduce(
    (acc: ScopeData[], scope: string) => {
      const scopeInfo = ScopeInfo[scope];
      if (scopeInfo && scopeInfo.description && scopeInfo.icon) {
        acc.push({
          scope,
          description: scopeInfo.description,
          icon: scopeInfo.icon,
        });
      }
      return acc;
    },
    []
  );
  return scopes;
};

const getAppLogo = (appName: string) => OAuthImage[appName.toLowerCase()];

export { getAppLogo, getScopeList };
