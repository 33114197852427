import '../../slateEditor/types';

import React, { FC, PropsWithChildren } from 'react';

export interface IProps {
  attributes: any;
}

const DefaultElement: FC<PropsWithChildren<IProps>> = (props) => {
  return (
    <p className="mb-5" {...props.attributes}>
      {props.children}
    </p>
  );
};

export default DefaultElement;
