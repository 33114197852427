import React, { FunctionComponent, useMemo } from 'react';

import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import './ListedAutomationsFilterControls.sass';
import { GetListedAutomationsRequestData } from '@payaca/types/automationTypes';
import ListedAutomationsFilterDropdowns from '@/ui/components/listedAutomationsFilterDropdowns/ListedAutomationsFilterDropdowns';

type Props = {
  getListedAutomationsRequestData?: GetListedAutomationsRequestData;
  onChange: (value: { [key: string]: any }) => void;
};

const ListedAutomationsFilterControls: FunctionComponent<Props> = ({
  getListedAutomationsRequestData,
  onChange,
}: Props): JSX.Element | null => {
  const renderedFilters = useMemo(() => {
    return (
      <ResponsiveViewWrapper className="flex-container filters-wrapper">
        <ListedAutomationsFilterDropdowns
          onChange={onChange}
          getListedAutomationsRequestData={getListedAutomationsRequestData}
        />
      </ResponsiveViewWrapper>
    );
  }, [getListedAutomationsRequestData, onChange]);

  return (
    <ResponsiveViewWrapper
      className={`listed-automations-filter-controls`}
      downBreakpointSm={850}
    >
      <div className="static-body-container flex-container flex-center">
        <div className="filters-container">{renderedFilters}</div>
      </div>
    </ResponsiveViewWrapper>
  );
};

export default ListedAutomationsFilterControls;
