import { FC, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router';
import Card from '@payaca/components/plCard/Card';
import useGetComplianceFormPrefillData from '../../../../api/queries/compliance/useGetComplianceFormPrefillData';
import useCreateConnectionApplication from '../../../../api/mutations/connectionApplication/useCreateConnectionApplication';
import { getInitialPrefillValues } from '../../../../helpers/compliance';
import Modal, { IProps as ModalProps } from '@payaca/components/plModal/Modal';
import Button from '@payaca/components/plButton/Button';

type InstallationType = { id: string; title: string; isDisabled?: boolean };
const installationTypes: InstallationType[] = [
  { id: 'HP', title: 'Heat pump' },
  { id: 'EVCP_AC', title: 'EV charger (AC)' },
  { id: 'EVCP_DC', title: 'EV charger (DC)' },
  { id: 'BATTERY', title: 'Battery' },
  { id: 'EVV2G', title: 'Vehicle to grid (V2G)' },
  { id: 'SOLAR_PV', title: 'Solar panel' },
];

type InstallationTypeCardProps = InstallationType & {
  onClick: () => void;
  isSelected: boolean;
  isDisabled?: boolean;
};
const InstallationTypeCard: FC<InstallationTypeCardProps> = (props) => {
  const { isDisabled, isSelected, title, onClick } = props;

  return (
    <Card
      onClick={!isDisabled ? onClick : undefined}
      className={`cursor h-[100px] w-[200px] justify-center${
        isSelected ? ' ring-2 ring-blue-600' : ''
      }${isDisabled ? ' cursor-not-allowed opacity-60' : ' cursor-pointer'}`}
    >
      <Card.Body>
        <span className="block font-medium">{title}</span>
        <span>{isDisabled && ' (Coming soon!)'}</span>
      </Card.Body>
    </Card>
  );
};

type Props = Pick<ModalProps, 'isOpen' | 'onClose'>;
const ConnectionApplicationChooseInstallationModal: FC<Props> = (props) => {
  const { isOpen, onClose } = props;
  const history = useHistory();

  const { dealId } = useParams<{
    dealId: string;
  }>();

  const [selectedInstallationTypes, setSelectedInstallationTypes] = useState<
    string[]
  >([]);

  const { data: prefillData } = useGetComplianceFormPrefillData(+dealId);

  useEffect(() => {
    if (!isOpen) {
      setSelectedInstallationTypes([]);
    }
  }, [isOpen]);

  const { mutateAsync: createConnectionApplicationMutation, isLoading } =
    useCreateConnectionApplication();

  const handleCreateConnectionApplication = async () => {
    const newConnectionApplication = await createConnectionApplicationMutation({
      dealId: dealId,
      data: getInitialPrefillValues(prefillData) || {},
      installationTypes: selectedInstallationTypes,
    });

    onClose?.();

    history.push(
      `/deals/${dealId}/compliance/connection-application/${newConnectionApplication.createConnectionApplication.id}`
    );
  };

  return (
    <Modal
      title="What are you installing?"
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      <Modal.Body>
        <div className="flex flex-row flex-wrap justify-between gap-4">
          {installationTypes.map((installationType) => (
            <InstallationTypeCard
              {...installationType}
              isSelected={selectedInstallationTypes?.includes(
                installationType.id
              )}
              onClick={() => {
                setSelectedInstallationTypes((prev) => {
                  const updatedInstallationTypes = [...prev];
                  if (prev) {
                    if (!prev.includes(installationType.id)) {
                      updatedInstallationTypes.push(installationType.id);
                    } else {
                      updatedInstallationTypes.splice(
                        updatedInstallationTypes.indexOf(installationType.id),
                        1
                      );
                    }
                  }
                  return updatedInstallationTypes;
                });
              }}
              key={installationType.id}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            onClick={handleCreateConnectionApplication}
            disabled={!selectedInstallationTypes.length}
            isProcessing={isLoading}
          >
            Get started
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default ConnectionApplicationChooseInstallationModal;
