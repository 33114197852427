import { useInfiniteQuery } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  GetScheduledEventsForCustomerInput,
  PaginationInput,
} from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import keyFactory from '@/api/queries/customers/keyFactory';

const GetCustomerEventsQuery = graphql(`
  query GetCustomerEvents(
    $customerId: Int!
    $pagination: PaginationInput!
    $input: GetScheduledEventsForCustomerInput
  ) {
    customer(id: $customerId) {
      events(input: $input, pagination: $pagination) {
        items {
          id
          name
          beginAt
          endAt
        }
        limit
        offset
        totalCount
      }
    }
  }
`);

const useGetCustomerEvents = (
  customerId?: string,
  input?: GetScheduledEventsForCustomerInput
) => {
  return useInfiniteQuery({
    enabled: !!customerId,
    queryKey: keyFactory.customerEvents(customerId!, input),
    queryFn: ({ pageParam }) => {
      return gqlClient.request(GetCustomerEventsQuery, {
        customerId: parseInt(customerId!),
        input,
        pagination: {
          offset: pageParam || PAGINATION_ARG_DEFAULTS.offset,
          limit: PAGINATION_ARG_DEFAULTS.limit,
        },
      });
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.customer.events) {
        return null;
      }

      const newOffset =
        lastPage.customer.events.offset + lastPage.customer.events.limit;

      if (lastPage.customer.events.totalCount <= newOffset) {
        return null;
      }

      return newOffset;
    },
  });
};

export default useGetCustomerEvents;
