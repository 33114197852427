import { Optional } from '../types';

import {
  ErrorLoadable,
  Loadable,
  LoadableMatchStates,
  LoadableState,
  LoadedLoadable,
  LoadingLoadable,
  UnloadedLoadable,
} from './types';

export function unloaded<T>(value?: Optional<T>): UnloadedLoadable<T> {
  return {
    state: LoadableState.UNLOADED,
    ...(arguments.length === 0 ? {} : { value }),
  };
}

export function loading<T>(value?: Optional<T>): LoadingLoadable<T> {
  return {
    state: LoadableState.LOADING,
    ...(arguments.length === 0 ? {} : { value }),
  };
}

export function loaded<T>(value: T): LoadedLoadable<T> {
  return {
    state: LoadableState.LOADED,
    value,
  };
}

export function error<T>(err: Error, value?: T): ErrorLoadable<T> {
  return {
    state: LoadableState.ERROR,
    error: err,
    ...(arguments.length === 1 ? {} : { value }),
  };
}

export function mapLoadable<T, U>(
  x: LoadedLoadable<T>,
  fn: (val: T) => U
): LoadedLoadable<U>;
export function mapLoadable<T, U>(
  x: Loadable<T>,
  fn: (val: Optional<T>) => U
): Loadable<U> {
  switch (x.state) {
    case LoadableState.UNLOADED:
      return unloaded(fn(x.value));
    case LoadableState.LOADING:
      return loading(fn(x.value));
    case LoadableState.LOADED:
      return loaded(fn(x.value));
    case LoadableState.ERROR:
      return error(x.error, fn(x.value));
  }
}

export function flatMapLoadable<T, U>(
  x: Loadable<T>,
  fn: (val: T | Optional<T>) => Loadable<U>
): Loadable<U> {
  return fn(x.value);
}

export function matchLoadable<T, U>(
  x: Loadable<T>,
  states: LoadableMatchStates<T, U>
): U {
  switch (x.state) {
    case LoadableState.UNLOADED:
    case LoadableState.LOADING:
      return states[x.state](x.value);
    case LoadableState.LOADED:
      return states[x.state](x.value);
    case LoadableState.ERROR:
      return states[x.state](x.error, x.value);
  }
}
