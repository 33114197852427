import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import {
  getDeal,
  getDocumentsByDealId,
  getJobContentByJobId,
  getJobPaymentsByDealId,
  getJobsByDealId,
  getScheduledEventsByDealId,
} from '@/utils/stateAccessors';

import { Job } from '@payaca/types/jobTypesV2';
import { JobActionType } from '@payaca/types/jobActionsTypes';

import { isInvoice } from '@payaca/helpers/jobStatusHelper';
import { getModal } from '@/helpers/modalHelper';

import { actions as appActions } from '@/api/app';
import { actions as jobActions } from '@/api/jobs';

import TitledContentPanel from '@payaca/components/titledContentPanel/TitledContentPanel';
import DealListedJobsTable from '../dealListedJobsTable/DealListedJobsTable';
import ResendJobModal from '../resendJobModal/ResendJobModal';
import RecordPaymentModal from '../recordPaymentModal/RecordPaymentModal';
import IconButton from '@payaca/components/button/IconButton';
import RestoreJobModal from '../restoreJobModal/RestoreJobModal';
import { canAddProposalToDeal } from '@payaca/helpers/dealHelper';
import './DealProposalsInvoices.sass';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { useSelector } from '@/api/state';

interface Props {
  dealId: number;
  onDealUpdateSuccess: (updatedJobIds?: number[]) => void;
}

export const DealProposalsInvoices: FC<Props> = ({
  dealId,
  onDealUpdateSuccess,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDuplicatingJob, setIsDuplicatingJob] = useState(false);
  const [isArchivingJob, setIsArchivingJob] = useState(false);
  const [isMarkingJobAccepted, setIsMarkingJobAccepted] = useState(false);
  const [isMarkingJobDeclined, setIsMarkingJobDeclined] = useState(false);
  const [isRaisingInvoice, setIsRaisingInvoice] = useState(false);
  const [resendJobId, setResendJobId] = useState<number>();
  const [recordPaymentJobId, setRecordPaymentJobId] = useState<number>();
  const [restoreJobId, setRestoreJobId] = useState<number>();

  const deal = useSelector((state) => {
    return getDeal(state, dealId);
  });
  const jobs = useSelector((state) => {
    return getJobsByDealId(state, dealId);
  });
  const isGettingJobsForDeal = useSelector((state) => {
    return state.jobsStore.isGettingJobsForDeal;
  });

  const recordPaymentJobContent = useSelector((state) => {
    if (!recordPaymentJobId) return;
    return getJobContentByJobId(state, recordPaymentJobId);
  });

  const customer = useSelector((state) => state.customer.currentCustomer);

  const jobPayments = useSelector((state) => {
    return getJobPaymentsByDealId(state, dealId);
  });

  const proposals = useMemo(() => {
    return jobs?.filter((x) => !isInvoice(x.status));
  }, [jobs, jobs?.length]);

  const invoiceableProposalId = useMemo(() => {
    return proposals.find(
      (x) => !x.archivedAt && !x.inactivatedAt && !isInvoice(x.status)
    )?.id;
  }, [proposals]);

  const invoices = useMemo(() => {
    return jobs?.filter((x) => isInvoice(x.status));
  }, [jobs, jobs?.length]);

  const markJobAccepted = useCallback(
    (jobId: number) => {
      setIsMarkingJobAccepted(true);
      dispatch(
        jobActions.acceptQuote(jobId, () => {
          onDealUpdateSuccess([jobId]);
          setIsMarkingJobAccepted(false);
        })
      );
    },
    [dispatch, onDealUpdateSuccess]
  );

  const markJobDeclined = useCallback(
    (jobId: number) => {
      setIsMarkingJobDeclined(true);
      dispatch(
        jobActions.declineJob(jobId, () => {
          onDealUpdateSuccess([jobId]);
          setIsMarkingJobDeclined(false);
        })
      );
    },
    [dispatch, onDealUpdateSuccess]
  );

  const duplicateJob = useCallback(
    (jobId: number) => {
      const job = jobs.find((x) => x.id === jobId);
      if (!job) return;

      setIsDuplicatingJob(true);
      dispatch(
        jobActions.duplicateJob(jobId, (error: any, response: any) => {
          if (!error) {
            history.push('/'); // this is needed so the page actually reloads - otherwise it sees that the route hasn't changed and doesn't do anything
            if (isInvoice(job.status)) {
              history.push(`/invoices/${response.newJobId}`);
            } else {
              history.push(`/quotes/${response.newJobId}`);
            }
          }
          setIsDuplicatingJob(false);
        })
      );
    },
    [dispatch, history, jobs]
  );

  const invoiceJob = useCallback(
    (jobId: number) => {
      setIsRaisingInvoice(true);
      dispatch(
        jobActions.convertJobToInvoice(jobId, (err: Error, resp: any) => {
          setIsRaisingInvoice(false);
          if (!err) {
            history.push({
              pathname: `/invoices/${resp.invoiceId}`,
            });
          }
        })
      );
    },
    [dispatch, history]
  );

  const archiveJob = useCallback(
    (jobId: number) => {
      dispatch(
        appActions.showModal(
          getModal('ARCHIVE_JOB', {
            primaryAction: () => {
              setIsArchivingJob(true);
              dispatch(
                jobActions.archiveJob(jobId, () => {
                  dispatch(appActions.hideModal());
                  setIsArchivingJob(false);
                  onDealUpdateSuccess(jobs.map((x) => x.id));
                })
              );
            },
            secondaryAction: () => dispatch(appActions.hideModal()),
            onClose: () => dispatch(appActions.hideModal()),
          })
        )
      );
    },
    [dispatch, history, onDealUpdateSuccess]
  );

  const goToSendJob = useCallback(
    (jobId: number) => {
      const job = jobs.find((x) => x.id === jobId);
      if (!job) return;

      const jobIsInvoice = isInvoice(job.status);

      history.push(
        `/${jobIsInvoice ? 'invoices' : 'quotes'}/${jobId}?view=send`
      );
    },
    [jobs, history]
  );

  const quickActionDefinitions = useMemo(() => {
    return {
      [JobActionType.MARK_AS_ACCEPTED]: {
        actionName: 'Mark as accepted',
        actionBehaviour: markJobAccepted,
        isActionProcessing: isMarkingJobAccepted,
      },
      [JobActionType.MARK_AS_DECLINED]: {
        actionName: 'Mark as declined',
        actionBehaviour: markJobDeclined,
        isActionProcessing: isMarkingJobDeclined,
      },
      [JobActionType.INVOICE]: {
        actionName: 'Invoice',
        actionBehaviour: invoiceJob,
        isActionProcessing: isRaisingInvoice,
      },
      [JobActionType.ARCHIVE]: {
        actionName: 'Archive',
        actionBehaviour: archiveJob,
        isActionProcessing: isArchivingJob,
      },
      [JobActionType.SEND]: {
        actionName: 'Send',
        actionBehaviour: goToSendJob,
      },
      [JobActionType.RESEND]: {
        actionName: 'Resend',
        actionBehaviour: setResendJobId,
      },
      [JobActionType.RECORD_DEPOSIT_PAYMENT]: {
        actionName: 'Record payment',
        actionBehaviour: setRecordPaymentJobId,
      },
      [JobActionType.RECORD_PAYMENT]: {
        actionName: 'Record payment',
        actionBehaviour: setRecordPaymentJobId,
      },
      [JobActionType.UNARCHIVE]: {
        actionName: 'Restore',
        actionBehaviour: setRestoreJobId,
      },
    };
  }, [
    duplicateJob,
    isDuplicatingJob,
    markJobAccepted,
    isMarkingJobAccepted,
    markJobDeclined,
    isMarkingJobDeclined,
    invoiceJob,
    isRaisingInvoice,
    archiveJob,
    isArchivingJob,
    goToSendJob,
  ]);

  const sectionTitle = useCallback(
    (
      title: string,
      canAdd: boolean,
      onAddNew: () => void,
      isEmpty: boolean
    ) => {
      return (
        <div
          className={`deal-overview-section-title${
            isEmpty && canAdd ? ' clickable' : ''
          }`}
          onClick={() => canAdd && onAddNew()}
        >
          {/* allow title clicking if empty content */}
          <h3 onClick={(e) => !isEmpty && e.stopPropagation()}>{title}</h3>
          <IconButton icon={faPlus} size="xs" isDisabled={!canAdd} />
        </div>
      );
    },
    []
  );

  const canAddProposal = useMemo(() => {
    return canAddProposalToDeal(deal) && !isGettingJobsForDeal && !jobs?.length;
  }, [deal, isGettingJobsForDeal, jobs?.length]);

  const canAddInvoice = useMemo(() => {
    return (
      !deal?.archivedAt &&
      !isGettingJobsForDeal &&
      (!!(invoiceableProposalId && !invoices?.length) || !jobs?.length)
    );
  }, [
    deal,
    invoiceableProposalId,
    invoices?.length,
    isGettingJobsForDeal,
    jobs?.length,
  ]);

  return (
    <ResponsiveViewWrapper
      downBreakpointSm={900}
      downBreakpointXs={600}
      className="deal-proposals-invoices v1"
    >
      <div className="deal-proposals-invoices-inner">
        {/* Proposals section */}
        <TitledContentPanel
          title={sectionTitle(
            'Proposals',
            canAddProposal,
            () => history.push(`/quotes/new?dealId=${dealId}`),
            !proposals?.length
          )}
          className={!proposals?.length ? 'empty-content' : ''}
        >
          {proposals?.length ? (
            <DealListedJobsTable
              dealId={dealId}
              jobs={proposals}
              quickActionDefinitions={quickActionDefinitions}
            />
          ) : (
            <></>
          )}
        </TitledContentPanel>
        {/* Invoices section */}
        <TitledContentPanel
          title={sectionTitle(
            'Invoices',
            canAddInvoice,
            () => {
              if (!jobs?.length) {
                history.push(`/invoices/new?dealId=${dealId}`);
              } else if (!isRaisingInvoice && invoiceableProposalId) {
                invoiceJob(invoiceableProposalId);
              }
            },
            !invoices?.length
          )}
          className={!invoices?.length ? 'empty-content' : ''}
        >
          {invoices?.length ? (
            <DealListedJobsTable
              dealId={dealId}
              jobs={invoices}
              quickActionDefinitions={quickActionDefinitions}
            />
          ) : (
            <></>
          )}
        </TitledContentPanel>

        {resendJobId && (
          <ResendJobModal
            isOpen={!!resendJobId}
            onClose={() => {
              setResendJobId(undefined);
              // refresh
              onDealUpdateSuccess([resendJobId]);
            }}
            jobId={resendJobId}
          />
        )}
        {recordPaymentJobId && !!recordPaymentJobContent && (
          <RecordPaymentModal
            dealId={dealId}
            paymentTarget={{
              jobId: recordPaymentJobId,
            }}
            isOpen={!!recordPaymentJobId}
            onClose={() => setRecordPaymentJobId(undefined)}
            recordPaymentCallback={() =>
              onDealUpdateSuccess([recordPaymentJobId])
            }
            confirmPaymentCallback={() =>
              onDealUpdateSuccess([recordPaymentJobId])
            }
          />
        )}

        {restoreJobId && (
          <RestoreJobModal
            isOpen={!!restoreJobId}
            jobId={restoreJobId}
            onClose={() => setRestoreJobId(undefined)}
            onRestoreJobCallback={() => {
              setRestoreJobId(undefined);
              onDealUpdateSuccess(jobs.map((x) => x.id));
            }}
          />
        )}
      </div>
    </ResponsiveViewWrapper>
  );
};
