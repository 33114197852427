import useGetMyBusinessHours from '@/api/queries/me/useGetMyBusinessHours';
import PlDatePickerPopover from '@payaca/components/plDatePicker/DatePickerPopover';
import { ComponentProps, FC } from 'react';

export const DatePickerPopover: FC<
  ComponentProps<typeof PlDatePickerPopover>
> = (props) => {
  const { data } = useGetMyBusinessHours();
  return <PlDatePickerPopover {...props} businessHours={data} />;
};
