import Button from '@payaca/components/plButton/Button';
import Modal, { IProps as ModalProps } from '@payaca/components/plModal/Modal';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as lineItemActions from '@payaca/store/lineItems/lineItemsActions';
import { singularPlural } from '@payaca/utilities/stringUtilities';
import { EBtnColour } from '@payaca/components/plButton/useButtonClassName';
import { ListedLineItem } from '../../components/listedLineItemsTable/ListedLineItemsTable';

type Props = {
  onDeleteCallback?: () => void;
  selectedLineItems: ListedLineItem[];
} & Omit<ModalProps, 'title'>;
const ConfirmDeleteLineItemsModal = (props: Props) => {
  const { isOpen, onClose, onDeleteCallback, selectedLineItems } = props;
  const dispatch = useDispatch();

  const [isDeletingLineItems, setIsDeletingLineItems] =
    useState<boolean>(false);

  const onBulkDeleteItems = useCallback(
    (lineItems: ListedLineItem[]) => {
      setIsDeletingLineItems(true);
      const lineItemIds = lineItems.map((li) => +li.id);
      dispatch(
        lineItemActions.requestDeleteLineItems(lineItemIds, () => {
          onDeleteCallback?.();
          setIsDeletingLineItems(false);
        })
      );
    },
    [onDeleteCallback]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Delete ${selectedLineItems.length} Items you sell`}
    >
      <Modal.Body>
        <p>
          Are you sure you want to delete{' '}
          {singularPlural(selectedLineItems.length, 'Item', 'Items')}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            onClick={() => {
              if (!isDeletingLineItems) {
                onBulkDeleteItems(selectedLineItems);
              }
            }}
            isProcessing={isDeletingLineItems}
            colour={EBtnColour.Red}
          >
            Delete
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteLineItemsModal;
