import React, { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import SetupBusinessForm from '@/ui/components/setupBusinessForm/SetupBusinessForm';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';

import * as authActions from '@payaca/store/auth/authActions';
import { actions as usersActions } from '@/api/users';
import * as accountActions from '@payaca/store/account/accountActions';
import * as userRolesActions from '@payaca/store/userRoles/userRolesActions';
import { useSelector } from '@/api/state';
import useLogout from '@/hooks/auth/useLogout';

enum ProcessingAccountType {
  CREATING = 'creating',
  UPDATING = 'updating',
}

const SetupBusinessPage: FC = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const logout = useLogout();
  const [
    createUpdateBusinessErrorMessage,
    setCreateUpdateBusinessErrorMessage,
  ] = useState<string | null>(null);
  const [isProcessingAccount, setIsProcessingAccount] = useState<
    ProcessingAccountType | false
  >(false);

  const accountId = useSelector(
    (state: any) => state.users.myProfile?.accounts?.[0]?.id
  );

  const onCreateUpdateAccountSuccess = useCallback(() => {
    // fetch user roles
    dispatch(userRolesActions.requestGetUserRoles());
    dispatch(accountActions.requestGetAccountAccessInformation());
    dispatch(
      usersActions.getProfile(() => {
        history.push('/dashboard');
      })
    );
  }, [dispatch, history]);

  const createAccount = useCallback(
    (companyDetails: any) => {
      setIsProcessingAccount(ProcessingAccountType.CREATING);
      setCreateUpdateBusinessErrorMessage(null);
      // create business
      dispatch(
        usersActions.createBusinessAccount(
          { ...companyDetails, name: companyDetails.legalBusinessName },
          (createAccountError: Error) => {
            if (!createAccountError) {
              onCreateUpdateAccountSuccess();
            } else {
              setIsProcessingAccount(false);
              setCreateUpdateBusinessErrorMessage(
                'Sorry, there was an error setting up your account, please try again.'
              );
            }
          }
        )
      );
    },
    [dispatch, onCreateUpdateAccountSuccess]
  );

  const updateAccount = useCallback(
    (companyDetails: { [key: string]: any }) => {
      setIsProcessingAccount(ProcessingAccountType.UPDATING);
      setCreateUpdateBusinessErrorMessage(null);
      // update business
      dispatch(
        authActions.requestSignUpAccountUpdateV2(
          {
            legalBusinessName: companyDetails.legalBusinessName,
            numberOfEmployees: companyDetails.numberOfEmployees,
            preferredTimezone: companyDetails.preferredTimezone,
            preferredLocale: companyDetails.preferredLocale,
          },
          (error) => {
            if (error) {
              setIsProcessingAccount(false);
              setCreateUpdateBusinessErrorMessage(
                'Sorry, there was an error updating your account, please try again.'
              );
            } else {
              onCreateUpdateAccountSuccess();
            }
          }
        )
      );
    },
    [dispatch, onCreateUpdateAccountSuccess]
  );

  return (
    <UnauthenticatedPageWrapper
      className="setup-business-page"
      titleContent={
        <>
          <h1>{'Impress customers with your brand'}</h1>
          <p>{'Customise your quotes, invoices and other documents'}</p>
        </>
      }
      footerContent={
        <div className="flex-container">
          <div className="flex-grow"></div>
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={logout}
            colourVariant={ButtonColourVariant.WHITE}
          >
            Log out
          </Button>
        </div>
      }
    >
      <SetupBusinessForm
        onSubmitBusiness={(companyInfo: { [key: string]: any }) => {
          if (accountId) {
            updateAccount(companyInfo);
          } else {
            createAccount(companyInfo);
          }
        }}
        isCreatingUpdatingAccount={!!isProcessingAccount}
        createUpdateBusinessError={createUpdateBusinessErrorMessage}
      />
    </UnauthenticatedPageWrapper>
  );
};

export default SetupBusinessPage;
