import { Reducer } from 'redux';

import { ActionType as MaterialsListActionType } from '../materialsList/materialsListTypes';
import { mergeFetchedEntities } from '../utils';

import { ActionType, State } from './suppliersTypes';

export const initialState: State = {
  isPersistingSupplier: false,

  isArchivingSuppliers: false,
  areSuppliersArchivedSuccessfully: undefined,

  suppliers: {},
};

const suppliersReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_PERSIST_SUPPLIER:
      return {
        ...state,
        isPersistingSupplier: true,
      };
    case ActionType.PERSIST_SUPPLIER_FAILURE:
    case ActionType.PERSIST_SUPPLIER_SUCCESS:
      return {
        ...state,
        isPersistingSupplier: false,
      };

    case ActionType.REQUEST_GET_SUPPLIER: {
      const fetchedEntity =
        state.suppliers && state.suppliers[action.payload.supplierId];

      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          [action.payload.supplierId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }
    case ActionType.GET_SUPPLIER_FAILURE: {
      const fetchedEntity =
        state.suppliers && state.suppliers[action.payload.supplierId];

      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          [action.payload.supplierId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }
    case ActionType.GET_SUPPLIER_SUCCESS: {
      const fetchedEntity =
        state.suppliers && state.suppliers[action.payload.supplierId];

      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          [action.payload.supplierId]: {
            ...fetchedEntity,
            entity: action.payload.supplier,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.REQUEST_ARCHIVE_SUPPLIERS:
      return {
        ...state,
        isArchivingSuppliers: true,
        areSuppliersArchivedSuccessfully: undefined,
      };
    case ActionType.ARCHIVE_SUPPLIERS_SUCCESS:
      return {
        ...state,
        isArchivingSuppliers: false,
        areSuppliersArchivedSuccessfully: true,
      };
    case ActionType.ARCHIVE_SUPPLIERS_FAILURE:
      return {
        ...state,
        isArchivingSuppliers: false,
        areSuppliersArchivedSuccessfully: false,
      };

    case ActionType.CLEAR_ARCHIVE_SUPPLIERS:
      return {
        ...state,
        isArchivingSuppliers: false,
        areSuppliersArchivedSuccessfully: undefined,
      };

    case ActionType.CLEAR_SUPPLIERS:
      return {
        ...state,
        suppliers: {},
      };

    case MaterialsListActionType.GET_MATERIALS_LIST_MATERIAL_WITH_RELATED_ENTITIES_SUCCESS:
    case MaterialsListActionType.GET_MATERIALS_LIST_WITH_RELATED_ENTITIES_SUCCESS:
      return {
        ...state,
        suppliers: mergeFetchedEntities({
          cache: state.suppliers,
          values: action.payload.suppliers,
        }),
      };

    case ActionType.GET_SUPPLIERS_FOR_MATERIAL_SUCCESS:
      return {
        ...state,
        suppliers: mergeFetchedEntities({
          cache: state.suppliers,
          values: action.payload,
        }),
      };

    default:
      return state;
  }
};

export default suppliersReducer;
