import React, {
  FC,
  isValidElement,
  PropsWithChildren,
  ReactElement,
} from 'react';
import clsx from 'clsx';
import { Tab } from '@headlessui/react';

export interface IPanelProps {
  label: string;
  render: (selected: boolean) => ReactElement;
}

const Panel: FC<IPanelProps> = (props) => <></>;

type Variant = 'default' | 'segment' | 'ruled';

export interface IProps {
  className?: string;
  selectedIndex?: number;
  defaultIndex?: number;
  onChange?: (index: number) => void;
  fullWidth?: boolean;
  variant?: Variant;
}

const Tabs: FC<PropsWithChildren<IProps>> = (props) => {
  const {
    className,
    fullWidth = false,
    selectedIndex,
    defaultIndex,
    onChange,
    variant = 'default',
    children,
  } = props;

  const tabPanels = React.Children.toArray(children).reduce<IPanelProps[]>(
    (acc, child) => {
      if (
        !isValidElement(child) ||
        typeof child.props.label === 'undefined' ||
        typeof child.props.render === 'undefined'
      ) {
        return acc;
      }

      acc.push(child.props);

      return acc;
    },
    []
  );

  return (
    <Tab.Group
      defaultIndex={defaultIndex}
      selectedIndex={selectedIndex}
      onChange={onChange}
    >
      <PlTabList variant={variant} className={className}>
        {tabPanels.map((column) => {
          return (
            <PlTab key={column.label} fullWidth={fullWidth} variant={variant}>
              {column.label}
            </PlTab>
          );
        })}
      </PlTabList>
      <PlTabPanels>
        {tabPanels.map((column) => {
          return (
            <Tab.Panel key={column.label}>
              {({ selected }) => column.render(selected)}
            </Tab.Panel>
          );
        })}
      </PlTabPanels>
    </Tab.Group>
  );
};

export const PlTabPanels: FC<PropsWithChildren> = ({ children }) => (
  <Tab.Panels className="mt-4 w-full">{children}</Tab.Panels>
);

export const PlTab: FC<
  PropsWithChildren<{ fullWidth: boolean; variant: Variant }>
> = ({ fullWidth, variant = 'default', children }) => (
  <Tab
    className={clsx(
      variant === 'default' &&
        'ui-selected:border-blue-600 ui-selected:font-semibold inline-flex cursor-pointer items-center justify-center gap-x-2 whitespace-nowrap border-b-2 border-transparent bg-transparent px-2 py-2.5 text-blue-900 focus:outline-none disabled:pointer-events-none disabled:opacity-50',
      variant === 'segment' &&
        'ui-selected:bg-white ui-selected:shadow-sm inline-flex cursor-pointer items-center justify-center gap-x-2 rounded-lg bg-transparent px-5 py-2.5 font-medium focus:outline-none disabled:pointer-events-none disabled:opacity-50',
      fullWidth && 'flex-[1_1_0px]'
    )}
  >
    {children}
  </Tab>
);

export const PlTabList: FC<
  PropsWithChildren<{ variant: Variant; className?: string }>
> = ({ variant = 'default', className, children }) => (
  <Tab.List
    className={clsx(
      'prose',
      className,
      variant === 'default' && 'prose flex gap-x-1',
      variant === 'ruled' &&
        'prose flex gap-x-1 border-b border-gray-200 dark:border-neutral-700',
      variant === 'segment' &&
        'inline-flex rounded-xl bg-gray-100 p-1.5 transition hover:bg-gray-200'
    )}
  >
    {children}
  </Tab.List>
);

export default Object.assign(Tabs, {
  Panel,
});
