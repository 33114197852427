import UntitledIcon, { TIconName } from '@payaca/untitled-icons';
import clsx from 'clsx';
import { FC, PropsWithChildren, useMemo } from 'react';

export enum EAlertColour {
  SOFT_TEAL = 'soft-teal',
  SOFT_RED = 'soft-red',
  SOFT_YELLOW = 'soft-yellow',
  SOFT_BLUE = 'soft-blue',
}

export interface IProps {
  colour?: EAlertColour;
  className?: string;
  iconName?: TIconName;
}

const Alert: FC<PropsWithChildren<IProps>> = (props) => {
  const { colour = EAlertColour.SOFT_TEAL, className, children } = props;

  const colourClasses = useMemo(() => {
    switch (colour) {
      case EAlertColour.SOFT_BLUE:
        return 'bg-blue-100 border border-blue-200 text-blue-800 dark:bg-blue-800/10 dark:border-blue-900 dark:text-blue-500';
      case EAlertColour.SOFT_TEAL:
        return 'bg-teal-100 border border-teal-200 text-teal-800 dark:bg-teal-800/10 dark:border-teal-900 dark:text-teal-500';
      case EAlertColour.SOFT_RED:
        return 'bg-red-100 border border-red-200 text-red-800 dark:bg-red-800/10 dark:border-red-900 dark:text-red-500';
      case EAlertColour.SOFT_YELLOW:
        return 'bg-yellow-100 border border-yellow-200 text-yellow-800 dark:bg-yellow-800/10 dark:border-yellow-900 dark:text-yellow-500';
    }
  }, [colour]);

  return (
    <div
      className={clsx(
        'prose rounded-lg p-4 text-base',
        colourClasses,
        className
      )}
      role="alert"
    >
      <div className="flex flex-row items-start">
        {props.iconName && (
          <UntitledIcon
            name={props.iconName}
            className="mr-4 h-6 w-6 shrink-0"
          />
        )}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Alert;
