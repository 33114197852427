import React, { useState } from 'react';

import { PublicEntityTemplate } from '@payaca/types/entity-templates';
import {
  PublicHydratedServicePlanCommitment,
  PublicServicePlanPrice,
  PublicServicePlanSubscription,
} from '@payaca/types/service-plans';

import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import CurrencyField from '@payaca/components/currencyField/CurrencyField';
import EditableElementControl from '@payaca/components/editableElementControl/EditableElementControl';

import { Nullish } from '@payaca/utilities/types';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import './ServicePlanPricesControl.sass';
import {
  CreateServicePlanPriceInput,
  UpdateServicePlanPriceInput,
} from '@payaca/store/servicePlans/servicePlansTypes';
import SalesTaxSettingsField from '../salesTaxSettingsField/SalesTaxSettingsField';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { getAccountRegionFromCurrencyCode } from '@payaca/helpers/internationalHelper';
import { useAccount } from '../../../utils/storeHooks';

export type ControllableServicePlanCommitment<
  TEntityType extends 'task' | 'scheduledEvent'
> = {
  publicId?: Nullish<PublicHydratedServicePlanCommitment['publicId']>;
  name?: Nullish<PublicHydratedServicePlanCommitment['name']>;
  description?: Nullish<PublicHydratedServicePlanCommitment['description']>;
  scalesWithQuantity?: Nullish<
    PublicHydratedServicePlanCommitment['scalesWithQuantity']
  >;
  entityTemplate: {
    publicId?: Nullish<PublicEntityTemplate<TEntityType>['publicId']>;
    entityType: PublicEntityTemplate<TEntityType>['entityType'];
    data: PublicEntityTemplate<TEntityType>['data'];
  };
};

type Props = {
  prices: Array<PublicServicePlanPrice>;
  subscriptions: Array<PublicServicePlanSubscription>;
  onChangePrice: (price: UpdateServicePlanPriceInput) => Promise<void>;
  onDeletePrice: (
    pricePublicId: PublicServicePlanPrice['publicId']
  ) => Promise<void>;
  onAddPrice: (
    price: Omit<CreateServicePlanPriceInput, 'servicePlanPublicId'>
  ) => Promise<void>;
};

const ServicePlanPricesControl = ({
  prices,
  subscriptions,
  onChangePrice,
  onDeletePrice,
  onAddPrice,
}: Props) => {
  const hasMonthlyPrice = prices.some((price) => {
    return (
      price.billingInterval === 'month' && price.billingIntervalCount === 1
    );
  });
  const hasYearlyPrice = prices.some((price) => {
    return price.billingInterval === 'year' && price.billingIntervalCount === 1;
  });

  return (
    <div className="service-plan-prices-control">
      {!!prices?.length &&
        prices.map((price, index) => (
          <EditPrice
            price={price}
            subscriptions={subscriptions}
            onChangePrice={onChangePrice}
            onDeletePrice={onDeletePrice}
            key={`${price.publicId}-${index}`}
          />
        ))}
      {!hasMonthlyPrice && (
        <CreatePrice
          billingInterval="month"
          onAddPrice={onAddPrice}
          key="create-monthly-price"
        />
      )}
      {!hasYearlyPrice && (
        <CreatePrice
          billingInterval="year"
          onAddPrice={onAddPrice}
          key="create-yearly-price"
        />
      )}
    </div>
  );
};

const EditPrice = ({
  price,
  subscriptions,
  onChangePrice,
  onDeletePrice,
}: {
  price: PublicServicePlanPrice;
} & Pick<Props, 'subscriptions' | 'onChangePrice' | 'onDeletePrice'>) => {
  const hasAnySubscribers = subscriptions.some(
    (x) => x.servicePlanPricePublicId === price.publicId
  );

  const activeSubscribersCount = subscriptions.filter(
    (x) =>
      x.servicePlanPricePublicId === price.publicId && x.status === 'active'
  ).length;

  const billingIntervalString = `every ${
    price.billingIntervalCount && price.billingIntervalCount > 1
      ? `${price.billingIntervalCount} ${price.billingInterval}s`
      : price.billingInterval
  }`;
  const [basicPrice, setBasicPrice] = useState(price.basicPrice);
  const [taxRateId, setTaxRateId] = useState(price.taxRateId);
  const [priceIncludesTax, setPriceIncludesTax] = useState(
    price.priceIncludesTax
  );
  const [hasBeenDeleted, setHasBeenDeleted] = useState(false);

  if (hasBeenDeleted) {
    return null;
  }

  if (!hasAnySubscribers) {
    return (
      <EditableElementControl
        canDelete
        addText={''}
        value={basicPrice?.toString()}
        onSave={() => {
          return onChangePrice({
            publicId: price.publicId,
            basicPrice,
            priceIncludesTax,
            billingInterval: price.billingInterval,
            billingIntervalCount: price.billingIntervalCount,
            name: price.name,
            description: price.description,
            taxRateId,
          }).catch(() => {
            setBasicPrice(price.basicPrice);
            setTaxRateId(price.taxRateId);
            setPriceIncludesTax(price.priceIncludesTax);
          });
        }}
        onDelete={() => {
          setHasBeenDeleted(true);
          onDeletePrice(price.publicId).catch(() => setHasBeenDeleted(false));
        }}
        editTemplate={
          <span className="flex-container flex-center">
            <CurrencyField
              name="basicPrice"
              value={basicPrice}
              onChange={(value) => setBasicPrice(value.basicPrice)}
            />
            <SalesTaxSettingsField
              excludeReverseChargeOptions={true}
              styleVariant={InputStyleVariant.OUTSIZE}
              taxRateId={taxRateId}
              onChange={(value: { [key: string]: any }) => {
                setTaxRateId(value.taxRateId);
              }}
              hideLabel={true}
            />
            <span>{billingIntervalString}</span>
            <CheckboxField
              name="priceIncludesTax"
              value={priceIncludesTax}
              onChange={(value) => setPriceIncludesTax(value.priceIncludesTax)}
              label="Price includes tax"
            />
          </span>
        }
        renderValue={(value) => (
          <span>
            <strong>
              {currencyPrice(
                basicPrice,
                price.currencyCode
                  ? getAccountRegionFromCurrencyCode(price.currencyCode)
                  : undefined
              )}
            </strong>
            {` (${price.priceIncludesTax ? 'inc' : 'ex'} tax) `}
            {billingIntervalString}
          </span>
        )}
      />
    );
  } else {
    return (
      <span>
        <strong>
          {currencyPrice(
            basicPrice,
            price.currencyCode
              ? getAccountRegionFromCurrencyCode(price.currencyCode)
              : undefined
          )}
        </strong>
        {` (${price.priceIncludesTax ? 'inc' : 'ex'} tax) `}
        {billingIntervalString}{' '}
        <small>
          <em>
            ({activeSubscribersCount} active subscriber
            {activeSubscribersCount > 1 ? 's' : ''})
          </em>
        </small>
      </span>
    );
  }
};

const CreatePrice = ({
  billingInterval,
  onAddPrice,
}: {
  billingInterval: 'month' | 'year';
} & Pick<Props, 'onAddPrice'>) => {
  const account = useAccount();

  const [basicPrice, setBasicPrice] = useState<number | undefined>(undefined);
  const [priceIncludesTax, setPriceIncludesTax] = useState(false);
  const [taxRateId, setTaxRateId] = useState<number | undefined>(undefined);
  return (
    <EditableElementControl
      addText={`Add a ${billingInterval}ly price`}
      value={basicPrice?.toString()}
      isSaveDisabled={!!(basicPrice || basicPrice === 0) && !taxRateId}
      onSave={() => {
        return onAddPrice({
          basicPrice: basicPrice || 0,
          priceIncludesTax,
          billingInterval,
          billingIntervalCount: 1,
          name: `${billingInterval}ly price`,
          taxRateId: taxRateId || 0,
        });
      }}
      editTemplate={
        <span className="flex-container flex-center">
          <CurrencyField
            name="basicPrice"
            value={basicPrice}
            onChange={(value) => setBasicPrice(value.basicPrice)}
          />
          <SalesTaxSettingsField
            excludeReverseChargeOptions={true}
            styleVariant={InputStyleVariant.OUTSIZE}
            taxRateId={taxRateId}
            onChange={(value: { [key: string]: any }) => {
              setTaxRateId(value.taxRateId);
            }}
            hideLabel={true}
          />
          <span>every {billingInterval}</span>
          <CheckboxField
            name="priceIncludesTax"
            value={priceIncludesTax}
            onChange={(value) => setPriceIncludesTax(value.priceIncludesTax)}
            label="Price includes tax"
          />
        </span>
      }
      renderValue={(value) => (
        <span>
          <strong>{currencyPrice(basicPrice || 0, account.region)}</strong> (ex
          tax) every {billingInterval}
        </span>
      )}
    />
  );
};

export default ServicePlanPricesControl;
