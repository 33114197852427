import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Filter2 } from 'react-iconly';
import TimesIcon from '@payaca/components/svgAssets/TimesIcon';

import Button from '@payaca/components/button/Button';
import { ButtonColourVariant } from '@payaca/components/button/enums';
import ListedAutomationsFilterDropdownContent from './ListedAutomationsFilterDropdownsContent';

import './ListedAutomationsFilterDropdowns.sass';

type Props = {
  getListedAutomationsRequestData: any;
  onChange: (change: any) => void;
};
const ListedAutomationsFilterDropdowns: FC<Props> = ({
  getListedAutomationsRequestData,
  onChange,
}: Props) => {
  const [expandFilters, setExpandFilters] = useState(false);

  return (
    <>
      <Button
        className="listed-automations-filter-toggle"
        colourVariant={ButtonColourVariant.GREY}
        onClick={() => setExpandFilters(!expandFilters)}
      >
        {expandFilters ? (
          <>
            <FontAwesomeIcon
              icon={faChevronRight}
              className="filter-collapse-icon"
            />
            <TimesIcon className="filter-close-icon" />
          </>
        ) : (
          <Filter2 />
        )}
      </Button>
      {expandFilters && (
        <ListedAutomationsFilterDropdownContent
          expandFilters={expandFilters}
          getListedAutomationsRequestData={getListedAutomationsRequestData}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default ListedAutomationsFilterDropdowns;
