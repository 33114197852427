export enum LineItemsPermissions {
  ADD_LINE_ITEM_GROUP = 'lineItems.addLineItemGroup',
  UPDATE_LINE_ITEM_GROUP = 'lineItems.updateLineItemGroup',
  GET_LINE_ITEM_GROUPS = 'lineItems.getLineItemGroups',
  GET_COLLECTION_MANAGEMENT_VIEW = 'lineItems.getCollectionManagementView',

  ADD_LINE_ITEM = 'lineItems.addLineItem',
  UPDATE_LINE_ITEM = 'lineItems.updateLineItem',
  UPDATE_LINE_ITEM_MARKUP_PERCENTAGE = 'lineItems.updateLineItemMarkupPercentage',
  UPDATE_LINE_ITEM_MARKUP_AMOUNT = 'lineItems.updateLineItemMarkupAmount',
  GET_LINE_ITEMS = 'lineItems.getLineItems',
  DELETE_LINE_ITEM = 'lineItems.deleteLineItem',

  BULK_IMPORT = 'lineItems.bulkImport',
}
