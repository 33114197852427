import { delay, take, put, all, race, call } from 'redux-saga/effects';
import qs from 'qs';
import { IntercomAPI } from 'react-intercom';

import * as authActions from '@payaca/store/auth/authActions';

import * as api from '../../services/api';

import { ActionType } from './appTypes';
import { clearApp } from './appActions';

import { clearLocalStorage } from '@/helpers/localStorageKeyHelper';

const TOKEN_KEY = 'payacav1';
const IGNORE_PATHS = ['/login', '/reset'];

export const actions = {
  start: (callback) => ({ type: ActionType.START, callback }),
  storePathname: (pathname) => ({ type: ActionType.STORE_PATHNAME, pathname }),
  storePreviousPathname: (pathname) => ({
    type: ActionType.STORE_PREVIOUS_PATHNAME,
    pathname,
  }),
  loaded: () => ({ type: ActionType.LOADED }),
  toggleShowNavbar: () => ({ type: ActionType.TOGGLE_SHOW_NAVBAR }),
  hideNavbar: () => ({ type: ActionType.HIDE_NAVBAR }),
  sendPasswordResetEmail: (emailAddress, callback) => ({
    type: ActionType.SEND_PASSWORD_RESET_EMAIL,
    emailAddress,
    callback,
  }),
  validResetToken: (token, callback) => ({
    type: ActionType.VALID_RESET_TOKEN,
    token,
    callback,
  }),
  resetPassword: (token, password, callback) => ({
    type: ActionType.RESET_PASSWORD,
    token,
    password,
    callback,
  }),
  showBanner: (payload) => ({ type: ActionType.SHOW_BANNER, payload }),
  hideBanner: () => ({ type: ActionType.HIDE_BANNER }),
  showModal: (payload) => ({ type: ActionType.SHOW_MODAL, payload }),
  hideModal: () => ({ type: ActionType.HIDE_MODAL }),
  dismissMobileMode: () => ({ type: ActionType.DISMISS_MOBILE_MODE }),
  validUserInviteToken: (token, callback) => ({
    type: ActionType.VALID_USER_INVITE_TOKEN,
    token,
    callback,
  }),
  registerInvitedUser: (token, payload, callback) => ({
    type: ActionType.REGISTER_INVITED_USER,
    token,
    payload,
    callback,
  }),
  requestGetMaintenance: () => ({
    type: ActionType.REQUEST_GET_MAINTENANCE,
  }),
  getMaintenanceSuccess: (payload) => ({
    type: ActionType.GET_MAINTENANCE_SUCCESS,
    payload,
  }),
  getMaintenanceFailure: (maintenanceError) => ({
    type: ActionType.GET_MAINTENANCE_FAILURE,
    maintenanceError,
  }),
  hideMaintenanceBanner: (hideMaintenanceBanner) => ({
    type: ActionType.HIDE_MAINTENANCE_BANNER,
    hideMaintenanceBanner,
  }),
  setLastReadFutureMaintenaceMessageDownTimeAt: (downTimeAt) => ({
    type: ActionType.SET_LAST_READ_FUTURE_MAINTENACE_MESSAGE_DOWN_TIME_AT,
    downTimeAt,
  }),
  showNavigationSidebar: () => ({
    type: ActionType.SHOW_NAVIGATION_SIDEBAR,
  }),
  setNavigationSidebarAutoExpandedAfterDeploy: () => ({
    type: ActionType.SET_NAVIGATION_SIDEBAR_AUTO_EXPANDED_AFTER_DEPLOY,
  }),
  hideNavigationSidebar: () => ({
    type: ActionType.HIDE_NAVIGATION_SIDEBAR,
  }),
  expandNavigationSidebar: () => ({
    type: ActionType.EXPAND_NAVIGATION_SIDEBAR,
  }),
  collapseNavigationSidebar: () => ({
    type: ActionType.COLLAPSE_NAVIGATION_SIDEBAR,
  }),
};

export const selectors = {
  isLoaded: (state) => state.loaded === true,
  isLoggedIn: (state) => !!localStorage.getItem(TOKEN_KEY),
  getAuthToken: (state) => localStorage.getItem(TOKEN_KEY),
};

const watchAppStart = function* watchAppStart() {
  while (true) {
    const { callback } = yield take(ActionType.START);

    try {
      const token = localStorage.getItem(TOKEN_KEY);

      const query = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });

      let oneTimeLoginToken =
        query.oneTimeLoginToken ||
        (window.location.pathname === '/enableCustomerPayments' && query.state);

      if (oneTimeLoginToken) {
        yield put(authActions.requestLoginWithToken(oneTimeLoginToken));
        yield take('auth.storeTokensSuccess');
      } else if (!token) {
        const ignorePath = IGNORE_PATHS.find((path) =>
          window.location.pathname.includes(path)
        );
        if (!token && !ignorePath) {
          yield put(actions.storePathname(window.location.pathname));
        }
      }
    } catch (e) {
      console.log('Remove token', e);
      localStorage.removeItem(TOKEN_KEY);
    } finally {
      yield put(actions.loaded());
    }
  }
};

const watchSendPasswordResetEmail = function* watchSendPasswordResetEmail() {
  while (true) {
    const { emailAddress, callback } = yield take(
      ActionType.SEND_PASSWORD_RESET_EMAIL
    );

    try {
      const { response, timeout } = yield race({
        response: call(api.sendPasswordResetEmail, emailAddress),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Send password reset email request timed out.'
        );
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      console.log(e);

      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message.errors);
        } else {
          callback(e.message.errors || e.message);
        }
      }
    }
  }
};

const watchValidResetToken = function* watchValidResetToken() {
  while (true) {
    const { token, callback } = yield take(ActionType.VALID_RESET_TOKEN);

    try {
      const { response, timeout } = yield race({
        response: call(api.validResetToken, token),
        timeout: delay(5000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Check valid reset password token timed out.'
        );
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message);
        } else {
          callback(e.message);
        }
      }
    }
  }
};

const watchLoginSuccess = function* () {
  while (true) {
    yield take('auth.loginSuccess');
    yield put(actions.storePathname());
    yield put(actions.setLastReadFutureMaintenaceMessageDownTimeAt());
  }
};

const watchResetPassword = function* watchResetPassword() {
  while (true) {
    const { token, password, callback } = yield take(ActionType.RESET_PASSWORD);

    try {
      const { response, timeout } = yield race({
        response: call(api.resetPassword, token, password),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Reset password timed out.');
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message);
        } else {
          callback(e.message);
        }
      }
    }
  }
};

const watchValidUserInviteToken = function* watchValidUserInviteToken() {
  while (true) {
    const { token, callback } = yield take(ActionType.VALID_USER_INVITE_TOKEN);

    try {
      const { response, timeout } = yield race({
        response: call(api.validUserInviteToken, token),
        timeout: delay(5000),
      });
      if (timeout) {
        throw new api.TimeoutException(
          'Check valid user invite token timed out.'
        );
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message);
        } else {
          callback(e.message);
        }
      }
    }
  }
};

const watchRegisterInvitedUser = function* watchRegisterInvitedUser() {
  while (true) {
    const { token, payload, callback } = yield take(
      ActionType.REGISTER_INVITED_USER
    );

    try {
      const { response, timeout } = yield race({
        response: call(api.registerInvitedUser, token, payload),
        timeout: delay(15000),
      });
      if (timeout) {
        throw new api.TimeoutException('Register invited user timed out.');
      }
      if (callback) {
        callback(null, response);
      }
    } catch (e) {
      if (callback) {
        if (e.message instanceof Array) {
          callback(e.message[0].message);
        } else {
          callback(e.message);
        }
      }
    }
  }
};

const watchRequestGetMaintenance = function* watchRequestGetMaintenance() {
  while (true) {
    yield take(ActionType.REQUEST_GET_MAINTENANCE);

    try {
      const { response, timeout } = yield race({
        response: call(api.requestGetMaintenance),
        timeout: delay(15000),
      });
      if (timeout) {
        yield put(
          actions.getMaintenanceFailure(new Error('Get maintenance timed out'))
        );
      } else {
        yield put(actions.getMaintenanceSuccess(response));
      }
    } catch (error) {
      yield put(actions.getMaintenanceFailure(error));
    }
  }
};

const watchLogout = function* watchLogout() {
  while (true) {
    const { callback } = yield take('auth.logout');
    IntercomAPI('shutdown');
    // clear local storage
    clearLocalStorage();
    yield put(clearApp());
  }
};

export const saga = function* saga() {
  yield all([
    watchAppStart(),
    watchSendPasswordResetEmail(),
    watchLoginSuccess(),
    watchValidResetToken(),
    watchResetPassword(),
    watchValidUserInviteToken(),
    watchRegisterInvitedUser(),
    watchRequestGetMaintenance(),
    watchLogout(),
  ]);
};
