import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import Modal from '@payaca/components/modal/Modal';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import * as invoicesActions from '@payaca/store/invoices/invoicesActions';

import './MarkInvoiceAsSentModal.sass';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import { useSelector } from '@/api/state';

type Props = {
  isOpen: boolean;
  invoiceId: number;
  onClose: () => void;
  markAsSentCallback?: () => void;
};

const MarkInvoiceAsSentModal: FunctionComponent<Props> = ({
  isOpen,
  invoiceId,
  onClose,
  markAsSentCallback,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [errorMessages, setErrorMessages] = useState<string[]>();

  const isMarkingInvoiceAsSent = useSelector((state) => {
    return state.invoices.isMarkingInvoiceAsSent;
  });

  const onMarkAsSent = useCallback(() => {
    dispatch(
      invoicesActions.requestMarkInvoiceAsSent(
        invoiceId,
        () => {
          markAsSentCallback && markAsSentCallback();
          setErrorMessages([]);
          onClose();
        },
        setErrorMessages
      )
    );
  }, [invoiceId, dispatch, markAsSentCallback, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      title="Mark invoice as sent"
      size="xs"
      onClose={onClose}
      className="mark-as-sent-modal"
      actions={
        <>
          <Button
            onClick={onMarkAsSent}
            iconAfter={faChevronRight}
            isProcessing={isMarkingInvoiceAsSent}
            styleVariant={ButtonStyleVariant.OUTSIZE}
          >
            Mark as sent without sending
          </Button>
          {errorMessages?.map((errorMessage, i) => {
            return (
              <ErrorMessage
                message={errorMessage}
                key={`mark-as-sent-error-message-${i}`}
              />
            );
          })}
        </>
      }
    >
      <p>
        This option is intended to be used in situations where you have sent the{' '}
        invoice offline. For example - where you have printed off the invoice
        and handed it to your customer on paper, in person.
      </p>
      <p>When an invoice has been marked as sent:</p>
      <ul>
        <li>No emails or other communication will go to the customer</li>
        <li>
          You will not be able to retrospectively send this invoice to the
          customer using the standard Payaca process
        </li>
        <li>
          If your account is connected to any third party services (Zapier,
          Xero, Quickbooks), they will treat this invoice as they would any
          other sent invoice
        </li>
        <li>Invoice reminders will not be sent</li>
      </ul>
    </Modal>
  );
};

export default MarkInvoiceAsSentModal;
