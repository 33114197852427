import { InvoicesPermissions } from './invoices.permissions';
import { InvoicesRoles } from './invoices.roles';

export const invoicesMappings = {
  [InvoicesPermissions.GET_ASSIGNED_INVOICES]: [InvoicesRoles.ASSIGNEE_VIEWER],
  [InvoicesPermissions.GET_INVOICES]: [InvoicesRoles.VIEWER],
  [InvoicesPermissions.GET_PAYMENT_RECONCILIATION_RECORDS]: [
    InvoicesRoles.VIEWER,
  ],
  [InvoicesPermissions.CREATE_INVOICE]: [InvoicesRoles.PERSISTER_SENDER],
  [InvoicesPermissions.EDIT_INVOICE]: [InvoicesRoles.PERSISTER_SENDER],
  [InvoicesPermissions.SEND_INVOICE]: [InvoicesRoles.PERSISTER_SENDER],
  [InvoicesPermissions.VOID_INVOICE]: [InvoicesRoles.MANAGER],
  [InvoicesPermissions.GET_LISTED_INVOICES]: [InvoicesRoles.LISTED_VIEWER],
};
