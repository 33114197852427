import { getMaterial } from '@/utils/stateAccessors';
import Badge from '@payaca/components/badge/Badge';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import {
  requestGetMaterial,
  requestPersistMaterial,
} from '@payaca/store/materials/materialsActions';
import { PersistMaterialRequestData } from '@payaca/store/materials/materialsTypes';
import { Tag, TaggableEntityType } from '@payaca/types/tagTypes';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import EntityTagControl from '../entityTagControl/EntityTagControl';
import { TagControlAlignment } from '@payaca/components/tagControl/TagControl';

import './MaterialBasicInformationControl.sass';
import UploadCollectionControl from '../uploadCollectionControl/UploadCollectionControl';
import { faImage, faPen } from '@fortawesome/free-solid-svg-icons';
import CreateEditMaterialModal from '../createEditMaterialModal/CreateEditMaterialModal';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';
import ImageBlock from '@payaca/components/imageBlock/ImageBlock';
import { useSelector } from '@/api/state';
import { ImageFileExtensions } from '@payaca/helpers/fileHelper';

type Props = {
  materialId: number;
};
const MaterialBasicInformationControl: FC<Props> = ({
  materialId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [showEditMaterialModal, setShowEditMaterialModal] = useState(false);

  const material = useSelector((state) => getMaterial(state, materialId));

  const onSubmit = useCallback(
    (persistMaterialData: PersistMaterialRequestData) => {
      dispatch(
        requestPersistMaterial(persistMaterialData, () =>
          dispatch(requestGetMaterial(materialId))
        )
      );
    },
    [dispatch, materialId]
  );

  if (!material) return null;
  return (
    <div className="material-basic-information-control">
      <div className="material-title-category">
        <h1>Material</h1>
        {material?.category && <Badge>{material.category.label}</Badge>}
      </div>
      <div className="material-details">
        {!material?.isSystemManaged && (
          <UploadCollectionControl
            uploadIds={material.uploadIds}
            onUploadCollectionChange={(uploadIds: number[]) => {
              onSubmit({
                id: materialId,
                uploadIds,
              } as PersistMaterialRequestData);
            }}
            acceptFileTypes={ImageFileExtensions}
            uploadTriggerIcon={faImage}
            // 10mb
            fileSizeLimitBytes={10 * 1024 * 1024}
            showFileNames={false}
            allowMultipleUploads={false}
            isDisabled={material?.isSystemManaged}
          />
        )}
        {material.isSystemManaged && (
          <ImageBlock
            imageSrc={material.thumbnailUrl}
            renderBlockIfNoImageSrc={true}
          />
        )}
        <div className="material-basic-information-control__name-description-tags-container">
          <div className="material-name-edit-tags">
            <div className="material-name-edit">
              <h3>{material.name}</h3>
              {!material.isSystemManaged && (
                <PermissionGuard
                  renderIfHasPermissions={[
                    MaterialsPermissions.PERSIST_MATERIAL,
                  ]}
                >
                  <Button
                    onClick={() => setShowEditMaterialModal(true)}
                    styleVariant={ButtonStyleVariant.ANCHOR}
                  >
                    Edit
                  </Button>
                </PermissionGuard>
              )}
            </div>
            <EntityTagControl
              alignment={TagControlAlignment.LEFT}
              entityId={materialId}
              entityType={TaggableEntityType.MATERIAL}
              entityTags={material?.tags || []}
              onEntityTagsChange={() =>
                dispatch(requestGetMaterial(materialId))
              }
              isReadOnly={material?.isSystemManaged}
            />
          </div>
          {material.description && (
            <div className="material-basic-information-control__material-description">
              {material.description}
            </div>
          )}
        </div>
      </div>

      <CreateEditMaterialModal
        isOpen={showEditMaterialModal}
        onClose={() => setShowEditMaterialModal(false)}
        material={material}
        onPersistMaterialSuccess={() =>
          dispatch(requestGetMaterial(materialId))
        }
      />
    </div>
  );
};

export default MaterialBasicInformationControl;
