import { ServicePlanPermissions } from './service-plans.permissions';
import { ServicePlanRoles } from './service-plans.roles';

export const servicePlanMappings = {
  [ServicePlanPermissions.PREVIEW_SERVICE_PLANS]: [ServicePlanRoles.PREVIEWER],
  [ServicePlanPermissions.GET_SERVICE_PLANS]: [ServicePlanRoles.VIEWER],
  [ServicePlanPermissions.GET_SERVICE_PLAN_PERIODS]: [
    ServicePlanRoles.VIEWER,
    ServicePlanRoles.PERIOD_MANAGER,
  ],
  [ServicePlanPermissions.ARRANGE_SERVICE_PLAN_PERIOD_COMMITMENT]: [
    ServicePlanRoles.PERIOD_MANAGER,
  ],
  [ServicePlanPermissions.PERSIST_SERVICE_PLAN]: [ServicePlanRoles.PERSISTER],
  [ServicePlanPermissions.PERSIST_DISCOUNT]: [
    ServicePlanRoles.DISCOUNT_MANAGER,
  ],
};
