import { createReducer, mergeFetchedEntities } from '../utils';

import { ActionType, AnyAction, State } from './servicePlansTypes';

export const initialState: State = {
  servicePlans: {},
  servicePlanSubscriptions: {},
  servicePlanPeriods: {},

  isGettingListedServicePlans: false,
  listedServicePlans: [],

  isGettingListedDiscountCodes: false,
  listedDiscountCodes: [],

  isGettingListedServicePlanCustomerReminders: false,
  listedServicePlanCustomerReminders: [],

  listedServicePlanPeriods: [],

  isGettingServicePlansAnalytics: false,

  isGettingServicePeriods: false,
};

const setIsGettingListedServicePlanCustomerReminders =
  (value: boolean) => (state: State) => ({
    ...state,
    isGettingListedServicePlanCustomerReminders: value,
  });
const setIsGettingListedDiscountCodes = (value: boolean) => (state: State) => ({
  ...state,
  isGettingListedDiscountCodes: value,
});
const setIsGettingListedServicePlans = (value: boolean) => (state: State) => ({
  ...state,
  isGettingListedServicePlans: value,
});
const setIsGettingServicePeriods = (value: boolean) => (state: State) => ({
  ...state,
  isGettingServicePeriods: value,
});

const servicePlansReducer = createReducer<State, ActionType, AnyAction>(
  initialState,
  {
    [ActionType.GET_SERVICE_PLANS_CUSTOMER_REMINDERS_REQUEST]:
      setIsGettingListedServicePlanCustomerReminders(true),
    [ActionType.GET_SERVICE_PLANS_CUSTOMER_REMINDERS_SUCCESS]: (
      state,
      action
    ) => ({
      ...state,
      isGettingListedServicePlanCustomerReminders: false,
      listedServicePlanCustomerReminders: action.payload,
    }),

    [ActionType.GET_LISTED_DISCOUNT_CODES_REQUEST]:
      setIsGettingListedDiscountCodes(true),
    [ActionType.GET_LISTED_DISCOUNT_CODES_SUCCESS]: (state, action) => ({
      ...state,
      isGettingListedDiscountCodes: false,
      listedDiscountCodes: action.payload,
    }),
    [ActionType.TOGGLE_SERVICE_PLANS_CUSTOMER_REMINDER_SUCCESS]: (
      state,
      action
    ) => {
      const { publicId } = action.payload;
      const copyListedServicePlanCustomerReminders = [
        ...state.listedServicePlanCustomerReminders,
      ];

      const foundIndex = copyListedServicePlanCustomerReminders.findIndex(
        (r) => r.publicId === publicId
      );
      if (foundIndex !== -1) {
        copyListedServicePlanCustomerReminders[foundIndex] = action.payload;
      }

      return {
        ...state,
        listedServicePlanCustomerReminders:
          copyListedServicePlanCustomerReminders,
      };
    },
    [ActionType.GET_LISTED_DISCOUNT_CODES_FAILURE]:
      setIsGettingListedDiscountCodes(false),

    [ActionType.GET_LISTED_SERVICE_PLANS_REQUEST]:
      setIsGettingListedServicePlans(true),
    [ActionType.GET_LISTED_SERVICE_PLANS_SUCCESS]: (state, action) => ({
      ...state,
      isGettingListedServicePlans: false,
      listedServicePlans: action.payload,
    }),
    [ActionType.GET_LISTED_SERVICE_PLANS_FAILURE]:
      setIsGettingListedServicePlans(false),

    [ActionType.GET_SERVICE_PERIODS_REQUEST]: setIsGettingServicePeriods(true),
    [ActionType.GET_SERVICE_PERIODS_SUCCESS]: (state, action) => ({
      ...state,
      isGettingServicePeriods: false,
      listedServicePlanPeriods: action.payload,
    }),
    [ActionType.GET_SERVICE_PERIODS_FAILURE]: setIsGettingServicePeriods(false),

    [ActionType.GET_SERVICE_PLAN_REQUEST]: (state, action) => {
      const fetchedEntity =
        state.servicePlans && state.servicePlans[action.payload.publicId];

      return {
        ...state,
        servicePlans: {
          ...state.servicePlans,
          [action.payload.publicId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    },
    [ActionType.GET_SERVICE_PLAN_SUCCESS]: (state, action) => {
      const fetchedEntity =
        state.servicePlans && state.servicePlans[action.payload.publicId];

      return {
        ...state,
        servicePlans: {
          ...state.servicePlans,
          [action.payload.publicId]: {
            ...fetchedEntity,
            entity: action.payload,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    },
    [ActionType.GET_SERVICE_PLAN_FAILURE]: (
      state,
      { error: err, meta: { publicId } }
    ) => {
      const fetchedEntity = state.servicePlans && state.servicePlans[publicId];

      return {
        ...state,
        servicePlans: {
          ...state.servicePlans,
          [publicId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: err,
          },
        },
      };
    },
    [ActionType.GET_SUBSCRIPTIONS_FOR_SERVICE_PLAN_SUCCESS]: (
      state,
      action
    ) => {
      return {
        ...state,
        servicePlanSubscriptions: mergeFetchedEntities({
          cache: state.servicePlanSubscriptions,
          values: action.payload,
          idKey: 'publicId',
        }),
      };
    },
    [ActionType.GET_SUBSCRIPTIONS_FOR_CUSTOMER_SUCCESS]: (state, action) => {
      return {
        ...state,
        servicePlanSubscriptions: mergeFetchedEntities({
          cache: state.servicePlanSubscriptions,
          values: action.payload,
          idKey: 'publicId',
        }),
      };
    },
    [ActionType.GET_SERVICE_PLAN_SUBSCRIPTION_SUCCESS]: (state, action) => {
      return {
        ...state,
        servicePlanSubscriptions: mergeFetchedEntities({
          cache: state.servicePlanSubscriptions,
          values: [action.payload],
          idKey: 'publicId',
        }),
      };
    },
    [ActionType.CLEAR_SERVICE_PLAN_SUBSCRIPTIONS]: (state, action) => {
      return {
        ...state,
        servicePlanSubscriptions: {},
      };
    },
    [ActionType.CLEAR_SERVICE_PLANS]: (state, action) => {
      return {
        ...state,
        servicePlans: {},
      };
    },
    [ActionType.CLEAR_SERVICE_PLAN_PERIODS]: (state, action) => {
      return {
        ...state,
        servicePlanPeriods: {},
      };
    },
    [ActionType.GET_SERVICE_PLAN_PERIOD_REQUEST]: (state, action) => {
      const fetchedEntity =
        state.servicePlanPeriods &&
        state.servicePlanPeriods[action.payload.publicId];

      return {
        ...state,
        servicePlanPeriods: {
          ...state.servicePlanPeriods,
          [action.payload.publicId]: {
            ...fetchedEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    },
    [ActionType.GET_SERVICE_PLAN_PERIOD_SUCCESS]: (state, action) => {
      const fetchedEntity =
        state.servicePlanPeriods &&
        state.servicePlanPeriods[action.payload.publicId];

      return {
        ...state,
        servicePlanPeriods: {
          ...state.servicePlanPeriods,
          [action.payload.publicId]: {
            ...fetchedEntity,
            entity: action.payload,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    },
    [ActionType.GET_SERVICE_PLAN_PERIOD_FAILURE]: (
      state,
      { error: err, meta: { publicId } }
    ) => {
      const fetchedEntity =
        state.servicePlanPeriods && state.servicePlanPeriods[publicId];

      return {
        ...state,
        servicePlanPeriods: {
          ...state.servicePlanPeriods,
          [publicId]: {
            ...fetchedEntity,
            fetchFailedAt: new Date(),
            isFetching: false,
            fetchError: err,
          },
        },
      };
    },
    ['auth.logout' as any]: () => {
      return initialState;
    },
  }
);

export default servicePlansReducer;
