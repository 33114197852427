import { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';

import { useAccount } from '../utils/storeHooks';
import { formatToMinimumTwoDigits } from '@payaca/utilities/timeUtilities';

import {
  getBusinessHoursDays,
  getBusinessHoursStartEndTimes,
} from '@payaca/helpers/scheduleHelper';

import { Days } from '@payaca/types/scheduleTypes';

type HiddenDays = (0 | 1 | 2 | 3 | 4 | 5 | 6)[];

const businessDaysHiddenMap = [
  Days.SUNDAY,
  Days.MONDAY,
  Days.TUESDAY,
  Days.WEDNESDAY,
  Days.THURSDAY,
  Days.FRIDAY,
  Days.SATURDAY,
];

export function useCalendarSettings(
  hideNonBusinessHours: boolean,
  userView: string
) {
  const account = useAccount();
  // get accounts start, end times and business days
  const { businessHoursStart, businessHoursEnd } =
    getBusinessHoursStartEndTimes(account.businessHours);

  const savedHiddenDays = useMemo(() => {
    const businessDays = getBusinessHoursDays(account.businessHours);
    const businessDaysAsNumbers = businessDays.map((day) =>
      businessDaysHiddenMap.indexOf(day)
    );
    return [0, 1, 2, 3, 4, 5, 6]
      .filter((d) => !businessDaysAsNumbers.includes(d))
      .map((d) => d as HiddenDays[number]);
  }, [account.businessHours]);

  const [startTime, setStartTime] = useState<string>(businessHoursStart);
  const [endTime, setEndTime] = useState<string>(businessHoursEnd);
  const [hiddenDays, setHiddenDays] = useState<HiddenDays>(savedHiddenDays);

  const roundedBusinessHoursDuration = useMemo(() => {
    const start = moment(businessHoursStart, 'HH:mm');
    const end = moment(businessHoursEnd, 'HH:mm');
    const duration = moment.duration(end.diff(start));
    // calendar unable to show minutes as a slot duration - round duration hours up to nearest whole hour
    const durationHours = Math.ceil(duration.asHours());
    return durationHours;
  }, [account.businessHours, businessHoursStart, businessHoursEnd]);

  const { slotDuration, slotDurationClass } = useMemo(() => {
    if (userView === 'resourceTimelineWeek' || userView === 'timelineWeek') {
      if (hideNonBusinessHours) {
        let slotDurationClass = '';
        let updatedDurationHours = roundedBusinessHoursDuration;
        // slotDuration must be whole hours and divisble by the duration and 24 hours, otherwise events will not show relatively in the right place
        // get next durationHours that is divisible by 24 and duration hours
        for (let i = 1; i < 24; i++) {
          if (
            24 % updatedDurationHours === 0 &&
            roundedBusinessHoursDuration % updatedDurationHours === 0
          ) {
            break;
          }
          updatedDurationHours -= 1;
          if (updatedDurationHours === 1) {
            slotDurationClass = `hour-slots-${roundedBusinessHoursDuration}`;
          } else {
            slotDurationClass = `hour-slots-${updatedDurationHours}`;
          }
        }
        return {
          slotDuration: `${formatToMinimumTwoDigits(
            updatedDurationHours
          )}:00:00`,
          slotDurationClass,
        };
      } else {
        return { slotDuration: '24:00:00' };
      }
    } else {
      return { slotDuration: '00:30:00' };
    }
  }, [userView, hideNonBusinessHours, roundedBusinessHoursDuration]);

  useEffect(() => {
    if (hideNonBusinessHours) {
      setStartTime(businessHoursStart);
      setEndTime(businessHoursEnd);
      setHiddenDays(savedHiddenDays);
    } else {
      // show all hours, all days
      setStartTime('00:00:00');
      setEndTime('23:59:59');
      setHiddenDays([]);
    }
  }, [
    hideNonBusinessHours,
    businessHoursStart,
    businessHoursEnd,
    savedHiddenDays,
  ]);

  return {
    businessStartTime: startTime,
    businessEndTime: endTime,
    hiddenDays,
    slotDuration,
    slotDurationClass,
  };
}
