import React from 'react';

import BasicPageWrapper from '@/ui/pages/pageWrappers/basicPageWrapper/BasicPageWrapper';

import Animation from './maintenance-animation.gif';

import './MaintenancePage.sass';
import { useSelector } from '@/api/state';

const MaintenancePage = () => {
  const currentMaintenanceSchedule = useSelector((state: any) => {
    return state.app.maintenanceSchedule?.current;
  });

  if (!currentMaintenanceSchedule) {
    return null;
  }
  return (
    <BasicPageWrapper className="maintenance">
      <img src={Animation}></img>

      <h1>We are down for maintenance</h1>
      <div className="description">
        We are currently undergoing some necessary maintenance, we expect to be
        back up on{' '}
        <span className="bold-weight">
          {currentMaintenanceSchedule.upTimeAt}
        </span>
        , sorry for any inconvenience. Have a well deserved break and check back
        in later.
      </div>
      {/* TODO: create a game like google have when offline :) */}
    </BasicPageWrapper>
  );
};

export default MaintenancePage;
