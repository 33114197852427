import { takeLatest } from 'redux-saga/effects';

import { SagaConfig, ActionType } from './listedFilesTypes';

import { Req } from '@payaca/helpers/storeHelper';
import { handleAsyncAction } from '../utils';
import {
  getListedFilesForDeal,
  shareFilesForDeal,
  unshareFilesForDeal,
} from './listedFilesActions';

const listedTasksSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  const handleGetListedFilesForDeal = handleAsyncAction(
    getListedFilesForDeal,
    async (payload) => {
      try {
        const response = await req.get(
          `/listed_files/deal/${payload.dealId}?types=${
            payload.fileType ? payload.fileType.join(',') : ''
          }&searchTerm=${payload.searchTerm || ''}`
        );
        return { dealId: payload.dealId, files: await response.json() } as any;
      } catch (err) {
        console.log(
          `Get listed files for Project failed: ${JSON.stringify(err)}`
        );
        payload.onErrorCallback?.();
      }
    },
    async (response, requestData) => {
      requestData.payload.callback?.(response.files);
      return response;
    }
  );

  const handleShareFilesForDeal = handleAsyncAction(
    shareFilesForDeal,
    async (payload) => {
      const { callback, dealId, ...body } = payload;
      try {
        const response = await req.put(
          `/listed_files/deal/${payload.dealId}/share-files`,
          body
        );
        return { dealId: payload.dealId, files: await response.json() } as any;
      } catch (err) {
        console.log(`Share files for deal failed: ${JSON.stringify(err)}`);
      }
    },
    async (response, requestData) => {
      requestData.payload.callback?.(response);
      return response;
    }
  );

  const handleUnshareFilesForDeal = handleAsyncAction(
    unshareFilesForDeal,
    async (payload) => {
      const { callback, dealId, ...body } = payload;
      try {
        const response = await req.put(
          `/listed_files/deal/${payload.dealId}/unshare-files`,
          body
        );
        return { dealId: payload.dealId, files: await response.json() } as any;
      } catch (err) {
        console.log(`Unshare files for deal failed: ${JSON.stringify(err)}`);
      }
    },
    async (response, requestData) => {
      requestData.payload.callback?.(response);
      return response;
    }
  );

  return function* () {
    yield takeLatest(
      ActionType.GET_LISTED_FILES_FOR_DEALS_REQUEST,
      handleGetListedFilesForDeal
    );
    yield takeLatest(
      ActionType.SHARE_LISTED_FILES_REQUEST,
      handleShareFilesForDeal
    );
    yield takeLatest(
      ActionType.UNSHARE_LISTED_FILES_REQUEST,
      handleUnshareFilesForDeal
    );
  };
};

export default listedTasksSagaCreator;
