import { useSelector } from '@/api/state';
import { User } from '@payaca/types/userTypes';

const emptyArray: Array<any> = [];

// TOOD: Move to packages store
export const useAccount = () => {
  return useSelector((state: any) => {
    return state.users.myProfile.accounts[0];
  });
};

export const useAccountUsers = (): User[] => {
  return useSelector((state: any) => {
    return state.users?.accountUsers ?? emptyArray;
  });
};

export const useAccountUser = (userId?: number): User | undefined => {
  const accountUsers = useAccountUsers();
  if (userId) {
    const user = accountUsers?.find((x) => x.id === userId);
    return user;
  }
};
