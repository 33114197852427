import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash-es';
import { useDispatch } from 'react-redux';

import * as invoicesActions from '@payaca/store/invoices/invoicesActions';
import { UpdateInvoiceRequestData } from '@payaca/store/invoices/invoicesTypes';

import { useSelector } from '@/api/state';
import {
  getDeal,
  getInvoice,
  getInvoiceLinesByInvoiceId,
  getJobLineItemsByInvoiceId,
} from '@/utils/stateAccessors';

import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import BasicField from '@payaca/components/basicField/BasicField';
import CollapsiblePanel, {
  CollapsiblePanelStyleVariant,
} from '@payaca/components/collapsiblePanel/CollapsiblePanel';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getInvoiceTotalBreakdown } from '@payaca/helpers/invoiceHelper';

import { Deal } from '@payaca/types/dealTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Invoice } from '@payaca/types/invoiceTypes';

import './InvoiceSidePanel.sass';
import DealPriceDetailControl from '../dealPriceDetailControl/DealPriceDetailControl';
import DealPaymentOptionsControl from '../dealPaymentOptionsControl/DealPaymentOptionsControl';
import VoidInvoiceButton from '../voidInvoiceButton/VoidInvoiceButton';
import { usePaymentReconciliationRecordsForDeal } from '@payaca/store/hooks/appState';

const getInitialFormState = (invoice?: Invoice) => {
  return {
    invoiceId: invoice?.id,
    customReference: invoice?.customReference,
    notes: invoice?.notes,
    dueInDays: invoice?.dueInDays,
    assignedToUserId: invoice?.assignedToUserId,
    contactId: invoice?.contactId,
  };
};

type Props = {
  invoiceId: number;
};

const InvoiceSidePanel: FC<Props> = ({
  invoiceId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const invoice: Invoice | undefined = useSelector((state) => {
    return getInvoice(state, invoiceId);
  });

  const deal: Deal | undefined = useSelector((state) => {
    if (!invoice) return;
    return getDeal(state, invoice.dealId);
  });

  const paymentReconciliationRecords = usePaymentReconciliationRecordsForDeal(
    invoice?.dealId
  );

  const unreconciledTotal = paymentReconciliationRecords
    .filter((record) => !record.invoiceId)
    .reduce((acc, record) => acc + record.value, 0);

  const region = useSelector(
    (state: any) => state.users.myProfile.accounts[0].region
  );

  const [isSaveRequired, setIsSaveRequired] = useState(false);

  const isUpdatingInvoice: boolean = useSelector((state) => {
    return state.invoices.isUpdatingInvoice;
  });

  const isFetchingInvoice: boolean = useSelector((state) => {
    return (
      state.invoices.invoices && state.invoices.invoices[invoiceId]?.isFetching
    );
  });

  const invoiceLines = useSelector((state) => {
    return getInvoiceLinesByInvoiceId(state, invoiceId);
  });

  const jobLineItems = useSelector((state) => {
    return getJobLineItemsByInvoiceId(state, invoiceId);
  });

  const onInvoiceUpdateSuccess = useCallback(() => {
    dispatch(invoicesActions.requestGetInvoice(invoiceId));
  }, [dispatch, invoiceId]);

  const initialFormState = useMemo(() => {
    return getInitialFormState(invoice);
  }, [invoice?.id]);

  const requiresUpdateInvoice = useCallback(
    (formState: { [key: string]: any }) => {
      if (!invoice) return false;
      if (isFetchingInvoice || isUpdatingInvoice) return true;
      return !isEqual(formState, getInitialFormState(invoice));
    },
    [isFetchingInvoice, isUpdatingInvoice, invoice]
  );

  const updateInvoice = useCallback(
    (formState: { [key: string]: any }) => {
      const updateInvoiceRequestData = formState as UpdateInvoiceRequestData;

      if (updateInvoiceRequestData && updateInvoiceRequestData.invoiceId) {
        if (requiresUpdateInvoice(formState)) {
          dispatch(
            invoicesActions.requestUpdateInvoice(
              updateInvoiceRequestData,
              onInvoiceUpdateSuccess
            )
          );
        }
      }
    },
    [dispatch, onInvoiceUpdateSuccess, requiresUpdateInvoice]
  );

  const invoiceTotalBreakdown = useMemo(() => {
    return getInvoiceTotalBreakdown(invoiceLines, jobLineItems);
  }, [invoiceLines, jobLineItems]);

  const invoicedToDate = useMemo(() => {
    if (!deal || !invoice) return 0;
    return deal.invoicedValue - invoice.totalValue;
  }, [deal?.id, deal?.invoicedValue, invoice?.id]);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      useEffect(() => {
        if (isSaveRequired) {
          updateInvoice(formState);
          setIsSaveRequired(false);
        }
      }, [isSaveRequired]);

      if (!invoice) return <></>;

      return (
        <div className="invoice-side-panel-form-contents">
          <div className="invoice-due-days-wrapper">
            Invoice due in{' '}
            <BasicField
              name="dueInDays"
              value={formState.dueInDays}
              type="number"
              onChange={(value: { [key: string]: any }) => {
                const updateObject: { [key: string]: any } = {
                  dueInDays: undefined,
                };
                if (!isNaN(Number(value.dueInDays)) && value.dueInDays !== '') {
                  updateObject['dueInDays'] = Math.round(
                    Number(value.dueInDays)
                  );
                }
                onFieldChange(updateObject);
              }}
              changeTimeoutMs={2000}
              onBlur={() => setIsSaveRequired(true)}
              onChangeTimeout={() => setIsSaveRequired(true)}
            />{' '}
            days
          </div>
          <div className="invoice-and-deal-totals">
            <div className="invoice-totals-container">
              <LabelValuePair
                suffixLabelWith=""
                label="Subtotal"
                value={currencyPrice(invoiceTotalBreakdown.subtotal, region)}
              />
              <LabelValuePair
                suffixLabelWith=""
                label="Tax"
                value={currencyPrice(invoiceTotalBreakdown.vatTotal, region)}
              />

              {!!invoiceTotalBreakdown.cisTotal && (
                <LabelValuePair
                  suffixLabelWith=""
                  label="CIS suffered"
                  value={`${
                    invoiceTotalBreakdown.cisTotal * -1 >= 0 ? '+' : ''
                  }${currencyPrice(
                    invoiceTotalBreakdown.cisTotal * -1,
                    region
                  )}`}
                />
              )}
              <hr />
              <LabelValuePair
                suffixLabelWith=""
                label="Total"
                value={currencyPrice(invoice.totalValue, region)}
                className="total"
              />
            </div>
            {deal && (
              <CollapsiblePanel
                styleVariant={CollapsiblePanelStyleVariant.OUTSIZE}
                title={`Project #${
                  deal.reference || deal.customReference
                } summary`}
                isOpen={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
              >
                <LabelValuePair
                  suffixLabelWith=""
                  label="Accepted total"
                  value={currencyPrice(deal?.acceptedValue, region)}
                />
                <LabelValuePair
                  suffixLabelWith=""
                  label="Invoiced to date"
                  value={currencyPrice(invoicedToDate, region)}
                />
                <LabelValuePair
                  suffixLabelWith=""
                  label="Still to invoice"
                  value={currencyPrice(
                    deal.acceptedValue - invoicedToDate - invoice.totalValue,
                    region
                  )}
                />
                <hr />
                <>
                  {unreconciledTotal > 0 && (
                    <>
                      <LabelValuePair
                        suffixLabelWith=""
                        label="Unreconciled payment value"
                        value={currencyPrice(unreconciledTotal, region)}
                      />
                      {invoice.totalValue > 0 && (
                        <small>
                          Unreconciled payments will be reconciled against this
                          Invoice as soon as it is sent. This will{' '}
                          {unreconciledTotal >= invoice.totalValue
                            ? 'fully'
                            : 'partially'}{' '}
                          pay off the Invoice.
                        </small>
                      )}
                    </>
                  )}
                </>
              </CollapsiblePanel>
            )}
          </div>
        </div>
      );
    },
    [
      invoicedToDate,
      invoice,
      updateInvoice,
      isUpdatingInvoice,
      isSaveRequired,
      invoiceTotalBreakdown,
      region,
      deal,
      unreconciledTotal,
    ]
  );

  return (
    <div className="invoice-side-panel">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
      />

      {invoice && (
        <>
          <DealPaymentOptionsControl dealId={invoice?.dealId} />
          <DealPriceDetailControl dealId={invoice.dealId} canHideVat={true} />
          <VoidInvoiceButton invoiceId={invoiceId} />
        </>
      )}
    </div>
  );
};
export default InvoiceSidePanel;
