import React from 'react';

import Button from '@payaca/components/button/Button';

import { ServicePlanPermissions } from '@payaca/permissions/service-plans/service-plans.permissions';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import ServicePlanPeriodCommitmentEntityCard from './ServicePlanPeriodCommitmentEntityCard';
import './ServicePlanPeriodUnarrangedCommitmentEntityCard.sass';

const ServicePlanPeriodUnarrangedCommitmentEntityCard = ({
  name,
  onArrange,
  commitmentId,
  entityType,
  onCommitmentUpdate,
}: React.PropsWithChildren<{
  name?: string;
  onArrange: () => void;
  commitmentId: string;
  entityType: 'scheduledEvent' | 'task';
  onCommitmentUpdate: () => void;
}>) => {
  return (
    <ServicePlanPeriodCommitmentEntityCard
      commitmentId={commitmentId}
      entityType={entityType}
      status={'unarranged'}
      className={'unarranged-commitment-entity-card'}
      onCommitmentUpdate={onCommitmentUpdate}
    >
      <div className="unarranged-commitment-entity-card-inner">
        <h3 className="name">{name}</h3>
        <PermissionGuard
          renderIfHasPermissions={[
            ServicePlanPermissions.ARRANGE_SERVICE_PLAN_PERIOD_COMMITMENT,
          ]}
        >
          <div className="button-container">
            <Button onClick={onArrange}>Arrange</Button>
          </div>
        </PermissionGuard>
      </div>
    </ServicePlanPeriodCommitmentEntityCard>
  );
};

export default ServicePlanPeriodUnarrangedCommitmentEntityCard;
