import { BaseJob, Job } from '@payaca/types/jobTypesV2';
import {
  JobContent,
  JobContentBase,
  JobContentTotals,
} from '@payaca/types/jobContentTypes';
import {
  PartialUpdateJobRequestData,
  UpdateJobRequestData,
} from '@payaca/types/jobRequestTypes';
import { UpdateJobContentRequestData } from '@payaca/types/jobContentRequestTypes';
import { JobPayment } from '@payaca/types/jobPaymentTypes';
import {
  getAttemptedDepositPaymentValueFromJobPayments,
  getBacsPendingDepositPaymentsFromJobPayments,
  getBacsPendingPaymentsExcludingDepositFromJobPayments,
  getCompletedDepositPaymentValueFromJobPayments,
  getTotalAttemptedPaymentValueFromJobPayments,
  getTotalCompletedPaymentValueFromJobPayments,
} from './jobPaymentHelper';
import {
  isEstimate,
  isInvoice,
  isInvoiceOverdue,
  isQuote,
  isQuoteOrEstimate,
  isSentQuoteEstimate,
  jobHasSent,
} from './jobStatusHelper';
import { JobStatus, JobType, ReadableJobStatus } from '@payaca/types/jobTypes';

import { getJobContactFromCustomer } from './customerHelper';
import { Customer } from '@payaca/types/customerTypes';
import moment from 'moment-timezone';
import { StatusBadgeState } from './statusBadgeHelper';
import isEqual from 'lodash.isequal';
import { BadgeColourVariant } from '@payaca/types/plBadge';

export const getInitialUpdateJobContentRequestDataFromBaseJobContent = (
  jobContentBase: JobContentBase
): UpdateJobContentRequestData => {
  return {
    jobContentId: jobContentBase.id,
    depositPercentage: jobContentBase.depositPercentage,
    depositAmount: jobContentBase.depositAmount,

    minimumDepositPercentage: jobContentBase.minimumDepositPercentage,
    minimumDepositAmount: jobContentBase.minimumDepositAmount,
    discountPercentage: jobContentBase.discountPercentage,
    discountAmount: jobContentBase.discountAmount,
    markupPercentage: jobContentBase.markupPercentage,
    markupAmount: jobContentBase.markupAmount,
  };
};

export const getInitialUpdateJobRequestDataFromBaseJob = (
  baseJob: BaseJob
): UpdateJobRequestData => {
  return {
    jobId: baseJob.id,
    status: baseJob.status,
    customReference: baseJob.customReference || String(baseJob.reference),
    showStripePaymentOption: baseJob.showStripePaymentOption,
    showBacsPaymentOption: baseJob.showBacsPaymentOption,
    jobNotes: baseJob.jobNotes,
    jobDescription: baseJob.jobDescription,
    dueInOrValidForDays: baseJob.dueInOrValidForDays,
    contactId: baseJob.contactId,
    autoProgressToInvoice: baseJob.autoProgressToInvoice,
    invoiceConfig: baseJob.invoiceConfig,
  };
};

export const getJobRequiresUpdate = (
  job: BaseJob,
  proposedChanges: PartialUpdateJobRequestData
) => {
  const currentJobState = getInitialUpdateJobRequestDataFromBaseJob(job);

  const keys = Object.keys(proposedChanges).filter((key) => {
    return (
      proposedChanges[key as keyof PartialUpdateJobRequestData] != undefined
    );
  });

  const j1 = Object.entries(proposedChanges)
    .filter(([key, value]) => {
      return keys.includes(key);
    })
    .reduce((obj: { [key: string]: any }, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  const j2 = Object.entries(currentJobState)
    .filter(([key, value]) => {
      return keys.includes(key);
    })
    .reduce((obj: { [key: string]: any }, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  return !isEqual(j1, j2);
};

export const isDepositPaymentRequired = (
  job: BaseJob,
  jobContent: JobContentBase
): boolean => {
  return (
    isQuoteOrEstimate(job.status) &&
    (!!jobContent.depositAmount || !!jobContent.depositPercentage)
  );
};

export const isInvoicePaymentRequired = (
  job: BaseJob,
  jobContentTotals: JobContentTotals
): boolean => {
  return isInvoice(job.status) && jobContentTotals.total > 0;
};

export const isDepositAttemptedPaid = (
  jobContentTotals: JobContentTotals,
  jobPayments: JobPayment[]
): boolean => {
  const attemptedDepositPaymentValue =
    getAttemptedDepositPaymentValueFromJobPayments(jobPayments);
  const remainingDeposit =
    (jobContentTotals.calculatedDepositAmount || 0) -
    attemptedDepositPaymentValue;
  return remainingDeposit <= 0;
};

export const isDepositConfirmedPaid = (
  jobContentTotals: JobContentTotals,
  jobPayments: JobPayment[]
): boolean => {
  const completedDepositPaymentValue =
    getCompletedDepositPaymentValueFromJobPayments(jobPayments);
  const remainingDeposit =
    (jobContentTotals.calculatedDepositAmount || 0) -
    completedDepositPaymentValue;
  return remainingDeposit <= 0;
};

export const isInvoiceAttemptedPaid = (
  jobContentTotals: JobContentTotals,
  jobPayments: JobPayment[]
): boolean => {
  const attemptedPaymentValue =
    getTotalAttemptedPaymentValueFromJobPayments(jobPayments);
  const remainingTotal = jobContentTotals.total - attemptedPaymentValue;
  return remainingTotal <= 0;
};

export const isInvoiceConfirmedPaid = (
  jobContentTotals: JobContentTotals,
  jobPayments: JobPayment[]
): boolean => {
  const attemptedPaymentValue =
    getTotalCompletedPaymentValueFromJobPayments(jobPayments);
  const remainingTotal = jobContentTotals.total - attemptedPaymentValue;
  return remainingTotal <= 0;
};

export const hasBacsPendingPayments = (
  job: BaseJob,
  jobPayments: JobPayment[]
) => {
  const jobIsQuoteOrEstimate = isQuoteOrEstimate(job.status);
  if (jobIsQuoteOrEstimate) {
    return !!getBacsPendingDepositPaymentsFromJobPayments(jobPayments).length;
  }
  return !!getBacsPendingPaymentsExcludingDepositFromJobPayments(jobPayments)
    .length;
};

export const isJobAccepted = (job: Job): boolean => {
  return isQuoteOrEstimate(job.status) && !!job.acceptedAt;
};

export const isJobDeclined = (job: Job): boolean => {
  return isQuoteOrEstimate(job.status) && !!job.declinedAt;
};

export const getReadableJobStatus = (
  job: Job,
  jobContent: JobContent,
  jobPayments: JobPayment[]
) => {
  const isArchived = job.archivedAt;
  const isInactivated = job.inactivatedAt;
  const isJobSentQuoteEstimate = isSentQuoteEstimate(job.status);
  const isPaymentPending = hasBacsPendingPayments(job, jobPayments);

  // Return statuses relating to invoices and invoiced quotes
  if (isArchived) {
    return ReadableJobStatus.ARCHIVED;
  }
  if (isInactivated) {
    return ReadableJobStatus.INACTIVE;
  }

  // has this quote expired?
  if (
    isJobSentQuoteEstimate &&
    job.validUntil &&
    moment(job.validUntil) < moment() &&
    !job.acceptedAt
  ) {
    return ReadableJobStatus.EXPIRED;
  }

  if (job.status === JobStatus.INVOICED) {
    if (isInvoiceConfirmedPaid(jobContent, jobPayments)) {
      return ReadableJobStatus.PAID;
    }
    if (isPaymentPending) {
      return ReadableJobStatus.PAYMENT_PENDING;
    }
    if (isInvoiceOverdue(job.dueAt || null, job.status) && !job.bouncedAt) {
      return ReadableJobStatus.OVERDUE;
    }
  }
  if (job.status === JobStatus.PAID) {
    return ReadableJobStatus.PAID;
  }

  // return statuses relating to standard quotes
  if (isJobSentQuoteEstimate || job.status === JobStatus.ACCEPTED) {
    if (
      !!jobContent.calculatedDepositAmount &&
      isDepositConfirmedPaid(jobContent, jobPayments)
    ) {
      return ReadableJobStatus.DEPOSIT_PAID;
    }
    if (isPaymentPending) {
      return ReadableJobStatus.PAYMENT_PENDING;
    }
    if (
      isJobAccepted(job) &&
      !!jobContent.calculatedDepositAmount &&
      !isDepositConfirmedPaid(jobContent, jobPayments)
    ) {
      // No op
    }
    if (isJobAccepted(job)) {
      if (
        !!jobContent.calculatedDepositAmount &&
        !isDepositConfirmedPaid(jobContent, jobPayments)
      ) {
        return ReadableJobStatus.DEPOSIT_DUE;
      } else {
        return ReadableJobStatus.ACCEPTED;
      }
    }
    if (isJobDeclined(job)) {
      return ReadableJobStatus.DECLINED;
    }
  }

  // return standard statuses
  if (job.bouncedAt) {
    return ReadableJobStatus.BOUNCED;
  }
  if (job.viewedAt) {
    return ReadableJobStatus.VIEWED;
  }

  switch (job.status) {
    case JobStatus.QUOTED:
    case JobStatus.ESTIMATED:
    case JobStatus.INVOICED:
      return ReadableJobStatus.SENT;
    default:
      return ReadableJobStatus.DRAFT;
  }
};

export const canMarkJobAsAccepted = (job: Job): boolean => {
  return (
    isSentQuoteEstimate(job.status) &&
    !isJobAccepted(job) &&
    !isJobDeclined(job)
  );
};

export const canMarkJobAsDeclined = (job: Job): boolean => {
  return (
    isSentQuoteEstimate(job.status) &&
    !isJobDeclined(job) &&
    !isJobAccepted(job)
  );
};

export const canConvertJobToInvoice = (
  job: Job,
  hasRelatedInvoice: boolean,
  dealVersion: number
): boolean => {
  if (dealVersion >= 2) return false;
  return isQuoteOrEstimate(job.status) && !hasRelatedInvoice;
};

// TODO: Still used in client - update to new colours
export const getReadableJobStatusStyle = (
  status: ReadableJobStatus
): { colour: string; backgroundColour: string } => {
  switch (status) {
    case ReadableJobStatus.ARCHIVED:
    case ReadableJobStatus.INACTIVE:
      return {
        colour: '#ffffff',
        backgroundColour: '#919191',
      };
    case ReadableJobStatus.PAID:
    case ReadableJobStatus.DEPOSIT_PAID:
    case ReadableJobStatus.ACCEPTED:
      return {
        colour: '#263E59',
        backgroundColour: '#75E582',
      };
    case ReadableJobStatus.DEPOSIT_DUE:
    case ReadableJobStatus.PAYMENT_PENDING:
    case ReadableJobStatus.VIEWED:
      return {
        backgroundColour: '#FABB00',
        colour: '#263E59',
      };
    case ReadableJobStatus.OVERDUE:
    case ReadableJobStatus.DECLINED:
    case ReadableJobStatus.EXPIRED:
      return {
        colour: '#ffffff',
        backgroundColour: '#D93A3A',
      };
    case ReadableJobStatus.BOUNCED:
      return {
        backgroundColour: '#7E27BE',
        colour: '#ffffff',
      };
    case ReadableJobStatus.SENT:
      return {
        backgroundColour: '#DBE5F0',
        colour: '#263E59',
      };
    case ReadableJobStatus.DRAFT:
    default:
      return {
        backgroundColour: '#607387',
        colour: '#ffffff',
      };
  }
};

export const getJobStatusBadgeState = (status?: ReadableJobStatus) => {
  switch (status) {
    case ReadableJobStatus.ACCEPTED:
    case ReadableJobStatus.DEPOSIT_PAID:
    case ReadableJobStatus.PAID:
      return StatusBadgeState.GREEN_SUCCESS;
    case ReadableJobStatus.DEPOSIT_DUE:
    case ReadableJobStatus.PAYMENT_PENDING:
      return StatusBadgeState.AMBER_PENDING;
    case ReadableJobStatus.DECLINED:
    case ReadableJobStatus.EXPIRED:
    case ReadableJobStatus.BOUNCED:
    case ReadableJobStatus.OVERDUE:
      return StatusBadgeState.RED_FAILURE;
    case ReadableJobStatus.ARCHIVED:
    case ReadableJobStatus.INACTIVE:
      return StatusBadgeState.GHOST;
    case ReadableJobStatus.DRAFT:
      return StatusBadgeState.GREY_INITIAL;
    default:
      return StatusBadgeState.BLUE_NEUTRAL;
  }
};

export const getJobStatusPLBadgeState = (
  status?: ReadableJobStatus
): BadgeColourVariant => {
  switch (status) {
    case ReadableJobStatus.ACCEPTED:
    case ReadableJobStatus.DEPOSIT_PAID:
    case ReadableJobStatus.PAID:
      return 'teal';
    case ReadableJobStatus.DEPOSIT_DUE:
    case ReadableJobStatus.PAYMENT_PENDING:
      return 'yellow';
    case ReadableJobStatus.DECLINED:
    case ReadableJobStatus.EXPIRED:
    case ReadableJobStatus.BOUNCED:
    case ReadableJobStatus.OVERDUE:
      return 'red';
    case ReadableJobStatus.ARCHIVED:
    case ReadableJobStatus.INACTIVE:
      return 'black';
    default:
      return 'blue';
  }
};

export const canRecordDepositPayment = (job: Job, dealVersion: number) => {
  if (dealVersion >= 2) return false;
  const isJobSentQuoteEstimate = isSentQuoteEstimate(job.status);
  const acceptedAfterDeclined = moment(job.acceptedAt).isAfter(job.declinedAt);

  if (!isJobSentQuoteEstimate) {
    return false;
  }
  if (isJobDeclined(job)) {
    if (acceptedAfterDeclined) {
      return true;
    } else return false;
  } else return true;
};

export const canRecordInvoicePayment = (
  job: Job,
  dealVersion: number
): boolean => {
  if (dealVersion >= 2) return false;
  const jobIsQuoteOrEstimate = isQuoteOrEstimate(job.status);
  if (jobIsQuoteOrEstimate) return false;
  const isSentJob = jobHasSent(job.status);
  return isSentJob;
};

export const getJobType = (job: Pick<BaseJob, 'status'>): JobType => {
  if (isInvoice(job.status)) {
    return 'Invoice';
  } else if (isQuote(job.status)) {
    return 'Quote';
  } else if (isEstimate(job.status)) {
    return 'Estimate';
  } else {
    return 'Job';
  }
};

export const canResendJob = (
  job: Job,
  customer: Customer,
  hasRelatedJobInformation: boolean
): boolean => {
  const primaryContact = getJobContactFromCustomer(customer, job.contactId);

  return (
    jobHasSent(job.status) &&
    !job.archivedAt &&
    (isQuote(job.status) ? !hasRelatedJobInformation : true) &&
    !!primaryContact?.emailAddress
  );
};

export const canEditSentJob = (
  job: BaseJob,
  jobPayments: JobPayment[],
  hasRelatedJobInformation: boolean
): boolean => {
  const nonFailedPayments = jobPayments?.filter(
    (x) => !x.paymentFailedConfirmationAt
  );

  if (nonFailedPayments?.length) {
    return false;
  }
  if (isSentQuoteEstimate(job.status)) {
    return !hasRelatedJobInformation && !job.acceptedAt;
  } else if (job.status === JobStatus.INVOICED) {
    return true;
  }
  return false;
};

export const canUnacceptProposal = (
  job: Job,
  jobPayments?: JobPayment[],
  hasRelatedJob?: boolean,
  dealVersion = 1
): boolean => {
  if (dealVersion > 1) return false;

  return (
    isSentQuoteEstimate(job.status) &&
    !!job.acceptedAt &&
    !jobPayments?.length &&
    !hasRelatedJob
  );
};

export const getOutstandingPaymentValue = (
  job: Job,
  jobContent: JobContent,
  jobPayments: JobPayment[]
): number | null => {
  let value = null;

  const jobIsInvoice = isInvoice(job.status);

  if (jobIsInvoice && !!job.sentAt) {
    value =
      jobContent.total -
      getTotalCompletedPaymentValueFromJobPayments(jobPayments);
  } else if (!jobIsInvoice && !!job.acceptedAt) {
    value =
      (jobContent.calculatedDepositAmount || 0) -
      getCompletedDepositPaymentValueFromJobPayments(jobPayments);
  }

  if (value === null) return value;
  return value < 0 ? 0 : Math.ceil(value);
};

export const canDuplicateJob = (job: Job): boolean => {
  return !job.isPartialInvoice;
};

export const getJobPDFName = (
  job: Pick<BaseJob, 'status' | 'acceptedAt' | 'customReference' | 'reference'>
): string => {
  return (
    (job.acceptedAt ? 'Signed_' : '') +
    getJobType(job) +
    '_' +
    (job.customReference || String(job.reference)) +
    '.pdf'
  );
};
