import {
  FunctionComponent,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import * as subscriptionActions from '@payaca/store/subscription/subscriptionActions';
import * as accountActions from '@payaca/store/account/accountActions';

import ManageSubscriptionPageContent from '@/ui/components/manageSubscription/manageSubscriptionPageContent/ManageSubscriptionPageContent';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

import { useSelector } from '@/api/state';

import {
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';
import { Nullish } from '@payaca/utilities/types';

import { trackPurchaseWithAwin } from '@/utils/awin';

import { currencyCodeForRegion } from '@payaca/helpers/currencyHelper';
import { getProductPriceCostExcludingVat } from '@payaca/helpers/subscriptionHelper';

import { useAccount } from '@/utils/storeHooks';

import { useLocation } from 'react-router';
import QueryString from 'qs';
import Card from '@payaca/components/plCard/Card';
import SelectSubscriptionControl from './components/SelectSubscriptionControl';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import { IntercomAPI } from 'react-intercom';

const ManageSubscriptionPage: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch();

  const location = useLocation();

  const { view } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const accountAccessInformation = useSelector(
    (state) => state.account.accountAccessInformation
  );

  const hasActiveSubscription = useMemo(
    () => !!accountAccessInformation?.hasActiveSubscription,
    [accountAccessInformation]
  );

  const [showSelectSubscriptionView, setShowSelectSubscriptionView] =
    useState<boolean>(!hasActiveSubscription || view === 'select-subscription');

  const canBookDemo = useMemo(() => {
    if (!accountAccessInformation) return false;
    return (
      !accountAccessInformation.hasActiveSubscription &&
      !accountAccessInformation.hasInactiveSubscription
    );
  }, [accountAccessInformation]);

  const requiresUpdateBusinessSettingsPromptOnSubscribe = useMemo(() => {
    return canBookDemo;
  }, [canBookDemo]);

  const account = useAccount();

  const onCreateSubscriptionSuccess = useCallback(
    ({
      subscriptionProduct,
      recurringInterval,
      additionalUserSeats,
    }: {
      subscriptionProduct: Nullish<SubscriptionProduct>;
      recurringInterval: RecurringInterval;
      additionalUserSeats: number;
    }) => {
      dispatch(
        accountActions.setRequiresUpdateBusinessSettingsPrompt(
          requiresUpdateBusinessSettingsPromptOnSubscribe
        )
      );
      if (subscriptionProduct) {
        const price =
          recurringInterval === RecurringInterval.MONTH
            ? subscriptionProduct.monthlyPrice
            : subscriptionProduct.annualPrice;
        if (!price) {
          return;
        }
        trackPurchaseWithAwin({
          currency: (currencyCodeForRegion as any)[account.region],
          amountInMajorCurrencyUnits:
            getProductPriceCostExcludingVat(price, additionalUserSeats) / 100,
          orderReference: `${account.id}:${
            subscriptionProduct.stripeId
          }:${Date.now()}`,
        });
      }
    },
    [dispatch, requiresUpdateBusinessSettingsPromptOnSubscribe, account]
  );

  useEffect(() => {
    dispatch(subscriptionActions.requestGetAccountSubscription());
  }, []);

  return (
    <AuthenticatedPageWrapper
      className="bg-gray-50"
      previousPageNavigationConfig={
        hasActiveSubscription && showSelectSubscriptionView
          ? { onClick: () => setShowSelectSubscriptionView(false) }
          : undefined
      }
      title="Manage subscription"
    >
      <div className="p-4 pt-0">
        <Card>
          <Card.Body>
            {showSelectSubscriptionView ? (
              <SelectSubscriptionControl
                onUpdateSubscriptionSuccess={onCreateSubscriptionSuccess}
              />
            ) : (
              <ManageSubscriptionPageContent
                showSelectSubscriptionView={() =>
                  setShowSelectSubscriptionView(true)
                }
              />
            )}
            <div className="flex items-center justify-center gap-x-3 mt-6">
              <span className="text-gray-500">Need help?</span>
              <Button
                variant={EBtnVariant.Outline}
                size={EBtnSize.Small}
                onClick={() => IntercomAPI('show')}
              >
                Get in touch
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </AuthenticatedPageWrapper>
  );
};

export default ManageSubscriptionPage;
