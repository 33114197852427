import useGetProjectCustomFields from '@/api/queries/project/useGetProjectCustomFields';
import { Deal } from '@payaca/types/dealTypes';
import { FC, useCallback, useMemo } from 'react';
import { AutosaveCustomField } from '@payaca/custom-fields-react/components/AutosaveCustomField';
import {
  CustomFieldDefinition,
  CustomFieldType,
  CustomFieldValueHelperType,
} from '@payaca/custom-fields/types/index';
import useUpdateCustomFieldValuesForProject from '@/api/mutations/project/useUpdateCustomFieldValuesForProject';
import AccordionWithChildCount from '@payaca/components/plAccordionWithChildCount/AccordionWithChildCount';
import { useQueryClient } from '@tanstack/react-query';
import projectKeys from '@/api/queries/project/keyFactory';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import { Link } from 'react-router-dom';

export const ProjectCustomFieldGroup: FC<{ projectId: Deal['id'] }> = ({
  projectId,
}) => {
  const queryClient = useQueryClient();
  const { customFields } = useGetProjectCustomFields(projectId);
  const { mutateAsync: updateCustomFieldValuesMutation, isLoading } =
    useUpdateCustomFieldValuesForProject();

  const handleSave = useCallback(
    async (customFieldIdentifier: string, value: any) => {
      await updateCustomFieldValuesMutation({
        projectId: projectId.toString(),
        fieldValues: [
          {
            identifier: customFieldIdentifier,
            value: value ? JSON.stringify(value) : null,
          },
        ],
      }).then(() => {
        queryClient.invalidateQueries({
          queryKey: projectKeys.customFields(projectId),
        });
      });
    },
    [projectId]
  );

  const populatedCustomFieldsCount = useMemo(() => {
    return (
      customFields?.filter(
        (x) => x.value !== null && x.value !== undefined && x.value !== ''
      ).length || 0
    );
  }, [customFields]);

  if (!customFields?.length) {
    return (
      <AccordionWithChildCount title="Custom Fields" count="0">
        <div className="mt-2 flex flex-col gap-4">
          <PermissionGuard
            renderIfHasPermissions={[
              AccountsPermissions.MANAGE_CUSTOM_FIELD_GROUPS,
            ]}
          >
            <p>
              <Link to={'/companySettings/custom-fields'}>
                Create custom fields
              </Link>
            </p>
          </PermissionGuard>
          <PermissionGuard
            renderIfMissingPermissions={[
              AccountsPermissions.MANAGE_CUSTOM_FIELD_GROUPS,
            ]}
          >
            <p>Contact admin to create custom fields</p>
          </PermissionGuard>
        </div>
      </AccordionWithChildCount>
    );
  }

  return (
    <AccordionWithChildCount
      title="Custom Fields"
      count={`${populatedCustomFieldsCount} of ${customFields.length}`}
    >
      <div className="mt-2 flex flex-col gap-4">
        {customFields?.map((customField, i) => {
          return (
            <AutosaveCustomField
              definition={
                customField.field as CustomFieldDefinition<CustomFieldType>
              }
              initialValue={
                customField.value
                  ? (JSON.parse(
                      customField.value
                    ) as CustomFieldValueHelperType[CustomFieldType])
                  : null
              }
              key={`${i}-${customField.field.identifier}`}
              saveFunction={(value) => {
                return handleSave(customField.field.identifier, value);
              }}
            />
          );
        })}
      </div>
    </AccordionWithChildCount>
  );
};
