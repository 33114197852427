import {
  AggregateValueChartData,
  KeyIndicatorData,
  KeyIndicatorsData,
  KeyIndicatorType,
  SubscriptionUsageData,
} from '@payaca/types/analyticsTypes';
import {
  DataType,
  MetricType,
  TagMetricAnalyticsData,
} from '@payaca/types/tagAnalyticsTypes';
import { TaggableEntityType } from '@payaca/types/tagTypes';
import { DeclareAsyncAction } from '../types';

export interface AnalyticsState {
  sentQuotesAggregateChartData: AggregateValueChartData | null;
  sentQuoteValueAggregateChartData: AggregateValueChartData | null;
  sentInvoicesAggregateChartData: AggregateValueChartData | null;
  sentInvoiceValueAggregateChartData: AggregateValueChartData | null;
  isGettingSubscriptionUsageData: boolean;
  subscriptionUsageData: SubscriptionUsageData | null;
  keyIndicatorsData: KeyIndicatorsData | null;
}

export enum AnalyticsActionTypes {
  REQUEST_GET_SENT_QUOTES_AGGREGATE_CHART_DATA = 'analytics.requestGetSentQuotesAggregateChartData',
  GET_SENT_QUOTES_AGGREGATE_CHART_DATA_SUCCESS = 'analytics.getSentQuotesAggregateChartDataSuccess',
  GET_SENT_QUOTES_AGGREGATE_CHART_DATA_FAILURE = 'analytics.getSentQuotesAggregateChartDataFailure',
  CLEAR_SENT_QUOTES_AGGREGATE_CHART_DATA = 'analytics.clearSentQuotesAggregateChartData',

  REQUEST_GET_SENT_QUOTE_VALUE_AGGREGATE_CHART_DATA = 'analytics.requestGetSentQuoteValueAggregateChartData',
  GET_SENT_QUOTE_VALUE_AGGREGATE_CHART_DATA_SUCCESS = 'analytics.getSentQuoteValueAggregateChartDataSuccess',
  GET_SENT_QUOTE_VALUE_AGGREGATE_CHART_DATA_FAILURE = 'analytics.getSentQuoteValueAggregateChartDataFailure',
  CLEAR_SENT_QUOTE_VALUE_AGGREGATE_CHART_DATA = 'analytics.clearSentQuoteValueAggregateChartData',

  REQUEST_GET_SENT_INVOICES_AGGREGATE_CHART_DATA = 'analytics.requestGetSentInvoicesAggregateChartData',
  GET_SENT_INVOICES_AGGREGATE_CHART_DATA_SUCCESS = 'analytics.getSentInvoicesAggregateChartDataSuccess',
  GET_SENT_INVOICES_AGGREGATE_CHART_DATA_FAILURE = 'analytics.getSentInvoicesAggregateChartDataFailure',
  CLEAR_SENT_INVOICES_AGGREGATE_CHART_DATA = 'analytics.clearSentInvoicesAggregateChartData',

  REQUEST_GET_SENT_INVOICE_VALUE_AGGREGATE_CHART_DATA = 'analytics.requestGetSentInvoiceValueAggregateChartData',
  GET_SENT_INVOICE_VALUE_AGGREGATE_CHART_DATA_SUCCESS = 'analytics.getSentInvoiceValueAggregateChartDataSuccess',
  GET_SENT_INVOICE_VALUE_AGGREGATE_CHART_DATA_FAILURE = 'analytics.getSentInvoiceValueAggregateChartDataFailure',
  CLEAR_SENT_INVOICE_VALUE_AGGREGATE_CHART_DATA = 'analytics.clearSentInvoiceValueAggregateChartData',

  REQUEST_GET_SUBSCRIPTION_USAGE_DATA = 'analytics.requestGetSubscriptionUsageData',
  GET_SUBSCRIPTION_USAGE_DATA_SUCCESS = 'analytics.getSubscriptionUsageDataSuccess',
  GET_SUBSCRIPTION_USAGE_DATA_FAILURE = 'analytics.getSubscriptionUsageDataFailure',
  CLEAR_SUBSCRIPTION_USAGE_DATA = 'analytics.clearSubscriptionUsageData',

  REQUEST_GET_KEY_INDICATORS_DATA = 'analytics.requestGetKeyIndicatorsData',

  GET_TAG_METRIC_ANALYTICS_DATA_REQUEST = 'analytics.getTagMetricAnalyticsData:request',
}

export interface AnalyticsSagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => string;
}

export type GetTagMetricAnalyticsData = DeclareAsyncAction<
  AnalyticsActionTypes,
  {
    request: {
      type: AnalyticsActionTypes.GET_TAG_METRIC_ANALYTICS_DATA_REQUEST;
      payload: {
        entityType: TaggableEntityType;
        metricType: MetricType;
        callback?: (
          tagMetricAnalyticsData: TagMetricAnalyticsData<DataType>
        ) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;

export type GetKeyIndicatorsData = DeclareAsyncAction<
  AnalyticsActionTypes,
  {
    request: {
      type: AnalyticsActionTypes.REQUEST_GET_KEY_INDICATORS_DATA;
      payload: {
        indicatorTypes: KeyIndicatorType[];
        callback?: (
          keyIndicatorData: KeyIndicatorData<KeyIndicatorType>[]
        ) => void;
        onErrorCallback?: (error: Error) => void;
      };
    };
  }
>;
