import React, { FunctionComponent, useEffect } from 'react';
import './SentInvoicesChart.css';
import { AggregateValueBarChart, CategoriesKey } from '@/ui/components';
import { useDispatch } from 'react-redux';
import * as analyticsActions from '@payaca/store/analytics/analyticsActions';
import { AggregateValueChartData } from '@payaca/types/analyticsTypes';
import * as d3 from 'd3';
import { useSelector } from '@/api/state';

const formatXAxisTickValue = (value: number) => {
  return Number.isInteger(value) ? d3.format('d')(value) : '';
};

const SentInvoicesChart: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch();

  const sentInvoicesAggregateChartData: AggregateValueChartData | null =
    useSelector((state) => {
      return state.analytics.sentInvoicesAggregateChartData;
    });

  useEffect(() => {
    dispatch(analyticsActions.requestGetSentInvoicesAggregateChartData());
    return () => {
      dispatch(analyticsActions.clearSentInvoicesAggregateChartData());
    };
  }, []);

  return (
    <div className={`sent-invoices-chart`}>
      <CategoriesKey
        categories={sentInvoicesAggregateChartData?.aggregateCategories}
      />
      <AggregateValueBarChart
        data={sentInvoicesAggregateChartData}
        xAxisLabel="Invoices sent"
        yAxisLabel="Month sent"
        overrideChartPadding={{ left: 75, bottom: 35, top: 0, right: 10 }}
        formatXAxisTickValue={formatXAxisTickValue}
      />
    </div>
  );
};

export default SentInvoicesChart;
