import React, { FunctionComponent, PropsWithChildren } from 'react';

import './BodyWithSidePanelContentWrapper.sass';

type Props = {
  sidebarContent?: React.ReactNode;
  title?: React.ReactNode;
};

const BodyWithSidePanelContentWrapper: FunctionComponent<
  PropsWithChildren<Props>
> = ({
  sidebarContent,
  title,
  children,
}: PropsWithChildren<Props>): JSX.Element | null => {
  return (
    <div className="body-with-sidebar-content-wrapper flex-container">
      <div className="main">
        {title && (
          <div className="title-bar">
            {typeof title === 'string' ? <h1>{title}</h1> : title}
          </div>
        )}
        <div className="body-content">{children}</div>
      </div>
      <div className="sidebar">{sidebarContent}</div>
    </div>
  );
};

export default BodyWithSidePanelContentWrapper;
