import React from 'react';
import { Upload } from 'react-iconly';

import Button from '@payaca/components/button/Button';

type Props = {
  isUploadingCsv: boolean;
  onUploadFile: (file: File) => void;
};
const UploadTemplateSection = ({ isUploadingCsv, onUploadFile }: Props) => {
  return (
    <div className="upload-button-wrapper">
      <Button type={'submit'} isProcessing={isUploadingCsv}>
        <div className="icon-wrapper">
          <Upload set="light" />
        </div>
        <span>Upload</span>
      </Button>
      <label htmlFor={`file-upload`}></label>
      <input
        id={'file-upload'}
        type="file"
        accept=".csv"
        onChange={(e) => {
          // prevent file upload if mid-validate/upload
          if (!isUploadingCsv && e?.target?.files?.[0]) {
            onUploadFile(e.target.files[0]);
          }
          // Allow uploading of the same file
          if (e.target && e.target.value) {
            e.target.value = '';
          }
        }}
        hidden
      ></input>
    </div>
  );
};

export default UploadTemplateSection;
