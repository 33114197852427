import tinycolor from 'tinycolor2';

/**
 * Convert a colour to a colour with specified alpha
 */
export const getColourWithAlpha = (colour: string, alpha: number) => {
  const stripColourInstance = tinycolor(colour);
  stripColourInstance.setAlpha(alpha);
  return stripColourInstance.toRgbString();
};

export const getBackgroundColourForColour = (colour: string) => {
  const hsl = tinycolor(colour).toHsl();
  hsl.s = 20;
  hsl.l = 95;

  return tinycolor(hsl).toHslString();
};

export const getAdjustedReadableTextColourForBackground = (
  colour: string,
  backgroundColour: string
) => {
  // check is colour readable on background
  const isReadable = tinycolor.isReadable(colour, backgroundColour, {});

  if (isReadable) {
    return colour;
  }

  const isBackgroundLight = tinycolor(backgroundColour).isLight();
  if (isBackgroundLight) {
    // background is light, so darken text
    const darkenedColour = tinycolor(colour).darken(10).toString();
    return darkenedColour;
  }
  const lightenedColour = tinycolor(colour).lighten(10).toString();
  return lightenedColour;
};

/**
 * Get the most readable text colour for a given background colour
 */
export const getReadableTextColour = (
  colour: string,
  colourList = ['#263e59', 'white']
): string | undefined => {
  return tinycolor
    .mostReadable(colour, colourList, {
      includeFallbackColors: true,
    })
    .toHexString();
};

export const lightenProportionally = (colour: string) => {
  const colourInstance = tinycolor(colour);
  const luminance = colourInstance.getLuminance(); // 0 = black, 1 = white
  let lightenFactor = 5;
  if (luminance <= 0.1) {
    lightenFactor = 60;
  } else if (luminance <= 0.3) {
    lightenFactor = 55;
  } else if (luminance <= 0.5) {
    lightenFactor = 20;
  } else if (luminance <= 0.7) {
    lightenFactor = 10;
  }

  const lightenedColour = colourInstance.lighten(lightenFactor);
  if (lightenedColour.toHex() === 'ffffff') {
    lightenedColour.darken(1);
  }
  return lightenedColour.toHexString();
};

export const isValidColour = (colour: string) => tinycolor(colour).isValid();
