import React, { FC, useState } from 'react';
import { useParams } from 'react-router';

import {
  IntegratedEmailProjectActivityEvent,
  ProjectActivityEvent,
} from '../../../gql/graphql';

import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import SendIntegratedEmailModal from '../sendIntegratedEmailModal/SendIntegratedEmailModal';

import { IntegratedEmailsPermissions } from '@payaca/permissions/integrated-emails/integrated-emails.permissions';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { IntegratedEmail } from '@payaca/types/integratedEmails';

type Props = {
  projectActivityEvent:
    | ProjectActivityEvent
    | IntegratedEmailProjectActivityEvent;
};

const DealActivityEventPrimaryAction: FC<Props> = (props) => {
  const { projectActivityEvent } = props;

  switch (projectActivityEvent.__typename) {
    case 'IntegratedEmailProjectActivityEvent':
      if (projectActivityEvent.type === 'integratedEmailReceived') {
        return (
          <PermissionGuard
            renderIfHasPermissions={[IntegratedEmailsPermissions.SEND_EMAIL]}
          >
            <IntegratedEmailReceivedReply
              integratedEmailId={+projectActivityEvent.integratedEmailId}
            />
          </PermissionGuard>
        );
      }
      return null;
    default:
      return null;
  }
};

const IntegratedEmailReceivedReply = (props: {
  integratedEmailId: IntegratedEmail['id'];
}) => {
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);
  const { dealId } = useParams<{ dealId: string }>();

  return (
    <>
      <Button
        variant={EBtnVariant.LinkInline}
        className="mr-4"
        onClick={() => {
          setIsSendEmailModalOpen(true);
        }}
      >
        Reply
      </Button>
      <SendIntegratedEmailModal
        onClose={() => setIsSendEmailModalOpen(false)}
        dealId={+dealId}
        {...props}
        isOpen={isSendEmailModalOpen}
      />
    </>
  );
};
export default DealActivityEventPrimaryAction;
