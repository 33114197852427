export enum StatusBadgeState {
  GREEN_SUCCESS = 'green-success',
  RED_FAILURE = 'red-failure',
  AMBER_PENDING = 'amber-pending',
  BLUE_NEUTRAL = 'blue-neutral',
  GREY_INITIAL = 'grey-draft',
  GHOST = 'ghost',
}

export const StatusBadgeConfigMap: {
  [key: string]: { colour?: string; backgroundColour?: string };
} = {
  [StatusBadgeState.GREEN_SUCCESS]: { colour: '#4ea83d' },
  [StatusBadgeState.RED_FAILURE]: { colour: '#750a0a' },
  [StatusBadgeState.AMBER_PENDING]: { colour: '#725604' },
  [StatusBadgeState.BLUE_NEUTRAL]: { colour: '#263e59' },
  [StatusBadgeState.GREY_INITIAL]: {
    colour: '#263e59',
    backgroundColour: '#99b1cc',
  },
  [StatusBadgeState.GHOST]: { colour: '#5b7696', backgroundColour: '#f9fcff' },
};
