import { AppState } from '@payaca/store';
import { useEffect, useState } from 'react';
import { Selector, TypedUseSelectorHook } from 'react-redux';
import { store } from './index';

export const useMagicSelector: TypedUseSelectorHook<AppState> = (selector) => {
  const [state, setState] = useState(store.getState());
  useEffect(() => {
    let isMounted = true;
    const unsubscribe = store.subscribe(() => {
      if (isMounted) {
        setState(store.getState());
      }
    });
    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, []);
  return selector(state as unknown as AppState);
};
