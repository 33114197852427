import {
  CsvGuidelines,
  CsvUpload,
  CsvUploadDataType,
  CsvUploadStatus,
} from '@payaca/types/csvUploadTypes';
import {
  Action,
  DeclareAsyncAction,
  ErrorAction,
  UnpackAsyncAction,
} from '../types';

export interface CsvUploadState {
  isGettingCsvTemplate: boolean;
  csvDownloadUrl: string | undefined;
  csvGuidelines: CsvGuidelines;
  csvTemplateError: string | null;

  // isUploadingCsv: boolean;
  // uploadCsvError: string | string[] | null;
  // uploadCsvWarning: string[] | null;

  store: Record<
    CsvUpload<CsvUploadDataType>['id'],
    CsvUpload<CsvUploadDataType>
  >;
}

export enum ActionType {
  REQUEST_GET_CSV_TEMPLATE = 'csvUpload.requestGetCsvTemplate',
  REQUEST_GET_CSV_UPDATE_TEMPLATE = 'csvUpload.requestGetCsvUpdateTemplate',
  GET_CSV_TEMPLATE_SUCCESS = 'csvUpload.getCsvTemplateSuccess',
  GET_CSV_TEMPLATE_FAILURE = 'csvUpload.getCsvTemplateFailure',

  REQUEST_UPLOAD_CSV = 'csvUpload.requestUploadCsv',
  UPLOAD_CSV_SUCCESS = 'csvUpload.uploadCsvSuccess',
  UPLOAD_CSV_FAILURE = 'csvUpload.uploadCsvFailure',

  CLEAR_CSV = 'csvUpload.clearCsv',

  GET_CSV_UPLOADS_REQUEST = 'csvUpload.getCsvUploads:request',
  GET_CSV_UPLOADS_SUCCESS = 'csvUpload.getCsvUploads:success',
  GET_CSV_UPLOADS_FAILURE = 'csvUpload.getCsvUploads:failure',

  CREATE_CSV_UPLOAD_REQUEST = 'csvUpload.createCsvUpload:request',
  CREATE_CSV_UPLOAD_SUCCESS = 'csvUpload.createCsvUpload:success',
  CREATE_CSV_UPLOAD_FAILURE = 'csvUpload.createCsvUpload:failure',

  UPDATE_CSV_UPLOAD_STATUS_REQUEST = 'csvUpload.updateCsvUploadStatus:request',
  UPDATE_CSV_UPLOAD_STATUS_SUCCESS = 'csvUpload.updateCsvUploadStatus:success',
  UPDATE_CSV_UPLOAD_STATUS_FAILURE = 'csvUpload.updateCsvUploadStatus:failure',

  CONFIRM_CONTINUE_PROCESSING_REQUEST = 'csvUpload.confirmContinueProcessing:request',

  GET_FILE_DOWNLOAD_URL_REQUEST = 'csvUpload.getFileDownloadUrl:request',
}

export type GetCsvUploadsAction = DeclareAsyncAction<
  ActionType,
  {
    request: { type: ActionType.GET_CSV_UPLOADS_REQUEST };
    success: {
      type: ActionType.GET_CSV_UPLOADS_SUCCESS;
      payload: Array<CsvUpload<CsvUploadDataType>>;
    };
    failure: { type: ActionType.GET_CSV_UPLOADS_FAILURE };
  }
>;

export type CreateCsvUploadAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CREATE_CSV_UPLOAD_REQUEST;
      payload: {
        dataType: CsvUploadDataType;
        fileName: string;
        fileSize: number;
      };
    };
    success: {
      type: ActionType.CREATE_CSV_UPLOAD_SUCCESS;
      payload: CsvUpload<CsvUploadDataType>;
    };
    failure: { type: ActionType.CREATE_CSV_UPLOAD_FAILURE };
  }
>;

export type UpdateCsvUploadStatusAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.UPDATE_CSV_UPLOAD_STATUS_REQUEST;
      payload: {
        csvUploadId: CsvUpload<CsvUploadDataType>['id'];
        status:
          | 'uploading'
          | 'uploadAborted'
          | 'uploadComplete'
          | 'uploadFailed';
      };
    };
    success: {
      type: ActionType.UPDATE_CSV_UPLOAD_STATUS_SUCCESS;
      payload: CsvUpload<CsvUploadDataType>;
    };
    failure: { type: ActionType.UPDATE_CSV_UPLOAD_STATUS_FAILURE };
  }
>;

export type ConfirmContinueProcessingCsvUploadAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.CONFIRM_CONTINUE_PROCESSING_REQUEST;
      payload: { csvUploadId: CsvUpload<CsvUploadDataType>['id'] };
    };
  }
>;

export type GetFileDownloadUrlAction = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_FILE_DOWNLOAD_URL_REQUEST;
      payload: { csvUploadId: CsvUpload<CsvUploadDataType>['id'] };
      meta: (url: string) => void;
    };
  }
>;

export type AnyAction =
  | Action<ActionType>
  | ErrorAction<ActionType>
  | UnpackAsyncAction<GetCsvUploadsAction>
  | UnpackAsyncAction<CreateCsvUploadAction>
  | UnpackAsyncAction<UpdateCsvUploadStatusAction>
  | UnpackAsyncAction<ConfirmContinueProcessingCsvUploadAction>
  | UnpackAsyncAction<GetFileDownloadUrlAction>;

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}
