import React, { FC, PropsWithChildren } from 'react';
import BaseButton, { IButtonProps } from '../plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '../plButton/useButtonClassName';

export const Banner: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="prose bg-gradient-to-r from-purple-600 to-blue-400">
      <div className="mx-auto max-w-[150rem] px-4 py-4 sm:px-6 lg:px-8">
        {children}
      </div>
    </div>
  );
};

export const Button: FC<Omit<IButtonProps, 'colour'>> = (buttonProps) => {
  return (
    <BaseButton
      {...buttonProps}
      variant={buttonProps.variant || EBtnVariant.Outline}
      colour={EBtnColour.White}
      size={buttonProps.size || EBtnSize.Small}
    />
  );
};

export const AnnouncementBanner = Object.assign(Banner, {
  Button,
});
