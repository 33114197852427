import { SelectOptionsPreset } from '@payaca/types/formElementTypes';

const toLabelAndValue = (x: string) => ({
  label: x,
  value: x,
});

export const selectOptionsPresets: Record<
  SelectOptionsPreset,
  Array<{ label: string; value: string }>
> = {
  'electrical:conductorMaterial': ['Aluminium', 'Copper', 'Steel'].map(
    toLabelAndValue
  ),

  'electrical:earthingConductorCsa': [
    '0.5',
    '0.75',
    '1',
    '1.25',
    '1.5',
    '2.5',
    '4',
    '6',
    '10',
    '16',
    '25',
    '35',
    '50',
    '75',
    '95',
    '120',
    '150',
    '185',
    '200',
    '240',
    '300',
    '380',
    '400',
    '480',
    '500',
    '600',
    '630',
    '740',
    '800',
    '960',
    '1000',
  ].map(toLabelAndValue),

  'electrical:conductorCsa': [
    '0.5',
    '0.75',
    '1',
    '1.25',
    '1.5',
    '2.5',
    '4',
    '6',
    '10',
    '16',
    '25',
    '35',
    '50',
    '70',
    '95',
    '120',
    '150',
    '185',
    '200',
    '240',
    '300',
    '380',
    '400',
    '480',
    '500',
    '600',
    '630',
    '740',
    '800',
    '960',
    '1000',
    '1200',
    'Arm',
    'Sheath',
    'Con',
    'Trunk',
    'LIM',
    'N/A',
    '2x0.5',
    '2x0.75',
    '2x1',
    '2x1.25',
    '2x1.5',
    '2x2.5',
    '2x4',
    '2x6',
    '2x10',
    '2x16',
    '2x25',
    '2x35',
    '2x50',
    '2x70',
    '2x95',
    '2x120',
    '2x150',
    '2x185',
    '2x200',
    '2x240',
    '2x300',
    '2x380',
    '2x400',
    '2x480',
    '2x500',
    '2x600',
    '2x630',
    '2x740',
    '2x800',
    '2x960',
    '2x1000',
    '2x1200',
    '3x0.5',
    '3x0.75',
    '3x1',
    '3x1.25',
    '3x1.5',
    '3x2.5',
    '3x4',
    '3x6',
    '3x10',
    '3x16',
    '3x25',
    '3x35',
    '3x50',
    '3x70',
    '3x95',
    '3x120',
    '3x150',
    '3x185',
    '3x200',
    '3x240',
    '3x300',
    '3x380',
    '3x400',
    '3x480',
    '3x500',
    '3x600',
    '3x630',
    '3x740',
    '3x800',
    '3x960',
    '3x1000',
    '3x1200',
    '4x0.5',
    '4x0.75',
    '4x1',
    '4x1.25',
    '4x1.5',
    '4x2.5',
    '4x4',
    '4x6',
    '4x10',
    '4x16',
    '4x25',
    '4x35',
    '4x50',
    '4x70',
    '4x95',
    '4x120',
    '4x150',
    '4x185',
    '4x200',
    '4x240',
    '4x300',
    '4x380',
    '4x400',
    '4x480',
    '4x500',
    '4x600',
    '4x630',
    '4x740',
    '4x800',
    '4x960',
    '4x1000',
    '4x1200',
  ].map(toLabelAndValue),

  'electrical:wiringType': [
    'A: Thermoplastic insulated / sheathed cables',
    'B: Thermoplastic cables in metallic conduit',
    'C: Thermoplastic cables in non-metallic conduit',
    'D: Thermoplastic cables in metallic trunking',
    'E: Thermoplastic cables in non-metallic trunking',
    'F: Thermoplastic / SWA cables',
    'G: Thermosetting / SWA cables',
    'H: Mineral-insulated cables',
    'O: other - state',
    'LIM',
    'N/A',
  ].map((label) => ({
    label,
    value: label.split(':')[0],
  })),

  'electrical:maxDisconnectionTime': [
    '0.2',
    '0.3',
    '0.4',
    '0.8',
    '1',
    '5',
    'N/A',
  ].map(toLabelAndValue),

  'electrical:wiringReferenceMethods': [
    {
      label: 'A: Enclosed in conduit in thermally insulating wall',
      value: 'A',
    },
    { label: 'B: Enclosed in conduit or trunking on a wall', value: 'B' },
    { label: 'C: Clipped direct', value: 'C' },
    { label: 'D: Direct in the ground or in Ducting', value: 'D' },
    {
      label: 'E: Free air / perforated cable tray (multi-core cable)',
      value: 'E',
    },
    {
      label: 'F: Free air / perforated cable tray (single-core cable)',
      value: 'F',
    },
    { label: 'G: Free air / flat spaced by 1 cable diameter', value: 'G' },
    {
      label:
        '100 (T&E): Cable above a plasterboard ceiling covered by a thermal insulation not exceeding 100mm in thickness',
      value: '100',
    },
    {
      label:
        '101 (T&E): Cable above a plasterboard ceiling covered by a thermal insulation exceeding 100 mm in thickness',
      value: '101',
    },
    {
      label:
        '102 (T&E): Cable in a stud wall with thermal insulation with cable touching the inner wall surface',
      value: '102',
    },
    {
      label:
        '103 (T&E): Cable in a stud wall with thermal insulation with cable not touching the inner wall surface',
      value: '103',
    },
    { label: 'N/A: Not Applicable', value: 'N/A' },
    { label: 'LIM', value: 'LIM' },
  ],

  'electrical:overcurrentProtectiveDevice': [
    'BSEN 60947-3 Isolator',
    'BS 5419 Isolator',
    'BS 3036 Fuse',
    'BS 4265',
    'BS 60269-2',
    'BS 1361 Fuse HBC Domestic Type 1',
    'BS 1361 Fuse HBC Domestic Type 2',
    'BS 1362 Fuse Domestic',
    'BS 88-2 Fuse System E (bolted)',
    'BS 88-2 Fuse System G (clip-in)',
    'BS 88-2 Fuse HRC gM (Motor)',
    'BS 88-2 Fuse HRC gG (General)',
    'BS 88-3 Fuse System C',
    'BSEN 60898 MCB Type B',
    'BSEN 60898 MCB Type C',
    'BSEN 60898 MCB Type D',
    'BSEN 60947-2',
    'BSEN 60947-2 MCB Type B',
    'BSEN 60947-2 MCB Type C',
    'BSEN 60947-2 MCB Type D',
    'BSEN 60947-2 MCCB',
    'BSEN 60947-2 ACB',
    'BS 3871 MCB Type 1',
    'BS 3871 MCB Type 2',
    'BS 3871 MCB Type 3',
    'BS 3871 MCB Type 4',
    'BSEN 4752-1 Type B',
    'BSEN 4752-1 Type D',
    'BSEN 7288 RCD',
    'BSEN 4293 RCD',
    'BSEN 61008-1 RCD',
    'BSEN 61008-1 AC RCD',
    'BSEN 61008-1 A RCD',
    'BSEN 61008-1 B RCD',
    'BSEN 61008-1 F RCD',
    'BSEN 61009-1 RCBO B (OCPD Type)',
    'BSEN 61009-1 RCBO C (OCPD Type)',
    'BSEN 61009-1 RCBO D (OCPD Type)',
    'BSEN 61009-1 RCBO AC (RCD Type)',
    'BSEN 61009-1 RCBO A (RCD Type)',
    'BSEN 61009-1 RCBO B (RCD Type)',
    'BSEN 61009-1 RCBO F (RCD Type)',
    'BSEN 62606 B RCD (OCPD Type)',
    'BSEN 62606 C RCD (OCPD Type)',
    'BSEN 62606 D RCD (OCPD Type)',
    'BSEN 62606 AC RCD (RCD Type)',
    'BSEN 62606 A RCD (RCD Type)',
    'BSEN 62606 F RCD (RCD Type)',
    'BSEN 62606 B RCD (RCD Type)',
    'BSEN 62423 B RCD (OCPD Type)',
    'BSEN 62423 C RCD (OCPD Type)',
    'BSEN 62423 D RCD (OCPD Type)',
    'BSEN 62423 B RCD (RCD Type)',
    'BSEN 62423 F RCD (RCD Type)',
    'N/A',
    'BSEN 62602 (No Type)',
    '61643 Type 1 SPD',
    '61643 Type 2 SPD',
    '61643 Type 3 SPD',
    '61643 Type NA SPD',
    'Non-verifiable',
    'Limitation',
  ].map(toLabelAndValue),

  'electrical:supplierProtectiveDevice': [
    'BSEN 60947-3 Isolator',
    'BS 5419 Isolator',
    'BS 3036 Fuse',
    'BS 4265',
    'BS 60269-2',
    'BS 1361 Fuse HBC Domestic Type 1',
    'BS 1361 Fuse HBC Domestic Type 2',
    'BS 1362 Fuse Domestic',
    'BS 88-2 Fuse System E (bolted)',
    'BS 88-2 Fuse System G (clip-in)',
    'BS 88-2 Fuse HRC gM (Motor)',
    'BS 88-2 Fuse HRC gG (General)',
    'BS 88-3 Fuse System C',
    'BSEN 60898 MCB Type B',
    'BSEN 60898 MCB Type C',
    'BSEN 60898 MCB Type D',
    'BSEN 60947-2',
    'BSEN 60947-2 MCB Type B',
    'BSEN 60947-2 MCB Type C',
    'BSEN 60947-2 MCB Type D',
    'BSEN 60947-2 MCCB',
    'BSEN 60947-2 ACB',
    'BS 3871 MCB Type 1',
    'BS 3871 MCB Type 2',
    'BS 3871 MCB Type 3',
    'BS 3871 MCB Type 4',
    'BSEN 4752-1 Type B',
    'BSEN 4752-1 Type D',
    'BSEN 7288 RCD',
    'BSEN 4293 RCD',
    'BSEN 61008-1 RCD',
    'BSEN 61008-1 AC RCD',
    'BSEN 61008-1 A RCD',
    'BSEN 61008-1 B RCD',
    'BSEN 61008-1 F RCD',
    'BSEN 61009-1 RCBO B (OCPD Type)',
    'BSEN 61009-1 RCBO C (OCPD Type)',
    'BSEN 61009-1 RCBO D (OCPD Type)',
    'BSEN 61009-1 RCBO AC (RCD Type)',
    'BSEN 61009-1 RCBO A (RCD Type)',
    'BSEN 61009-1 RCBO B (RCD Type)',
    'BSEN 61009-1 RCBO F (RCD Type)',
    'BSEN 62606 B RCD (OCPD Type)',
    'BSEN 62606 C RCD (OCPD Type)',
    'BSEN 62606 D RCD (OCPD Type)',
    'BSEN 62606 AC RCD (RCD Type)',
    'BSEN 62606 A RCD (RCD Type)',
    'BSEN 62606 F RCD (RCD Type)',
    'BSEN 62606 B RCD (RCD Type)',
    'BSEN 62423 B RCD (OCPD Type)',
    'BSEN 62423 C RCD (OCPD Type)',
    'BSEN 62423 D RCD (OCPD Type)',
    'BSEN 62423 B RCD (RCD Type)',
    'BSEN 62423 F RCD (RCD Type)',
    'BSEN 62602 (No Type)',
    '61643 Type 1 SPD',
    '61643 Type 2 SPD',
    '61643 Type 3 SPD',
    '61643 Type NA SPD',
    'Non-verifiable',
    'Limitation',
    'N/A',
  ].map(toLabelAndValue),

  'electrical:supplierProtectiveDeviceRatedCurrent': [
    '1',
    '2',
    '2.5',
    '3',
    '4',
    '5',
    '6',
    '7.5',
    '8',
    '10',
    '13',
    '15',
    '16',
    '20',
    '25',
    '30',
    '32',
    '40',
    '45',
    '50',
    '60',
    '63',
    '70',
    '80',
    '90',
    '100',
    '113',
    '125',
    '150',
    '160',
    '175',
    '200',
    '225',
    '250',
    '300',
    '315',
    '320',
    '350',
    '400',
    '480',
    '500',
    '600',
    '630',
    '800',
    '1000',
    '1200',
    '1250',
    '1600',
    '2000',
    '2500',
    '2600',
    '3000',
    '3200',
    '4000',
    'LIM',
    'N/A',
  ].map(toLabelAndValue),

  'electrical:shortCircuitCapacity': [
    '1',
    '1.5',
    '2',
    '3',
    '4',
    '4.5',
    '6',
    '9',
    '10',
    '15',
    '16',
    '16.5',
    '18',
    '20',
    '25',
    '31.5',
    '33',
    '36',
    '40',
    '50',
    '70',
    '80',
    '100',
    '120',
    'S1A',
    'S2A',
    'S3A',
    'S4A',
    'LIM',
    'N/A',
  ].map(toLabelAndValue),

  'electrical:rcdOperatingCurrent': [
    '10',
    '30',
    '100',
    '300',
    '500',
    '100s',
    '300S',
    '500s',
    '1000',
    'LIM',
    'N/A',
  ].map(toLabelAndValue),

  'electrical:testVoltageDc': ['250', '500', '1000', 'N/A'].map(
    toLabelAndValue
  ),

  'electrical:tickCross': ['✓', '✗'].map(toLabelAndValue),
  'electrical:tickCrossNa': ['✓', '✗', 'N/A'].map(toLabelAndValue),
  'electrical:satisfactoryUnsatisfactory': [
    'Satisfactory',
    'Unsatisfactory',
  ].map(toLabelAndValue),

  'electrical:simpleCheckResult': ['✓', 'N/A', 'LIM', '✗'].map(toLabelAndValue),

  'electrical:inspectionResult': [
    '✓',
    'N/A',
    'LIM',
    'C1',
    'C2',
    'C3',
    'FI',
  ].map(toLabelAndValue),

  'electrical:certificationResult': ['✓', 'N/A'].map(toLabelAndValue),

  'electrical:earthElectrodeType': [
    'Earth Rod',
    'Earth Tape',
    'Earth Pipe',
    'Earth Wire',
    'Earth Plate',
    'Structural metalwork',
    'Welded metalwork',
    'Metallic covering of cable',
    'Other underground metalwork',
    'None',
    'N/A',
  ].map(toLabelAndValue),

  'electrical:observationCode': ['C1', 'C2', 'C3', 'FI'].map(toLabelAndValue),

  'electrical:supplySystemType': [
    'TN-C-S',
    'TN-S',
    'TT',
    'TN-C',
    'IT',
    'N/A',
  ].map(toLabelAndValue),

  'electrical:mainSwitchType': [
    'BSEN 60947-3 Isolator',
    'BS 5419 Isolator',
    'BS 3036 Fuse',
    'BS 4265',
    'BS 60269-2',
    'BS 1361 Fuse HBC Domestic Type 1',
    'BS 1361 Fuse HBC Domestic Type 2',
    'BS 1362 Fuse Domestic',
    'BS 88-2 Fuse System E (bolted)',
    'BS 88-2 Fuse System G (clip-in)',
    'BS 88-2 Fuse HRC gM (Motor)',
    'BS 88-2 Fuse HRC gG (General)',
    'BS 88-3 Fuse System C',
    'BSEN 60898 MCB Type B',
    'BSEN 60898 MCB Type C',
    'BSEN 60898 MCB Type D',
    'BSEN 60947-2',
    'BSEN 60947-2 MCB Type B',
    'BSEN 60947-2 MCB Type C',
    'BSEN 60947-2 MCB Type D',
    'BSEN 60947-2 MCCB',
    'BSEN 60947-2 ACB',
    'BS 3871 MCB Type 1',
    'BS 3871 MCB Type 2',
    'BS 3871 MCB Type 3',
    'BS 3871 MCB Type 4',
    'BSEN 4752-1 Type B',
    'BSEN 4752-1 Type D',
    'BSEN 7288 RCD',
    'BSEN 4293 RCD',
    'BSEN 61008-1 RCD',
    'BSEN 61008-1 AC RCD',
    'BSEN 61008-1 A RCD',
    'BSEN 61008-1 B RCD',
    'BSEN 61008-1 F RCD',
    'BSEN 61009-1 RCBO B (OCPD Type)',
    'BSEN 61009-1 RCBO C (OCPD Type)',
    'BSEN 61009-1 RCBO D (OCPD Type)',
    'BSEN 61009-1 RCBO AC (RCD Type)',
    'BSEN 61009-1 RCBO A (RCD Type)',
    'BSEN 61009-1 RCBO B (RCD Type)',
    'BSEN 61009-1 RCBO F (RCD Type)',
    'BSEN 62606 B RCD (OCPD Type)',
    'BSEN 62606 C RCD (OCPD Type)',
    'BSEN 62606 D RCD (OCPD Type)',
    'BSEN 62606 AC RCD (RCD Type)',
    'BSEN 62606 A RCD (RCD Type)',
    'BSEN 62606 F RCD (RCD Type)',
    'BSEN 62606 B RCD (RCD Type)',
    'BSEN 62423 B RCD (OCPD Type)',
    'BSEN 62423 C RCD (OCPD Type)',
    'BSEN 62423 D RCD (OCPD Type)',
    'BSEN 62423 B RCD (RCD Type)',
    'BSEN 62423 F RCD (RCD Type)',
    'N/A',
    'BSEN 62602 (No Type)',
    '61643 Type 1 SPD',
    '61643 Type 2 SPD',
    '61643 Type 3 SPD',
    '61643 Type NA SPD',
    'Non-verifiable',
    'Limitation',
  ].map(toLabelAndValue),

  'electrical:mainSwitchCurrentRating': [
    '1',
    '2',
    '2.5',
    '3',
    '4',
    '5',
    '6',
    '7.5',
    '8',
    '10',
    '13',
    '15',
    '16',
    '20',
    '25',
    '30',
    '32',
    '40',
    '45',
    '50',
    '60',
    '63',
    '70',
    '80',
    '90',
    '100',
    '113',
    '125',
    '150',
    '160',
    '175',
    '200',
    '225',
    '250',
    '300',
    '315',
    '320',
    '350',
    '400',
    '480',
    '500',
    '600',
    '630',
    '800',
    '1000',
    '1200',
    '1250',
    '1600',
    '2000',
    '2500',
    '2600',
    '3000',
    '3200',
    '4000',
    'LIM',
    'N/A',
  ].map(toLabelAndValue),

  'electrical:mainSwitchRatingSetting': [
    '1',
    '2',
    '2.5',
    '3',
    '4',
    '5',
    '6',
    '7.5',
    '8',
    '10',
    '13',
    '15',
    '16',
    '20',
    '25',
    '30',
    '32',
    '40',
    '45',
    '50',
    '60',
    '63',
    '70',
    '80',
    '90',
    '100',
    '113',
    '125',
    '150',
    '160',
    '175',
    '200',
    '225',
    '250',
    '300',
    '315',
    '320',
    '350',
    '400',
    '480',
    '500',
    '600',
    '630',
    '800',
    '1000',
    '1200',
    '1250',
    '1600',
    '2000',
    '2500',
    '2600',
    '3000',
    '3200',
    '4000',
    'LIM',
    'N/A',
  ].map(toLabelAndValue),

  'electrical:mainSwitchVoltageRating': [
    '110',
    '230',
    '240',
    '400',
    '415',
    '800',
    'N/A',
  ].map(toLabelAndValue),

  'electrical:mainSwitchRcdResidualOperatingCurrent': [
    '10',
    '30',
    '100',
    '300',
    '500',
    '100s',
    '300S',
    '500s',
    '1000',
    'LIM',
    'N/A',
  ].map(toLabelAndValue),
};
