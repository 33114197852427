import { takeEvery, call, put, race, delay } from 'redux-saga/effects';

import { PayloadAction } from 'typesafe-actions';

import { SagaConfig, ActionType } from './listedItemsTypes';
import { GetListedItemsRequestData } from '@payaca/types/listedItemTypes';
import {
  clearListedItemsPage,
  getListedItemsPageFailure,
  getListedItemsPageSuccess,
} from './listedItemsActions';
import { refreshAuthToken } from '../auth/refreshAuthToken';
import { DEFAULT_API_REQUEST_TIMEOUT_MS } from '../constants';

const listedItemsSagaCreator = ({ apiBaseurl, getAuthHeader }: SagaConfig) => {
  function* handleGetListedItemsPage(
    action: PayloadAction<
      ActionType.REQUEST_GET_LISTED_ITEMS_PAGE,
      {
        getListedItemsRequestData: GetListedItemsRequestData;
      }
    >
  ) {
    yield call(refreshAuthToken);
    try {
      const { response, timeout } = yield race({
        response: call(
          getListedItemsPage,
          action.payload.getListedItemsRequestData
        ),
        timeout: delay(DEFAULT_API_REQUEST_TIMEOUT_MS),
      });

      if (timeout) {
        yield put(
          getListedItemsPageFailure(
            new Error('Get listed items page timed out.')
          )
        );
      } else {
        yield put(getListedItemsPageSuccess(response));
      }
    } catch (error: any) {
      yield put(getListedItemsPageFailure(error));
    }
  }

  function* handleAppLogout() {
    yield put(clearListedItemsPage());
  }

  const getListedItemsPage = async (
    getListedItemsRequestData: GetListedItemsRequestData
  ) => {
    const authHeader = await getAuthHeader();

    return fetch(
      `${apiBaseurl}/api/line_items/listed_items?pageNumber=${
        getListedItemsRequestData.pageNumber
      }&pageSize=${getListedItemsRequestData.pageSize}&searchTerm=${
        getListedItemsRequestData.searchTerm || ''
      }&reference=${getListedItemsRequestData.reference || ''}&sortBy=${
        getListedItemsRequestData.sortBy || ''
      }&sortDirection=${getListedItemsRequestData.sortDirection || ''}`,
      {
        method: 'GET',
        headers: {
          Authorization: authHeader,
          'Content-Type': 'application/json',
          'X-Simple-Job': 'true',
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(
          `getListedItemsPage failed: ${response.status} ${response.statusText}`
        );
      }
    });
  };

  return function* () {
    yield takeEvery(
      ActionType.REQUEST_GET_LISTED_ITEMS_PAGE,
      handleGetListedItemsPage
    );
    yield takeEvery('auth.logout', handleAppLogout);
  };
};

export default listedItemsSagaCreator;
