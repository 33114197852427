import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CloseSquare, Edit, TickSquare, TwoUsers } from 'react-iconly';

import CustomerInformation from '@/ui/components/customerInformation/CustomerInformation';
import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import CompanyLogo from '@payaca/components/companyLogo/CompanyLogo';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import Modal from '@payaca/components/modal/Modal';
import TextareaFieldFormatter from '@payaca/components/textareaField/TextareaFieldFormatter';
import Tooltip from '@payaca/components/tooltip/Tooltip';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import RadioButton from '@payaca/components/radioButton/RadioButton';
import { getEmailFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import MarkdownLabel from '@payaca/components/markdownLabel/MarkdownLabel';
import UploadCollectionControl from '../uploadCollectionControl/UploadCollectionControl';

import { Customer, CustomerContact } from '@payaca/types/customerTypes';

import './SendSimpleCustomEmailControl.sass';
import { useSelector } from '@/api/state';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';

const isValidEmail = getEmailFieldValidator();

const acceptFileTypes = getAcceptedFileTypes([
  'document',
  'image',
  'video',
  'other',
]);
interface Props {
  initialSendToEmail?: string;
  initialSubject?: string;
  initialEmailBody?: string;
  initialSendACopy?: boolean;
  allowCustomEmailSubject?: boolean;
  allowCustomEmailTemplate?: boolean;
  customer?: Customer;
  isSending?: boolean;
  requiredEmailBodyVariables?: string[];
  optionalEmailBodyVariables?: string[];
  allAllowableEmailVariables?: { label: string; value: string }[];
  onChange?: ({
    sendToEmail,
    emailSubject,
    emailBody,
    sendACopy,
    emailVariables,
  }: any) => void;
  onAttachmentsChange?: (uploadIds: number[]) => void;
  onValidityChange?: (isValid: boolean) => void;
  onSend?: ({ sendToEmail, emailSubject, emailBody, sendACopy }: any) => void;
  sendToEmailComponent?: JSX.Element;
  uploadIds?: number[];
}

export const SendSimpleCustomEmailControl: FC<Props> = ({
  initialSendToEmail = '',
  initialSubject = '',
  initialEmailBody = '',
  initialSendACopy,
  allowCustomEmailSubject = false,
  allowCustomEmailTemplate = false,
  requiredEmailBodyVariables = [],
  optionalEmailBodyVariables = [],
  allAllowableEmailVariables = [],
  customer,
  onChange,
  onValidityChange,
  onSend,
  isSending = false,
  sendToEmailComponent,
  onAttachmentsChange,
  uploadIds = [],
}) => {
  const [manualEmailEntry, setManualEmailEntry] = useState(false);
  const [showEditEmailBodyModal, setShowEditEmailBodyModal] = useState(false);
  const [sendToEmail, setSendToEmail] = useState(initialSendToEmail);
  const [emailSubject, setEmailSubject] = useState(initialSubject);
  const [emailBody, setEmailBody] = useState(initialEmailBody);
  const [previousEmailBody, setPreviousEmailBody] = useState(initialEmailBody);
  const [previousEmailSubject, setPreviousEmailSubject] =
    useState(initialSubject);
  const [sendACopy, setSendACopy] = useState(initialSendACopy);
  const [selectedContact, setSelectedContact] = useState<
    CustomerContact | undefined
  >();
  const [eventHandlers, setEventHandlers] = useState<any>({
    insertText: () => null,
  });
  const [variableFilterTerm, setVariableFilterTerm] = useState('');

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const emailBodyContainsVariables = useMemo(
    () =>
      requiredEmailBodyVariables.reduce((acc, variable) => {
        if (!acc || !emailBody.includes(`[${variable}]`)) return false;
        return acc;
      }, true),
    [requiredEmailBodyVariables, emailBody]
  );

  const isEmailBodyValid = useMemo(
    () => !allowCustomEmailTemplate || !!emailBody,
    [emailBody, allowCustomEmailTemplate]
  );
  const isEmailSubjectValid = useMemo(
    () => !allowCustomEmailSubject || !!emailSubject,
    [emailSubject, allowCustomEmailSubject]
  );

  const allowSend = useMemo(() => {
    const emailValidation = isValidEmail('sendToEmail', { sendToEmail });
    return (
      emailValidation.isValid &&
      isEmailBodyValid &&
      emailBodyContainsVariables &&
      isEmailSubjectValid
    );
  }, [
    sendToEmail,
    emailSubject,
    isEmailBodyValid,
    isEmailSubjectValid,
    requiredEmailBodyVariables,
    emailBodyContainsVariables,
  ]);

  const onSelectEmailVariable = useCallback(
    (selectedVariable: any) => {
      if (!selectedVariable?.value) return;
      onChange &&
        onChange({
          emailVariables: [
            ...optionalEmailBodyVariables,
            selectedVariable.value,
          ],
        });
      setVariableFilterTerm('');
    },
    [onChange, optionalEmailBodyVariables]
  );

  const filteredVariables = useMemo(() => {
    return variableFilterTerm
      ? allAllowableEmailVariables.filter((x) => {
          return x.value.search(variableFilterTerm.trim()) >= 0;
        })
      : allAllowableEmailVariables;
  }, [allAllowableEmailVariables, variableFilterTerm]);

  const variablesList = useCallback(
    (variables: { label: string; value: string }[]) => {
      return (
        <ContentPanel className={'email-variables-list'}>
          {variables.map((variable, i) => {
            const variableExists =
              emailBody.includes(`[${variable.value}]`) ||
              emailSubject.includes(`[${variable.value}]`);
            return (
              <div
                className={
                  'email-variable ' + (variableExists ? 'valid' : 'optional')
                }
                key={i}
                onClick={() => {
                  onSelectEmailVariable(variable);
                  eventHandlers.insertText(`[${variable.value}]`);
                }}
              >
                {variableExists ? <TickSquare /> : <CloseSquare />}[
                {variable.label}]
              </div>
            );
          })}
        </ContentPanel>
      );
    },
    [eventHandlers, onSelectEmailVariable]
  );

  const renderEmailVariables = useMemo(
    () => (
      <div className={'email-variables-container'}>
        {/* required variables */}
        {!!requiredEmailBodyVariables.length && (
          <>
            <p>
              The following words{' '}
              <span className="bold-weight">(and brackets)</span> must be
              included somewhere in your message:
            </p>
            {variablesList(
              requiredEmailBodyVariables.map((x) => ({ label: x, value: x }))
            )}
          </>
        )}

        {/* optional variables */}
        {!!allAllowableEmailVariables.length && (
          <div className="email-variables-filter-and-list">
            {/* filter search bar */}
            <BasicField
              name={'variableFilterTerm'}
              value={variableFilterTerm}
              onChange={(value) =>
                setVariableFilterTerm(value.variableFilterTerm)
              }
              styleVariant={InputStyleVariant.STANDARD}
              additionalInputProps={{
                placeholder: 'Search available variables...',
              }}
              label="Available variables"
              description={
                <>
                  The following words{' '}
                  <span className="bold-weight">(and brackets)</span> can be
                  included anywhere in your subject/message
                </>
              }
            />
            {/* email variable list */}
            {variablesList(filteredVariables)}
          </div>
        )}
      </div>
    ),
    [
      requiredEmailBodyVariables,
      emailBody,
      emailSubject,
      eventHandlers,
      variableFilterTerm,
      filteredVariables,
      allAllowableEmailVariables,
      onSelectEmailVariable,
    ]
  );

  useEffect(() => {
    onChange &&
      onChange({
        ...(emailSubject ? { emailSubject } : {}),
        ...(sendToEmail !== 'undefined' ? { sendToEmail } : {}),
        ...(emailBody !== 'undefined' ? { emailBody } : {}),
        ...(typeof sendACopy === 'boolean' ? { sendACopy } : {}),
      });
    onValidityChange && onValidityChange(allowSend);
  }, [emailSubject, sendToEmail, emailBody, sendACopy, allowSend]);

  const storePreviousState = useCallback(() => {
    setPreviousEmailSubject(emailSubject);
    setPreviousEmailBody(emailBody);
  }, [emailSubject, emailBody]);

  useEffect(() => {
    if (!manualEmailEntry && customer?.contacts.length === 1) {
      return setSendToEmail(customer?.contacts[0]?.emailAddress || '');
    }
    setSendToEmail(
      !manualEmailEntry ? String(selectedContact?.emailAddress) : ''
    );
  }, [customer, manualEmailEntry]);

  const handleToggleManualEntry = useCallback(() => {
    setManualEmailEntry(!manualEmailEntry);
  }, [manualEmailEntry]);

  const handleSelectContact = useCallback(
    (customerContact: CustomerContact) => {
      setSelectedContact(customerContact);
      setSendToEmail(customerContact.emailAddress || '');
    },
    []
  );

  const renderCustomerContact = useCallback(
    (
      customerContact: CustomerContact,
      index: number,
      originalComponent: JSX.Element
    ) => {
      return (
        <div
          className="customer-contact-select-wrapper"
          key={`customer-contact-${index}`}
        >
          <RadioButton
            onClick={() => handleSelectContact(customerContact)}
            isSelected={customerContact.id === selectedContact?.id}
          />
          {originalComponent}
        </div>
      );
    },
    [selectedContact]
  );

  return (
    <>
      <div className={'send-document-to-customer-container'}>
        {sendToEmailComponent || (
          <>
            {customer && !manualEmailEntry && (
              <div className={'form-email-select-container'}>
                <CustomerInformation
                  customer={customer}
                  showPrimaryContactOnly={false}
                  hideAddresses={true}
                  renderCustomerContact={renderCustomerContact}
                />
                <div
                  className={'toggle-manual-email-entry'}
                  onClick={handleToggleManualEntry}
                >
                  <Tooltip text={'Enter e-mail'}>
                    <Edit />
                  </Tooltip>
                </div>
              </div>
            )}
            {(!customer || manualEmailEntry) && (
              <div className={'manual-email-entry-container'}>
                {customer && (
                  <div
                    className={'toggle-manual-email-entry'}
                    onClick={handleToggleManualEntry}
                  >
                    <Tooltip text={'Choose contact'}>
                      <TwoUsers />
                    </Tooltip>
                  </div>
                )}
                <BasicField
                  name={'email'}
                  type={'email'}
                  label={'Enter e-mail address to send to:'}
                  onChange={({ email }) => setSendToEmail(email)}
                  styleVariant={InputStyleVariant.OUTSIZE}
                />
              </div>
            )}
          </>
        )}
        {allowCustomEmailSubject && (
          <div className={'email-subject'}>
            <span>Subject:</span>&nbsp;{emailSubject}
          </div>
        )}
        {allowCustomEmailTemplate && (
          <div className={'email-preview'}>
            <div className={'flex-container'}>
              <h4>
                Preview:
                <span>
                  {!emailBodyContainsVariables &&
                    'You are missing some variables!'}
                </span>
              </h4>
            </div>
            <hr />
            <div
              className={'toggle-manual-email-entry'}
              onClick={() => {
                setShowEditEmailBodyModal(true);
                storePreviousState();
              }}
            >
              <Tooltip text={'Edit message'}>
                <Edit />
              </Tooltip>
            </div>
            <div
              className={'email-preview-body'}
              onClick={() => {
                setShowEditEmailBodyModal(true);
                storePreviousState();
              }}
            >
              <CompanyLogo logoUrl={account.logoUrl} />
              <MarkdownLabel markdown={emailBody} />
            </div>
          </div>
        )}
        {onAttachmentsChange && (
          <UploadCollectionControl
            label="Attachments"
            uploadIds={uploadIds || []}
            onUploadCollectionChange={onAttachmentsChange}
            allowMultipleUploads={false}
            // 10mb
            fileSizeLimitBytes={10 * 1024 * 1024}
            acceptFileTypes={acceptFileTypes}
          />
        )}

        <div className={'form-complete-copy-control'}>
          <CheckboxField
            name={'sendMeACopy'}
            label={'Send me a copy'}
            value={sendACopy}
            onChange={() => setSendACopy(!sendACopy)}
          />
        </div>
        {onSend && (
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={() =>
              onSend({
                sendToEmail,
                emailBody,
                sendACopy,
                emailSubject,
              })
            }
            isDisabled={!allowSend || isSending}
            isProcessing={isSending}
          >
            Send
          </Button>
        )}
      </div>

      <Modal
        className={'send-document-to-customer-email-edit-modal'}
        isOpen={showEditEmailBodyModal}
        onClose={() => {
          setEmailBody(previousEmailBody);
          setEmailSubject(previousEmailSubject);
          setShowEditEmailBodyModal(false);
        }}
        actions={
          <Button
            styleVariant={ButtonStyleVariant.OUTSIZE}
            onClick={() => {
              setShowEditEmailBodyModal(false);
            }}
            isDisabled={!emailSubject || !emailBody}
          >
            Save changes
          </Button>
        }
        title={`Compose your email`}
        size="lg"
      >
        <div className="email-subject-and-body-wrapper">
          {allowCustomEmailSubject ? (
            <BasicField
              name={'emailSubject'}
              label={'Subject'}
              value={emailSubject}
              onChange={({ emailSubject }) => setEmailSubject(emailSubject)}
              additionalInputProps={{
                onKeyUp: (e: any) => {
                  const cursorPosition = e.target.selectionStart;
                  setEventHandlers({
                    insertText: (textToInsert: string) => {
                      const textBeforeCursorPosition = emailSubject.substring(
                        0,
                        cursorPosition
                      );
                      const textAfterCursorPosition = emailSubject.substring(
                        cursorPosition,
                        emailSubject.length
                      );
                      setEmailSubject(
                        textBeforeCursorPosition +
                          textToInsert +
                          textAfterCursorPosition
                      );
                    },
                  });
                },
                onClick: (e: any) => {
                  const cursorPosition = e.target.selectionStart;

                  setEventHandlers({
                    insertText: (textToInsert: string) => {
                      const textBeforeCursorPosition = emailSubject.substring(
                        0,
                        cursorPosition
                      );
                      const textAfterCursorPosition = emailSubject.substring(
                        cursorPosition,
                        emailSubject.length
                      );
                      setEmailSubject(
                        textBeforeCursorPosition +
                          textToInsert +
                          textAfterCursorPosition
                      );
                    },
                  });
                },
              }}
            />
          ) : (
            <p>
              <strong>Subject:</strong> {emailSubject}
            </p>
          )}
          <hr />
          <TextareaFieldFormatter
            name={'emailBody'}
            value={emailBody}
            bindEventHandlers={setEventHandlers}
            onChange={({ emailBody }) => setEmailBody(emailBody)}
          />
        </div>
        {renderEmailVariables}
      </Modal>
    </>
  );
};
