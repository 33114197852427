import { FunctionComponent } from 'react';
import Button from '@payaca/components/plButton/Button';

import {
  EBtnShapeVariant,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Input from '@payaca/components/plInput/Input';
import UntitledIcon from '@payaca/untitled-icons';

type Props = {
  isDisabled?: boolean;
  value: number;
  minimumValue?: number;
  onChange: (value: number) => void;
  showInputField?: boolean;
};

const QuantityControl: FunctionComponent<Props> = ({
  isDisabled = false,
  value,
  minimumValue = 0,
  onChange,
  showInputField = false,
}: Props): JSX.Element => {
  return (
    <div className="flex justify-center space-x-4 items-center">
      <Button
        variant={EBtnVariant.Outline}
        shape={EBtnShapeVariant.PILL}
        disabled={value <= minimumValue || isDisabled}
        onClick={() => onChange(value - 1)}
        size={EBtnSize.XSmall}
        className="py-2"
      >
        <UntitledIcon name="minus.3" className="h-4 w-4" />
      </Button>
      {showInputField ? (
        <Input
          type="number"
          value={value}
          onChange={(value) => onChange(parseInt(value))}
          className="w-20"
        />
      ) : (
        <span className="w-6 text-center">{value}</span>
      )}
      <Button
        variant={EBtnVariant.Outline}
        shape={EBtnShapeVariant.PILL}
        disabled={isDisabled}
        onClick={() => onChange(value + 1)}
        size={EBtnSize.XSmall}
        className="py-2"
      >
        <UntitledIcon name="plus.3" className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default QuantityControl;
