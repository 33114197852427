import {
  getMaterial,
  getMaterialsListMaterial,
  getRegion,
  getSupplierMaterialsForMaterial,
  getUserRoles,
} from '@/utils/stateAccessors';
import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import './MaterialsListMaterialControl.sass';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import ImageBlock from '@payaca/components/imageBlock/ImageBlock';
import { useSelector } from '@/api/state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { getPreferredSupplierMaterial } from '@payaca/helpers/supplierMaterialsHelper';
import { useSupplier } from '@payaca/store/hooks/appState';
import { currencyPrice } from '@payaca/helpers/financeHelper';

type Props = {
  materialsListMaterialId: number;
  onDeleteMaterialsListMaterialSuccess?: () => void;
  onUpdateMaterialsListMaterialSuccess?: () => void;
  showPreferredSupplierInfo?: boolean;
};

const MaterialsListMaterialControl: FunctionComponent<Props> = ({
  materialsListMaterialId,
  onDeleteMaterialsListMaterialSuccess,
  onUpdateMaterialsListMaterialSuccess,
  showPreferredSupplierInfo = true,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [materialQuantity, setMaterialQuantity] = useState<string>();
  const [isSaveRequired, setIsSaveRequired] = useState(false);
  const [quantityUpdateErrorMessage, setQuantityUpdateErrorMessage] =
    useState<string>();
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<string>();
  const region = useSelector(getRegion);

  const materialsListMaterial = useSelector((state) =>
    getMaterialsListMaterial(state, materialsListMaterialId)
  );

  const material = useSelector((state) => {
    return materialsListMaterial
      ? getMaterial(state, materialsListMaterial.materialId)
      : undefined;
  });

  const isFetchingMaterialsListMaterial: boolean = useSelector((state) => {
    return (
      state.materialsLists?.materialsListMaterials &&
      state.materialsLists?.materialsListMaterials[materialsListMaterialId]
        ?.isFetching
    );
  });

  const deleteMaterialsListMaterial = useCallback(() => {
    if (!materialsListMaterial) return;
    setDeleteErrorMessage(undefined);
    if (materialsListMaterial.materialPurchaseRecordIds.length) {
      setDeleteErrorMessage(
        'To delete this Material you first need to delete the purchase records.'
      );
    } else {
      setIsDeleting(true);
      dispatch(
        materialsListActions.requestDeleteMaterialsListMaterial(
          materialsListMaterialId,
          () => {
            onDeleteMaterialsListMaterialSuccess?.();
            setIsDeleting(false);
          },
          () => {
            setIsDeleting(false);
          }
        )
      );
    }
  }, [
    onDeleteMaterialsListMaterialSuccess,
    materialsListMaterialId,
    materialsListMaterial,
  ]);

  useEffect(() => {
    if (materialsListMaterial) {
      setMaterialQuantity(materialsListMaterial.materialQuantity.toString());
    }
  }, [materialsListMaterial?.id, materialsListMaterial?.autopopulatedQuantity]);

  const requiresUpdate = useCallback(
    (materialQuantity: number) => {
      if (isFetchingMaterialsListMaterial || isEditing) return true;
      return materialQuantity != materialsListMaterial?.materialQuantity;
    },
    [
      isFetchingMaterialsListMaterial,
      isEditing,
      materialsListMaterial?.materialQuantity,
    ]
  );

  const saveMaterialQuantity = useCallback(
    (materialQuantity: number) => {
      if (!requiresUpdate(materialQuantity)) return;

      setIsEditing(true);
      dispatch(
        materialsListActions.requestPersistMaterialsListMaterial(
          {
            materialsListMaterialId: materialsListMaterialId,
            materialQuantity: materialQuantity,
          },
          () => {
            setIsEditing(false);
            dispatch(
              materialsListActions.requestGetMaterialsListMaterialWithRelatedEntities(
                materialsListMaterialId
              )
            );
            onUpdateMaterialsListMaterialSuccess?.();
          }
        )
      );
    },
    [
      requiresUpdate,
      onUpdateMaterialsListMaterialSuccess,
      materialsListMaterialId,
    ]
  );

  const minQuantity = useMemo(() => {
    if (!materialsListMaterial) return 1;
    return (
      materialsListMaterial.purchaseIntendedMaterialQuantity +
      materialsListMaterial.purchasedMaterialQuantity
    );
  }, [materialsListMaterial]);

  useEffect(() => {
    if (materialQuantity && isSaveRequired) {
      const materialQuantityFloat = parseFloat(materialQuantity);
      if (materialQuantityFloat <= 0) {
        setQuantityUpdateErrorMessage('Qty must be greater than zero');
      } else if (materialQuantityFloat <= minQuantity) {
        saveMaterialQuantity(minQuantity);
        setMaterialQuantity(minQuantity.toString());
        setQuantityUpdateErrorMessage(
          'Qty cannot be less than purchase records'
        );
      } else {
        saveMaterialQuantity(materialQuantityFloat);
      }

      setIsSaveRequired(false);
    }
  }, [isSaveRequired, materialQuantity]);

  const supplierMaterials = useSelector((state) =>
    materialsListMaterial
      ? getSupplierMaterialsForMaterial(
          state,
          materialsListMaterial?.materialId
        )
      : []
  );

  const preferredSupplierMaterial = useMemo(() => {
    return getPreferredSupplierMaterial(
      supplierMaterials,
      material?.preferredSupplierId
    );
  }, [supplierMaterials, material]);

  const preferredSupplier = useSupplier(preferredSupplierMaterial?.supplierId);

  if (!materialsListMaterial || !material) return null;

  return (
    <ResponsiveViewWrapper
      downBreakpointSm={500}
      className={`materials-list-material-control ${
        materialsListMaterial.isFullyPurchased ? 'fully-purchased' : ''
      }`}
    >
      <>
        <div className="primary-information">
          <ImageBlock
            imageSrc={material.thumbnailUrl}
            renderBlockIfNoImageSrc={true}
          />
          <div className="name-and-supplier">
            <div className="material-name">
              <span>{material.name}</span>
            </div>
            {preferredSupplierMaterial && showPreferredSupplierInfo && (
              <small>
                Available from <strong>{preferredSupplier?.name}</strong>
                {preferredSupplierMaterial?.price !== null && (
                  <>
                    {' '}
                    for{' '}
                    <strong>
                      {currencyPrice(preferredSupplierMaterial.price, region)}
                    </strong>{' '}
                    per unit
                    {preferredSupplierMaterial.isTaxIncluded && (
                      <>
                        {' '}
                        <strong>+ tax</strong>
                      </>
                    )}
                  </>
                )}{' '}
              </small>
            )}
          </div>
          {isFetchingMaterialsListMaterial || isEditing ? (
            <MiniLoader />
          ) : (
            <span></span>
          )}
          <div className="quantity-control-container">
            <PermissionGuard
              renderIfHasPermissions={[
                MaterialsListPermissions.PERSIST_MATERIALS_LIST_MATERIAL,
              ]}
            >
              <BasicField
                label="Qty"
                name="materialQuantity"
                value={materialQuantity}
                type="number"
                onChange={(value) =>
                  setMaterialQuantity(value.materialQuantity)
                }
                additionalInputProps={{
                  min: 0,
                }}
                hideSpinnerArrows={false}
                onBlur={() => {
                  setIsEditing(false);
                  setIsSaveRequired(true);
                }}
                onChangeTimeout={() => setIsSaveRequired(true)}
              />
            </PermissionGuard>
            <PermissionGuard
              renderIfMissingPermissions={[
                MaterialsListPermissions.PERSIST_MATERIALS_LIST_MATERIAL,
              ]}
            >
              <span>Qty: {materialQuantity}</span>
            </PermissionGuard>
          </div>
          <PermissionGuard
            renderIfHasPermissions={[
              MaterialsListPermissions.DELETE_MATERIALS_LIST_MATERIAL,
            ]}
          >
            <Button
              styleVariant={ButtonStyleVariant.ANCHOR}
              className="delete-trigger"
              onClick={deleteMaterialsListMaterial}
              isProcessing={isDeleting}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </PermissionGuard>
        </div>
        {!!quantityUpdateErrorMessage?.length && (
          <FeedbackBlock
            feedbackLevel={FeedbackLevel.ERROR}
            isDismissable={true}
            onDismissed={() => setQuantityUpdateErrorMessage(undefined)}
          >
            {quantityUpdateErrorMessage}
          </FeedbackBlock>
        )}

        {!!deleteErrorMessage?.length && (
          <FeedbackBlock
            feedbackLevel={FeedbackLevel.ERROR}
            isDismissable={true}
            onDismissed={() => setDeleteErrorMessage(undefined)}
          >
            {deleteErrorMessage}
          </FeedbackBlock>
        )}
      </>
    </ResponsiveViewWrapper>
  );
};

export default MaterialsListMaterialControl;
