import React, { FC, useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash-es';

import { useSelector } from '@/api/state';

import {
  getJobLineItem,
  getJobLineItemAttachmentByJobLineItemId,
} from '@/utils/stateAccessors';

import EditJobLineItemInformation from '../../components/viewEditItem/EditJobLineItemInformation';
import EditJobLineItemPrice from '../../components/viewEditItem/EditJobLineItemPrice';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';

import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';

import { JobLineItem } from '@payaca/types/jobContentTypes';
import { UpdateJobLineItemRequestData } from '@payaca/store/jobContent/jobContentTypes';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { JobLineItemAttachment } from '@payaca/types/jobTypes';
import { Job } from '@payaca/types/jobTypesV2';
import { Deal } from '@payaca/types/dealTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import { getManagedAttachments, isInvoice } from '../../../helpers/jobHelper';

import { useJob } from '@payaca/store/hooks/appState';

import { useFormState } from '../../../hooks/useFormState';
import { useLineItemInfoFormValidators } from '../../../hooks/useLineItemValidators';
import { Prompt } from 'react-router';

export type FormState = {
  jobLineItemId?: JobLineItem['id'];
  name?: JobLineItem['name'];
  description?: JobLineItem['description'];
  isMultipleChoice?: JobLineItem['isMultipleChoice'];
  isOptional?: JobLineItem['isOptional'];
  isSelected?: JobLineItem['isSelected'];
  quantity?: JobLineItem['quantity'];
  attachments?: JobLineItemAttachment[];
  lineItemId?: JobLineItem['lineItemId'];
  price?: JobLineItem['price'];
  cisApplies?: boolean;
  taxRateId?: JobLineItem['taxRateId'];
  discountPercentage?: JobLineItem['discountPercentage'];
  discountDescription?: JobLineItem['discountDescription'];
};
type FormValidationResult = Partial<
  Record<keyof FormState, FieldValidationResult>
>;
type Props = {
  jobLineItemId: number;
  jobId: Job['id'];
  dealId?: Deal['id'];
  isAlwaysRequired?: boolean;
};
const EditJobLineItemControl: FC<Props> = ({
  jobLineItemId,
  jobId,
  dealId,
  isAlwaysRequired = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const clearDynamicFeedbackMessage = useRef<() => void>();
  const job = useJob(jobId);
  const fieldValidators = useLineItemInfoFormValidators();

  const jobLineItem: JobLineItem | undefined = useSelector((state) =>
    getJobLineItem(state, jobLineItemId)
  );

  const jobLineItemAttachment: JobLineItemAttachment | undefined = useSelector(
    (state) =>
      jobLineItemId
        ? getJobLineItemAttachmentByJobLineItemId(state, jobLineItemId)
        : undefined
  );

  const onPersistJobLineItemSuccess = () => {
    if (jobLineItemId) {
      dispatch(jobContentActions.requestGetJobLineItem(jobLineItemId));
      dispatch(
        jobContentActions.requestGetJobLineItemAttachmentsForJobLineItem(
          jobLineItemId
        )
      );
    }
  };

  const initialState: FormState = {
    jobLineItemId: jobLineItem?.id,
    name: jobLineItem?.name,
    description: jobLineItem?.description,
    quantity: jobLineItem?.quantity,
    attachments: jobLineItemAttachment ? [jobLineItemAttachment] : [],
    isMultipleChoice: jobLineItem?.isMultipleChoice,
    isOptional: jobLineItem?.isOptional,
    isSelected: jobLineItem?.isSelected,
    lineItemId: jobLineItem?.lineItemId,
    price: jobLineItem?.price,
    cisApplies: jobLineItem?.cisDeductionRate !== null,
    taxRateId: jobLineItem?.taxRateId,
    discountDescription: jobLineItem?.discountDescription,
    discountPercentage: jobLineItem?.discountPercentage,
  };

  const persistLineItem = (
    state: FormState,
    updateLineItem = false,
    onSaveFinished?: () => void
  ) => {
    clearDynamicFeedbackMessage.current?.();
    return new Promise<void>((resolve, reject) => {
      dispatch(
        jobContentActions.requestUpdateJobLineItem(
          {
            ...state,
            updateLineItem,
            reference: state.name,
          } as UpdateJobLineItemRequestData,
          async (error?: Error) => {
            if (error) {
              clearDynamicFeedbackMessage.current = showDynamicFeedbackMessage({
                title: `We couldn't save your changes`,
                isCancellable: true,
                feedbackLevel: FeedbackLevel.ERROR,
              });
              reject(error);
            } else {
              onPersistJobLineItemSuccess?.();
              clearDynamicFeedbackMessage.current = showDynamicFeedbackMessage({
                title: 'Your changes have been saved',
                lifespanMs: DynamicFeedbackLifespanMs.SHORT,
                feedbackLevel: FeedbackLevel.SUCCESS,
              });
              resolve();
            }
          }
        )
      );
    })
      .then(() => {
        // save attachments
        if (
          state.jobLineItemId &&
          !isEqual(state.attachments, initialState.attachments)
        ) {
          return saveJobLineItemAttachments(
            state.jobLineItemId,
            state.attachments || [],
            updateLineItem
          );
        }
        return;
      })
      .finally(() => {
        onSaveFinished?.();
      });
  };

  const {
    formState,
    updateFormFields,
    formValidationResult,
    isDirty,
    saveAndFlush,
  } = useFormState<FormState, FormValidationResult>(
    initialState,
    fieldValidators,
    {
      autoSaveFn: persistLineItem,
      autoSaveDebounceMillis: 1000,
    }
  );

  const saveJobLineItemAttachments = async (
    jobLineItemId: number,
    attachments: any[],
    updateLineItem: boolean
  ) => {
    // get attachments to add / attachments to remove
    const { toAdd, toRemove } = getManagedAttachments(
      jobLineItemAttachment ? [jobLineItemAttachment] : [],
      attachments
    );

    const attachmentPromises = [];
    if (toAdd.length) {
      attachmentPromises.push(
        new Promise<void>((resolve, reject) => {
          dispatch(
            jobContentActions.requestAddAttachmentToJobLineItem(
              jobLineItemId,
              toAdd[0],
              updateLineItem,
              (error?: Error) => {
                error ? reject() : resolve();
              }
            )
          );
        })
      );
    }
    if (toRemove.length) {
      attachmentPromises.push(
        new Promise<void>((resolve, reject) => {
          dispatch(
            jobContentActions.requestRemoveAttachmentFromJobLineItem(
              jobLineItemId,
              toRemove[0].id,
              updateLineItem,
              (error?: Error) => {
                error ? reject() : resolve();
              }
            )
          );
        })
      );
    }
    await Promise.all(attachmentPromises)
      .then(() => {
        onPersistJobLineItemSuccess?.();
      })
      .catch(() => {
        clearDynamicFeedbackMessage.current = showDynamicFeedbackMessage({
          title: `We couldn't save your changes`,
          isCancellable: true,
          feedbackLevel: FeedbackLevel.ERROR,
        });
      });
  };

  return (
    <>
      {/* <Prompt
        when={isDirty}
        message="There are unsaved changes on the page, are you sure you want to leave?"
      /> */}
      {/* Item ref, description, image */}
      <EditJobLineItemInformation
        jobLineItemId={jobLineItemId}
        formState={formState}
        updateFormFields={updateFormFields}
        formValidationResult={formValidationResult}
        persistLineItem={persistLineItem}
        saveAndFlush={saveAndFlush}
        isAlwaysRequired={
          isAlwaysRequired ||
          (job && isInvoice(job.status)) ||
          job?.isProtoInvoice
        }
      />

      {/* Item price, profit */}
      <EditJobLineItemPrice
        jobLineItemId={jobLineItemId}
        dealId={dealId || job?.dealId}
        formState={formState}
        updateFormFields={updateFormFields}
        saveAndFlush={saveAndFlush}
      />

      {/* TODO: i - job line item materials */}
      {/* Item materials */}
      {/* <ViewEditItemMaterials
            lineItemId={lineItemId}
            isEditMode={isEditMode}
          /> */}
    </>
  );
};
export default EditJobLineItemControl;
