import { AccountRegions } from '@payaca/types/accountTypes';
import { CurrencyCodes } from '@payaca/types/currencyTypes';

export const currencyCodeForRegion = {
  [AccountRegions.AUSTRALIA]: CurrencyCodes.AUD,
  [AccountRegions.CANADA]: CurrencyCodes.CAD,
  [AccountRegions.NEW_ZEALAND]: CurrencyCodes.NZD,
  [AccountRegions.SOUTH_AFRICA]: CurrencyCodes.ZAR,
  [AccountRegions.UK]: CurrencyCodes.GBP,
  [AccountRegions.US]: CurrencyCodes.USD,
};

// https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts
const MinimumStripeCurrencyAmount = {
  [CurrencyCodes.AUD]: 50,
  [CurrencyCodes.CAD]: 50,
  [CurrencyCodes.NZD]: 50,
  [CurrencyCodes.ZAR]: 50, // unknown?
  [CurrencyCodes.GBP]: 30,
  [CurrencyCodes.USD]: 50,
};

export const getMinimumStripeChargeAmountForRegion = (
  region: AccountRegions
) => {
  const currency = currencyCodeForRegion[region] ?? CurrencyCodes.GBP;
  return MinimumStripeCurrencyAmount[currency];
};
