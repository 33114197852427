import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import PrivateField from '@payaca/components/privateField/PrivateField';
import CollapsiblePanel, {
  CollapsiblePanelStyleVariant,
} from '@payaca/components/collapsiblePanel/CollapsiblePanel';
import PasswordValidationFeedback from '../passwordValidationFeedback/PasswordValidationFeedback';

import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import {
  minimumLengthFieldValidator,
  mustContainLetterFieldValidator,
  mustContainNumberFieldValidator,
} from '@payaca/helpers/passwordValidationHelper';

type Props = {
  isSubmitting: boolean;
  onSubmit: (newPassword: string) => void;
};

const CreatePasswordForm: FunctionComponent<Props> = ({
  isSubmitting,
  onSubmit,
}: Props): JSX.Element | null => {
  const [showPasswordValidationFeedback, setShowPasswordValidationFeedback] =
    useState(false);

  const fieldValidators = useMemo(() => {
    const isRequiredFieldValidator = getIsRequiredFieldValidator();

    return {
      newPassword: [
        isRequiredFieldValidator,
        minimumLengthFieldValidator,
        mustContainLetterFieldValidator,
        mustContainNumberFieldValidator,
      ],
    };
  }, []);

  const initialFormState = useMemo(() => {
    return {
      newPassword: '',
    };
  }, []);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <ValidatedFieldWrapper
            validationResult={{
              ...validationState['newPassword'],
              errors: [],
            }}
            isTouched={touchedState['newPassword'] || false}
          >
            <PrivateField
              styleVariant={InputStyleVariant.OUTSIZE}
              name="newPassword"
              value={formState.newPassword}
              label={'New password'}
              isRequired={true}
              onChange={onFieldChange}
              onTouch={onFieldTouch}
              additionalInputProps={{
                onFocus: () => setShowPasswordValidationFeedback(true),
              }}
            />
          </ValidatedFieldWrapper>

          <CollapsiblePanel
            isOpen={showPasswordValidationFeedback}
            styleVariant={CollapsiblePanelStyleVariant.UNSTYLED}
            shouldRenderHeader={false}
          >
            <PasswordValidationFeedback password={formState.newPassword} />
          </CollapsiblePanel>

          <div className="button-wrapper">
            <Button
              type="submit"
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isDisabled={!isValid}
              isProcessing={isSubmitting}
              onClick={() => !isSubmitting && onSubmit(formState.newPassword)}
            >
              Set password
            </Button>
          </div>
        </>
      );
    },
    [onSubmit, isSubmitting, showPasswordValidationFeedback]
  );

  return (
    <div className="create-password-form-container">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
      />
    </div>
  );
};

export default CreatePasswordForm;
