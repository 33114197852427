import React, { FC } from 'react';
import { formatPrice } from '@payaca/helpers/financeHelper';
import { CurrencyValue as TCurrencyValue } from '@payaca/types/currencyTypes';

const CurrencyValue: FC<TCurrencyValue> = (props) => {
  const { value, currency } = props;

  const f = formatPrice(navigator.language);

  return (
    <>
      {f({
        priceInMinorCurrencyUnits: value,
        exponent: currency.exponent,
        currencyCode: currency.code,
      })}
    </>
  );
};

export default CurrencyValue;
