import React, { FC, useCallback, useState } from 'react';
import { FormElement } from '@payaca/types/formElementTypes';
import { getFormElement } from '../formUtils/getFormElement';
import TypeToSearchField from '@payaca/components/typeToSearchField/TypeToSearchField';
import { useDispatch } from 'react-redux';
import * as formActions from '@payaca/store/forms/formsActions';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import { useSelector } from '@/api/state';

const categoryIndexMap: any = {
  cat_one: 0,
  cat_two: 1,
  cat_three: 2,
  cat_four: 3,
  cat_five: 4,
};

interface Props {
  elem: FormElement;
  formState: any;
  changeHandler: any;
  onFieldTouch: any;
  touchedState: any;
  validationState: any;
  uiHandlers: any;
  name?: string;
}

export const SearchGroupFormElement: FC<Props> = ({
  elem,
  formState,
  changeHandler,
  onFieldTouch,
  touchedState,
  validationState,
  uiHandlers,
  name = elem.id,
}) => {
  const dispatch = useDispatch();
  const [catSearchTerms, setCatSearchTerms] = useState(Array(5).fill(''));
  const [isInitialised, setIsInitialised] = useState(false);

  const isSearchingFormItems = useSelector(
    (state) => state.forms.isSearchingFormItems
  );
  const searchResults = useSelector(
    (state) => state.forms.searchItemResults || []
  );

  const handleSearch = useCallback(
    (name: string, targetCategory: string, searchTerms: string) => {
      if (!elem.item_type) return;
      isInitialised &&
        changeHandler({
          [name]: searchTerms,
        });
      const targetCategoryIndex = categoryIndexMap[targetCategory] || 0;
      const newSearch = [...catSearchTerms];
      newSearch[targetCategoryIndex] = searchTerms;
      dispatch(
        formActions.requestSearchFormItems(
          elem.item_type,
          targetCategory,
          ...newSearch
        )
      );
      setCatSearchTerms(newSearch);
      setIsInitialised(true);
    },
    [dispatch, catSearchTerms]
  );

  const getValue = useCallback(
    (path: string) => {
      return path.split('.').reduce((acc, part: string) => {
        acc = acc[part] || '';
        return acc;
      }, formState);
    },
    [formState]
  );

  return (
    <div className={'form-search-group'}>
      {elem.children &&
        elem.children.map((i) => {
          const { id, title, type, item_category = 'cat_one', isOptional } = i;
          switch (type) {
            case 'search':
              return (
                <div className={'form-element'}>
                  <ValidatedFieldWrapper
                    isTouched={
                      (name
                        ? touchedState[`${name}.${id}`]
                        : touchedState[id]) || !!getValue(`${name}.${id}`)
                    }
                    validationResult={
                      validationState &&
                      (name
                        ? validationState[`${name}.${id}`]
                        : validationState[id])
                    }
                  >
                    <TypeToSearchField
                      label={title}
                      value={getValue(`${name}.${id}`)}
                      noOptionsText={'Searching...'}
                      placeholder={'Start typing to search...'}
                      isLoadingOptions={isSearchingFormItems}
                      isRequired={!isOptional}
                      onSearchTermChange={(searchTerms: string) =>
                        handleSearch(
                          `${name}.${id}`,
                          item_category,
                          searchTerms
                        )
                      }
                      onOpen={() => {
                        onFieldTouch(`${name}.${id}`);
                        handleSearch(
                          `${name}.${id}`,
                          item_category,
                          getValue(name ? `${name}.${id}` : id)
                        );
                      }}
                      options={[
                        ...searchResults.filter((i: any) => !!i[item_category]),
                        ...(searchResults.find((i: any) => {
                          return (
                            !!i[item_category] &&
                            i[item_category] === getValue(`${name}.${id}`)
                          );
                        })
                          ? []
                          : [
                              {
                                [item_category]:
                                  getValue(`${name}.${id}`) || 'N/A',
                              },
                            ]),
                      ]}
                      getOptionLabel={(option: any) => {
                        return option[item_category];
                      }}
                      renderOption={(option: any) => {
                        return <div>{option[item_category]}</div>;
                      }}
                      styleVariant={InputStyleVariant.OUTSIZE}
                    />
                  </ValidatedFieldWrapper>
                </div>
              );
            default:
              return (
                <div key={id}>
                  {getFormElement(
                    i,
                    formState[id],
                    changeHandler,
                    onFieldTouch,
                    touchedState,
                    validationState
                  )}
                </div>
              );
          }
        })}
    </div>
  );
};
