import React, { FC, useMemo } from 'react';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import Badge from '@payaca/components/badge/Badge';
import moment from 'moment-timezone';
import Button from '@payaca/components/button/Button';
import './DealPaymentItem.sass';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { PaymentMethodType } from '@payaca/types/jobPaymentTypes';
import FeedbackMessage from '@payaca/components/feedbackMessage/FeedbackMessage';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import StatusBadge, {
  StatusBadgeState,
} from '@payaca/components/statusBadge/StatusBadge';
import { useSelector } from '@/api/state';

interface Props {
  jobPaymentId: number;
  setShowRecordPaymentModal: (status: boolean) => void;
}

export const DealPaymentItem: FC<Props> = ({
  jobPaymentId,
  setShowRecordPaymentModal,
}: Props) => {
  const {
    isDepositPayment,
    paymentValue,
    paymentMethod,
    paymentCompletedConfirmationAt,
    paymentFailedConfirmationAt,
    stripePaymentAttemptedAt,
    bacsPaymentMadeAt,
  } = useSelector((state: any) => {
    return state?.jobPayments?.jobPayments[jobPaymentId]?.entity || {};
  });

  const customer = useSelector((state) => {
    return state.customer.currentCustomer;
  });

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const timestampDateRegionalFormat = useMemo(
    () =>
      getInternationalMomentDateFormatByRegion(
        DateFormats.TIMESTAMP,
        account.region
      ),
    [account]
  );

  const paymentStatus = useMemo(() => {
    if (paymentFailedConfirmationAt) {
      return 'Failed';
    }
    if (paymentCompletedConfirmationAt) {
      return 'Complete';
    }
    return 'Pending';
  }, [paymentFailedConfirmationAt, paymentCompletedConfirmationAt]);

  const badgeState = useMemo(() => {
    if (paymentFailedConfirmationAt) {
      return StatusBadgeState.RED_FAILURE;
    }
    if (paymentCompletedConfirmationAt) {
      return StatusBadgeState.GREEN_SUCCESS;
    }
    return StatusBadgeState.AMBER_PENDING;
  }, [paymentStatus]);

  return (
    <div className={`deal-payment-item ${paymentStatus.toLowerCase()}`}>
      <div className="deal-payment-item-inner flex-container">
        <div className={`deal-payment-amount`}>
          <h3>{currencyPrice(paymentValue, account.region)}</h3>
        </div>
        <div className={'deal-payment-data'}>
          <div className="flex-container flex-center payment-label">
            <h4>{isDepositPayment ? `Deposit Payment` : 'Payment'}</h4>
            <StatusBadge state={badgeState}>{paymentStatus}</StatusBadge>
          </div>
          <div>Payment method: {paymentMethod.replaceAll('_', ' ')}</div>
          {paymentMethod == PaymentMethodType.FINANCE_PAYOUT &&
            !!paymentCompletedConfirmationAt && (
              <div>
                <FeedbackMessage
                  iconBefore={faCircleInfo}
                  message={'Fees may have been deducted from this payment'}
                />
              </div>
            )}
          <div>
            <small>
              Recorded{' '}
              {customer?.name &&
              paymentMethod != PaymentMethodType.FINANCE_PAYOUT
                ? `by ${customer.name} `
                : ''}
              on{' '}
              {moment(
                bacsPaymentMadeAt ||
                  stripePaymentAttemptedAt ||
                  paymentCompletedConfirmationAt
              ).format(timestampDateRegionalFormat)}
            </small>
          </div>
        </div>

        {bacsPaymentMadeAt &&
          !paymentFailedConfirmationAt &&
          !paymentCompletedConfirmationAt && (
            <div className={'deal-payment-action'}>
              <Button onClick={() => setShowRecordPaymentModal(true)}>
                Confirm Payment
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};
