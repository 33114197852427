import React, { FunctionComponent, useEffect, useMemo } from 'react';
import './SentInvoiceValueChart.css';
import { AggregateValueBarChart, CategoriesKey } from '@/ui/components';
import { useDispatch } from 'react-redux';
import * as analyticsActions from '@payaca/store/analytics/analyticsActions';
import { AggregateValueChartData } from '@payaca/types/analyticsTypes';
import * as d3 from 'd3';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import { useSelector } from '@/api/state';

const formatXAxisTickValue = (value: number) => {
  const poundsValue = value / 100;
  return poundsValue < 1 ? '' : d3.format('~s')(poundsValue);
};

const SentInvoiceValueChart: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch();

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const accountCurrencySymbol = useMemo(
    () =>
      getRegionalTextString(account.region, RegionalStrings.CURRENCY_SYMBOL),
    [account]
  );

  const sentInvoiceValueAggregateChartData: AggregateValueChartData | null =
    useSelector((state) => {
      return state.analytics.sentInvoiceValueAggregateChartData;
    });

  useEffect(() => {
    dispatch(analyticsActions.requestGetSentInvoiceValueAggregateChartData());
    return () => {
      dispatch(analyticsActions.clearSentInvoiceValueAggregateChartData());
    };
  }, []);

  return (
    <div className={`sent-invoice-value-chart`}>
      <CategoriesKey
        categories={sentInvoiceValueAggregateChartData?.aggregateCategories}
      />
      <AggregateValueBarChart
        data={sentInvoiceValueAggregateChartData}
        xAxisLabel={`Invoices value (${accountCurrencySymbol})`}
        yAxisLabel="Month sent"
        overrideChartPadding={{ left: 75, bottom: 35, top: 0, right: 10 }}
        formatValue={(value: number) => currencyPrice(value, account.region)}
        formatXAxisTickValue={formatXAxisTickValue}
      />
    </div>
  );
};

export default SentInvoiceValueChart;
