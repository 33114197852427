import { put, take } from 'redux-saga/effects';

import { requestRefreshAuthToken } from './authActions';
import { AuthActionTypes } from './authTypes';

export function* refreshAuthToken() {
  yield put(requestRefreshAuthToken());
  yield take([
    AuthActionTypes.REFRESH_AUTH_TOKEN_FAILURE,
    AuthActionTypes.STORE_TOKENS_SUCCESS,
  ]);
}
