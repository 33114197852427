import React, { FC, useCallback, useState } from 'react';

import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getDeal, getInvoice } from '@/utils/stateAccessors';
import { Deal } from '@payaca/types/dealTypes';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import * as invoicesActions from '@payaca/store/invoices/invoicesActions';
import ValidationResultModal from '../validationResultModal/ValidationResultModal';
import { useSelector } from '@/api/state';

type Props = {
  invoiceId: number;
  proceedToPreview: () => void;
};
const EditInvoicePreviewButton: FC<Props> = ({
  invoiceId,
  proceedToPreview,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [showValidationResultModal, setShowValidationResultModal] =
    useState(false);
  const [validationResult, setValidationResult] = useState<{
    isValid: boolean;
    errors: string[];
  }>();

  const invoice = useSelector((state) => getInvoice(state, invoiceId));

  const deal: Deal | undefined = useSelector((state) =>
    invoice ? getDeal(state, invoice.dealId) : undefined
  );

  const isUpdatingInvoice: boolean = useSelector((state) => {
    return state.invoices.isUpdatingInvoice;
  });

  const isPersistingCustomer: boolean = useSelector((state) => {
    return state.customer.isPersistingCustomer;
  });

  const isSettingDealCustomer: boolean = useSelector((state) => {
    return state.deals.isSettingDealCustomer;
  });

  const isProcessing = useMemo(() => {
    return isUpdatingInvoice || isPersistingCustomer || isSettingDealCustomer;
  }, [isUpdatingInvoice, isPersistingCustomer, isSettingDealCustomer]);

  const isGettingInvoiceValidationResult: boolean = useSelector((state) => {
    return state.invoices.isGettingInvoiceValidationResult;
  });

  const isRequestPreviewEnabled = useMemo(() => {
    return !isProcessing && !!deal?.customerId;
  }, [deal?.customerId, isProcessing]);

  const onGetInvoiceValidationResultSuccess = useCallback(
    (validationResult: { isValid: boolean; errors: string[] }) => {
      setValidationResult(validationResult);
      if (!validationResult.isValid) {
        setShowValidationResultModal(true);
      } else {
        proceedToPreview();
      }
    },
    [proceedToPreview]
  );

  const onRequestPreview = useCallback(() => {
    dispatch(
      invoicesActions.requestGetInvoiceValidationResult(
        invoiceId,
        onGetInvoiceValidationResultSuccess
      )
    );
  }, [onGetInvoiceValidationResultSuccess, invoiceId, dispatch]);

  return (
    <>
      <Button
        className="edit-invoice-preview-button"
        styleVariant={ButtonStyleVariant.OUTSIZE}
        isProcessing={isGettingInvoiceValidationResult}
        isDisabled={!isRequestPreviewEnabled}
        onClick={() => !isGettingInvoiceValidationResult && onRequestPreview()}
      >
        Preview
      </Button>
      {validationResult && showValidationResultModal && (
        <ValidationResultModal
          title={'Preview not available'}
          validationResult={validationResult}
          isOpen={showValidationResultModal}
          onClose={() => setShowValidationResultModal(false)}
        />
      )}
    </>
  );
};

export default EditInvoicePreviewButton;
