import { DealActivityEvent } from '@payaca/types/dealActivityTypes';

export interface DealActivityState {
  dealActivityEvents: DealActivityEvent[] | null;
  isGettingDealActivityEvents: boolean;
}

export enum DealActivityActionTypes {
  REQUEST_GET_DEAL_ACTIVITY_EVENTS = 'dealActivity.requestGetDealActivityEvents',
  GET_DEAL_ACTIVITY_EVENTS_SUCCESS = 'dealActivity.getDealActivityEventsSuccess',
  GET_DEAL_ACTIVITY_EVENTS_FAILURE = 'dealActivity.getDealActivityEventsFailure',
  CLEAR_DEAL_ACTIVITY_EVENTS = 'dealActivity.clearDealActivityEvents',
}

export interface DealActivitySagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}
