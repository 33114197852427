import React, { FC, useMemo } from 'react';

import './TaskChecklistCompletionControl.sass';

import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import { Task } from '@payaca/types/taskTypes';

type Props = {
  onChange?: (value: { [key: string]: any }) => void;
  task: Task;
  formState: {
    id: number;
    isCompleted: boolean;
    checklistItems: {
      id: number;
      isCompleted: boolean;
    }[];
  };
  isEditable?: boolean;
};
const TaskChecklistCompletionControl: FC<Props> = ({
  task,
  formState,
  isEditable,
  onChange,
}: Props): JSX.Element | null => {
  return (
    <div className={`task-checklist-completion-control`}>
      <div>
        <CheckboxField
          isDisabled={!isEditable}
          name="isCompleted"
          value={formState.isCompleted}
          label={<strong>{task.name}</strong>}
          onChange={
            onChange
              ? (value) => {
                  const isCompleted = value.isCompleted;

                  if (isCompleted) {
                    onChange({
                      isCompleted: true,
                      checklistItems: formState.checklistItems.map((x: any) => {
                        return {
                          ...x,
                          isCompleted: true,
                        };
                      }),
                    });
                  } else if (!formState.checklistItems?.length) {
                    onChange(value);
                  }
                }
              : undefined
          }
        />
      </div>
      {!!formState.checklistItems?.length && (
        <div className="checklist-completion-container">
          <div className="relationship-line-container">
            <div className="relationship-line"></div>
          </div>
          <div>
            {formState.checklistItems.map(
              (
                formChecklistItem: {
                  id: number;
                  isCompleted: boolean;
                },
                i: number
              ) => {
                const checklistItem = task.checklistItems.find(
                  (x) => x.id == formChecklistItem.id
                );

                if (!checklistItem) return;

                return (
                  <div
                    className="checklist-item flex-container flex-center"
                    key={`checklist-item-${i}`}
                  >
                    <CheckboxField
                      isDisabled={!isEditable}
                      name={`checklistItems[${i}].isCompleted`}
                      onChange={
                        onChange
                          ? (value) => {
                              const isCompleted =
                                value[`checklistItems[${i}].isCompleted`];

                              if (!isCompleted) {
                                onChange({
                                  ...value,
                                  isCompleted: false,
                                });
                              } else if (
                                formState.checklistItems
                                  .filter(
                                    (x: any) => x.id !== formChecklistItem.id
                                  )
                                  .every((x: any) => x.isCompleted)
                              ) {
                                onChange({
                                  ...value,
                                  isCompleted: true,
                                });
                              } else {
                                onChange(value);
                              }
                            }
                          : undefined
                      }
                      value={formChecklistItem.isCompleted}
                      label={checklistItem.name}
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskChecklistCompletionControl;
