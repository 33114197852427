import useLogoutMutation from '@/api/mutations/auth/useLogout';
import { useDispatch } from 'react-redux';
import * as authActions from '@payaca/store/auth/authActions';

const useLogout = () => {
  const dispatch = useDispatch();
  const { mutateAsync: logoutMutation } = useLogoutMutation();

  return () => {
    logoutMutation().finally(() => {
      dispatch(authActions.logout());
    });
  };
};

export default useLogout;
