import React, { FC, useMemo, useState } from 'react';
import { Customer } from '@payaca/types/customerTypes';
import {
  getIsRequiredFieldValidator,
  getNumericalRangeFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { validateForm } from '@payaca/helpers/formValidationHelper';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import Button from '@payaca/components/plButton/Button';
import { getMarkupPercentageFromCommissionDeductionPercentage } from '@payaca/helpers/customerHelper';
import useGetCustomer from '@/api/queries/customers/useGetCustomer';
import { useParams } from 'react-router';
import useOptimisticUpdateCustomer from '@/api/mutations/customers/optimistic/useOptimisticUpdateCustomer';

const CustomerCommissionDeductionControl: FC = () => {
  const { customerId } = useParams<{ customerId: string }>();

  /**
   * Queries
   */
  const { data: customerData } = useGetCustomer(customerId);

  /**
   * Mutations
   */
  const { mutate: updateCustomer } = useOptimisticUpdateCustomer(customerId);

  /**
   * State
   */
  const [deductionPercentage, setDeductionPercentage] = useState<
    undefined | number
  >(customerData?.customer.commissionDeductionPercentage || 0);

  const [deductionPercentageString, setDeductionPercentageString] = useState(
    `${deductionPercentage}%`
  );

  const validators = useMemo(
    () => [
      getIsRequiredFieldValidator({
        customErrorMessage: 'This field must contain valid input',
      }),
      getNumericalRangeFieldValidator(
        -0.0001,
        100,
        undefined,
        'This field must have a positive value',
        'This field may not exceed 100%'
      ),
    ],
    []
  );

  const { deductionPercentage: deductionPercentageValidationResult } =
    useMemo(() => {
      return validateForm(
        { deductionPercentage },
        { deductionPercentage: validators }
      );
    }, [deductionPercentage]);

  const onSubmit = (
    deductionPercentage: Customer['commissionDeductionPercentage']
  ) => {
    updateCustomer({
      customerId,
      commissionDeductionPercentage: deductionPercentage,
    });
  };

  return (
    <div>
      <h4>Commission</h4>
      <hr />
      <div className="mt-6">
        <Field
          validationState={
            deductionPercentageValidationResult.isValid
              ? undefined
              : {
                  isValid: deductionPercentageValidationResult.isValid,
                  validationMessages:
                    deductionPercentageValidationResult.errors,
                }
          }
        >
          <Field.Label className="mb-0">What you pay the customer</Field.Label>
          <span className="mb-4 block text-sm text-gray-600">
            This is the % which will be charged to you
          </span>
          <div className="flex flex-row gap-4">
            <Input
              className="w-[120px]"
              value={deductionPercentageString}
              onChange={(value) => {
                const dp = parseFloat(value.replace('%', ''));

                setDeductionPercentageString(value);

                if (isNaN(dp)) {
                  setDeductionPercentage(undefined);
                } else {
                  setDeductionPercentage(dp);
                }
              }}
              onBlur={() => {
                if (deductionPercentage !== undefined) {
                  setDeductionPercentageString(`${deductionPercentage}%`);
                }
              }}
            />
            <Button
              disabled={!deductionPercentageValidationResult.isValid}
              onClick={() => onSubmit(deductionPercentage)}
            >
              Save
            </Button>
          </div>
        </Field>
      </div>
      <div className="mt-6">
        <Field>
          <Field.Label className="mb-0">Added to your Invoices</Field.Label>
          <span className="mb-2 block text-sm text-gray-600">
            This is the % which will be applied on Invoices to account for this
            Customer&apos;s commission
          </span>
          <span className="text-md font-semibold">
            {deductionPercentage && deductionPercentageValidationResult.isValid
              ? `${getMarkupPercentageFromCommissionDeductionPercentage(
                  deductionPercentage
                )}%`
              : ''}
          </span>
        </Field>
      </div>
    </div>
  );
};

export default CustomerCommissionDeductionControl;
