export enum AddressAttachableEntityType {
  DEAL = 'deal',
}

export type LoqateAddressResult = {
  id: string;
  label: string;
  type?: 'Postcode' | 'Address' | 'BuildingName';
};

export enum LoqateAddressResultType {
  POSTCODE = 'Postcode',
  ADDRESS = 'Address',
  BUILDING_NAME = 'BuildingName',
}
