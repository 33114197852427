// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

const subscriptionKeys = {
  all: ['subscription'] as const,
  products: () => {
    return [
      ...subscriptionKeys.all,
      'products',
    ] as const;
  },
};

export default subscriptionKeys;
