import { Reducer } from 'redux';
import { call, delay, put, race } from 'redux-saga/effects';

import { FetchedEntity } from '@payaca/types/storeTypes';
import { IsExact, NoExtraProperties, Optional } from '@payaca/utilities/types';

import { refreshAuthToken } from './auth/refreshAuthToken';

import {
  Action,
  AsyncAction,
  AsyncActionCreator,
  ErrorAction,
  InferActionMeta,
  InferActionPayload,
  RequestPayloadAndMetaCreator,
  UnpackRequestAction,
} from './types';

/**
 * Create an action creator for an AsyncAction
 */
export const createAsyncAction =
  <T extends AsyncAction>({
    request,
    success,
    failure,
  }: {
    request: T['request']['type'];
    success?: T['success']['type'];
    failure?: T['failure']['type'];
  }) =>
  <U extends RequestPayloadAndMetaCreator<T>>(
    fn?: IsExact<
      keyof ReturnType<RequestPayloadAndMetaCreator<T>>,
      keyof ReturnType<U>
    > extends true
      ? U
      : void extends InferActionPayload<UnpackRequestAction<T>>
      ? void extends InferActionMeta<UnpackRequestAction<T>>
        ? undefined
        : never
      : never
  ): AsyncActionCreator<T, U> => {
    return {
      request: (...args: Parameters<U>) => {
        const a: any = (fn ?? (() => ({})))(...args as Iterable<any>);
        return {
          type: request,
          payload: a.payload,
          meta: a.meta,
        } as unknown as ReturnType<AsyncActionCreator<T, U>['request']>;
      },
      success:
        success &&
        (((payload: SuccessPayload<T>, meta: RequestMeta<T>) => ({
          type: success,
          payload,
          meta,
        })) as unknown as AsyncActionCreator<T, U>['success']),
      failure:
        failure &&
        (((err: Error, payload: RequestPayload<T>, meta: RequestMeta<T>) => ({
          type: failure,
          payload,
          meta,
          error: err,
        })) as unknown as AsyncActionCreator<T, U>['failure']),
    };
  };

/**
 * Create a generator function to serve as a saga handler for an AsyncAction
 */
export const handleAsyncAction = <T extends AsyncAction>(
  creator: AsyncActionCreator<T, (...args: Array<any>) => any>,
  handler: Handler<T>,
  onSuccess?: (
    successPayload: SuccessPayload<T>,
    requestData: {
      payload: RequestPayload<T>;
      meta: RequestMeta<T>;
    }
  ) => void,
  onFailure?: (
    error: Error,
    requestData: {
      payload: RequestPayload<T>;
      meta: RequestMeta<T>;
    }
  ) => void,
  debounce?: number
) => {
  return function* (action: T['request']) {
    if (debounce) {
      yield delay(debounce);
    }
    yield call(refreshAuthToken);
    const { payload: requestPayload, meta: requestMeta } =
      action as UnknownAction;
    try {
      const { response, timeout } = yield race({
        response: call(handler as UnknownFetcher, requestPayload, requestMeta),
        timeout: delay(30000),
      });
      if (timeout) {
        throw new Error('Request timed out');
      }
      if (creator.success) {
        yield put(
          (creator.success as any)(response, (action as UnknownAction).meta)
        );
      }
      onSuccess?.(response as SuccessPayload<T>, {
        payload: requestPayload as RequestPayload<T>,
        meta: requestMeta as RequestMeta<T>,
      });
    } catch (err) {
      const errorInstance = err instanceof Error ? err : new Error(String(err));
      if (creator.failure) {
        yield put(
          (creator.failure as any)(errorInstance, requestPayload, requestMeta)
        );
      }
      onFailure?.(errorInstance, {
        payload: requestPayload as RequestPayload<T>,
        meta: requestMeta as RequestMeta<T>,
      });
    }
  };
};

type Handler<T extends AsyncAction> = T['request'] extends Action<
  string,
  infer TPayload,
  infer TMeta
>
  ? void extends TPayload
    ? void extends TMeta
      ? () => T['success'] extends Action<string, infer SuccessPayload>
          ? Promise<SuccessPayload>
          : never
      : (
          meta: TMeta
        ) => T['success'] extends Action<string, infer SuccessPayload>
          ? Promise<SuccessPayload>
          : never
    : void extends TMeta
    ? (
        payload: TPayload
      ) => T['success'] extends Action<string, infer SuccessPayload>
        ? Promise<SuccessPayload>
        : never
    : (
        payload: TPayload,
        meta: TMeta
      ) => T['success'] extends Action<string, infer SuccessPayload>
        ? Promise<SuccessPayload>
        : never
  : never;

type RequestPayload<T extends AsyncAction> = T['request'] extends Action<
  any,
  infer TPayload
>
  ? TPayload
  : never;

type RequestMeta<T extends AsyncAction> = T['request'] extends Action<
  any,
  any,
  infer TMeta
>
  ? TMeta
  : never;

type SuccessPayload<T extends AsyncAction> = T['success'] extends Action<
  any,
  infer TPayload
>
  ? TPayload
  : never;

type UnknownAction = {
  type: string;
  payload?: unknown;
  meta?: unknown;
};

type UnknownFetcher = (payload?: unknown, meta?: unknown) => Promise<unknown>;

/**
 * Create an optimised, type-safe reducer.
 */
export const createReducer =
  <
    TState,
    TActionType extends string,
    TAnyAction extends Action<TActionType> | ErrorAction<TActionType>
  >(
    initialState: TState,
    reducers: Partial<ReducerMap<TState, TActionType, TAnyAction>>
  ): Reducer<TState, TAnyAction> =>
  (state = initialState, action) =>
    reducers[action.type]?.(
      state,
      action as ActionForType<TActionType, TAnyAction>
    ) ?? state;

type ReducerMap<
  TState,
  TActionType extends string,
  TAnyAction extends Action
> = {
  [TKey in TActionType]: (
    state: TState,
    action: ActionForType<TKey, TAnyAction>
  ) => NoExtraProperties<TState>;
};

type ActionForType<T extends string, U extends Action> = U extends Action<T>
  ? U
  : never;

export const mergeFetchedEntities = <
  T extends Record<string, any>,
  TIdKey extends keyof T
>({
  cache,
  values,
  now = new Date(),
  idKey = 'id' as TIdKey,
}: {
  cache: Optional<Record<T[TIdKey], FetchedEntity<T>>>;
  values: Array<T>;
  now?: Optional<Date>;
  idKey?: Optional<TIdKey>;
}) =>
  values.reduce(
    (acc, value) => {
      return {
        ...acc,
        [value[idKey]]: {
          ...cache?.[value[idKey] as T[TIdKey]],
          entity: value,
          fetchSucceededAt: now,
          isFetching: false,
        },
      };
    },
    {
      ...cache,
    }
  );
