import React, { FC, PropsWithChildren } from 'react';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import UntitledIcon from '@payaca/untitled-icons';
import Card from '@payaca/components/plCard/Card';
import Conditional from '@payaca/components/conditional/Conditional';

export interface IProps {
  onRemove?: () => void;
  thumbnailUrl: string;
  fileName: string;
}

const ProjectFileCard: FC<IProps> = (props) => {
  const { onRemove, thumbnailUrl, fileName } = props;

  return (
    <Card className="relative flex aspect-square items-center justify-center overflow-hidden">
      <img src={thumbnailUrl} alt={fileName} className="h-auto w-full" />

      <Conditional condition={!!onRemove}>
        <Button
          className="!absolute right-2 top-2"
          variant={EBtnVariant.Ghost}
          colour={EBtnColour.Gray}
          size={EBtnSize.XSmall}
          onClick={onRemove}
        >
          <UntitledIcon name="x-close" className="h-3 w-3" />
        </Button>
      </Conditional>
    </Card>
  );
};

const ProjectFileCardWrapper = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren
>((props, ref) => {
  const { children } = props;

  return (
    <div
      className="grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-6"
      ref={ref}
    >
      {children}
    </div>
  );
});

export default Object.assign(ProjectFileCard, {
  Wrapper: ProjectFileCardWrapper,
});
