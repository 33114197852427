import { Customer, TPatchCustomerBody } from '@payaca/types/customerTypes';
import { ListedCustomersListViewPage } from '@payaca/types/listedCustomerTypes';
import { FetchedEntity } from '@payaca/types/storeTypes';
import { DeclareAsyncAction } from '../types';

export interface State {
  isArchivingCustomers: boolean;
  areCustomersArchivedSuccessfully?: boolean;
  isPersistingCustomer: boolean;
  isCustomerPersistedSuccessfully?: boolean;
  currentCustomer?: Customer;
  isGettingCustomer: boolean;
  isGettingListedCustomersPage: boolean;
  listedCustomersPage: ListedCustomersListViewPage | null;

  customers: {
    [key: string]: FetchedEntity<Customer>;
  };
}

export enum ActionType {
  REQUEST_PERSIST_CUSTOMER = 'customer.requestPersistCustomer',
  PERSIST_CUSTOMER_SUCCESS = 'customer.persistCustomerSuccess',
  PERSIST_CUSTOMER_FAILURE = 'customer.persistCustomerFailure',

  REQUEST_GET_AND_SET_CURRENT_CUSTOMER = 'customer.requestGetAndSetCurrentCustomer',
  SET_CURRENT_CUSTOMER = 'customer.setCurrentCustomer',
  CLEAR_CURRENT_CUSTOMER = 'customer.clearCurrentCustomer',
  CLEAR_CUSTOMERS = 'customer.clearCustomers',

  REQUEST_GET_CUSTOMER = 'customer.requestGetCustomer',
  GET_CUSTOMER_SUCCESS = 'customer.getCustomerSuccess',
  GET_CUSTOMER_FAILURE = 'customer.getCustomerFailure',

  REQUEST_GET_CUSTOMER_FOR_SCHEDULED_EVENT = 'customer.requestGetCustomerForScheduledEvent',

  REQUEST_GET_LISTED_CUSTOMERS_PAGE = 'customer.requestGetListedCustomersPage',
  GET_LISTED_CUSTOMERS_PAGE_SUCCESS = 'customer.getListedCustomersPageSuccess',
  GET_LISTED_CUSTOMERS_PAGE_FAILURE = 'customer.getListedCustomersPageFailure',
  CLEAR_LISTED_CUSTOMERS_PAGE = 'customer.clearListedCustomersPage',

  REQUEST_ARCHIVE_CUSTOMERS = 'customer.requestArchiveCustomers',
  ARCHIVE_CUSTOMERS_SUCCESS = 'customer.archiveCustomersSuccess',
  ARCHIVE_CUSTOMERS_FAILURE = 'customer.archiveCustomersFailure',

  GET_CUSTOMER_ANALYTICS_REQUEST = 'customer.getCustomerAnalytics:request',
  GET_CUSTOMER_ANALYTICS_SUCCESS = 'customer.getCustomerAnalytics:success',
  GET_CUSTOMER_ANALYTICS_FAILURE = 'customer.getCustomerAnalytics:failure',

  PATCH_CUSTOMER_REQUEST = 'customer.putCustomer:request',
  PATCH_CUSTOMER_SUCCESS = 'customer.putCustomer:success',
  PATCH_CUSTOMER_FAILURE = 'customer.putCustomer:failure',

  EXPORT_CUSTOMERS_DATA_REQUEST = 'customer.exportData:request',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export type GetCustomerAnalytics = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.GET_CUSTOMER_ANALYTICS_REQUEST;
      payload: {
        customerId: number;
        callback?: (data: any) => void;
      };
    };
    success: {
      type: ActionType.GET_CUSTOMER_ANALYTICS_SUCCESS;
    };
    failure: {
      type: ActionType.GET_CUSTOMER_ANALYTICS_FAILURE;
    };
  }
>;

export type ExportCustomersData = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.EXPORT_CUSTOMERS_DATA_REQUEST;
      payload: {
        callback?: () => void;
      };
    };
  }
>;

export type PatchCustomer = DeclareAsyncAction<
  ActionType,
  {
    request: {
      type: ActionType.PATCH_CUSTOMER_REQUEST;
      payload: {
        customerId: number;
        body: TPatchCustomerBody;
        callback?: (data: any) => void;
      };
    };
    success: {
      type: ActionType.PATCH_CUSTOMER_SUCCESS;
      payload: Customer;
    };
    failure: {
      type: ActionType.PATCH_CUSTOMER_FAILURE;
    };
  }
>;
