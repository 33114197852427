import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import projectKeys from '@/api/queries/project/keyFactory';

const GetProjectProfitBreakdown = graphql(`
  query GetProjectProfitBreakdown($projectId: Int!) {
    project(id: $projectId) {
      profitBreakdown {
        revenue {
          actual {
            currency {
              code
              exponent
            }
            value
          }
          forecast {
            currency {
              code
              exponent
            }
            value
          }
        }
        labour {
          actual {
            currency {
              code
              exponent
            }
            value
          }
          forecast {
            currency {
              code
              exponent
            }
            value
          }
        }
        materials {
          actual {
            currency {
              code
              exponent
            }
            value
          }
          forecast {
            currency {
              code
              exponent
            }
            value
          }
        }
        profit {
          actual {
            currency {
              code
              exponent
            }
            value
          }
          forecast {
            currency {
              code
              exponent
            }
            value
          }
        }
      }
    }
  }
  fragment BreakdownItem on ForecastValue {
    actual {
      currency {
        code
        exponent
      }
      value
    }
    forecast {
      currency {
        code
        exponent
      }
      value
    }
  }
`);

const useGetProjectProfitBreakdown = (projectId?: number) => {
  return useQuery({
    enabled: !!projectId,
    queryKey: projectKeys.profitBreakdown(projectId!),
    queryFn: () => {
      return gqlClient.request(GetProjectProfitBreakdown, {
        projectId: projectId!,
      });
    },
  });
};

export default useGetProjectProfitBreakdown;
