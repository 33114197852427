import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import BasicField from '@payaca/components/basicField/BasicField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import CurrencyField from '@payaca/components/currencyField/CurrencyField';
import ProgressiveDisclosure from '@payaca/components/progressiveDisclosure/ProgressiveDisclosure';
import SupplierSelectionControl from '../supplierSelectionControl/SupplierSelectionControl';
import SalesTaxSettingsField from '../salesTaxSettingsField/SalesTaxSettingsField';

import './SupplierMaterialControl.sass';

type Props = {
  supplierMaterial: {
    id?: number;
    supplierId?: number;
    price?: number;
    reference?: string;
    url?: string;
    taxRateId: number;
  };
  fieldNamePrefix?: string;
  touchedState?: { [fieldName: string]: boolean };
  validationState?: { [fieldName: string]: FieldValidationResult };
  onChange: (value: { [key: string]: any }) => void;
  onTouch?: (fieldName: string) => void;
};

const SupplierMaterialControl: FunctionComponent<Props> = ({
  supplierMaterial,
  fieldNamePrefix,
  touchedState = {},
  validationState,
  onTouch,
  onChange,
}: Props): JSX.Element | null => {
  const [showMaterialUrl, setShowMaterialUrl] = useState(false);
  const [showSupplierMaterialReference, setShowSupplierMaterialReference] =
    useState(false);
  const getFieldName = useCallback(
    (name: string) => {
      return fieldNamePrefix ? `${fieldNamePrefix}.${name}` : name;
    },
    [fieldNamePrefix]
  );
  const referenceFieldName = useMemo(
    () => getFieldName('reference'),
    [fieldNamePrefix]
  );

  const urlFieldName = useMemo(() => getFieldName('url'), [fieldNamePrefix]);

  const priceFieldName = useMemo(
    () => getFieldName('price'),
    [fieldNamePrefix]
  );

  const supplierIdFieldName = useMemo(
    () => getFieldName('supplierId'),
    [fieldNamePrefix]
  );

  const taxRateIdFieldName = useMemo(
    () => getFieldName('taxRateId'),
    [fieldNamePrefix]
  );

  return (
    <ResponsiveViewWrapper
      downBreakpointSm={850}
      className="material-supplier-material-control"
    >
      <div className="supplier-field">
        <ValidatedFieldWrapper
          validationResult={
            validationState ? validationState[supplierIdFieldName] : undefined
          }
          isTouched={touchedState[supplierIdFieldName] || false}
        >
          <SupplierSelectionControl
            label="Supplier"
            selectedSupplierId={supplierMaterial.supplierId}
            onChange={(selectedSupplierId) => {
              onTouch && onTouch(supplierIdFieldName);
              onChange({ [supplierIdFieldName]: selectedSupplierId });
            }}
            enableSupplierCreation={true}
            excludeSystemManaged={true}
          />
        </ValidatedFieldWrapper>
      </div>
      <div className="cost-tax-wrapper">
        <div className="cost-field">
          <ValidatedFieldWrapper
            validationResult={
              validationState ? validationState[priceFieldName] : undefined
            }
            isTouched={touchedState[priceFieldName] || false}
          >
            <CurrencyField
              isRequired={true}
              name={priceFieldName}
              label="Cost (ex tax)"
              value={supplierMaterial.price}
              onTouch={onTouch}
              onChange={onChange}
              styleVariant={InputStyleVariant.OUTSIZE}
            />
          </ValidatedFieldWrapper>
        </div>
        <div className="tax-field">
          <SalesTaxSettingsField
            excludeReverseChargeOptions={true}
            styleVariant={InputStyleVariant.OUTSIZE}
            taxRateId={supplierMaterial.taxRateId}
            onChange={(value: { [key: string]: any }) => {
              onChange({
                [taxRateIdFieldName]: value.taxRateId,
              });
            }}
          />
        </div>
      </div>
      <ProgressiveDisclosure
        title="Add Material URL"
        setIsRevealed={() => {
          setShowMaterialUrl(true);
        }}
        isRevealed={showMaterialUrl}
      >
        <ValidatedFieldWrapper
          validationResult={
            validationState ? validationState[urlFieldName] : undefined
          }
          isTouched={touchedState[urlFieldName] || false}
        >
          <BasicField
            name={urlFieldName}
            label="Material URL"
            value={supplierMaterial.url}
            onTouch={onTouch}
            onChange={onChange}
            styleVariant={InputStyleVariant.OUTSIZE}
          />
        </ValidatedFieldWrapper>
      </ProgressiveDisclosure>
      <ProgressiveDisclosure
        title="Add Supplier reference"
        setIsRevealed={() => {
          setShowSupplierMaterialReference(true);
        }}
        isRevealed={showSupplierMaterialReference}
      >
        <ValidatedFieldWrapper
          validationResult={
            validationState ? validationState[referenceFieldName] : undefined
          }
          isTouched={touchedState[referenceFieldName] || false}
        >
          <BasicField
            name={referenceFieldName}
            label="Supplier reference"
            value={supplierMaterial.reference}
            onTouch={onTouch}
            onChange={onChange}
            styleVariant={InputStyleVariant.OUTSIZE}
          />
        </ValidatedFieldWrapper>
      </ProgressiveDisclosure>
    </ResponsiveViewWrapper>
  );
};

export default SupplierMaterialControl;
