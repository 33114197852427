export enum AutomationDataAccessType {
  CUSTOMER_NAME = 'customer.name',

  CUSTOMER_PRIMARYCONTACT_NAME = 'primaryContact.name',
  CUSTOMER_PRIMARYCONTACT_EMAILADDRESS = 'primaryContact.emailAddress',
  CUSTOMER_PRIMARYCONTACT_TELEPHONENUMBER = 'primaryContact.telephoneNumber',

  ACCOUNT_COMPANYNAME = 'account.companyName',
  ACCOUNT_VATNUMBER = 'account.vatNumber',
  ACCOUNT_ACCOUNTNAME = 'account.accountName',
  ACCOUNT_ACCOUNTNUMBER = 'account.accountNumber',
  ACCOUNT_SORTCODE = 'account.sortCode',
  ACCOUNT_GASSAFEREGNUMBER = 'account.gasSafeRegNo',
  ACCOUNT_NICEICREGNUMBER = 'account.niceicRegNo',
  ACCOUNT_EMAILADDRESS = 'account.email',
  ACCOUNT_ADDRESS = 'account.address',
  ACCOUNT_CITY = 'account.city',
  ACCOUNT_POSTCODE = 'account.postcode',
  ACCOUNT_CONTACTNUMBER = 'account.contactNumber',
  ACCOUNT_PAYMENTTERMS = 'account.paymentTerms',
  ACCOUNT_BUSINESSNOTES = 'account.businessNotes',
  ACCOUNT_SMSSENDERHANDLE = 'account.smsSenderHandle',
  ACCOUNT_REGION = 'account.region',

  DEAL_TAGS_TAGTEXT_ARRAY = 'deal.tags.X.tagText',
  DEAL_NOT_TAGS_TAGTEXT_ARRAY = 'deal.not.tags.X.tagText',
  DEAL_REFERENCE = 'deal.reference',
  DEAL_DESCRIPTION = 'deal.description',
  DEAL_CREATEDAT = 'deal.createdAt',
  DEAL_PIPELINESTAGE = 'deal.pipelineStage',
  DEAL_PIPELINEID = 'deal.pipelineId',
  DEAL_SITEADDRESSES_ARRAYSTRING = 'deal.siteaddresses.arraystring',
  DEAL_SITEADDRESSES_CONTACTS_EMAILADDRESS_ARRAY = 'deal.siteaddresses.contacts.emailaddress.array',
  DEAL_SITEADDRESSES_CONTACTS_TELEPHONENUMBER_ARRAY = 'deal.siteaddresses.contacts.telephonenumber.array',
  DEAL_CUSTOMFIELD_VALUE = 'project.customField.value',

  USER_FIRSTNAME = 'user.firstname',
  USER_LASTNAME = 'user.lastname',
  USER_EMAILADDRESS = 'user.email',
  USER_SYSTEMROLE = 'user.systemRole',
  USER_GASSAFEIDCARDNUMBER = 'user.gasSafeIdCardNumber',
  USER_CONTACTNUMBER = 'user.contactNumber',

  SCHEDULEDEVENT_TAGS_TAGTEXT_ARRAY = 'event.tags.X.tagText',
  SCHEDULEDEVENT_ASSIGNEDUSERS_EMAILADDRESS_ARRAY = 'event.assignedusers.emailaddress.array',
  SCHEDULEDEVENT_ASSIGNEDUSERS_EMAILADDRESS_ARRAYSTRING = 'event.assignedusers.emailaddress.arraystring',
  SCHEDULEDEVENT_ASSIGNEDUSERS_NAME_ARRAYSTRING = 'event.assignedusers.name.arraystring',
  SCHEDULEDEVENT_ASSIGNEDUSERS_TELEPHONENUMBER_ARRAY = 'event.assignedusers.telephonenumber.array',
  SCHEDULEDEVENT_NAME = 'event.name',
  SCHEDULEDEVENT_DESCRIPTION = 'event.description',
  SCHEDULEDEVENT_BEGINAT = 'event.beginAt',
  SCHEDULEDEVENT_BEGINAT_FORMATTEDDATE = 'event.beginAt.formattedDate',
  SCHEDULEDEVENT_BEGINAT_FORMATTEDTIME = 'event.beginAt.formattedTime',
  SCHEDULEDEVENT_ENDAT = 'event.endAt',
  SCHEDULEDEVENT_ENDAT_FORMATTEDDATE = 'event.endAt.formattedDate',
  SCHEDULEDEVENT_ENDAT_FORMATTEDTIME = 'event.endAt.formattedTime',
  SCHEDULEDEVENT_LOCATION_ADDRESS_STRING = 'event.location.address.string',
  SCHEDULEDEVENT_NOT_TAGS_TAGTEXT_ARRAY = 'event.not.tags.X.tagText',
  SCHEDULEDEVENT_TASKSTATUS = 'event.taskStatus',
  SCHEDULEDEVENT_LOCATION_CONTACTS_EMAILADDRESS_ARRAY = 'event.location.contacts.emailaddress.array',
  SCHEDULEDEVENT_LOCATION_CONTACTS_TELEPHONENUMBER_ARRAY = 'event.location.contacts.telephonenumber.array',

  INVOICE_READABLE_STATUS = 'invoice.readableStatus',
  INVOICE_REFERENCE = 'invoice.reference',
  INVOICE_AMOUNTDUE = 'invoice.amountDue',
  INVOICE_DUEAT = 'invoice.dueAt',
  INVOICE_DUEAT_FORMATTEDDATE = 'invoice.dueAt.formattedDate',
  INVOICE_VIEWLINK = 'invoice.viewLink',
  INVOICE_VIEWBUTTON = 'invoice.viewButton',
  INVOICE_SENTAT = 'invoice.sentAt',
  INVOICE_SENTAT_FORMATTEDDATE = 'invoice.sentAt.formattedDate',
  INVOICE_VIEWEDAT = 'invoice.viewedAt',
  INVOICE_VIEWEDAT_FORMATTEDDATE = 'invoice.viewedAt.formattedDate',
  INVOICE_CREATEDAT = 'invoice.createdAt',
  INVOICE_CREATEDAT_FORMATTEDDATE = 'invoice.createdAt.formattedDate',

  PROPOSAL_READABLE_STATUS = 'proposal.readableStatus',
  PROPOSAL_REFERENCE = 'proposal.reference',
  PROPOSAL_VALIDUNTIL = 'proposal.validUntil',
  PROPOSAL_VIEWLINK = 'proposal.viewLink',
  PROPOSAL_VALIDUNTIL_FORMATTEDDATE = 'proposal.validUntil.formattedDate',
  PROPOSAL_VIEWBUTTON = 'proposal.viewButton',
  PROPOSAL_SENTAT = 'proposal.sentAt',
  PROPOSAL_SENTAT_FORMATTEDDATE = 'proposal.sentAt.formattedDate',
  PROPOSAL_VIEWEDAT = 'proposal.viewedAt',
  PROPOSAL_VIEWEDAT_FORMATTEDDATE = 'proposal.viewedAt.formattedDate',
  PROPOSAL_ACCEPTEDAT = 'proposal.acceptedAt',
  PROPOSAL_ACCEPTEDAT_FORMATTEDDATE = 'proposal.acceptedAt.formattedDate',
  PROPOSAL_ACCEPTEDAT_FORMATTEDTIME = 'proposal.acceptedAt.formattedTime',
  PROPOSAL_CREATEDAT = 'proposal.createdAt',
  PROPOSAL_CREATEDAT_FORMATTEDDATE = 'proposal.createdAt.formattedDate',

  TRIGGER_TAGTEXT_ARRAY = 'trigger.X.tagText',
  TRIGGER_PIPELINESTAGE_NEW = 'trigger.newPipelineStage',
  TRIGGER_PIPELINESTAGE_OLD = 'trigger.oldPipelineStage',

  TRIGGER_TRIGGEREDAT_DAYOFWEEK = 'trigger.triggeredAt.dayOfWeek',

  // custom data entry
  CUSTOM_TELEPHONENUMBER = 'customTelephoneNumber',
  CUSTOM_EMAILADDRESS = 'customEmailAddress',
}
