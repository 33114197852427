import React, { FunctionComponent } from 'react';

import { default as MaterialUiCheckbox } from '@material-ui/core/Checkbox';

import './Checkbox.sass';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: () => void;
  colour?: string;
  preventClickPropagation?: boolean;
  id?: string;
};

const Checkbox: FunctionComponent<Props> = ({
  isChecked,
  isDisabled = false,
  colour,
  onChange,
  preventClickPropagation,
  id,
}: Props): JSX.Element => {
  return (
    <div
      className={`checkbox ${isDisabled ? 'disabled' : ''} ${
        isChecked ? 'checked' : ''
      }`}
    >
      <MaterialUiCheckbox
        color="default"
        checked={isChecked}
        //onChange was not firing when it should have been in certain circumstances. Moving onchange functionality to onClick which works in all places and was recmmended on stackOverflow.
        //onChange={onChange}
        onClick={(e) => {
          onChange();
          preventClickPropagation && e.stopPropagation();
        }}
        inputProps={{ 'aria-label': 'checkbox', id: `${id}` }}
        disabled={isDisabled}
        checkedIcon={
          <div className="checkmark-wrapper">
            <FontAwesomeIcon icon={faCheck} />
          </div>
        }
        icon={<div className="checkmark-wrapper"></div>}
      />
    </div>
  );
};

export default Checkbox;
