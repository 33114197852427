import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  RemoveTagsFromEntityMutation,
  RemoveTagsFromEntityMutationVariables,
  RemoveTagsFromEntityInput,
} from '@/gql/graphql';

const RemoveTagsFromEntity = graphql(`
  mutation RemoveTagsFromEntity($input: RemoveTagsFromEntityInput!) {
    removeTagsFromEntity(input: $input) {
      ... on Project {
        id
        tags {
          id
          colour
          name
        }
      }
      ... on ScheduledEvent {
        id
        tags {
          id
          colour
          name
        }
      }
    }
  }
`);

const useRemoveTagsFromEntity = (
  options?: UseMutationOptions<
    RemoveTagsFromEntityMutation,
    unknown,
    RemoveTagsFromEntityMutationVariables['input']
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(RemoveTagsFromEntity, { input });
    },
    ...options,
  });
};

export default useRemoveTagsFromEntity;
