import {
  ActionType,
  GetListedFilesForDeal,
  PutShareFilesForDeal,
  PutUnshareFilesForDeal,
} from './listedFilesTypes';
import { createAsyncAction } from '../utils';

// GET NOTES FOR DEAL
export const getListedFilesForDeal = createAsyncAction<GetListedFilesForDeal>({
  request: ActionType.GET_LISTED_FILES_FOR_DEALS_REQUEST,
  success: ActionType.GET_LISTED_FILES_FOR_DEALS_SUCCESS,
  failure: ActionType.GET_LISTED_FILES_FOR_DEALS_FAILURE,
})((payload: GetListedFilesForDeal['request']['payload']) => ({ payload }));

export const shareFilesForDeal = createAsyncAction<PutShareFilesForDeal>({
  request: ActionType.SHARE_LISTED_FILES_REQUEST,
  success: ActionType.SHARE_LISTED_FILES_SUCCESS,
  failure: ActionType.SHARE_LISTED_FILES_FAILURE,
})((payload: PutShareFilesForDeal['request']['payload']) => ({ payload }));

export const unshareFilesForDeal = createAsyncAction<PutUnshareFilesForDeal>({
  request: ActionType.UNSHARE_LISTED_FILES_REQUEST,
  success: ActionType.UNSHARE_LISTED_FILES_SUCCESS,
  failure: ActionType.UNSHARE_LISTED_FILES_FAILURE,
})((payload: PutUnshareFilesForDeal['request']['payload']) => ({ payload }));
