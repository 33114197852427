import React, { FunctionComponent } from 'react';

import Modal from '@payaca/components/modal/Modal';

import './ValidationResultModal.sass';

type Props = {
  isOpen: boolean;
  validationResult: { isValid: boolean; errors: string[] };
  onClose: () => void;
  title?: string;
};

const ValidationResultModal: FunctionComponent<Props> = ({
  isOpen,
  title,
  validationResult,
  onClose,
}: Props): JSX.Element | null => {
  return (
    <Modal
      isOpen={isOpen}
      title={title || ''}
      onClose={() => onClose()}
      className="validation-result-modal"
      size="sm"
    >
      <p>Resolve the following validation errors and try again</p>
      {validationResult.errors.length && (
        <ul className="validation-errors">
          {validationResult.errors.map((error, index) => (
            <li key={`validation-result-error-${index}`}>{error}</li>
          ))}
        </ul>
      )}
    </Modal>
  );
};

export default ValidationResultModal;
