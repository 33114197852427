import { PaymentsPermissions } from './payments.permissions';
import { PaymentsRoles } from './payments.roles';

export const paymentsMappings = {
  [PaymentsPermissions.GET_PAYMENTS]: [
    PaymentsRoles.VIEWER,
    PaymentsRoles.RECORDER,
  ],
  [PaymentsPermissions.RECORD_PAYMENT]: [PaymentsRoles.RECORDER],
  [PaymentsPermissions.CONFIRM_PAYMENT]: [PaymentsRoles.RECORDER],
  [PaymentsPermissions.TAKE_PAYMENT]: [PaymentsRoles.RECORDER],
  [PaymentsPermissions.BULK_MARK_INVOICES_PAID]: [PaymentsRoles.BULK_RECORDER],
};
