import React, { ComponentPropsWithoutRef, FC, forwardRef } from 'react';
import { clstx } from '../utils';

const TooltipCore = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  (props, ref) => {
    const { className, children, ...rest } = props;

    return (
      <div
        ref={ref}
        className={clstx(
          'z-tooltip max-w-prose rounded-lg bg-gray-900 px-2 py-1 text-sm font-medium text-white shadow-sm dark:bg-slate-700',
          className
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

export default TooltipCore;
