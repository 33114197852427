import PhoneInput from 'react-phone-input-2';

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import FieldLabel from '../fieldLabel/FieldLabel';
import InputWrapper, { InputStyleVariant } from '../inputWrapper/InputWrapper';

import 'react-phone-input-2/lib/bootstrap.css';
import './PhoneNumberField.css';
import { AccountRegions } from '@payaca/types/accountTypes';
import { useSelector } from 'react-redux';

const formatValue = (value: string, dialCode: string) => {
  let formattedValue = value;
  // remove any leading zeros after country prefix
  if (value[2] === '0') {
    formattedValue = value.slice(0, 2) + value.slice(3, value.length);
  }
  // if phone value is dialcode clear input value
  if (value === dialCode) {
    formattedValue = '';
  }
  return formattedValue;
};

type Props = {
  styleVariant?: InputStyleVariant;
  name: string;
  value?: any;
  label?: string;
  description?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  iconBefore?: IconDefinition;
  iconAfter?: IconDefinition;
  onChange?: (value: { [key: string]: string }) => void;
  onTouch?: (fieldName: string) => void;
};

const BasicField: FunctionComponent<React.PropsWithChildren<Props>> = ({
  styleVariant,
  name,
  value,
  label,
  description,
  isDisabled = false,
  isRequired = false,
  iconBefore,
  iconAfter,
  onChange,
  onTouch,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const onInputChange = useCallback(
    (value: string, country: any) => {
      const formattedValue = formatValue(value, country.dialCode);
      onChange &&
        onChange({
          [name]: formattedValue,
        });
    },
    [onChange, name]
  );

  const onInputTouch = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      onTouch && onTouch(name);
    },
    [onTouch, name]
  );

  useEffect(() => {
    if (value && onChange) {
      const strippedValue = value.replace(' ', '');
      if (value !== strippedValue) {
        onChange({
          [name]: strippedValue,
        });
      }
    }
  }, [value, onChange]);

  const account = useSelector(
    (state: any) => state?.users?.myProfile?.accounts?.[0]
  );

  const countryCode = useMemo(() => {
    switch (account?.region) {
      case AccountRegions.AUSTRALIA:
        return 'au';
      case AccountRegions.CANADA:
        return 'ca';
      case AccountRegions.NEW_ZEALAND:
        return 'nz';
      case AccountRegions.SOUTH_AFRICA:
        return 'za';
      case AccountRegions.US:
        return 'us';
      case AccountRegions.UK:
      // fall-through

      default:
        return 'gb';
    }
  }, [account]);

  return (
    <div className="phone-number-field">
      <FieldLabel
        label={label}
        isRequired={isRequired}
        description={description}
      />
      <InputWrapper isDisabled={isDisabled} styleVariant={styleVariant}>
        {iconBefore && (
          <FontAwesomeIcon
            className={'input-wrapper__pre-icon'}
            icon={iconBefore}
          />
        )}
        <PhoneInput
          country={countryCode}
          value={value} // set value to gb default if value is falsy, otherwise dialcode is cleared
          onChange={onInputChange}
          countryCodeEditable={false}
          onBlur={onInputTouch}
          enableLongNumbers={12}
        />
        {iconAfter && (
          <FontAwesomeIcon
            className={'input-wrapper__post-icon'}
            icon={iconAfter}
          />
        )}
      </InputWrapper>
    </div>
  );
};

export default BasicField;
