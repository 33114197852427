import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import './CreateEditSupplierMaterialControl.sass';
import BasicField from '@payaca/components/basicField/BasicField';
import CurrencyField from '@payaca/components/currencyField/CurrencyField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import {
  FieldValidationResult,
  FieldValidator,
} from '@payaca/types/fieldValidationTypes';
import {
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
  getNumericalRangeFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';

import { requestPersistSupplierMaterial } from '@payaca/store/materials/materialsActions';
import { SupplierMaterial } from '@payaca/types/materialTypes';
import { PersistSupplierMaterialRequestData } from '@payaca/store/materials/materialsTypes';
import SupplierSelectionControl from '../supplierSelectionControl/SupplierSelectionControl';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import MaterialSelectionControl from '../materialSelectionControl/MaterialSelectionControl';
import SalesTaxSettingsField from '../salesTaxSettingsField/SalesTaxSettingsField';
import { useSelector } from '@/api/state';
import { useDefaultTaxRate } from '@payaca/store/hooks/appState';

const supplierIsRequiredFieldValidator = getIsRequiredFieldValidator({
  readableName: 'Supplier',
});
const materialIsRequiredFieldValidator = getIsRequiredFieldValidator({
  readableName: 'Material',
});
const priceIsRequiredFieldValidator = getIsRequiredFieldValidator({
  readableName: 'Cost',
});
const priceRangeFieldvalidator = getNumericalRangeFieldValidator(
  0 - 0.01,
  undefined,
  undefined,
  'Cost must be a positive number'
);
const referenceLengthFieldValidator = getLengthFieldValidator({ max: 250 });
const urlLengthFieldValidator = getLengthFieldValidator({ max: 500 });

type Props = {
  supplierMaterial?: SupplierMaterial;
  onPersistSupplierMaterialSuccess?: (taskId: number) => void;
  isMaterialSelectionDisabled?: boolean;
  isSupplierSelectionDisabled?: boolean;
};
const CreateEditSupplierMaterialControl: FunctionComponent<Props> = ({
  supplierMaterial,
  onPersistSupplierMaterialSuccess,
  isMaterialSelectionDisabled = false,
  isSupplierSelectionDisabled = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const defaultTaxRate = useDefaultTaxRate();

  const initialFormState = useMemo(() => {
    return {
      id: supplierMaterial?.id,
      materialId: supplierMaterial?.materialId,
      supplierId: supplierMaterial?.supplierId,
      price: supplierMaterial?.price,
      url: supplierMaterial?.url,
      reference: supplierMaterial?.reference,
      taxPercentage: supplierMaterial ? supplierMaterial.taxPercentage : 20,
      isTaxIncluded: supplierMaterial ? !!supplierMaterial.isTaxIncluded : true,
      taxRateId: supplierMaterial?.taxRateId ?? defaultTaxRate?.id ?? null,
    };
  }, [supplierMaterial, defaultTaxRate]);

  const fieldValidators = useMemo(() => {
    const fv: { [fieldName: string]: FieldValidator[] } = {
      supplierId: [supplierIsRequiredFieldValidator],
      materialId: [materialIsRequiredFieldValidator],
      price: [priceIsRequiredFieldValidator, priceRangeFieldvalidator],
      url: [urlLengthFieldValidator],
      reference: [referenceLengthFieldValidator],
    };

    return fv;
  }, []);

  const isPersistingSupplierMaterial = useSelector((state) => {
    return state.materials.isPersistingSupplierMaterial;
  });

  const persistSupplierMaterial = useCallback(
    (persistSupplierMaterialData: PersistSupplierMaterialRequestData) => {
      dispatch(
        requestPersistSupplierMaterial(
          persistSupplierMaterialData,
          onPersistSupplierMaterialSuccess
        )
      );
    },
    [dispatch, onPersistSupplierMaterialSuccess]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <React.Fragment>
          <div className="form-body">
            <div className="form-section">
              {!isSupplierSelectionDisabled && (
                <ValidatedFieldWrapper
                  validationResult={validationState?.supplierId}
                  isTouched={touchedState.supplierId || false}
                >
                  <SupplierSelectionControl
                    label="Supplier"
                    selectedSupplierId={formState.supplierId}
                    onChange={(selectedSupplierId) => {
                      onFieldTouch('supplierId');
                      onFieldChange({ supplierId: selectedSupplierId });
                    }}
                    enableSupplierCreation={true}
                    excludeSystemManaged={true}
                  />
                </ValidatedFieldWrapper>
              )}
              {!isMaterialSelectionDisabled && (
                <ValidatedFieldWrapper
                  validationResult={validationState?.materialId}
                  isTouched={touchedState.materialId || false}
                >
                  <MaterialSelectionControl
                    label="Material"
                    selectedMaterialId={formState.materialId}
                    onChange={(selectedMaterialId) => {
                      onFieldTouch('materialId');
                      onFieldChange({ materialId: selectedMaterialId });
                    }}
                    enableMaterialCreation={true}
                  />
                </ValidatedFieldWrapper>
              )}

              <ValidatedFieldWrapper
                validationResult={validationState.url}
                isTouched={touchedState.url}
              >
                <BasicField
                  name="url"
                  label="Material URL"
                  value={formState.url}
                  onTouch={onFieldTouch}
                  onChange={onFieldChange}
                  styleVariant={InputStyleVariant.OUTSIZE}
                />
              </ValidatedFieldWrapper>
              <ValidatedFieldWrapper
                validationResult={validationState.reference}
                isTouched={touchedState.reference || false}
              >
                <BasicField
                  name={'reference'}
                  label="Reference"
                  value={formState.reference}
                  onTouch={onFieldTouch}
                  onChange={onFieldChange}
                  styleVariant={InputStyleVariant.OUTSIZE}
                />
              </ValidatedFieldWrapper>
              <div className="flex-container flex-center cost-vat-container">
                <div className="flex-grow">
                  <ValidatedFieldWrapper
                    validationResult={validationState.price}
                    isTouched={touchedState.price || false}
                  >
                    <CurrencyField
                      isRequired={true}
                      name={'price'}
                      label="Cost"
                      description={`ex tax`}
                      value={formState.price}
                      onTouch={onFieldTouch}
                      onChange={onFieldChange}
                      styleVariant={InputStyleVariant.OUTSIZE}
                    />
                  </ValidatedFieldWrapper>
                </div>
                <div className="tax-field-container">
                  <SalesTaxSettingsField
                    excludeReverseChargeOptions={true}
                    styleVariant={InputStyleVariant.OUTSIZE}
                    taxRateId={formState.taxRateId}
                    onChange={(value: { [key: string]: any }) => {
                      onFieldChange({
                        taxRateId: value.taxRateId,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="actions-container">
            <Button
              styleVariant={ButtonStyleVariant.OUTSIZE}
              isDisabled={!isValid}
              isProcessing={isPersistingSupplierMaterial}
              onClick={() =>
                !isPersistingSupplierMaterial &&
                persistSupplierMaterial(
                  formState as PersistSupplierMaterialRequestData
                )
              }
            >
              Save
            </Button>
          </div>
        </React.Fragment>
      );
    },
    [
      isPersistingSupplierMaterial,
      supplierMaterial,
      isSupplierSelectionDisabled,
      isMaterialSelectionDisabled,
    ]
  );

  return (
    <div className="create-edit-supplier-material-control">
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
        fieldValidators={fieldValidators}
      />
    </div>
  );
};

export default CreateEditSupplierMaterialControl;
