import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import './EntityTemplatesControl.sass';
import { PublicEntityTemplate } from '@payaca/types/entity-templates';
import { useDispatch } from 'react-redux';
import {
  createTemplate,
  getListedTemplatesPage,
} from '@payaca/store/templates/templatesActions';
import {
  GetListedTemplatesQueryParams,
  ListedTemplatesPage,
  SortBy,
} from '@payaca/types/listedTemplateTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import Button from '@payaca/components/button/Button';
import MiniLoader from '@payaca/components/miniLoader/MiniLoader';
import PaginationControl from '@payaca/components/paginationControl/PaginationControl';
import SearchField from '../searchField/SearchField';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import './EntityTemplatesControl.sass';
import PersistedEntityTemplateCard from '../entityTemplateCard/PersistedEntityTemplateCard';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import { TemplatesPermissions } from '@payaca/permissions/templates/templates.permissions';
import CreateEditEntityTemplateModal from '../createEditEntityTemplateModal/CreateEditEntityTemplateModal';
import EmptyState from '../emptyState/EmptyState';
import { useHistory } from 'react-router';

type Props = {
  entityType: PublicEntityTemplate<
    'task' | 'scheduledEvent' | 'proposal'
  >['entityType'];
};
const EntityTemplatesControl: FC<Props> = ({
  entityType,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  const [getListedTemplatesRequestData, setGetListedTemplatesRequestData] =
    useState<GetListedTemplatesQueryParams>({
      pageNumber: 1,
      pageSize: 25,
      sortBy: SortBy.NAME,
      sortDirection: SortDirection.ASCENDING,
      entityTypes: [entityType],
    });

  const [listedTemplatesPage, setListedTemplatesPage] = useState<
    ListedTemplatesPage | undefined
  >(undefined);

  const [isGettingPage, setIsGettingPage] = useState(false);

  const handleGetListedTemplatesPage = useCallback(
    (queryParams: GetListedTemplatesQueryParams) => {
      setIsGettingPage(true);

      dispatch(
        getListedTemplatesPage.request({
          queryParams: queryParams,
          callback: (page: ListedTemplatesPage) => {
            setListedTemplatesPage(page);
            setIsGettingPage(false);
          },
          onErrorCallback: () => {
            setIsGettingPage(false);
          },
        })
      );
    },
    []
  );

  useEffect(() => {
    setListedTemplatesPage(undefined);
    setGetListedTemplatesRequestData({
      pageNumber: 1,
      pageSize: 25,
      sortBy: SortBy.NAME,
      sortDirection: SortDirection.ASCENDING,
      entityTypes: [entityType],
    });
  }, [entityType]);

  useEffect(() => {
    handleGetListedTemplatesPage(getListedTemplatesRequestData);
  }, [getListedTemplatesRequestData]);

  return (
    <div className="entity-templates-control">
      <div className="search-field-container">
        {entityType === 'task' && (
          <SearchField
            styleVariant={InputStyleVariant.UNSTYLED}
            placeholder="Search Templates"
            onSearchTermChange={(searchTerm?: string | undefined) =>
              setGetListedTemplatesRequestData((requestData) => ({
                ...requestData,
                pageNumber: 1,
                searchTerm: searchTerm,
              }))
            }
          />
        )}
        {entityType === 'scheduledEvent' && (
          <SearchField
            styleVariant={InputStyleVariant.UNSTYLED}
            placeholder="Search Templates"
            onSearchTermChange={(searchTerm?: string | undefined) =>
              setGetListedTemplatesRequestData((requestData) => ({
                ...requestData,
                pageNumber: 1,
                searchTerm: searchTerm,
              }))
            }
          />
        )}
        {entityType === 'proposal' && (
          <SearchField
            styleVariant={InputStyleVariant.UNSTYLED}
            placeholder="Search Templates"
            onSearchTermChange={(searchTerm?: string | undefined) =>
              setGetListedTemplatesRequestData((requestData) => ({
                ...requestData,
                pageNumber: 1,
                searchTerm: searchTerm,
              }))
            }
          />
        )}
        {isGettingPage && <MiniLoader />}
        <PermissionGuard
          renderIfHasPermissions={[TemplatesPermissions.PERSIST_TEMPLATE]}
        >
          <Button
            onClick={() => {
              if (entityType === 'proposal') {
                history.push(`/templates/edit?entityType=${entityType}`);
              } else {
                setShowCreateTemplateModal(true);
              }
            }}
          >
            Create Template
          </Button>
        </PermissionGuard>
      </div>
      {listedTemplatesPage && (
        <div className="listed-templates-container">
          {listedTemplatesPage.items.map((template, i) => {
            return (
              <PersistedEntityTemplateCard
                showEntityTypeIcon={true}
                key={i}
                template={template}
                onUpdateEntityTemplateSuccess={() => null}
                allowModifyTemplateNameDescription={true}
                onDeleteEntityTemplateSuccess={() => {
                  handleGetListedTemplatesPage(getListedTemplatesRequestData);
                }}
              />
            );
          })}
        </div>
      )}
      {!isGettingPage &&
        !listedTemplatesPage?.items?.length &&
        !getListedTemplatesRequestData?.searchTerm?.length && (
          <EmptyState configName={'templates'} arrowIndication={false} />
        )}
      {listedTemplatesPage && (
        <PaginationControl
          pageNumber={listedTemplatesPage.pageNumber}
          pageSize={listedTemplatesPage.pageSize}
          totalItemCount={listedTemplatesPage.totalItemCount}
          onSelectPage={(pageNumber) => {
            setGetListedTemplatesRequestData((requestData) => ({
              ...requestData,
              pageNumber,
            }));
          }}
          allowPageSizeSelection={false}
        />
      )}

      <CreateEditEntityTemplateModal
        isOpen={showCreateTemplateModal}
        onClose={() => setShowCreateTemplateModal(false)}
        action="Create"
        template={{
          entityType: entityType,
          data: {},
        }}
        onRequestSave={(t) => {
          dispatch(
            createTemplate.request({
              template: t,
              callback: () =>
                handleGetListedTemplatesPage(getListedTemplatesRequestData),
            })
          );
          setShowCreateTemplateModal(false);
        }}
        allowModifyTemplateNameDescription={true}
      />
    </div>
  );
};
export default EntityTemplatesControl;
