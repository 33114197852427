import React, { FC, useEffect, useMemo } from 'react';

import { DealProgressionActionConfig } from '@payaca/types/automationTypes';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';

import './SmsNotificationAutomationActionConfigFieldset.sass';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { GenericUserHelpTip } from '../genericUserHelpTip/GenericUserHelpTip';
import { Danger } from 'react-iconly';
import { usePipelines, useSelector } from '@payaca/store/hooks/appState';
import { Pipeline, PipelineStage } from '@payaca/types/pipelineTypes';
import { useDispatch } from 'react-redux';
import { requestGetAccountPipelines } from '@payaca/store/pipelines/pipelineActions';

type Props = {
  config: DealProgressionActionConfig;
  onChange: (changeValue: { [key: string]: any }) => void;
  validationState?: Record<string, FieldValidationResult>;
  touchedState?: Record<string, boolean>;
  onTouch?: (fieldName: string) => void;
  showFieldValidationMessagesUnconditionally?: boolean;
};

const DealProgressionAutomationActionConfigFieldset: FC<Props> = ({
  config,
  onChange,
  validationState,
  touchedState,
  onTouch,
  showFieldValidationMessagesUnconditionally,
}) => {
  const dispatch = useDispatch();
  // TODO: Apply some additional filtering here - not all variables should be available on default automations
  const pipelines = usePipelines();
  const pipelineStageOptions = useMemo(() => {
    if (!pipelines) return [];
    return pipelines
      .reduce((acc: any[], { stages }: Pipeline) => {
        stages.forEach((stage: PipelineStage) => {
          if (acc.includes(stage.title)) return;
          acc.push(stage.title);
        });
        return acc;
      }, [])
      .map((label: string) => ({ label, value: label }));
  }, [pipelines]);

  useEffect(() => {
    dispatch(requestGetAccountPipelines());
  }, []);

  return (
    <fieldset className="deal-progression-automation-action-config-fieldset automation-action-config-fieldset">
      <ValidatedFieldWrapper
        validationResult={validationState?.targetStage}
        isTouched={
          !!touchedState?.targetStage ||
          showFieldValidationMessagesUnconditionally
        }
      >
        <DropdownField
          label={'Which stage should your Project be moved to'}
          name={`targetStage`}
          value={config.targetStage}
          options={pipelineStageOptions}
          onChange={onChange}
          onTouch={onTouch}
        />
      </ValidatedFieldWrapper>
      <GenericUserHelpTip icon={<Danger />}>
        {`Any default Payaca pipeline stages cannot be skipped via an automation - Bear this in mind when making your choice!`}
      </GenericUserHelpTip>
    </fieldset>
  );
};

export default DealProgressionAutomationActionConfigFieldset;
