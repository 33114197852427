import React, { FC, useMemo } from 'react';

import { ListedSupplier } from '@payaca/types/listedSupplierTypes';

import { getAddressAsString } from '@payaca/helpers/locationHelper';
import QuickActionsTableCell from '@payaca/components/quickActionsTableCell/QuickActionsTableCell';
import RowSelectionTableCell from '@payaca/components/rowSelectionTableCell/RowSelectionTableCell';

type Props = {
  listedSupplier: ListedSupplier;
  onClickRow?: (DealId: number) => void;
  quickActionDefinitions?: {
    actionName: string;
    actionBehaviour: (id: number) => void;
    isActionProcessing?: boolean;
  }[];
  canSelectSuppliers?: boolean;
  selectedSupplierIds: number[];
  onSelectSuppliers?: (supplierIds: number[]) => void;
  onUnselectSuppliers?: (supplierIds: number[]) => void;
};
const ListedSuppliersTableRow: FC<Props> = ({
  listedSupplier,
  onClickRow,
  quickActionDefinitions,
  onSelectSuppliers,
  onUnselectSuppliers,
  canSelectSuppliers,
  selectedSupplierIds,
}: Props): JSX.Element => {
  const renderAddressesTableCell = useMemo(() => {
    const addresses = listedSupplier.addresses;
    return (
      <td className="tr-end">
        {addresses?.map((address, i) => {
          return (
            <div key={`${listedSupplier.id}-address-${i}`}>
              {getAddressAsString(address.address)}
            </div>
          );
        })}
      </td>
    );
  }, [listedSupplier]);

  const supplierIsSelected = useMemo(
    () => selectedSupplierIds.includes(listedSupplier.id),
    [listedSupplier, selectedSupplierIds]
  );

  return (
    <tbody
      className={`listed-supplier-table-row-container${
        supplierIsSelected ? ' selected' : ''
      } ${onClickRow ? 'clickable' : ''}`}
      onClick={() => onClickRow && onClickRow(listedSupplier.id)}
    >
      <tr>
        {canSelectSuppliers && (
          <RowSelectionTableCell
            isSelected={supplierIsSelected}
            onChange={() => {
              supplierIsSelected
                ? onUnselectSuppliers &&
                  onUnselectSuppliers([listedSupplier.id])
                : onSelectSuppliers && onSelectSuppliers([listedSupplier.id]);
            }}
          />
        )}
        <td className={`align-left tr-start`}>
          <strong>{listedSupplier.name}</strong>
        </td>
        <td className={`align-left`}>{listedSupplier.emailAddress}</td>
        <td className={' align-left'}>{listedSupplier.phoneNumber}</td>
        <td className={' align-left'}>{listedSupplier.websiteUrl}</td>
        {renderAddressesTableCell}
        {quickActionDefinitions && (
          <QuickActionsTableCell
            recordId={listedSupplier.id}
            quickActions={
              listedSupplier.isSystemManaged ? [] : quickActionDefinitions
            }
          />
        )}
      </tr>
    </tbody>
  );
};

export default ListedSuppliersTableRow;
