export enum AccountsRoles {
  SUBSCRIPTION_EDITOR = 'roles.accountsSubscriptionEditor',
  PERSONAL_EDITOR = 'roles.accountsPersonalEditor',
  EDITOR = 'roles.accountsEditor',
  VIEWER = 'roles.accountsViewer',
  USERS_VIEWER = 'roles.accountsUsersViewer',
  USERS_COST_PER_HOUR_EDITOR = 'roles.accountsUsersCostPerHourEditor',
  USERS_COST_PER_HOUR_VIEWER = 'roles.accountsUsersCostPerHourViewer',
  CUSTOM_EMAIL_DOMAIN_EDITOR = 'roles.accountsCustomEmailDomainEditor',
  CUSTOM_EMAIL_DOMAIN_SENDER = 'roles.accountsCustomEmailDomainSender',
  CUSTOM_FIELD_GROUP_MANAGER = 'roles.accountsCustomFieldGroupManager',
  CUSTOM_FIELD_VALUE_MANAGER = 'roles.accountsCustomFieldValueManager',
  TAG_MANAGER = 'roles.accountsTagManager',
}
