import moment from 'moment-timezone';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import BasicField from '../basicField/BasicField';

import DropdownField from '../dropdownField/DropdownField';
import InputWrapper, {
  InputStyleVariant,
  InputWidth,
} from '../inputWrapper/InputWrapper';

import {
  DurationUnit,
  durationUnitHierarchy,
} from '@payaca/utilities/timeUtilities';
import './DurationInputControl.sass';
import FieldLabel from '../fieldLabel/FieldLabel';

type Props = {
  label?: string;
  value?: string;
  name: string;
  onTouch?: (fieldName: string) => void;
  onChange: (change: { [key: string]: string }) => void;
  inputStyleVariant?: InputStyleVariant;
  inputWidth?: InputWidth;
  units?: DurationUnit[];
  isRequired?: boolean;
};

const DurationInputControl: FC<Props> = ({
  label,
  value,
  name,
  onTouch = () => {},
  onChange,
  inputStyleVariant,
  inputWidth,
  isRequired = false,
  units = [DurationUnit.DAYS, DurationUnit.HOURS, DurationUnit.MINUTES],
}: Props): JSX.Element => {
  const [durationBreakdown, setDurationBreakdown] = useState<{
    [key in DurationUnit]?: number;
  }>({});

  useEffect(() => {
    const duration = moment.duration(value);
    const db: {
      [key in DurationUnit]?: number;
    } = {};

    durationUnitHierarchy.forEach((unit, i) => {
      if (units.includes(unit)) {
        const value = duration.as(unit);

        let roundedValue = 0;

        if (units.some((u) => durationUnitHierarchy.indexOf(u) > i)) {
          roundedValue = Math.floor(value);
        } else {
          const precisionMultiplier = 10 ** 2;
          roundedValue =
            Math.round(value * precisionMultiplier) / precisionMultiplier;
        }

        db[unit] = roundedValue;
        if (roundedValue) {
          duration.subtract(moment.duration(roundedValue, unit));
        }
      }
    });
    setDurationBreakdown(db);
  }, [value]);

  useEffect(() => {
    if (Object.keys(durationBreakdown).length) {
      const durationString = moment.duration(durationBreakdown).toISOString();
      onChange({
        [name]: durationString,
      });
    }
  }, [durationBreakdown]);

  return (
    <div className="duration-input-control">
      <FieldLabel label={label} isRequired={isRequired} />
      <fieldset>
        {durationUnitHierarchy
          .filter((x) => units.includes(x))
          .map((unit, i) => {
            return (
              <div key={i}>
                <BasicField
                  inputWidth={inputWidth}
                  styleVariant={inputStyleVariant}
                  name="durationUnitCount"
                  value={durationBreakdown[unit]}
                  onChange={(value: { [key: string]: string }) => {
                    setDurationBreakdown((db) => {
                      return {
                        ...db,
                        [unit]: value.durationUnitCount?.length
                          ? +value.durationUnitCount
                          : undefined,
                      };
                    });
                  }}
                  onTouch={() => onTouch(name)}
                  placeholder="0"
                  type="number"
                  iconAfter={
                    <span>{unit === DurationUnit.MINUTES ? 'mins' : unit}</span>
                  }
                />
              </div>
            );
          })}
      </fieldset>
    </div>
  );
};
export default DurationInputControl;
