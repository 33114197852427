import { Customer } from './customerTypes';
import { ListViewPage, SortDirection } from './listViewTypes';
import { Address } from './locationTypes';
import { DefaultPipelineStages } from './pipelineTypes';
import { Tag } from './tagTypes';

export interface ListedDeal {
  dealId: number;
  reference: string;
  customerId?: Customer['id'];
  customerName?: string;
  contactName?: string;
  contactEmail?: string;
  contactPhoneNumber?: string;
  customerBillingAddress?: Address;
  dealValue?: number;
  isAnyBounced: boolean;
  isAnyOverdue: boolean;
  isAllViewed: boolean;
  tags: Tag[];
  lastUpdatedAt: Date;
  siteAddresses: Address[];
  pipelineId?: number;
  pipelineStage: DefaultPipelineStages | string;
  description?: string;
  assignedToUserId?: number;
}

export interface ListedDealsListViewPage extends ListViewPage<ListedDeal> {
  totalUnfilteredItemCount: number;
  totalDealValue: number;
}

export type PipelineListedDeals = Record<string, ListedDealsListViewPage>;

export enum SortBy {
  DEAL_REFERENCE = 'reference',
  CUSTOMER_NAME = 'customerName',
  LAST_UPDATED = 'lastUpdated',
}

export enum ColumnType {
  DEAL_REFERENCE = 'Project Reference',
  CUSTOMER_NAME = 'Customer Name',
  TYPE = 'Type',
  REFERENCE = 'Ref',
  VALUE = 'Value',
  DUE = 'Due',
  STATUS = 'Status',
  LAST_UPDATED = 'Last Updated',
}
export type DealSearchTarget =
  | 'deal'
  | 'dealAddresses'
  | 'invoices'
  | 'changeProposals'
  | 'proposals'
  | 'customer';
export interface GetListedDealsRequestData {
  pipelineId?: number;
  pageSize: number;
  pageNumber: number;
  searchTerm?: string;
  searchTarget?: DealSearchTarget;
  sortBy: SortBy;
  sortDirection: SortDirection;
  types?: any[];
  readableStatuses?: any[];
  userAssignments?: (number | 'unassigned')[];
  customerIds?: number[];
  tagIds?: number[];
  pipelineStages?: string[];
  archived?: boolean;
  excludeDealsWithUpcomingEvents?: boolean;
}

export enum ListedDealViews {
  TABLE = 'table',
  PIPELINE = 'pipeline',
}
