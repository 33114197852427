import React, { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { actions as jobActions } from '@/api/jobs';
import EditJobPage from '../editJobPage/EditJobPage';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import LoaderOverlay from '@payaca/components/loaderOverlay/LoaderOverlay';
import { useLocation } from 'react-router-dom';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';

type Props = {
  match: any;
  history: any;
  isNew?: boolean;
};
const NewEditInvoicePage: FC<Props> = ({ match, isNew = false }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const invoiceId = useMemo(
    () => match?.params?.invoiceId?.split('?')[0],
    [match]
  );
  const dealId = useMemo(() => {
    return getUrlSearchParam(location.search, 'dealId');
  }, [location]);
  const [isInitialising, setIsInitialising] = useState(!invoiceId);

  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);

  useEffect(() => {
    if (!invoiceId) {
      setIsInitialising(true);
      dispatch(
        jobActions.createJob(
          false,
          (err: any, job: any) => {
            setIsInitialising(false);
            if (job) {
              // history replace so navigating back will go to dashboard not /new
              history.replace({
                pathname: `/invoices/${job.id}/create/new`,
                search: dealId ? `?dealId=${dealId}` : '',
              });
            } else {
              showDynamicFeedbackMessage({
                title: 'Something went wrong',
                body: 'Please click the chat button and let us know about it.',
                feedbackLevel: FeedbackLevel.ERROR,
                isCancellable: true,
                lifespanMs: DynamicFeedbackLifespanMs.SHORT,
              });
            }
          },
          dealId as any
        )
      );
    }
  }, [invoiceId, history]);

  if (isInitialising) {
    return <LoaderOverlay />;
  }

  return (
    <EditJobPage
      jobId={+invoiceId}
      viewType={match?.params?.view}
      sendJobToCustomer={(
        id: number,
        payload: any,
        cb: (err: any, response: any) => void
      ) => dispatch(jobActions.sendInvoiceToCustomer(id, payload, cb))}
    />
  );
};

export default NewEditInvoicePage;
