import { User } from '@payaca/types/userTypes';
import { Note } from '@payaca/types/notesTypes';

export const getNoteTitle = (note?: Note, createdByUser?: User) => {
  if (note) {
    if (createdByUser) {
      return note?.dealId ? 'Project Note ' : 'Event Note ';
    } else {
      return note?.isCreatedBySystem ? 'System Note' : 'Note';
    }
  }
}