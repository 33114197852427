import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { graphql } from '@/gql';
import {
  UpdateUserMutation as _UpdateUserMutation,
  UpdateUserInput,
} from '@/gql/graphql';
import { gqlClient } from '@/api/graphql-client';

const UpdateUserMutation = graphql(`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`);

export const useUpdateUser = () => {
  return useMutation({
    mutationFn: (update: UpdateUserInput) => {
      return gqlClient.request(UpdateUserMutation, {
        input: update,
      });
    },
  });
};
