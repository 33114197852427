import { Reducer } from 'redux';

import { AuthActionTypes, AuthState } from './authTypes';

export const initialState: AuthState = {
  isLoggingIn: false,
  isLoggedInSuccessfully: undefined,

  isSigningUp: false,
  isSignedUpSuccessfully: undefined,

  isResettingPassword: false,
  hasSuccessfullyResetPassword: null,

  signUpWithEmailError: null,
  isSigningUpWithEmail: false,
  isSignedUpWithEmailSuccessfully: null,

  isResendingEmailVerification: false,

  isSigningUpAccontUpdate: false,

  isRequestingValidateVerificationToken: false,

  isVerifyingUserToken: false,
};

const authReducer: Reducer<AuthState> = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.REQUEST_LOGIN:
    case AuthActionTypes.REQUEST_LOGIN_WITH_APPLE:
    case AuthActionTypes.REQUEST_LOGIN_WITH_GOOGLE:
    case AuthActionTypes.REQUEST_LOGIN_WITH_XERO:
    case AuthActionTypes.REQUEST_LOGIN_WITH_TOKEN:
      return {
        ...state,
        isLoggingIn: true,
        isLoggedInSuccessfully: undefined,
      };
    case AuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedInSuccessfully: false,
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedInSuccessfully: true,
      };

    case AuthActionTypes.REQUEST_SIGN_UP_WITH_APPLE_V1:
    case AuthActionTypes.REQUEST_SIGN_UP_WITH_GOOGLE_V1:
    case AuthActionTypes.REQUEST_SIGN_UP_WITH_XERO_V1:
    case AuthActionTypes.REQUEST_SIGN_UP_WITH_GOOGLE:
    case AuthActionTypes.REQUEST_SIGN_UP_WITH_XERO:
      return {
        ...state,
        isSigningUp: true,
        isSignedUpSuccessfully: undefined,
      };
    case AuthActionTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        isSigningUp: false,
        isSignedUpSuccessfully: false,
      };
    case AuthActionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        isSigningUp: false,
        isSignedUpSuccessfully: true,
      };
    case AuthActionTypes.CLEAR_AUTH_LOGIN_SIGN_UP:
      return initialState;

    case AuthActionTypes.REQUEST_RESET_PASSWORD: {
      return {
        ...state,
        isResettingPassword: true,
        hasSuccessfullyResetPassword: null,
      };
    }
    case AuthActionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isResettingPassword: false,
        hasSuccessfullyResetPassword: true,
      };
    }
    case AuthActionTypes.RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        isResettingPassword: false,
        hasSuccessfullyResetPassword: false,
      };
    }
    case AuthActionTypes.CLEAR_RESET_PASSWORD: {
      return {
        ...state,
        isResettingPassword: false,
        hasSuccessfullyResetPassword: null,
      };
    }

    case AuthActionTypes.REQUEST_RESEND_EMAIL_VERIFICATION: {
      return {
        ...state,
        isResendingEmailVerification: true,
      };
    }
    case AuthActionTypes.RESEND_EMAIL_VERIFICATION_SUCCESS: {
      return {
        ...state,
        isResendingEmailVerification: false,
      };
    }
    case AuthActionTypes.RESEND_EMAIL_VERIFICATION_FAILURE: {
      return {
        ...state,
        isResendingEmailVerification: false,
      };
    }

    case AuthActionTypes.REQUEST_SIGN_UP_WITH_EMAIL: {
      return {
        ...state,
        isSigningUpWithEmail: true,
        signUpWithEmailError: null,
        isSignedUpWithEmailSuccessfully: null,
      };
    }
    case AuthActionTypes.SIGN_UP_WITH_EMAIL_SUCCESS: {
      return {
        ...state,
        isSigningUpWithEmail: false,
        signUpWithEmailError: null,
        isSignedUpWithEmailSuccessfully: true,
      };
    }
    case AuthActionTypes.SIGN_UP_WITH_EMAIL_FAILURE: {
      return {
        ...state,
        isSigningUpWithEmail: false,
        signUpWithEmailError: action.payload.errorMessage,
        isSignedUpWithEmailSuccessfully: false,
      };
    }
    case AuthActionTypes.CLEAR_SIGN_UP_WITH_EMAIL: {
      return {
        ...state,
        isSigningUpWithEmail: false,
        signUpWithEmailError: null,
        isSignedUpWithEmailSuccessfully: null,
      };
    }

    case AuthActionTypes.REQUEST_SIGN_UP_WITH_EMAIL_V2: {
      return {
        ...state,
        isSigningUpWithEmail: true,
        signUpWithEmailError: null,
        isSignedUpWithEmailSuccessfully: null,
      };
    }

    case AuthActionTypes.REQUEST_SIGN_UP_ACCOUNT_UPDATE_V2: {
      return {
        ...state,
        isSigningUpAccontUpdate: true,
      };
    }
    case AuthActionTypes.SIGN_UP_ACCOUNT_UPDATE_V2_SUCCESS: {
      return {
        ...state,
        isSigningUpAccontUpdate: false,
      };
    }
    case AuthActionTypes.SIGN_UP_ACCOUNT_UPDATE_V2_FAILURE: {
      return {
        ...state,
        isSigningUpAccontUpdate: false,
      };
    }

    case AuthActionTypes.REQUEST_VALIDATE_VERIFICATION_TOKEN:
      return {
        ...state,
        isRequestingValidateVerificationToken: true,
      };
    case AuthActionTypes.VALIDATE_VERIFICATION_TOKEN_SUCCESS:
      return {
        ...state,
        isRequestingValidateVerificationToken: false,
      };
    case AuthActionTypes.VALIDATE_VERIFICATION_TOKEN_FAILURE:
      return {
        ...state,
        isRequestingValidateVerificationToken: false,
      };

    case AuthActionTypes.REQUEST_VERIFY_USER_TOKEN:
      return {
        ...state,
        isVerifyingUserToken: true,
      };
    case AuthActionTypes.VERIFY_USER_TOKEN_SUCCESS:
      return {
        ...state,
        isVerifyingUserToken: false,
      };
    case AuthActionTypes.VERIFY_USER_TOKEN_FAILURE:
      return {
        ...state,
        isVerifyingUserToken: false,
      };

    default:
      return state;
  }
};

export default authReducer;
