import {
  useQuery,
  useMutation,
  UseMutationOptions,
} from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import {
  CreateAddressInput,
  CreateAddressMutation as _CreateAddressMutation,
} from '@/gql/graphql';

const CreateAddressMutation = graphql(`
  mutation CreateAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      id
    }
  }
`);

const useCreateAddress = (
  options?: UseMutationOptions<
    _CreateAddressMutation,
    unknown,
    CreateAddressInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(CreateAddressMutation, {
        input,
      });
    },
    ...options,
  });
};

export default useCreateAddress;
