import Showdown from 'showdown';
import htmlReactParser from 'html-react-parser';

export const isValidHtml = (testString: string) => {
  const regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  return regexForHTML.test(testString);
};

export const convertMarkdownToHtml = (markdown: string) => {
  const converter = new Showdown.Converter();
  const htmlText = converter.makeHtml(markdown);
  return htmlText;
};

export const parseHtmlToReact = (html: string) => {
  const options = {
    replace: (root: any) => {
      const { attribs } = root;
      //Remove your contenteditable attribute from your htmlstring, otherwise, an error will be reported when react render - https://github.com/remarkablemark/html-react-parser/issues/140
      if (attribs && attribs.contenteditable) {
        delete attribs.contenteditable;
      }
    },
  };
  return htmlReactParser(html, options);
};
