// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import { GetTagsInput, PaginationInput, UsersInput } from '@/gql/graphql';

const meKeys = {
  all: ['me'] as const,
  account: () => [...meKeys.all, 'account'] as const,
  businessHours: () => [...meKeys.account(), 'businessHours'] as const,
  customFieldGroups: () => [...meKeys.account(), 'customFieldGroups'] as const,
  accountFeatures: () => [...meKeys.account(), 'features'] as const,
  accountRegionPreferences: () =>
    [...meKeys.account(), 'regionPreferences'] as const,
  accountAvailableTags: (
    input?: GetTagsInput,
    pagination?: PaginationInput
  ) => {
    if (!pagination && !input) {
      return [...meKeys.account(), 'availableTags'] as const;
    }
    return [...meKeys.account(), 'availableTags', pagination, input] as const;
  },
  accountBranding: () => [...meKeys.account(), 'branding'] as const,
  accountSubdomain: () => [...meKeys.account(), 'subdomain'] as const,
  accountUsers: (input?: UsersInput) => {
    if (!input) {
      return [...meKeys.account(), 'accountUsers'] as const;
    }

    return [...meKeys.account(), 'accountUsers', input] as const;
  },
  accountTaxRates: () => [...meKeys.account(), 'taxRates'] as const,
  accountCisDeductionRate: () =>
    [...meKeys.account(), 'cisDeductionRate'] as const,
};

export default meKeys;
