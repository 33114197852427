// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import {
  GetCustomersInput,
  GetProjectsForCustomerInput,
  GetScheduledEventsForCustomerInput,
  PaginationInput,
} from '@/gql/graphql';

const customerKeys = {
  all: ['customer'] as const,
  existingCustomerContactEmails: [
    'customer',
    'existingCustomerContactEmails',
  ] as const,
  customer: (
    customerId: string,
    input?: GetProjectsForCustomerInput,
    pagination?: PaginationInput
  ) => {
    if (!input) {
      return [...customerKeys.all, customerId] as const;
    }

    if (!pagination) {
      return [...customerKeys.all, customerId, input] as const;
    }

    return [...customerKeys.all, customerId, input, pagination] as const;
  },
  customerProjects: (
    customerId: string,
    input?: GetProjectsForCustomerInput,
    pagination?: PaginationInput
  ) => {
    if (!input) {
      return [...customerKeys.customer(customerId), 'projects'] as const;
    }

    if (!pagination) {
      return [...customerKeys.customer(customerId), 'projects', input] as const;
    }

    return [
      ...customerKeys.customer(customerId),
      'projects',
      input,
      pagination,
    ] as const;
  },
  customers: (input?: GetCustomersInput, pagination?: PaginationInput) => {
    if (!input) {
      return [...customerKeys.all, 'customers'] as const;
    }

    if (!pagination) {
      return [...customerKeys.all, 'customers', input] as const;
    }

    return [...customerKeys.all, 'customers', input, pagination] as const;
  },
  customerEvents: (
    customerId: string,
    input?: GetScheduledEventsForCustomerInput
  ) => {
    if (!input) {
      return [...customerKeys.customer(customerId), 'events'] as const;
    }

    return [...customerKeys.customer(customerId), 'events', input] as const;
  },
};

export default customerKeys;
