import React, { FC } from 'react';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';

const SubscriptionProductSkeletonCard: FC = () => {
  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body className="flex flex-col gap-y-4">
        <SkeletonLoader.Title className="w-1/4" />
        <SkeletonLoader.Text />
        <SkeletonLoader.Title className="w-1/6" />
        <SkeletonLoader.Text className="w-1/3" />
        <SkeletonLoader className="h-10 w-full rounded-lg" />
        <SkeletonLoader.Text className="w-3/4" />
        <SkeletonLoader.Text className="w-3/4" />
        <SkeletonLoader.Text className="w-3/4" />
        <SkeletonLoader.Text className="w-3/4" />
      </Card.Body>
    </Card>
  );
};
const SubscriptionProductsSkeleton: FC = () => {
  return (
    <>
      <SubscriptionProductSkeletonCard />
      <SubscriptionProductSkeletonCard />
      <SubscriptionProductSkeletonCard />
    </>
  );
};

export default SubscriptionProductsSkeleton;
