import { action } from 'typesafe-actions';
import { ActionType } from './userRolesTypes';

export const requestGetUserRoles = () => {
  return action(ActionType.REQUEST_GET_USER_ROLES);
};

export const getUserRolesSuccess = (roles: string[]) => {
  return action(ActionType.GET_USER_ROLES_SUCCESS, {
    roles,
  });
};

export const getUserRolesFailure = (error: Error) => {
  return action(ActionType.GET_USER_ROLES_FAILURE, null, null, error);
};

export const clearUserRoles = () => {
  return action(ActionType.CLEAR_USER_ROLES);
};
