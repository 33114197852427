import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import tinycolor from 'tinycolor2';

export enum AvatarGroupStyleVariant {
  STACK = 'stack',
  GRID = 'grid',
}

type Props = {
  styleVariant?: AvatarGroupStyleVariant;
};

const AvatarGroup: FunctionComponent<PropsWithChildren<Props>> = ({
  styleVariant = AvatarGroupStyleVariant.STACK,
  children,
}) => {
  const styleClasses = useMemo(() => {
    switch (styleVariant) {
      case AvatarGroupStyleVariant.GRID:
        return 'flex gap-4 flex-wrap';
      default:
        return 'flex -space-x-2 flex-wrap gap-y-2';
    }
  }, [styleVariant]);

  return <div className={`${styleClasses}`}>{children}</div>;
};

export default AvatarGroup;
