import { Reducer } from 'redux';

import { mergeFetchedEntities } from '../utils';

import { ActionType, State } from './scheduledEventsTypes';

export const initialState: State = {
  scheduledEvents: {},

  isGettingScheduledEvents: false,

  isCreatingScheduledEvent: false,
  hasCreatedScheduledEventSuccessfully: null,
  createScheduledEventErrorMessage: null,

  isUpdatingScheduledEvent: false,
  hasUpdatedScheduledEventSuccessfully: null,
  updateScheduledEventErrorMessage: null,

  isArchivingScheduledEvent: false,
  hasArchivedScheduledEventSuccessfully: null,
  archiveScheduledEventErrorMessage: null,
  isGettingScheduledEventsForDeal: false,
  isGettingScheduledEventsForTask: false,
};

const scheduledEventsReducer: Reducer<State> = (
  state = initialState,
  action
): any => {
  switch (action.type) {
    // GET LISTED SCHEDULED EVENTS
    case ActionType.REQUEST_GET_LISTED_SCHEDULED_EVENTS:
      return {
        ...state,
        isGettingScheduledEvents: true,
      };
    case ActionType.GET_LISTED_SCHEDULED_EVENTS_SUCCESS: {
      const scheduledEventObj: { [id: number]: any } = {};
      action.payload.scheduledEvents.forEach((scheduledEvent: any) => {
        scheduledEventObj[scheduledEvent.id] = {
          entity: scheduledEvent,
          fetchSucceededAt: new Date(),
          isFetching: false,
        };
      });
      return {
        ...state,
        isGettingScheduledEvents: false,
        scheduledEvents: {
          // This has been updated so that we can run two parallel event retrievals and keep the results of both in the store.
          // This /may/ affect mobile, but it's unlikely and also not especially relevant as we are not planning on releasing it from this branch
          ...state.scheduledEvents,
          ...scheduledEventObj,
        },
      };
    }
    case ActionType.GET_LISTED_SCHEDULED_EVENTS_FAILURE:
      return {
        ...state,
        isGettingScheduledEvents: false,
      };

    // CREATE SCHEDULED EVENT
    case ActionType.REQUEST_CREATE_SCHEDULED_EVENT:
      return {
        ...state,
        isCreatingScheduledEvent: true,
        hasCreatedScheduledEventSuccessfully: null,
        updateScheduledEventErrorMessage: null,
        archiveScheduledEventErrorMessage: null,
        createScheduledEventErrorMessage: null,
      };
    case ActionType.CREATE_SCHEDULED_EVENT_SUCCESS: {
      const newScheduledEvent = action.payload.scheduledEvent;
      const existingScheduledEventEntity =
        state.scheduledEvents && state.scheduledEvents[newScheduledEvent.id];
      return {
        ...state,
        isCreatingScheduledEvent: false,
        hasCreatedScheduledEventSuccessfully: true,
        createScheduledEventErrorMessage: null,
        scheduledEvents: {
          ...state.scheduledEvents,
          [newScheduledEvent.id]: {
            ...existingScheduledEventEntity,
            entity: newScheduledEvent,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.CREATE_SCHEDULED_EVENT_FAILURE:
      return {
        ...state,
        isCreatingScheduledEvent: false,
        hasCreatedScheduledEventSuccessfully: false,
        createScheduledEventErrorMessage: action.payload.errorMessage,
      };

    // GET SCHEDULED EVENT
    case ActionType.REQUEST_GET_SCHEDULED_EVENT: {
      const existingEntity =
        state.scheduledEvents &&
        state.scheduledEvents[action.payload.scheduledEventId];

      return {
        ...state,
        scheduledEvents: {
          ...state.scheduledEvents,
          [action.payload.scheduledEventId]: {
            ...existingEntity,
            fetchAttemptedAttemptedAt: new Date(),
            fetchSucceededAt: undefined,
            fetchFailedAt: undefined,
            isFetching: true,
            fetchError: undefined,
          },
        },
      };
    }

    case ActionType.GET_SCHEDULED_EVENT_SUCCESS: {
      const fetchedEntity =
        state.scheduledEvents &&
        state.scheduledEvents[action.payload.scheduledEventId];

      return {
        ...state,
        scheduledEvents: {
          ...state.scheduledEvents,
          [action.payload.scheduledEventId]: {
            ...fetchedEntity,
            entity: action.payload.scheduledEvent,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }

    case ActionType.GET_SCHEDULED_EVENT_FAILURE: {
      const fetchedEntity =
        state.scheduledEvents &&
        state.scheduledEvents[action.payload.scheduledEventId];

      return {
        ...state,
        scheduledEvents: {
          ...state.scheduledEvents,
          [action.payload.scheduledEventId]: {
            ...fetchedEntity,
            fetchFailedAtAt: new Date(),
            isFetching: false,
            fetchError: action.error,
          },
        },
      };
    }

    // UDPATE SCHEDULED EVENT
    case ActionType.REQUEST_UPDATE_SCHEDULED_EVENT:
      return {
        ...state,
        isUpdatingScheduledEvent: true,
        hasUpdatedScheduledEventSuccessfully: null,
        updateScheduledEventErrorMessage: null,
        archiveScheduledEventErrorMessage: null,
        createScheduledEventErrorMessage: null,
      };
    case ActionType.UPDATE_SCHEDULED_EVENT_SUCCESS: {
      const updatedScheduledEvent = action.payload.scheduledEvent;
      const existingScheduledEventEntity =
        state.scheduledEvents &&
        state.scheduledEvents[updatedScheduledEvent.id];
      return {
        ...state,
        isUpdatingScheduledEvent: false,
        hasUpdatedScheduledEventSuccessfully: true,
        updateScheduledEventErrorMessage: null,
        scheduledEvents: {
          ...state.scheduledEvents,
          [updatedScheduledEvent.id]: {
            ...existingScheduledEventEntity,
            entity: updatedScheduledEvent,
            fetchSucceededAt: new Date(),
            isFetching: false,
          },
        },
      };
    }
    case ActionType.UPDATE_SCHEDULED_EVENT_FAILURE:
      return {
        ...state,
        isUpdatingScheduledEvent: false,
        hasUpdatedScheduledEventSuccessfully: false,
        updateScheduledEventErrorMessage: action.payload.errorMessage,
      };

    // ARHCIVE SCHEDULED EVENT
    case ActionType.REQUEST_ARCHIVE_SCHEDULED_EVENT:
      return {
        ...state,
        isArchivingScheduledEvent: true,
        hasArchivedScheduledEventSuccessfully: null,
        updateScheduledEventErrorMessage: null,
        archiveScheduledEventErrorMessage: null,
        createScheduledEventErrorMessage: null,
      };
    case ActionType.ARCHIVE_SCHEDULED_EVENT_SUCCESS: {
      const scheduledEvents = { ...state.scheduledEvents };
      delete scheduledEvents[action.payload.scheduledEventId];
      return {
        ...state,
        isArchivingScheduledEvent: false,
        hasArchivedScheduledEventSuccessfully: true,
        archiveScheduledEventErrorMessage: null,
        scheduledEvents,
      };
    }
    case ActionType.ARCHIVE_SCHEDULED_EVENT_FAILURE:
      return {
        ...state,
        isArchivingScheduledEvent: false,
        hasArchivedScheduledEventSuccessfully: false,
        archiveScheduledEventErrorMessage: action.payload.errorMessage,
      };

    case ActionType.CLEAR_SCHEDULED_EVENT_REQUESTS:
      return {
        ...state,
        isCreatingScheduledEvent: false,
        hasCreatedScheduledEventSuccessfully: null,
        createScheduledEventErrorMessage: null,

        isUpdatingScheduledEvent: false,
        hasUpdatedScheduledEventSuccessfully: null,
        updateScheduledEventErrorMessage: null,

        isArchivingScheduledEvent: false,
        hasArchivedScheduledEventSuccessfully: null,
        archiveScheduledEventErrorMessage: null,
      };

    case ActionType.REQUEST_GET_SCHEDULED_EVENTS_FOR_DEAL: {
      return {
        ...state,
        isGettingScheduledEventsForDeal: true,
      };
    }
    case ActionType.GET_SCHEDULED_EVENTS_FOR_DEAL_FAILURE:
    case ActionType.GET_SCHEDULED_EVENTS_FOR_DEAL_SUCCESS: {
      return {
        ...state,
        isGettingScheduledEventsForDeal: false,
      };
    }

    case ActionType.REQUEST_GET_SCHEDULED_EVENTS_FOR_TASK: {
      return {
        ...state,
        isGettingScheduledEventsForTask: true,
      };
    }
    case ActionType.GET_SCHEDULED_EVENTS_FOR_TASK_FAILURE:
      return {
        ...state,
        isGettingScheduledEventsForTask: false,
      };
    case ActionType.GET_SCHEDULED_EVENTS_FOR_TASK_SUCCESS:
      return {
        ...state,
        scheduledEvents: mergeFetchedEntities({
          cache: state.scheduledEvents,
          values: action.payload,
        }),
        isGettingScheduledEventsForTask: false,
      };

    case ActionType.CLEAR_SCHEDULED_EVENTS:
      return initialState;

    default:
      return state;
  }
};

export default scheduledEventsReducer;
