export enum IndustryTypes {
  APPLIANCE_REPAIR = 'APPLIANCE_REPAIR',
  CARPENTRY = 'CARPENTRY',
  COMMERCIAL_CONSTRUCTION = 'COMMERCIAL_CONSTRUCTION',
  ELECTRICAL = 'ELECTRICAL',
  FENCING_AND_DECKING = 'FENCING_AND_DECKING',
  FLOORING = 'FLOORING',
  GLAZING = 'GLAZING',
  LANDSCAPING_AND_LAWNS = 'LANDSCAPING_AND_LAWNS',
  LOCKSMITH_SERVICES = 'LOCKSMITH_SERVICES',
  PAINTING_AND_DECORATING = 'PAINTING_AND_DECORATING',
  PEST_CONTROL = 'PEST_CONTROL',
  POOL_AND_SPA_SERVICES = 'POOL_AND_SPA_SERVICES',
  PLASTERING = 'PLASTERING',
  PLUMBING = 'PLUMBING',
  HEATING = 'HEATING',
  PROPERTY_MAINTENANCE = 'PROPERTY_MAINTENANCE ',
  RESIDENTIAL_RENOVATIONS = 'RESIDENTIAL_RENOVATIONS',
  ROOFING = 'ROOFING',
  SECURITY_SERVICES = 'SECURITY_SERVICES',
  TILING = 'TILING',
  TREE_CARE = 'TREE_CARE',
  WINDOW_CLEANING = 'WINDOW_CLEANING',
  OTHER = 'OTHER',
}

export const IndustryProfessionMap = {
  [IndustryTypes.APPLIANCE_REPAIR]: 'appliance experts',
  [IndustryTypes.CARPENTRY]: 'carpenters',
  [IndustryTypes.COMMERCIAL_CONSTRUCTION]: 'construction experts',
  [IndustryTypes.ELECTRICAL]: 'electricians',
  [IndustryTypes.FENCING_AND_DECKING]: 'fencing experts',
  [IndustryTypes.FLOORING]: 'flooring experts',
  [IndustryTypes.GLAZING]: 'glazing experts',
  [IndustryTypes.LANDSCAPING_AND_LAWNS]: 'landscapers',
  [IndustryTypes.LOCKSMITH_SERVICES]: 'locksmiths',
  [IndustryTypes.PAINTING_AND_DECORATING]: 'painters',
  [IndustryTypes.PEST_CONTROL]: 'pest controllers',
  [IndustryTypes.POOL_AND_SPA_SERVICES]: 'pool & spa experts',
  [IndustryTypes.PLASTERING]: 'plasterers',
  [IndustryTypes.PLUMBING]: 'plumbers',
  [IndustryTypes.HEATING]: 'heating engineers',
  [IndustryTypes.PROPERTY_MAINTENANCE]: 'property experts',
  [IndustryTypes.RESIDENTIAL_RENOVATIONS]: 'renovators',
  [IndustryTypes.ROOFING]: 'roofers',
  [IndustryTypes.SECURITY_SERVICES]: 'security experts',
  [IndustryTypes.TILING]: 'tilers',
  [IndustryTypes.TREE_CARE]: 'tree specialists',
  [IndustryTypes.WINDOW_CLEANING]: 'window cleaners',
  [IndustryTypes.OTHER]: 'business leaders',
};

export enum NumberOfEmployees {
  ONE = '1',
  TWO_TO_FIVE = '2-5',
  SIX_TO_TWENTY = '6-20',
  TWNETY_ONE_TO_FIFTY = '21-50',
  FIFTY_ONE_TO_TWO_HUNDRED = '51-200',
  TWO_HUNDRED_PLUS = '200+',
}
