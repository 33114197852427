import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import MaterialBasicInformationControl from '../materialBasicInformationControl/MaterialBasicInformationControl';

import './MaterialHeader.sass';

type Props = {
  materialId: number;
};
const MaterialHeader: FC<Props> = ({ materialId }: Props): JSX.Element => {
  return (
    <div className="material-header">
      <div className="material-header-inner flex-container">
        <MaterialBasicInformationControl materialId={materialId} />
      </div>
    </div>
  );
};

export default MaterialHeader;
