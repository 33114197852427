import React, { FC, PropsWithChildren, useCallback } from 'react';

import { ConfirmPurchaseOrderMaterialPurchaseRecordRequestData } from '@payaca/store/materialsList/materialsListTypes';
import PurchaseOrderMaterialPurchaseRecordControl from './PurchaseOrderMaterialPurchaseRecordControl';
import {
  HydratedMaterialPurchaseIntent,
  HydratedPurchaseOrder,
} from '@payaca/types/materialsListTypes';
import './PurchaseOrderMaterialPurchaseRecordsControl.sass';

type Props = {
  materialPurchaseIntents: HydratedPurchaseOrder['materialPurchaseIntents'];
  materialPurchaseRecords: ConfirmPurchaseOrderMaterialPurchaseRecordRequestData[];
  onChange: (
    materialPurchaseRecords: ConfirmPurchaseOrderMaterialPurchaseRecordRequestData[]
  ) => void;
};
const PurchaseOrderMaterialPurchaseRecordsControl: FC<Props> = ({
  materialPurchaseIntents,
  materialPurchaseRecords,
  onChange,
}: PropsWithChildren<Props>): JSX.Element => {
  const onChangeMaterialPurchaseRecord = useCallback(
    (materialsListMaterialId: number, value: { [key: string]: any }) => {
      onChange(
        materialPurchaseRecords.map((x) => {
          if (materialsListMaterialId === x.materialsListMaterialId) {
            return { ...x, ...value };
          } else {
            return x;
          }
        })
      );
    },
    [materialPurchaseRecords, onChange]
  );

  const onAddMaterialPurchaseRecord = useCallback(
    (
      materialPurchaseRecord: ConfirmPurchaseOrderMaterialPurchaseRecordRequestData
    ) => {
      onChange([...materialPurchaseRecords, materialPurchaseRecord]);
    },
    [onChange, materialPurchaseRecords]
  );

  const onRemoveMaterialsPurchaseRecord = useCallback(
    (materialsListMaterialId: number) => {
      onChange(
        materialPurchaseRecords.filter(
          (x) => x.materialsListMaterialId !== materialsListMaterialId
        )
      );
    },
    [materialPurchaseRecords, onChange]
  );

  const renderPurchaseOrderMaterialPurchaseRecordControl = useCallback(
    (materialPurchaseIntent: HydratedMaterialPurchaseIntent) => {
      const materialPurchaseRecord = materialPurchaseRecords.find(
        (x) =>
          materialPurchaseIntent.materialsListMaterialId ===
          x.materialsListMaterialId
      );

      return (
        <PurchaseOrderMaterialPurchaseRecordControl
          materialPurchaseIntent={materialPurchaseIntent}
          materialPurchaseRecord={materialPurchaseRecord}
          onChange={(value) => {
            onChangeMaterialPurchaseRecord(
              materialPurchaseIntent.materialsListMaterialId,
              value
            );
          }}
          onAdd={onAddMaterialPurchaseRecord}
          onRemove={() =>
            onRemoveMaterialsPurchaseRecord(
              materialPurchaseIntent.materialsListMaterialId
            )
          }
        />
      );
    },
    [
      materialPurchaseRecords,
      onChangeMaterialPurchaseRecord,
      onAddMaterialPurchaseRecord,
      onRemoveMaterialsPurchaseRecord,
    ]
  );

  return (
    <div className="purchase-order-material-purchase-records-control">
      <ul>
        {materialPurchaseIntents.map((mpi, i) => {
          return (
            <li key={`purchase-order-material-purchase-record-control-${i}`}>
              {renderPurchaseOrderMaterialPurchaseRecordControl(mpi)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PurchaseOrderMaterialPurchaseRecordsControl;
