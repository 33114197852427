import React, {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import './AggregateValueBarChart.css';
import * as d3 from 'd3';

import {
  AggregateValueChartData,
  AggregateValueChartDatapoint,
  AggregateValueChartAggregateDatapoint,
} from '@payaca/types/analyticsTypes';

const DEFAULT_CHART_PADDING = {
  top: 0,
  bottom: 40,
  left: 40,
  right: 0,
};

const ANIMATION_DURATION_MS = 1000;
const LABEL_MARGIN_PX = 4;
const MAX_BAND_WIDTH_PX = 40;

enum BarLabelPosition {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  NO_DISPLAY = 'no_display',
}

interface Coordinates {
  x: number;
  y: number;
}

type Props = {
  data: AggregateValueChartData | null;
  xAxisLabel?: string;
  yAxisLabel?: string;
  overrideChartPadding?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  formatValue?: (value: number) => string;
  formatXAxisTickValue?: (value: number) => string;
};

const AggregateValueBarChart: FunctionComponent<Props> = ({
  data,
  xAxisLabel,
  yAxisLabel,
  overrideChartPadding,
  formatValue,
  formatXAxisTickValue,
}: Props): JSX.Element => {
  const [chartContainer, setChartContainer] = useState<HTMLDivElement | null>(
    null
  );
  const [requiresUpdateVisualisationData, setRequiresUpdateVisualisationData] =
    useState(true);

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipCoordinates, setTooltipCoordinates] = useState<Coordinates>({
    x: 0,
    y: 0,
  });
  const [tooltipContent, setTooltipContent] = useState<
    string | JSX.Element | JSX.Element[]
  >();

  const chartContainerRef = useCallback((node: any) => {
    if (node !== null) {
      setChartContainer(node);
    }
  }, []);

  const chartPadding = useMemo(() => {
    return { ...DEFAULT_CHART_PADDING, ...overrideChartPadding };
  }, [overrideChartPadding]);

  const svgSelection = useMemo(() => {
    if (chartContainer) {
      return d3.select(chartContainer).append('svg');
    }
  }, [chartContainer]);

  const xAxisLabelSelection = useMemo(() => {
    if (svgSelection) {
      return svgSelection
        .append('text')
        .text(xAxisLabel || '')
        .attr('class', 'axis-label');
    }
  }, [svgSelection, xAxisLabel]);

  const yAxisLabelSelection = useMemo(() => {
    if (svgSelection) {
      return svgSelection
        .append('text')
        .text(yAxisLabel || '')
        .attr('class', 'axis-label');
    }
  }, [svgSelection, yAxisLabel]);

  const xScale = useMemo(() => {
    return d3.scaleLinear().domain([0, 0]).range([0, 0]);
  }, []);
  const yScale = useMemo(() => {
    return d3.scaleBand().rangeRound([0, 0]).padding(0.1);
  }, []);

  const yAxis = useMemo(() => {
    return d3.axisLeft(yScale);
  }, [yScale]);

  const xAxis = useMemo(() => {
    let _xAxis = d3.axisBottom(xScale);

    if (formatXAxisTickValue) {
      _xAxis = _xAxis.tickFormat((value) => {
        return formatXAxisTickValue(value.valueOf());
      });
    }

    return _xAxis;
  }, [xScale, formatValue]);

  const chartDataGroupSelection = useMemo(() => {
    if (svgSelection) {
      return svgSelection
        .append('g')
        .attr(
          'transform',
          `translate(${chartPadding.left}, ${chartPadding.top})`
        );
    }
  }, [svgSelection]);

  const [aggregateBarGroups, setAggregateBarGroups] =
    useState<
      d3.Selection<
        SVGGElement,
        AggregateValueChartDatapoint,
        SVGGElement,
        unknown
      >
    >();
  const [barGroups, setBarGroups] =
    useState<
      d3.Selection<
        SVGGElement,
        AggregateValueChartAggregateDatapoint,
        SVGGElement,
        AggregateValueChartDatapoint
      >
    >();
  const [bars, setBars] =
    useState<
      d3.Selection<
        SVGRectElement,
        AggregateValueChartAggregateDatapoint,
        SVGGElement,
        AggregateValueChartDatapoint
      >
    >();
  const [barLabels, setBarLabels] =
    useState<
      d3.Selection<
        SVGTextElement,
        AggregateValueChartAggregateDatapoint,
        SVGGElement,
        AggregateValueChartDatapoint
      >
    >();

  const yAxisSelection = useMemo(() => {
    if (svgSelection) {
      return svgSelection
        .append('g')
        .attr(
          'transform',
          `translate(${chartPadding.left},${chartPadding.top})`
        )
        .call(yAxis);
    }
  }, [svgSelection, yAxis]);

  const xAxisSelection = useMemo(() => {
    if (svgSelection) {
      return svgSelection
        .append('g')
        .attr(
          'transform',
          `translate(${chartPadding.left},${
            chartPadding.top + yScale.range()[1]
          })`
        )
        .call(xAxis);
    }
  }, [svgSelection, xAxis, yScale]);

  const handleBarMouseenter = useCallback(
    function (event: any, datapoint: any) {
      setTooltipContent(
        <div>
          <p>{data?.aggregateCategories[datapoint.categoryLabel]}: </p>
          <p>{formatValue ? formatValue(datapoint.value) : datapoint.value}</p>
        </div>
      );
      setShowTooltip(true);
    },
    [formatValue, data?.categories]
  );

  const handleBarMousemove = useCallback(
    function (event: any) {
      const chartBounds = chartContainer?.getBoundingClientRect();
      setTooltipCoordinates({
        x: event.clientX - (chartBounds?.x || 0) + 5,
        y: event.clientY - (chartBounds?.y || 0) + 5,
      });
    },
    [chartContainer]
  );

  const handleBarMouseleave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const getBarLabelPosition = useCallback(
    (
      datapoint: AggregateValueChartAggregateDatapoint,
      index: number,
      elements: SVGTextElement[] | ArrayLike<SVGTextElement>
    ) => {
      const currentElement = elements[index];
      const bbox = currentElement.getBBox();
      const width = bbox.width;
      const height = bbox.height;

      const barWidth = xScale(datapoint.value) || 0;
      const barHeight = yScale.bandwidth();

      if (height >= barHeight) {
        return BarLabelPosition.NO_DISPLAY;
      } else if (width + LABEL_MARGIN_PX * 2 < barWidth) {
        return BarLabelPosition.INTERNAL;
      } else if (index + 1 === elements.length) {
        const barXEnd = (xScale(datapoint?.aggregateValue) || 0) + barWidth;
        if (
          barXEnd + LABEL_MARGIN_PX + width >
          xScale.range()[1] + chartPadding.right
        ) {
          return BarLabelPosition.NO_DISPLAY;
        } else {
          return BarLabelPosition.EXTERNAL;
        }
      } else {
        return BarLabelPosition.NO_DISPLAY;
      }
    },
    [xScale, yScale]
  );

  const updateVisualisationDimensions = useCallback(() => {
    const height = chartContainer?.clientHeight || 0;
    const width = chartContainer?.clientWidth || 0;
    const scaleWidth = width - chartPadding.right - chartPadding.left;
    const scaleHeight = height - chartPadding.bottom - chartPadding.top;
    const bandWidth = Math.min(MAX_BAND_WIDTH_PX, yScale.bandwidth());

    xScale.range([0, scaleWidth]);
    yScale.range([0, scaleHeight]);

    xAxisLabelSelection &&
      xAxisLabelSelection
        .attr('dy', height - 3)
        .attr('dx', chartPadding.left + scaleWidth / 2)
        .attr('text-anchor', 'middle');

    yAxisLabelSelection &&
      yAxisLabelSelection
        .attr('dy', '1em')
        .attr('dx', -(chartPadding.top + scaleHeight / 2))
        .attr('transform', 'rotate(-90)')
        .attr('text-anchor', 'middle');

    yAxisSelection && yAxisSelection.call(yAxis);

    xAxisSelection &&
      xAxisSelection
        .attr(
          'transform',
          `translate(${chartPadding.left},${
            chartPadding.top + yScale.range()[1]
          })`
        )
        .call(xAxis);

    aggregateBarGroups &&
      setAggregateBarGroups(
        aggregateBarGroups.attr(
          'transform',
          (d: AggregateValueChartDatapoint) => {
            const barOffset = (yScale.bandwidth() - bandWidth) / 2;
            return `translate(0, ${
              (yScale(d.categoryLabel) || 0) + barOffset
            })`;
          }
        )
      );

    barGroups && setBarGroups(barGroups);
    barGroups &&
      barGroups
        .transition()
        .duration(ANIMATION_DURATION_MS)
        .attr(
          'transform',
          (d: AggregateValueChartAggregateDatapoint) =>
            `translate(${xScale(d.aggregateValue) || 0}, 0)`
        );

    bars && setBars(bars.attr('height', bandWidth));
    bars &&
      bars
        .transition()
        .duration(ANIMATION_DURATION_MS)
        .attr(
          'width',
          (d: AggregateValueChartAggregateDatapoint) =>
            Math.max(0, xScale(d.value)) || 0
        );

    barLabels && setBarLabels(barLabels.attr('y', bandWidth / 2));
    barLabels &&
      barLabels
        .transition()
        .duration(ANIMATION_DURATION_MS)
        .attr(
          'x',
          (
            datapoint: AggregateValueChartAggregateDatapoint,
            i: number,
            elements: SVGTextElement[] | ArrayLike<SVGTextElement>
          ) => {
            const barWidth = xScale(datapoint.value) || 0;
            const barLabelPosition = getBarLabelPosition(
              datapoint,
              i,
              elements
            );

            if (barLabelPosition === BarLabelPosition.INTERNAL) {
              return barWidth - LABEL_MARGIN_PX;
            } else {
              return barWidth + LABEL_MARGIN_PX;
            }
          }
        )
        .attr(
          'class',
          (
            datapoint: AggregateValueChartAggregateDatapoint,
            i: number,
            elements: SVGTextElement[] | ArrayLike<SVGTextElement>
          ) => {
            const barLabelPosition = getBarLabelPosition(
              datapoint,
              i,
              elements
            );

            if (barLabelPosition === BarLabelPosition.INTERNAL) {
              return `bar-label ${datapoint.categoryLabel}-bar-label internal-bar-label`;
            } else if (barLabelPosition === BarLabelPosition.EXTERNAL) {
              return `bar-label ${datapoint.categoryLabel}-bar-label external-bar-label`;
            } else {
              return `bar-label ${datapoint.categoryLabel}-bar-label no-display-bar-label`;
            }
          }
        );
  }, [
    xScale,
    yScale,
    xAxisLabelSelection,
    yAxisLabelSelection,
    xAxisSelection,
    yAxisSelection,
    xAxis,
    yAxis,
    chartContainer,
    aggregateBarGroups,
    barGroups,
    bars,
    barLabels,
  ]);

  const updateAxisLabels = useCallback(() => {
    xAxisLabelSelection && xAxisLabelSelection.text(xAxisLabel || '');
    yAxisLabelSelection && yAxisLabelSelection.text(yAxisLabel || '');
  }, [xAxisLabelSelection, yAxisLabelSelection, xAxisLabel, yAxisLabel]);

  const updateVisualisationData = useCallback(() => {
    if (!data) return;

    xScale.domain([0, data.maxValue]);
    const labels = Object.keys(data.categories);
    yScale.domain(labels);
    yAxis.tickFormat((categoryLabel: string) => data.categories[categoryLabel]);

    if (chartDataGroupSelection) {
      const _aggregateBarGroups = chartDataGroupSelection
        .selectAll('g.aggregate-bar')
        .data(data.datapoints)
        .enter()
        .append('g')
        .attr('class', 'aggregate-bar');

      setAggregateBarGroups(_aggregateBarGroups);

      const _barGroups = _aggregateBarGroups
        .selectAll('g.bar')
        .data(
          (datapoint: AggregateValueChartDatapoint) =>
            datapoint.aggregateDatapoints
        )
        .enter()
        .append('g')
        .attr('class', 'bar');

      _barGroups.sort(
        (
          a: AggregateValueChartAggregateDatapoint,
          b: AggregateValueChartAggregateDatapoint
        ) => {
          return b.aggregateValue - a.aggregateValue;
        }
      );

      setBarGroups(_barGroups);

      const _bars = _barGroups
        .append('rect')
        .attr(
          'class',
          (d: AggregateValueChartAggregateDatapoint) => d.categoryLabel
        );

      _bars
        .on('mouseenter', handleBarMouseenter)
        .on('mousemove', handleBarMousemove)
        .on('mouseleave', handleBarMouseleave);

      setBars(_bars);

      const _barLabels = _barGroups
        .append('text')
        .attr(
          'class',
          (d: AggregateValueChartAggregateDatapoint) =>
            `bar-label ${d.categoryLabel}-bar-label`
        )
        .text((d: AggregateValueChartAggregateDatapoint) =>
          formatValue ? formatValue(d.value) : d.value
        );

      setBarLabels(_barLabels);
    }
  }, [
    data,
    xScale,
    yScale,
    yAxis,
    chartDataGroupSelection,
    handleBarMouseenter,
    handleBarMousemove,
    handleBarMouseleave,
  ]);

  useEffect(() => {
    window.addEventListener('resize', updateVisualisationDimensions);

    return () => {
      window.removeEventListener('resize', updateVisualisationDimensions);
    };
  }, [updateVisualisationDimensions]);

  useEffect(() => {
    updateAxisLabels();
  }, [xAxisLabel, yAxisLabel, updateAxisLabels]);

  useEffect(() => {
    if (svgSelection) updateVisualisationDimensions();
  }, [svgSelection, updateVisualisationDimensions]);

  useEffect(() => {
    if (data && requiresUpdateVisualisationData) {
      updateVisualisationData();
      updateVisualisationDimensions();
      setRequiresUpdateVisualisationData(false);
    }
  }, [
    data,
    requiresUpdateVisualisationData,
    updateVisualisationDimensions,
    updateVisualisationData,
  ]);

  useEffect(() => {
    if (data) {
      setRequiresUpdateVisualisationData(true);
    }
  }, [data]);

  return (
    <div
      ref={chartContainerRef}
      className="aggregate-value-bar-chart-container"
    >
      <div
        className="chart-tooltip"
        style={{
          top: tooltipCoordinates.y,
          left: tooltipCoordinates.x,
          opacity: showTooltip ? 1 : 0,
        }}
      >
        {tooltipContent}
      </div>
    </div>
  );
};

export default AggregateValueBarChart;
