import { Task } from '@payaca/types/taskTypes';
import { FetchedEntity } from '@payaca/types/storeTypes';

export interface State {
  isPersistingTask: boolean;
  isDeletingTask: boolean;
  isUpdatingTaskCompletion: boolean;

  isGettingTasksForScheduledEvent: boolean;
  isGettingTasksForDeal: boolean;
  tasks: { [key: number]: FetchedEntity<Task> };
}

export enum ActionType {
  REQUEST_PERSIST_TASK = 'tasks.requestPersistTask',
  PERSIST_TASK_SUCCESS = 'tasks.persistTaskSuccess',
  PERSIST_TASK_FAILURE = 'tasks.persistTaskFailure',

  REQUEST_DELETE_TASK = 'tasks.requestDeleteTask',
  DELETE_TASK_SUCCESS = 'tasks.deleteTaskSuccess',
  DELETE_TASK_FAILURE = 'tasks.deleteTaskFailure',

  REQUEST_UPDATE_TASK_COMPLETION = 'tasks.requestUpdateTaskCompletion',
  UPDATE_TASK_COMPLETION_SUCCESS = 'tasks.updateTaskCompletionSuccess',
  UPDATE_TASK_COMPLETION_FAILURE = 'tasks.updateTaskCompletionFailure',

  REQUEST_GET_TASK = 'tasks.requestGetTask',
  REQUEST_GET_TASKS_FOR_DEAL = 'tasks.requestGetTasksForDeal',
  GET_TASKS_FOR_DEAL_SUCCESS = 'tasks.getTasksForDealSuccess',
  GET_TASKS_FOR_DEAL_FAILURE = 'tasks.getTasksForDealFailure',
  REQUEST_GET_TASKS_FOR_SCHEDULED_EVENT = 'tasks.requestGetTasksForScheduledEvent',
  GET_TASKS_FOR_SCHEDULED_EVENT_SUCCESS = 'tasks.getTasksForScheduledEventSuccess',
  GET_TASKS_FOR_SCHEDULED_EVENT_FAILURE = 'tasks.getTasksForScheduledEventFailure',
  GET_TASK_SUCCESS = 'tasks.getTaskSuccess',
  GET_TASK_FAILURE = 'tasks.getTaskFailure',

  CLEAR_TASK_REQUEST = 'tasks.clearTask:request',
  CLEAR_TASKS = 'tasks.clearTasks',
}

export interface SagaConfig {
  apiBaseurl: string;
  getAuthHeader: () => Promise<string>;
  isNativeApp?: boolean;
}

export interface PersistTaskRequestData {
  id?: number;
  dealId?: number;
  name?: string;
  notes?: string;
  assignedToUserId?: number;
  deadlineDate?: Date;
  checklistItems?: {
    id?: number;
    name: string;
  }[];
  scheduledEventIds?: number[];
  formTemplateId?: number;
  documentId?: number;
  customerId?: number;
}

export interface UpdateTaskCompletionRequestData {
  id: number;
  isCompleted: boolean;
  checklistItems?: {
    id: number;
    isCompleted: boolean;
  }[];
}
