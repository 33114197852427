import { FC, useState } from 'react';
import { useGetUser } from '../../../../api/queries/user/useGetUser';
import { useParams } from 'react-router';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import UntitledIcon, { TIconName } from '@payaca/untitled-icons';
import { Nullish } from '@payaca/utilities/types';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getAccountRegionFromCurrencyCode } from '@payaca/helpers/internationalHelper';
import { UpdateUserModal } from '../../../components/updateUserModal/UpdateUserModal';
import Button from '@payaca/components/plButton/Button';

const UserProfile: FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const { data: userData, refetch: refetchUser } = useGetUser(+userId);
  const user = userData?.user;
  const region =
    user?.costPerHour?.currency &&
    getAccountRegionFromCurrencyCode(user?.costPerHour?.currency.code || 'gbp');

  const [showUpdateUserProfileModal, setShowUpdateUserProfileModal] =
    useState(false);
  return (
    <>
      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body>
          <div className="flex justify-between">
            <h3>About</h3>
            <Button onClick={() => setShowUpdateUserProfileModal(true)}>
              Edit
            </Button>
          </div>
          <div className="mt-2 flex flex-col gap-y-2">
            <UserProperty iconName="key-01.3" value={user?.systemRole} />
            <UserProperty iconName="mail-02.3" value={user?.email} />
            <UserProperty iconName="phone.3" value={user?.phone} />
            <UserProperty
              iconName="currency-pound"
              value={
                user?.costPerHour?.value &&
                `${currencyPrice(user?.costPerHour?.value, region)}/hr`
              }
            />
          </div>
        </Card.Body>
      </Card>
      <UpdateUserModal
        userId={+userId}
        isOpen={showUpdateUserProfileModal}
        onClose={() => setShowUpdateUserProfileModal(false)}
        onSuccess={() => {
          void refetchUser();
          setShowUpdateUserProfileModal(false);
        }}
      />
    </>
  );
};

type UserPropertyProps = {
  iconName: TIconName;
  value?: Nullish<string | number>;
};
const UserProperty: FC<UserPropertyProps> = ({ iconName, value }) => {
  if (!value) {
    return null;
  }
  return (
    <div className="flex gap-x-3 items-center">
      <UntitledIcon name={iconName} className="h-4 w-4 text-blue-900" />
      <span>{value}</span>
    </div>
  );
};

export default UserProfile;
