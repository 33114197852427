import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { apiFetch } from '@/utils/apiFetch';
import { ConnectionApplication } from '@payaca/types/connectionApplication';

type ConnectionApplicationSubmissionResponse = {
  success: boolean;
  message?: string;
};

type Variables = {
  connectionApplicationId: string;
  data: ConnectionApplication['data'];
};

const usePostSubmitConnectionApplication = (
  options?: UseMutationOptions<
    ConnectionApplicationSubmissionResponse,
    ConnectionApplicationSubmissionResponse,
    Variables
  >
) => {
  return useMutation({
    mutationFn: async ({ connectionApplicationId, data }) => {
      const response = await apiFetch(
        `/provider/rest/ena/connection-application/${connectionApplicationId}/submit`,
        {
          method: 'POST',
          body: JSON.stringify(data),
        }
      );

      const json =
        (await response.json()) as ConnectionApplicationSubmissionResponse;

      if (!json.success) {
        throw json;
      }

      return json;
    },
    ...options,
  });
};

export default usePostSubmitConnectionApplication;
