import { action } from 'typesafe-actions';

import { LineItem } from '@payaca/types/lineItemTypes';

import { createAsyncAction } from '../utils';

import {
  ActionType,
  DuplicateLineItemAction,
  PersistLineItemRequestData,
} from './lineItemsTypes';

export const requestGetLineItem = (
  lineItemId: number,
  callback?: (lineItem: LineItem) => void,
  errorCallback?: (error: any) => void
) =>
  action(ActionType.REQUEST_GET_LINE_ITEM, {
    lineItemId,
    callback,
    errorCallback,
  });

export const requestGetLineItemWithRelatedEntities = (
  lineItemId: number,
  callback?: () => void
) =>
  action(ActionType.REQUEST_GET_LINE_ITEM_WITH_RELATED_ENTITIES, {
    lineItemId,
    callback,
  });

export const getLineItemWithRelatedEntitiesSuccess = (response: any) =>
  action(ActionType.GET_LINE_ITEM_WITH_RELATED_ENTITIES_SUCCESS, response);

export const getLineItemSuccess = (lineItem: LineItem) =>
  action(ActionType.GET_LINE_ITEM_SUCCESS, {
    lineItemId: lineItem.id,
    lineItem,
  });

export const getLineItemFailure = (error: Error, errorMessage: string) =>
  action(ActionType.GET_LINE_ITEM_FAILURE, { errorMessage }, null, error);

export const clearLineItems = () => action(ActionType.CLEAR_LINE_ITEMS);

export const requestGetLineItemsForLineItemGroup = (lineItemGroupId: number) =>
  action(ActionType.REQUEST_GET_LINE_ITEMS_FOR_LINE_ITEM_GROUP, {
    lineItemGroupId,
  });

export const getLineItemsForLineItemGroupSuccess = (
  lineItems: Array<LineItem>
) => action(ActionType.GET_LINE_ITEMS_FOR_LINE_ITEM_GROUP_SUCCESS, lineItems);

export const requestPersistLineItem = (
  persistLineItemRequestData: PersistLineItemRequestData,
  callback?: (lineItemId: number) => void,
  errorCallback?: (error: string) => void
) =>
  action(ActionType.REQUEST_PERSIST_LINE_ITEM, {
    persistLineItemRequestData,
    callback,
    errorCallback,
  });

export const persistLineItemSuccess = () =>
  action(ActionType.PERSIST_LINE_ITEM_SUCCESS);

export const persistLineItemFailure = (error: Error) =>
  action(ActionType.PERSIST_LINE_ITEM_FAILURE, null, null, error);

export const duplicateLineItem = createAsyncAction<DuplicateLineItemAction>({
  request: ActionType.DUPLICATE_LINE_ITEM_REQUEST,
  success: ActionType.DUPLICATE_LINE_ITEM_SUCCESS,
  failure: ActionType.DUPLICATE_LINE_ITEM_FAILURE,
})((payload: DuplicateLineItemAction['request']['payload']) => ({ payload }));
