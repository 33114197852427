import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import {
  DefaultSystemRoles,
  SubscriptionLevels,
} from '@payaca/permissions/default-role.config';
import {
  userHasRequiredPermission,
  userHasAllRequiredPermissions,
} from '@payaca/permissions/permissions.utils';
import './PermissionGuard.sass';
import { Unlock } from 'react-iconly';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import Modal from '@payaca/components/modal/Modal';
import { getUserRoles } from '@/utils/stateAccessors';
import { useSelector } from '@/api/state';

export interface Props {
  // if user has any of these permissions, we'll let them access this feature
  renderIfHasPermissions?: string[];
  requireAllPermissions?: boolean;
  renderIfMissingPermissions?: string[];

  // what subscription level does this feature become available (if applicable)
  associatedSubscription?: SubscriptionLevels;
  placeholderMessage?: any;
}

const systemRoleNumericMap: any = {
  [SubscriptionLevels.SOLO]: 0,
  [SubscriptionLevels.TEAM]: 1,
  [SubscriptionLevels.BUSINESS]: 2,
};

export const PermissionGuard: FC<PropsWithChildren<Props>> = ({
  renderIfHasPermissions,
  requireAllPermissions = false,
  renderIfMissingPermissions,
  associatedSubscription,
  children,
  placeholderMessage = '',
}) => {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const myProfile = useSelector((state: any) => state.users.myProfile);
  const userRoles = useSelector(getUserRoles);
  const accountSubscription = useSelector(
    (state) => state.subscription.accountSubscription
  );

  const userCanUseFeature = useMemo(() => {
    let result = false;

    if (renderIfHasPermissions?.length) {
      result = requireAllPermissions
        ? userHasAllRequiredPermissions(userRoles, renderIfHasPermissions)
        : userHasRequiredPermission(userRoles, renderIfHasPermissions);
    }

    if (renderIfMissingPermissions?.length) {
      const isMissingPermissions = !userHasRequiredPermission(
        userRoles,
        renderIfMissingPermissions
      );

      result = isMissingPermissions;
    }

    return result;
  }, [
    userRoles,
    renderIfHasPermissions,
    requireAllPermissions,
    renderIfMissingPermissions,
  ]);
  const hideElement = useMemo(() => {
    if (!associatedSubscription) return true;
    const currentSubscriptionNumericValue =
      systemRoleNumericMap[(accountSubscription as any) /* FIXME */.mapping] ||
      0;
    return (
      ![
        DefaultSystemRoles.SALES_MANAGER,
        DefaultSystemRoles.ADMIN,
        DefaultSystemRoles.SUPER_ADMIN,
      ].includes(myProfile.systemRole) &&
      currentSubscriptionNumericValue <
        systemRoleNumericMap[associatedSubscription]
    );
  }, []);

  if (!userCanUseFeature && hideElement && !placeholderMessage?.length)
    return null;

  return userCanUseFeature ? (
    children
  ) : (
    <>
      {hideElement ? (
        placeholderMessage
      ) : (
        <>
          <div className={'feature-unavailable-container'}>
            <div
              className={'padlock'}
              onClick={() => setShowUpgradeModal(true)}
            >
              <Unlock stroke={'bold'} />
            </div>
            <div className={'children'}>{children}</div>
          </div>
          <Modal
            className={'feature-unavailable-upgrade-modal'}
            isOpen={showUpgradeModal}
            onClose={() => setShowUpgradeModal(false)}
            title={'This feature is not available on your current plan! 🥺'}
            actions={
              <div className={'flex-container'}>
                <Button
                  onClick={() => setShowUpgradeModal(false)}
                  styleVariant={ButtonStyleVariant.OUTSIZE}
                  isOutlined={true}
                >{`No thanks`}</Button>
                <Button
                  onClick={() => null}
                  styleVariant={ButtonStyleVariant.OUTSIZE}
                  colourVariant={ButtonColourVariant.PRIMARY}
                >{`Try "Team" plan free for 7 days`}</Button>
              </div>
            }
          >
            <h4>Upgrade to the Team plan to get access to:</h4>
            <ul>
              <li>
                All our <strong>Forms</strong>, including the Gas Safety
                Certificate, Job Report and more
              </li>
              <li>
                <strong>Customisable Project Workflow</strong> - decided what
                happens when the statuses of your Projects change using our easy
                system
              </li>
              <li>
                <strong>Access to Zapier</strong> - connect Payaca with 1000s of
                other apps and websites
              </li>
            </ul>
          </Modal>
        </>
      )}
    </>
  );
};
