import React, { FC, useEffect, useMemo, useState } from 'react';

import Modal from '@payaca/components/modal/Modal';
import './ScheduledEventLinkedTasksModal.sass';
import { getTasksByDealId, getUserRoles } from '@/utils/stateAccessors';
import Button from '@payaca/components/button/Button';
import IconButton from '@payaca/components/button/IconButton';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { ScheduledEvent } from '@payaca/types/scheduledEventsTypes';
import ScheduledEventLinkedTasksControl from '../scheduledEventLinkedTasksControl/ScheduledEventLinkedTasksControl';
import ScheduledEventLinkedNewTaskControl from '../scheduledEventLinkedNewTaskControl/scheduledEventLinkedNewTaskControl';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from '@/api/state';

enum ModalState {
  LINK_NEW_TASK = 'link-new-task',
  LINK_EXISTING_TASK = 'link-existing-task',
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onPersistScheduledEventSuccess?: () => void;
  scheduledEvent: ScheduledEvent;
};
const ScheduledEventLinkedTasksModal: FC<Props> = ({
  isOpen,
  onClose,
  onPersistScheduledEventSuccess,
  scheduledEvent,
}: Props): JSX.Element | null => {
  const [modalState, setModalState] = useState(ModalState.LINK_EXISTING_TASK);

  const dealTasks = useSelector((state) => {
    return scheduledEvent.dealId
      ? getTasksByDealId(state, scheduledEvent.dealId)
      : [];
  });

  const userRoles = useSelector(getUserRoles);

  useEffect(() => {
    if (
      !dealTasks.length &&
      userHasRequiredPermission(userRoles, [TasksPermissions.PERSIST_TASK])
    ) {
      setModalState(ModalState.LINK_NEW_TASK);
    }
  }, []);

  const modalTitle = useMemo(() => {
    return (
      <ResponsiveViewWrapper className="title" downBreakpointSm={400}>
        <h3>Link tasks</h3>
        <div className="rhs">
          {userHasRequiredPermission(userRoles, [
            TasksPermissions.PERSIST_TASK,
          ]) &&
            modalState != ModalState.LINK_NEW_TASK && (
              <>
                <Button
                  className="md-button"
                  isOutlined={true}
                  onClick={() => setModalState(ModalState.LINK_NEW_TASK)}
                >
                  Create task
                </Button>
                <IconButton
                  className="sm-button"
                  isOutlined={true}
                  onClick={() => setModalState(ModalState.LINK_NEW_TASK)}
                  icon={faPlus}
                  size="sm"
                />
              </>
            )}

          {!!dealTasks.length &&
            modalState != ModalState.LINK_EXISTING_TASK && (
              <Button
                className="md-button"
                isOutlined={true}
                onClick={() => {
                  setModalState(ModalState.LINK_EXISTING_TASK);
                }}
              >
                Select existing
              </Button>
            )}
        </div>
      </ResponsiveViewWrapper>
    );
  }, [userRoles, modalState]);

  if (!scheduledEvent.dealId) return null;

  return (
    <Modal
      isOpen={isOpen}
      className="scheduled-event-linked-tasks-modal"
      title={modalTitle}
      onClose={onClose}
      size="sm"
    >
      {modalState === ModalState.LINK_EXISTING_TASK && (
        <ScheduledEventLinkedTasksControl
          scheduledEvent={scheduledEvent}
          onPersistScheduledEventSuccess={onPersistScheduledEventSuccess}
        />
      )}
      {modalState === ModalState.LINK_NEW_TASK && scheduledEvent.dealId && (
        <ScheduledEventLinkedNewTaskControl
          scheduledEventId={scheduledEvent.id}
          dealId={scheduledEvent.dealId}
          onPersistScheduledEventSuccess={onPersistScheduledEventSuccess}
        />
      )}
    </Modal>
  );
};
export default ScheduledEventLinkedTasksModal;
