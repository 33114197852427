import React, { FC } from 'react';

import ConfirmDeleteModal, {
  ConfirmDeleteModalProps,
} from '../confirm-delete-modal/ConfirmDeleteModal';

const ConfirmDeleteCustomerReminderModal: FC<
  Omit<ConfirmDeleteModalProps, 'entityName'>
> = (props) => (
  <ConfirmDeleteModal
    entityName={{ singular: 'Customer Reminder', plural: 'Customer Reminders' }}
    {...props}
  />
);

export default ConfirmDeleteCustomerReminderModal;
