import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { JobContent, JobLineItem } from '@payaca/types/jobContentTypes';
import './InvoiceTotals.sass';
import { currencyPrice, formatPercentage } from '@payaca/helpers/financeHelper';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import moment from 'moment-timezone';
import { ClientInvoice } from '@payaca/types/clientTypes';
import { Invoice, InvoiceLine } from '@payaca/types/invoiceTypes';
import { getInvoiceTotalBreakdown } from '@payaca/helpers/invoiceHelper';
import { AccountRegions } from '@payaca/types/accountTypes';
import { DealTotals } from '@payaca/types/dealTypes';
import PaidStamp from '../paidStamp/PaidStamp';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import VoidStamp from '../voidStamp/VoidStamp';

type Props = {
  invoiceId: number;
  invoices: Pick<
    Invoice,
    'id' | 'sentAt' | 'voidedAt' | 'totalValue' | 'dueValue'
  >[];
  dealTotals: Pick<DealTotals, 'acceptedValue'>;
  jobLineItems: JobLineItem[];
  invoiceLines: InvoiceLine[];
  region?: AccountRegions;
};

const InvoiceTotals: FunctionComponent<React.PropsWithChildren<Props>> = ({
  invoices,
  invoiceId,
  dealTotals,
  invoiceLines,
  jobLineItems,
  region,
}: React.PropsWithChildren<Props>): JSX.Element | null => {
  const invoice = useMemo(() => {
    return invoices.find((x) => x.id == invoiceId);
  }, [invoices, invoiceId]);

  const invoiceTotalBreakdown = useMemo(() => {
    return getInvoiceTotalBreakdown(invoiceLines, jobLineItems);
  }, [invoiceLines, jobLineItems]);

  if (!invoice) return null;

  return (
    <div className="mt-7 flex flex-col">
      <table className="ml-auto text-right text-base">
        <tbody>
          <tr>
            <td className="pr-2">Subtotal:</td>
            <td>{currencyPrice(invoiceTotalBreakdown.subtotal, region)}</td>
          </tr>

          {!!invoiceTotalBreakdown.discount && (
            <tr>
              <td className="pr-2">Discount:</td>
              <td>
                {invoiceTotalBreakdown.discount * -1 >= 0 ? '+' : ''}
                {currencyPrice(invoiceTotalBreakdown.discount * -1, region)}
              </td>
            </tr>
          )}

          <tr>
            <td className="pr-2">
              {getRegionalTextString(region, RegionalStrings.VALUE_ADDED_TAX)}:
            </td>
            <td>{currencyPrice(invoiceTotalBreakdown.vatTotal, region)}</td>
          </tr>

          {invoiceTotalBreakdown.cisTotal != 0 && (
            <tr>
              <td className="pr-2">CIS suffered:</td>
              <td>
                {invoiceTotalBreakdown.cisTotal * -1 >= 0 ? '+' : ''}
                {currencyPrice(invoiceTotalBreakdown.cisTotal * -1, region)}
              </td>
            </tr>
          )}

          <tr>
            <td className="pr-2">Total for this invoice:</td>
            <td>
              <b>{currencyPrice(invoice.totalValue, region)}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceTotals;
