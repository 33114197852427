import { FC } from 'react';

type Props = {
  numberOfSelectedFilters: number;
};
const NumberOfSelectedFilters: FC<Props> = (props) => {
  const { numberOfSelectedFilters } = props;
  return (
    <span
      data-testid="number-of-selected-filters"
      className="font-medium text-[10px] py-0.5 px-[5px] bg-gray-800 text-white leading-3 rounded-full"
    >
      {numberOfSelectedFilters}
    </span>
  );
};

export default NumberOfSelectedFilters;
