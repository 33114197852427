import React, { FC } from 'react';

type Props = {
  className?: string;
};
const TimesIcon: FC<Props> = ({ className }: Props): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.302"
      height="10.3"
      viewBox="0 0 10.302 10.3"
      className={className}
    >
      <g
        id="Iconly_Light_Close_Square"
        data-name="Iconly Light Close Square"
        transform="translate(-1034.85 -936.747)"
      >
        <g id="Close_Square-3" data-name="Close Square-3">
          <g id="Stroke_1-73" data-name="Stroke 1-73">
            <path
              id="Path_200"
              data-name="Path 200"
              d="M1035.6,947.041a.75.75,0,0,1-.531-1.28l8.792-8.792a.75.75,0,0,1,1.061,1.06l-8.792,8.792A.746.746,0,0,1,1035.6,947.041Z"
            />
          </g>
          <g id="Stroke_2-3" data-name="Stroke 2-3">
            <path
              id="Path_201"
              data-name="Path 201"
              d="M1044.4,947.047a.746.746,0,0,1-.53-.22l-8.8-8.8a.75.75,0,0,1,1.061-1.06l8.8,8.8a.75.75,0,0,1-.531,1.28Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TimesIcon;
