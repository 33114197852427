import React, { FC, useCallback, useEffect, useState } from 'react';
import * as clipboard from 'clipboard-polyfill/text';

import UntitledIcon from '@payaca/untitled-icons';

type Props = {
  textToCopy: string;
};
const CopyTextIconButton: FC<Props> = ({ textToCopy }: Props): JSX.Element => {
  const [isRecentlyCopied, setIsRecentlyCopied] = useState<boolean>(false);

  useEffect(() => {
    if (!isRecentlyCopied) {
      return;
    }

    const timer = setTimeout(() => {
      setIsRecentlyCopied(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isRecentlyCopied]);

  const copyText = useCallback(async () => {
    await clipboard.writeText(textToCopy);
    setIsRecentlyCopied(true);
  }, [textToCopy]);

  return (
    <div className="flex h-5 w-5 items-center justify-center">
      {isRecentlyCopied ? (
        <UntitledIcon className="h-4 w-4" name={'check'} onClick={copyText} />
      ) : (
        <button className="cursor-pointer bg-transparent p-0">
          <UntitledIcon
            className="h-4 w-4"
            name={'copy-07.3'}
            onClick={copyText}
          />
        </button>
      )}
    </div>
  );
};

export default CopyTextIconButton;
