import React, { FC, PropsWithChildren } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './Modal.css';

type Props = {
  classes?: string;
  headerContent?: JSX.Element;
  icon?: any;
  onClose?: () => void;
  open: boolean;
  showCloseIcon?: boolean;
  size?: any;
  title: string;
};
const ModalWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  classes,
  headerContent,
  icon,
  onClose,
  open,
  showCloseIcon,
  size,
  title,
}: PropsWithChildren<Props>): JSX.Element => {
  const titleClass = 'title' + (classes ? ` ${classes}` : '');
  const contentClass = 'content' + (classes ? ` ${classes}` : '');
  return (
    <Dialog
      open={!!open}
      onClose={() => onClose && onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={size || 'sm'}
    >
      {icon && (
        <div style={{ textAlign: 'center' }}>
          <img
            style={{ maxWidth: 100, maxHeight: 100, marginTop: 20 }}
            alt="Modal icon"
            src={icon}
          />
        </div>
      )}
      <div className="modal-header-wrapper">
        <DialogTitle className={titleClass} id="alert-dialog-title">
          {title}
        </DialogTitle>
        {headerContent}
        {showCloseIcon && (
          <div className="close-icon">
            <FontAwesomeIcon icon={faTimes} onClick={onClose} />
          </div>
        )}
      </div>
      <DialogContent className={contentClass}>{children}</DialogContent>
    </Dialog>
  );
  // }
};
export default ModalWrapper;
