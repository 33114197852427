export enum AccountsPermissions {
  GET_ACCOUNT = 'accounts.getAccount',
  GET_USERS = 'accounts.getUsers',
  GET_USER_COST_PER_HOUR = 'accounts.getUserCostPerHour',
  SET_USER_COST_PER_HOUR = 'accounts.setUserCostPerHour',
  INVITE_USER = 'accounts.inviteUser',
  DEACTIVATE_USER = 'accounts.deactivateUser',
  REACTIVATE_USER = 'accounts.reactivateUser',
  UPDATE_ROLE = 'accounts.updateRole',
  ADD_CONNECTION = 'accounts.addConnection',
  UPDATE_CONNECTION = 'accounts.updateConnection',
  DELETE_CONNECTION = 'accounts.deleteConnection',
  UPDATE_SUBSCRIPTION = 'accounts.updateSubscription',
  UPDATE_MY_ACCOUNT = 'accounts.updateMyAccount',
  UPDATE_ACCOUNT = 'accounts.updateAccount',
  SET_CUSTOM_EMAIL_DOMAIN = 'accounts.setCustomEmailDomain',
  SEND_FROM_CUSTOM_EMAIL_DOMAIN = 'accounts.sendFromCustomEmailDomain',
  MANAGE_CUSTOM_FIELD_GROUPS = 'accounts.manageCustomFieldGroups',
  MANAGE_CUSTOM_FIELD_VALUES = 'accounts.manageCustomFieldValues',
  MANAGE_TAGS = 'accounts.manageTags',
}
