import { FC, useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import { useHistory } from 'react-router-dom';
import { Deal } from '@payaca/types/dealTypes';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import ManageableMaterialsListPurchaseOrders from '@/ui/components/manageableMaterialsListPurchaseOrders/ManageableMaterialsListPurchaseOrders';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import { MaterialsList } from '@payaca/types/materialsListTypes';

export const PurchaseOrdersPanel: FC<{
  projectId: Deal['id'];
  materialsListId: MaterialsList['id'];
}> = ({ projectId, materialsListId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleGetMaterialsListWithRelatedEntities = useCallback(
    (materialsListId: number) => {
      dispatch(
        materialsListActions.requestGetMaterialsListWithRelatedEntities(
          materialsListId
        )
      );
    },
    []
  );

  useEffect(() => {
    handleGetMaterialsListWithRelatedEntities(materialsListId);
  }, []);

  return (
    <div className="@container p-6">
      <PermissionGuard
        renderIfHasPermissions={[MaterialsListPermissions.GET_PURCHASE_ORDERS]}
      >
        <ManageableMaterialsListPurchaseOrders
          emptyText="No Purchase Orders found"
          materialsListId={materialsListId}
          navigateToPurchaseOrder={(purchaseOrderId) =>
            history.push(
              `/purchaseOrders/${purchaseOrderId}?projectId=${projectId}`
            )
          }
        />
      </PermissionGuard>
    </div>
  );
};
