import { action } from 'typesafe-actions';
import { ActionType } from './appTypes';

export const toggleVideoOverlay = (
  videoOverlayPayload: { src: string; onClose?: () => void } | null
) => {
  return action(ActionType.TOGGLE_VIDEO_OVERLAY, videoOverlayPayload);
};

export const hideWelcomeVideo = () => {
  return action(ActionType.HIDE_WELCOME_VIDEO);
};

export const clearApp = () => {
  return action(ActionType.CLEAR_APP);
};
