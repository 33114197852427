// ======================================================================================================
// Customer helper functions
// ======================================================================================================

const parseFirstAddressLine = (address: any): string => {
  return parseAddressArrayToString([
    address.flatNumber,
    address.houseNumber,
    address.houseName,
  ]);
};

const parseSecondAddressLine = (address: any): string => {
  return parseAddressArrayToString([address.street1, address.street2]);
};

// Parse reponse from lookupAddress service
const parseLookupAddress = (
  address: any
): {
  firstLineAddress: string;
  secondLineAddress: string;
  city: string;
  postcode: string;
} => {
  return {
    firstLineAddress: parseFirstAddressLine(address),
    secondLineAddress: parseSecondAddressLine(address),
    city: address.postTown,
    postcode: address.postCode,
  };
};

// Parse response from fetched customer
const parseCustomerAddress = (
  address: string
): { firstLineAddress: string; secondLineAddress: string } => {
  if (address) {
    const addressArr = address.split(', ');
    const firstLineAddress = addressArr.shift() || '';
    const secondLineAddress = addressArr.join(', ');
    return {
      firstLineAddress,
      secondLineAddress,
    };
  }

  return {
    firstLineAddress: '',
    secondLineAddress: '',
  };
};

const formatParsedFullAddress = (
  firstLineAddress: string,
  secondLineAddress: string,
  city: string,
  postcode: string
): string => {
  return parseAddressArrayToString([
    firstLineAddress,
    secondLineAddress,
    city,
    postcode,
  ]);
};

const parseAddressArrayToString = (strArr: string[]) =>
  strArr.filter((v) => v).join(', ');

const parseCustomer = (customer: any) => {
  // Just use "firstLineAddress" and "secondLineAddress" instead of "address"
  const parsedAddress = parseCustomerAddress(customer?.address || '');
  const parsedCustomer = {
    ...customer,
    firstLineAddress: parsedAddress.firstLineAddress,
    secondLineAddress: parsedAddress.secondLineAddress,
  };
  return parsedCustomer;
};

const parseCustomers = (customers: any[]) =>
  customers.map((customer) => parseCustomer(customer));

export {
  formatParsedFullAddress,
  parseAddressArrayToString,
  parseCustomer,
  parseCustomers,
  parseCustomerAddress,
  parseFirstAddressLine,
  parseLookupAddress,
  parseSecondAddressLine,
};
