import { action } from 'typesafe-actions';
import {
  ActionType,
  CreateAutomationPayload,
  GetDefaultAutomationDefinition,
  GetDefaultAutomationDefinitions,
  UpdateDefaultAutomation,
} from './automationTypes';
import {
  Automation,
  ListedAutomationsListViewPage,
  GetListedAutomationsRequestData,
  AutomationTemplate,
  AutomationActionTypes,
} from '@payaca/types/automationTypes';
import { createAsyncAction } from '../utils';

export const requestGetListedAutomationsPage = (
  getListedAutomationsRequestData: GetListedAutomationsRequestData,
  callback?: (
    listedAutomationsListViewPage: ListedAutomationsListViewPage
  ) => void
) => {
  return action(ActionType.REQUEST_GET_LISTED_AUTOMATIONS_PAGE, {
    getListedAutomationsRequestData,
    callback,
  });
};

export const getListedAutomationsPageSuccess = (
  listedAutomationsListViewPage: ListedAutomationsListViewPage
) => {
  return action(
    ActionType.GET_LISTED_AUTOMATIONS_PAGE_SUCCESS,
    listedAutomationsListViewPage
  );
};

export const getListedAutomationsPageFailure = (error: Error) => {
  return action(
    ActionType.GET_LISTED_AUTOMATIONS_PAGE_FAILURE,
    null,
    null,
    error
  );
};

export const requestGetAutomation = (automationId: number) => {
  return action(ActionType.REQUEST_GET_AUTOMATION, { automationId });
};

export const getAutomationSuccess = (
  automationId: number,
  automation: Automation<AutomationActionTypes>
) => {
  return action(ActionType.GET_AUTOMATION_SUCCESS, {
    automationId,
    automation,
  });
};

export const getAutomationFailure = (automationId: number, error: Error) => {
  return action(ActionType.GET_AUTOMATION_FAILURE, automationId, null, error);
};

export const requestCreateAutomation = (
  createAutomationPayload: CreateAutomationPayload,
  callback?: any
) => {
  return action(ActionType.REQUEST_CREATE_AUTOMATION, {
    createAutomationPayload,
    callback,
  });
};

export const createAutomationSuccess = (automationId: number) => {
  return action(ActionType.CREATE_AUTOMATION_SUCCESS, automationId);
};

export const createAutomationFailure = (error: Error) => {
  return action(ActionType.CREATE_AUTOMATION_FAILURE, null, error);
};

export const requestUpdateAutomation = (
  automationId: number,
  automationUpdates: Automation<AutomationActionTypes>,
  callback?: (automation: Automation<AutomationActionTypes>) => void
) => {
  return action(ActionType.REQUEST_UPDATE_AUTOMATION, {
    automationId,
    automationUpdates,
    callback,
  });
};

export const updateAutomationSuccess = (
  automationId: number,
  automation: Automation<AutomationActionTypes>
) => {
  return action(ActionType.UPDATE_AUTOMATION_SUCCESS, {
    automationId,
    automation,
  });
};

export const updateAutomationFailure = (automationId: number, error: Error) => {
  return action(
    ActionType.UPDATE_AUTOMATION_FAILURE,
    automationId,
    null,
    error
  );
};

export const requestArchiveAutomations = (
  automationIds: number[],
  onArchiveSuccess?: any
) => {
  return action(ActionType.REQUEST_ARCHIVE_AUTOMATIONS, {
    automationIds,
    onArchiveSuccess,
  });
};

export const archiveAutomationsSuccess = () => {
  return action(ActionType.ARCHIVE_AUTOMATIONS_SUCCESS);
};

export const archiveAutomationsFailure = (error: Error) => {
  return action(ActionType.ARCHIVE_AUTOMATIONS_FAILURE, null, error);
};

export const clearAutomations = () => {
  return action(ActionType.CLEAR_AUTOMATIONS);
};

export const requestGetAutomationTemplates = () => {
  return action(ActionType.REQUEST_GET_AUTOMATION_TEMPLATES);
};

export const getAutomationTemplatesSuccess = (
  automationTemplates: AutomationTemplate[]
) => {
  return action(ActionType.GET_AUTOMATION_TEMPLATES_SUCCESS, {
    automationTemplates,
  });
};

export const getAutomationTemplatesFailure = (error: Error) => {
  return action(ActionType.GET_AUTOMATION_TEMPLATES_FAILURE, null, error);
};

export const getDefaultAutomationDefinition =
  createAsyncAction<GetDefaultAutomationDefinition>({
    request: ActionType.GET_DEFAULT_AUTOMATION_DEFINITION_REQUEST,
  })((payload: GetDefaultAutomationDefinition['request']['payload']) => ({
    payload,
  }));

export const getDefaultAutomationDefinitions =
  createAsyncAction<GetDefaultAutomationDefinitions>({
    request: ActionType.GET_DEFAULT_AUTOMATION_DEFINITIONS_REQUEST,
  })((payload: GetDefaultAutomationDefinitions['request']['payload']) => ({
    payload,
  }));

export const updateDefaultAutomation =
  createAsyncAction<UpdateDefaultAutomation>({
    request: ActionType.UPDATE_DEFAULT_AUTOMATION_REQUEST,
  })((payload: UpdateDefaultAutomation['request']['payload']) => ({
    payload,
  }));
