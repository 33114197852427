import { Reducer } from 'redux';

import { ActionType, State } from './pipelineTypes';

export const initialState: State = {
  pipelines: [],
  isPerformingPipelineAction: false,
};

const pipelineReducer: Reducer<State> = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.REQUEST_GET_ACCOUNT_PIPELINES:
    case ActionType.REQUEST_CREATE_PIPELINE:
    case ActionType.REQUEST_UPDATE_PIPELINE:
    case ActionType.REQUEST_DELETE_PIPELINE:
      return {
        ...state,
        isPerformingPipelineAction: true,
      };
    case ActionType.GET_ACCOUNT_PIPELINES_FAILURE:
    case ActionType.CREATE_PIPELINE_FAILURE:
    case ActionType.UPDATE_PIPELINE_FAILURE:
    case ActionType.DELETE_PIPELINE_FAILURE:
    case ActionType.CREATE_PIPELINE_SUCCESS:
      return {
        ...state,
        isPerformingPipelineAction: false,
      };
    case ActionType.GET_ACCOUNT_PIPELINES_SUCCESS:
    case ActionType.UPDATE_PIPELINE_SUCCESS:
    case ActionType.DELETE_PIPELINE_SUCCESS:
      return {
        ...state,
        pipelines: action.payload,
        isPerformingPipelineAction: false,
      };
    case ActionType.CLEAR_PIPELINES:
      return {
        ...state,
        pipelines: [],
        isPerformingPipelineAction: false,
      };
    default:
      return state;
  }
};

export default pipelineReducer;
