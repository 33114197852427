import React, { FC, useEffect, useState } from 'react';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import { useParams } from 'react-router';
import { Field } from '@payaca/components/plField/Field';
import { useUserHasPermission } from '@/hooks/usePermissions';
import { CustomersPermissions } from '@payaca/permissions/customers/customers.permissions';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import UntitledIcon from '@payaca/untitled-icons';
import useGetCustomer from '@/api/queries/customers/useGetCustomer';
import useOptimisticUpdateCustomer from '@/api/mutations/customers/optimistic/useOptimisticUpdateCustomer';

export interface IProps {}

const FileSharingSettings: FC<IProps> = () => {
  const { customerId } = useParams<{ customerId: string }>();

  /**
   * Queries
   */
  const { data: customerData } = useGetCustomer(customerId);

  /**
   * Mutations
   */
  const { mutate: updateCustomer } = useOptimisticUpdateCustomer(customerId);

  /**
   * State
   */
  const [fileShareSettings, setFileShareSettings] = useState({
    clientPortal: false,
    attachToEmails: false,
  });

  const userCanEditClientPortalShareSettings = useUserHasPermission({
    permissions: [CustomersPermissions.CLIENT_PORTAL_SHARE_SETTINGS],
  });

  useEffect(() => {
    if (userCanEditClientPortalShareSettings) {
      setFileShareSettings({
        clientPortal: true,
        attachToEmails:
          customerData?.customer.fileSharingSettings.attachToEmails ?? false,
      });
    } else {
      setFileShareSettings({
        clientPortal: false,
        attachToEmails: true,
      });
    }
  }, [userCanEditClientPortalShareSettings, customerData]);

  const handleAttachToEmailChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    const { checked } = e.target;

    updateCustomer({
      customerId,
      fileSharingSettings: {
        attachToEmails: checked,
      },
    });
  };

  return (
    <div className="prose space-y-2">
      <h4 className="flex-grow">File sharing settings</h4>

      <div className="flex items-center gap-x-2.5">
        <Field name="clientPortal">
          <Checkbox
            label="Client Portal"
            checked={fileShareSettings.clientPortal}
            disabled
          />
        </Field>

        {!userCanEditClientPortalShareSettings && (
          <Tooltip
            className="flex"
            showArrow
            tooltipContent="Upgrade to the business plan for access to the client portal "
          >
            <UntitledIcon name="info-circle.3" className="h-5 w-5" />
          </Tooltip>
        )}
      </div>

      <Field name="attachToEmails">
        <Checkbox
          label="Attach to emails"
          checked={fileShareSettings.attachToEmails}
          disabled={!userCanEditClientPortalShareSettings}
          onChange={handleAttachToEmailChange}
        />
      </Field>
    </div>
  );
};

export default FileSharingSettings;
