import React, { FC, useCallback } from 'react';

import { LoginControl } from '@/ui/components';
import UnauthenticatedPageWrapper from '../pageWrappers/unauthenticatedPageWrapper/UnauthenticatedPageWrapper';
import SocialAuthButtons from '@/ui/components/socialAuthButtons/SocialAuthButtons';

import './LoginPage.sass';
import { marketingSiteUrl } from '@/helpers/urlHelper';

type Props = {
  history: any;
};
const LoginPage: FC<Props> = ({ history }: Props): JSX.Element => {
  const goToRegisterPage = useCallback(() => {
    window.location.href = 'https://payaca.com/try-payaca';
  }, [history]);

  return (
    <UnauthenticatedPageWrapper
      titleContent={
        <div className="title-content-wrapper">
          <h1>Welcome back </h1>
          <a onClick={goToRegisterPage}>No account?</a>
        </div>
      }
      className="login-page"
      footerContent={
        <>
          <div className="social-login-separator-wrapper">
            <span>or log in with</span>
          </div>
          <SocialAuthButtons isLogin={true} />
          <div className="flex justify-center space-x-4 text-white">
            <a
              className="text-white"
              href={marketingSiteUrl('/terms-and-conditions')}
            >
              Terms & Conditions
            </a>
            <span>|</span>
            <a
              className="text-white"
              href={marketingSiteUrl('/privacy-policy')}
            >
              Privacy Policy
            </a>
          </div>
        </>
      }
    >
      <LoginControl />
    </UnauthenticatedPageWrapper>
  );
};

export default LoginPage;
