// ======================================================================================================
// Private routing
// - Routes/redirects accessible to authorised users (role dependent)
// ======================================================================================================

import React, { useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import WebUtil from '@payaca/shared-web/utilities/index';

import {
  getUsersPrivateRoutes,
  getUsersPrivateRedirects,
} from './privateRoutingConfig';
import RoutesAndRedirects from './RoutesAndRedirects';

import { actions as usersActions } from '@/api/users';
import * as accountActions from '@payaca/store/account/accountActions';
import * as userRolesActions from '@payaca/store/userRoles/userRolesActions';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { useLocation } from 'react-router';
import { getIsFetchingUserRoles, getUserRoles } from '@/utils/stateAccessors';
import LoaderOverlay from '@payaca/components/loaderOverlay/LoaderOverlay';

const PrivateRouting = ({
  dismissMobileMode,
  match,
  accountAccessInformation,
  profile,
  users,
  userRolesActions,
}) => {
  const location = useLocation();
  useEffect(() => {
    users.getProfile();
    userRolesActions.requestGetUserRoles();
  }, [users, userRolesActions]);

  useEffect(() => {
    accountActions.requestGetAccountAccessInformation();
  }, [accountActions]);

  const isMobile = useMemo(() => WebUtil.isMobileScreen(), []);
  const isFormsRoute = useMemo(() => {
    return !location.pathname.includes('/forms');
  }, [location]); // forms is web-optimised

  const trialPeriodIsExpired = useMemo(() => {
    if (!accountAccessInformation) return false;
    return (
      !accountAccessInformation.hasActiveSubscription &&
      accountAccessInformation.isTrialPeriodExpired
    );
  }, [accountAccessInformation]);

  const hasExceededUserSeats = useMemo(() => {
    if (!accountAccessInformation) return false;

    return (
      accountAccessInformation.hasActiveSubscription &&
      accountAccessInformation.hasExceededActiveSubscriptionUserSeats
    );
  }, [accountAccessInformation]);

  const isFetchingRoles = useSelector(getIsFetchingUserRoles);
  const userRoles = useSelector(getUserRoles);
  const isAdmin = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      AccountsPermissions.UPDATE_SUBSCRIPTION,
    ]);
  }, [userRoles]);

  const userHasContactNumber = useMemo(() => {
    return !!profile?.contactNumber;
  }, [profile?.contactNumber]);

  const accountIsSetup = useMemo(() => {
    // account exists with legal business name - will catch all users who have registered through web or mobile
    return profile?.accounts?.[0]?.legalBusinessName;
  }, [profile]);

  const hasBetaFeatures = useMemo(() => {
    return accountAccessInformation?.features?.showBetaFeatures;
  }, [accountAccessInformation]);
  const hasDispatchFeature = useMemo(
    () => accountAccessInformation?.features?.dispatch,
    [accountAccessInformation]
  );

  const availableRoutes = useMemo(() => {
    return getUsersPrivateRoutes({
      isAdmin,
      isMobile,
      userRoles,
      userHasContactNumber,
      accountIsSetup,
      trialPeriodIsExpired,
      hasExceededUserSeats,
      hasBetaFeatures,
      isFormsRoute,
      hasDispatchFeature,
    });
  }, [
    isAdmin,
    isMobile,
    hasExceededUserSeats,
    accountIsSetup,
    trialPeriodIsExpired,
    userHasContactNumber,
    userRoles,
    hasBetaFeatures,
    isFormsRoute,
    hasDispatchFeature,
  ]);

  const availableRedirects = useMemo(() => {
    return getUsersPrivateRedirects({
      userRoles,
      isAdmin,
      isMobile,
      accountIsSetup,
      hasExceededUserSeats,
      trialPeriodIsExpired,
      userHasContactNumber,
      isFormsRoute,
    });
  }, [
    userRoles,
    isAdmin,
    isMobile,
    accountIsSetup,
    hasExceededUserSeats,
    trialPeriodIsExpired,
    userHasContactNumber,
    isFormsRoute,
  ]);

  if (isFetchingRoles || !Object.keys(profile).length) {
    return <LoaderOverlay />;
  }

  return (
    <RoutesAndRedirects
      availableRoutes={availableRoutes}
      availableRedirects={availableRedirects}
      matchPath={match.path}
    />
  );
};

export default connect(
  (state) => ({
    dismissMobileMode: state.app.dismissMobileMode,
    profile: state.users.myProfile,
    accountAccessInformation: state.account.accountAccessInformation,
  }),
  (dispatch) => ({
    users: bindActionCreators(usersActions, dispatch),
    accountActions: bindActionCreators(accountActions, dispatch),
    userRolesActions: bindActionCreators(userRolesActions, dispatch),
  })
)(PrivateRouting);
