import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { graphql } from '@/gql';
import { gqlClient } from '@/api/graphql-client';
import { UpdateTagMutation, UpdateTagMutationVariables } from '@/gql/graphql';

const UpdateTag = graphql(`
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      id
    }
  }
`);

const useUpdateTag = (
  options?: UseMutationOptions<
    UpdateTagMutation,
    unknown,
    UpdateTagMutationVariables['input']
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UpdateTag, {
        input,
      });
    },
    ...options,
  });
};

export default useUpdateTag;
