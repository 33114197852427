import { Req } from '@payaca/helpers/storeHelper';

import {
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { handleAsyncAction } from '../utils';
import {
  archiveTimelog,
  archiveTimelogType,
  batchCreateTimelogs,
  clearActiveTimelog,
  clearTimelogsToCreate,
  createTimelog,
  createTimelogType,
  dequeueTimelogsToCreate,
  enqueueTimelogToCreate,
  getAssigneeGroupedListedTimelogs,
  getListedTimelogsPage,
  getTimelogTypes,
  updateTimelog,
  updateTimelogType,
} from './timelogsActions';

import { SagaConfig, ActionType, CreateTimelogData } from './timelogsTypes';

import {
  PublicHydratedTimelog,
  PublicTimelogType,
} from '@payaca/types/timelogs';
import {
  AssigneeGroupedListedTimelogs,
  ListedTimelogsPage,
} from '@payaca/types/listedTimelogTypes';
import { PayloadAction } from 'typesafe-actions';

const timelogsSagaCreator = ({
  apiBaseurl,
  getAuthHeader,
  isNativeApp = false,
}: SagaConfig) => {
  const req = Req(`${apiBaseurl}/api`, getAuthHeader, isNativeApp);

  const handleCreateTimelog = handleAsyncAction(
    createTimelog,
    async (args) => {
      const response = await req.post(`/timelogs`, args.timelog);
      return response.json();
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as PublicHydratedTimelog);
    },
    (_err, requestData) => {
      requestData.payload.onErrorCallback?.(_err);
    }
  );

  const handleBatchCreateTimelogs = handleAsyncAction(
    batchCreateTimelogs,
    async (args) => {
      const response = await req.post(`/timelogs/batch`, args);
      return;
    }
  );

  const handleUpdateTimelog = handleAsyncAction(
    updateTimelog,
    async (args) => {
      const { publicId, ...body } = args.timelog;
      const response = await req.patch(`/timelogs/${publicId}`, body);
      return response.json();
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as PublicHydratedTimelog);
    },
    (_err, requestData) => {
      requestData.payload.onErrorCallback?.(_err);
    }
  );

  const handleGetTimelogTypes = handleAsyncAction(
    getTimelogTypes,
    async (args) => {
      try {
        const response = await req.get(`/timelogs/types`);
        return response.json();
      } catch (err) {
        console.log(`Get timelog types failed: ${JSON.stringify(err)}`);
        throw err;
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as PublicTimelogType[]);
    },
    (_err, requestData) => {
      requestData.payload.onErrorCallback?.(_err);
    }
  );

  const handleCreateTimelogType = handleAsyncAction(
    createTimelogType,
    async (args) => {
      try {
        const response = await req.post(`/timelogs/types`, args.timelogType);
        return response.json();
      } catch (err) {
        console.log(`Create timelog type failed: ${JSON.stringify(err)}`);
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as PublicTimelogType);
    }
  );

  const handleUpdateTimelogType = handleAsyncAction(
    updateTimelogType,
    async (args) => {
      const { publicId, ...body } = args.timelogType;

      try {
        const response = await req.patch(`/timelogs/types/${publicId}`, body);
        return response.json();
      } catch (err) {
        console.log(`Update timelog type failed: ${JSON.stringify(err)}`);
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as PublicTimelogType);
    }
  );

  const handleArchiveTimelogType = handleAsyncAction(
    archiveTimelogType,
    async (args) => {
      try {
        const response = await req.del(
          `/timelogs/types/${args.timelogTypePublicId}`
        );
      } catch (err) {
        console.log(`Archive timelog type failed: ${JSON.stringify(err)}`);
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    }
  );

  const handleArchiveTimelog = handleAsyncAction(
    archiveTimelog,
    async (args) => {
      try {
        const response = await req.del(`/timelogs/${args.timelogPublicId}`);
      } catch (err) {
        console.log(`Archive timelog failed: ${JSON.stringify(err)}`);
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.();
    }
  );

  const handleGetListedTimelogsPage = handleAsyncAction(
    getListedTimelogsPage,
    async (args) => {
      try {
        const response = await req.get(
          `/timelogs/listed-timelogs?pageNumber=${
            args.queryParams.pageNumber
          }&pageSize=${args.queryParams.pageSize}&periodStart=${
            args.queryParams.periodStart?.toISOString() || ''
          }${
            args.queryParams.assignees
              ? `${args.queryParams.assignees
                  .map((assignee, i) => {
                    return `&assignees[${i}][type]=${assignee.type}&assignees[${i}][publicId]=${assignee.publicId}`;
                  })
                  .join('')}`
              : ''
          }&periodEnd=${args.queryParams.periodEnd?.toISOString() || ''}${
            args.queryParams.typePublicIds
              ? `${args.queryParams.typePublicIds
                  .map((typePublicId) => {
                    return `&typePublicIds[]=${typePublicId}`;
                  })
                  .join('')}`
              : ''
          }&sortBy=${args.queryParams.sortBy}&sortDirection=${
            args.queryParams.sortDirection
          }${
            args.queryParams.entity
              ? `&entity[entityId]=${args.queryParams.entity.entityId}&entity[entityType]=${args.queryParams.entity.entityType}`
              : ''
          }`
        );
        return response.json();
      } catch (err) {
        console.log(`Get listed timelogs page failed: ${JSON.stringify(err)}`);
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(_response as ListedTimelogsPage);
    }
  );

  const handleGetAssigneeGroupedListedTimelogs = handleAsyncAction(
    getAssigneeGroupedListedTimelogs,
    async (args) => {
      try {
        const response = await req.get(
          `/timelogs/listed-timelogs/assignees?${
            args.queryParams.assignees
              ? `${args.queryParams.assignees
                  .map((assignee, i) => {
                    return `&assignees[${i}][type]=${assignee.type}&assignees[${i}][publicId]=${assignee.publicId}`;
                  })
                  .join('')}`
              : ''
          }&periodStart=${
            args.queryParams.periodStart?.toISOString() || ''
          }&periodEnd=${args.queryParams.periodEnd?.toISOString() || ''}${
            args.queryParams.typePublicIds
              ? `${args.queryParams.typePublicIds
                  .map((typePublicId) => {
                    return `&typePublicIds[]=${typePublicId}`;
                  })
                  .join('')}`
              : ''
          }${
            args.queryParams.entity
              ? `&entity[entityId]=${args.queryParams.entity.entityId}&entity[entityType]=${args.queryParams.entity.entityType}`
              : ''
          }`
        );
        return response.json();
      } catch (err) {
        console.log(
          `Get assignee grouped listed timelogs failed: ${JSON.stringify(err)}`
        );
      }
    },
    (_response, requestData) => {
      requestData.payload.callback?.(
        _response as AssigneeGroupedListedTimelogs
      );
    }
  );

  function* handleBatchCreateQueuedTimelogs(
    action: PayloadAction<
      ActionType.BATCH_CREATE_QUEUED_TIMELOGS_REQUEST,
      {
        timelogsToCreate: {
          timelog: CreateTimelogData;
          identifier: string;
        }[];
      }
    >
  ) {
    const timelogsToCreate = action.payload.timelogsToCreate;

    yield put(
      dequeueTimelogsToCreate.request({
        identifiers: timelogsToCreate.map(
          (timelogToCreate) => timelogToCreate.identifier
        ),
      })
    );

    // don't care about success or failure - assume this will succeed
    yield put(
      batchCreateTimelogs.request(
        timelogsToCreate.map((timelogToCreate) => timelogToCreate.timelog)
      )
    );
  }

  function* handleLogout() {
    yield put(clearActiveTimelog.request());
    yield put(clearTimelogsToCreate.request());
  }

  return function* () {
    yield takeEvery(
      ActionType.GET_LISTED_TIMELOGS_PAGE_REQUEST,
      handleGetListedTimelogsPage
    );
    yield takeEvery(
      ActionType.GET_ASSIGNEE_GROUPED_LISTED_TIMELOGS_REQUEST,
      handleGetAssigneeGroupedListedTimelogs
    );
    yield takeEvery(ActionType.ARCHIVE_TIMELOG_REQUEST, handleArchiveTimelog);
    yield takeEvery(ActionType.CREATE_TIMELOG_REQUEST, handleCreateTimelog);
    yield takeLatest(
      ActionType.BATCH_CREATE_TIMELOGS_REQUEST,
      handleBatchCreateTimelogs
    );
    yield takeEvery(ActionType.UPDATE_TIMELOG_REQUEST, handleUpdateTimelog);
    yield takeEvery(
      ActionType.GET_TIMELOG_TYPES_REQUEST,
      handleGetTimelogTypes
    );
    yield takeLatest(
      ActionType.UPDATE_TIMELOG_TYPE_REQUEST,
      handleUpdateTimelogType
    );
    yield takeLatest(
      ActionType.CREATE_TIMELOG_TYPE_REQUEST,
      handleCreateTimelogType
    );
    yield takeEvery(
      ActionType.ARCHIVE_TIMELOG_TYPE_REQUEST,
      handleArchiveTimelogType
    );
    yield takeLatest(
      ActionType.BATCH_CREATE_QUEUED_TIMELOGS_REQUEST,
      handleBatchCreateQueuedTimelogs
    );
    yield takeLatest('auth.logout', handleLogout);
  };
};

export default timelogsSagaCreator;
