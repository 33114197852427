import { getSupplier } from '@/utils/stateAccessors';

import { formatPhoneNumber } from '@payaca/helpers/phoneNumberHelper';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Call, Location, Message } from 'react-iconly';

import { SupplierAddress } from '@payaca/types/supplierTypes';
import './SupplierContactInformation.sass';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import GlobeIcon from '@payaca/components/svgAssets/GlobeIcon';
import { useSelector } from '@/api/state';

type Props = {
  supplierId: number;
};
const SupplierContactInformation: FC<Props> = ({
  supplierId,
}: Props): JSX.Element | null => {
  const supplier = useSelector((state) => getSupplier(state, supplierId));

  const supplierTelephoneElement = useMemo(() => {
    if (supplier?.phoneNumber?.length) {
      const formattedTelephoneNumber = formatPhoneNumber(supplier.phoneNumber);
      return (
        <div className="supplier-information-element">
          <a
            href={`tel:${formattedTelephoneNumber}`}
            target="_blank"
            rel="noreferrer"
          >
            <Call set="light" size="small" />
            <span>{formattedTelephoneNumber}</span>
          </a>
        </div>
      );
    }
  }, [supplier]);

  const supplierEmailElement = useMemo(() => {
    if (supplier?.emailAddress?.length) {
      return (
        <div className="supplier-information-element">
          <a
            href={`mailto:${supplier.emailAddress}`}
            target="_blank"
            rel="noreferrer"
          >
            <Message set="light" size="small" />
            <span>{supplier.emailAddress}</span>
          </a>
        </div>
      );
    }
  }, [supplier]);

  const supplierWebsiteUrlElement = useMemo(() => {
    if (supplier?.websiteUrl?.length) {
      let navigateTo = supplier.websiteUrl;
      try {
        const u = new URL(supplier.websiteUrl);
      } catch (error) {
        navigateTo = `//${supplier.websiteUrl}`;
      }

      return (
        <div className="supplier-information-element">
          <a href={navigateTo} target="_blank" rel="noreferrer">
            <GlobeIcon />
            <span>{supplier.websiteUrl}</span>
          </a>
        </div>
      );
    }
  }, [supplier]);

  const renderSupplierAddressElement = useCallback(
    (supplierAddress: SupplierAddress, index: number) => {
      const addressAsString = getAddressAsString(supplierAddress.address);

      if (!addressAsString?.length) return;

      return (
        <div className="supplier-information-element">
          <a
            href={`https://maps.google.com/?q=${addressAsString}`}
            target="_blank"
            rel="noreferrer"
          >
            <Location set="light" size="small" />
            <span>
              {supplierAddress.description &&
                `${supplierAddress.description} | `}
              {addressAsString}
            </span>
          </a>
        </div>
      );
    },
    []
  );

  if (!supplier) return null;
  return (
    <div className="supplier-contact-information">
      {supplierEmailElement}
      {supplierTelephoneElement}
      {supplierWebsiteUrlElement}
      {supplier.addresses?.map(renderSupplierAddressElement)}
    </div>
  );
};

export default SupplierContactInformation;
