import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import './CreateTimelogControl.sass';
import Button from '@payaca/components/plButton/Button';
import {
  FieldValidationResult,
  FieldValidator,
} from '@payaca/types/fieldValidationTypes';
import { CreateTimelogData } from '@payaca/store/timelogs/timelogsTypes';
import { useSelector } from '@/api/state';
import { PublicHydratedTimelog } from '@payaca/types/timelogs';
import { createTimelog } from '@payaca/store/timelogs/timelogsActions';
import moment from 'moment-timezone';
import PersistTimelogFields from '../persistTimelogFields/PersistTimelogFields';
import {
  getAllowEmptyValidator,
  getDateRangeFieldValidator,
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import ValidationFeedbackBlock from '@payaca/components/validationFeedbackBlock/ValidationFeedbackBlock';
import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { getDurationValidator } from '@payaca/helpers/timelogValidationHelper';
import Modal from '@payaca/components/plModal/Modal';

type Props = {
  initialFormState: Partial<
    Pick<
      CreateTimelogData,
      'startTime' | 'primaryLinkedEntity' | 'assigneePublicId' | 'assigneeType'
    > & {
      duration?: string;
    }
  >;
  onCreateTimelogSuccess?: (timelog: PublicHydratedTimelog) => void;
};

const CreateTimelogControl: FunctionComponent<Props> = ({
  initialFormState: ifs,
  onCreateTimelogSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: any) => state.users.myProfile);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showValidation, setShowValidation] = useState(false);

  const validators: { [key: string]: FieldValidator[] } = {
    startTime: [
      getIsRequiredFieldValidator(),
      getDateRangeFieldValidator(undefined, new Date()),
    ],
    duration: [getDurationValidator('startTime')],
    notes: [getAllowEmptyValidator(getLengthFieldValidator({ max: 10000 }))],
  };

  const initialFormState = useMemo(() => {
    return {
      assigneePublicId: currentUser.id.toString(),
      assigneeType: 'user',
      costPerHour: currentUser.costPerHour,
      ...ifs,
    };
  }, [ifs, currentUser]);

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      setErrorMessage(undefined);
      setIsProcessing(true);
      dispatch(
        createTimelog.request({
          timelog: {
            startTime: formState.startTime,
            endTime: moment(formState.startTime)
              .add(formState.duration)
              .toDate(),
            notes: formState.notes,
            costPerHour: formState.costPerHour,
            typePublicId: formState.typePublicId,
            assigneePublicId: formState.assigneePublicId.toString(),
            assigneeType: formState.assigneeType,
            primaryLinkedEntity: formState.primaryLinkedEntity,
          },
          callback: (timelog) => {
            setIsProcessing(false);
            onCreateTimelogSuccess?.(timelog);
          },
          onErrorCallback: (error) => {
            setIsProcessing(false);
            setErrorMessage('Something went wrong');
          },
        })
      );
    },
    [onCreateTimelogSuccess]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <Modal.Body>
            <PersistTimelogFields
              touchedState={touchedState}
              onFieldChange={onFieldChange}
              onFieldTouch={onFieldTouch}
              formState={formState}
              validationState={validationState}
              persistType="create"
            />
          </Modal.Body>

          <Modal.Footer className="flex flex-col gap-4">
            {errorMessage && (
              <FeedbackBlock feedbackLevel={FeedbackLevel.ERROR}>
                {errorMessage}
              </FeedbackBlock>
            )}
            {showValidation && !isValid && (
              <ValidationFeedbackBlock
                validationResults={Object.values(validationState)}
              />
            )}

            <Modal.Footer.Actions>
              <Button
                onClick={() => {
                  isValid ? onSubmit(formState) : setShowValidation(true);
                }}
                isProcessing={isProcessing}
              >
                Submit
              </Button>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </>
      );
    },
    [isProcessing, onSubmit, errorMessage, showValidation]
  );

  return (
    <ResponsiveViewWrapper
      downBreakpointSm={700}
      downBreakpointXs={500}
      className="create-timelog-control"
    >
      <ValidatedForm<{ [key: string]: any }>
        fieldValidators={validators}
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
      />
    </ResponsiveViewWrapper>
  );
};

export default CreateTimelogControl;
