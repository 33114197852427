import {
  faExclamationTriangle,
  faFilter,
  faImage,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownField from '../dropdownField/DropdownField';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import './IconTooltip.sass';
import TooltipUI from '@material-ui/core/Tooltip';

type Props = {
  iconDefinition: IconDefinition;
  tooltipText: string;
  className?: string;
};

const IconTooltip: FunctionComponent<Props> = ({
  iconDefinition,
  tooltipText,
  className,
}: Props): JSX.Element | null => {
  return (
    <TooltipUI title={tooltipText} arrow placement="top">
      <div className={`icon-tooltip ${className ? className : ''}`}>
        <FontAwesomeIcon className="alert-icon" icon={iconDefinition} />
      </div>
    </TooltipUI>
  );
};

export default IconTooltip;

type AlertTooltipProps = Omit<Props, 'iconDefinition'>;

export const AlertTooltip: FunctionComponent<AlertTooltipProps> = ({
  tooltipText,
  className,
}: AlertTooltipProps): JSX.Element | null => {
  return (
    <IconTooltip
      tooltipText={tooltipText}
      iconDefinition={faExclamationTriangle}
      className={`alert-tooltip ${className ? className : ''}`}
    />
  );
};
