import React, { FC } from 'react';

import useCreateCustomer, {
  CreateCustomerMutationOptions,
} from '@/api/mutations/customers/useCreateCustomer';
import CreateCustomerModal, {
  IProps as ICreateCustomerModalProps,
} from '@/ui/components/createCustomerModal/CreateCustomerModal';

export interface IProps extends ICreateCustomerModalProps {
  mutationOptions?: CreateCustomerMutationOptions;
}

const CreateCustomerModalMutation: FC<IProps> = (props) => {
  const { mutationOptions, onSubmit, isProcessing, ...rest } = props;

  /**
   * Mutations
   */
  const {
    mutateAsync: createCustomerMutation,
    isLoading: isCreatingCustomerMutationLoading,
  } = useCreateCustomer(mutationOptions);

  return (
    <CreateCustomerModal
      isProcessing={isProcessing || isCreatingCustomerMutationLoading}
      onSubmit={(formState, e) => {
        createCustomerMutation({
          name: formState.name,
          contacts: [
            {
              name: formState.name,
              email: formState.email,
              phone: formState.phone,
              isPrimary: true,
            },
          ],
          addresses:
            !!formState.address?.line1 ||
            !!formState.address?.line2 ||
            !!formState.address?.city ||
            !!formState.address?.postalCode ||
            !!formState.address?.country
              ? [formState.address]
              : undefined,
        });

        onSubmit?.(formState, e);
      }}
      {...rest}
    />
  );
};

export default CreateCustomerModalMutation;
