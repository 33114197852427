import React from 'react';
import WifiOffIcon from '@material-ui/icons/WifiOff';

import './OfflineBanner.sass';

const OfflineBanner = () => {
  if (!navigator.onLine) {
    return (
      <div className="offline-banner">
        <WifiOffIcon />
        Looks like you&#39;re offline, please check your connection. Your Payaca
        experience may be affected until you&#39;re back online.
      </div>
    );
  }
  return null;
};

export default OfflineBanner;
