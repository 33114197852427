import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';

import {
  getDeal,
  getInvoiceLinesByDealId,
  getInvoicesByDealId,
  getRegion,
} from '@/utils/stateAccessors';
import {
  useAcceptedJobLineItemsForDeal,
  usePaymentsForDeal,
  useProposalsForDeal,
} from '@payaca/store/hooks/appState';
import * as invoiceActions from '@payaca/store/invoices/invoicesActions';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import SectionHeader from '@payaca/components/sectionHeader/SectionHeader';
import {
  acceptedProposalOnDeal,
  canCreateInvoiceForDeal,
} from '@payaca/helpers/dealHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getLatestDateFromJobPayment } from '@payaca/helpers/jobPaymentHelper';
import { InvoicesPermissions } from '@payaca/permissions/invoices/invoices.permissions';
import { JobPermissions } from '@payaca/permissions/jobs/job.permissions';
import { PaymentsPermissions } from '@payaca/permissions/payments/payments.permissions';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';
import {
  requestGetInvoicesForDeal,
  requestGetPaymentReconciliationRecordsForDeal,
} from '@payaca/store/invoices/invoicesActions';
import { requestGetJobPaymentsForDeal } from '@payaca/store/jobPayments/jobPaymentsActions';
import DealPaymentReconciliationTable from '../dealPaymentReconciliationTable/DealPaymentReconciliationTable';
import InvoiceCard from '../invoiceCard/InvoiceCard';
import PaymentCard from '../paymentCard/PaymentCard';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import RecordPaymentModal from '../recordPaymentModal/RecordPaymentModal';
import './DealInvoicesPayments.sass';
import { useSelector } from '@/api/state';
import CreateInvoiceModal from '../createInvoiceModal/CreateInvoiceModal';
import * as jobsActions from '@payaca/store/jobs/jobsActions';
import ProtoInvoiceCard from '../protoInvoiceCard/ProtoInvoiceCard';
import projectKeys from '@/api/queries/project/keyFactory';

interface Props {
  dealId: number;
  onDealUpdateSuccess: (updatedJobIds?: number[]) => void;
}

export const DealInvoicesPayments: FC<Props> = ({
  dealId,
  onDealUpdateSuccess,
}: Props) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [showRecordPaymentModal, setShowRecordPaymentModal] = useState(false);
  const [showPaymentReconciliationTable, setShowPaymentReconciliationTable] =
    useState(false);
  const [isCreatingProtoInvoice, setIsCreatingProtoInvoice] = useState(false);

  const region = useSelector((state) => getRegion(state));

  const acceptedJobLineItems = useAcceptedJobLineItemsForDeal(dealId);

  const deal = useSelector((state) => {
    return getDeal(state, dealId);
  });

  const payments = usePaymentsForDeal(dealId);

  const proposals = useProposalsForDeal(dealId);
  const invoiceLines = useSelector((state) => {
    return getInvoiceLinesByDealId(state, dealId);
  });

  const invoices = useSelector((state) => {
    return getInvoicesByDealId(state, dealId);
  });

  useEffect(() => {
    dispatch(invoiceActions.requestGetInvoicesForDeal(dealId));
    dispatch(
      invoiceActions.requestGetPaymentReconciliationRecordsForDeal(dealId)
    );
    dispatch(invoiceActions.requestGetInvoiceLinesForDeal(dealId));
  }, [dealId]);

  const sortedPayments = useMemo(() => {
    if (!payments) return [];

    return payments.sort((p1, p2) => {
      const p1Date = getLatestDateFromJobPayment(p1);
      const p2Date = getLatestDateFromJobPayment(p2);

      return p1Date < p2Date ? 1 : -1;
    });
  }, [payments]);

  const pendingPayments = useMemo(() => {
    return sortedPayments.filter(
      (x) => !x.paymentFailedConfirmationAt && !x.paymentCompletedConfirmationAt
    );
  }, [sortedPayments]);

  const confirmedPayments = useMemo(() => {
    return sortedPayments.filter(
      (x) =>
        !!x.paymentFailedConfirmationAt || !!x.paymentCompletedConfirmationAt
    );
  }, [sortedPayments]);

  const canCreateInvoice = useMemo(() => {
    return (
      !deal?.archivedAt &&
      canCreateInvoiceForDeal(acceptedJobLineItems, invoices, invoiceLines)
    );
  }, [deal, invoices, invoiceLines, acceptedJobLineItems]);

  const canCreateProtoInvoice = useMemo(() => {
    const hasCurrentProposal = proposals?.find(
      (p) => !p.inactivatedAt && !p.archivedAt
    );
    return !hasCurrentProposal && deal && deal?.version > 1;
  }, [proposals, deal?.version]);

  const onRecordOrConfirmPaymentSuccess = useCallback(() => {
    dispatch(requestGetDeal(dealId));
    dispatch(requestGetInvoicesForDeal(dealId));
    dispatch(requestGetJobPaymentsForDeal(dealId));
    dispatch(requestGetPaymentReconciliationRecordsForDeal(dealId));
    void queryClient.invalidateQueries({
      queryKey: projectKeys.project(dealId),
    });
    void queryClient.invalidateQueries({
      queryKey: projectKeys.profitBreakdown(dealId),
    });
  }, [dispatch, dealId, queryClient]);

  const dealHasAcceptedProposal = useMemo(
    () => acceptedProposalOnDeal(proposals),
    [proposals]
  );

  const protoInvoice = proposals?.find(
    (p) => p.isProtoInvoice && !p.inactivatedAt && !p.archivedAt && !p.sentAt
  );

  if (!deal) return null;

  return (
    <div className="deal-invoices-payments">
      <PermissionGuard
        renderIfHasPermissions={[InvoicesPermissions.GET_INVOICES]}
      >
        <section className="invoices">
          <SectionHeader
            headerComponent={<h3>Invoices</h3>}
            actionsComponent={
              <>
                {dealHasAcceptedProposal && canCreateInvoice && (
                  <PermissionGuard
                    renderIfHasPermissions={[
                      InvoicesPermissions.CREATE_INVOICE,
                    ]}
                  >
                    <div>
                      <Button
                        styleVariant={ButtonStyleVariant.ANCHOR}
                        onClick={() => {
                          setShowCreateInvoiceModal(true);
                        }}
                      >
                        Create Invoice
                      </Button>
                    </div>
                  </PermissionGuard>
                )}
                {canCreateProtoInvoice && (
                  <PermissionGuard
                    renderIfHasPermissions={[
                      InvoicesPermissions.CREATE_INVOICE,
                      JobPermissions.ADD_JOB,
                    ]}
                  >
                    <div>
                      <Button
                        styleVariant={ButtonStyleVariant.ANCHOR}
                        isProcessing={isCreatingProtoInvoice}
                        onClick={() => {
                          if (isCreatingProtoInvoice) return;
                          setIsCreatingProtoInvoice(true);
                          dispatch(
                            jobsActions.requestCreateJob(
                              true,
                              dealId,
                              (error, response) => {
                                setIsCreatingProtoInvoice(false);

                                if (!error) {
                                  history.push(
                                    `/deals/${dealId}/invoices/quick/${response.id}/edit`
                                  );
                                }
                              },
                              true
                            )
                          );
                        }}
                      >
                        Create Invoice
                      </Button>
                    </div>
                  </PermissionGuard>
                )}
              </>
            }
          />
          {!!proposals?.filter((x) => !x.inactivatedAt && !x.archivedAt)
            ?.length &&
            !dealHasAcceptedProposal &&
            !protoInvoice && (
              <p className="accept-proposal-prompt-message">
                You need an accepted{' '}
                <Button
                  styleVariant={ButtonStyleVariant.ANCHOR}
                  onClick={() => history.push(`/deals/${dealId}/proposals`)}
                >
                  Proposal
                </Button>{' '}
                to create an Invoice.
              </p>
            )}
          {dealHasAcceptedProposal && (
            <div className="summary-wrapper">
              <dl className="invoices-summary-data">
                <div>
                  <dt>Invoiced</dt>
                  <dd>{currencyPrice(deal.sentInvoicedValue, region)}</dd>
                </div>
                <div className="divider" />
                <div>
                  <dt>To Invoice</dt>
                  <dd>
                    {currencyPrice(
                      deal.acceptedValue - deal.sentInvoicedValue,
                      region
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          )}
          {(!!deal?.invoiceIds?.length || protoInvoice) && (
            <ul className="invoices-list">
              {protoInvoice && (
                <li>
                  <ProtoInvoiceCard
                    protoInvoiceId={protoInvoice.id}
                    onClick={() => {
                      history.push(
                        `/deals/${dealId}/invoices/quick/${protoInvoice.id}/edit`
                      );
                    }}
                  />
                </li>
              )}
              {deal?.invoiceIds.map((invoiceId, i) => {
                return (
                  <li key={i}>
                    <InvoiceCard
                      onRecordPaymentSuccess={onRecordOrConfirmPaymentSuccess}
                      invoiceId={invoiceId}
                      onClick={() =>
                        history.push(`/deals/${dealId}/invoices/${invoiceId}`)
                      }
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </section>
      </PermissionGuard>

      <PermissionGuard
        renderIfHasPermissions={[PaymentsPermissions.GET_PAYMENTS]}
      >
        <section className="payments">
          <SectionHeader
            headerComponent={<h3>Payments</h3>}
            actionsComponent={
              dealHasAcceptedProposal && (
                <PermissionGuard
                  renderIfHasPermissions={[PaymentsPermissions.RECORD_PAYMENT]}
                >
                  <div>
                    <Button
                      styleVariant={ButtonStyleVariant.ANCHOR}
                      onClick={() => setShowRecordPaymentModal(true)}
                    >
                      Record Payment
                    </Button>
                  </div>
                </PermissionGuard>
              )
            }
          />

          {!dealHasAcceptedProposal ? (
            <p className="accept-proposal-prompt-message">
              You need an accepted{' '}
              <Button
                styleVariant={ButtonStyleVariant.ANCHOR}
                onClick={() => history.push(`/deals/${dealId}/proposals`)}
              >
                Proposal
              </Button>{' '}
              or a sent Invoice to record a Payment.
            </p>
          ) : (
            <>
              <div className="summary-wrapper">
                <dl className="payments-summary-data">
                  <div>
                    <dt>Received</dt>
                    <dd>{currencyPrice(deal.completedPaymentValue, region)}</dd>
                  </div>
                  <div className="divider" />

                  <div>
                    <dt>Due</dt>
                    <dd>{currencyPrice(deal.dueValue, region)}</dd>
                  </div>
                </dl>
              </div>
              {/* todo sort job payments */}
              {!!deal?.jobPaymentIds?.length && (
                <ul className="payments-list">
                  {pendingPayments.map((payment, i) => {
                    return (
                      <li key={`pending-payment-${i}`}>
                        <PaymentCard
                          paymentId={payment.id}
                          onConfirmPaymentSuccess={
                            onRecordOrConfirmPaymentSuccess
                          }
                        />
                      </li>
                    );
                  })}
                  {confirmedPayments.map((payment, i) => {
                    return (
                      <li key={`confirmed-payment-${i}`}>
                        <PaymentCard
                          paymentId={payment.id}
                          onConfirmPaymentSuccess={
                            onRecordOrConfirmPaymentSuccess
                          }
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
          )}
        </section>
      </PermissionGuard>

      {!!confirmedPayments?.length && (
        <PermissionGuard
          renderIfHasPermissions={[
            InvoicesPermissions.GET_PAYMENT_RECONCILIATION_RECORDS,
          ]}
        >
          <section className="payment-reconciliation">
            {!showPaymentReconciliationTable && (
              <div>
                <Button
                  styleVariant={ButtonStyleVariant.ANCHOR}
                  onClick={() => setShowPaymentReconciliationTable(true)}
                >
                  Show payment reconciliation details
                </Button>
              </div>
            )}

            {showPaymentReconciliationTable && (
              <>
                <SectionHeader
                  headerComponent={<h3>Payment reconciliation</h3>}
                />
                <DealPaymentReconciliationTable dealId={dealId} />
              </>
            )}
          </section>
        </PermissionGuard>
      )}

      <RecordPaymentModal
        dealId={dealId}
        isOpen={showRecordPaymentModal}
        onClose={() => setShowRecordPaymentModal(false)}
        recordPaymentCallback={onRecordOrConfirmPaymentSuccess}
        confirmPaymentCallback={onRecordOrConfirmPaymentSuccess}
      />
      <CreateInvoiceModal
        dealId={dealId}
        isOpen={showCreateInvoiceModal}
        onClose={() => setShowCreateInvoiceModal(false)}
        onCreateInvoiceSuccess={(invoiceId) => {
          history.push(`/deals/${dealId}/invoices/${invoiceId}`);
        }}
      />
    </div>
  );
};
