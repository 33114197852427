import React, {
  FC,
  useMemo,
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from 'react';

import {
  HydratedMaterialPurchaseIntent,
  HydratedPurchaseOrder,
  MaterialPurchaseIntent,
} from '@payaca/types/materialsListTypes';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonColourVariant } from '@payaca/components/button/enums';
import TextareaField from '@payaca/components/textareaField/TextareaField';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import SearchMaterialsDrawer from '../searchMaterialsDrawer/SearchMaterialsDrawer';
import { UpdateIsolatedPurchaseOrderRequestData } from '@payaca/store/materialsList/materialsListTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { useDispatch } from 'react-redux';
import { updateIsolatedPurchaseOrder } from '@payaca/store/materialsList/materialsListActions';
import ImageBlock from '@payaca/components/imageBlock/ImageBlock';
import { InputWidth } from '@payaca/components/inputWrapper/InputWrapper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import IconButton from '@payaca/components/button/IconButton';
import { useSelector } from '@/api/state';
import { getRegion } from '@/utils/stateAccessors';
import { LabelPositionVariant } from '@payaca/components/basicField/BasicField';
import EmptyState from '@payaca/components/emptyState/EmptyState';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import {
  DynamicFeedbackLifespanMs,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import Field from '@payaca/components/plField/Field';
import { DateInput } from '../dateInput/DateInput';
import { PurchaseOrderDeliveryAddressControl } from '../purchaseOrderDeliveryAddressControl/PurchaseOrderDeliveryAddressControl';
import { Textarea } from '@payaca/components/plTextarea/Textarea';
import useCreateAddress from '@/api/mutations/addresses/useCreateAddress';
import { IsolatedPurchaseOrderDeliveryAddressControl } from '../purchaseOrderDeliveryAddressControl/IsolatedPurchaseOrderDeliveryAddressControl';
import { useQueryClient } from '@tanstack/react-query';
import purchaseOrderKeys from '@/api/queries/purchase-orders/keyFactory';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';

type Props = {
  purchaseOrder: HydratedPurchaseOrder;
  onPreviewPurchaseOrderClick?: () => void;
};

const IsolatedPurchaseOrderControl: FC<Props> = ({
  purchaseOrder,
  onPreviewPurchaseOrderClick,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);
  const clearDynamicFeedbackMessage = useRef<() => void>();

  const [updatePurchaseOrder, setUpdatedPurchaseOrder] =
    useState(purchaseOrder);

  const queryClient = useQueryClient();

  const initialFormState = useMemo(() => {
    return {
      additionalNotes: purchaseOrder.additionalNotes,
      materialPurchaseIntents: purchaseOrder.materialPurchaseIntents.map(
        (mpi) => {
          return {
            id: mpi.id,
            materialQuantity: mpi.materialQuantity,
          };
        }
      ),
      dueAt: purchaseOrder.dueAt ? new Date(purchaseOrder.dueAt) : undefined,
      deliveryAddressId: purchaseOrder.deliveryAddressId,
      showMaterialPrices: purchaseOrder.showMaterialPrices,
    };
  }, []);

  const onSubmit = useCallback(
    async (
      isValid: boolean,
      updateData: UpdateIsolatedPurchaseOrderRequestData,
      callback?: (purchaseOrder: HydratedPurchaseOrder) => void
    ) => {
      clearDynamicFeedbackMessage.current?.();

      dispatch(
        updateIsolatedPurchaseOrder.request({
          payload: {
            ...updateData,
            purchaseOrderId: purchaseOrder.id,
          },
          callback: (purchaseOrder: HydratedPurchaseOrder) => {
            // invalidate query if deliveryaddressid was passed
            if (updateData.deliveryAddressId) {
              queryClient.invalidateQueries({
                queryKey: purchaseOrderKeys.purchaseOrderAddress(
                  purchaseOrder.id
                ),
              });
            }
            clearDynamicFeedbackMessage.current = showDynamicFeedbackMessage({
              title: `Your changes have been saved`,
              isCancellable: true,
              feedbackLevel: FeedbackLevel.SUCCESS,
              lifespanMs: DynamicFeedbackLifespanMs.SHORT,
            });
            setUpdatedPurchaseOrder(purchaseOrder);
            callback?.(purchaseOrder);
          },
          onErrorCallback: () => {
            clearDynamicFeedbackMessage.current = showDynamicFeedbackMessage({
              title: `We couldn't save your changes`,
              isCancellable: true,
              feedbackLevel: FeedbackLevel.ERROR,
            });
          },
        })
      );
    },
    []
  );

  return (
    <>
      <ValidatedForm<UpdateIsolatedPurchaseOrderRequestData>
        initialFormState={initialFormState}
        fieldValidators={{}}
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => (
          <FormContents
            isValid={isValid}
            formState={formState}
            validationState={validationState}
            touchedState={touchedState}
            onFieldChange={onFieldChange}
            onFieldTouch={onFieldTouch}
            onSubmit={onSubmit}
            isSubmitting={false}
            purchaseOrder={updatePurchaseOrder}
            onPreviewPurchaseOrderClick={onPreviewPurchaseOrderClick}
          />
        )}
      />
    </>
  );
};

const FormContents: FC<{
  isValid: boolean;
  formState: UpdateIsolatedPurchaseOrderRequestData;
  validationState: {
    [key: string]: FieldValidationResult;
  };
  touchedState: {
    [key: string]: boolean;
  };
  onFieldChange: (value: { [key: string]: any }) => void;
  onFieldTouch: (fieldName: string) => void;
  onSubmit: (
    isValid: boolean,
    formState: UpdateIsolatedPurchaseOrderRequestData,
    callback?: (purchaseOrder: HydratedPurchaseOrder) => void
  ) => void;
  isSubmitting: boolean;
  errorMessage?: string;
  purchaseOrder: HydratedPurchaseOrder;
  onPreviewPurchaseOrderClick?: () => void;
}> = ({
  isValid,
  formState,
  validationState,
  touchedState,
  onFieldChange,
  onFieldTouch,
  onSubmit,
  isSubmitting,
  errorMessage,
  purchaseOrder,
  onPreviewPurchaseOrderClick,
}) => {
  const [showSearchMaterialsDrawer, setShowSearchMaterialsDrawer] =
    useState(false);
  const [requiresSave, setRequiresSave] = useState(false);

  const region = useSelector(getRegion);

  useEffect(() => {
    if (requiresSave) {
      setRequiresSave(false);
      onSubmit(isValid, formState);
    }
  }, [requiresSave]);

  const { totalMaterialQuantity, predictedTotalPriceExcludingTax } =
    useMemo(() => {
      return purchaseOrder.materialPurchaseIntents.reduce(
        (
          acc: {
            totalMaterialQuantity: number;
            predictedTotalPriceExcludingTax: number;
          },
          item
        ) => {
          acc.totalMaterialQuantity += item.materialQuantity;
          acc.predictedTotalPriceExcludingTax +=
            item.predictedTotalPriceExcludingTax;
          return acc;
        },
        {
          totalMaterialQuantity: 0,
          predictedTotalPriceExcludingTax: 0,
        }
      );
    }, [purchaseOrder.materialPurchaseIntents]);

  const existingMaterialIds = purchaseOrder.materialPurchaseIntents.map(
    (mpi: HydratedMaterialPurchaseIntent) => mpi.material.id
  );

  return (
    <div className="flex flex-col items-start gap-8">
      <div className="flex flex-wrap gap-8">
        <div className="min-w-[30rem] max-w-[80rem] shrink grow-[2] basis-0">
          <Card sizeVariant={CardSizeVariant.SM}>
            <Card.Body>
              <div className="flex gap-4">
                <div className="shrink grow">
                  <Card.Title>Materials required</Card.Title>
                  <p className="my-4 text-base">
                    Browse existing materials linked with{' '}
                    {purchaseOrder.supplier.name} or add new ones
                  </p>
                </div>
                <div className="shrink-0">
                  <Button
                    iconAfter={faPlus}
                    onClick={() => setShowSearchMaterialsDrawer(true)}
                    isOutlined={!!purchaseOrder.materialPurchaseIntents.length}
                  >
                    Add materials
                  </Button>
                </div>
              </div>
              {!formState.materialPurchaseIntents?.length && (
                <EmptyState>
                  <EmptyState.Image />
                  <EmptyState.Body>Nothing to see here yet</EmptyState.Body>
                </EmptyState>
              )}
              <ul className="m-0 list-none p-0">
                {formState.materialPurchaseIntents?.map((mpi) => {
                  const hydratedMpi =
                    purchaseOrder.materialPurchaseIntents.find(
                      (x) => x.id === mpi.id
                    );

                  if (!hydratedMpi) return;

                  return (
                    <li
                      key={`mpi-${mpi.id}`}
                      className="border-b py-2.5 last:border-b-0"
                    >
                      <MaterialPurchaseIntentControl
                        materialPurchaseIntent={{
                          ...hydratedMpi,
                          materialQuantity: mpi.materialQuantity,
                        }}
                        onRemove={() => {
                          const newMpis =
                            formState.materialPurchaseIntents.filter(
                              (mpi2) => mpi2.id !== mpi.id
                            );
                          onFieldChange({
                            materialPurchaseIntents: newMpis,
                          });
                          setRequiresSave(true);
                        }}
                        onQuantityChange={(materialQuantity) => {
                          const newMpis = formState.materialPurchaseIntents.map(
                            (mpi2) =>
                              mpi2.id !== mpi.id
                                ? mpi2
                                : { ...mpi2, materialQuantity }
                          );
                          onFieldChange({
                            materialPurchaseIntents: newMpis,
                          });
                        }}
                        onQuantityChangeTimeout={() => {
                          setRequiresSave(true);
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
              {!!totalMaterialQuantity && (
                <div className="flex">
                  <div className="mt-4 flex w-auto flex-col items-end text-base">
                    <div>
                      Predicted cost ({totalMaterialQuantity} items):{' '}
                      <strong>
                        {currencyPrice(predictedTotalPriceExcludingTax, region)}
                      </strong>
                    </div>
                    <div className="text-gray-500">Exc tax</div>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
        <div className="min-w-[30rem] max-w-[80rem] shrink grow-[1] basis-0">
          <Card sizeVariant={CardSizeVariant.SM}>
            <Card.Body>
              <div className="flex flex-col gap-4">
                <Field name="dueAt">
                  <Field.Label>Due by</Field.Label>
                  <DateInput
                    value={formState.dueAt}
                    onChange={(value) => {
                      onFieldChange({ dueAt: value });
                      onFieldTouch('dueAt');
                      setRequiresSave(true);
                    }}
                  />
                </Field>

                <Field name="deliveryAddressId">
                  <Field.Label>Deliver to</Field.Label>
                  <IsolatedPurchaseOrderDeliveryAddressControl
                    purchaseOrderId={purchaseOrder.id}
                    supplierId={purchaseOrder?.supplierId}
                    onChange={(value) => {
                      onFieldChange(value);
                      setRequiresSave(true);
                    }}
                    value={{
                      deliveryAddressId: formState.deliveryAddressId,
                    }}
                  />
                </Field>

                <Field name="additionalNotes">
                  <Field.Label>Additional notes</Field.Label>
                  <Textarea
                    onChange={(value) => {
                      onFieldChange({ additionalNotes: value });
                    }}
                    onTouch={() => onFieldTouch('additionalNotes')}
                    value={formState.additionalNotes}
                    onChangeTimeout={() => setRequiresSave(true)}
                  />
                </Field>
                <Field name="showMaterialPrices">
                  <Checkbox
                    checked={formState.showMaterialPrices}
                    onChange={() => {
                      onFieldChange({
                        showMaterialPrices: !formState.showMaterialPrices,
                      });
                      setRequiresSave(true);
                    }}
                    label="Show Material prices on Purchase Order"
                  />
                </Field>
              </div>
            </Card.Body>
          </Card>
        </div>
        <SearchMaterialsDrawer
          defaultFilters={{
            supplierIds: [purchaseOrder.supplier.id],
          }}
          isOpen={showSearchMaterialsDrawer}
          onClose={() => setShowSearchMaterialsDrawer(false)}
          disabledMaterialIds={existingMaterialIds}
          onAddMaterials={(materialIds) => {
            const existingMpis = formState.materialPurchaseIntents;

            const newMpis = materialIds
              .filter((materialId) => !existingMaterialIds.includes(materialId))
              .map((materialId) => {
                return {
                  materialId: materialId,
                  materialQuantity: 1,
                };
              });

            onSubmit(
              isValid,
              {
                ...formState,
                materialPurchaseIntents: [...existingMpis, ...newMpis],
              },
              (po) => {
                onFieldChange({
                  materialPurchaseIntents: po.materialPurchaseIntents.map(
                    (mpi) => {
                      return {
                        id: mpi.id,
                        materialQuantity: mpi.materialQuantity,
                      };
                    }
                  ),
                });
              }
            );

            setShowSearchMaterialsDrawer(false);
          }}
        />
      </div>
      {onPreviewPurchaseOrderClick && !!totalMaterialQuantity && (
        <Button onClick={onPreviewPurchaseOrderClick}>
          Preview Purchase Order
        </Button>
      )}
    </div>
  );
};

const MaterialPurchaseIntentControl: FC<{
  materialPurchaseIntent: HydratedMaterialPurchaseIntent;
  onQuantityChange: (
    materialQuantity: MaterialPurchaseIntent['materialQuantity']
  ) => void;
  onQuantityChangeTimeout: () => void;
  onRemove: () => void;
}> = ({
  materialPurchaseIntent,
  onQuantityChange,
  onRemove,
  onQuantityChangeTimeout,
}) => {
  const region = useSelector(getRegion);

  return (
    <div className="flex flex-wrap items-center gap-2">
      <div className="shrink-0">
        <ImageBlock
          imageSrc={materialPurchaseIntent.material.thumbnailUrl}
          renderBlockIfNoImageSrc={true}
        />
      </div>
      <span className="grow font-semibold">
        {materialPurchaseIntent.material.name}
      </span>
      <div className="ml-auto flex shrink-0 grow-0 items-center gap-2">
        <div className="shrink-0 grow-0">
          <BasicField
            label="Qty"
            name="materialQuantity"
            onChange={(value) => {
              onQuantityChange(+value.materialQuantity);
            }}
            inputWidth={InputWidth.XXSM}
            type="numeric"
            additionalInputProps={{
              step: 1,
              min: 0,
              defaultValue: materialPurchaseIntent.materialQuantity,
            }}
            labelPosition={LabelPositionVariant.INLINE}
            onChangeTimeout={onQuantityChangeTimeout}
            changeTimeoutMs={500}
          />
        </div>
        <span className="w-[5rem] shrink-0 grow-0">
          {currencyPrice(
            materialPurchaseIntent.predictedUnitPriceExcludingTax,
            region
          )}
        </span>
        <div>
          <IconButton
            icon={faTimes}
            onClick={onRemove}
            size={'sm'}
            colourVariant={ButtonColourVariant.WHITE}
          />
        </div>
      </div>
    </div>
  );
};

export default IsolatedPurchaseOrderControl;
